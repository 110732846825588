import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface CalendarWeekOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  data: any[];
  previewLiveData: boolean;

  use24Hour: boolean;
  weekOffset: number;
  syncFonts: boolean;
  syncTextColors: boolean;

  // TODO: Do we want this? Never exposed..
  dayFormat: string;

  verticalLines_color: string;
  event_backgroundColor: string;

  // can be exposed together
  today_backgroundColor: string;
  today_textColor: string; // TODO: Might be better to store as todayTextColor, so does not get synced...  but worried about backwards compat..

  eventTitle_fontFamily: string | null;
  eventTitle_textColor: string | null;
  eventTitle_fontSize: number;
  eventTitle_fontWeight: number;
  eventTitle_letterSpacing: number;
  eventTitle_lineHeight: number;
  eventTitle_textAlign: string;
  eventTitle_fontStyle: string;
  eventTitle_textTransform: string;

  eventTime_fontFamily: string | null;
  eventTime_textColor: string | null;
  eventTime_fontSize: number;
  eventTime_fontWeight: number;
  eventTime_letterSpacing: number;
  eventTime_lineHeight: number;
  eventTime_textAlign: string;
  eventTime_fontStyle: string;
  eventTime_textTransform: string;

  days_fontFamily: string | null;
  days_textColor: string | null;
  days_fontSize: number;
  days_fontWeight: number;
  days_letterSpacing: number;
  days_lineHeight: number;
  days_textAlign: string;
  days_fontStyle: string;
  days_textTransform: string;

  multidayEventTitle_fontFamily: string | null;
  multidayEventTitle_textColor: string | null;
  multidayEventTitle_fontSize: number;
  multidayEventTitle_fontWeight: number;
  multidayEventTitle_letterSpacing: number;
  multidayEventTitle_lineHeight: number;
  multidayEventTitle_textAlign: string;
  multidayEventTitle_fontStyle: string;
  multidayEventTitle_textTransform: string;
}
const mainColor = "rgba(52, 66, 86, 100)";

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  borderRadius: 0,
  previewLiveData: false,
  use24Hour: false,
  weekOffset: 0,
  dayFormat: "date-short",
  verticalLines_color: "rgba(0,0,0,0.2)",
  event_backgroundColor: mainColor,
  today_backgroundColor: mainColor,
  today_textColor: "white",
  syncFonts: false,
  syncTextColors: false,
};

export const defaultTextOptions: any = {
  days_fontSize: 32,
  days_fontWeight: 700,
  days_textAlign: "center",
  days_textColor: mainColor,
  days_textTransform: "uppercase",
  days_lineHeight: 1.2,
  eventTitle_fontSize: 28,
  eventTitle_fontWeight: 400,
  eventTitle_textAlign: "left",
  eventTitle_textColor: mainColor,
  eventTitle_textTransform: "none",
  eventTitle_lineHeight: 1.2,
  eventTime_fontSize: 32,
  eventTime_fontWeight: 700,
  eventTime_textAlign: "left",
  eventTime_textColor: mainColor,
  eventTime_textTransform: "none",
  eventTime_lineHeight: 1.2,
  multidayEventTitle_fontSize: 28,
  multidayEventTitle_fontWeight: 400,
  multidayEventTitle_textAlign: "left",
  multidayEventTitle_textColor: "#ffffff",
  multidayEventTitle_textTransform: "none",
  multidayEventTitle_lineHeight: 2.0,
};

// Add uniform defaults to all text fields:
["eventTitle", "eventTime", "days", "multidayEventTitle"].forEach((prop) => {
  defaultTextOptions[`${prop}_fontFamily`] = "Montserrat";
  defaultTextOptions[`${prop}_textDecoration`] = "none";
  defaultTextOptions[`${prop}_fontStyle`] = "normal";
  defaultTextOptions[`${prop}_letterSpacing`] = 0;
});

defaultTextOptions.days_textAlign = "center";

const CreateCalendarWeek = (options: Partial<CalendarWeekOptions>) => {
  return Object.assign({}, defaultOptions, defaultTextOptions, options, {
    wid: makeId(),
    type: "CalendarWeek",
    name: DEFAULT_LAYER_NAMES.CalendarWeek,
  });
};

export default CreateCalendarWeek;
