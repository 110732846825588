<template>
  <portal to="help">
    <HelpBubble
      id="conditions-help-1"
      key="help1"
      class="w-72 transform -translate-x-3"
      position="l"
      :closable="true"
      :style="help1Style"
      @close="closeHelp1"
    >
      <div class="leading-tight space-y-3">
        <div v-t="'ConditionsHelp.tip1of2'"></div>
        <div v-t="'ConditionsHelp.tip1Text1'"></div>
        <div v-t="'ConditionsHelp.tip1Text2'"></div>
      </div>
    </HelpBubble>

    <HelpBubble
      id="conditions-help-2"
      key="help2"
      class="w-72 -translate-x-3"
      position="l"
      :closable="true"
      :style="help2Style"
      @close="closeHelp2"
    >
      <div class="leading-tight space-y-3">
        <div v-t="'ConditionsHelp.tip2of2'"></div>
        <div v-t="'ConditionsHelp.tip2Text1'"></div>
        <div v-t="'ConditionsHelp.tip2Text2'"></div>
      </div>
    </HelpBubble>
  </portal>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HelpBubble from "@/components/HelpBubble.vue";
import { EventBus } from "@/eventbus";
import { userPreferences } from "@/userPreferences";
import { CSSProperties } from "vue/types/jsx";
import gsap from "gsap";

@Component({
  components: {
    HelpBubble,
  },
})
export default class ConditionsEditorHelpBubbles extends Vue {
  helpIndex = 0;

  help1Style: CSSProperties = {
    opacity: "0",
    pointerEvents: "none",
    top: "0px",
    left: "0px",
  };

  help2Style: CSSProperties = {
    opacity: "0",
    pointerEvents: "none",
    top: "0px",
    left: "0px",
  };

  get showBubbles() {
    return this.helpIndex === 1 || this.helpIndex === 2;
  }

  created() {
    /**
     * We wait for this event to fire before showing the help bubbles.
     * This event is currently fired from ConditionSaved.returnToCanvas()
     *
     * Using `any` here because the type definition for mitt is wrong.
     */
    EventBus.on("CONDITION_CREATED", this.handleConditionCreated as any);
  }

  beforeDestroy() {
    // Using `any` here because the type definition for mitt is wrong.
    EventBus.off("CONDITION_CREATED", this.handleConditionCreated as any);
  }

  handleConditionCreated(conditionUuid: string) {
    const skipHelp =
      userPreferences.get<boolean>("Conditions.SkipHelpBubbles") === true;

    if (skipHelp) {
      return;
    }

    this.showHelp1();
    this.removeCanvasInteractivity();

    /**
     * We need to use a double nextTick here because of quirks
     * with portal-vue and refs.
     *
     * https://v2.portal-vue.linusb.org/guide/caveats.html#refs
     */
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.positionHelpBubbles(conditionUuid);
        this.ensureHelpBubblesAreVisible();
      });
    });
  }

  /**
   * Because help bubbles need to exist in a <portal> in the root of the app,
   * we need to position them manually according to the position of the
   * condition button that was just added.
   *
   * This is very brittle in the sense that we are using CSS selectors
   * to reference elements in the DOM.
   */
  positionHelpBubbles(conditionUuid: string) {
    const activeButton = document.querySelector(
      `[data-condition-button="${conditionUuid}"]`
    ) as HTMLElement;

    if (activeButton) {
      const box = activeButton.getBoundingClientRect();
      const help1 = document.querySelector("#conditions-help-1") as HTMLElement;
      if (help1) {
        this.help1Style.top = `${box.top + box.height / 2}px`;
        this.help1Style.left = `${box.left - help1.clientWidth}px`;
      }
    }
    const copyButton = document.querySelector(
      "#condition-copy-button"
    ) as HTMLButtonElement;
    if (copyButton) {
      const box = copyButton.getBoundingClientRect();
      this.help2Style.top = `${box.top + box.height / 2}px`;
      this.help2Style.left = this.help1Style.left;
    }
  }

  /**
   * This is a bit of a hack to ensure the help bubbles are within the viewport
   * when there are lots of conditions, or if the editor panel is scrolled out
   * of view.
   *
   * This is very brittle in the sense that we are using CSS selectors
   * to reference elements in the DOM.
   */
  ensureHelpBubblesAreVisible() {
    const help2 = document.querySelector("#conditions-help-2") as HTMLElement;

    const scrollPanel = document.querySelector(
      ".RightEditorPanel"
    ) as HTMLDivElement;

    if (help2) {
      const help1Top = parseInt(this.help1Style.top as string, 10);
      const help2Top = parseInt(this.help2Style.top as string, 10);

      const box = help2.getBoundingClientRect();
      const diff = help2Top + box.height - window.innerHeight;

      if (diff > 0) {
        this.help1Style.top = `${help1Top - diff}px`;
        this.help2Style.top = `${help2Top - diff}px`;
        scrollPanel.scrollTop += diff;
      }
    }
  }

  showHelp1() {
    gsap.fromTo(
      "#conditions-help-1",
      { opacity: 0, pointerEvents: "none" },
      { opacity: 1, pointerEvents: "auto", duration: 0.3 }
    );
  }

  closeHelp1() {
    gsap
      .timeline()
      .fromTo(
        "#conditions-help-1",
        { opacity: 1, pointerEvents: "auto" },
        {
          opacity: 0,
          pointerEvents: "none",
          duration: 0.15,
          ease: "power2.out",
        }
      )
      .fromTo(
        "#conditions-help-2",
        { opacity: 0, pointerEvents: "none" },
        { opacity: 1, pointerEvents: "auto", duration: 0.3 }
      );
  }

  closeHelp2() {
    gsap
      .timeline()
      .fromTo(
        "#conditions-help-2",
        { opacity: 1, pointerEvents: "auto" },
        { opacity: 0, pointerEvents: "none", duration: 0.15 }
      );

    // Store user preference to skip help bubbles in the future
    userPreferences.set("Conditions.SkipHelpBubbles", true);
    this.restoreCanvasInteractivity();
  }

  removeCanvasInteractivity() {
    (document.querySelector("#help-portal") as HTMLDivElement).classList.add(
      "inset-0"
    );
  }

  restoreCanvasInteractivity() {
    (document.querySelector("#help-portal") as HTMLDivElement).classList.remove(
      "inset-0"
    );
  }
}
</script>
