<template>
  <div class="w-full">
    <TimeDisplay
      class="start flex"
      :value="start"
      :options="options"
      :meridiemScale="meridiemScale"
    />
    <TimeDisplay
      class="flex"
      :value="end"
      :options="endOptions"
      :meridiemScale="meridiemScale"
    />
  </div>
</template>

<script lang="ts">
import { DateTime, DateTimeFormatOptions } from "luxon";
import { Component, Prop, Vue } from "vue-property-decorator";
import TimeDisplay from "@/components/widgets/TimeDisplay.vue";

@Component({
  components: { TimeDisplay },
})
export default class TimeRangeDisplay extends Vue {
  @Prop(Object) start: DateTime;
  @Prop(Object) end: DateTime;
  @Prop(Object) options: DateTimeFormatOptions;
  @Prop(Number) meridiemScale: number;
  @Prop(Boolean) mergeSameDay: boolean;

  get endOptions() {
    return this.options;
  }
}
</script>

<style scoped>
.start::after {
  content: "-";
  margin-left: 3pt;
  margin-right: 3pt;
}
</style>
