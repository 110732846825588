<template>
  <div class="flex flex-col items-center justify-center w-full px-3 pb-1">
    <div
      v-if="dataName"
      class="flex w-56 justify-start items-start flex-col px-3 pb-2 mb-3"
    >
      <div class="flex flex-col items-start">
        <div class="flex items-center -ml-2">
          <LightningBoltIcon :hover="false" :on="true" class="w-7 h-7" />

          <span
            v-t="'LightningBolt.connectedTo'"
            class="uppercase text-xs text-gray-500"
          ></span>
        </div>
        <div class="text-md flex space-x-2 items-center">
          <div>
            {{ dataName }}
          </div>
          <div v-if="isModerated" @click="clickModerate">
            <Icon
              name="ShieldCheck"
              class="text-app-teal w-5 h-5 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Add or replace data prompt button: -->
    <ButtonGradient
      @click="openDataWizard"
      padding="px-4 w-56 h-10"
      class="text-white"
      customGradient="linear-gradient(-3deg, rgb(255, 93, 58) , rgb(214, 31, 150) 60%,  rgb(155, 42, 220))"
    >
      <div class="flex items-center w-full relative justify-between">
        <div v-t="addOrReplaceDataText" class="whitespace-nowrap text-sm"></div>
        <LightningBoltIconWhite
          class="w-6 h-6"
          style="transform: scaleX(0.8)"
        />
      </div>
    </ButtonGradient>

    <div class="flex flex-col space-y-3 items-start py-3">
      <button
        type="button"
        v-if="showDataManagerButton"
        @click="clickManage"
        class="flex items-center font-semibold rounded px-4 py-3 w-56 h-10 border hover:border-app-teal"
      >
        <div class="flex items-center w-full relative">
          <div v-t="'LightningBolt.editConnection'"></div>
          <Icon name="Table" class="absolute text-gray-400 -right-px w-6 h-6" />
        </div>
      </button>

      <portal to="filterModal">
        <FilterManager v-if="isFilterManagerVisible" />
      </portal>

      <button
        type="button"
        @click="openFilterManager"
        class="flex items-center font-semibold rounded px-4 py-3 w-56 h-10 border hover:border-app-teal"
        v-if="dataName"
      >
        <div class="flex items-center w-full relative">
          <div v-t="'filter'"></div>
          <div
            class="ml-3 rounded bg-gray-300 h-5 w-5 text-xs flex justify-center items-center"
            v-if="filterStore.totalSavedRules > 0"
          >
            {{ filterStore.totalSavedRules }}
          </div>
          <Icon name="Filter" class="absolute text-gray-400 -right-1 w-6 h-6" />
        </div>
      </button>

      <button
        type="button"
        v-if="connection"
        @click="clickRemove"
        class="flex items-center font-semibold rounded px-4 py-3 w-56 h-10 border hover:border-red-500"
      >
        <div class="flex items-center w-full relative">
          <div v-t="'LightningBolt.unbind'"></div>
          <UnbindIcon
            class="cursor-pointer h-8 w-8 absolute -right-1"
            :disabled="removingConnection"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { NodeSetData } from "@/types/data";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import LightningBoltIconWhite from "@/components/icons/LightningBoltIconWhite.vue";
import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import FilterManager from "@/components/data/connections/filters/FilterManager.vue";

import FormLabel from "@/components/FormLabel.vue";
import Icon from "@/components/icons/Icon.vue";

import { ComponentOptions } from "./ComponentOptions";
import ButtonGradient from "@/components/ButtonGradient.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  PLACEHOLDER_CONNECTION_UUID,
} from "@/constants";
import UnbindIcon from "@/components/icons/UnbindIcon.vue";
import { isEmpty } from "@core/utils/isEmpty";
import { useFilterEditorStore } from "@/stores/filterEditor";
import { useAppEditorStore } from "@/stores/appEditor";
import { logger } from "@core/logger";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionsStore } from "@/stores/connections";

// Hmm...should index cycler only appear when previewLive?
// If we show it, have to change number of indices that they cycle through

interface DatasetComponent extends ComponentOptions {
  dataIndex: number;
}

@Component({
  components: {
    FilterManager,
    FormLabel,
    Icon,
    ButtonGradient,
    ToggleInput,
    UnbindIcon,
    LightningBoltIconWhite,
    LightningBoltIcon,
  },
})
export default class DataSetChooser extends Vue {
  @Prop({ default: false }) isRepeater: boolean;

  removingConnection = false;

  @Watch("selectedWidget", { deep: true })
  selectedWidgetChanged() {
    this.loadFilter();
  }

  created() {
    this.loadFilter();
  }

  loadFilter() {
    if (!this.dataBinding) return;
    this.filterEditorStore.loadFilter(
      this.dataBinding.dataConnectionUuid as string,
      this.dataBinding.filterUuid
    );
  }

  get isFilterManagerVisible() {
    return this.filterEditorStore.showModal;
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get appData() {
    return useAppDataStore();
  }

  get filterStore() {
    return useFilterEditorStore();
  }

  get selectedWidget() {
    if (this.appEditor.selectedWidget === undefined) {
      logger.track("DataSetChooser: selectedWidget is undefined");
    }
    return this.appEditor.selectedWidget as unknown as DatasetComponent;
  }

  get addOrReplaceDataText() {
    return this.dataBinding
      ? this.$t("connectionSourceChooser.replaceDataSource")
      : this.$t("connectionSourceChooser.connectToDataSource");
  }

  get connectionEditorBasePath() {
    return `${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`;
  }

  get connectionEditorFullPath() {
    return `${this.connectionEditorBasePath}/${
      this.connection?.uuid || PLACEHOLDER_CONNECTION_UUID
    }`;
  }

  clickModerate() {
    this.$router.push({
      path: `${this.connectionEditorFullPath}/settings/moderation`,
    });
  }

  clickManage() {
    this.$router.push({
      path: this.connectionEditorFullPath,
      query: {
        type: "Collection",
        property: "data",
        widgetId: this.selectedWidget.wid,
      },
    });
  }

  clickRemove() {
    console.log("remove");
    if (this.isRepeater) {
      this.$emit("clickRemove");
      return;
    }

    this.appEditor.removeDataBinding(this.dataBinding);

    this.appEditor.updateApp();
  }

  get showDataManagerButton(): boolean {
    // Always show "Manage Data" button for Graphs, because they have placeholder data
    // But for Repeaters, only show if there is a data connection
    if (this.selectedWidget?.type != "Repeater") {
      return true;
    }
    return this.dataName ? true : false;
  }

  get dataBinding() {
    const bindingType =
      this.selectedWidget?.type === "Repeater" ? "DataSetParent" : "DataSet";

    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.selectedWidget.wid,
      bindingType: bindingType,
    });
    return bindings[0];
  }

  get dataSet() {
    if (this.hasDataBinding) {
      const dataUuid = this.dataBinding.dataUuid as string;
      const dataSet = this.appData.data[this.selectedWidget.wid];
      return dataSet[dataUuid] as NodeSetData;
    }
    return {
      children: [],
    };
  }

  get connection() {
    // console.log("db...", this.dataBinding);
    if (this.hasDataBinding) {
      return useConnectionsStore().connections.find(
        (c) => c.uuid === (this.dataBinding.dataConnectionUuid as string)
      );
    }
    return null;
  }

  get isModerated() {
    if (isEmpty(this.connection?.moderationMode)) return false;
    return this.connection?.moderationMode;
  }

  get dataName() {
    // console.log("name...", this.connection);
    return this.connection?.name;
  }

  get supportedSources() {
    return [];
  }

  get hasDataBinding() {
    return !!this.dataBinding;
  }

  get filterEditorStore() {
    return useFilterEditorStore();
  }

  openFilterManager() {
    this.filterEditorStore.openEditor(
      this.dataBinding.dataConnectionUuid as string,
      this.dataBinding.filterUuid
    );
  }

  openDataWizard() {
    /**
     * This same pattern should work for Repeaters, and all Graphs, which are the contexts in which this component is used right now.
     */

    const path = this.hasDataBinding
      ? `${this.connectionEditorFullPath}/replace`
      : `${this.connectionEditorBasePath}/new`;

    this.$router.push({
      path,
      query: {
        type: "Collection",
        property: "data",
        widgetId: this.selectedWidget.wid,
      },
    });
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.selectedWidget.wid], props);
  }
}
</script>

<style lang="postcss" scoped>
/* .data-button {
  @apply flex items-center font-semibold rounded px-4 py-3 w-56 h-10 border hover:border-app-teal;
} */
</style>
