<template>
  <div
    class="relative flex-grow flex min-h-0 flex-col h-full"
    :class="{ 'p-8': isReplacing }"
  >
    <SetupNavigation />

    <div
      class="h-full overflow-y-auto dark-scrollbar flex-grow flex flex-col border-b border-l border-r border-gray-900 shadow-xl rounded-br-lg rounded-bl-lg bg-gray-700"
    >
      <div class="relative w-full flex-grow px-6 py-4 justify-center">
        <component
          v-if="setupStep"
          :is="steps[currentStepName]"
          :setup-step="setupStep"
          @complete="onStepComplete"
        />
        <FatalServerError
          v-if="showFatalError"
          class="absolute z-10 p-16 inset-0 flex bg-gray-700 flex-col items-center"
        />
      </div>

      <div
        v-if="showFooterNav"
        class="flex flex-grow-0 border-t border-app-dark3 px-6 py-4"
        :class="{
          'justify-between': showBackButton,
          'justify-end': !showBackButton,
        }"
      >
        <router-link
          v-if="showBackButton"
          class="rounded flex items-center text-white border select-none dark-form-focus border-gray-500 hover:border-gray-400"
          :to="getBackLink"
          ><span class="px-3" v-t="'back'"></span
        ></router-link>

        <portal-target name="setupWizardSelect" slim></portal-target>

        <portal-target name="setupWizardNext" slim></portal-target>
      </div>
      <portal-target name="setupWizardUiBlocker" slim></portal-target>
    </div>
  </div>
</template>

<script lang="ts">
import RemapFilterConditionColumnsMixin from "@/components/RemapFilterConditionColumnsMixin";
import { defineComponent } from "vue";
export default defineComponent({
  mixins: [RemapFilterConditionColumnsMixin],
  created() {
    this.findAndSetNodesRequiringRemap();
  },
});
</script>

<script setup lang="ts">
import { computed, onMounted, watch, ref, onUnmounted } from "vue";
import {
  useConnectionSetupStoreV2,
  ConnectionSetupInitParams,
} from "@/stores/connectionSetupV2";
import {
  useRoute,
  useRouter,
  onBeforeRouteUpdate,
} from "vue-router/composables";

import { getQueryValue } from "@/utils";
import SetupNavigation from "@/components/data/connections/setup/SetupNavigationV2.vue";
import FatalServerError from "@/components/FatalServerError.vue";
import SetupProvideV2 from "./SetupProvideV2.vue";
import SetupSchemaV2 from "./SetupSchemaV2.vue";
import SetupSyncV2 from "./SetupSyncV2.vue";
import SetupNameV2 from "./SetupNameV2.vue";
import SetupCompleteV2 from "./SetupCompleteV2.vue";
import SetupReplaceV2 from "./SetupReplaceV2.vue";
import SetupSelectV2 from "./SetupSelectV2.vue";

const steps = {
  SetupProvideV2,
  SetupSchemaV2,
  SetupSyncV2,
  SetupNameV2,
  SetupCompleteV2,
  SetupReplaceV2,
  SetupSelectV2,
};

const route = useRoute();
const router = useRouter();
const setupStore = useConnectionSetupStoreV2();
const isReplacing = ref(false);
const setupStep = computed(() => setupStore.currentStep);
const showBackButton = computed(
  () => !setupStore.isSetupComplete && getBackLink.value !== undefined
);
const showFatalError = computed(() => setupStore.hasUnhandledServerError);
const currentStepName = computed(
  () => setupStore.currentStep?.clientComponentName
);

const showFooterNav = computed(() => {
  return !setupStore.isSetupComplete;
});

onMounted(() => {
  initState();
});

onUnmounted(() => {
  setupStore.$reset();
});

watch(
  () => route.fullPath,
  () => bindRouteValues()
);

onBeforeRouteUpdate(async (to, from, next) => {
  initStep(to.params.stepName);
  next();
});

function initStep(stepName: string) {
  setupStore.setCurrentStep(stepName);
}

function initState() {
  const routeVals = bindRouteValues();
  setupStore.init(routeVals);
  isReplacing.value = route.name === "connection-replace-setup-v2";
}

function onStepComplete() {
  setupStore.completeStep().then(() => {
    const nextStep = setupStore.nextStep;
    if (nextStep) {
      router.push({
        path: nextStep.id,
      });
    }
  });
}

const getBackLink = computed(() => {
  const step = setupStore.previousStep;
  if (!step) return undefined;

  const query = route.query;
  return { path: step.id, query };
});

function bindRouteValues(): ConnectionSetupInitParams {
  return {
    providerSlug: route.params.providerSlug,
    stepId: route.params.stepName,
    activeConnectionUuid: route.params.connectionUuid,
    widgetId: getQueryValue(route.query?.widgetId),
    propertyName: getQueryValue(route.query?.property),
    propertyType: getQueryValue(route.query?.type),
    bindingType: getQueryValue(route.query?.bindingType),
    isReplacing: route.name === "connection-replace-setup-v2",
  };
}
</script>
