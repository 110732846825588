<template>
  <div class="space-y-8">
    <div>
      <div class="text-2xl">Your Connection has been created</div>
      <div>Now you can add data to this connection by using the API.</div>
    </div>

    <ApiExample title="How to Add Rows" verb="POST" :uri="apiUrl">
      <pre>
{
  "rows": {
    "columns": [
      { "columnId": "A",  "value": "Pickup" }
    ]
  }
}</pre
      >
    </ApiExample>

    <ApiExample title="How to List all rows" verb="GET" :uri="apiUrl"
      >No request body required for GET</ApiExample
    >

    <ApiExample title="How to Delete Rows" verb="DELETE" :uri="apiUrl">
      <pre>
{ 
  "rowUuids": [ 
    "dc7050ee-f8eb-46bd-976e-add929ad3c70",
    "ab7050ee-f8eb-46bd-976e-add929ad3444" 
  ]
}</pre
      >
    </ApiExample>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { appSettings } from "@/appSettings";
import { useConnectionSetupStore } from "@/stores/connectionSetup";
import ApiExample from "@/components/data/connections/setup/complete/ApiExample.vue";
@Component({
  components: { ApiExample },
})
export default class CompleteZapier extends Vue {
  get store() {
    return useConnectionSetupStore();
  }

  get apiUrl() {
    return `${appSettings.backEnd.baseUrlAndPath}/dataconnection/${this.store.connectionUuid}/manualtabledata`;
  }
}
</script>

<style lang="postcss"></style>
