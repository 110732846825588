<template>
  <div class="flex space-x-1 items-center">
    <span>$</span>
    <input
      :id="makeKeyId(rowUuid, node.uuid || '')"
      type="number"
      v-model="currency"
      @click.stop
      @blur="$emit('blur', $event)"
    />
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import InputBase from "@/components/data/connections/manualSource/inputs/InputBase.vue";
import ColorPicker from "@/components/inputs/ColorPicker.vue";
import { formatCurrencyString } from "@/utils";

// TODO: appears to be disconnect/convert error -- $2,000 goes to $2 and $1,500 to $1.5.... prob the comma vs decimal issue...

@Component({
  components: {
    ColorPicker,
  },
})
export default class CurrencyInput extends InputBase {
  /**
   * When we set the value, we pass in both the numeric value and the formatted string value.
   * We set both properties on the node, so this should still work
   * (It works when fetching data from server because value is computed there based on formattedValue)
   */
  get currency() {
    return this.value;
  }

  set currency(val: number) {
    this.setNodeValue({
      rowUuid: this.rowUuid,
      columnUuid: this.node.uuid || "",
      formattedValue: formatCurrencyString(val),
      value: val,
    });
  }
}
</script>
