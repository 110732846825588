<template>
  <div class="pb-10">
    <!-- Header: -->
    <div
      class="flex justify-between border-b border-app-dark1 px-6 pt-5 pb-4 sticky top-0 bg-app-dark2 z-10"
    >
      <div class="flex items-center">
        <Icon class="h-5 w-5" name="CloudUpload" />
        <span class="ml-4 text-2xl" v-t="'SubscribeToPublish.title'"></span>
      </div>

      <div class="flex space-x-5">
        <div @click.prevent="onClose">
          <ButtonGradient
            class="px-2 py-1 border-2 border-gray-600"
            customGradient="transparent"
            v-t="'close'"
          >
          </ButtonGradient>
        </div>
      </div>
    </div>

    <!-- Body: -->
    <div class="w-full px-6">
      <div
        v-t="'SubscribeToPublish.firstLine'"
        class="mt-6 font-bold text-app-pink"
      ></div>
      <div v-t="'SubscribeToPublish.secondLine'" class="mt-6"></div>

      <div
        v-if="metadata.hasReseller"
        v-t="'SubscribeToPublish.contactReseller'"
        class="mt-6"
      ></div>

      <div v-if="!metadata.hasReseller">
        <div v-t="'SubscribeToPublish.chooseOption'" class="mt-6"></div>

        <div class="ml-6 mt-4">
          <span
            class="font-bold"
            v-t="'SubscribeToPublish.optionUnlimitedName'"
          ></span>
          <span v-t="'SubscribeToPublish.optionUnlimited'"></span>
        </div>
        <div class="ml-6">
          <a
            class="text-app-teal"
            v-t="'SubscribeToPublish.callToSubscribe'"
            :href="metadata.subscribeToConnectUnlimitedUrl"
            target="_blank"
          ></a>
        </div>

        <div class="ml-6 mt-4">
          <span
            class="font-bold"
            v-t="'SubscribeToPublish.optionOneName'"
          ></span>
          <span v-t="'SubscribeToPublish.optionOne'"></span>
        </div>
        <div class="ml-6">
          <a
            class="text-app-teal"
            v-t="'SubscribeToPublish.callToSubscribe'"
            :href="metadata.subscribeToConnectOneUrl"
            target="_blank"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { CanPublishMetadata } from "@/types/data";
import ButtonGradient from "@/components/ButtonGradient.vue";
import Icon from "@/components/icons/Icon.vue";

@Component({
  components: {
    ButtonGradient,
    Icon,
  },
})
export default class SubscribeToPublish extends Vue {
  @Prop(Object) metadata: CanPublishMetadata;

  onClose() {
    this.$emit("close");
  }
}
</script>
