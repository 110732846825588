<template>
  <div class="px-4 py-1 overflow-auto h-full text-sm">
    <DraggableLayers :contextWid="'base'"></DraggableLayers>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import draggable from "vuedraggable";
import DraggableLayers from "./DraggableLayers.vue";

@Component({
  components: {
    Icon,
    draggable,
    DraggableLayers,
  },
})
export default class LayerMenu extends Vue {}
</script>

<style scoped></style>
