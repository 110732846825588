import { NodeKind } from "@/types/data";

export const StackedGraphPlaceholderData = [
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Cinnamon",
      formattedValue: "Cinnamon",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Amount",
      uuid: "b",
      value: 3,
      formattedValue: "3",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 0,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Fennel Seeds",
      formattedValue: "Fennel Seeds",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Amount",
      uuid: "b",
      value: 6,
      formattedValue: "6",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 1,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Sichuan Peppercorns",
      formattedValue: "Sichuan Peppercorns",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Amount",
      uuid: "b",
      value: 3,
      formattedValue: "3",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 2,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Cloves",
      formattedValue: "Cloves",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Amount",
      uuid: "b",
      value: 1,
      formattedValue: "1",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 3,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Sand Ginger",
      formattedValue: "Sand Ginger",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Amount",
      uuid: "b",
      value: 3,
      formattedValue: "3",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 4,
      query: "__sf_index__",
    },
  ],
].map((row) => {
  return row.map((node) => {
    return {
      ...node,
      kind: NodeKind.node,
      isArtificial: node.displayName === "Index",
    };
  });
});
