<template>
  <div class="w-full flex flex-grow h-full">
    <SelectTreeValue
      v-if="store.schemaType === 'Tree'"
      :rootNode="store.schema"
      :selectedQuery="selectedNode?.query"
      :selectionType="store.propertyType"
      @select="onSelectClick"
    />

    <SelectSpreadsheetValue
      v-if="store.schemaType === 'Tabular'"
      :sheets="store.sheets"
      :rangeId="store.queryContext"
      :connectionUuid="store.connectionUuid"
      :propertyType="store.propertyType"
      @sheetSelected="onQueryContextSelected"
      @select="onSelectClick"
    />

    <portal to="setupWizardSelect">
      <ScalarNodeDisplay
        v-if="nodeDisplayProps.value"
        :nodeDisplayProps="nodeDisplayProps"
      />
      <div v-else v-t="'selectionPrompt'"></div>
    </portal>

    <portal to="setupWizardNext">
      <FormButton
        :disabled="!nodeDisplayProps.value"
        v-t="'Select'"
        @click="onCompleteClick"
      ></FormButton>
    </portal>

    <portal to="setupWizardUiBlocker">
      <UiBlocker :visible="saving">
        <div
          class="mt-4 text-xl text-white"
          v-t="'SetupName.savingConnection'"
        ></div>
      </UiBlocker>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConnectionSourceId } from "@/types/ConnectionSources";
import { ScalarNode, useConnectionSetupStore } from "@/stores/connectionSetup";

import UiBlocker from "@/components/UiBlocker.vue";
import SelectTreeValue from "@/components/data/connections/setup/selection/SelectTreeValue.vue";
import SelectSpreadsheetValue from "@/components/data/connections/setup/selection/SelectSpreadsheetValue.vue";
import FormButton from "@/components/FormButton.vue";
import ScalarNodeDisplay from "@/components/data/connections/manage/data/views/ScalarNodeDisplay.vue";
import { NodeData } from "@/types/data";

@Component({
  components: {
    SelectTreeValue,
    SelectSpreadsheetValue,
    UiBlocker,
    FormButton,
    ScalarNodeDisplay,
  },
})
export default class SetupSelection extends Vue {
  @Prop(String) sourceId: ConnectionSourceId;

  saving = false;

  get store() {
    return useConnectionSetupStore();
  }

  selectedNode: Partial<NodeData> | null = null;

  created() {
    this.store.step = "Selection";
  }

  onQueryContextSelected(queryContext: string) {
    // console.log("onQueryContextSelected", queryContext);
    this.store.setQueryContext(queryContext);
  }

  // Note: renderValue is coming from SelectSpreadsheetValue
  get nodeDisplayProps() {
    return {
      uuid: this.selectedNode?.uuid,
      dataType: this.selectedNode?.dataType,
      value:
        (this.selectedNode as any)?.renderValue ?? this.selectedNode?.value,
    };
  }

  onSelectClick(node: ScalarNode) {
    this.selectedNode = node;
  }

  onCompleteClick() {
    this.saving = true;
    // Trigger the binding/connection creation, close modal.
    this.store
      .defineSelectedNode(this.selectedNode as ScalarNode)
      .then((routeParams) => {
        this.$router.push({
          path: routeParams.step.toLowerCase(),
          query: { ...this.$route.query, c: routeParams.connectionUuid },
        });
      })
      .finally(() => {
        this.saving = false;
      });
  }
}
</script>
