<template>
  <div
    v-if="visible"
    class="absolute flex flex-col items-center justify-center inset-0 z-50"
    :class="isTransparent ? 'bg-transparent' : 'bg-gray-600 bg-opacity-80'"
  >
    <LoadingAnimation role="alert" aria-busy="true" v-if="animation" />
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import LoadingAnimation from "@/components/LoadingAnimation.vue";

@Component({ components: { LoadingAnimation } })
export default class UiBlocker extends Vue {
  @Prop(Boolean) visible: false;
  @Prop({ default: false }) isTransparent: boolean;
  @Prop({ type: Boolean, default: true }) animation: boolean;
}
</script>
