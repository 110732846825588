<template>
  <div>
    <div class="-mt-1 space-y-1">
      <InputLabel v-t="'SelectColumnValue.selectAColumn'"> </InputLabel>
      <div v-if="isLoadingColumns">Loading...</div>
      <SelectMenu
        v-if="isLoadingColumns === false"
        ref="menu"
        :dark="true"
        class="w-full dark-form-focus"
        :value="nodeUuid"
        @input="onSelected"
        :options="columnOptions"
      />
    </div>
    <div class="mt-2 space-y-1">
      <InputLabel class="flex justify-between">
        <span v-t="'SelectColumnValue.columnValuePreview'"></span>

        <Tooltip
          position="t"
          class="flex space-x-1"
          :disabled="previewTruncateMessage === null"
          :text="previewTruncateMessage"
        >
          <button
            class="text-gray-400"
            :class="{
              'text-gray-600 hover:text-gray-600': prevDisabled,
              'hover:text-gray-300': !prevDisabled,
            }"
            type="button"
            :disabled="prevDisabled"
            @click="prev"
          >
            ◀
          </button>
          <span class="flex space-x-1">
            {{ pageDescription }}
          </span>
          <button
            class="text-gray-400"
            :class="{
              'text-gray-600 hover:text-gray-600': nextDisabled,
              'hover:text-gray-300': !nextDisabled,
            }"
            type="button"
            :disabled="nextDisabled"
            @click="next"
          >
            ▶
          </button>
        </Tooltip>
      </InputLabel>

      <ValuePreview>
        <span class="text-app-gold" v-if="valuePreview">{{
          valuePreview
        }}</span>
        <span
          class="text-gray-500"
          v-else
          v-t="'SelectColumnValue.empty'"
        ></span>
      </ValuePreview>

      <InputHelpText v-t="'SelectColumnValue.valuePreviewHelp'">
      </InputHelpText>
    </div>

    <div class="mt-2 space-y-1">
      <InputLabel v-t="'SelectColumnValue.dataType'"></InputLabel>

      <ValuePreview>
        <span class="text-white">{{ valueDataType }}</span>
      </ValuePreview>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";

import SelectMenu from "@/components/SelectMenu.vue";
import { InputOption } from "@/types/inputs";
import { LogicOperand } from "@/types/logic";
import { OperandChangedEvent } from "./OperandChangedEvent";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import InputLabel from "./InputLabel.vue";
import InputHelpText from "./InputHelpText.vue";
import ValuePreview from "./ValuePreview.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { useColumnInfoStore } from "@/stores/columnInfo";
import { ColumnPreviewInfo } from "@/types/data";
import Tooltip from "@/components/Tooltip.vue";
import { BASE_PARENT_ID } from "@/constants";

@Component({
  components: { SelectMenu, InputLabel, InputHelpText, ValuePreview, Tooltip },
})
export default class ColumnValueSelect extends Vue {
  @Prop(Object) model: LogicOperand;

  columns: ColumnPreviewInfo[] = [];

  isLoadingColumns = false;

  previewIndex = 0;

  get prevDisabled() {
    return this.previewIndex === 0;
  }

  get nextDisabled() {
    return this.previewIndex === this.totalPreviewRecords - 1;
  }

  prev() {
    if (this.previewIndex > 0) {
      this.previewIndex--;
    }
  }

  next() {
    if (this.previewIndex < this.totalPreviewRecords - 1) {
      this.previewIndex++;
    }
  }

  created() {
    this.loadColumns().then(() => {
      if (!isNonEmptyString(this.nodeUuid) && this.columnOptions.length > 0) {
        this.onSelected(this.columnOptions[0].value as string);
      }
    });
  }

  get connectionUuid() {
    if (isNonEmptyString(this.model.connectionUuid)) {
      return this.model.connectionUuid;
    }

    const appEditor = useAppEditorStore();
    const widget = appEditor.selectedWidget;
    if (widget === undefined) {
      return undefined;
    }

    const widgetId =
      widget.parentId === BASE_PARENT_ID ? widget.wid : widget.parentId;

    const db = appEditor.dataBindings.find(
      (db) =>
        db.widgetId === widgetId &&
        (db.bindingType === "DataSetParent" || db.bindingType === "DataSet")
    );

    return db?.dataConnectionUuid;
  }

  loadColumns() {
    if (this.connectionUuid === undefined) {
      return Promise.resolve();
    }

    this.isLoadingColumns = true;

    const columnInfoStore = useColumnInfoStore();
    return columnInfoStore
      .getColumns(this.connectionUuid)
      .then((columns) => {
        this.columns = columns;
      })
      .finally(() => {
        this.isLoadingColumns = false;
      });
  }

  get nodeUuid() {
    return this.model.nodeUuid;
  }

  get columnOptions(): InputOption[] {
    return this.columns.map((n) => {
      return {
        label: n.name,
        value: n.uuid ?? "Empty",
      };
    });
  }

  get valuePreview() {
    const node = this.columns.find((n) => n.uuid === this.model.nodeUuid);

    const value =
      node && Array.isArray(node.sampleValues) && node.sampleValues.length > 0
        ? node.sampleValues[this.previewIndex].formattedValue
        : "";

    return isNonEmptyString(value) ? value : null;
  }

  get pageDescription() {
    const plus = this.previewTruncateMessage === null ? "" : "*";
    return `${this.previewIndex + 1} of ${this.totalPreviewRecords}${plus}`;
  }

  get totalPreviewRecords() {
    const node = this.columns.find((n) => n.uuid === this.model.nodeUuid);
    return node?.sampleValues.length ?? 0;
  }

  get previewTruncateMessage() {
    const node = this.columns.find((n) => n.uuid === this.model.nodeUuid);

    if (
      node &&
      node.importedRecordCount !== undefined &&
      node.importedRecordCount > node.sampleValues.length
    ) {
      return this.$t("SelectColumnValue.previewTruncateMessage", {
        count: node.importedRecordCount,
      }) as string;
    }
    return null;
  }

  // TODO Translate these terms.
  get valueDataType() {
    const node = this.columns.find((n) => n.uuid === this.model.nodeUuid);
    switch (node?.dataType) {
      case "Bool":
        return "True / False";
      case "Date":
      case "Time":
      case "Number":
        return node?.dataType;
      case "DateTime":
        return "Date & Time";
      default:
        return "Text";
    }
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.menu as SelectMenu)?.focus();
    });
  }

  onSelected(uuid: string) {
    this.previewIndex = 0;
    const node = this.columns.find((n) => n.uuid === uuid);
    if (node) {
      const payload: OperandChangedEvent = {
        operand: {
          connectionUuid: this.connectionUuid,
          nodeUuid: uuid,
          valueType: node.dataType,
        },
        node: {
          dataType: node.dataType,
          query: node.query,
          uuid: node.uuid,
        },
      };
      this.$emit("input", payload);
    }
  }
}
</script>
