import { WidgetType } from "@/types";
import { AllWidgets } from "@/components/widgets/index";
import TextWrapper from "@/components/widgets/Text/TextWrapper.vue";
import CalendarAgendaWrapper from "@/components/widgets/CalendarAgenda/CalendarAgendaWrapper.vue";
import CalendarDayWrapper from "@/components/widgets/CalendarDay/CalendarDayWrapper.vue";
import CalendarEventWrapper from "@/components/widgets/CalendarEvent/CalendarEventWrapper.vue";
import CalendarWeekWrapper from "@/components/widgets/CalendarWeek/CalendarWeekWrapper.vue";
import CountdownWrapper from "@/components/widgets/Countdown/CountdownWrapper.vue";
import RepeaterWrapper from "@/components/widgets/Repeater/RepeaterWrapper.vue";
import GroupWrapper from "@/components/widgets/Group/GroupWrapper.vue";
import BarGraphWrapper from "@/components/widgets/BarGraph/BarGraphWrapper.vue";
import ColumnGraphWrapper from "@/components/widgets/ColumnGraph/ColumnGraphWrapper.vue";
import PieGraphWrapper from "@/components/widgets/PieGraph/PieGraphWrapper.vue";
import StackedGraphWrapper from "@/components/widgets/StackedGraph/StackedGraphWrapper.vue";
import LineGraphWrapper from "@/components/widgets/LineGraph/LineGraphWrapper.vue";
import VideoWrapper from "@/components/widgets/Video/VideoWrapper.vue";
import DatetimeWrapper from "@/components/widgets/Datetime/DatetimeWrapper.vue";

const wrappers = {
  TextWrapper,
  CalendarAgendaWrapper,
  CalendarDayWrapper,
  CalendarEventWrapper,
  CalendarWeekWrapper,
  CountdownWrapper,
  RepeaterWrapper,
  GroupWrapper,
  BarGraphWrapper,
  ColumnGraphWrapper,
  PieGraphWrapper,
  StackedGraphWrapper,
  LineGraphWrapper,
  VideoWrapper,
  DatetimeWrapper,
};

export const getWidgetComponent = (type: WidgetType) => {
  const wrapper = `${type}Wrapper`;
  // Special case: also use TextWrapper for Datetime component, in order to get auto-sizing behavior
  // if (type === "Datetime") {
  //   return TextWrapper;
  // }
  if (wrapper in wrappers) {
    return wrapper;
  }
  return `${type}Component`;
};

export const BuilderWidgets = {
  ...AllWidgets,
  ...wrappers,
};
