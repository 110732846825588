<template>
  <div class="space-y-8">
    <div class="text-xl" v-t="'SetupSync.howOften'"></div>

    <fieldset>
      <div class="bg-app-dark2 rounded-md -space-y-px">
        <label
          :key="o.value"
          v-for="(o, index) in options"
          :class="{
            'bg-app-dark1 border-black z-10': refreshRate === o.value,
            'border-gray-900': refreshRate !== o.value,
            'rounded-tl-md rounded-tr-md': index === 0,
            'rounded-bl-md rounded-br-md': index === options.length - 1,
          }"
          class="relative border p-4 flex cursor-pointer focus:outline-none"
        >
          <input
            type="checkbox"
            :checked="refreshRate === o.value"
            :value="refreshRate"
            @change="refreshRate = o.value"
            class="form-checkbox rounded h-4 w-4 mt-0.5 cursor-pointer dark-form-focus"
            :class="{
              'text-app-green': refreshRate === o.value,
              'bg-gray-600': refreshRate !== o.value,
            }"
          />
          <div class="ml-3 flex flex-col">
            <span
              :class="{
                'text-white': refreshRate === o.value,
                'text-gray-300': refreshRate !== o.value,
              }"
              class="block text-sm font-medium"
              v-text="o.label"
            ></span>
            <span
              :class="{
                'text-gray-200': refreshRate === o.value,
                'text-gray-500': refreshRate !== o.value,
              }"
              class="block text-sm"
              v-text="o.description"
            ></span>
          </div>
        </label>
      </div>
    </fieldset>

    <portal to="setupWizardNext">
      <FormButton @click="onContinue" v-t="'continue'"></FormButton>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConnectionRefreshRate } from "@/types/data";
import { useConnectionSetupStore } from "@/stores/connectionSetup";

import TabInput from "@/components/inputs/TabInput.vue";
import FormButton from "@/components/FormButton.vue";
import { DEFAULT_CONNECTION_REFRESH_RATE } from "@/constants";

@Component({
  components: {
    TabInput,
    FormButton,
  },
})
export default class SetupSync extends Vue {
  refreshRate: ConnectionRefreshRate = DEFAULT_CONNECTION_REFRESH_RATE;

  created() {
    this.store.step = "Sync";
  }

  mounted() {
    if (this.store.refreshRate) {
      this.refreshRate = this.store.refreshRate;
    }
  }

  get options() {
    const options: {
      label: string;
      description: string;
      value: ConnectionRefreshRate;
    }[] = [
      {
        label: this.$t("refreshRate.OnDemand").toString(),
        value: 0,
        description: this.$t("refreshRate.OnDemandDescription").toString(),
      },
      {
        // TODO: Figure out how to localize this...
        label: this.$t("refreshRate.Recurring", {
          amount: 15,
          unit: "minutes",
        }).toString(),
        value: DEFAULT_CONNECTION_REFRESH_RATE,
        // TODO: Figure out how to localize this...
        description: this.$t("refreshRate.RecurringDescription", {
          amount: 15,
          unit: "minutes",
        }).toString(),
      },
      {
        label: this.$t("refreshRate.Never").toString(),
        value: null,
        description: this.$t("refreshRate.NeverDescription").toString(),
      },
    ];
    return options;
  }

  get store() {
    return useConnectionSetupStore();
  }

  onContinue() {
    this.store.defineSync(this.refreshRate).then((routeParams) => {
      this.$router.push({
        path: routeParams.step.toLowerCase(),
        query: { ...this.$route.query },
      });
    });
  }
}
</script>
