<template>
  <div class="divide-y">
    <CollapsePanel :shouldListenForOpenCommand="true">
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <div>
        <CalendarDataChooser :widgetId="model.wid" />
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarRoomSchedule.locationTitle'"></div>
      </template>
      <GroupTextFormatEditor
        :model="model"
        element="locationTitle"
        @update="apply($event)"
        :disableAlignment="true"
        :disableLineHeight="true"
      />
      <div class="px-4 space-y-3 pb-3">
        <ColorInputBar
          :value="model.dividerFillColor"
          @input="preview({ dividerFillColor: $event })"
          @change="apply({ dividerFillColor: $event })"
          propName="dividerFillColor"
        >
          <span v-t="'calendarRoomSchedule.dividerFill'"></span>
        </ColorInputBar>
        <NumberInputBar
          :value="model.dividerSize"
          @change="apply({ dividerSize: $event })"
          ><div v-t="'calendarRoomSchedule.dividerSize'"></div
        ></NumberInputBar>
      </div>
      <div class="px-4 -mt-1 space-y-1 pb-5">
        <ToggleInput :value="model.syncFonts" @input="syncFontsInput"
          ><span v-t="'syncFonts'"></span
        ></ToggleInput>

        <ToggleInput :value="model.syncTextColors" @input="syncTextColorsInput"
          ><span v-t="'syncTextColors'"></span
        ></ToggleInput>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarRoomSchedule.headerEventTitle'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="headerEventTitle"
          @update="apply($event)"
          :disableAlignment="true"
          :disableLineHeight="true"
        />
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarRoomSchedule.headerEventTime'"></div>
      </template>
      <div>
        <GroupTextFormatEditor
          :model="model"
          element="headerEventTime"
          @update="apply($event)"
          :disableAlignment="true"
          :disableLineHeight="true"
        />
        <div class="px-4 space-y-3 pb-8">
          <ToggleInput :value="use24Hour" @input="apply({ use24Hour: $event })"
            ><span v-t="'calendarDay.use24HourClock'"></span
          ></ToggleInput>
          <ColorInputBar
            :value="model.progressBarFillColor"
            @input="preview({ progressBarFillColor: $event })"
            @change="apply({ progressBarFillColor: $event })"
            propName="progressBarFillColor"
          >
            <span v-t="'calendarRoomSchedule.progressBarFill'"></span>
          </ColorInputBar>
          <ColorInputBar
            :value="model.progressBarBackgroundColor"
            @input="preview({ progressBarBackgroundColor: $event })"
            @change="apply({ progressBarBackgroundColor: $event })"
            propName="progressBarBackgroundColor"
          >
            <span v-t="'calendarRoomSchedule.progressBarBackground'"></span>
          </ColorInputBar>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarRoomSchedule.subheaderEventTitle'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="subheaderEventTitle"
          @update="apply($event)"
          :disableAlignment="true"
          :disableLineHeight="true"
        />
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarRoomSchedule.subheaderEventTime'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="subheaderEventTime"
          @update="apply($event)"
          :disableAlignment="true"
          :disableLineHeight="true"
        />
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarRoomSchedule.clockTime'"></div>
      </template>
      <div class="pb-3">
        <div class="mb-2 italic p-3 leading-tight rounded bg-gray-100 text-xs">
          This element will only show up in your component if your calendar has
          no upcoming events.
        </div>

        <GroupTextFormatEditor
          :model="model"
          element="clockTime"
          @update="apply($event)"
          :disableAlignment="true"
          :disableLineHeight="true"
        />
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { CalendarRoomScheduleOptions } from "./CalendarRoomScheduleOptions";
import ColorInput from "@/components/inputs/ColorInput.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import CalendarDataChooser from "@/components/widgets/CalendarDataChooser.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    FontFamilyInput,
    CalendarDataChooser,
    CollapsePanel,
    BaseWidgetEditor,
    ToggleInput,
    TextInput,
    GroupTextFormatEditor,
    FormLabel,
    ColorInput,
    ColorInputBar,
    NumberInputBar,
  },
})
export default class CalendarRoomScheduleEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor
      .selectedWidget as unknown as CalendarRoomScheduleOptions;
  }

  get textElements() {
    return [
      "locationTitle",
      "headerEventTime",
      "headerEventTitle",
      "subheaderEventTime",
      "subheaderEventTitle",
    ];
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  syncFontsInput(val: boolean) {
    this.appEditor.toggleSyncFonts({
      wid: this.model.wid,
      syncFonts: val,
      textElements: this.textElements,
    });
  }

  syncTextColorsInput(val: boolean) {
    this.appEditor.toggleSyncTextColors({
      wid: this.model.wid,
      syncTextColors: val,
      textElements: this.textElements,
    });
  }

  get use24Hour() {
    return this.model.use24Hour;
  }
}
</script>
