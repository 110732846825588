<template>
  <div class="space-y-4">
    <!-- Sync info box: -->
    <div class="flex flex-col space-y-4 items-start">
      <div v-if="dataLastUpdated" class="">
        <p v-t="'ManualSync.lastSyncOccurred'"></p>
        <h3>{{ syncDateRender }}</h3>
      </div>

      <FormButton
        v-if="showSyncButton"
        @click="showConfirmBox = true"
        :disabled="!canRefresh"
        class="flex items-center pl-2 space-x-2"
      >
        <IconSolid class="w-5 h-5" name="Refresh" />
        <span v-t="'ManualSync.syncNow'"></span>
      </FormButton>
    </div>

    <!-- Confirmation box: -->
    <div v-if="showConfirmBox" class="border-t border-gray-800 pt-4">
      <div v-t="'ManualSync.beforeSync1'"></div>
      <ul>
        <li v-t="'ManualSync.beforeSync2'"></li>
        <li v-t="'ManualSync.beforeSync3'"></li>
      </ul>

      <div class="flex space-x-4 mt-4">
        <FormButton @click="syncData" v-t="'continue'"></FormButton>

        <OutlineButton
          @click="showConfirmBox = false"
          v-t="'cancel'"
        ></OutlineButton>
      </div>
    </div>

    <!-- Auth Error box: -->
    <div class="mt-6 space-y-6" v-if="hasError">
      <div v-if="!isAuthError" class="text-red-500">
        <div v-t="'ManualSync.fatalSyncError'"></div>
        <br />
        <div v-if="errorCode">
          <div>{{ connectionErrorMessage }}</div>
        </div>
      </div>

      <div
        v-if="isAuthError"
        class="text-red-500"
        v-t="'ManualSync.authError'"
      ></div>

      <OutlineButton
        v-if="isAuthError"
        @click="$router.push(fixConnectionPath)"
        v-t="'Manager.fixConnectionProblem'"
      ></OutlineButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { DateTime, DateTimeFormatOptions } from "luxon";

import FormButton from "@/components/FormButton.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import { getConnectionErrorMessage } from "@/components/data/connections/connectionErrorMessage";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    FormButton,
    OutlineButton,
    IconSolid,
  },
})
export default class ManualSync extends Vue {
  showConfirmBox = false;
  syncButtonDisabled = false;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get errorCode() {
    return this.connectionEditor.errorCode;
  }

  get errorMessage() {
    return this.connectionEditor.errorMessage;
  }

  get lastDataSourceUpdate() {
    return this.connectionEditor.lastDataSourceUpdate;
  }

  get fixConnectionPath() {
    return this.$router.currentRoute.fullPath.replace(
      "settings/sync",
      "reauthorize"
    );
  }

  get hasError() {
    return this.errorCode;
  }

  get isAuthError() {
    return this.errorCode === "authFailure";
  }

  get showSyncButton() {
    return this.showConfirmBox === false && this.isRefreshable;
  }

  get canRefresh() {
    return this.syncButtonDisabled === false;
  }

  get isRefreshable() {
    return this.connection?.refreshRateSec !== null;
  }

  get syncDateRender() {
    if (!this.lastDataSourceUpdate) return undefined;

    const dateOptions = {
      weekday: "long",
      day: "numeric",
      month: "short",
    } as DateTimeFormatOptions;
    const timeOptions = DateTime.TIME_SIMPLE;
    const dt = DateTime.fromJSDate(this.lastDataSourceUpdate);
    return `${dt.toLocaleString(dateOptions)} at ${dt.toLocaleString(
      timeOptions
    )}`;
  }

  get dataLastUpdated() {
    return this.lastDataSourceUpdate ?? null;
  }

  get connectionErrorMessage() {
    return getConnectionErrorMessage(this.errorCode, this.errorMessage);
  }

  async syncData() {
    this.syncButtonDisabled = true;

    setTimeout(() => {
      this.syncButtonDisabled = false;
    }, 10000);

    this.connectionEditor.synchronizeData().finally(() => {
      this.showConfirmBox = false;
    });
  }
}
</script>
