<template>
  <img ref="img" :src="src" loading="lazy" />
</template>

<script lang="ts">
import { DataType, DataValue } from "@/types/data";
import { imageResize } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";

const transparentGif =
  "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

@Component
export default class DataNodeImage extends Vue {
  @Prop(String) uuid: string;
  @Prop(String) readonly dataType: DataType;
  @Prop(Object) value: DataValue;

  size = 0;

  mounted() {
    const img = this.$refs.img as HTMLImageElement;
    this.size =
      Math.max(img.clientWidth, img.clientHeight) * window.devicePixelRatio * 2;
  }

  get src() {
    const targetSize = this.size === 0 ? undefined : this.size;
    return (
      imageResize(this.value.formattedValue, targetSize, targetSize) ??
      transparentGif
    );
  }
}
</script>
