import { defineStore } from "pinia";
import { api } from "@/api/backend";
import { AppInfo, Keyword } from "@/types/data";
import { EventBus } from "@/eventbus";

export interface TemplatesState {
  templates: AppInfo[];
  template: AppInfo | null;
  categories: Keyword[];
  category: number | null;
  tags: Keyword[];
  error: string;
}

export const useTemplatesStore = defineStore("templates", {
  state: (): TemplatesState => {
    return {
      templates: [],
      template: null,
      categories: [],
      category: null,
      tags: [],
      error: "",
    };
  },
  getters: {},

  actions: {
    async search(category: number | null, isPortrait: boolean, query: string) {
      const params = new URLSearchParams();
      params.append("query", query);
      params.append("isTemplateView", "true");
      params.append("excludePortrait", isPortrait ? "false" : "true");
      params.append("excludeLandscape", isPortrait ? "true" : "false");
      params.append("pageSize", "100");
      if (category) {
        params.append("categoryId", category.toString());
      }

      const url = `apps/search?${params.toString()}`;
      this.templates = await api.get<AppInfo[]>(url);
    },

    async getTemplate(uuid: string) {
      EventBus.emit("AWAITING_SERVER", true);
      console.log("about to get template....");
      this.template = await api.get<AppInfo>(`apps/${uuid}/detail`);
      console.log("Got template details", this.template);
      EventBus.emit("AWAITING_SERVER", false);
    },

    async getOptions(showLoader = false) {
      if (showLoader) {
        EventBus.emit("AWAITING_SERVER", true);
      }
      return api
        .get<Keyword[]>(`keywords`)
        .then((res: any) => {
          this.categories = res.categories;
          this.tags = res.tags;
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    async getDeleteOptions(id: number): Promise<number> {
      EventBus.emit("AWAITING_SERVER", true);
      return api.get<number>(`keywords/delete?categoryId=${id}`).finally(() => {
        EventBus.emit("AWAITING_SERVER", false);
      });
    },

    async deleteCategoryOrReplace(
      categoryId: number,
      replaceId: number | null
    ) {
      const params = new URLSearchParams();
      params.append("categoryId", categoryId.toString());
      if (replaceId) {
        params.append("replaceId", replaceId.toString());
      }
      const url = `keywords?${params.toString()}`;

      EventBus.emit("AWAITING_SERVER", true);
      return api
        .delete(url)
        .then(() => {
          const index = this.categories.findIndex((c) => c.id === categoryId);
          if (index > -1) {
            this.categories.splice(index, 1);
          }
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    async update(template: AppInfo) {
      EventBus.emit("AWAITING_SERVER", true);
      return api
        .put<AppInfo>(`apps/${template.uuid}/detail`, template)
        .then((app) => {
          this.template = app;

          const index = this.templates.findIndex((t) => t.uuid == app.uuid);
          this.templates[index] = app;
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    async saveCategories(categories: Keyword[]) {
      EventBus.emit("AWAITING_SERVER", true);
      return api
        .put<Keyword[]>(`keywords`, { categories })
        .then((keywords) => {
          this.categories = keywords;
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    async archive(uuid: string) {
      EventBus.emit("AWAITING_SERVER", true);
      return api
        .put(`apps/${uuid}/archive`)
        .then(() => {
          this.template = null;

          const index = this.templates.findIndex((t) => t.uuid == uuid);
          if (index > -1) {
            this.templates.splice(index, 1);
          }
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    async clone(uuid: string): Promise<AppInfo> {
      EventBus.emit("AWAITING_SERVER", true);
      return api
        .post<AppInfo>(`apps/${uuid}/clone?uuid=${uuid}`)
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    setCategory(categoryId: number | null) {
      this.category = categoryId;
    },
  },
});
