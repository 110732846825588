<template>
  <div
    class="shadow-xl flex items-center justify-between text-lg rounded-lg bg-gray-700 px-6 py-4"
  >
    <div class="flex items-center space-x-4">
      <IconBigCheckmark class="text-app-green" />
      <div v-text="message" />
    </div>

    <router-link
      v-if="showLink"
      class="border border-gray-500 rounded text-sm px-3 py-1 hover:border-gray-300"
      :to="link"
      v-text="buttonText"
    />

    <button
      type="button"
      v-if="showButton"
      class="border border-gray-500 rounded text-sm px-3 py-1 hover:border-gray-300"
      v-text="buttonText"
      @click="onClick"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconBigCheckmark from "@/components/icons/IconBigCheckmark.vue";
import FormButton from "@/components/FormButton.vue";

@Component({
  components: { FormButton, IconBigCheckmark },
})
export default class SetupStepConfirm extends Vue {
  @Prop(String) button?: string;
  @Prop({ type: String, required: true }) message: string;
  @Prop({ type: Boolean, default: true }) allowChanges: boolean;
  @Prop({ type: [Object, String] }) link: any;
  @Prop({ type: String, default: "Link" }) mode: "Link" | "Button";

  get buttonText() {
    return this.button ?? this.$t("ConnectionStepConfirm.makeChanges");
  }

  get showLink() {
    return (
      this.mode === "Link" &&
      this.allowChanges &&
      typeof this.link !== "undefined"
    );
  }

  get showButton() {
    return this.mode === "Button";
  }

  onClick() {
    this.$emit("click");
  }
}
</script>
