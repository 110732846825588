<template>
  <ButtonGradient
    class="px-2 py-1 whitespace-nowrap"
    @click="saveClickHandler"
    :disabled="!editableDataStore.hasUnsavedChanges"
  >
    Save Changes
  </ButtonGradient>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";

import { SchemaNode } from "@/types/data";
import { EventBus } from "@/eventbus";
import { encodeLookupKey, validateCell } from "@/stores/editableData";
import {
  APP_EDITOR_ROUTE_PATH,
  PLACEHOLDER_CONNECTION_UUID,
} from "@/constants";
import { useEditableDataStore } from "@/stores/editableData";
import { useAppEditorStore } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { useConnectionDataStore } from "@/stores/connectionData";

@Component({
  components: { Modal, ButtonGradient },
})
export default class SaveButton extends Vue {
  get connectionData() {
    return useConnectionDataStore();
  }

  get moderationMode() {
    return this.connectionEditor.moderationMode;
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get schema() {
    return this.connectionEditor.schema;
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get appData() {
    return useAppDataStore();
  }

  get connectionDataStore() {
    return useConnectionDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get appUuid() {
    return this.appEditor.uuid;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  get hasDataConnection() {
    return (
      typeof this.dataConnectionUuid !== "undefined" &&
      this.dataConnectionUuid !== PLACEHOLDER_CONNECTION_UUID
    );
  }

  get dataConnectionUuid() {
    return this.$route.params.connectionUuid;
  }

  get hasManualConnection() {
    if (!this.hasDataConnection) return false;
    return this.connection?.canEditData;
  }

  get hasSyncedConnection() {
    if (!this.hasDataConnection) return false;
    return this.connection?.isSyncable;
  }

  get isModerated() {
    return this.moderationMode !== null;
  }

  validateNewRows() {
    let allValid = true;
    // Add them to error count here
    this.editableDataStore.rows.forEach((row) => {
      row.columns.forEach((node) => {
        const schemaNode = this.schema.find(
          (n: any) => n.uuid === node.uuid
        ) as SchemaNode;

        if (!validateCell(schemaNode, node.value)) {
          const key = encodeLookupKey({
            rowUuid: row.rowUuid,
            columnUuid: node.uuid || "",
          });
          this.editableDataStore.addValidationError(key);
          allValid = false;
        }
      });
    });

    return allValid;
  }

  async saveClickHandler() {
    if (this.hasManualConnection) {
      this.editableDataStore.clearEditingCell({
        ...this.editableDataStore.editingCell,
        schema: this.schema,
      });

      this.editableDataStore.errorMessage = "";
      this.editableDataStore.showFixValidationErrorsMessage = false;
      // Check for validation errors (using count)
      // Secondary check here (to handle cases of newly created cells that user has not yet clicked in/out from to trigger validation)
      // But we want to run "secondary" check first, to ensure it runs, even if they already have an error
      if (
        !this.validateNewRows() ||
        this.editableDataStore.validationErrorsCount > 0
      ) {
        this.editableDataStore.showFixValidationErrorsMessage = true;
        return;
      }
    }

    EventBus.emit("AWAITING_SERVER", true);

    const dcUuid = this.connection?.uuid || "";

    // We will need to refresh data for all widgets bound to the dataset upon save
    const allWidsBoundToDataset = this.appEditor.dataBindings
      .filter(
        (db) =>
          db.dataConnectionUuid === dcUuid &&
          ["DataSet", "DataSetParent"].includes(db.bindingType)
      )
      .map((db) => db.widgetId);

    /**
     * For synced connections, we use special endpoint to update moderation selections.
     * (and indeed, this is the only thing the user could be modifying from this view, for synced connections.)
     * For editable connections, we can use upsert data endpoint.
     */
    if (this.hasSyncedConnection) {
      await this.saveModerationInfo();

      await this.connectionDataStore.refreshConnectionData({
        connectionId: dcUuid,
        isModerated: true,
      });

      if (this.appUuid) {
        this.appData.invalidateCacheForConnection(dcUuid);
      }

      this.connectionEditor.isLoading = false;
      return;
    }

    /**
     * If connection is already editable, upsert the data.
     * If not already editable, then create a data connection from the placeholder data.
     */
    try {
      if (this.hasManualConnection) {
        // In this case, must Upsert

        await this.editableDataStore.upsertManualTableData({
          dcUuid: dcUuid || this.dataConnectionUuid, // When saving newly-disconnected data, must use dcUuid from this.connection, not from route (or must change route..)
          widgetIds: allWidsBoundToDataset,
        });

        this.appData.invalidateCacheForConnection(
          dcUuid || this.dataConnectionUuid
        );

        await this.connectionDataStore.refreshConnectionData({
          connectionId: dcUuid,
          isModerated: this.isModerated,
        });
      } else {
        // In this case, must create data connection, binding, get data (using rows and schema from state)
        const newConnection =
          await this.editableDataStore.createEditableConnectionFromPlaceholderData(
            {
              appUuid: this.appUuid,
              widget: this.selectedWidget,
            }
          );

        // Let backend know about new binding
        await this.appEditor.updateApp();

        /**
         * Must update route here, to reflect new connectionUuid
         */
        this.$router.push({
          path: `/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/connections/${newConnection.uuid}/data`,
          query: { ...this.$route.query },
        });
      }

      this.editableDataStore.clearLookups();
    } catch (e) {
      console.log("ERROR saving", e);
      const message = typeof e === "string" ? e : (e as any)[0].message;
      this.editableDataStore.errorMessage = `Error saving: ${message}`;
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
      this.connectionEditor.isLoading = false;
    }
  }

  async saveModerationInfo() {
    /**
     * If dataset is moderated, use updateModerationData to let the backend know about the updated row moderation selections.
     * This will only occur for synced connections.
     * Editable connections, on the other hand, will use upsertManualTableData to update moderation info.
     */

    // console.log("Saving mod info...", this.isModerated);
    if (this.isModerated) {
      // console.log("update mod data", this.moderationDataCopy);

      const dcUuid = this.connection?.uuid || "";

      try {
        const schema = this.schema || [];
        const primaryKeyUuid = schema.find((n: any) => n.isPrimaryKey)
          ? schema.find((n: any) => n.isPrimaryKey)?.uuid
          : "";

        const moderatedRows = this.editableDataStore.rows.map((x) => {
          return {
            isSelected: !!x.isSelected,
            refUuid: x.rowUuid,
            data: x.columns,
            primaryKeyId: primaryKeyUuid ?? "",
            // This was needed..
            // Though it caused the row to actually disappear...
            primaryKeyValue: x.columns.find((c) => c.uuid === primaryKeyUuid)
              ?.formattedValue,
          };
        });

        await this.connectionData.updateModerationData({
          dcUuid,
          data: moderatedRows,
        });
      } catch (e: any) {
        console.log("Error updating moderated data:", e);

        const errorMessage = e?.code
          ? this.$t(e.code).toString()
          : e[0]?.message;
        this.editableDataStore.errorMessage = errorMessage;
      } finally {
        EventBus.emit("AWAITING_SERVER", false);
      }
    }
  }
}
</script>
