import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";

import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { DEFAULT_LAYER_NAMES } from "@/constants";

interface RectangleOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    AnimationOptions,
    BackgroundOptions {
  borderRadius?: number;
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...getDefaultAnimationOptions(),
  borderRadius: 0,
};

const CreateRectangle = (options: Partial<RectangleOptions>) => {
  return Object.assign(
    {
      type: "Rectangle",
    },
    defaultOptions,
    options,
    {
      wid: makeId(),
      name: DEFAULT_LAYER_NAMES.Rectangle,
    }
  );
};

export default CreateRectangle;
