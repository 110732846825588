<template>
  <div>
    <slot />
    <div
      class="pl-3 cursor-pointer hover:bg-gray-600 py-1"
      :key="index"
      v-for="(el, index) in elements"
      :style="el.previewStyle"
      @click="addText(el.style)"
      v-html="el.html"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CreateText, { TextOptions } from "@/components/widgets/Text/TextOptions";
import { getTextStyleCss, measureText } from "@/textfit";
import { contentToHtml, makeTextContent } from "@/text";
import { useAppEditorStore } from "@/stores/appEditor";

@Component
export default class TextMenu extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get textStyles() {
    return [
      {
        title: "Heading",
        fontFamily: "Inter",
        fontSize: 192,
        fontWeight: 700,
        fontStyle: "normal",
      },
      {
        title: "Subhead",
        fontFamily: "Inter",
        fontSize: 128,
        fontWeight: 700,
        fontStyle: "normal",
      },
      {
        title: "Label",
        fontFamily: "Inter",
        fontSize: 96,
        fontWeight: 400,
        fontStyle: "normal",
      },
      // Like the height is just a bit off.....throws off editor
      {
        title: "Fancy",
        fontFamily: "Playfair Display",
        fontSize: 128,
        fontWeight: 400,
        fontStyle: "italic",
      },

      // Show up too small!
      // {
      //   title: "Small",
      //   fontFamily: "Inter",
      //   fontSize: 40,
      //   fontWeight: 400,
      //   fontStyle: "normal"
      // },
      // {
      //   title: "Tiny",
      //   fontFamily: "Inter",
      //   fontSize: 20,
      //   fontWeight: 400,
      //   fontStyle: "normal"
      // }
    ] as any[];
  }

  get elements() {
    return this.textStyles.map((ts) => {
      const ps = getTextStyleCss(Object.assign({}, ts));
      ps.fontSize = `${(ts.fontSize || 100) * 0.25}px`;
      ps.lineHeight = "100%";

      ts.content = makeTextContent(ts.title);
      return {
        previewStyle: ps,
        style: ts,
        html: contentToHtml(ts.content),
      };
    });
  }

  addText(options: TextOptions) {
    const width = this.artboard.w * 0.25;
    const x = this.isBaseEditingContext ? (this.artboard.w - width) / 2 : 5;
    const y = this.isBaseEditingContext ? this.artboard.h / 3 : 5;
    const w = this.isBaseEditingContext
      ? width
      : (this.editingContext.width || 1) * 0.9 ?? this.artboard.w / 2;

    // Scale font size down by proportion of our editing context's width to artboard width
    const fontSize = Math.floor(
      ((options.fontSize || 128) * w) / this.artboard.w
    );

    const merged = Object.assign({}, options, {
      x,
      y,
      w,
      canScaleY: false,
      fontSize,
    });

    const widget = CreateText(merged);
    const measureObj = { ...widget };
    const size = measureText(measureObj);

    widget.h = size.h;
    widget.w = size.w;

    this.appEditor.addWidgetAction({ widget });
    this.appEditor.replaceSelections([widget.wid]);
    this.$emit("closeMenu");
  }
}
</script>

<style scoped></style>
