<template>
  <button
    type="button"
    class="flex-shrink-0 group relative rounded inline-flex items-center justify-center h-6 w-12 cursor-pointer border-2 bg-gray-800 border-gray-900"
    aria-pressed="false"
  >
    <span
      aria-hidden="true"
      class="pointer-events-none absolute w-full h-full rounded bg-transparent"
    ></span>
    <span
      aria-hidden="true"
      class="pointer-events-none bg-gray-800 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-100"
    ></span>
    <span
      aria-hidden="true"
      :class="{ 'translate-x-5': selected, 'translate-x-0': !selected }"
      class="pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-900 rounded bg-white shadow transform ring-0 transition-transform ease-in-out duration-100"
    ></span>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DarkToggleButton extends Vue {
  @Prop(Boolean) selected: boolean;
  @Prop({ default: false }) bgTransparent: boolean;
}
</script>
