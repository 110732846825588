<template>
  <div class="flex flex-col h-full">
    <div class="-mt-2 pb-2 border-b border-gray-800 shadow-lg">
      <slot />

      <div class="px-4 pt-3">
        <button
          @click="upload"
          class="w-full h-8 text-white bg-gradient-to-b from-app-orange to-app-red rounded-md text-sm font-semibold"
          type="button"
          v-t="'uploadPhoto'"
        ></button>
      </div>
      <form
        class="px-4 pt-3 pb-2"
        slot="search"
        @submit.prevent="onSearchSubmit"
      >
        <div class="relative flex text-gray-600">
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" class="p-1">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                class="w-6 h-6"
              >
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>

          <input
            type="search"
            v-model="searchRequest.query"
            name="q"
            ref="search"
            class="py-2 flex-grow text-sm text-black btn rounded-md pl-10 dark-form-focus search-box"
            :placeholder="searchPlaceholder"
            autocomplete="off"
            @input="onSearchInput"
            @blur="onSearchBlur"
          />

          <!-- "Cancel search X": -->
          <span
            class="absolute top-0 h-full flex items-center"
            style="right: 10px"
            v-if="searchRequest.query"
            @click="clearSearch"
            ><svg
              viewBox="0 0 24 24"
              class="w-2 h-2 cursor-pointer"
              stroke="gray"
              stroke-width="4"
            >
              <line x1="0" x2="24" y1="24" y2="0" />
              <line x1="0" x2="24" y1="0" y2="24" />
            </svg>
          </span>
        </div>
        <FormInputError
          class="transform translate-y-1"
          v-show="searchTermRequired"
          ><span v-t="'PhotosMenu.searchTermRequired'"></span
        ></FormInputError>
        <div
          class="flex w-full text-gray-400 pt-3 relative space-x-3"
          v-if="showSearchView"
        >
          <ButtonIcon
            icon="RectangleHorizontal"
            tooltip="includeHorizontalImages"
            tooltipPosition="r"
            :class="{ 'text-app-teal': isHorizontal }"
            @click="onOrientationClick('horizontal')"
          />

          <ButtonIcon
            icon="RectangleVertical"
            tooltip="includeVerticalImages"
            tooltipPosition="r"
            :class="{ 'text-app-teal': isVertical }"
            @click="onOrientationClick('vertical')"
          />

          <ButtonIcon
            icon="ColorTransparent"
            class="border-2"
            tooltip="transparentBackgroundOnly"
            @click="onColorClick"
            :class="[isTransparent ? 'border-app-teal' : 'border-transparent']"
            size="5"
          />
          <div class="absolute bottom-0 right-0 text-xxs">
            <a class="no-underline" href="https://pixabay.com/" target="_blank"
              >Pixabay</a
            >
          </div>
        </div>
      </form>
    </div>

    <div class="relative flex-grow">
      <div class="p-6" v-if="showUserAssetsError && !isLoadingAssets">
        <div class="mb-3" v-t="'PhotosMenu.errorLoadingUserAssets'"></div>
        <OutlineButton
          size="xs"
          class="text-xs"
          @click="loadUserAssets"
          v-t="'PhotosMenu.retryLoadingPhotos'"
        ></OutlineButton>
      </div>
      <div
        ref="savedAssets"
        class="absolute overflow-y-auto inset-0 p-4"
        v-if="showUserAssets"
      >
        <div
          class="flex justify-between items-center"
          v-show="!isLoadingAssets"
        >
          <label
            class="text text-sm"
            v-if="userAssets.length !== 0"
            v-t="'PhotosMenu.savedImages'"
          ></label>

          <OutlineButton
            size="xs"
            class="text-xs"
            @click="isEditMode = !isEditMode"
          >
            <span v-if="isEditMode" v-t="'done'"></span>
            <span v-else v-t="'edit'"></span>
          </OutlineButton>
        </div>

        <div class="grid grid-cols-2 gap-4 pt-3 pb-16">
          <div :key="a.uuid" v-for="a in userAssets" class="relative">
            <img
              :src="a.url"
              :id="a.uuid"
              width="112"
              :height="Math.round((112 / a.width) * a.height)"
              loading="lazy"
              class="block cursor-pointer ring-1 ring-gray-700 shadow-sm"
              draggable="true"
              @dragstart="onStartDrag($event, a)"
            />

            <div
              v-if="isEditMode"
              class="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center cursor-pointer"
              @click="handleRemoveClick(a)"
            >
              <Icon
                name="CloseCircle"
                style="border-radius: 100px"
                class="text-red-500 ring-1 ring-red-800 hover:text-red-600 shadow-md"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-6"
        v-if="isLoadingAssets"
        v-t="'PhotosMenu.loadingAssets'"
      ></div>
      <div
        ref="searchResultsContainer"
        class="absolute overflow-y-auto inset-0 p-4"
        v-if="showSearchView"
        @scroll="onPanelScroll"
      >
        <img
          :src="a.url"
          :id="a.id"
          :key="a.id"
          loading="lazy"
          width="255"
          :height="Math.round((255 / a.width) * a.height)"
          v-for="a in searchResults"
          class="block pb-2 cursor-pointer shadow-sm"
          draggable="true"
          @dragstart="onStartDrag($event, a)"
        />
        <label v-if="showEmptySearchResults" v-t="'PhotosMenu.noResults'"
          >No results</label
        >
        <label
          v-if="store.loadingSearchResults"
          v-t="'PhotosMenu.loading'"
        ></label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import gsap from "gsap";
import { Component } from "vue-property-decorator";

import { SavedAsset } from "@/types";
import { SearchRequest } from "@/types/photos";
import { useAssetStore } from "@/stores/assets";
import { useAppEditorStore } from "@/stores/appEditor";
import { uploadImage } from "@/uploadAssets";

import Icon from "@/components/icons/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";
import PhotoDragging from "@/components/menus/PhotoDragging.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import FormInputError from "@/components/FormInputError.vue";

import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { logger } from "@core/logger";
import { makeId } from "@/utils";

@Component({
  components: { ButtonIcon, Tooltip, Icon, OutlineButton, FormInputError },
})
export default class PhotosMenu extends PhotoDragging {
  isEditMode = false;

  searchRequest = { color: "", orientation: "", query: "" } as SearchRequest;
  isVertical = false;
  isHorizontal = false;
  isLoadingAssets = false;
  searchTermRequired = false;
  showUserAssetsError = false;

  get store() {
    return useAssetStore();
  }

  get userAssets() {
    return this.store.userImages;
  }

  get searchResults() {
    return this.store.stockAssets;
  }

  get showSearchView() {
    return this.store.searching;
  }

  get showUserAssets() {
    return !this.showUserAssetsError && !this.store.searching;
  }

  get showEmptySearchResults() {
    return this.store.searchResultCount === 0;
  }

  async created() {
    this.loadUserAssets();
  }

  loadUserAssets() {
    this.isLoadingAssets = true;
    this.store
      .loadUserAssets()
      .then(() => {
        this.showUserAssetsError = false;
      })
      .catch((err) => {
        logger.track("Error loading user assets", err);
        this.showUserAssetsError = true;
      })
      .finally(() => {
        this.isLoadingAssets = false;
      });
  }

  beforeDestroy() {
    this.store.clearSearch();
  }

  onSearchBlur() {
    if (!isNonEmptyString(this.searchRequest.query)) {
      // Clear search results
      this.store.clearSearch();
      this.searchRequest.query = "";
    }
  }

  onSearchInput() {
    if (this.searchTermRequired && isNonEmptyString(this.searchRequest.query)) {
      // turn it off if it was on and they just entered something.
      this.searchTermRequired = false;
    }
  }

  searchAssets(request: SearchRequest) {
    if (this.store.loadingSearchResults) {
      return;
    }
    this.store.searchStockAssets(request);
  }

  get searchPlaceholder() {
    return this.$t("searchPhotosPrompt") as string;
  }

  onPanelScroll() {
    const el = this.$refs.searchResultsContainer as HTMLDivElement;
    const scrolledToBottom =
      el.scrollTop + el.clientHeight >= el.scrollHeight * 0.98;

    if (scrolledToBottom && !this.store.loadingSearchResults) {
      this.assetStore.pageAssets(this.searchRequest);
    }
  }

  onSearchSubmit() {
    if (!isNonEmptyString(this.searchRequest.query)) {
      this.searchTermRequired = true;
    } else {
      this.searchAssets(this.searchRequest);
    }
  }

  clearSearch() {
    this.store.clearSearch();
    this.searchRequest.query = "";
    (this.$refs.search as HTMLInputElement).focus();
    // (this.$refs.pressEnterTip as Tooltip).hideTip();

    this.$nextTick(() => {
      const div = this.$refs.savedAssets as HTMLDivElement;
      div.scrollTop = 0;
    });
  }

  onOrientationClick(value: string) {
    if (this.store.loadingSearchResults) {
      return false;
    }

    value === "vertical"
      ? (this.isVertical = !this.isVertical)
      : (this.isHorizontal = !this.isHorizontal);

    this.searchRequest.orientation =
      this.isVertical && this.isHorizontal
        ? "all"
        : this.isHorizontal
        ? "horizontal"
        : this.isVertical
        ? "vertical"
        : "";

    this.searchAssets(this.searchRequest);
  }

  get isTransparent() {
    return this.searchRequest.color === "transparent";
  }

  onColorClick() {
    if (this.store.loadingSearchResults) {
      return false;
    }

    this.searchRequest.color = this.isTransparent ? "" : "transparent";
    this.searchAssets(this.searchRequest);
  }

  upload() {
    if (this.store.loadingSearchResults) {
      return false;
    }
    return uploadImage().then((asset?: SavedAsset) => {
      if (asset === undefined) {
        // User cancelled upload
        return;
      }

      const { height, width } = asset;

      const appEditor = useAppEditorStore();
      return appEditor
        .addImageComponent({
          asset: asset,
          boundingBox: {
            x: 50,
            y: 50,
            w: width,
            h: height,
          },
          newWidgetId: makeId(),
          parentWidgetId: this.editingContext?.parentId,
        } as any)
        .then(() => {
          this.store.saveUserAsset(asset);
          this.$nextTick(this.animateToNewImage);
        });
    });
  }

  animateToNewImage() {
    const tl = gsap.timeline();
    const div = this.$refs.savedAssets as HTMLDivElement;
    if (div) {
      const images = Array.from(
        div.querySelectorAll("img")
      ) as HTMLImageElement[];

      const lastImage = images.at(-1) as HTMLImageElement;

      gsap.set(lastImage, { opacity: 0, scale: 0.1 });
      tl.to(div, {
        scrollTop: div.scrollHeight,
        duration: 1,
        ease: "power2.out",
      });

      tl.to(lastImage, {
        delay: 0.1,
        scale: 1,
        opacity: 1,
        duration: 0.25,
        ease: "back.out(2)",
      });
    }
  }

  async handleRemoveClick(asset: SavedAsset) {
    this.store.removeUserAsset(asset.uuid);
  }
}
</script>

<style scoped>
input[type="search"]::-webkit-search-cancel-button {
  visibility: hidden;
}

.search-box {
  color: black !important;
}

#dragged-ghost {
  pointer-events: none;
}

.under-fixed {
  margin-top: 125px;
}
</style>
