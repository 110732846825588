<template>
  <component :is="`Icon${iconName}`" viewBox="0 0 66 66" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconBlink from "./IconBlink.vue";
import IconFade from "./IconFade.vue";
import IconGrow from "./IconGrow.vue";
import IconNone from "./IconNone.vue";
import IconPulse from "./IconPulse.vue";
import IconShrink from "./IconShrink.vue";
import IconSlideDown from "./IconSlideDown.vue";
import IconSlideLeft from "./IconSlideLeft.vue";
import IconSlideRight from "./IconSlideRight.vue";
import IconSlideUp from "./IconSlideUp.vue";

@Component({
  components: {
    IconBlink,
    IconFade,
    IconGrow,
    IconNone,
    IconPulse,
    IconShrink,
    IconSlideDown,
    IconSlideLeft,
    IconSlideRight,
    IconSlideUp,
  },
})
export default class AnimationIcon extends Vue {
  @Prop(String) name: string;

  get iconName() {
    return this.name;
  }
}
</script>

<style scoped lang="postcss"></style>
