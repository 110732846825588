<template>
  <div class="border border-gray-600 rounded divide-y divide-gray-600">
    <div class="flex flex-col" :key="o.value" v-for="o in typeOptions">
      <button
        type="button"
        class="group relative w-full bg-gray-700/30 cursor-pointer px-4 py-3 flex items-center space-x-3 text-sm hover:border-gray-500 hover:bg-opacity-60"
        @click="onTypeSelected(o.value)"
      >
        <OperandTypeIcon class="shrink-0" :type="o.value" />
        <div class="flex-grow text-left">{{ o.label }}</div>
        <CheckBox
          v-if="types.length > 1"
          class="shrink-0"
          :checked="o.selected"
        />
        <div
          :class="{ hidden: o.selected }"
          class="absolute -inset-1.5 pointer-events-none invisible group-hover:visible"
        >
          <HelpBubble
            :position="helpBubblePosition"
            class="absolute top-1/2 w-64 transform -translate-y-1/2"
          >
            <div class="text-left" v-text="o.description"></div>
          </HelpBubble>
        </div>
      </button>
      <div class="p-4 bg-app-dark2 border-t border-gray-600" v-if="o.selected">
        <component
          :is="o.component"
          :model="model"
          :node="node"
          :inputType="inputType"
          :min="min"
          :max="max"
          @input="onValueInput($event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { LogicOperand } from "@/types/logic";
import {
  getOperandTypeFromSelectType,
  getSelectTypeFromOperandType,
  OperandInputValueType,
  OperandSelectType,
} from "./OperandSelectType";

import SelectMenu from "../SelectMenu.vue";
import OperandTypeIcon from "./OperandTypeIcon.vue";
import SelectColumnValue from "./SelectColumnValue.vue";
import SelectScalarValue from "./SelectScalarValue.vue";
import SelectDateTimeValue from "./SelectDateTimeValue.vue";
import SelectQueryStringValue from "./SelectQueryStringValue.vue";
import SelectStaticValue from "./SelectStaticValue.vue";
import CheckBox from "./CheckBox.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import HelpBubble from "@/components/HelpBubble.vue";
import { OperandChangedEvent } from "./OperandChangedEvent";
import { SchemaNode } from "@/types/data";

@Component({
  components: {
    SelectMenu,
    OperandTypeIcon,
    OutlineButton,
    SelectColumnValue,
    SelectScalarValue,
    SelectDateTimeValue,
    SelectQueryStringValue,
    SelectStaticValue,
    CheckBox,
    HelpBubble,
  },
})
export default class OperandEditor extends Vue {
  @Prop(Object) model: LogicOperand;
  @Prop(Object) node: SchemaNode;
  @Prop(String) side: "left" | "right";
  @Prop(Array) types: OperandSelectType[];
  @Prop(String) inputType: OperandInputValueType;
  @Prop(Number) min: number | undefined;
  @Prop(Number) max: number | undefined;

  get selectedType() {
    return getSelectTypeFromOperandType(this.model?.type);
  }

  get valueComponent() {
    switch (this.selectedType) {
      case "Column":
        return "SelectColumnValue";
      case "Scalar":
        return "SelectScalarValue";
      case "DateTime":
        return "SelectDateTimeValue";
      case "QueryString":
        return "SelectQueryStringValue";
      case "Static":
        return "SelectStaticValue";
    }
    return undefined;
  }

  get valueTitle() {
    if (this.selectedType) {
      return this.typeOptionLabels[this.selectedType];
    }
    return undefined;
  }

  get typeOptionLabels(): Record<OperandSelectType, string> {
    let inputType = this.inputType?.toLowerCase() ?? "empty";
    let typeKey;
    switch (this.inputType) {
      case "DayOfWeek":
        typeKey = "ConditionsDataTypes.dayofweek";
        break;
      case "Month":
        typeKey = "ConditionsDataTypes.month";
        break;
      default:
        typeKey = `dataType.${inputType}`;
        break;
    }

    return {
      Column: this.$t("OperandSelectType.Column").toString(),
      Scalar: this.$t("OperandSelectType.Scalar").toString(),
      DateTime: this.$t("OperandSelectType.DateTime").toString(),
      Static: this.$t("OperandSelectType.Static", {
        type: this.$t(typeKey).toString(),
      }).toString(),
      QueryString: this.$t("OperandSelectType.QueryString").toString(),
    };
  }

  get typeOptions(): {
    label: string;
    value: OperandSelectType;
    component: string;
    selected: boolean;
    description: string;
  }[] {
    return this.types.map((type) => {
      return {
        label: this.typeOptionLabels[type],
        value: type,
        component: `Select${type}Value`,
        selected: this.selectedType === type,
        description: this.$t(`OperandTypeDescription.${type}`).toString(),
      };
    });
  }

  get helpBubblePosition() {
    return this.side === "left" ? "r" : "l";
  }

  onTypeSelected(type: OperandSelectType) {
    if (this.selectedType === type) {
      return;
    }
    const payload: OperandChangedEvent = {
      operand: { type: getOperandTypeFromSelectType(type) },
    };
    this.onValueInput(payload);
  }

  onValueInput(event: OperandChangedEvent) {
    const defaultOperand: Partial<LogicOperand> = {
      uuid: this.model.uuid,
      type: this.model.type,
      connectionUuid: "",
      nodeUuid: "",
      valueType: undefined,
      default: "",
      value: "",
    };
    const payload: OperandChangedEvent = {
      operand: { ...defaultOperand, ...event.operand },
      node: event.node,
    };

    this.$emit("change", payload);
  }

  onRemove() {
    this.$emit("change", {
      operand: Object.assign({}, this.model, {
        type: undefined,
        value: "",
        valueType: undefined,
      } as Partial<LogicOperand>),
    });
  }
}
</script>
