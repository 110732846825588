<template>
  <div class="flex mt-2">
    <div
      class="font-semibold cursor-pointer px-3"
      @click="toggle(false)"
      :class="!value ? 'text-white' : 'text-gray-500'"
    >
      No
    </div>
    <div>
      <ToggleInput :value="value" @input="toggle" :dark="dark"> </ToggleInput>
    </div>
    <div
      class="font-semibold cursor-pointer px-3"
      @click="toggle(true)"
      :class="value ? 'text-white' : 'text-gray-500'"
    >
      Yes
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ToggleInput from "@/components/inputs/ToggleInput.vue";

@Component({
  components: { ToggleInput },
})
export default class ToggleInputLabel extends Vue {
  @Prop(Boolean) value: boolean;
  @Prop({ default: false }) dark: boolean;

  toggle(val: boolean) {
    if (val !== null && this.value === val) return;
    const newVal = val === null ? !this.value : val;

    this.$emit("input", newVal);
    this.$emit("change", newVal);
  }
}
</script>
