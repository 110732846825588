<template>
  <div
    @click.stop
    :style="styles"
    class="bg-white w-48 absolute text-sm text-black"
    ref="dropdown"
  >
    <div
      v-for="(btn, j) in buttonActions"
      @click="buttonClickHandler(btn)"
      :key="j"
      class="w-full flex items-center p-2 cursor-pointer hover:bg-app-teal hover:text-white"
    >
      <div class="w-5 h-5 mr-2">
        <IconSolid :name="btn.icon" />
      </div>
      <div>
        {{ btn.label }}
      </div>
    </div>

    <div id="dropdown-arrow" :style="arrowStyle"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { makeCellDomId } from "./helpers";
import IconSolid from "@/components/icons/IconSolid.vue";
import { useEditableDataStore } from "@/stores/editableData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    IconSolid,
  },
})
export default class DataRowDropdown extends Vue {
  @Prop({ default: true }) isEditable: boolean;

  get moderationMode() {
    const store = useConnectionEditorStore();
    return store.moderationMode;
  }

  get schema() {
    const store = useConnectionEditorStore();
    return store.schema;
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  makeKeyId(rowUuid: string, columnUuid: string) {
    // return `row-${rowUuid}-${columnUuid}`;
    return makeCellDomId({ rowUuid, columnUuid });
  }

  @Watch("dropdownOpenRowUuid")
  dropdownOpenChanged() {
    this.$nextTick(this.computeDropdownIsCutoff);
  }

  get isModerated() {
    return this.moderationMode !== null;
  }

  get moderationNode() {
    return (this.editableDataStore.rows || []).find(
      (n) => n.rowUuid === this.editableDataStore.dropdownOpenRowUuid
    );
  }

  get moderationSelectText() {
    return this.moderationMode === "Approval"
      ? this.$t("dataModeration.approveRecord").toString()
      : this.$t("dataModeration.removeRecord").toString();
  }

  get moderationDeselectText() {
    return this.moderationMode === "Approval"
      ? this.$t("dataModeration.unapproveRecord").toString()
      : this.$t("dataModeration.includeRecord").toString();
  }

  get menuBox() {
    const menu = this.$refs.dropdown as HTMLElement;
    return menu?.getBoundingClientRect();
  }

  dropdownIsCutoff = false;

  computeDropdownIsCutoff() {
    if (this.buttonActions.length <= 1) {
      this.dropdownIsCutoff = false;
      return;
    }
    const gridBox = this.$el?.parentElement?.getBoundingClientRect();
    const menuAbsoluteY =
      (gridBox?.y || 0) + this.editableDataStore.dropdownPosition.y;
    const menuBottom = menuAbsoluteY + (this.menuBox?.height || 0);
    const gridBottom = (gridBox?.y || 0) + (gridBox?.height || 0);
    this.dropdownIsCutoff = menuBottom > gridBottom;
  }

  get styles() {
    if (this.editableDataStore.dropdownOpenRowUuid === "") {
      return {
        visibility: "hidden",
      };
    }

    let top = this.editableDataStore.dropdownPosition.y;
    if (this.dropdownIsCutoff) {
      top -= this.menuBox?.height;
      top += 35; // row height
    }

    return {
      top: `${top}px`,
      left: `${this.editableDataStore.dropdownPosition.x}px`,
      zIndex: 1000,
    };
  }

  get arrowStyle() {
    if (this.dropdownIsCutoff) {
      return {
        bottom: "10px",
      };
    }

    return {
      top: "10px",
    };
  }

  get buttonActions() {
    const isDeleted = Object.keys(
      this.editableDataStore.deletedRowsLookup
    ).includes(this.editableDataStore.dropdownOpenRowUuid);

    if (isDeleted) {
      return [
        {
          icon: "Undo",
          label: this.$t("dataRow.undoDelete").toString(),
          fn: "undoDeleteClickHandler",
        },
      ];
    }

    let moderationIcon = "Remove";
    if (this.moderationNode?.isSelected && this.moderationMode === "Removal") {
      moderationIcon = "CheckmarkFilled";
    }
    if (
      !this.moderationNode?.isSelected &&
      this.moderationMode === "Approval"
    ) {
      moderationIcon = "CheckmarkFilled";
    }

    const moderationButton = this.isModerated
      ? [
          {
            icon: moderationIcon,
            label: this.moderationNode?.isSelected
              ? this.moderationDeselectText
              : this.moderationSelectText,
            fn: "moderateSelectClickHandler",
          },
        ]
      : [];

    const editButtons = [
      {
        icon: "InsertAbove",
        label: this.$t("dataRow.insertAbove").toString(),
        fn: "insertAboveClickHandler",
      },
      {
        icon: "InsertBelow",
        label: this.$t("dataRow.insertBelow").toString(),
        fn: "insertBelowClickHandler",
      },
      {
        icon: "MoveToFront",
        label: this.$t("dataRow.moveToFront").toString(),
        fn: "moveToFirstClickHandler",
      },
      {
        icon: "MoveToBack",
        label: this.$t("dataRow.moveToBack").toString(),
        fn: "moveToLastClickHandler",
      },
      {
        icon: "Reposition",
        label: this.$t("dataRow.reposition").toString(),
        fn: "moveToPositionClickHandler",
      },
      {
        icon: "Trash",
        label: this.$t("dataRow.delete").toString(),
        fn: "deleteRowClickHandler",
      },
    ];

    const openFormButton = [
      {
        icon: "Pencil",
        label: this.isEditable
          ? this.$t("dataRow.openForm").toString()
          : this.$t("dataRow.viewDetails").toString(),
        fn: "editRowClickHandler",
      },
    ];

    let buttons = [...openFormButton, ...moderationButton];
    if (this.isEditable) buttons = [...buttons, ...editButtons];

    return buttons;
  }

  buttonClickHandler(btn: any) {
    // TODO: why pass this in?
    (this as any)[btn.fn](this.editableDataStore.dropdownOpenRowUuid);
    this.editableDataStore.dropdownOpenRowUuid = "";
  }

  editRowClickHandler(uuid: string) {
    this.editableDataStore.editingRowUuid = uuid;
  }

  insertAboveClickHandler(rowUuid: string) {
    this.insertClickHandler(rowUuid, "above");
  }

  insertBelowClickHandler(rowUuid: string) {
    this.insertClickHandler(rowUuid, "below");
  }

  deleteRowClickHandler(uuid: string) {
    this.editableDataStore.removeRow(uuid);
  }

  undoDeleteClickHandler(uuid: string) {
    this.editableDataStore.undoRemoveRow(uuid);
  }

  moderateSelectClickHandler(uuid: string) {
    this.editableDataStore.setModerationDataRowSelected({
      refUuid: this.editableDataStore.dropdownOpenRowUuid,
      value: !this.moderationNode?.isSelected,
    });
  }

  focusFirstCell(rowUuid: string) {
    const columnUuid = this.schema[0].uuid || "";
    this.editableDataStore.editingCell.rowUuid = rowUuid;
    this.editableDataStore.editingCell.columnUuid = columnUuid;

    // TODO: It would be nice to do this without having to lookup/store IDs on dom elements...
    this.$nextTick(() => {
      const input = document.querySelector(
        `#${this.makeKeyId(rowUuid, columnUuid)}`
      );
      (input as HTMLElement).focus();
    });
  }

  /**
   * Clear filters first (for all of these actions) because otherwise unclear where to insert
   * (Not 100% sure about UX here)
   */
  async insertClickHandler(rowUuid: string, position: "above" | "below") {
    this.editableDataStore.clearFilters();
    this.editableDataStore.editingRowUuid = ""; // close editing form
    const uuid = await this.editableDataStore.insertRow({ position, rowUuid });
    this.focusFirstCell(uuid as string);
  }

  moveToFirstClickHandler(rowUuid: string) {
    this.editableDataStore.clearFilters();
    this.editableDataStore.moveRowToPosition({ rowUuid, position: 0 });
  }

  moveToLastClickHandler(rowUuid: string) {
    this.editableDataStore.clearFilters();
    this.editableDataStore.moveRowToPosition({
      rowUuid,
      position: this.editableDataStore.rows.length - 1,
    });
  }

  moveToPositionClickHandler(rowUuid: string) {
    this.editableDataStore.showMoveRowModal = true;
    this.editableDataStore.moveRowUuid = rowUuid;
  }
}
</script>

<style scoped lang="postcss">
#dropdown-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
  position: absolute;
  left: -7px;
  z-index: 999;
}
</style>
