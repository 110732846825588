<template>
  <ShapeBase
    v-bind="$props"
    :gradientParts="gradientParts"
    :borderGradientParts="borderGradientParts"
    dataType="Svg"
  >
    <path
      :d="svgPath"
      :fill="fill"
      :stroke-width="borderWidth * 2"
      :stroke="border"
      :stroke-dasharray="dashArray"
      stroke-linejoin="round"
    />
    <path :d="svgPath" :fill="fill" stroke-linejoin="round" />
  </ShapeBase>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { getPolygonPath, makeId, parseGradientCss } from "@/utils";
import ShapeBase from "@/components/ShapeBase.vue";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";

@Component({
  components: {
    ShapeBase,
  },
})
export default class SvgComponent extends AnimatableWidget {
  @Prop(String) readonly color: string;
  @Prop(String) readonly backgroundColor: string;

  // NOTE: It is crucial that we declare all background props here, in order for photoDropContainer to work
  @Prop(String) readonly backgroundImageUrl: string;
  @Prop(String) readonly backgroundSize: string;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  @Prop(String) path: string;
  @Prop(Number) readonly numSides: number;
  @Prop(Number) readonly innerRadius: number;
  @Prop(Boolean) readonly starEnabled: boolean;

  @Prop(Number) readonly borderWidth: number;
  // @Prop(String) readonly borderStyle: string;
  // @Prop(String) readonly borderAlign: string;
  @Prop(String) readonly borderColor: string;
  @Prop(Number) readonly borderDashSize: number;
  @Prop(Number) readonly borderGapSize: number;

  @Prop(Number) readonly shadowX: number;
  @Prop(Number) readonly shadowY: number;
  @Prop(Number) readonly shadowBlur: number;
  @Prop(String) readonly shadowColor: string;
  @Prop(Boolean) readonly shadowDisplay: boolean;

  // @Prop() tempBackgroundInfo: any;
  @Prop({ default: makeId }) componentId: string;

  get borderDashed() {
    return this.borderDashSize > 0;
  }

  get dashArray() {
    if (this.borderDashed) {
      return `${this.borderDashSize} ${this.borderGapSize}`;
    } else {
      return "";
    }
  }

  get svgPath() {
    if (this.path) return this.path;
    // console.log("get path w", this.w);
    const width = this.w * this.scaleX;
    const height = this.h * this.scaleY;
    const min = Math.min(width, height);
    const path = getPolygonPath({
      ctr: { x: width / 2, y: height / 2 },
      numSides: this.numSides,
      radius: min * 0.5 - this.borderWidth,
      innerRadius: min * this.innerRadius, // can  try scaling both these by 0.5 to deal  with  cut off border...
      starEnabled: this.starEnabled,
      borderWidth: this.borderWidth,
    });
    return path;
  }

  get gradientParts() {
    return parseGradientCss(this.backgroundColor);
  }

  get borderGradientParts() {
    return parseGradientCss(this.borderColor);
  }

  get fill() {
    if (this.backgroundImageUrl) {
      return this.$helpers.svgUrl(`#${this.componentId}backgroundImageUrl`);
    }
    if (this.gradientParts.type === "Solid") {
      return this.backgroundColor;
    }
    return this.$helpers.svgUrl(`#${this.gradientParts.type}-grad-${this.wid}`);
  }

  get border() {
    if (this.borderGradientParts.type === "Solid") {
      return this.borderColor;
    }
    return this.$helpers.svgUrl(
      `#${this.borderGradientParts.type}-grad-border-${this.wid}`
    );
  }
}
</script>
