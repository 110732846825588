import RectangleComponent from "@/components/widgets/Rectangle/RectangleComponent.vue";
import TextComponent from "@/components/widgets/Text/TextComponent.vue";
import EllipseComponent from "@/components/widgets/Ellipse/EllipseComponent.vue";
import ImageComponent from "@/components/widgets/Image/ImageComponent.vue";
import GroupComponent from "@/components/widgets/Group/GroupComponent.vue";
import RepeaterComponent from "@/components/widgets/Repeater/RepeaterComponent.vue";
import MultiframeComponent from "@/components/widgets/Multiframe/MultiframeComponent.vue";
import SvgComponent from "@/components/widgets/Svg/SvgComponent.vue";
import DatetimeComponent from "@/components/widgets/Datetime/DatetimeComponent.vue";
import AnalogClockComponent from "@/components/widgets/AnalogClock/AnalogClockComponent.vue";
import CalendarEventComponent from "@/components/widgets/CalendarEvent/CalendarEventComponent.vue";
import CalendarDayComponent from "@/components/widgets/CalendarDay/CalendarDayComponent.vue";
import CalendarWeekComponent from "@/components/widgets/CalendarWeek/CalendarWeekComponent.vue";
import CountdownComponent from "@/components/widgets/Countdown/CountdownComponent.vue";
import CalendarAgendaComponent from "@/components/widgets/CalendarAgenda/CalendarAgendaComponent.vue";
import CalendarRoomScheduleComponent from "@/components/widgets/CalendarRoomSchedule/CalendarRoomScheduleComponent.vue";
import ProgressBarComponent from "@/components/widgets/ProgressBar/ProgressBarComponent.vue";
import ProgressDonutComponent from "@/components/widgets/ProgressDonut/ProgressDonutComponent.vue";
import ColumnGraphComponent from "@/components/widgets/ColumnGraph/ColumnGraphComponent.vue";
import BarGraphComponent from "@/components/widgets/BarGraph/BarGraphComponent.vue";
import LineGraphComponent from "@/components/widgets/LineGraph/LineGraphComponent.vue";
import PieGraphComponent from "@/components/widgets/PieGraph/PieGraphComponent.vue";
import StackedGraphComponent from "@/components/widgets/StackedGraph/StackedGraphComponent.vue";
import TriangleComponent from "@/components/widgets/Triangle/TriangleComponent.vue";
import LineComponent from "@/components/widgets/Line/LineComponent.vue";
import VideoComponent from "@/components/widgets/Video/VideoComponent.vue";

export const AllWidgets = {
  RectangleComponent,
  TextComponent,
  EllipseComponent,
  ImageComponent,
  GroupComponent,
  MultiframeComponent,
  SvgComponent,
  DatetimeComponent,
  CalendarEventComponent,
  CalendarDayComponent,
  CalendarWeekComponent,
  RepeaterComponent,
  CountdownComponent,
  CalendarAgendaComponent,
  CalendarRoomScheduleComponent,
  ProgressBarComponent,
  ProgressDonutComponent,
  ColumnGraphComponent,
  BarGraphComponent,
  LineGraphComponent,
  PieGraphComponent,
  StackedGraphComponent,
  AnalogClockComponent,
  TriangleComponent,
  LineComponent,
  VideoComponent,
};
