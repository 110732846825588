<template>
  <div class="h-full w-full">
    <div class="absolute inset-0 px-6 py-4 overflow-y-auto">
      <div>
        <div class="text-xl mb-6">
          {{
            $t("ConnectionSetup.schema.defineTreeSchema.selectCollectionNode")
          }}
        </div>

        <div v-if="showQuerySelection" class="flex space-x-6">
          <TabInput
            :value="selectedQuery"
            :options="[{ label: selectedQueryLabel, value: selectedQuery }]"
            @click.native="onChangeSelection"
          />
          <OutlineButton type="button" @click="onChangeSelection">{{
            $t("ConnectionSetup.schema.defineTreeSchema.changeSelectionBtnText")
          }}</OutlineButton>
        </div>

        <div class="space-y-6" v-show="showSelectionTree">
          <TreeNode
            v-show="showSelectionTree"
            :depth="0"
            :node="schema"
            :shouldExpand="true"
            :showValue="true"
            :selectedQuery="selectedQuery"
            selectionType="Collection"
            @select="onNodeSelected"
          />

          <portal to="setupWizardNext">
            <FormButton @click="onFinishedSelecting">{{
              $t(
                "ConnectionSetup.schema.defineTreeSchema.finishedSelectingBtnText"
              )
            }}</FormButton>
          </portal>
        </div>
      </div>

      <div
        class="border-t border-gray-800 mt-8 mb-2"
        v-if="showColumnDefinition"
      ></div>
      <div v-if="showColumnDefinition">
        <div>
          {{ $t("ConnectionSetup.schema.defineTreeSchema.selectValueToUse") }}
        </div>
        <DefineColumns
          :showQuery="true"
          :parentUuid="selectedUuid"
          :nodes="nodes"
          @complete="onCompleteClick"
          schemaType="Tree"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import TabInput from "@/components/inputs/TabInput.vue";
import TreeNode from "@/components/data/connections/setup/schema/TreeNode.vue";
import DefineColumns from "@/components/data/connections/setup/schema/DefineColumns.vue";
import FormButton from "@/components/FormButton.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import pick from "lodash.pick";
import { DataValue, SchemaNode, TreeSchemaNode } from "@/types/data";
import { useI18n } from "vue-i18n-composable";

const props = defineProps<{
  schema: SchemaNode;
}>();

const emits = defineEmits<{
  (e: "complete", value: Partial<SchemaNode>[]): void;
}>();

const selectedUuid = ref<string | undefined>(undefined);
const selectedQuery = ref<string | null>(null);
const nodes = ref<SchemaNode[]>([]);
const dataSetNode = ref<SchemaNode | null>(null);
const isNodeSelected = ref(false);
const i18n = useI18n();

const schema = computed(() => {
  return props.schema;
});

const showColumnDefinition = computed(() => {
  return isNodeSelected.value && nodes?.value.length > 0;
});

const showQuerySelection = computed(() => {
  return isNodeSelected.value;
});

const showSelectionTree = computed(() => {
  return !isNodeSelected.value;
});

const selectedQueryLabel = computed(() => {
  if (typeof selectedQuery.value === "string") {
    if (selectedQuery.value === "$") {
      return i18n.t("TreeNode.documentRoot").toString();
    }
  }

  return selectedQuery.value;
});

/**
 * Invoked when DefineColumns is complete
 */
function onCompleteClick(nodes: SchemaNode[]) {
  const cleanedNodes = [dataSetNode.value, ...nodes].map((n) =>
    pick(
      n,
      "dataType",
      "isSelected",
      "kind",
      "name",
      "parentUuid",
      "query",
      "referenceName",
      "uuid"
    )
  );

  emits("complete", cleanedNodes);
}

function isSelfOrFirstChildAnArray(node: TreeSchemaNode) {
  return (
    node.dataType === "ObjectArray" ||
    (node.children?.length === 1 && node.children[0].dataType === "ObjectArray")
  );
}

function onFinishedSelecting() {
  if (selectedUuid.value && selectedQuery.value) isNodeSelected.value = true;
}

function onChangeSelection() {
  isNodeSelected.value = false;
}

function onNodeSelected(node: TreeSchemaNode) {
  selectedQuery.value = node.query;
  selectedUuid.value = node.uuid;
  dataSetNode.value = node;

  if (["ObjectArray", "PrimativeArray"].includes(node.dataType)) {
    nodes.value = gatherColumnList(node);
  }
}

function gatherColumnList(node: TreeSchemaNode) {
  /**
   * This fixes issue #1863, where a user selects a collection from a tree data source,
   * but the first child in the collection is *not* a reliable indicator of the interface of all following children.
   * Example data source: https://scrfddat.blob.core.windows.net/devtest/ObjectCollection.json
   * In that data source, the first two children have 2 properties, but the third child has 3 properties.
   * When generating the schema view for the chosen collection, we want to show all 3 properties.
   * So we have to find the child with the most properties, and use that as our schema-indicator.
   */
  const childWithMostProperties = node.children.slice(0).sort((a, b) => {
    if (!b.children || !a.children) return 0;
    return b.children[0].children?.length - a.children[0].children?.length;
  })[0];

  const list = gatherChildNodes(childWithMostProperties, node.name);
  const rows = collectData(node);

  // console.log("list", list, "rows", rows);
  rows.forEach((row) => {
    list.forEach((c, colIndex) => {
      c.sampleValues.push(row.children[colIndex]);
    });
  });

  // Ignore nested collections, and use query if name is blank
  return list
    .filter((n) => !n.dataType.includes("Array"))
    .map((n) => {
      let newName = n.query.split(".")[n.query.split(".").length - 1];
      if (n.query.includes("[")) {
        newName = n.query.slice(
          n.query.lastIndexOf(".") + 1,
          n.query.lastIndexOf("[")
        );
      }
      // console.log("query parts....", queryParts);
      return { ...n, name: n.name ?? newName };
    });
}

function collectData(node: TreeSchemaNode) {
  return node.children.map((c) => {
    return {
      ...c,
      children: gatherChildNodes(c).map((n) => n.value),
    };
  });
}

function gatherChildNodes(node: TreeSchemaNode, name = "") {
  const res: TreeSchemaNode[] = [];
  node.children?.forEach((c) => {
    // Lets us build up nested names like "Current.HiTemp"
    const separator = name?.length > 0 ? "." : "";
    const displayQuery = `${name}${separator}${c.name}`;
    const isSelected = true;
    const sampleValues: DataValue[] = [];

    if (isSelfOrFirstChildAnArray(c)) {
      return;
    }

    if (c.dataType === "Object") {
      res.push(...gatherChildNodes(c, displayQuery));
      return;
    }
    res.push({ ...c, displayQuery, isSelected, sampleValues });
  });
  return res;
}
</script>
