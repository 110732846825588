<template>
  <div :id="uuid" class="flex items-center space-x-2 mt-1">
    <div
      :style="{ 'background-color': colorValue }"
      class="w-7 h-7 rounded"
    ></div>
    <div v-text="displayValue" class="text-center"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataType, DataValue } from "@/types/data";
import { cleanColorValue } from "@/utils";

@Component
export default class DataNodeColor extends Vue {
  @Prop({ type: String, required: false }) readonly uuid: string;
  @Prop(String) readonly dataType: DataType;
  @Prop() readonly value: DataValue;

  get colorValue() {
    return cleanColorValue(this.value.value).value;
  }

  get displayValue() {
    const result = this.value.formattedValue ?? this.value.value;
    return cleanColorValue(result).formattedValue;
  }
}
</script>

<style scoped></style>
