<template>
  <div class="w-full">
    <div>
      <form ref="form" class="space-y-6" @submit.prevent="onImport">
        <div>
          <div class="block text-xl mb-1" v-t="'GoogleSheets.enterUrl'"></div>

          <input
            ref="url"
            type="text"
            placeholder="https://docs.google.com/spreadsheets/d/xxxxx"
            class="block w-full px-2 py-1 flex-grow rounded-sm border text-gray-900 dark-form-focus"
            v-model="url"
          />
          <div
            class="inline-block px-3 py-px rounded border border-red-500 mt-1 text-sm bg-red-500 bg-opacity-50"
            v-if="inputError"
            v-text="inputError"
          ></div>
        </div>

        <portal to="setupWizardNext">
          <FormButton
            @click="startImport"
            type="button"
            v-t="'GoogleSheets.importSpreadsheet'"
          ></FormButton>
        </portal>
      </form>

      <FatalServerError class="m-6" v-if="serverAuthError">
        <div class="">
          The problem occured while attempting to communicate with Google's
          permission system
        </div>
      </FatalServerError>

      <ThirdPartyOAuth
        class="border border-gray-500 p-8 self-center shadow-md rounded-lg mt-8"
        v-if="showAuthPrompt"
        dataProviderId="GoogleSheets"
        @success="onAuthSuccess"
      >
        <div
          class="mb-4 text-red-500"
          v-if="showAccessError"
          v-t="'GoogleSheets.accessErrorGoogleSheet'"
        ></div>
      </ThirdPartyOAuth>

      <portal to="setupWizardUiBlocker">
        <UiBlocker :visible="fetching">
          <div
            class="mt-4 text-xl text-white"
            v-t="'GoogleSheets.loadingSpreadsheet'"
          ></div>
        </UiBlocker>
      </portal>
    </div>

    <div class="relative mt-8" v-if="spreadsheet">
      <div
        class="border border-app-dark2 bg-black bg-opacity-20 rounded p-6 space-y-6"
      >
        <div class="flex items-center space-x-4">
          <img
            :src="store.source?.iconUrl"
            :alt="store.source?.displayName + ' icon'"
            class="h-12"
          />
          <div>
            <div class="text-xl">{{ spreadsheet.spreadsheetName }}</div>
            <div class="font-mono text-xs text-gray-500">
              {{ spreadsheetId }}
            </div>
          </div>
        </div>

        <div class="text-xl" v-t="'GoogleSheets.selectSheet'"></div>

        <TabInput v-model="selectedRangeId" :options="sheetOptions" />

        <div
          v-if="showEmptyError"
          class="text-red-500 italic"
          v-t="'GoogleSheets.emptySheetError'"
        ></div>

        <portal to="setupWizardNext">
          <FormButton
            :disabled="selectedRangeId === null"
            type="button"
            @click="onSheetSelected"
            v-t="'continue'"
          ></FormButton>
        </portal>
      </div>

      <portal to="setupWizardUiBlocker">
        <UiBlocker :visible="importing">
          <div
            class="mt-4 text-xl text-white"
            v-t="loadingSheetDataMessage"
          ></div>
        </UiBlocker>
      </portal>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api/backend";
import { TabularDataSchema } from "@/types/data";
import { GoogleAppType, GoogleSheetsResponse } from "@/types/google";
import ThirdPartyOAuth from "@/components/data/connections/setup/ThirdPartyOAuth.vue";
import FatalServerError from "@/components/FatalServerError.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import TabInput from "@/components/inputs/TabInput.vue";
import {
  SourceDataInfo,
  useConnectionSetupStore,
} from "@/stores/connectionSetup";

const getSpreadsheetId = (url: string): string | undefined => {
  const re = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)");
  const result = re.exec(url);
  if (result && result.length === 2) {
    return result[1];
  }
};

@Component({
  components: {
    FatalServerError,
    ThirdPartyOAuth,
    UiBlocker,
    FormButton,
    SelectMenu,
    TabInput,
  },
})
export default class ProvideGoogleSheets extends Vue {
  inputError: string | null = null;
  authorizing = false;
  showAuthPrompt = false;
  showAccessError = false;
  showEmptyError = false;
  url = "";
  fetching = false;
  importing = false;
  spreadsheetId: string | null = null;
  spreadsheet: GoogleSheetsResponse | null = null;
  selectedRangeId: string | null = null;

  mounted() {
    (this.$refs.url as HTMLInputElement).focus();
  }

  get store() {
    return useConnectionSetupStore();
  }

  startImport() {
    this.onImport();
  }
  onImport() {
    this.inputError = "";
    this.showAuthPrompt = false;

    const spreadsheetId = getSpreadsheetId(this.url);
    if (typeof spreadsheetId === "undefined") {
      this.inputError = this.$t("ProvideUrl.invalidUrl").toString();
      return Promise.reject();
    }

    this.spreadsheetId = spreadsheetId;
    this.fetching = true;

    return api
      .get<GoogleSheetsResponse>(`google/${this.spreadsheetId}/sheets`)
      .then((response) => {
        this.spreadsheet = response;
        this.selectedRangeId = this.spreadsheet?.sheets?.[0].rangeId ?? null;
      })
      .catch((errors) => {
        const code = errors?.[0]?.code;

        switch (code) {
          case "authFailure":
            this.showAuthPrompt = true;
            break;
          default:
            this.inputError = Array.isArray(errors)
              ? `Errors: ${errors.map((e: any) => e.message).join(". ")}`
              : errors.toString();
            break;
        }

        return Promise.reject(errors);
      })
      .finally(() => {
        this.fetching = false;
      });
  }

  get serverAuthError() {
    const query = this.$route.query;
    return query.hasInvalidState === "true" || query.hasError === "true";
  }

  get GoogleAppType() {
    return GoogleAppType.Sheets;
  }

  onAuthSuccess() {
    this.onImport().catch(() => {
      if (this.showAuthPrompt) {
        this.showAccessError = true;
      }
    });
  }

  get sheetOptions() {
    return (
      this.spreadsheet?.sheets.map((s) => {
        return {
          label: s.title,
          value: s.rangeId,
        };
      }) ?? []
    );
  }

  onSheetSelected() {
    if (this.selectedRangeId === null) {
      return;
    }

    const rangeOpts = {
      range: this.selectedRangeId,
      take: 25,
    };

    const payload = {
      providerAuthId: this.spreadsheet?.providerAuthId,
      spreadsheetId: this.spreadsheetId,
      rangeOptions: rangeOpts,
      isCollection: this.store.isCollection,
    };

    this.importing = true;
    this.showEmptyError = false;

    return api
      .post<TabularDataSchema>(`google/${this.spreadsheetId}/sheets`, payload)
      .then((response) => {
        this.$emit("complete", {
          name: this.spreadsheet?.spreadsheetName,
          type: "Tabular",
          schema: response,
          providerAuthId: payload.providerAuthId,
        } as SourceDataInfo);
      })
      .catch((errors) => {
        // console.log("error importing sheet", err);
        const code = errors?.[0]?.code;
        if (code === "noData") {
          this.showEmptyError = true;
        }
      })
      .finally(() => {
        this.importing = false;
      });
  }

  get loadingSheetDataMessage() {
    const sheet =
      this.spreadsheet?.sheets.find((s) => s.rangeId === this.selectedRangeId)
        ?.title ?? this.$t("GoogleSheets.selectedSheet").toString();
    return this.$t("GoogleSheets.loadingSheetData", { sheet });
  }
}
</script>
