<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <span
    class="button-root relative z-10 h-8 w-28 inline-flex shadow-sm rounded-sm text-white"
    @click.stop
  >
    <button
      type="button"
      :disabled="isSaving"
      :class="[
        'relative inline-flex items-center px-4 w-full justify-center rounded-l-md font-semibold',
        isSaving ? 'opacity-80 cursor-not-allowed' : '',
        hasUnsavedChanges || isErrored ? 'bg-app-yellow' : 'bg-app-emerald',
      ]"
      @click="save"
      v-t="saveStatus"
    ></button>
    <span class="relative block">
      <button
        id="option-menu"
        type="button"
        :class="[
          'relative h-full inline-flex items-center px-1',
          'rounded-r-md border-l border-white',
          hasUnsavedChanges || isErrored ? 'bg-app-yellow' : 'bg-app-emerald',
        ]"
        @click="toggle"
      >
        <span class="sr-only">Open options</span>
        <!-- Heroicon name: chevron-down -->
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div
        class="origin-top-right absolute right-0 mt-2 -mr-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        v-show="expanded"
      >
        <div
          class="py-1 block text-sm text-gray-700 hover:text-gray-900"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="option-menu"
        >
          <button class="option-btn" role="menuitem" @click="saveAs">
            <Icon class="h-5 w-5" name="Duplicate" />
            <span class="ml-2" v-t="'saveACopy'"></span>
          </button>

          <button
            class="option-btn"
            role="menuitem"
            v-if="!isTemplate"
            @click="saveAsTemplate"
          >
            <Icon class="h-5 w-5" name="Template" />
            <span class="ml-2" v-t="'saveAsTemplate'"></span>
          </button>
        </div>
      </div>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import { EventBus } from "@/eventbus";
import { useAppsStore } from "@/stores/apps";
import { logger } from "@core/logger";
import { useAppEditorStore } from "@/stores/appEditor";

const SAVE_INTERVAL_SECONDS = 30;

@Component({
  components: { Icon },
})
export default class SaveButton extends Vue {
  expanded = false;
  timer: any;

  isSaving = false;
  isErrored = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get hasUnsavedChanges() {
    return this.appEditor.hasUnsavedChanges;
  }

  get isTemplate() {
    return this.appEditor.isTemplate;
  }

  get appUuid() {
    return this.appEditor.uuid;
  }

  get appsStore() {
    return useAppsStore();
  }

  get saveStatus() {
    if (this.isSaving) {
      return "saving";
    } else if (this.hasUnsavedChanges || this.isErrored) {
      return "save";
    } else {
      return "saved";
    }
  }

  mounted() {
    EventBus.on("SAVING_APP", (val) => {
      this.isSaving = val;
    });
  }

  created() {
    this.timer = setInterval(() => {
      // Only save if app has unsaved changes, and a connections modal is not open
      const isConnectionsModalOpen = this.$route.path.includes("connections");
      if (isConnectionsModalOpen) return;
      if (!this.hasUnsavedChanges) return;
      this.save();
    }, 1000 * SAVE_INTERVAL_SECONDS);
  }

  destroyed() {
    clearInterval(this.timer);
  }

  async saveAs() {
    const nameSuggestion = `${this.appEditor.name} (copy)`;
    const newName =
      window.prompt(this.$t("SaveButton.saveAs").toString(), nameSuggestion) ??
      nameSuggestion;

    // Save any unsaved changes first
    if (this.hasUnsavedChanges) {
      await this.save();
    }

    EventBus.emit("AWAITING_SERVER", true);
    this.appsStore
      .cloneApp(this.appUuid, newName)
      .then(() => {
        window.setTimeout(() => {
          window.alert(
            this.$t("SaveButton.saveAsSuccess", { newName }).toString()
          );
        }, 25);
      })
      .catch((err) => {
        logger.track(err);
        window.alert(this.$t("unknownServerError") as string);
      })
      .finally(() => {
        EventBus.emit("AWAITING_SERVER", false);
      });
  }

  async saveAsTemplate() {
    EventBus.emit("AWAITING_SERVER", true);

    // Save any unsaved changes first
    await this.save();

    try {
      const resp = await this.appsStore.saveAsTemplate(this.appUuid);
      const newUuid = resp.uuid;
      this.$router.push(`/templates/${newUuid}/detail`);
    } catch (err) {
      logger.track(err);
      return window.alert(this.$t("unknownServerError"));
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
    }
  }

  close() {
    this.expanded = false;
    document.removeEventListener("click", this.close);
  }

  open() {
    this.expanded = true;
    document.addEventListener("click", this.close);
  }

  toggle() {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  }

  async save() {
    EventBus.emit("APP_SAVE_ERROR", false);
    this.isErrored = false;

    EventBus.emit("SAVING_APP", true);
    // console.log("start saving");
    const startSave = Date.now();

    try {
      await this.appEditor.updateApp();
      EventBus.emit("APP_CHANGES_SAVED");
    } catch (reason) {
      EventBus.emit("APP_SAVE_ERROR", true);
      console.log("save error", reason);
      this.isErrored = true;
      // return Promise.reject(reason);
    } finally {
      const endSave = Date.now();
      const diff = endSave - startSave;
      const MIN_TIME_SAVING = 800;
      setTimeout(() => {
        EventBus.emit("SAVING_APP", false);
      }, MIN_TIME_SAVING - diff);
    }
  }
}
</script>

<style lang="postcss" scoped>
.button-root {
  /* #f0f0f0 in decimal RGB */
  --color: 6, 78, 59;
}

.button-shade {
  /* border-top-color: rgba(255, 255, 255, 0.5);
  border-right-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-left-color: rgba(255, 255, 255, 0.5); */
  box-shadow: inset 1px 1px 1px #fff3,
    inset -1px -1px 1px rgba(var(--color), 0.3), 0 1px 1px #0002,
    0 1px 5px #0002;
}

.option-btn {
  @apply flex w-full px-4 py-2 text-left whitespace-nowrap hover:bg-gray-100;
}
</style>
