<template>
  <div class="relative w-full select-none">
    <div
      v-if="dropSupported"
      @drag.prevent
      @dragover.prevent
      @dragenter.prevent="highlight = true"
      @dragleave.prevent="highlight = false"
      @drop.prevent.stop="onFileDropped"
      class="w-full h-64"
    >
      <label
        class="border border-dashed border-gray-400 relative block h-full w-full rounded flex items-center justify-center cursor-pointer"
        :class="{
          'dark-form-focus-ring': isDraggingFile,
          'bg-gray-600': highlight,
        }"
        @mouseover="highlight = true"
        @mouseout="highlight = false"
        for="file"
      >
        <div
          :class="{ invisible: uploading }"
          class="flex flex-col items-center justify-center"
        >
          <div class="text-xl">
            <span class="underline">Choose a file</span>&nbsp;<span
              >or drag one here</span
            >.
          </div>
          <div class="mt-3 text-sm text-gray-300">
            File size limit is {{ formatSize(maxFileSize) }}
          </div>

          <div
            v-if="inputError"
            class="mt-4 text-center bg-red-500 bg-opacity-40 border border-red-500 rounded px-4 py-1"
          >
            {{ inputError }}
          </div>
        </div>
        <input
          class="invisible absolute top-0 left-0"
          id="file"
          type="file"
          @change="onFileSelected"
        />
      </label>
    </div>

    <div v-if="!dropSupported">
      <input type="file" @change="onFileSelected" />
      <button type="button">Upload File</button>
    </div>

    <div class="flex items-end justify-between my-3">
      <SupportedFormats />
      <Link
        class="mx-1"
        href="https://screenfeed.com/support-articles/managing-your-data-connections"
        target="_blank"
        v-t="'needHelp'"
      />
    </div>

    <portal to="setupWizardUiBlocker">
      <UiBlocker :visible="uploading">
        <div class="mt-4 text-xl text-white" v-text="uploadingMessage"></div>
        <div class="mt-4 text-xl text-white" v-if="uploadPercent <= 99">
          {{
            $t("ProvideFileUpload.uploadProgress", {
              uploadPercent,
              uploadTotal,
            })
          }}
        </div>
      </UiBlocker>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import { SourceDataSchema } from "@/types/data";
import { api } from "@/api/backend";
import { SourceDataInfo } from "@/stores/connectionSetup";
import SupportedFormats from "@/components/data/connections/setup/provide/SupportedFormats.vue";
import { getConnectionErrorMessage } from "@/components/data/connections/connectionErrorMessage";
import Link from "@/components/Link.vue";
import VueI18n from "vue-i18n";

@Component({
  components: { UiBlocker, FormButton, SupportedFormats, Link },
})
export default class FileUpload extends Vue {
  inputError: VueI18n.TranslateResult | null = null;
  highlight = false;
  isDraggingFile = false;

  uploading = false;
  uploadPercent = 0;
  uploadTotal = "";

  get dropSupported() {
    var div = document.createElement("div");
    return (
      ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window
    );
  }

  get uploadingMessage() {
    return this.uploadPercent <= 99
      ? this.$t("ProvideFileUpload.uploading")
      : this.$t("ProvideFileUpload.processingUpload");
  }

  get maxFileSize() {
    const limitInMb = 5;
    return Math.pow(1024, 2) * limitInMb;
  }

  formatSize(bytes: number, precision: number = 2) {
    const base = 1024;
    const prefixes = ["kB", "MB", "GB", "TB"];
    if (!isFinite(bytes)) {
      return "- Bytes";
    } else if (bytes == 1) {
      return "1 Byte";
    } else if (bytes < base) {
      return bytes + " Bytes";
    }
    const index = Math.floor(Math.log(bytes) / Math.log(base));
    const value = parseFloat(
      (bytes / Math.pow(base, Math.floor(index))).toFixed(precision)
    ).toString();
    const unit = prefixes[index - 1];
    return `${value} ${unit}`;
  }

  onDragOver(e: unknown) {
    const event = e as DragEvent;
    this.isDraggingFile =
      (event.dataTransfer?.types?.indexOf("Files") ?? -1) > -1;
  }

  onDragLeave() {
    this.isDraggingFile = false;
  }

  created() {
    document.addEventListener("dragover", this.onDragOver);
    document.addEventListener("dragleave", this.onDragLeave);
  }

  beforeDestroy() {
    document.removeEventListener("dragover", this.onDragOver);
    document.removeEventListener("dragleave", this.onDragLeave);
  }

  onFileDropped(e: DragEvent) {
    this.highlight = false;
    const file = e.dataTransfer?.files[0];
    if (file) {
      this.uploadFile(file);
    }
  }

  onFileSelected(e: InputEvent) {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      this.uploadFile(file);
    }
  }

  onUploadProgress(e: ProgressEvent) {
    this.uploadPercent = Math.round((e.loaded * 100) / e.total);
    this.uploadTotal = this.formatSize(e.total);
  }

  uploadFile(file: File) {
    this.isDraggingFile = false;
    this.inputError = "";

    if (file.size >= this.maxFileSize) {
      this.inputError = this.$t("ProvideFileUpload.exceedsFileLimit", {
        size: this.formatSize(file.size),
      }).toString();
      return;
    }

    const data = new FormData();
    data.append("file", file);
    data.append("type", file.type);
    data.append("name", file.name);

    this.uploading = true;
    this.uploadPercent = 0;
    this.uploadTotal = "";

    // TODO: We should catch any server errors here....
    api
      .post<SourceDataSchema>("dataconnection/upload", data, {
        onUploadProgress: this.onUploadProgress,
      })
      .catch((errors) => {
        const errorMessage = Array.isArray(errors)
          ? `Errors: ${errors.map((e: any) => e.message).join(". ")}`
          : errors.toString();

        this.inputError = getConnectionErrorMessage(
          errors?.[0]?.code,
          errorMessage
        );

        return Promise.reject(errors);
      })
      .then((response) => {
        this.$emit("complete", {
          name: file.name,
          type: response.schemaType,
          schema: response,
        } as SourceDataInfo);
      })
      .finally(() => {
        this.uploading = false;
      });
  }
}
</script>
<style scoped lang="postcss">
.error-message {
  background: rgba(246, 38, 38, 0.35);
  border: 1px solid rgba(246, 38, 38, 0.75);
}
</style>
