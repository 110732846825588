<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-if="steps.length > 0">
    <nav aria-label="Progress">
      <ol
        role="list"
        :class="{ 'rounded-tr-lg rounded-tl-lg': shouldRoundCorners }"
        class="cursor-default select-none border bg-gray-800 border-gray-900 divide-y divide-app-dark2 md:flex md:divide-y-0"
      >
        <li
          v-for="(step, stepIdx) in steps"
          :key="step.name"
          class="relative md:flex-1 md:flex"
        >
          <router-link
            v-if="step.isComplete"
            :to="step.to"
            class="group cursor-pointer flex items-center w-full"
            :class="{ 'pointer-events-none': isWizardComplete }"
          >
            <span class="px-6 py-4 flex items-center font-bold">
              <span
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-app-teal rounded-full group-hover:bg-app-teal group-hover:bg-opacity-80"
              >
                <IconBigCheckmark class="text-app-teal" aria-hidden="true" />
              </span>
              <span class="ml-4 text-gray-500">{{ step.name }}</span>
            </span>
          </router-link>

          <div
            v-else-if="step.isCurrent"
            class="px-6 py-4 flex items-center font-bold"
            aria-current="step"
          >
            <span
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-app-teal rounded-full"
            >
              <span class="text-sm">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-white">{{ step.name }}</span>
          </div>

          <div v-else class="group flex items-center">
            <span class="px-6 py-4 flex items-center font-bold">
              <span
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-500 rounded-full"
              >
                <span class="text-sm text-gray-500">{{ step.id }}</span>
              </span>
              <span class="ml-4 text-gray-500">{{ step.name }}</span>
            </span>
          </div>

          <template v-if="stepIdx !== steps.length - 1">
            <!-- Arrow separator for lg screens and up -->
            <div
              class="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                class="h-full w-full text-black"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script lang="ts">
import { useConnectionSetupStore } from "@/stores/connectionSetup";
import { SetupStepId } from "@/types/ConnectionSources";
import { Component, Vue } from "vue-property-decorator";
import { Location } from "vue-router";
import IconBigCheckmark from "@/components/icons/IconBigCheckmark.vue";
import { APP_EDITOR_ROUTE_PATH } from "@/constants";

interface WizardStep {
  id: string;
  name: string;
  to: Location;
  isCurrent: boolean;
  isComplete: boolean;
  isDisabled: boolean;
}

@Component({
  components: { IconBigCheckmark },
})
export default class SetupNavigation extends Vue {
  get store() {
    return useConnectionSetupStore();
  }

  get source() {
    return this.store.source;
  }

  get shouldRoundCorners() {
    // Use rounded corners if this is a dashboard, setup route
    return !this.$route.path.startsWith(`/${APP_EDITOR_ROUTE_PATH}`);
  }

  get isWizardComplete() {
    return this.store.steps.find((s) => s.id === "Complete" && s.isCurrent);
  }

  getStepName(stepId: SetupStepId): string {
    switch (stepId) {
      case "Provide":
        return this.$t("Setup.provideData").toString();
      case "Schema":
        return this.$t("Setup.defineSchema").toString();
      case "Selection":
        return this.$t("Setup.selection").toString();
      case "Sync":
        return this.$t("Setup.defineSync").toString();
      case "Name":
        return this.$t("Setup.defineName").toString();
      case "Configure":
        return this.$t("Setup.defineConfigure").toString();
      case "Replace":
        return this.$t("Setup.remapColumns").toString();
      default:
        return "Unknown";
    }
  }

  get steps(): WizardStep[] {
    return (
      this.store.steps
        .filter((s) => s.id !== "Complete")
        .map((s, index) => {
          return {
            id: (index + 1).toString(),
            name: this.getStepName(s.id),
            to: { path: s.id.toLowerCase(), query: this.$route.query },
            isCurrent: s.isCurrent,
            isComplete: s.isComplete,
            isDisabled: !s.isCurrent && !s.isComplete,
          };
        }) ?? []
    );
  }
}
</script>

<style lang="postcss"></style>
