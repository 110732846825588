<template>
  <div class="whitespace-pre-wrap h-full w-full" :style="styles">
    <div ref="text" v-html="html"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { TextContent, contentToHtml } from "@/text";
import { TextTransformValue } from "../TextStyleOptions";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";
import { getTextStyleCss } from "@/textfit";
import { TextOptions } from "./TextOptions";

@Component({
  components: {},
})
export default class TextComponent extends AnimatableWidget {
  @Prop(Object) content: TextContent;
  @Prop(String) alignHorizontal: string;
  @Prop(String) alignVertical: string;
  @Prop(String) fontFamily?: string;
  @Prop(Number) fontWeight?: number;
  @Prop(String) fontStyle?: string;
  @Prop(Number) fontSize?: number;
  @Prop(String) textColor?: string;
  @Prop(String) textTransform: TextTransformValue;
  @Prop(Boolean) textStrike?: boolean;
  @Prop(Boolean) textUnderline?: boolean;
  @Prop(Boolean) fitText?: boolean;
  @Prop(Number) letterSpacing?: number;
  @Prop(Number) lineHeight?: number;
  @Prop(Number) dataRowIndex?: number;
  @Prop() paragraphSpacing?: number | "auto";
  @Prop(Number) readonly shadowX: number;
  @Prop(Number) readonly shadowY: number;
  @Prop(Number) readonly shadowBlur: number;
  @Prop(String) readonly shadowColor: string;
  @Prop(Boolean) readonly shadowDisplay: boolean;

  get html() {
    return contentToHtml(this.content);
  }

  get styles() {
    return getTextStyleCss(this.$props as TextOptions);
  }
}
</script>
