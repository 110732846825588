import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { DateTime, DateTimeFormatOptions } from "luxon";
// Luxon's preset formats (most of them):

export const LUXON_TIME_FORMATS = [
  "TIME_SIMPLE",
  "TIME_WITH_SECONDS",
  "TIME_24_SIMPLE",
  "TIME_24_WITH_SECONDS",
];

export const LUXON_DATE_FORMATS = [
  "DATE_SHORT",
  "DATE_MED",
  "DATE_MED_WITH_WEEKDAY",
  "DATE_FULL",
  "DATE_HUGE",
];

export const LUXON_DATETIME_FORMATS = [
  ...LUXON_DATE_FORMATS,
  ...LUXON_TIME_FORMATS,
  "DATETIME_SHORT",
  "DATETIME_SHORT_WITH_SECONDS",
  "DATETIME_MED",
  "DATETIME_MED_WITH_SECONDS",
];

/**
 * Instead of relying on Luxon presets, we copy in all those presets here, and then we can add to this list
 */

export const CUSTOM_DATETIME_FORMATS: DateTimeFormatOptions[] = [
  { year: "numeric", month: "numeric", day: "numeric" }, // DATE_SHORT: 10/14/1983
  { year: "numeric", month: "short", day: "numeric" }, // DATE_MED: Oct 14, 1983
  { year: "numeric", month: "short", day: "numeric", weekday: "short" }, // DATE_MED_WITH_WEEKDAY: Fri, Oct 14, 1983
  { year: "numeric", month: "long", day: "numeric" }, // DATE_FULL: October 14, 1983
  { year: "numeric", month: "long", day: "numeric", weekday: "long" }, // DATE_HUGE: Friday, October 14, 1983
  { hour: "numeric", minute: "numeric" }, // TIME_SIMPLE: 	1:30 PM
  { hour: "numeric", minute: "numeric", second: "numeric" }, // TIME_WITH_SECONDS: 	1:30:23 PM
  { hour: "numeric", minute: "numeric", hour12: false }, // TIME_24_SIMPLE: 13:30
  { hour: "numeric", minute: "numeric", second: "numeric", hour12: false }, // TIME_24_WITH_SECONDS: 13:30:23
  {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }, // DATETIME_SHORT: 10/14/1983, 1:30 PM
  {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }, // DATETIME_MED: Oct 14, 1983, 1:30 PM
  {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }, // DATETIME_SHORT_WITH_SECONDS: 10/14/1983, 1:30:23 PM
  {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }, // DATETIME_MED_WITH_SECONDS: Oct 14, 1983, 1:30:23 PM

  // CUSTOM, NON-LUXON PRESETS
  { weekday: "short" },
  { weekday: "long" },
  { month: "short" },
  { month: "long" },
  { month: "long", day: "numeric" },
  { month: "numeric", day: "numeric" },
  { hour: "numeric" },
];

// https://stackoverflow.com/questions/3191664/list-of-all-locales-and-their-short-codes
export const LOCALE_OPTIONS = [
  {
    label: "English (U.S.)",
    value: "en-US",
  },
  {
    label: "English (U.K.)",
    value: "en-UK",
  },
  {
    label: "French",
    value: "fr-FR",
  },
  {
    label: "French (Can.)",
    value: "fr-CA",
  },
  {
    label: "German",
    value: "de-DE",
  },
  {
    label: "Spanish",
    value: "es-ES",
  },
  {
    label: "Swedish",
    value: "sv-SE",
  },
  {
    label: "Danish",
    value: "da",
  },
  {
    label: "Dutch (Neth.)",
    value: "nl-NL",
  },
  {
    label: "Dutch (Bel.)",
    value: "nl-BE",
  },
];

export interface DatetimeOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  datetimeFormat: string;
  datetimeValue: string;
  userSpecifiedLocale: string;
  useBrowserLocale: boolean;
  usePlayerTimezone: boolean;
  timezone: string | null;
  textColor: string;
  fontFamily: string;
  fontSize: number;
  fontWeight: number;
  textTransform: string;
  fontStyle: string;
  letterSpacing: number;
  lineHeight: number;
  alignHorizontal: string;
  // isDate: boolean;
  // isTime: boolean;
  showSeconds: boolean; // NOTE: Not used currently
  use24: boolean; // NOTE: Not used currently
  useCurrentTime: boolean;
}

// export const defaultTextOptions = {
//   textColor: "#000000",
//   fontFamily: "Cabin",
//   fontSize: 84,
//   fontWeight: 400,
//   textTransform: "capitalize",
//   fontStyle: "normal",
//   letterSpacing: 0,
//   alignHorizontal: "Left",
//   lineHeight: 1,
// };

export const defaultTextOptions = {
  textColor: "#1d283a",
  fontFamily: "Montserrat",
  fontSize: 84,
  fontWeight: 700,
  textTransform: "uppercase",
  fontStyle: "normal",
  letterSpacing: 0,
  textAlign: "center",
  lineHeight: 1.2,
  alignHorizontal: "center",
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultTextOptions,
  datetimeValue: DateTime.now(),
  datetimeFormat: JSON.stringify(CUSTOM_DATETIME_FORMATS[0]),
  userSpecifiedLocale: "en-US",
  useBrowserLocale: true,
  usePlayerTimezone: true,
  timezone: null,
  showSeconds: true,
  use24: false,
  useCurrentTime: false,

  // isDate: false,
  // isTime: false,
};

const CreateDatetime = (options: Partial<DatetimeOptions>) => {
  const wid = makeId();

  return Object.assign({ wid }, defaultOptions, options, {
    type: "Datetime",
    canScaleY: false,
  });
};

export default CreateDatetime;
