<template>
  <div class="w-full h-full relative p-12">
    <!-- Hide location title if there are no events; only show clock: -->
    <div v-if="eventsForCalendar.length > 0">
      <div :style="getStyles('locationTitle')">
        {{ locationTitle }}
      </div>
      <div
        class="w-full mt-6"
        :style="{
          backgroundColor: dividerFillColor,
          height: `${dividerSize}px`,
        }"
      ></div>
    </div>

    <!-- Data OR clock layer: -->
    <div class="w-full h-full">
      <div
        v-if="headerEvent.summary"
        class="w-full flex flex-col justify-center items-center"
        style="height: 85%"
      >
        <!-- Header div: current or next event -->
        <div class="mb-20" style="width: 60%">
          <div class="mb-2" :style="getStyles('headerEventTitle')">
            <span v-if="!headerIsInProgress && headerEvent.start">Next</span>
            {{ headerEvent.summary }}
          </div>

          <!-- TODO: Break this out into computed prop and account for "all day". Also account for no events case. -->
          <div
            v-if="headerEvent.start"
            class="mb-4"
            :style="getStyles('headerEventTime')"
          >
            <span v-if="headerIsInProgress">Now</span>
            <span v-else>{{ formatTime(headerEvent.start) }}</span>
            -
            {{ formatTime(headerEvent.end) }}
          </div>

          <div
            v-if="headerIsInProgress"
            class="h-4 relative"
            style="width: 60%"
            :style="{ backgroundColor: progressBarBackgroundColor }"
          >
            <div
              class="absolute top-0 left-0 h-full"
              :style="headerProgressStyle"
            ></div>
          </div>
        </div>

        <!-- Subheader div: later event: -->
        <div v-if="subheaderEvent" style="width: 60%">
          <div class="mb-2" :style="getStyles('subheaderEventTitle')">
            <span v-if="headerIsInProgress">NEXT:</span
            ><span v-else>LATER:</span> {{ subheaderEvent.summary }}
          </div>
          <div :style="getStyles('subheaderEventTime')">
            {{ formatTime(subheaderEvent.start) }} -
            {{ formatTime(subheaderEvent.end) }}
          </div>
        </div>
      </div>

      <!-- Clock: -->
      <div v-else class="flex items-center justify-center w-full h-full">
        <div :style="getStyles('clockTime')">
          {{ now }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { makeHourTodayDate } from "@/types/calendar";
import { DateTime, Interval } from "luxon";
import { CalendarEvent } from "@/types/calendar";
import { NodeData, NodeSetData } from "@/types/data";
import CalendarEmptyMessage from "@/components/widgets/CalendarEmptyMessage.vue";
import CalendarBase from "@/components/widgets/CalendarBase.vue";

@Component({ components: { CalendarEmptyMessage } })
export default class CalendarRoomScheduleComponent extends CalendarBase {
  @Prop(Array) data: any[];
  // @Prop(Number) dataIndex: number;
  @Prop(Boolean) previewLiveData: boolean;

  @Prop(Boolean) use24Hour: boolean;

  @Prop(String) progressBarBackgroundColor: string;
  @Prop(String) progressBarFillColor: string;
  @Prop(String) dividerFillColor: string;
  @Prop(Number) dividerSize: number;

  @Prop(String) readonly locationTitle_fontFamily: string | null;
  @Prop(Number) readonly locationTitle_fontWeight: number;
  @Prop(String) readonly locationTitle_textColor: string | null;
  @Prop(Number) readonly locationTitle_fontSize: number;
  @Prop(Number) readonly locationTitle_letterSpacing: number;
  @Prop(String) readonly locationTitle_textTransform: string | null;
  @Prop(String) readonly locationTitle_textDecoration: string | null;
  @Prop(String) readonly locationTitle_fontStyle: string | null;
  @Prop(Number) readonly locationTitle_lineHeight: number;
  @Prop(String) readonly locationTitle_textAlign: string | null;

  @Prop(String) readonly headerEventTitle_fontFamily: string | null;
  @Prop(Number) readonly headerEventTitle_fontWeight: number;
  @Prop(String) readonly headerEventTitle_textColor: string | null;
  @Prop(Number) readonly headerEventTitle_fontSize: number;
  @Prop(Number) readonly headerEventTitle_letterSpacing: number;
  @Prop(String) readonly headerEventTitle_textTransform: string | null;
  @Prop(String) readonly headerEventTitle_textDecoration: string | null;
  @Prop(String) readonly headerEventTitle_fontStyle: string | null;
  @Prop(Number) readonly headerEventTitle_lineHeight: number;
  @Prop(String) readonly headerEventTitle_textAlign: string | null;

  @Prop(String) readonly headerEventTime_fontFamily: string | null;
  @Prop(Number) readonly headerEventTime_fontWeight: number;
  @Prop(String) readonly headerEventTime_textColor: string | null;
  @Prop(Number) readonly headerEventTime_fontSize: number;
  @Prop(Number) readonly headerEventTime_letterSpacing: number;
  @Prop(String) readonly headerEventTime_textTransform: string | null;
  @Prop(String) readonly headerEventTime_textDecoration: string | null;
  @Prop(String) readonly headerEventTime_fontStyle: string | null;
  @Prop(Number) readonly headerEventTime_lineHeight: number;
  @Prop(String) readonly headerEventTime_textAlign: string | null;

  @Prop(String) readonly subheaderEventTitle_fontFamily: string | null;
  @Prop(Number) readonly subheaderEventTitle_fontWeight: number;
  @Prop(String) readonly subheaderEventTitle_textColor: string | null;
  @Prop(Number) readonly subheaderEventTitle_fontSize: number;
  @Prop(Number) readonly subheaderEventTitle_letterSpacing: number;
  @Prop(String) readonly subheaderEventTitle_textTransform: string | null;
  @Prop(String) readonly subheaderEventTitle_textDecoration: string | null;
  @Prop(String) readonly subheaderEventTitle_fontStyle: string | null;
  @Prop(Number) readonly subheaderEventTitle_lineHeight: number;
  @Prop(String) readonly subheaderEventTitle_textAlign: string | null;

  @Prop(String) readonly subheaderEventTime_fontFamily: string | null;
  @Prop(Number) readonly subheaderEventTime_fontWeight: number;
  @Prop(String) readonly subheaderEventTime_textColor: string | null;
  @Prop(Number) readonly subheaderEventTime_fontSize: number;
  @Prop(Number) readonly subheaderEventTime_letterSpacing: number;
  @Prop(String) readonly subheaderEventTime_textTransform: string | null;
  @Prop(String) readonly subheaderEventTime_textDecoration: string | null;
  @Prop(String) readonly subheaderEventTime_fontStyle: string | null;
  @Prop(Number) readonly subheaderEventTime_lineHeight: number;
  @Prop(String) readonly subheaderEventTime_textAlign: string | null;

  @Prop(String) readonly clockTime_fontFamily: string | null;
  @Prop(Number) readonly clockTime_fontWeight: number;
  @Prop(String) readonly clockTime_textColor: string | null;
  @Prop(Number) readonly clockTime_fontSize: number;
  @Prop(Number) readonly clockTime_letterSpacing: number;
  @Prop(String) readonly clockTime_textTransform: string | null;
  @Prop(String) readonly clockTime_textDecoration: string | null;
  @Prop(String) readonly clockTime_fontStyle: string | null;
  @Prop(Number) readonly clockTime_lineHeight: number;
  @Prop(String) readonly clockTime_textAlign: string | null;

  // defaultData = dummyCalendarData;
  updateFrequencySeconds = 1;
  dummyTime = 0;

  mounted() {
    setInterval(() => {
      this.dummyTime++;
    }, this.updateFrequencySeconds * 1000);
  }

  /*
DESIGN NOTES:
- main times look like "Now - 11:30AM", or "10:00AM - 11:30AM"
- submain times look the same
- Question about clock view
- Question about location title (how to pull default? Look through events until finds one?)
- Question about all/multi day events
- Question about overlapping events/"overbooking"

Easy to be "done" if we assume:
1. calendar only contains events for one location
2. there is no overbooking

Enlarge clock, handle multiday/allday formatting (?), show location input

We could try a dropdown/filter for location, but it feels like a data connection thing? 
like when choose DC, have option to use entire calendar or filter by location
*/

  // Issue: if cal has no events, data shows up as null
  // note: need way to start header/subheader start at same x-position
  // height:85% is so ugly
  // maybe we show "available" with clock?
  // TODO: Update as time advances
  // Maybe we do like previous titles...try to grab location from data, but let user edit?
  // NOTE: Easiest for us would be force them to apply one calendar (assume all events are at same location)
  // Not even clear that should check for overlaps with today...No, that's in the dseign
  // TODO (?): Manually ignore overlapping events somehow

  // Return events that overlap with today,
  // and end after current time,
  // sorted by start time.
  // This way, eventsForCalendar[0] should be headerEvent
  // and eventsForCalendar[1] should be subheaderEvent
  get eventsForCalendar(): CalendarEvent[] {
    const data = this.data;

    const start = makeHourTodayDate(0);
    const end = makeHourTodayDate(0, 1);
    const interval = Interval.fromDateTimes(start, end);

    return (
      data
        .map((row: (NodeData | NodeSetData)[]) => new CalendarEvent(row))
        // .filter(ev => ev.start.hour !== 0) // Why was I doing this??
        .filter((ev) => interval.overlaps(ev.interval))
        .filter((ev) => DateTime.now() < ev.end)
        .sort((a, b) => {
          return a.start.diff(b.start).milliseconds;
        })
    );
  }

  get locationTitle() {
    for (let i = 0; i < this.eventsForCalendar.length; i++) {
      const ev = this.eventsForCalendar[i];
      if (ev.location) {
        return ev.location;
      }
    }

    return "Location";
  }

  get now() {
    return this.formatTime(DateTime.now());
  }

  /*
  If an event is in progress, show that.
  If not, show next event (starts next).
  If none, show clock (?)
  */
  get headerEvent(): any {
    // console.log("header..", this.eventsForCalendar);
    return this.eventsForCalendar[0] ?? { summary: "" };
  }

  /*
  Show event that starts next after headerEvent, if one exists,
  unless event is all day/multi day. (We shouldn't technically have to handle that case...only happens if overbooked)
  Well...if multi day and ends before next event.....Hmmm.....
  */
  get subheaderEvent() {
    return this.eventsForCalendar[1];
  }

  formatTime(dt: DateTime) {
    const options: any = {
      hour: "numeric",
      minute: "numeric",
      hour12: !this.use24Hour,
    };
    return dt.toLocaleString(options);
  }

  get headerIsInProgress() {
    if (!("start" in this.headerEvent)) return false;
    return this.headerEvent.start < DateTime.now();
  }

  get headerProgressStyle() {
    if (!("start" in this.headerEvent)) return { width: "0" };
    const elapsed = DateTime.now().diff(this.headerEvent.start);
    const total = this.headerEvent.end.diff(this.headerEvent.start);
    const percent = (elapsed.milliseconds * 100) / total.milliseconds;
    return { width: `${percent}%`, backgroundColor: this.progressBarFillColor };
  }
}
</script>
