import { Vue, Component } from "vue-property-decorator";
import { SchemaNode } from "@/types/data";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { useAppEditorStore } from "@/stores/appEditor";
import { logger } from "@core/logger";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({})
export default class RemapFilterConditionColumnsMixin extends Vue {
  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get nodesRequiringRemap() {
    return this.connectionEditor.nodesRequiringRemap;
  }

  get existingConnection() {
    return this.connectionEditor.connection;
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidgetBinding() {
    return this.appEditor.selectedWidgetBinding;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get parents() {
    return this.appEditor.parents;
  }

  get mustRemapNodes() {
    /**
     * We only want to add the remap step if widget is repeater,
     * ( Could specify further to restrict to only non-static child widgets if needed )
     * or a graph, if bound to data that is referenced by a filter or condition.
     */
    const isRepeaterWithChildren =
      this.selectedWidget?.type === "Repeater" &&
      this.selectedWidget?.wid in this.parents;

    let mustRemap = isRepeaterWithChildren;

    if (
      this.nodesRequiringRemap?.length > 0 &&
      !this.selectedWidget?.type.includes("Calendar")
    ) {
      mustRemap = true;
    }
    return mustRemap;
  }

  get logicNodeReferencesForSelectedWidget() {
    return (this.existingConnection?.logicNodeReferences || []).filter(
      (ref) => {
        if (ref.type === "Filter") {
          return ref.logicUuid === this.selectedWidgetBinding?.filterUuid;
        } else if (ref.type === "Condition") {
          // TODO

          if (this.appEditor.selectedWidget === undefined) {
            logger.track(
              "RemapFilterConditionColumnsMixin: selectedWidget is undefined"
            );
            return [];
          }

          const selectedWidgetsChildrenWidgetIds =
            this.parents[this.appEditor.selectedWidget.wid];

          const conditionGroupsForChildren =
            useConditionGroupsStore().conditionGroups.filter((cg) =>
              cg.widgets.some((wg) =>
                selectedWidgetsChildrenWidgetIds.includes(wg.widgetId)
              )
            );
          return conditionGroupsForChildren.some((cg) =>
            cg.conditions.some((condition) => condition.uuid === ref.logicUuid)
          );

          // return false;
        }
      }
    );
  }

  findAndSetNodesRequiringRemap() {
    const nodesRequiringRemap = this.logicNodeReferencesForSelectedWidget
      .flatMap((ref) => ref.nodeUuids)
      .map((uuid) =>
        this.existingConnection?.nodeSets[0].nodes?.find((n) => n.uuid === uuid)
      )
      .filter((node) => node !== undefined) as SchemaNode[];

    this.connectionEditor.nodesRequiringRemap = nodesRequiringRemap;
    this.connectionEditor.logicRefsRequiringRemap =
      this.logicNodeReferencesForSelectedWidget.map((ref) => ref.logicUuid);
  }

  mounted() {
    if (this.existingConnection === null && this.$route.params.connectionUuid) {
      this.connectionEditor.getConnection({
        appUuid: this.$route.params.id,
        dataConnectionUuid: this.$route.params.connectionUuid,
      });
    }
  }
}
