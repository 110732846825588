import { LogicOperandType } from "@/types/logic";

/**
 * This represents the operand type presented to users in the UI.
 */
export type OperandSelectType =
  | "Column"
  | "Scalar"
  | "DateTime"
  | "Static"
  | "QueryString";

/**
 * Controls the type of data the user needs to provide via the
 * 'value' input control for the right operand.
 */
export type OperandInputValueType =
  | "String"
  | "Boolean"
  | "Number"
  | "Date"
  | "Time"
  | "DateTime"
  | "DayOfWeek"
  | "Month";

/**
 * Takes the UI operand type and returns the appropriate LogicOperandType
 */
export const getOperandTypeFromSelectType = (
  type: OperandSelectType
): LogicOperandType => {
  switch (type) {
    case "Column":
      return "ConnectionColumn";
    case "Scalar":
      return "ConnectionScalar";
    case "DateTime":
      return "CurrentDate";
    default:
      return type as LogicOperandType;
  }
};

/**
 * This takes the operand type from the backend and returns the
 * appropriate operand type for the UI.
 */
export const getSelectTypeFromOperandType = (
  type: LogicOperandType | undefined
): OperandSelectType | undefined => {
  if (typeof type === "undefined") {
    return undefined;
  }
  if (type.startsWith("Current")) {
    return "DateTime";
  }
  switch (type) {
    case "ConnectionColumn":
      return "Column";
    case "ConnectionScalar":
      return "Scalar";
    case "Static":
      return "Static";
    case "QueryString":
      return "QueryString";
  }
};
