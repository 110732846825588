<template>
  <div class="divide-y">
    <CollapsePanel :shouldListenForOpenCommand="true">
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <DataSetChooser />
      <div class="py-3 px-4">
        <div class="mb-3">
          <div
            class="text-sm text-gray-500 ml-2"
            v-t="'graph.labelColumn'"
          ></div>
          <EditorSelectMenu
            :value="model.yAxisColumnId"
            :options="yColumnOptions"
            @input="apply({ yAxisColumnId: $event })"
          />
        </div>
        <div
          v-if="yColumnOptions.length < 1"
          v-t="'graph.yAxisColumnError'"
        ></div>
        <div class="my-3">
          <div
            class="text-sm text-gray-500 ml-2"
            v-t="'graph.valueColumn'"
          ></div>
          <EditorSelectMenu
            :value="model.xAxisColumnId"
            :options="xColumnOptions"
            @input="apply({ xAxisColumnId: $event })"
          />
        </div>
        <div
          v-if="xColumnOptions.length < 1"
          v-t="'graph.xAxisColumnError'"
        ></div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.pie'"></div>
      </template>
      <div class="divide-y">
        <div class="pb-5 px-4">
          <div
            class="slice-color wrap mb-2"
            :key="index"
            v-for="(item, index) in labels"
          >
            <div v-if="item">
              <ColorInputBar
                class="mb-4 pl-3"
                :value="colorArray[index]"
                :gradientEnabled="false"
                :bindable="false"
                @input="preview({ colors: updateColor($event, index) })"
                @change="apply({ colors: updateColor($event, index) })"
                ><span class="text-sm" v-t="item"></span
              ></ColorInputBar>
            </div>
          </div>
          <ColorInputBar
            :value="model.lineColor"
            :gradientEnabled="false"
            @input="preview({ lineColor: $event })"
            @change="apply({ lineColor: $event })"
            propName="lineColor"
            ><span class="text-sm" v-t="'border'"></span
          ></ColorInputBar>
          <div class="px-3 pb-5">
            <div class="text-sm text-gray-500 mt-3" v-t="'borderWidth'"></div>
            <SliderInput
              controls
              :value="model.lineThickness"
              :min="0"
              :max="20"
              :step="1"
              @input="preview({ lineThickness: $event })"
              @change="apply({ lineThickness: $event })"
            />
          </div>
        </div>
        <div>
          <div class="py-3 px-3">
            <ToggleInput
              :value="model.showValues"
              @input="apply({ showValues: $event })"
              ><span class="text-sm" v-t="'graph.showValues'"></span>
            </ToggleInput>
            <ToggleInput
              v-if="model.showValues"
              class="mt-3"
              :value="model.valuesAsPercent"
              @input="apply({ valuesAsPercent: $event })"
              ><span class="text-sm" v-t="'graph.valuesAsPercent'"></span>
            </ToggleInput>
          </div>
          <GroupTextFormatEditor
            v-if="model.showValues"
            :model="model"
            element="value"
            @update="apply($event)"
            :disableLineHeight="true"
            :disableAlignment="true"
            :disableLetterSpacing="true"
            :disableTextTransform="true"
          />
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.legendOptions'"></div>
      </template>
      <div class="px-3">
        <ToggleInput
          :value="model.showLegend"
          @input="apply({ showLegend: $event })"
          ><span class="text-sm" v-t="'graph.showLegend'"></span>
        </ToggleInput>
      </div>
      <GroupTextFormatEditor
        class="mt-2"
        v-if="model.showLegend"
        :model="model"
        element="legend"
        @update="apply($event)"
        :disableLineHeight="true"
        :disableAlignment="true"
        :disableLetterSpacing="true"
      />
      <div class="px-4 pb-8">
        <div
          class="text-gray-500 text-sm mt-3 ml-2"
          v-t="'position'"
          v-if="model.showLegend"
        ></div>
        <EditorSelectMenu
          v-if="model.showLegend"
          :value="model.legendPosition"
          :options="legendOptions"
          @input="apply({ legendPosition: $event })"
        />
        <div class="px-3">
          <div
            class="text-gray-500 text-sm mt-3"
            v-t="'margin'"
            v-if="model.showLegend"
          ></div>
          <SliderInput
            controls
            v-if="model.showLegend"
            :value="model.legendMargin"
            :min="-100"
            :max="100"
            :step="1"
            @input="preview({ legendMargin: $event })"
            @change="apply({ legendMargin: $event })"
          />
        </div>
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import SliderInput from "@/components/inputs/SliderInput.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import { PieGraphOptions } from "./PieGraphOptions";
import DataSetChooser from "@/components/widgets/DataSetChooser.vue";
import { NodeData } from "@/types/data";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CollapsePanel,
    ColorInputBar,
    SliderInput,
    ToggleInput,
    EditorSelectMenu,
    BaseWidgetEditor,
    GroupTextFormatEditor,
    DataSetChooser,
  },
})
export default class PieGraphEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as PieGraphOptions;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  get colorArray() {
    return this.model.colors.slice(0, this.dataSet.length);
  }

  updateColor(color: string, index: number) {
    let newColors = this.colorArray.slice();
    newColors[index] = color;
    return newColors;
  }

  get dataBinding() {
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.model.wid,
      bindingType: "DataSet",
    });

    return bindings.length > 0 ? bindings[0] : undefined;
  }

  get dataSet(): NodeData[][] {
    return (this.widgetData[this.model.wid]?.[0]?.data as NodeData[][]) ?? [];
  }

  get dataColumns(): NodeData[] {
    return this.dataSet.length > 0 ? this.dataSet[0] : [];
  }

  get labels() {
    if (this.dataSet.length > 0) {
      return this.dataSet.map((row) => {
        return row.find((c) => c.uuid === this.model.yAxisColumnId)
          ?.formattedValue as string;
      });
    }
    return [] as string[];
  }

  get yColumnOptions() {
    return this.dataColumns
      .filter(
        (c) =>
          c.dataType === "String" ||
          c.dataType === "Date" ||
          c.dataType === "Time" ||
          c.dataType === "DateTime"
      )
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get xColumnOptions() {
    return this.dataColumns
      .filter((c) => c.dataType === "Number" && c.query != "__sf_index__")
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get legendOptions() {
    return [
      { label: this.$t("align.top"), value: "Top" },
      { label: this.$t("align.bottom"), value: "Bottom" },
      { label: this.$t("align.left"), value: "Left" },
      { label: this.$t("align.right"), value: "Right" },
    ];
  }
}
</script>
