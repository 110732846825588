import { defineStore } from "pinia";

import {
  DataConnection,
  NodeSetData,
  NodeData,
  ConnectionDataResponse,
  ModerationDataNode,
  ModerationDataQueryResult,
  ManualDataRow,
  SchemaNode,
  SchemaType,
} from "@/types/data";
import { api, BackendError } from "@/api/backend";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { getComponentName } from "@/components/data/dataNodeComponents";
import { useConnectionsStore } from "@/stores/connections";
import uniqWith from "lodash.uniqwith";
import {
  parseDataRows,
  parseDataSchema,
  parseModeratedRows,
} from "@/util/dataUtils";
import { useEditableDataStore } from "@/stores/editableData";
import Vue from "vue";

export interface ConnectionDataState {
  connectionData: NodeSetData | NodeData | null;
  isLoadingData: boolean;
  errorLoadingData: string | null;
  recordIndex: number;
  moderationDataRows: ModerationDataNode[];
  isModerationKeyError: boolean;

  /**
   * Used in ReadonlyData.vue We need to refactor to a Pinia store
   */
  readOnlyTableData: NodeData[][] | null;
  /**
   * Used in ReadonlyData.vue We need to refactor to a Pinia store
   */
  readOnlyTreeData: NodeSetData | null;

  /**
   * Used in ReadonlyData.vue We need to refactor to a Pinia store
   */
  readOnlySchemaType: SchemaType | null;
}

export const useConnectionDataStore = defineStore("connectionData", {
  state: (): ConnectionDataState => {
    return {
      connectionData: null,
      isLoadingData: false,
      isModerationKeyError: false,
      errorLoadingData: null,
      recordIndex: 0,
      moderationDataRows: [],
      readOnlySchemaType: null,
      readOnlyTableData: null,
      readOnlyTreeData: null,
    };
  },
  getters: {
    connectionDataSize(): number {
      return this.connectionData === null
        ? 0
        : (this.connectionData as NodeSetData).children?.length || 0;
    },

    connectionIsTree(): boolean {
      if (!this.connectionData) return false;

      return (
        this.connectionData?.kind === "NodeObject" ||
        (this.connectionData as any).children?.[0]?.kind === "NodeObject"
      );
    },

    isEmpty(): boolean {
      if (!this.connectionData) return true;
      if (!("children" in this.connectionData)) {
        return true;
      }
      if (this.connectionData.children === null) return true;
      return this.connectionData.children?.length === 0;
    },

    dataRow(): any[] {
      let columns: NodeData[] = [];

      if (!this.connectionData) return [];
      if (!("children" in this.connectionData)) return [];
      if (this.connectionData?.children?.length > 0) {
        columns = (this.connectionData as NodeSetData)?.children?.[
          this.recordIndex
        ] as NodeData[];
      }

      // Possible to get here... even though should detect as tree....
      if (!Array.isArray(columns)) return [];

      const selectedConnection = useConnectionEditorStore().connection;

      // NOTE: copying from DataSetExplorer this pattern of passing nodeSetUuid, nodeUuid and connectionUuid here.
      return (columns || []).map((node) => {
        return {
          ...node,
          connectionUuid: selectedConnection?.uuid,
          nodeSetUuid: selectedConnection?.nodeSets?.[0]?.uuid,
          nodeUuid: node.uuid,
          component: getComponentName(node.dataType),
        };
      });
    },

    moderationRow(): ModerationDataNode {
      // Hopefully we can rely on index..
      return this.moderationDataRows[this.recordIndex];
    },
  },

  actions: {
    setReadOnlyData(payload: { schemaType: SchemaType; data: unknown }) {
      this.readOnlySchemaType = payload.schemaType;

      if (payload.schemaType === "Tabular") {
        this.readOnlyTableData = payload.data as NodeData[][];
      }

      if (payload.schemaType === "Tree") {
        this.readOnlyTreeData = payload.data as NodeSetData;
      }
    },

    /**
     * Only used by initializeConnection
     * Need to wrap data up like {data: result.data} because widget data is organized by widgetId, and then property
     * And we can assume the property name is "data"
     *
     * NOTE that this data is filtered by moderation status, if moderation is active.
     * To get unfiltered moderation data for moderated connections, must use getModerationData.
     */
    async loadData(payload: { connectionId: string }) {
      const { connectionId } = payload;

      return api
        .get<ConnectionDataResponse>(`dataconnection/${connectionId}/data`)
        .then((result) => {
          const connectionStore = useConnectionEditorStore();
          connectionStore.setLastSourceUpdate(result.lastUpdated);

          return (result.data as NodeSetData).children as NodeData[][];
        });
    },

    /**
     * Loads data for read-only view in data manager.
     *
     * All the other methods in this jungle are loading data for collections.
     * We'll use this until we convert to the one true faith, Piniaism
     */
    async loadReadOnlyData(args: { uuid: string }) {
      // console.log("loadReadOnlyData", args.uuid);
      return api
        .get<ConnectionDataResponse>(`dataconnection/${args.uuid}/data`)
        .then((result) => {
          const dataResponse = result.data;
          let schemaType: SchemaType | null = null;

          if (dataResponse.kind === "NodeObject") {
            schemaType = "Tree";
          } else if (dataResponse.kind === "NodeSet") {
            const isTable = (dataResponse as NodeSetData).children.some((c) =>
              Array.isArray(c)
            );

            schemaType = isTable ? "Tabular" : "Tree";
          }

          if (schemaType === "Tabular") {
            this.setReadOnlyData({
              schemaType,
              data: (dataResponse as NodeSetData).children as NodeData[][],
            });
          }

          if (schemaType === "Tree") {
            this.setReadOnlyData({
              schemaType,
              data: dataResponse as NodeSetData,
            });
          }
        });
    },

    async initializeConnection(connection: DataConnection) {
      const connectionsStore = useConnectionsStore();
      connectionsStore.addConnection(connection);

      this.loadData({
        connectionId: connection.uuid,
      });
    },

    /**
     * Fetches connection data for collection connections.
     * Will grab moderation data instead if the connection is moderated.
     * (This ensures that *all* connection data is returned, even for moderated connections that have some rows removed.)
     *
     */
    async refreshConnectionData(args: {
      connectionId: string;
      isModerated: boolean;
      updateSchema?: boolean;
    }) {
      const { connectionId, isModerated, updateSchema } = args;
      let rows: ManualDataRow[] = [];
      let schema: SchemaNode[] = [];

      if (isModerated) {
        const dataSet = await this.getModerationData({
          dcUuid: connectionId,
        });
        rows = parseModeratedRows(dataSet);
      } else {
        const fetchPayload: any = {
          connectionId,
        };

        const dataSet = await this.loadData(fetchPayload);
        schema = parseDataSchema(dataSet);

        if (!dataSet) {
          return { rows: null, schema };
        }
        rows = parseDataRows(dataSet);
      }

      const editableDataStore = useEditableDataStore();
      editableDataStore.setRowsInitialState(rows);

      if (updateSchema && schema.length > 0) {
        const connectionStore = useConnectionEditorStore();
        connectionStore.schema = schema;
      }
      return { rows };
    },

    openNewConnection(connection: DataConnection) {
      const connectionEditorStore = useConnectionEditorStore();
      const connectionsStore = useConnectionsStore();
      connectionEditorStore.connection = connection;
      connectionsStore.connections = uniqWith(
        [connection].concat(connectionsStore.connections),
        (a, b) => a.uuid === b.uuid
      );
      return this.fetchConnectionData({
        connectionId: connection.uuid,
      });
    },

    async fetchConnectionData(payload: { connectionId: string }) {
      const { connectionId } = payload;
      const { moderationMode, connection } = useConnectionEditorStore();
      this.isLoadingData = true;

      if (moderationMode !== null) {
        await this.getModerationData({
          dcUuid: connection?.uuid || "",
        })
          .then((rows) => {
            const children = rows.map((row: ModerationDataNode) => row.data);
            if (!this.connectionData) this.connectionData = {} as NodeData;
            Vue.set(this.connectionData, "children", children);
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      }
      return api
        .get<ConnectionDataResponse>(`dataconnection/${connectionId}/data`)
        .then((result) => {
          if (moderationMode === null) {
            this.connectionData = result.data;
          } else {
            // We need to set uuid here in order for createDynamicWidget to be able to pick it up
            Vue.set(this.connectionData as NodeData, "uuid", result.data.uuid);
          }
        })
        .finally(() => {
          this.isLoadingData = false;
        });
    },

    async getModerationData(args: { dcUuid: string }) {
      this.errorLoadingData = null;

      return api
        .get<ModerationDataQueryResult>(
          `dataconnection/${args.dcUuid}/moderation`
        )
        .then((res) => {
          const nodes = res?.data?.data?.moderationDataNodes;
          this.moderationDataRows = nodes;
          return nodes;
        })
        .catch((err) => {
          // TODO: translate
          this.errorLoadingData = "dataModeration.getModDataError";
          return Promise.reject(err);
        });
    },

    async updateModerationData(args: {
      dcUuid: string;
      data: ModerationDataNode[];
    }) {
      this.errorLoadingData = null;

      const payload = {
        moderatedData: {
          data: {
            moderationDataNodes: args.data,
          },
        },
      };

      return api
        .put(`dataconnection/${args.dcUuid}/moderation`, payload)
        .then(() => {
          this.moderationDataRows = args.data;
        })
        .catch((err: BackendError[]) => {
          // TODO: translate
          this.errorLoadingData = "dataModeration.updateModDataError";

          if (err[0].code === "nonUniquePrimaryKeys") {
            err[0].code = `dataModeration.${err[0].code}`;
            throw err[0];
          }

          throw err;
        });
    },
  },
});
