<template>
  <div>
    <div
      class="text-gray-400 text-[11px] mb-1"
      v-text="helpText"
      :class="{ invisible: dragging }"
    ></div>

    <div
      :data-condition-button="uuid"
      class="relative text-sm flex items-center h-10 border rounded-md cursor-pointer hover:bg-gray-500/10"
      :class="{
        'border-purple-600 ring-1 ring-purple-600': selected,
        'border-gray-500': !selected,
      }"
      @click="$emit('select', uuid)"
    >
      <div
        v-if="draggable"
        drag-handle
        class="flex items-center justify-center w-8 h-8 shrink-0 cursor-[grab]"
      >
        <svg
          class="w-2"
          viewBox="0 0 18 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="currentColor">
            <circle cx="14.5" cy="25" r="3" />
            <circle cx="14.5" cy="14" r="3" />
            <circle cx="14.5" cy="3" r="3" />
            <circle cx="3.5" cy="25" r="3" />
            <circle cx="3.5" cy="14" r="3" />
            <circle cx="3.5" cy="3" r="3" />
          </g>
        </svg>
      </div>

      <div
        class="flex-grow text-gray-700 leading-tight break-words line-clamp-2"
        :class="{ 'text-center': isDefaultCondition, 'pl-3': !draggable }"
        v-text="name"
      ></div>

      <button
        v-if="!isDefaultCondition"
        @click.stop="toggleMenu"
        type="button"
        class="flex items-center justify-center pr-2 w-6 h-8 shrink-0"
      >
        <Icon class="block w-4 h-4" name="ChevronDown" />
      </button>

      <transition name="fade">
        <div
          click.stop
          ref="menu"
          v-if="menuOpen"
          class="transition-transform transition-opacity z-10 shadow-lg absolute top-full right-0 transform translate-y-1.5 rounded-md border border-gray-300 bg-white w-30 py-px"
        >
          <div
            @click.stop="$emit('edit', uuid)"
            class="text-xs flex items-center px-2.5 py-1.5 space-x-1.5 hover:bg-gray-500/10"
          >
            <svg
              class="w-3 h-3"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7913 0.36984C17.0282 0.133031 17.3495 0 17.6844 0C18.0194 0 18.3406 0.133031 18.5775 0.36984L23.6302 5.42253C23.867 5.65941 24 5.98064 24 6.31559C24 6.65054 23.867 6.97177 23.6302 7.20865L7.20892 23.6299C6.97209 23.8668 6.65085 23.9999 6.31586 24H1.26317C0.928158 24 0.606865 23.8669 0.369974 23.63C0.133084 23.3931 0 23.0718 0 22.7368V17.6841C7.15428e-05 17.3492 0.133202 17.0279 0.370109 16.7911L13.0018 4.15936L16.7913 0.36984ZM13.8949 6.83854L2.52634 18.2071V21.4737H5.79291L17.1615 10.1051L13.8949 6.83854ZM18.9476 8.31898L20.951 6.31559L17.6844 3.04903L15.681 5.05242L18.9476 8.31898Z"
                fill="currentColor"
              />
            </svg>

            <div v-t="'edit'"></div>
          </div>
          <div
            @click.stop="$emit('remove', uuid)"
            class="text-xs flex items-center px-2.5 py-1.5 space-x-1.5 hover:bg-gray-500/10"
          >
            <svg
              class="w-3 h-3"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.4C9.45392 2.4 7.01212 3.41143 5.21178 5.21178C3.41143 7.01212 2.4 9.45392 2.4 12C2.4 14.5461 3.41143 16.9879 5.21178 18.7882C7.01212 20.5886 9.45392 21.6 12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01212 18.7882 5.21178C16.9879 3.41143 14.5461 2.4 12 2.4ZM0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24C5.3724 24 0 18.6276 0 12ZM6 12C6 11.6817 6.12643 11.3765 6.35147 11.1515C6.57652 10.9264 6.88174 10.8 7.2 10.8H16.8C17.1183 10.8 17.4235 10.9264 17.6485 11.1515C17.8736 11.3765 18 11.6817 18 12C18 12.3183 17.8736 12.6235 17.6485 12.8485C17.4235 13.0736 17.1183 13.2 16.8 13.2H7.2C6.88174 13.2 6.57652 13.0736 6.35147 12.8485C6.12643 12.6235 6 12.3183 6 12Z"
                fill="currentColor"
              />
            </svg>

            <div v-t="'remove'"></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { DEFAULT_CONDITION_ID } from "@/constants";
import { Component, Prop, Vue } from "vue-property-decorator";

import Icon from "@/components/icons/Icon.vue";
@Component({
  components: { Icon },
})
export default class ConditionButton extends Vue {
  @Prop(String) name: string;
  @Prop(Number) index: number;
  @Prop(String) uuid: string;
  @Prop(Boolean) selected: boolean;
  @Prop(Boolean) draggable: boolean;
  @Prop(Boolean) dragging: boolean;

  menuOpen = false;

  get helpText() {
    if (this.uuid === DEFAULT_CONDITION_ID) {
      return this.$t("ConditionsButton.hintDefault").toString();
    }
    if (this.index === 0) {
      return this.$t("ConditionsButton.hintFirst").toString();
    }

    if (this.index > 0) {
      return this.$t("ConditionsButton.hintMiddle").toString();
    }

    return "";
  }

  get isDefaultCondition() {
    return this.uuid === DEFAULT_CONDITION_ID;
  }

  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.cleanUp();
      return;
    }

    this.menuOpen = true;
    window.addEventListener("keydown", this.handleEscapeKey);
    document.addEventListener("click", this.toggleMenu, { once: true });
  }

  handleEscapeKey(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.menuOpen = false;
    }
  }

  beforeDestroy() {
    this.cleanUp();
  }

  cleanUp() {
    window.removeEventListener("keydown", this.handleEscapeKey);
  }
}
</script>

<style scoped lang="postcss">
.fade-enter,
.fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
