import { StyleValue } from "vue/types/jsx";
import { DefaultShadowOptions } from "./components/widgets/ShadowOptions";
import { TextOptions } from "./components/widgets/Text/TextOptions";
import { VerticalAlignValue } from "./components/widgets/TextStyleOptions";
import { contentToHtml } from "./text";
import { Size } from "./types";

const toInt = (value: string | null): number => {
  return value === null ? 0 : parseInt(value, 10);
};

const measureContentSize = (el: HTMLElement | null): Size => {
  if (el) {
    const s = getComputedStyle(el);
    if (s) {
      const padX = toInt(s.paddingLeft) + toInt(s.paddingRight);
      const padY = toInt(s.paddingTop) + toInt(s.paddingBottom);
      return {
        w: el.clientWidth - padX,
        h: el.clientHeight - padY,
      };
    }
  }
  return { w: 0, h: 0 };
};

const isTooBig = (el: HTMLElement) => {
  const parent = el.parentElement;
  const width = el.scrollWidth > el.offsetWidth;
  const height = el.scrollHeight > el.offsetHeight;
  const p = parent && el.clientHeight > parent.clientHeight + 1;
  return width || height || p;
};

export const textFit = (el: HTMLElement, minFontSize = 10) => {
  const style = window.getComputedStyle(el);
  const display = style.display;

  // fill available space in the parent element
  el.style.display = "block";
  el.style.width = "100%";
  el.style.height = "100%";

  let fontSize = parseInt(style.fontSize || "", 10);
  const startSize = fontSize;

  while (isTooBig(el) && fontSize >= minFontSize) {
    fontSize--;
    el.style.fontSize = `${fontSize}px`;
  }

  // Reset styles
  el.style.width = "auto";
  el.style.height = "auto";
  el.style.display = display;

  return { startSize, endSize: fontSize };
};

export const fitTextLine = (el: HTMLElement, area?: Size) => {
  if (!area) {
    area = measureContentSize(el.parentElement);
  }
  const es = getComputedStyle(el);
  const startSize = parseInt(es.fontSize || "", 10);
  const scale = Math.max(el.clientWidth / area.w);
  const newSize = startSize * (1 / scale);
  el.style.fontSize = `${newSize.toFixed(2)}px`;
  return { startSize, endSize: newSize, scale };
};

export const fitTextLines = (els: HTMLElement[]) => {
  if (els.length > 0) {
    let parent = els[0].parentElement;
    const area = measureContentSize(parent);
    let h = 0;
    let sizes: number[] = [];
    els.forEach((el) => {
      sizes.push(fitTextLine(el, area).endSize);
      h += el.clientHeight;
    });

    if (h > area.h) {
      const scale = Math.max(area.h / h);
      els.forEach((el, index) => {
        let newSize = sizes[index] * scale;
        el.style.fontSize = `${newSize.toFixed(2)}px`;
      });
    }
  }
};

const alignContent = (value: VerticalAlignValue | undefined) => {
  switch (value) {
    case "top":
      return "flex-start";
    case "middle":
      return "center";
    case "bottom":
      return "flex-end";
    default:
      return "flex-start";
  }
};

export const getTextStyleCss = (settings: TextOptions) => {
  const decorations = [];
  if (settings.textUnderline) {
    decorations.push("underline");
  }
  if (settings.textStrike) {
    decorations.push("line-through");
  }

  const style: StyleValue = {
    display: "flex",
    flexDirection: "column",
    textAlign: settings.alignHorizontal,
    justifyContent: alignContent(settings.alignVertical),
    fontFamily: settings.fontFamily,
    fontWeight: settings.fontWeight,
    fontStyle: settings.fontStyle,
    fontSize: `${settings.fontSize}px`,
    textDecoration: decorations.join(" "),
    textTransform: settings.textTransform,
    letterSpacing: `${settings.letterSpacing}pt`,
    lineHeight: settings.lineHeight,
    color: settings.textColor,
    opacity: settings.opacity,
  };

  if (settings.shadowDisplay) {
    const { shadowX, shadowY, shadowBlur, shadowColor } = DefaultShadowOptions;
    const x = isNaN(settings.shadowX) ? shadowX : settings.shadowX;
    const y = isNaN(settings.shadowY) ? shadowY : settings.shadowY;
    const blur = isNaN(settings.shadowBlur) ? shadowBlur : settings.shadowBlur;
    const color = settings.shadowColor ?? shadowColor;
    style.filter = `drop-shadow(${x}px ${y}px ${blur}px ${color})`;
  }

  return style;
};

export const measureText = (
  settings: TextOptions,
  compiledHtml?: string,
  draggedNode?: boolean
): Size => {
  const htmlContent =
    typeof compiledHtml === "string"
      ? compiledHtml
      : contentToHtml(settings.content);

  const div = document.createElement("div");
  div.innerHTML = htmlContent;
  const styles = getTextStyleCss(settings);
  Object.keys(styles).forEach((key: string) => {
    (div.style as any)[key] = (styles as any)[key];
  });

  div.style.position = "fixed";
  div.style.top = "0";
  div.style.left = "0";

  // Trying to make consistent with tip-tap....
  // But thinking may be better to just NOT break words in editor.
  // div.style.wordWrap = "break-word";

  // if (constrainAxis === "x" && constrainValue > 0) {
  //   div.style.width = `${constrainValue}px`;
  // }

  // Must set width to get it to work:
  if (!draggedNode) {
    div.style.width = `${settings.w * settings.scaleX}px`;
  }

  // if (constrainAxis === "y" && constrainValue > 0) {
  //   div.style.height = `${constrainValue}px`;
  // }
  document.body.appendChild(div);

  const bounds = div.getBoundingClientRect();
  document.body.removeChild(div);
  return {
    w: bounds.width,
    h: bounds.height,
  };
};
