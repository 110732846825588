<template>
  <div class="px-4">
    <slot />
    Grids
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class GridsMenu extends Vue {}
</script>

<style scoped></style>
