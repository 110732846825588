<template>
  <button
    :type="type"
    :disabled="disabled"
    class="primary-purple text-white border select-none dark-form-focus"
    :class="classes"
    @click="$emit('click')"
    ref="button"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PrimaryButton extends Vue {
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: "button" }) type: "submit" | "button";
  @Prop(String) size: "xs" | "sm" | "base" | "lg" | "xl" | "2xl";

  get classes() {
    let classes = this.sizeClasses;
    if (this.disabled) {
      classes += " cursor-not-allowed opacity-50";
    } else {
      classes +=
        "  hover:bg-app-purpleLight focus:outline focus:outline-gray-100";
    }
    return classes;
  }

  get sizeClasses() {
    switch (this.size) {
      case "xs":
        return "text-xs px-2 py-1 rounded-xs";
      case "sm":
        return "text-sm px-3 py-1 rounded-sm";
      case "lg":
        return "text-lg px-5 py-3 rounded-lg";
      case "xl":
        return "text-xl px-6 py-4 rounded-xl";
      case "2xl":
        return "text-2xl px-7 py-5 rounded-xl";
      default:
        return "text-md px-4 py-2 rounded";
    }
  }
}
</script>

<style lang="postcss" scoped>
/**
 * Temporary until Pete's design system is ready
 */
.primary-purple {
  background-color: #9747ff;
  border-color: #9747ff;
}
</style>
