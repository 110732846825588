import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface AnalogClockOptions extends ComponentOptions, TransformOptions {
  timezone: string | null;
  usePlayerTimezone: boolean;
  clock_textColor: string;
  clock_fontFamily: string;
  clock_fontSize: number;
  clock_fontWeight: number;
  clock_fontStyle: string;
  secondsColor: string;
  faceColor: string;
  strokeColor: string;
  showSeconds: boolean;
  numberInset: number;
}

export const defaultTextOptions = {
  clock_textColor: "#1d283a",
  clock_fontFamily: "Montserrat",
  clock_fontSize: 48,
  clock_fontWeight: 700,
  clock_fontStyle: "normal",
};

const defaultOptions: Partial<AnalogClockOptions> = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...defaultTextOptions,
  timezone: null,
  usePlayerTimezone: true,
  secondsColor: "#CC0000",
  faceColor: "#FFFFFF",
  strokeColor: "#000000",
  showSeconds: true,
  numberInset: 50,
};

const CreateAnalogClock = (options: Partial<AnalogClockOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "AnalogClock",
    wid: makeId(6),
    lockAspect: true,
    name: DEFAULT_LAYER_NAMES.AnalogClock,
  });
};

export default CreateAnalogClock;
