import { BASE_PARENT_ID } from "@/constants";
import { WidgetType } from "@/types";

export interface ComponentOptions {
  wid: string;
  parentId: string;
  locked: boolean;
  type: WidgetType;
  name?: string;
}

export const DefaultComponentOptions = {
  parentId: BASE_PARENT_ID,
  locked: false,
};
