<template>
  <div class="flex flex-col space-y-6">
    <!-- Name input: -->
    <div class="flex flex-col pl-[0.25rem]">
      <h3
        class="text-lg font-bold"
        v-t="'dataManager.settings.name.namePrompt'"
      ></h3>
      <input
        type="text"
        class="text-lg rounded-lg w-[50%] mt-1 px-2 py-1 text-black"
        placeholder="name"
        v-model="newName"
        :class="[showNameRequiredError ? 'border-3 border-errors' : '']"
      />

      <div
        v-if="showNameRequiredError"
        class="validation-error self-start px-2 py-1 mt-1"
        v-t="'dataManager.settings.name.nameRequired'"
      ></div>
    </div>

    <div class="flex flex-col space-y-2">
      <div v-if="errorMessage" class="text-red-500">
        {{ $t("Error.generic") }}
      </div>
      <div>
        <ButtonGradient @click="saveChanges" class="py-1 text-lg">
          <span v-t="'saveChanges'"></span>
        </ButtonGradient>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import ToggleInputLabelled from "@/components/inputs/ToggleInputLabelled.vue";
import { SchemaNode } from "@/types/data";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    ButtonGradient,
    ToggleInputLabelled,
  },
})
export default class Name extends Vue {
  newName = "";

  showValidationError = false;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get schema() {
    return this.connectionEditor.schema as SchemaNode[];
  }

  get errorMessage() {
    return this.connectionEditor.errorMessage;
  }

  created() {
    this.setup();
  }

  /**
   * Use Watcher because it is possible for user to open directly to this view from left-hand data panel,
   * which causes Manager's getConnection to finish loading after this is created.
   */
  @Watch("connection.uuid")
  connectionChanged() {
    this.$nextTick(this.setup);
  }

  setup() {
    if (!this.connection) return;
    this.newName = this.connection.name;
  }

  get showNameRequiredError() {
    return this.showValidationError && this.newName === "";
  }

  async saveChanges() {
    if (!this.newName) {
      this.showValidationError = true;
      return;
    }

    if (this.connection === null) {
      return;
    }

    try {
      // Must update state.connection's name here, in case user tabs to other Settings tab and back to this Sharing tab
      this.connectionEditor.setConnection({
        ...this.connection,
        name: this.newName,
      });

      // NOTE/TODO: We need to add nodes to every call to updateDataConnection... for all but calendar connections
      this.connectionEditor.updateDataConnection({
        name: this.newName,
        appUuid: this.$route.params.id,
        nodes: this.schema,
      });
    } catch (e) {
      console.log("error...", e);
    }
  }
}
</script>

<style scoped lang="postcss">
.validation-error {
  opacity: 0.75;
  background: rgba(246, 38, 38, 0.5);
  border: 1px solid #f62626;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
</style>
