<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <mask maskUnits="userSpaceOnUse" :id="`${svgId('fade')}`">
      <linearGradient
        :id="`${svgId('gradient')}`"
        x1="100%"
        y1="0"
        x2="0"
        y2="0"
      >
        <stop offset="0" style="stop-color: #222"></stop>
        <stop offset="1" style="stop-color: #fff"></stop>
      </linearGradient>
      <rect
        :fill="`url(#${svgId('gradient')})`"
        width="100%"
        height="100%"
      ></rect>
    </mask>

    <pattern
      :id="`${svgId('pattern')}`"
      x="2"
      y="2"
      width="8"
      height="8"
      patternUnits="userSpaceOnUse"
    >
      <g stroke-width="0">
        <rect fill="currentColor" x="0" y="0" width="4" height="4"></rect>
        <rect fill="#fff" x="4" y="0" width="4" height="4"></rect>
        <rect fill="currentColor" x="4" y="4" width="4" height="4"></rect>
        <rect fill="#fff" x="0" y="4" width="4" height="4"></rect>
      </g>
    </pattern>

    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      :mask="`url(#${svgId('fade')})`"
      :fill="`url(#${svgId('pattern')})`"
    ></rect>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class IconOpacity extends Vue {
  customid = Math.random().toString().substring(2);

  svgId(prefix: string) {
    return `${prefix}-${this.customid}`;
  }
}
</script>

<style scoped></style>
