<template>
  <div class="w-12 mx-2">
    <input type="text" class="w-full" :value="value" @input="change" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class DatetimeFormatTextInput extends Vue {
  @Prop(String) value: string;
  @Prop(Number) index: number;

  change(ev: any) {
    this.$emit("inputChange", {
      index: this.index,
      value: ev.target.value
    });
  }
}
</script>
