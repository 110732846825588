<template>
  <div class="relative">
    <div class="flex items-center">
      <div
        class="h-5 w-5 cursor-pointer hover:bg-gray-200 p-1 rounded-xl"
        @click="zoomOut"
      >
        <Icon name="Minus" />
      </div>
      <div
        class="px-2 py-1 rounded-md cursor-pointer hover:bg-gray-500/10"
        @click="showOptions = !showOptions"
      >
        {{ scaleRender }}
      </div>
      <div
        class="h-5 w-5 cursor-pointer hover:bg-gray-200 p-1 rounded-xl"
        @click="zoomIn"
      >
        <Icon name="Plus" />
      </div>
    </div>

    <div
      id="scale-options"
      v-if="showOptions"
      class="w-24 bg-white shadow rounded"
    >
      <div
        v-for="(opt, i) in scaleOptions"
        :key="i"
        class="w-full p-2 cursor-pointer hover:bg-gray-200"
        @click="clickOption(opt)"
      >
        {{ opt.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import Tooltip from "@/components/Tooltip.vue";
import { KeyCodes } from "@/keycodes";
import { useAppEditorStore } from "@/stores/appEditor";

const scales = [
  0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.25, 1.5, 4, 5,
];

const findNearestPrevIndex = (n: number, arr: number[]) => {
  let i = -1;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] > n) {
      return i - 1;
    }
    if (arr[i] === n) {
      return i;
    }
  }
  return i;
};

const findNearestNextIndex = (n: number, arr: number[]) => {
  let i = -1;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] >= n) {
      return i;
    }
  }
  return i;
};

@Component({ components: { Icon, IconSolid, Tooltip } })
export default class ZoomControls extends Vue {
  showOptions = false;
  spacePressed = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get scale() {
    return this.appEditor.scale;
  }

  created() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
  }

  mounted() {
    document.addEventListener("click", () => {
      this.showOptions = false;
    });
    this.$el.addEventListener("click", (ev: any) => {
      ev.stopPropagation();
    });
  }

  onKeyDown(e: KeyboardEvent) {
    // Ok meta key not triggering on Windows 10 Chrome 88 in Browserstack..
    const { code } = e;
    if (code === KeyCodes.SPACE && this.selections.length === 0) {
      // console.log("space true");
      this.spacePressed = true;
    }
  }

  onKeyUp(e: any) {
    if (e.code === KeyCodes.SPACE) {
      // console.log("keyup space", e.repeat);
      // if (e.isComposing) return;
      this.spacePressed = false;
    }
  }

  // [x] Scale may start at 40 or 60 rather than 50, and not show up on list.
  zoomIn() {
    // const idx = scales.findIndex(s => s === this.scale);
    const idx = findNearestPrevIndex(this.scale, scales);
    if (idx === -1) return;
    if (idx === scales.length - 1) return;
    this.appEditor.setScale(scales[idx + 1]);
  }

  zoomOut() {
    // const idx = scales.findIndex(s => s === this.scale);
    const idx = findNearestNextIndex(this.scale, scales);
    if (idx === 0) return;
    this.appEditor.setScale(scales[idx - 1]);
  }

  get scaleRender() {
    return this.renderPercent(this.scale);
  }

  get scaleOptions() {
    return scales.slice(0).map((scl) => {
      return {
        value: scl,
        label: this.renderPercent(scl),
      };
    });
  }

  renderPercent(scl: number) {
    return `${(scl * 100).toFixed(0)}%`;
  }

  clickOption(opt: any) {
    this.appEditor.setScale(opt.value);
    this.showOptions = false;
  }
}
</script>

<style scoped>
#scale-options {
  position: absolute;
  top: 120%;
}
</style>
