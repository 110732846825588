<template>
  <label class="block leading-none text-sm font-semibold mb-1"><slot /></label>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FormLabel extends Vue {}
</script>

<style scoped></style>
