<template>
  <form @submit.prevent="">
    <!-- <div class="text-2xl" v-t="'dataEditing.deleteTitle'"></div> -->

    <div class="h-full mt-4">
      <div v-if="!isLoading">
        <div v-t="'dataEditing.confirmDeleteConnection'"></div>

        <div v-if="hasDataBindings" class="mb-5 mt-4 text-red-500">
          <div v-t="'dataEditing.deleteConnectionWarning'"></div>
          <div v-for="app in appsWithBindings" :key="app.appUuid" class="mt-4">
            <router-link
              @click.native.stop=""
              :to="{
                name: 'edit',
                params: {
                  id: app.appUuid,
                },
              }"
              target="_blank"
              class="underline text-white hover:text-white visited:text-purple-600"
            >
              {{ app.appName }}
            </router-link>
          </div>
        </div>

        <div
          v-if="errorCode"
          class="flex-grow flex flex-col justify-center items-center mt-4 mb-4 text-red-500"
        >
          <div class="text-3xl" v-t="'errorOccurred'"></div>
          <div v-t="errorMessage"></div>
          <div v-t="'tryAgain'"></div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { DataBindingAppInfo } from "@/types/data";
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    ButtonGradient,
  },
})
export default class ConnectionDelete extends Vue {
  @Prop() dcUuid: string;
  @PropSync("canDeleteConnection") syncedCanDeleteConnection: boolean;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get dataBindingUsageInfo() {
    return this.connectionEditor.dataBindingUsageInfo;
  }

  get errorCode() {
    return this.connectionEditor.errorCode;
  }

  get errorMessage() {
    return this.connectionEditor.errorMessage;
  }

  get isLoading() {
    return this.connectionEditor.isLoading;
  }

  get appsWithBindings(): DataBindingAppInfo[] {
    return (
      this.dataBindingUsageInfo
        ?.flatMap((b) => b.appInfo)
        .filter(
          (app, idx, self) =>
            idx === self.findIndex((t) => t.appUuid === app.appUuid)
        ) ?? []
    );
  }

  async created() {
    await this.connectionEditor.getConnectionBindings({ dcUuid: this.dcUuid });

    this.syncedCanDeleteConnection = !this.hasDataBindings;
  }

  get hasDataBindings() {
    return this.dataBindingUsageInfo && this.dataBindingUsageInfo.length > 0;
  }

  destroyed() {
    this.connectionEditor.$reset();
  }
}
</script>
