<template>
  <TextComponent v-bind="$props" :content="textContent" />
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextTransformValue } from "../TextStyleOptions";
import { makeTextContent } from "@/text";
import { DateTime } from "luxon";
import TextComponent from "@/components/widgets/Text/TextComponent.vue";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";

export const formatDatetime = (
  date: DateTime,
  options: {
    format: string;
    locale: string | null;
  }
) => {
  const { format, locale } = options;
  let dt = date;
  if (locale) {
    dt = dt.setLocale(locale);
  }
  return dt.toLocaleString(JSON.parse(format));
};

@Component({
  components: {
    TextComponent,
  },
})
export default class DatetimeComponent extends AnimatableWidget {
  @Prop() readonly datetimeFormat: string;
  @Prop() readonly datetimeValue: string;
  @Prop() readonly userSpecifiedLocale: string;
  @Prop() readonly useBrowserLocale: boolean;
  @Prop(Boolean) readonly usePlayerTimezone: boolean;
  @Prop(String) readonly timezone: string;

  @Prop(String) readonly alignHorizontal: string;
  @Prop(String) readonly fontFamily: string;
  @Prop(Number) readonly fontWeight: number;
  @Prop(String) readonly fontStyle: string;
  @Prop(Number) readonly fontSize: number;
  @Prop(String) readonly textColor: string;
  @Prop(String) readonly textTransform: TextTransformValue;
  @Prop(Number) readonly letterSpacing: number;
  @Prop(Number) readonly lineHeight: number;
  @Prop(Boolean) readonly useCurrentTime: boolean;

  // ================== HANDLE TIMER ==================

  time: DateTime = DateTime.now();

  private timer: number;

  created() {
    if (this.useCurrentTime) {
      this.startTimer();
    }
  }

  beforeDestroy() {
    this.stopTimer();
  }

  startTimer() {
    this.tick();
    this.timer = window.setInterval(this.tick, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  tick() {
    // console.log("tick...");
    this.time = this.usePlayerTimezone
      ? DateTime.now()
      : DateTime.now().setZone(this.timezone);
  }

  @Watch("useCurrentTime")
  updateTimer(val: boolean) {
    if (val) {
      this.startTimer();
    } else {
      this.stopTimer();
    }
  }

  // ================== END HANDLE TIMER ==================

  get locale() {
    if (this.useBrowserLocale) return null;
    return this.userSpecifiedLocale;
  }

  get content() {
    if (!this.isValidDateString(this.datetimeValue)) return "";
    const rawDate = new Date(this.datetimeValue);
    let date: DateTime = DateTime.fromJSDate(rawDate);

    if (this.useCurrentTime) {
      date = this.time;
    }

    return formatDatetime(date, {
      format: this.datetimeFormat,
      locale: this.locale,
    });
  }

  isValidDateString(dateString: string) {
    if (typeof dateString !== "string" || dateString.trim() === "") {
      return false; // Invalid if not a string or is empty/whitespace
    }
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  get textContent() {
    return makeTextContent(this.content);
  }
}
</script>

<style></style>
