<template>
  <div class="relative w-3/5 h-8 text-black">
    <div
      class="absolute left-2 top-1/2 transform -translate-y-1/2 pointer-events-none opacity-40"
    >
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        class="mt-px w-5 h-5"
      >
        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
      </svg>
    </div>
    <input
      type="search"
      :value="search"
      @input="$emit('onSearchInput', $event)"
      @keydown="$emit('onSearchKeydown', $event)"
      @keyup.enter="$emit('onSearchEnter', $event)"
      @blur="$emit('blur')"
      name="q"
      class="w-full rounded h-full pl-[2.1rem] pr-2 dark-form-focus text-[14px]"
      :placeholder="placeholder"
      autocomplete="off"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class SearchBar extends Vue {
  @Prop(String) placeholder: string;

  search = "";
}
</script>
