<template>
  <div>
    <!-- Multi Component Editor -->
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MultiComponentEditor extends Vue {}
</script>

<style scoped></style>
