<template>
  <component
    :is="`Icon${name}`"
    fill="none"
    class="icon"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    :stroke-width="width"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconTemplates from "./IconTemplates.vue";
import IconShapes from "./IconShapes.vue";
import IconData from "./IconData.vue";
import IconChevronDown from "./IconChevronDown.vue";
import IconChevronRight from "./IconChevronRight.vue";
import IconChevronUp from "./IconChevronUp.vue";
import IconChevronLeft from "./IconChevronLeft.vue";
import IconClose from "./IconClose.vue";
import IconPhotos from "./IconPhotos.vue";
import IconVideos from "./IconVideos.vue";
import IconBoldText from "./IconBoldText.vue";
import IconItalicText from "./IconItalicText.vue";
import IconUnderlineText from "./IconUnderlineText.vue";
import IconText from "./IconText.vue";
import IconBackground from "./IconBackground.vue";
import IconUndo from "./IconUndo.vue";
import IconRedo from "./IconRedo.vue";
import IconOpacity from "./IconOpacity.vue";
import IconOpacityEditor from "./IconOpacityEditor.vue";
import IconPosition from "./IconPosition.vue";
import IconLockOpen from "./IconLockOpen.vue";
import IconLockClosed from "./IconLockClosed.vue";
import IconAdjustments from "./IconAdjustments.vue";
import IconAlignTop from "./IconAlignTop.vue";
import IconAlignMiddle from "./IconAlignMiddle.vue";
import IconAlignBottom from "./IconAlignBottom.vue";
import IconAlignLeft from "./IconAlignLeft.vue";
import IconAlignCenter from "./IconAlignCenter.vue";
import IconAlignRight from "./IconAlignRight.vue";
import IconSelector from "./IconSelector.vue";
import IconDimensions from "./IconDimensions.vue";
import IconMoveForward from "./IconMoveForward.vue";
import IconMoveBackward from "./IconMoveBackward.vue";
import IconDistributeHorizontally from "./IconDistributeHorizontally.vue";
import IconDistributeVertically from "./IconDistributeVertically.vue";
import IconCornerRadius from "./IconCornerRadius.vue";
import IconColor from "./IconColor.vue";
import IconUpload from "./IconUpload.vue";
import IconEye from "./IconEye.vue";
import IconEyeOff from "./IconEyeOff.vue";
import IconSearch from "./IconSearch.vue";
import IconTextAlignLeft from "./IconTextAlignLeft.vue";
import IconTextAlignCenter from "./IconTextAlignCenter.vue";
import IconTextAlignRight from "./IconTextAlignRight.vue";
import IconTextAlignTop from "./IconTextAlignTop.vue";
import IconTextAlignMiddle from "./IconTextAlignMiddle.vue";
import IconTextAlignBottom from "./IconTextAlignBottom.vue";
import IconGrids from "./IconGrids.vue";
import IconWidgets from "./IconWidgets.vue";
import IconCloseCircle from "./IconCloseCircle.vue";
import IconOpenLink from "./IconOpenLink.vue";
import IconGridAdd from "./IconGridAdd.vue";
import IconColorSwatch from "./IconColorSwatch.vue";
import IconPencilAlt from "./IconPencilAlt.vue";
import IconSizeLandscape from "./IconSizeLandscape.vue";
import IconSizePortrait from "./IconSizePortrait.vue";
import IconSizeTriangle from "./IconSizeTriangle.vue";
import IconBack from "./IconBack.vue";
import IconPlus from "./IconPlus.vue";
import IconTemplate from "./IconTemplate.vue";
import IconDuplicate from "./IconDuplicate.vue";
import IconBan from "./IconBan.vue";
import IconCloudUpload from "./IconCloudUpload.vue";
import IconDownload from "./IconDownload.vue";
import IconMinus from "./IconMinus.vue";
import IconCancel from "./IconCancel.vue";
import IconReverse from "./IconReverse.vue";
import IconRotate from "./IconRotate.vue";
import IconRotation from "./IconRotation.vue";
import IconRectangleVertical from "./IconRectangleVertical.vue";
import IconRectangleHorizontal from "./IconRectangleHorizontal.vue";
import IconColorTransparent from "./IconColorTransparent.vue";
import IconMoveToBack from "./IconMoveToBack.vue";
import IconMoveToFront from "./IconMoveToFront.vue";
import IconSquare from "./IconSquare.vue";
import IconUserGroup from "./IconUserGroup.vue";
import IconArrowUp from "./IconArrowUp.vue";
import IconArrowDown from "./IconArrowDown.vue";
import IconLetterSpacing from "./IconLetterSpacing.vue";
import IconLineHeight from "./IconLineHeight.vue";
import IconBlur from "./IconBlur.vue";
import IconFilter from "./IconFilter.vue";
import IconTable from "./IconTable.vue";
import IconHand from "./IconHand.vue";
import IconPlay from "./IconPlay.vue";
import IconGrid from "./IconGrid.vue";
import IconSlide from "./IconSlide.vue";
import IconShieldCheck from "./IconShieldCheck.vue";
import IconSmile from "./IconSmile.vue";
import IconSwitch from "./IconSwitch.vue";
import IconNameText from "./IconNameText.vue";
import IconCirclePlus from "./IconCirclePlus.vue";
import IconCircleCheckmark from "./IconCircleCheckmark.vue";
import IconCircleOpen from "./IconCircleOpen.vue";
import IconCircleClose from "./IconCircleClose.vue";
import IconCog from "./IconCog.vue";
import IconSlider from "./IconSlider.vue";
import IconUseOnOwn from "./IconUseOnOwn.vue";
import IconGridThick from "./IconGridThick.vue";
import IconPlusCircle from "./IconPlusCircle.vue";
import IconMinusCircle from "./IconMinusCircle.vue";
import IconViewGrid from "./IconViewGrid.vue";
import IconViewList from "./IconViewList.vue";
import IconLayers from "./IconLayers.vue";
import IconChildIndicator from "./IconChildIndicator.vue";

@Component({
  components: {
    IconTemplates,
    IconShapes,
    IconData,
    IconChevronDown,
    IconChevronRight,
    IconChevronUp,
    IconChevronLeft,
    IconClose,
    IconPhotos,
    IconVideos,
    IconBoldText,
    IconItalicText,
    IconUnderlineText,
    IconText,
    IconBackground,
    IconUndo,
    IconRedo,
    IconOpacity,
    IconOpacityEditor,
    IconPosition,
    IconLockOpen,
    IconLockClosed,
    IconAdjustments,
    IconAlignTop,
    IconAlignMiddle,
    IconAlignBottom,
    IconAlignLeft,
    IconAlignCenter,
    IconAlignRight,
    IconDimensions,
    IconMoveForward,
    IconMoveBackward,
    IconDistributeHorizontally,
    IconDistributeVertically,
    IconCornerRadius,
    IconColor,
    IconUpload,
    IconSelector,
    IconEye,
    IconEyeOff,
    IconSearch,
    IconTextAlignLeft,
    IconTextAlignCenter,
    IconTextAlignRight,
    IconTextAlignTop,
    IconTextAlignMiddle,
    IconTextAlignBottom,
    IconGrids,
    IconWidgets,
    IconCloseCircle,
    IconOpenLink,
    IconGridAdd,
    IconColorSwatch,
    IconPencilAlt,
    IconSizeLandscape,
    IconSizePortrait,
    IconSizeTriangle,
    IconBack,
    IconPlus,
    IconPlay,
    IconTemplate,
    IconDuplicate,
    IconDownload,
    IconBan,
    IconCloudUpload,
    IconMinus,
    IconReverse,
    IconRotate,
    IconRotation,
    IconColorTransparent,
    IconRectangleVertical,
    IconRectangleHorizontal,
    IconMoveToBack,
    IconMoveToFront,
    IconSquare,
    IconUserGroup,
    IconArrowUp,
    IconArrowDown,
    IconLetterSpacing,
    IconLineHeight,
    IconBlur,
    IconCancel,
    IconFilter,
    IconTable,
    IconHand,
    IconGrid,
    IconSlide,
    IconShieldCheck,
    IconSmile,
    IconSwitch,
    IconNameText,
    IconCircleCheckmark,
    IconCircleOpen,
    IconCirclePlus,
    IconCircleClose,
    IconCog,
    IconSlider,
    IconUseOnOwn,
    IconGridThick,
    IconPlusCircle,
    IconMinusCircle,
    IconViewGrid,
    IconViewList,
    IconLayers,
    IconChildIndicator,
  },
})
export default class Icon extends Vue {
  @Prop(String) name: string;
  @Prop({ default: 2 }) width: number;
}
</script>

<style scoped></style>
