<template>
  <div class="flex space-x-2 items-center whitespace-nowrap justify-center">
    <div @click="decrementRecordIndex()">
      <Icon name="ArrowUp" class="arrow-btn -rotate-90" />
    </div>

    <div class="text-xs text-gray-400 flex space-x-1 items-center">
      <div class="w-8">
        <input
          type="text"
          class="rounded w-full text-xs text-gray-900 px-1 py-0.5"
          @keyup.enter="setRecordIndex"
          :value="store.recordIndex + 1"
        />
      </div>
      <div>{{ $t("dataPanel.of") }} {{ store.connectionDataSize }}</div>
    </div>

    <div @click="incrementRecordIndex()">
      <Icon name="ArrowUp" class="arrow-btn rotate-90" />
    </div>
  </div>
</template>

<script lang="ts">
import { useConnectionDataStore } from "@/stores/connectionData";
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";

@Component({
  components: {
    Icon,
  },
})
export default class RecordIndexControl extends Vue {
  get store() {
    return useConnectionDataStore();
  }

  incrementRecordIndex() {
    this.store.recordIndex =
      (this.store.recordIndex + 1) % this.store.connectionDataSize;
  }

  decrementRecordIndex() {
    this.store.recordIndex =
      (this.store.recordIndex + this.store.connectionDataSize - 1) %
      this.store.connectionDataSize;
  }

  setRecordIndex(ev: any) {
    let val = parseInt(ev.target.value) - 1;
    // Clamp the value between allowed index values:
    val = Math.max(val, 0);
    val = Math.min(val, this.store.connectionDataSize - 1);
    this.store.recordIndex = val;
  }
}
</script>

<style scoped lang="postcss">
.arrow-btn {
  @apply h-[18px] w-[18px] cursor-pointer hover:bg-gray-400 rounded-full border border-white p-0.5;
}
</style>
