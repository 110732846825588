<template>
  <textarea
    cols="60"
    rows="8"
    :value="value"
    @input="handleInput"
    @click.stop
    @blur="$emit('blur', $event)"
  ></textarea>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import InputBase from "@/components/data/connections/manualSource/inputs/InputBase.vue";

@Component({
  components: {},
})
export default class TextAreaInput extends InputBase {}
</script>
