<template>
  <div>
    <SelectMenu
      ref="menu"
      :dark="true"
      v-if="isListInput"
      :options="listOptions"
      :value="value"
      @input="handleSelect"
    />
    <input
      class="w-full pl-2 py-1 rounded-sm text-black font-mono"
      v-else
      ref="textInput"
      :type="inputElementType"
      :value="value"
      :min="min"
      :max="max"
      @input="handleInput"
      :class="{
        'outline outline-red-500 bg-red-50 dark-form-focus-error': isInvalid,
        'dark-form-focus': !isInvalid,
      }"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { OperandInputValueType } from "./OperandSelectType";

import SelectMenu from "../SelectMenu.vue";
import InputLabel from "./InputLabel.vue";

@Component({ components: { SelectMenu, InputLabel } })
export default class StaticInput extends Vue {
  @Prop(String) inputType: OperandInputValueType;
  @Prop() value: string | number;
  @Prop(Number) min: number | undefined;
  @Prop(Number) max: number | undefined;
  @Prop(Boolean) isInvalid: boolean;

  handleSelect(value: string) {
    this.$emit("input", value);
  }

  handleInput(e: Event) {
    e.preventDefault();
    let value = (e.target as HTMLInputElement).value;
    this.$emit("input", this.cleanValue(value));
  }

  @Watch("min")
  onMinChanged() {
    this.handleMinMaxChange();
  }

  @Watch("max")
  onMaxChanged() {
    this.handleMinMaxChange();
  }

  handleMinMaxChange() {
    const current = this.value;
    const cleaned = this.cleanValue(this.value.toString());
    if (current !== cleaned) {
      this.$emit("input", cleaned);
    }
  }

  cleanValue(value: string) {
    const original = value;
    if (this.inputType === "Number" && value.trim().length > 0) {
      const num = parseInt(value, 10);
      if (isNaN(num) || (typeof this.min === "number" && num < this.min)) {
        value = this.min?.toString() as string;
      }

      if (isNaN(num) || (typeof this.max === "number" && num > this.max)) {
        value = this.max?.toString() as string;
      }
    }

    if (original !== value) {
      // This will force the input element to re-render after emit
      // https://stackoverflow.com/a/56034824/5651
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
    return value;
  }

  @Watch("inputType", { immediate: true })
  onTypeChanged(newType: OperandInputValueType) {
    if (newType === "DayOfWeek" || newType === "Month") {
      this.handleSelect(this.listOptions[0].value);
    }
  }

  mounted() {
    if (this.isListInput) {
      (this.$refs.menu as SelectMenu)?.focus();

      if (!isNonEmptyString(this.value)) {
        this.handleSelect(this.listOptions[0].value);
      }
    } else {
      (this.$el.querySelector("input") as HTMLInputElement)?.focus();
    }
  }

  get isListInput() {
    return this.inputType === "DayOfWeek" || this.inputType === "Month";
  }

  get inputElementType() {
    switch (this.inputType) {
      case "Date":
        return "date";
      case "DateTime":
        return "datetime-local";
      case "Time":
        return "time";
      case "Number":
        return "number";
      default:
        return "text";
    }
  }

  get listOptions() {
    if (this.inputType === "Month") {
      return this.monthOptions;
    }
    if (this.inputType === "DayOfWeek") {
      return this.dayOfWeekOptions;
    }
    return [];
  }

  get monthOptions() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((n) => {
      const d = new Date(2000, n, 1);
      return {
        label: d.toLocaleDateString(undefined, {
          month: "long",
        }),
        value: d.toLocaleDateString("en-US", {
          month: "long",
        }),
        disabled:
          (typeof this.min !== "undefined" && n < this.min) ||
          (typeof this.max !== "undefined" && n > this.max),
      };
    });
  }

  get dayOfWeekOptions() {
    const english = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const week = [0, 1, 2, 3, 4, 5, 6].map((n) => {
      const d = new Date();
      d.setDate(d.getDate() + n);
      return {
        value: d.toLocaleDateString("en-US", { weekday: "long" }),
        label: d.toLocaleDateString(undefined, { weekday: "long" }),
      };
    });

    week.sort((a, b) => {
      const aIndex = english.indexOf(a.value);
      const bIndex = english.indexOf(b.value);
      return aIndex - bIndex;
    });
    return week;
  }
}
</script>
