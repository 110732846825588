<template>
  <div class="flex flex-col flex-grow w-full">
    <div class="relative flex flex-col flex-grow bg-app-dark1">
      <div class="absolute inset-0 overflow-auto">
        <TreeNode
          :node="rootNode"
          :depth="0"
          :readonly="readonly"
          :selectedQuery="selectedQuery"
          :shouldExpand="true"
          :showValue="true"
          :selectionType="selectionType"
          @select="onNodeSelected"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import TreeNode from "@/components/data/connections/setup/schema/TreeNode.vue";
import FormButton from "@/components/FormButton.vue";
import { TreeSchemaNode } from "@/types/data";

@Component({
  components: { FormButton, TreeNode },
})
export default class SelectTreeValue extends Vue {
  @Prop(Boolean) readonly: boolean;
  @Prop(Object) rootNode: TreeSchemaNode;
  @Prop(String) selectedQuery: string;
  @Prop(String) selectionType: string;

  get hasSelection() {
    return (
      this.readonly === false &&
      typeof this.selectedQuery === "string" &&
      this.selectedQuery.trim().length > 0
    );
  }

  onNodeSelected(node: TreeSchemaNode) {
    if (this.readonly === true) {
      return;
    }
    this.$emit("select", node);
  }
}
</script>

<style lang="postcss"></style>
