<template>
  <div class="flex flex-row items-center" v-if="model">
    <component
      :is="aspectIcon"
      class="w-4 aspect-icon h-12"
      @click.native="toggleLock()"
    >
    </component>
    <div class="grid gap-1 grid-cols-2 grid-rows-2 w-58 h-20">
      <div class="flex">
        <EditorNumberInput
          class="w-26"
          label="W"
          :controls="true"
          :align="'left'"
          :disabled="widthHeightDisabled"
          :value="apparentW"
          @change="update($event, 'w')"
        />
      </div>
      <div class="flex">
        <EditorNumberInput
          class="w-26"
          label="X"
          :controls="true"
          :align="'left'"
          :disabled="!model"
          :value="apparentX"
          @change="update($event, 'x')"
        />
      </div>
      <div class="flex">
        <EditorNumberInput
          class="w-26"
          label="H"
          :controls="true"
          :align="'left'"
          :disabled="widthHeightDisabled"
          :value="apparentH"
          @change="update($event, 'h')"
        />
      </div>
      <div class="flex">
        <EditorNumberInput
          class="w-26"
          label="Y"
          :controls="true"
          :align="'left'"
          :disabled="!model"
          :value="apparentY"
          @change="update($event, 'y')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import {
  dimsToUpdateApparentHeight,
  dimsToUpdateApparentWidth,
  getApparentDims,
  getApparentDimsInvert,
} from "@/utils";
import IconAspectUnlock from "@/components/icons/IconAspectUnlock.vue";
import IconAspectLock from "@/components/icons/IconAspectLock.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import { TransformOptions } from "../widgets/TransformOptions";
import { useAppEditorStore } from "@/stores/appEditor";
import { Widget } from "../widgets/Widget";

// TODO: Dimensions x and y will not go negative on Safari
//

@Component({
  components: {
    EditorNumberInput,
    ToggleInput,
    IconAspectUnlock,
    IconAspectLock,
  },
})
export default class DimensionsEditor extends Vue {
  @Prop({ default: true }) showLockAspectToggle: boolean;

  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    if (this.appEditor.selectedWidget === undefined) {
      throw new Error(" no selected widget");
    }
    return this.appEditor.selectedWidget as Widget;
  }

  get apparentDims() {
    return getApparentDims(this.model);
  }

  get apparentX() {
    return this.apparentDims.x;
  }

  get apparentY() {
    return this.apparentDims.y;
  }

  get apparentW() {
    return this.apparentDims.w;
  }

  get apparentH() {
    return this.apparentDims.h;
  }

  get x() {
    return this.model ? this.model.x : NaN;
  }
  get y() {
    return this.model ? this.model.y : NaN;
  }
  get w() {
    return this.model ? this.model.w : NaN;
  }
  get h() {
    return this.model ? this.model.h : NaN;
  }
  get scaleX() {
    return this.model ? this.model.scaleX : NaN;
  }
  get scaleY() {
    return this.model ? this.model.scaleY : NaN;
  }

  get widthHeightDisabled() {
    return !this.model || this.model.type === "Group";
  }

  get enableLockAspect() {
    return (
      this.model &&
      "lockAspect" in this.model &&
      this.model.type !== "Svg" &&
      this.model.type !== "ProgressDonut" &&
      this.model.type !== "PieGraph" &&
      this.model.type !== "AnalogClock"
    );
  }
  get lockAspect() {
    return this.model && "lockAspect" in this.model
      ? this.model.lockAspect
      : false;
  }
  set lockAspect(value: boolean) {
    if (!this.model || this.model.lockAspect === value) {
      return;
    }
    this.appEditor.toggleLockAspect({
      wid: this.model.wid,
      lockAspect: value,
    });
  }

  toggleLock() {
    let opposite = !this.model.lockAspect;
    this.appEditor.toggleLockAspect({
      wid: this.model.wid,
      lockAspect: opposite,
    });
  }

  get aspectIcon() {
    if (this.lockAspect === false) {
      return "IconAspectUnlock";
    } else {
      return "IconAspectLock";
    }
  }

  // TODO: Not quite working when only size up w/h by 1, and aspect is locked
  // NOTE: We must convert apparentDims back to real in order to update "real" dims
  update(value: number, prop: string) {
    // console.log(value, prop);
    if (
      !this.model
      // || (this.model as any)[prop] === value
    ) {
      return;
    }

    const apparentData = {
      x: this.apparentX,
      y: this.apparentY,
      w: this.apparentW,
      h: this.apparentH,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    };

    // This works...assumes that lockAspect will always be true for polygons/Svg:
    if (this.model.type === "Svg" && ["w", "h"].includes(prop)) {
      // const newVal = value / this.scaleX;
      const mergedData = { ...apparentData, ...{ w: value, h: value } };
      const props = getApparentDimsInvert(mergedData);
      this.appEditor.setWidgetProps([this.model.wid], props);
      return;
    }

    const mergedData = { ...apparentData, ...{ [prop]: value } };

    let { x, y, w, h } = getApparentDimsInvert(mergedData);

    if (this.lockAspect) {
      if (prop === "h") {
        // w = Math.round(value === 0 ? 0 : (value / this.h) * this.w);

        const newW = Math.round(
          value === 0
            ? 0
            : (value / (this.h * this.scaleY)) * this.w * this.scaleX
        );
        const res = dimsToUpdateApparentWidth(
          {
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
          } as TransformOptions,
          newW
        );
        x = res.x;
        w = res.w;
      }
      if (prop === "w") {
        // h = Math.round(value === 0 ? 0 : (value / this.w) * this.h);

        const newH = Math.round(
          value === 0
            ? 0
            : (value / (this.w * this.scaleX)) * this.h * this.scaleY
        );
        const res = dimsToUpdateApparentHeight(
          {
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
          } as TransformOptions,
          newH
        );
        y = res.y;
        h = res.h;
      }
    }

    const props = { x, y, w, h };
    this.appEditor.setWidgetProps([this.model.wid], props);
  }
}
</script>
