<script setup lang="ts">
import { withDefaults, defineProps } from "vue";
import { useUserStore } from "@/stores/user";
const store = useUserStore();

export interface Props {
  layout?: "stacked" | "inline";
}

const props = withDefaults(defineProps<Props>(), {
  layout: "stacked",
});
</script>

<template>
  <div class="overflow-x-hidden">
    <div class="text-gray-400">Signed in as</div>
    <div
      :class="{
        'flex flex-col': props.layout === 'stacked',
        'space-x-1': props.layout === 'inline',
      }"
    >
      <span>{{ store.username }}</span>
      <span v-if="layout === 'inline'" class="text-gray-500">•</span>
      <span class="whitespace-nowrap">{{ store.accountName }}</span>
    </div>
  </div>
</template>
