<template>
  <div class="w-full">
    <DefineColumns
      :nodes="nodes"
      @complete="onCompleteClick"
      mode="Design"
      schemaType="Tabular"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import DefineColumns from "@/components/data/connections/setup/schema/DefineColumns.vue";
import FormButton from "@/components/FormButton.vue";
import { SchemaNode } from "@/types/data";

@Component({
  components: {
    FormButton,
    DefineColumns,
  },
})
export default class DefineManualSchema extends Vue {
  @Prop(String) button: string;
  @Prop(Array) nodes: SchemaNode[];

  columns: SchemaNode[] = [];

  created() {
    this.columns = this.nodes.slice();
  }

  onCompleteClick(nodes: SchemaNode[]) {
    this.$emit("complete", nodes.slice());
  }
}
</script>
