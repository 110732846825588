import "./assets/tailwind.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import { router } from "./router";
import VuePortal from "portal-vue";
import i18n from "./i18n";

import { createPinia, PiniaVuePlugin } from "pinia";

// Global Helper Functions
import { helpers } from "@/helpers/builder";
Vue.use({
  install() {
    Vue.prototype.$helpers = helpers;
  },
});

// Globally register core widgets
import { BuilderWidgets } from "@/components/widgets/builderWidgets";

Object.entries(BuilderWidgets).forEach(([id, component]) => {
  Vue.component(id, component);
});

Vue.use(PiniaVuePlugin);
Vue.use(VuePortal);
Vue.use(VueRouter);

Vue.config.ignoredElements = ["trix-editor", "trix-toolbar"];
Vue.config.productionTip = false;
Vue.config.devtools = true;

export const snapHintBus = new Vue();

const pinia = createPinia();

// add load listener to avoid jest error
window.onload = () => {
  new Vue({
    router,
    i18n,
    pinia,
    render: (h) => h(App),
  }).$mount("#app");
};
