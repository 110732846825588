<template>
  <div class="space-y-6 w-full">
    <div class="text-center mx-16" style="text-wrap: balance" v-if="guideText">
      {{ guideText }}
    </div>

    <div class="flex items-start justify-center w-full space-x-12">
      <OperandEditor
        class="w-80"
        side="left"
        :types="leftOperandTypes"
        :model="model.operandLeft"
        :node="leftNode"
        @change="updateOperandLeft($event)"
      />

      <div class="w-80 space-y-2" v-if="hasLeftSelection">
        <button
          type="button"
          class="w-full border border-gray-600 bg-gray-700/30 cursor-pointer rounded px-3 py-1 flex items-center justify-between space-x-2 text-sm hover:border-gray-500"
          :style="getOperationStyle(o.value)"
          @click="onOperationChanged(o.value)"
          :key="o.value.toString()"
          v-for="o in operatorOptions"
        >
          <div v-text="o.label"></div>

          <CheckBox :checked="o.value === model.operation" />
        </button>
      </div>

      <div
        class="min-h-[240px] flex flex-col items-center justify-center w-80 rounded bg-gray-700/30 text-gray-500"
        v-if="hasLeftSelection === false"
      >
        <div class="text-2xl" v-t="'ConditionsEditor.step2'"></div>
        <div v-t="'ConditionsEditor.comparisonMethod'"></div>
      </div>

      <OperandEditor
        class="w-80"
        side="right"
        v-if="showRightOperand"
        :types="rightOperandTypes"
        :model="model.operandRight"
        :node="rightNode"
        :inputType="rightInputType"
        :min="rightMin"
        :max="rightMax"
        @change="updateOperandRight($event)"
      />
      <div
        class="min-h-[240px] flex flex-col items-center justify-center w-80 rounded bg-gray-700/30 text-gray-500"
        v-if="!showRightOperand"
      >
        <div class="text-center" v-if="rightSelectionRequired">
          <div class="text-2xl" v-t="'ConditionsEditor.step3'"></div>
          <div v-t="'ConditionsEditor.comparisonValue'"></div>
        </div>
        <div v-else>
          <div v-t="'ConditionsEditor.comparisonValueNotNeeded'"></div>
        </div>
      </div>
    </div>

    <div
      v-if="showAddButton"
      class="flex items-center justify-center pt-6 border-t border-gray-600"
    >
      <div
        class="relative flex h-8 rounded border border-app-dark4 font-semibold leading-none text-white shadow-lg"
      >
        <button
          v-if="showOrButton"
          @click="addOrRule"
          class="pointer-cursor px-3 rounded-sm w-16 text-center hover:bg-app-purple"
          v-t="'Condition.or'"
        ></button>
        <div
          v-if="showOrButton"
          class="transform -translate-y-px mt-1 h-6 border-l border-app-dark4"
        ></div>
        <button
          @click="addRule"
          class="pointer-cursor px-3 rounded-sm w-16 text-center hover:bg-app-green"
          v-t="'Condition.and'"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { LogicRule, LogicOperation, LogicOperand } from "@/types/logic";
import { getOperatorOptions } from "@/components/logic/operators";
import { DataType } from "@/types/data";
import {
  OperandInputValueType,
  OperandSelectType,
} from "@/components/logic/OperandSelectType";
import { removeReactivity } from "@/utils";
import {
  NodeInfo,
  OperandChangedEvent,
} from "@/components/logic/OperandChangedEvent";

import SelectMenu from "@/components/SelectMenu.vue";
import OperandEditor from "@/components/logic/OperandEditor.vue";
import FormButton from "@/components/FormButton.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import CheckBox from "@/components/logic/CheckBox.vue";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { describeRule } from "@/components/logic/describeRule";
import { useFilterEditorStore } from "@/stores/filterEditor";

function operandHasSelection(operand: Partial<LogicOperand>) {
  switch (operand.type) {
    case "ConnectionColumn":
    case "ConnectionScalar":
      return operand.nodeUuid !== "";
    case "QueryString":
    case "Static":
      return operand.value !== "";
    case undefined:
      return false;
    default:
      return true;
  }
}

function clearOperandValues(operand: Partial<LogicOperand>) {
  const copy = removeReactivity<LogicOperand>(operand);
  copy.connectionUuid = "";
  copy.default = "";
  copy.nodeUuid = "";
  (copy.type as any) = undefined;
  copy.value = "";
  (copy.valueType as any) = "";
  return copy;
}

@Component({
  components: {
    SelectMenu,
    OperandEditor,
    FormButton,
    OutlineButton,
    CheckBox,
  },
})
export default class LogicRuleEditor extends Vue {
  @Prop(Object) model: LogicRule;
  @Prop(String) groupUuid: string;

  leftNode: NodeInfo | null = null;
  rightNode: NodeInfo | null = null;

  /**
   * Useful for detecting if a user has changed
   * a rule that is being edited.
   */
  userHasModified = false;

  updateOperandLeft(event: OperandChangedEvent) {
    const { operand, node } = event;
    this.leftNode = node ?? null;
    this.update({
      operandLeft: operand,
      operation: undefined,
      operandRight: clearOperandValues(this.model.operandRight),
    });
  }

  updateOperandRight(event: OperandChangedEvent) {
    const { operand, node } = event;
    this.rightNode = node ?? null;
    this.update({ operandRight: operand });
  }

  @Watch("leftDataType")
  resetOperationSelection(leftType: DataType | undefined) {
    if (typeof leftType === "undefined") {
      return;
    }

    if (isNonEmptyString(this.model.operation)) {
      // If the left datatype changed, the operation list should update
      // with options relevant for the type. When this happens, auto-select
      // the first operation.
      this.onOperationChanged(this.operatorOptions[0].value as LogicOperation);
    }
  }

  onOperationChanged(operation: LogicOperation) {
    this.update({ operation });
  }

  update(patch: Partial<LogicRule>) {
    const result = Object.assign(
      {},
      removeReactivity<LogicRule>(this.model),
      patch
    );

    this.store.updateRule(result);
    this.userHasModified = true;
  }

  getOperationStyle(operation: LogicOperation) {
    return operation === this.model.operation
      ? { "text-shadow:": "0 1px 0 #0003" }
      : {};
  }

  get showDescription() {
    return this.hasLeftSelection;
  }

  describe() {
    return describeRule(this.model, this.store.columns);
  }

  get guideText() {
    return "";
  }

  get store() {
    return useFilterEditorStore();
  }

  get ruleIsComplete() {
    const left = this.hasLeftSelection;
    const op = isNonEmptyString(this.model.operation);
    const right = !this.showRightOperand || this.hasRightSelection;
    return left && op && right;
  }

  get hasLeftSelection() {
    return operandHasSelection(this.model.operandLeft);
  }

  get hasRightSelection() {
    return operandHasSelection(this.model.operandRight);
  }

  get showRightOperand() {
    const standaloneOperations: LogicOperation[] = [
      "Null",
      "NotNull",
      "BoolIsTrue",
      "BoolIsFalse",
    ];

    return (
      this.hasLeftSelection &&
      isNonEmptyString(this.model.operation) &&
      !standaloneOperations.includes(this.model.operation as LogicOperation)
    );
  }

  get rightSelectionRequired() {
    const standaloneOperations: LogicOperation[] = [
      "Null",
      "NotNull",
      "BoolIsTrue",
      "BoolIsFalse",
    ];

    return !standaloneOperations.includes(
      this.model.operation as LogicOperation
    );
  }

  get leftOperandTypes() {
    return ["Column"];
  }

  get rightOperandTypes() {
    const types: OperandSelectType[] = ["DateTime", "QueryString", "Static"];

    // If left type is date/time, exclude them from right
    const dateTimeTypes: DataType[] = ["Date", "Time", "DateTime"];
    if (
      this.model.operandLeft.type?.startsWith("Current") ||
      (typeof this.leftDataType !== "undefined" &&
        !dateTimeTypes.includes(this.leftDataType))
    ) {
      types.splice(types.indexOf("DateTime"), 1);
    }
    return types;
  }

  get leftDataType(): DataType | undefined {
    switch (this.model.operandLeft?.type) {
      case "ConnectionColumn":
      case "ConnectionScalar":
        return this.model.operandLeft?.valueType;
      case "CurrentDate":
      case "CurrentMonth":
      case "CurrentDayOfWeek":
        return "Date";
      case "CurrentTime":
        return "Time";
      case "CurrentDateTime":
        return "DateTime";
      case "CurrentDayOfMonth":
      case "CurrentDayOfYear":
      case "CurrentYear":
        return "Number";
      case "QueryString":
        return "String";
      default:
        return undefined;
    }
  }

  get operatorOptions(): { label: string; value: LogicOperation }[] {
    const dataType = this.leftDataType;

    if (typeof dataType === "undefined") {
      return [];
    }

    const operandType = this.model.operandLeft?.type;
    const includeNullOptions =
      !this.model.operandLeft?.type?.startsWith("Current");

    return getOperatorOptions(operandType, dataType, includeNullOptions).map(
      (o) => {
        return {
          label: this.$t(o.key).toString(),
          value: o.operation,
        };
      }
    );
  }

  get rightInputType(): OperandInputValueType {
    const leftOperandType = this.model.operandLeft?.type;
    if (leftOperandType === "CurrentMonth") {
      return "Month";
    }
    if (leftOperandType === "CurrentDayOfWeek") {
      return "DayOfWeek";
    }

    switch (this.leftDataType) {
      case "Bool":
        return "Boolean";
      case "Date":
      case "Time":
      case "DateTime":
      case "Number":
        return this.leftDataType;
      default:
        return "String";
    }
  }

  get rightMin() {
    const lt = this.model.operation === "DtBefore";
    switch (this.model.operandLeft?.type) {
      case "CurrentMonth":
        // Can't choose less than January (index: 0)
        return lt ? 1 : 0;
      case "CurrentDayOfYear":
      case "CurrentDayOfMonth":
        // Can't choose less than 1
        return lt ? 2 : 1;
    }
    return undefined;
  }

  get rightMax() {
    const gt = this.model.operation === "DtAfter";

    switch (this.model.operandLeft?.type) {
      case "CurrentMonth":
        // If current month & greater than, November (index: 10)
        return gt ? 10 : 11;
      case "CurrentDayOfYear":
        // Account for leap years 366 days
        return gt ? 365 : 366;
      case "CurrentDayOfMonth":
        // Some months have 31
        return gt ? 30 : 31;
    }

    return undefined;
  }

  get showAddButton() {
    return this.ruleIsComplete && this.isValid && this.isLastRuleOfGroup;
  }

  get isValid() {
    return !this.store.invalidRules.includes(this.model.uuid);
  }

  get isLastRuleOfCondition() {
    const rules = this.store.groups.at(-1)?.rules;
    return rules?.at(-1)?.uuid === this.model.uuid;
  }

  get isLastRuleOfGroup() {
    const group = this.store.groups.find((g) => g.uuid === this.groupUuid);
    const rules = group?.rules;
    return rules?.at(-1)?.uuid === this.model.uuid;
  }

  get showOrButton() {
    return this.isLastRuleOfCondition;
  }

  addOrRule() {
    if (this.store.totalRules >= 10) {
      window.alert(this.$t("ConditionsEditor.maxRules").toString());
      return;
    }
    this.store.addRule();
  }

  addRule() {
    if (this.store.totalRules >= 10) {
      window.alert(this.$t("ConditionsEditor.maxRules").toString());
      return;
    }
    this.store.addRule(this.groupUuid);
  }
}
</script>
