<template>
  <router-link
    :to="{ name: 'edit', params: { id: uuid } }"
    class="group rounded-[8px] block h-[160px]"
  >
    <div
      class="rounded-[8px] cursor-pointer bg-gray-500 bg-cover bg-center bg-no-repeat relative border border-gray-400 h-full hover:border-app-white"
      :class="{
        'dark-form-focus-ring': isSelected,
        'outline-app-teal2 outline outline-offset-4 outline-[2.5px]':
          isPublished,
      }"
      :style="{
        backgroundImage: imageUrl,
      }"
    >
      <div
        v-if="!image && !showDummyImages"
        class="w-full h-full flex justify-center text-xs italic"
      >
        <span class="self-center -mt-6" v-t="'generatingPreview'"></span>
      </div>

      <div
        class="linear-gradient-inner absolute top-0 right-0 bottom-0 left-0 pointer-events-none rounded-[8px]"
      ></div>

      <div
        class="text-[14px] app-name leading-tight absolute bottom-0 left-0 pl-3 pr-2 py-2 flex flex-col space-y-1 w-full"
      >
        <div class="truncate">{{ name }}</div>

        <div class="w-full flex justify-between items-end">
          <div class="text-[12px] opacity-60">{{ width }} x {{ height }}</div>
          <div
            @click.stop.prevent="handleArrowClick"
            class="opacity-70 hover:opacity-100"
          >
            <Icon
              name="ChevronRight"
              style="transform: rotate(90deg) scaleY(1.8) scaleX(1.8)"
            />
          </div>
        </div>
      </div>

      <!-- Dropdown menu: -->
      <portal to="dashboard-2">
        <div
          v-if="isActive"
          :style="dropdownPosition"
          class="absolute rounded-[8px] border border-gray-400 text-[12px] z-50 flex flex-col bg-app-body w-[125px] overflow-hidden"
        >
          <div
            class="flex items-center space-x-[-1px] opacity-75 hover:opacity-100 px-2 py-1 hover:bg-gray-700 w-full flex-grow cursor-pointer"
            @click="doAction('preview')"
          >
            <IconButton icon="Eye" class="icon" />
            <div>Preview</div>
          </div>
          <div
            class="flex items-center space-x-[-1px] opacity-75 hover:opacity-100 px-2 py-1 hover:bg-gray-700 w-full flex-grow cursor-pointer"
            @click="doAction('duplicate')"
          >
            <IconButton icon="Duplicate" class="icon" />
            <div>Duplicate</div>
          </div>
          <div
            class="flex items-center space-x-[-1px] opacity-75 hover:opacity-100 px-2 py-1 hover:bg-gray-700 w-full flex-grow cursor-pointer"
            @click="doAction('copyUrl')"
          >
            <IconButton icon="Link" class="icon" />
            <div>Copy Url</div>
          </div>
          <div
            class="flex items-center space-x-[-1px] opacity-75 hover:opacity-100 px-2 py-1 hover:bg-gray-700 w-full flex-grow cursor-pointer"
            @click="doAction('delete')"
          >
            <IconButton icon="Trash" class="icon" />
            <div>Delete</div>
          </div>
        </div>
      </portal>
    </div>
  </router-link>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import IconButton from "@/views/Dashboard/IconButton.vue";
import { AppListAction } from "@/types";
import { EventBus } from "@/eventbus";
import Icon from "@/components/icons/Icon.vue";

@Component({
  components: {
    IconButton,
    Icon,
  },
})
export default class GridItem extends Vue {
  @Prop(Boolean) isSelected: boolean;
  @Prop(String) name: string;
  @Prop(String) uuid: string;
  @Prop(Boolean) isPublished: boolean;
  @Prop(String) image: string;
  @Prop(Number) width: number;
  @Prop(Number) height: number;
  @Prop(Number) itemWidth: number;

  // This forces update when RecycleScroller is scrolled...
  // Which allows us to update the dropdown position correctly
  @Prop(Number) forceUpdateDropdown: number;

  imgSize = 500;

  isActive = false;

  get dropdownPosition() {
    const bbox = this.$el.getBoundingClientRect();
    const padding = this.isPublished ? 9 : 3;
    // If the bottom of the dropdown is too close to the bottom of the window, move it up
    const offset = Math.abs(bbox.bottom - window.innerHeight) < 100 ? -130 : 0;
    return {
      top: `${
        bbox.top + bbox.height + padding + this.forceUpdateDropdown + offset
      }px`,
      left: `${bbox.right - 125}px`,
      filter: `drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25)`,
    };
  }

  turnOffDropdown() {
    this.isActive = false;
  }

  mounted() {
    EventBus.on("TURN_OFF_DROPDOWN", this.turnOffDropdown);
    document.body.addEventListener("click", this.turnOffDropdown);
  }

  beforeDestroy() {
    EventBus.off("TURN_OFF_DROPDOWN", this.turnOffDropdown);
    document.body.removeEventListener("click", this.turnOffDropdown);
  }

  handleArrowClick() {
    if (!this.isActive) {
      EventBus.emit("TURN_OFF_DROPDOWN");
    }
    this.isActive = !this.isActive;
  }

  doAction(action: AppListAction) {
    if (["duplicate", "delete"].includes(action)) {
      EventBus.emit("OPEN_DASHBOARD_APP_MODAL", {
        action,
        uuid: this.uuid,
        name: this.name,
      });
    } else {
      EventBus.emit("APPS_LIST_ACTION", { action, uuid: this.uuid });
    }
  }

  get aspectRatio() {
    return this.width / this.height;
  }

  get imageUrl() {
    if (this.showDummyImages) {
      return "url('https://placekitten.com/800/600')";
    }
    const height = Math.floor(this.imgSize / this.aspectRatio);
    return `url(${this.image}?w=${this.imgSize}&h=${height})`;
  }

  get showDummyImages() {
    return (
      new URL(this.$route.fullPath, window.location.origin).searchParams.get(
        "dummyImages"
      ) === "true"
    );
  }
}
</script>

<style lang="postcss" scoped>
.icon {
  @apply w-3 h-3 mr-2 cursor-pointer;
}

.linear-gradient-inner {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 21.13%, #000 100%);
}
</style>
