import { api } from "@/api/backend";
import { AppInfo, AppPayload } from "@/types/data";
import { DateTime, Duration } from "luxon";
import { defineStore } from "pinia";

export type AppSortValue = "created" | "modified" | "nameAsc" | "nameDesc";
export type AppSearchParams = {
  search?: string;
  sort?: AppSortValue;
};

export interface AppsState {
  apps: AppInfo[];
  sort: AppSortValue;
  search: string | undefined;
  selectedIndex: number;
}

const lux = (dateString: string) => {
  return DateTime.fromJSDate(new Date(dateString));
};

const appSorters: Record<string, (a: AppInfo, b: AppInfo) => number> = {
  modified: (a, b) => {
    return lux(b.modifiedAt).diff(lux(a.modifiedAt)).milliseconds;
  },
  created: (a, b) => {
    return lux(b.createdOn).diff(lux(a.createdOn)).milliseconds;
  },
  nameAsc: (a, b) => {
    return a.name > b.name ? 1 : -1;
  },
  nameDesc: (a, b) => {
    return a.name > b.name ? -1 : 1;
  },
};

export const useAppsStore = defineStore("apps", {
  state: (): AppsState => {
    return {
      apps: [],
      sort: "modified",
      search: "",
      selectedIndex: -1,
    };
  },

  getters: {
    matchingApps: (state) => {
      const search = state.search ?? undefined;
      const sorter = appSorters[state.sort ?? "modified"];
      return (state.apps as AppInfo[])
        .filter((a) => (search ? a.name.toLowerCase().includes(search) : true))
        .sort(sorter);
    },
    selectedApp(): AppInfo | null {
      return this.selectedIndex >= 0 &&
        this.selectedIndex < this.matchingApps.length
        ? this.matchingApps[this.selectedIndex]
        : null;
    },
  },

  actions: {
    searchApps(params: AppSearchParams) {
      this.selectedIndex = -1;
      this.search = params.search?.toLowerCase() ?? "";

      switch (params.sort) {
        case "created":
        case "modified":
        case "nameAsc":
        case "nameDesc":
          this.sort = params.sort;
          break;
        default:
          this.sort = "modified";
      }
    },

    setSelectedIndex(index: number) {
      this.selectedIndex = index;
    },

    async loadApps() {
      this.apps = await api.get<AppInfo[]>("apps");
    },

    async cloneApp(appId: string, name: string) {
      return api.post<AppPayload>(`apps/${appId}/clone`, { name });
    },

    async saveAsTemplate(appId: string) {
      return api.post<AppPayload>(`apps/${appId}/clone`, {
        isTemplate: true,
      });
    },

    async archiveApp(appUuid: string) {
      return api.put(`apps/${appUuid}/archive`);
    },
  },
});
