<template>
  <div class="grid gap-4 grid-cols-2 auto-rows-min px-4 pt-4">
    <div
      class="flex justify-center items-center bg-gray-500 h-16"
      :key="t.uuid"
      v-for="t in templates"
    >
      {{ t.uuid }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { makeId, range } from "@/utils";
@Component
export default class TemplatesMenu extends Vue {
  templates = range(0, 50).map(i => {
    return {
      uuid: makeId(10)
    };
  });
}
</script>

<style scoped></style>
