<template>
  <svg viewBox="0 0 16 16">
    <polygon
      :key="index"
      v-for="(points, index) in polygons"
      :fill="fill"
      :points="points"
    />
    <circle
      :key="index"
      v-for="(circle, index) in circles"
      :fill="circle.fill"
      :cx="circle.cx"
      :cy="circle.cy"
      :r="circle.r"
      stroke="black"
    />
    <line
      :key="'line-' + i"
      v-for="(line, i) in lines"
      :x1="line.x1"
      :x2="line.x2"
      :y1="line.y1"
      :y2="line.y2"
      stroke="currentColor"
    />

    <g v-if="name === 'tool-image'" fill="currentColor">
      <rect fill-opacity="0.25" x="2" y="3" width="12" height="10" />
      <polygon points="2 13 2 11 6 7 14 13" />
      <circle cx="10" cy="6" r="2" />
      <polygon fill-opacity="0.6" points="12 9 7 13 14 13 14 11" />
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Icon extends Vue {
  @Prop({ type: String, default: "" }) name: string;
  @Prop({ type: String, default: "currentColor" }) fill: string;

  get circles() {
    const lens = { cx: 7, cy: 7, r: 4, fill: "none" };
    switch (this.name) {
      case "tool-circle":
        return [{ cx: 8, cy: 8, r: 5, fill: this.fill }];
      case "tool-clock":
        return [{ cx: 8, cy: 8, r: 5, fill: "none" }];
      case "tool-zoom-in":
        return [lens];
      case "tool-zoom-out":
        return [lens];
      case "tool-zoom":
        return [lens];
      case "ellipses":
        return [
          { cx: 8, cy: 4, r: 1.3, fill: this.fill },
          { cx: 8, cy: 8, r: 1.3, fill: this.fill },
          { cx: 8, cy: 12, r: 1.3, fill: this.fill }
        ];
      default:
        return null;
    }
  }

  get lines() {
    switch (this.name) {
      case "tool-clock":
        return [
          { x1: 7.5, y1: 8, x2: 10.5, y2: 8 },
          { x1: 8, y1: 8, x2: 8, y2: 5.5 }
        ];
      case "tool-zoom-in":
        return [
          { x1: 5, y1: 7, x2: 9, y2: 7 },
          { x1: 7, y1: 5, x2: 7, y2: 9 },
          { x1: 9.83, y1: 9.83, x2: 14, y2: 14 }
        ];
      case "tool-zoom-out":
        return [
          { x1: 5, y1: 7, x2: 9, y2: 7 },
          { x1: 9.83, y1: 9.83, x2: 14, y2: 14 }
        ];
      case "tool-zoom":
        return [{ x1: 9.83, y1: 9.83, x2: 14, y2: 14 }];
      case "tool-plus":
        return [
          { x1: 4, x2: 12, y1: 8, y2: 8 },
          { y1: 4, y2: 12, x1: 8, x2: 8 }
        ];
      case "pencil":
        return [{ x1: 10, y1: 5, x2: 11, y2: 6 }];
      default:
        return null;
    }
  }

  get polygons() {
    switch (this.name) {
      case "arrow-up":
        return ["0 11 8 3 16 11"];
      case "arrow-down":
        return ["0 5 16 5 8 13"];
      case "arrow2":
        return ["2 12 8 3 14 12"];
      case "plus":
        return ["2 7 7 7 7 2 9 2 9 7 14 7 14 9 9 9 9 14 7 14 7 9 2 9"];
      case "minus":
        return ["2 7 14 7 14 9 2 9"];
      case "pencil":
        // 3 12 12 3 13 4 4 13 3 13
        // return ["4 11 11 4 12 5 5 12 3.5 12.5"];
        return ["5 10 10 5 11 6 6 11 4.5 11.5"];

      case "text-left":
        return [
          "2 7.5 8 7.5 8 8.5 2 8.5",
          "2 4.5 14 4.5 14 5.5 2 5.5",
          "2 10.5 11 10.5 11 11.5 2 11.5"
        ];
      case "text-center":
        return [
          "5 7.5 11 7.5 11 8.5 5 8.5",
          "2 4.5 14 4.5 14 5.5 2 5.5",
          "4 10.5 12 10.5 12 11.5 4 11.5"
        ];
      case "text-right":
        return [
          "8 7.5 14 7.5 14 8.5 8 8.5",
          "2 4.5 14 4.5 14 5.5 2 5.5",
          "5 10.5 14 10.5 14 11.5 5 11.5"
        ];
      case "text-justify":
        return [
          "2 7.5 14 7.5 14 8.5 2 8.5",
          "2 4.5 14 4.5 14 5.5 2 5.5",
          "2 10.5 14 10.5 14 11.5 2 11.5"
        ];
      case "text-top":
        return [
          "2 3 14 3 14 4 2 4",
          "7.33578644 12.9497528 8.33578644 12.9497528 8.33578644 6.93051984 9.95421356 8.53553391 10.6613203 7.82842712 7.83578644 5 5 7.82842712 5.70710678 8.53553391 7.33578644 6.93051984"
        ];
      case "text-middle":
        return [
          "2 7.5 14 7.5 14 8.5 2 8.5",
          "7.33578644 14 8.33578644 14 8.33578644 10.9305198 9.95421356 12.5355339 10.6613203 11.8284271 7.83578644 9 5 11.8284271 5.70710678 12.5355339 7.33578644 10.9305198",
          "7.33578644 2 8.33578644 2 8.33578644 5 9.95421356 3.41421356 10.6613203 4.12132034 7.83578644 6.94974747 5 4.12132034 5.70710678 3.41421356 7.33578644 5"
        ];
      case "text-bottom":
        return [
          "2 12 14 12 14 13 2 13",
          "7.5 3 8.5 3 8.5 9 10.1184271 7.41421356 10.8255339 8.12132034 8 10.9497475 5.16421356 8.12132034 5.87132034 7.41421356 7.5 9"
        ];
      case "tool-pointer":
        return [
          "6 3.5 6 11.4497528 7.80813432 9.95914522 8.94381173 12.8042765 10.2394965 12.2874471 9.06110881 9.40800868 11.2225974 9.27112736"
        ];
      case "tool-paragraph":
        return ["9 13 9 4.75 12 4.75 12 3 4 3 4 4.75 7 4.75 7 13"];
      case "tool-headline":
        return ["6 13 6 9 10 9 10 13 12 13 12 3 10 3 10 7 6 7 6 3 4 3 4 13"];
      case "tool-square":
        return ["13 13 13 3 3 3 3 13"];
      case "tool-triangle":
        return ["8 4 12 12 4 12"];
      case "tool-pentagon":
        // TODO: not perfectly centered vertically
        return [
          // "8 1 14.657395614066075 5.836881039375369 12.114496766047312 13.663118960624633 3.885503233952689 13.663118960624633 1.3426043859339245 5.836881039375369"
          "8 2 13.706339097770922 6.145898033750315 11.526711513754838 12.854101966249685 4.473288486245162 12.854101966249685 2.293660902229078 6.145898033750316"
        ];
      case "border-inside":
        return [
          "2 2 2 5 4 5 4 11 2 11 2 14 5 14 5 12 11 12 11 14 14 14 14 11 12 11 12 5 14 5 14 2 11 2 11 4 5 4 5 2 3 2 3 3 5 3 5 5 11 5 12 3 13 3 13 4 11 5 11 11 13 11 13 13 11 13 11 11 5 11 5 13 3 13 3 12 5 11 5 5 3 4 3 3 3 2",
          "3 5 3 4 4 4 4 3 5 3 5 5",
          "3 12 3 11 5 11 5 13 4 13 4 12",
          "11 13 11 11 13 11 13 12 12 12 12 13",
          "11 5 11 3 12 3 12 4 13 4 13 5"
        ];

      case "border-center":
        return [
          "2 2 2 5 3 5 3 11 2 11 2 14 5 14 5 13 11 13 11 14 14 14 14 11 13 11 13 5 14 5 14 2 11 2 11 3 5 3 5 2 3 2 3 3 5 3 5 4 11 4 11 3 13 3 13 5 12 5 12 11 13 11 13 13 11 13 11 12 5 12 5 13 3 13 3 11 4 11 4 5 3 5 3 3 3 2",
          "3 5 3 4 4 4 4 3 5 3 5 5",
          "3 12 3 11 5 11 5 13 4 13 4 12",
          "11 13 11 11 13 11 13 12 12 12 12 13",
          "11 5 11 3 12 3 12 4 13 4 13 5"
        ];

      case "border-outside":
        return [
          "2 2 2 14 14 14 14 2 3 2 3 3 13 3 13 13 3 13 3 3 3 2",
          "3 5 3 4 4 4 4 3 5 3 5 5",
          "3 12 3 11 5 11 5 13 4 13 4 12",
          "11 13 11 11 13 11 13 12 12 12 12 13",
          "11 5 11 3 12 3 12 4 13 4 13 5"
        ];

      case "distribute-horizontally":
        return [
          "2 2 2 14 3 14 3 2",
          "13 2 13 14 14 14 14 2",
          "6 4 6 12 10 12 10 4"
        ];

      case "distribute-vertically":
        return [
          "2 2 2 3 14 3 14 2",
          "2 14 14 14 14 13 2 13",
          "4 6 4 10 12 10 12 6"
        ];

      case "align-left":
        return ["2 2 2 14 3 14 3 2", "4 6 4 10 12 10 12 6"];

      case "align-center":
        return ["7.5 2 7.5 14 8.5 14 8.5 2", "4 6 4 10 12 10 12 6"];

      case "align-right":
        return ["13 2 13 14 14 14 14 2", "4 6 4 10 12 10 12 6"];

      case "align-top":
        return ["2 2 2 3 14 3 14 2", "6 4 6 12 10 12 10 4"];

      case "align-middle":
        return ["2 7.5 2 8.5 14 8.5 14 7.5", "6 4 6 12 10 12 10 4"];

      case "align-bottom":
        return ["2 13 2 14 14 14 14 13", "6 4 6 12 10 12 10 4"];

      case "data-connection":
        return ["7 14 11 7 9 7 9 2 5 9 7 9"];
    }
    return null;
  }
}
</script>
