import { Node } from "@tiptap/vue-2";
import { DATA_TOKEN_TYPE } from "./index";

export interface DataTokenOptions {
  HTMLAttributes: Record<string, any>;
}

export const DataToken = Node.create<DataTokenOptions>({
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,
  name: DATA_TOKEN_TYPE,
  addAttributes() {
    return {
      uuid: {
        default: null,
        renderHTML: (attributes: any) => {
          return {
            "data-uuid": attributes.uuid,
          };
        },
        parseHTML: (element: any) => {
          return {
            uuid: element.getAttribute("data-uuid"),
          };
        },
      },
      text: {
        default: "",
        renderHTML: (attributes: any) => {
          return {
            "data-text": attributes.text,
          };
        },
        parseHTML: (element: any) => {
          return {
            text: element.getAttribute("data-text"),
          };
        },
      },
    };
  },
  renderHTML({ node, HTMLAttributes }) {
    return ["span", HTMLAttributes, node.attrs["text"]];
  },
  parseHTML() {
    return [
      {
        tag: "span[data-uuid]",
      },
    ];
  },
});
