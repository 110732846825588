<template>
  <portal to="EditorPanel">
    <slot />
  </portal>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ComponentEditorPanel extends Vue {}
</script>
