<template>
  <div class="relative w-full my-4">
    <div
      class="absolute top-0 h-1 w-full rounded-full border-0"
      :class="{
        'bg-gray-300': !dataBound,
        'bg-yellow-200': dataBound,
      }"
    ></div>
    <div
      :style="fillStyle"
      class="absolute top-0 h-1 rounded-full border-0"
      :class="{
        'bg-app-teal': !dataBound,
        'bg-yellow-200': dataBound,
      }"
    ></div>
    <input
      class="slider-input"
      :class="{ 'is-data-bound': dataBound }"
      type="range"
      @input="onInput"
      :value="value"
      :min="min"
      :max="max"
      :step="step"
      @change="onChange"
      :disabled="isDisabled"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class SliderInput extends Vue {
  @Prop(Number) value: number;
  @Prop({ type: Number, default: 0 }) min: number;
  @Prop({ type: Number, default: Infinity }) max: number;
  @Prop({ type: Number, default: 1 }) step: number;
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) dataBound: boolean;

  @Watch("value")
  valueChanged(v: number) {
    // console.log("slider val", v);
  }

  onInput(e: InputEvent) {
    this.$emit("input", parseFloat((e.target as HTMLInputElement).value));
  }

  onChange(e: InputEvent) {
    this.$emit("change", parseFloat((e.target as HTMLInputElement).value));
  }

  linearScale(value: number) {
    return 100 * ((value - this.min) / (this.max - this.min));
  }

  get isDisabled() {
    return this.disabled || this.dataBound;
  }

  get fillStyle() {
    return {
      left: 0,
      right: `${100 - this.linearScale(this.value)}%`,
    };
  }
}
</script>

<style scoped>
/* */

/* input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #21c5f0;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #21c5f0;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}  */
</style>
