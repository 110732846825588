<template>
  <transition
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import gsap from "gsap";

@Component
export default class ExpandTransition extends Vue {
  @Prop({ type: Number, default: 0.25 }) duration: number;

  turnOffModalScroll() {
    const modalScrollers = document.querySelectorAll(".modal-scroll-body");
    modalScrollers.forEach((modalScroller) => {
      const el = modalScroller as HTMLElement;
      el.setAttribute("_overflow", el.style.overflow);
      el.style.overflow = "hidden";
    });
  }

  restoreModalScroll() {
    const modalScrollers = document.querySelectorAll(".modal-scroll-body");
    modalScrollers.forEach((modalScroller) => {
      const el = modalScroller as HTMLElement;
      const value = el.getAttribute("_overflow");
      if (value !== null) {
        el.style.overflow = value;
        el.removeAttribute("_overflow");
      }
    });
  }

  beforeEnter(el: HTMLElement) {
    console.log("beforeEnter");
    this.turnOffModalScroll();
    gsap.set(el, { height: "0px", opacity: 0 });
  }

  enter(el: HTMLElement, done: () => void) {
    console.log("enter");
    gsap
      .timeline({
        onComplete: () => {
          done();
          this.restoreModalScroll();
        },
      })
      .to(el, { height: "auto", opacity: 1, duration: this.duration });
  }

  leave(el: HTMLElement, done: () => void) {
    console.log("leave");
    this.turnOffModalScroll();
    gsap
      .timeline({
        onComplete: () => {
          done();
          this.restoreModalScroll();
        },
      })
      .to(el, { opacity: 0, height: "0px", duration: this.duration });
  }
}
</script>
