<template>
  <div class="mt-4">
    <div class="uppercase tracking-wider text-gray-400 text-xs">
      Supported data formats
    </div>
    <div class="text-sm space-x-1">
      <span>Excel,</span> <span>CSV,</span> <span>TSV,</span> <span>XML,</span>
      <span>JSON</span> <span>&amp;</span>
      <span>iCalendar</span>
    </div>
  </div>

  <!-- <div class="inline-block border border-app-dark1 bg-black bg-opacity-20">
    <div
      class="bg-app-dark2 border-b border-app-dark1 py-2 text-gray-200 text-center"
      v-t="'SupportedFormats.supportedDataFormats'"
    ></div>

    <div class="flex p-3 space-x-8">
      <div :key="f.name" v-for="f in formats">
        <div
          class="uppercase tracking-wider text-gray-400 text-xs text-center"
          v-text="f.name"
        ></div>
        <div class="flex">
          <div :key="l.name" v-for="(l, index) in f.links">
            <Link class="mx-1" :href="l.link" target="_blank">{{ l.name }}</Link
            ><span class="text-white mr-px" v-if="index < f.links.length - 1"
              >,</span
            >
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import Link from "@/components/Link.vue";
@Component({
  components: { Link },
})
export default class SupportedFormats extends Vue {
  get formats() {
    return [
      {
        name: "Table Data",
        links: [
          {
            name: "Excel",
            link: "https://en.wikipedia.org/wiki/Microsoft_Excel",
          },
          {
            name: "CSV",
            link: "https://en.wikipedia.org/wiki/Comma-separated_values#Example",
          },
          {
            name: "TSV",
            link: "https://en.wikipedia.org/wiki/Tab-separated_values#Example",
          },
        ],
      },
      {
        name: "Tree Data",
        links: [
          {
            name: "XML",
            link: "https://en.wikipedia.org/wiki/XML",
          },
          {
            name: "JSON",
            link: "https://en.wikipedia.org/wiki/JSON#Syntax",
          },
        ],
      },
      {
        name: "Calendar",
        links: [
          {
            name: "iCalendar",
            link: "https://en.wikipedia.org/wiki/ICalendar#Technical_specifications",
          },
        ],
      },
    ];
  }
}
</script>
