<template>
  <div>
    <div>Api</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Api extends Vue {}
</script>
