<template>
  <div>
    <div
      class="fixed inset-0 flex justify-center items-center bg-gray-500 z-50"
      :style="{ 'backdrop-filter': blurBackground ? 'blur(10px)' : '' }"
      :class="[dimBackgroundFully ? 'bg-opacity-90' : 'bg-opacity-50']"
    ></div>
    <div
      ref="overlay"
      class="fixed inset-0 flex items-center justify-center z-50"
    >
      <div
        ref="modalBody"
        class="bg-app-dark2 text-white flex flex-col"
        @mousedown.stop
        :class="classes"
      >
        <div
          class="pl-6 flex flex-row-reverse items-center justify-between"
          v-if="showHeader"
        >
          <button
            v-if="showCloseBtn"
            type="button"
            class="p-6 inline-flex rounded-md text-white focus:outline-none hover:text-black"
            @click.prevent="$emit('close')"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <slot name="title">
            <div v-if="title" class="text-lg">{{ title }}</div>
          </slot>
        </div>
        <div class="flex flex-col flex-grow overflow-y-auto modal-scroll-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

// so confused...where is this portal's destination?
@Component
export default class Modal extends Vue {
  @Prop({ type: String, default: "1/3" }) size: string;
  @Prop({ default: false }) ignorePadding: boolean;
  @Prop({ type: Boolean, default: false }) showCloseBtn: boolean;
  @Prop({ type: String, default: undefined }) title: string;
  @Prop({ type: Boolean, default: true }) closeOnEscape: boolean;
  @Prop({ type: Boolean, default: true }) blurBackground: boolean;
  @Prop({ type: Boolean, default: true }) dimBackgroundFully: boolean;

  onOverlayMousedown() {
    this.$emit("close");
  }

  mounted() {
    (this.$refs.overlay as HTMLElement).addEventListener(
      "mousedown",
      this.onOverlayMousedown
    );
    document.addEventListener("keydown", this.onKeyDown);
  }

  beforeDestroy() {
    const overlay = this.$refs.overlay as HTMLElement;
    if (overlay) {
      overlay.removeEventListener("mousedown", this.onOverlayMousedown);
    }
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown(e: KeyboardEvent) {
    if (this.closeOnEscape && e.code === "Escape") {
      this.$emit("close");
    }
  }

  get showHeader() {
    return this.showCloseBtn || this.title;
  }

  get classes() {
    if (this.size === "fill") {
      return "fixed inset-6 rounded-lg shadow-lg";
    }
    if (this.size === "cover") {
      return "fixed inset-0";
    }
    return this.size ? `w-${this.size} rounded-lg shadow-lg` : "";
  }
}
</script>
