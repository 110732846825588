<template>
  <div class="relative z-40" @click.stop>
    <button
      type="button"
      class="shadow text-white rounded px-3 py-1 dark-form-focus"
      :class="{
        'bg-gradient-to-b from-gray-600 to-gray-700 hover:from-gray-500 hover:to-gray-600':
          !expanded,
        'bg-gray-600': expanded,
      }"
      @click="toggle"
    >
      Export&hellip;
    </button>
    <div class="block">
      <div
        class="origin-top-right absolute right-0 mt-2 -mr-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        v-show="expanded"
      >
        <div
          class="py-1 block text-sm text-gray-700 hover:text-gray-900"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="option-menu"
        >
          <button
            class="flex w-full px-4 py-2 text-left whitespace-nowrap hover:bg-gray-100"
            role="menuitem"
            @click="exportData('json')"
          >
            <!-- <Icon class="h-5 w-5" name="Duplicate" /> -->
            <span v-t="'exportAsJson'"></span>
          </button>

          <button
            class="flex w-full px-4 py-2 text-left whitespace-nowrap hover:bg-gray-100"
            role="menuitem"
            @click="exportData('csv')"
          >
            <!-- <Icon class="h-5 w-5" name="Template" /> -->
            <span v-t="'exportAsCsv'"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import { auth } from "@/api/authService";
import { appSettings } from "@/appSettings";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

// TODO: error handling

@Component({
  components: {
    ButtonGradient,
  },
})
export default class FileDownloader extends Vue {
  expanded = false;

  close() {
    this.expanded = false;
    document.removeEventListener("click", this.close);
  }

  open() {
    this.expanded = true;
    document.addEventListener("click", this.close);
  }

  toggle() {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  }

  exportData(fileType: "csv" | "json") {
    const store = useConnectionEditorStore();
    const connection = store.connection;
    if (connection === null) {
      return;
    }
    const endpoint = `${appSettings.backEnd.baseUrlAndPath}/dataconnection/${connection.uuid}/export?exportFormat=${fileType}`;
    const datetime = new Date().toLocaleString("en-US", { hour12: false });
    const fileName = `${
      connection?.name || "DataConnection"
    }_${datetime}.${fileType}`;
    this.downloadFile({ endpoint, fileName });
    this.close();
  }

  async downloadFile(args: { endpoint: string; fileName: string }) {
    const { endpoint, fileName } = args;
    const accessToken = await auth.getAccessToken();
    if (!accessToken) throw "No access token";

    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", endpoint);
    //form.setAttribute("target", "_blank");

    const btInput = document.createElement("input");
    btInput.setAttribute("type", "hidden");
    btInput.setAttribute("name", "bearerToken");
    btInput.setAttribute("value", `Bearer ${accessToken}`);
    form.appendChild(btInput);

    const fnInput = document.createElement("input");
    fnInput.setAttribute("type", "hidden");
    fnInput.setAttribute("name", "fileName");
    fnInput.setAttribute("value", fileName);
    form.appendChild(fnInput);

    document.body.appendChild(form);

    form.submit();

    document.body.removeChild(form);
  }
}
</script>
