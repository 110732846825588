<template>
  <Modal size="fill">
    <div
      id="data-manager"
      @keydown.esc="cancelClickHandler"
      class="select-none w-full h-full flex flex-col bg-app-dark3 rounded-xl shadow-lg text-white"
    >
      <!-- Header/tabs: -->
      <div
        class="px-8 py-4 rounded-tl-xl rounded-tr-xl flex-grow-0 w-full bg-app-dark0 flex justify-between items-center"
      >
        <BreadcrumbNavigation class="text-xl" :links="links" />

        <OutlineButton
          class="p-2 border-2 border-gray-600"
          customGradient="transparent"
          @click="cancelClickHandler"
          v-t="'close'"
        >
        </OutlineButton>
      </div>

      <div class="flex-grow flex flex-col overflow-auto p-6">
        <router-view></router-view>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import Modal from "@/components/Modal.vue";
import BreadcrumbNavigation from "@/components/BreadcrumbNavigation.vue";
import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  APP_EDITOR_ROUTE_PATH,
} from "@/constants";
import OutlineButton from "@/components/OutlineButton.vue";
import { useConnectionSourceStore } from "@/stores/connectionSource";
import { Route } from "vue-router";

/**
 * NOTE: This component is used in App Builder, not the Dashboard
 */

const sourceStore = useConnectionSourceStore();
const route = useRoute();
const router = useRouter();

const links = computed(() => {
  // Adding "shouldRefresh=false" to this path should ensure that when user clicks "Make Connection" to go back, we do not refresh connections list
  const basePath = `/${APP_EDITOR_ROUTE_PATH}/${route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}/new?shouldRefresh=false`;

  const links: any[] = [
    {
      path: basePath,
      text: "Make Connection",
    },
  ];

  if (route.path.includes("preview")) {
    links.push({
      text: "Preview Data",
    });
  } else if (route.path.includes("select")) {
    links.push({
      text: `Select ${route.query.type || "Node"}`,
    });
  } else if (route.path.includes("new/")) {
    let source =
      route.name === "new-editor-connection-setup-v2"
        ? getSourceFromSlug(route)
        : getSourceFromUrl(route.path);

    if (source) {
      links.push({
        path: `/${APP_EDITOR_CONNECTION_ROUTE_PATH}/new`,
        text: "Create",
      });

      let sourceName = source?.displayName ?? "Unknown Source";
      links.push({ text: sourceName });
    } else {
      links.push({ text: "Create" });
    }
  }

  /**
   * If we only have one breadcrumb (i.e. if user is at start of process), do not render as a link
   */
  if (links.length === 1) delete links[0].path;

  return links;
});

function cancelClickHandler() {
  router.push(`/${APP_EDITOR_ROUTE_PATH}/${route.params.id}`);
}

/**
 * @deprecated New implementation should use `getSourceFromSlug()` instead.
 */
function getSourceFromUrl(path: string) {
  const parts = path.split("/new/");
  if (parts.length > 1) {
    const sourceId = parts[1].split("/")[0];
    return sourceStore.getSourceByName(sourceId);
  }
}

function getSourceFromSlug(route: Route) {
  const slug = route.params.providerSlug;
  if (slug) return sourceStore.getSourceByName(slug);
}
</script>
