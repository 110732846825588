<template>
  <div>
    <div class="text-settings px-4">
      <div class="my-3 flex space-x-4">
        <FontFamilyInput
          class="w-38"
          :value="fontFamily"
          @input="apply({ fontFamily: $event })"
        />
        <FontSizeInput :value="fontSize" @input="apply({ fontSize: $event })" />
      </div>

      <div class="flex my-3">
        <FontVariantInput
          :family="fontFamily"
          :value="fontVariant"
          @input="onVariantSelected"
          class="w-40"
        />

        <ScalarBinder
          propName="textColor"
          propType="Color"
          v-slot:default="slotProps"
        >
          <ColorInput
            class="ml-6"
            iconSize="5"
            :value="slotProps.value"
            :dataBound="slotProps.isBound"
            @input="apply({ textColor: $event }, 'NO_UNDO')"
            @change="apply({ textColor: $event })"
          />
        </ScalarBinder>
      </div>
      <div class="flex w-full px-3">
        <div class="line-height flex items-center mr-12 space-x-1">
          <Icon name="LineHeight" class="w-6 h-6 mr-px" />
          <EditorNumberInput
            class="w-14"
            :step="0.1"
            :controls="true"
            :value="lineHeight"
            @change="apply({ lineHeight: $event })"
          />
        </div>
        <div class="letter-spacing flex items-center space-x-1">
          <Icon name="LetterSpacing" class="w-6 h-6 mr-px" />
          <EditorNumberInput
            class="w-14"
            :controls="true"
            :value="letterSpacing"
            @change="apply({ letterSpacing: $event })"
          />
        </div>
      </div>

      <div class="py-3 px-1 flex space-x-10">
        <EditorRadioInput
          type="icon"
          class="mt-2 w-24"
          :value="alignHorizontal"
          :options="hAlignOptions"
          @input="apply({ alignHorizontal: $event })"
        />

        <TextTransformInput
          class="mt-2 w-24"
          :value="textTransform"
          @input="updateTextTransform"
        />
      </div>
    </div>

    <!-- Date/time options: -->
    <div class="flex flex-col space-y-3 px-4 mb-3 mt-1">
      <div>
        <div>
          <ToggleInput v-model="useCurrentTime"
            ><span class="text-sm" v-t="'clock.useCurrentTime'"></span
          ></ToggleInput>
        </div>
      </div>

      <div v-if="!useCurrentTime">
        <div class="text-sm text-gray-500 ml-2" v-t="'clock.value'"></div>

        <ScalarBinder
          propName="datetimeValue"
          propType="DateTime"
          v-slot:default="slotProps"
          tooltipPosition="l"
        >
          <input
            :readonly="slotProps.isBound"
            type="datetime-local"
            :class="{
              'border border-app-gold': slotProps.isBound,
              'hover:ring-1': !slotProps.isBound,
            }"
            class="w-52 truncate hover:bg-white select-none focus:bg-white text-gray-900 relative focus:ring-1 focus:ring-app-teal focus:outline-none rounded-sm px-2 py-1 text-left"
            :value="renderValue(slotProps.value)"
            @change="handleChange"
        /></ScalarBinder>
      </div>

      <div>
        <div class="text-sm text-gray-500 ml-2" v-t="'clock.format'"></div>
        <EditorSelectMenu :options="formatOptions" v-model="datetimeFormat" />
      </div>

      <div>
        <ToggleInput v-model="useBrowserLocale"
          ><span class="text-sm" v-t="'clock.specify'"></span
        ></ToggleInput>

        <div v-if="!useBrowserLocale">
          <div
            class="text-sm text-gray-500 ml-2 pt-3"
            v-t="'clock.locale'"
          ></div>
          <EditorSelectMenu
            :options="localeOptions"
            v-model="userSpecifiedLocale"
          />
        </div>
      </div>
      <div v-if="useCurrentTime">
        <ToggleInput
          class="ml-2 my-2"
          :value="model.usePlayerTimezone"
          @input="apply({ usePlayerTimezone: $event })"
          ><span class="text-sm" v-t="'clock.timezone'"></span
        ></ToggleInput>
        <TimeZoneSelect
          v-if="!model.usePlayerTimezone"
          :value="model.timezone"
          @selectTimeZone="apply({ timezone: $event })"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import DatetimeFormatInput from "@/components/inputs/DatetimeFormatInput.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import EditorRadioInput from "@/components/inputs/EditorRadioInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import FontSizeInput from "@/components/inputs/FontSizeInput.vue";
import ColorInput from "@/components/inputs/ColorInput.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import FontVariantInput from "@/components/inputs/FontVariantInput.vue";
import TextTransformInput from "@/components/inputs/TextTransformInput.vue";
import TimeZoneSelect from "@/components/TimeZoneSelect.vue";

import {
  CUSTOM_DATETIME_FORMATS,
  DatetimeOptions,
  LOCALE_OPTIONS,
} from "@/components/widgets/Datetime/DatetimeOptions";
import { formatDatetime } from "@/components/widgets/Datetime/DatetimeComponent.vue";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { DateTime } from "luxon";
import { NO_UNDO, FontVariant } from "@/types";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    DatetimeFormatInput,
    ButtonGradient,
    EditorSelectMenu,
    ToggleInput,
    CollapsePanel,
    EditorRadioInput,
    FormLabel,
    FontFamilyInput,
    Icon,
    FontSizeInput,
    ColorInput,
    EditorNumberInput,
    BaseWidgetEditor,
    ScalarBinder,
    FontVariantInput,
    TimeZoneSelect,
    TextTransformInput,
  },
})
export default class DatetimeEditor extends Vue {
  localeOptions = LOCALE_OPTIONS;

  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as DatetimeOptions;
  }

  apply(props: { [key: string]: any }, NO_UNDO?: NO_UNDO) {
    this.appEditor.setWidgetProps(this.selections, props, NO_UNDO);
  }

  get formatOptions() {
    const date = DateTime.fromObject({
      year: 2032,
      month: 2,
      day: 19,
      hour: 14,
      minute: 45,
      second: 30,
    });
    // Ugly that this is repeated in component
    const locale = this.useBrowserLocale ? null : this.userSpecifiedLocale;

    return CUSTOM_DATETIME_FORMATS.map((format) => {
      const formatString = JSON.stringify(format);
      return {
        label: formatDatetime(date, { format: formatString, locale }),
        value: formatString,
      };
    });
  }

  get fontWeight() {
    return this.getValue("fontWeight");
  }

  get fontStyle() {
    return this.getValue("fontStyle");
  }

  get fontFamily() {
    return this.getValue("fontFamily");
  }

  get fontSize() {
    return this.getValue<number>("fontSize");
  }

  get letterSpacing() {
    return this.getValue("letterSpacing");
  }

  get lineHeight() {
    return this.getValue("lineHeight");
  }

  get alignHorizontal() {
    return this.getValue<string>("alignHorizontal");
  }

  get fontVariant() {
    return { style: this.fontStyle, weight: this.fontWeight };
  }

  onVariantSelected(variant: FontVariant) {
    this.apply({ fontWeight: variant.weight, fontStyle: variant.style });
  }

  getValue<T>(key: string): T {
    let result: unknown;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as T[];
      result = values[0];
    }
    return result as T;
  }

  get hAlignOptions() {
    return ["Left", "Center", "Right"].map((text) => ({
      label: `TextAlign${text}`,
      value: text,
    }));
  }

  get textTransform() {
    return this.getValue("textTransform");
  }

  updateTextTransform(value: string) {
    if (value === this.textTransform) {
      this.apply({ textTransform: "none" });
    } else {
      this.apply({ textTransform: value });
    }
  }

  get datetimeFormat() {
    return this.model?.datetimeFormat ?? "DATE_SHORT";
  }
  set datetimeFormat(value: any) {
    this.apply({ datetimeFormat: value });
  }

  get userSpecifiedLocale() {
    return this.model?.userSpecifiedLocale ?? null;
  }
  set userSpecifiedLocale(value: string | null) {
    this.apply({ userSpecifiedLocale: value });
  }

  get useBrowserLocale() {
    return this.model?.useBrowserLocale ?? true;
  }
  set useBrowserLocale(value: boolean) {
    this.apply({ useBrowserLocale: value });
  }

  get useCurrentTime() {
    return this.model?.useCurrentTime ?? true;
  }
  set useCurrentTime(value: boolean) {
    this.apply({ useCurrentTime: value });
  }

  get datetimeValue() {
    return this.model?.datetimeValue;
  }

  set datetimeValue(_value: string) {
    // Not used -- rely on parsedDatetimeValue instead, because that is format that input of type "datetime-local" requires
  }

  handleChange(ev: any) {
    this.apply({ datetimeValue: ev.target.value });
  }

  renderValue(val: string) {
    // The html input of type "datetime-local" requires the datetime to be in this format...I guess..
    return DateTime.fromISO(val).toFormat("yyyy-MM-dd'T'HH:mm:ss");
  }
}
</script>
