<template>
  <div class="pie-graph relative" v-if="data && data.length > 0">
    <div
      v-if="showLegend"
      class="legend flex"
      ref="legend"
      :style="legendStyles"
    >
      <div
        class="legend-item flex items-center m-2"
        :key="labelIndex"
        v-for="(item, labelIndex) in arcs"
      >
        <svg
          class="box flex-shrink-0"
          viewbox="0 0 100 100"
          :width="legend_fontSize * scaleX"
          :height="legend_fontSize * scaleY"
        >
          <rect x="0" y="0" :width="100" :height="100" :fill="item.color" />
        </svg>
        <div class="label ml-2">
          {{ item.label }}
        </div>
      </div>
    </div>
    <svg
      class="chart"
      :height="height"
      :width="width"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        class="arc"
        :key="index"
        v-for="(item, index) in arcs"
        :transform="`translate(${width / 2}, ${height / 2})`"
        :fill="item.color"
        :stroke="lineColor"
        :stroke-width="lineThickness"
      >
        <path :d="item.path" />
        <text
          v-if="showValues"
          :transform="`translate(${item.centroid})`"
          text-anchor="middle"
          :style="getStyles('value')"
          :font-size="value_fontSize"
          stroke="none"
        >
          {{ valuesAsPercent ? `${item.percent}%` : item.value }}
        </text>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import * as d3 from "d3";
import { NodeData } from "@/types/data";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CSSProperties } from "vue/types/jsx";

@Component({})
export default class PieGraphComponent extends Vue {
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(String) readonly value_fontFamily: string;
  @Prop(Number) readonly value_fontSize: number;
  @Prop(Number) readonly value_fontWeight: number;
  @Prop(String) readonly value_fontStyle: string;
  @Prop(String) readonly value_textColor: string;
  @Prop(String) readonly legend_fontFamily: string;
  @Prop(Number) readonly legend_fontSize: number;
  @Prop(Number) readonly legend_fontWeight: number;
  @Prop(String) readonly legend_fontStyle: string;
  @Prop(String) readonly legend_textColor: string;
  @Prop(String) readonly legend_textTransform: string;
  @Prop(String) readonly lineColor: string;
  @Prop(Number) readonly lineThickness: number;
  @Prop(Boolean) readonly showValues: boolean;
  @Prop(Boolean) readonly valuesInside: boolean;
  @Prop(Boolean) readonly valuesAsPercent: boolean;
  @Prop(Boolean) readonly showLegend: boolean;
  @Prop(Number) readonly legendMargin: number;
  @Prop(String) readonly legendPosition: string;
  @Prop(String) readonly wid: string;
  @Prop(String) readonly xAxisColumnId: string;
  @Prop(String) readonly yAxisColumnId: string;
  @Prop(Array) readonly colors: string[];
  @Prop(Boolean) readonly lockAspect: boolean;
  @Prop({ type: Array, default: () => [] }) readonly data: NodeData[][];

  get width() {
    return this.w * this.scaleX;
  }

  get height() {
    return this.h * this.scaleY;
  }

  get graphWidth() {
    return this.width - this.lineThickness;
  }

  get graphHeight() {
    return this.height - this.lineThickness;
  }

  get radius() {
    return Math.min(this.graphWidth, this.graphHeight) / 2;
  }

  get labels() {
    if (this.data.length > 0) {
      return this.data.map((row) => {
        return row.find((c) => c.uuid === this.yAxisColumnId)
          ?.formattedValue as string;
      });
    }
    return [] as string[];
  }

  get values() {
    if (this.data.length > 0) {
      let values: any = [];

      this.data.map((row) => {
        values.push(
          parseInt(
            row.find((c) => c.uuid === this.xAxisColumnId)?.value as string
          )
        );
      });

      return values;
    }
    return [];
  }

  get arcs() {
    let total = this.values.reduce((a: number, b: number) => a + b, 0);

    let pie = d3.pie()(this.values);
    let arcs = this.data.map((r, index) => {
      let value = parseInt(
        r.find((c) => c.uuid === this.xAxisColumnId)?.value as string
      );
      let label =
        r.find((c) => c.uuid === this.yAxisColumnId)?.formattedValue ?? "";
      let d: any = d3.arc();
      let arc = d3
        .arc()
        .innerRadius(0)
        .outerRadius(this.radius)
        .startAngle(pie[index].startAngle)
        .endAngle(pie[index].endAngle);
      let path = d({
        innerRadius: 0,
        outerRadius: this.radius,
        startAngle: pie[index].startAngle,
        endAngle: pie[index].endAngle,
      });
      let centroid = arc.centroid(d);
      let color = this.colors[index];

      return {
        label: label,
        value: value,
        percent: ((value / total) * 100).toFixed(0),
        path: path,
        centroid: centroid,
        color: color,
      };
    });
    return arcs;
  }

  getStyles(prefix: string) {
    const vm = this as any;
    const result: any = {};
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontSize`]) {
      result.fontSize = vm[`${prefix}_fontSize`] * this.scaleX;
    }
    if (vm[`${prefix}_fontWeight`] && prefix) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.fill = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }

    return result;
  }

  get legendStyles() {
    let result: CSSProperties;

    const unconditionalProperties: CSSProperties = {
      position: "absolute",
      display: "flex",
      flexWrap: "wrap",

      fontFamily: this.getStyles("legend").fontFamily,
      fontSize: this.getStyles("legend").fontSize + "px",
      fontWeight: this.getStyles("legend").fontWeight,
      color: this.getStyles("legend").fill,
      fontStyle: this.getStyles("legend").fontStyle,
      textTransform: this.getStyles("legend").textTransform,
    };

    switch (this.legendPosition) {
      case "Top":
        {
          let conditionalProperties = {
            flexDirection: "row" as const,
            bottom: `${this.graphHeight + this.legendMargin}px`,
            left: "0%",
            alignItems: "center",
            justifyContent: "center",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      case "Bottom":
        {
          let conditionalProperties = {
            flexDirection: "row" as const,
            top: `${this.graphHeight + this.legendMargin}px`,
            left: "0%",
            height: `${this.graphHeight / 5}px`,
            alignItems: "center",
            justifyContent: "center",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      case "Left":
        {
          let conditionalProperties = {
            flexDirection: "column" as const,
            top: "0%",
            right: `${this.graphWidth + this.legendMargin}px`,
            height: `${this.graphHeight}px`,
            alignItems: "flex-start",
            justifyContent: "flex-start",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      case "Right":
        {
          let conditionalProperties = {
            flexDirection: "column" as const,
            top: "0%",
            left: `${this.graphWidth * 1.05 + this.legendMargin}px`,
            height: `${this.graphHeight}px`,
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      default:
        {
          let conditionalProperties = {
            flexDirection: "row" as const,
            top: "0%",
            left: "0%",
            height: `${this.graphHeight / 5}px`,
            alignItems: "flex-start",
            justifyContent: "flex-start",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
    }

    return result;
  }
}
</script>

<style></style>
