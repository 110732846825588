<template>
  <div class="space-y-8 p-12">
    <div class="text-center italic text-xl">
      <div>
        If you're interested in modifying your design based on data, time, day,
        or weather, then you're in the right place.
      </div>
      <div>Here are a few things you should know before you get started.</div>
    </div>

    <div
      class="border border-gray-500 divide-y divide-gray-500 rounded mx-auto max-w-[640px]"
    >
      <ConditionsIntroStep
        @click="activeStep = 1"
        :number="1"
        :expanded="activeStep === 1"
      >
        <template slot="title">
          First create the &#8216;IF&#8217;, then design the &#8216;THAT&#8217;
        </template>
        This feature allows you to tell the design “if this, then that…”. Your
        first step is to define what the &#8216;IF&#8217; is (the condition).
        Later when you are back in the Canvas you&#8217;ll be able to make the
        design changes that represent the &#8216;THAT&#8217; (the result).
      </ConditionsIntroStep>

      <ConditionsIntroStep
        @click="activeStep = 2"
        :number="2"
        :expanded="activeStep === 2"
      >
        <template slot="title">
          Conditions are applied to objects on the canvas
        </template>
        When you create a condition, it is applied with the object that is
        currently selected. After creating your condition and returning to the
        Canvas view - you will be given a toggle to make changes to that object
        for each condition you create. Change the color, position, etc under
        each condition and Connect will display that version when a condition is
        met. If you want more than one object to change, you can simply copy the
        condition and paste it to other objects.
      </ConditionsIntroStep>

      <ConditionsIntroStep
        @click="activeStep = 3"
        :number="3"
        :expanded="activeStep === 3"
      >
        <template slot="title"> Using AND/OR in a Condition </template>
        When creating a condition you can choose to add AND and OR statements to
        your conditions. Some examples are: “If the day is Saturday AND
        inventory is 0, then…”, or “If the day is Saturday OR the day is Sunday,
        then…” .
      </ConditionsIntroStep>

      <ConditionsIntroStep
        @click="activeStep = 4"
        :number="4"
        :expanded="activeStep === 4"
      >
        <template slot="title">Create Multiple Conditions</template>
        <div>
          Creating multiple conditions allows you to adjust your design based on
          multiple scenarios. After creating multiple conditions, you will be
          able to define the order that they should be evaluated. The first
          condition that is evaluated to true will be used. You will be able to
          change the design of your object(s) for each condition.
        </div>
        <div>
          For example: You may also create multiple conditions to apply to your
          design, then have the design change differently for each. For example:
          A user may be evaluating a customer feedback score between 1-10. One
          condition may be triggered when the score is 1-3, another when it is
          4-7 and another when it is 8-10. Each condition can apply different
          design changes.
        </div>
      </ConditionsIntroStep>

      <ConditionsIntroStep
        @click="activeStep = 5"
        :number="5"
        :expanded="activeStep === 5"
      >
        <template slot="title">Ask for Help</template>
        If you are struggling, please be sure to reach out for support.
      </ConditionsIntroStep>
    </div>

    <div class="w-full flex items-center justify-center flex-wrap">
      <OutlineButton
        type="link"
        href="https://youtu.be/_JebjmuBf3c"
        target="_blank"
        class="flex items-center space-x-2 mr-8"
      >
        <svg
          class="w-4 h-4"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15" cy="15" r="14.5" stroke="#808080" />
          <path
            d="M11.625 9.83734L21 15.25L11.625 20.6627L11.625 9.83734Z"
            stroke="#808080"
          />
        </svg>

        <div class="whitespace-nowrap">3-Minute Tutorial</div>
      </OutlineButton>

      <OutlineButton
        @click="addCondition"
        class="whitespace-nowrap bg-app-green"
        style="border-color: transparent"
      >
        Create a Condition
      </OutlineButton>
    </div>

    <label
      @click="skipIntro = !skipIntro"
      class="w-full justify-end flex items-center space-x-3 italic"
    >
      <span>Skip this information in the future</span>

      <CheckBox :checked="skipIntro" />
    </label>
  </div>
</template>

<script lang="ts">
import { useConditionEditorStore } from "@/stores/conditionEditor";
import { Component, Vue } from "vue-property-decorator";
import { userPreferences } from "@/userPreferences";

import OutlineButton from "@/components/OutlineButton.vue";
import ConditionsIntroStep from "./ConditionsIntroStep.vue";
import CheckBox from "../logic/CheckBox.vue";
@Component({
  components: {
    OutlineButton,
    ConditionsIntroStep,
    CheckBox,
  },
})
export default class ConditionsIntro extends Vue {
  newClicked = false;

  activeStep = 1;
  skipIntro = true;

  get createRoute() {
    return {
      path: "/conditions/new",
    };
  }

  get store() {
    return useConditionEditorStore();
  }

  showVideo() {
    alert("This will show a video");
  }

  addCondition() {
    if (this.skipIntro) {
      userPreferences.set("Conditions.SkipIntroHelp", true);
    }

    this.$emit("continue");
  }
}
</script>
