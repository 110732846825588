<template>
  <div class="flex-grow flex flex-col">
    <div class="mb-8" v-if="sheetOptions.length > 1">
      <div class="mb-2 text-lg">Which sheet do you want to use?</div>
      <TabInput
        :value="rangeId"
        @input="onSheetSelected"
        :options="sheetOptions"
      />
    </div>

    <SelectTableValue
      :data="tableData"
      :selectedNode="selectedNode"
      :propertyType="propertyType"
      @select="onSelect"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api/backend";
import {
  NodeData,
  ConnectionDataResponse,
  NodeSetData,
  TableSchema,
} from "@/types/data";

import TabInput from "@/components/inputs/TabInput.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import SelectTableValue from "@/components/data/connections/setup/selection/SelectTableValue.vue";

@Component({
  components: { TabInput, UiBlocker, FormButton, SelectTableValue },
})
export default class SelectSpreadsheetValue extends Vue {
  @Prop(String) connectionUuid: string;
  @Prop(String) propertyType: string;
  @Prop(Array) sheets: TableSchema[];
  @Prop(String) rangeId: string;

  isLoading = false;
  selectedNode: NodeData | null = null;
  sheetData: Record<string, NodeData[][]> = {};

  //

  created() {
    if (
      typeof this.rangeId === "string" &&
      this.sheets.some((s) => s.rangeId === this.rangeId)
    ) {
      this.onSheetSelected(this.rangeId);
    } else if (this.sheets.length === 1) {
      this.onSheetSelected(this.sheets[0].rangeId);
    }
  }

  onSelect(selectedNode: NodeData) {
    this.selectedNode = selectedNode;
    this.$emit("select", selectedNode);
  }

  get tableData(): NodeData[][] {
    return this.sheetData[this.rangeId] ?? [];
  }

  get sheetOptions() {
    return this.sheets.map((g) => {
      return {
        label: g.title,
        value: g.rangeId,
      };
    });
  }

  @Watch("rangeId", { immediate: true })
  onRangeIdChanged(rangeId: string) {
    if (rangeId === null) {
      return;
    }

    const dcUuid = this.connectionUuid;
    const sheet = this.sheets.find((s) => s.rangeId === rangeId);
    const nodeSetUuid = sheet?.schema?.[0]?.uuid;

    if (!nodeSetUuid) {
      throw new Error("unable to find nodeset uuid from selected sheet schema");
    }

    if (Array.isArray(this.sheetData[rangeId])) {
      return Promise.resolve();
    }

    this.isLoading = true;
    api
      .get<ConnectionDataResponse>(
        `dataconnection/${dcUuid}/data?nodeSetUuid=${nodeSetUuid}`
      )
      .then((response) => {
        const rows = (response.data as NodeSetData).children as NodeData[][];
        Vue.set(this.sheetData, rangeId, rows);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onSheetSelected(rangeId: string) {
    this.$emit("sheetSelected", rangeId);
  }
}
</script>
