<template>
  <div class="relative flex flex-col flex-grow space-y-6">
    <!-- HEADER -->
    <div class="flex items-center justify-between">
      <div class="text-xl space-x-1">
        <span v-t="'SelectScalarNode.selectA'"></span>
        <span class="text-app-gold" v-if="propertyTypeDisplay">{{
          propertyTypeDisplay
        }}</span>
        <span v-t="'SelectScalarNode.value'"></span>
      </div>

      <div class="relative top-2" v-if="hasSelection">
        <div
          class="absolute left-1/2 transform -translate-x-1/2 text-center bottom-full uppercase tracking-wider text-gray-400 text-xs"
          v-t="'SelectScalarNode.selection'"
        ></div>
        <div class="overflow-hidden max-w-[320px]">
          <DataNodeDisplay
            class="whitespace-nowrap truncate text"
            v-bind="nodeDisplayProps"
          />
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <FormButton
          type="button"
          class="px-3 py-1 text-sm"
          :disabled="!hasSelection"
          @click="onCompleteSelection"
          v-text="selectButtonText"
        ></FormButton>
        <OutlineButton
          class="px-3 py-1 text-sm"
          @click="onCancel"
          v-t="'cancel'"
        >
        </OutlineButton>
      </div>
    </div>

    <div class="relative flex flex-col flex-grow">
      <div class="flex flex-col flex-grow">
        <SelectTreeValue
          v-if="schemaType === 'Tree'"
          :rootNode="treeData"
          :selectedQuery="candidate.query"
          :selectionType="targetType"
          @select="onNodeSelected"
        />

        <SelectTableValue
          v-if="schemaType === 'Tabular'"
          :data="tableData"
          :showHeaders="true"
          :selectedNode="candidate"
          :propertyType="targetType"
          @select="onNodeSelected"
          @back="$emit('back')"
        />
      </div>
      <UiBlocker :visible="loading">
        <span v-t="'SelectScalarNode.loadingData'"></span>
      </UiBlocker>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api/backend";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import {
  ConnectionDataResponse,
  DataConnection,
  DataType,
  DataValue,
  NodeData,
  NodeKind,
  NodeSetData,
  SchemaType,
} from "@/types/data";

import FormButton from "@/components/FormButton.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import SelectTreeValue from "@/components/data/connections/setup/selection/SelectTreeValue.vue";
import SelectTableValue from "@/components/data/connections/setup/selection/SelectTableValue.vue";
import DataNodeDisplay from "@/components/data/DataNodeDisplay.vue";
import OutlineButton from "@/components/OutlineButton.vue";

type TreeNode = {
  uuid: string;
  children?: TreeNode[];
  dataType: DataType;
  kind: NodeKind;
  name: string;
  parentUuid: string;
  query: string;
  value: DataValue;
};

const findTreeNode = (
  nodes: TreeNode[],
  uuid: string
): TreeNode | undefined => {
  if (nodes.length == 0) {
    return undefined;
  }
  return (
    nodes.find((d) => d.uuid == uuid) ||
    findTreeNode(
      nodes.flatMap((d) => d.children || []),
      uuid
    )
  );
};

@Component({
  components: {
    UiBlocker,
    FormButton,
    SelectTreeValue,
    SelectTableValue,
    DataNodeDisplay,
    OutlineButton,
  },
})
export default class SelectScalarNode extends Vue {
  @Prop(String) connectionUuid: string;
  @Prop(String) nodeUuid: string;
  @Prop(String) nodeQuery: string;
  @Prop(String) targetType: DataType;

  loading = false;
  connection: DataConnection | null = null;

  schemaType: SchemaType | null = null;
  treeData: TreeNode | null = null;
  tableData: NodeData[][] | null = null;

  // Store the node the user clicks on,
  // Not emitted until the "Save" button clicked
  candidate: Partial<NodeData> = {};

  resetState() {
    console.log("RESETTING STATE");
    this.connection = null;
    this.schemaType = null;
    this.treeData = null;
    this.tableData = null;
    this.candidate = {};
  }

  get selectButtonText() {
    return "Use Selection";
  }

  get propertyTypeDisplay() {
    if (!isNonEmptyString(this.targetType)) {
      return "";
    }
    switch (this.targetType) {
      case "Bool":
        return "true / false";
      case "Date":
        return "date";
      case "Time":
        return "time";
      case "DateTime":
        return "date & time";
      case "Number":
        return "number";
      default:
        return "text";
    }
  }

  get hasSelection() {
    return isNonEmptyString(this.candidate?.uuid);
  }

  get selectedNode() {
    if (this.schemaType === "Tree") {
      if (!isNonEmptyString(this.nodeUuid)) {
        return undefined;
      }
      return findTreeNode(
        this.treeData?.children || [],
        this.nodeUuid
      ) as TreeNode;
    } else {
      if (
        !isNonEmptyString(this.nodeUuid) ||
        !isNonEmptyString(this.nodeQuery)
      ) {
        return undefined;
      }
      const flatData = (this.tableData || []).flat();
      return flatData.find(
        (n) => n.uuid === this.nodeUuid && n.query === this.nodeQuery
      ) as NodeData;
    }
  }

  // get selectedQuery() {
  //   if (this.schemaType === "Tree") {
  //     return this.selection.query ?? this.nodeQuery;
  //   } else {
  //     return this.nodeQuery;
  //   }
  // }

  get nodeDisplayProps() {
    const node = this.candidate;
    if (typeof node === "undefined") {
      return undefined;
    }

    const result = {
      uuid: node.uuid,
      dataType: node.dataType,
      value: {
        value: node.value,
        formattedValue: node.formattedValue,
      },
    };

    return result;
  }

  ////////////////////////////////////////////////////////////////////////////

  getConnection() {
    if (
      this.connection !== null &&
      this.connection.uuid === this.connectionUuid
    ) {
      return Promise.resolve(this.connection);
    }

    return api
      .get<DataConnection>(`dataconnection/${this.connectionUuid}`)
      .then((c) => {
        this.connection = c;
        return c;
      });
  }

  @Watch("connectionUuid", { immediate: true })
  onConnectionUuidChanged(uuid: string) {
    this.resetState();
    if (!isNonEmptyString(uuid)) {
      return;
    }

    this.loading = true;

    this.getConnection()
      .then((connection) => {
        api
          .get<ConnectionDataResponse>(`dataconnection/${connection.uuid}/data`)
          .then((result) => {
            const dataResponse = result.data;

            // DETERMINE SCHEMA TYPE ==================================
            if (connection.isCollection === true) {
              this.schemaType = "Tabular";
            } else if (dataResponse.kind === "NodeObject") {
              this.schemaType = "Tree";
            } else if (dataResponse.kind === "NodeSet") {
              const isTable = (dataResponse as NodeSetData).children.some((c) =>
                Array.isArray(c)
              );

              this.schemaType = isTable ? "Tabular" : "Tree";
            }

            // STORE SCHEMA  ==================================
            if (this.schemaType === "Tabular") {
              this.tableData = (dataResponse as NodeSetData)
                .children as NodeData[][];
            }

            if (this.schemaType === "Tree") {
              // We need to completely redefine our Data types. Too confusing.
              this.treeData = dataResponse as unknown as TreeNode;
            }

            // EMIT SELECTION (IF POSSIBLE)  ==================
            // We do this so the parent component can have access
            // to the selected node value.
            if (typeof this.selectedNode !== "undefined") {
              const selection = this.mapToSelection(
                this.selectedNode as NodeData
              );
              this.candidate = selection;
              this.$emit("load", selection);
            }
          });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onCompleteSelection() {
    this.$emit("change", this.candidate);
  }

  mapToSelection(node: NodeData): Partial<NodeData> {
    const result: Partial<NodeData> = {
      uuid: node.uuid,
      query: node.query,
      dataType: node.dataType,
      groupUuid: node.groupUuid,
      kind: node.kind,
      value: node.value,
      formattedValue: node.formattedValue,
    };

    /**
     * The node returned from SelectTreeValue is actually a TreeSchemaNode and
     * prepresents the `value` as `{ value, formattedValue }`.
     *
     * The node returned from SelectTableValue is a `NodeData` and represents
     * the value as two properties on the `node` itself.
     *
     * To account for this, we'll
     */
    if (typeof node.value === "object") {
      result.value = (node as any).value.value;
      result.formattedValue = (node as any).value.formattedValue;
    }

    return result;
  }

  // For selecting a Node from a Tree-shaped Document
  onNodeSelected(node: NodeData) {
    const mappedNode = this.mapToSelection(node);

    for (const prop in mappedNode) {
      Vue.set(this.candidate, prop, mappedNode[prop as keyof NodeData]);
    }
  }

  onCancel() {
    this.$emit("cancel");
  }
}
</script>
