<script lang="ts">
import { AnimationOptions } from "@/types/animation";
import { Component, Prop } from "vue-property-decorator";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class AnimatableWidget extends BaseWidget {
  @Prop(Object) readonly animations: AnimationOptions;
  @Prop(Number) readonly cycleDuration?: number;
  @Prop(Number) readonly cellIndex?: number;

  @Prop(Number) readonly h: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly x: number;
  @Prop(Number) readonly y: number;
  @Prop(Number) readonly z: number;

  @Prop(Number) readonly angle: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Boolean) readonly canScaleY: boolean;
  @Prop(Boolean) readonly canScaleX: boolean;
  @Prop(Boolean) readonly lockAspect: boolean;

  @Prop(Number) readonly opacity: number;
}
</script>
