<template>
  <div>
    <div
      class="flex items-center justify-between pl-6 pr-3 cursor-pointer hover:bg-gray-100"
      @click="isOpen = !isOpen"
    >
      <div class="h-16 flex items-center text-sm font-bold select-none">
        <slot name="title">{{ title }}</slot>
      </div>
      <Icon class="block w-5 h-5" :name="iconName" />
    </div>
    <div v-if="isOpen">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import { EventBus } from "@/eventbus";

@Component({
  components: { Icon },
})
export default class CollapsePanel extends Vue {
  @Prop(Boolean) open: boolean;
  @Prop(String) title: string;
  @Prop({ default: false }) shouldListenForOpenCommand: boolean;

  isOpen = false;

  get iconName() {
    return this.isOpen ? "ChevronDown" : "ChevronRight";
  }

  listenForOpenCommand() {
    console.log("Returning from open command");
    if (!this.shouldListenForOpenCommand) return;

    console.log("Listening for open command");
    this.isOpen = true;
  }

  created() {
    this.isOpen = this.open;
    EventBus.on("OPEN_DATA_PANEL", this.listenForOpenCommand);
  }

  beforeDestroy() {
    EventBus.off("OPEN_DATA_PANEL", this.listenForOpenCommand);
  }

  collapse() {
    this.isOpen = false;
  }
}
</script>
