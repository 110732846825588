import { defineStore } from "pinia";
import Vue from "vue";
import {
  DataConnection,
  NodeSetData,
  DataBindingUsageInfo,
  SchemaNode,
  ModerationMode,
  GetConnectionBindingsResult,
  EditSchemaResult,
} from "@/types/data";
import { api, BackendError } from "@/api/backend";
import { logger } from "@core/logger";
import { EventBus } from "@/eventbus";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionsStore } from "@/stores/connections";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionDataStore } from "./connectionData";

export interface ConnectionReqInfo {
  dataConnectionUuid: string;
  appUuid?: string;
  skipSetConnection?: boolean;
}

export interface RemapCollectionLogicRefsPayload {
  targetConnectionUuid: string;
  appUuid: string;
  logicUuids: string[];
  sourceNodeToTargetNode?: Record<string, string>;
}

export interface RemapCollectionLogicRefsInfo
  extends RemapCollectionLogicRefsPayload {
  sourceConnectionUuid: string;
}
export interface ConnectionEditorState {
  connection: DataConnection | null;
  schema: any | null;

  /**
   * Bindings that are currently in use in Published Apps only.
   */
  dataBindingUsageInfo: DataBindingUsageInfo[] | null;

  /**
   * Binding mappings for Published Apps that no longer exist in the data source.
   * Determined server side
   */
  brokenBindings: DataBindingUsageInfo[] | null;

  errorCode: string | null;
  errorMessage: string | null;
  dataUuid: string | null;
  isConnectionInitialized: boolean;
  isConnectionSuccess: boolean;
  isLoading: boolean;
  isEditMode: boolean;
  selectedModerationPrimaryKeyNodeQuery: string | null;
  moderationPrimaryKeyNodeCandidates: Partial<SchemaNode>[];
  missingSchemaNodes: SchemaNode[] | null;
  schemaInfoUuid: string | null;
  lastDataSourceUpdate: Date | null;
  nodesRequiringRemap: SchemaNode[];
  logicRefsRequiringRemap: string[];
  previouslySelectedConnection: DataConnection | null;
}

export const useConnectionEditorStore = defineStore("connectionEditor", {
  state: (): ConnectionEditorState => {
    return {
      connection: null,
      schema: null,
      dataUuid: null,
      dataBindingUsageInfo: null,
      brokenBindings: null,
      errorCode: null,
      errorMessage: null,
      isConnectionInitialized: false,
      isConnectionSuccess: false,
      isLoading: false,
      isEditMode: false,
      selectedModerationPrimaryKeyNodeQuery: null,
      moderationPrimaryKeyNodeCandidates: [],
      missingSchemaNodes: null,
      schemaInfoUuid: null,
      lastDataSourceUpdate: null,
      nodesRequiringRemap: [],
      logicRefsRequiringRemap: [],
      previouslySelectedConnection: null,
    };
  },
  getters: {
    moderationMode(): ModerationMode {
      return this.connection?.moderationMode || null;
    },

    isEditable(): boolean {
      return this.connection?.canEditData || false;
    },

    canModerate(): boolean {
      return this.connection?.canModerate || false;
    },
  },

  actions: {
    // MUTATIONS ============================================

    setConnection(connection: DataConnection | null) {
      this.connection = connection;
      this.errorCode = connection?.faultErrorCode ?? null;
      this.errorMessage = connection?.faultErrorMessage ?? null;
    },

    updateConnection(connection: DataConnection) {
      this.connection = { ...this.connection, ...connection };
    },

    setLastSourceUpdate(date?: string | Date) {
      if (typeof date === "string") {
        try {
          this.lastDataSourceUpdate = new Date(date);
        } catch (err) {
          logger.track(`setLastSourceUpdate: error parsing date ${date}`);
        }
      }
      if (date instanceof Date) {
        this.lastDataSourceUpdate = date;
      }
    },

    setRefreshRate(refreshRate: number) {
      if (this.connection !== null) {
        Vue.set(this.connection, "refreshRateSec", refreshRate);
      }
    },

    setModerationMode(moderationMode: ModerationMode) {
      if (this.connection) {
        if (this.connection.moderationMode && moderationMode === null)
          this.connection.shouldRemoveModeration = true;

        this.connection.moderationMode = moderationMode;
      }
    },

    // ACTIONS ============================================

    /**
     * synchronizeData
     * updateDataConnection
     */

    async updateRemapLogicRefs(payload: RemapCollectionLogicRefsInfo) {
      const {
        sourceConnectionUuid,
        appUuid,
        logicUuids,
        sourceNodeToTargetNode,
        targetConnectionUuid,
      } = payload;

      return api.post(`dataconnection/${sourceConnectionUuid}/logic/map`, {
        appUuid,
        logicUuids,
        sourceNodeToTargetNode,
        targetConnectionUuid,
      });
    },

    async updateSheetSelection(args: { connectionUuid: string; nodes: any }) {
      const { connectionUuid, nodes } = args;
      const connection = await this.getConnection({
        dataConnectionUuid: connectionUuid,
      });
      // console.log("conn in update sheet", connection);
      // Needs name -- could pass in default name or do what we're doing now, fetch connection info
      return api.put<DataConnection>(`dataconnection/${connectionUuid}`, {
        ...connection,
        nodes,
      });
    },

    async getConnection(payload: ConnectionReqInfo) {
      const { dataConnectionUuid, appUuid, skipSetConnection } = payload;
      let url = `dataconnection/${dataConnectionUuid}`;
      if (appUuid) {
        url += `?appUuid=${appUuid}`;
      }
      return api.get<DataConnection>(url).then((res) => {
        if (!skipSetConnection) this.setConnection(res);
        return res || null;
      });
    },

    async getConnectionBindings(payload: { dcUuid: string }) {
      this.isLoading = true;
      EventBus.emit("AWAITING_SERVER", true);

      return api
        .get<GetConnectionBindingsResult>(
          `dataconnection/${payload.dcUuid}/databindings`
        )
        .then((res) => {
          /**
           * Do NOT setConnection here, because the dataConnection comes back without nodeSets!
           */

          this.dataBindingUsageInfo = res.appBindingInfo;
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
          this.isLoading = false;
        });
    },

    clearErrors() {
      this.errorCode = null;
      this.errorMessage = null;
    },

    async deleteConnection(connection: DataConnection) {
      this.clearErrors();
      this.isLoading = true;

      if (!connection) return;

      EventBus.emit("AWAITING_SERVER", true);
      return api
        .delete(`dataconnection/${connection.uuid}`)
        .catch((err) => {
          this.errorCode = "deleteError";
          // TODO
          // this.setErrorMessage(TranslationKeys.deleteError);
          throw err;
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
          this.isLoading = false;
        });
    },

    removeDeletedConnection(connection: DataConnection) {
      const connectionsStore = useConnectionsStore();
      connectionsStore.removeConnection(connection.uuid);
    },

    async cloneConnection(args: { dcUuid: string; name: string }) {
      this.clearErrors();
      this.isLoading = true;
      EventBus.emit("AWAITING_SERVER", true);

      return api
        .post<DataConnection>(`dataconnection/${args.dcUuid}/clone`, {
          name: args.name,
        })
        .catch((err) => {
          this.errorCode = "cloneError";
          // TODO
          // this.setErrorMessage(TranslationKeys.cloneError);
          throw err;
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
          this.isLoading = false;
        });
    },

    async synchronizeData() {
      if (this.connection === null) {
        return Promise.reject(
          "No Connection Loaded in this. Can't synchronize"
        );
      }

      const { uuid, moderationMode } = this.connection;

      this.clearErrors();
      this.isLoading = true;

      return api
        .post<EditSchemaResult>(`dataconnection/${uuid}/schema`, {})
        .then((res) => {
          this.setConnection(res.dataConnection);
          this.dataBindingUsageInfo = res.appBindings;
          this.brokenBindings = res.brokenBindings;
          this.missingSchemaNodes = res.missingNodes;
          this.schemaInfoUuid = res.schemaInfoUuid;
          this.setLastSourceUpdate(res.lastDataSourceUpdate as Date);
        })
        .then(async (): Promise<string> => {
          const store = useConnectionDataStore();
          const connection = this.connection as DataConnection;

          /**
           * If a user presses "Sync Now" on a non-collection connection, we need to load the data
           */
          if (
            connection.isCollection === false &&
            connection.schemaType !== "Calendar" &&
            typeof connection.moderationMode !== "string"
          ) {
            await store.loadReadOnlyData({ uuid: connection.uuid });
            return connection.uuid;
          }

          /**
           * Afterwards, we need to update our copy of the data on the front end (rows + schema).
           * And if we are in app editor, must refresh widget's data -- accomplished by invalidating the data cache for this connection.
           * If dataset is moderated, must use moderated data endpoint instead.
           */

          useAppDataStore().invalidateCacheForConnection(connection.uuid);

          await store.refreshConnectionData({
            connectionId: uuid,
            isModerated: moderationMode !== null,
            updateSchema: true,
          });

          return connection.uuid;
        })
        .then((connectionId) => {
          EventBus.emit("DATA_CONNECTION_SYNCHRONIZED", { uuid: connectionId });
        })
        .catch((err: BackendError[] | string | null) => {
          let code = "unknown";
          // TODO
          // let message = TranslationKeys.unknownError;
          let message = "";

          if (Array.isArray(err) && err.length > 0) {
            code = err[0].code;
            message = err[0].message;
          } else if (typeof err === "string") {
            message = err;
          }

          this.errorCode = code;
          this.errorMessage = message;

          return Promise.reject(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    /**
     * TODO: We should always send in entire Connection...or pull it in here somehow... because in usage, we send partial info into this.
     * That's why we need the hard coded refreshRate.
     *
     * But..is this where the "interpret 'Never' to mean 'convert to manual'" is supposed to happen?
     */
    async updateDataConnection(connection: Partial<DataConnection>) {
      this.clearErrors();

      this.isLoading = true;

      if (this.connection === null) {
        throw new Error("No connection loaded in this. Can't update");
      }

      EventBus.emit("AWAITING_SERVER", true);

      const defaultPayload = this.connection;
      const payload = { ...defaultPayload, ...connection };
      if (!payload.nodes)
        payload.nodes = (payload as any as NodeSetData).children as any;

      /**
       * Fix issue with Json/Xml data sources, which require the parent nodeSet to be included as a node
       */
      if (payload.schemaType == "Tree" && payload.nodes !== undefined)
        payload.nodes.push(this.connection?.nodeSets[0] as any);

      if (
        this.connection?.moderationMode !== null &&
        this.selectedModerationPrimaryKeyNodeQuery !== null &&
        payload.nodes !== undefined
      ) {
        const primaryKeyNodeIndex = payload.nodes.findIndex(
          (n) => n.query === this.selectedModerationPrimaryKeyNodeQuery
        );
        if (primaryKeyNodeIndex !== -1)
          payload.nodes[primaryKeyNodeIndex].isPrimaryKey = true;
      }

      // console.log("edit mode",this.isEditMode);
      // console.log("Put request updateDC", payload);

      const req =
        !this.isEditMode && this.connection.canEditData
          ? api.post<DataConnection>(
              `dataconnection/manualtabledata/schema`,
              payload
            )
          : api.put<DataConnection>(
              `dataconnection/${this.connection?.uuid}`,
              payload
            );

      return req
        .then(async (result) => {
          this.isConnectionSuccess = true;
          const payload = { uuid: result.uuid };
          if (this.isEditMode) {
            EventBus.emit("DATA_CONNECTION_UPDATED", payload);
          } else {
            EventBus.emit("DATA_CONNECTION_CREATED", payload);
          }

          const appEditor = useAppEditorStore();
          const connectionData = useConnectionDataStore();
          const widgetId = appEditor.selectedWidget?.wid;

          if (widgetId !== undefined) {
            await connectionData.initializeConnection(result);
          }

          // Let connectionDataStore know about changes so RecordView (in left hand DataMenu) can reflect changes
          this.connection = result;

          return result;
        })
        .catch((err: BackendError[]) => {
          // this.setErrorCode(err[0]?.code ? err[0].code : "unknown");
          this.errorCode = err[0]?.code ? err[0].code : "unknown";

          // TODO:
          // this.setErrorMessage(
          //   err[0]?.code
          //     ? `errors.${err[0].code}`
          //     : TranslationKeys.unknownError
          // );
          console.log("err with dc update", err);
          throw err;
        })
        .finally(() => {
          this.isLoading = false;
          EventBus.emit("AWAITING_SERVER", false);
        });
    },
  },
});
