import { ref, onMounted, onUnmounted, Ref } from "vue";
import { useI18n } from "vue-i18n-composable";
import debounce from "lodash.debounce";

export type TextValidation = {
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  regex?: RegExp;
};

export function useTextValidation(
  target: Ref<HTMLInputElement | null>,
  validation: TextValidation = {}
) {
  const { t } = useI18n();

  const textInput = ref<string | null>(target.value?.value ?? null);
  const isValid = ref(false);
  const errorMessage = ref<string | null>(null);

  function validateInput() {
    textInput.value = target?.value?.value ?? null;
    isValid.value = validate();
  }

  function validate() {
    if (validation.isRequired && !textInput.value) {
      errorMessage.value = t("Setup.validation.required").toString();
      return false;
    }

    return true;
  }

  const onBlurHandler = (e: FocusEvent) => {
    validateInput();
  };

  const onChangeHandler = debounce(() => {
    validateInput();
  }, 200);

  onMounted(() => {
    target?.value?.addEventListener("blur", onBlurHandler);
    target?.value?.addEventListener("input", onChangeHandler);
  });

  onUnmounted(() => {
    target?.value?.removeEventListener("blur", onBlurHandler);
    target?.value?.addEventListener("input", onChangeHandler);
    errorMessage.value = null;
  });

  return {
    isValid,
    errorMessage,
  };
}
