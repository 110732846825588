<template>
  <div class="flex flex-col">
    <div class="w-full">
      <div class="flex flex-row space-x-1" @click="toggleLock">
        <Icon :name="iconName" class="w-5 h-5" />
        <span v-t="tooltipText"></span>
      </div>
    </div>
    <div
      class="mt-3 px-4 w-full py-3 text-sm text-gray-500 bg-gray-200 rounded border-2"
      v-if="isLocked"
    >
      Unlock the selected item to modify it.
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import { useAppEditorStore } from "@/stores/appEditor";

// TODO: Border style is not exposed anywhere

@Component({
  components: {
    Icon,
    ToggleInput,
  },
})
export default class LockEditor extends Vue {
  lockedProp = "locked";

  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get showEditor() {
    return this.selections.length > 0;
  }

  get isLocked() {
    // If any are locked, treat the whole selection as locked
    const values = (this.selectedProps["locked"] || [false]) as boolean[];
    return values.some((v) => v);
  }

  get iconName() {
    return this.isLocked ? "LockClosed" : "LockOpen";
  }

  get tooltipText() {
    return this.isLocked ? "unlock" : "lock";
  }

  toggleLock() {
    this.appEditor.setWidgetProps(this.selections, {
      locked: !this.isLocked,
    });
  }
}
</script>
