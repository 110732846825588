<template>
  <div class="flex-grow flex flex-col">
    <div class="relative flex-grow bg-app-dark1" v-if="showTable">
      <div class="absolute inset-0 overflow-auto data-grid-container">
        <table class="data-grid text-sm">
          <thead v-if="showHeaders && columnNames.length > 0">
            <tr>
              <th v-for="(name, i) in columnNames" :key="i" v-text="name"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in rows" :key="i">
              <td v-for="(node, j) in row" :key="j">
                <div
                  class="flex space-x-2 border"
                  @click="selectValue(node)"
                  :class="{
                    'border-app-teal bg-app-teal bg-opacity-10':
                      node.isSelected,
                    'border-transparent': !node.isSelected,
                    'cursor-pointer text-white': node.canSelect,
                    'cursor-not-allowed text-gray-500': !node.canSelect,
                    'hover:bg-gray-600 ': node.canSelect && !node.isSelected,
                  }"
                >
                  <div class="truncate-container">
                    <div class="truncate-content">
                      <DataNodeDisplay
                        :class="{
                          'block object-contain pointer-events-none h-5 w-full':
                            node.dataType === 'ImageUrl' ||
                            node.dataType === 'ImageUpload',
                        }"
                        :uuid="node.uuid"
                        :dataType="node.dataType"
                        :value="node.renderValue"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="z-10 absolute top-0 left-0 right-0 border-b border-black">
        <!-- Table top border. Hides some of the under-scroll content -->
      </div>
      <div class="z-10 absolute top-0 left-0 bottom-0 border-r border-black">
        <!-- Table left border. Hides some of the under-scroll content -->
      </div>
      <div
        class="z-10 absolute w-4 h-4 bottom-0 right-0 border-r border-b border-black bg-app-dark1"
      >
        <!-- This is a small square on the bottom right corner. Otherwise a white box shows -->
      </div>
    </div>

    <Modal
      size="1/3"
      :blurBackground="false"
      :dimBackgroundFully="false"
      v-if="showNoSelectableValues || showEmpty || !showTable"
    >
      <div class="flex flex-col items-center p-4 space-y-6">
        <div class="flex flex-col items-center space-y-2">
          <div v-if="showNoSelectableValues">
            This data source doesn't have any
            {{ propertyType.toLowerCase() }} values.
          </div>
          <div v-else>This data source doesn't have any data.</div>
          <div>Please pick a different data source.</div>
        </div>

        <OutlineButton class="mt-6" @click="$emit('back')">Back</OutlineButton>
      </div>
    </Modal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataValue, NodeData } from "@/types/data";
import Modal from "@/components/Modal.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import FormButton from "@/components/FormButton.vue";
import DataNodeDisplay from "@/components/data/DataNodeDisplay.vue";

interface TableValue extends NodeData {
  isSelected: boolean;
  canSelect: boolean;
  renderValue: DataValue;
}

@Component({
  components: { FormButton, DataNodeDisplay, Modal, OutlineButton },
})
export default class SelectTableValue extends Vue {
  @Prop(Boolean) readonly: boolean;
  @Prop(String) propertyType: string;
  @Prop(Array) data: NodeData[][];
  @Prop(Object) selectedNode: NodeData;
  @Prop(Boolean) showHeaders: boolean;

  get showEmpty() {
    return this.rows.length === 0;
  }

  get showTable() {
    return this.rows.length > 0;
  }

  get showNoSelectableValues() {
    return (
      this.readonly === false && this.showTable && !this.hasSelectableValues
    );
  }

  get hasSelectableValues() {
    return this.rows
      .flatMap((row) => row.map((n) => n))
      .some((n) => n.canSelect);
  }

  get columnNames(): string[] {
    if (this.showHeaders && this.rows.length > 0) {
      return this.rows[0].map((n) => n.displayName);
    }
    return [];
  }

  get rows(): TableValue[][] {
    if (!Array.isArray(this.data)) {
      return [];
    }

    return this.data.map((row) => {
      return row
        .filter((n) => n.isArtificial !== true)
        .map((n) => {
          const isSelected =
            this.selectedNode &&
            this.selectedNode.uuid === n.uuid &&
            this.selectedNode.query === n.query &&
            (typeof this.selectedNode.groupUuid === "undefined" ||
              this.selectedNode.groupUuid === n.groupUuid);

          const canSelect = this.readonly === false && this.canSelect(n);

          return Object.assign(n, {
            isSelected,
            canSelect,
            renderValue: {
              value: n.value,
              formattedValue: n.formattedValue,
            },
          }) as TableValue;
        });
    });
  }

  canSelect(node: NodeData) {
    switch (this.propertyType) {
      case "Number":
        return node.dataType === "Number";
      case "Color":
        return node.dataType === "Color";
      case "Bool":
        return node.dataType === "Bool";
      case "Time":
        return node.dataType.endsWith("Time");
      case "Date":
      case "DateTime":
        return node.dataType?.match(/Date|Time/); // Should match "Date", "Time" or "DateTime"
      default:
        return true;
    }
  }

  selectValue(node: TableValue) {
    if (this.readonly === true) {
      return;
    }

    if (this.canSelect(node)) {
      this.$emit("select", node);
    }
  }
}
</script>
