<template>
  <svg
    v-if="data && data.length > 0"
    class="chart"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="chart"
    ref="chart"
    :width="width"
    :height="height"
  >
    <defs>
      <clipPath :id="`bar-clip-${wid}`">
        <rect x1="0" y1="0" :width="graphWidth" :height="graphHeight" />
      </clipPath>
    </defs>
    <g :transform="`translate(${leftMargin}, ${topMargin})`">
      <rect
        class="bg"
        v-if="showBg"
        :width="graphWidth"
        :height="graphHeight"
        x="0"
        y="0"
        :fill="bgColor"
      />
      <g class="grid" :transform="`translate(0, ${graphHeight})`">
        <g
          class="tick"
          text-anchor="middle"
          v-for="(tick, index) in ticks"
          :key="index"
          :transform="`translate(${x(tick) + 0.5}, 0)`"
        >
          <line
            class="gridLine"
            v-if="showGrid"
            :stroke="lineColor"
            opacity="0.2"
            :y2="-graphHeight"
          ></line>
        </g>
      </g>
      <g class="bars" :clip-path="$helpers.svgUrl(`#bar-clip-${wid}`)">
        <rect
          v-for="(bar, index) in bars"
          :fill="bar.color"
          :key="index"
          :height="bar.height"
          :width="bar.width"
          :x="bar.x"
          :y="bar.y"
        ></rect>
        <g v-if="showValues">
          <text
            class="values"
            v-for="(bar, index) in bars"
            :key="index"
            :x="bar.x + bar.width + 10"
            :y="bar.y ? bar.y + bar.height / 2 : bar.height / 2"
            :style="getStyles('label')"
            :font-size="label_fontSize"
            text-anchor="start"
            dominant-baseline="middle"
          >
            {{ bar.value }}
          </text>
        </g>
      </g>
      <g class="x-axis" :transform="`translate(0, ${graphHeight})`">
        <line
          class="domain"
          :stroke="lineColor"
          :stroke-width="lineThickness"
          x1="0"
          :x2="graphWidth"
          y1="0"
        ></line>
        <g
          class="tick"
          text-anchor="middle"
          v-for="(tick, index) in ticks"
          :key="index"
          :transform="`translate(${x(tick) + 0.5}, 0)`"
        >
          <line
            :stroke="lineColor"
            :stroke-width="lineThickness"
            :y1="0 - lineThickness / 2"
            :y2="3 + lineThickness"
          ></line>

          <text
            :style="getStyles('label')"
            :y="9 + lineThickness"
            :dy="label_fontSize"
            :font-size="label_fontSize"
          >
            {{ tick }}
          </text>
        </g>
      </g>
      <g class="y-axis" :transform="`translate(0, 0)`">
        <line
          class="domain"
          :stroke="lineColor"
          :stroke-width="lineThickness"
          x1="0"
          y1="0"
          :y2="graphHeight"
        ></line>
        <g
          class="tick"
          text-anchor="end"
          v-for="(bar, index) in bars"
          :key="index"
          :transform="
            bar.y
              ? `translate(0, ${bar.y + bar.height / 2})`
              : `translate(0, 0)`
          "
        >
          <line
            :stroke="lineColor"
            :stroke-width="lineThickness"
            :x1="0 + lineThickness / 2"
            :x2="-3 - lineThickness"
          ></line>
          <text
            :style="getStyles('label')"
            :x="-9 - lineThickness"
            dy="0.32em"
            :font-size="label_fontSize"
          >
            {{ bar.yLabel }}
          </text>
        </g>
      </g>
    </g>
    <text
      class="x-axis title"
      :transform="xTitleTransform"
      text-anchor="middle"
      :style="getStyles('title')"
      :font-size="title_fontSize"
    >
      {{ valueTitle }}
    </text>
    <text
      class="y-axis title"
      ref="yTitle"
      :transform="yTitleTransform"
      text-anchor="middle"
      :style="getStyles('title')"
      :font-size="title_fontSize"
    >
      {{ labelTitle }}
    </text>
  </svg>
</template>

<script lang="ts">
import * as d3 from "d3";
import { Component, Prop, Vue } from "vue-property-decorator";
import { NodeData } from "@/types/data";
import { StyleValue } from "vue/types/jsx";

@Component({})
export default class BarGraphComponent extends Vue {
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly bottomMargin: number;
  @Prop(Number) readonly barPadding: number;

  @Prop(Number) readonly minValue: number;
  @Prop(Number) readonly maxValue: number;
  @Prop(Number) readonly minManual: number;
  @Prop(Number) readonly maxManual: number;
  @Prop(String) readonly xTitle: string;
  @Prop(String) readonly yTitle: string;
  @Prop(String) readonly xTitleManual: string;
  @Prop(String) readonly yTitleManual: string;

  @Prop(String) readonly title_fontFamily: string;
  @Prop(Number) readonly title_fontSize: number;
  @Prop(Number) readonly title_fontWeight: number;
  @Prop(String) readonly title_fontStyle: string;
  @Prop(String) readonly title_textColor: string;
  @Prop(String) readonly title_textTransform: string;

  @Prop(String) readonly label_fontFamily: string;
  @Prop(Number) readonly label_fontSize: number;
  @Prop(Number) readonly label_fontWeight: number;
  @Prop(String) readonly label_fontStyle: string;
  @Prop(String) readonly label_textColor: string;
  @Prop(String) readonly label_textTransform: string;

  @Prop(String) readonly lineColor: string;
  @Prop(Number) readonly lineThickness: number;
  @Prop(Number) readonly labelTicks: number;
  @Prop(Number) readonly leftTitlePadding: number;
  @Prop(Number) readonly bottomTitlePadding: number;
  @Prop(String) readonly bgColor: string;
  @Prop(String) readonly barColor: string;
  @Prop(Array) readonly customBarColors: string[];
  @Prop(Boolean) readonly useCustomBarColors: boolean;
  @Prop(Boolean) readonly showBg: boolean;
  @Prop(Boolean) readonly showGrid: boolean;
  @Prop(Boolean) readonly showValues: boolean;
  @Prop(String) readonly xAxisColumnId: string;
  @Prop(String) readonly yAxisColumnId: string;
  @Prop(String) readonly wid: string;
  @Prop({ type: Array, default: () => [] }) readonly data: NodeData[][];

  get width() {
    return this.w * this.scaleX;
  }

  get height() {
    return this.h * this.scaleY;
  }

  get graphWidth() {
    return this.width - this.leftMargin - this.rightMargin - this.lineThickness;
  }

  get graphHeight() {
    return (
      this.height -
      70 -
      this.lineThickness -
      this.label_fontSize -
      this.bottomTitlePadding -
      this.title_fontSize
    );
  }

  get rightMargin() {
    return this.showValues ? this.valueWidth : 0;
  }

  get graphXTitle() {
    return this.xTitleManual === null ? this.xTitle : this.xTitleManual;
  }
  get graphYTitle() {
    return this.yTitleManual === null ? this.yTitle : this.yTitleManual;
  }

  get leftMargin() {
    return (
      this.labelWidth +
      this.leftTitlePadding +
      90 +
      this.title_fontSize / 2 +
      this.lineThickness
    );
  }

  get topMargin() {
    return this.label_fontSize;
  }

  get valueTitle() {
    if (this.data && this.data.length > 0) {
      if (this.xTitle != "") {
        return this.graphXTitle;
      }
      return this.data[0].find((c) => c.uuid === this.xAxisColumnId)
        ?.displayName;
    }
    return "";
  }

  get labelTitle() {
    if (this.data && this.data.length > 0) {
      if (this.yTitle != "") {
        return this.graphYTitle;
      }
      return this.data[0].find((c) => c.uuid === this.yAxisColumnId)
        ?.displayName;
    }
    return "";
  }

  get labels() {
    if (this.data.length > 0) {
      return this.data.map((row) => {
        return row.find((c) => c.uuid === this.yAxisColumnId)
          ?.formattedValue as string;
      });
    }
    return [] as string[];
  }

  get values() {
    if (this.data && this.data.length > 0) {
      let values: number[] = [];

      this.data.map((row) => {
        values.push(
          parseInt(
            row.find((c) => c.uuid === this.xAxisColumnId)?.value as string
          )
        );
      });
      return values;
    }
    return [];
  }

  /**
   * NOTE: Since optionValue is a Prop, it doesn't change when user updates data. We probably...want the graph to respond to that. (Issue shows up when user removes highest value from data)
   */
  get xMax() {
    let optionValue = this.maxManual === null ? this.maxValue : this.maxManual;
    return isNaN(optionValue)
      ? Math.floor(Math.min(...this.values))
      : optionValue;
  }

  get xMin() {
    let optionValue = this.minManual === null ? this.minValue : this.minManual;
    return isNaN(optionValue)
      ? Math.floor(Math.min(...this.values))
      : optionValue;
  }

  get x() {
    return d3
      .scaleLinear()
      .range([this.graphWidth, 0])
      .domain([this.xMax, this.xMin]);
  }

  get y() {
    return d3
      .scaleBand()
      .range([0, this.graphHeight])
      .padding(1 - this.barPadding)
      .domain(this.labels);
  }

  get colorArray() {
    return this.customBarColors;
  }

  get ticks() {
    return this.x.ticks(this.labelTicks);
  }

  get bars() {
    let bars = this.data.map((r, index) => {
      let value = parseInt(
        r.find((c) => c.uuid === this.xAxisColumnId)?.value as string
      );
      let label =
        r.find((c) => c.uuid === this.yAxisColumnId)?.formattedValue || "";
      let color = this.useCustomBarColors
        ? this.colorArray[index]
        : this.barColor;

      return {
        yLabel: label,
        value: value,
        x: this.x(this.xMin),
        y: label ? this.y(label) : 0,
        width:
          this.x(this.xMin) + this.x(value) < 0
            ? 0
            : this.x(this.xMin) + this.x(value),
        height: this.y.bandwidth(),
        color: color,
      };
    });
    return bars;
  }

  get xTitleTransform() {
    return `translate(${
      this.graphWidth / 2 + this.leftMargin + this.lineThickness / 2
    }, ${
      this.graphHeight +
      40 +
      this.bottomTitlePadding +
      this.lineThickness +
      this.label_fontSize +
      this.title_fontSize
    } )`;
  }

  get yTitleTransform() {
    return `translate(${
      this.leftMargin - this.labelWidth - this.leftTitlePadding - 40
    }, ${this.graphHeight / 2} ), rotate(-90)`;
  }

  get longestLabel() {
    let longest = this.labels.reduce(function (a: string, b: string) {
      return a.length > b.length ? a : b;
    });
    return longest;
  }

  get longestValue() {
    return Math.max(...this.values);
  }

  get labelWidth() {
    let labelLength = this.longestLabel.length;
    let fontSize = this.label_fontSize;
    return labelLength * (fontSize / 2);
  }

  get valueWidth() {
    let valueLength = this.longestValue.toString().length;
    let fontSize = this.label_fontSize;

    return valueLength * fontSize * 1.5;
  }

  getStyles(prefix: string) {
    const vm = this as any;
    const result: StyleValue = {};
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.fill = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }

    return result;
  }
}
</script>

<style></style>
