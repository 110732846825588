<template>
  <div class="pb-2">
    <div
      class="mb-1 text-xs leading-none text-gray-700 font-semibold"
      v-if="name"
    >
      {{ name }}
    </div>
    <div class="color-grid grid gap-1">
      <button
        :key="`${name}-${index}`"
        v-for="(c, index) in values"
        type="button"
        @click="select(c)"
        class="h-5 w-5 swatch-outline"
        :class="{ 'swatch-transparent': c === 'hsla(0, 0%, 0%, 0)' }"
        :style="{ backgroundColor: c }"
      ></button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ColorPanelPalette extends Vue {
  @Prop(String) name: string;
  @Prop(Array) values: any[];

  select(color: string) {
    this.$emit("select", color);
  }
}
</script>

<style scoped lang="postcss">
.color-grid {
  grid-template-columns: repeat(9, 1fr);
  grid-auto-rows: min-content;
}
</style>
