<template>
  <div class="pb-8 divide-y">
    <div v-if="isBaseEditingContext" class="divide-y">
      <CollapsePanel :shouldListenForOpenCommand="true">
        <template slot="title">
          <div v-t="'data'"></div>
        </template>
        <div class="px-4 py-3">
          <DataSetChooser
            :isRepeater="true"
            @clickRemove="clickRemoveDataBinding"
          />
        </div>

        <div v-if="isGrid && hasDataSet" class="px-4 py-3">
          <div
            class="mb-2 ml-2 text-sm text-gray-500"
            v-t="'repeaterEditor.repeatDirection'"
          ></div>

          <div class="grid grid-cols-2">
            <button
              class="border border-transparent rounded-sm"
              :key="o.value"
              v-for="o in flowOptions"
              :class="{ 'shadow-md border-gray-300': o.value === model.flow }"
              @click="apply({ flow: o.value })"
            >
              <Tooltip :text="o.label">
                <img :src="o.image" class="w-full h-24" />
              </Tooltip>
            </button>
          </div>
        </div>

        <div class="px-4 py-3" v-if="hasDataSet">
          <ToggleInput
            :value="model.cycleContent"
            @input="apply({ cycleContent: $event })"
            ><span v-t="'repeaterEditor.cycleContent'"></span
          ></ToggleInput>
          <div
            class="mt-1 px-3 text-xs text-gray-500"
            v-t="'repeaterEditor.cycleExplain'"
          ></div>
          <div v-if="model.cycleContent">
            <div class="mt-3 flex justify-between">
              <div
                class="whitespace-nowrap"
                v-t="'repeaterEditor.cyclePreview'"
              ></div>
              <div class="flex justify-between items-center">
                <button
                  type="button"
                  class="text-gray-500"
                  @click.stop="prevPage"
                >
                  ◀
                </button>
                <div class="mx-2 text-xs font-mono">
                  {{ page + 1 }} / {{ pageCount }}
                </div>
                <button
                  type="button"
                  class="text-gray-500"
                  @click.stop="nextPage"
                >
                  ▶
                </button>
              </div>
            </div>
            <div
              class="mt-1 px-3 text-xs text-gray-500"
              v-t="'repeaterEditor.cyclePreviewExplain'"
            ></div>
            <div class="mt-3 flex justify-between">
              <div
                class="whitespace-nowrap"
                v-t="'repeaterEditor.cycleDuration'"
              ></div>

              <EditorNumberInput
                class="w-16"
                controls
                :value="model.cycleDuration"
                @change="apply({ cycleDuration: $event })"
              />
            </div>
            <div
              class="mt-1 px-3 text-xs text-gray-500"
              v-t="'repeaterEditor.cycleDurationExplain'"
            ></div>

            <div class="my-3">
              <div
                class="whitespace-nowrap mb-1"
                v-t="'repeaterEditor.cycleAnimationStyle'"
              ></div>

              <SelectMenu
                :value="model.cycleAnimationStyle"
                @input="apply({ cycleAnimationStyle: $event })"
                :options="animationStyles"
              />
            </div>
          </div>

          <ToggleInput class="mt-3" v-model="shouldRandomize"
            ><span v-t="'repeaterEditor.randomizeData'"></span
          ></ToggleInput>
          <div
            class="mt-1 px-3 text-xs text-gray-500"
            v-t="'repeaterEditor.randomizeExplain'"
          ></div>

          <div v-if="!shouldRandomize" class="mt-2">
            <ToggleInput v-model="sortEnabled">
              <span v-t="'repeaterEditor.dataSort'"></span>
            </ToggleInput>
            <div class="flex flex-col mt-2 space-y-3" v-if="sortEnabled">
              <SelectMenu
                class="w-40"
                v-model="orderByDataUuid"
                :options="dataColumnOptions"
              />
              <!-- Custom radio input for sortDirection: -->
              <div class="w-full flex rounded border">
                <div
                  class="w-1/2 flex justify-center items-center cursor-pointer py-1 space-x-2 border-r"
                  :class="{
                    'bg-app-teal text-white': sortDirection === 'Ascending',
                  }"
                  @click="sortDirection = 'Ascending'"
                >
                  <div class="h-4 w-4"><Icon name="ArrowUp" width="3" /></div>
                  <div v-t="'ascending'"></div>
                </div>
                <div
                  class="w-1/2 flex justify-center items-center cursor-pointer py-1 space-x-2"
                  :class="{
                    'bg-app-teal text-white': sortDirection === 'Descending',
                  }"
                  @click="sortDirection = 'Descending'"
                >
                  <div class="h-4 w-4">
                    <Icon name="ArrowDown" width="3" />
                  </div>
                  <div v-t="'descending'"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="px-4 py-3" v-if="hasDataSet">
          <ToggleInput
            :value="model.hideEmptyCells"
            @input="apply({ hideEmptyCells: $event })"
          >
            <span v-t="'repeaterEditor.hideCellsWithoutData'"></span>
          </ToggleInput>
          <div
            class="mt-1 px-3 text-xs text-gray-500"
            v-t="'repeaterEditor.hideCellsWithoutDataExplain'"
          ></div>
        </div>
      </CollapsePanel>
      <CollapsePanel>
        <template slot="title">
          <div v-t="'repeaterEditor.grid'"></div>
        </template>
        <div class="space-y-3 px-4 pb-5">
          <ColorInputBar
            :value="model.backgroundColor"
            @input="preview({ backgroundColor: $event })"
            @change="apply({ backgroundColor: $event })"
            :gradientEnabled="true"
            propName="backgroundColor"
          >
            <span v-t="'backgroundColor'"></span>
          </ColorInputBar>

          <ColorInputBar
            v-if="!isSlide"
            :value="model.cellBackgroundColor"
            @input="preview({ cellBackgroundColor: $event })"
            @change="apply({ cellBackgroundColor: $event })"
            :gradientEnabled="true"
            propName="cellBackgroundColor"
          >
            <span v-t="'repeaterEditor.cellBackgroundColor'"></span>
          </ColorInputBar>

          <ColorInputBar
            :value="model.borderColor"
            @input="onBorderColorPreview($event)"
            @change="onBorderColorUpdate($event)"
            :gradientEnabled="false"
            propName="borderColor"
          >
            <span v-t="'repeaterEditor.borderColor'"></span>
          </ColorInputBar>

          <NumberInputBar
            :min="0"
            :value="model.borderWidth"
            @change="apply({ borderWidth: $event })"
            ><div v-t="'repeaterEditor.borderWidth'"
          /></NumberInputBar>

          <NumberInputBar
            :min="1"
            :max="100"
            :value="model.rows"
            @change="apply({ rows: $event })"
            ><div v-t="'repeaterEditor.rows'"
          /></NumberInputBar>

          <NumberInputBar
            :min="1"
            :max="100"
            :value="model.columns"
            @change="apply({ columns: $event })"
            ><div v-t="'repeaterEditor.columns'"
          /></NumberInputBar>

          <NumberInputBar
            :min="0"
            :max="100"
            :value="model.rowGap"
            @change="apply({ rowGap: $event })"
            ><div v-t="'repeaterEditor.rowGap'"
          /></NumberInputBar>

          <NumberInputBar
            :min="0"
            :max="100"
            :value="model.columnGap"
            @change="apply({ columnGap: $event })"
            ><div v-t="'repeaterEditor.columnGap'"
          /></NumberInputBar>
        </div>
      </CollapsePanel>
    </div>

    <ActionModal
      v-if="showRemoveBindingModal"
      @on-accept="removeDataBindingConfirmed"
      @on-reject="showRemoveBindingModal = false"
    >
      <div v-t="'repeaterEditor.removeBindingWarning'"></div>

      <ul class="text-red-500 list-disc m-4">
        <li v-for="(wg, index) in widgetsBoundToRepeaterData" :key="index">
          {{ (wg || {}).type }}
        </li>
      </ul>

      <div v-t="'repeaterEditor.removeBindingConfirm'"></div>
    </ActionModal>
    <BaseWidgetEditor
      :selectionLocked="model.locked"
      :showRotationEditor="false"
      v-if="isBaseEditingContext"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { RepeaterOptions } from "./RepeaterOptions";

// Components
import DataSetChooser from "@/components/widgets/DataSetChooser.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import ComponentEditorButton from "@/components/ComponentEditorButton.vue";
import ComponentEditorPanel from "@/components/ComponentEditorPanel.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import FormLabel from "@/components/FormLabel.vue";
import ActionModal from "@/components/ActionModal.vue";

import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import BorderEditor from "@/components/editors/BorderEditor.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";
import RadioInput from "@/components/inputs/RadioInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/icons/Icon.vue";
import LockEditor from "@/components/editors/LockEditor.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";

import {
  DataBinding,
  DataConnection,
  DataSortDirection,
  NodeData,
  NodeSetData,
} from "@/types/data";
import { Widget } from "@/components/widgets/Widget";
import { isTransparent, removeReactivity } from "@/utils";
// import { EventBus } from "@/eventbus";

import repeatGridFlowRow from "@/assets/repeat-grid-flow-row.svg";
import repeatGridFlowRowReverse from "@/assets/repeat-grid-flow-row-reverse.svg";
import repeatGridFlowColumn from "@/assets/repeat-grid-flow-column.svg";
import repeatGridFlowColumnReverse from "@/assets/repeat-grid-flow-column-reverse.svg";
import tinycolor from "tinycolor2";
import { useAppEditorStore } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionsStore } from "@/stores/connections";
import { useFilterEditorStore } from "@/stores/filterEditor";

@Component({
  components: {
    DataSetChooser,
    CollapsePanel,
    EditorNumberInput,
    NumberInputBar,
    NumberSliderInput,
    ComponentEditorPanel,
    ComponentEditorButton,
    FormLabel,
    SelectMenu,
    BaseWidgetEditor,
    ColorInputBar,
    BorderEditor,
    ButtonIcon,
    RadioInput,
    ToggleInput,
    Tooltip,
    ActionModal,
    Icon,
    LockEditor,
    ButtonGradient,
  },
})
export default class RepeaterEditor extends Vue {
  get appData() {
    return useAppDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as RepeaterOptions;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  showRemoveBindingModal = false;
  widgetsBoundToRepeaterData: Widget[] = [];
  gapLocked = false;
  hasInitialized = false;
  sortEnabled = false;

  created() {
    this.initializeSort();
  }

  get dataBinding() {
    return this.dataBindings.find(
      (db) =>
        db.widgetId === this.model.wid &&
        db.property === "data" &&
        db.bindingType === "DataSetParent"
    );
  }

  get isSlide() {
    return this.model.rows === 1 && this.model.columns === 1;
  }

  handleRepeaterBgColorChange() {
    // If bg is transparent (the default color) change it to cell color
    if (isTransparent(this.model.backgroundColor as string)) {
      this.apply({ backgroundColor: this.model.cellBackgroundColor });
    }
    // Set cell color to transparent
    this.apply({
      cellBackgroundColor: tinycolor(this.model.cellBackgroundColor)
        .setAlpha(0)
        .toHex8String(),
    });
  }

  @Watch("model.rows")
  rowsChanged() {
    if (this.isSlide) {
      this.handleRepeaterBgColorChange();
    }
  }

  @Watch("model.columns")
  columnsChanged() {
    if (this.isSlide) {
      this.handleRepeaterBgColorChange();
    }
  }

  //////////////////////////////////////////////////////////////////////
  //  SORTING
  //////////////////////////////////////////////////////////////////////
  @Watch("sortEnabled")
  onSortEnabledChange(value: boolean) {
    if (this.hasInitialized) {
      if (value) {
        this.sortDirection = "Ascending";
        this.orderByDataUuid = this.dataColumns[0].uuid ?? undefined;
      } else {
        this.sortDirection = "None";
        this.orderByDataUuid = undefined;
      }
    } else {
      return;
    }
  }

  initializeSort() {
    this.sortEnabled =
      this.sortDirection === "Ascending" || this.sortDirection === "Descending";
    this.$nextTick(() => {
      this.hasInitialized = true;
    });
  }

  get sortDirection() {
    return this.dataBinding?.sortDirection || "None";
  }

  set sortDirection(direction: DataSortDirection) {
    const copy = removeReactivity<DataBinding>(this.dataBinding);
    const isChanged = copy.sortDirection !== direction;
    copy.sortDirection = direction;
    this.appEditor.updateDataBinding(copy);
    if (isChanged) {
      this.appEditor.updateApp();
    }
  }

  get orderByDataUuid() {
    return this.dataBinding?.orderByDataUuid;
  }

  set orderByDataUuid(columnUuid: string | undefined) {
    const copy = removeReactivity<DataBinding>(this.dataBinding);
    const isChanged = copy.orderByDataUuid !== columnUuid;
    copy.orderByDataUuid = columnUuid;
    this.appEditor.updateDataBinding(copy);
    if (isChanged) {
      this.appEditor.updateApp();
    }
  }

  get shouldRandomize() {
    return this.sortDirection === "Random";
  }

  set shouldRandomize(value: boolean) {
    const copy = removeReactivity<DataBinding>(this.dataBinding);
    const newValue = value ? "Random" : "None";
    const isChanged = copy.sortDirection !== newValue;
    copy.sortDirection = newValue;

    if (newValue === "None") {
      this.sortEnabled = false;
    } else {
      copy.orderByDataUuid = undefined;
    }

    this.appEditor.updateDataBinding(copy);

    if (isChanged) {
      this.appEditor.updateApp();
    }
  }

  get dataSet(): NodeData[][] {
    return (this.widgetData[this.model.wid]?.[0]?.data as NodeData[][]) ?? [];
  }

  // TODO: We may have to handle case where user removes dataset and adds new one (need to "reset" chosen columnn in that case)
  get dataColumns(): NodeData[] {
    // console.log("data cols...", this.dataSet, this.widgetData, this.model.wid);
    return this.dataSet.length > 0 ? this.dataSet[0] : [];
  }

  // NOTE: May want to put Index first, rather than last
  get dataColumnOptions() {
    return [
      // { label: "Row Index", value: DATA_ROW_INDEX },
      ...this.dataColumns.map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      }),
    ];
  }

  //////////////////////////////////////////////////////////////////////
  // END OF SORTING
  //////////////////////////////////////////////////////////////////////

  // Check if any nodes within this repeater are using this binding; if so, show modal warning
  clickRemoveDataBinding() {
    if (!this.dataBinding) {
      return;
    }
    const dataUuid = this.dataBinding.dataUuid;
    const boundChildWidgets = this.dataBindings
      .filter(
        (db) =>
          db.bindingType === "DataSetNode" &&
          db.dataParentUuid === dataUuid &&
          db.parentWidgetId === this.model.wid
      )
      .map((db) => this.appEditor.widgetById(db.widgetId) as Widget)
      .filter((db) => db !== undefined);

    // Remove any filter associated with this data binding
    const filterStore = useFilterEditorStore();
    filterStore.$reset();

    if (boundChildWidgets.length > 0) {
      this.showRemoveBindingModal = true;
      // console.log("bound children", boundChildWidgets);
      this.widgetsBoundToRepeaterData = boundChildWidgets;
    } else {
      // console.log("removing...", this.dataBinding);
      this.appEditor.removeDataBinding(this.dataBinding);
    }
  }

  removeDataBindingConfirmed() {
    if (!this.dataBinding) {
      return;
    }
    this.appEditor.removeRepeaterBinding({
      widgetIds: this.widgetsBoundToRepeaterData.map((wg) => wg.wid),
      dataBinding: this.dataBinding,
    });
    this.showRemoveBindingModal = false;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  mounted() {
    this.gapLocked = this.rowGap === this.columnGap;
  }

  get isGrid() {
    return this.model.rows !== 1 && this.model.columns !== 1;
  }

  get rowGap() {
    return this.model?.rowGap;
  }

  set rowGap(value: number) {
    const props: any = { rowGap: value };
    if (this.gapLocked) {
      props.columnGap = value;
    }
    this.apply(props);
  }

  get columnGap() {
    return this.model?.columnGap;
  }

  set columnGap(value: number) {
    const props: any = { columnGap: value };
    if (this.gapLocked) {
      props.rowGap = value;
    }
    this.apply(props);
  }

  get flowOptions() {
    const rowImage =
      this.sortDirection === "Descending"
        ? repeatGridFlowRow
        : repeatGridFlowRowReverse;
    const cowImage =
      this.sortDirection === "Descending"
        ? repeatGridFlowColumn
        : repeatGridFlowColumnReverse;

    return [
      {
        label: "By Row",
        value: "row",
        image: rowImage,
      },
      {
        label: "By Column",
        value: "column",
        image: cowImage,
      },
    ];
  }

  //////////////////////////////////////////////////////////////////////
  // START PAGING
  //////////////////////////////////////////////////////////////////////

  get page() {
    // In App Renderer, the cyclingIndex values will keep incrementing
    // So we use modulo operator to determine page number
    const cycleValue = this.appEditor.cyclingIndexes[this.model.wid] || 0;
    return cycleValue % this.pageCount;
  }

  get pageSize() {
    return this.model.rows * this.model.columns;
  }

  get pageCount() {
    if (!this.dataBinding) {
      return 0;
    }

    const dataSet = this.appData.data[this.dataBinding.widgetId]
      ?.data as NodeSetData;

    const recordCount = dataSet?.children?.length ?? 0;

    return Math.ceil(recordCount / this.pageSize);
  }

  get hasDataSet() {
    return this.nodeSets.length > 0;
  }

  get nodeSets() {
    // console.log("conn...", this.connection);
    return this.connection?.nodeSets || [];
  }

  /**
   * Must pass in bindingType, in case Slide also has scalar bindings
   */
  get connection(): DataConnection | undefined {
    // This computed property used to refer to a getter
    // named connectionsForWidget(). However this was the only usage
    // So the contents of that getter have been moved here.

    const appEditor = useAppEditorStore();

    const connections = appEditor
      .bindingsForComponent({
        widgetId: this.model.wid,
        bindingType: "DataSetParent",
      })
      .map((binding: DataBinding) => {
        return useConnectionsStore().connections.find(
          (c) => c.uuid === binding.dataConnectionUuid
        );
      })
      .filter((c: DataConnection | undefined) => typeof c !== "undefined");

    return connections.length > 0 ? connections[0] : undefined;
  }

  prevPage() {
    let value = this.page - 1;
    if (value < 0) {
      value = this.pageCount - 1;
    }
    this.appEditor.setCyclingIndex({ widgetId: this.model.wid, value });
  }

  nextPage() {
    let value = this.page + 1;
    if (value === this.pageCount) {
      value = 0;
    }
    Vue.set(this.appEditor.cyclingIndexes, this.model.wid, value);
  }

  //////////////////////////////////////////////////////////////////////
  // END PAGING
  //////////////////////////////////////////////////////////////////////

  get animationStyles() {
    return [
      { label: "None", value: "none" },
      { label: "Fade", value: "fade" },
      { label: "Slide Up", value: "slideUp" },
      { label: "Slide Down", value: "slideDown" },
      { label: "Slide Left", value: "slideLeft" },
      { label: "Slide Right", value: "slideRight" },
      { label: "Flip", value: "flip" },
      { label: "Shrink/Grow", value: "scale" },
    ];
  }

  onBorderColorUpdate(borderColor: string) {
    let borderWidth = this.model.borderWidth;
    if (borderWidth === 0) {
      borderWidth = 10;
    }
    this.apply({ borderColor, borderWidth });
  }

  onBorderColorPreview(borderColor: string) {
    let borderWidth = this.model.borderWidth;
    if (borderWidth === 0) {
      borderWidth = 10;
    }
    this.preview({ borderColor, borderWidth });
  }
}
</script>

<style lang="postcss" scoped>
.textStyle {
  max-width: 14rem;
}
.textStyle:hover {
  background: #ddd;
}
.text-styles {
  top: 25px;
  left: 0px;
  z-index: 10000;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
