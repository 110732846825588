<template>
  <button
    @click="onClick"
    class="block h-8 px-3 font-semibold hover:bg-gray-100 rounded"
    :class="{ 'bg-gray-200': active }"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ComponentEditorButton extends Vue {
  @Prop(Boolean) active: boolean;

  onClick() {
    this.$emit("click");
  }
}
</script>

<style scoped></style>
