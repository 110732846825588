import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface CalendarRoomScheduleOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  use24Hour: boolean;
  locationTitle: string | null;
  data?: any[];
  previewLiveData: boolean;

  progressBarBackgroundColor: string;
  progressBarFillColor: string;
  dividerFillColor: string;
  dividerSize: number;
  syncFonts: boolean;
  syncTextColors: boolean;

  locationTitle_fontFamily: string | null;
  locationTitle_textColor: string | null;
  locationTitle_fontSize: number;
  locationTitle_fontWeight: number;
  locationTitle_letterSpacing: number;
  locationTitle_lineHeight: number;
  locationTitle_textAlign: string;
  locationTitle_fontStyle: string;
  locationTitle_textTransform: string;
  locationTitle_textDecoration: string;

  headerEventTitle_fontFamily: string | null;
  headerEventTitle_textColor: string | null;
  headerEventTitle_fontSize: number;
  headerEventTitle_fontWeight: number;
  headerEventTitle_letterSpacing: number;
  headerEventTitle_lineHeight: number;
  headerEventTitle_textAlign: string;
  headerEventTitle_fontStyle: string;
  headerEventTitle_textTransform: string;
  headerEventTitle_textDecoration: string;

  headerEventTime_fontFamily: string | null;
  headerEventTime_textColor: string | null;
  headerEventTime_fontSize: number;
  headerEventTime_fontWeight: number;
  headerEventTime_letterSpacing: number;
  headerEventTime_lineHeight: number;
  headerEventTime_textAlign: string;
  headerEventTime_fontStyle: string;
  headerEventTime_textTransform: string;
  headerEventTime_textDecoration: string;

  subheaderEventTitle_fontFamily: string | null;
  subheaderEventTitle_textColor: string | null;
  subheaderEventTitle_fontSize: number;
  subheaderEventTitle_fontWeight: number;
  subheaderEventTitle_letterSpacing: number;
  subheaderEventTitle_lineHeight: number;
  subheaderEventTitle_textAlign: string;
  subheaderEventTitle_fontStyle: string;
  subheaderEventTitle_textTransform: string;
  subheaderEventTitle_textDecoration: string;

  subheaderEventTime_fontFamily: string | null;
  subheaderEventTime_textColor: string | null;
  subheaderEventTime_fontSize: number;
  subheaderEventTime_fontWeight: number;
  subheaderEventTime_letterSpacing: number;
  subheaderEventTime_lineHeight: number;
  subheaderEventTime_textAlign: string;
  subheaderEventTime_fontStyle: string;
  subheaderEventTime_textTransform: string;
  subheaderEventTime_textDecoration: string;

  clockTime_fontFamily: string | null;
  clockTime_textColor: string | null;
  clockTime_fontSize: number;
  clockTime_fontWeight: number;
  clockTime_letterSpacing: number;
  clockTime_lineHeight: number;
  clockTime_textAlign: string;
  clockTime_fontStyle: string;
  clockTime_textTransform: string;
  clockTime_textDecoration: string;
}

const mainColor = "rgba(52, 66, 86, 100)";
const accentColor = "rgba(244, 62, 92, 100)";

export const defaultTextOptions: any = {
  locationTitle_fontSize: 80,
  locationTitle_fontWeight: 700,
  locationTitle_textColor: mainColor,
  locationTitle_letterSpacing: 0,
  headerEventTitle_fontSize: 80,
  headerEventTitle_fontWeight: 700,
  headerEventTitle_textColor: mainColor,
  headerEventTitle_letterSpacing: 0,
  headerEventTime_fontSize: 55,
  headerEventTime_fontWeight: 400,
  headerEventTime_textColor: accentColor,
  headerEventTime_letterSpacing: 0,
  subheaderEventTitle_fontSize: 40,
  subheaderEventTitle_fontWeight: 700,
  subheaderEventTitle_textColor: mainColor,
  subheaderEventTitle_letterSpacing: 1,
  subheaderEventTime_fontSize: 35,
  subheaderEventTime_fontWeight: 400,
  subheaderEventTime_textColor: accentColor,
  subheaderEventTime_letterSpacing: 0,
  clockTime_fontSize: 80,
  clockTime_fontWeight: 400,
  clockTime_textColor: "#000000",
};

[
  "locationTitle",
  "headerEventTitle",
  "headerEventTime",
  "subheaderEventTitle",
  "subheaderEventTime",
  "clockTime",
].forEach((prop) => {
  defaultTextOptions[`${prop}_fontFamily`] = "Montserrat";
  defaultTextOptions[`${prop}_textTransform`] = "none";
  defaultTextOptions[`${prop}_textDecoration`] = "none";
  defaultTextOptions[`${prop}_fontStyle`] = "normal";
  defaultTextOptions[`${prop}_lineHeight`] = 1.2;
  defaultTextOptions[`${prop}_textAlign`] = "left";
});

const defaultOptions = {
  ...defaultTextOptions,
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  borderRadius: 0,
  use24Hour: false,
  previewLiveData: false,
  progressBarBackgroundColor: "rgba(225, 231, 239, 100)",
  progressBarFillColor: "rgba(52, 66, 86, 100)",
  dividerFillColor: "rgba(52, 66, 86, 100)",
  dividerSize: 1,
  syncFonts: false,
  syncTextColors: false,
};

const CreateCalendarRoomSchedule = (
  options: Partial<CalendarRoomScheduleOptions>
) => {
  return Object.assign({}, defaultOptions, options, {
    wid: makeId(),
    type: "CalendarRoomSchedule",
    name: DEFAULT_LAYER_NAMES.CalendarRoomSchedule,
  });
};

export default CreateCalendarRoomSchedule;
