import i18n from "@/i18n";
import { LogicOperand, LogicOperation, LogicRule } from "@/types/logic";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { SchemaNode } from "@/types/data";

export type ConditionRuleToken = {
  type: "text" | "value";
  value: string;
};

export const makeConditionRuleTextToken = (
  value = ""
): ConditionRuleToken[] => [{ type: "text", value: value }];

function describeOperation(operation: LogicOperation): ConditionRuleToken[] {
  if (typeof operation !== "undefined") {
    const label = (
      i18n.t(`ConditionOperatorLabel.${operation}`) as string
    ).toLowerCase();
    return [
      {
        type: "text",
        value: `${label} `,
      },
    ];
  }
  return makeConditionRuleTextToken();
}

function describeQueryStringOperand(
  operand: Partial<LogicOperand>
): ConditionRuleToken[] {
  const hasValue = isNonEmptyString(operand.value);
  const tokens: ConditionRuleToken[] = [];
  tokens.push({ type: "text", value: "the value of a querystring parameter " });
  if (hasValue) {
    tokens.push({ type: "text", value: "named " });
    tokens.push({ type: "value", value: `${operand.value ?? ""} ` });
  }
  return tokens;
}

function describeStaticOperand(
  operand: Partial<LogicOperand>
): ConditionRuleToken[] {
  if (!isNonEmptyString(operand.value)) {
    return makeConditionRuleTextToken();
  }

  return [
    {
      type: "value",
      value: `${operand.value} `,
    },
  ];
}

function describeCurrentDateTimeOperand(
  operand: Partial<LogicOperand>
): ConditionRuleToken[] {
  const value = (
    i18n.t(`DateTimeLogic.${operand.type}`) as string
  ).toLowerCase();
  return [
    { type: "text", value: "the " },
    {
      type: "value",
      value: `${value} `,
    },
  ];
}

function describeColumnOperand(
  operand: Partial<LogicOperand>,
  columns: SchemaNode[] = []
): ConditionRuleToken[] {
  const hasValue = isNonEmptyString(operand.nodeUuid);
  const col = columns.find((c) => c.uuid === operand.nodeUuid);
  const name = col?.name ?? "Unknown";

  const tokens: ConditionRuleToken[] = [];
  tokens.push({ type: "text", value: "the repeater value " });
  if (hasValue) {
    tokens.push({ type: "text", value: "named " });
    tokens.push({
      type: "value",
      value: name,
    });
    tokens.push({ type: "text", value: " " });
  }
  return tokens;
}

function describeScalarOperand(
  operand: Partial<LogicOperand>
): ConditionRuleToken[] {
  const hasValue = isNonEmptyString(operand.nodeUuid);
  const tokens: ConditionRuleToken[] = [];
  tokens.push({ type: "text", value: "the data value " });
  if (hasValue) {
    tokens.push({ type: "text", value: "at " });
    tokens.push({
      type: "value",
      value: `${operand.value ?? ""} `,
    });
  }
  return tokens;
}

function describeOperand(
  operand: Partial<LogicOperand> | undefined,
  columns: SchemaNode[] = []
): ConditionRuleToken[] {
  if (typeof operand?.type === "undefined") {
    return makeConditionRuleTextToken();
  }

  if (operand.type.startsWith("Current")) {
    return describeCurrentDateTimeOperand(operand);
  }

  switch (operand.type) {
    case "Static":
      return describeStaticOperand(operand);
    case "QueryString":
      return describeQueryStringOperand(operand);
    case "ConnectionColumn":
      return describeColumnOperand(operand, columns);
    case "ConnectionScalar":
      return describeScalarOperand(operand);
    default:
      return makeConditionRuleTextToken();
  }
}

export function describeRule(
  rule: LogicRule,
  columns: SchemaNode[] = []
): ConditionRuleToken[] {
  const { operandLeft, operation, operandRight } = rule;
  const left = describeOperand(operandLeft, columns);
  const right = describeOperand(operandRight, columns);
  const op = describeOperation(operation);

  const start = makeConditionRuleTextToken("if ");

  return [...start, ...left, ...op, ...right];
}
