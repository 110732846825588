import { defineStore } from "pinia";
import { api } from "@/api/backend";
import {
  ConnectionSource,
  ConnectionSourceId,
  SetupStep,
} from "@/types/ConnectionSources";
import { EventBus } from "@/eventbus";

export interface ConnectionSourceState {
  isLoadingData: boolean;
  shouldShowError: boolean;
  sources: ConnectionSource[];
}

export interface SourceQueryOptions {
  excludeBySlugs?: string[];
  includeSchemaTypes?: string[];
}

export const useConnectionSourceStore = defineStore("connectionSource", {
  state: (): ConnectionSourceState => {
    return {
      isLoadingData: false,
      shouldShowError: false,
      sources: [],
    };
  },

  getters: {},

  actions: {
    getSourceSetupRoute(source: {
      slug: string;
      setupSteps: string[];
      setupStepsV2: SetupStep[];
      setupProperties: Record<string, string> | null;
      providerDefinitionVersion: number | null;
    }) {
      const qps = source.setupProperties
        ? new URLSearchParams(source.setupProperties)
        : "";
      const qpParams = source.setupProperties ? "?" + qps.toString() : "";
      return source.providerDefinitionVersion
        ? `v${source.providerDefinitionVersion}/${source.slug.toLowerCase()}/${
            source.setupStepsV2[0].id
          }${qpParams}`
        : `${source.slug.toLowerCase()}/${source.setupSteps[0].toLowerCase()}${qpParams}`;
    },

    getSourceByName(
      name: ConnectionSourceId | string | undefined
    ): ConnectionSource | undefined {
      if (!name) return undefined;
      return this.sources.find(
        (source) => source.name.toLowerCase() === name.toLowerCase()
      );
    },

    getSourceBySlug(slug: string | undefined): ConnectionSource | undefined {
      if (!slug) return undefined;
      return this.sources.find(
        (source) => source.slug.toLowerCase() === slug.toLowerCase()
      );
    },

    getSources(queryOpts: SourceQueryOptions): ConnectionSource[] {
      return this.sources.filter((source) => {
        if (queryOpts.excludeBySlugs?.includes(source.slug)) return false;

        if (
          queryOpts.includeSchemaTypes &&
          queryOpts.includeSchemaTypes.length > 0
        ) {
          if (queryOpts.includeSchemaTypes?.includes(source.schemaType))
            return true;
          else return false;
        }

        return true;
      });
    },

    async fetchConnectionSources() {
      if (this.sources.length > 0) return;

      EventBus.emit("AWAITING_SERVER", true);
      this.isLoadingData = true;

      try {
        const langCode = window.navigator.language;
        this.sources = await api.get<ConnectionSource[]>(
          `dataconnection/providers?langCode=${langCode}`
        );
      } catch (err) {
        this.shouldShowError = true;
      } finally {
        this.isLoadingData = false;
        EventBus.emit("AWAITING_SERVER", false);
      }
    },
  },
});
