<template>
  <div>
    <select @input="change">
      <option
        v-for="(option,i) in typeToOptions(type).options"
        :key="i"
        :value="option"
        :selected="isSelected(option)"
      >{{getOptionLabel(option)}}</option>
    </select>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { sampleDate } from "@/datetimeFormats";
import { dtOptionsToString } from "@/datetimeUtils";
import { capitalize } from "@/utils";

@Component({
  components: {}
})
export default class DatetimeFormatSelect extends Vue {
  @Prop(String) type: string;
  @Prop(Number) index: number;
  @Prop(String) value: string;
  @Prop(Boolean) hour12: boolean;

  getOptionLabel(option: string) {
    if (option === "remove") return "Remove";

    let value = option;
    let type = this.type;
    if (type === "weekday" || type === "day") {
      if (["2-digit", "numeric"].includes(value)) {
        type = "day";
      } else {
        type = "weekday";
      }
    }

    const label = dtOptionsToString([{ type, value }], sampleDate);
    return `${this.typeToOptions(this.type).label} (${label})`;
  }

  isSelected(option: string) {
    if (this.type === "hour") {
      if (!this.hour12) {
        return this.value + "-24" === option;
      }
    }
    return this.value === option;
  }

  change(ev: any) {
    // console.log("change", ev.target.value);
    this.$emit("inputChange", {
      index: this.index,
      value: ev.target.value
    });
  }

  typeToOptions(type: string) {
    let options = ["2-digit", "numeric"];
    switch (type) {
      case "month":
        options = ["2-digit", "numeric", "narrow", "short", "long"];
        break;

      case "day":
      case "weekday":
        options = ["2-digit", "numeric", "narrow", "short", "long", "ordinal"];
        break;
      case "dayPeriod":
        options = ["PM", "pm", "P", "p"];
        break;
      case "hour":
        options = ["2-digit", "numeric", "2-digit-24", "numeric-24"];
    }

    options.push("remove");

    return {
      label: type === "weekday" ? "Day" : capitalize(type),
      options
    };
  }
}
</script>
