<template>
  <div class="flex items-center flex-wrap space-x-6 whitespace-nowrap">
    <button
      type="button"
      :key="option.value"
      v-for="option in options"
      class="flex items-center space-x-3 px-3 py-1 pr-5 rounded border dark-form-focus"
      :class="{
        'bg-gray-600 border-gray-600 shadow': value === option.value,
        'border-gray-600 hover:border-gray-500': value !== option.value,
      }"
      @click="onSelect(option.value)"
    >
      <input
        type="checkbox"
        tabindex="-1"
        class="pointer-events-none form-checkbox rounded text-app-green bg-gray-600"
        :checked="value === option.value"
        :class="{
          'border-transparent': value !== option.value,
        }"
      />
      <span v-text="option.label"></span>
    </button>
  </div>
</template>

<script lang="ts">
import { InputOption } from "@/types/inputs";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TabInput extends Vue {
  @Prop({ type: [String, Number, Boolean] }) value: string | number | boolean;
  @Prop(Array) options: InputOption[];

  onSelect(value: string) {
    this.$emit("input", value);
  }
}
</script>
