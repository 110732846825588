<template>
  <div
    class="flex w-12 h-8"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <button
      v-if="controls"
      @click="decrease"
      class="border text-lg border-gray-300 block w-8 font-bold rounded-tl rounded-bl"
    >
      -
    </button>
    <div
      class="relative flex focus:bg-white hover:bg-white hover:ring-1 rounded-sm focus:outline-none cursor-pointer focus-within:ring-1 focus-within:ring-app-teal"
      :class="{
        'border-app-teal ring-app-teal ring-1 bg-white': showMenu,
        'hover:ring-gray-300': !showMenu,
      }"
    >
      <EditorNumberInput
        class="w-12 border-l-0 border-t-0 border-r-0 border-b-0"
        :value="value"
        :center="true"
        @change="input"
        v-if="!isAutoFit || !allowAuto"
        :isTransparent="true"
      />
      <div class="w-12 px-1 text-center" v-if="isAutoFit && allowAuto">
        Auto
      </div>
      <div
        class="flex items-center text-gray-400"
        @click="showMenu = !showMenu"
      >
        <Icon
          v-if="hover || showMenu"
          class="mx-2 w-4 h-4 pointer-events-none"
          :name="icon"
        />
      </div>
      <div
        v-if="showMenu"
        class="absolute bg-white z-50 rounded-md w-24 top-full py-1 ring-1 ring-black ring-opacity-5 mt-1"
      >
        <div class="flex flex-col">
          <div class="flex-grow h-72 overflow-y-auto">
            <div
              v-if="allowAuto"
              class="w-full px-3 py-1"
              :class="{
                'bg-app-teal text-black': isAutoFit,
                'bg-white hover:bg-gray-100': !isAutoFit,
              }"
              @click="input(0)"
            >
              Auto
            </div>
            <div
              v-for="item in sizeOptions"
              :key="item.size"
              class="w-full px-3 py-1 hover:bg-app-teal hover:text-white relative checkmark-parent"
              @click="input(item.value)"
            >
              <span> {{ item.value }}</span>

              <span
                v-if="item.value === value"
                class="absolute inset-y-0 right-0 flex items-center pr-2 checkmark"
              >
                <IconSolid name="Checkmark" class="h-5 w-5" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="controls"
      @click="increase"
      class="border border-gray-300 block w-8 text-lg font-bold rounded-tr rounded-br"
    >
      +
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import { range } from "@/utils";

const step = 12;
const sizes = range(1, 20).map((x) => x * step);

// One quick thing we can add is close on select, and color change
// Should also (for this and familyinput) do checkmark/bold for selected, and app-teal for hover

@Component({
  components: {
    Icon,
    IconSolid,
    EditorNumberInput,
  },
})
export default class FontSizeInput extends Vue {
  @Prop(Number) value: number;
  @Prop({ type: Boolean, default: false }) controls: boolean;
  @Prop({ type: Boolean, default: false }) allowAuto: boolean;

  showMenu = false;
  hover = false;

  closeMenu(ev: any = null) {
    if (ev === null || !this.$el.contains(ev.target)) {
      this.showMenu = false;
    }
    // this.showMenu = false;
  }

  mounted() {
    document.addEventListener("click", this.closeMenu);
  }

  beforeDestroy() {
    document.removeEventListener("click", this.closeMenu);
  }

  input(value: number) {
    this.$emit("input", value);
    this.closeMenu();
    this.hover = false;
  }

  decrease() {
    this.input(this.value - step);
  }

  increase() {
    this.input(this.value + step);
  }

  get icon() {
    if (this.showMenu) {
      return "ChevronUp";
    } else {
      return "ChevronDown";
    }
  }

  get isAutoFit() {
    return this.value === 0;
  }

  get sizeOptions() {
    return sizes.map((size) => {
      return {
        value: size,
        selected: this.value === size,
      };
    });
  }
}
</script>

<style scoped lang="postcss">
.checkmark {
  @apply text-app-teal;
}
.checkmark-parent:hover .checkmark {
  @apply text-white;
}
</style>
