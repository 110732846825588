<template>
  <div
    class="flex relative z-10"
    :style="{ marginRight: `-${items.length - 1}px` }"
  >
    <button
      type="button"
      :key="item.value"
      :disabled="disabled"
      v-for="(item, index) in items"
      @click="update(item.value)"
      class="btn-radio focus:outline-none focus:shadow-outline"
      :class="[
        item.selected ? 'btn-radio-active z-20 ' + activeColor : '',
        type === 'icon' ? 'h-12' : '',
      ]"
      :style="{ left: `-${index}px` }"
    >
      <Icon
        v-if="type === 'icon'"
        :name="item.label"
        fill="currentColor"
        class="w-full h-full p-2"
      />
      <span v-else class="block py-1 px-4" v-t="item.label"></span>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { InputOption } from "@/types/inputs";

@Component({
  components: { Icon },
})
export default class RadioInput extends Vue {
  @Prop() value: string | number | boolean;
  @Prop({ type: String, default: "text" }) type: string;
  @Prop({ default: null }) customFill: string;
  @Prop(Array) options: InputOption[];
  @Prop(Boolean) disabled: boolean;

  get items() {
    return this.options.map((item) => {
      return Object.assign({}, item, { selected: this.value === item.value });
    });
  }

  get activeColor() {
    return this.customFill ?? "bg-app-teal border-app-teal";
  }

  update(value: string) {
    this.$emit("input", value);
  }
}
</script>

<style lang="postcss" scoped>
.btn-radio {
  @apply relative text-gray-600 border-t  border-b border-gray-400;
}
.btn-radio:first-child {
  @apply rounded-l border-l border-r;
}
.btn-radio:last-child {
  @apply rounded-r border-l border-r;
}
.btn-radio-active {
  @apply text-white;
}
</style>
