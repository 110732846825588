<template>
  <div class="w-full h-full flex flex-col">
    <div class="flex w-full flex-row space-x-4 ml-6 h-full">
      <AnimationPresetTile
        v-for="(item, index) in presets"
        :key="index + item.label"
        :preset="item"
        :selectedPresetId="selectedPresetId"
        @select="select($event)"
      ></AnimationPresetTile>
    </div>
    <div class="w-full mt-4" v-if="showPropertyEditor">
      <AnimationPropertyEditor
        :animations="animations"
        :animationKey="animationKey"
        @animationPropChanged="animationPropChanged"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { AnimationPreset, Animations } from "@/types/animation";
import AnimationPresetTile from "./AnimationPresetTile.vue";
import AnimationPropertyEditor from "./editors/AnimationPropertyEditor.vue";

@Component({
  components: {
    AnimationPresetTile,
    AnimationPropertyEditor,
  },
})
export default class AnimationPresetRow extends Vue {
  @Prop(Array) readonly presets: AnimationPreset[];
  @Prop(Object) readonly animations: Animations;
  @Prop(String) readonly animationKey: string;
  @Prop(String) readonly selectedPresetId: string;

  get rowPresetIds() {
    let ids: string[] = [];
    this.presets.forEach((preset) => {
      ids.push(preset.presetId);
    });
    return ids;
  }

  select(event: any) {
    this.$emit("select", event);
  }

  animationPropChanged(newAnimations: any) {
    this.$emit("animationPropChanged", newAnimations);
  }

  get showPropertyEditor() {
    return (
      this.rowPresetIds.includes(this.selectedPresetId) &&
      this.selectedPresetId != "none"
    );
  }
}
</script>

<style scoped lang="postcss"></style>
