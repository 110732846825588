<template>
  <div class="flex-grow">
    <component v-if="!redirectToEditor" :is="completeComponent" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConnectionSourceId } from "@/types/ConnectionSources";
import FormButton from "@/components/FormButton.vue";
import { APP_EDITOR_ROUTE_PATH } from "@/constants";
import { useConnectionSetupStore } from "@/stores/connectionSetup";
import CompleteDefault from "@/components/data/connections/setup/complete/CompleteDefault.vue";
import CompleteZapier from "@/components/data/connections/setup/complete/CompleteZapier.vue";
import CompleteRestApi from "@/components/data/connections/setup/complete/CompleteRestApi.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

/**
 * (1) If user is creating a connection from App editor view,
 * create the binding and then close the modal. (Node selection will happen as an earlier setup step.)
 *
 * (1.5) If connection source is zapier/api, show instructions
 *
 * (2) If user is REPLACING a Collection connection from App editor view,
 * update the binding and then if widget is repeater, show "remap columns" view.
 *
 * (3) If user is creating a Collection connection from the Dashboard/Connections,
 * take them to the Dashboard/Connections/{connectionUuid} view (which is Data Manager).
 */

@Component({
  components: {
    FormButton,
    CompleteDefault,
    CompleteZapier,
    CompleteRestApi,
  },
})
export default class SetupComplete extends Vue {
  @Prop(String) sourceId: ConnectionSourceId;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get logicRefsRequiringRemap() {
    return this.connectionEditor.logicRefsRequiringRemap;
  }

  get selectedWidget() {
    const appEditor = useAppEditorStore();
    return appEditor.selectedWidget;
  }

  get redirectToEditor() {
    return !this.store.isCollection && this.store.appUuid;
  }

  get existingConnectionUuid() {
    return this.$route.params?.connectionUuid;
  }

  get newConnectionUuid(): string {
    return this.$route.query?.c as string;
  }

  get isReplacing() {
    return this.$route.path.includes("replace");
  }

  async created() {
    // Redirect Scalar Bindings back to app editor
    // NOTE: We handle this for tree connections in CompleteDefault

    // If calendar, and we are replacing, must call remap refs endpoint
    if (this.selectedWidget?.type.includes("Calendar") && this.isReplacing) {
      await this.connectionEditor.updateRemapLogicRefs({
        sourceConnectionUuid: this.existingConnectionUuid,
        targetConnectionUuid: this.newConnectionUuid || "",
        logicUuids: this.logicRefsRequiringRemap,
        appUuid: this.$route.params.id,
      });
    }

    if (this.redirectToEditor) {
      this.$router.push({
        name: "edit",
        params: { id: this.store.appUuid as string },
      });
    }
  }

  get store() {
    return useConnectionSetupStore();
  }

  get completeComponent() {
    switch (this.store.sourceId) {
      case "Zapier":
        return "CompleteZapier";
      case "RestApi":
        return "CompleteRestApi";
      default:
        return "CompleteDefault";
    }
  }

  // Maybe should use prop
  get fromEditor() {
    return this.$route.path.includes(APP_EDITOR_ROUTE_PATH);
  }

  async redirect() {
    // TODO: Where do they go from here...

    /**
     * (1) If user is creating a connection from App editor view,
     * create the binding and then close the modal. (Node selection will happen as an earlier setup step.)
     *
     * (1.5) If connection source is zapier/api, show instructions
     *
     * (2) If user is REPLACING a Collection connection from App editor view,
     * update the binding and then if widget is repeater, show "remap columns" view.
     *
     * (3) If user is creating a Collection connection from the Dashboard/Connections,
     * take them to the Dashboard/Connections/{connectionUuid} view (which is Data Manager).
     */

    // if (this.fromEditor) {
    //   // Setup.Vue handles this
    //   EventBus.emit("DATA_BINDING_CREATE");

    //   return;
    // }

    const path = "complete";

    const connectionUuid = this.$route.params.connectionUuid;

    this.$router.push({
      path: `${this.sourceId}/${connectionUuid}/${path}`,
    });
  }
}
</script>
