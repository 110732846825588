<template>
  <div class="flex space-x-4 items-center">
    <div
      :key="index"
      v-for="(link, index) in links"
      class="flex space-x-4 items-center"
    >
      <div v-if="index > 0">&gt;</div>
      <router-link
        v-if="link.path"
        :to="link.path"
        v-text="link.text"
        class="link"
      ></router-link>
      <div v-else v-text="link.text"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component
export default class BreadcrumbNavigation extends Vue {
  @Prop(Array) links: { path?: RawLocation; text: string }[];

  preserveQuery(path: RawLocation) {
    if (typeof path === "string") {
      return {
        path,
        query: { ...this.$route.query },
      };
    } else {
      return { ...path, query: { ...path.query, ...this.$route.query } };
    }
  }
}
</script>

<style scoped lang="postcss">
.link {
  @apply relative;
}
.link::after {
  content: "";
  @apply absolute top-full left-0 right-0 h-px bg-gray-500;
}
.link:hover::after {
  @apply bg-gray-400;
}
</style>
