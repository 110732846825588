import i18n from "@/i18n";
import { FaultErrorCode } from "@/types/faultErrorCode";
import { logger } from "@core/logger";

/**
 * Ensures that the error code is a valid FaultErrorCode.
 *
 * Will return "unknown" if the error code is not valid.
 */
export const getFaultErrorCode = (value: string): FaultErrorCode => {
  switch (value as FaultErrorCode) {
    case "authFailure":
    case "httpTimeout":
    case "httpException":
    case "noData":
    case "parsingError":
    case "urlInvalid":
    case "maxFileSizeExceeded":
    case "maxRecordsExceeded":
    case "spreadsheetNotFound":
      return value as FaultErrorCode;
    default:
      return "unknown";
  }
};

/**
 * Returns the translated error code message and the API errorMessage (not translated) if
 * the error message might be useful to the user.
 *
 * Any unknown codes will be logged to TrackJS.
 */
export const getConnectionErrorMessage = (
  errorCode: string | null,
  errorMessage: string | null
) => {
  if (!errorCode) return "";

  const knownFaultCode = getFaultErrorCode(errorCode);
  if (knownFaultCode !== errorCode) {
    logger.track(`Unknown fault code: ${errorCode}`);
  }

  return i18n.t("connectionFaultErrorCodes." + knownFaultCode, {
    additionalInfo: errorMessage,
  });
};
