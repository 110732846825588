import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { appSettings } from "../appSettings";
import { auth } from "./authService";

export interface BackendRequestConfig extends AxiosRequestConfig {
  anonymous?: boolean;
}

export interface PagedResult {
  page: number;
  pageSize?: number;
  totalItems: number;
}

export interface BackendResponseMeta {
  paging?: PagedResult;
}

export interface BackendApiResponse<T> {
  data: T;
  meta: BackendResponseMeta;
  errors: BackendError[];
}

export interface BackendError {
  code: string;
  message: string;
}

class ApiClient {
  private instance = axios.create({
    baseURL: appSettings.backEnd.baseUrlAndPath,
  });

  constructor() {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        let originalRequest = error.config;

        if (error.response.status === 401 && originalRequest._rety)
          return auth.logout();

        if (error.response.status === 401) {
          originalRequest._rety = true;

          if (auth.isImpersonating()) auth.logout();

          return (await auth.getOrRenewAccessToken())
            ? this.instance.request(originalRequest)
            : auth.logout();
        }

        return Promise.reject(error);
      }
    );
  }

  delete<T>(
    url: string,
    config?: BackendRequestConfig | undefined
  ): Promise<T> {
    return this.callWithAuth<BackendApiResponse<T>>(config, (a, c) =>
      a.delete(url, c)
    )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data?.errors ?? error?.message);
      });
  }

  get<T>(url: string, config?: BackendRequestConfig | undefined): Promise<T> {
    return this.callWithAuth<BackendApiResponse<T>>(config, (a, c) =>
      a.get(url, c)
    )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data?.errors ?? error?.message);
      });
  }

  post<T>(
    url: string,
    data?: any,
    config?: BackendRequestConfig | undefined
  ): Promise<T> {
    return this.callWithAuth<BackendApiResponse<T>>(config, (a, c) =>
      a.post(url, data, c)
    )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data?.errors ?? error?.message);
      });
  }

  put<T>(
    url: string,
    data?: any,
    config?: BackendRequestConfig | undefined
  ): Promise<T> {
    return this.callWithAuth<BackendApiResponse<T>>(config, (a, c) =>
      a.put(url, data, c)
    )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data?.errors ?? error?.message);
      });
  }

  private async callWithAuth<R>(
    config: BackendRequestConfig | undefined,
    fn: (
      a: AxiosInstance,
      c: AxiosRequestConfig | undefined
    ) => Promise<AxiosResponse<R>>
  ): Promise<AxiosResponse<R>> {
    if (
      (!config || !config.anonymous) &&
      (!config || !config.headers || !config.headers["Authorization"])
    ) {
      try {
        const token = await auth.getOrRenewAccessToken();
        if (token != null) {
          config = config || {};
          config.headers = config.headers || {};
          config.headers["Authorization"] = `Bearer ${token}`;
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return await fn(this.instance, config);
  }
}

export const api = new ApiClient();
