<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { useAppEditorStore } from "@/stores/appEditor";
import CalendarDayComponent from "@/components/widgets/CalendarDay/CalendarDayComponent.vue";
import { defaultTextOptions } from "./CalendarDayOptions";

@Component
export default class CalendarDayWrapper extends CalendarDayComponent {
  get appEditor() {
    return useAppEditorStore();
  }
  get scale() {
    return this.appEditor.scale;
  }
  get selections() {
    return this.appEditor.selections;
  }

  get totalHeight() {
    const headerHeight =
      (this.$refs.header as HTMLElement)?.getBoundingClientRect().height || 100;
    return (
      headerHeight / this.scale +
      this.trackHeights.reduce((acc, val) => parseInt(val) + acc + 30, 0) // NOTE: hard-coded 30 is troubling, reflects space-y/padding
    );
  }

  mounted() {
    this.onLayoutChanged();
  }

  @Watch("totalHeight")
  hChanged(val: number) {
    const props = { h: val };
    this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
  }

  get watcherObject() {
    const result: any = {
      trackLength: this.tracks.length,
      showEventLocation: this.showEventLocation,
      showEventTime: this.showEventTime,
      dayOffset: this.dayOffset,
      uuids: this.data.length,
    };

    return {
      ...this.textLayoutProps,
      ...result,
    };
  }

  @Watch("watcherObject")
  onLayoutChanged() {
    // Wait for vue to render the html, then update tracks
    this.$nextTick(this.updateTrackHeights);
  }

  @Watch("scaleX")
  async onScaleXChanged() {
    await this.handleResize();
    this.updateTrackHeights();
  }

  @Watch("startHour")
  startHourChanged() {
    this.$nextTick(() => {
      this.nowXPosition = this.getNowXPosition();
    });
  }
  @Watch("endHour")
  endHourChanged() {
    this.$nextTick(() => {
      this.nowXPosition = this.getNowXPosition();
    });
  }

  handleResize() {
    return Promise.resolve(this.resizeText(this.wid, defaultTextOptions));
  }
}
</script>
