<template>
  <ScrollView>
    <template slot="header">
      <BreadcrumbNavigation class="h-full text-xl" :links="links" />
    </template>

    <template slot="body">
      <router-view class="pt-8" />
    </template>
  </ScrollView>
</template>

<script setup lang="ts">
/**
 * NOTE: This component is used in the Dashboard, not in App Builder
 */
import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import ScrollView from "@/views/Dashboard/ScrollView.vue";
import BreadcrumbNavigation from "@/components/BreadcrumbNavigation.vue";
import { APP_EDITOR_CONNECTION_ROUTE_PATH } from "@/constants";
import { useConnectionSourceStore } from "@/stores/connectionSource";
import { Route } from "vue-router";

const sourceStore = useConnectionSourceStore();
const route = useRoute();

const links = computed(() => {
  let links: { path?: string; text: string }[] = [
    { path: "/connections", text: "Connections" },
  ];

  let source =
    route.name === "connection-setup-v2"
      ? getSourceFromSlug(route)
      : getSourceFromUrl(route.path);

  if (source) {
    links.push({
      path: `/${APP_EDITOR_CONNECTION_ROUTE_PATH}/new`,
      text: "Create",
    });

    let sourceName = source?.displayName ?? "Unknown Source";
    links.push({ text: sourceName });
  } else {
    links.push({ text: "Create" });
  }

  return links;
});

/**
 * @deprecated New implementation should use `getSourceFromSlug()` instead.
 */
function getSourceFromUrl(path: string) {
  const parts = path.split("/new/");
  if (parts.length > 1) {
    const sourceId = parts[1].split("/")[0];
    return sourceStore.getSourceByName(sourceId);
  }
}

function getSourceFromSlug(route: Route) {
  const slug = route.params.providerSlug;
  if (slug) return sourceStore.getSourceByName(slug);
}
</script>
