<template>
  <svg :viewBox="`0 0 ${width} ${height}`">
    <g :transform="`translate(${[width / 2, height / 2]})`">
      <circle
        :cx="0"
        :cy="0"
        :r="clockRadius"
        :fill="faceColor"
        :stroke="strokeColor"
        :stroke-width="4 * scaleX"
      />
      <g
        v-for="(tick, index) in secondTicks"
        :key="index + wid"
        :transform="`rotate(${sixty(tick)})`"
      >
        <line
          :x1="0"
          :x2="0"
          :y1="clock.secondTickStart"
          :y2="clock.secondTickStart + clock.secondTickLength"
          :stroke="strokeColor"
          :stroke-width="2 * scaleX"
        />
      </g>
      <g v-for="(tick, index) in hourTicks" :key="index">
        <text
          v-if="index != 0"
          :style="getStyles('clock')"
          :x="clock.hourLabelRadius * Math.sin(twelve(tick) * clock.radians)"
          :y="
            -clock.hourLabelRadius * Math.cos(twelve(tick) * clock.radians) +
            clock.hourLabelYOffset
          "
        >
          {{ index }}
        </text>
        <g :transform="`rotate(${twelve(tick)})`">
          <line
            :x1="0"
            :x2="0"
            :y1="clock.hourTickStart"
            :y2="clock.hourTickStart + clock.hourTickLength"
            :stroke="strokeColor"
            :stroke-width="4 * scaleX"
          />
        </g>
      </g>
      <g :id="`clock-hands-${randomId}`">
        <g v-for="(hand, index) in handData" :key="index + wid">
          <line
            :class="`${hand.type}-hand`"
            :style="getHandStyles(hand.type)"
            :x1="0"
            :x2="0"
            :y1="20"
            :y2="hand.length"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateTime } from "luxon";
import { makeId } from "@/utils";
import * as d3 from "d3";

@Component({})
export default class AnalogClockComponent extends Vue {
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(String) readonly timezone: string;
  @Prop(Boolean) readonly usePlayerTimezone: string;
  @Prop(Number) readonly clock_fontSize: number;
  @Prop(String) readonly clock_textColor: string;
  @Prop(String) readonly clock_fontFamily: string;
  @Prop(Number) readonly clock_fontWeight: number;
  @Prop(String) readonly clock_fontStyle: string;
  @Prop(String) readonly secondsColor: string;
  @Prop(String) readonly faceColor: string;
  @Prop(String) readonly strokeColor: string;
  @Prop(Boolean) readonly showSeconds: boolean;
  @Prop(Number) readonly numberInset: number;
  @Prop(String) wid: string;

  twelve = d3.scaleLinear().range([0, 360]).domain([0, 12]);
  sixty = d3.scaleLinear().range([0, 360]).domain([0, 60]);

  randomId = makeId();

  get width() {
    return this.w * this.scaleX;
  }

  get height() {
    return this.h * this.scaleY;
  }

  get clockRadius() {
    return this.width / 2 - 10;
  }

  getStyles(prefix: string) {
    const vm = this as any;
    const result: any = {};
    if (vm[`${prefix}_fontSize`]) {
      result.fontSize = vm[`${prefix}_fontSize`];
    }
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.fill = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }
    result.textAnchor = "middle";
    result.alignmentBaseline = "middle";

    return result;
  }

  getHandStyles(type: string) {
    const result: any = {};
    if (type === "second") {
      result.strokeWidth = 4 * this.scaleX;
      result.stroke = this.secondsColor;
    }
    if (type === "minute") {
      result.strokeWidth = 8 * this.scaleX;
      result.stroke = this.strokeColor;
    }
    if (type === "hour") {
      result.strokeWidth = 12 * this.scaleX;
      result.stroke = this.strokeColor;
    }
    return result;
  }

  get clock() {
    return {
      hourHandLength: (2 * this.clockRadius) / 3,
      minuteHandLength: this.clockRadius - 25,
      secondHandLength: this.clockRadius - 25,
      secondHandBalance: 30,
      secondTickStart: this.clockRadius,
      secondTickLength: -10 * this.scaleX,
      hourTickStart: this.clockRadius,
      hourTickLength: -18 * this.scaleX,
      hourLabelRadius: this.clockRadius - this.numberInset,
      hourLabelYOffset: 0,
      radians: Math.PI / 180,
    };
  }

  get secondTicks() {
    return this.sixty.ticks(60);
  }

  get hourTicks() {
    return this.twelve.ticks(12);
  }

  get handData() {
    return [
      {
        type: "hour",
        value: 0,
        length: -this.clock.hourHandLength,
        scale: this.twelve,
      },
      {
        type: "minute",
        value: 0,
        length: -this.clock.minuteHandLength,
        scale: this.sixty,
      },
      {
        type: "second",
        value: 0,
        length: -this.clock.secondHandLength,
        scale: this.sixty,
        balance: this.clock.secondHandBalance,
      },
    ];
  }

  private timer: number;

  moveHands() {
    d3.select(`#clock-hands-${this.randomId}`)
      .selectAll("line")
      .data(this.handData)
      .transition()
      .ease(d3.easeElastic.period(0.5))
      .attr("transform", (d) => `rotate(${d.scale(d.value)})`);
  }

  mounted() {
    this.startTimer();
    this.tick();
  }

  startTimer() {
    this.timer = window.setInterval(this.tick, 1000);
  }

  updateTime() {
    const time = this.usePlayerTimezone
      ? DateTime.now()
      : DateTime.now().setZone(this.timezone);
    this.handData[0].value = (time.hour % 12) + time.minute / 60;
    this.handData[1].value = time.minute + time.second / 60;
    this.handData[2].value = time.second;
  }

  tick() {
    this.updateTime();
    this.moveHands();
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>

<style scoped></style>
