export class KeyCodes {
  // static BACKSPACE = 8;
  // static SHIFT = 16;
  // static SPACE = 32;
  // static LEFT = 37;
  // static UP = 38;
  // static RIGHT = 39;
  // static DOWN = 40;
  // static DELETE = 46;
  // static PLUS = 187;
  // static MINUS = 189;
  // static C = 67;
  // static V = 86;

  static BACKSPACE = "Backspace";
  static DELETE = "Delete";
  static SHIFT = "ShiftLeft"; // or right... so do isShift?
  static SPACE = "Space";
  static LEFT = "ArrowLeft";
  static RIGHT = "ArrowRight";
  static UP = "ArrowUp";
  static DOWN = "ArrowDown";
  static C = "KeyC";
  static V = "KeyV";
  static Z = "KeyZ";
  static Y = "KeyY";
  static G = "KeyG";
  static PLUS = "Equal";
  static MINUS = "Minus";
  static TAB = "Tab";
  static ESCAPE = "Escape";
  static ZERO = "Digit0";

  static isArrowKey(value: string) {
    return (
      value === KeyCodes.RIGHT ||
      value === KeyCodes.LEFT ||
      value === KeyCodes.UP ||
      value === KeyCodes.DOWN
    );
  }

  static isMultiselectKey(event: KeyboardEvent) {
    // console.log("ismult", this.isMeta(event) || event.shiftKey);
    return this.isMeta(event) || event.shiftKey;
  }

  static isMeta(event: KeyboardEvent) {
    const isWindows = navigator.platform.indexOf("Win") > -1;
    return isWindows ? event.ctrlKey : event.metaKey;
  }

  static isUndo(event: KeyboardEvent) {
    return this.isMeta(event) && !event.shiftKey && event.code === KeyCodes.Z;
  }

  static isRedo(event: KeyboardEvent) {
    return this.isMeta(event) && event.shiftKey && event.code === KeyCodes.Z;
  }

  static isCopy(event: KeyboardEvent) {
    return this.isMeta(event) && event.code === KeyCodes.C;
  }

  static isPaste(event: KeyboardEvent) {
    return this.isMeta(event) && event.code === KeyCodes.V;
  }

  static isGroup(event: KeyboardEvent) {
    return this.isMeta(event) && !event.shiftKey && event.code === KeyCodes.G;
  }

  static isUngroup(event: KeyboardEvent) {
    return this.isMeta(event) && event.shiftKey && event.code === KeyCodes.G;
  }

  static isArtboardReset(event: KeyboardEvent) {
    return this.isMeta(event) && event.code === KeyCodes.ZERO;
  }
}
