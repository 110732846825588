<template>
  <span class="inline-block flex items-baseline"
    ><span v-text="time"></span
    ><span
      v-if="meridiem"
      style="margin-left: 1pt"
      :style="{ 'font-size': `${meridiemScale}em` }"
      v-text="meridiem"
    ></span>
  </span>
</template>

<script lang="ts">
import { DateTime, DateTimeFormatOptions } from "luxon";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TimeDisplay extends Vue {
  @Prop(Object) value: DateTime;
  @Prop(Object) options: DateTimeFormatOptions;
  @Prop({ type: Number, default: 0.75 }) meridiemScale: number;

  get meridiem() {
    const opts = this.options ?? {};
    if (opts.hour12) {
      return this.value?.hour >= 12 ? "PM" : "AM";
    }
  }

  get time() {
    const opts = this.options ?? {};
    return this.value?.toLocaleString(opts).replace(/\sAM|\sPM/gi, "");
  }
}
</script>
