import Vue from "vue";
import { defineStore } from "pinia";
import {
  NodeData,
  NodeSetData,
  DataSortDirection,
  DataBinding,
} from "@/types/data";

import { api } from "@/api/backend";
import { removeReactivity } from "@/utils";
import { useAppEditorStore } from "@/stores/appEditor";
import { EventBus } from "@/eventbus";

/**
 * This is a dictionary of all datasets that have been loaded into the app.
 * The outer key is the widgetId.
 */
export type WidgetDataCache = Record<
  string,
  Record<string, NodeSetData | NodeData>
>;

export interface RefreshWidgetDataOptions {
  widgetId?: string;
  appUuid?: string;
  sortDirection?: DataSortDirection;
  orderByDataUuid?: string;
  conditionUuid?: string;
}

interface AppWidgetData {
  widgetData: WidgetDataCache;
}

interface AppDataState {
  // See encodeDataCacheKey for info about these keys
  dataCache: Record<string, NodeSetData | NodeData>;
}

const CACHE_KEY_SEPARATOR = ",";
const NO_VALUE_INDICATOR = "";

const orderedBindingProperties: string[] = [
  "widgetId",
  "property",
  "query",
  "dataConnectionUuid",
  "orderByDataUuid",
  "shouldRandomize",
  "sortDirection",
  "filterUuid",
  "conditionUuid",
];

export const encodeDataCacheKey = (db: DataBinding) => {
  const props = orderedBindingProperties.map(
    (prop) => db[prop as keyof DataBinding]
  );

  const cacheKey = props.reduce((acc: string, val) => {
    return acc + (val ?? NO_VALUE_INDICATOR) + CACHE_KEY_SEPARATOR;
  }, "");

  return cacheKey;
};

const decodeDataCacheKey = (key: string) => {
  return Object.fromEntries(
    key
      .split(CACHE_KEY_SEPARATOR)
      .map((value, idx) => [orderedBindingProperties[idx], value])
  );
};

export const useAppDataStore = defineStore("appData", {
  state: (): AppDataState => {
    return {
      dataCache: {},
    };
  },

  getters: {
    /**
     * Looks at data bindings and the cache of dynamic data to compute the dynamic data for each widget,
     * in the shape that the widgetData getter is expecting.
     */
    data() {
      const result: Record<string, Record<string, NodeSetData | NodeData>> = {};
      const { dataBindings } = useAppEditorStore();
      Object.entries(this.dataCache).forEach(([cacheKey, dataset]) => {
        const keyParts = decodeDataCacheKey(cacheKey);
        const { widgetId, property } = keyParts;

        if (!(widgetId in result)) {
          result[widgetId] = {};
        }

        const db = dataBindings.find((db) =>
          Object.entries(keyParts).every(
            ([key, value]) =>
              value === NO_VALUE_INDICATOR ||
              db[key as keyof DataBinding]?.toString() === value
          )
        );

        if (db) {
          result[widgetId][property] = dataset;
        }
      });

      return result;
    },
  },

  actions: {
    async initializeWidgetData(appUuid: string) {
      return api.get<AppWidgetData>(`apps/${appUuid}/data`).then((res) => {
        // console.log("Widget data:", JSON.stringify(res.widgetData));
        Object.keys(res.widgetData).forEach((widgetId) => {
          const record = res.widgetData[widgetId];
          this.updateDataCache(widgetId, record);
        });
      });
    },

    async refreshWidgetData(widgetId: string) {
      const appUuid = useAppEditorStore().uuid;
      return api
        .get<AppWidgetData>(`apps/${appUuid}/data?widgetId=${widgetId}`)
        .then((res) => {
          if (widgetId in res.widgetData) {
            this.updateDataCache(widgetId, res.widgetData[widgetId]);
          }
        });
    },

    // Children of repeaters have their dynamic data passed through the parent, not via refreshing widget data directly
    isCacheableBinding(binding: DataBinding) {
      return !["DataSetNode", "Asset"].includes(binding?.bindingType);
    },

    /**
     * Cache the dynamic data corresponding to each property in the dataset.
     */
    updateDataCache(
      widgetId: string,
      dataset: Record<string, NodeSetData | NodeData>
    ) {
      const { dataBindings } = useAppEditorStore();

      Object.entries(dataset).forEach(([prop, value]) => {
        const binding = dataBindings
          .filter(this.isCacheableBinding)
          .find((db) => db.widgetId === widgetId && db.property === prop);

        if (!binding) return;

        // console.log("update cache for", binding, value);

        const cacheKey = encodeDataCacheKey(binding);

        Vue.set(this.dataCache, cacheKey, removeReactivity(value));
      });
    },

    syncDataForBindings() {
      const { dataBindings } = useAppEditorStore();
      const widgetIdsNeedingRefresh: string[] = [];
      dataBindings.forEach((db) => {
        if (
          this.isCacheableBinding(db) &&
          !(encodeDataCacheKey(db) in this.dataCache)
        ) {
          widgetIdsNeedingRefresh.push(db.widgetId);
        }
      });

      EventBus.emit("AWAITING_SERVER", true);

      return Promise.all(
        widgetIdsNeedingRefresh.map((widgetId) => {
          return this.refreshWidgetData(widgetId);
        })
      ).finally(() => {
        EventBus.emit("AWAITING_SERVER", false);
      });
    },

    /**
     * This ensures that we re-fetch data for all widgets in the app using this connection.
     */
    invalidateCacheForConnection(connectionUuid: string) {
      Object.keys(this.dataCache)
        .filter((key) => key.includes(connectionUuid))
        .forEach((key) => {
          Vue.delete(this.dataCache, key);
        });

      this.syncDataForBindings();
    },
  },
});
