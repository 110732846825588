export interface CyclingOptions {
  /**
   * Whether to rotate content (when repeaters are bound to a dataset).
   * If false, only the first "page" of data will be displayed.
   * If true, all "pages" of data will be rotated.
   */
  cycleContent: boolean;

  /**
   * How many seconds to display each "page" before rotating
   */
  cycleDuration: number;

  /**
   * What style of animated transition to use when paging
   */
  cycleAnimationStyle: string;

  /**
   * Duration for the paging animation
   */
  cycleAnimationDuration: number;
}

export const DefaultCyclingOptions = {
  cycleContent: false,
  cycleDuration: 10,
  cycleAnimationStyle: "fade",
  cycleAnimationDuration: 0.5
};
