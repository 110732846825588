<template>
  <div
    class="flex w-[6.5rem] justify-between text-sm"
    v-if="connectionEditorStore.moderationMode !== null"
  >
    <div>
      <button
        type="button"
        class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer"
        aria-pressed="false"
        @click="handleClick"
      >
        <span
          aria-hidden="true"
          class="pointer-events-none absolute w-full h-full rounded-full bg-gray-100"
        ></span>
        <span
          aria-hidden="true"
          class="pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-100 bg-gray-100"
        ></span>
        <span
          aria-hidden="true"
          class="pointer-events-none absolute left-0 inline-block h-[0.95rem] w-[0.95rem] rounded-full shadow transform ring-0 transition-transform ease-in-out duration-100 flex justify-center items-center"
          :class="[
            selected ? 'translate-x-[22px]' : 'translate-x-[3px]',
            circleFillClass,
          ]"
        >
          <IconSolid
            class="w-[0.8rem] h-[0.8rem] text-white"
            :name="
              connectionEditorStore.moderationMode === 'Approval'
                ? 'Checkmark'
                : 'X'
            "
            v-if="selected"
          />
        </span>
      </button>
    </div>

    <div class="text-white">{{ label }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/eventbus";
import IconSolid from "@/components/icons/IconSolid.vue";
import { useConnectionDataStore } from "@/stores/connectionData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { useAppDataStore } from "@/stores/appData";

/**
 * Stretch: would be nice to show some nicer indication that we are reaching out to backend..
 * And handle errors.
 */

@Component({
  components: {
    IconSolid,
  },
})
export default class RecordData extends Vue {
  get connectionDataStore() {
    return useConnectionDataStore();
  }

  get connectionEditorStore() {
    return useConnectionEditorStore();
  }

  get selected() {
    return this.connectionDataStore.moderationRow?.isSelected;
  }

  get label() {
    let label = "";
    if (this.selected) {
      label =
        this.connectionEditorStore.moderationMode === "Approval"
          ? "Approved"
          : "Removed";
    } else {
      label =
        this.connectionEditorStore.moderationMode === "Approval"
          ? "Approve"
          : "Remove";
    }
    return this.$t(`dataPanel.moderate${label}`);
  }

  get circleFillClass() {
    if (this.selected) {
      return this.connectionEditorStore.moderationMode === "Approval"
        ? "bg-app-teal"
        : "bg-red-500";
    }
    return "bg-gray-500";
  }

  async handleClick() {
    EventBus.emit("AWAITING_SERVER", true);
    this.connectionDataStore.isModerationKeyError = false;

    try {
      const connectionDataStore = useConnectionDataStore();
      await connectionDataStore.updateModerationData({
        dcUuid: this.connectionEditorStore.connection?.uuid as string,
        data: this.connectionDataStore.moderationDataRows.map(
          (dataRow, index) => {
            if (index === this.connectionDataStore.recordIndex) {
              return {
                ...dataRow,
                isSelected: !dataRow.isSelected,
              };
            } else {
              return dataRow;
            }
          }
        ),
      });

      useAppDataStore().invalidateCacheForConnection(
        this.connectionEditorStore.connection?.uuid || ""
      );
    } catch (e: any) {
      console.log("Error updating mod data...", e);
      if (e.code === "dataModeration.nonUniquePrimaryKeys") {
        this.connectionDataStore.isModerationKeyError = true;
      }
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
    }
  }
}
</script>
