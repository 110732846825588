<template>
  <div class="divide-y">
    <div class="my-3">
      <GroupTextFormatEditor
        :model="model"
        element="clock"
        :disableLineHeight="true"
        :disableLetterSpacing="true"
        :disableTextTransform="true"
        :disableAlignment="true"
        @update="apply($event)"
      />
      <div class="ml-6">
        <div class="text-sm text-gray-500" v-t="'clock.numberInset'"></div>
        <NumberSliderInput
          class="pr-6"
          :min="0"
          :max="100"
          :step="1"
          :value="model.numberInset"
          @change="apply({ numberInset: $event })"
          @input="preview({ numberInset: $event })"
        />
      </div>

      <div class="ml-3 mr-6 space-y-3">
        <ColorInputBar
          :value="model.faceColor"
          :gradientEnabled="false"
          @input="preview({ faceColor: $event })"
          @change="apply({ faceColor: $event })"
          propName="faceColor"
          ><span v-t="'clock.faceColor'" class="text-sm"></span
        ></ColorInputBar>
        <ColorInputBar
          :value="model.secondsColor"
          :gradientEnabled="false"
          @input="preview({ secondsColor: $event })"
          @change="apply({ secondsColor: $event })"
          propName="secondsColor"
          ><span v-t="'clock.secondsColor'" class="text-sm"></span
        ></ColorInputBar>
        <ColorInputBar
          :value="model.strokeColor"
          :gradientEnabled="false"
          @input="preview({ strokeColor: $event })"
          @change="apply({ strokeColor: $event })"
          propName="strokeColor"
          ><span v-t="'clock.detailColor'" class="text-sm"></span
        ></ColorInputBar>
      </div>

      <div class="py-3 px-4">
        <ToggleInput
          class="ml-2 my-2"
          :value="model.usePlayerTimezone"
          @input="apply({ usePlayerTimezone: $event })"
          ><span class="text-sm" v-t="'clock.timezone'"></span
        ></ToggleInput>
        <TimeZoneSelect
          v-if="!model.usePlayerTimezone"
          :value="model.timezone"
          @selectTimeZone="apply({ timezone: $event })"
        />
      </div>
    </div>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import TimeZoneSelect from "@/components/TimeZoneSelect.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { AnalogClockOptions } from "./AnalogClockOptions";

@Component({
  components: {
    ToggleInput,
    ColorInputBar,
    NumberSliderInput,
    GroupTextFormatEditor,
    BaseWidgetEditor,
    TimeZoneSelect,
  },
})
export default class AnalogClockEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as AnalogClockOptions;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }
}
</script>
