import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface ProgressDonutOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  w: number;
  h: number;
  bgFill: string;
  fgFill: string;
  bgInnerRadius: number;
  bgOuterRadius: number;
  fgInnerRadius: number;
  fgOuterRadius: number;
  cornerRadius: number;
  minValue: number;
  maxValue: number;
  currentValue: number;
  lockAspect: boolean;
}

export const defaultProgressDonutOptions = {
  w: 100,
  h: 400,
  minValue: 0,
  maxValue: 500,
  currentValue: 330,
  bgFill: "rgba(254, 205, 211, 100)",
  fgFill: "rgba(244, 62, 92, 100)",
  bgInnerRadius: 55,
  bgOuterRadius: 100,
  fgInnerRadius: 55,
  fgOuterRadius: 100,
  cornerRadius: 0,
  lockAspect: true,
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultProgressDonutOptions,
};

const CreateProgressDonut = (options: Partial<ProgressDonutOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "ProgressDonut",
    wid: makeId(6),
    lockAspect: true,
    name: DEFAULT_LAYER_NAMES.ProgressDonut,
  });
};

export default CreateProgressDonut;
