/**
 * DON'T OVERUSE THIS FUNCTION!
 *
 * Returns the component instance of a widget in the CanvasRender context.
 *
 * It recursively scans the component tree of the provided node and returns
 * the first component that has an attribute `renderWid` with the value of
 * the supplied `wid`.
 *
 * I specifically added that attribute in the following components
 *  - CanvasRender
 *  - RepeaterComponent
 *  - GroupComponent
 *
 * This feels __really__ janky, but it works and it solved a problem where
 * WidgetResizer needs access to the component instance of the widget
 * so it can call handleResize() on it.
 */
export const findWidget = (node: Vue, wid: string): Vue | null => {
  if (node.$attrs?.renderWid === wid) {
    return node;
  }
  const children = node.$children;
  if (children) {
    for (const child of children) {
      const found = findWidget(child, wid);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
