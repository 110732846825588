"use strict";

import * as _pointFinder from "./point-finder";
import { subtractVectors } from "@/utils";
import { screenCoordsToCanvas } from "@/utils";

// Takes a position in canvas coordinates and finds its coordinates relative to a target point (e.g. a widget's center):
const getPositionRelativeTo = (pt, tgt, artboardPosition) => {
  const ptRelArtboard = subtractVectors(pt, artboardPosition);
  const ptRelTgt = subtractVectors(ptRelArtboard, tgt);
  return ptRelTgt;
};

export default function (_ref, onUpdate) {
  const x = _ref.x,
    y = _ref.y,
    scaleX = _ref.scaleX,
    scaleY = _ref.scaleY,
    scl = _ref.canvasScale,
    width = _ref.width,
    height = _ref.height,
    angle = _ref.angle,
    startX = _ref.startX,
    startY = _ref.startY,
    canvasX = _ref.canvasX,
    canvasY = _ref.canvasY,
    canvasW = _ref.canvasW,
    canvasH = _ref.canvasH,
    artboardX = _ref.artboardX,
    artboardY = _ref.artboardY;

  const wgCenter = (0, _pointFinder.getCenter)({
    x: x,
    y: y,
    scaleX: scaleX,
    scaleY: scaleY,
    width: width,
    height: height,
  });

  const cBox = {
    x: canvasX,
    y: canvasY,
    w: canvasW,
    h: canvasH,
  };

  const ptRelCanvas = screenCoordsToCanvas({ x: startX, y: startY }, cBox, scl);
  const ptRelWgCtr = getPositionRelativeTo(ptRelCanvas, wgCenter, {
    x: artboardX,
    y: artboardY,
  });
  const pressAngle = (Math.atan2(ptRelWgCtr.y, ptRelWgCtr.x) * 180) / Math.PI;
  // console.log("pressAng", pressAngle);s

  return function (event) {
    const ptRelCanvas = screenCoordsToCanvas(
      { x: event.pageX, y: event.pageY },
      cBox,
      scl
    );
    const ptRelWgCtr = getPositionRelativeTo(ptRelCanvas, wgCenter, {
      x: artboardX,
      y: artboardY,
    });
    const degree = (Math.atan2(ptRelWgCtr.y, ptRelWgCtr.x) * 180) / Math.PI;
    let ang = angle + degree - pressAngle;

    if (event.shiftKey) {
      ang = ((ang / 15) >> 0) * 15;
    }

    // console.log("rot....");

    onUpdate({
      angle: ang,
    });
  };
}
