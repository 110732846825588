<template>
  <div>
    <div class="text-sm text-gray-500 px-3"><slot /></div>
    <div class="relative flex flex-col my-4 mb-6 w-58 mx-auto">
      <input
        class="slider-input"
        type="range"
        :min="min"
        :max="max"
        step="1"
        :value="sliderMin"
        @change="setSliderMin($event, true)"
        @input="setSliderMin($event, false)"
      />
      <input
        class="slider-input"
        type="range"
        :min="min"
        :max="max"
        step="1"
        :value="sliderMax"
        @change="setSliderMax($event, true)"
        @input="setSliderMax($event, false)"
      />

      <div
        class="absolute top-0 h-1 w-full bg-gray-300 rounded-full border-0"
      ></div>
      <div
        :style="fillStyle"
        class="absolute top-0 h-1 bg-app-teal rounded-full border-0"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class DoubleSliderInput extends Vue {
  @Prop(Number) minValue: number;
  @Prop(Number) maxValue: number;
  @Prop(Number) min: number;
  @Prop(Number) max: number;
  @Prop({ default: 1 }) minRange: number;

  get sliderMin() {
    return this.minValue;
  }

  setSliderMin(ev: any, track = false) {
    const { value } = ev.target;
    const v = parseInt(value);
    if (this.maxValue - v < this.minRange) {
      this.$emit("updateMax", { value: v + this.minRange, track: false });
    }
    this.$emit("updateMin", { value: v, track });
  }

  get sliderMax() {
    return this.maxValue;
  }

  setSliderMax(ev: any, track = false) {
    const { value } = ev.target;
    const v = parseInt(value);
    if (v - this.minValue < this.minRange) {
      this.$emit("updateMin", { value: v - this.minRange, track: false });
    }
    this.$emit("updateMax", { value: v, track });
  }

  linearScale(value: number) {
    return 100 * ((value - this.min) / (this.max - this.min));
  }

  get fillStyle() {
    return {
      left: `${this.linearScale(this.minValue)}%`,
      right: `${100 - this.linearScale(this.maxValue)}%`,
    };
  }
}
</script>
