<template>
  <div class="space-y-8">
    <div class="text-xl" v-t="'SetupSync.howOften'"></div>

    <fieldset>
      <div class="bg-app-dark2 rounded-md -space-y-px">
        <label
          :key="o.value"
          v-for="(o, index) in options"
          :class="{
            'bg-app-dark1 border-black z-10': refreshRate === o.value,
            'border-gray-900': refreshRate !== o.value,
            'rounded-tl-md rounded-tr-md': index === 0,
            'rounded-bl-md rounded-br-md': index === options.length - 1,
          }"
          class="relative border p-4 flex cursor-pointer focus:outline-none"
        >
          <input
            type="checkbox"
            :checked="refreshRate === o.value"
            :value="refreshRate"
            @change="refreshRate = o.value"
            class="form-checkbox rounded h-4 w-4 mt-0.5 cursor-pointer dark-form-focus"
            :class="{
              'text-app-green': refreshRate === o.value,
              'bg-gray-600': refreshRate !== o.value,
            }"
          />
          <div class="ml-3 flex flex-col">
            <span
              :class="{
                'text-white': refreshRate === o.value,
                'text-gray-300': refreshRate !== o.value,
              }"
              class="block text-sm font-medium"
              v-text="o.label"
            ></span>
            <span
              :class="{
                'text-gray-200': refreshRate === o.value,
                'text-gray-500': refreshRate !== o.value,
              }"
              class="block text-sm"
              v-text="o.description"
            ></span>
          </div>
        </label>
      </div>
    </fieldset>

    <portal to="setupWizardNext">
      <FormButton @click="onComplete" v-t="'continue'"></FormButton>
    </portal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from "vue";
import { ConnectionRefreshRate } from "@/types/data";
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";
import { useI18n } from "vue-i18n-composable";
import FormButton from "@/components/FormButton.vue";
import { SetupStep } from "@/types/ConnectionSources";

const i18n = useI18n();
const refreshRate = ref<ConnectionRefreshRate>("FifteenMin");
const setupStore = useConnectionSetupStoreV2();

const emits = defineEmits<{
  (e: "complete", value: SetupStep): void;
}>();

const props = defineProps<{
  setupStep: SetupStep | undefined;
}>();

onMounted(() => {
  // if (this.store.refreshRate) {
  //   this.refreshRate = this.store.refreshRate;
  // }
});

const options = computed(() => {
  const options: {
    label: string;
    description: string;
    value: ConnectionRefreshRate;
  }[] = [
    {
      label: i18n.t("refreshRate.OnDemand").toString(),
      value: "OnDemand",
      description: i18n.t("refreshRate.OnDemandDescription").toString(),
    },
    {
      label: i18n
        .t("refreshRate.Recurring", {
          amount: 15,
          unit: "minutes",
        })
        .toString(),
      value: "FifteenMin",
      description: i18n
        .t("refreshRate.RecurringDescription", {
          amount: 15,
          unit: "minutes",
        })
        .toString(),
    },
    {
      label: i18n.t("refreshRate.Never").toString(),
      value: "Never",
      description: i18n.t("refreshRate.NeverDescription").toString(),
    },
  ];
  return options;
});

function onComplete() {
  setupStore.refreshRate = refreshRate.value;
  emits("complete", props.setupStep!);
}
</script>
