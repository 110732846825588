<template>
  <div
    :class="template ? 'visible' : 'invisible'"
    class="fixed text-white flex items-center justify-center overflow-auto z-50 bg-gray-400 bg-opacity-90 left-0 right-0 top-0 bottom-0"
    x-transition:enter="transition ease duration-300"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease duration-300"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
  >
    <div
      class="bg-app-dark1b rounded-lg shadow-2xl sm:w-10/12 mx-10 max-h-screen max-w-screen overflow-visible"
      x-transition:enter="transition ease duration-100 transform"
      x-transition:enter-start="opacity-0 scale-90 translate-y-1"
      x-transition:enter-end="opacity-100 scale-100 translate-y-0"
      x-transition:leave="transition ease duration-100 transform"
      x-transition:leave-start="opacity-100 scale-100 translate-y-0"
      x-transition:leave-end="opacity-0 scale-90 translate-y-1"
      style="width: 960px; height: 600px"
    >
      <router-view />
      <div class="w-full pt-5 text-red-600 text-lg" v-if="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { useTemplatesStore } from "@/stores/templates";

/**
 * NOTE: At least increase top margin above the "Create new category" in the "Manage Categories" view
 */

@Component({})
export default class TemplatesBase extends Vue {
  get store() {
    return useTemplatesStore();
  }

  get template() {
    return this.store.template;
  }
  get error() {
    return this.store.error;
  }
}
</script>
