<template>
  <div
    class="flex flex-wrap justify-start"
    :class="{ 'bg-white': isFromDataManager }"
    @mousedown="setClickFromHexInput(false)"
    @click="click"
  >
    <div
      v-if="gradientEnabled"
      class="flex items-center mb-3 border-b pb-2"
      style="width: 120%"
    >
      <div
        class="cursor-pointer h-6 w-6 rounded-full mr-2"
        v-for="(type, i) in backgroundTypes"
        :key="i"
        @click="setBackgroundType(type)"
        :class="type === backgroundType ? 'selected-type' : ''"
      >
        <svg class="w-full h-full" viewBox="0 0 10 10">
          <defs>
            <linearGradient
              id="linear-icon"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
              gradientTransform="rotate(90)"
            >
              <stop offset="0%" stop-color="white" />
              <stop offset="100%" stop-color="gray" />
            </linearGradient>
            <linearGradient
              id="linear-icon-selected"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
              gradientTransform="rotate(90)"
            >
              <stop offset="0%" stop-color="white" />
              <stop offset="100%" stop-color="#21c5f0" />
            </linearGradient>
            <radialGradient
              id="radial-icon"
              cx="50%"
              cy="50%"
              r="50%"
              fx="50%"
              fy="50%"
            >
              <stop offset="0%" stop-color="white" />
              <stop offset="100%" stop-color="gray" />
            </radialGradient>
            <radialGradient
              id="radial-icon-selected"
              cx="50%"
              cy="50%"
              r="50%"
              fx="50%"
              fy="50%"
            >
              <stop offset="0%" stop-color="white" />
              <stop offset="100%" stop-color="#21c5f0" />
            </radialGradient>
          </defs>
          <circle cx="5" cy="5" r="5" :fill="getSvgFill(type)" />
        </svg>
      </div>
    </div>

    <div
      v-if="backgroundType !== 'Solid'"
      class="w-full flex items-center mb-3 border-b"
    >
      <GradientSliderInput
        class="w-full mt-2 mb-4"
        :min="0"
        :step="1"
        :max="100"
        :minValue="gradientOffset1"
        :maxValue="gradientOffset2"
        :color1="gradientColor1"
        :color2="gradientColor2"
        :activeGradientNum="activeGradientNum"
        @inputMin="updateGradient({ offset_1: $event.target.value }, false)"
        @inputMax="updateGradient({ offset_2: $event.target.value }, false)"
        @changeMin="updateGradient({ offset_1: $event.target.value })"
        @changeMax="updateGradient({ offset_2: $event.target.value })"
        @clickMin="activeGradientNum = 1"
        @clickMax="activeGradientNum = 2"
      />

      <div
        class="p-1 mx-1 cursor-pointer hover:bg-gray-200"
        @click="reverseGradient"
      >
        <Icon name="Reverse" class="h-4 w-4" />
      </div>

      <div
        class="p-1 mx-1 cursor-pointer hover:bg-gray-200"
        @click="rotateGradient"
        v-if="backgroundType === 'Linear'"
      >
        <!-- NOTE: Possibly rotate this: -->
        <Icon name="Rotate" class="h-4 w-4" />
      </div>
    </div>

    <div
      class="h-36 w-full mb-3 relative rounded border border-gray-500"
      :style="{ background: saturationBg }"
      ref="saturationBox"
      @mousedown="onGridMouseDown"
      @mousemove="onGridMouseMove"
    >
      <div class="saturation--white absolute inset-0 rounded-sm"></div>
      <div class="saturation--black absolute inset-0 rounded-sm"></div>
      <div
        class="saturation-pointer"
        :style="{ top: pointerTop, left: pointerLeft }"
      >
        <div class="saturation-circle"></div>
      </div>
    </div>

    <div class="w-full flex mb-1">
      <div class="flex-grow">
        <div class="w-full h-3 mb-2">
          <input
            type="range"
            min="0"
            max="360"
            :value="h"
            class="slider hue-slider"
            @input="hueSliderChange($event)"
            @change="hueSliderChange($event, true)"
          />
        </div>
        <div class="w-full h-3 relative">
          <div class="absolute inset-0 checkerboard2 rounded-lg"></div>
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            :value="a"
            class="slider absolute inset-0"
            :style="alphaSliderStyle"
            @input="alphaSliderChange($event)"
            @change="alphaSliderChange($event, true)"
          />
        </div>
      </div>
      <div class="w-8 h-8 ml-2 relative">
        <div class="absolute inset-0 rounded-lg checkerboard"></div>
        <div
          class="absolute inset-0 rounded-lg border border-gray-500"
          :style="{ background: sampleBg }"
        ></div>
      </div>
    </div>

    <div
      class="inputs-container h-6 mt-3 flex items-center w-full justify-around"
      v-if="!isFromDataManager"
    >
      <div class="w-20 h-6">
        <input
          class="block px-1 text-sm rounded-sm w-full border-1 ring-1 ring-transparent bg-transparent border-transparent hover:border-gray-200 focus-within:ring-app-teal focus-within:bg-white rounded"
          type="text"
          :value="hex"
          @mousedown="mousedownHexInput"
          @keyup.enter="submitHex"
        />
      </div>

      <div v-for="(c, i) in models" :key="i" class="input-wrap">
        <EditorNumberInput
          :step="c.step"
          :min="c.min"
          :max="c.max"
          :py="0"
          :px="0"
          :disabled="disabled"
          :value="c.value"
          @change="numInputChange($event, c.name)"
        />
      </div>

      <div class="input-wrap">
        <EditorNumberInput
          :step="1"
          :min="0"
          :max="100"
          :px="0"
          :py="0"
          :disabled="disabled"
          :value="Math.round(a)"
          @change="alphaInputChange($event)"
        />
      </div>
    </div>
    <div
      class="inputs-container h-6 mt-3 flex items-center w-full justify-around"
      v-if="isFromDataManager"
    >
      <div class="w-20 h-6">
        <input
          class="block px-1 text-sm w-full border border-gray-300 focus:outline-none focus:shadow-outline focus:bg-white focus:border-gray-400"
          type="text"
          :value="hex"
          @mousedown="mousedownHexInput"
          @keyup.enter="submitHex"
        />
      </div>

      <div v-for="(c, i) in models" :key="i" class="input-wrap">
        <NumberInput
          :step="c.step"
          :min="c.min"
          :max="c.max"
          :disabled="disabled"
          :value="c.value"
          @change="numInputChange($event, c.name)"
        />
      </div>

      <div class="input-wrap">
        <NumberInput
          :step="1"
          :min="0"
          :max="100"
          :disabled="disabled"
          :value="Math.round(a)"
          @change="alphaInputChange($event)"
        />
      </div>
    </div>

    <div
      class="labels-container w-full flex justify-around items-center text-xs"
    >
      <div class="w-20 text-center">HEX</div>

      <div
        class="relative w-36 h-6"
        @mouseenter="modelHighlighted = true"
        @mouseleave="modelHighlighted = false"
      >
        <div
          class="model-labels absolute mt-1 w-full left-0 h-4 flex justify-around items-center bg-gray-200 bg-opacity-0"
          :class="{ 'bg-opacity-100': modelHighlighted }"
        >
          <div v-for="(l, i) in colorModel.split('')" :key="i">
            {{ l.toUpperCase() }}
          </div>
        </div>
        <select
          v-model="colorModel"
          :disabled="disabled"
          class="model-select bg-transparent text-transparent absolute inset-0 w-full"
        >
          <option
            v-for="(model, i) in colorModels"
            :key="i"
            :value="model"
            class="flex w-full justify-around"
          >
            {{ model.toUpperCase() }}
          </option>
        </select>
      </div>

      <div class="w-12 text-center">Alpha</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import tinycolor from "tinycolor2";
import Icon from "@/components/icons/Icon.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import { Rectangle } from "@/types";
import { clamp, parseGradientCss } from "@/utils";
import GradientSliderInput from "@/components/inputs/GradientSliderInput.vue";
import { EventBus } from "@/eventbus";

const cssFromGradientParts = (
  type: string,
  col1: string,
  col2: string,
  offset1 = 0,
  offset2 = 100,
  angle = 0
) => {
  if (type === "Radial") {
    return `radial-gradient(${col1} ${offset1}%, ${col2} ${offset2}%)`;
  } else if (type === "Linear") {
    return `linear-gradient(${angle}deg, ${col1} ${offset1}%, ${col2} ${offset2}%)`;
  }

  return "";
};

// [x] NOTE: Probably want to allow swatch-clicking to control active gradient color, rather than set to Solid...

// [x] TODO: handle tracking for gradient mutations
// (and misc color/numbersliderinput instances)
// [ ] TODO: control gradient angle? ... Hmm... 2 of them don't work on Rect...And Ellipse!
// [x] TODO: do not show rotate btn if Radial
// [x] TODO: weird bug where first time you click hue, the colors swap.
// [x] TODO: need to be aware of gradientEnabled somehow...don't want to pass through bar, input, panel, to picker..
// [x] reverse btn?
// [x] alpha not working (and when you change hue or anything, jumps back to 1 -- oh, so instead of 0.99, use this.a)
// [x] TODO: show gradient in colorInput svg (use div??)
// NOTE: handle storing cols....(it's good enough for now)
// clean up gradient icons

// ============
// TODO: make angle wider (for 3-digit display)

// probably need to recreate subtle hover highlights
// Also: think of way to use saved colors here...

// [x] TODO: Shadow fill can likely also take gradient, yes? And border? Look into this....Hmm it seems not

// TODO: Would be nice to be able to update radial gradient center

// TODO:
// divider lines would be nice
// hmm...multi-range slider?

// Some bug...if you reverse, and then go back to solid...it's weird...
// So perhaps it would behoove us to separate concept of "primary color" from "solid bg color"...

// 2 bugs (starting hue slide, and drag to bottom)
// Border on slider thumbs?
// Checkboard bgs.. Still need for Text buttons
// Spacing between inputs....(right-side alignment..)%
// TODO: throttle

@Component({
  components: {
    EditorNumberInput,
    Icon,
    GradientSliderInput,
    NumberInput,
  },
})
export default class ColorPicker extends Vue {
  @Prop() value: string; // Will be of form hsla({h}, {s}%, {l}%, {a})
  @Prop({ default: false }) gradientEnabled: boolean;
  @Prop({ default: false }) stopPropagation: boolean;
  @Prop({ default: false }) isFromDataManager: boolean;

  colorModels = ["rgb", "hsl", "hsv"];
  colorModel = this.colorModels[0];
  modelHighlighted = false;

  isGridMouseDown = false;
  gridRect: Rectangle = { w: 0, h: 0, x: 0, y: 0 };
  valueCopy = "";

  backgroundTypes = ["Solid", "Linear", "Radial"];
  activeGradientNum = 1; // 1 or 2

  click(ev: MouseEvent) {
    // console.log("click cp!");
    if (this.stopPropagation) {
      ev.stopPropagation();
    }
  }

  bgStyle() {
    if (this.isFromDataManager) {
      return {
        background: "white",
      };
    } else {
      return {
        background: "transparent",
      };
    }
  }

  created() {
    if (this.gradientParts.type === "Solid") {
      this.valueCopy = this.value;
    } else {
      this.valueCopy = this.gradientParts[`color_${this.activeGradientNum}`];
    }
  }

  setBackgroundType(type: string) {
    this.backgroundType = type;
    console.log("type...", type);
  }

  // ahh....almost works.....but then click on "close cp" does not work

  // You know....feels it would be better to just get rid of mouseup=>closePanel if mousedown was anywhere in panel...
  setClickFromHexInput(val: boolean) {
    EventBus.emit("CLICK_FROM_HEX_INPUT", val);
  }

  mousedownHexInput(ev: any) {
    ev.stopPropagation();
    this.setClickFromHexInput(true);
  }

  @Watch("value")
  onValueChanged(value: string) {
    const parts: any = parseGradientCss(value);
    if (parts.type === "Solid") {
      this.valueCopy = value;
      // console.log("value changed", value, this.a);
    } else {
      this.valueCopy = parts[`color_${this.activeGradientNum}`];
    }
  }

  @Watch("activeGradientNum")
  onValChanged(val: any) {
    const parts: any = parseGradientCss(this.value);
    this.valueCopy = parts[`color_${val}`];
  }

  // Handle Gradients ================================================

  get gradientParts(): any {
    const parts = parseGradientCss(this.value);
    return parts;
  }

  get backgroundType() {
    return this.gradientParts.type || "Solid";
  }

  set backgroundType(val: string) {
    this.updateGradient({ backgroundType: val });
  }

  get gradientAngle() {
    return this.gradientParts.angle || 0;
  }

  get gradientOffset1() {
    return this.gradientParts.offset_1 || 0;
  }

  get gradientOffset2() {
    return this.gradientParts.offset_2 || 100;
  }

  get gradientColor1() {
    return this.gradientParts.color_1 || "hsla(50, 65%, 77%, 1)";
  }

  get gradientColor2() {
    return this.gradientParts.color_2 || "hsla(194, 65%, 77%, 1)";
  }

  getSvgFill(type: string) {
    if (type === "Solid")
      return this.backgroundType === type ? "#21c5f0" : "gray";
    if (type === "Linear")
      return this.backgroundType === type
        ? "url(#linear-icon-selected)"
        : "url(#linear-icon)";
    if (type === "Radial")
      return this.backgroundType === type
        ? "url(#radial-icon-selected)"
        : "url(#radial-icon)";
    return "";
  }

  rotateGradient() {
    const angle = (this.gradientAngle + 45) % 360;
    this.updateGradient({
      angle,
    });
  }

  reverseGradient() {
    this.updateGradient({
      color_1: this.gradientColor2,
      color_2: this.gradientColor1,
    });
  }

  // TODO: use object.assign to override with props
  updateGradient(props: any = {}, enableTracking = true) {
    // console.log("update", props);
    const bgType = props.backgroundType || this.backgroundType;
    if (bgType === "Solid") {
      // NOTE: May want some way of storing solid fill
      this.$emit("input", this.valueCopy);
      return;
    }

    if ("offset_1" in props) {
      this.activeGradientNum = 1;
    }

    if ("offset_2" in props) {
      this.activeGradientNum = 2;
    }

    const offset_1 = isNaN(props.offset_1)
      ? this.gradientOffset1
      : props.offset_1;
    const offset_2 = isNaN(props.offset_2)
      ? this.gradientOffset2
      : props.offset_2;
    const angle = isNaN(props.angle) ? this.gradientAngle : props.angle;

    const color_1 = props.color_1 ?? this.gradientColor1;
    const color_2 = props.color_2 ?? this.gradientColor2;

    const gradientCss = cssFromGradientParts(
      bgType,
      color_1,
      color_2,
      offset_1,
      offset_2,
      angle
    );

    // console.log("emit", gradientCss);
    const event = enableTracking ? "change" : "input";
    this.$emit(event, gradientCss);
  }

  updateGradientColor(color: tinycolor.Instance, enableTracking = true) {
    const colObject = color.toHsl();
    // This turns it into an hsla string...makes it work...
    // Issue is in parseGradientCss  when one color has hsla and other has hsl
    const col = `hsla(${colObject.h}, ${colObject.s * 100}%, ${
      colObject.l * 100
    }%, ${color.getAlpha()})`;

    if (this.activeGradientNum === 1) {
      this.updateGradient(
        {
          color_1: col,
          color_2: this.gradientColor2,
        },
        enableTracking
      );
    } else {
      this.updateGradient(
        {
          color_1: this.gradientColor1,
          color_2: col,
        },
        enableTracking
      );
    }
  }

  // =================================================================

  get hex() {
    return `#${tinycolor(this.valueCopy).toHex()}`;
  }

  get rgb() {
    return tinycolor(this.valueCopy).toRgb();
  }

  get hsl() {
    return tinycolor(this.valueCopy).toHsl();
  }

  get hsv() {
    return tinycolor(this.valueCopy).toHsv();
  }

  get r() {
    return this.rgb.r;
  }

  get g() {
    return this.rgb.g;
  }

  get b() {
    return this.rgb.b;
  }

  get h() {
    return this.hsl.h;
  }

  get s() {
    // must come from hsv, not hsl, for saturation display to work:
    return this.hsv.s * 100;
  }

  get sFromHsl() {
    return this.hsl.s * 100;
  }

  get v() {
    return this.hsv.v * 100;
  }

  get l() {
    return this.hsl.l * 100;
  }

  get a() {
    return tinycolor(this.valueCopy).getAlpha() * 100;
  }

  get models() {
    return this.colorModel.split("").map((part) => {
      let max = 255;
      switch (part) {
        case "h":
          max = 360;
          break;
        case "s":
        case "v":
        case "l":
          max = 100;
          break;
        default:
          max = 255;
      }

      let valueProperty = part;
      if (part === "s" && this.colorModel === "hsl") {
        valueProperty = "sFromHsl";
      }
      let value = (this as any)[valueProperty];

      return {
        name: part,
        min: 0,
        max: max,
        step: 1,
        value: Math.round(value),
      };
    });
  }

  get pointerTop() {
    return -this.v + 1 + 100 + "%";
  }

  get pointerLeft() {
    return this.s + "%";
  }

  submitHex(ev: any) {
    const tc = tinycolor(ev.target.value);
    if (tc.isValid()) {
      console.log("enter hex", tc);
      this.updateColor(tc);
    } else {
      console.log("invalid hex input");
      // TODO: Probably show some error message
    }
  }

  get alphaSliderStyle() {
    const rgb = this.rgb;
    const rgbStr = [rgb.r, rgb.g, rgb.b].join(",");

    return {
      background:
        "linear-gradient(to right, rgba(" +
        rgbStr +
        ", 0) 0%, rgba(" +
        rgbStr +
        ", 1) 100%)",
    };
  }

  getInputConfig(part: string) {
    let max = 255;
    if (part === "h") {
      max = 360;
    }
    if (part === "s" || part === "v" || part === "l") {
      max = 100;
    }

    const modelPart =
      part === "s" && this.colorModel === "hsl" ? "sFromHsl" : part;

    return {
      min: 0,
      max,
      step: 1,
      value: (this as any)[modelPart],
    };
  }

  get saturationBg() {
    return `hsl(${this.h}, 100%, 50%)`;
  }

  get sampleBg() {
    return this.value;
  }

  measureGrid() {
    const container = this.$refs.saturationBox as HTMLElement;
    const bbox = container.getBoundingClientRect();
    return {
      w: container.clientWidth,
      h: container.clientHeight,
      x: bbox.left + window.pageXOffset,
      y: bbox.top + window.pageYOffset,
    };
  }

  getGridColor(e: any) {
    const pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
    const pageY = e.pageY || (e.touches ? e.touches[0].pageY : 0);
    const left = clamp(pageX - this.gridRect.x, 0, this.gridRect.w);
    const top = clamp(pageY - this.gridRect.y, 0, this.gridRect.h);
    const saturation = left / this.gridRect.w;
    const brightness = clamp(-(top / this.gridRect.h) + 1, 0, 1);

    const hsv = this.hsv;
    // Not sure why we do this...
    hsv.s = saturation === 0 ? 0.001 : saturation;
    hsv.v = brightness === 0 ? 0.004 : brightness;

    return tinycolor(hsv);
  }

  onGridMouseMove(e: any) {
    if (this.isGridMouseDown) {
      this.updateColor(this.getGridColor(e), false);
    }
  }

  onGridMouseDown() {
    this.isGridMouseDown = true;
    this.gridRect = this.measureGrid();
    window.addEventListener("mouseup", this.onGridMouseUp);
  }

  onGridMouseUp(e: any) {
    this.isGridMouseDown = false;
    this.updateColor(this.getGridColor(e));
    window.removeEventListener("mouseup", this.onGridMouseUp);
  }

  hueSliderChange(ev: any, enableTracking = false) {
    const hsl = this.hsl;
    hsl.h = ev.target.value;
    // I mean...this works (allows you to change hue when color is black)...with the problem that r,g,b are set to 1...
    if (hsl.s === 0) hsl.s = 0.001;
    if (hsl.l === 0) hsl.l = 0.004; // any lower and the hack fails

    this.updateColor(tinycolor(hsl), enableTracking);
  }

  alphaSliderChange(e: any, enableTracking = false) {
    const color = tinycolor(this.valueCopy).setAlpha(e.target.value / 100);
    this.updateColor(color, enableTracking);
  }

  saturationChange(saturation: number, brightness: number) {
    const hsv = this.hsv;
    // This fixes dragging off to the left issue:
    // TODO: still need to fix drag off bottom,
    // and starting hue slider
    hsv.s = saturation === 0 ? 0.001 : saturation;

    // Again...sort of works but not really:
    hsv.v = brightness === 0 ? 0.004 : brightness;
    // hsv.v = brightness;

    this.updateColor(tinycolor(hsv));
  }

  numInputChange(value: number, type: string) {
    const color: any = (this as any)[this.colorModel];
    color[type] = value;
    this.updateColor(tinycolor(color));
  }

  alphaInputChange(value: number) {
    const color = tinycolor(this.valueCopy).setAlpha(value / 100);
    this.updateColor(color);
  }

  updateColor(color: tinycolor.Instance, enableTracking = true) {
    if (this.backgroundType === "Solid") {
      const cssString = color.toHslString();
      // Is this needed?
      this.valueCopy = cssString;
      const event = enableTracking ? "change" : "input";
      // console.log("emit...", cssString);
      this.$emit(event, cssString);
    } else {
      this.updateGradientColor(color, enableTracking);
    }
  }

  get disabled() {
    return false;
  }
}
</script>

<style scoped lang="postcss">
/* NOTE: Need Autoprefixer in order for appearance-none to work */
.slider {
  @apply w-full h-full outline-none rounded-lg;
}

.input-wrap {
  @apply block w-12 h-6 flex flex-col items-center;
}

.model-select {
  -webkit-appearance: none;
  appearance: none;
  border: none;
}
.model-select:focus {
  outline: none;
}

.checkerboard2 {
  opacity: 0.3;
  background-image: url("../../assets/png_background.jpg");
  background-size: cover;
}

.saturation--white {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.saturation--black {
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
}

.saturation-pointer {
  cursor: pointer;
  position: absolute;
}

.saturation-circle {
  cursor: head;
  width: 4px;
  height: 4px;
  box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, 0.3),
    0 0 1px 2px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  transform: translate(-2px, -2px);
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  @apply border border-gray-500;
}

.slider:hover {
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  /* 
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  box-shadow: 0 0 0 3px #fff; */

  box-shadow: 0 0 2px 1px #777;
  @apply appearance-none relative cursor-pointer bg-transparent w-3 h-3 z-10 rounded-full border-3 border-solid border-white top-0;
}

.hue-slider {
  background: linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
}

.selected-type {
  outline: #21c5f0 solid 1px;
}

.slider-special input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  /* background: #eee; */
  box-shadow: 0 0 0.03rem 0.03rem #aaa;
  border-radius: 0.2rem;
  height: 0.5rem;
  cursor: pointer;

  /* background: linear-gradient(to right, #aaa, #aaa 30%, blue 30.01%, blue); */
}

.slider-special input[type="range"]:focus {
  outline: none;
}

#gradient-angle-label {
  position: absolute;
  background: rgb(207, 206, 206);
  top: -2rem;
  left: 3rem;
  font-size: 0.7rem;
  padding: 0.3rem;
  box-shadow: 0.04rem 0.04rem 0.08rem 0.08rem rgb(109, 109, 108);
}

.hideAngleLabel {
  opacity: 0;
  transition: opacity 0.1s linear;
}
</style>
