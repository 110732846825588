import { defineStore } from "pinia";
import {
  CompleteStep,
  ConnectionSource,
  Documentation,
  SetupStep,
} from "@/types/ConnectionSources";
import { useConnectionSourceStore } from "./connectionSource";
import { useConnectionDataStore } from "./connectionData";
import { useConditionGroupsStore } from "./conditionGroups";
import { BackendError, api } from "@/api/backend";
import {
  BindingType,
  ConnectionRefreshRate,
  DataBinding,
  DataBindingProperty,
  DataConnection,
  Node,
  SchemaNode,
  SourceDataSchema,
} from "@/types/data";
import { EventBus } from "@/eventbus";
import { useAppEditorStore } from "./appEditor";
import { useConnectionsStore } from "./connections";
import { BASE_PARENT_ID, PLACEHOLDER_CONNECTION_UUID } from "@/constants";

export type ConnectionSetupInitParams = {
  providerSlug: string;
  stepId: string;
  activeConnectionUuid: string | null;
  widgetId: string | null;
  propertyName: string | null;
  propertyType: string | null;
  bindingType: string | null;
  isReplacing: boolean;
};

function buildCompleteStep(source: ConnectionSource | undefined): CompleteStep {
  return {
    id: "complete",
    order: 100,
    shouldPersistConnection: false,
    clientComponentName: "SetupCompleteV2",
    displayNameI18nKey: "SetupComplete.complete",
    documentationComponentName:
      source?.documentation?.clientComponentName ?? "CompleteDefaultV2",
    shouldShowInNavigation: false,
  };
}

function buildReplaceStep(): SetupStep {
  return {
    id: "replace",
    order: 99,
    shouldPersistConnection: false,
    clientComponentName: "SetupReplaceV2",
    displayNameI18nKey: "Setup.remapColumns",
    shouldShowInNavigation: true,
  };
}

function buildScalarSelectStep(): SetupStep {
  return {
    id: "selection",
    order: 99,
    shouldPersistConnection: false,
    clientComponentName: "SetupSelectV2",
    displayNameI18nKey: "Setup.selection",
    shouldShowInNavigation: true,
  };
}

export interface ConnectionSetupStateV2 {
  providerId: string | undefined;
  steps: SetupStep[] | undefined;
  documentation: Documentation | undefined;
  currentStep: SetupStep | undefined;
  schemaWrapper: SourceDataSchema | undefined;
  isSetupComplete: boolean;
  hasUnhandledServerError: boolean;
  connection: DataConnection | undefined;
  connectionUuid: string | undefined;

  /**
   * User defined name for the connection.
   */
  name: string | null;

  /**
   * The user's selection for how often they want their url data source synchronized
   */
  refreshRate: ConnectionRefreshRate | null;

  /**
   * This stores the users column configurations for collections
   */
  configuredNodes: SchemaNode[];

  /**
   * Authorization ID for the 3rd party data source for the connection
   */
  providerAuthId: string | null;

  /**
   * Whether the connection is a collection.
   * If false, it's a "Scalar" connection.
   */
  isCollection: boolean;

  /**
   * The uuid of the connection that will be replaced
   */
  connectionUuidToBeReplaced: string | null;

  /**
   * Whether the user is replacing an existing connection with a new one.
   */
  isReplacing: boolean;

  /**
   * The id for the widget the user is attempting to bind to.
   * Only applicable for ⚡️ binding
   */
  widgetId: string | null;

  /**
   * The widget property the user is attempting to bind.
   * Only applicable for ⚡️ binding
   */
  propertyName: string | null;

  /**
   * The widget property the user is attempting to bind.
   * Only applicable for ⚡️ binding
   */
  propertyType: string | null;

  selectedNode: Partial<Node> | null;

  bindingType: BindingType | null;
}

export const useConnectionSetupStoreV2 = defineStore("connectionSetupV2", {
  state: (): ConnectionSetupStateV2 => {
    return {
      providerId: undefined,
      steps: undefined,
      documentation: undefined,
      currentStep: undefined,
      schemaWrapper: undefined,
      isSetupComplete: false,
      hasUnhandledServerError: false,
      name: null,
      configuredNodes: [],
      refreshRate: null,
      providerAuthId: null,
      isCollection: true,
      connection: undefined,
      connectionUuid: undefined,
      connectionUuidToBeReplaced: null,
      widgetId: null,
      isReplacing: false,
      propertyName: null,
      propertyType: null,
      selectedNode: null,
      bindingType: null,
    };
  },

  getters: {
    previousStep(): SetupStep | undefined {
      if (!this.currentStep || !this.steps) return undefined;

      const index = this.steps.findIndex((s) => s.id === this.currentStep?.id);
      return index > 0 ? this.steps[index - 1] : undefined;
    },

    nextStep(): SetupStep | undefined {
      if (!this.currentStep || !this.steps) return undefined;

      const index = this.steps.findIndex((s) => s.id === this.currentStep?.id);
      return index !== this.steps.length - 1
        ? this.steps[index + 1]
        : undefined;
    },

    isFinalStep(): boolean {
      return this.steps?.at(-1)?.id === this.currentStep?.id;
    },

    source(): ConnectionSource | undefined {
      const sourceStore = useConnectionSourceStore();
      return sourceStore.getSourceByName(this.providerId);
    },
  },

  actions: {
    init(params: ConnectionSetupInitParams): void {
      const provider = useConnectionSourceStore().getSourceBySlug(
        params.providerSlug
      );
      if (!provider)
        throw new Error(
          `This is not a valid connection source. ${params.providerSlug}`
        );

      this.providerId = provider.name;
      this.steps = [...provider.setupStepsV2!];
      this.documentation = provider.documentation;
      this.currentStep = this.steps?.[0];
      this.widgetId = params.widgetId;
      this.propertyName = params.propertyName;
      this.propertyType = params.propertyType;
      this.bindingType = params.bindingType as BindingType | null;
      this.isReplacing = params.isReplacing;

      this.isCollection = this.schemaWrapper?.schemaType !== "Tree";

      if (this.isReplacing) this.initReplaceMode(params.activeConnectionUuid!);

      if (this.bindingType === "Scalar") this.initScalarMode();
    },

    initReplaceMode(connectionUuidToReplace: string): void {
      this.connectionUuidToBeReplaced = connectionUuidToReplace;
      this.injectStep(buildReplaceStep());
    },

    initScalarMode(): void {
      this.injectStep(buildScalarSelectStep());
    },

    injectStep(step: SetupStep): void {
      if (!this.steps) return;

      this.steps.push(step);
    },

    setCurrentStep(stepId: string): void {
      this.currentStep = this.steps?.find((s) => s.id === stepId);
    },

    loadSchema(apiEndpoint: string, payload: any): Promise<void> {
      EventBus.emit("AWAITING_SERVER", true);
      return api
        .post<{ connectionUuid: string; schema: SourceDataSchema }>(
          apiEndpoint,
          payload
        )
        .then((response) => {
          this.schemaWrapper = response.schema;
          this.connectionUuid = response.connectionUuid;
        })
        .catch((error: BackendError[]) => {
          if (error && error.length > 0 && error[0].code === "Validation")
            throw error[0];

          this.hasUnhandledServerError = true;
          throw new Error("Unhandled error");
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    saveConnection(): Promise<DataConnection> {
      if (!this.schemaWrapper?.connectionUuid)
        return Promise.reject(new Error("No connection UUID"));

      const payload: any = {
        name: this.name,
        providerAuthId: this.providerAuthId,
        nodes: this.configuredNodes,
        isCollection: this.isCollection,
        refreshRate: this.refreshRate,
      };

      EventBus.emit("AWAITING_SERVER", true);
      return api
        .put<DataConnection>(
          `dataconnection/${this.schemaWrapper?.connectionUuid}`,
          payload
        )
        .then((response) => {
          this.connection = response;
          return response;
        })
        .catch((error) => {
          this.hasUnhandledServerError = true;
          throw error;
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    },

    schemaToBeReplaced(): Node[] {
      if (
        this.isReplacing &&
        this.widgetId &&
        this.connectionUuidToBeReplaced
      ) {
        const appEditor = useAppEditorStore();

        const nodeSet = useConnectionsStore().connections.find(
          (c) => c.uuid === this.connectionUuidToBeReplaced
        )?.nodeSets?.[0];

        const nodes = (nodeSet?.nodes ?? []).filter((n) => !n.isArtificial);

        const bindings = appEditor.dataBindings.filter(
          (db) =>
            db.parentWidgetId === this.widgetId &&
            db.dataConnectionUuid === this.connectionUuidToBeReplaced &&
            db.bindingType === "DataSetNode"
        );

        const schemaNodes =
          bindings
            .map((db) => nodes.find((n) => n.uuid === db.dataUuid))
            .filter((db) => typeof db !== "undefined") ?? [];

        return schemaNodes as Node[];
      }
      return [];
    },

    async completeStep(): Promise<void> {
      if (this.currentStep?.shouldPersistConnection)
        await this.saveConnection();

      if (this.isFinalStep) {
        // Manual data sources don't get their connection UUID until now, so set it.
        // if (result.uuid) {
        //   params.connectionUuid = result.uuid;
        // }
        this.injectStep(buildCompleteStep(this.source));

        this.isSetupComplete = true;
        const connectionDataStore = useConnectionDataStore();
        await connectionDataStore.openNewConnection(this.connection!);
        await connectionDataStore.initializeConnection(this.connection!);
        await this.postSaveConnection();
      }
    },

    async postSaveConnection() {
      if (this.widgetId) {
        const appEditor = useAppEditorStore();
        if (
          appEditor.selectedWidget !== undefined &&
          this.connectionUuid != PLACEHOLDER_CONNECTION_UUID &&
          (appEditor.selectedWidget.type.includes("Graph") ||
            appEditor.selectedWidget.type.includes("Calendar")) &&
          this.isReplacing
        )
          return this.replaceDataBinding();
        else return this.createBinding();
      }
    },

    async createBinding() {
      if (this.bindingType === "Scalar") return this.createScalarBinding();
      else return this.createCollectionBinding();
    },

    async replaceDataBinding() {
      const appEditor = useAppEditorStore();

      if (appEditor.selectedWidget === undefined)
        throw new Error("Selected widget is undefined");

      await appEditor.replaceDataBinding({
        connection: this.connection!,
        widget: appEditor.selectedWidget,
      });

      // Needed in case data is filtered
      // TODO: This should be handled by the app editor
      await appEditor.updateApp();
    },

    async createScalarBinding() {
      const conditionGroupsStore = useConditionGroupsStore();
      const activeConditionId = conditionGroupsStore.getActiveConditionId(
        this.widgetId as string
      );
      const binding: DataBinding = {
        widgetId: this.widgetId as string,
        property: this.propertyName as DataBindingProperty,
        dataUuid: this.selectedNode?.uuid!,
        dataName: this.selectedNode?.query,
        query: this.selectedNode?.query,
        dataConnectionUuid: this.connectionUuid,
        bindingType: "Scalar",
        parentWidgetId: BASE_PARENT_ID,
        conditionUuid: activeConditionId,
      };

      const appEditor = useAppEditorStore();
      appEditor.addDataBinding(binding);

      // TODO does add databinding update the app?
      //await appEditor.updateApp();
    },

    async createCollectionBinding() {
      const appEditor = useAppEditorStore();
      const widget = appEditor.widgets[this.widgetId as string];
      const bindingType =
        widget?.type === "Repeater" ? "DataSetParent" : "DataSet";

      const binding: DataBinding = {
        widgetId: this.widgetId as string,
        property: this.propertyName as DataBindingProperty,
        bindingType: bindingType,
        dataUuid: this.selectedNode?.uuid!,
        dataName: this.selectedNode?.name!,
        dataConnectionUuid: this.connectionUuid,
      };

      appEditor.addDataBinding(binding);

      //await useConnectionDataStore().initializeConnection(connection);
    },
  },
});
