<template>
  <svg
    class="w-full h-full photo-drop-container"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    :viewBox="viewBox"
    :height="h * scaleY"
    :width="w * scaleX"
  >
    <defs>
      <pattern
        :id="componentId + 'backgroundImageUrl'"
        patternUnits="userSpaceOnUse"
        :width="patternW"
        :height="patternH"
      >
        <image
          :data-image-load="href"
          :href="href"
          x="0"
          y="0"
          :width="imageW"
          :height="imageH"
          :preserveAspectRatio="backgroundSizeRender"
        />
      </pattern>
    </defs>

    <slot></slot>
  </svg>
</template>

<script lang="ts">
import { EventBus } from "@/eventbus";
import { AppMode, WidgetType } from "@/types";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { getImageQueryParams } from "@/utils";

@Component({
  inheritAttrs: false, // DO NOT REMOVE
})
export default class PhotoDropContainer extends Vue {
  @Inject() appMode: AppMode;

  @Prop(String) readonly backgroundImageUrl: string;

  @Prop(String) readonly backgroundSize: string;
  @Prop(Number) readonly borderRadius: number;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop({ default: "no-repeat" }) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;
  @Prop(String) readonly wid: string;
  @Prop(String) readonly type: WidgetType;
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Boolean) readonly locked: boolean;
  @Prop() readonly tempBackgroundInfo: any;
  @Prop(String) parentId: string;
  @Prop(String) componentId: string;

  // Pass this in via Ellipse/Rectangle/Svg => ShapeBase
  @Prop(Number) cellIndex: number;

  // NOTE: ran into image (https://cdncab.screenfeed.com/prvd/49/svg/4pgvptf9jr1yz4zsf9paeqzzzw.svg) that messed up background img -- maybe bc svg??

  // Issue with repeater children was that each one had same wid, so "#"/id lookup of pattern failed
  get href() {
    // Avoid issue that query params may already be attached from ImageComponent, which also uses this
    if (this.backgroundImageUrl?.includes("width")) {
      return this.backgroundImageUrl;
    }
    return this.appMode === "render" && this.backgroundImageUrl != null
      ? `${this.backgroundImageUrl}${
          this.backgroundImageUrl.indexOf("?") > -1 ? "&" : "?"
        }${this.imageQueryParams}`
      : this.backgroundImageUrl;
  }

  mouseEnter() {
    if (this.appMode === "render" || this.locked) {
      return;
    }
    EventBus.emit("PHOTO_DRAG_ENTER", {
      widgetId: this.wid,
      cellIndex: this.cellIndex,
    });
  }

  mouseLeave() {
    if (this.appMode === "render") {
      return;
    }
    EventBus.emit("PHOTO_DRAG_LEAVE");
  }

  get viewBox() {
    return `0 0 ${this.width} ${this.height}`;
  }

  get width() {
    return this.type === "Svg" ? this.w : this.w * this.scaleX;
  }

  get height() {
    return this.type === "Svg" ? this.h : this.h * this.scaleY;
  }

  get imageW() {
    // const width = this.type ===  "Svg"?this.w : this.w*this.scaleX;
    return this.backgroundRepeat === "no-repeat"
      ? this.width
      : this.backgroundRepeatSize;
  }

  get imageQueryParams() {
    if (this.backgroundImageUrl != null) {
      return getImageQueryParams(this);
    }
    return "";
  }

  get imageH() {
    const bgWidth = this.tempBackgroundInfo?.url
      ? this.tempBackgroundInfo.w
      : this.backgroundImageW;
    const bgHeight = this.tempBackgroundInfo?.url
      ? this.tempBackgroundInfo.h
      : this.backgroundImageH;

    const scl = bgWidth ? bgHeight / bgWidth : 1;

    return this.backgroundRepeat === "no-repeat"
      ? this.height
      : this.backgroundRepeatSize * scl;
  }

  get patternW() {
    return this.backgroundRepeat === "no-repeat" ? "100%" : this.imageW;
  }

  get patternH() {
    return this.backgroundRepeat === "no-repeat" ? "100%" : this.imageH;
  }

  get backgroundSizeRender() {
    return `xMidYMid ${this.backgroundSize === "cover" ? "slice" : "meet"}`;
  }
}
</script>
