<template>
  <ConditionsModal>
    <!-- Confirmation Header -->
    <div class="text-center border-y border-gray-600 my-10 py-12 space-y-6">
      <div class="text-2xl font-bold" v-text="title"></div>
      <div class="text-lg italic" v-t="'ConditionSaved.returnExplain'"></div>

      <div class="flex justify-center space-x-4">
        <PrimaryButton
          @click="returnToCanvas"
          v-t="'ConditionSaved.returnToCanvas'"
        ></PrimaryButton>
        <OutlineButton
          @click="addCondition"
          v-t="'ConditionSaved.addAnother'"
        ></OutlineButton>
      </div>

      <a
        class="block mx-auto text-center text-app-teal underline"
        href="https://www.screenfeed.com/support-articles/using-conditions-in-connect"
        target="_blank"
        v-t="'ConditionSaved.learnMore'"
      ></a>
    </div>
  </ConditionsModal>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ConditionsModal from "./ConditionsModal.vue";
import PrimaryButton from "../PrimaryButton.vue";
import OutlineButton from "../OutlineButton.vue";
import { Route } from "vue-router";
import { EventBus } from "@/eventbus";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";

import { useConditionEditorStore } from "@/stores/conditionEditor";

@Component({
  components: {
    ConditionsModal,
    PrimaryButton,
    OutlineButton,
  },
})
export default class ConditionSaved extends Vue {
  get store() {
    return useConditionEditorStore();
  }

  get title() {
    return this.$t("ConditionSaved.conditionSaved", {
      name: this.store.name,
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: (proceed?: boolean) => void) {
    if (window?.event?.type == "popstate" && to.name == "condition-edit") {
      next(false);
    } else {
      next();
    }
  }

  returnToCanvas() {
    const newConditionUuid = this.$route.query.newConditionUuid as string;
    this.$router
      .push({
        name: "edit",
        params: { id: this.$route.params.id },
      })
      .then(() => {
        if (isNonEmptyString(newConditionUuid)) {
          EventBus.emit("CONDITION_CREATED", newConditionUuid);
        }
      });
  }

  addCondition() {
    this.$router.push({
      name: "condition-edit",
      params: { id: this.$route.params.id, conditionUuid: "new" },
      query: {
        widgetId: this.$route.query.widgetId,
        conditionGroupUuid: this.$route.query.conditionGroupUuid,
      },
    });
  }
}
</script>
