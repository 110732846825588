<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import CalendarWeekComponent from "@/components/widgets/CalendarWeek/CalendarWeekComponent.vue";

import { useAppEditorStore } from "@/stores/appEditor";
import { defaultTextOptions } from "./CalendarWeekOptions";

@Component
export default class CalendarWeekWrapper extends CalendarWeekComponent {
  @Watch("totalHeight")
  hChanged(val: number) {
    const appEditor = useAppEditorStore();
    const props = { h: val };
    appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
  }

  handleResize() {
    return Promise.resolve(this.resizeText(this.wid, defaultTextOptions));
  }

  get watcherObject() {
    return {
      ...this.textLayoutProps,
      data: this.data,
      weekOffset: this.weekOffset,
    };
  }

  @Watch("watcherObject", { deep: true })
  onTrackLayoutInfoChanged() {
    this.updateLayout();
  }

  @Watch("scaleX")
  async onScaleXChanged(newX: number) {
    console.log("scaleX changed", newX);
    await this.handleResize();
  }
}
</script>
