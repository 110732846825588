<template>
  <svg
    :data-type="dataType"
    class="w-full h-full"
    :style="svgStyle"
    vector-effect="non-scaling-stroke"
    :height="h * scaleY"
    :width="w * scaleX"
  >
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        :id="'Linear-grad-' + wid"
        :gradientTransform="gradientTransform"
      >
        <stop :stop-color="color_1" :offset="offset_1 + '%'" />
        <stop :stop-color="color_2" :offset="offset_2 + '%'" />
      </linearGradient>
      <radialGradient :id="'Radial-grad-' + wid">
        <stop :stop-color="color_1" :offset="offset_1 + '%'" />
        <stop :stop-color="color_2" :offset="offset_2 + '%'" />
      </radialGradient>

      <linearGradient
        gradientUnits="userSpaceOnUse"
        :id="'Linear-grad-border-' + wid"
        :gradientTransform="borderGradientTransform"
      >
        <stop :stop-color="border_color_1" :offset="border_offset_1 + '%'" />
        <stop :stop-color="border_color_2" :offset="border_offset_2 + '%'" />
      </linearGradient>
      <radialGradient :id="'Radial-grad-border-' + wid">
        <stop :stop-color="border_color_1" :offset="border_offset_1 + '%'" />
        <stop :stop-color="border_color_2" :offset="border_offset_2 + '%'" />
      </radialGradient>
    </defs>

    <PhotoDropContainer
      v-bind="$props"
      backgroundPropertyName="backgroundImageUrl"
    >
      <slot></slot>
    </PhotoDropContainer>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { getShadowCss } from "@/components/widgets/ShadowOptions";
import PhotoDropContainer from "@/components/widgets/PhotoDropContainer.vue";

@Component({
  components: {
    PhotoDropContainer,
  },
})
export default class ShapeBase extends Vue {
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;

  @Prop(String) readonly backgroundImageUrl: string;
  @Prop(String) readonly backgroundSize: string;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  @Prop(Number) readonly shadowX: number;
  @Prop(Number) readonly shadowY: number;
  @Prop(Number) readonly shadowBlur: number;
  @Prop(String) readonly shadowColor: string;
  @Prop(Boolean) readonly shadowDisplay: boolean;

  @Prop(Object) readonly gradientParts: any;
  @Prop(Object) readonly borderGradientParts: any;
  @Prop(String) readonly backgroundColor: string;
  @Prop(Number) readonly borderWidth: number;
  @Prop(String) readonly borderColor: string;
  @Prop(String) readonly dataType: string;
  @Prop(String) readonly wid: string;
  @Prop(String) readonly parentId: string;

  @Prop(Boolean) readonly locked: boolean;

  @Prop() readonly tempBackgroundInfo: any;

  @Prop(String) componentId: string;
  @Prop(Number) cellIndex: number;

  get svgStyle() {
    const shadowObj = {
      shadowX: this.shadowX,
      shadowY: this.shadowY,
      shadowBlur: this.shadowBlur,
      shadowColor: this.shadowColor,
      shadowDisplay: this.shadowDisplay,
    };
    const shadowCss = getShadowCss(shadowObj);

    return shadowCss;
  }

  get color_1() {
    return this.gradientParts.color_1;
  }
  get color_2() {
    return this.gradientParts.color_2;
  }
  get offset_1() {
    return this.gradientParts.offset_1 || 0;
  }
  get offset_2() {
    return this.gradientParts.offset_2 || 100;
  }
  get gradient_angle() {
    return this.gradientParts.angle || 0;
  }

  get gradientTransform() {
    const angle = this.gradient_angle;
    return `rotate(${angle}, ${(this.w * this.scaleX) / 2}, ${
      (this.h * this.scaleY) / 2
    })`;
  }

  get border_color_1() {
    return this.borderGradientParts.color_1;
  }
  get border_color_2() {
    return this.borderGradientParts.color_2;
  }
  get border_offset_1() {
    return this.borderGradientParts.offset_1 || 0;
  }
  get border_offset_2() {
    return this.borderGradientParts.offset_2 || 100;
  }
  get border_gradient_angle() {
    return this.borderGradientParts.angle || 0;
  }

  get borderGradientTransform() {
    const angle = this.gradient_angle;
    return `rotate(${angle}, ${(this.w * this.scaleX) / 2}, ${
      (this.h * this.scaleY) / 2
    })`;
  }
}
</script>

<style scoped></style>
