<template>
  <div class="flex flex-col px-4 relative h-full overflow-scroll pb-10">
    <slot />

    <div v-for="key in Object.keys(allComponents)" :key="key">
      <!-- <div class="uppercase text-xs">{{ key }}</div> -->
      <div class="flex flex-wrap mb-4 relative">
        <!-- Avoid hover for now because text crowds it: -->

        <div
          v-for="comp in allComponents[key]"
          :key="comp.name + comp.display"
          class="icon-container flex items-center flex-col w-14 text-center text-xs mr-1 mt-1 cursor-pointer rounded"
          :class="{
            'hover:text-app-pink': componentIsEnabled(comp.name),
          }"
          @click="addComponent(comp)"
          :style="{ opacity: componentIsEnabled(comp.name) ? 1 : 0.2 }"
        >
          <div
            class="w-14"
            :class="{
              disabledWidget: !componentIsEnabled(comp.name),
            }"
            @mouseenter="mouseenterIcon($event, key)"
            @mouseleave="clearIconTransform"
            @mousedown="mousedownIcon"
            @mouseup="clearIconTransform"
          >
            <ComponentIcon :name="comp.name" style="transform: scale(1.25)" />
            <div style="line-height: 1.2">{{ comp.display }}</div>
          </div>
        </div>
        <div
          v-if="tooltipVisible && selectedKey === key"
          class="absolute flex w-full top-1/2 items-center justify-center text-xs pointer-events: none"
        >
          <span class="bg-gray-700" v-t="disabledTooltip"></span>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import MultiframeOptions from "@/components/widgets/Multiframe/MultiframeOptions";
import CreateText from "@/components/widgets/Text/TextOptions";
import CreateCalendarEvent from "@/components/widgets/CalendarEvent/CalendarEventOptions";
import CreateCalendarDay from "@/components/widgets/CalendarDay/CalendarDayOptions";
import CreateCalendarWeek from "@/components/widgets/CalendarWeek/CalendarWeekOptions";
import CreateCalendarAgenda from "@/components/widgets/CalendarAgenda/CalendarAgendaOptions";
import CreateCalendarRoomSchedule from "@/components/widgets/CalendarRoomSchedule/CalendarRoomScheduleOptions";
import CreateCountdown from "@/components/widgets/Countdown/CountdownOptions";
import CreateDatetime from "@/components/widgets/Datetime/DatetimeOptions";
import CreateAnalogClock from "@/components/widgets/AnalogClock/AnalogClockOptions";
import CreateProgressBar from "@/components/widgets/ProgressBar/ProgressBarOptions";
import CreateProgressDonut from "@/components/widgets/ProgressDonut/ProgressDonutOptions";
import CreateColumnGraph from "@/components/widgets/ColumnGraph/ColumnGraphOptions";
import CreateBarGraph from "@/components/widgets/BarGraph/BarGraphOptions";
import CreateLineGraph from "@/components/widgets/LineGraph/LineGraphOptions";
import CreatePieGraph from "@/components/widgets/PieGraph/PieGraphOptions";
import CreateStackedGraph from "@/components/widgets/StackedGraph/StackedGraphOptions";
import CreateRepeater, {
  createRepeaterDimensions,
} from "../widgets/Repeater/RepeaterOptions";
import CreateVideo from "@/components/widgets/Video/VideoOptions";

import ComponentIcon from "@/components/icons/componentIcons/ComponentIcon.vue";
import { defaultCountdownOptions } from "@/components/widgets/Countdown/CountdownOptions";
import { defaultTextOptions as defaultDateTimeOptions } from "@/components/widgets/Datetime/DatetimeOptions";
import { Widget } from "../widgets/Widget";
import { measureText } from "@/textfit";
import { RepeaterOptions } from "../widgets/Repeater/RepeaterOptions";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    ComponentIcon,
  },
})
export default class WidgetsMenu extends Vue {
  tooltipVisible = false;
  selectedKey = "";
  timer!: number;

  get appEditor() {
    return useAppEditorStore();
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  destroyed() {
    window.clearTimeout(this.timer);
  }

  showTip() {
    this.tooltipVisible = true;
  }

  hideTip() {
    this.tooltipVisible = false;
  }

  get allComponents(): { [key: string]: { name: string; display: string }[] } {
    return {
      repeater: [{ name: "Repeater", display: "Repeater" }],
      calendars: [
        // name  must match  with icon file name
        { name: "CalendarEvent", display: "Event" },
        { name: "CalendarDay", display: "Day" },
        { name: "CalendarAgenda", display: "Agenda" },
        { name: "CalendarWeek", display: "Week" },
        { name: "CalendarRoomSchedule", display: "Room Schedule" },
      ],
      progress: [
        { name: "ProgressBar", display: "Progress Bar" },
        { name: "ProgressEllipse", display: "Progress Circle" },
      ],
      timer: [
        { name: "Counter", display: "Countdown / Up" },
        { name: "Datetime", display: "Date / Time" },
        { name: "AnalogClock", display: "Analog Clock" },
      ],
      graphs: [
        { name: "ColumnGraph", display: "Column Graph" },
        { name: "BarGraph", display: "Bar Graph" },
        { name: "LineGraph", display: "Line Graph" },
        { name: "PieGraph", display: "Pie Graph" },
        { name: "StackedGraph", display: "Stacked Graph" },
      ],
    };
  }

  mouseenterIcon(ev: MouseEvent, key: string) {
    const { currentTarget } = ev;
    this.selectedKey = key;
    if (
      !(ev.target as Element)?.className.toString().includes("disabledWidget")
    ) {
      (currentTarget as HTMLElement).style.transform = "translate(-1px,-1px)";
    } else {
      this.timer = window.setTimeout(this.showTip, 150);
    }
  }

  mousedownIcon(ev: MouseEvent) {
    const { currentTarget } = ev;
    (currentTarget as HTMLElement).style.transform = "translate(1px,1px)";
  }

  clearIconTransform(ev: MouseEvent) {
    const { currentTarget } = ev;
    (currentTarget as HTMLElement).style.transform = "translate(0,0)";
    this.hideTip();
    window.clearTimeout(this.timer);
  }

  addToCanvas(widget: Widget) {
    this.appEditor.addWidgetAction({ widget });
    this.appEditor.replaceSelections([widget.wid]);
    this.$emit("closeMenu");
  }

  get disabledTooltip() {
    if (!this.isBaseEditingContext) {
      return "repeaterEditor.disabledWidget";
    }
    return "";
  }

  // Disallow addition of dataset-based widgets as children to Repeaters:
  componentIsEnabled(compName: string) {
    if (
      !this.isBaseEditingContext &&
      [
        "Repeater",
        "ColumnGraph",
        "BarGraph",
        "LineGraph",
        "PieGraph",
        "StackedGraph",
        "CalendarAgenda",
        "CalendarDay",
        "CalendarEvent",
        "CalendarRoomSchedule",
        "CalendarWeek",
        "AnalogClock",
      ].includes(compName)
    ) {
      return false;
    }
    return true;
  }

  addComponent(component: any) {
    if (!this.componentIsEnabled(component.name)) {
      return;
    }
    (this as any)[`add${component.name}`]();
  }

  get size(): { w: number; h: number } {
    let size = { w: this.artboard.w, h: this.artboard.h };
    return size;
  }

  get inRepeater() {
    const wg = this.appEditor.widgetById(this.editingContext.parentId);
    if (!wg) {
      return false;
    } else if (wg.type === "Repeater") {
      return true;
    } else {
      return false;
    }
  }

  addCalendarEvent() {
    const w = this.artboard.w * 0.8;
    const h = this.artboard.h * 0.6;
    const x = this.artboard.w * 0.1;
    const y = this.artboard.h / 2 - h / 2;

    const widget = CreateCalendarEvent({
      x,
      y,
      w,
      h,
      canScaleY: false,
    });

    this.addToCanvas(widget);
  }

  addCalendarDay() {
    const w = this.artboard.w * 0.8;
    const h = this.artboard.h * (582 / 1080);
    const x = this.artboard.w * 0.1;
    const y = this.artboard.h / 2 - h / 2;
    const widget = CreateCalendarDay({
      x,
      y,
      w,
      h,
      canScaleY: false,
    });

    this.addToCanvas(widget);
  }

  addCalendarWeek() {
    const w = this.artboard.w * 0.8;
    const h = this.artboard.h * (771 / 1080);
    const x = this.artboard.w * 0.1;
    const y = this.artboard.h / 2 - h / 2;
    const widget = CreateCalendarWeek({
      x,
      y,
      w,
      h,
      canScaleY: false,
    });
    this.addToCanvas(widget);
  }

  addCalendarAgenda() {
    const h = this.artboard.h * 0.5;
    const w = this.artboard.w * 0.5;
    const x = (this.artboard.w - w) / 2;
    const y = this.artboard.h / 2 - h / 2;
    const widget = CreateCalendarAgenda({
      x,
      y,
      w,
      // h
      canScaleY: false,
    });

    this.addToCanvas(widget);
  }

  addCalendarRoomSchedule() {
    const w = this.artboard.w * 0.62;
    const h = this.artboard.h * 0.8;
    const x = this.artboard.w * 0.19;
    const y = this.artboard.h * 0.1;
    const widget = CreateCalendarRoomSchedule({
      x,
      y,
      w,
      h,
      // canScaleY: false
    });

    this.addToCanvas(widget);
  }

  addCounter() {
    const { w, h } = this.size;
    let countdown = defaultCountdownOptions;

    const widget = CreateCountdown({
      x: w * 0.2,
      y: h * 0.35,
      w: w * 0.6,
      h: h * 0.3,
      time_fontSize: this.inRepeater ? 36 : countdown.time_fontSize,
      label_fontSize: this.inRepeater ? 18 : countdown.label_fontSize,
      marginX: this.inRepeater ? 1 : countdown.marginX,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addDatetime() {
    const { w, h } = this.size;
    let datetime = defaultDateTimeOptions;
    const widget = CreateDatetime({
      x: this.inRepeater ? w * 0.25 : w * 0.38,
      y: this.inRepeater ? h * 0.44 : h * 0.46,
      w: this.inRepeater ? w * 0.5 : w * 0.24,
      h: this.inRepeater ? h * 0.12 : h * 0.08,
      fontSize: this.inRepeater ? 60 : datetime.fontSize,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addAnalogClock() {
    const h = this.size.h / 2;
    const w = h;
    const x = this.size.w / 2 - w / 2;
    const y = this.size.h / 4;

    const widget = CreateAnalogClock({
      x: x,
      y: y,
      w: w,
      h: h,
    });

    this.addToCanvas(widget as Widget);
  }

  addProgressBar() {
    const { w, h } = this.size;
    const widget = CreateProgressBar({
      x: this.inRepeater ? w * 0.4 : w * 0.45,
      y: h / 10,
      w: this.inRepeater ? w * 0.2 : w * 0.1,
      h: h * 0.8,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addProgressEllipse() {
    const w = this.size.w * 0.4;
    const h = w;
    const x = this.size.w * 0.3;
    const y = this.size.h / 2 - h / 2;
    const widget = CreateProgressDonut({
      x,
      y,
      w,
      h,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addColumnGraph() {
    const w = this.artboard.w * 0.7;
    const h = this.artboard.h * 0.7;
    const widget = CreateColumnGraph({
      x: this.artboard.w * 0.15,
      y: this.artboard.h * 0.15,
      w,
      h,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addBarGraph() {
    const widget = CreateBarGraph({
      x: this.artboard.w * 0.2,
      y: this.artboard.h / 8,
      w: this.artboard.w * 0.6,
      h: this.artboard.w * 0.4,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addLineGraph() {
    const w = this.artboard.w * 0.7;
    const h = this.artboard.h * 0.7;
    const widget = CreateLineGraph({
      x: this.artboard.w * 0.15,
      y: this.artboard.h * 0.15,
      w,
      h,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addPieGraph() {
    const widget = CreatePieGraph({
      x: this.artboard.w * 0.35,
      y: this.artboard.h / 5,
      w: this.artboard.w * 0.3,
      h: this.artboard.w * 0.3,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addStackedGraph() {
    const widget = CreateStackedGraph({
      x: this.artboard.w * 0.45,
      y: this.artboard.h / 10,
      w: this.artboard.w * 0.1,
      h: this.artboard.h * 0.8,
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addMultiframe() {
    const widget = MultiframeOptions({
      canScaleX: true,
      canScaleY: true,
      x: this.artboard.w / 4,
      y: this.artboard.h / 4,
      w: this.artboard.w / 2,
      h: this.artboard.h / 2,
      backgroundImageUrl2: "",
    });

    this.addToCanvas(widget as unknown as Widget);
  }

  addText() {
    const x = this.isBaseEditingContext ? this.artboard.w / 4 : 0;
    const y = this.isBaseEditingContext ? this.artboard.y / 4 : 0;
    const w = this.isBaseEditingContext
      ? this.artboard.w / 2
      : this.editingContext.width ?? this.artboard.w / 2;
    const widget = CreateText({
      canScaleY: false,
      x,
      y,
      w,
    });

    // Will want to do  this whenever scalex is changed too
    const size = measureText(widget);
    widget.h = size.h;

    this.addToCanvas(widget);
  }

  addRepeater() {
    const transformOptions: Partial<RepeaterOptions> = createRepeaterDimensions(
      this.artboard
    );

    const widget = CreateRepeater(transformOptions);

    this.addToCanvas(widget as unknown as Widget);
  }

  addVideo() {
    this.addToCanvas(CreateVideo({}) as unknown as Widget);
  }
}
</script>

<style scoped>
.icon-container {
  transition: transform 0.1s;
}
</style>
