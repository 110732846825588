import { RepeaterOptions } from "@/components/widgets/Repeater/RepeaterOptions";
import { Widget } from "@/components/widgets/Widget";
import {
  ImageAssetInfo,
  FontAssetInfo,
  VideoAssetInfo,
} from "@/types/bundleTypes";
import { AppState } from "@/stores/AppState";

import isEqual from "lodash.isequal";
import uniqWith from "lodash.uniqwith";
import pick from "lodash.pick";
import { GroupOptions } from "@/components/widgets/Group/GroupOptions";
import { NodeData } from "@/types/data";
import { getActiveWidget } from "@/util/conditionsUtils";
import { ConditionsData } from "@/types/rendererData";

export const gatherAssets = (
  state: AppState,
  boundWidgets: Widget[],
  widgetData: Record<string, any[]>,
  conditions: ConditionsData | Record<string, string> | undefined
) => {
  type WidgetWithRenderInfo = Widget & { shouldPreload: boolean };

  const fonts: FontAssetInfo[] = [];
  const images: ImageAssetInfo[] = [];
  const videos: VideoAssetInfo[] = [];

  // Gather Font Properties
  const tryAddFontValue = (
    fonts: Map<string, FontAssetInfo>,
    key: string,
    value: string
  ) => {
    let prop = key;
    let group = "default";
    const parts = key.split("_");
    if (parts.length === 2) {
      // This is a prop like calendarTitle_fontWeight
      group = parts[0];
      prop = parts[1];
    }
    if (["fontFamily", "fontWeight", "fontStyle"].includes(prop)) {
      const info = (fonts.get(group) || {
        source: "Google",
      }) as FontAssetInfo;

      // Convert from `fontFamily` to `family`
      const newPropName = prop.toLowerCase().substring(4);
      (info as any)[newPropName] = value;
      fonts.set(group, info);
    }
  };

  const getAssetsForWidget = (wg: Widget, shouldPreload = true) => {
    const fontGroups: Map<string, FontAssetInfo> = new Map();
    for (const prop in wg) {
      const value = (wg as any)[prop];
      if (typeof value !== "undefined") {
        // Add font properties
        tryAddFontValue(fontGroups, prop, value);

        // Gather Image Properties
        if (
          (wg.type === "Image" && prop === "url") ||
          (prop === "backgroundImageUrl" && value !== null)
        ) {
          images.push({
            url: value,
            height: Math.ceil(wg.h * wg.scaleY),
            width: Math.ceil(wg.w * wg.scaleX),
            mode: (wg as any).backgroundSize === "contain" ? "max" : "crop",
            shouldPreload: shouldPreload,
          });
        }

        // Gather Video Properties
        if (wg.type === "Video" && prop === "url") {
          videos.push({
            url: value,
            height: Math.ceil(wg.h * wg.scaleY),
            width: Math.ceil(wg.w * wg.scaleX),
            shouldPreload: shouldPreload,
          });
        }
      }
    }

    Array.from(fontGroups.values()).forEach((info) => {
      fonts.push(info);
    });
  };

  const getDataBoundChildren = (
    repeater: RepeaterOptions
  ): WidgetWithRenderInfo[] => {
    const pageSize = repeater.rows * repeater.columns;

    const widgetIds: string[] = [];

    // Loop through all direct children of repeater and add id
    (state.parents[repeater.wid] || []).forEach((repeaterChildWidgetId) => {
      widgetIds.push(repeaterChildWidgetId);

      // If child is a "Group", loop through all group children and add id
      if (state.widgets[repeaterChildWidgetId]?.type === "Group") {
        (state.parents[repeaterChildWidgetId] || []).forEach(
          (groupChildWidgetId) => {
            widgetIds.push(groupChildWidgetId);
          }
        );
      }
    });

    return widgetIds.flatMap((wid) => {
      /**
       * At this point in the code, `widgetData[wid]` may contain
       * and array of dynamic props for each widget.
       *
       * Conditions is already applied to this array so it will only
       * have the props for the active condition.
       *
       * The size of the array will be equal to
       *  - The number of cells of the repeater (if not bound to data)
       *  - The number of rows in the data set (if bound to data)
       */
      const dynamicProps = widgetData[wid];
      if (dynamicProps.length === 0) {
        dynamicProps.push({});
      }

      return dynamicProps.map((props: any, index: number) => {
        /**
         * Here is where we merge the dynamic props with the props the user
         * defined manually in app builder.
         *
         * Because we are grabbing them manually here, we'll need to apply
         * conditions.
         *
         * But when we apply conditions to a repeater child, we'll also need
         * to pass along the `groupUuid` associated with the current row of
         * the data set because it may be relevent for getting the correct
         * condition see `recordCondRefToRecordCondition` for more info.
         */
        const repeaterData = (repeater as any).data as NodeData[][];

        /**
         * This `groupUuid` is basically the unique row id for the current row
         * of the repeaters data set (if it has one).
         *
         * We are using the `index` of the dynamic props array
         * because it corresponds to the data row index.
         */
        const groupUuid: string | undefined =
          repeaterData?.[index]?.[0]?.groupUuid;

        const nonDynamicProps = getActiveWidget(
          state.widgets[wid],
          conditions,
          groupUuid
        );

        return Object.assign({}, nonDynamicProps, props, {
          shouldPreload: index < pageSize,
        });
      });
    });
  };

  /**
   * Gather grouped widgets that are not inside a repeater
   */
  const getGroupChildren = (group: GroupOptions): WidgetWithRenderInfo[] => {
    return (state.parents[group.wid] || []).flatMap((wid) => {
      const dynamicProps = widgetData[wid];
      if (dynamicProps.length === 0) {
        dynamicProps.push({});
      }

      return dynamicProps.map((props: any) => {
        return Object.assign({}, state.widgets[wid], props, {
          shouldPreload: true,
        });
      });
    });
  };

  boundWidgets.forEach((wg: Widget) => {
    if (wg.type === "Repeater") {
      getDataBoundChildren(wg as any as RepeaterOptions).forEach((child) =>
        getAssetsForWidget(child, child.shouldPreload)
      );
    } else if (wg.type === "Group") {
      getGroupChildren(wg as GroupOptions).forEach((child) =>
        getAssetsForWidget(child, true)
      );
    } else {
      getAssetsForWidget(wg);
    }
  });

  // Add intro and fallback images to assets.images so that backend can add them to bundle:
  const assets = state.assets || [];
  const introImage = assets.find((a) => a.functionType === "IntroImage");
  const fallbackImage = assets.find((a) => a.functionType === "FallbackImage");
  if (introImage) {
    images.push({
      ...introImage,
      shouldPreload: true,
      url: introImage?.url || "",
    });
  }
  if (fallbackImage) {
    images.push({
      ...fallbackImage,
      shouldPreload: true,
      url: fallbackImage?.url || "",
    });
  }

  return {
    fonts: uniqWith(fonts, isEqual),
    images: uniqWith(images, (a, b) => {
      return isEqual(
        pick(a, ["url", "height", "width", "mode"]),
        pick(b, ["url", "height", "width", "mode"])
      );
    }),
    videos: uniqWith(videos, (a, b) => {
      return isEqual(
        pick(a, ["url", "height", "width"]),
        pick(b, ["url", "height", "width"])
      );
    }),
  };
};
