import { useDragDropStore } from "./dragDrop";
import { DataBinding, NodeData, NodeSetData } from "@/types/data";
import { useConnectionEditorStore } from "./connectionEditor";
import { useConnectionDataStore } from "./connectionData";
import { WidgetDataCache } from "./appData";
import { ResolvedWidgetData } from "@/types";
import { WidgetWithConditions } from "@/components/widgets/Widget";
import { getActiveWidget } from "@/util/conditionsUtils";
import { useConditionGroupsStore } from "./conditionGroups";
import { RepeaterOptions } from "@/components/widgets/Repeater/RepeaterOptions";

export const applyDynamicImages = (
  widgets: Record<string, WidgetWithConditions>,
  dataBindings: DataBinding[],
  data: WidgetDataCache,
  result: ResolvedWidgetData
): ResolvedWidgetData => {
  const dragDropStore = useDragDropStore();
  const connectionDataStore = useConnectionDataStore();
  const connectionEditorStore = useConnectionEditorStore();
  const conditionGroupsStore = useConditionGroupsStore();
  const conditions = conditionGroupsStore.activeWidgetConditionsMap;

  const tempBackgroundInfo = dragDropStore.tempBackgroundInfo;
  const draggedPhotoNodeUuid = dragDropStore.draggedPhotoNodeUuid;

  for (const widgetId in result) {
    const widget = widgets[widgetId];

    // If tempBackgroundInfo matches this widgetId, override backgroundImageUrl property
    if (tempBackgroundInfo?.wid === widgetId && tempBackgroundInfo?.url) {
      result[widgetId] = (result[widgetId] ?? []).filter(
        (x) => !("backgroundImageUrl" in x) && !("url" in x)
      );

      // Pipe in the dynamic images as temporary previews, before photo is dropped
      // We refer to the bound dataset, when hovering over a photo or shape inside of a Repeater.
      // But for dynamic NON-repeating images, we can treat them as if "isDynamic" is false.
      // Because we can just pipe in tempBackgroundInfo.url
      // console.log("tempbg..", tempBackgroundInfo, widgetId, widget?.type);
      if (tempBackgroundInfo?.isDynamic) {
        const db = dataBindings.find(
          (db) =>
            db.widgetId === widget.parentId && db.bindingType === "DataSet"
        );

        const isDraggingForeignNode =
          dragDropStore.isDraggingImage &&
          dragDropStore.isDraggingNode &&
          connectionEditorStore.connection?.uuid !== db?.dataConnectionUuid;

        const prop = db?.property || "";

        let rows: NodeData[][] = [];

        if (
          db &&
          data &&
          data[db.widgetId] &&
          data[db.widgetId][prop] &&
          !isDraggingForeignNode
        ) {
          rows = ((data?.[db.widgetId]?.[prop] as NodeSetData).children ??
            []) as NodeData[][];
        } else {
          // If dataBinding doesn't exist yet, pull data off the dataset from selectedConnection
          const d = connectionDataStore.connectionData as NodeSetData;
          rows = (d?.children || []) as NodeData[][];
        }

        // Find the proper "column" (that contains the nodeUuid that initiated the tempBackgroundInfo)
        let dataSourceIndex = -1;
        rows.forEach((row) => {
          row.forEach((cell, i) => {
            if (cell.uuid === draggedPhotoNodeUuid) dataSourceIndex = i;
          });
        });

        // console.log("Yeup, it's dynamic", rows, dataSourceIndex);
        if (dataSourceIndex > -1) {
          for (let i = 0; i < rows.length; i++) {
            const url = rows[i][dataSourceIndex]?.value;
            // console.log("Url...", url);
            result[widgetId].push({ backgroundImageUrl: url, url });
          }
        }
      } else {
        // Just replace background image(s) with static url of dragged asset:
        // We need to add a copy of the url for each child of a repeater
        let gridSize = 1;
        if (widgets[widget.parentId]) {
          const { rows, columns, type } = getActiveWidget(
            widgets[widget.parentId],
            conditions
          ) as unknown as RepeaterOptions;
          if (type === "Repeater") {
            gridSize = rows * columns;
          }
        }

        for (let i = 0; i < gridSize; i++) {
          result[widgetId].push({
            backgroundImageUrl: tempBackgroundInfo?.url,
            url: tempBackgroundInfo?.url,
          });
        }
      }
    }
  }

  return result;
};
