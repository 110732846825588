<template>
  <div class="text-center">
    <div class="text-2xl" v-t="'SetupComplete.confirmCreated'"></div>

    <div v-if="isEditable">
      <router-link class="link" :to="editLink">Edit your data</router-link>
    </div>

    <div class="flex flex-col" v-if="isModeratable">
      <div>Need to moderate your data?</div>
      <router-link class="link" :to="moderationLink"
        >Set up moderation</router-link
      >

      <a
        href="https://screenfeed.com/support-articles/managing-your-data-connections"
        target="_blank"
        class="link"
        >Learn about moderation</a
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  APP_EDITOR_ROUTE_PATH,
} from "@/constants";
import { DataConnection } from "@/types/data";

const props = defineProps<{
  connection: DataConnection;
}>();

const route = useRoute();
const router = useRouter();

onMounted(() => {
  if (props.connection?.schemaType === "Tree") {
    /**
     * NOTE: For App editor.. it may be better to redirect back to app, but this is fine for now.
     */
    router.push(editLink.value);
  }
});

const isEditable = computed(() => {
  return props.connection.canEditData;
});

const isModeratable = computed(() => {
  return props.connection.canModerate;
});

const basePath = computed(() => {
  return route.path.includes(APP_EDITOR_ROUTE_PATH)
    ? `${APP_EDITOR_ROUTE_PATH}/${route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`
    : `${APP_EDITOR_CONNECTION_ROUTE_PATH}`;
});

const editLink = computed(() => {
  return {
    path: `/${basePath.value}/${props.connection.uuid}/data`,
  };
});

const moderationLink = computed(() => {
  return `/${basePath.value}/${props.connection.uuid}/settings/moderation`;
});
</script>

<style scoped lang="postcss">
.link {
  @apply text-app-teal;
  text-decoration: underline;
}
</style>
