<template>
  <Modal
    size="1/2"
    :title="$t('connectionList.appConnections')"
    :showCloseBtn="true"
    @close="$emit('close')"
  >
    <div class="flex flex-col">
      <div v-for="appConn in appsInConnectionRender" :key="appConn.appUuid">
        <div class="bg-gray-500 w-full h-[1px]"></div>
        <ListItem
          :uuid="appConn.appUuid"
          :name="appConn.appName"
          :image="appConn.previewUrl"
          :isShort="true"
        />
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import ListItem from "@/views/Dashboard/AppListViews/ListItem.vue";
import Modal from "@/components/Modal.vue";
import { AppConnection } from "@/types/data";
import { useAppsStore } from "@/stores/apps";

@Component({
  components: {
    ListItem,
    Modal,
  },
})
export default class AppsInConnection extends Vue {
  @Prop() appsInConnection: AppConnection[];

  get store() {
    return useAppsStore();
  }

  get appsInConnectionRender() {
    return this.appsInConnection.map((app) => {
      return {
        ...app,
        previewUrl: this.store.apps.find((a) => a.uuid === app.appUuid)
          ?.previewUrl,
      };
    });
  }
}
</script>
