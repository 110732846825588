<template>
  <div
    class="relative flex flex-col items-start rounded border border-transparent"
    :style="styles"
    :data-depth="depth"
    :class="{
      'caret-expanded': expanded,
      'border-app-teal bg-app-teal bg-opacity-10': isSelected && isCollection,
      'hover:border-gray-500':
        !isSelected && isCollection && selectionType === 'Collection',
    }"
  >
    <div class="flex items-center px-3 px-1 space-x-3 rounded" @click="select">
      <div
        v-if="showCaret"
        @click.stop="expanded = !expanded"
        class="cursor-pointer caret text-app-teal"
        :class="{
          'caret-expanded': expanded,
        }"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
          />
        </svg>
      </div>
      <span
        class="rounded border px-2 -mx-2"
        :class="{
          'border-app-teal bg-app-teal bg-opacity-10': isSelected,
          'border-transparent': !isSelected,
          'font-bold': isCollection && canSelect,
          'opacity-50': !canSelect,
          'cursor-pointer': canSelect && !isDraggable,
          'hover:bg-gray-600 ': canSelect && !isSelected,
        }"
      >
        <span
          v-if="node.query !== '$'"
          v-text="isPrimativeArrayObject ? index + 1 : name"
        ></span>

        <span v-if="displayValue || isPrimativeArrayObject" class="inline-flex"
          ><span class="mr-2">:</span>

          <DraggableNode
            :canDrag="isDraggable"
            :model="flattenNode(node)"
            class="node-hover"
          >
            <DataNodeDisplay
              :dataType="
                (isPrimativeArrayObject ? node.children[0] : node).dataType
              "
              :uuid="(isPrimativeArrayObject ? node.children[0] : node).uuid"
              :value="(isPrimativeArrayObject ? node.children[0] : node).value"
            />
          </DraggableNode>
        </span>
      </span>
      <OutlineButton
        size="xs"
        v-if="isCollection && selectionType === 'Collection'"
        >Collection ({{ els.length }} items)</OutlineButton
      >
    </div>

    <div
      v-if="expanded && Array.isArray(els) && !isPrimativeArrayObject"
      :class="nodeClasses"
    >
      <TreeNode
        v-for="(child, idx) in els"
        :key="idx"
        :node="child"
        :readonly="readonly"
        :showValue="showValue"
        :depth="depth + 1"
        :selectionType="selectionType"
        :selectedQuery="selectedQuery"
        :shouldExpand="shouldExpand"
        @select="onChildSelect"
        :parentType="node.dataType"
        :isDraggable="isDraggable"
        :index="idx"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { TreeSchemaNode } from "@/types/data";
import OutlineButton from "@/components/OutlineButton.vue";
import DataNodeDisplay from "@/components/data/DataNodeDisplay.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import DraggableNode from "@/components/data/DraggableNode.vue";

@Component({
  name: "TreeNode",
  components: {
    OutlineButton,
    DataNodeDisplay,
    DraggableNode,
  },
})
export default class TreeNode extends Vue {
  @Prop() node: TreeSchemaNode;
  @Prop(Boolean) readonly: boolean;
  @Prop(Number) depth: number;
  @Prop(String) selectionType: string;
  @Prop(String) selectedQuery: string;
  @Prop(Boolean) shouldExpand: boolean;
  @Prop(Boolean) showValue: boolean;
  @Prop(String) parentType: string;
  @Prop(Number) index: number;
  @Prop({ default: false }) isDraggable: boolean;

  expanded = false;

  // TODO: Test on empty nodes -- should not be allowed to drag them.
  // [x] TODO: continue testing on primative arrays -- ahh, dataType is Object, that would explain why it breaks...
  // TODO: Huh, not sure why hover: filter dropshadow not working here... should look same as Record view

  created() {
    this.expanded = this.shouldExpand;
    if (this.node.name === "root") {
      console.log("load tree", this.node);
    }
  }

  get displayValue() {
    return this.showValue && typeof this.node.value !== "undefined";
  }

  get showCaret() {
    if (this.node.query === "$" && this.node.name === "root") return false;
    return this.hasChildren && !this.isPrimativeArrayObject;
  }

  flattenNode(node: TreeSchemaNode) {
    // It's very odd to me that the second thing is needed for children of primative arrays..
    // Must have to do with representing them "transparently" as mentioned below
    return {
      ...node,
      value: node.value?.value ?? node.children?.[0]?.value.value,
      formattedValue:
        node.value?.formattedValue ?? node.children?.[0]?.value.formattedValue,
    };
  }

  /**
   * The idea behind this is that primitive arrays, like [1,2,3,4..],
   * have their bare values wrapped in objects, to give them the same structure as arrays of objects.
   * But, when the user views these primitive arrays in the schema chooser,
   * we want to represent them differently -- as "transparent", so their contents show up directly,
   * rather than forcing the user to open/expand an object with a single property.
   */
  get isPrimativeArrayObject() {
    return (
      this.node.dataType === "Object" && this.parentType === "PrimativeArray"
    );
  }

  get name() {
    if (["Object", "PrimativeArray"].includes(this.node.dataType)) {
      const parts = this.node.query.split(".");
      // console.log("node name...", this.node, this.parentType);

      return parts[parts.length - 1];
    }
    // if (this.node.dataType === "PrimativeArray") {
    //   return this.node.query;
    // }
    // TODO: Probably need all primative values here -- this is for children of PrimativeArray
    // But should ONLY do this when that is what parent is...
    // if (this.node.dataType === 'Number') {
    //   return this.node.value.formattedValue;
    // }

    return this.node.name ?? this.$t("TreeNode.documentRoot").toString();
  }

  get els() {
    const els = this.node.children || [];
    const count = Array.isArray(els) ? els.length : 0;
    // console.log('els...', els, count);
    return els.map((el, index) =>
      Object.assign({}, el, {
        id: index,
        siblingCount: count,
      })
    );
  }

  get styles() {
    const d = this.depth === 0 || this.depth === 1 ? 0 : 26;
    // console.log("depth...", this.depth);

    const styles = {
      "margin-left": `${d}px`,
    };
    return styles;
  }

  get nodeClasses() {
    return {};
  }

  get canSelect() {
    if (this.readonly) {
      return false;
    }
    let dataType: string = this.node.dataType;
    if (this.isPrimativeArrayObject) {
      dataType = this.node.children[0]?.dataType;
    }
    switch (this.selectionType) {
      case "Collection":
        return this.isCollection;
      case "Number":
        return dataType === "Number";
      case "Color":
        return dataType === "Color";
      case "DateTime":
        return dataType?.match(/Date|Time/); // Should match "Date", "Time" or "DateTime"
      default:
        return this.isScalar;
    }
  }

  get isScalar() {
    switch (this.node.dataType) {
      case "PrimativeArray":
      case "ObjectArray":
      case "Object":
      case "NestedArray":
        return false;
    }
    return true;
  }

  get isCollection() {
    return (
      this.node.dataType === "ObjectArray" ||
      this.node.dataType === "PrimativeArray"
    );
    // const els = this.node.children;
    // Has at least two children with the same name
    // return els && els.length > 1 && els[0].name === els[1].name;
  }

  get hasChildren() {
    return this.node.children && this.node.children.length > 0;
  }

  get isSelected() {
    return this.readonly === false && this.selectedQuery === this.node.query;
  }

  select() {
    this.expanded = true;
    if (this.readonly === false && this.canSelect) {
      this.$emit("select", this.node);
    }
  }

  onChildSelect(node: TreeSchemaNode) {
    if (this.readonly) {
      return;
    }
    this.$emit("select", node);
  }
}
</script>

<style lang="postcss" scoped>
.caret {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
  transform-origin: center;
}
.caret-expanded {
  transform: rotate(0deg);
}

.node-hover:hover {
  filter: drop-shadow(1px, 1px, 1px, black);
}
</style>
