<template>
  <div
    class="px-4 pr-2 overflow-y-scroll flex flex-col space-y-4 items-start w-full"
    @scroll="scrollHandler"
  >
    <div
      v-if="store.appConnections.length > 0"
      class="flex flex-col space-y-2 w-full"
    >
      <div
        class="text-gray-100 font-bold text-sm"
        v-t="'dataPanel.usedInApp'"
      ></div>
      <ConnectionItem
        :key="c.uuid"
        v-for="c in store.appConnections"
        :model="c"
      />
    </div>

    <div class="flex flex-col space-y-2 w-full">
      <div
        v-if="store.appConnections.length > 0"
        class="text-gray-100 font-bold text-sm"
        v-t="'dataPanel.nonAppConnections'"
      ></div>
      <ConnectionItem
        :key="c.uuid"
        v-for="c in store.nonAppConnections"
        :model="c"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ConnectionItem from "@/components/menus/data/ConnectionItem.vue";
import { useConnectionsStore } from "@/stores/connections";
/**
 * Stretch: Some kind of loader (shimmer placeholder?) when user scrolls to bottom?
 */

@Component({
  components: {
    ConnectionItem,
  },
})
export default class ConnectionsList extends Vue {
  get store() {
    return useConnectionsStore();
  }

  scrollHandler() {
    const el = this.$el as HTMLElement;
    const MAX_SCROLL = el.scrollHeight - el.offsetHeight;
    if (el.scrollTop >= 0.85 * MAX_SCROLL) {
      this.store.fetchPagedConnections();
    }
  }
}
</script>
