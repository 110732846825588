import { api } from "@/api/backend";
import { UserInfo } from "@/types";
import { defineStore } from "pinia";
import { auth } from "@/api/authService";

export type UserState = UserInfo & {
  // Add other state properties here...
};

export const useUserStore = defineStore("user", {
  state: (): UserState => {
    return {
      accountId: 0,
      accountName: "",
      featureAccessFlags: [],
      username: "",
    };
  },

  actions: {
    async updateUserInfo() {
      const accountId = await auth.getAccountId();
      const authUser = await auth.getUser();

      if (
        this.accountId === accountId &&
        authUser?.profile.unique_name === this.username
      ) {
        return;
      }

      const userInfo = await api.get<UserInfo>("/users/me");
      this.$patch(userInfo);
    },
  },
});
