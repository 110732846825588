import { DataType } from "@/types/data";
import { LogicOperandType, LogicOperation } from "@/types/logic";

type OperatorOption = { key: string; operation: LogicOperation };
const options: Record<string, LogicOperation[]> = {
  Null: ["Null", "NotNull"],
  Bool: ["BoolIsTrue", "BoolIsFalse"],
  Date: ["DtEquals", "DtNotEquals", "DtBefore", "DtAfter"],
  Number: ["NumEquals", "NumNotEquals", "NumGreaterThan", "NumLessThan"],
  String: [
    "StrEquals",
    "StrNotEquals",
    "StrContains",
    "StrNotContains",
    "StrStartsWith",
    "StrNotStartsWith",
    "StrEndsWith",
    "StrNotEndsWith",
  ],
  Time: ["TimeBefore", "TimeAfter"],
};

const getOptions = (
  operations: LogicOperation[],
  includeNullOptions = true
): OperatorOption[] => {
  return operations.concat(includeNullOptions ? options.Null : []).map((op) => {
    return {
      key: `ConditionOperatorLabel.${op}`,
      operation: op,
    };
  });
};

export const getOperatorOptions = (
  operandType: LogicOperandType | undefined,
  dataType: DataType | undefined,
  includeNullOptions = false
): OperatorOption[] => {
  switch (operandType) {
    case "CurrentDayOfWeek":
    case "CurrentMonth":
      return getOptions(["DtEquals", "DtNotEquals"], false);
    case "CurrentYear":
    case "CurrentDayOfYear":
    case "CurrentDayOfMonth":
      return getOptions(options.Date, false);
    case "CurrentTime":
      return getOptions(["TimeBefore", "TimeAfter"], false);
    case "CurrentDateTime":
      return getOptions(["DtBefore", "DtAfter"], false);
    case "CurrentDate":
      return getOptions(options.Date, false);
  }

  switch (dataType) {
    case "Bool":
      return getOptions(options.Bool, includeNullOptions);
    case "Date":
    case "DateTime":
      return getOptions(options.Date, includeNullOptions);
    case "Number":
      return getOptions(options.Number, includeNullOptions);
    case "Time":
      return getOptions(options.Time, includeNullOptions);
    case "ImageUrl":
    case "Url":
    case "String":
    case "Color":
      return getOptions(options.String, includeNullOptions);
    default:
      return getOptions([]);
  }
};
