<template>
  <div
    id="rulers"
    class="h-0 w-0 select-none text-gray-600 absolute top-0 left-0"
  >
    <div
      class="bg-white left-0 top-0 absolute"
      :style="cornerBoxStyle"
      @click="resetArtboard"
    ></div>
    <div class="ruler border-b border-l" :style="horRulerStyle">
      <div class="absolute" :style="horRulerLinesStyle">
        <div
          class="ruler-unit border-l"
          v-for="(line, i) in horLines"
          :key="i"
          :style="line.style"
        >
          <span class="ruler-number">{{ line.number }}</span>
        </div>
      </div>
      <div
        class="absolute bg-gray-300 bg-opacity-40"
        :style="horRulerMarkerStyle"
      ></div>
    </div>
    <div class="ruler border-r border-t" :style="vertRulerStyle">
      <div class="absolute" :style="vertRulerLinesStyle">
        <div
          class="ruler-unit border-t"
          v-for="(line, i) in vertLines"
          :key="i"
          :style="line.style"
        >
          <span class="ruler-number ruler-number-vert">{{ line.number }}</span>
        </div>
      </div>
      <div
        class="absolute bg-gray-300 bg-opacity-40"
        :style="vertRulerMarkerStyle"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { scaledCoords, getBoundingBox } from "../utils";
import { Widget } from "@/components/widgets/Widget";
import { useAppEditorStore } from "@/stores/appEditor";

@Component
export default class CanvasRulers extends Vue {
  smallestRuler = 50;

  get appEditor() {
    return useAppEditorStore();
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get scale() {
    return this.appEditor.scale;
  }

  get canvasBox() {
    return this.appEditor.canvasBox;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get rulerSize() {
    return this.appEditor.rulerSize;
  }

  get origin() {
    return this.appEditor.origin;
  }

  get realCurrentSelection() {
    if (this.selections.some((s) => typeof s === "undefined")) {
      return { x: 0, y: 0, w: 0, h: 0 };
    }

    const widgets = this.selections.map(this.appEditor.widgetById) as Widget[];

    // Add group dimensions offset if selection is child of group
    const parentWid = widgets[0]?.parentId;
    const parent = this.appEditor.widgetById(parentWid);
    const offset = { x: 0, y: 0 };
    if (parent?.type === "Group") {
      offset.x = parent.x;
      offset.y = parent.y;
    }

    return getBoundingBox(
      widgets.map((wg) => ({ ...wg, x: wg.x + offset.x, y: wg.y + offset.y }))
    );
  }

  get scaledOrigin() {
    return scaledCoords(this.origin, this.canvasBox, this.scale);
  }

  get cornerBoxStyle() {
    return {
      height: `${this.rulerSize}px`,
      width: `${this.rulerSize}px`,
    };
  }

  get horRulerMarkerStyle() {
    if (this.selections.length === 0) return {};

    const left =
      this.scaledOrigin.x -
      this.rulerSize +
      this.realCurrentSelection.x * this.scale;

    const width = this.realCurrentSelection.w * this.scale;

    return {
      top: 0,
      bottom: 0,
      left: `${left}px`,
      width: `${width}px`,
    };
  }

  get vertRulerMarkerStyle() {
    if (this.selections.length === 0) return {};
    const top =
      this.realCurrentSelection.y * this.scale +
      this.scaledOrigin.y -
      this.rulerSize;
    const height = this.realCurrentSelection.h * this.scale;

    return {
      left: 0,
      right: 0,
      top: `${top}px`,
      height: `${height}px`,
    };
  }

  get horRulerStyle() {
    return {
      height: `${this.rulerSize}px`,
      "margin-left": `${this.rulerSize - 1}px`,
      width: `${this.canvasBox.w - this.rulerSize + 1}px`,
    };
  }

  get vertRulerStyle() {
    return {
      width: `${this.rulerSize}px`,
      "margin-top": `${this.rulerSize - 1}px`,
      height: `${this.canvasBox.h - this.rulerSize}px`,
    };
  }

  get horRulerLinesStyle() {
    const artboardX = this.scaledOrigin.x;
    return {
      top: 0,
      left: `${artboardX - this.rulerSize}px`,
    };
  }

  get vertRulerLinesStyle() {
    const artboardY = this.scaledOrigin.y;
    return {
      left: 0,
      top: `${artboardY - this.rulerSize}px`,
    };
  }

  get vertLines() {
    return this.makeLines("vertical");
  }

  get horLines() {
    return this.makeLines("horizontal");
  }

  resetArtboard() {
    this.appEditor.resetArtboard();
  }

  makeLines(direction: string) {
    const res: any[] = [];
    for (let i = -10000; i <= 10000; i = i + this.smallestRuler) {
      res.push({
        number: i,
        style: this.lineStyle(direction, i),
      });
    }
    return res;
  }

  lineStyle(dir: string, val: number) {
    const width =
      dir === "horizontal"
        ? `${this.smallestRuler * this.scale}px`
        : `${this.rulerSize}px`;
    const height =
      dir === "horizontal"
        ? `${this.rulerSize}px`
        : `${this.smallestRuler * this.scale}px`;

    const scaledUnit = val * this.scale;

    const top = dir === "horizontal" ? 0 : `${scaledUnit}px`;
    const left = dir === "horizontal" ? `${scaledUnit}px` : 0;

    let display = "block";

    // Update ruler granularity based on scale:
    if (this.scale < 1.75 && val % 100 !== 0) display = "none";
    if (this.scale < 0.5 && (val / 100) % 2) display = "none";
    if (this.scale < 0.25 && (val / 100) % 4) display = "none";

    return {
      height,
      width,
      top,
      left,
      display,
    };
  }
}
</script>

<style lang="postcss">
.ruler {
  @apply bg-white left-0 top-0 absolute overflow-hidden;
}
.ruler-unit {
  @apply absolute left-0 top-0;
}
.ruler-number {
  @apply absolute pl-1;
  font-size: 0.65rem;
}
.ruler-number-vert {
  @apply transform -rotate-90;
  transform-origin: 0 0;
}
</style>
