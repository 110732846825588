import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface GroupOptions
  extends ComponentOptions,
    TransformOptions,
    AnimationOptions {
  verticalDynamism: boolean;
  verticalMargin: number;
  orderedChildIds: string[];
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...getDefaultAnimationOptions(),
  verticalDynamism: false,
  verticalMargin: 20,
  orderedChildIds: [],
};

export const CreateGroup = (options: Partial<GroupOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "Group",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.Group,
  });
};
