export interface BorderOptions {
  borderWidth: number;
  borderColor: string;
  borderDashSize: number;
  borderGapSize: number;
}

export const DefaultBorderOptions = {
  borderWidth: 0,
  borderColor: "rgba(80, 80, 80, 1)",
  borderIsDashed: false,
  borderDashSize: 0,
  borderGapSize: 15,
};
