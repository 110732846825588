import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import Uppy, { Restrictions, UploadResult, UppyOptions } from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Webcam from "@uppy/webcam";
import CustomXHRUpload from "@/lib/uppy-custom/xhr-upload-custom";
import CustomUrl from "@/lib/uppy-custom/url-custom";
import { AssetFunctionType, SavedAsset, Size } from "@/types";
import { FileData } from "@/types/photos";
import { appSettings } from "@/appSettings";
import { auth } from "@/api/authService";

const uploadEndpoint = `${appSettings.backEnd.baseUrlAndPath}/assets`;

type UploadAssetOptionsMeta = {
  assetFunctionType: AssetFunctionType;
  appUuid: string;
  width?: number;
  height?: number;
};

export type UploadAssetOptions = {
  restrictions?: Partial<Restrictions>;
  meta?: UploadAssetOptionsMeta;
  allowedFileTypes?: string[];
};

let uppy: any;

function getUppy(opts?: UploadAssetOptions): Promise<Uppy.Uppy> {
  const options: Partial<UppyOptions> = {
    debug: true,
    allowMultipleUploads: false,
    meta: opts?.meta,
    restrictions: {
      allowedFileTypes: opts?.allowedFileTypes,
      ...opts?.restrictions,
    },
  };

  if (typeof uppy !== "undefined") {
    uppy.reset();
    uppy.setOptions(options);
    return Promise.resolve(uppy);
  }

  return auth.getOrRenewAccessToken().then((token) => {
    const headers = {
      authorization: `Bearer ${token}`,
    };

    uppy = Uppy(options);

    uppy
      .use(Webcam, { modes: ["picture"] })
      .use(Dashboard, {
        plugins: ["Webcam"],
        proudlyDisplayPoweredByUppy: false,
      })
      .use(CustomXHRUpload, {
        id: "CustomXHRUpload",
        endpoint: uploadEndpoint,
        headers: headers,
        limit: 3,
        timeout: 0,
      })
      .use(CustomUrl, {
        id: "CustomUrl",
        target: Dashboard,
        companionHeaders: headers,
        companionUrl: uploadEndpoint,
      });

    return uppy;
  });
}

function getVideoDimensions(file: Uppy.UppyFile): Promise<Size> {
  return new Promise((resolve, reject) => {
    try {
      const url = file.isRemote
        ? ((file.remote?.body as any).url as string)
        : URL.createObjectURL(file.data);

      const video = document.createElement("video");
      video.crossOrigin = "anonymous";
      video.setAttribute("src", url);
      video.load();

      video.onloadedmetadata = function () {
        if (!file.isRemote) {
          // Revoke when you don't need the url any more to release any reference
          URL.revokeObjectURL(video.src);
        }
        console.log({ w: video.videoWidth, h: video.videoHeight });
        resolve({ w: video.videoWidth, h: video.videoHeight });
      };

      video.onerror = function (e) {
        console.error(e);
        reject("Unable to get video dimensions");
      };
    } catch (error) {
      console.error(error);
      reject("Unable to get video dimensions");
    }
  });
}

async function upload(
  options?: UploadAssetOptions
): Promise<SavedAsset[] | undefined> {
  return getUppy(options).then((uppy) => {
    let videoWidth: number | null = null;
    let videoHeight: number | null = null;

    return new Promise((resolve) => {
      uppy.once("dashboard:modal-closed", () => {
        resolve(undefined);
      });

      uppy.once("file-added", async (file: any) => {
        console.log("file-added", file);
        if (file.type.includes("video")) {
          try {
            const dimensions = await getVideoDimensions(file);
            videoWidth = dimensions.w;
            videoHeight = dimensions.h;

            const meta = uppy.getState().meta as UploadAssetOptionsMeta;
            meta.width = videoWidth;
            meta.height = videoHeight;
            uppy.setMeta(meta);
          } catch (error: string | any) {
            uppy.info(error, "error", 500);
          }
        }
      });

      uppy.once("complete", (result: UploadResult) => {
        const { failed } = result;

        const successful = result.successful as unknown as Record<
          string,
          FileData
        >;

        if (Array.isArray(failed) && failed.length > 0) {
          failed.forEach((file) => {
            uppy.info(
              {
                message: `Error uploading ${file.name}`,
                details: file?.error,
              },
              "error",
              500
            );
          });
        } else {
          const assets = Object.values(successful).map((f) => {
            const asset = f.response.body.data;

            // If we have video dimensions, set them on the asset
            if (
              asset.type &&
              asset.type.includes("video") &&
              videoWidth &&
              videoHeight
            ) {
              asset.width = videoWidth;
              asset.height = videoHeight;
            }
            return asset;
          });
          resolve(assets);

          (uppy.getPlugin("Dashboard") as Dashboard).closeModal();
        }
      });

      (uppy.getPlugin("Dashboard") as Dashboard).openModal();
    });
  });
}

// export const uploadImages = (options?: UploadAssetOptions) => {
//   return upload(options);
// };

export const uploadImage = (
  options?: UploadAssetOptions
): Promise<SavedAsset | undefined> => {
  const defaultOptions = {
    allowedFileTypes: [".jpg", ".jpeg", ".png"],
  };
  const uploadOptions = Object.assign(defaultOptions, options ?? {});
  return upload(uploadOptions).then((images) => {
    if (Array.isArray(images)) {
      return images[0];
    }
  });
};

export const uploadVideo = (
  options?: UploadAssetOptions
): Promise<SavedAsset | undefined> => {
  const defaultOptions = {
    allowedFileTypes: [".mp4", ".mov", ".webm"],
  };
  const uploadOptions = Object.assign(defaultOptions, options ?? {});
  return upload(uploadOptions).then((videos) => {
    if (Array.isArray(videos)) {
      return videos[0];
    }
  });
};
