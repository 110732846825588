<template>
  <div>
    <div class="relative" :key="r.uuid" v-for="(r, index) in model.rules">
      <div
        class="py-10 relative flex min-h-[300px] items-center justify-center"
        :class="{
          ' rounded-lg': model.rules.length === 1,
          'border-b-0 rounded-tl-lg rounded-tr-lg':
            model.rules.length > 1 && index === 0,
          'border-t-0 border-b-0':
            model.rules.length > 1 &&
            index !== 0 &&
            index !== model.rules.length - 1,
          'border-t-0 rounded-bl-lg rounded-br-lg':
            model.rules.length > 1 && index === model.rules.length - 1,
        }"
      >
        <LogicRuleEditor ref="ruleEditor" :groupUuid="model.uuid" :model="r" />
        <CloseButton
          v-if="showDelete"
          class="absolute top-8 right-8"
          @click="deleteRule(r.uuid)"
        />
      </div>

      <!-- AND link -->
      <div class="absolute top-0 left-0 right-0" v-if="index !== 0">
        <div class="absolute top-0 left-0 right-0 h-px bg-gray-600"></div>
        <div
          class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded bg-app-green px-3 py-px text-lg font-semibold shadow-lg"
          v-t="'Condition.and'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { useFilterEditorStore } from "@/stores/filterEditor";
import { LogicOperandType, LogicRule, LogicRuleGroup } from "@/types/logic";

import LogicRuleEditor from "./LogicRuleEditor.vue";
import CloseButton from "@/components/logic/CloseButton.vue";
import { validateRule } from "@/components/logic/ruleValidator";
import {
  ConditionRuleToken,
  makeConditionRuleTextToken,
} from "@/components/logic/describeRule";

@Component({
  components: {
    LogicRuleEditor,
    CloseButton,
  },
})
export default class RuleGroupEditor extends Vue {
  @Prop(Boolean) showAdd: boolean;
  @Prop(Number) groupIndex: number;
  @Prop(Number) lastGroupIndex: number;
  @Prop(Object) model: LogicRuleGroup;

  get store() {
    return useFilterEditorStore();
  }

  describe() {
    const ruleEditors = (this.$refs.ruleEditor || []) as LogicRuleEditor[];
    let tokens: ConditionRuleToken[] = [];
    ruleEditors.forEach((r, index) => {
      if (index > 0) {
        tokens = tokens.concat(makeConditionRuleTextToken(" AND "));
      }
      tokens = tokens.concat(r.describe());
    });
    return tokens;
  }

  get showDelete() {
    const groups = this.store.groups;
    const rules = groups?.flatMap((g) => g.rules) ?? [];
    return rules?.length > 1;
  }

  addRule() {
    this.store.addRule(this.model.uuid);
  }

  addOrRule() {
    this.store.addRule();
  }

  showAddButtons(rule: LogicRule, index: number) {
    return index === this.model.rules.length - 1 && validateRule(rule);
  }

  deleteRule(ruleId: string | undefined) {
    this.store.deleteRule(ruleId as string);
  }

  getComponentName(type: LogicOperandType | undefined) {
    switch (type) {
      case "ConnectionColumn":
      case "ConnectionScalar":
        return "DataRuleEditor";
      case "CurrentDate":
      case "CurrentTime":
      case "CurrentDateTime":
        return "DateTimeRuleEditor";
    }
  }
}
</script>
