import { Vue } from "vue-property-decorator";
import { defineStore } from "pinia";
import {
  Logic,
  LogicOperand,
  LogicOperation,
  LogicRule,
  LogicRuleGroup,
} from "@/types/logic";

import { api } from "@/api/backend";
import { makeId } from "@/utils";
import { EventBus } from "@/eventbus";
import { SchemaNode } from "@/types/data";
import { validateRule } from "@/components/logic/ruleValidator";
import { useAppEditorStore } from "./appEditor";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { useColumnInfoStore } from "./columnInfo";

const setOperandValues = (
  target: Partial<LogicOperand>,
  source: Partial<LogicOperand>
) => {
  const props: (keyof LogicOperand)[] = [
    "connectionUuid",
    "default",
    "nodeUuid",
    "type",
    "value",
    "valueType",
    "uuid",
  ];
  props.forEach((prop) => {
    Vue.set(target, prop, source[prop]);
  });
};

function removeTempUuid(x: { uuid?: string }) {
  if (x.uuid?.startsWith("new")) {
    delete x.uuid;
  }
}

EventBus.on("APP_EDITOR_INITIALIZED", () => {
  const store = useFilterEditorStore();
  store.$reset();
});

export interface FilterEditorState {
  isInitialized: boolean;
  dataConnectionUuid: string;

  name: string;
  order?: number;
  uuid: string;
  groups: LogicRuleGroup[];

  invalidRules: string[];
  showErrors: boolean;
  hasUnsavedChanges: boolean;
  isCreating: boolean;
  columns: SchemaNode[];
  isLoadingColumns: boolean;

  updateCounter: number;

  showModal: boolean;
}

export const useFilterEditorStore = defineStore("filterEditor", {
  state: (): FilterEditorState => {
    return {
      isInitialized: false,
      dataConnectionUuid: "",
      name: "",
      uuid: "",
      groups: [],

      invalidRules: [],
      showErrors: false,
      hasUnsavedChanges: false,
      isCreating: false,

      columns: [],
      isLoadingColumns: false,

      updateCounter: 0,

      showModal: false,
    };
  },

  getters: {
    totalRules(state): number {
      return state.groups.reduce((acc, g) => acc + g.rules.length, 0);
    },
    totalSavedRules(state): number {
      if (state.uuid === "" || state.uuid.startsWith("new-")) {
        return 0;
      }
      return this.totalRules;
    },
  },

  actions: {
    async loadFilter(
      dataConnectionUuid: string,
      filterId?: string
    ): Promise<void> {
      if (
        this.isInitialized &&
        isNonEmptyString(this.dataConnectionUuid) &&
        this.dataConnectionUuid === dataConnectionUuid &&
        isNonEmptyString(this.uuid) &&
        this.uuid === filterId
      ) {
        return Promise.resolve();
      }

      this.$reset();

      if (isNonEmptyString(filterId)) {
        const logic = await api.get<Logic>(
          `dataconnection/${dataConnectionUuid}/logic/${filterId}`
        );
        this.dataConnectionUuid = dataConnectionUuid;
        this.setFilterOptions(logic);
      } else {
        this.isCreating = true;
        this.dataConnectionUuid = dataConnectionUuid;
        this.createInitialGroup();
      }

      this.isInitialized = true;
      return Promise.resolve();
    },

    async openEditor(
      dataConnectionUuid: string,
      filterId?: string
    ): Promise<void> {
      return this.loadFilter(dataConnectionUuid, filterId).then(() => {
        this.showModal = true;
      });
    },

    createRule(): LogicRule {
      return {
        uuid: `new-${makeId()}`,
        operation: null as unknown as LogicOperation,
        operandLeft: {
          // We always set this to the connectionUuid of the filter
          connectionUuid: this.dataConnectionUuid,
          default: "",
          type: "ConnectionColumn",
          valueType: undefined,
          nodeUuid: "",
          value: "",
          uuid: `new-${makeId()}`,
        },
        operandRight: {
          connectionUuid: "",
          default: "",
          nodeUuid: "",
          type: undefined,
          valueType: undefined,
          value: "",
          uuid: `new-${makeId()}`,
        },
      };
    },

    createRuleGroup() {
      return {
        uuid: `new-${makeId()}`,
        rules: [],
      } as LogicRuleGroup;
    },

    getOrCreateRuleGroup(ruleGroupId?: string): LogicRuleGroup {
      let ruleGroup = this.groups.find((rg) => rg.uuid === ruleGroupId);

      if (typeof ruleGroup === "undefined") {
        ruleGroup = this.createRuleGroup();
        this.groups.push(ruleGroup);
        this.hasUnsavedChanges = true;
      }
      return ruleGroup;
    },

    addRule(ruleGroupUuid?: string) {
      const ruleGroup = this.getOrCreateRuleGroup(ruleGroupUuid);
      const rule = this.createRule();
      ruleGroup.rules.push(rule);
      this.hasUnsavedChanges = true;
      this.validateRules();
    },

    createInitialGroup() {
      console.log("createInitialGroup");
      const rule = this.createRule();
      const ruleGroup = this.createRuleGroup();
      ruleGroup.rules.push(rule);

      this.uuid = `new-${makeId()}`;
      this.groups.push(ruleGroup);
      this.hasUnsavedChanges = false;
    },

    updateRule(rule: LogicRule) {
      this.groups.forEach((rg) => {
        rg.rules.forEach((r) => {
          if (r.uuid === rule.uuid) {
            this.hasUnsavedChanges = true;
            r.operation = rule.operation;
            setOperandValues(r.operandLeft, rule.operandLeft);
            setOperandValues(r.operandRight, rule.operandRight);
          }
        });
      });
      this.validateRules();
    },

    deleteRule(ruleId: string) {
      this.groups.forEach((rg, groupIndex) => {
        const index = rg.rules.findIndex((r) => r.uuid === ruleId);
        if (index > -1) {
          rg.rules.splice(index, 1);
          this.hasUnsavedChanges = true;
        }
        if (rg.rules.length === 0) {
          this.groups.splice(groupIndex, 1);
        }
      });
      this.validateRules();
    },

    validateRules() {
      this.updateCounter++;
      this.invalidRules = [];
      this.groups.forEach((g) => {
        g.rules.forEach((r) => {
          const isValidRule = validateRule(r);
          if (isValidRule === false) {
            this.invalidRules.push(r.uuid);
          }
        });
      });
    },

    setFilterOptions(logic: Logic) {
      this.name = logic.name;
      this.order = logic.order;
      this.uuid = logic.uuid;
      this.groups = logic.groups;
      this.validateRules();
      return this.preloadSchema();
    },

    updateFilter() {
      const appEditor = useAppEditorStore();
      const appUuid = appEditor.uuid;

      const copy: Logic = {
        uuid: this.uuid,
        name: this.name,
        order: this.order,
        groups: JSON.parse(JSON.stringify(this.groups)),
      };

      copy.groups.forEach((g) => {
        removeTempUuid(g);
        g.order = undefined;
        g.rules.forEach((r, index) => {
          r.order = index;
          removeTempUuid(r);
          removeTempUuid(r.operandLeft);
          removeTempUuid(r.operandRight);
        });
      });

      return api.post<Logic>(
        `dataconnection/${this.dataConnectionUuid}/filter`,
        {
          appUuid: appUuid,
          logic: copy,
          filterUuid: this.uuid,
        }
      );
    },

    saveNewFilter() {
      const appEditor = useAppEditorStore();
      const appUuid = appEditor.uuid;

      const copy: Omit<Logic, "uuid"> & { appUuid: string } = {
        name: this.name,
        order: this.order,
        groups: JSON.parse(JSON.stringify(this.groups)),
        appUuid,
      };

      // Remove all temporary ids
      copy.groups.forEach((g) => {
        removeTempUuid(g);
        g.order = undefined;
        g.rules.forEach((r, index) => {
          r.order = index;
          removeTempUuid(r);
          removeTempUuid(r.operandLeft);
          removeTempUuid(r.operandRight);
        });
      });

      return api
        .post<Logic>(`dataconnection/${this.dataConnectionUuid}/filter`, {
          appUuid,
          logic: copy,
        })
        .then((logic) => {
          this.setFilterOptions(logic);
          return logic;
        });
    },

    save() {
      this.validateRules();
      if (this.invalidRules.length > 0) {
        this.showErrors = true;
        return Promise.reject("invalid");
      } else {
        this.showErrors = false;
      }

      const promise = this.isCreating
        ? this.saveNewFilter()
        : this.updateFilter();

      return promise.then(() => {
        this.isCreating = false;
        this.hasUnsavedChanges = false;
      });
    },

    deleteFilter() {
      return api
        .delete(`dataconnection/${this.dataConnectionUuid}/filter/${this.uuid}`)
        .then(() => {
          this.$reset();
        });
    },

    preloadSchema() {
      const schemaStore = useColumnInfoStore();
      schemaStore.getSchema(this.dataConnectionUuid);
    },
  },
});
