import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface ColumnGraphOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  h: number;
  w: number;
  minValue: number;
  maxValue: number;
  barPadding: number;
  xTitle: string;
  yTitle: string;

  title_fontFamily: string;
  title_fontSize: number;
  title_fontWeight: number;
  title_textColor: string;
  title_textTransform: string;
  label_fontFamily: string;
  label_fontSize: number;
  label_fontWeight: number;
  label_textColor: string;
  label_textTransform: string;

  lineColor: string;
  lineThickness: number;
  labelTicks: number;
  leftTitlePadding: number;
  bottomTitlePadding: number;
  bgColor: string;
  barColor: string;
  customBarColors: string[];
  useCustomBarColors: boolean;
  xLabelTilt: boolean;
  showBg: boolean;
  showGrid: boolean;
  showValues: boolean;
  initialSet: boolean;
  xAxisColumnId: string;
  yAxisColumnId: string;
  xTitleManual: string | null;
  yTitleManual: string | null;
  minManual: number | null;
  maxManual: number | null;
}

export const defaultColumnGraphOptions = {
  h: 768,
  w: 1355,
  minValue: 0,
  maxValue: 830,
  xTitle: "Name",
  yTitle: "Height (m)",
  barPadding: 0.45,
  title_fontFamily: "Montserrat",
  title_fontSize: 36,
  title_fontWeight: 300,
  title_fontStyle: "normal",
  title_textColor: "#000000",
  title_textTransform: "none",
  label_fontFamily: "Montserrat",
  label_fontSize: 20,
  label_fontWeight: 700,
  label_fontStyle: "normal",
  label_textColor: "#000000",
  label_textTransform: "none",
  lineColor: "#000000",
  lineThickness: 0,
  labelTicks: 8,
  leftTitlePadding: 0,
  bottomTitlePadding: 0,
  bgColor: "#f2f2f2",
  barColor: "#113054",
  customBarColors: ["#FDCDD3", "#FB6F84", "#E21D48", "#A1123A", "#790A25"],
  useCustomBarColors: true,
  xLabelTilt: false,
  showBg: false,
  showGrid: false,
  showValues: false,
  xTitleManual: null,
  yTitleManual: null,
  minManual: null,
  maxManual: null,
  /**
   * Stores the UUID of the data column representing the x axis
   */
  xAxisColumnId: "a",
  /**
   * Stores the UUID of the data column representing the y axis
   */
  yAxisColumnId: "b",
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultColumnGraphOptions,
};

const CreateColumnGraph = (options: Partial<ColumnGraphOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "ColumnGraph",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.ColumnGraph,
  });
};

export default CreateColumnGraph;
