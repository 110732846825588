import { Font, FontVariant } from "@/types";

let storedPromise!: Promise<{ GOOGLE_FONTS: GoogleFontsRecord }>;

type GoogleFontsRecord = Record<string, number[]>;

type GoogleFontInfo = {
  family: string;
  weights: number[];
};

const fonts = new Map<string, GoogleFontInfo>();

/**
 * We want to load the entire library of Google Fonts only when needed.
 */
const loadGoogleFonts = () => {
  if (fonts.size > 0) {
    return Promise.resolve();
  }

  storedPromise = (() => import("./google-fonts"))();
  return storedPromise.then((module) => {
    for (const family in module.GOOGLE_FONTS) {
      const info = {
        family,
        weights: module.GOOGLE_FONTS[family],
      };
      fonts.set(family.toLowerCase(), info);
    }
  });
};

const findFont = (family: string): Promise<GoogleFontInfo> => {
  return loadGoogleFonts().then(() => {
    const font = fonts.get(family.toLowerCase());
    if (typeof font === "undefined") {
      return Promise.reject();
    }
    return font;
  });
};

/**
 * Some Google fonts do not include italic variants.
 * We are adding them anyways.
 * @param weights
 */
const createVariants = (weights: number[]) => {
  return weights.flatMap((weight) => {
    return [
      { style: "normal", weight },
      { style: "italic", weight },
    ];
  }) as FontVariant[];
};

export const getGoogleFont = (family: string): Promise<Font> => {
  return findFont(family).then((font) => {
    return {
      family: font.family,
      variants: createVariants(font.weights),
    };
  });
};
