<template>
  <ShapeBase
    v-bind="$props"
    :gradientParts="gradientParts"
    :borderGradientParts="borderGradientParts"
    dataType="Triangle"
  >
    <polygon
      :points="points"
      :fill="fill"
      :stroke="border"
      :stroke-width="borderWidth"
      :stroke-dasharray="dashArray"
      :stroke-linejoin="linejoin"
    />
    <polygon
      :points="points"
      fill="none"
      :stroke="border"
      :stroke-width="borderWidth"
      :stroke-dasharray="dashArray"
      :stroke-linejoin="linejoin"
    />
  </ShapeBase>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { makeId, parseGradientCss } from "@/utils";
import ShapeBase from "@/components/ShapeBase.vue";
import AnimatableWidget from "../AnimatableWidget.vue";

@Component({
  inheritAttrs: false,
  components: {
    ShapeBase,
  },
})
export default class TriangleComponent extends AnimatableWidget {
  @Prop(String) readonly backgroundColor: string;
  @Prop(String) readonly backgroundImageUrl: string;
  @Prop(String) readonly backgroundSize: string;
  // @Prop(Number) readonly borderRadius: number;
  @Prop(String) readonly strokeLinejoin:
    | "miter"
    | "round"
    | "bevel"
    | "inherit";
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  @Prop(Number) readonly perspectiveAngle: number;

  // TODO: Wrap up in an object? Same for bgImage
  @Prop(Number) readonly shadowX: number;
  @Prop(Number) readonly shadowY: number;
  @Prop(Number) readonly shadowBlur: number;
  @Prop(String) readonly shadowColor: string;
  @Prop(Boolean) readonly shadowDisplay: boolean;

  @Prop(Number) readonly borderWidth: number;
  @Prop(String) readonly borderColor: string;
  @Prop(Number) readonly borderDashSize: number;
  @Prop(Number) readonly borderGapSize: number;

  // @Prop() tempBackgroundInfo: any;
  @Prop({ default: makeId }) componentId: string;

  get borderDashed() {
    return this.borderDashSize > 0;
  }

  get dashArray() {
    if (this.borderDashed) {
      return `${this.borderDashSize} ${this.borderGapSize}`;
    } else {
      return "";
    }
  }

  get linejoin() {
    return this.strokeLinejoin;
  }

  get points() {
    const w = this.w * this.scaleX;
    const h = this.h * this.scaleY;
    const buffer = this.borderWidth / 2;
    const p1 = `${w / 2},${buffer}`;
    const p2 = `${w - buffer},${h - buffer}`;
    const p3 = `${buffer},${h - buffer}`;
    // return `M${p1} L${p2} L${p3} Z`;
    return `${p1} ${p2} ${p3}`;
  }

  get fill() {
    if (this.backgroundImageUrl) {
      return this.$helpers.svgUrl(`#${this.componentId}backgroundImageUrl`);
    }
    if (this.gradientParts.type === "Solid") {
      return this.backgroundColor;
    }
    return this.$helpers.svgUrl(`#${this.gradientParts.type}-grad-${this.wid}`);
  }

  get border() {
    if (this.borderGradientParts.type === "Solid") {
      return this.borderColor;
    }
    return this.$helpers.svgUrl(
      `#${this.borderGradientParts.type}-grad-border-${this.wid}`
    );
  }

  get gradientParts() {
    return parseGradientCss(this.backgroundColor);
  }

  get borderGradientParts() {
    return parseGradientCss(this.borderColor);
  }
}
</script>
