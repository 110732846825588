<template>
  <div
    ref="canvas-edit-layer"
    id="canvas-edit-layer"
    class="absolute top-0 left-0 w-full h-full bg-transgroup"
    @mousedown="elementDown"
    @touchstart="elementTouchDown"
    :style="canvasStyle"
    @dblclick="onDoubleClick()"
  >
    <ToastMessage
      v-if="toastMessage"
      class="absolute left-10 top-8"
      :message="toastMessage"
      :duration="toastDuration"
    />
    <BoundingBox />
    <WidgetDragger
      :key="wg.wid"
      v-for="wg in widgets"
      :id="wg.wid"
      v-bind="wg"
      :spacePressed="spacePressed"
    >
      <div
        data-group-children
        class="w-full h-full"
        v-if="shouldRenderChildren(wg)"
      >
        <div
          v-for="child in getChildrenRender(wg)"
          :key="child.wid"
          class="wg-child"
          :style="getChildWgStyle(child, wg)"
          @mouseenter="enterChild(child)"
          @mouseleave="leaveChild(child)"
          @click="clickChild($event, child)"
          @dblclick="doubleClickChild($event, child)"
        ></div>
      </div>
    </WidgetDragger>
    <WidgetResizer
      v-if="resizerWidget"
      :id="resizerWidget.wid"
      v-bind="resizerWidget"
    />

    <div
      :style="{ transform: `scale(${scale})` }"
      class="absolute pointer-events-none top-0 left-0 w-full h-full"
    >
      <Artboard :renderBackground="false" v-if="isBaseEditingContext">
        <div :style="snapHintOneStyle"></div>
        <div :style="snapHintTwoStyle"></div>
      </Artboard>
      <Artboard :renderBackground="false" v-else>
        <div class="absolute" :style="editingCellSnapContainerStyle">
          <div :style="snapHintOneStyle"></div>
          <div :style="snapHintTwoStyle"></div>
        </div>
      </Artboard>
    </div>

    <div
      :style="dragBoxStyle"
      class="absolute pointer-events-none border bg-gray-300 border-gray-500 z-50 opacity-50"
    ></div>

    <Transition
      enter-class="opacity-0"
      enter-active-class="transition-opacity"
      leave-active-class="transition-opacity"
      enter-to-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="artboardIsOffscreen"
        class="absolute top-10 z-40 text-center center-canvas-area"
      >
        <button
          type="button"
          class="bg-app-purple rounded text-white font-semibold px-3 py-2 flex space-x-2 items-center shadow-md"
          @click="resetArtboard"
        >
          <div v-t="'resetArtboard'"></div>
          <div class="flex space-x-1 items-center">
            <span>(</span>
            <div
              class="border border-white rounded flex items-center justify-center h-5 w-5 text-xs font-normal"
            >
              ⌘
            </div>
            <div>+</div>
            <div
              class="border border-white rounded flex items-center justify-center h-5 w-5 text-xs font-normal font-mono"
            >
              0
            </div>
            <span>)</span>
          </div>
        </button>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { KeyCodes } from "@/keycodes";
import { EventBus } from "@/eventbus";
import { NO_UNDO, Point, Rectangle } from "@/types";
import { Widget, WidgetWithConditions } from "@/components/widgets/Widget";

import WidgetDragger from "@/components/WidgetDragger.vue";
import WidgetResizer from "@/components/WidgetResizer.vue";
import BoundingBox from "@/components/BoundingBox.vue";
import ToastMessage from "@/components/ToastMessage.vue";
import Tooltip from "@/components/Tooltip.vue";
import {
  getApparentDims,
  getVertices,
  doPolygonsIntersect,
  isWithinRotatedRectangle,
  makeId,
  scaledCoords,
  scaledCoordsInvert,
  subtractVectors,
  clamp,
  getRouteQueryValue,
  removeReactivity,
} from "@/utils";

import {
  addEvent,
  removeEvent,
  MOUSE_EVENTS,
  TOUCH_EVENTS,
} from "@/event-utilities";
import Artboard from "@/components/Artboard.vue";
import { GroupOptions } from "./widgets/Group/GroupOptions";
import { BASE_PARENT_ID } from "@/constants";
import { StyleValue } from "vue/types/jsx";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
// import { extractErrorMessage } from "@/utils";
import { useAppEditorStore } from "@/stores/appEditor";
import { DataBinding } from "@/types/data";
import { computeRepeaterEditingContext } from "@/utils";

let eventsFor = MOUSE_EVENTS;

interface ConditionGroupRef {
  conditionGroupUuid: string;
  widgetId: string;
}

/*
- [ ] delete all "perspective" stuff
- [ ] Group/children hovering feels a bit off. Shouldn't be able to rehover grp when child's selected.
- [ ] maybe use Canva's idea of diff border for grp when child is selected
- [ ] fix issues with old apps (settings defaults for angle,scaleX,scaleY)
*/

@Component({
  components: {
    WidgetResizer,
    WidgetDragger,
    BoundingBox,
    Artboard,
    ToastMessage,
    Tooltip,
  },
})
export default class CanvasEditor extends Vue {
  dragging = false;
  panning = false;
  spacePressed = false;
  toastMessage = "";
  toastDuration = 4;

  x1 = 0;
  y1 = 0;
  dragRect: Rectangle = { x: 0, y: 0, w: 0, h: 0 };
  panStart: Point = { x: 0, y: 0 };
  artboardPanStart: Rectangle = { x: 0, y: 0, w: 0, h: 0 };
  groupMoveMouseStart: Point = { x: 0, y: 0 };
  groupMoveWidgetsStart: any = {};

  snapPairs: any = [];

  copiedWidgetIndex = -1;

  artboardIsOffscreen = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get widgets() {
    return this.appEditor.editableWidgets;
  }

  get parents() {
    return this.appEditor.parents;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get clipboard() {
    return this.appEditor.clipboard;
  }

  get hoveredId() {
    return this.appEditor.hoveredId;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get scale() {
    return this.appEditor.scale;
  }

  get canvasBox() {
    return this.appEditor.canvasBox;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get textEditingWidget() {
    return this.appEditor.textEditingWidget;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get editingCellSnapContainerStyle(): StyleValue {
    const { offsetX, offsetY, width, height, parentId } = this.editingContext;
    const parent = this.appEditor.widgetById(parentId) as Widget;
    // console.log("parent", parent);

    const { x, y } = getApparentDims(parent);
    const cellDims = {
      x: x + offsetX,
      y: y + offsetY,
      w: width,
      h: height,
    };
    return {
      position: "absolute",
      left: `${cellDims.x}px`,
      top: `${cellDims.y}px`,
      width: `${cellDims.w}px`,
      height: `${cellDims.h}px`,
      // This actually works perfectly
      // But snapping doesn't account for rotation
      // So should turn it off when repeater's angle is non-zero
      // transform: `rotate(${parent.angle}deg)`
    };
  }

  get snapHintOneStyle() {
    if (this.snapPairs.length === 0) return {};
    const tgt = this.snapPairs[0].tgt;
    return this.snapHintStyle(tgt);
  }

  get snapHintTwoStyle() {
    if (this.snapPairs.length < 2) return {};
    const tgt = this.snapPairs[1].tgt;
    return this.snapHintStyle(tgt);
  }

  get resizerWidget() {
    if (
      this.selectedWidget !== undefined &&
      this.selectedWidget.parentId === this.editingContext.parentId
    ) {
      return this.selectedWidget;
    }
    return undefined;
  }

  snapHintStyle(tgt: any) {
    const isHorizontal = tgt.name.includes("y");

    // [x] TODO: Just dot the line if tgt.type doesn't exist or isn't "artboard"
    // [x] make sure size of dashed lines scales with scale

    // [ ] could clean up by NOT extending full width/height when tgt is a widget (rather than the artboard)

    const LINE_LEN = 5 / this.scale;
    const BREAK_LEN = 2 / this.scale;
    const LINE_WID = 1 / this.scale;

    let background = "black";
    // Use dotted line when tgt is widget rather than artboard:
    if (tgt.type !== "artboard") {
      background = isHorizontal
        ? `repeating-linear-gradient(to right, black 0, black ${LINE_LEN}px, transparent ${LINE_LEN}px, transparent ${
            LINE_LEN + BREAK_LEN
          }px)`
        : `repeating-linear-gradient(to bottom, black 0, black ${LINE_LEN}px, transparent ${LINE_LEN}px, transparent ${
            LINE_LEN + BREAK_LEN
          }px)`;
    }

    return {
      background,
      position: "absolute",
      left: isHorizontal ? 0 : `${tgt.value}px`,
      top: isHorizontal ? `${tgt.value}px` : 0,
      width: isHorizontal ? "100%" : `${LINE_WID}px`,

      // TODO: Unsure about this....maaybe just use 2?
      // Same issue with snap sensitivity
      height: isHorizontal ? `${LINE_WID}px` : "100%",
      // border: "black 1px dotted"
    };
  }

  get dragBoxStyle() {
    if (!this.dragging || this.dragRect.w === 0 || this.dragRect.h === 0) {
      return { display: "none" };
    }
    return {
      top: `${this.dragRect.y}px`,
      left: `${this.dragRect.x}px`,
      width: `${this.dragRect.w}px`,
      height: `${this.dragRect.h}px`,
    };
  }

  isSelected(widgetId: string) {
    return this.selections.includes(widgetId);
  }

  isHovered(wg: Widget) {
    return this.hoveredId === wg.wid;
  }

  // If verticalDynamism is enabled, put the children in proper order:
  getChildrenRender(groupWidget: Widget) {
    const group = groupWidget as GroupOptions;
    const children = this.appEditor.getChildren(group.wid);

    if (!group.verticalDynamism) {
      return children;
    }
    // Prob a better way to do this...
    return children.slice(0).sort((a, b) => {
      if (!group.orderedChildIds) return 1;
      return (
        group.orderedChildIds.indexOf(a.wid) -
        group.orderedChildIds.indexOf(b.wid)
      );
    });
  }

  getChildWgStyle(wg: Widget, groupWidget: Widget): StyleValue {
    let border = "";
    // NOTE: is this happening sometimes for children of repeaters?
    if (this.isHovered(wg)) border = "1px solid #4299e1";
    if (this.isSelected(wg.wid)) border = "1px solid green";

    const group = groupWidget as GroupOptions;
    const isFirstWg = group.orderedChildIds
      ? group.orderedChildIds[0] === wg.wid
      : false;

    // console.log("edited chldren", group.verticalDynamism);

    if (group.verticalDynamism) {
      return {
        width: `${wg.w * this.scale}px`,
        height: `${wg.h * this.scale}px`,
        transform: `rotate(${wg.angle}deg)`,
        marginLeft: `${wg.x * this.scale}px`,
        marginTop: isFirstWg ? 0 : `${group.verticalMargin * this.scale}px`,
        zIndex: wg.z,
        border,
      };
    }

    return {
      position: "absolute",
      top: `${wg.y * this.scale}px`,
      left: `${wg.x * this.scale}px`,
      width: `${wg.w * this.scale}px`,
      height: `${wg.h * this.scale}px`,
      transform: `rotate(${wg.angle}deg)`,
      border,
      background: "transparent",
      zIndex: wg.z,
    };
  }

  shouldRenderChildren(wg: Widget) {
    if (wg.type === "Repeater") return false;

    if (this.isSelected(wg.wid)) {
      return true;
    }

    return (this.parents[wg.wid] ?? []).some((childWidgetId) =>
      this.isSelected(childWidgetId)
    );
  }

  doubleClickChild(e: MouseEvent, wg: Widget) {
    if (this.selections.includes(wg.wid)) {
      if (wg.type === "Text") {
        this.appEditor.enterTextEditMode(wg.wid);
      }
      if (wg.type === "Repeater") {
        this.setRepeaterEditingContext(e, wg.wid);
      }
    }
  }

  clickChild(ev: any, wg: Widget) {
    if (ev.button !== 0) {
      return;
    }
    // Do not show globe icon
    ev.preventDefault();
    ev.stopPropagation();
    this.appEditor.replaceSelections([wg.wid]);
  }

  enterChild(wg: Widget) {
    this.appEditor.setHoveredId(wg.wid);
  }

  leaveChild(wg: Widget) {
    this.appEditor.setHoveredId("");
  }

  // TODO: If you only have 2 widgets selected, sketch gives you a bar to separate/bring them together

  // TODO: Snapping for group movement and widget creation...some kind of abstraction...

  created() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
    this.$nextTick(() => {
      (this.$refs["canvas-edit-layer"] as HTMLElement).addEventListener(
        "wheel",
        this.wheelListener
      );
    });
  }

  beforeDestroy() {
    (this.$refs["canvas-edit-layer"] as HTMLElement).removeEventListener(
      "wheel",
      this.wheelListener
    );
  }

  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
  }

  mounted() {
    setTimeout(() => {
      this.updateCanvasBox();
    }, 50);

    EventBus.on("WINDOW_RESIZE", () => {
      this.updateCanvasBox();
    });

    EventBus.on("SNAP_PAIRS", (pairs: any) => {
      this.snapPairs = pairs;
    });

    EventBus.on("DUPLICATE_WIDGET_ACTION", () => {
      this.pasteWidgetsClick();
    });

    // Fix issue where undoing a group/ungroup action causes multiselection box to show in wrong place (by just hiding the box):
    EventBus.on("UNDO_FIRED", () => {
      this.appEditor.replaceSelections([]);
    });

    // Prevents the default behavior of left-scroll causing page to go Back.
    document.body.style.overscrollBehaviorX = "none";
    // Prevents the default behavior of app slightly scrolling up/down out of viewport.
    document.body.style.overscrollBehaviorY = "none";
  }

  isMetaKey(event: WheelEvent) {
    const isWindows = navigator.platform.indexOf("Win") > -1;
    return isWindows ? event.ctrlKey : event.metaKey;
  }

  wheelListener(ev: WheelEvent) {
    // Hopefully prevent default browser zoom on Windows (with ctrl + scroll)
    ev.preventDefault();

    // Set zoom level on scroll, if meta key is pressed (command on Mac, ctrl on Windows)
    const scaleDampeningFactor = 0.25;
    if (this.isMetaKey(ev)) {
      const newScale = clamp(
        this.scale * (1 - (ev.deltaY * scaleDampeningFactor) / 100),
        0.15,
        10
      );
      this.appEditor.setScale(newScale);
      return;
    }

    let x = this.artboard.x;
    let y = this.artboard.y;

    x -= ev.deltaX / this.scale;
    y -= ev.deltaY / this.scale;

    this.appEditor.positionArtboard({ x, y });
  }

  // Show "Reset Artboard" button if user pans the artboard offscreen
  @Watch("artboard")
  artboardPanned() {
    const coords = scaledCoords(
      { ...this.artboard },
      this.canvasBox,
      this.scale
    );

    let isOffScreen = false;

    // Artboard off bottom
    if (coords.y > window.innerHeight) {
      isOffScreen = true;
    }

    // Artboard off top
    if (coords.y + this.artboard.h * this.scale < 0) {
      isOffScreen = true;
    }

    // Artboard off right
    if (coords.x > window.innerWidth) {
      isOffScreen = true;
    }

    // Artboard off left
    if (coords.x + this.artboard.w * this.scale < 0) {
      isOffScreen = true;
    }

    this.artboardIsOffscreen = isOffScreen;
  }

  updateCanvasBox() {
    const el = this.$el as HTMLElement;
    const bbox = el.getBoundingClientRect();
    // seems to fix issue now that toolbar has moved to top:
    const canvasBox = {
      x: bbox.left,
      y: bbox.top,
      w: bbox.width,
      h: bbox.height,
    };

    // console.log("canvasbox", canvasBox.x, canvasBox.y);
    this.appEditor.setCanvasBox(canvasBox);
  }

  get canGroup() {
    return (
      this.selections.length > 1 &&
      !this.selections.some(
        (wid) => this.appEditor.widgetById(wid)?.type === "Group"
      )
    );
  }

  get canUngroup() {
    return (
      this.selections.length === 1 &&
      this.appEditor.widgetById(this.selections[0])?.type === "Group"
    );
  }

  createGroup() {
    const wid = makeId();
    this.appEditor.groupWidgets({
      childWidgetIds: this.selections,
      groupWidgetId: wid,
      parentId: this.editingContext.parentId,
    });
  }

  destroyGroup() {
    this.appEditor.destroyGroup(this.selections[0]);
  }

  setToastMessage() {
    let message = "repeaterEditor.cannotPaste";
    this.toastMessage = message;
    setTimeout(() => {
      this.toastMessage = "";
    }, this.toastDuration * 1000);
  }

  resetArtboard() {
    EventBus.emit("RESET_ARTBOARD");
  }

  async onKeyDown(e: KeyboardEvent) {
    // Ok meta key not triggering on Windows 10 Chrome 88 in Browserstack..
    const { code } = e;

    if (e.target !== document.body) return;

    if (code === KeyCodes.BACKSPACE || code === KeyCodes.DELETE) {
      e.preventDefault(); // to prevent firefox from going Back

      let allowDelete = true;
      /**
       * Here we assume that if a widgetId is present in the URL
       * a modal window (data manager, conditions editor) is active
       * and we should prevent the deletion of that widget.
       */
      const widgetId = getRouteQueryValue(this.$route.query?.widgetId);
      if (widgetId && this.selections.includes(widgetId)) {
        console.log("cannot delete widget because it is present in url params");
        allowDelete = false;
      }

      /**
       * If repeater is selected, and is actively being edited, ignore delete button
       */
      if (this.editingContext.parentId === this.selections[0]) {
        allowDelete = false;
      }

      if (allowDelete) {
        this.appEditor.removeWidgetsAction({ widgetIds: this.selections });
        this.appEditor.replaceSelections([]);
      }
    }

    if (code === KeyCodes.SPACE) {
      // console.log("space true");
      this.spacePressed = true;
    }

    if (code === "Escape") {
      if (!this.isBaseEditingContext) {
        this.appEditor.resetEditingContext();
        return;
      }
    }

    if (KeyCodes.isGroup(e) && this.canGroup) {
      // console.log("grp!");
      e.preventDefault(); // "cmd + g" has some default behavior in chrome
      this.createGroup();
    }

    if (KeyCodes.isUngroup(e) && this.canUngroup) {
      // console.log("ungrp!");
      e.preventDefault();
      this.destroyGroup();
    }

    if (KeyCodes.isArtboardReset(e)) {
      EventBus.emit("RESET_ARTBOARD");
    }

    if (KeyCodes.isCopy(e)) {
      this.appEditor.copyWidgets();
      if (this.editingContext.repeaterIndex !== undefined) {
        // console.log("copy repeater index", this.editingContext.repeaterIndex);
        this.copiedWidgetIndex = this.editingContext.repeaterIndex;
      }
    }

    if (KeyCodes.isPaste(e)) {
      this.pasteWidgetsClick();
    }

    const isLocked = this.selections.map((wid) =>
      this.widgets.find((wg) => wg.wid === wid)
    )[0]?.locked;

    if (KeyCodes.isArrowKey(code) && !isLocked) {
      const direction =
        code === KeyCodes.RIGHT || code === KeyCodes.LEFT ? "x" : "y";
      let distance = code === KeyCodes.RIGHT || code === KeyCodes.DOWN ? 1 : -1;
      if (e.shiftKey) distance *= 10;
      this.appEditor.nudge({
        direction,
        distance,
        selections: this.selections,
      });
    }
  }

  get conditionGroupsStore() {
    return useConditionGroupsStore();
  }

  // TODO: Can all of this be moved into AppEditorStore.pasteWidgets?
  async pasteWidgetsClick() {
    /**
     * Disallow pasting slides/repeaters into other slides/repeaters:
     */
    const disallowPaste =
      this.clipboard.some((wg) =>
        [
          "CalendarAgenda",
          "CalendarDay",
          "CalendarEvent",
          "CalendarRoomSchedule",
          "CalendarWeek",
          "Repeater",
          "ColumnGraph",
          "BarGraph",
          "LineGraph",
          "PieGraph",
          "StackedGraph",
        ].includes(wg.type)
      ) && !this.isBaseEditingContext;

    if (disallowPaste) {
      this.setToastMessage();
      return;
    }

    const canPasteWithConditions = this.clipboard.every((wg) => {
      const wc = wg as unknown as WidgetWithConditions;
      const sharedParent = wc.parentId === this.editingContext.parentId;
      const hasNoConditions = Object.keys(wc.conditionalVersions).length === 1;
      return sharedParent || hasNoConditions;
    });

    if (!canPasteWithConditions) {
      // TODO: Show a better message.
      alert(
        "Can't paste widgets with conditions into a different editing context"
      );
      return;
    }

    const { conditionGroups } = this.conditionGroupsStore;

    if (this.clipboard.length === 0) return;

    const conditionGroupsToUpdate: ConditionGroupRef[] = [];

    /**
     * Recursively gathers all widgets in each descendent-tree of all widgets in the clipboard.
     * Handles cases like a Group within a Repeater within a Group.
     */
    const gatherWidgets = (
      wids: undefined | string[]
    ): (WidgetWithConditions & { bindings: DataBinding[] })[] => {
      if (!wids || wids.length === 0) return [];
      const widgets = wids.map((wid) => ({
        ...this.appEditor.widgets[wid],
        bindings: [],
      }));
      // Add any of the widget's children to the returned array
      const allChildren = widgets.flatMap((wg) => {
        const children = this.appEditor.parents[wg.wid];
        return gatherWidgets(children);
      });
      return widgets.concat(allChildren.map((c) => ({ ...c, bindings: [] })));
    };

    // It is crucial to removeReactivity -- spreading object is not enough
    const gatheredWidgets = removeReactivity<
      (WidgetWithConditions & { bindings: DataBinding[] })[]
    >(gatherWidgets(this.clipboard.map((w) => w.wid)));

    for (let i = 0; i < gatheredWidgets.length; i++) {
      const widget = gatheredWidgets[i];
      // Prepare to copy reference to conditionGroup associated with widget, if necessary:
      const widgetConditionGroup = conditionGroups.find((cg) =>
        cg.widgets.some((wg) => wg.widgetId === widget.wid)
      );

      /**
       * For text widgets that are children of repeaters,
       * when pasted into a non-repeater context,
       * we sever the binding (a more complex option would be to replace it with a scalar binding to that cell),
       * and replace the static content with a copy of the first cell's dynamic content.
       * (This parallels how we handle removing scalar data bindings from color and opacity properties, etc).
       *
       * Repeat the same solution for Image widgets, and Datetime widgets.. And Groups..for that, we have to go over all children..
       */
      const bindings =
        this.dataBindings.filter((db) => db.widgetId === widget.wid) || [];
      widget.bindings = bindings;

      if (
        bindings.length > 0 &&
        widget.parentId !== BASE_PARENT_ID &&
        this.isBaseEditingContext &&
        this.copiedWidgetIndex > -1
      ) {
        const widgetIndex = this.copiedWidgetIndex;
        const { property } = bindings[0];
        (widget as any)[property] =
          this.widgetData[widget.wid][widgetIndex]?.[property];
        widget.bindings = [];
      }

      // Update wid here so that it can be deterministically passed into payload of pasteWidgets for undo/redo
      const originalWid = widget.wid;
      const newWid = makeId();
      widget.wid = newWid;
      gatheredWidgets.forEach((w) => {
        if (w.parentId === originalWid) {
          w.parentId = newWid;
        }
      });

      if (typeof widgetConditionGroup !== "undefined") {
        conditionGroupsToUpdate.push({
          conditionGroupUuid: widgetConditionGroup.uuid as string,
          widgetId: newWid,
        });
      }
    }

    for (let i = 0; i < gatheredWidgets.length; i++) {
      if (gatheredWidgets[i].parentId === gatheredWidgets[0].parentId) {
        gatheredWidgets[i].parentId = this.editingContext.parentId;
      }
    }

    // Pass in wids to mutation so that its payload determines it always (for undo/redo):
    this.appEditor.pasteWidgets({
      widgets: gatheredWidgets,
    });

    // Trigger "syncDataForBindings" action to grab data for new widgets (could instead just paste it into data cache manually)
    await this.appEditor.updateApp();

    try {
      if (conditionGroupsToUpdate.length > 0) {
        EventBus.emit("AWAITING_SERVER", true);
        await Promise.all(
          conditionGroupsToUpdate.map((ref) => {
            const { widgetId, conditionGroupUuid } = ref;
            return this.conditionGroupsStore.copyConditionGroup({
              widgetId,
              conditionGroupUuid,
              appUuid: this.$route.params.id,
            });
          })
        );

        await this.conditionGroupsStore.getConditionGroups(
          this.$route.params.id
        );
      }

      this.appEditor.replaceSelections(gatheredWidgets.map((w) => w.wid));
    } catch (err) {
      // TODO: use this
      // const errorMessage = extractErrorMessage(
      //   err as any,
      //   this.$t("errorPasting").toString()
      // );
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
    }
  }

  // Ahh, source of windows (browserstack) bug: they're calling this while user is holding the key down
  // So how do we fix it?
  onKeyUp(e: any) {
    if (e.code === KeyCodes.SPACE) {
      // console.log("keyup space", e.repeat);
      // if (e.isComposing) return;
      this.spacePressed = false;
    }
  }

  panMove(e: any) {
    this.panning = true;
    // console.log("panning");
    const canvasX = e.touches ? e.touches[0].pageX : e.pageX - this.canvasBox.x;
    const canvasY = e.touches ? e.touches[0].pageY : e.pageY - this.canvasBox.y;

    const deltaX = canvasX - this.panStart.x;
    const deltaY = canvasY - this.panStart.y;

    let x = this.artboardPanStart.x;
    let y = this.artboardPanStart.y;

    x += deltaX / this.scale;
    y += deltaY / this.scale;

    this.appEditor.positionArtboard({ x, y });
  }

  panUp(e: any) {
    this.panning = false;
    removeEvent(document.documentElement, eventsFor.move, this.panMove);
    removeEvent(document.documentElement, eventsFor.stop, this.panUp);
  }

  positionGroup(e: any, NO_UNDO?: NO_UNDO) {
    // TODO: Seems there should be a method that does the following 3 operations:
    const x = e.touches ? e.touches[0].pageX : e.pageX - this.canvasBox.x;
    const y = e.touches ? e.touches[0].pageY : e.pageY - this.canvasBox.y;
    const coords = scaledCoordsInvert({ x, y }, this.canvasBox, this.scale);
    const coordsRelArtboard = subtractVectors(coords, this.artboard);
    const diffFromStart = subtractVectors(
      coordsRelArtboard,
      this.groupMoveMouseStart
    );

    this.appEditor.moveGroup(
      {
        wids: Object.keys(this.groupMoveWidgetsStart),
        delta: diffFromStart,
        groupStartingPositions: Object.assign({}, this.groupMoveWidgetsStart),
      },
      NO_UNDO
    );
  }

  groupDrag(e: any) {
    // Prevents globe icon/drag fail issue
    e.preventDefault();
    this.positionGroup(e, "NO_UNDO");
  }

  groupDragStop(e: any) {
    const x = e.touches ? e.touches[0].pageX : e.pageX - this.canvasBox.x;
    const y = e.touches ? e.touches[0].pageY : e.pageY - this.canvasBox.y;
    const coords = scaledCoordsInvert({ x, y }, this.canvasBox, this.scale);
    const coordsRelArtboard = subtractVectors(coords, this.artboard);
    const diffFromStart = subtractVectors(
      coordsRelArtboard,
      this.groupMoveMouseStart
    );

    if (!(diffFromStart.x === 0 && diffFromStart.y === 0)) {
      e.stopPropagation();
      this.positionGroup(e);
      this.groupMoveWidgetsStart = {};
    }

    removeEvent(document.documentElement, eventsFor.move, this.groupDrag);
    removeEvent(document.documentElement, eventsFor.stop, this.groupDragStop);
  }

  elementDown(e: any) {
    if (e.button !== 0) {
      return;
    }

    let x = e.touches ? e.touches[0].pageX : e.pageX - this.canvasBox.x;
    let y = e.touches ? e.touches[0].pageY : e.pageY - this.canvasBox.y;
    const b = this.canvasBox;

    // We are not going in here in Windows....why not? I think just a Browserstack bug..

    // Prepare to pan the canvas "camera":
    if (this.spacePressed) {
      // console.log("space pressed");
      this.panStart.x = x;
      this.panStart.y = y;
      this.artboardPanStart.x = this.artboard.x;
      this.artboardPanStart.y = this.artboard.y;

      addEvent(document.documentElement, eventsFor.move, this.panMove);
      addEvent(document.documentElement, eventsFor.stop, this.panUp);

      return;
    }

    // Prevent box from being drawn if click outside canvas:
    let isWithinCanvas = x > 0 && x < b.w + b.x && y > 0 && y < b.y + b.h;

    const coords = scaledCoordsInvert({ x, y }, this.canvasBox, this.scale);
    const coordsRelArtboard = subtractVectors(coords, this.artboard);

    let isWithinUnlockedWidget = false;
    let isWithinAnyWidget = false;

    this.widgets.forEach((w) => {
      const widgetDimensions = getApparentDims(w);

      const ec = this.editingContext;
      // We need to account for editing context here.
      widgetDimensions.x += ec.offsetX + ec.widgetX;
      widgetDimensions.y += ec.offsetY + ec.widgetY;

      const vertices = getVertices(widgetDimensions);
      const isWithin = isWithinRotatedRectangle(coordsRelArtboard, vertices);
      if (isWithin) {
        if (!w.locked) {
          isWithinUnlockedWidget = true;
        }
        isWithinAnyWidget = true;
      }
    });

    // Handle multiselection drag:
    if (this.selections.length > 1 && isWithinUnlockedWidget) {
      addEvent(document.documentElement, eventsFor.move, this.groupDrag);
      addEvent(document.documentElement, eventsFor.stop, this.groupDragStop);
      this.groupMoveMouseStart.x = coordsRelArtboard.x;
      this.groupMoveMouseStart.y = coordsRelArtboard.y;

      // If there are multiple widgets selected, we should start a group movement if user clicks within one of them:
      const selectedWidgets: any[] = this.selections
        .map((wid) => this.widgets.find((w) => w.wid === wid))
        .filter((w) => w !== undefined);

      // Clear out previous selections
      this.groupMoveWidgetsStart = {};

      selectedWidgets.forEach((w) => {
        this.groupMoveWidgetsStart[w.wid] = Object.assign({}, w);
      });
    } else if (isWithinCanvas && !isWithinUnlockedWidget) {
      /**
       * Record the x,y of the event so if a user begins dragging
       * (handled in elementMove) we can draw a selection box.
       */
      const dragStart = scaledCoordsInvert({ x, y }, this.canvasBox, 1);
      this.x1 = dragStart.x;
      this.y1 = dragStart.y;

      addEvent(document.documentElement, eventsFor.move, this.elementMove);
      addEvent(document.documentElement, eventsFor.stop, this.elementUp);
    }

    // This should effectively deselect all children widgets:
    if (!isWithinAnyWidget) {
      this.appEditor.replaceSelections([]);
    }
  }

  elementTouchDown(e: any) {
    eventsFor = TOUCH_EVENTS;
    this.elementDown(e);
  }

  elementMove(e: any) {
    const x1 = this.x1;
    const y1 = this.y1;

    let x2 = e.touches ? e.touches[0].pageX : e.pageX - this.canvasBox.x;
    let y2 = e.touches ? e.touches[0].pageY : e.pageY - this.canvasBox.y;

    const coords = scaledCoordsInvert({ x: x2, y: y2 }, this.canvasBox, 1);
    x2 = coords.x;
    y2 = coords.y;

    this.dragRect = {
      x: Math.min(x1, x2),
      y: Math.min(y1, y2),
      w: Math.abs(x1 - x2),
      h: Math.abs(y1 - y2),
    };

    this.dragging = true;

    // [ ] TODO: Shouldn't we have to use surroundingBox here? Or hereabouts? I think so...

    // Detect which widgets on the canvas intersect with cursorBox
    const hitWidgets = this.widgets.filter((widget) => {
      /**
       * NOTE: This is still not perfect for rotated widgets (but is better than NOT using getAngledBoundingBox, which ignores rotation altogether).
       * With this solution, the smallest enclosing non-rotated rectangle around a rotated widget will count as its "hitbox",
       * so that when the cursorBox is dragged to intersect with this "angledBoundingBox", the widget will count as selected.
       * It would require a different strategy to use the rotated widget itself as the hitbox.
       */
      const props = getApparentDims(widget);

      // const props = getAngledBoundingBox(getApparentDims(widget));

      // We need to account for editing context here.
      props.x += this.editingContext.offsetX + this.editingContext.widgetX;
      props.y += this.editingContext.offsetY + this.editingContext.widgetY;

      const widgetVertices = getVertices(props);

      // Map dragbox from screen coords into coords relative to artboard:
      const coords = scaledCoordsInvert(
        this.dragRect,
        this.canvasBox,
        this.scale
      );
      const coordsRelArtboard = subtractVectors(coords, this.artboard);
      const lowerRight = {
        x: this.dragRect.x + this.dragRect.w,
        y: this.dragRect.y + this.dragRect.h,
      };
      const coords2 = scaledCoordsInvert(
        lowerRight,
        this.canvasBox,
        this.scale
      );
      const coordsRelArtboard2 = subtractVectors(coords2, this.artboard);

      const dbox = {
        x: coordsRelArtboard.x,
        y: coordsRelArtboard.y,
        w: coordsRelArtboard2.x - coordsRelArtboard.x,
        h: coordsRelArtboard2.y - coordsRelArtboard.y,
      };

      const dragBoxVertices = getVertices({
        ...dbox,
        angle: 0,
        scaleX: 1,
        scaleY: 1,
        z: 1,
        canScaleX: false,
        canScaleY: false,
        lockAspect: false,
        opacity: 1,
      });

      // return (
      //   props.x < dbox.x + dbox.w &&
      //   props.x + props.w > dbox.x &&
      //   props.y < dbox.y + dbox.h &&
      //   props.y + props.h > dbox.y
      // );

      return doPolygonsIntersect(widgetVertices, dragBoxVertices);
    });

    let replacements = hitWidgets
      .filter((wg) => !wg.locked)
      .map((wg) => wg.wid);

    // Hack to make Dataset explorer stay open when user clicks and drags (even just a tiny bit) empty space in repeater cell
    if (replacements.length === 0 && !this.isBaseEditingContext) {
      replacements = [this.editingContext.parentId];
    }

    this.appEditor.replaceSelections(replacements);
  }

  elementUp(e: any) {
    this.dragging = false;
    e.stopPropagation();

    removeEvent(document.documentElement, eventsFor.move, this.elementMove);
    removeEvent(document.documentElement, eventsFor.stop, this.elementUp);
  }

  get canvasStyle() {
    let cursor = "";
    if (this.panning) {
      cursor = "grabbing";
    } else if (this.spacePressed) {
      cursor = "grab";
    }

    return { cursor };
  }

  onDoubleClick() {
    if (!this.isBaseEditingContext) {
      this.appEditor.resetEditingContext();
    }
  }

  setRepeaterEditingContext(e: MouseEvent, widgetId: string) {
    const widget = this.appEditor.widgetById(widgetId);
    if (!widget) return;
    const editingContext = computeRepeaterEditingContext(widget, e, this.scale);
    if (typeof editingContext === "undefined") return;

    this.appEditor.setEditingContext(editingContext);
    // Select the repeater so Dataset explorer shows up in righthand panel
    this.appEditor.replaceSelections([widgetId]);

    // Stop double click event from propagating and triggering a "resetEditingContext" action
    e.stopPropagation();
  }
}
</script>
