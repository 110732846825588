<template>
  <div data-type="Group" :style="groupStyle">
    <div
      v-for="(child, i) in children"
      :key="i"
      :style="getWgPositionStyle(child)"
      :class="{ absolute: !verticalDynamism }"
    >
      <div
        :style="getWgOuterStyle(child)"
        :id="child.selector"
        class="relative"
      >
        <div
          class="absolute -top-8 left-0 rounded border border-app-teal p-1"
          v-if="showWidgetIdDebugText"
        >
          Widget Id: {{ child.wid }}
        </div>
        <component
          :renderWid="child.wid"
          :is="$helpers.getWidgetComponent(child.type)"
          v-bind="child"
          :width="child.w * child.scaleX"
          :height="child.h * child.scaleY"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop } from "vue-property-decorator";

import { isNumber, scaleWidget } from "@/utils";
import { Widget } from "@/components/widgets/Widget";
import { getActiveWidget } from "@/util/conditionsUtils";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";
import { getAnimationId } from "../Animation";
import { GlobalAppState } from "@/GlobalAppState";
import { AppMode } from "@/types";

@Component({
  inheritAttrs: false,
})
export default class GroupComponent extends AnimatableWidget {
  @Inject() appMode: AppMode;
  @Inject() context: GlobalAppState;
  @Prop(Boolean) verticalDynamism: boolean;
  @Prop(Number) verticalMargin: number;
  @Prop(Array) orderedChildIds: string[];
  @Prop({ type: Number, default: 0 }) dataRowIndex: number;

  get children() {
    return (this.context.parents[this.wid] || []).map((wid) => {
      const w = this.context.widgets[wid];

      const mergedWidget = {
        ...getActiveWidget(w, this.context.conditions),
        ...this.context.widgetData[w.wid][this.dataRowIndex],
        dataRowIndex: this.dataRowIndex,
        selector: getAnimationId(w.wid, this.dataRowIndex),
      };

      if (this.appMode === "render") {
        return scaleWidget(mergedWidget, this.context.renderScale);
      }
      return mergedWidget;
    });
  }

  get showWidgetIdDebugText() {
    return this.$route?.query.debug === "true";
  }

  get groupStyle() {
    return {
      opacity: this.opacity / 100,
    };
  }

  getWgPositionStyle(wg: Widget) {
    const isFirstWg = this.orderedChildIds
      ? this.orderedChildIds[0] === wg.wid
      : false;

    if (this.verticalDynamism) {
      return {
        transform: `rotate(${wg.angle}deg)`,
        marginLeft: `${wg.x}px`,
        marginTop: isFirstWg ? 0 : `${this.verticalMargin}px`,
        width: `${wg.w}px`,
        zIndex: wg.z,
      };
    }
    return {
      width: `${wg.w}px`,
      height: `${wg.h}px`,
      left: `${wg.x}px`,
      top: `${wg.y}px`,
      transform: `rotate(${wg.angle}deg)`,
      zIndex: wg.z,
    };
  }

  getWgOuterStyle(widget: any) {
    const opacity = isNumber(widget.opacity) ? widget.opacity / 100 : 1;
    let extraStyles = {};

    // Allows text widgets to have box shadows (i.e. don't use drop shadow for them):
    const isTextWidget = widget.type ? widget.type.includes("Text") : false;
    if (isTextWidget) {
      const shadow = widget.dropShadowEnabled ? widget.dropShadow : "";
      extraStyles = Object.assign(extraStyles, {
        "box-shadow": shadow,
      });
    } else {
      const shadow = widget.dropShadowEnabled
        ? `drop-shadow(${widget.dropShadow})`
        : "";
      extraStyles = Object.assign(extraStyles, {
        filter: shadow,
      });
    }

    return Object.assign(
      {
        opacity,
      },
      extraStyles
    );
  }
}
</script>
