<template>
  <div>
    <div
      @click="onClick"
      class="relative p-4 flex items-center space-x-4 bg-gray-700/30"
      :class="{ 'cursor-pointer': !expanded }"
    >
      <div
        class="w-6 h-6 text-sm rounded-full border flex items-center justify-center"
        :class="{
          'bg-app-teal text-white border-app-teal': expanded,
          'text-gray-600 border-gray-600': !expanded,
        }"
      >
        <div v-text="number"></div>
      </div>
      <div class="flex-grow"><slot name="title" /></div>
      <div class="flex items-center justify-center">
        <svg
          v-if="expanded"
          class="w-3 h-3"
          viewBox="0 0 22 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 1.5L11 11.5L1 1.5"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="!expanded"
          class="w-3 h-3"
          viewBox="0 0 12 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L11 11L1 21"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="expanded"
      class="p-6 border-t border-gray-500 px-14 bg-gray-700/60 space-y-4"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConditionsIntroStep extends Vue {
  @Prop(String) title: string;
  @Prop(Number) number: number;
  @Prop(Boolean) expanded: boolean;

  onClick() {
    if (!this.expanded) {
      console.log("clicked", this.number);
      this.$emit("click");
    }
  }
}
</script>
