import { DatetimeOption } from "@/types/DateTimeOption";

export const sampleDate: Date = new Date(
  Date.parse("04 Aug 1999 20:25:30 GMT")
); // pick a fun date for dropdown examples

export const dateBaseFormat: DatetimeOption[] = [
  { type: "dateStyle", value: "full" }
];
export const timeBaseFormat: DatetimeOption[] = [
  { type: "timeStyle", value: "medium" }
];

export const timeBasePreset = {
  hour: "numeric",
  minute: "2-digit",
  second: "2-digit"
};

export const dateBasePreset = {
  day: "numeric",
  weekday: "long",
  month: "numeric",
  year: "numeric"
};

export const datetimePresets = [
  // DATES
  {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  },

  {
    month: "numeric",
    day: "numeric",
    year: "2-digit"
  },
  {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  },
  {
    month: "numeric",
    day: "numeric"
  },
  {
    month: "2-digit",
    day: "2-digit"
  },
  {
    month: "long",
    day: "numeric",
    year: "numeric"
  },
  {
    month: "long",
    day: "numeric"
  },
  {
    month: "short",
    day: "numeric",
    year: "numeric"
  },
  {
    month: "short",
    day: "numeric"
  },
  // TIMES
  {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit"
  },
  {
    hour: "numeric",
    minute: "2-digit"
  },
  {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: false
  },
  {
    hour: "numeric",
    minute: "2-digit",
    hour12: false
  },
  // DATE-TIMES
  {
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: false
  },
  {
    month: "short",
    weekday: "long",
    day: "numeric",
    year: "numeric"
  },
  {
    month: "short",
    weekday: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit"
  }

  // Oooho right, we acn't store presets this way!
  // {
  //     dateStyle: "full",
  //     timeStyle: "medium"
  // }
];
