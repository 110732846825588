<template>
  <div
    id="edit-form"
    class="bg-app-dark5 ml-3 border border-black overflow-y-scroll"
    @keydown.esc="handleEscKeydown"
    tabindex="1"
  >
    <!-- Header: -->
    <div class="flex justify-between items-center bg-app-dark1 p-1">
      <div class="flex items-center" v-if="rowBelongsToFilteredRows">
        <div
          @click="editableDataStore.decrementEditingRowIndex"
          class="p-1 rounded bg-gray-600 cursor-pointer mx-1 text-white select-none"
        >
          <Icon
            name="SizeTriangle"
            class="w-5 h-5"
            fill="white"
            stroke="none"
            style="transform: scale(1.5, 1)"
          />
        </div>
        <div
          @click="editableDataStore.incrementEditingRowIndex"
          style="transform: rotate(180deg)"
          class="p-1 rounded bg-gray-600 cursor-pointer mx-1 text-white select-none"
        >
          <Icon
            name="SizeTriangle"
            class="w-5 h-5"
            fill="white"
            stroke="none"
            style="transform: scale(1.5, 1)"
          />
        </div>

        <div class="ml-2">{{ recordPositionMessage }}</div>
      </div>

      <div
        @click="editableDataStore.editingRowUuid = ''"
        class="cursor-pointer px-2 py-1 text-white opacity-50"
      >
        <Icon name="Close" class="w-5 h-5" stroke-width="4" />
      </div>
    </div>

    <!-- Moderation Status: -->
    <div v-if="isModerated" class="p-3">
      <div class="mb-2">Moderation Status</div>
      <div class="inline-flex rounded border border-black">
        <div
          :class="[
            moderationNode?.isSelected
              ? 'px-4 bg-gradient-to-b from-gray-500 to-gray-600'
              : 'text-gray-400 px-5 bg-app-dark2',
          ]"
          class="cursor-pointer py-2 flex space-x-2 items-center"
          @click="
            editableDataStore.setModerationDataRowSelected({
              refUuid: editableDataStore.editingRowUuid,
              value: true,
            })
          "
        >
          <div
            v-if="moderationNode?.isSelected"
            class="rounded bg-gray-400 opacity-90 w-5 h-5"
            style="padding: 1px"
          >
            <IconSolid name="Checkmark" />
          </div>
          <div>{{ moderationSelectText }}</div>
        </div>
        <div
          :class="[
            moderationNode?.isSelected
              ? 'text-gray-400 px-5 bg-app-dark2'
              : 'px-4 bg-gradient-to-b from-gray-500 to-gray-600',
          ]"
          class="cursor-pointer py-2 flex space-x-2 items-center border-l border-black"
          @click="
            editableDataStore.setModerationDataRowSelected({
              refUuid: editableDataStore.editingRowUuid,
              value: false,
            })
          "
        >
          <div
            v-if="!moderationNode?.isSelected"
            class="rounded bg-gray-400 opacity-90 w-5 h-5"
            style="padding: 1px"
          >
            <IconSolid name="Checkmark" />
          </div>
          <div>{{ moderationDeselectText }}</div>
        </div>
      </div>
    </div>

    <!-- Form: -->
    <div class="flex flex-col p-4 space-y-4 pr-20">
      <div
        v-for="node in editingRow?.columns"
        :key="node.uuid || ''"
        class="flex flex-col space-y-2"
      >
        <div class="text-sm font-bold">
          {{ getNodeName(node.uuid || "") }}
        </div>
        <div>
          <div @keypress.enter="enterKeyPressHandler(node)" v-if="isEditable">
            <component
              class="text-black px-2 py-1"
              :is="getInputComponent(node)"
              :node="node"
              :rowUuid="editingRow?.rowUuid"
              @blur="inputBlurHandler($event, node)"
              :class="getCellClass(editingRow?.rowUuid || '', node)"
            >
            </component>
          </div>
          <div v-else>{{ node.value }}</div>
        </div>

        <div
          v-if="hasError(node.uuid || '')"
          class="border border-errors rounded text-xs bg-errors-faded"
          style="padding: 3px 5px; width: fit-content"
        >
          {{ getErrorMessage(node) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  NodeDataTypes,
  DataTypeInterface,
  encodeLookupKey,
} from "@/stores/editableData";
import { NodeData, SchemaNode } from "@/types/data";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";

import TextInput from "@/components/data/connections/manualSource/inputs/TextInput.vue";
import NumberInput from "@/components/data/connections/manualSource/inputs/NumberInput.vue";
import TextAreaInput from "@/components/data/connections/manualSource/inputs/TextAreaInput.vue";
import DatetimeInput from "@/components/data/connections/manualSource/inputs/DatetimeInput.vue";
import ImageUploadInput from "@/components/data/connections/manualSource/inputs/ImageUploadInput.vue";
import ColorInput from "@/components/data/connections/manualSource/inputs/ColorInput.vue";
import BooleanInput from "@/components/data/connections/manualSource/inputs/BooleanInput.vue";
import CurrencyInput from "@/components/data/connections/manualSource/inputs/CurrencyInput.vue";

import { useEditableDataStore } from "@/stores/editableData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

/**
 * [ ] ESC to close -- works as long as is focused -- well, not really with blur
 * [ ] Scrollbar custom style -- and make scrollable
 * [x] non-editable? (for now just show text)
 */

@Component({
  components: {
    Icon,
    IconSolid,
    TextInput,
    NumberInput,
    TextAreaInput,
    DatetimeInput,
    ImageUploadInput,
    ColorInput,
    BooleanInput,
    CurrencyInput,
  },
})
export default class EditDataRowForm extends Vue {
  @Prop({ default: true }) isEditable: boolean;

  get moderationMode() {
    const store = useConnectionEditorStore();
    return store.moderationMode;
  }

  get schema() {
    const store = useConnectionEditorStore();
    return store.schema as SchemaNode[];
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  get editingRow() {
    return this.editableDataStore.editingRow;
  }

  get isModerated() {
    return this.moderationMode !== null;
  }

  get moderationNode() {
    return (this.editableDataStore.rows || []).find(
      (n) => n.rowUuid === this.editableDataStore.editingRowUuid
    );
  }

  get moderationSelectText() {
    return this.moderationMode === "Approval"
      ? this.$t("dataModeration.moderationModeApproved")
      : this.$t("dataModeration.moderationModeRemoved");
  }

  get moderationDeselectText() {
    return this.moderationMode === "Approval"
      ? this.$t("dataModeration.moderationModeUnapproved")
      : this.$t("dataModeration.moderationModeIncluded");
  }

  // Both this and blurHandler will alert store to do the modifications/validation check
  enterKeyPressHandler(node: NodeData) {
    // TODO: Defocus the input? Tab to next?
    this.editableDataStore.clearEditingCell({
      rowUuid: this.editableDataStore.editingRowUuid,
      columnUuid: node.uuid || "",
      schema: this.schema,
    });
  }

  inputBlurHandler(ev: any, node: NodeData) {
    // console.log("blur");
    ev.stopPropagation();
    this.editableDataStore.clearEditingCell({
      rowUuid: this.editableDataStore.editingRowUuid,
      columnUuid: node.uuid || "",
      schema: this.schema,
    });
  }

  get editingRowIndex() {
    return this.editableDataStore.rowIndex;
  }

  get matchingText() {
    if (
      this.editableDataStore.searchText === "" &&
      !this.editableDataStore.applyValidationErrorsFilter
    ) {
      return "";
    }
    return " matching";
  }

  getNodeName(columnUuid: string) {
    return (this.schema || []).find((n) => n.uuid === columnUuid)?.name;
  }

  hasError(columnUuid: string) {
    const key = `${this.editableDataStore.editingRowUuid},${columnUuid}`;
    const hasError = Object.keys(
      this.editableDataStore.validationErrorsLookup
    ).includes(key);
    return hasError;
  }

  getErrorMessage(node: NodeData) {
    const { uuid, dataType } = node;
    if (!this.hasError(uuid || "")) return "";
    return NodeDataTypes.find((dt) => dt.name === dataType)?.errorMessage;
  }

  getInputComponent(node: NodeData) {
    const { uuid } = node;
    const schemaNode = this.schema.find((n) => n.uuid === uuid);
    const dataType = NodeDataTypes.find(
      (d) => d.name === schemaNode?.dataType
    ) as DataTypeInterface;
    return dataType.getComponent(node.value, true); // Second argument says to allow text area
  }

  // NOTE: this works, but only if user is focused within form
  // TODO: So perhaps focus (input or whole thing) as soon as they open it?
  // Huh...also interacts oddly with blur
  handleEscKeydown(ev: any) {
    // ev.stopPropagation();
    // console.log("close esc");
    // this.setEditingRow({ uuid: "" });
  }

  setEditingRow(uuid: string) {
    this.editableDataStore.editingRowUuid = uuid;
  }

  // Hide arrow buttons along with "matching records" text if row has been removed from current (text or validation) filter via edit of its values
  get rowBelongsToFilteredRows() {
    return this.editableDataStore.filteredRows
      .map((r) => r.rowUuid)
      .includes(this.editableDataStore.editingRowUuid);
  }

  // NOTE: total should have the deleted rows filtered out...
  // ahhh but it's weird... because we still show its index. So if deleted is 6, one after it would show up as "7 of 11"... Ugh
  // Whatever, maybe we just keep it in the total, and just skip when inc/dec
  get recordPositionMessage() {
    // Show no text if row is not included in filter
    // if (!this.rowBelongsToFilteredRows) return "";

    const position = this.editingRowIndex + 1;
    const total = this.editableDataStore.filteredRows.length;
    const suffix = this.editableDataStore.filteredRows.length === 1 ? "" : "s";
    return `${position} of ${total}${this.matchingText} record${suffix}`;
  }

  getCellClass(rowUuid: string, node: NodeData) {
    const { uuid } = node;
    let borderColorClass = "";

    const key = encodeLookupKey({ rowUuid, columnUuid: uuid || "" });

    // if (Object.keys(this.modificationsLookup).includes(key)) {
    //   borderColorClass = "border-modifications";
    // }

    if (
      Object.keys(this.editableDataStore.validationErrorsLookup).includes(key)
    ) {
      borderColorClass = "border-errors";
    }

    const borderClass = borderColorClass ? `border-2 ${borderColorClass}` : "";
    return `${borderClass}`;
  }
}
</script>

<style scoped>
#edit-form::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  background: rgb(30, 30, 30);
}

#edit-form::-webkit-scrollbar-thumb {
  background: rgba(214, 214, 214, 0.7);
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}
</style>
