import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface PieGraphOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  h: number;
  w: number;
  value_fontFamily: string;
  value_fontSize: number;
  value_fontWeight: number;
  value_fontStyle: string;
  value_textColor: string;
  legend_fontFamily: string;
  legend_fontSize: number;
  legend_fontWeight: number;
  legend_fontStyle: string;
  legend_textColor: string;
  legend_textTransform: string;
  lineColor: string;
  lineThickness: number;

  showLegend: boolean;
  legendMargin: number;
  legendPosition: string;
  showValues: boolean;
  valuesInside: boolean;
  valuesAsPercent: boolean;
  xAxisColumnId: string;
  yAxisColumnId: string;
  lockAspect: boolean;

  colors: string[];
}

export const defaultPieGraphOptions = {
  height: 400,
  w: 400,
  value_fontFamily: "Montserrat",
  value_fontSize: 24,
  value_fontWeight: 600,
  value_fontStyle: "normal",
  value_textColor: "#000000",
  legend_fontFamily: "Montserrat",
  legend_fontSize: 24,
  legend_fontWeight: 300,
  legend_fontStyle: "normal",
  legend_textColor: "#000000",
  legend_textTransform: "capitalize",
  lineColor: "#ffffff",
  lineThickness: 5,
  showLegend: true,
  legendMargin: 30,
  legendPosition: "Right",
  showValues: false,
  valuesInside: true,
  valuesAsPercent: false,
  valueColumnId: "",
  labelColumnId: "",
  lockAspect: true,
  colors: [
    "#134E4A",
    "#15756D",
    "#25B8A5",
    "#5DEAD5",
    "#CBFBF0",
    "#115F5A",
    "#1D968B",
    "#2CD4BD",
    "#98F6E3",
    "#E0FFF8",
  ],

  /**
   * Stores the UUID of the data column representing the x axis
   */
  xAxisColumnId: "b",
  /**
   * Stores the UUID of the data column representing the y axis
   */
  yAxisColumnId: "a",
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultPieGraphOptions,
};

const CreatePieGraph = (options: Partial<PieGraphOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "PieGraph",
    wid: makeId(),
    lockAspect: true,
    name: DEFAULT_LAYER_NAMES.PieGraph,
  });
};

export default CreatePieGraph;
