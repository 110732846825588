<template>
  <div
    class="relative flex items-center space-x-2 cursor-pointer h-5 pl-3"
    :class="{ 'opacity-50 cursor-not-allowed': disabled }"
    @click.stop="handleClick"
  >
    <div class="text-sm text-gray-500 select-none w-3/4">
      <slot />
    </div>
    <EditorNumberInput
      :class="`w-${width}`"
      :value="value"
      :attrs="attrs"
      :align="align"
      :controls="controls"
      :disabled="disabled"
      :inputtable="inputtable"
      :inline="inline"
      :max="max"
      :min="min"
      :step="step"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :readonly="readonly"
      :rounded="rounded"
      :px="px"
      :py="py"
      :textSize="textSize"
      :cycle360="cycle360"
      :isTransparent="isTransparent"
      :dataBound="dataBound"
      :icon="icon"
      :units="units"
      @input="onInput"
      @change="onChange"
      ref="numberInput"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";

@Component({
  components: {
    EditorNumberInput,
  },
})
export default class NumberInputBar extends Vue {
  @Prop({ type: Object, default: undefined }) attrs: any;
  @Prop({ type: String, default: "center" }) align: string;
  @Prop({ type: Boolean, default: true }) controls: boolean;
  @Prop({ type: Number, default: 14 }) width: number;
  @Prop(Boolean) disabled: boolean;
  @Prop({ type: Boolean, default: true }) inputtable: boolean;
  @Prop(Boolean) inline: boolean;
  @Prop({ type: Number, default: Infinity }) max: number;
  @Prop({ type: Number, default: 0 }) min: number;
  @Prop(String) name: string;
  @Prop(String) label: string;
  @Prop(String) placeholder: string;
  @Prop(Boolean) readonly: boolean;
  @Prop(Boolean) rounded: boolean;
  @Prop({ type: Number, default: 1 }) py: number;
  @Prop({ type: Number, default: 1 }) px: number;
  @Prop({ type: Number, default: 1 }) step: number;
  @Prop({ type: Number, default: NaN }) value: number;
  @Prop({ type: String, default: "sm" }) textSize: string;
  @Prop(Boolean) cycle360: boolean;
  @Prop(Boolean) isTransparent: boolean;
  @Prop(Boolean) dataBound: boolean;
  @Prop(String) icon: string;
  @Prop(String) units: string;

  handleClick() {
    (this.$refs.numberInput as EditorNumberInput).toggle();
  }

  onInput(value: string) {
    this.$emit("input", value);
  }
  onChange(value: string) {
    this.$emit("change", value);
  }
}
</script>
