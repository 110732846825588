<template>
  <div
    :style="draggerStyle"
    @mousedown="mousedown"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
    @click="click"
    @mouseup="mouseup"
    @dblclick="onDoubleClick($event)"
  >
    <div :style="element">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { styler, translate } from "@/lib/free-transform";
import { Component, Prop, Vue } from "vue-property-decorator";
import { EventBus } from "@/eventbus";
import { KeyCodes } from "@/keycodes";
import { useDragDropStore } from "@/stores/dragDrop";
import { DataConnection } from "@/types/data";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionDataStore } from "@/stores/connectionData";
import { useConnectionsStore } from "@/stores/connections";
import { computeRepeaterEditingContext, throttle } from "@/utils";

@Component({
  inheritAttrs: false,
})
export default class WidgetDragger extends Vue {
  @Prop({ type: Number }) w: number;
  @Prop({ type: Number }) h: number;
  @Prop({ type: Number }) x: number;
  @Prop({ type: Number }) y: number;
  @Prop({ type: Number }) z: number;
  @Prop({ type: String }) wid: string;
  @Prop({ type: Number, default: 1 }) scaleX: number;
  @Prop({ type: Number, default: 1 }) scaleY: number;
  @Prop({ type: Number, default: 0 }) angle: number;
  @Prop({ type: String }) type: string;
  @Prop({ type: Boolean }) locked: boolean;
  @Prop() children: any[];
  @Prop({ type: Boolean }) spacePressed: boolean;

  // @data.State connections: DataConnection[];

  mousedownPosition = {
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    snapX: 0,
    snapY: 0,
  };

  get appEditor() {
    return useAppEditorStore();
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get hoveredId() {
    return this.appEditor.hoveredId;
  }

  get scale() {
    return this.appEditor.scale;
  }

  get widgets() {
    return this.appEditor.renderableWidgets;
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get canSelect() {
    return !this.spacePressed && this.editingContext.parentId !== this.wid;
  }

  get selected() {
    return this.selections.includes(this.wid);
  }

  get hovered() {
    return this.wid === this.hoveredId;
  }

  // Accounts for distinction between render layer (this.x, etc) and manipulation layer (realPosition):
  get realPosition() {
    const rect = { x: this.x, y: this.y, w: this.w, h: this.h };

    const result = this.appEditor.scaleForEditLayer(rect);

    // Account for special case of children of Repeater that lives within a Group:
    const widget = this.appEditor.widgetById(this.wid);
    const parent = this.appEditor.widgetById(widget?.parentId as string);

    if (parent?.type === "Repeater") {
      const grandparent = this.appEditor.widgetById(parent?.parentId as string);
      if (grandparent?.type === "Group") {
        result.x += grandparent.x * this.scale;
        result.y += grandparent.y * this.scale;
      }
    }

    return result;
  }

  get element(): { [key: string]: any } {
    const { x, y, w, h } = this.realPosition;

    const { element } = styler({
      x: x,
      y: y,
      z: this.z,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      width: w,
      height: h,
      angle: this.angle,
      disableScale: true, // always disable scale
    });

    // Allows us to detect clicks on children (really, distinction between layers not necessary for this):
    const zStyle =
      this.type === "Group" ? { zIndex: 1000 } : { zIndex: this.z };

    return {
      ...element,
      ...zStyle,
      width: element.width ? `${element.width}px` : null,
      height: element.height ? `${element.height}px` : null,
      border: this.hovered ? "1px solid #4299e1" : null,
      // filter: "drop-shadow(3px 3px 5px black)",
    } as { [key: string]: any };
  }

  get draggerStyle() {
    const style: any = {};
    style.zIndex = this.z;

    return style;
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  handleTranslation(event: any) {
    this.mousedownPosition.x = this.x;
    this.mousedownPosition.y = this.y;

    event.stopPropagation();
    // Stop text from highlighting in editor when widget is dragged over it
    event.preventDefault();
    if (this.locked) {
      return;
    }

    const drag = translate(
      {
        x: this.x,
        y: this.y,
        w: this.w,
        h: this.h,
        angle: this.angle,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        startX: event.pageX,
        startY: event.pageY,
        canvasScale: this.scale,
        widgets: this.widgets,
        wid: this.wid,
        artboard: this.artboard,
        isBaseEditingContext: this.isBaseEditingContext,
        editingContext: this.editingContext,
      },
      (payload: any) => {
        const changed = !(
          payload.x === this.mousedownPosition.x &&
          payload.y === this.mousedownPosition.y
        );

        EventBus.emit("SNAP_PAIRS", payload.snapPairs);

        if (changed) {
          this.appEditor.setWidgetProps(
            [this.wid],
            {
              x: payload.x,
              y: payload.y,
              w: this.w,
              h: this.h,
            },
            "NO_UNDO"
          );
        }
      }
    );

    this.onDrag({ name: "drag", handler: drag });
  }

  onDrag(drag: any) {
    const { handler } = drag;

    const throttledHandler = throttle(handler);
    this.appEditor.isDraggingWidget = true;

    // Here, we check whether widget has actually changed, or user has merely clicked, to avoid adding mere click to undo/redo stack:
    const up = () => {
      this.appEditor.isDraggingWidget = false;

      if (
        !(
          this.x === this.mousedownPosition.x &&
          this.y === this.mousedownPosition.y
        )
      ) {
        this.appEditor.setWidgetProps([this.wid], {
          x: this.x,
          y: this.y,
          w: this.w,
          h: this.h,
        });
      }

      document.removeEventListener("mousemove", throttledHandler);
      document.removeEventListener("mouseup", up);

      /**
       * For some reason, sometimes the "drag" function fires once after this, causing the snap lines to reappear.
       * This timeout hack should ensure that this call always comes last.
       *
       * Note from Jesse...
       * Maybe we need to call throttledHandler.cancel()?
       */
      setTimeout(() => {
        EventBus.emit("SNAP_PAIRS", []);
      }, 50);
    };

    document.addEventListener("mousemove", throttledHandler);
    document.addEventListener("mouseup", up);
  }

  mouseenter() {
    this.appEditor.setHoveredId(this.wid);
  }
  mouseleave() {
    this.appEditor.setHoveredId("");
  }

  click() {
    if (this.locked) {
      this.appEditor.replaceSelections([this.wid]);
    }
  }

  // Need to NOT do this at the end of a groupDrag (handled with stopPropagation when end dragbox drawing, and when end groupdrag)
  mouseup(event: any) {
    const multiselected =
      this.selections.length > 1 && !KeyCodes.isMultiselectKey(event);
    if (multiselected) {
      this.appEditor.replaceSelections([this.wid]);
    }
  }

  mousedown(event: any) {
    if (event.button !== 0) {
      return;
    }
    if (this.locked) {
      return;
    }

    let shouldClearSelections = false;

    // We need to propagate this event in order to alert CanvasEditor that it can handle panning and multiselect dragging
    // (so do not stopPropagation)

    // Handle case where widget other than multiselected widgets is mousedowned (do not group drag!):
    if (
      this.selections.length > 1 &&
      !KeyCodes.isMultiselectKey(event) &&
      !this.selected
    ) {
      this.appEditor.replaceSelections([this.wid]);
    }
    // Let multidrag handle this:
    if (this.selections.length > 1 && !KeyCodes.isMultiselectKey(event)) return;

    /**
     * This chunk causes the widget to be deselected if the shift key is being pressed.
     * This is a convention of design tools, and should be familiar to users.
     */
    if (this.selected && KeyCodes.isMultiselectKey(event)) {
      this.appEditor.removeSelection(this.wid);
      return;
    }

    if (!this.canSelect) {
      return;
    }

    if (!KeyCodes.isMultiselectKey(event)) {
      if (this.selections.length === 1) {
        if (this.selections[0] !== this.wid) {
          shouldClearSelections = true;
        } else {
          // TODO: Is this even needed?
          const wg = this.appEditor.widgetById(this.selections[0]);
          if (wg && wg.locked) {
            shouldClearSelections = true;
          }
        }
      }
    }

    if (shouldClearSelections) {
      this.appEditor.replaceSelections([]);
    }

    this.appEditor.addSelection(this.wid);

    // Allows user to immediately begin dragging a multiselection after adding a new widget to it
    if (this.selections.length > 1) return;

    this.handleTranslation(event);
  }

  onDoubleClick(e: MouseEvent) {
    if (this.locked) {
      return;
    }

    /**
     * The canvas editor has a dblclick listener too which
     * will exit a special editing context if the event fires.
     *
     * This dblclick handler will fire first, so we have the option
     * of ensuring the dblclick never reaches the listener on the
     * canvas editor.
     *
     * So the following statements will prevent the dblclick event
     * from bubbling up and exiting the edit context.
     */

    if (!this.isBaseEditingContext) {
      e.stopPropagation();
    }
    if (this.selected && this.type === "Text") {
      e.stopPropagation();
      this.appEditor.enterTextEditMode(this.wid);
    }

    if (this.selected && this.type === "Repeater") {
      e.stopPropagation();

      // Open up the connection in the left-hand Data panel, so user can drag nodes in.
      // Also must open sidebar and set to show Data panel, in case is not.
      const db = this.dataBindings.find(
        (db) => db.widgetId === this.wid && db.bindingType === "DataSetParent"
      );
      const connectionsStore = useConnectionsStore();
      const connection = connectionsStore.connections.find(
        (c) => c.uuid === db?.dataConnectionUuid
      );
      if (connection) {
        this.appEditor.activeContentMenu = "Data";
        useConnectionDataStore().openNewConnection(
          connection as DataConnection
        );
      }
      this.setRepeaterEditingContext(e, this.wid);
    }
  }

  setRepeaterEditingContext(e: MouseEvent, widgetId: string) {
    const widget = this.appEditor.widgetById(widgetId);
    if (!widget) return;

    const editingContext = computeRepeaterEditingContext(widget, e, this.scale);

    if (typeof editingContext === "undefined") return;

    this.appEditor.setEditingContext(editingContext);
    // Select the repeater so Dataset explorer shows up in righthand panel
    this.appEditor.replaceSelections([this.wid]);
  }
}
</script>

<style lang="postcss">
.txfrm {
  position: relative;
}

.txfrm--active {
  position: absolute;
  z-index: 5;
}

.resizer-controls {
  z-index: 2;
}

.txfrm--active .vdr-border {
  @apply border-gray-500;
}

.resizer-rotator,
.resizer-corner,
.resizer-x,
.resizer-y {
  @apply absolute 
    transform -translate-x-1/2 -translate-y-1/2
    bg-white
    border
    border-gray-500
    cursor-pointer
    hover:border-gray-700;
  border-width: 1px;
}

.resizer-rotator {
  @apply w-6 h-6 left-1/2 -top-24;
  border-width: 0;
  border-radius: 100%;
  background-image: url("../assets/rotate-cursor.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.resizer-corner {
  @apply w-2 h-2;
}

.resizer-x {
  @apply w-2 h-8;
  width: 6px;
}
.resizer-y {
  @apply w-8 h-2;
  height: 6px;
}

.resizer-border {
  outline-style: dashed;
  outline-width: 1px;
  outline-color: theme("colors.gray.500");
  opacity: 0;
}
.resizer-border-selected {
  opacity: 0.5;
  outline-style: solid;
}
.resizer-border-hover {
  opacity: 0.5;
}
</style>
