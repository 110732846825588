<template>
  <div class="overflow-y-scroll w-full flex flex-1" ref="recordContainer">
    <div v-if="!showDataEmpty" class="w-full">
      <!-- Tree-shaped data: -->
      <div v-if="store.connectionIsTree" class="text-gray-100">
        <TreeNode
          :node="store.connectionData"
          :depth="0"
          :shouldExpand="true"
          :showValue="true"
          :isDraggable="true"
        />
      </div>

      <!-- Collection-shaped data: -->
      <div v-else class="w-full">
        <div class="flex flex-col space-y-3 w-full">
          <div
            :key="node.uuid"
            v-for="node in store.dataRow"
            class="rounded bg-app-dark3 hover:bg-gray-700 py-1 px-2 w-full"
          >
            <div class="text-xs text-gray-300">
              {{ node.displayName }}
            </div>

            <DraggableNode
              :canDrag="true"
              :model="node"
              class="text-gray-100 font-semibold line-clamp-3 text-lg opacity-90 hover:opacity-100"
            >
              <component
                :is="node.component"
                :name="node.displayName"
                :uuid="node.uuid"
                :dataType="node.dataType"
                :value="{
                  value: node.value,
                  formattedValue: node.formattedValue,
                }"
                :node="node"
              />
            </DraggableNode>
          </div>
        </div>
      </div>
    </div>

    <!-- If there are no rows in the data or it is an empty tree: -->
    <DataEmpty v-else />
  </div>
</template>

<script lang="ts">
import { useConnectionDataStore } from "@/stores/connectionData";
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import DataEmpty from "@/components/menus/data/DataEmpty.vue";
import { NodeData } from "@/types/data";
import TreeNode from "@/components/data/connections/setup/schema/TreeNode.vue";

import DataNodeValue from "@/components/data/DataNodeValue.vue";
import DataNodeImageUrl from "@/components/data/DataNodeImageUrl.vue";
import DataNodeDateTime from "@/components/data/DataNodeDateTime.vue";
import DataNodeEmpty from "@/components/data/DataNodeEmpty.vue";
import DataNodeColor from "@/components/data/DataNodeColor.vue";
import DraggableNode from "@/components/data/DraggableNode.vue";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { extractErrorMessage } from "@/utils";

@Component({
  components: {
    Icon,
    DataEmpty,
    TreeNode,
    DataNodeColor,
    DataNodeDateTime,
    DataNodeEmpty,
    DataNodeImageUrl,
    DataNodeValue,
    DraggableNode,
  },
})
export default class RecordData extends Vue {
  get store() {
    return useConnectionDataStore();
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  // Ensure connection data is fetched even if user didn't click on connection item button to load it.
  mounted() {
    if (this.store.connectionData === null && !this.store.isLoadingData) {
      this.store
        .fetchConnectionData({
          connectionId: this.connectionEditor.connection?.uuid || "",
        })
        .catch((err) => {
          console.log("Error fetching data", err);
          this.store.errorLoadingData = extractErrorMessage(err);
        });
    }
  }

  get showDataEmpty() {
    return this.store.isEmpty && !this.store.isLoadingData;
  }

  isPhoto(node: NodeData) {
    return ["ImageUrl", "ImageUpload"].includes(node.dataType);
  }
}
</script>
