import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  TextStyleOptions,
  DefaultTextStyleOptions,
} from "@/components/widgets/TextStyleOptions";
import {
  ShadowOptions,
  DefaultShadowOptions,
} from "@/components/widgets/ShadowOptions";
import { makeTextContent, TextContent } from "@/text";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface TextOptions
  extends ComponentOptions,
    TransformOptions,
    ShadowOptions,
    TextStyleOptions,
    AnimationOptions {
  content: TextContent;
  fitText: boolean;
  // Used when TextComp is child of TextGroup
  name?: string;
  spaceBefore?: number;
}

const defaultContent = makeTextContent("Text");

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultTextStyleOptions,
  ...DefaultShadowOptions,
  ...getDefaultAnimationOptions(),
  content: defaultContent,
  fitText: false,
};

const CreateText = (options: Partial<TextOptions>) => {
  const wid = makeId();
  // Must start with empty object
  return Object.assign({ wid }, defaultOptions, options, {
    type: "Text",
    name: DEFAULT_LAYER_NAMES.Text,
  });
};

export default CreateText;
