import { isObjectLiteral } from "./isObjectLiteral";

/**
 *
 * @param value Any value you want to check
 */
export const isEmpty = (value: any): boolean => {
  if (typeof value === "undefined") {
    return true;
  }

  if (value === null) {
    return true;
  }

  if (
    typeof value === "string" &&
    (value.length === 0 || (value.length > 0 && value.trim() === ""))
  ) {
    return true;
  }

  if (isObjectLiteral(value)) {
    return Object.keys(value).length === 0;
  }

  if (Array.isArray(value)) {
    return value.every(isEmpty);
  }

  return false;
};
