import mitt, { Emitter } from "mitt";
import { SnapPair } from "./types/snapping";
import { AppListAction } from "./types";
import { DraggingInfo } from "./stores/dragDrop";

type Events = {
  WINDOW_RESIZE: undefined;
  APP_CHANGES_SAVED: undefined;
  CANVAS_BOX_UPDATE: undefined;
  COLOR_PICKER_MOUNTED: undefined;
  AWAITING_SERVER: boolean;
  SAVING_APP: boolean;
  APP_EDITOR_INITIALIZED: { appId: string };
  APP_SAVE_ERROR: boolean;
  SNAP_PAIRS: SnapPair[];
  CLICK_FROM_HEX_INPUT: boolean;
  UPDATE_FONT_SIZE: number;
  COMPLETE_CORNER_RESIZE: string;
  FONTS_LOADED: string;
  RESET_ARTBOARD: undefined;
  DATA_CONNECTION_CREATED: { uuid: string };
  DATA_CONNECTION_UPDATED: { uuid: string };
  DATA_CONNECTION_SYNCHRONIZED: { uuid: string };
  DATA_TOKEN_DROPPED: {
    widgetId: string;
    conditionId: string;
    draggingInfo: DraggingInfo;
    parentWidgetId: string;
  };
  DATA_TOKEN_ADDED: undefined;
  APPS_LIST_VIEW_VISIBLE: string;
  APPS_LIST_ACTION: { action: AppListAction; uuid: string };
  OPEN_DASHBOARD_APP_MODAL: {
    action: AppListAction;
    uuid: string;
    name?: string;
  };
  CLEAR_UNDO_STACK: undefined;
  CONDITION_CREATED: string;
  DUPLICATE_WIDGET_ACTION: undefined;
  PLAYBACK_BEGIN: undefined;
  PHOTO_DRAG_ENTER: {
    widgetId: string;
    cellIndex?: number;
  };
  PHOTO_DRAG_LEAVE: undefined;
  UNDO_FIRED: undefined;
  FIRE_SAVE: undefined;
  OPEN_DATA_PANEL: undefined;
  TURN_OFF_DROPDOWN: undefined;
  IGNORE_TEXT_WIDGET_LISTENERS: boolean;
};

export const EventBus: Emitter<Events> = mitt<Events>();
