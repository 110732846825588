<template>
  <div class="flex items-center">
    <input @input="emitInput" type="checkbox" :checked="value" />
    <span class="mx-2">{{ label }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class CheckboxInput extends Vue {
  @Prop(String) label: string;
  @Prop(Boolean) value: boolean;

  emitInput(ev: any) {
    this.$emit("input", ev.target.checked);
  }
}
</script>
