import VueRouter, { Route, RouteConfig } from "vue-router";
import appSettings from "./appSettings";
import { auth } from "./api/authService";

import Editor from "@/views/Editor.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import CreateApp from "@/views/Dashboard/CreateApp.vue";
import Apps from "@/views/Dashboard/Apps.vue";
import AppsList from "@/views/Dashboard/AppListViews/List.vue";
import AppsGrid from "@/views/Dashboard/AppListViews/Grid.vue";
import AppsExpanded from "@/views/Dashboard/AppListViews/Expanded.vue";

import ConnectionList from "@/views/Connection/ConnectionList.vue";

// BETA STUFF -------------
import Connections from "@/views/Connection/Connections.vue";
import ConnectionCreate from "@/views/Connection/ConnectionCreateNew.vue";
import ConnectionSourceChooser from "@/components/data/connections/setup/SourceChooser.vue";
import ConnectionSetupProvide from "@/components/data/connections/setup/SetupProvide.vue";
import ConnectionSetup from "@/components/data/connections/setup/Setup.vue";
import ConnectionSetupSchema from "@/components/data/connections/setup/SetupSchema.vue";
import ConnectionSetupSync from "@/components/data/connections/setup/SetupSync.vue";
import ConnectionSetupName from "@/components/data/connections/setup/SetupName.vue";
import ConnectionSetupReplace from "@/components/data/connections/setup/SetupReplace.vue";
import ConnectionSetupSelection from "@/components/data/connections/setup/SetupSelect.vue";
import ConnectionSetupComplete from "@/components/data/connections/setup/SetupComplete.vue";

import ConnectionSetupV2 from "@/components/data/connections/setup/SetupV2.vue";

import DataManager from "@/components/data/connections/manage/Manager.vue";
import DataView from "@/components/data/connections/manage/data/Data.vue";
import Reauthorize from "@/components/data/connections/manage/Reauthorize.vue";
import DataReplace from "@/components/data/connections/manage/replace/Replace.vue";
import DataSettings from "@/components/data/connections/manage/settings/Settings.vue";
import DataApi from "@/components/data/connections/manage/api/Api.vue";
import DataSettingsSync from "@/components/data/connections/manualSource/settings/views/Synchronization.vue";
import DataSettingsSchema from "@/components/data/connections/manualSource/settings/views/SchemaColumns.vue";
import DataSettingsName from "@/components/data/connections/manualSource/settings/views/Name.vue";
import DataSettingsDisconnect from "@/components/data/connections/manualSource/settings/views/Disconnect.vue";
import DataSettingsModeration from "@/components/data/connections/manualSource/settings/views/Moderation.vue";
import DataSettingsConfigure from "@/components/data/connections/manualSource/settings/views/Configure.vue";

import ChooseConnectionModal from "@/components/data/connections/manage/ChooseConnectionModal.vue";
import ChooseConnection from "@/components/data/connections/manage/ChooseConnection.vue";

import DataPreview from "@/components/data/connections/manage/PreviewData.vue";
import ScalarSelect from "@/components/data/connections/manage/data/views/ScalarSelect.vue";
import Remap from "@/components/data/connections/manage/replace/Remap.vue";

// Conditions UI ---------------------------
import ConditionSaved from "@/components/conditions/ConditionSaved.vue";
import ConditionEditor from "@/components/conditions/ConditionEditor.vue";

import TemplateBase from "@/views/Templates/Base.vue";
import TemplateList from "@/views/Templates/List.vue";
const TemplateCategories = () => import("@/views/Templates/Categories.vue");

import {
  ConnectionSourceRouteConfig,
  ConnectionSources,
  SetupStepId,
} from "./types/ConnectionSources";
import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  APP_EDITOR_ROUTE_PATH,
  PLACEHOLDER_CONNECTION_UUID,
} from "./constants";
import { useUserStore } from "./stores/user";

const NotFound = { template: "<div>We could not find that page.</div>" };

const preserveQueryParams = (
  to: Route,
  from: Route,
  next: (route?: any) => void
) => {
  // console.log("from query", from.query);

  /**
   * NOTE: This will cause an infinite loop if anyone navigates to this route in the following situation:
   * - From a route that lacks query.type, AND
   * - To a route that lacks query.type.
   *
   * This means that whenever we launch this modal, via "Add Data" or "⚡", we NEED to pass in this query param..
   */
  if (!to.query.type) {
    next({
      path: to.path,
      query: { ...to.query, ...from.query },
    });
  } else {
    next();
  }
  // return to;
  // Causes infinite loop
  // next(to);
};

const getSetupComponent = (setupStepId: SetupStepId) => {
  switch (setupStepId) {
    case "Complete":
      return ConnectionSetupComplete;
    case "Provide":
      return ConnectionSetupProvide;
    case "Sync":
      return ConnectionSetupSync;
    case "Name":
      return ConnectionSetupName;
    case "Selection":
      return ConnectionSetupSelection;
    case "Schema":
      return ConnectionSetupSchema;
    case "Replace":
      return ConnectionSetupReplace;
  }
};

const makeSetupRoute = (options: {
  source: ConnectionSourceRouteConfig;
  isReplacing: boolean;
}) => {
  const steps = options.source.setupSteps.slice();
  // NOTE: May want this for GoogleSheets also
  // HACK: Add Selection step so it works with scalar
  if (options.source.provisionMethod === "Url") {
    // console.log("hack to add selection");
    steps.push("Selection");
  }

  if (options.isReplacing) {
    steps.push("Replace");
  }

  return {
    path: options.source.name.toLowerCase(),
    component: ConnectionSetup,
    props: {
      sourceId: options.source.name,
      isReplacing: !!options.isReplacing,
    },
    children: steps.map((step, index) => {
      const props: any = {
        step: step,
        sourceId: options.source.name,
        isReplacing: !!options.isReplacing,
      };

      const route: any = {
        props: props,
        path: step.toLowerCase(),
        component: getSetupComponent(step),
      };
      if (index === 0) {
        route.alias = "";
      }
      return route;
    }),
  };
};

/**
 * Setup routes responsible for creating new shared data
 * collections from the app dashboard.
 *
 */
const dashboardConnectionSetupRoutes = (): RouteConfig[] => {
  return ConnectionSources.map((s) => {
    return makeSetupRoute({
      source: s,
      isReplacing: false,
    });
  });
};

/**
 * Setup routes responsible for creating new connections
 * bound to widgets (both scalar and collections).
 */
const editorConnectionSetupRoutes = (): RouteConfig[] => {
  return ConnectionSources.map((s) => {
    return makeSetupRoute({
      source: s,
      isReplacing: false,
    });
  });
};

/**
 * Routes responsible for editing data connections
 */
const connectionEditorRoutes = (
  options: { includeReplaceRoutes: boolean } = { includeReplaceRoutes: true }
): RouteConfig => {
  const routes: RouteConfig = {
    path: `:connectionUuid(${PLACEHOLDER_CONNECTION_UUID}|[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`,
    redirect: ":connectionUuid/data",
    component: DataManager,
    props: true,
    children: [
      {
        path: "data",
        // alias: "", // Cause parent to show this view by default...ahh but then switching doesn't work. Because we replace connectionUuid...
        // So use redirect instead
        component: DataView,
        props: true,
      },
      {
        path: "reauthorize",
        component: Reauthorize, // Not exposed yet
        props: true,
      },
      {
        path: "api",
        component: DataApi, // Not exposed yet
        props: true,
      },
      {
        path: "settings",
        redirect: "settings/name",
        component: DataSettings,
        props: true,
        children: [
          {
            path: "name",
            component: DataSettingsName,
            props: true,
          },
          {
            path: "sync",
            component: DataSettingsSync,
            props: true,
          },
          {
            path: "disconnect",
            component: DataSettingsDisconnect,
            props: true,
          },
          {
            path: "moderation",
            component: DataSettingsModeration,
            props: true,
          },
          {
            path: "schema",
            component: DataSettingsSchema,
            props: true,
          },
          {
            path: "configure",
            component: DataSettingsConfigure,
            props: true,
          },
        ],
      },
    ],
  };

  if (options.includeReplaceRoutes) {
    const replaceSetupRoutes: RouteConfig[] = ConnectionSources.map((s) => {
      return makeSetupRoute({
        source: s,
        isReplacing: true,
      });
    });

    const replaceRoutes = {
      name: "connections-replace",
      path: "replace",
      component: DataReplace,
      props: true,
      children: [
        {
          path: ":newConnectionUuid/preview",
          component: DataPreview,
          props: (route: any) => ({
            isReplacing: true,
            ...route.params,
          }),
        },
        {
          path: ":newConnectionUuid/remap",
          component: Remap,
          props: (route: any) => ({
            isReplacing: true,
            ...route.params,
          }),
        },
        {
          path: "choose",
          alias: "",
          component: ChooseConnection,
          props: (route: any) => ({
            isReplacing: true,
            ...route.params,
          }),
        },
        {
          name: "connection-replace-setup-v2",
          path: "v2/:providerSlug/:stepName",
          component: ConnectionSetupV2,
        },
        ...replaceSetupRoutes,
      ],
    };

    routes.children?.push(replaceRoutes as any);
  }

  return routes;
};

/**
 * Routes responsible for binding to data connections
 */
const createBindingRoutes: RouteConfig = {
  name: "new-editor-connection",
  path: "new",
  component: ChooseConnectionModal,
  props: true,
  children: [
    {
      name: "new-editor-connection-setup-v2",
      path: "v2/:providerSlug/:stepName",
      component: ConnectionSetupV2,
    },
    ...editorConnectionSetupRoutes(),
    {
      path: "choose",
      alias: "",
      component: ChooseConnection,
      beforeEnter: preserveQueryParams,
    },
    {
      path: ":connectionUuid/select",
      component: ScalarSelect,
      props: (route: any) => ({
        isNewBinding: true,
        ...route.params,
      }),
      beforeEnter: preserveQueryParams,
    },
    {
      path: ":connectionUuid/preview",
      component: DataPreview,
      props: true,
      beforeEnter: preserveQueryParams,
    },
  ],
};

const routes = [
  {
    name: "home",
    path: "/",
    redirect: "/apps",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      isDashboard: true,
    },
    // beforeEnter: handleOAuth,
    children: [
      {
        path: "create-app",
        name: "create-app",
        component: CreateApp,
      },
      {
        path: "apps",
        name: "Apps",
        redirect: "/apps/list",
        component: Apps,
        children: [
          {
            path: "list",
            component: AppsList,
          },
          {
            path: "grid",
            component: AppsGrid,
          },
          {
            path: "expanded",
            component: AppsExpanded,
          },
        ],
      },
      {
        name: "templates-categories",
        path: "templates/categories",
        component: TemplateCategories,
      },
      {
        name: "templates-list",
        path: "templates",
        props: (route: any) => ({
          category: route.query.categoryId,
        }),
        component: TemplateList,
        children: [
          {
            name: "templates-base",
            path: ":uuid",
            component: TemplateBase,
            children: [
              {
                name: "templates-detail",
                path: "detail",
                props: true,
                component: () => import("@/views/Templates/Detail.vue"),
              },
              {
                name: "templates-edit",
                path: "edit",
                props: true,
                component: () => import("@/views/Templates/Edit.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "connections",
        component: Connections,
        children: [
          connectionEditorRoutes({
            includeReplaceRoutes: false,
          }),
          {
            path: "list",
            alias: "",
            name: "connections-list",
            component: ConnectionList,
          },
          {
            props: true,
            path: "new",
            component: ConnectionCreate,
            name: "connections-new",
            children: [
              {
                alias: "",
                path: "choose",
                component: ConnectionSourceChooser,
              },
              {
                name: "connection-setup-v2",
                path: "v2/:providerSlug/:stepName",
                component: ConnectionSetupV2,
              },
              ...dashboardConnectionSetupRoutes(),
            ],
          },
        ],
      },
    ],
  },
  {
    name: "edit",
    path: `/${APP_EDITOR_ROUTE_PATH}/:id`,
    component: Editor,
    meta: {
      requiresAuth: true,
      isEditor: true,
    },
    children: [
      {
        name: "condition-confirm",
        path: "conditions/saved",
        component: ConditionSaved,
      },
      {
        name: "condition-edit",
        path: "conditions/:conditionUuid",
        component: ConditionEditor,
      },
      {
        path: APP_EDITOR_CONNECTION_ROUTE_PATH,
        component: Connections,
        children: [connectionEditorRoutes(), createBindingRoutes],
      },
    ],
  },
  {
    name: "signout",
    path: "/signout",
    component: {
      beforeRouteEnter(to: any, from: any, next: any) {
        auth.logout();
      },
    },
  },
  { path: "*", component: NotFound },
];

export const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/auth/callback.html") {
    auth.handleCallback();
    return;
  }

  if (to.path === "/auth/silent-renew.html") {
    auth.handleSilentRenew();
    return;
  }

  if (to.query.impersonate) {
    auth.impersonate(to.query.impersonate);
    router.replace({ query: { impersonate: undefined } });
  }

  if (to.query.delegate) {
    auth.delegate().finally(() => {
      router.replace({ query: { delegate: undefined } });
      next();
    });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    auth.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) {
        auth
          .getUser()
          .then((user) => {
            if (appSettings.environment.isProduction) {
              // TrackJS is added in index.html
              const TrackJS = (window as any).TrackJS;
              if (user !== null && typeof TrackJS !== "undefined")
                TrackJS.configure({ userId: user?.profile.email });

              const LogRocket = (window as any).LogRocket;
              if (user !== null && typeof LogRocket !== "undefined")
                LogRocket.identify(user.profile.email);
            }

            // Ensure user info is up to date
            const userStore = useUserStore();
            return userStore.updateUserInfo();
          })
          .finally(() => {
            next();
          });
      } else {
        if (to.fullPath.includes("impersonate")) router.replace("/signout");
        else auth.login(to.fullPath);
      }
    });
  } else {
    next();
  }
});
