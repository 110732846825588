<template>
  <div class="flex w-full justify-center">
    <div class="px-4 py-3 ml-2">
      <DimensionsEditor
        v-if="!selectionLocked"
        :showLockAspectToggle="showLockAspectToggle"
      />
      <div class="grid gap-1 grid-cols-2 grid-rows-1 w-58 ml-4 mt-1">
        <RotationEditor v-if="!selectionLocked && showRotationEditor" />
        <OpacityEditor v-if="!selectionLocked" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import DimensionsEditor from "@/components/editors/DimensionsEditor.vue";
import RotationEditor from "@/components/editors/RotationEditor.vue";
import OpacityEditor from "@/components/editors/OpacityEditor.vue";

@Component({
  components: {
    DimensionsEditor,
    RotationEditor,
    OpacityEditor,
  },
})
export default class BaseWidgetEditor extends Vue {
  @Prop(Boolean) selectionLocked: boolean;
  @Prop({ default: true }) showLockAspectToggle: boolean;
  @Prop({ default: true }) showRotationEditor: boolean;
}
</script>
