<template>
  <div
    class="relative border rounded w-4 h-4"
    :class="{
      'bg-app-green border-gray-900 ring-2 ring-offset-0 ring-app-green':
        checked,
      ' border-gray-500': !checked,
    }"
  >
    <svg
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2.5 h-2.5"
      :class="{ hidden: !checked }"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2484 0L10.9299 1.3006L5.61783 6.32237L4.07006 4.91339L2.69427 3.61278L0 6.1598L1.3758 7.4604L4.24204 10.17L5.56051 11.4706L6.93631 10.17L13.6242 3.84762L15 2.54701L12.2484 0Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CheckBox extends Vue {
  @Prop(Boolean) checked: boolean;
}
</script>
