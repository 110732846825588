<template>
  <div class="flex flex-col h-full">
    <div class="-mt-2 pb-2 border-b border-gray-800 shadow-lg">
      <slot />

      <div class="px-4 pt-3">
        <button
          @click="upload"
          class="w-full h-8 text-white bg-gradient-to-b from-app-orange to-app-red rounded-md text-sm font-semibold"
          type="button"
          v-t="'VideoMenu.uploadVideo'"
        ></button>
      </div>
    </div>

    <div class="relative flex-grow">
      <div class="p-6" v-if="showUserAssetsError && !isLoadingAssets">
        <div class="mb-3" v-t="'VideoMenu.errorLoadingUserAssets'"></div>
        <OutlineButton
          size="xs"
          class="text-xs"
          @click="loadUserAssets"
          v-t="'VideoMenu.retryLoadingVideos'"
        ></OutlineButton>
      </div>
      <div
        ref="savedAssets"
        class="absolute overflow-y-auto inset-0 p-4"
        v-if="showUserAssets"
      >
        <div
          class="flex justify-between items-center"
          v-show="!isLoadingAssets"
        >
          <label
            class="text text-sm"
            v-if="userAssets.length !== 0"
            v-t="'VideoMenu.savedVideos'"
          ></label>

          <OutlineButton
            size="xs"
            class="text-xs"
            @click="isEditMode = !isEditMode"
          >
            <span v-if="isEditMode" v-t="'done'"></span>
            <span v-else v-t="'edit'"></span>
          </OutlineButton>
        </div>

        <div class="grid grid-cols-2 gap-4 pt-3 pb-16">
          <div :key="a.uuid" v-for="a in userAssets" class="relative">
            <VideoPlaceholder
              :width="112"
              :height="Math.round((112 / a.width) * a.height)"
              :url="a.url"
              class="block text-white bg-gray-500 text-sm"
              @click.native="onClickVideo(a)"
              :class="{ 'cursor-pointer': !isEditMode }"
            />

            <div
              v-if="isEditMode"
              class="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center cursor-pointer"
              @click="handleRemoveClick(a)"
            >
              <Icon
                name="CloseCircle"
                style="border-radius: 100px"
                class="text-red-500 ring-1 ring-red-800 hover:text-red-600 shadow-md"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-6"
        v-if="isLoadingAssets"
        v-t="'VideoMenu.loadingAssets'"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import gsap from "gsap";
import { Component } from "vue-property-decorator";

import { SavedAsset } from "@/types";
import { useAssetStore } from "@/stores/assets";
import { useAppEditorStore } from "@/stores/appEditor";
import { uploadVideo } from "@/uploadAssets";

import Icon from "@/components/icons/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";
import PhotoDragging from "@/components/menus/PhotoDragging.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import FormInputError from "@/components/FormInputError.vue";
import VideoPlaceholder from "@/components/VideoPlaceholder.vue";

import { logger } from "@core/logger";
import { getScaleFactor, makeId } from "@/utils";

@Component({
  components: {
    ButtonIcon,
    Tooltip,
    Icon,
    OutlineButton,
    FormInputError,
    VideoPlaceholder,
  },
})
export default class VideosMenu extends PhotoDragging {
  isEditMode = false;

  isVertical = false;
  isHorizontal = false;
  isLoadingAssets = false;
  showUserAssetsError = false;

  get store() {
    return useAssetStore();
  }

  get userAssets() {
    return this.store.userVideos;
  }

  get showUserAssets() {
    return !this.showUserAssetsError;
  }

  async created() {
    this.loadUserAssets();
  }

  loadUserAssets() {
    this.isLoadingAssets = true;
    this.store
      .loadUserAssets()
      .then(() => {
        this.showUserAssetsError = false;
      })
      .catch((err) => {
        logger.track("Error loading user assets", err);
        this.showUserAssetsError = true;
      })
      .finally(() => {
        this.isLoadingAssets = false;
      });
  }

  beforeDestroy() {
    this.store.clearSearch();
  }

  onClickVideo(asset: SavedAsset) {
    console.log("onClickVideo");
    if (!this.isEditMode) {
      this.addAssetToCanvas(asset);
    }
  }

  addAssetToCanvas(asset: SavedAsset) {
    const appEditor = useAppEditorStore();
    let { height, width } = asset;

    const scale = Math.min(
      1,
      getScaleFactor(
        {
          w: appEditor.width,
          h: appEditor.height,
        },
        {
          w: width,
          h: height,
        }
      )
    );

    console.log("scale", scale);

    // We want x and y to be in the center of the canvas
    const w = width * scale;
    const h = height * scale;
    const x = appEditor.width / 2 - w / 2;
    const y = appEditor.height / 2 - h / 2;

    return appEditor.addVideoComponent({
      asset: asset,
      boundingBox: { x, y, w, h },
      newWidgetId: makeId(),
      parentWidgetId: this.editingContext?.parentId,
    });
  }

  upload() {
    if (this.store.loadingSearchResults) {
      return false;
    }
    return uploadVideo()
      .then((asset?: SavedAsset) => {
        if (typeof asset === "undefined") {
          // User cancelled upload
          return;
        }

        this.addAssetToCanvas(asset).then(() => {
          this.store.saveUserAsset(asset);
          this.$nextTick(this.animateToNewAsset);
        });
      })
      .catch((err) => {
        logger.error("Error uploading video", err);
      });
  }

  animateToNewAsset() {
    const tl = gsap.timeline();
    const div = this.$refs.savedAssets as HTMLDivElement;
    if (div) {
      const assets = Array.from(
        div.querySelectorAll("video")
      ) as HTMLVideoElement[];

      const lastImage = assets.at(-1) as HTMLVideoElement;

      gsap.set(lastImage, { opacity: 0, scale: 0.1 });
      tl.to(div, {
        scrollTop: div.scrollHeight,
        duration: 1,
        ease: "power2.out",
      });

      tl.to(lastImage, {
        delay: 0.1,
        scale: 1,
        opacity: 1,
        duration: 0.25,
        ease: "back.out(2)",
      });
    }
  }

  async handleRemoveClick(asset: SavedAsset) {
    this.store.removeUserAsset(asset.uuid);
  }
}
</script>

<style scoped>
input[type="search"]::-webkit-search-cancel-button {
  visibility: hidden;
}

.search-box {
  color: black !important;
}

#dragged-ghost {
  pointer-events: none;
}

.under-fixed {
  margin-top: 125px;
}
</style>
