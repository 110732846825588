<template>
  <RecycleScroller
    ref="scroller"
    class="h-full flex-grow"
    :items="rows"
    :item-size="rowHeight"
    key-field="id"
    v-slot="{ item: row, index }"
    itemClass="ml-8 mt-6"
    listClass="!overflow-visible"
    @visible="onVisible"
    @resize="handleResize"
  >
    <div class="flex justify-start space-x-4 h-[200px] pr-4">
      <div
        v-for="(app, idx) in row.arr"
        :key="idx"
        :style="{
          width: 'calc(90% /' + itemsPerRow + ')',
          'max-height': '200px',
        }"
      >
        <router-link
          :to="{ name: 'create-app' }"
          v-if="app.isNewAppButton"
          class="self-start flex items-center justify-center rounded-[8px] border border-gray-400 h-[160px] cursor-pointer text-sm flex-col space-y-2 hover:bg-gray-700 hover:border-gray-100"
        >
          <Icon class="w-6 h-6" name="PlusCircle" />
          <div>New App</div>
        </router-link>
        <GridItem
          v-else
          :isSelected="index === selectedIndex"
          :name="app.name"
          :uuid="app.uuid"
          :width="app.width"
          :height="app.height"
          :image="app.previewUrl"
          :isPublished="app.publishMeta.isPublished"
          :forceUpdateDropdown="forceUpdateDropdown"
        />
      </div>
    </div>
  </RecycleScroller>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { RecycleScroller } from "vue-virtual-scroller";
import Icon from "@/components/icons/Icon.vue";
import IconButton from "@/views/Dashboard/IconButton.vue";
import { EventBus } from "@/eventbus";
import GridItem from "./GridItem.vue";
import { useAppsStore } from "@/stores/apps";
import { makeId } from "@/utils";
import { AppListAction } from "@/types";

@Component({
  components: {
    RecycleScroller,
    GridItem,
    Icon,
    IconButton,
  },
})
export default class DashboardAppsListGridView extends Vue {
  forceUpdateDropdown = 0;

  get store() {
    return useAppsStore();
  }

  get items() {
    const newAppButtonInfo = {
      uuid: "dummyNewApp",
      publishMeta: {},
      isNewAppButton: true,
    };
    return [newAppButtonInfo, ...this.store.matchingApps];
  }

  get rows() {
    const newAppButtonInfo = {
      uuid: "dummyNewApp",
      publishMeta: {},
      isNewAppButton: true,
    };
    return this.chunk(
      [newAppButtonInfo, ...this.store.matchingApps],
      this.itemsPerRow
    );
  }

  doAction(action: AppListAction, uuid: string) {
    EventBus.emit("APPS_LIST_ACTION", { action, uuid });
  }

  get selectedIndex() {
    return this.store.selectedIndex;
  }

  itemsPerRow = 5;

  readonly rowHeight = 180;
  itemWidth = 215;

  highlightedRow = -1;

  chunk(arr: any[], size: number) {
    return arr
      .reduce((acc, e, i) => {
        if (i % size === 0) {
          acc.push(arr.slice(i, i + size));
        }
        return acc;
      }, [])
      .map((arr: any[]) => ({ id: makeId(5), arr }));
  }

  onVisible() {
    /**
     * We notify the parent that the list is visible so that it can
     * set up a scroll listener
     */
    EventBus.emit("APPS_LIST_VIEW_VISIBLE", "");
  }

  handleResize() {
    const el = this.$el as HTMLDivElement;

    this.forceUpdateDropdown = this.forceUpdateDropdown === 0.1 ? 0 : 0.1;
    const padding = 60;

    const width = el.clientWidth - (isNaN(padding) ? 0 : padding);
    this.itemsPerRow = Math.floor(width / this.itemWidth);
  }

  handleScroll() {
    this.forceUpdateDropdown = this.forceUpdateDropdown === 1 ? 0 : 1;
    EventBus.emit("TURN_OFF_DROPDOWN");
  }

  mounted() {
    this.handleResize();
    (this.$el as any).addEventListener("scroll", this.handleScroll);
  }

  beforeDestroy() {
    (this.$el as any).removeEventListener("scroll", this.handleScroll);
  }

  @Watch("selectedIndex")
  onSelectedIndexChanged(newIndex: number) {
    const row = newIndex / this.itemsPerRow;
    this.highlightedRow = Math.floor(row);
  }

  @Watch("highlightedRow")
  scrollTo(newRow: number, oldRow: number | undefined) {
    const old = typeof oldRow === "undefined" ? -1 : oldRow;
    const direction = newRow > old ? "down" : "up";

    this.$nextTick(() => {
      const scroller = this.$refs.scroller as any;
      const scrollAfter = 2;
      if (newRow > scrollAfter) {
        newRow -= scrollAfter;
        scroller.scrollToPosition(newRow * this.rowHeight);
      }

      if (direction === "up" && newRow < scrollAfter + 1) {
        scroller.scrollToPosition(0);
      }
    });
  }
}
</script>
