<template>
  <div class="relative flex flex-col flex-grow">
    <div class="flex flex-col flex-grow">
      <SelectTreeValue
        v-if="readOnlySchemaType === 'Tree'"
        :readonly="true"
        :rootNode="readOnlyTreeData"
      />
      <SelectTableValue
        v-if="readOnlySchemaType === 'Tabular'"
        :readonly="true"
        :showHeaders="true"
        :data="readOnlyTableData"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SelectTreeValue from "@/components/data/connections/setup/selection/SelectTreeValue.vue";
import SelectTableValue from "@/components/data/connections/setup/selection/SelectTableValue.vue";
import { useConnectionDataStore } from "@/stores/connectionData";

@Component({
  components: {
    SelectTreeValue,
    SelectTableValue,
  },
})
export default class ScalarSelect extends Vue {
  get store() {
    return useConnectionDataStore();
  }

  get readOnlyTableData() {
    return this.store.readOnlyTableData;
  }

  get readOnlyTreeData() {
    return this.store.readOnlyTreeData;
  }

  get readOnlySchemaType() {
    return this.store.readOnlySchemaType;
  }
}
</script>
