<template>
  <div class="relative">
    <form @submit.prevent="onContinue">
      <div class="text-xl mb-2" v-t="'SetupName.enterConnectionName'"></div>
      <input
        ref="nameInput"
        type="text"
        class="px-2 py-1 w-128 flex-grow rounded-sm border text-gray-900 dark-form-focus"
        v-model.trim="name"
      />
    </form>

    <div v-if="creationError" class="mt-8 text-xl italic text-red-500">
      There was an error creating your connection: {{ creationError }}
    </div>

    <portal to="setupWizardUiBlocker">
      <UiBlocker :visible="saving">
        <div
          class="mt-4 text-xl text-white"
          v-t="'SetupName.savingConnection'"
        ></div>
      </UiBlocker>
    </portal>

    <portal to="setupWizardNext">
      <FormButton @click="onContinue" v-t="'continue'"></FormButton>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { useConnectionSetupStore } from "@/stores/connectionSetup";
import FormButton from "@/components/FormButton.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import TabInput from "@/components/inputs/TabInput.vue";
import { APP_EDITOR_ROUTE_PATH } from "@/constants";

@Component({
  components: { FormButton, UiBlocker, TabInput },
})
export default class SetupName extends Vue {
  name = "";
  inputError = "";
  saving = false;

  creationError = "";

  created() {
    this.name = this.store.name ?? this.suggestedConnectionName;
    this.store.step = "Name";
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.nameInput as HTMLInputElement).focus();
    });
  }

  get suggestedConnectionName() {
    let result = "";

    if (!this.store.isCollection) {
      return result;
    }

    const defaultDocumentNames = ["Spreadsheet", "ClipboardData.csv"];
    const isDefaultDocumentName =
      this.store.sourceName &&
      defaultDocumentNames.includes(this.store.sourceName);

    if (!isDefaultDocumentName) {
      result = this.store.sourceName ?? result;
    }

    if (this.store.schemaType === "Tabular" && !this.store.canEditData) {
      const sheetTitle = this.store.selectedSheetName;
      const isDefaultSheetName = sheetTitle === "Table1";
      if (!isDefaultDocumentName && !isDefaultSheetName) {
        result = `${this.store.sourceName} - ${sheetTitle}`;
      }
    }
    return result;
  }
  get store() {
    return useConnectionSetupStore();
  }

  get isFromAppEditor() {
    return this.$route.path.includes(APP_EDITOR_ROUTE_PATH);
  }

  onContinue() {
    this.inputError = "";
    this.creationError = "";
    if (this.name.length === 0) {
      this.inputError = this.$t("SetupName.nameIsRequired").toString();
      return;
    }

    this.saving = true;
    this.store
      .defineName(this.name)
      .then((routeParams) => {
        const c = routeParams.connectionUuid;
        this.$router.push({
          path: routeParams.step.toLowerCase(),
          query: { ...this.$route.query, c: c },
        });
      })
      .catch((e) => {
        this.creationError = e[0]?.message;
      })
      .finally(() => {
        this.saving = false;
      });
  }
}
</script>
