<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import CountdownComponent from "@/components/widgets/Countdown/CountdownComponent.vue";

@Component
export default class CountdownWrapper extends CountdownComponent {
  get options() {
    return {
      time_fontSize: this.time_fontSize,
      time_textTransform: this.time_textTransform,
      time_fontStyle: this.time_fontStyle,
      time_fontFamily: this.time_fontFamily,
      time_fontWeight: this.time_fontWeight,
      label_fontSize: this.label_fontSize,
      label_textTransform: this.label_textTransform,
      label_fontStyle: this.label_fontStyle,
      label_fontFamily: this.label_fontFamily,
      label_fontWeight: this.label_fontWeight,
      showYears: this.showYears,
      showMonths: this.showMonths,
      showWeeks: this.showWeeks,
      showDays: this.showDays,
      showHours: this.showHours,
      showMinutes: this.showMinutes,
      showSeconds: this.showSeconds,
      pastAllow: this.pastAllowed,
    };
  }

  @Watch("options")
  onTextOptionsChanged() {
    this.$nextTick(this.countdown);
  }
}
</script>
