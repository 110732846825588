"use strict";
var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

import * as _pointFinder from "./point-finder";

/**
 * Perform Scaling based on a positioned handle
 *
 * @param {string} scaleType scale point position name
 * @param {Object} payload an object holding element information
 * @param {number} payload.startX mouse down position on X axis
 * @param {number} payload.startY mouse down position on Y axis
 * @param {number} payload.x position of x
 * @param {number} payload.y position of y
 * @param {number} payload.scaleX amount of scale for x (width)
 * @param {number} payload.scaleY amount of scale for y (height)
 * @param {number} payload.canvasScale scale of canvas
 * @param {number} payload.width original width
 * @param {number} payload.height original height
 * @param {number} payload.angle the angle of rotation
 * @param {number} payload.scaleLimit minimum scale limit
 * @param {boolean} payload.scaleFromCenter is scale from center
 * @param {boolean} payload.aspectRatio is scale on aspect ration
 * @param {boolean} payload.lockAspect whether aspect ratio is locked on widget
 * @param {Function} onUpdate a callback on mouse up
 */
export default function (scaleType, _ref, onUpdate) {
  var startX = _ref.startX,
    startY = _ref.startY,
    x = _ref.x,
    y = _ref.y,
    scaleX = _ref.scaleX,
    scaleY = _ref.scaleY,
    scl = _ref.canvasScale,
    width = _ref.width,
    height = _ref.height,
    angle = _ref.angle,
    scaleLimit = _ref.scaleLimit,
    _ref$scaleFromCenter = _ref.scaleFromCenter,
    scaleFromCenter =
      _ref$scaleFromCenter === undefined ? false : _ref$scaleFromCenter,
    _ref$enableScaleFromC = _ref.enableScaleFromCenter,
    enableScaleFromCenter =
      _ref$enableScaleFromC === undefined ? true : _ref$enableScaleFromC,
    _ref$aspectRatio = _ref.aspectRatio,
    aspectRatio = _ref$aspectRatio === undefined ? false : _ref$aspectRatio,
    _ref$enableAspectRati = _ref.enableAspectRatio,
    enableAspectRatio =
      _ref$enableAspectRati === undefined ? true : _ref$enableAspectRati,
    lockAspect = _ref.lockAspect;

  var ratio = (width * scaleX) / (height * scaleY);

  var point = (0, _pointFinder.getPoint)(scaleType, {
    x: x,
    y: y,
    scaleX: scaleX,
    scaleY: scaleY,
    width: width,
    height: height,
    angle: angle,
    scaleFromCenter: scaleFromCenter,
  });

  var oppositePoint = (0, _pointFinder.getOppositePoint)(scaleType, {
    x: x,
    y: y,
    scaleX: scaleX,
    scaleY: scaleY,
    width: width,
    height: height,
    angle: angle,
  });

  var currentProps = {
    x: x,
    y: y,
    scaleX: scaleX,
    scaleY: scaleY,
  };

  return function (event) {
    if (
      enableScaleFromCenter &&
      ((event.altKey && !scaleFromCenter) || (!event.altKey && scaleFromCenter))
    ) {
      startX = event.pageX;
      startY = event.pageY;

      scaleFromCenter = event.altKey && !scaleFromCenter;

      point = (0, _pointFinder.getPoint)(
        scaleType,
        _extends({}, currentProps, {
          width: width,
          height: height,
          angle: angle,
          scaleFromCenter: scaleFromCenter,
        })
      );

      oppositePoint = (0, _pointFinder.getOppositePoint)(
        scaleType,
        _extends({}, currentProps, {
          width: width,
          height: height,
          angle: angle,
        })
      );
    }

    if (!event.shiftKey && aspectRatio) {
      aspectRatio = false;
    } else if (event.shiftKey && !aspectRatio) {
      aspectRatio = true;
    }

    if (!enableAspectRatio) {
      aspectRatio = false;
    }
    // new
    if (lockAspect) {
      aspectRatio = true;
    }
    // newer and somewhat hacky (shouldn't be here...)
    // Hmm....seems to fail if we have resized the wg......
    // yeah wwhy not just override lockAspect property?
    // const isCornerHandle = !scaleType.includes('m');
    // if (widgetType === 'Text' && isCornerHandle) {
    //   aspectRatio = true;
    // }

    var moveDiff = {
      x: (event.pageX - startX) / scl,
      y: (event.pageY - startY) / scl,
    };

    var movePoint = (0, _pointFinder.getMovePoint)(
      scaleType,
      oppositePoint,
      point,
      moveDiff
    );

    // console.log("move", movePoint);

    if (enableScaleFromCenter && scaleFromCenter) {
      movePoint.x *= 2;
      movePoint.y *= 2;
    }

    var _getSineCosine = (0, _pointFinder.getSineCosine)(scaleType, angle),
      sin = _getSineCosine.sin,
      cos = _getSineCosine.cos;

    var rotationPoint = {
      x: movePoint.x * cos + movePoint.y * sin,
      y: movePoint.y * cos - movePoint.x * sin,
    };

    currentProps.scaleX =
      rotationPoint.x / width > scaleLimit
        ? rotationPoint.x / width
        : scaleLimit;
    currentProps.scaleY =
      rotationPoint.y / height > scaleLimit
        ? rotationPoint.y / height
        : scaleLimit;

    switch (scaleType) {
      case "ml":
      case "mr":
        currentProps.scaleY = scaleY;
        if (aspectRatio) {
          currentProps.scaleY =
            (width * currentProps.scaleX * (1 / ratio)) / height;
        }
        break;
      case "tm":
      case "bm":
        currentProps.scaleX = scaleX;
        if (aspectRatio) {
          currentProps.scaleX = (height * currentProps.scaleY * ratio) / width;
        }
        break;
      default:
        if (aspectRatio) {
          currentProps.scaleY =
            (width * currentProps.scaleX * (1 / ratio)) / height;
        }
    }

    if (enableScaleFromCenter && scaleFromCenter) {
      var center = (0, _pointFinder.getCenter)({
        x: x,
        y: y,
        width: width,
        height: height,
        scaleX: currentProps.scaleX,
        scaleY: currentProps.scaleY,
      });
      currentProps.x = x + (point.x - center.x);
      currentProps.y = y + (point.y - center.y);
    } else {
      var freshOppositePoint = (0, _pointFinder.getOppositePoint)(scaleType, {
        width: width,
        height: height,
        angle: angle,
        x: x,
        y: y,
        scaleX: currentProps.scaleX,
        scaleY: currentProps.scaleY,
      });

      currentProps.x = x + (oppositePoint.x - freshOppositePoint.x);
      currentProps.y = y + (oppositePoint.y - freshOppositePoint.y);
    }

    // Ok....top corners do NOT change y, and left corners do NOT change x.
    // bottom and right ones DO. and scales always change.

    onUpdate(currentProps);
  };
}
