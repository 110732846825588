import { TableSchema, NodeSetData, SchemaNode } from "./data";

export class GoogleAppType {
  static Sheets = "Sheets";
  static Calendar = "Calendar";
}

export interface GoogleAppClientModel {
  oAuthEndpoint: string;
  clientId: string;
  redirectUri: string;
  responseType: string;
  accessType: string;
  scope: string;
  includeGrantedScopes: string;
  state: string;
}

// SHEETS ////////////////////////////////////////////////////////

export interface GoogleSheetsResponse {
  connectionUuid: string;
  providerAuthId: string;
  spreadsheetName: string;
  sheets: TableSchema[];
}

export interface GoogleSheetListItem {
  id: number;
  title: string;
  rangeId: string;
}

// CALENDAR

export class GoogleCalendarAccessRole {
  // defaults to owner
  static owner = "owner";
  static reader = "reader";
}

export class GoogleCalendarEventOrderBy {
  // defaults to null = unspecified
  static startTime = "startTime";
  static updated = "updated";
}

export interface GoogleCalendar {
  id: string;
  description: string;
  location: string;
  summary: string;
  backgroundColor: string;
  foregroundColor: string;
  isSelected: boolean;
}

export interface CalendarEventInfo {
  Summary: string;
  Start: string;
  End: string;
  CreatorEmail?: string;
  Location?: string;
  Description?: string;
}
export interface GoogleCalendarList {
  connectionUuid: string;
  providerAuthId: string;
  nextPageToken: string;
  calendars: GoogleCalendar[];
  eventRefModels: NodeSetData[];
}

export interface GoogleCalendarSchemaDto {
  connectionUuid: string;
  schema: SchemaNode[];
}

export interface GoogleCalendarFilters {
  resultsPerPage: number | null;
  nextPageToken: string | null;
}

export interface GoogleCalendarEventFilters {
  calendarId: string;
  searchQuery: string | null;
  startDate: Date | null;
  endDate: Date | null;
  resultsPerPage: number | null;
  orderBy: GoogleCalendarEventOrderBy | null;
  nextPageToken: string | null;
}
