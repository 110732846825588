import Vue from "vue";
import VueI18n from "vue-i18n";
import { createI18n } from "vue-i18n-composable";
import dateTimeFormats from "@/locales/dateTimeFormats";
import messages from "./locales/en.json";
import appSettings from "./appSettings";

Vue.use(VueI18n);

const options = {
  locale: appSettings.i18n.locale,
  fallbackLocale: appSettings.i18n.fallbackLocale,
  messages: { en: messages },
  dateTimeFormats,
};

export default createI18n(options);
