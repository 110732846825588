<template>
  <div class="flex flex-col min-h-0 flex-grow">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Replace extends Vue {}
</script>
