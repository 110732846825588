<template>
  <div class="px-4">
    <slot />
    Background
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { makeId, range } from "@/utils";
@Component
export default class BackgroundMenu extends Vue {}
</script>

<style scoped></style>
