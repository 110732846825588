import { Node } from "@tiptap/core";
import { DataToken } from "@/text/DataToken";

const Document = Node.create({
  name: "doc",
  topNode: true,
  content: "block+",
});

const Paragraph = Node.create({
  name: "paragraph",
  group: "block",
  content: "inline*",
  parseHTML() {
    return [{ tag: "p" }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["p", HTMLAttributes, 0];
  },
});

const Text = Node.create({
  name: "text",
  group: "inline",
});

export const contentSchema = [Document, Paragraph, Text, DataToken];
