import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface CalendarEventOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  dataIndex: number;
  data?: any[];
  // previewLiveData: boolean;

  showEventLocation: boolean;
  showEventCreator: boolean;
  showEventDescription: boolean;
  showDivider: boolean;
  use24Hour: boolean;
  dividerColor: string;
  syncFonts: boolean;
  syncTextColors: boolean;

  eventTitle_fontFamily: string | null;
  eventTitle_textColor: string | null;
  eventTitle_fontSize: number;
  eventTitle_fontWeight: number;
  eventTitle_letterSpacing: number;
  eventTitle_lineHeight: number;
  eventTitle_textAlign: string;
  eventTitle_fontStyle: string; // "normal" | "italic"
  eventTitle_textTransform: string;
  eventTitle_textDecoration: string; // "none" | "strikethrough" | "underline"
  // Technically one could do both underline and strike.....do we care about that case?

  eventTime_fontFamily: string | null;
  eventTime_textColor: string | null;
  eventTime_fontSize: number;
  eventTime_fontWeight: number;
  eventTime_letterSpacing: number;
  eventTime_lineHeight: number;
  eventTime_textAlign: string;
  eventTime_fontStyle: string;
  eventTime_textTransform: string;
  eventTime_textDecoration: string;

  eventLocation_fontFamily: string | null;
  eventLocation_textColor: string | null;
  eventLocation_fontSize: number;
  eventLocation_fontWeight: number;
  eventLocation_letterSpacing: number;
  eventLocation_lineHeight: number;
  eventLocation_textAlign: string;
  eventLocation_fontStyle: string;
  eventLocation_textTransform: string;
  eventLocation_textDecoration: string;

  eventCreator_fontFamily: string | null;
  eventCreator_textColor: string | null;
  eventCreator_fontSize: number;
  eventCreator_fontWeight: number;
  eventCreator_letterSpacing: number;
  eventCreator_lineHeight: number;
  eventCreator_textAlign: string;
  eventCreator_fontStyle: string;
  eventCreator_textTransform: string;
  eventCreator_textDecoration: string;

  eventDescription_fontFamily: string | null;
  eventDescription_textColor: string | null;
  eventDescription_fontSize: number;
  eventDescription_fontWeight: number;
  eventDescription_letterSpacing: number;
  eventDescription_lineHeight: number;
  eventDescription_textAlign: string;
  eventDescription_fontStyle: string;
  eventDescription_textTransform: string;
  eventDescription_textDecoration: string;
}

const mainColor = "rgba(52,66,86,100)";
const accentColor = "rgba(244,62,92,100)";

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  borderRadius: 0,
  dataIndex: 0,
  // previewLiveData: false,
  showEventLocation: true,
  showEventCreator: true,
  showDivider: true,
  showEventDescription: true,
  use24Hour: false,
  dividerColor: accentColor,
  syncFonts: false,
  syncTextColors: false,
};

export const defaultTextOptions: any = {
  eventTitle_fontSize: 180,
  eventTitle_fontWeight: 700,
  eventTitle_textColor: mainColor,
  eventTime_fontSize: 120,
  eventTime_fontWeight: 400,
  eventTime_textColor: accentColor,
  eventLocation_fontSize: 84,
  eventLocation_fontWeight: 700,
  eventLocation_textColor: mainColor,
  eventDescription_fontSize: 60,
  eventDescription_fontWeight: 400,
  eventDescription_textColor: mainColor,
  eventCreator_fontSize: 60,
  eventCreator_fontWeight: 700,
  eventCreator_textColor: mainColor,
};

// Add uniform defaults to all text fields:
["Title", "Location", "Time", "Description", "Creator"].forEach((prop) => {
  defaultTextOptions[`event${prop}_fontFamily`] = "Montserrat";
  defaultTextOptions[`event${prop}_textTransform`] = "none";
  defaultTextOptions[`event${prop}_textDecoration`] = "none";
  defaultTextOptions[`event${prop}_fontStyle`] = "normal";
  defaultTextOptions[`event${prop}_lineHeight`] = 1.2;
  defaultTextOptions[`event${prop}_letterSpacing`] = 0;
  defaultTextOptions[`event${prop}_textAlign`] = "left";
});

const CreateCalendarEvent = (options: Partial<CalendarEventOptions>) => {
  return Object.assign({}, defaultOptions, defaultTextOptions, options, {
    wid: makeId(),
    type: "CalendarEvent",
    name: DEFAULT_LAYER_NAMES.CalendarEvent,
  });
};

export default CreateCalendarEvent;
