import Url from "@uppy/url/lib/index";
import CustomRequestClient from "../CustomRequestClient";

class CustomUrl extends Url {
  constructor(uppy, opts) {
    super(uppy, opts);

    // Extending Url plugin so we can override the RequestClient
    // in order to get the authorization header to work
    super.client = new CustomRequestClient(uppy, {
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionCookiesRule: this.opts.companionCookiesRule,
    });
  }
}

export default CustomUrl;
