<template>
  <input
    :id="makeKeyId(rowUuid, node.uuid || '')"
    :type="type"
    :value="model"
    @input="inputHandler"
    @keypress.enter="inputHandler"
    @click.stop
  />
</template>

<script lang="ts">
import { EMPTY_ROW_UUID } from "@/stores/editableData";
import { Component } from "vue-property-decorator";
import InputBase from "@/components/data/connections/manualSource/inputs/InputBase.vue";
import { DateTime } from "luxon";

@Component({
  components: {},
})
export default class DatetimeInput extends InputBase {
  get fromEmptyRow() {
    return this.rowUuid === EMPTY_ROW_UUID;
  }

  get type() {
    switch (this.inputType) {
      case "Time":
        return "time";
      case "Date":
        return "date";
      default:
        return "datetime-local";
    }
  }

  get model() {
    switch (this.inputType) {
      case "Time":
        return this.time;
      case "Date":
        return this.date;
      default:
        const x = this.datetime.toISO();
        // Get rid of final characters of "ISO" string, which prevent "datetime-local" input from working
        return x.slice(0, x.length - 10);
    }
  }

  inputHandler(ev: any) {
    const val: string = ev.target.value;
    let value = new Date(val);

    switch (this.inputType) {
      case "Time":
        const time = DateTime.fromFormat(val, "HH:mm");
        const hour = time.get("hour");
        const minute = time.get("minute");
        const newTime = this.datetime.set({ hour, minute });
        value = newTime.toJSDate();
        break;
      case "Date":
        const dt = DateTime.fromFormat(val, "yyyy-MM-dd");
        const day = dt.get("day");
        const month = dt.get("month");
        const year = dt.get("year");
        const newDt = this.datetime.set({ day, month, year });
        value = newDt.toJSDate();
        break;
    }

    this.handleInput({ target: { value } });
  }

  /**
   * Handle JS differently (as stored in placeholder data) from dates as stored on back-end
   */
  get datetime() {
    if (DateTime.fromJSDate(this.value).invalidReason) {
      const date = this.value ? new Date(this.value) : new Date();

      return DateTime.fromJSDate(date);
    }

    return DateTime.fromJSDate(this.value);
  }

  get date() {
    return this.datetime.toISODate();
  }

  get time() {
    return this.datetime.toLocaleString(DateTime.TIME_24_SIMPLE);
  }
}
</script>
