<template>
  <div class="divide-y">
    <CollapsePanel v-if="showDataPanel">
      <template slot="title">
        <div>Data</div>
      </template>
      <div>
        <DataColumnChooser
          label="imageEditor.contentDataColumn"
          dataProperty="url"
          :dataParentUuid="dataParentUuid"
        />
      </div>
    </CollapsePanel>
    <CollapsePanel v-if="showAnimationPanel">
      <template slot="title">
        <div v-t="'animation.animation'"></div>
      </template>
      <AnimationEditor />
    </CollapsePanel>

    <div>
      <div class="px-4 py-3">
        <div
          v-t="'backgroundSize'"
          class="mb-2 ml-2 text-sm text-gray-500"
        ></div>
        <EditorSelectMenu
          :value="backgroundSize"
          :options="backgroundSizeOptions"
          @input="apply({ backgroundSize: $event })"
        />
      </div>
      <BaseWidgetEditor :selectionLocked="selectedWidget?.locked ?? false" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import FormLabel from "@/components/FormLabel.vue";

import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import DataColumnChooser from "@/components/widgets/DataColumnChooser.vue";
import AnimationEditor from "@/components/editors/AnimationEditor.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { animationAllowed } from "@/components/widgets/animationAllowed";
@Component({
  components: {
    CollapsePanel,
    EditorSelectMenu,
    BaseWidgetEditor,
    DataColumnChooser,
    FormLabel,
    AnimationEditor,
  },
})
export default class ImageEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  apply(props: any) {
    if (this.selectedWidget !== undefined) {
      this.appEditor.setWidgetProps([this.selectedWidget?.wid], props);
    }
  }

  get dataParentUuid() {
    if (this.selectedWidget) {
      const bindings = this.appEditor.bindingsForComponent({
        widgetId: this.selectedWidget.parentId,
      });
      if (bindings.length > 0) {
        return bindings[0].dataUuid;
      }
    }
    return undefined;
  }

  get showAnimationPanel() {
    return animationAllowed(this.selectedWidget);
  }

  get showDataPanel() {
    return typeof this.dataParentUuid === "string";
  }

  get backgroundSize() {
    return (this.selectedWidget as any).backgroundSize;
  }
  //TODO: Translation
  get backgroundSizeOptions() {
    return [
      { label: "Fit", value: "contain" },
      { label: "Fill", value: "cover" },
    ];
  }
}
</script>
