import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import { BackgroundOptions } from "../BackgroundOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";

export interface ImageOptions
  extends ComponentOptions,
    TransformOptions,
    BackgroundOptions,
    AnimationOptions,
    BorderOptions {
  datasets: any[] | null;
  url: string;
  name: string;
  uuid: string;
  // backgroundSize: string;
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...getDefaultAnimationOptions(),
  datasets: [],
  backgroundSize: "contain",
};

const CreateImage = (options: Partial<ImageOptions>) => {
  const wid = makeId();
  return Object.assign({ wid }, defaultOptions, options, { type: "Image" });
};

export default CreateImage;
