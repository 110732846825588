<template>
  <div
    :style="{ cursor: canDrag ? 'grab' : '' }"
    :class="{
      'hover:-translate-x-[1px] hover:-translate-y-[1px] transition-all':
        canDrag && (!dragDropStore.isDraggingNode || isMouseWithinNode),
    }"
    @mousedown="onMouseDown"
    @mouseenter="isMouseWithinNode = true"
    @mouseleave="isMouseWithinNode = false"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import gsap from "gsap";
import { Point } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CANVAS_ID } from "@/constants";
import { useDragDropStore, DataWidgetInfo } from "@/stores/dragDrop";
import { useAppEditorStore } from "@/stores/appEditor";

@Component
export default class DraggableNode extends Vue {
  @Prop(Object) model: DataWidgetInfo;
  @Prop(Boolean) canDrag: boolean;

  isMouseDown = false;
  isDragging = false;
  isMouseWithinNode = false;

  originPoint: Point = { x: 0, y: 0 };
  grabOffset: Point = { x: 0, y: 0 };

  private clone: HTMLElement | undefined;

  get appEditor() {
    return useAppEditorStore();
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  onMouseDown(e: MouseEvent) {
    if (!this.canDrag) return;

    this.dragDropStore.hoverTarget = null;
    this.dragDropStore.draggingInfo = {
      type: "Node",
      ...this.model,
      dataParentUuid: this.model.nodeSetUuid,
      dataUuid: this.model.uuid || "",
    };
    this.appEditor.leaveTextEditMode();

    // Handle this special case in DataNodeImageUrl
    if (
      this.model.dataType === "ImageUrl" ||
      this.model.dataType === "ImageUpload"
    ) {
      return;
    }
    e.preventDefault();
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);

    // Clear out editing context so that can drag node to any cell (i.e., don't preselect the first cell)
    this.appEditor.resetEditingContext();

    document.body.style.cursor = "grabbing";
    this.isMouseDown = true;
    this.grabOffset = {
      x: e.offsetX,
      y: e.offsetY,
    };

    let rect = (e.target as HTMLElement).getBoundingClientRect();
    this.originPoint = { x: rect.x, y: rect.y };
  }

  onMouseUp(e: MouseEvent) {
    this.isMouseDown = false;

    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
    document.body.style.cursor = "default";
    if (this.isDragging) {
      this.isDragging = false;

      const CANVAS = document.getElementById(CANVAS_ID) as HTMLElement;
      const isOverArtboard = CANVAS.contains((e as any).target);

      if (!isOverArtboard) {
        this.dragDropStore.draggingInfo = null;

        if (this.clone !== undefined) {
          gsap.to(this.clone, {
            x: this.originPoint.x,
            y: this.originPoint.y,
            duration: 0.3,
            ease: "power2.in",
            onComplete: () => {
              this.removeClone();
            },
          });
        }
        return;
      }

      const dropPoint = this.appEditor.getArtboardCoordinates(
        { x: e.clientX, y: e.clientY },
        this.grabOffset
      );
      if (this.dragDropStore.draggingInfo) {
        this.dragDropStore.draggingInfo.dropPoint = dropPoint;
      }

      this.dragDropStore.handleNodeDrop();
      // console.log("Handled node drop..!");

      if (this.clone !== undefined) {
        gsap.to(this.clone, {
          scale: 0.1,
          opacity: 0,
          duration: this.dragDropStore.usagePromptIsOpen ? 0.02 : 0.1,
          ease: "back.in(1.5)",
          onComplete: () => {
            this.removeClone();
          },
        });
      }
    }
  }

  onMouseMove(e: MouseEvent) {
    if (this.isMouseDown) {
      this.createClone();
      this.isDragging = true;

      let x = e.clientX - this.grabOffset.x;
      let y = e.clientY - this.grabOffset.y;

      // Signal to user that drop will be ignored in this case
      const CANVAS = document.getElementById(CANVAS_ID) as HTMLElement;
      const opacity = CANVAS.contains((e as any).target) ? "1" : "0.5";
      if (this.clone !== undefined) {
        this.clone.style.opacity = opacity;
        this.clone.style.transform = `translate(${x}px,${y}px)`;
      }
      this.$emit("drag", {
        node: this.model,
        x: x,
        y: y,
      });
    }
  }

  createClone() {
    if (!this.clone) {
      let html = this.$el.innerHTML;
      this.clone = document.createElement("div");
      this.clone.innerHTML = html;
      this.clone.classList.add("node-clone");
      document.body.appendChild(this.clone);
    }
  }

  removeClone() {
    if (this.clone) {
      document.body.removeChild(this.clone);
      this.clone = undefined;
    }
  }

  beforeDestroy() {
    this.removeClone();
  }
}
</script>

<style lang="postcss">
.node-clone {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000;
  pointer-events: none;
  filter: drop-shadow(1px 1px 1px rgb(30, 30, 30));
}
</style>
