import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import {
  ShadowOptions,
  DefaultShadowOptions,
} from "@/components/widgets/ShadowOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface SvgOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions,
    AnimationOptions,
    ShadowOptions {
  path: string;
  numSides: number;
  innerRadius: number;
  starEnabled: boolean;
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...DefaultShadowOptions,
  ...getDefaultAnimationOptions(),
};

const CreateSvg = (options: Partial<SvgOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    wid: makeId(),
    type: "Svg",
    name: DEFAULT_LAYER_NAMES.Polygon,
  });
};

export default CreateSvg;
