// export interface BackgroundImageOptions {
//   url: string;
//   position: Point;
//   scale: number;
//   h: number;
//   w: number;
//   [property: string]: any;
// }

export type BackgroundSizeOptions = "repeat" | "no-repeat";

export interface BackgroundOptions {
  backgroundColor?: string;
  // backgroundImage?: string | null;
  backgroundRepeat: BackgroundSizeOptions;
  backgroundSize: "cover" | "contain";
  // backgroundPosition?: Point;
  // backgroundScale?: number;
  backgroundImageUrl: string | null;
  backgroundImageW: number | null;
  backgroundImageH: number | null;
  backgroundRepeatSize: number;
}

export const DefaultBackgroundOptions = {
  backgroundColor: "rgba(127, 127, 127, 1)",
  backgroundImageUrl: null,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImageW: null,
  backgroundImageH: null,
  backgroundRepeatSize: 100,
  // backgroundPosition: { x: 0, y: 0 },
  // backgroundScale: 1
};

// export const DefaultBackgroundOptions = {
//   backgroundColor: "rgba(127, 127, 127, 1)",
//   backgroundImage: {
//     url: "",
//     h: 0,
//     w: 0,
//     scale: 1,
//     position: { x: 0, y: 0 }
//   }
// };
