<template>
  <div class="relative flex items-center space-x-2 text-sm text-gray-500 ml-2">
    <label class="block w-3/4 cursor-pointer" @click="toggle"><slot /></label>
    <div @click="toggle" class="flex items-center">
      <ToggleButton
        class="w-10"
        :selected="value"
        v-if="!dark"
        :bgTransparent="bgTransparent"
      />
      <DarkToggleButton class="w-10" :selected="value" v-if="dark" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ToggleButton from "@/components/ToggleButton.vue";
import DarkToggleButton from "@/components/DarkToggleButton.vue";

@Component({
  components: { ToggleButton, DarkToggleButton },
})
export default class ToggleInput extends Vue {
  @Prop(Boolean) value: boolean;
  @Prop(Boolean) dark: boolean;
  @Prop({ default: false }) bgTransparent: boolean;

  toggle() {
    this.$emit("input", !this.value);
    this.$emit("change", !this.value);
  }
}
</script>
