import { SchemaType } from "@/types/data";

/**
 * Represents the different steps in the connection setup process.
 * - **Provide**: Users provide their data (upload, url import)
 * - **Selection**: Users define the query selection (rangeId for Tabular, node for Tree)
 * - **Schema**: Users define the structure of their collections (how to skip for Document connections?)
 * - **Sync**: Users define the refresh rate of their URL connections
 * - **Name**: Users define a name for a connection
 * - **Remap**: When a user replaces a connection with a new one, they must remap columns
 * - **Complete**: This needs to be worked out... what happens when a connection is created?
 * - **Configure**: Users define advanced configuration options for a connection
 */
export type SetupStepId =
  | "Provide"
  | "Selection"
  | "Schema"
  | "Sync"
  | "Name"
  | "Replace"
  | "Complete"
  | "Configure";

export interface SetupStep {
  id: string;
  clientComponentName: string;
  displayNameI18nKey: string;
  order: number;
  shouldPersistConnection: boolean;
  shouldShowInNavigation: boolean;
}

export interface Documentation {
  clientComponentName: string;
}

export type AuthenticatorType = "AccessKey";

export interface Authenticator {
  type: AuthenticatorType;
  endpoint: string;
}

export type SelectorType = "single" | "multi";
export interface Selector {
  type: SelectorType;
  order: number;
  endpoint: string;
}

export type InputType = "Text";
export interface Input {
  type: InputType;
  callbackModelKey: string;
  order: number;
  displayName: string;
  isRequired: boolean;
  isHidden: boolean;
  groupId?: string;
  properties: Record<string, any> | null;
}

export interface ValidationInput extends Input {
  value: any | undefined;
  isValid: boolean;
}

export interface ProvideStep extends SetupStep {
  authenticator: Authenticator | null;
  inputs: Input[] | null;
  endpoint: string;
}

export interface CompleteStep extends SetupStep {
  documentationComponentName: string;
}

export interface ConnectionSource {
  /**
   * Unique name for the data source
   */
  name: string;

  /**
   * Unique routing identifier
   */
  slug: string;

  /**
   * Describes how data for this connection source is provided. (Upload, Url, or None)
   */
  provisionMethod: string;

  /**
   * Data source name displayed to users, should be translated by server
   */
  displayName: string;

  /**
   * Description used to help users decide which data source they should use. Should be translated by server.
   */
  displayDescription: string;

  /**
   * Whether this data source can be programmatically updated from the backend
   */
  isRefreshable: boolean;

  /**
   * Whether this data source can be moderated
   */
  canModerate: boolean;

  /**
   * Url to an icon for this source
   */
  iconUrl: string;

  /**
   * Url to a support article for this source
   */
  supportArticleUrl: string | null;

  /**
   * The setup steps for this source
   */
  setupSteps: SetupStepId[] | undefined;

  setupStepsV2: SetupStep[] | undefined;

  /**
   * Additional routing query parameters
   */
  setupProperties: Record<string, string> | null;

  /**
   * Whether this data source is account specific
   */
  isAccountSpecific: boolean;

  /**
   * Identifies the schema type for this source
   */
  schemaType: SchemaType;

  /**
   * Identifies the provider definition version for this source.
   */
  providerDefinitionVersion: number | null;

  /**
   * Provides documentation for this source if any
   */
  documentation: Documentation | undefined;
}

export type ConnectionDataProvisionMethod = "Upload" | "Url" | "None";

export type ConnectionSourceId =
  | "FileUpload"
  | "Clipboard"
  | "Url"
  | "GoogleSheets"
  | "GoogleCalendar"
  | "Zapier"
  | "DataDesigner"
  | "RestApi"
  | "MicrosoftWorkbook"
  | "iCalendar"
  | "MicrosoftCalendar";

const urlSteps: SetupStepId[] = [
  "Provide",
  "Schema",
  "Sync",
  "Name",
  "Complete",
];

const designSteps: SetupStepId[] = ["Schema", "Name", "Complete"];
const uploadSteps: SetupStepId[] = ["Provide", "Schema", "Name", "Complete"];

export type ConnectionSourceRouteConfig = {
  name: string;
  setupSteps: SetupStepId[];
  provisionMethod: string;
};

export const ConnectionSources: ConnectionSourceRouteConfig[] = [
  {
    name: "FileUpload",
    setupSteps: uploadSteps,
    provisionMethod: "Upload",
  },
  {
    name: "Clipboard",
    setupSteps: uploadSteps,
    provisionMethod: "Upload",
  },
  {
    name: "Url",
    setupSteps: urlSteps,
    provisionMethod: "Url",
  },
  {
    name: "GoogleSheets",
    setupSteps: urlSteps,
    provisionMethod: "Url",
  },
  {
    name: "GoogleCalendar",
    setupSteps: urlSteps,
    provisionMethod: "Url",
  },
  {
    name: "Zapier",
    setupSteps: designSteps,
    provisionMethod: "None",
  },
  {
    name: "DataDesigner",
    setupSteps: designSteps,
    provisionMethod: "None",
  },
  {
    name: "RestApi",
    setupSteps: designSteps,
    provisionMethod: "None",
  },
  {
    name: "MicrosoftWorkbook",
    setupSteps: urlSteps,
    provisionMethod: "Url",
  },
  {
    name: "iCalendar",
    setupSteps: urlSteps,
    provisionMethod: "Url",
  },
  {
    name: "MicrosoftCalendar",
    setupSteps: urlSteps,
    provisionMethod: "Url",
  },
];
