import { defineStore } from "pinia";
import { api } from "@/api/backend";
import {
  ColumnPreviewInfo,
  ConnectionDataResponse,
  NodeData,
  NodeSetData,
  SchemaNode,
  SourceDataSchema,
  TabularDataSchema,
} from "@/types/data";
import { EventBus } from "@/eventbus";

async function getColumnsFromData(connectionId: string, firstRowIndex: number) {
  const take = 25;
  return api
    .get<ConnectionDataResponse>(
      `dataconnection/${connectionId}/data?take=${take}`
    )
    .then((response) => {
      const nodeSet = response.data as NodeSetData;
      const rows = nodeSet.children as NodeData[][];

      const importedRecordCount = Math.max(
        0,
        (nodeSet.importedRecordCount ?? 0) - firstRowIndex
      );

      if (rows && rows.length > 0) {
        const row = rows[0];
        const columns = row
          .filter((n) => {
            if (n.kind !== "Node") {
              return false;
            }

            // TODO: Verify whether we need to filter out artificial nodes
            const isDataIndexNode = n.query === "__sf_static_index__";
            if (n.isArtificial && !isDataIndexNode) {
              return false;
            }

            return true;
          })
          .map((n: NodeData, nodeIndex: number) => {
            return {
              ...n,
              name: n.displayName,
              importedRecordCount: importedRecordCount,
              // Get all sample values for this column
              sampleValues: rows.map((r) => {
                const node = r[nodeIndex];
                return {
                  formattedValue: node.formattedValue,
                  value: node.value,
                };
              }),
            } as unknown as ColumnPreviewInfo;
          });
        return columns;
      }
      return [];
    });
}

function invalidateCache({ uuid }: { uuid: string }) {
  const store = useColumnInfoStore();
  delete store.schemas[uuid];
  delete store.columns[uuid];
}

// Invalidate cached schemas and columns when connection is updated or synchronized
EventBus.on("DATA_CONNECTION_UPDATED", invalidateCache);
EventBus.on("DATA_CONNECTION_SYNCHRONIZED", invalidateCache);

export interface ColumnInfoState {
  schemas: Record<string, SourceDataSchema | TabularDataSchema>;
  columns: Record<string, SchemaNode[]>;
}

export const useColumnInfoStore = defineStore("columnInfo", {
  state: (): ColumnInfoState => {
    return {
      schemas: {},
      columns: {},
    };
  },

  getters: {},

  actions: {
    getSchema<T = SourceDataSchema>(connectionId: string) {
      if (connectionId === undefined) {
        return Promise.reject(`Connection ID is undefined`);
      }

      if (connectionId in this.schemas) {
        return Promise.resolve(this.schemas[connectionId] as T);
      }

      return api
        .get<TabularDataSchema>(`dataconnection/${connectionId}/schema`)
        .then((schema) => {
          this.schemas[connectionId] = schema;
          return schema as T;
        });
    },

    getColumns(connectionId: string) {
      if (this.columns[connectionId]) {
        return Promise.resolve(this.columns[connectionId]);
      }

      return this.getSchema<SourceDataSchema | TabularDataSchema>(
        connectionId
      ).then(async (schema) => {
        const sheets = (schema as TabularDataSchema).sheets;
        const hasHeader = sheets !== undefined && sheets[0].hasHeader;
        const firstRowIndex = hasHeader ? 1 : 0;
        const columns = await getColumnsFromData(connectionId, firstRowIndex);
        this.columns[connectionId] = columns;
        return columns;
      });
    },
  },
});
