<template>
  <div class="divide-y">
    <div class="divide-y py-5">
      <div>
        <div class="px-4 space-y-3">
          <div class="flex-col w-full text-sm pl-3">
            <div v-t="'countdown.dateTime'" class="text-gray-500"></div>
            <ScalarBinder
              propName="datetimeValue"
              propType="DateTime"
              v-slot:default="slotProps"
              tooltipPosition="l"
            >
              <input
                :readonly="slotProps.isBound"
                type="datetime-local"
                :class="{
                  'border border-app-gold': slotProps.isBound,
                  'hover:ring-1': !slotProps.isBound,
                }"
                class="w-48 bg-app-panel focus:bg-white hover:ring-app-teal border-none outline-none rounded px-1 focus:ring-1 focus:ring-app-teal"
                :value="renderValue(slotProps.value)"
                @change="handleChange"
              />
            </ScalarBinder>
          </div>
        </div>
        <div class="py-3 px-4 flex flex-col space-y-1">
          <ToggleInput
            :value="model.pastAllowed"
            @input="apply({ pastAllowed: $event })"
            ><span v-t="`countdown.pastAllowed`"></span>
          </ToggleInput>
          <div
            class="text-sm text-gray-600 mt-1 px-2 py-1 leading-tight border rounded-sm shadow-sm"
            v-t="'countdown.pastAllowedExplain'"
          ></div>
          <ToggleInput
            class="pt-2"
            :value="model.paused"
            @input="pauseEvent($event)"
            ><span v-t="`countdown.pause`"></span>
          </ToggleInput>
        </div>
      </div>
      <div class="px-4 py-3 space-y-1">
        <div v-t="'countdown.timeUnits'" class="text-sm ml-2"></div>

        <ToggleInput
          :value="model.showYears"
          @input="toggleTimeUnit('showYears', $event)"
          ><span v-t="`countdown.showYears`"></span>
        </ToggleInput>
        <ToggleInput
          :value="model.showMonths"
          @input="toggleTimeUnit('showMonths', $event)"
          ><span v-t="`countdown.showMonths`"></span>
        </ToggleInput>
        <ToggleInput
          :value="model.showWeeks"
          @input="toggleTimeUnit('showWeeks', $event)"
          ><span v-t="`countdown.showWeeks`"></span>
        </ToggleInput>
        <ToggleInput
          :value="model.showDays"
          @input="toggleTimeUnit('showDays', $event)"
          ><span v-t="`countdown.showDays`"></span>
        </ToggleInput>
        <ToggleInput
          :value="model.showHours"
          @input="toggleTimeUnit('showHours', $event)"
          ><span v-t="`countdown.showHours`"></span>
        </ToggleInput>
        <ToggleInput
          :value="model.showMinutes"
          @input="toggleTimeUnit('showMinutes', $event)"
          ><span v-t="`countdown.showMinutes`"></span>
        </ToggleInput>
        <ToggleInput
          :value="model.showSeconds"
          @input="toggleTimeUnit('showSeconds', $event)"
          ><span v-t="`countdown.showSeconds`"></span>
        </ToggleInput>
      </div>
    </div>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'countdown.time'"></div>
      </template>

      <div>
        <GroupTextFormatEditor
          :model="model"
          element="time"
          @update="apply($event)"
          :disableAlignment="true"
          :disableLineHeight="true"
          :disableTextTransform="true"
        />
        <div class="px-4 pb-3 space-y-3">
          <NumberInputBar
            :value="model.marginX"
            @change="apply({ marginX: $event })"
          >
            <span v-t="'countdown.marginX'"></span
          ></NumberInputBar>
          <NumberInputBar
            :value="model.marginY"
            :min="-Infinity"
            @change="apply({ marginY: $event })"
          >
            <span v-t="'countdown.marginY'"></span
          ></NumberInputBar>
          <ToggleInput
            class="px-1"
            :value="model.showDivider"
            @input="apply({ showDivider: $event })"
            ><span v-t="'countdown.showDivider'"></span>
          </ToggleInput>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'countdown.labelOptions'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="label"
          @update="apply($event)"
          :disableLineHeight="true"
          :disableAlignment="true"
        />
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import FormLabel from "@/components/FormLabel.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { DateTime } from "luxon";
import { CountdownOptions } from "./CountdownOptions";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CollapsePanel,
    ToggleInput,
    ColorInputBar,
    NumberInputBar,
    FormLabel,
    FontFamilyInput,
    NumberSliderInput,
    GroupTextFormatEditor,
    SelectMenu,
    BaseWidgetEditor,
    ScalarBinder,
  },
})
export default class CountdownEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as CountdownOptions;
  }

  get selections() {
    return this.appEditor.selections;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  get displayedTimeUnits() {
    return [
      this.model.showYears,
      this.model.showMonths,
      this.model.showWeeks,
      this.model.showDays,
      this.model.showHours,
      this.model.showMinutes,
      this.model.showSeconds,
    ].filter((value) => value === true);
  }

  toggleTimeUnit(prop: any, value: any) {
    if (this.displayedTimeUnits.length === 1 && value === false) {
      return;
    } else {
      this.apply({ [prop]: value });
    }
  }

  handleChange(ev: any) {
    this.apply({
      datetimeValue: ev.target.value,
    });
  }

  // The input type "datetime-local" requires this format
  renderValue(val: string) {
    return DateTime.fromISO(val).toFormat("yyyy-MM-dd'T'HH:mm:ss");
  }

  pauseEvent(event: any) {
    this.apply({
      paused: event,
      pausedDateTime: DateTime.now().toISO(),
    });
  }
}
</script>
