<template>
  <router-link
    :to="{ name: 'edit', params: { id: uuid } }"
    v-bind:data-id="`app-list-item-${uuid}`"
    style="height: 60px"
    class="group items-center text-sm w-full px-2 py-2 bg-app-dark1b hover:bg-app-overlays-25 flex justify-between cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-700 focus:ring-gray-500"
    :class="{
      'dark-form-focus-ring': isSelected,
    }"
  >
    <div class="flex space-x-4 items-center">
      <div
        class="rounded preview cursor-pointer bg-gray-500 bg-cover bg-center bg-no-repeat shrink-0"
        :class="{
          'dark-form-focus-ring': isSelected,
        }"
        style="aspect-ratio: 21 / 12; width: 64px"
        :style="{
          backgroundImage: imageUrl,
        }"
      >
        <div
          v-if="!image && !showDummyImages"
          class="w-full h-full flex justify-center text-center text-[8px] p-[2px] leading-tight"
        >
          <span class="self-center" v-t="'generatingPreview'"></span>
        </div>
      </div>
      <div class="text-[14px] app-name leading-tight truncate">
        {{ name }}
      </div>
    </div>

    <div
      class="flex flex-grow justify-end space-x-4 items-center text-[12px]"
      v-if="!isShort"
    >
      <div
        @click.prevent="doAction('launch')"
        v-if="isPublished"
        class="text-app-teal2 opacity-70 hover:opacity-100 hover:underline"
      >
        Published
      </div>
      <div class="opacity-70 app-size">{{ width }} x {{ height }}</div>
      <div
        class="flex items-center space-x-[3px] opacity-70 hover:opacity-100 hover:underline"
        @click.prevent="doAction('preview')"
      >
        <IconButton @click="doAction('preview')" icon="Eye" class="icon" />
        <div class="icon-label">Preview</div>
      </div>
      <div
        class="flex items-center space-x-[3px] opacity-70 hover:opacity-100 hover:underline"
        @click.prevent="doAction('duplicate')"
      >
        <IconButton
          @click="doAction('duplicate')"
          icon="Duplicate"
          class="icon"
        />
        <div class="icon-label">Duplicate</div>
      </div>
      <div
        class="flex items-center space-x-[3px] opacity-70 hover:opacity-100 hover:underline"
        @click.prevent="doAction('copyUrl')"
      >
        <IconButton @click="doAction('copyUrl')" icon="Link" class="icon" />
        <div class="icon-label">Copy Url</div>
      </div>
      <div
        class="flex items-center space-x-[3px] opacity-70 hover:opacity-100 hover:underline"
        @click.prevent="doAction('delete')"
      >
        <IconButton @click="doAction('delete')" icon="Trash" class="icon" />
        <div class="icon-label">Delete</div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconButton from "@/views/Dashboard/IconButton.vue";
import { RecycleScroller } from "vue-virtual-scroller";
import { EventBus } from "@/eventbus";
import { AppListAction } from "@/types";

@Component({
  components: {
    RecycleScroller,
    IconButton,
  },
})
export default class ListItem extends Vue {
  @Prop(Boolean) isSelected: boolean;
  @Prop(String) name: string;
  @Prop(String) uuid: string;
  @Prop(Boolean) isPublished: boolean;
  @Prop(String) image: string;
  @Prop(Number) width: number;
  @Prop(Number) height: number;
  @Prop({ default: false }) isShort: boolean;

  imgSize = 500;

  doAction(action: AppListAction) {
    if (["duplicate", "delete"].includes(action)) {
      EventBus.emit("OPEN_DASHBOARD_APP_MODAL", {
        action,
        uuid: this.uuid,
        name: this.name,
      });
    } else {
      EventBus.emit("APPS_LIST_ACTION", { action, uuid: this.uuid });
    }
  }

  get aspectRatio() {
    return this.width / this.height;
  }

  get imageUrl() {
    if (this.showDummyImages) {
      return "url('https://placekitten.com/800/600')";
    }
    const height = Math.floor(this.imgSize / this.aspectRatio);
    return `url(${this.image}?w=${this.imgSize}&h=${height})`;
  }

  get showDummyImages() {
    return (
      new URL(this.$route.fullPath, window.location.origin).searchParams.get(
        "dummyImages"
      ) === "true"
    );
  }
}
</script>

<style lang="postcss" scoped>
.icon {
  width: 0.9rem;
  height: 0.9rem;
}

@media (max-width: 1200px) {
  .icon-label {
    display: none;
  }
}

@media (max-width: 1000px) {
  .app-name {
    width: 12rem;
  }
}

@media (max-width: 800px) {
  .app-size {
    display: none;
  }
}
</style>
