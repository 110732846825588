<template>
  <div class="divide-y">
    <div class="space-y-3 py-8 px-4">
      <ColorInputBar
        :value="backgroundColor"
        :iconSize="'5'"
        @input="preview({ backgroundColor: $event })"
        @change="apply({ backgroundColor: $event })"
        :gradientEnabled="true"
        propName="backgroundColor"
      >
        <span v-t="'fill'"></span>
      </ColorInputBar>

      <ColorInputBar
        :value="borderColor"
        :iconSize="'5'"
        @input="preview({ borderColor: $event })"
        @change="apply({ borderColor: $event })"
        :gradientEnabled="true"
        propName="borderColor"
      >
        <span v-t="'border'"></span>
      </ColorInputBar>

      <NumberInputBar
        :value="borderWidth"
        @change="apply({ borderWidth: $event })"
      >
        <span v-t="'borderWidth'"></span>
      </NumberInputBar>

      <NumberInputBar
        :value="borderDashSize"
        @change="apply({ borderDashSize: $event })"
      >
        <span v-t="'borderDashSize'"></span>
      </NumberInputBar>

      <NumberInputBar
        :value="borderGapSize"
        @change="apply({ borderGapSize: $event })"
      >
        <span v-t="'borderGapSize'"></span>
      </NumberInputBar>

      <div v-t="'borderCorners'" class="mb-2 ml-3 text-sm text-gray-500"></div>
      <EditorSelectMenu
        class="px-3"
        :value="strokeLinejoin"
        :options="linejoinOptions"
        @input="apply({ strokeLinejoin: $event })"
      />
    </div>

    <div v-if="hasBackgroundImage" class="p-3">
      <ButtonGradient
        class="text-white text-xs font-normal ml-2"
        customGradient="linear-gradient(to bottom, rgb(125, 125, 125), rgb(70, 70, 70))"
        padding="pr-4 py-2 pl-3"
        @click="onRemoveImage"
      >
        <span>
          <Icon name="Close" :width="5" class="w-3 h-3" />
        </span>
        <span class="ml-2" v-t="'removeImage'"></span
      ></ButtonGradient>
      <div class="py-3">
        <div
          v-t="'backgroundSize'"
          class="mb-2 ml-2 text-sm text-gray-500"
        ></div>
        <EditorSelectMenu
          :value="backgroundSize"
          :options="backgroundSizeOptions"
          @input="apply({ backgroundSize: $event })"
        />
      </div>
    </div>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'shadow'"></div>
      </template>
      <div class="divide-y">
        <ShadowEditor />
      </div>
    </CollapsePanel>
    <CollapsePanel v-if="showAnimationPanel">
      <template slot="title">
        <div v-t="'animation.animation'"></div>
      </template>
      <AnimationEditor />
    </CollapsePanel>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import BorderEditor from "@/components/editors/BorderEditor.vue";
import ShadowEditor from "@/components/editors/ShadowEditor.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import Icon from "@/components/icons/Icon.vue";
import AnimationEditor from "@/components/editors/AnimationEditor.vue";
import { DataBinding } from "@/types/data";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { animationAllowed } from "@/components/widgets/animationAllowed";

@Component({
  components: {
    ColorInputBar,
    NumberInputBar,
    CollapsePanel,
    EditorSelectMenu,
    BorderEditor,
    ShadowEditor,
    ButtonGradient,
    AnimationEditor,
    Icon,
  },
})
export default class TriangleEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get showAnimationPanel() {
    return animationAllowed(this.selectedWidget);
  }

  // TODO: Must update to account for dynamic bg images in repeater
  get hasBackgroundImage() {
    return (
      this.dataBindings.filter(
        (db: DataBinding) =>
          db.widgetId === this.selections[0] &&
          db.property === "backgroundImageUrl"
      ).length > 0
    );
  }

  mounted() {
    console.log(this.selectedProps);
  }

  get backgroundColor() {
    return this.selectedProps.backgroundColor[0];
  }

  get strokeLinejoin() {
    return this.selectedProps.strokeLinejoin[0];
  }

  get backgroundSize() {
    return (this.selectedWidget as any).backgroundSize;
  }

  get backgroundSizeOptions() {
    return [
      { label: "Fit", value: "contain" },
      { label: "Fill", value: "cover" },
    ];
  }

  get linejoinOptions() {
    return [
      { label: "Round", value: "round" },
      // { label: "Miter", value: "miter" },
      { label: "Bevel", value: "bevel" },
    ];
  }

  get borderColor() {
    return this.selectedProps.borderColor[0];
  }

  get borderWidth() {
    return this.selectedProps.borderWidth[0];
  }

  get borderDashSize() {
    return this.selectedProps.borderDashSize[0];
  }

  get borderGapSize() {
    return this.selectedProps.borderGapSize[0];
  }

  apply(props: any) {
    this.appEditor.setWidgetProps(this.selections, props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps(this.selections, props, "NO_UNDO");
  }

  onRemoveImage() {
    this.appEditor.removeDataBindingsForWidgets({
      widgetsInfo: this.appEditor.selections.map((wid) => ({
        wid,
        conditionId: useConditionGroupsStore().getActiveConditionId(wid),
      })),
    });
  }
}
</script>
