<template>
  <div class="flex flex-col space-y-6">
    <h2 class="text-xl font-bold">Disconnect from data source</h2>

    <!-- Box1: -->
    <div class="box box-inner">
      <h3 class="text-lg font-bold">
        Use this option if you want to be able to...
      </h3>
      <ul>
        <li>Edit this data set in the data grid</li>
        <li>Use the data API for this data set</li>
      </ul>
    </div>

    <!-- Box2: -->
    <div class="box box-inner">
      <h3 class="text-lg font-bold">Disconnecting will...</h3>
      <ul>
        <li>Stop any further synchronizations</li>
        <li>Preserve all the data already synchronized</li>
        <li>Preserve your moderation selections</li>
      </ul>
    </div>

    <div v-if="showError" class="text-red-500 select-text">
      <div>
        There was an error disconnecting your data source. Please try again or
        contact support.
      </div>
      <div v-if="errorMessage">({{ errorMessage }})</div>
    </div>

    <PopoverConfirm
      confirm="Yes"
      class="ml-8"
      :shiftArrow="true"
      confirmGradient="linear-gradient(to bottom, rgba(255, 84, 84, 1), rgb(128,42,42))"
      @onConfirm="disconnect"
    >
      <template slot="selector">
        <ButtonGradient
          class="text-lg -ml-8 opacity-80"
          customGradient="linear-gradient(to bottom, rgba(255, 84, 84, 1), rgb(128,42,42))"
          padding="pt-2 pb-3 px-4"
          hover="opacity-100"
        >
          <span>Disconnect</span>
        </ButtonGradient>
      </template>
    </PopoverConfirm>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import PopoverConfirm from "@/components/PopoverConfirm.vue";
import {
  APP_EDITOR_ROUTE_PATH,
  APP_EDITOR_CONNECTION_ROUTE_PATH,
} from "@/constants";
import { useEditableDataStore } from "@/stores/editableData";
import { useAppEditorStore } from "@/stores/appEditor";
import { logger } from "@core/logger";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

// TODO: Opening Disconnect popover should scroll to bottom if needed
// TODO: Possible for Popover to get cut off at bottom

@Component({
  components: {
    ButtonGradient,
    PopoverConfirm,
  },
})
export default class Disconnect extends Vue {
  errorMessage = "";
  showError = false;

  basePath = `/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get isFromAppEditor() {
    return this.$route.path.includes(APP_EDITOR_ROUTE_PATH);
  }

  mounted() {
    this.errorMessage = "";
    this.showError = false;
  }

  async disconnect() {
    this.errorMessage = "";
    this.showError = false;

    if (this.connection === null) {
      logger.track("Disconnect: connection is null");
      return;
    }

    try {
      const newConnection =
        await this.editableDataStore.disconnectAndCreateEditableConnection({
          appUuid: this.$route.params.id,
          name: this.connection.name,
          convertFromConnectionUuid: this.connection.uuid,
          widget: this.selectedWidget,
        });
      this.connectionEditor.setConnection(newConnection);

      // Must update data binding here, before user attempts to upsert
      await this.appEditor.updateApp();

      if (this.isFromAppEditor) {
        // App editor
        this.$router.push({ path: `${this.basePath}/${newConnection.uuid}` });
      } else {
        // Dashboard
        // Why doesn't this have new data....
        this.$router.push({ path: `/connections/${newConnection.uuid}/data` });
      }
      this.editableDataStore.showDisconnectSuccessMessage = true;
    } catch (e) {
      console.log("Error disconnecting:", e);
      this.errorMessage = typeof e === "string" ? e : (e as any)[0]?.message;
      this.showError = true;
    } finally {
      this.connectionEditor.isLoading = false;
    }
  }
}
</script>

<style scoped lang="postcss">
.box-inner {
  @apply px-8 py-3 pb-4 w-110;
}
</style>
