import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";

export interface VideoOptions
  extends ComponentOptions,
    TransformOptions,
    AnimationOptions,
    BorderOptions {
  url: string;
  loop: boolean;
  mimeType: string;
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...getDefaultAnimationOptions(),
  loop: true,
  mimeType: "video/unknown",
};

const CreateVideo = (options: Partial<VideoOptions>) => {
  const wid = makeId();
  return Object.assign({ wid }, defaultOptions, options, { type: "Video" });
};

export default CreateVideo;
