<template>
  <ScalarBinder
    propName="opacity"
    propType="Number"
    v-slot:default="slotProps"
    :tooltipPosition="'l'"
  >
    <EditorNumberInput
      class="w-22"
      icon="OpacityEditor"
      :controls="!slotProps.isBound"
      :align="'left'"
      :min="0"
      :max="100"
      :value="slotProps.value"
      :dataBound="slotProps.isBound"
      @change="onChange"
    />
  </ScalarBinder>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FormLabel from "@/components/FormLabel.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";

import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { useAppEditorStore } from "@/stores/appEditor";

// TODO: Dimensions x and y will not go negative on Safari

@Component({
  components: {
    FormLabel,
    EditorNumberInput,
    ScalarBinder,
  },
})
export default class OpacityEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get showEditor() {
    return "opacity" in this.appEditor.selectedProps;
  }

  onChange(value: number) {
    this.appEditor.setWidgetProps(this.appEditor.selections, {
      opacity: value,
    });
  }

  // TODO figure out how to share this across components...(mixin? but hmm can only extend one..)
  // getValue<T>(key: string): T {
  //   let result: any;
  //   if (this.selectedProps.hasOwnProperty(key)) {
  //     let values = this.selectedProps[key] as any[];
  //     // NOTE: Again, this is crude -- just taking the first widget's val as the group's val
  //     // if (values.length === 1) {
  //     result = values[0];
  //     // }
  //   }
  //   return result as T;
  // }
}
</script>
