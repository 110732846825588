<template>
  <div class="divide-y">
    <CollapsePanel :shouldListenForOpenCommand="true">
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <DataSetChooser />
      <div class="py-3 px-4 space-y-3">
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.xColumn'"></div>
          <EditorSelectMenu
            :value="model.xAxisColumnId"
            :options="xColumnOptions"
            @input="apply({ xAxisColumnId: $event })"
          />
        </div>
        <div
          v-if="xColumnOptions.length < 1"
          v-t="'graph.xAxisColumnError'"
        ></div>
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.yColumn'"></div>
          <EditorSelectMenu
            :value="model.yAxisColumnId"
            :options="yColumnOptions"
            @input="apply({ yAxisColumnId: $event })"
          />
        </div>
        <div
          v-if="yColumnOptions.length < 1"
          v-t="'graph.yAxisColumnError'"
        ></div>
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.xTitle'"></div>
          <EditorTextInput
            :value="xTitlePreview"
            @input="apply({ xTitleManual: $event })"
            @change="apply({ xTitleManual: $event })"
          />
        </div>
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.yTitle'"></div>
          <EditorTextInput
            :value="yTitlePreview"
            @input="preview({ yTitleManual: $event })"
            @change="apply({ yTitleManual: $event })"
          />
        </div>
        <div class="space-y-3">
          <div>
            <NumberInputBar :value="min" @change="apply({ minManual: $event })"
              ><span class="text-sm" v-t="'minValue'"></span>
            </NumberInputBar>
          </div>
          <div>
            <NumberInputBar :value="max" @change="apply({ maxManual: $event })"
              ><span class="text-sm" v-t="'maxValue'"></span>
            </NumberInputBar>
          </div>
          <div class="pb-4 px-3">
            <div class="text-gray-500 text-sm" v-t="'graph.labelTicks'"></div>
            <SliderInput
              :value="model.labelTicks"
              :min="2"
              :max="30"
              :step="2"
              @input="preview({ labelTicks: $event })"
              @change="apply({ labelTicks: $event })"
            />
          </div>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.plotlineOptions'"></div>
      </template>
      <div class="pb-5 px-4">
        <div class="px-3 pb-5">
          <div
            class="text-gray-500 text-sm"
            v-t="'graph.plotlineThickness'"
          ></div>
          <SliderInput
            class="mt-3"
            :value="model.plotlineThickness"
            :min="0"
            :max="50"
            :step="1"
            @input="preview({ plotlineThickness: $event })"
            @change="apply({ plotlineThickness: $event })"
          />
        </div>
        <ColorInputBar
          :value="model.plotlineColor"
          :gradientEnabled="false"
          @input="preview({ plotlineColor: $event })"
          @change="apply({ plotlineColor: $event })"
          propName="plotlineColor"
          ><span class="text-sm" v-t="'graph.lineColor'">></span>
        </ColorInputBar>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.axisOptions'"></div>
      </template>
      <div class="pb-5 divide-y">
        <div class="pb-3">
          <div
            class="font-semibold text-gray-500 text-sm px-6 pb-2"
            v-t="'graph.titleOptions'"
          ></div>
          <GroupTextFormatEditor
            :model="model"
            element="title"
            @update="apply($event)"
            :disableLineHeight="true"
            :disableAlignment="true"
            :disableLetterSpacing="true"
          />
          <div class="space-y-3 px-4">
            <NumberInputBar
              :value="model.leftTitlePadding"
              @change="apply({ leftTitlePadding: $event })"
              ><div class="text-sm" v-t="'graph.yTitlePadding'"></div
            ></NumberInputBar>

            <NumberInputBar
              :value="model.bottomTitlePadding"
              @change="apply({ bottomTitlePadding: $event })"
            >
              <div class="text-sm" v-t="'graph.xTitlePadding'"></div
            ></NumberInputBar>
          </div>
        </div>
        <div>
          <div
            class="font-semibold text-gray-500 text-sm mt-5 px-6 pb-2"
            v-t="'graph.labelOptions'"
          ></div>
          <GroupTextFormatEditor
            :model="model"
            element="label"
            @update="apply($event)"
            :disableLineHeight="true"
            :disableAlignment="true"
            :disableLetterSpacing="true"
          />
        </div>
        <div class="px-4 pt-5">
          <div class="px-3 pb-5">
            <div
              class="text-gray-500 text-sm"
              v-t="'graph.axisThickness'"
            ></div>
            <SliderInput
              class="w-36 mb-2"
              :value="model.lineThickness"
              :min="0"
              :max="50"
              :step="1"
              @input="preview({ lineThickness: $event })"
              @change="apply({ lineThickness: $event })"
            />
          </div>

          <ColorInputBar
            class="py-3"
            :value="model.lineColor"
            :gradientEnabled="false"
            @input="preview({ lineColor: $event })"
            @change="apply({ lineColor: $event })"
            propName="lineColor"
            ><div class="text-sm" v-t="'graph.axisColor'"></div
          ></ColorInputBar>
          <ToggleInput
            class="py-3"
            :value="model.xLabelTilt"
            @input="apply({ xLabelTilt: $event })"
            ><span class="text-sm" v-t="'graph.xLabelTilt'"></span>
          </ToggleInput>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.backgroundOptions'"></div>
      </template>
      <div class="py-3 px-4">
        <ToggleInput
          class="mb-3"
          :value="model.showBg"
          @input="apply({ showBg: $event })"
          ><span class="text-sm" v-t="'graph.showBg'"></span>
        </ToggleInput>
        <div v-if="model.showBg">
          <ColorInputBar
            v-if="model.showBg"
            :value="model.bgColor"
            :gradientEnabled="false"
            @input="preview({ bgColor: $event })"
            @change="apply({ bgColor: $event })"
            propName="bgColor"
            ><span class="text-sm" v-t="'graph.bgColor'"></span
          ></ColorInputBar>
        </div>
        <ToggleInput
          class="mt-3 mb-3"
          :value="model.showGrid"
          @input="apply({ showGrid: $event })"
          ><span class="text-sm" v-t="'graph.showGrid'"></span>
        </ToggleInput>
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import ColorPicker from "@/components/inputs/ColorPicker.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import SliderInput from "@/components/inputs/SliderInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import EditorTextInput from "@/components/inputs/EditorTextInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import DataSetChooser from "@/components/widgets/DataSetChooser.vue";
import { LineGraphOptions } from "@/components/widgets/LineGraph/LineGraphOptions";
import { NodeData } from "@/types/data";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CollapsePanel,
    ColorPicker,
    ColorInputBar,
    NumberInputBar,
    NumberSliderInput,
    ToggleInput,
    EditorTextInput,
    ButtonGradient,
    EditorSelectMenu,
    SliderInput,
    BaseWidgetEditor,
    GroupTextFormatEditor,
    DataSetChooser,
  },
})
export default class LineGraphEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as LineGraphOptions;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  setAxisColumns() {
    const xAxisColumnIdOptions = this.xColumnOptions.map((o) => o.value);
    const yAxisColumnIdOptions = this.yColumnOptions.map((o) => o.value);
    if (
      xAxisColumnIdOptions &&
      xAxisColumnIdOptions.length > 0 &&
      !xAxisColumnIdOptions.includes(this.model.xAxisColumnId)
    ) {
      let xAxisColumnId = xAxisColumnIdOptions[0];
      this.apply({ xAxisColumnId });
    }

    if (
      yAxisColumnIdOptions &&
      yAxisColumnIdOptions.length > 0 &&
      !yAxisColumnIdOptions.includes(this.model.yAxisColumnId)
    ) {
      let yAxisColumnId = yAxisColumnIdOptions[0];
      this.apply({ yAxisColumnId });
    }
  }

  @Watch("dataWatchProp", { immediate: true })
  onDataBindingChanged() {
    this.setAxisColumns();
    this.setInitial();
  }

  get dataWatchProp() {
    const id = this.dataBinding?.dataConnectionUuid;
    const ph =
      this.widgetData[this.model.wid]?.[0]?._placeholderData?.toString();
    return `c${id}-${ph}`;
  }

  get xTitlePreview() {
    return this.model.xTitleManual === null
      ? this.model.xTitle
      : this.model.xTitleManual;
  }

  get yTitlePreview() {
    return this.model.yTitleManual === null
      ? this.model.yTitle
      : this.model.yTitleManual;
  }

  get min() {
    return this.model.minManual === null
      ? this.model.minValue
      : this.model.minManual;
  }

  get max() {
    return this.model.maxManual === null
      ? this.model.maxValue
      : this.model.maxManual;
  }

  get dataBinding() {
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.model.wid,
      bindingType: "DataSet",
    });

    return bindings.length > 0 ? bindings[0] : undefined;
  }

  get dataSet(): NodeData[][] {
    return (this.widgetData[this.model.wid]?.[0]?.data as NodeData[][]) ?? [];
  }

  get dataColumns(): NodeData[] {
    return this.dataSet.length > 0 ? this.dataSet[0] : [];
  }

  get xColumnOptions() {
    return this.dataColumns
      .filter(
        (c) =>
          c.dataType === "String" ||
          c.dataType === "Date" ||
          c.dataType === "Time" ||
          c.dataType === "DateTime"
      )
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get yColumnOptions() {
    return this.dataColumns
      .filter((c) => c.dataType === "Number" && c.query != "__sf_index__")
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get initialXTitle() {
    if (this.xColumnOptions.length > 0) {
      return this.dataSet[0]?.find(
        (c) => c.uuid === this.xColumnOptions[0].value
      )?.displayName;
    } else {
      return "";
    }
  }

  get initialYTitle() {
    if (this.yColumnOptions.length > 0) {
      return this.dataSet[0]?.find(
        (c) => c.uuid === this.yColumnOptions[0].value
      )?.displayName;
    } else {
      return "";
    }
  }

  get values() {
    let values: any = [];
    this.dataSet.map((row) => {
      values.push(
        parseInt(
          row.find((c) => c.uuid === this.model.yAxisColumnId)?.value as string
        )
      );
    });
    return values;
  }

  get buffer() {
    let max = Math.ceil(Math.max(...this.values));
    let min = Math.floor(Math.min(...this.values));
    let dif = max - min;
    return dif * 0.1;
  }

  get maxValue() {
    return Math.ceil(Math.max(...this.values) + this.buffer);
  }

  get minValue() {
    return Math.floor(Math.min(...this.values) - this.buffer);
  }

  setInitial() {
    this.apply({
      xTitle: this.initialXTitle,
      yTitle: this.initialYTitle,
      minValue: this.minValue,
      maxValue: this.maxValue,
    });
  }
}
</script>
