/**
 * The default `parentId` value for widgets added
 * directly the canvas (not a child of another widget).
 */
export const BASE_PARENT_ID = "base";
export const PLACEHOLDER_IMAGE_PROPERTY = "__placeholderImage";

/**
 * Default connection refresh rate in minutes
 */
export const DEFAULT_CONNECTION_REFRESH_RATE = 15;

/**
 * Root path for app editor
 */
export const APP_EDITOR_ROUTE_PATH = "app/edit";

/**
 * Unique string that is present in non-editor "Connection" paths.
 * It is used to determine which context the user is in when managing
 * or creating a connection.
 */
export const APP_EDITOR_CONNECTION_ROUTE_PATH = "connections";

/**
 * The unique id for "placeholder" connections. For example,
 * Calendar, Graphs have fake data.
 *
 * This is used when a user presses "Manage Data" for a
 * Calendar or Graph that isn't yet databound.
 */
export const PLACEHOLDER_CONNECTION_UUID = "placeholder";

/**
 * The DOM id for the <Canvas/> component.
 *
 * It is used in a few places where we need to measure the DOM
 * at runtime.
 */
export const CANVAS_ID = "canvas";

/**
 * The DOM id for the left-hand sidebar in the Dashboard.
 * Used when computing positions of cells in virtual grid collection.
 */

export const DASHBOARD_SIDEBAR_ID = "dashboard_sidebar";

/**
 * The value for the "default" condition.
 */
export const DEFAULT_CONDITION_ID = "00000000-0000-0000-0000-000000000000";

export const DEFAULT_FALLBACK_IMAGE_TIMEOUT_SEC = 60 * 60; // 60 minutes
export const DEFAULT_INTRO_IMAGE_TIMEOUT_SEC = 2;

// Default widget names for layer panel display
export const DEFAULT_LAYER_NAMES: { [key: string]: string } = {
  Text: "Text",
  Repeater: "Repeater",
  Group: "Group",
  CalendarEvent: "Event",
  CalendarDay: "Day",
  CalendarAgenda: "Agenda",
  CalendarRoomSchedule: "Room Schedule",
  CalendarWeek: "Calendar Week",
  ProgressBar: "Progress Bar",
  ProgressDonut: "Progress Circle",
  Countdown: "Countdown / Up",
  Datetime: "Date / Time",
  AnalogClock: "Analog Clock",
  ColumnGraph: "Column Graph",
  BarGraph: "Bar Graph",
  LineGraph: "Line Graph",
  PieGraph: "Pie Graph",
  StackedGraph: "Stacked Graph",
  Slide: "Slide",
  Multiframe: "Multiframe",
  Image: "Image",
  Video: "Video",
  Rectangle: "Rectangle",
  Ellipse: "Ellipse",
  Svg: "Polygon",
  Triangle: "Triangle",
  Line: "Line",
};
