import { Vue } from "vue-property-decorator";
import { defineStore } from "pinia";
import { useConditionGroupsStore } from "./conditionGroups";
import {
  Condition,
  LogicOperand,
  LogicOperation,
  LogicRule,
  LogicRuleGroup,
} from "@/types/logic";

import { api } from "@/api/backend";
import { makeId } from "@/utils";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { DataConnection, SchemaNode } from "@/types/data";
import { validateRule } from "@/components/logic/ruleValidator";
import { ConditionRuleToken } from "@/components/logic/describeRule";
import { useAppEditorStore } from "./appEditor";
import { useColumnInfoStore } from "./columnInfo";

const setOperandValues = (
  target: Partial<LogicOperand>,
  source: Partial<LogicOperand>
) => {
  const props: (keyof LogicOperand)[] = [
    "connectionUuid",
    "default",
    "nodeUuid",
    "type",
    "value",
    "valueType",
    "uuid",
  ];
  props.forEach((prop) => {
    Vue.set(target, prop, source[prop]);
  });
};

function removeTempUuid(x: { uuid?: string }) {
  if (x.uuid?.startsWith("new")) {
    delete x.uuid;
  }
}

export interface ConditionEditorState {
  appUuid: string;

  name: string;
  order?: number;
  conditionUuid: string;
  conditionGroupUuid?: string;
  groups: LogicRuleGroup[];

  selectedWidgetId: string;

  widgetRefs: { widgetId: string; parentRepeaterWidgetId?: string }[];

  invalidRules: string[];
  showErrors: boolean;
  hasUnsavedChanges: boolean;
  isCreating: boolean;

  connections: DataConnection[] | null;
  columns: SchemaNode[];

  isLoadingConnections: boolean;
  isLoadingColumns: boolean;

  updateCounter: number;
  description: ConditionRuleToken[];
  nodeDescriptions: Record<string, string>;
}

export const useConditionEditorStore = defineStore("conditionEditor", {
  state: (): ConditionEditorState => {
    return {
      appUuid: "",
      name: "",
      order: undefined,
      conditionUuid: "",
      groups: [],

      selectedWidgetId: "",
      widgetRefs: [],

      invalidRules: [],
      showErrors: false,
      hasUnsavedChanges: false,
      isCreating: false,

      connections: null,
      columns: [],

      isLoadingConnections: false,
      isLoadingColumns: false,

      updateCounter: 0,
      description: [],
      nodeDescriptions: {},
    };
  },

  getters: {
    /**
     * If the selected widget is a child of a repeater and the repeater has a data connection,
     * this will return the data connection. Otherwise, it will return undefined.
     */
    repeaterConnectionId: (state): string | undefined => {
      // All widgets share the same parent because we enforce it during copy/paste.
      const parentWidgetId = state.widgetRefs?.[0]?.parentRepeaterWidgetId;

      if (typeof parentWidgetId === "undefined") {
        return undefined;
      }

      const appEditor = useAppEditorStore();
      const parentType = appEditor.widgets[parentWidgetId]?.type;

      if (parentType !== "Repeater") {
        return undefined;
      }

      const db = appEditor.dataBindings.find(
        (db) =>
          db.widgetId === parentWidgetId &&
          (db.bindingType === "DataSetParent" || db.bindingType === "DataSet")
      );

      return db?.dataConnectionUuid;
    },

    nameIsValid: (state): boolean => {
      return isNonEmptyString(state.name);
    },

    totalRules: (state): number => {
      return state.groups.reduce((acc, g) => acc + g.rules.length, 0);
    },
  },

  actions: {
    async initialize(
      appUuid: string,
      selectedWidgetId: string,
      conditionUuid: string,
      conditionGroupUuid?: string,
      parentRepeaterWidgetId?: string
    ) {
      this.$reset();
      this.appUuid = appUuid;
      this.conditionGroupUuid = conditionGroupUuid;

      this.selectedWidgetId = selectedWidgetId;

      const appEditor = useAppEditorStore();

      // We need to gather all widget references for this condition group.
      // If the currently selected widget is not in the list, we need to add it.
      const groupStore = useConditionGroupsStore();
      const group = groupStore.conditionGroups.find(
        (cg) => cg.uuid === conditionGroupUuid
      );
      const widgetRefs = group?.widgets ?? [];
      if (!widgetRefs.some((x) => x.widgetId === selectedWidgetId)) {
        widgetRefs.push({ widgetId: selectedWidgetId, parentRepeaterWidgetId });
      }
      this.widgetRefs = widgetRefs;

      if (conditionUuid === "new" && appEditor.selectedWidget !== undefined) {
        appEditor.replaceSelections([selectedWidgetId]);
        if (appEditor.selectedWidget === undefined) {
          return Promise.reject("NoWidgetSelected");
        }

        await this.loadColumns();
        this.isCreating = true;
        this.createNewCondition();
        return Promise.resolve();
      }

      return api
        .get<Condition>(`apps/${appUuid}/conditions/${conditionUuid}`)
        .then(async (condition) => {
          this.conditionUuid = condition.uuid;
          this.name = condition.name;
          this.order = condition.order;
          this.groups = condition.groups;

          await this.loadColumns();
          return this.validateRules();
        });
    },

    createRule(): LogicRule {
      return {
        uuid: `new-${makeId()}`,
        operation: null as unknown as LogicOperation,
        operandLeft: {
          connectionUuid: "",
          default: "",
          type: undefined,
          valueType: undefined,
          nodeUuid: "",
          value: "",
          uuid: `new-${makeId()}`,
        },
        operandRight: {
          connectionUuid: "",
          default: "",
          nodeUuid: "",
          type: undefined,
          valueType: undefined,
          value: "",
          uuid: `new-${makeId()}`,
        },
      };
    },

    loadColumns() {
      const uuid = this.repeaterConnectionId;
      if (typeof uuid === "undefined") {
        return Promise.resolve([]);
      }

      const columnInfoStore = useColumnInfoStore();
      return columnInfoStore.getColumns(uuid).then((columns) => {
        this.columns = columns;
      });
    },

    createRuleGroup() {
      return {
        uuid: `new-${makeId()}`,
        rules: [],
      } as LogicRuleGroup;
    },

    getOrCreateRuleGroup(ruleGroupId?: string): LogicRuleGroup {
      let ruleGroup = this.groups.find((rg) => rg.uuid === ruleGroupId);

      if (typeof ruleGroup === "undefined") {
        ruleGroup = this.createRuleGroup();
        this.groups.push(ruleGroup);
        this.hasUnsavedChanges = true;
      }
      return ruleGroup;
    },

    addRule(ruleGroupUuid?: string) {
      const ruleGroup = this.getOrCreateRuleGroup(ruleGroupUuid);
      const rule = this.createRule();
      ruleGroup.rules.push(rule);
      this.hasUnsavedChanges = true;
      this.validateRules();
    },

    createNewCondition() {
      const rule = this.createRule();
      const ruleGroup = this.createRuleGroup();
      ruleGroup.rules.push(rule);

      this.conditionUuid = `new-${makeId()}`;
      this.groups.push(ruleGroup);
      this.hasUnsavedChanges = false;
    },

    updateRule(rule: LogicRule) {
      this.groups.forEach((rg) => {
        rg.rules.forEach((r) => {
          if (r.uuid === rule.uuid) {
            this.hasUnsavedChanges = true;
            r.operation = rule.operation;
            setOperandValues(r.operandLeft, rule.operandLeft);
            setOperandValues(r.operandRight, rule.operandRight);
          }
        });
      });
      this.validateRules();
    },

    deleteRule(ruleId: string) {
      this.groups.forEach((rg, groupIndex) => {
        const index = rg.rules.findIndex((r) => r.uuid === ruleId);
        if (index > -1) {
          rg.rules.splice(index, 1);
          this.hasUnsavedChanges = true;
        }
        if (rg.rules.length === 0) {
          this.groups.splice(groupIndex, 1);
        }
      });
      this.validateRules();
    },

    changeConditionName(name: string) {
      this.name = name;
      this.hasUnsavedChanges = true;
    },

    validateRules() {
      this.updateCounter++;
      this.invalidRules = [];
      this.groups.forEach((g) => {
        g.rules.forEach((r) => {
          const isValidRule = validateRule(r);
          if (isValidRule === false) {
            this.invalidRules.push(r.uuid);
          }
        });
      });
    },

    updateCondition() {
      const appEditor = useAppEditorStore();
      const appUuid = appEditor.uuid;

      const copy: Condition = {
        uuid: this.conditionUuid,
        name: this.name,
        order: this.order,
        groups: JSON.parse(JSON.stringify(this.groups)),
      };

      copy.groups.forEach((g) => {
        removeTempUuid(g);
        g.order = undefined;
        g.rules.forEach((r, index) => {
          r.order = index;
          removeTempUuid(r);
          removeTempUuid(r.operandLeft);
          removeTempUuid(r.operandRight);
        });
      });

      return api
        .put<Condition>(`apps/${appUuid}/conditions/${copy.uuid}`, {
          condition: copy,
        })
        .then((condition) => {
          // Ensure "publish" button updates to show "republish"
          appEditor.updateApp();
          return condition;
        });
    },

    saveNewCondition() {
      const appEditor = useAppEditorStore();
      const appUuid = appEditor.uuid;

      const copy: Omit<Condition, "uuid"> = {
        name: this.name,
        order: this.order,
        groups: JSON.parse(JSON.stringify(this.groups)),
      };

      // Remove all temporary ids
      copy.groups.forEach((g) => {
        removeTempUuid(g);
        g.order = undefined;
        g.rules.forEach((r, index) => {
          r.order = index;
          removeTempUuid(r);
          removeTempUuid(r.operandLeft);
          removeTempUuid(r.operandRight);
        });
      });

      const payload: any = {
        widgets: this.widgetRefs,
        condition: copy,
      };

      if (
        typeof this.conditionGroupUuid !== "undefined" &&
        this.conditionGroupUuid !== "create"
      ) {
        payload.conditionGroupUuid = this.conditionGroupUuid;
      }

      return api
        .post<Condition>(`apps/${appUuid}/conditions`, payload)
        .then((condition) => {
          this.conditionGroupUuid = condition.conditionGroupUuid as string;

          this.widgetRefs.forEach(({ widgetId }) => {
            // Add this condition to the widget model
            appEditor.addWidgetCondition({
              widgetId: widgetId,
              conditionUuid: condition.uuid,
            });

            // Copy Data Bindings
            appEditor.cloneDataBindingsForNewCondition({
              widgetId: widgetId,
              conditionUuid: condition.uuid,
            });
          });

          // Save App
          return appEditor.updateApp().then(() => {
            return condition;
          });
        });
    },

    save() {
      this.validateRules();
      if (this.invalidRules.length > 0 || this.nameIsValid === false) {
        this.showErrors = true;
        return Promise.reject("invalid");
      }

      const action = this.isCreating ? "created" : "updated";

      const promise = this.isCreating
        ? this.saveNewCondition()
        : this.updateCondition();

      return promise.then((condition) => {
        this.hasUnsavedChanges = false;
        // Load ConditionGroup from API so the conditions are populated
        // when the user returns to the canvas.
        const appEditor = useAppEditorStore();
        const conditionGroupsStore = useConditionGroupsStore();
        return conditionGroupsStore
          .getConditionGroups(appEditor.uuid)
          .then(() => {
            conditionGroupsStore.setActiveCondition({
              conditionGroupUuid: condition.conditionGroupUuid as string,
              conditionUuid: condition.uuid,
            });
            return { action, condition };
          });
      });
    },

    loadConnections() {
      if (this.connections !== null) {
        return Promise.resolve();
      }

      const appEditor = useAppEditorStore();

      return api
        .get<DataConnection[]>(`dataconnection?appUuid=${appEditor.uuid}`)
        .then((connections) => {
          this.connections = connections ?? [];
        });
    },
  },
});
