<template>
  <div class="flex items-center">
    <!-- <Icon
      name="Color"
      ref="pickerButton"
      :style="buttonStyle"
      :class="buttonClasses"
      @click.native.stop="toggle"
    /> -->

    <div
      ref="pickerButton"
      class="rounded"
      :style="buttonStyle"
      :class="buttonClasses"
      @click.stop="toggle"
    ></div>

    <portal to="colorPicker">
      <div v-if="showPanel" @click.stop>
        <div class="flex flex-grow">
          <div class="divide-y picker drop-shadow">
            <button
              @click.stop="clickClosePanel"
              class="w-full flex space-x-1 flex h-12 px-3 items-center justify-end hover:bg-gray-100"
              :style="textStyle"
            >
              <span class="font-semibold text-sm" v-t="'close'"></span>
              <span class="font-regular text-sm" v-t="'escape'"></span>
            </button>
            <ColorPanel
              @click.native.stop
              ref="colorPanel"
              :value="value"
              :gradientEnabled="gradientEnabled"
              @input="handleColorPreview"
              @change="handleColorChange"
              @selectSwatch="handleColorSelection"
            />
          </div>
          <button
            class="flex items-start w-5 bg-gray-300 button-shadow"
            @click.stop="clickClosePanel"
          >
            <Icon name="ChevronRight" class="w-5 h-5 mt-4" />
          </button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import gsap from "gsap";
import Icon from "@/components/icons/Icon.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ComponentEditorPanel from "@/components/ComponentEditorPanel.vue";
import ColorPanel from "@/components/ColorPanel.vue";
import { EventBus } from "@/eventbus";
import tinycolor from "tinycolor2";

// - [ ] TODO: If using svg for button, need to ensure it has gradient defs....maybe just use a div??

/**
 * TODO: Handle styling/read-only for dataBound case
 */

const animationParams = {
  duration: 0.6,
  ease: "power1.inOut",
};

const pickerId = "#colorPicker";

@Component({
  components: {
    Icon,
    CollapsePanel,
    ComponentEditorPanel,
    ColorPanel,
  },
})
export default class ColorInput extends Vue {
  @Prop(String) value: string;
  @Prop(String) iconSize: string;
  @Prop(String) tooltip: string;
  @Prop(Boolean) gradientEnabled: boolean;
  @Prop(Boolean) dataBound: boolean;

  showPanel = false;
  panelIsVisible = false;
  panelIsClosing = false;
  clickFromHexInput = false;

  get buttonStyle() {
    const style = {
      background: this.value,
      boxShadow: "inset 0 0 0 1px rgb(0 0 0 / 20%)",
    };

    return style;
  }

  get textStyle() {
    const result: any = {};
    (result.fontFamily = "Inter"), "sans-serif";
    return result;
  }

  get iconSizeClasses() {
    switch (this.iconSize) {
      case "3":
        return ["h-3", "w-3"];
      case "4":
        return ["h-4", "w-4"];
      case "5":
        return ["h-5", "w-5"];
      case "6":
        return ["h-6", "w-6"];
      case "7":
        return ["h-7", "w-7"];
      case "8":
        return ["h-8", "w-8"];
      default:
        return ["h-6", "w-6"];
    }
  }

  get buttonClasses() {
    const classes = ["swatch-outline"];
    if (this.value === "hsla(0, 0%, 0%, 0)") {
      classes.push("swatch-transparent");
    }
    if (this.dataBound) {
      classes.push("border border-app-gold cursor-auto");
    }
    return classes.concat(this.iconSizeClasses);
  }

  toggle() {
    // ev.stopPropagation();

    if (this.dataBound) return;

    if (this.showPanel) {
      console.log("toggle");
      this.closePanel();
      return;
    }

    this.showPanel = true;

    // This is causing bug with drag mouse off of input.....
    document.body.addEventListener("click", this.closePanel);
    document.addEventListener("keydown", this.onKeyDown);
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.closePanel();
    }
  }

  closePanel() {
    //  If user mousedowns within hex input, then drags mouse off of color panel before mouseup, panel should not close
    if (this.clickFromHexInput) {
      return;
    }
    if (this.panelIsClosing) {
      return;
    }

    this.panelIsClosing = true;
    document.removeEventListener("keydown", this.onKeyDown);
    document.body.removeEventListener("click", this.closePanel);
    const picker = document.querySelector(pickerId) as HTMLElement;
    // console.log("picker", picker);
    if (picker) {
      let pickerBox = picker.getBoundingClientRect();

      gsap.to(
        picker,
        Object.assign({}, animationParams, {
          x: pickerBox.width + 10,

          onComplete: () => {
            this.showPanel = false;
            this.panelIsVisible = false;
            this.panelIsClosing = false;
          },
        })
      );
      gsap.to(picker, {
        opacity: 0,
        duration: 0.1,
        delay: 0.3,
        ease: "power1.out",
      });
    }
  }

  clickClosePanel() {
    this.clickFromHexInput = false;
    this.closePanel();
  }

  created() {
    EventBus.on("COLOR_PICKER_MOUNTED", this.positionColorPicker);
    EventBus.on("WINDOW_RESIZE", this.positionColorPicker);
    EventBus.on("CLICK_FROM_HEX_INPUT", this.updateClickFromHexInput);
  }

  beforeDestroy() {
    EventBus.off("COLOR_PICKER_MOUNTED", this.positionColorPicker);
    EventBus.off("WINDOW_RESIZE", this.positionColorPicker);
    EventBus.off("CLICK_FROM_HEX_INPUT", this.updateClickFromHexInput);
  }

  updateClickFromHexInput(val: any) {
    this.clickFromHexInput = val as boolean;
  }

  positionColorPicker() {
    if (this.showPanel && !this.panelIsVisible) {
      this.panelIsVisible = true;
      const picker = document.querySelector(pickerId) as HTMLElement;
      let pickerBox = picker.getBoundingClientRect();
      gsap.to(picker, { opacity: 1, duration: 0.3, ease: "power1.in" });
      gsap.from(
        picker,
        Object.assign({}, animationParams, {
          x: pickerBox.width + 10,
          delay: 0.1,
        })
      );
    }
  }

  handleColorChange(value: string) {
    this.$emit("change", value);
  }

  handleColorSelection(value: string) {
    // Preserve color alpha when click a new color swatch:
    const newAlpha = tinycolor(value).getAlpha();
    const currentAlpha =
      newAlpha > 0 ? tinycolor(this.value).getAlpha() : newAlpha;

    let newValue = value;
    if (currentAlpha > 0) {
      newValue = tinycolor(value).setAlpha(currentAlpha).toHslString();
    }

    this.$emit("change", newValue);
  }

  handleColorPreview(value: string) {
    this.$emit("input", value);
  }
}
</script>
<style lang="postcss">
.picker {
  width: calc(100% - theme("spacing.5"));
}

.button-shadow {
  box-shadow: inset 4px 0 8px -4px rgba(0, 0, 0, 0.5);
}
</style>
