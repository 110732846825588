export interface TransformOptions {
  // Position properties
  w: number;
  h: number;
  x: number;
  y: number;
  z: number;

  // Transform properties
  angle: number;
  scaleX: number;
  scaleY: number;

  // Resize Options
  canScaleY: boolean;
  canScaleX: boolean;
  lockAspect: boolean;

  opacity: number;

  [key: string]: any;
}

export const DefaultTransformOptions: TransformOptions = {
  w: 540,
  h: 360,
  x: 0,
  y: 0,
  z: 0,
  opacity: 100,
  angle: 0,
  scaleX: 1,
  scaleY: 1,
  canScaleY: true,
  canScaleX: true,
  lockAspect: false,
};
