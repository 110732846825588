<template>
  <svg
    width="24px"
    height="24px"
    viewBox="4 4 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="transform origin-center"
  >
    <g>
      <polygon
        stroke-linejoin="round"
        fill="white"
        fill-rule="evenodd"
        points="9 17 17 7 17 13 23 13 15 23 15 17"
      ></polygon>
    </g>
  </svg>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class LightningBoltIconWhite extends Vue {}
</script>
