<template>
  <div></div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class CalendarBase extends Vue {
  /**
   * Returns an object containing all the text props
   * that would affect the size of the layout.
   *
   * This is useful for Wrappers that need to update
   * their height when these props change.
   */
  get textLayoutProps() {
    const propSuffixes = [
      "fontFamily",
      "fontWeight",
      "fontSize",
      "letterSpacing",
      "textTransform",
      "fontStyle",
      "lineHeight",
    ];
    return Object.keys(this.$props).reduce((acc, prop) => {
      if (propSuffixes.some((suffix) => prop.endsWith(suffix))) {
        return Object.assign(acc, { [prop]: (this as any)[prop] });
      }
      return acc;
    }, {});
  }

  /**
   * Calendars have a lot of props in the form of
   *
   * `${element}_${prop} -> title_fontFamily`
   *
   * This method returns an array of all such elements
   */
  get textElements() {
    return Object.keys(this.$props)
      .filter((prop) => prop.endsWith("_fontFamily"))
      .map((prop) => prop.replace("_fontFamily", ""));
  }

  /**
   * Fits all text elements in the calendar.
   *
   * Uses a minimum size of 12px.
   *
   * Returns an object containing all text props
   * with resized font sizes.
   */
  resizeText(wid: string, initialSizes?: any) {
    return this.textElements.reduce((acc, prefix) => {
      if (prefix !== undefined) {
        const size = this.resizeTextElements(prefix, wid, initialSizes);
        (acc as any)[`${prefix}_fontSize`] = size;
      }
      return acc;
    }, {});
  }

  resizeTextElements(prefix: string, wid: string, initialSizes?: any) {
    const textArray = Array.from(
      document.getElementsByClassName(`${prefix}-${wid}`)
    ) as HTMLElement[];

    // If `initialSizes` is provided it means we're resizing during
    // a drag action, and we want the text to be able to expand up to
    // the original default size for each element.

    // If `initialSizes` is not provided, it means we're resizing
    // due to an explicit user action (in the editor), and we'll
    // use the widgets current font size as the max size.

    const userDefinedSize = (this as any)[`${prefix}_fontSize`];
    const maxFontSize = initialSizes?.[`${prefix}_fontSize`] ?? userDefinedSize;
    const newSizes = textArray.map((el) => this.resizeTextEl(el, maxFontSize));
    const minSize = Math.min(...newSizes);

    textArray.forEach((el) => {
      el.style.fontSize = `${minSize}px`;
    });

    return minSize;
  }

  resizeTextEl(el: HTMLElement, maxFontSize: number) {
    const style = window.getComputedStyle(el);

    // Temporarily set to full width
    el.style.width = "100%";
    el.style.display = "block";

    // Get font size as a number
    let fontSize = parseInt(style.fontSize || "", 10);

    // If current font size is smaller than initial, reset to initial
    if (fontSize < maxFontSize) {
      el.style.fontSize = `${maxFontSize}px`;
      fontSize = maxFontSize;
    }

    // While text is too wide, reduce font size to no less than 12px
    while (el.scrollWidth > el.offsetWidth && fontSize >= 12) {
      fontSize--;
      el.style.fontSize = `${fontSize}px`;
    }

    // Reset style to original
    el.style.width = style.width;
    el.style.display = style.display;

    return fontSize;
  }

  textStyleProps = [
    "fontFamily",
    "textColor",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "textAlign",
    "fontStyle",
    "textTransform",
  ];

  getStyles(prefix: string, isCalendarEvent = false) {
    const vm = this as any;
    const result: any = {};
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontSize`]) {
      result.fontSize = `${vm[`${prefix}_fontSize`]}px`;
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.color = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_letterSpacing`]) {
      result.letterSpacing = `${vm[`${prefix}_letterSpacing`]}pt`;
    }
    if (vm[`${prefix}_lineHeight`]) {
      result.lineHeight = vm[`${prefix}_lineHeight`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }
    if (vm[`${prefix}_textDecoration`]) {
      result.textDecoration = vm[`${prefix}_textDecoration`];
    }
    if (vm[`${prefix}_textAlign`]) {
      result.textAlign = vm[`${prefix}_textAlign`];
    }
    if (vm[`${prefix}_textAlign`]) {
      let justifyContent = "center";
      switch (vm[`${prefix}_textAlign`]) {
        case "left":
          justifyContent = "flex-start";
          break;
        case "right":
          justifyContent = "flex-end";
          break;
      }

      if (prefix === "eventCreator" && isCalendarEvent) {
        let alignItems = "center";
        switch (vm[`${prefix}_textAlign`]) {
          case "left":
            alignItems = "flex-start";
            break;
          case "right":
            alignItems = "flex-end";
            break;
        }
        result.alignItems = alignItems;
        return result;
      }
      result.justifyContent = justifyContent;

      // NOTE: can likely refactor Event so we don't need this
      if (isCalendarEvent) {
        result.textAlign = vm[`${prefix}_textAlign`];
      }
    }
    return result;
  }
}
</script>

<style></style>
