<template>
  <input
    :id="makeKeyId(rowUuid, node.uuid || '')"
    type="text"
    :value="value"
    @input="handleInput"
    @click.stop
    @blur="$emit('blur', $event)"
  />
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import InputBase from "@/components/data/connections/manualSource/inputs/InputBase.vue";

@Component({
  components: {},
})
export default class TextInput extends InputBase {
  mounted() {
    (this.$el as HTMLInputElement).focus();
    (this.$el as HTMLInputElement).select();
  }
}
</script>
