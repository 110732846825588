<template>
  <input
    type="text"
    :value="value"
    ref="input"
    class="wrapper flex px-2 items-center bg-app-panel focus:outline-none focus:bg-white justify-center relative border-1 ring-1 ring-transparent border-transparent hover:border-gray-200 focus-within:ring-app-teal focus-within:bg-white rounded"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @dblclick="onDoubleClick"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EditorTextInput extends Vue {
  @Prop() value: any;

  onDoubleClick() {
    let input = this.$refs.input as HTMLInputElement;
    input.select();
  }
}
</script>
