import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface BarGraphOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  h: number;
  w: number;
  topMargin: number;
  rightMargin: number;
  bottomMargin: number;
  leftMargin: number;
  minValue: number;
  maxValue: number;
  barPadding: number;
  xTitle: string;
  yTitle: string;

  title_fontFamily: string;
  title_fontSize: number;
  title_fontWeight: number;
  title_textColor: string;
  title_textTransform: string;
  label_fontFamily: string;
  label_fontSize: number;
  label_fontWeight: number;
  label_textColor: string;
  label_textTransform: string;

  lineColor: string;
  lineThickness: number;
  initialSet: boolean;
  labelTicks: number;
  leftTitlePadding: number;
  bottomTitlePadding: number;
  bgColor: string;
  barColor: string;
  customBarColors: string[];
  useCustomBarColors: boolean;
  showBg: boolean;
  showGrid: boolean;
  showValues: boolean;
  xAxisColumnId: string;
  yAxisColumnId: string;
  xTitleManual: string | null;
  yTitleManual: string | null;
  minManual: number | null;
  maxManual: number | null;
}

export const defaultBarGraphOptions = {
  h: 400,
  w: 500,
  minValue: 0,
  maxValue: 4130,
  barPadding: 0.55,
  xTitle: "Name",
  yTitle: "Length (mi.)",
  title_fontFamily: "Montserrat",
  title_fontSize: 36,
  title_fontWeight: 300,
  title_fontStyle: "normal",
  title_textColor: "#000000",
  title_textTransform: "none",
  label_fontFamily: "Montserrat",
  label_fontSize: 20,
  label_fontWeight: 700,
  label_fontStyle: "normal",
  label_textColor: "#000000",
  label_textTransform: "capitalize",
  lineColor: "#000000",
  lineThickness: 0,
  labelTicks: 6,
  leftTitlePadding: 15,
  bottomTitlePadding: 15,
  bgColor: "#f2f2f2",
  barColor: "#113054",
  customBarColors: ["#0C3656", "#185783", "#2984C5", "#3ABFF8", "#BAE5FD"],
  useCustomBarColors: true,
  xLabelTilt: false,
  showBg: false,
  showGrid: false,
  showValues: false,
  xTitleManual: null,
  yTitleManual: null,
  minManual: null,
  maxManual: null,
  /**
   * Stores the UUID of the data column representing the x axis
   */
  xAxisColumnId: "b",
  /**
   * Stores the UUID of the data column representing the y axis
   */
  yAxisColumnId: "a",
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultBarGraphOptions,
};

const CreateBarGraph = (options: Partial<BarGraphOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "BarGraph",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.BarGraph,
  });
};

export default CreateBarGraph;
