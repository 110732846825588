import { NodeKind } from "@/types/data";

export const ColumnGraphPlaceholderData = [
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Burj Khalifa",
      formattedValue: "Burj Khalifa",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Height (m)",
      uuid: "b",
      value: 830,
      formattedValue: "830",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 0,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Shanghai Tower",
      formattedValue: "Shanghai Tower",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Height (m)",
      uuid: "b",
      value: 632,
      formattedValue: "632",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 1,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Abraj Al-Bait",
      formattedValue: "Abraj Al-Bait",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Height (m)",
      uuid: "b",
      value: 601,
      formattedValue: "601",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 2,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Taipei 101",
      formattedValue: "Taipei 101",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Height (m)",
      uuid: "b",
      value: 508,
      formattedValue: "508",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 3,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Empire State Building",
      formattedValue: "Empire State Building",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Height (m)",
      uuid: "b",
      value: 381,
      formattedValue: "381",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 4,
      query: "__sf_index__",
    },
  ],
].map((row) => {
  return row.map((node) => {
    return {
      ...node,
      kind: NodeKind.node,
      isArtificial: node.displayName === "Index",
    };
  });
});
