<template>
  <div class="flex h-full space-x-6 items-center justify-between">
    <div class="flex items-center flex-grow space-x-4 text-black">
      <SearchBar
        :placeholder="searchPlaceholder"
        @onSearchInput="onSearchInput"
        @onSearchKeydown="onSearchKeydown"
        @onSearchEnter="onSearchEnter"
        @blur="store.setSelectedIndex(-1)"
      />

      <!-- Sorting options: -->
      <div class="h-full flex items-center w-40">
        <SelectMenu
          :label="sortLabel"
          class="w-full h-8 text-[12px]"
          v-model="sort"
          :textColor="'gray-500'"
          :hoverColor="'gray-200'"
          :checkColor="'gray-500'"
          :options="appSortOptions"
          :rounded="true"
          :fullWidth="true"
          :dark="true"
        />
      </div>
    </div>

    <!-- View control: -->
    <div class="flex space-x-2 shrink-0">
      <div
        v-for="view in views"
        :key="view.name"
        class="h-8 w-8 cursor-pointer icon view-control shrink-0"
        :class="{ 'opacity-100': view.selected, 'opacity-40': !view.selected }"
        @click="setDisplay(view.path)"
      >
        <Icon :name="view.icon" />
      </div>
    </div>

    <ActionModal
      v-if="isCloneModalOpen"
      @on-accept="onCloneApp"
      @on-reject="isCloneModalOpen = false"
    >
      <div class="flex flex-col space-y-2">
        <div>Please enter a name for your app:</div>
        <input
          v-model="newAppName"
          class="w-full rounded px-2 dark-form-focus text-[14px] text-black py-2"
        />
      </div>
    </ActionModal>

    <ActionModal
      v-if="isDeleteModalOpen"
      size="1/2"
      @on-accept="onDeleteApp"
      @on-reject="isDeleteModalOpen = false"
    >
      <div>Are you sure you want to delete this app?</div>
    </ActionModal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import debounce from "lodash.debounce";

import IconSolid from "@/components/icons/IconSolid.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import Icon from "@/components/icons/Icon.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import { useAppsStore, AppSearchParams } from "@/stores/apps";
import SearchBar from "@/views/Dashboard/SearchBar.vue";
import { EventBus } from "@/eventbus";
import { AppListAction } from "@/types";
import ActionModal from "@/components/ActionModal.vue";
import { logger } from "@core/logger";

const views = [
  {
    name: "Grid",
    icon: "ViewGrid",
    path: "grid",
  },
  {
    name: "List",
    icon: "ViewList",
    path: "list",
  },
];

@Component({
  components: {
    IconSolid,
    SelectMenu,
    ButtonGradient,
    Icon,
    AppsHeader,
    SearchBar,
    ActionModal,
  },
})
export default class AppsHeader extends Vue {
  isCloneModalOpen = false;
  isDeleteModalOpen = false;
  editingAppUuid: string | null = null;
  newAppName = "";

  onCloneApp() {
    EventBus.emit("AWAITING_SERVER", true);
    this.store
      .cloneApp(this.editingAppUuid || "", this.newAppName)
      .then((app) => {
        this.$router.push(`/app/edit/${app.uuid}`);
      })
      .catch((err) => {
        logger.track(err);
        window.alert(this.$t("unknownServerError") as string);
      })
      .finally(() => {
        EventBus.emit("AWAITING_SERVER", false);
      });
  }

  onDeleteApp() {
    EventBus.emit("AWAITING_SERVER", true);
    this.store
      .archiveApp(this.editingAppUuid || "")
      .then(() => {
        return this.store.loadApps();
      })
      .catch((err) => {
        logger.track(err);
        window.alert(this.$t("unknownServerError") as string);
      })
      .finally(() => {
        EventBus.emit("AWAITING_SERVER", false);
        this.isDeleteModalOpen = false;
      });
  }

  openDeleteModal(uuid: string) {
    this.editingAppUuid = uuid;
    this.isDeleteModalOpen = true;
  }

  openCloneModal(uuid: string, name: string) {
    this.editingAppUuid = uuid;
    this.newAppName = `${name} - copy`;
    this.isCloneModalOpen = true;
  }

  get store() {
    return useAppsStore();
  }

  get searchPlaceholder() {
    return this.$t("appList.searchApps").toString();
  }

  get searchParams() {
    const result: any = {};

    if (this.$route.query.sort) {
      result.sort = this.$route.query.sort;
    }

    if (this.$route.query.search) {
      result.search = this.$route.query.search;
    }

    if (this.$route.query.dummyImages) {
      result.dummyImages = this.$route.query.dummyImages;
    }
    return result;
  }

  handleOpenDashboardModal(payload: {
    action: AppListAction;
    uuid: string;
    name?: string;
  }) {
    if (payload.action === "duplicate") {
      this.openCloneModal(payload.uuid, payload.name || "");
    }
    if (payload.action === "delete") {
      this.openDeleteModal(payload.uuid);
    }
  }

  mounted() {
    this.store.searchApps(this.searchParams);
    EventBus.on("OPEN_DASHBOARD_APP_MODAL", this.handleOpenDashboardModal);
  }

  beforeDestroy() {
    EventBus.off("OPEN_DASHBOARD_APP_MODAL", this.handleOpenDashboardModal);
  }

  @Watch("searchParams", { deep: true })
  onSearchParamsChanged(params: AppSearchParams) {
    this.store.searchApps(params);
  }

  setDisplay(path: string) {
    this.$router.push({ path: `/apps/${path}`, query: this.searchParams });
  }

  get views() {
    return views.map((v: any) => {
      const selected = this.$route.path.endsWith(v.path);
      return Object.assign({}, v, { selected });
    });
  }

  get appSortOptions() {
    return [
      { label: this.$t("apps.sortModified"), value: "modified" },
      { label: this.$t("apps.sortCreated"), value: "created" },
      { label: this.$t("apps.sortNameAsc"), value: "nameAsc" },
      { label: this.$t("apps.sortNameDesc"), value: "nameDesc" },
    ];
  }

  setQueryParam(key: string, value: string) {
    const queryCopy = { ...this.$route.query };
    if (typeof value === "string" && value.trim().length > 0) {
      queryCopy[key] = value.trim();
    } else {
      delete queryCopy[key];
    }
    this.$router.replace({ query: queryCopy });
  }

  get sortLabel() {
    // Do not label as "Sort by Sort A-Z"
    if (this.sort.includes("name")) return "";
    return this.$t("sortBy");
  }

  get sort() {
    return (this.$route.query["sort"] as string) ?? "modified";
  }

  set sort(value: string) {
    this.setQueryParam("sort", value);
  }

  get search() {
    return this.$route.query["search"] as string;
  }

  onSearchInputInner(e: Event) {
    this.setQueryParam("search", (e.target as HTMLInputElement)?.value);
  }

  onSearchInput = debounce(this.onSearchInputInner, 250);

  onSearchKeydown(e: KeyboardEvent) {
    if (e.key === "ArrowDown") {
      this.store.setSelectedIndex(this.store.selectedIndex + 1);
    }
    if (e.key === "ArrowUp") {
      let newIndex = this.store.selectedIndex - 1;
      if (newIndex < -1) {
        newIndex = -1;
      }
      this.store.setSelectedIndex(newIndex);
    }
  }

  onSearchEnter() {
    if (this.store.selectedApp) {
      this.$router.push({
        name: "edit",
        params: { id: this.store.selectedApp.uuid },
      });
    }
  }
}
</script>
