import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  ShadowOptions,
  DefaultShadowOptions,
} from "@/components/widgets/ShadowOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface TriangleOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    ShadowOptions,
    AnimationOptions,
    BackgroundOptions {
  strokeLinejoin: "miter" | "round" | "bevel" | "inherit";
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...DefaultShadowOptions,
  ...getDefaultAnimationOptions(),
  strokeLinejoin: "round",
};

const CreateTriangle = (options: Partial<TriangleOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "Triangle",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.Triangle,
  });
};

export default CreateTriangle;
