<template>
  <div class="mt-6">Expanded view</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import IconSolid from "@/components/icons/IconSolid.vue";

@Component({
  components: {
    IconSolid,
  },
})
export default class DashboardAppsListExpandedView extends Vue {}
</script>
