<template>
  <div :style="styles" class="hover:bg-black" ref="container">
    <div
      class="relative cell-target"
      :class="cell.classes"
      :style="cell.style"
      :key="cellIndex"
      ref="repeaterCell"
      :data-idx="cellIndex"
      v-for="(cell, cellIndex) in cells"
    >
      <div
        v-if="cell.widgets.length === 0 && isBaseEditingContext && isHovered"
        class="w-full h-full edit items-center justify-center text-2xl text-gray-600 flex text-center select-none"
        v-t="'repeaterEditor.clickToEdit'"
      ></div>
      <div
        :id="getSelector(w.wid, cellIndex)"
        v-for="(w, widgetIndex) in cell.widgets"
        :key="`w-${cellIndex}-${widgetIndex}`"
        :style="componentStyle(w)"
        class="relative"
      >
        <div
          class="absolute -top-8 left-0 rounded border border-app-teal p-1"
          v-if="showWidgetIdDebugText"
        >
          Widget Id: {{ w.wid }}
        </div>
        <component
          :renderWid="w.wid"
          :is="$helpers.getWidgetComponent(w.type)"
          v-bind="w"
          :cellIndex="cellIndex"
        />
      </div>
    </div>

    <div
      class="fixed flex items-center justify-center h-full w-full bg-gray-100 opacity-80 p-8 rounded text-4xl"
      v-if="emptyDatasetMessage"
    >
      {{ emptyDatasetMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { StyleValue } from "vue/types/jsx";
import { gsap } from "gsap";

import { AppMode } from "@/types";
import { DataBinding, NodeData } from "@/types/data";
import {
  allSettled,
  isNearWhite,
  isTransparent,
  loadImages,
  range,
  scaleWidget,
} from "@/utils";
import { getActiveWidget } from "@/util/conditionsUtils";
import { styler } from "@/lib/free-transform";
import { EventBus } from "@/eventbus";
import { GlobalAppState } from "@/GlobalAppState";
import { animate, getAnimationId } from "@/components/widgets/Animation";
import { Widget, WidgetWithConditions } from "@/components/widgets/Widget";
import { RepeaterFlowValue } from "@/components/widgets/Repeater/RepeaterOptions";
import { getImageQueryParams } from "@/utils";

@Component
export default class RepeaterComponent extends Vue {
  @Inject() appMode: AppMode;
  @Inject() context: GlobalAppState;

  @Prop(String) wid: string;
  @Prop(Number) height: number;
  @Prop(Number) width: number;
  @Prop(String) dataUuid: string;
  @Prop(String) borderColor: string;
  @Prop(Number) borderWidth: number;
  @Prop(String) backgroundColor: string;
  @Prop(String) cellBackgroundColor: string;
  @Prop(String) flow: RepeaterFlowValue;
  @Prop(Number) scaleX: number;
  @Prop(Number) scaleY: number;
  @Prop(Number) x: number;
  @Prop(Number) y: number;
  @Prop(Number) w: number;
  @Prop(Number) h: number;

  @Prop(Boolean) hideEmptyCells: boolean;
  @Prop(Boolean) locked: boolean;
  @Prop({ type: Number, default: 1 }) rows: number;
  @Prop({ type: Number, default: 0 }) rowGap: number;
  @Prop({ type: Number, default: 1 }) columns: number;
  @Prop({ type: Number, default: 0 }) columnGap: number;
  @Prop({ type: Array, default: () => [] }) data: NodeData[][];

  @Prop(Boolean) cycleContent: boolean;
  @Prop({ type: Number, default: 10 }) cycleDuration: number;
  @Prop(String) cycleAnimationStyle: string;
  @Prop(Number) cycleAnimationDuration: number;
  @Prop(Boolean) sortData: boolean;

  // ============================================================
  // ANIMATION START
  // ============================================================

  pageIndex = 0;
  cycleTimeout: number;
  allImagesLoaded = false;
  preloadImagesPromise: null | Promise<unknown[]> = null;

  /**
   * ------------------------------------------------------------------
   * The following props are modified by RepeaterWrapper.
   * RepeaterWrapper has access to Pinia, RepeaterComponent does not.
   */
  isHovered = false;
  cellEditIndex: number | null = null;
  isBaseEditingContext = true;
  isRepeaterBeingEdited = false;
  isDraggingImage = false;
  isDraggingItem = false;
  /* ------------------------------------------------------------------ */

  async next() {
    if (this.preloadImagesPromise) await this.preloadImagesPromise;

    // Gsap animation and cycle swap
    const cellDivs = this.$el.querySelectorAll(".cell-target");

    // Animate out data out, swap data, then animate new data in:
    gsap.to(cellDivs, {
      ...this.animationParams.outTo,
      onComplete: () => {
        // Does this need to be broadcast?
        // How does text editor widget know which index this repeater is on?
        this.pageIndex++;
        gsap.set(cellDivs, { ...this.animationParams.reset });
        this.$nextTick(() => {
          // In case hideCellsWithoutData is on and previous page had cells without data, causing new cells to be created here:
          animate(this);
          const cellDivsRefresh = this.$el.querySelectorAll(".cell-target");
          gsap.from(cellDivsRefresh, { ...this.animationParams.inFrom });
        });
        this.preloadImagesPromise = this.preloadNextImages();
        this.cycleTimeout = window.setTimeout(
          this.next,
          this.cycleDuration * 1000
        );
      },
    });
  }

  /**
   * This will only grab dynamic images. Repeated static images should already be loaded.
   * We need to know which widget is associated with each image as well, in order to determine its query params accurately, and thus preload it successfully.
   */
  preloadNextImages() {
    if (this.allImagesLoaded) return null;
    const nextPage = (this.page + 1) % this.pageCount;

    const dataBindings: DataBinding[] = this.context?.dataBindings || [];

    if (nextPage === 0) {
      /**
       * We have reached the last page, and are looping back to start.
       * This should mean all images are loaded and we can stop doing this.
       */
      this.allImagesLoaded = true;
      return null;
    }
    const startIdx = this.pageSize * nextPage;
    const nextRecords = this.data.slice(startIdx, startIdx + this.pageSize);

    // Find all data bindings that are relevant to image-preloading
    const relevantBindings = dataBindings.filter(
      (db) =>
        this.childrenWids.includes(db.widgetId) &&
        db.bindingType === "DataSetNode" &&
        db.property.toLowerCase().includes("url")
    );

    // For each relevant binding, find the widget it's associated with in order to get the proper query params for the image url.
    const nextImageUrls: string[] = relevantBindings.map((binding) => {
      const widget = this.childWidgets[binding.widgetId];
      const flatWidget = getActiveWidget(widget, this.context.conditions);

      // Must apply renderScale here
      flatWidget.w = Math.round(flatWidget.w * (this.context.renderScale || 1));
      flatWidget.h = Math.round(flatWidget.h * (this.context.renderScale || 1));

      const imageQueryParams = getImageQueryParams(flatWidget);
      const imageUrl = (nextRecords[0].find((x) => x.uuid === binding.dataUuid)
        ?.value || "") as string;
      const imageQuerySeparator = imageUrl.indexOf("?") > 0 ? "&" : "?";
      return `${imageUrl}${imageQuerySeparator}${imageQueryParams}`;
    });

    return nextImageUrls.length > 0
      ? allSettled(loadImages(nextImageUrls))
      : null;
  }

  get animationParams() {
    const timeParams = {
      stagger: 0.15,
      duration: 0.4,
    };
    let outTo: gsap.TweenVars = { opacity: 0, ...timeParams };
    let reset: gsap.TweenVars = { opacity: 1 };
    let inFrom: gsap.TweenVars = { opacity: 0, ...timeParams };

    let newOutTo: gsap.TweenVars = {};
    let newReset: gsap.TweenVars = {};
    let newInFrom: gsap.TweenVars = {};

    switch (this.cycleAnimationStyle) {
      case "none":
        outTo = { duration: 0.01 };
        inFrom = { duration: 0.01 };
        break;
      case "fade":
        break;
      case "flip":
        newOutTo = { rotation: -60 };
        newReset = { rotation: 0 };
        newInFrom = { rotation: 60 };
        break;
      case "scale":
        newOutTo = { scale: 0 };
        newReset = { scale: 1 };
        newInFrom = { scale: 0 };
        break;
      case "slideUp":
        newOutTo = { y: -50 };
        newReset = { y: 0 };
        newInFrom = { y: 50 };
        break;
      case "slideDown":
        newOutTo = { y: 50 };
        newReset = { y: 0 };
        newInFrom = { y: -50 };
        break;
      case "slideLeft":
        newOutTo = { x: -50 };
        newReset = { x: 0 };
        newInFrom = { x: 50 };
        break;
      case "slideRight":
        newOutTo = { x: 50 };
        newReset = { x: 0 };
        newInFrom = { x: -50 };
        break;
    }

    outTo = { ...outTo, ...newOutTo };
    reset = { ...reset, ...newReset };
    inFrom = { ...inFrom, ...newInFrom };

    return { outTo, reset, inFrom };
  }

  beginCycling() {
    if (this.appMode === "render" && this.cycleContent) {
      this.preloadImagesPromise = this.preloadNextImages();
      this.cycleTimeout = window.setTimeout(
        this.next,
        this.cycleDuration * 1000
      );
    }
  }

  beforeDestroy() {
    clearTimeout(this.cycleTimeout);
    EventBus.off("PLAYBACK_BEGIN", this.beginCycling);
  }

  created() {
    EventBus.on("PLAYBACK_BEGIN", this.beginCycling);
  }

  get showWidgetIdDebugText() {
    return this.$route?.query.debug === "true";
  }

  // ============================================================
  // ANIMATION END
  // ============================================================

  getSelector(widgetId: string, cellIndex: number) {
    return getAnimationId(widgetId, cellIndex);
  }

  bindWidgetData(widgetIds: string[], dataRowIndex = 0): Widget[] {
    // NOTE: Now relying on this.data[idx] being same as this.widgetData[wid][idx].
    // Can we rely on that?
    // Otherwise we have to hack the groupUuid onto the dynamic props in this.widgetData[wid]
    return widgetIds
      .map((wid) => {
        const groupUuid = this.data?.[dataRowIndex]?.[0]?.groupUuid;
        const wg = getActiveWidget(
          this.context.widgets[wid],
          this.context.conditions,
          groupUuid
        );
        if (this.appMode === "render") {
          return scaleWidget(wg, this.context.renderScale);
        }
        return wg;
      })
      .filter((w) => w)
      .map((w) => {
        const dynamicProps = this.context.widgetData[w.wid][dataRowIndex] ?? {};
        return {
          ...w,
          ...dynamicProps,
          dataRowIndex,
          cycleDuration: this.cycleDuration,
        };
      });
  }

  get dataLength() {
    return this.data?.length ?? 0;
  }

  get page() {
    return this.pageIndex % this.pageCount;
  }

  get pageSize() {
    return this.rows * this.columns;
  }

  get pageCount() {
    if (this.data?.length > 0) {
      let len = this.data?.length;
      return Math.ceil(Math.max(1, len) / this.pageSize);
    }
    return 1;
  }

  get childrenWids() {
    return this.context.parents[this.wid] ?? [];
  }

  get childWidgets() {
    const result: Record<string, WidgetWithConditions> = {};
    for (const wid in this.context.widgets) {
      if (this.childrenWids.includes(wid)) {
        result[wid] = this.context.widgets[wid];
      }
    }
    return result;
  }

  isModerated = false;

  get isFiltered() {
    return !!this.dataBinding?.filterUuid;
  }

  get importedRecordCount() {
    const d = this.context.widgetData[this.wid];
    return d[0]?.importedRecordCount;
  }

  // NOTE: this.importedRecordCount will be undefined if there is no dataset bound to the repeater
  get emptyDatasetMessage() {
    // The user has bound a dataset to the repeater, and either a filter or moderation has caused it to come back empty
    if (this.importedRecordCount > 0 && this.dataLength === 0) {
      if (this.isFiltered && this.isModerated) {
        return this.$t("datasetEmptyExplanation.moderatedAndFiltered");
      }
      if (this.isFiltered) {
        return this.$t("datasetEmptyExplanation.filtered");
      }
      if (this.isModerated) {
        return this.$t("datasetEmptyExplanation.moderated");
      }
    }

    // The user has bound a dataset to the repeater, but there is no data in the source at all
    if (this.importedRecordCount === 0) {
      return this.$t("datasetEmptyExplanation.noData");
    }

    return "";
  }

  get dataBinding() {
    const dataBindings: DataBinding[] = this.context?.dataBindings || [];

    const db = dataBindings.find(
      (db) => db.bindingType === "DataSetParent" && db.widgetId === this.wid
    );

    return db;
  }

  get cells() {
    let cellCount = this.rows * this.columns;
    let startIndex = this.page * cellCount || 0;
    let endIndex = (this.page + 1) * cellCount;

    const isDataBoundRepeater = !!this.dataBinding;
    /**
     * We want to determine whether the repeater is databound or not,
     * so we can iterate through records if so, and otherwise just repeat static elements.
     *
     * We had been using this.data?.length to determine that,
     * but that caused issue with filtered empty datasets (data with a filter that returns no rows).
     *
     * When we try to check existence of data binding instead,
     * we run into issue that renderer does not have access to data module.
     * So we use other way of checking state.
     */

    if (this.hideEmptyCells && isDataBoundRepeater && this.dataLength > 0) {
      endIndex = Math.min(this.dataLength, endIndex);
    }

    const indexRange = range(startIndex, endIndex);

    return indexRange.map((dataRowIndex) => {
      let widgets: any[] = [];

      if (this.isDraggingImage) {
        // Support hover-on-preview for dynamic photos over photos/shapes within repeater
        widgets = this.bindWidgetData(this.childrenWids, dataRowIndex);
      } else if (isDataBoundRepeater) {
        if (dataRowIndex < this.dataLength) {
          widgets = this.bindWidgetData(this.childrenWids, dataRowIndex);
        }
      } else {
        widgets = this.bindWidgetData(this.childrenWids);
      }

      return {
        classes: this.cellClasses(dataRowIndex),
        style: this.cellStyle(dataRowIndex) as StyleValue,
        widgets,
      };
    });
  }

  get styles() {
    const result: StyleValue = {
      width: `${this.width}px`,
      height: `${this.height}px`,
      display: "flex",
      flexFlow: `${this.flow} wrap`,
      paddingTop: `${this.gapY / 2}px`,
      paddingBottom: `${this.gapY / 2}px`,
      paddingLeft: `${this.gapX / 2}px`,
      paddingRight: `${this.gapX / 2}px`,
    };

    if (this.backgroundColor?.indexOf("gradient") > 0) {
      result.backgroundImage = this.backgroundColor;
      result.backgroundSize = "cover";
    } else {
      result.backgroundColor = this.backgroundColor;
    }

    // When node/photo is being dragged, indicate with purple background that repeater can accept node
    if (!this.locked && (this.isDraggingItem || this.isRepeaterBeingEdited)) {
      result.backgroundColor = "rgba(150,10,150,0.22)"; // light purple
    }

    return result;
  }

  get cellWidth() {
    // Get height minus padding on vertical sides of the repeater
    const afterPadding = this.width - this.gapX * 2;
    // Reduce by the sum of the space between columns
    const afterGap = afterPadding - (this.columns - 1) * this.gapX;
    return afterGap / this.columns;
  }

  get cellHeight() {
    // Get width minus padding on horizontal sides of the repeater
    const afterPadding = this.height - this.gapY * 2;
    // Reduce by the sum of the space between rows
    const afterGap = afterPadding - (this.rows - 1) * this.gapY;
    return afterGap / this.rows;
  }

  get gapY() {
    // Gap is zero if it's a 1x1 repeater
    return this.rows * this.columns > 1 ? this.rowGap : 0;
  }

  get gapX() {
    // Gap is zero if it's a 1x1 repeater
    return this.rows * this.columns > 1 ? this.columnGap : 0;
  }

  cellStyle(index: number) {
    // Use margin because support of css gap is not great
    // https://caniuse.com/?search=gap
    const result: Partial<CSSStyleDeclaration> = {
      display: "block",
      borderStyle: "solid",
      borderWidth: `${this.borderWidth}px`,
      borderColor: this.borderColor,
      marginTop: `${this.gapY / 2}px`,
      marginBottom: `${this.gapY / 2}px`,
      marginLeft: `${this.gapX / 2}px`,
      marginRight: `${this.gapX / 2}px`,
      width: `${this.cellWidth}px`,
      height: `${this.cellHeight}px`,
    };

    if (this.cellBackgroundColor?.indexOf("gradient") > 0) {
      result.backgroundImage = this.cellBackgroundColor;
      result.backgroundSize = "cover";
    } else {
      result.backgroundColor = this.cellBackgroundColor;
    }

    /**
     * In most "default" cases, where cell and background of repeater are both transparent, use white as "editing" background.
     * (It looks better than transparent, IMO.)
     * But, if the repeater has a child text widget with near-white text color, use transparent instead, to avoid white-on-white issue.
     */
    let defaultColor = "white";

    // Handle the case where a Repeater contains a Group that contains Text widgets
    const allDescendentWidgetIds = this.childrenWids.concat(
      this.childrenWids
        .map((wid) => this.context.parents[wid])
        .flat()
        .filter((x) => x)
    );

    // const allDescendentWidgetIds = this.childrenWids;

    if (
      allDescendentWidgetIds
        .map((widgetId) =>
          getActiveWidget(
            this.context.widgets[widgetId],
            this.context.conditions
          )
        )
        .filter((x) => x)
        .some(
          (child: any) =>
            (child as Widget).type === "Text" &&
            isNearWhite((child as Widget).textColor)
        )
    ) {
      defaultColor = "transparent";
    }

    if (this.isCellBeingEdited(index)) {
      result.backgroundColor = !isTransparent(this.cellBackgroundColor)
        ? this.cellBackgroundColor
        : !isTransparent(this.backgroundColor)
        ? this.backgroundColor
        : defaultColor;
      // Use boxShadow instead of drop-shadow to avoid adding shadow to children that are outside the cell's borders
      // result.filter = "drop-shadow(4px 4px 15px rgb(0, 140, 192))"; // blue/teal
      result.boxShadow = "2px 2px 15px rgb(0, 140, 192)"; // teal shadow
    }

    return result;
  }

  isCellBeingEdited(index: number) {
    // cellEditIndex (which is editingContext.repeaterIndex) will always start at 0 and count up to page size.
    // So we mod out the pageSize from the index here.
    return (
      this.isRepeaterBeingEdited && this.cellEditIndex === index % this.pageSize
    );
  }

  cellClasses(index: number) {
    let shouldBeDimmed = false;
    let shouldBeHighlighted = false;

    const cellIsBeingEdited = this.isCellBeingEdited(index);

    if (this.locked === false) {
      if (this.isRepeaterBeingEdited) {
        // Not sure
      }
      if (cellIsBeingEdited) {
        shouldBeHighlighted = true;
      }

      shouldBeDimmed = this.isRepeaterBeingEdited && !cellIsBeingEdited;
    }

    return {
      "ring ring-2 opacity-90": shouldBeHighlighted,
      "opacity-20": shouldBeDimmed,
    };
  }

  componentStyle(wg: any) {
    const { element } = styler({
      x: wg.x,
      y: wg.y,
      scaleX: wg.scaleX,
      scaleY: wg.scaleY,
      width: wg.w || 0,
      height: wg.h || 0,
      angle: wg.angle,
      disableScale: true,
    });

    return {
      ...element,
      opacity: wg.opacity / 100,
      width: `${element.width}px`,
      height: `${element.height}px`,
      zIndex: wg.z,
    } as StyleValue;
  }
}
</script>

<style></style>
