import { WidgetWithConditions } from "@/components/widgets/Widget";
import { SavedAsset } from ".";
import { FaultErrorCode } from "./faultErrorCode";
import { Logic } from "./logic";

/**
 * A data connection represents
 *  - A data source (e.g. Google Sheet, CSV, JSON url)
 *  - Authorization for that data source
 *  - The expected data schema
 */
export interface DataConnection {
  uuid: string;
  appUuid?: string;
  authProvider: AuthProvider;
  authProviderDisplay: string;
  name: string;
  canConfigureRequest: boolean;
  canEditData: boolean;
  canEditSchema: boolean;
  canModerate: boolean;
  dataProvider: string;
  dataProviderDisplay: string;
  refreshRateSec: number;
  refreshRateTimeSpan: number;
  source: string;
  providerAuthId?: string;
  isUpload: boolean;
  createdOn: string;
  modifiedAt: string;
  nodes: Node[] | SchemaNode[];
  nodeSets: NodeSet[];
  logic: Logic[];
  allowFaultedSchema: boolean;
  shouldAlertOnFaultedSchema: boolean;
  moderationMode?: ModerationMode;
  shouldRemoveModeration?: boolean;
  appConnections: AppConnection[];
  isFaulted: boolean;
  isSyncable: boolean;
  faultErrorCode?: FaultErrorCode;
  faultErrorMessage?: string;
  isCollection?: boolean;
  schemaType?: string;
  options: ConnectionOption[];
  logicNodeReferences: LogicNodeReference[];
  iconUrl: string;
}

export interface LogicNodeReference {
  conditionGroupUuid: string | null;
  logicUuid: string;
  nodeUuids: string[];
  type: "Filter" | "Condition";
}

export class ConnectionOption {
  id: number | null;
  type: string;
  key: string;
  value: string;
}

export enum ConnectionOptionType {
  Method = 0,
  Header = 1,
  Parameter = 2,
  Body = 3,
}

export interface ProviderAuthorization {
  providerAuthId: number;
  username: string;
  name: string;
}

export type AuthProvider = "Microsoft" | "Google" | "None";

export interface AppConnection {
  appUuid: string;
  appName: string;
  isPublished: boolean;
}

export interface DataValue {
  /**
   * The value as a string with formatting.
   */
  formattedValue: string;

  /**
   * The parsed data value from the data source
   */
  value: any;
}

export interface SchemaNode {
  /**
   * An array of potential dataTypes that the schemaNode could be.
   */
  altTypes: DataType[];

  /**
   * Describes the schemaNode type. See the Data Types Enum model above.
   */
  dataType: DataType;

  /**
   * Only used on client side to display XML / JSON query paths. Server knows nothing about it.
   */
  displayQuery: string;

  /**
   * Indicates if the node is generated by the BE. Currently the Index node is artificial.
   */
  isArtificial: boolean;

  /**
   * If moderation is enabled for the connection, if isPrimaryKey is True, then this nodes value is used as the reference key to lookup the associated moderation value.
   */
  isPrimaryKey: boolean;

  /**
   * Indicates if the node is required to be present when inserting data via the API. Also used to enforce a schema for a custom widget, i.e. Calendar widgets.
   */
  isRequired: boolean;

  /**
   * Indicates if the schemaNode is selected. If not selected it will still be persisted but will not be included when fetching the schema and data for building an app.
   */
  isSelected: boolean;

  /**
   * Indicates if the node exists in the original schema but no longer exists in the sync'ed data schema.
   */
  isMissing?: boolean;

  /**
   * Indicates if the schemaNode is not supported for selecting. The primary use case for this currently is to flag nested arrays in Json/Xml.
   *
   * TODO: DELETE THIS. IT WONT BE NEEDED
   */
  isUnsupported?: boolean;

  /**
   * Enum Values: Node, NodeSet Indicates if the schemaNode is a Node or NodeSet
   */
  kind: NodeKind;

  /**
   * The name of the schema node.
   */
  name: string;

  /**
   * The uuid of this node's parent node.
   */
  parentUuid: string;

  /**
   * The reference value associated with the schemaNode that is used to query the node data.
   */
  query: string;

  /**
   * An alternative name used to identify a column or entity.
   * The property is not editable and is populated by the server.
   *
   * - For tabular data, the reference field is the actual column name
   * created by the user in the data source (not to be confused with
   * query, which is the actual spreadsheet column letter).
   * - For json/xml the reference field is the path to the specific
   * properties parent in the document.
   */
  referenceName: string;

  /**
   * An array of values { formattedValue: string, value: any } from the data source. The formattedValue is the string value from the data source; value is the parsed data value from the data source.
   */
  sampleValues: DataValue[];

  /**
   * Explicit indication if the schemaNode should be deleted and removed from the underlying schema.
   */
  shouldDelete: boolean;

  /**
   *  Used to represent a header row(s) offset.
   */
  startIndex?: number;

  /**
   * The node uuid.
   */
  uuid?: string;
}

export type ColumnPreviewInfo = SchemaNode & {
  importedRecordCount?: number;
};

export interface TreeSchemaNode extends SchemaNode {
  /**
   * Children of the current SchemaNode. Only present for TreeData.
   */
  children: TreeSchemaNode[];

  /**
   * Number of items in children array
   */
  length?: number;

  /**
   * The node value. Only applies to TreeData, should not be present for tabular data
   */
  value: DataValue;
}

/**
 * This represents
 *  - The schema for a 2D array of values
 *  - Instructions for the backend to query data from the underlying source
 */
export interface NodeSet {
  uuid?: string;
  name: string;
  startIndex?: number;
  endIndex?: number;
  nodes?: Node[];
  query: string;
  customQuery?: string;
  dataType?: DataType;
  parentDataNodeSetUuid?: string;
  isSelected: boolean;
}

/**
 * This represents
 *  - The schema for a single data value
 *  - Instructions for the backend to query data from the underlying source
 */
export interface Node {
  uuid?: string;
  name: string;
  dataType: DataType;
  query: string;
  isSelected: boolean;
  isPrimaryKey: boolean;
  isRequired?: boolean;
  isArtificial?: boolean;
}

export class NodeKind {
  static node = "Node";
  static nodeSet = "NodeSet";
}

/**
 * This represents
 *  - A data value
 *  - Metadata about that value (name, kind)
 */
export interface NodeData {
  /**
   * The unique ID for the column (not the actual data row)
   */
  uuid: string | null;
  dataType: DataType;
  query: string;
  kind: NodeKind;
  displayName: string;
  isArtificial: boolean;
  isPrimaryKey: boolean;
  value: string | boolean | number | Date | null;
  formattedValue: string;
  groupUuid?: string;
  assetUuid?: string;
}

/**
 * This represents
 *  - A data tree
 *  - Metadata about that value (name, kind)
 */
export interface NodeSetData {
  uuid: string;
  dataType: DataType;
  query: string;
  kind: NodeKind;
  children: (NodeData | NodeSetData)[][];
  importedRecordCount?: number;
}

export interface ManualDataRow {
  rowUuid: string;
  columns: NodeData[];
  /**
   * Used to control moderation settings (removed or approved e.g.)
   */
  isSelected?: boolean;
  isHidden?: boolean;
}

export type ModerationMode = "Approval" | "Removal" | null;

export type BindingType = "Collection" | "Scalar";

export type SchemaType = "Undefined" | "Tabular" | "Tree" | "Calendar";

export type DataType =
  | "Number"
  | "String"
  | "Bool"
  | "Date"
  | "Time"
  | "DateTime"
  | "ImageUrl"
  | "ImageUpload"
  | "Color"
  | "Object"
  | "Url"
  | "PrimativeArray"
  | "ObjectArray"
  | "NestedArray";

export const DataTypes: DataType[] = [
  "Number",
  "String",
  "Bool",
  "Date",
  "Time",
  "DateTime",
  "ImageUrl",
  "ImageUpload",
  "Color",
  "Object",
  "PrimativeArray",
  "ObjectArray",
  "NestedArray",
];

export const UserDefinedDataTypes: DataType[] = [
  "Number",
  "String",
  "Bool",
  "Date",
  "Time",
  "DateTime",
  "ImageUrl",
  "ImageUpload",
  "Color",
];

export type Lookup<T> = { [key: string]: T };

export interface UserUploadedAsset {
  uuid: string;
  url: string;
  name?: string;
}

export type UserDefinedAppData = {
  fonts: string[];
  userUploadedAssets: UserUploadedAsset[];
};

export interface AppModel {
  appSchemaVersion: number;
  widgets: Record<string, WidgetWithConditions>;
  parents: { [key: string]: string[] };
  custom: UserDefinedAppData;
}

export interface SavedAppInfo extends AppModel {
  dataBindings: DataBinding[];
  name: string;
  data: Record<string, Record<string, NodeSetData | NodeData>>;
}

// Pretty sure the idea is that singleDataNode is like "Location",
// DataSetNode is like "CalendarEventInfo",
// and DataSet would be like "CalendarInfo" -- an array of CalendarEventInfo objects, each of which is a "row" in a table
// Wonder if different naming might bring that out more
export type DataBindingType =
  | "Scalar"
  | "DataSetNode"
  | "DataSet"
  | "Asset"
  | "Filter"
  | "DataSetParent";

export type DataSortDirection = "None" | "Ascending" | "Descending" | "Random";

export interface DataBinding {
  widgetId: string;
  property: DataBindingProperty;
  dataConnectionUuid?: string;
  dataParentUuid?: string | null;
  dataUuid: string;
  bindingType: DataBindingType;
  query?: string;
  dataName?: string;
  parentWidgetId?: string;
  filterUuid?: string;
  sortDirection?: DataSortDirection;
  orderByDataUuid?: string;
  shouldRandomize?: boolean;
  conditionUuid?: string;
  conditionGroupUuid?: string | null;
}

export interface DataBindingAppInfo {
  appName: string;
  appUuid: string;
  widgetIds: string[];
  isPublished: boolean;
}

export interface DataBindingFilterInfo {
  appName: string;
  appUuid: string;
  filterUuid: string;
  filterName: string;
}

export interface DataBindingUsageInfo {
  dataUuid: string;
  dataParentUui?: string;
  isUsedInFilter: boolean;
  bindingType: DataBindingType;
  appInfo: DataBindingAppInfo[];
  filterInfo: DataBindingFilterInfo[];
}
export interface AppPayload {
  appVersionUuid: string;
  assets: SavedAsset[];
  categories: any[];
  checksum: number | null;
  createdOn: string;
  dataBindings: DataBinding[];
  description: string;
  feed: Feed;
  height: number;
  ianaTimeZone: string | null;
  isPublic: boolean;
  isTemplate: boolean;
  model: AppModel;
  modifiedAt: string | null;
  name: string;
  previewUrl: string;
  tags: any[];
  uuid: string;
  width: number;
  fallbackImageTimeoutSec?: number;
  introImageTimeoutSec?: number;
}

export class FeedDeliveryMethod {
  static MediaRss = "MediaRss";
  static Html = "Html";
  static DirectUrl = "DirectUrl";
}

/**
 * PublishedUrl - url associated with the published version of the app. If the
 *    app is unpublished the url will return the draft version.
 *
 * PreviewUrl - url associated with the current draft version
 */
export interface Feed {
  name: string;
  publishedUrl: string;
  previewUrl: string;
  deliveryMethod: FeedDeliveryMethod;
}

export type DataStruct = "Tabular" | "Tree" | "Undefined";

export interface SourceDataSchema {
  url: string;
  schema: SchemaNode[];
  connectionUuid: string;
  schemaInfoUuid: string;
  lastDataSourceUpdate: string;
  recordCount: number;
  schemaType: SchemaType;
  clientComponentName: string;
  dataStruct: DataStruct;
}
export interface TabularDataSchema {
  url: string;
  sheets: TableSchema[];
  connectionUuid: string;
  schemaInfoUuid: string;
  lastDataSourceUpdate: string;
  schemaType: SchemaType;
  dataStruct: DataStruct;
  spreadsheetName: string;
  messages: string[] | null;
}

export interface GetConnectionBindingsResult {
  dataConnection: DataConnection;
  appBindingInfo: DataBindingUsageInfo[];
}

export interface EditSchemaResult {
  dataConnection: DataConnection;
  appBindings: DataBindingUsageInfo[];
  brokenBindings: DataBindingUsageInfo[];
  hasSchemaChanged: boolean;
  schema: any;
  missingNodes: SchemaNode[];
  schemaInfoUuid: string;
  lastDataSourceUpdate: Date | null;
}

export interface TableSchema {
  id: string;
  title: string;
  rangeId: string;
  hasHeader: boolean;
  schema: SchemaNode[];
  recordCount: number;
}

export interface ConnectionDataResponse {
  data: NodeSetData | NodeData;
  lastUpdated: string;
}

export type ConnectionRefreshRate =
  | "Never"
  | "OnDemand"
  | "FifteenMin"
  | "Hourly"
  | "Daily"
  | "Weekly";

/**
 * This is the model that is sent to and from the server
 */
export interface FilterOptions {
  logic: Logic;
  types: string[];
  schema: SchemaNode[];
  message: string;
  dataConnectionName: string;
}

export interface ModerationDataNode {
  refUuid: string;
  primaryKeyId: string;
  primaryKeyValue?: string;
  isSelected: boolean;
  isHidden?: boolean;
  moderatedByUsername?: string;
  moderatedOn?: Date;
  data: NodeData[];
}

export interface ModerationDataNodeSet {
  parentDataNodeSet: NodeSetData;
  moderationDataNodes: ModerationDataNode[];
}

export interface ModerationDataContainer {
  lastDataSourceUpdate: Date;
  data: ModerationDataNodeSet;
}

export interface ModerationDataQueryResult {
  data: ModerationDataContainer;
  hasPrimaryKey: boolean;
  hasSchemaChanged: boolean;
  isDataValid: boolean;
}

export interface PublishMetadata {
  isPublished: boolean;
  publishedOn: string | null;
  unpublishedOn: string | null;
}

export interface CanPublishMetadata {
  canPublish: boolean;
  hasReseller: boolean;
  subscribeToConnectOneUrl: string;
  subscribeToConnectUnlimitedUrl: string;
}

export interface AppInfo {
  uuid: string;
  name: string;
  description: string;
  createdByAccountId: number;
  createdOn: string;
  modifiedAt: string;
  appVersionUuid: string;
  featuredStart: string;
  featuredEnd: string;
  featuredOrder: string;
  width: number;
  height: number;
  previewUrl: string;
  previewAssetUuid: string | null;
  ianaTimeZone: string | null;
  isTemplate: boolean;
  isPublic: boolean;
  publishMeta: PublishMetadata;
  feed: Feed;
  checksum: number;
  categories: Keyword[];
  tags: Keyword[];
  maxAllowedConnectionCount: number;
  dataBindings?: DataBinding[];
}

// Keywords can be Categories or Tags
export interface Keyword {
  id: number | null;
  type: string;
  text: string;
  value: string;
  order: number;
  isPrimary: boolean;
}

export type DataBindingProperty =
  | "h"
  | "w"
  | "x"
  | "y"
  | "z"
  | "scaleX"
  | "scaleY"
  | "data"
  | "parentId"
  | "opacity"
  | "angle"
  | "locked"
  | "lockAspect"
  | "backgroundColor"
  | "backgroundImageUrl"
  | "backgroundSize"
  | "shadow"
  | "shadowX"
  | "shadowY"
  | "shadowBlur"
  | "shadowColor"
  | "border"
  | "borderWidth"
  | "borderColor"
  | "borderIsDashed"
  | "borderDashSize"
  | "borderGapSize"
  | "borderStyle"
  | "borderAlign"
  | "perspective"
  | "perspectiveAngle"
  | "color"
  | "blur"
  | "blurValue"
  | "blurDisplay"
  | "paddingTop"
  | "paddingRight"
  | "paddingBottom"
  | "paddingLeft"
  | "fill"
  | "font"
  | "textColor"
  | "fontFamily"
  | "fontSize"
  | "fontWeight"
  | "textTransform"
  | "fontStyle"
  | "letterSpacing"
  | "lineHeight"
  | "textAlign"
  | "textStyle"
  | "alignHorizontal"
  | "alignVertical"
  | "content"
  | "url"
  | "dataset"
  | "datasets"
  | "syncFonts"
  | "syncTextColors"
  | "paragraphSpacing"
  | "minValue"
  | "maxValue"
  | "lineColor"
  | "bgColor"
  | "barColor"
  | "plotlineColor"
  | "bgFill"
  | "fgFill"
  | "stroke"
  | "dividerFill"
  | "dividerStroke"
  | "currentValue"
  | "dayOffset"
  | "dayFormat"
  | "startHour"
  | "endHour"
  | "eventBackgroundColor"
  | "allDayEventBackgroundColor"
  | "verticalLinesColor"
  | "verticalLines_color"
  | "event_backgroundColor"
  | "today_backgroundColor"
  | "today_textColor"
  | "nowLineColor"
  | "calendarBackgroundColor"
  | "showEventLocation"
  | "showEventCreator"
  | "showEventTime"
  | "showEventDescription"
  | "trackLength"
  | "dividerColor"
  | "dividerFillColor"
  | "showDivider"
  | "progressBarBackgroundColor"
  | "progressBarFillColor"
  | "datetimeFormat"
  | "datetimeValue"
  | "userSpecifiedLocale"
  | "useBrowserLocale"
  | "useCurrentTime"
  | "verticalDynamism"
  | "verticalMargin"
  | "orderedChildIds"
  | "backgroundImageUrl2"
  | "cellBackgroundColor";
