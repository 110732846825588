import { ManualDataRow } from "@/types/data";
import {
  NodeData,
  NodeSetData,
  NodeKind,
  ModerationDataNode,
} from "@/types/data";
import { uuidv4 } from "@core/utils/uuid";

export function getChildDataNodes(
  children: (NodeData | NodeSetData)[][]
): NodeData[][] {
  return (
    (children?.filter((ch) =>
      ch.some((c) => c.kind === NodeKind.node)
    ) as NodeData[][]) ?? []
  );
}

export function getChildDataNodeSets(
  children: (NodeData | NodeSetData)[][]
): NodeSetData[][] {
  return (
    (children?.filter((ch) =>
      ch.some((c) => c.kind === NodeKind.nodeSet)
    ) as NodeSetData[][]) ?? []
  );
}

/**
 * These fns convert placeholderData (for graphs, calendars(?)) to manualData format (schema + rows)
 * Can also be used to convert widgetData to manualData format
 */

export const parseDataSchema = (data: NodeData[][]): any[] => {
  // console.log("parse schema", data);
  return (
    (data?.[0] ?? [])
      .filter((n) => n.kind === NodeKind.node)
      .map((node) => {
        const { uuid, displayName, kind, dataType } = node;

        return {
          uuid,
          kind,
          query: uuid,
          dataType,
          name: displayName,
          columnId: uuid,
          columnUuid: uuid,
          altTypes: [],
          sampleValues: [],
          isSelected: true,
          isArtificial: node.isArtificial ?? false,
          isRequired: true, // not sure about this
          isPrimaryKey: node.isPrimaryKey,
        };
      })
      // Ignore _sf_index:
      .filter((n) => !n.isArtificial)
  );
};

// const parseDataNode = (node: NodeData): ManualDataNode => {
//   const { dataType, uuid, displayName, value, formattedValue, query } = node;
//   // console.log("parse node", value, formattedValue);
//   return {
//     dataType: dataType,
//     columnId: uuid,
//     columnUuid: uuid,
//     name: displayName,
//     isArtificial: node.isArtificial ?? false,
//     value,
//     formattedValue: formattedValue ?? value,
//     query,
//   };
// };

export const parseModeratedRows = (
  data: ModerationDataNode[]
): ManualDataRow[] => {
  return data.map((row) => {
    return {
      rowUuid: row.refUuid,
      isSelected: row.isSelected,
      columns: row.data
        // .map(parseDataNode)
        // Ignore _sf_index:
        .filter((n) => !n.isArtificial),
    };
  });
};

export const parseDataRows = (data: NodeData[][]): ManualDataRow[] => {
  if (!data) return [];
  return data.map((row) => {
    return {
      // The groupUuid i.e. rowUuid is buried in the nodes, so just grab from the first one
      rowUuid: row[0]?.groupUuid || uuidv4(),
      // isSelected: row.isSelected,
      columns: row
        .filter((n) => n.kind === NodeKind.node)
        // .map(parseDataNode)
        // Ignore _sf_index:
        .filter((n) => !n.isArtificial),
    };
  });
};
