<template>
  <div
    class="relative"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click.stop.prevent="onClick"
  >
    <IconSolid :name="icon" />

    <div
      ref="arrow"
      class="pointer-events-none absolute"
      :class="arrowClasses"
    ></div>
    <div
      ref="text"
      class="pointer-events-none absolute rounded-sm text-xs font-bold text-white px-2 leading-6 whitespace-nowrap"
      :class="contentClasses"
      v-t="tooltip"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import IconSolid from "@/components/icons/IconSolid.vue";
import TooltipMixin from "@/components/TooltipMixin";

@Component({
  components: { IconSolid },
})
export default class ButtonIcon extends mixins(TooltipMixin) {
  @Prop(String) icon: string;

  onClick() {
    this.$emit("click");
  }
}
</script>

<style scoped>
.tip-arrow-t {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-bottom-width: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-r {
  left: 100%;
}
.tip-arrow-b {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-l {
  right: 100%;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f00;
}

.tip-offset-t {
  bottom: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-r {
  left: calc(100% + theme("spacing.2"));
}
.tip-offset-b {
  top: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-l {
  right: calc(100% + theme("spacing.2"));
}
</style>
