<template>
  <div class="select-none w-full">
    <form class="space-y-8" @submit.prevent="onImport">
      <div>
        <div class="flex items-end justify-between my-3">
          <div class="block text-2xl" v-t="'ProvideUrl.enterUrl'"></div>
          <Link
            class="mx-1"
            href="https://screenfeed.com/support-articles/managing-your-data-connections"
            target="_blank"
            v-t="'needHelp'"
          />
        </div>
        <input
          ref="url"
          type="text"
          placeholder="https://api.company.com/data"
          class="block w-full px-2 py-1 flex-grow rounded-sm border text-gray-900 dark-form-focus"
          v-model="state.url"
        />
        <div
          class="inline-block px-3 py-px rounded border border-red-500 mt-1 text-sm bg-red-500 bg-opacity-50"
          v-if="inputError"
        >
          {{ inputError }}
        </div>

        <button
          title="Configure HTTP request options"
          type="button"
          @click="showAdvancedSettings = !showAdvancedSettings"
          class="text-white inline-flex items-center px-5 py-2 mt-7 rounded-md text-sm cursor-pointer border border-gray-500 hover:bg-gray-800"
          v-t="'ProvideUrl.advanced'"
        ></button>

        <div v-if="showAdvancedSettings" class="w-full mt-6">
          <div class="w-50 flex flex-col">
            <label v-t="'ProvideUrl.requestMethod'"></label>
            <SelectMenu
              class="w-full mt-1"
              :options="getMethods"
              :value="state.method"
              @input="onSelectedMethodChanged"
            />
          </div>
          <div class="flex flex-col mt-3" v-if="state.method === 'POST'">
            <label class="mb-1" v-t="'ProvideUrl.body'"></label>
            <textarea
              v-model="state.body"
              rows="5"
              cols="5"
              class="rounded-sm text-gray-900 p-3"
              placeholder="{ Code here... }"
              style="width: 420px"
            ></textarea>
          </div>
          <div class="flex flex-col mt-3">
            <label class="mb-1" v-t="'ProvideUrl.params'"></label>
            <div class="flex flex-row">
              <input
                ref="paramKey"
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
                placeholder="Key"
                v-model="param.key"
              />
              <input
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 w-50"
                placeholder="Value"
                v-model="param.value"
              />
              <button
                type="button"
                class="text-white inline-flex items-center px-5 py-2 ml-5 rounded-md text-sm cursor-pointer border bg-app-teal border-gray-500 hover:border-gray-900"
                @click="onParamAdd"
              >
                Add
              </button>
            </div>
            <div
              class="flex flex-row mt-3"
              v-for="(p, i) in state.params"
              :key="p.key"
            >
              <input
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
                v-model="p.key"
                placeholder="Key"
              />
              <input
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 w-50"
                v-model="p.value"
                placeholder="Value"
              />
              <IconButton
                icon="Trash"
                class="icon w-7 h-7 cursor-pointer my-auto ml-5"
                @click="onParamDelete(i)"
              />
            </div>
          </div>
          <div class="flex flex-col mt-3">
            <label class="mb-1" v-t="'ProvideUrl.headers'">Headers</label>
            <div class="flex flex-row">
              <input
                ref="headerKey"
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
                placeholder="Key"
                v-model="header.key"
              />
              <input
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 w-50"
                placeholder="Value"
                v-model="header.value"
              />
              <button
                type="button"
                class="text-white inline-flex items-center px-5 py-2 ml-5 rounded-md text-sm cursor-pointer border bg-app-teal border-gray-500 hover:border-gray-900"
                @click="onHeaderAdd"
              >
                Add
              </button>
            </div>
            <div
              class="flex flex-row mt-3"
              v-for="(h, i) in state.headers"
              :key="h.key"
            >
              <input
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
                placeholder="Key"
                v-model="h.key"
              />
              <input
                type="text"
                class="rounded-sm border text-gray-900 px-2 py-1 w-50"
                placeholder="Value"
                v-model="h.value"
              />
              <IconButton
                icon="Trash"
                class="icon w-7 h-7 cursor-pointer my-auto ml-5"
                @click="onHeaderDelete(i)"
              />
            </div>
          </div>
        </div>
      </div>

      <SupportedFormats />

      <portal to="setupWizardNext">
        <FormButton
          @click="onImport"
          type="button"
          v-t="'ProvideUrl.importData'"
        ></FormButton>
      </portal>
    </form>

    <portal to="setupWizardUiBlocker">
      <UiBlocker :visible="importing">
        <div
          class="mt-4 text-xl text-white"
          v-t="'ProvideUrl.loadingData'"
        ></div>
      </UiBlocker>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  ConnectionOption,
  ConnectionOptionType,
  SourceDataSchema,
} from "@/types/data";
import { api } from "@/api/backend";
import {
  UrlConnectionState,
  SourceDataInfo,
  useConnectionSetupStore,
} from "@/stores/connectionSetup";
import { APP_EDITOR_ROUTE_PATH } from "@/constants";
import { getConnectionErrorMessage } from "@/components/data/connections/connectionErrorMessage";
import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import SupportedFormats from "@/components/data/connections/setup/provide/SupportedFormats.vue";
import Link from "@/components/Link.vue";
import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import IconButton from "@/views/Dashboard/IconButton.vue";
import VueI18n from "vue-i18n";

@Component({
  components: {
    UiBlocker,
    FormButton,
    SelectMenu,
    SupportedFormats,
    Link,
    LightningBoltIcon,
    IconButton,
  },
})
export default class Url extends Vue {
  inputError: VueI18n.TranslateResult | null = null;
  importing = false;
  showAdvancedSettings: boolean = false;
  param: ConnectionOption = this.newParam();
  header: ConnectionOption = this.newHeader();
  store = useConnectionSetupStore();
  state: UrlConnectionState = {
    url: "",
    method: "GET",
    headers: [],
    params: [],
    body: "",
  };

  get seekingCollection() {
    return this.$route.path.includes(APP_EDITOR_ROUTE_PATH)
      ? this.$route.query.type === "Collection"
      : true;
  }

  mounted() {
    if (this.store.urlConnection) this.state = this.store.urlConnection;

    (this.$refs.url as HTMLInputElement).focus();
    this.showAdvancedSettings =
      this.$route.query.advanced === "true" ||
      !!this.state.body ||
      this.state.headers.length > 0 ||
      this.state.params.length > 0;
  }

  onImport() {
    this.inputError = null;

    if (this.state.url.length === 0) {
      this.inputError = this.$t("ProvideUrl.invalidUrl").toString();
      return;
    }

    if (this.param.key !== "" || this.param.value !== "") this.onParamAdd();

    if (this.header.key !== "" || this.header.value !== "") this.onHeaderAdd();

    this.importing = true;
    api
      .post<SourceDataSchema>(`dataconnection/directurl`, {
        url: this.state.url,
        isCollection: this.seekingCollection,
        requestMethod: this.state.method,
        body: this.cleanBody,
        headers: this.state.headers,
        parameters: this.state.params,
      })
      .catch((errors) => {
        const errorMessage = Array.isArray(errors)
          ? `Errors: ${errors.map((e: any) => e.message).join(". ")}`
          : errors.toString();

        this.inputError = getConnectionErrorMessage(
          errors?.[0]?.code,
          errorMessage
        );

        return Promise.reject(errors);
      })
      .then((response) => {
        this.$emit("complete", {
          name: null,
          type: response.schemaType,
          schema: response,
        } as SourceDataInfo);

        this.store.urlConnection = this.state;
      })
      .finally(() => {
        this.importing = false;
      });
  }

  get cleanBody() {
    if (this.state.body === "" || this.state.body === undefined) return null;

    const o = JSON.parse(this.state.body);
    return JSON.stringify(o, undefined, 4);
  }

  get getMethods() {
    return [
      { label: "GET", value: "GET" },
      { label: "POST", value: "POST" },
    ];
  }

  onSelectedMethodChanged(value: any) {
    this.state.method = value;
  }

  newParam() {
    return {
      key: "",
      value: "",
      type: ConnectionOptionType[ConnectionOptionType.Parameter],
      id: null,
    };
  }

  newHeader() {
    return {
      key: "",
      value: "",
      type: ConnectionOptionType[ConnectionOptionType.Header],
      id: null,
    };
  }

  onParamAdd() {
    if (
      this.state.params.findIndex((p) => p.key === this.param.key) != -1 ||
      (this.param.key === "" && this.param.value === "")
    )
      return;

    this.state.params.push(this.param);
    this.param = this.newParam();
    (this.$refs.paramKey as HTMLInputElement).focus();
  }

  onParamDelete(index: number) {
    this.state.params.splice(index, 1);
  }

  onHeaderAdd() {
    if (
      this.state.headers.findIndex((p) => p.key === this.header.key) != -1 ||
      (this.header.key === "" && this.header.value === "")
    )
      return;

    this.state.headers.push(this.header);

    this.header = this.newHeader();

    (this.$refs.headerKey as HTMLInputElement).focus();
  }

  onHeaderDelete(index: number) {
    this.state.headers.splice(index, 1);
  }
}
</script>

<style scoped lang="postcss">
.error-message {
  background: rgba(246, 38, 38, 0.35);
  border: 1px solid rgba(246, 38, 38, 0.75);
}
</style>
