import { SavedAsset } from "@/types";
import { DataBinding, PublishMetadata, UserDefinedAppData } from "@/types/data";
import { WidgetWithConditions } from "@/components/widgets/Widget";

export type AppMode = "render" | "edit";

export interface AppState {
  assets: SavedAsset[];
  widgets: Record<string, WidgetWithConditions>;
  dataBindings: DataBinding[];
  /**
   * Mapping of parent widget id -> child widget array
   */
  parents: Record<string, string[]>;
  width: number;
  height: number;
  uuid: string;
  appVersionUuid: string;
  appSchemaVersion: number;
  name: string;
  custom: UserDefinedAppData;
  checksum: number | null;
  publishMeta: PublishMetadata | null;
  ianaTimeZone: string | null;
  isTemplate: boolean;
  feed: any;
  appMode: AppMode;
  maxAllowedConnectionCount: number;

  fallbackImageTimeoutSec?: number;
  introImageTimeoutSec?: number;
}

export const makeInitialAppState = (): AppState => {
  return {
    assets: [],
    widgets: {},
    dataBindings: [],
    parents: {},
    custom: {
      fonts: [],
      userUploadedAssets: [],
    },
    uuid: "",
    appSchemaVersion: 1,
    appVersionUuid: "",
    name: "",
    width: 1920,
    height: 1080,
    checksum: null,
    publishMeta: null,
    ianaTimeZone: null,
    isTemplate: false,
    feed: {},
    appMode: "render",
    maxAllowedConnectionCount: 5,
  };
};
