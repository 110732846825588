<template>
  <div
    class="w-80 absolute right-[19rem] top-[7.5rem] container leading-tight"
    @click.stop
  >
    <div
      class="help-content text-[15px] flex flex-col bg-app-dark3 text-gray-200 space-y-3 p-6 absolute top-0 left-0 rounded"
    >
      <div class="text-[17px]">Tip 1 of 1</div>

      <p>This app is connected to an editable data source.</p>
      <p>Use the "Manage" button to edit this data.</p>
      <p>
        Or use the "Replace Data Source" button to replace this dataset with
        your own, for example a Google Sheet or a CSV.
      </p>
    </div>

    <div
      class="block absolute top-1 w-8 h-8 transform -translate-x-1/2 -translate-y-1/2 rounded-full z-10 text-gray-800 hover:text-gray-500 cursor-pointer bg-gray-400 flex items-center justify-center"
      @click="$emit('close')"
    >
      <IconSolid name="X" class="w-4 h-4" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HelpBubble from "@/components/HelpBubble.vue";
import IconSolid from "@/components/icons/IconSolid.vue";

@Component({ components: { HelpBubble, IconSolid } })
export default class HelpConnectData extends Vue {}
</script>

<style scoped>
.container {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.help-content:before {
  content: "";
  position: absolute;
  top: 7.6rem;
  right: -18px;
  height: 30px;
  width: 30px;
  background: rgb(51, 51, 51);
  /* background: white; */
  box-sizing: border-box;
  transform: rotate(45deg) translate(-50%);
  border-top: inherit;
  border-right: inherit;
  box-shadow: inherit;
}
</style>
