<template>
  <div class="w-full h-full divide-y">
    <div>
      <div
        class="font-bold text-sm py-4 ml-6"
        v-t="'animation.transitions'"
      ></div>
      <div class="flex w-full h-full flex-wrap space-y-4 mb-4">
        <AnimationPresetRow
          v-for="(row, index) in transitionPresetRows"
          :key="index"
          :presets="row"
          :animations="animations"
          :animationKey="'transition'"
          :selectedPresetId="transition.presetId"
          @select="onPresetSelection('transition', $event)"
          @animationPropChanged="updateAnimation"
        ></AnimationPresetRow>
      </div>
    </div>
    <div>
      <div class="font-bold text-sm py-4 ml-6" v-t="'animation.loops'"></div>
      <div class="flex w-full flex-wrap space-y-4 mb-4">
        <AnimationPresetRow
          v-for="(row, index) in loopPresetRows"
          :key="index"
          :presets="row"
          :animations="animations"
          :animationKey="'loop'"
          :selectedPresetId="loop.presetId"
          @select="onPresetSelection('loop', $event)"
          @animationPropChanged="updateAnimation"
        ></AnimationPresetRow>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  AppTimeline,
  animate,
  resetAnimation,
  AnimationPresets,
  AnimatableWidgetTypes,
} from "../widgets/Animation";
import {
  AnimationPreset,
  AnimationOptions,
  AnimationPresetList,
} from "@/types/animation";
import { gsap } from "gsap";
import ColorPanel from "@/components/ColorPanel.vue";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import AnimationPresetRow from "../AnimationPresetRow.vue";
import { getActiveWidget } from "@/util/conditionsUtils";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConditionGroupsStore } from "@/stores/conditionGroups";

@Component({
  components: {
    ColorPanel,
    Icon,
    IconSolid,
    AnimationPresetRow,
  },
})
export default class AnimationEditor extends Vue {
  showAnimateInOptions = false;
  showAnimateOutOptions = false;
  showBehaviorOptions = false;
  progressBarTimeline = gsap.timeline();

  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get widgets() {
    return this.appEditor.widgets;
  }

  created() {
    this.progressBarTimeline = gsap.timeline({
      paused: true,
      onComplete: this.stopProgressAnimation,
    });
  }

  @Watch("animationPlaying")
  onAnimationPlayingChanged(isPlaying: boolean) {
    if (isPlaying) {
      this.playPreview();
    } else {
      this.resetPreview();
    }
  }

  get animationPlaying() {
    return this.appEditor.animationPlaying;
  }

  stopAnimationPreview() {
    this.appEditor.animationPlaying = false;
  }

  get animations() {
    return (this.selectedWidget as unknown as AnimationOptions).animations;
  }

  get randomKey() {
    return new Date().getTime() + Math.floor(Math.random() * 10000).toString();
  }

  get timeline() {
    return AppTimeline;
  }
  get transition() {
    return this.animations.transition;
  }

  get transitionOptions() {
    return this.presets.transition;
  }

  get transitionPresetRows() {
    return this.getRows(this.transitionOptions);
  }

  get loop() {
    return this.animations.loop;
  }

  get loopOptions() {
    return this.presets.loop;
  }

  get loopPresetRows() {
    return this.getRows(this.loopOptions);
  }

  getRows(options: AnimationPreset[]) {
    let rows: any = [];
    options.reduce(
      (acc: any = [], currentValue, currentIndex, array: AnimationPreset[]) => {
        if (currentIndex % 2 === 0) {
          rows.push(array.slice(currentIndex, currentIndex + 2));
        }
      },
      []
    );
    return rows;
  }

  stopProgressAnimation() {
    const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
    //Need to check if app has any widgets with animations that have a loop but don't have an outro.
    let animations = Object.values(this.widgets)
      .filter((wg) => AnimatableWidgetTypes.includes(wg.type))
      .map((wg) => getActiveWidget(wg, conditions).animations);
    let shouldContinue = animations.some(
      (animation) =>
        animation.loop.presetId != "none" && !animation.transition.outro
    );
    if (!shouldContinue) {
      this.stopAnimationPreview();
    }
  }

  resetPreview() {
    resetAnimation(this.timeline);
    resetAnimation(this.progressBarTimeline);
  }

  playPreview() {
    animate(this.$root);
    let duration = this.timeline.getChildren(true, false, true)[1].duration();
    this.progressBarTimeline.fromTo(
      "#animation-progress-bar",
      { scaleX: 0 },
      {
        scaleX: 1,
        transformOrigin: "0px 0px",
        duration: duration,
        ease: "none",
      }
    );
    this.progressBarTimeline.play();
  }

  get previewButtonText() {
    return this.animationPlaying
      ? this.$t("animation.stopPreview")
      : this.$t("animation.preview");
  }

  get previewIconName() {
    return this.animationPlaying ? "Cancel" : "Play";
  }

  get presets() {
    return AnimationPresets;
  }

  updateAnimation(ev: AnimationPreset) {
    this.appEditor.setWidgetProps(this.selections, { animations: ev });
    this.stopAnimationPreview();
  }

  onPresetSelection(animationType: keyof AnimationPresetList, ev: any) {
    if (this.animationPlaying) {
      this.stopAnimationPreview();
    }
    const newId =
      this.animations[animationType].presetId === ev.presetId
        ? "none"
        : ev.presetId;

    const preset = this.presets[animationType].find(
      (p) => p.presetId === newId
    );
    if (preset) {
      const animations = Object.assign(
        {},
        (this.selectedWidget as unknown as AnimationOptions).animations
      );
      animations[animationType] = preset;
      this.appEditor.setWidgetProps(this.selections, { animations });
    }
    this.resetPreview();
  }

  beforeDestroy() {
    this.stopAnimationPreview();
    this.resetPreview();
  }
}
</script>

<style lang="postcss" scoped></style>
