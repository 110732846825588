import { DataType } from "@/types/data";

export const getComponentName = (dataType: DataType) => {
  switch (dataType) {
    case "Time":
    case "Date":
    case "DateTime":
      return `DataNodeDateTime`;
    case "String":
    case "Bool":
    case "Number":
    case "Url":
      return `DataNodeValue`;
    case "Color":
      return `DataNodeColor`;
    case "ImageUrl":
    case "ImageUpload":
      // case "Url":
      return `DataNodeImageUrl`;
    case "ObjectArray":
      return `DataNodeEmpty`;
    default:
      return `DataNode${dataType}`;
  }
};
