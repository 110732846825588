<template>
  <div class="flex flex-col items-start space-y-8" v-if="connection !== null">
    <div class="flex items-center space-x-4">
      <img class="w-12 h-12" :src="connection.iconUrl" />
      <div class="text-2xl" :title="connection.name">
        {{ connection.name }}
      </div>
    </div>
    <div
      v-if="!isAssociatedWithPublishedApp"
      class="text-red-500 text-lg"
      v-t="'Synchronization.disabled'"
    ></div>
    <!-- Sync fequency custom radio input: -->
    <div class="rounded-md px-6 py-4 w-100 bg-gray-700/80 custom-shadow-box">
      <div class="space-y-4">
        <h3 class="text-lg font-bold" v-t="'Synchronization.howOften'"></h3>
        <div class="flex space-x-8">
          <label
            :key="index"
            v-for="(o, index) in options"
            class="relative flex cursor-pointer focus:outline-none"
          >
            <input
              type="checkbox"
              :checked="refreshMode === o.value"
              :value="refreshMode"
              @change="refreshMode = o.value"
              class="form-checkbox rounded h-4 w-4 mt-1 cursor-pointer dark-form-focus"
              :class="{
                'text-app-green': refreshMode === o.value,
                'bg-gray-600': refreshMode !== o.value,
              }"
            />
            <div class="ml-3 flex flex-col">
              <span
                :class="{
                  'text-white': refreshMode === o.value,
                  'text-gray-300': refreshMode !== o.value,
                }"
                class="block font-medium"
                v-text="o.label"
              ></span>
            </div>
          </label>
        </div>

        <div class="flex items-start flex-col space-y-2 pl-8">
          <div
            class="flex space-x-3"
            :class="{ 'opacity-50 text-gray-500': refreshMode !== 'recurring' }"
          >
            <div class="text-sm" v-t="'Synchronization.every'"></div>
            <input
              type="number"
              v-model="refreshRate"
              class="w-12 pl-1 text-sm rounded-sm dark-form-focus"
              :class="{
                'text-black': refreshMode === 'recurring',
                'text-gray-500': refreshMode !== 'recurring',
              }"
              :controls="true"
              :disabled="refreshMode !== 'recurring'"
              :step="5"
              :min="minValue"
            />
            <div class="text-sm" v-t="'Synchronization.minutes'"></div>
          </div>
          <div
            class="border border-red-800 px-2 rounded bg-red-700/10 text-center text-sm text-red-500"
            v-if="isValidRate === false"
            v-text="$t('Synchronization.minimumRateError', { minValue })"
          ></div>
        </div>
      </div>

      <div
        class="flex items-center space-x-4 mt-8 border-t border-gray-800 pt-4"
      >
        <ButtonGradient
          class="py-1 w-36 justify-center dark-form-focus shadow-sm"
          :disabled="isValidRate === false || saving"
          @click="onSave"
        >
          <span v-t="'saveChanges'"></span>
        </ButtonGradient>
        <div v-if="saved" class="text-green-500">Your changes were saved</div>
        <div v-if="errorMessage" class="text-red-500 text-sm">
          {{ $t("Error.generic") }}
        </div>
      </div>
    </div>

    <ManualSync class="rounded-md w-100 p-6 bg-gray-700/80 custom-shadow-box" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import RadioInput from "@/components/inputs/RadioInput.vue";
import ManualSync from "@/components/data/connections/manualSource/settings/views/ManualSync.vue";

import ButtonGradient from "@/components/ButtonGradient.vue";
import { SchemaNode } from "@/types/data";
import NumberInput from "@/components/inputs/NumberInput.vue";
import IconSolid from "@/components/icons/IconSolid.vue";

import { DEFAULT_CONNECTION_REFRESH_RATE } from "@/constants";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    RadioInput,
    ButtonGradient,
    IconSolid,
    ManualSync,
    NumberInput,
  },
})
export default class Synchronization extends Vue {
  minValue = DEFAULT_CONNECTION_REFRESH_RATE;
  refreshMode: "recurring" | "ondemand" | null = null;
  refreshRate = DEFAULT_CONNECTION_REFRESH_RATE;
  saved = false;
  saving = false;
  savedTimer!: number;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get errorMessage() {
    return this.connectionEditor.errorMessage;
  }

  get schema() {
    return this.connectionEditor.schema as SchemaNode[];
  }

  get isAssociatedWithPublishedApp() {
    return this.connection?.appConnections?.some((a) => a.isPublished);
  }

  /**
   * This next chunk of code is weird. Here's an explanation.
   * By default our minimum rate is 15 min. But for some valued
   * customers, we'll manually set their minimum rate below 15
   * minutes.
   *
   * When this component loads, the connection in VUEX may or
   * may not be loaded yet. We use a watcher here to listen for
   * changes to the refreshRateSec value. Whenever the value is
   * changed, we compare it to the default minimum. If the new
   * value is lower than the default minimum, we change the minimum.
   *
   * It is worth noting that the value of refreshRateSec is only
   * modified whenever the user presses "Save Changes". And they
   * aren't able to "Save Changes" for a value that is below the
   * minimum.
   *
   * This allows the backend to define a custom minimum and prevents
   * users from entering a value less than the minimum.
   * @param newValue This
   */
  @Watch("connection.refreshRateSec", { immediate: true })
  onRefreshRateChanged(newValue: number) {
    // Set minimum
    if (newValue !== null && newValue < this.minValue) {
      this.minValue = newValue;
    }

    // Convert value to minutes.
    if (newValue !== null && newValue > 0) {
      this.refreshRate = newValue / 60;
      this.refreshMode = "recurring";
    } else {
      this.refreshMode = "ondemand";
    }
  }

  get isValidRate() {
    if (this.refreshMode === "ondemand") {
      return true;
    }
    return (
      this.refreshMode === "recurring" && this.refreshRate >= this.minValue
    );
  }

  get options(): { label: string; value: "recurring" | "ondemand" }[] {
    if (this.connection === null) {
      return [];
    }
    return [
      {
        label: "On a Recurring Schedule",
        value: "recurring",
      },
      {
        label: "On Demand",
        value: "ondemand",
      },
    ];
  }

  onSavedTimerComplete() {
    this.saved = false;
  }

  beforeDestroy() {
    window.clearTimeout(this.savedTimer);
  }

  async onSave() {
    this.saving = true;
    this.saved = false;
    this.connectionEditor.setRefreshRate(this.refreshRate * 60);
    this.connectionEditor
      .updateDataConnection({})
      .then(() => {
        this.saved = true;
      })
      .finally(() => {
        this.saving = false;
        this.savedTimer = window.setTimeout(this.onSavedTimerComplete, 3500);
      });
  }
}
</script>
