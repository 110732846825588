<template>
  <div class="flex-col h-full">
    <!-- <CalendarEmptyMessage v-if="!hasDataConnection" /> -->

    <div ref="content">
      <div class="flex" :style="getStyle('eventTitle')">
        {{ d.summary }}
      </div>

      <!-- Show date if not the same as today's date: -->
      <TimeDisplay
        :style="getStyle('eventTime')"
        v-if="!eventStartsToday && !d.isSingleDay"
        :value="d.start"
        :options="{ month: 'long', day: 'numeric' }"
      />

      <TimeRangeDisplay
        class="flex flex-wrap"
        v-if="!d.isSingleDay"
        :style="getStyle('eventTime')"
        :start="d.start"
        :end="d.end"
        :options="timeRangeFormat"
      />
      <TimeDisplay
        v-else
        :style="getStyle('eventTime')"
        :value="d.start"
        :options="{
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        }"
      />
      <div
        class="flex mt-3"
        v-if="showEventLocation && d.location"
        :style="getStyle('eventLocation')"
      >
        {{ d.location }}
      </div>
      <div
        v-if="showDivider"
        class="w-96 h-1 mt-3"
        :style="{ backgroundColor: dividerColor }"
      ></div>
      <div
        v-if="showEventCreator && d.creatorEmail"
        class="flex flex-col mt-3"
        :style="getStyle('eventCreator')"
      >
        <div class="w-full">{{ d.creatorEmail }}</div>
        <div class="font-normal w-full">(Event Creator)</div>
      </div>
      <div
        class="flex mt-3"
        v-if="showEventDescription && d.description"
        :style="getStyle('eventDescription')"
      >
        {{ d.description }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { CalendarEvent } from "@/types/calendar";
import { NodeData, NodeSetData } from "@/types/data";
import TimeRangeDisplay from "@/components/widgets/TimeRangeDisplay.vue";
import TimeDisplay from "@/components/widgets/TimeDisplay.vue";
import CalendarEmptyMessage from "@/components/widgets/CalendarEmptyMessage.vue";
import CalendarBase from "@/components/widgets/CalendarBase.vue";
import { DateTime } from "luxon";

/*
DESIGN NOTES:
- Time displays like 
"12:45pm - 2:00pm", 
"Tuesday, March 10", 
"March 10 - March 13", or 
"March 10, 9:15am - March 13, 3:00pm"
*/

/*
[ ] TODO: test timeRangeDisplay options
*/

// TODO: Update as time advances

// TODO: Divider line should be alignable as well!
// [ ] NOTE: seems divider line should use main text color as default...maybe Pete mentioned that? So..."inherit"?

@Component({
  components: {
    TimeRangeDisplay,
    TimeDisplay,
    CalendarEmptyMessage,
  },
})
export default class CalendarEventComponent extends CalendarBase {
  @Prop(Array) data: any[];
  @Prop(Number) dataIndex: number;
  @Prop({ default: true }) previewLiveData: boolean;

  @Prop(Boolean) showEventLocation: boolean;
  @Prop(Boolean) showEventDescription: boolean;
  @Prop(Boolean) showEventCreator: boolean;
  @Prop(Boolean) showDivider: boolean;
  @Prop(Number) scaleX: number;
  @Prop({ default: false }) use24Hour: boolean;

  @Prop(String) readonly dividerColor: string;

  // ---------------------------------------------------------------------
  @Prop(String) readonly eventTitle_fontFamily: string | null;
  @Prop(Number) readonly eventTitle_fontWeight: number;
  @Prop(String) readonly eventTitle_textColor: string | null;
  @Prop(Number) readonly eventTitle_fontSize: number;
  @Prop(Number) readonly eventTitle_letterSpacing: number;
  @Prop(String) readonly eventTitle_textTransform: string | null;
  @Prop(String) readonly eventTitle_textDecoration: string | null;
  @Prop(String) readonly eventTitle_fontStyle: string | null;
  @Prop(Number) readonly eventTitle_lineHeight: number;
  @Prop(String) readonly eventTitle_textAlign: string | null;

  @Prop(String) readonly eventTime_fontFamily: string | null;
  @Prop(Number) readonly eventTime_fontWeight: number;
  @Prop(String) readonly eventTime_textColor: string | null;
  @Prop(Number) readonly eventTime_fontSize: number;
  @Prop(Number) readonly eventTime_letterSpacing: number;
  @Prop(String) readonly eventTime_textTransform: string | null;
  @Prop(String) readonly eventTime_textDecoration: string | null;
  @Prop(String) readonly eventTime_fontStyle: string | null;
  @Prop(Number) readonly eventTime_lineHeight: number;
  @Prop(String) readonly eventTime_textAlign: string | null;

  @Prop(String) readonly eventDescription_fontFamily: string | null;
  @Prop(Number) readonly eventDescription_fontWeight: number;
  @Prop(String) readonly eventDescription_textColor: string | null;
  @Prop(Number) readonly eventDescription_fontSize: number;
  @Prop(Number) readonly eventDescription_letterSpacing: number;
  @Prop(String) readonly eventDescription_textTransform: string | null;
  @Prop(String) readonly eventDescription_textDecoration: string | null;
  @Prop(String) readonly eventDescription_fontStyle: string | null;
  @Prop(Number) readonly eventDescription_lineHeight: number;
  @Prop(String) readonly eventDescription_textAlign: string | null;

  @Prop(String) readonly eventLocation_fontFamily: string | null;
  @Prop(Number) readonly eventLocation_fontWeight: number;
  @Prop(String) readonly eventLocation_textColor: string | null;
  @Prop(Number) readonly eventLocation_fontSize: number;
  @Prop(Number) readonly eventLocation_letterSpacing: number;
  @Prop(String) readonly eventLocation_textTransform: string | null;
  @Prop(String) readonly eventLocation_textDecoration: string | null;
  @Prop(String) readonly eventLocation_fontStyle: string | null;
  @Prop(Number) readonly eventLocation_lineHeight: number;
  @Prop(String) readonly eventLocation_textAlign: string | null;

  @Prop(String) readonly eventCreator_fontFamily: string | null;
  @Prop(Number) readonly eventCreator_fontWeight: number;
  @Prop(String) readonly eventCreator_textColor: string | null;
  @Prop(Number) readonly eventCreator_fontSize: number;
  @Prop(Number) readonly eventCreator_letterSpacing: number;
  @Prop(String) readonly eventCreator_textTransform: string | null;
  @Prop(String) readonly eventCreator_textDecoration: string | null;
  @Prop(String) readonly eventCreator_fontStyle: string | null;
  @Prop(Number) readonly eventCreator_lineHeight: number;
  @Prop(String) readonly eventCreator_textAlign: string | null;

  @Prop(String) wid: string;

  getStyle(element: string) {
    return this.getStyles(element, true);
  }

  get d() {
    const data = this.data;

    /**
     * Remove any events whose End time is before current time.
     */
    return (
      (data ?? [{}])
        .map((row: (NodeData | NodeSetData)[]) => new CalendarEvent(row))
        .filter((e) => DateTime.now().toMillis() <= e.end.toMillis())[0] ?? {}
    );
  }

  get eventStartsToday() {
    const today: any = DateTime.now();
    const start: any = this.d.start;
    const eventStartsToday = ["day", "month", "year"].every(
      (prop: string) => today[prop] === start[prop]
    );
    return eventStartsToday;
  }

  get timeRangeFormat() {
    if (!this.d) return {};
    if (this.d.durationExceedsOneDay) {
      if (this.d.isAllDay) {
        return {
          month: "long",
          day: "numeric",
        };
      } else {
        return {
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: !this.use24Hour,
        };
      }
    } else {
      return {
        hour: "numeric",
        minute: "numeric",
        hour12: !this.use24Hour,
      };
    }
  }
}
</script>
