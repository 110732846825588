<template>
  <div id="app-editor" class="w-full h-full relative select-none">
    <Header id="app-header" class="z-30" />
    <Canvas
      id="app-main"
      class="z-0 select-text"
      :style="{
        visibility: hideCanvas ? 'hidden' : 'visible',
      }"
    />
    <Sidebar id="app-sidebar" class="z-30" />

    <div
      class="fixed inset-0 flex z-50 items-center justify-center text-white bg-gray-700"
      v-if="showServerError"
    >
      <div class="max-w-lg">
        <FatalServerError
          class="bg-gray-800 p-4 rounded-lg shadow-xl mb-4 relative"
        >
          <div>{{ loadErrorMsg }}</div>
          <div
            v-if="allowToCloseFatalErrorMessage"
            class="absolute -top-3 -right-3 rounded-full bg-gray-600 hover:bg-gray-500 cursor-pointer p-1"
            @click="showServerError = false"
          >
            <Icon name="Close" class="h-5 w-5" />
          </div>
        </FatalServerError>
        <router-link
          class="text-lg text-app-teal border-b border-app-teal hover:text-white ml-4"
          :to="{ name: 'Apps' }"
          v-t="'Editor.goToApps'"
        ></router-link>
      </div>
    </div>

    <router-view></router-view>

    <portal-target name="mainEditor" class="absolute"></portal-target>
    <div
      class="absolute top-0 left-0"
      :style="dragGhostStyle"
      id="dragged-ghost"
    >
      <img :src="draggingAssetSrc" class="w-full h-full block object-contain" />
    </div>

    <HelpConnectData
      v-if="showHelpOverlay && appEditor.isLoaded"
      @close="showHelpOverlay = false"
    />

    <portal-target name="tokenDropdown" class="absolute"></portal-target>
    <portal-target name="modal" class="absolute"></portal-target>
    <portal-target name="filterModal" class="absolute"></portal-target>

    <portal-target
      name="guide"
      class="pointer-events-none absolute w-full h-full"
    ></portal-target>
  </div>
</template>

<script lang="ts">
import { computed } from "vue";
import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import Canvas from "@/components/Canvas.vue";
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import ActionModal from "@/components/ActionModal.vue";
import Modal from "@/components/Modal.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import FatalServerError from "@/components/FatalServerError.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import { getFontOptions } from "@/fonts/fontOptions";

import { allSettled, extractErrorMessage, loadImages } from "@/utils";

import { quantizeNumber } from "@/utils";
import { EventBus } from "@/eventbus";
import { Route } from "vue-router";
import { loadFonts } from "@/fonts/loadFonts";
import Icon from "@/components/icons/Icon.vue";

import SubscribeToPublish from "@/components/SubscribeToPublish.vue";
import DownloadBundle from "@/components/DownloadBundle.vue";
import { FontAssetInfo } from "@/types/bundleTypes";
import { StyleValue } from "vue/types/jsx";
import { logger } from "@core/logger";
import { useDragDropStore } from "@/stores/dragDrop";
import { useAppEditorStore } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionsStore } from "@/stores/connections";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { AppMode } from "@/types";
import { BASE_PARENT_ID } from "@/constants";
import appSettings from "@/appSettings";
import HelpConnectData from "@/components/HelpConnectData.vue";

// Need this for hooks to work:
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);

// - [ ] BUG: When you say "no I don't want to save" it still pops up the window alert

@Component({
  components: {
    Canvas,
    Sidebar,
    Header,
    Modal,
    ButtonGradient,
    Icon,
    ActionModal,
    SubscribeToPublish,
    DownloadBundle,
    FatalServerError,
    UiBlocker,
    HelpConnectData,
  },
})
export default class AppEditor extends Vue {
  @Provide() appMode: AppMode = "edit";
  showHelpOverlay = this.isFromTemplate;

  /**
   * The following clump of properties provides the
   * state RepeaterComponent and GroupComponent need
   * to render children.
   *
   * This allows us to avoid using Pinia in app renderer.
   *
   * Time will tell if this is a good approach.
   */
  @Provide()
  context = {
    widgets: computed(() => this.appEditor.widgets),
    parents: computed(() => this.appEditor.parents),
    dataBindings: computed(() => this.appEditor.dataBindings),
    widgetData: computed(() => this.appEditor.widgetData),
    conditions: computed(
      () => this.conditionGroupsStore.activeWidgetConditionsMap
    ),
    renderScale: computed(() => this.appEditor.scale),
  };

  hideCanvas = false;
  saveInProgress = false;
  loadErrorMsg = "";
  showServerError = false;

  resolveCb: (value: boolean) => void;

  // GETTERS -------------------------------------------------------
  get conditionGroupsStore() {
    return useConditionGroupsStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  get customFonts() {
    return this.appEditor.customFonts;
  }

  get allowToCloseFatalErrorMessage() {
    return !appSettings.environment.isProduction;
  }

  get draggingAssetSrc() {
    return this.dragDropStore.draggingInfo?.url;
  }

  get dragGhostStyle() {
    let result: StyleValue = {};
    if (
      !this.dragDropStore.draggingInfo ||
      !this.dragDropStore.draggingInfo.url
    )
      result = {
        opacity: 0,
        pointerEvents: "none",
      };
    // return result;
    const style = this.dragDropStore.ghostStyle;

    if (this.dragDropStore.draggingInfo === null) return result;

    result = {
      opacity: style.opacity,
      transform: `translate(${style.left}px, ${style.top}px)`,
      width: `${style.width}px`,
      height: `${style.height}px`,
      pointerEvents: "none",
      zIndex: 10000,
    };

    return result;
  }

  // Use this to determine whether to show helpful overlay and pre-select data-bound widget for user
  get isFromTemplate() {
    return this.$route.query.isFromTemplate === "true";
  }

  closeHelpOverlay() {
    this.showHelpOverlay = false;
  }

  // LIFECYCLE METHODS -------------------------------------------------------
  created() {
    window.addEventListener("beforeunload", this.saveApp);
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("click", this.closeHelpOverlay);
    const conditionsStore = useConditionGroupsStore();
    conditionsStore.copiedConditionGroupUuid = null;
    conditionsStore.getConditionGroups(this.$route.params.id);
    useConnectionsStore().fetchPagedConnections();
    EventBus.on("RESET_ARTBOARD", this.zoomAtStart);

    EventBus.on("PHOTO_DRAG_ENTER", this.handlePhotoDragEnter);
    EventBus.on("PHOTO_DRAG_LEAVE", this.handlePhotoDragLeave);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.saveApp);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("click", this.closeHelpOverlay);
    EventBus.off("RESET_ARTBOARD", this.zoomAtStart);
    EventBus.off("PHOTO_DRAG_ENTER", this.handlePhotoDragEnter);
    EventBus.off("PHOTO_DRAG_LEAVE", this.handlePhotoDragLeave);
  }

  // ROUTE HANDLERS -------------------------------------------------------

  beforeRouteEnter(to: Route, from: Route, next: (vm: any) => void) {
    next((vm: AppEditor) => vm.initialize());
  }

  async beforeRouteLeave(to: any, from: any, next: any) {
    if (this.appEditor.hasUnsavedChanges) {
      try {
        await this.saveApp();
      } catch {
        // I think it's ok to swallow this error, since we're leaving the page anyway
      }
    }
    next();
  }

  get selectedWidgetId() {
    return this.appEditor.selectedWidget?.wid;
  }

  // WATCHERS -------------------------------------------------------

  @Watch("customFonts")
  onCustomFontsChanged() {
    getFontOptions(this.customFonts).then((fontOptions) => {
      const assets = fontOptions.flatMap((f) => {
        return f.variants.map((v) => {
          return {
            source: "Google",
            family: f.family,
            weight: v.weight,
            style: v.style,
          };
        });
      }) as FontAssetInfo[];

      return loadFonts(assets, "user-fonts");
    });
  }

  /**
   * Zooms the canvas so that the artboard fits the screen.
   */
  zoomAtStart() {
    const ruler = this.appEditor.rulerSize;
    const scaleStep = this.appEditor.scaleStep;
    const artboard = this.appEditor.artboard;
    const canvas = (
      document.querySelector("#canvas") as HTMLElement
    ).getBoundingClientRect();

    const area = { w: canvas.width - ruler * 3, h: canvas.height - ruler * 3 };

    const max = Math.max(artboard.w / area.w, artboard.h / area.h);
    const scale = quantizeNumber(1 / max, scaleStep, false);

    this.appEditor.setScale(scale);

    // // Well...this is hacky and super weird, but seems to work....for some reason...
    // // This correctly positions the artboard at the center of canvas-scaler:
    let x = ruler + (canvas.width - artboard.w) / 2;
    let y = ruler + (canvas.height - artboard.h) / 2;
    this.appEditor.positionArtboard({ x, y });
  }

  saveChanges() {
    this.resolveCb(true);
  }

  discardChanges() {
    this.resolveCb(false);
  }

  async saveApp() {
    this.saveInProgress = true;

    try {
      await this.appEditor.updateApp();
    } finally {
      this.saveInProgress = false;
    }
    // We don't catch here so that error propagates to confirmSaveFlow
  }

  confirmSave() {
    return new Promise((resolve, reject) => {
      this.resolveCb = resolve;
    });
  }

  /**
   * Load images and fonts that are in use by the app.
   */
  loadDependencies() {
    const assets = this.appEditor.assetsInUse;
    const urls = assets.images.filter((a) => a.shouldPreload).map((a) => a.url);

    const promises: Promise<unknown>[] = loadImages(urls);
    promises.push(loadFonts(assets.fonts, "in-use"));

    return allSettled(promises);
  }

  /**
   * When the app is updated, we need to update the data
   * for all widgets that are bound to data.
   */
  async updateWidgetData(checksum: number | null) {
    if (checksum === null) return;

    // EventBus.emit("AWAITING_SERVER", true);
    const appData = useAppDataStore();
    appData.syncDataForBindings().finally(() => {
      // EventBus.emit("AWAITING_SERVER", false);
    });
  }

  async initialize() {
    this.appEditor.resetEditorState();
    EventBus.emit("AWAITING_SERVER", true);
    this.hideCanvas = true;

    const appId = this.$route.params.id;
    if (!appId) return;

    return (
      this.appEditor
        // Load app model + data
        .loadApp(appId)
        .then(() => {
          return this.loadDependencies();
        })
        .then(() => {
          this.appEditor.isLoaded = true;
          EventBus.emit("AWAITING_SERVER", false);
          EventBus.emit("APP_EDITOR_INITIALIZED", { appId });
          this.hideCanvas = false;
          this.$nextTick(() => {
            this.zoomAtStart();
            this.preselectTemplateWidget();
          });

          // Set up a watcher to update widget data whenever the app is changed.
          this.$watch(() => this.appEditor.checksum, this.updateWidgetData);
        })
        .catch((reason: string) => {
          console.log("Error with load app", reason);
          logger.track(reason);
          this.loadErrorMsg = extractErrorMessage(reason);
          this.showServerError = true;
          EventBus.emit("AWAITING_SERVER", false);
        })
    );
  }

  preselectTemplateWidget() {
    if (!this.isFromTemplate) {
      return;
    }

    const datasetBinding = this.appEditor.dataBindings.filter((db) =>
      ["dataset", "datasetparent"].some(
        (x) => db.bindingType?.toLowerCase() === x
      )
    )?.[0];
    if (datasetBinding) {
      const widget = this.appEditor.widgets[datasetBinding.widgetId];
      this.appEditor.replaceSelections([widget.wid]);

      this.$nextTick(() => {
        EventBus.emit("OPEN_DATA_PANEL");
      });
    }
  }

  handleResize() {
    EventBus.emit("WINDOW_RESIZE");
  }

  // PHOTO DROP CONTAINER EVENTS --------------------
  // These are pretty much copied directly from PhotoDropContainer.vue
  handlePhotoDragEnter(e: { widgetId: string; cellIndex?: number }) {
    if (!this.draggingAssetSrc) return;

    const widgetType = this.appEditor.widgets[e.widgetId].type;
    const { parentId, repeaterIndex } = this.appEditor.editingContext;
    const ourParentId = this.appEditor.widgets[e.widgetId].parentId;

    // If a repeater is being edited, ignore this event if either (1) that repeater is not this component's parent or (2) the active cell is not this component's cell
    if (!this.appEditor.isBaseEditingContext) {
      if (parentId !== ourParentId) return;
      if (repeaterIndex !== e.cellIndex) return;
    }

    // If this component belongs to a repeater, ignore this event if isBaseEditingContext
    // But only do that if we're not dragging a node -- if we are dragging node, want to process this event normally
    if (ourParentId !== BASE_PARENT_ID) {
      if (
        this.appEditor.isBaseEditingContext &&
        !this.dragDropStore.isDraggingImage
      )
        return;
    }

    this.dragDropStore.hoverTarget = {
      widgetType: widgetType,
      widgetId: e.widgetId,
      isPhotoDropContainer: true,
    };
  }

  handlePhotoDragLeave() {
    if (this.dragDropStore.isHandlingDrop === false) {
      this.dragDropStore.hoverTarget = null;
    }
  }
}
</script>

<style lang="postcss" scoped>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app-editor {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header header"
    "sidebar main";
}

#app-header {
  grid-area: header;
}
#app-main {
  grid-area: main;
  align-self: stretch;
  overflow: hidden;
}
#app-sidebar {
  grid-area: sidebar;
  align-self: stretch;
  overflow: hidden;
}

.checkerboard {
  opacity: 0.25;
  background-image: url("../assets/checkerboard2.jpg");
  background-size: cover;
}

#dragged-ghost {
  pointer-events: none;
}
</style>
