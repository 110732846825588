<template>
  <div class="w-full h-full" :class="{ 'bg-gray-500': isSnapshot }">
    <video v-if="isSnapshot === false" v-bind="props" class="w-full h-full">
      <source v-if="url" :src="url" :type="mimeType" />
    </video>
  </div>
</template>

<script lang="ts">
/**
 * This component is only used in App Renderer
 * VideoWrapper is used in App Editor
 */
import { Component, Prop } from "vue-property-decorator";
import { makeId } from "@/utils";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";
import { renderState } from "@/rendererState";

@Component({
  components: {},
})
export default class VideoComponent extends AnimatableWidget {
  @Prop(String) readonly url: string;
  @Prop(String) readonly mimeType: string;
  @Prop(Boolean) readonly loop: boolean;

  get isSnapshot() {
    return renderState.isSnapshot;
  }

  /**
   * It is important that each instance of this component has its own unique id,
   * for the case of children of repeaters (instances of which share their wid across cells).
   */
  @Prop({ default: makeId }) componentId: string;

  get props() {
    return {
      id: this.componentId,
      autoplay: true,
      muted: true,
      loop: this.loop,
    };
  }
}
</script>
