<template>
  <div class="pb-3 space-y-2 px-3">
    <ToggleInput class="py-3" v-model="blurDisplay"
      ><span class="font-semibold text-sm" v-t="'visible'"></span
    ></ToggleInput>

    <div class="w-full">
      <!-- unsure about center vs start here: -->
      <FormLabel v-t="'blurValue'" />
      <NumberSliderInput
        :min="0"
        :max="100"
        :step="1"
        :value="blurValue"
        @change="apply('blurValue', $event)"
        @input="preview('blurValue', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    NumberSliderInput,
    ToggleInput,
    FormLabel,
  },
})
export default class BlurEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get selections() {
    return this.appEditor.selections;
  }

  // TODO figure out how to share this across components...
  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];
      if (values.length === 1) {
        result = values[0];
      }
    }
    return result as T;
  }

  apply(prop: string, value: any) {
    this.appEditor.setWidgetProps(this.selections, {
      [prop]: value,
    });
  }

  preview(prop: string, value: any) {
    this.appEditor.setWidgetProps(
      this.selections,
      { [prop]: value },
      "NO_UNDO"
    );
  }

  get blurValue() {
    return this.getValue("blurValue");
  }

  get blurDisplay() {
    return this.getValue("blurDisplay");
  }

  set blurDisplay(val: boolean) {
    this.apply("blurDisplay", val);
  }
}
</script>
