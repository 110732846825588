<template>
  <div class="relative flex flex-col justify-center text-center">
    <FatalServerError v-if="clientModelError">
      <div class="text-lg" v-text="$t('ThirdPartyAuth.clientModelError')"></div>
      <div class="font-mono">Error: {{ clientModelError }}</div>
    </FatalServerError>

    <div v-if="!clientModelError">
      <slot name="title">
        <div class="text-xl mb-4" v-text="grantAccessMessage"></div>
      </slot>
      <slot />
      <div v-if="errorMessage" class="mb-4 text-red-500">
        {{ errorMessage }}
      </div>

      <AuthSignInButton
        v-if="authClientModel"
        :text="signInButtonText"
        :iconUrl="signInButtonIconUrl"
        @click="authorize"
        :theme="theme"
        :disabled="authorizing"
      />
      <div
        class="text-xs text-gray-400"
        v-t="'ThirdPartyAuth.opensPopup'"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { api } from "@/api/backend";
import FatalServerError from "@/components/FatalServerError.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import ToolTip from "@/components/Tooltip.vue";
import AuthSignInButton from "./AuthSignInButton.vue";

type AuthClientModel = {
  uiDict: Record<string, string>;
  authModel: Record<string, string>;
};

@Component({
  components: {
    FatalServerError,
    UiBlocker,
    ToolTip,
    AuthSignInButton,
  },
})
export default class ThirdPartyOAuth extends Vue {
  @Prop(String) dataProviderId: string;
  @Prop(Object) state: string;
  @Prop({ type: String, default: "light" }) theme: "dark" | "light";
  @Prop(Boolean) showAccessError: boolean;

  authorizing = false;
  clientModelError: string | null = null;
  showScopeError = false;
  showUnknownError = false;
  authClientModel: Record<string, string> | null = null;
  uiDictionary: Record<string, string> | null = null;

  private popupTimer: number | null = null;
  private popupWindow: Window | null = null;

  created() {
    const returnUrl = new URL(
      "/assets/oauth-popup.html",
      window.location.origin
    );

    let params = new URLSearchParams();
    params.set("dataProviderId", this.dataProviderId);
    params.set("clientUri", returnUrl.toString());
    params.set("langCode", window.navigator.language);

    let endpoint = `connection-oauth2/auth-model?${params.toString()}`;

    api
      .get<AuthClientModel>(endpoint)
      .then((model) => {
        this.authClientModel = model.authModel;
        this.uiDictionary = model.uiDict;
      })
      .catch((reason) => {
        this.clientModelError = reason;
      });
  }

  get errorMessage() {
    if (this.authorizing) return undefined;

    if (this.showScopeError) return this.scopeErrorMessage;

    if (this.showUnknownError) return this.$t("errors.unknownError");

    return undefined;
  }

  get signInButtonText() {
    return this.uiDictionary ? this.uiDictionary["oAuthSignInButtonText"] : "";
  }

  get signInButtonIconUrl() {
    return this.uiDictionary
      ? this.uiDictionary["oAuthSignInButtonIconFileName"]
      : "";
  }

  get grantAccessMessage() {
    return this.uiDictionary
      ? this.uiDictionary["oAuthGrantAccessMessage"]
      : "";
  }

  get scopeErrorMessage() {
    return this.uiDictionary
      ? this.uiDictionary["oAuthScopeErrorMessage"]
      : undefined;
  }

  beforeDestroy() {
    this.clearPopupTimer();
  }

  clearPopupTimer() {
    if (this.popupTimer !== null) {
      window.clearInterval(this.popupTimer);
      this.popupTimer = null;
    }
    this.authorizing = false;
  }

  closePopup() {
    this.clearPopupTimer();
    if (this.popupWindow) {
      this.popupWindow.close();
    }
  }

  handlePopupClosed() {
    this.clearPopupTimer();
  }

  handlePopupParams(params: string | undefined) {
    if (typeof params === "undefined") return;

    const values = new URLSearchParams(params);
    const authSuccess = values.get("oAuthSuccess");
    const hasMissingOrInvalidScope = values.get(
      "oAuthHasMissingOrInvalidScope"
    );

    if (authSuccess === "True") {
      this.$emit("success");
      this.closePopup();
    }

    if (authSuccess === "False") {
      if (hasMissingOrInvalidScope === "true") {
        this.$emit("error", { hasMissingOrInvalidScope: true });
        this.showScopeError = true;
      } else this.showUnknownError = true;

      this.closePopup();
    }
  }

  authorize() {
    if (this.authClientModel === null) return;

    this.showScopeError = false;
    this.showUnknownError = false;

    const url = new URL(this.authClientModel.oAuthEndpoint);
    this.popupWindow = window.open(
      url.toString(),
      "AuthPopup",
      "width=500,height=600,0,status=0"
    );

    // Use polling method to check the status of the popup window.
    this.popupTimer = window.setInterval(() => {
      try {
        if (this.popupWindow?.closed === true) {
          console.log("third-party auth popup closed");
          this.handlePopupClosed();
        } else {
          console.log(
            "third-party auth handleParams",
            this.popupWindow?.location.search
          );
          this.handlePopupParams(this.popupWindow?.location.search);
        }
      } catch (ex) {
        if (ex instanceof DOMException) {
          /**
           * When the popup window navigates to the auth provider's domain
           * and we attempt to read properties of `this.popupWindow`
           * the browser throws a DOMException, we can just ignore it
           * because eventually, when the auth routine redirects back
           * to the connect app domain, we'll be able to access it again.
           */
        }
      }
    }, 100);
  }
}
</script>
