<template>
  <div class="divide-y">
    <CollapsePanel :shouldListenForOpenCommand="true">
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <DataSetChooser />
      <div class="py-3 px-4 space-y-3">
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.xColumn'"></div>
          <EditorSelectMenu
            :value="model.xAxisColumnId"
            :options="xColumnOptions"
            @input="apply({ xAxisColumnId: $event })"
          />
        </div>
        <div
          v-if="xColumnOptions.length < 1"
          v-t="'graph.xAxisColumnError'"
        ></div>
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.yColumn'"></div>
          <EditorSelectMenu
            :value="model.yAxisColumnId"
            :options="yColumnOptions"
            @input="apply({ yAxisColumnId: $event })"
          />
        </div>
        <div
          v-if="yColumnOptions.length < 1"
          v-t="'graph.yAxisColumnError'"
        ></div>
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.xTitle'"></div>
          <EditorTextInput
            :value="xTitlePreview"
            @input="preview({ xTitleManual: $event })"
            @change="apply({ xTitleManual: $event })"
          />
        </div>
        <div>
          <div class="text-sm text-gray-500 ml-2" v-t="'graph.yTitle'"></div>
          <EditorTextInput
            :value="yTitlePreview"
            @input="apply({ yTitleManual: $event })"
            @change="apply({ yTitleManual: $event })"
          />
        </div>
        <div class="space-y-3">
          <NumberInputBar
            :width="22"
            :value="model.minManual ? model.minManual : model.minValue"
            :min="-Infinity"
            @change="apply({ minManual: $event })"
            ><span class="text-sm" v-t="'minValue'"></span>
          </NumberInputBar>
          <NumberInputBar
            :width="22"
            :value="model.maxValue"
            @change="apply({ maxManual: $event })"
            ><span class="text-sm" v-t="'maxValue'"></span>
          </NumberInputBar>
          <div class="pb-4 px-3">
            <div class="text-gray-500 text-sm" v-t="'graph.labelTicks'"></div>
            <SliderInput
              :value="model.labelTicks"
              :min="2"
              :max="30"
              :step="2"
              @input="preview({ labelTicks: $event })"
              @change="apply({ labelTicks: $event })"
            />
          </div>
        </div>
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.columnOptions'"></div>
      </template>
      <div class="py-3 px-4">
        <div class="px-3">
          <div class="text-sm text-gray-500" v-t="'graph.columnPadding'"></div>
          <SliderInput
            :value="model.barPadding"
            :min="0"
            :max="1"
            :step="0.01"
            @input="preview({ barPadding: $event })"
            @change="apply({ barPadding: $event })"
          />
        </div>
        <ToggleInput
          class="mt-10"
          :value="model.showValues"
          @input="apply({ showValues: $event })"
          ><span class="text-sm" v-t="'graph.showValues'"></span>
        </ToggleInput>
        <ToggleInput
          class="my-3"
          :value="model.useCustomBarColors"
          @input="apply({ useCustomBarColors: $event })"
          ><span class="text-sm" v-t="'graph.customColumnColors'"></span>
        </ToggleInput>
        <div
          class="text-sm mb-1 text-gray-500"
          v-if="!model.useCustomBarColors"
        ></div>
        <ColorInputBar
          v-if="!model.useCustomBarColors"
          :value="model.barColor"
          :gradientEnabled="false"
          @input="preview({ barColor: $event })"
          @change="apply({ barColor: $event })"
          propName="barColor"
          ><span v-t="'graph.columnColor'"></span
        ></ColorInputBar>
        <div
          class="bar-color wrap mb-2"
          :key="index"
          v-for="(item, index) in labels"
        >
          <div class="flex flex-row mb-1" v-if="model.useCustomBarColors">
            <div class="text-gray-500 text-sm" v-if="item"></div>
          </div>
          <ColorInputBar
            v-if="model.useCustomBarColors"
            :value="colorArray[index]"
            class="mb-4 pl-3"
            :gradientEnabled="false"
            :bindable="false"
            @input="preview({ customBarColors: updateColor($event, index) })"
            @change="apply({ customBarColors: updateColor($event, index) })"
          >
            <span v-t="item"></span
          ></ColorInputBar>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.axisOptions'"></div>
      </template>
      <div class="pb-5 divide-y">
        <div class="pb-3">
          <div
            class="font-semibold text-gray-500 text-sm px-6 pb-2"
            v-t="'graph.titleOptions'"
          ></div>
          <GroupTextFormatEditor
            :model="model"
            element="title"
            @update="apply($event)"
            :disableLineHeight="true"
            :disableAlignment="true"
            :disableLetterSpacing="true"
          />
          <div class="space-y-3 px-4">
            <NumberInputBar
              :value="model.leftTitlePadding"
              @change="apply({ leftTitlePadding: $event })"
              ><div class="text-sm" v-t="'graph.yTitlePadding'"></div
            ></NumberInputBar>

            <NumberInputBar
              :value="model.bottomTitlePadding"
              @change="apply({ bottomTitlePadding: $event })"
            >
              <div class="text-sm" v-t="'graph.xTitlePadding'"></div
            ></NumberInputBar>
          </div>
        </div>
        <div>
          <div
            class="font-semibold text-gray-500 text-sm mt-5 px-6 pb-2"
            v-t="'graph.labelOptions'"
          ></div>
          <GroupTextFormatEditor
            :model="model"
            element="label"
            @update="apply($event)"
            :disableLineHeight="true"
            :disableAlignment="true"
            :disableLetterSpacing="true"
          />
        </div>
        <div class="px-4 pt-5">
          <div class="px-3 pb-5">
            <div
              class="text-gray-500 text-sm"
              v-t="'graph.axisThickness'"
            ></div>
            <SliderInput
              class="w-36 mb-2"
              :value="model.lineThickness"
              :min="0"
              :max="50"
              :step="1"
              @input="preview({ lineThickness: $event })"
              @change="apply({ lineThickness: $event })"
            />
          </div>

          <ColorInputBar
            class="py-3"
            :value="model.lineColor"
            :gradientEnabled="false"
            @input="preview({ lineColor: $event })"
            @change="apply({ lineColor: $event })"
            propName="lineColor"
            ><div class="text-sm" v-t="'graph.axisColor'"></div
          ></ColorInputBar>
          <ToggleInput
            class="py-3"
            :value="model.xLabelTilt"
            @input="apply({ xLabelTilt: $event })"
            ><span class="text-sm" v-t="'graph.xLabelTilt'"></span>
          </ToggleInput>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'graph.backgroundOptions'"></div>
      </template>
      <div class="py-3 px-4">
        <ToggleInput
          class="mb-3"
          :value="model.showBg"
          @input="apply({ showBg: $event })"
          ><span class="text-sm" v-t="'graph.showBg'"></span>
        </ToggleInput>
        <div v-if="model.showBg">
          <ColorInputBar
            v-if="model.showBg"
            :value="model.bgColor"
            :gradientEnabled="false"
            @input="preview({ bgColor: $event })"
            @change="apply({ bgColor: $event })"
            propName="bgColor"
            ><span class="text-sm" v-t="'graph.bgColor'"></span
          ></ColorInputBar>
        </div>
        <ToggleInput
          class="mt-3 mb-3"
          :value="model.showGrid"
          @input="apply({ showGrid: $event })"
          ><span class="text-sm" v-t="'graph.showGrid'"></span>
        </ToggleInput>
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import EditorTextInput from "@/components/inputs/EditorTextInput.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import SliderInput from "@/components/inputs/SliderInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import DataSetChooser from "@/components/widgets/DataSetChooser.vue";
import { ColumnGraphOptions } from "@/components/widgets/ColumnGraph/ColumnGraphOptions";
import { NodeData } from "@/types/data";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CollapsePanel,
    ColorInputBar,
    NumberInputBar,
    ToggleInput,
    EditorTextInput,
    EditorSelectMenu,
    SliderInput,
    BaseWidgetEditor,
    GroupTextFormatEditor,
    DataSetChooser,
  },
})
export default class ColumnGraphEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as ColumnGraphOptions;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  get colorArray() {
    return this.model.customBarColors.slice(0, this.dataSet.length);
  }

  updateColor(color: string, index: number) {
    let newColors = this.colorArray.slice();
    newColors[index] = color;
    return newColors;
  }

  get xTitlePreview() {
    return this.model.xTitleManual === null
      ? this.model.xTitle
      : this.model.xTitleManual;
  }

  get yTitlePreview() {
    return this.model.yTitleManual === null
      ? this.model.yTitle
      : this.model.yTitleManual;
  }

  get dataBinding() {
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.model.wid,
      bindingType: "DataSet",
    });

    return bindings.length > 0 ? bindings[0] : undefined;
  }

  get dataSet(): NodeData[][] {
    return (this.widgetData[this.model.wid]?.[0]?.data as NodeData[][]) ?? [];
  }

  get dataColumns(): NodeData[] {
    return this.dataSet.length > 0 ? this.dataSet[0] : [];
  }

  get xColumnOptions() {
    return this.dataColumns
      .filter(
        (c) =>
          c.dataType === "String" ||
          c.dataType === "Date" ||
          c.dataType === "Time" ||
          c.dataType === "DateTime"
      )
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get yColumnOptions() {
    return this.dataColumns
      .filter((c) => c.dataType === "Number" && c.query != "__sf_index__")
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get labels() {
    if (this.dataSet.length > 0) {
      return this.dataSet.map((row) => {
        return row.find((c) => c.uuid === this.model.xAxisColumnId)
          ?.formattedValue as string;
      });
    }
    return [] as string[];
  }
}
</script>
