<template>
  <div class="relative flex flex-col flex-grow space-y-6">
    <div class="flex items-center justify-between">
      <div class="text-xl">Select a {{ propertyTypeDisplay }} value</div>

      <ScalarNodeDisplay
        v-if="hasSelection"
        :nodeDisplayProps="nodeDisplayProps"
      />

      <div class="space-x-2 flex items-center">
        <div class="text-red-500" v-if="showErrorMessage">
          {{ $t("Error.generic") }}
        </div>
        <FormButton :disabled="!hasSelection" @click="onCompleteClick"
          >Save Selection</FormButton
        >
      </div>
    </div>
    <div class="relative flex flex-col flex-grow">
      <div class="flex flex-col flex-grow">
        <SelectTreeValue
          v-if="schemaType === 'Tree'"
          :rootNode="treeData"
          :selectedQuery="selectedQuery"
          :selectionType="propertyType"
          @select="onNodeSelected"
        />

        <SelectTableValue
          v-if="schemaType === 'Tabular'"
          :data="tableData"
          :selectedNode="selectedNode"
          :propertyType="propertyType"
          @select="onNodeSelected"
          @back="$router.back()"
        />
      </div>
      <UiBlocker :visible="loading || creating">
        {{ creating ? "Creating Binding" : "Loading Data" }}
      </UiBlocker>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ConnectionDataResponse,
  DataBinding,
  DataConnection,
  DataType,
  NodeData,
  NodeSetData,
  SchemaNode,
  SchemaType,
  ManualDataRow,
  DataBindingProperty,
} from "@/types/data";
import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
// import Modal from "@/components/Modal.vue";
import FormButton from "@/components/FormButton.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import { Widget } from "@/components/widgets/Widget";
import { APP_EDITOR_ROUTE_PATH } from "@/constants";
import { getQueryValue, removeReactivity } from "@/utils";
import { useConnectionSetupStore } from "@/stores/connectionSetup";
import TabInput from "@/components/inputs/TabInput.vue";

import SelectTreeValue from "@/components/data/connections/setup/selection/SelectTreeValue.vue";
import SelectTableValue from "@/components/data/connections/setup/selection/SelectTableValue.vue";
import DataNodeDisplay from "@/components/data/DataNodeDisplay.vue";
import ScalarNodeDisplay from "@/components/data/connections/manage/data/views/ScalarNodeDisplay.vue";
import { api } from "@/api/backend";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { useAppEditorStore } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

const findById = (arr: any[], id: string, nestingKey = ""): any => {
  // if empty array then return
  if (arr.length == 0) return;

  // return element if found else collect all children(or other nestedKey) array and run this function
  return (
    arr.find((d) => d.uuid == id) ||
    findById(
      arr.flatMap((d) => d[nestingKey] || []),
      id,
      nestingKey
    ) ||
    "Not found"
  );
};

/**
 * Only accessed from App Editor.
 *
 * Will allow user to choose a single node,
 * EITHER from a grid view, OR a tree view,
 * depending on the structure of the data.
 *
 * NOTE: This can either be accessed in a modal on its own (when user selects existing connection)
 * OR as a step in the connection setup/creation process (when user creates new connection).
 *
 * TODO: Would be nice to have sticky top for grid header/schema row.
 *
 * TODO: type return type of fetchConnData
 */

@Component({
  components: {
    ButtonGradient,
    UiBlocker,
    TabInput,
    FormButton,
    SelectTreeValue,
    SelectTableValue,
    DataNodeDisplay,
    ScalarNodeDisplay,
  },
})
export default class ScalarSelect extends Vue {
  // @Prop(String) connectionUuid: string;
  @Prop() dataBinding: DataBinding | null;

  // NOTE: Rather than passing this in as prop via router, we could instead check route.path for presence of "/new"
  @Prop({ default: false }) creatingConnection: boolean;

  loading = false;
  creating = false;
  connection: DataConnection | null = null;
  schema: SchemaNode[] = [];
  rows: ManualDataRow[] = [];

  // TODO: refactor to use selectedNode
  selectedQuery: any = null; // For TreeNode

  showErrorMessage = false;

  selectedNode: Partial<NodeData> = {};

  ////////////////////////////////////////////////////////////////////////////

  schemaType: SchemaType | null = null;
  treeData: NodeSetData | null = null;
  tableData: NodeData[][] | null = null;

  get appData() {
    return useAppDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get selectedWidget() {
    // Technically selectedWidget could be undefined
    return this.appEditor.selectedWidget as Widget;
  }

  get connectionName() {
    if (this.connection === null || typeof this.connection === "undefined") {
      return "";
    }
    return this.connection?.name;
  }

  get propertyType() {
    return getQueryValue<string>(this.$route.query.type) ?? "";
  }

  get propertyTypeDisplay() {
    return this.propertyType.toLowerCase();
  }

  get hasSelection() {
    return (
      typeof this.selectedNode.uuid === "string" &&
      this.selectedNode.uuid.length > 0
    );
  }

  get nodeDisplayProps() {
    return {
      uuid: this.selectedNode.uuid,
      dataType: this.selectedNode.dataType,
      value: {
        value: this.selectedNode.value,
        formattedValue: this.selectedNode.formattedValue,
      },
    };
  }

  ////////////////////////////////////////////////////////////////////////////

  /**
   * TODO: Turn this into getter?
   */
  isNodeset = false;

  get store() {
    return useConnectionSetupStore();
  }

  get connectionUuid() {
    if (this.$route.params.connectionUuid) {
      return this.$route.params.connectionUuid;
    }
    if (this.store.connectionUuid) {
      return this.store.connectionUuid;
    }
    return getQueryValue<string>(this.$route.query.c);
  }

  // TODO: error handling
  async created() {
    // console.log("create scalar select");
    this.loading = true;

    // must set connection locally
    const connection = await this.connectionEditor.getConnection({
      dataConnectionUuid: this.connectionUuid,
      appUuid: this.$route.params.id,
    });

    this.connection = connection;

    api
      .get<ConnectionDataResponse>(`dataconnection/${connection?.uuid}/data`)
      .then((result) => {
        const dataResponse = result.data;

        if (connection?.isCollection === true) {
          this.schemaType = "Tabular";
        } else if (dataResponse.kind === "NodeObject") {
          this.schemaType = "Tree";
        } else if (dataResponse.kind === "NodeSet") {
          const isTable = (dataResponse as NodeSetData).children.some((c) =>
            Array.isArray(c)
          );

          this.schemaType = isTable ? "Tabular" : "Tree";
        }

        if (this.schemaType === "Tabular") {
          this.tableData = (dataResponse as NodeSetData)
            .children as NodeData[][];
        }

        if (this.schemaType === "Tree") {
          // This looks _REALLY_ weird and we should follow up and refactor this.
          // but there's enough functional overlap between NodeSetData and TreeSchemaNode
          // that this should work until we have time to circle back and refactor types.
          this.treeData = dataResponse as NodeSetData;
        }

        if (this.dataBinding) {
          this.selectedNode = {
            query: this.dataBinding.query as string,
            uuid: this.dataBinding.dataUuid,
          };
          this.selectedQuery = this.dataBinding.query; // Needed for tree node -- should refactor that to use selectedNode

          /**
           * Find the selected node from either tableData or treeData
           */

          let node: Partial<NodeData>;

          if (this.schemaType === "Tree") {
            node = findById(
              this.treeData?.children || [],
              this.selectedNode.uuid || "",
              "children"
            );
          } else {
            const flatData = (this.tableData || []).flat();

            node = flatData.find(
              (n) =>
                n.uuid === this.selectedNode.uuid &&
                n.query === this.selectedNode.query
            ) as NodeData;
          }

          this.selectedNode = { ...node, ...this.selectedNode };

          /**
           * See below onNodeSelected for explanation of why we need this for now
           */
          if (typeof node.value === "object") {
            this.selectedNode.value = (node as any).value.value;
            this.selectedNode.formattedValue = (
              node as any
            ).value.formattedValue;
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get showTree() {
    return !this.isNodeset;
  }

  get conditionGroupsStore() {
    return useConditionGroupsStore();
  }

  get activeConditionId() {
    return this.conditionGroupsStore.getActiveConditionId(
      this.selectedWidget.wid
    );
  }

  /**
   * If this is being done in modal (not as part of setup flow), after selecting existing connection, just create binding and close.
   *
   * However, if user accesses an existing binding, confirm should UPDATE the binding rather than create it
   */

  async onCompleteClick() {
    this.showErrorMessage = false;

    this.creating = true;

    try {
      if (this.dataBinding) {
        const db = removeReactivity<DataBinding>(this.dataBinding);
        db.query = this.selectedNode.query;
        db.dataUuid = this.selectedNode.uuid as string;

        this.appEditor.updateDataBinding(db);
      } else {
        await this.appEditor.createDataBinding({
          connection: this.connection as DataConnection,
          widget: this.selectedWidget,
          property: (this.$route.query?.property || "") as DataBindingProperty,
          isScalar: true,
          query: this.selectedNode.query as string,
          dataUuid: this.selectedNode.uuid as string,
          conditionUuid: this.activeConditionId,
        });
      }

      await this.appEditor.updateApp();

      this.$router.push(`/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}`);
    } catch (e) {
      this.showErrorMessage = true;
    } finally {
      this.creating = false;
    }
  }

  // - [ ] TODO: refine this
  // For instance, add support for booleans. Prob datetimes. and colors!
  // Figure out how to share this logic with TreeNode.vue

  // Used for grid when showing a Collection or non-Tree Document
  canSelect(node: NodeData) {
    switch (this.$route.query.type as DataType) {
      case "Number":
        return node.dataType === "Number";
      case "Color":
        return node.dataType === "Color";
      case "DateTime":
        return node.dataType?.match(/Date|Time/); // Should match "Date", "Time" or "DateTime"
      case "String":
        return true;

      default:
        return true;
    }
  }

  // For selecting a Node from a Tree-shaped Document
  onNodeSelected(node: NodeData) {
    this.selectedNode = {
      uuid: node.uuid,
      query: node.query,
      dataType: node.dataType,
      groupUuid: node.groupUuid,
      kind: node.kind,
      value: node.value,
      formattedValue: node.formattedValue,
    };

    /**
     * The node returned from SelectTreeValue is actually a TreeSchemaNode and
     * prepresents the `value` as `{ value, formattedValue }`.
     *
     * The node returned from SelectTableValue is a `NodeData` and represents
     * the value as two properties on the `node` itself.
     *
     * To account for this, we'll
     */
    if (typeof node.value === "object") {
      this.selectedNode.value = (node as any).value.value;
      this.selectedNode.formattedValue = (node as any).value.formattedValue;
    }

    this.selectedQuery = node.query; // Crucial to make treenode recursion work
  }
}
</script>
