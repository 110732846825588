<template>
  <div class="w-full h-full">
    <svg class="w-full h-full" :viewBox="viewBox" v-if="url">
      <PhotoDropContainer v-bind="$props" :backgroundImageUrl="urlRender">
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          :rx="radius"
          :ry="radius"
          :fill="rectFill"
        />
      </PhotoDropContainer>
    </svg>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop } from "vue-property-decorator";
import { makeId } from "@/utils";
import { AppMode } from "@/types";
import { PLACEHOLDER_IMAGE_PROPERTY } from "@/constants";
import PhotoDropContainer from "@/components/widgets/PhotoDropContainer.vue";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";
import { getImageQueryParams } from "@/utils";

// NOTE: Issue with certain ".svg" images -- update their viewbox somehow

@Component({
  components: {
    PhotoDropContainer,
  },
})
export default class ImageComponent extends AnimatableWidget {
  @Inject("appMode") appMode: AppMode;

  @Prop(String) readonly url: string;

  // These props must be declared here so they are bound to $props of PhotoDropContainer
  @Prop(Number) readonly borderRadius: number;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  @Prop(Boolean) [PLACEHOLDER_IMAGE_PROPERTY]: boolean;
  @Prop({ default: "contain" }) readonly backgroundSize: string;
  /**
   * It is important that each instance of this component has its own unique id,
   * for the case of children of repeaters (instances of which share their wid across cells).
   */
  @Prop({ default: makeId }) componentId: string;

  get viewBox() {
    return `0 0 ${this.w * this.scaleX} ${this.h * this.scaleY}`;
  }

  get rectFill() {
    if (this.urlRender) {
      return this.$helpers.svgUrl(`#${this.componentId}backgroundImageUrl`);
    }
    return "";
  }

  get radius() {
    return this.borderRadius ?? 0;
  }

  get hasPlaceholder() {
    return this[PLACEHOLDER_IMAGE_PROPERTY] === true;
  }

  get imageQueryParams() {
    if (this.url != null) {
      return getImageQueryParams(this);
    }
    return "";
  }

  get urlParamSeparator() {
    return this.url.indexOf("?") > -1 ? "&" : "?";
  }

  get fitMode() {
    if (this.backgroundSize === "contain") {
      return "max";
    } else {
      return "crop";
    }
  }

  get urlRender() {
    return this.appMode === "render"
      ? `${this.url}${this.urlParamSeparator}${this.imageQueryParams}`
      : this.url;
  }
}
</script>

<style scoped lang="postcss">
.placeholder-img {
  background-color: #fff;
  box-shadow: inset 0 0 2em rgba(0, 0, 0, 0.06),
    inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
/* 
#svgContainer svg {
  height: 100%;
  width: 100%;
} */
</style>
