<template>
  <div id="data-preview" class="p-8 flex flex-col space-y-8 h-full">
    <div class="flex items-center justify-between">
      <!-- <BreadcrumbNavigation class="text-2xl" :links="breadcrumbs" /> -->

      <div class="text-2xl">{{ connectionName }}</div>

      <div class="flex items-center space-x-4">
        <div
          v-if="showErrorMessage"
          class="text-red-500 text-lg italic select-text"
        >
          {{ $t("Error.generic") }}
        </div>

        <OutlineButton @click="backClickHandler" v-t="'PreviewData.goBack'">
        </OutlineButton>
        <FormButton
          ref="continueButton"
          class="dark-form-focus"
          @click="confirmClickHandler"
          v-t="'PreviewData.continue'"
        >
        </FormButton>
      </div>
    </div>

    <div class="relative flex-grow bg-app-dark1">
      <div class="absolute inset-0 overflow-auto data-grid-container">
        <table class="data-grid text-sm">
          <thead>
            <tr>
              <th v-for="(node, idx) in schema" :key="`column-${idx}`">
                {{ node.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, idx) in editableDataStore.rows"
              :key="`row-${idx}`"
            >
              <td
                v-for="(node, idx) in row.columns"
                :key="`node-${idx}`"
                @mouseover="onCellMouseOver($event, node.dataType)"
                @mouseout="onCellMouseOut($event, node.dataType)"
              >
                <DataNodeDisplay
                  :class="{
                    'block object-contain pointer-events-none h-6 w-full':
                      node.dataType === 'ImageUrl' ||
                      node.dataType === 'ImageUpload',
                  }"
                  :uuid="node.uuid"
                  :dataType="node.dataType"
                  :value="{
                    value: node.value,
                    formattedValue: node.formattedValue,
                  }"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-show="!loading"
        class="z-10 absolute top-0 left-0 right-0 border-b border-black"
      >
        <!-- Table top border. Hides some of the under-scroll content -->
      </div>
      <div
        v-show="!loading"
        class="z-10 absolute top-9 left-0 right-4 border-b border-gray-900"
      >
        <!-- Border under table header row. The real border disappears when scrolled. -->
      </div>
      <div
        v-show="!loading"
        class="z-10 absolute top-0 left-0 bottom-0 border-r border-black"
      >
        <!-- Table left border. Hides some of the under-scroll content -->
      </div>
      <div
        v-show="!loading"
        class="z-10 absolute w-4 h-4 bottom-0 right-0 border-r border-b border-black bg-app-dark1"
      >
        <!-- This is a small square on the bottom right corner. Otherwise a white box shows -->
      </div>
    </div>
    <UiBlocker :visible="loading"> {{ blockerMessage }} </UiBlocker>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { DataBindingProperty, DataConnection, DataType } from "@/types/data";

import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  APP_EDITOR_ROUTE_PATH,
  PLACEHOLDER_CONNECTION_UUID,
} from "@/constants";

import Modal from "@/components/Modal.vue";
import FormButton from "@/components/FormButton.vue";
import OutlineButton from "@/components/OutlineButton.vue";

import UiBlocker from "@/components/UiBlocker.vue";
import BreadcrumbNavigation from "@/components/BreadcrumbNavigation.vue";
import DataNodeDisplay from "@/components/data/DataNodeDisplay.vue";
import { mixins } from "vue-class-component";
import RemapFilterConditionColumnsMixin from "@/components/RemapFilterConditionColumnsMixin";
import { useEditableDataStore } from "@/stores/editableData";
import { useAppEditorStore } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionDataStore } from "@/stores/connectionData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

/**
 * The data shown here will *always* be a Collection.
 * Only way to access this is when user selects existing connection from "ChooseConnection" flow, in app editor.
 * (Note this flow may be accessed via the "Replace Data" tab of the data manager.)
 */

/**
 * - [ ] TODO: If this is in replace view, must grab data from newConnectionUuid, rather than connectionUuid!
 */

@Component({
  components: {
    Modal,
    UiBlocker,
    FormButton,
    BreadcrumbNavigation,
    DataNodeDisplay,
    OutlineButton,
  },
})
export default class PreviewData extends mixins(
  RemapFilterConditionColumnsMixin
) {
  @Prop(String) connectionUuid: string;
  @Prop(String) newConnectionUuid: string;
  @Prop({ type: Boolean, default: false }) isReplacing: boolean;

  loading = false;

  connection: DataConnection | null = null;

  get appData() {
    return useAppDataStore();
  }

  get connectionDataStore() {
    return useConnectionDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  blockerMessage = "Loading Data";
  basePath = `/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}/${this.$route.params.connectionUuid}`;

  get connectionName() {
    return this.connection?.name || "";
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get logicRefsRequiringRemap() {
    return this.connectionEditor.logicRefsRequiringRemap;
  }

  get schema() {
    return this.connectionEditor.schema;
  }

  async loadData() {
    this.loading = true;

    const connectionUuid = this.newConnectionUuid || this.connectionUuid;

    try {
      const connection = await this.connectionEditor.getConnection({
        dataConnectionUuid: connectionUuid,
        appUuid: this.$route.params.id,
        skipSetConnection: true,
      });
      this.connection = connection;

      /**
       * I'm unsure about whether we want to show moderated data, or all data, in preview here.
       * By passing isModerated to refreshConnectionData, we are choosing to show all data.
       * TODO: It would be better to highlight the selected rows somehow, if moderated, and we show all rows.
       */
      const isModerated = connection?.moderationMode !== null;
      const { rows, schema } =
        await this.connectionDataStore.refreshConnectionData({
          connectionId: connectionUuid,
          isModerated,
          updateSchema: true,
        });

      // Fix issue where previous data was being displayed even though current dataset is empty:
      if (rows === null) {
        this.editableDataStore.setRowsInitialState([]);
        this.connectionEditor.schema = schema;
      }
    } catch (e: any) {
      console.log("Error loading preview data", e);
      this.editableDataStore.errorMessage = e[0]?.message;
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.loadData();
  }

  mounted() {
    console.log("Mount preivewdata");
  }

  onCellMouseOver(event: MouseEvent, dataType: DataType) {
    if (dataType === "ImageUrl" || dataType === "ImageUpload") {
      const cell = event.target as HTMLTableCellElement;
      const img = cell.querySelector("img");
      if (img !== null) {
        const clone = img.cloneNode() as HTMLImageElement;
        clone.setAttribute("class", "");
        clone.classList.add("img-clone");
        clone.style.position = "absolute";
        clone.style.pointerEvents = "none";
        cell.appendChild(clone);
      }
    }
  }

  onCellMouseOut(event: MouseEvent, dataType: DataType) {
    if (dataType === "ImageUrl" || dataType === "ImageUpload") {
      const cell = event.target as HTMLTableCellElement;
      const img = cell.querySelector(".img-clone");
      if (img !== null) {
        cell.removeChild(img);
      }
    }
  }

  backClickHandler() {
    const backPath = this.isReplacing
      ? `${this.basePath}/replace`
      : `/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}/new`;

    this.$router.push({
      path: backPath,
      query: { ...this.$route.query, shouldRefresh: "false" },
    });
  }

  /**
   * If user is adding a dataset, close modal and create binding.
   * If user is replacing a graph/calendar dataset, could either close modal or go to data manager data tab (right now, we are closing modal).
   * If user is replacing a repeater dataset, go to "remap columns" view (still within the "Replace" tab).
   * Also must go to "remap columns" view if existing connection has any logic refs (conditions or filters that reference its columns).
   */

  errorSaving = false;

  get showErrorMessage() {
    return this.editableDataStore.errorMessage || this.errorSaving;
  }

  async confirmClickHandler() {
    // Remember to do the add app connection stuff.. like handle >5 error

    this.findAndSetNodesRequiringRemap();
    let bindingWasReplaced = false;
    this.errorSaving = false;

    try {
      if (this.selectedWidget === undefined) {
        const message = "Unable to proceed without a selected widget";
        throw new Error(message);
      }

      /**
       * User only needs to remap columns for Repeaters (or Graphs/Calendars whose bound connection has logic references to it from conditions or filters).
       * When Graphs and Calendars do *not* require this step, we can just update the binding and close the modal.
       */
      if (this.isReplacing) {
        if (this.mustRemapNodes) {
          this.$router.push({
            path: `${this.basePath}/replace/${this.newConnectionUuid}/remap`,
            query: { ...this.$route.query },
          });
          return;
        } else {
          // Widget is a graph (w/o filter) or calendar

          if (
            this.$route.params.connectionUuid !== PLACEHOLDER_CONNECTION_UUID
          ) {
            this.loading = true;
            this.blockerMessage = "Creating binding";

            // A data binding already exists -- replace it.
            await this.appEditor.replaceDataBinding({
              connection: this.connection as DataConnection,
              widget: this.selectedWidget,
            });
            bindingWasReplaced = true;

            // If calendar, must call remap refs endpoint
            if (this.selectedWidget.type.includes("Calendar")) {
              await this.connectionEditor.updateRemapLogicRefs({
                sourceConnectionUuid: this.existingConnection?.uuid || "",
                targetConnectionUuid: this.newConnectionUuid,
                logicUuids: this.logicRefsRequiringRemap,
                appUuid: this.$route.params.id,
              });
            }
          } else {
            // Placeholder data is being replaced.
            // Move on to the "createDataBinding" step below.
          }
        }
      }

      this.loading = true;
      this.blockerMessage = "Creating binding";

      if (!bindingWasReplaced) {
        await this.appEditor.createDataBinding({
          connection: this.connection as DataConnection,
          widget: this.selectedWidget,
          property: (this.$route.query?.property ||
            "data") as DataBindingProperty,
        });
      }

      await this.appEditor.updateApp();

      // Open up the connection in the left-hand Data panel, so user can drag nodes in.
      // Also must open sidebar and set to show Data panel, in case is not.
      if (this.selectedWidget.type === "Repeater") {
        this.appEditor.activeContentMenu = "Data";
        useConnectionDataStore().openNewConnection(
          this.connection as DataConnection
        );
      }

      this.$router.push(`/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}`);
    } catch (e) {
      this.errorSaving = true;
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="postcss">
.img-clone {
  @apply bg-gray-500 shadow-lg ring-2 ring-offset-2 ring-gray-600 h-48;
}
</style>
