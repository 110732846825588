<template>
  <div class="relative top-2">
    <div
      class="absolute left-1/2 transform -translate-x-1/2 text-center bottom-full uppercase tracking-wider text-gray-400 text-xs"
      v-t="'selection'"
    ></div>
    <DataNodeDisplay class="text-xl" v-bind="nodeDisplayProps" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import DataNodeDisplay from "@/components/data/DataNodeDisplay.vue";
import { NodeData } from "@/types/data";

@Component({
  components: {
    DataNodeDisplay,
  },
})
export default class ScalarNodeDisplay extends Vue {
  @Prop() nodeDisplayProps: Partial<NodeData>;
}
</script>
