<template>
  <div class="flex items-center h-16 bg-gray-100">
    <LogoButton tooltip="mainMenu" />
    <div class="absolute border-b border-gray-300 left-20 right-0 top-16">
      <!-- Bottom Border for Header -->
    </div>
    <div class="flex ml-2">
      <ToolTip :text="$t('appSettings').toString()">
        <button
          type="button"
          @click="openAppSettingsModal"
          class="whitespace-nowrap flex items-center px-2 py-1 rounded-md space-x-2 hover:bg-gray-500/10"
        >
          <IconSolid class="w-5 h-5 text-gray-500" name="Cog" />
          <span class="font-semibold">{{ name }}</span>
        </button>
      </ToolTip>

      <div
        style="margin-left: 30px"
        class="flex items-center"
        v-if="isTemplate"
      >
        <router-link
          :to="{ path: `/templates/${uuid}/detail` }"
          class="h-5 w-5 cursor-pointer"
        >
          <Icon class="w-full h-full" name="PencilAlt" />
        </router-link>
      </div>
    </div>

    <div class="flex flex-grow items-center justify-center">
      <ZoomControls class="mr-4" />
      <PreviewLink v-if="isLoaded" :url="previewLink" />
      <ButtonIcon
        class="w-12 h-8 cursor-pointer rounded-md"
        hover
        borders
        icon="Undo"
        tooltip="undo"
        @click="undo"
        :disabled="!canUndo"
        :preventFocusOnClick="true"
      />
      <ButtonIcon
        class="w-12 h-8 cursor-pointer rounded-md"
        hover
        borders
        icon="Redo"
        tooltip="redo"
        @click="redo"
        :disabled="!canRedo"
        :preventFocusOnClick="true"
      />
    </div>
    <div
      class="italic text-gray-500 mr-2 max-h-full whitespace-normal"
      :class="{ 'text-red-500': errorSaving }"
    >
      {{ saveStateText }}
    </div>
    <div class="mr-6 whitespace-nowrap flex flex-row">
      <SaveButton class="ml-3" />
      <PublishButton class="ml-3" />
      <ToolTip
        class="h-8 inline-flex"
        :text="$t('GetApp.disabledTooltip').toString()"
        :disabled="isPublished"
      >
        <GetAppButton :disabled="!isPublished" class="ml-3" />
      </ToolTip>
    </div>

    <portal to="mainEditor">
      <AppSettings v-if="showAppSettingsModal" @close="closeAppSettingsModal" />
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InlineInput from "@/components/InlineInput.vue";
import ToggleInputLabelled from "@/components/inputs/ToggleInputLabelled.vue";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";
import SaveButton from "@/components/SaveButton.vue";
import PublishButton from "@/components/PublishButton.vue";
import GetAppButton from "@/components/GetAppButton.vue";
import LogoButton from "@/components/LogoButton.vue";

import AnchorButton from "@/components/AnchorButton.vue";
import UndoRedo from "@/UndoRedoMixin";
import ZoomControls from "@/components/ZoomControls.vue";
import AppSettings from "@/components/AppSettings.vue";
import PreviewLink from "@/components/PreviewLink.vue";

import { EventBus } from "@/eventbus";
import { KeyCodes } from "@/keycodes";
import ToolTip from "./Tooltip.vue";

import { useAppEditorStore } from "@/stores/appEditor";
import appSettings from "@/appSettings";

@Component({
  components: {
    InlineInput,
    ToggleInputLabelled,
    ButtonIcon,
    SaveButton,
    PublishButton,
    GetAppButton,
    LogoButton,
    AnchorButton,
    Icon,
    IconSolid,
    ZoomControls,
    ToolTip,
    AppSettings,
    PreviewLink,
  },
})
export default class Header extends Mixins(UndoRedo) {
  showAppSettingsModal = false;
  errorSaving = false;
  isSaving = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get uuid() {
    return this.appEditor.uuid;
  }

  get name() {
    return this.appEditor.name;
  }

  get isLoaded() {
    return this.appEditor.isLoaded;
  }

  get feed() {
    return this.appEditor.feed;
  }

  get isTemplate() {
    return this.appEditor.isTemplate;
  }

  get publishMeta() {
    return this.appEditor.publishMeta;
  }

  get textEditingWidget() {
    return this.appEditor.textEditingWidget;
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  closeAppSettingsModal() {
    this.showAppSettingsModal = false;
  }

  mounted() {
    EventBus.on("SAVING_APP", (val) => {
      this.isSaving = val;
    });
    EventBus.on("APP_SAVE_ERROR", (val) => {
      this.errorSaving = val;
    });
    EventBus.on("CLEAR_UNDO_STACK", this.clearStack);
    EventBus.on("FIRE_SAVE", this.appEditor.updateApp);
  }

  async openAppSettingsModal() {
    this.showAppSettingsModal = true;
  }

  get isPublished() {
    return this.publishMeta?.isPublished;
  }

  get saveStateText() {
    if (this.errorSaving) return this.$t("errorSaving");
    return "";
  }

  created() {
    window.addEventListener("keydown", this.onKey);
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.onKey);
    EventBus.off("CLEAR_UNDO_STACK", this.clearStack);
    EventBus.off("FIRE_SAVE", this.appEditor.updateApp);
  }

  onKey(e: any) {
    if (KeyCodes.isUndo(e) && this.canUndo && !this.textEditingWidget) {
      this.undo();
      return;
    }
    if (KeyCodes.isRedo(e) && this.canRedo && !this.textEditingWidget) {
      this.redo();
      return;
    }
  }

  get isLocalEnv() {
    return !appSettings.environment.isProduction;
  }

  get previewLink() {
    const url = new URL(this.feed.previewUrl);
    // Ensure https
    if (url.protocol === "http:") {
      url.protocol = "https:";
    }
    url.searchParams.append("theme", "preview");
    url.searchParams.append("_width", `${this.artboard.w}`);
    url.searchParams.append("_height", `${this.artboard.h}`);

    return url.toString();
  }
}
</script>

<style scoped>
svg:hover {
  color: rgb(152, 20, 212);
}

.hover:hover {
  border-color: rgba(152, 20, 212);
}

.anchor {
  background-color: rgb(152, 20, 212);
}

.warning {
  border: solid 2px red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
