<template>
  <div class="relative flex flex-col w-full h-full">
    <div data-title="header" ref="header">
      <div class="mb-4" :class="`day-${wid}`" :style="getStyles('day')">
        {{ dateTitle }}
      </div>
      <div class="flex">
        <div
          class="pl-1 pr-3"
          :style="hourWidthStyle"
          v-for="(h, index) in hourRange"
          :key="index"
        >
          <TimeDisplay
            class="w-full"
            :class="`hours-${wid}`"
            :style="getStyles('hours')"
            :value="h"
            :options="{ hour: 'numeric', hour12: !use24Hour }"
          />
        </div>
      </div>
    </div>

    <div class="relative flex-grow mt-4" :style="backgroundStyle">
      <div class="absolute inset-0 flex" v-if="showVerticalLines">
        <div
          v-for="(h, index) in hourRange"
          :key="index"
          :style="verticalLineStyle"
        ></div>
      </div>
      <div class="absolute inset-0">
        <div
          v-if="displayNowLine"
          class="absolute top-0 left-0 w-full h-full flex"
        >
          <div class="flex flex-col items-center" :style="nowLineStyle">
            <div :style="nowLineBallStyle" class="w-3 h-3 rounded-3xl"></div>
            <div
              :style="{ background: nowLineColor }"
              style="width: 2px"
              class="h-full"
            ></div>
          </div>
        </div>
      </div>

      <!-- other -->

      <!-- <CalendarEmptyMessage v-if="!hasDataConnection" /> -->

      <div
        data-element="tracks"
        class="absolute inset-0 flex flex-col space-y-4 py-4"
        :class="{ 'opacity-0': !tracksMeasured }"
      >
        <div
          :id="`track-${wid}-${trackIndex}`"
          v-for="(track, trackIndex) in tracks"
          :key="trackIndex"
          :style="trackStyle(trackIndex)"
          class="w-full relative"
        >
          <div
            v-for="(ev, eventIndex) in track"
            :key="eventIndex"
            :style="getEventStyle(ev)"
          >
            <div :style="getEventStartFinStyle(ev)">
              <svg
                class="h-full w-full"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <path :fill="eventBackgroundColor" d="M51,0 L51,100 L0,50 Z" />
              </svg>
            </div>
            <div class="px-4 py-3 leading-none space-y-2">
              <div
                :style="getStyles('eventTitle')"
                class="flex"
                :class="`eventTitle-${wid}`"
              >
                {{ ev.summary }}
              </div>
              <div
                v-if="ev.location && showEventLocation"
                :style="getStyles('eventLocation')"
                class="flex"
                :class="`eventLocation-${wid}`"
              >
                {{ ev.location }}
              </div>
              <div
                class="flex"
                :class="`eventTime-${wid}`"
                :style="getStyles('eventTime')"
                v-if="showEventTime && ev.isAllDay"
              >
                All Day
              </div>
              <TimeRangeDisplay
                class="flex flex-wrap"
                :class="`eventTime-${wid}`"
                v-if="showEventTime && !ev.isAllDay"
                :style="getStyles('eventTime')"
                :start="ev.start"
                :end="ev.end"
                :options="{
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: !use24Hour,
                }"
              />
            </div>

            <div :style="getEventEndFinStyle(ev)">
              <svg
                class="h-full w-full"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <path
                  :fill="eventBackgroundColor"
                  d="M49,0 L100,50 L49,100 Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { DateTime, Interval } from "luxon";
import { getRange, getEventXProportions, clamp } from "@/utils";
import { makeHourTodayDate, createTracks, eventIsOver } from "@/types/calendar";

import { CalendarEvent } from "@/types/calendar";
import { NodeData, NodeSetData } from "@/types/data";
import CalendarEmptyMessage from "@/components/widgets/CalendarEmptyMessage.vue";
import TimeDisplay from "@/components/widgets/TimeDisplay.vue";
import TimeRangeDisplay from "@/components/widgets/TimeRangeDisplay.vue";
import CalendarBase from "@/components/widgets/CalendarBase.vue";
import { EventBus } from "@/eventbus";

/*

[ ] Event "wings" show black if you apply gradient as eventBackgroundColor
(but cool that gradient works from css in svg!)

[x] Question  about dummy data. Should it show that if it has a DC that is empty? Hmm it just works

[x] Do we want to sort by long to short, so that all day events go on top?

[x] Hide now line unless current day

[x?] when you increase size of title, vertlines/hour titles get messed up

So we need to shift cal bg and vertlines

[ ] TODO: clean up allday checks -- IN PARTICULAR, should not say "all day" for multiday events
[ ] todo: "update" for boolean changes bug...
[?] todo: handle defaultStartHour

[?] TODO: move uppercase for todaytext to option

[ ] NOTE going to be trickiness if we allow endHour to be 12 am of the next day...

Starting to feel that we'll need text shrink....even in landscape, for a single-hour event...not a lot of room.
*/

@Component({
  components: { TimeDisplay, TimeRangeDisplay, CalendarEmptyMessage },
})
export default class CalendarDayComponent extends CalendarBase {
  @Prop(Array) data: any[];
  @Prop(Boolean) previewLiveData: boolean;

  @Prop() wid: string;
  // @Prop(Boolean) useDefaultStartTime: boolean;
  @Prop(Number) startHour: number;
  @Prop(Number) endHour: number;
  @Prop(Number) scaleX: number;
  @Prop(Number) scaleY: number;

  @Prop(Boolean) showEventLocation: boolean;
  @Prop(Boolean) showEventTime: boolean;
  @Prop(Boolean) showNowLine: boolean;
  @Prop(Boolean) showVerticalLines: boolean;
  @Prop(Boolean) showBackground: boolean;

  @Prop(String) eventBackgroundColor: string;
  @Prop(String) calendarBackgroundColor: string;
  @Prop(String) verticalLinesColor: string;
  @Prop(String) nowLineColor: string;

  @Prop(Number) dayOffset: number;
  @Prop(String) dayFormat: string;
  @Prop({ default: false }) use24Hour: boolean;

  // ---------------------------------------------------------------------
  @Prop(String) readonly fontFamily: string;
  @Prop(String) readonly textColor: string;

  @Prop(String) readonly hours_fontFamily: string | null;
  @Prop(Number) readonly hours_fontWeight: number;
  @Prop(String) readonly hours_textColor: string | null;
  @Prop(Number) readonly hours_fontSize: number;
  @Prop(Number) readonly hours_letterSpacing: number;
  @Prop(String) readonly hours_textTransform: string | null;
  @Prop(String) readonly hours_textDecoration: string | null;
  @Prop(String) readonly hours_fontStyle: string | null;
  @Prop(Number) readonly hours_lineHeight: number;
  @Prop(String) readonly hours_textAlign: string | null;

  @Prop(String) readonly day_fontFamily: string | null;
  @Prop(Number) readonly day_fontWeight: number;
  @Prop(String) readonly day_textColor: string | null;
  @Prop(Number) readonly day_fontSize: number;
  @Prop(Number) readonly day_letterSpacing: number;
  @Prop(String) readonly day_textTransform: string | null;
  @Prop(String) readonly day_textDecoration: string | null;
  @Prop(String) readonly day_fontStyle: string | null;
  @Prop(Number) readonly day_lineHeight: number;
  @Prop(String) readonly day_textAlign: string | null;

  @Prop(String) readonly eventTitle_fontFamily: string | null;
  @Prop(Number) readonly eventTitle_fontWeight: number;
  @Prop(String) readonly eventTitle_textColor: string | null;
  @Prop(Number) readonly eventTitle_fontSize: number;
  @Prop(Number) readonly eventTitle_letterSpacing: number;
  @Prop(String) readonly eventTitle_textTransform: string | null;
  @Prop(String) readonly eventTitle_textDecoration: string | null;
  @Prop(String) readonly eventTitle_fontStyle: string | null;
  @Prop(Number) readonly eventTitle_lineHeight: number;
  @Prop(String) readonly eventTitle_textAlign: string | null;

  @Prop(String) readonly eventLocation_fontFamily: string | null;
  @Prop(Number) readonly eventLocation_fontWeight: number;
  @Prop(String) readonly eventLocation_textColor: string | null;
  @Prop(Number) readonly eventLocation_fontSize: number;
  @Prop(Number) readonly eventLocation_letterSpacing: number;
  @Prop(String) readonly eventLocation_textTransform: string | null;
  @Prop(String) readonly eventLocation_textDecoration: string | null;
  @Prop(String) readonly eventLocation_fontStyle: string | null;
  @Prop(Number) readonly eventLocation_lineHeight: number;
  @Prop(String) readonly eventLocation_textAlign: string | null;

  @Prop(String) readonly eventTime_fontFamily: string | null;
  @Prop(Number) readonly eventTime_fontWeight: number;
  @Prop(String) readonly eventTime_textColor: string | null;
  @Prop(Number) readonly eventTime_fontSize: number;
  @Prop(Number) readonly eventTime_letterSpacing: number;
  @Prop(String) readonly eventTime_textTransform: string | null;
  @Prop(String) readonly eventTime_textDecoration: string | null;
  @Prop(String) readonly eventTime_fontStyle: string | null;
  @Prop(Number) readonly eventTime_lineHeight: number;
  @Prop(String) readonly eventTime_textAlign: string | null;

  // ---------------------------------------------------------------------
  vm = this as any;
  tracksMeasured = false;
  trackHeights: string[] = [];
  eventPaddingPercent = 0.5;
  hourMarginBottom = 20;
  verticalLineAscent = 10;

  updateFrequencySeconds = 1;
  dummyTime = 0;

  nowXPosition = "";

  hours_init: number;
  day_init: number;
  eventTitle_init: number;
  eventLocation_init: number;
  eventTime_init: number;

  timeChangeTimer: number;

  created() {
    EventBus.on("FONTS_LOADED", this.handleFontsLoaded);
  }

  mounted() {
    this.nowXPosition = this.getNowXPosition();

    this.timeChangeTimer = window.setInterval(() => {
      this.nowXPosition = this.getNowXPosition();
      this.dummyTime++;
    }, this.updateFrequencySeconds * 1000);
  }

  beforeDestroy() {
    EventBus.off("FONTS_LOADED", this.handleFontsLoaded);
    window.clearInterval(this.timeChangeTimer);
  }

  handleFontsLoaded(id: string) {
    if (id === "in-use") {
      this.$nextTick(this.updateTrackHeights);
    }
  }

  get displayNowLine() {
    const now = DateTime.now();
    // const start = makeHourTodayDate(this.startHour);
    // const end = makeHourTodayDate(this.endHour);
    return (
      this.showNowLine &&
      now > this.calendarDate.start &&
      now < this.calendarDate.end
    );
  }

  get backgroundStyle() {
    return {
      background: this.showBackground
        ? this.calendarBackgroundColor
        : "transparent",
    };
  }

  endsAfterCalendarDay(ev: CalendarEvent) {
    return ev.end > this.calendarDate.end;
  }

  startsBeforeCalendarDay(ev: CalendarEvent) {
    return ev.start < this.calendarDate.start;
  }

  getEventFinStyle() {
    return {
      height: "100%",
      width: "5vh",
      position: "absolute",
    };
  }

  getEventStartFinStyle(ev: CalendarEvent) {
    if (!this.startsBeforeCalendarDay(ev)) {
      return { display: "none" };
    }
    return {
      ...this.getEventFinStyle(),
      left: "-2.5vh",
    };
  }

  getEventEndFinStyle(ev: CalendarEvent) {
    if (!this.endsAfterCalendarDay(ev)) {
      return { display: "none" };
    }
    return {
      ...this.getEventFinStyle(),
      right: "-2.5vh",
      top: 0,
    };
  }

  get calendarDate() {
    const d = DateTime.now()
      .plus({ days: this.dayOffset })
      .set({ hour: this.startHour })
      .set({ minute: 0 });
    return Interval.fromDateTimes(
      d,
      d.plus({ hours: this.endHour - this.startHour })
    );
  }

  get events() {
    // const useLiveData =
    //   this.data && this.data.length > 0 && this.previewLiveData;
    // const data = useLiveData ? this.data : dummyCalendarData;

    const data = this.data;

    // Ensure that if event starts at 5:50pm and cal interval ends at 6pm, we don't try to show it
    const start = this.calendarDate.start;
    const end = this.calendarDate.end.minus({ minutes: 15 });
    const interval = Interval.fromDateTimes(start, end);

    return data
      .map((row: (NodeData | NodeSetData)[]) => new CalendarEvent(row))
      .filter((e: CalendarEvent) => interval.overlaps(e.interval))
      .sort(
        (a: CalendarEvent, b: CalendarEvent) =>
          b.interval.length() - a.interval.length()
      );
  }

  updateTrackHeights() {
    if (this.tracks.length === 0) return;
    this.trackHeights = this.tracks.map((track, i) => {
      const id = `track-${this.wid}-${i}`;
      const el = document.getElementById(id) as HTMLDivElement;
      const childrenHeights = Array.from(el.children).map(
        (c) => c.clientHeight
      );
      const max = Math.max.apply(null, childrenHeights);
      return `${max}px`; // add a bit of padding with scale by 1.2;
    });

    this.tracksMeasured = true;
  }

  trackStyle(index: number) {
    if (this.tracksMeasured) {
      return { height: this.trackHeights[index] };
    }
    return {};
  }

  get tracks() {
    return createTracks(this.events);
  }

  get hourWidthStyle() {
    return {
      width: `${100 / this.hourRange.length}%`,
    };
  }

  get verticalLineStyle() {
    return {
      position: "relative",
      top: "-1vh",
      width: `${100 / this.hourRange.length}%`,
      borderLeft: `solid 1px ${this.verticalLinesColor}`,
      height: `calc(100% + 1vh)`,
    };
  }

  get nowLineBallStyle() {
    return {
      background: this.nowLineColor,
    };
  }

  get nowLineStyle() {
    return {
      position: "absolute",
      top: "-1.5vh",
      left: this.nowXPosition,
      width: "1px",
      borderLeft: `solid 1px ${this.verticalLinesColor}`,
      height: `calc(100% + 1.5vh)`,
    };
  }

  getEventStyle(ev: CalendarEvent) {
    const xs = this.getEventXPositions(ev).map((n) => clamp(n, 0, 1));
    const opacity = ev && eventIsOver(ev) ? 0.5 : 1;
    return {
      position: "absolute",
      top: "0",
      left: `${100 * xs[0]}%`,
      right: `${100 * (1 - xs[1])}%`,
      background: this.eventBackgroundColor,
      opacity,
    };
  }

  get hourRange() {
    return getRange(this.startHour, this.endHour - 1).map((h) =>
      DateTime.fromObject({ hour: h, minute: 0 })
    );
  }

  get dayFormatTokens(): any {
    switch (this.dayFormat) {
      case "date-short":
        return { month: "short", day: "numeric" };
      case "date-med":
        return { weekday: "short", month: "short", day: "numeric" };
      case "date-long":
        return { weekday: "long", month: "long", day: "numeric" };
      default:
        return {};
    }
  }

  get dateTitle() {
    if (this.dayFormat === "today") {
      switch (this.dayOffset) {
        case -1:
          return "Yesterday";
        case 0:
          return "Today";
        case 1:
          return "Tomorrow";
        case 2:
          return "In 2 Days";
        case 3:
          return "In 3 Days";
      }
      return "Today";
    }

    return DateTime.now()
      .plus({ days: this.dayOffset })
      .toLocaleString(this.dayFormatTokens);
  }

  getNowXPosition() {
    const d = DateTime.now();

    const startHour = makeHourTodayDate(this.startHour);
    const endHour = makeHourTodayDate(this.endHour);

    const xs = getEventXProportions(
      { start: d, end: d },
      { start: startHour, end: endHour }
    );

    return `${100 * xs[0]}%`;
  }

  getEventXPositions(event: CalendarEvent) {
    // Special case: events that start earlier and end after chosen interval:
    if (
      this.endsAfterCalendarDay(event) &&
      this.startsBeforeCalendarDay(event)
    ) {
      return [0, 1];
    }

    return getEventXProportions(event, {
      start: this.calendarDate.start,
      end: this.calendarDate.end,
    });
  }
}
</script>

<style></style>
