<template>
  <div
    :class="{ 'cursor-pointer': includeModal }"
    class="flex items-start justify-center mt-2"
  >
    <img
      v-if="hasUrl"
      :id="uuid"
      :src="url"
      class="rounded"
      :style="styles"
      @click="showModal = true"
      draggable="true"
      @load="onImageLoaded"
      @dragstart="
        onStartDrag(
          $event,
          {
            url: url,
            width: dimensions.actualWidth,
            height: dimensions.actualHeight,
          },
          true,
          node
        )
      "
    />
    <Modal
      v-if="includeModal && showModal"
      v-on:close="showModal = false"
      size="1/2"
      :class="'cursor-default'"
      :showCloseBtn="true"
    >
      <div class="flex justify-center">
        <img :id="uuid" :src="url" style="objectfit: contain" />
      </div>
      <div class="mt-4 mb-4">
        {{ url }}
      </div>
    </Modal>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import PhotoDragging from "@/components/menus/PhotoDragging.vue";
import { DataValue } from "@/types/data";
import { StyleValue } from "vue/types/jsx";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";

// This component extends PhotoDragging (as does the Photos Menu)
@Component({
  components: {
    Modal,
  },
})
export default class DataNodeImageUrl extends PhotoDragging {
  @Prop({ type: String, required: false }) readonly uuid: string;
  @Prop() node: any;
  @Prop() readonly value: DataValue;
  @Prop({ type: String, default: "auto" }) readonly width: string;
  @Prop({ type: String, default: "100%" }) readonly height: string;
  @Prop({ type: String, default: "contain" }) readonly fit: string;
  @Prop({ type: Boolean, default: false }) includeModal: boolean;
  @Prop() isDragging: boolean;

  showModal = false;

  dimensions = {
    actualWidth: 0,
    actualHeight: 0,
  };

  onImageLoaded() {
    const img = document.getElementById(`#${this.uuid}`) as HTMLImageElement;
    if (!img) return;
    this.dimensions.actualWidth = img.naturalHeight;
    this.dimensions.actualHeight = img.naturalWidth;
  }

  get url() {
    return this.node?.dataType === "ImageUpload"
      ? this.value.formattedValue
      : this.value.value;
  }

  get hasUrl() {
    return isNonEmptyString(this.url);
  }

  get styles(): StyleValue {
    return {
      width: this.width,
      height: this.height,
      objectFit: this.fit as any,
      pointerEvents: this.isDragging ? "none" : "auto",
    };
  }
}
</script>

<style scoped>
#dragged-ghost {
  pointer-events: none;
}
</style>
