<template>
  <div>
    <div class="grid grid-cols-3 gap-4">
      <router-link
        class="source-link"
        :to="{ path: source.path, query: $route.query }"
        :data-testid="'source-link-' + source.name"
        :key="index"
        v-for="(source, index) in sources()"
      >
        <img
          :src="source.iconUrl"
          :alt="source.displayName + ' icon'"
          class="w-10 h-10 flex-none"
        />
        <div class="flex-grow">
          <div class="font-bold" data-testId="source-name">
            {{ source.displayName }}
          </div>
          <div class="text-sm text-gray-400" data-testId="source-description">
            {{ source.displayDescription }}
          </div>
        </div>
      </router-link>
    </div>
    <FatalServerError v-if="shouldShowError"></FatalServerError>
  </div>
</template>

<script setup lang="ts">
import { withDefaults, computed } from "vue";
import FatalServerError from "@/components/FatalServerError.vue";
import { useConnectionSourceStore } from "@/stores/connectionSource";
import { DataType, DataTypes } from "@/types/data";
import { useRoute } from "vue-router/composables";

type SeekingType = "Collection" | DataType | undefined;

const route = useRoute();
const store = useConnectionSourceStore();

const props = withDefaults(
  defineProps<{
    type?: SeekingType;
    isReplacing?: boolean;
    isSeekingCalendar?: boolean;
  }>(),
  {
    isReplacing: false,
    isSeekingCalendar: false,
  }
);

const shouldShowError = computed(() => store.shouldShowError);

function isSeekingScalarType(type: SeekingType) {
  const t = (type ?? "").toLowerCase();
  return DataTypes.map((dt) => dt.toLowerCase()).includes(t);
}

function getAllowedSchemaTypesFor(type: SeekingType) {
  if (isSeekingScalarType(type)) return ["Tree", "Tabular", "Undefined"];

  return [];
}

function sources() {
  const excludeSlugs = props.isReplacing ? ["DataDesigner"] : [];
  const seekingType = props.type;
  const includeSchemaTypes = props.isSeekingCalendar
    ? ["Calendar"]
    : getAllowedSchemaTypesFor(seekingType);

  return store
    .getSources({
      excludeBySlugs: excludeSlugs,
      includeSchemaTypes: includeSchemaTypes,
    })
    .filter((s) => {
      if (isSeekingScalarType(seekingType)) {
        return ["GoogleSheets", "Url", "MicrosoftWorkbook"].includes(s.name);
      }

      return true;
    })
    .map((s) =>
      Object.assign({}, s, {
        path: `${route.path}/${store.getSourceSetupRoute(s)}`,
      })
    );
}
</script>

<style scoped lang="postcss">
.source-link {
  @apply flex items-center space-x-5 px-5 py-3 rounded-md;
  @apply bg-gradient-to-b from-gray-800 to-gray-800 border border-gray-900;
  @apply hover:border-black hover:shadow-lg hover:from-app-dark2 hover:to-gray-800;
}
</style>
