<template>
  <EditorSelectMenu
    :options="options"
    @input="onVariantSelected"
    :value="value"
    :disabled="disabled"
    v-slot:default="props"
  >
    <div :style="props.item.style">
      <span>{{ props.item.label }}</span>
    </div>
  </EditorSelectMenu>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { InputOption } from "@/types/inputs";
import { FontVariant } from "@/types";
import { getGoogleFont } from "@/fonts/searchFonts";
import { normalizeFontVariant } from "@/fonts/fontHelpers";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";

@Component({ components: { EditorSelectMenu } })
export default class FontVariantInput extends Vue {
  @Prop(String) family: string;
  @Prop(Object) value: FontVariant;
  @Prop(Boolean) disabled: boolean;

  loadingVariants = false;
  options: InputOption[] = [];
  selectedVariant: FontVariant | null = null;

  created() {
    this.selectedVariant = this.value ?? null;
  }

  @Watch("value", { immediate: true })
  onValueChanged(v: FontVariant) {
    this.selectedVariant = normalizeFontVariant(v.style, v.weight);
  }

  @Watch("family", { immediate: true })
  onFamilyChanged(family: string) {
    getGoogleFont(family).then((font) => {
      this.options = font.variants.map((v) => {
        const key = `FontVariant.${v.style}${v.weight}`;
        return {
          label: this.$t(key).toString(),
          value: v,
          style: {
            fontWeight: v.weight,
            fontStyle: v.style,
            fontSize: "0.85rem",
            whiteSpace: "nowrap",
          },
        };
      });

      /**
       *
       * If a user is changing font families it's possible they
       * had selected a variant on their previous font that is not
       * available on their new font.
       *
       * For example, suppose they had selected "Inter Light Italic"
       * then are switching to "Lobster", we can't auto select
       * "Lobseter Light Italic" because that variant doesn't exist.
       *
       * We'll try to find the best match in this case.
       */
      if (this.selectedVariant) {
        const currentStyle = this.selectedVariant?.style ?? "normal";
        const currentWeight = this.selectedVariant?.weight ?? 400;

        const variants = font.variants
          .filter((v) => v.style === currentStyle)
          .map((v) => ({
            style: v.style,
            weight: v.weight,
            diff: Math.abs(currentWeight - v.weight),
          }));

        if (variants.length > 0) {
          if (variants.find((w) => w.weight === currentWeight)) {
            return;
          }

          variants.sort((a, b) => a.diff - b.diff);
          const newVariant = {
            style: variants[0].style,
            weight: variants[0].weight,
          };
          // Select the best match
          this.onVariantSelected(newVariant);
        } else {
          // Select the first variant (which should be normal 400)
          this.onVariantSelected(font.variants[0]);
        }
      }
    });
  }

  onVariantSelected(variant: FontVariant) {
    this.selectedVariant = variant;
    this.$emit("input", variant);
  }
}
</script>

<style scoped></style>
