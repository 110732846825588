<template>
  <div class="flex bg-gray-800 relative z-50">
    <div
      class="relative top-0 bottom-0 left-0 z-10 bg-gradient-to-t from-black to-gray-800"
    >
      <div class="absolute bg-gray-700 w-20 h-18 z-0" ref="highlight"></div>

      <div
        class="relative z-10 h-full flex flex-col justify-between"
        :id="sidebarId"
      >
        <div class="flex flex-col">
          <button
            class="flex flex-col justify-center items-center text-white block w-20 h-18 hover:opacity-90 focus:outline-none focus-visible:underline"
            :class="{
              'focus-visible:border focus-visible:border-white':
                item !== activeContentMenu,
              'opacity-50':
                activeContentMenu !== null && item !== activeContentMenu,
              'opacity-80': activeContentMenu === null,
              'opacity-100': activeContentMenu == item,
            }"
            :key="item"
            v-for="item in items"
            :id="'sidebar-' + item"
            @click="select($event, item)"
          >
            <Icon class="w-6 h-6 icon" :name="item" v-if="item !== 'Data'" />
            <LightningBoltIcon v-else :on="true" />
            <div class="mt-px text-xxs font-semibold">
              {{ $t(item.toLowerCase()) }}
            </div>
          </button>

          <a
            target="_blank"
            href="https://www.screenfeed.com/support-categories/using-connect"
            class="flex flex-col justify-center items-center cursor-pointer text-white block w-20 h-20 opacity-80 hover:opacity-90 focus:outline-none focus-visible:underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
              />
            </svg>
            <div class="mt1 text-xxs font-semibold" v-t="'help'"></div>
          </a>
        </div>
        <UserInfo
          layout="stacked"
          class="w-20 px-2 pb-1 text-xxs text-gray-300 overflow-hidden"
          @mouseenter.native="onMouseOverUserInfo"
        />
      </div>
    </div>

    <!-- ExpandPanel -->
    <div
      :class="drawerClasses"
      v-if="displayContentMenu"
      class="h-full w-72 z-0 overflow-hidden text-white border-l border-gray-900 bg-app-dark2"
    >
      <div class="flex justify-end items-center mr-3 mt-3"></div>

      <!-- Removing  "scrollbar" from class for now -- note that this means each menu needs to manage its own sidebar: -->

      <div :id="menuPanelId" class="flex-grow relative h-full flex-col">
        <div class="flex justify-between items-center px-4 mb-4 font-bold">
          <div>{{ activeMenuText }}</div>
          <CloseButton @close="closeContentMenu" />
        </div>

        <component v-if="activeMenuComponent" :is="activeMenuComponent" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import gsap from "gsap";
import { Component, Watch, Vue } from "vue-property-decorator";
import UserInfo from "@/components/UserInfo.vue";

import Icon from "@/components/icons/Icon.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";

import TemplatesMenu from "@/components/menus/TemplatesMenu.vue";
import ShapesMenu from "@/components/menus/ShapesMenu.vue";
import DataMenu from "@/components/menus/DataMenu.vue";
import PhotosMenu from "@/components/menus/PhotosMenu.vue";
import VideosMenu from "@/components/menus/VideosMenu.vue";
import GridsMenu from "@/components/menus/GridsMenu.vue";
import WidgetsMenu from "@/components/menus/WidgetsMenu.vue";
import TextMenu from "@/components/menus/TextMenu.vue";
import BackgroundMenu from "@/components/menus/BackgroundMenu.vue";
import LayersMenu from "@/components/menus/LayersMenu.vue";
import { EventBus } from "@/eventbus";
import OutlineButton from "@/components/OutlineButton.vue";
import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import CloseButton from "@/components/menus/CloseButton.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { ContentMenuName } from "@/types";
import { useUserStore } from "@/stores/user";

@Component({
  components: {
    Icon,
    ButtonIcon,
    TemplatesMenu,
    ShapesMenu,
    DataMenu,
    PhotosMenu,
    VideosMenu,
    TextMenu,
    BackgroundMenu,
    GridsMenu,
    WidgetsMenu,
    LayersMenu,
    OutlineButton,
    LightningBoltIcon,
    CloseButton,
    UserInfo,
  },
})
export default class Sidebar extends Vue {
  duration = 0.2;
  ease = "power2.out";

  sidebarId = "sidebar";
  menuPanelId = "menu-panel";

  get videoEnabled() {
    const userStore = useUserStore();
    return userStore.featureAccessFlags.includes("enable_video_widget");
  }

  @Watch("displayContentMenu")
  canvasSizeChanged() {
    EventBus.emit("CANVAS_BOX_UPDATE");
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get displayContentMenu() {
    return this.appEditor.activeContentMenu !== null;
  }

  mounted() {
    this.$nextTick(() => {
      gsap.set(this.$refs.highlight as gsap.TweenTarget, {
        x: 0,
        y: 0,
        scaleY: 0,
      });
    });
  }

  beforeDestroy() {
    this.closeContentMenu();
  }

  get activeContentMenu() {
    return this.appEditor.activeContentMenu;
  }

  get activeMenuText() {
    if (this.activeContentMenu === "Data") {
      return this.$t("dataPanel.label");
    }
    if (this.activeContentMenu !== null) {
      return this.$t(this.activeContentMenu.toLowerCase());
    }
    return "";
  }

  get activeMenuComponent() {
    if (this.activeContentMenu !== null) {
      return `${this.activeContentMenu}Menu`;
    }
    return null;
  }

  closeContentMenu() {
    this.appEditor.activeContentMenu = null;
  }

  highlight(newY: number) {
    gsap.set(this.$refs.highlight as gsap.TweenTarget, { y: newY });
    gsap.to(this.$refs.highlight as gsap.TweenTarget, {
      scaleY: 1,
      duration: this.duration,
      ease: this.ease,
    });
  }

  unHighlight() {
    gsap.to(this.$refs.highlight as gsap.TweenTarget, {
      scaleY: 0,
      duration: this.duration,
      ease: this.ease,
    });
  }

  moveHighlight(newY: number) {
    gsap.to(this.$refs.highlight as gsap.TweenTarget, {
      y: newY,
      duration: this.duration,
      ease: this.ease,
    });
  }

  select(e: MouseEvent, menu: ContentMenuName) {
    const btn = e.currentTarget as HTMLButtonElement;

    if (this.activeContentMenu === menu) {
      this.unHighlight();
    } else if (this.activeContentMenu === null) {
      this.highlight(btn.offsetTop);
    } else {
      this.moveHighlight(btn.offsetTop);
    }

    this.appEditor.activeContentMenu =
      menu === this.activeContentMenu ? null : menu;
  }

  get drawerClasses() {
    return this.displayContentMenu
      ? ["left-0", "relative"]
      : ["left-full", "absolute"];
  }

  @Watch("activeContentMenu")
  onActiveContentMenuChanged(newValue: string | null) {
    if (newValue === null) {
      this.unHighlight();
    } else {
      const btn = this.$el?.querySelector(
        `#sidebar-${newValue}`
      ) as HTMLElement;
      this.moveHighlight(btn.offsetTop);
    }
  }

  get items() {
    let names: ContentMenuName[] = [
      "Data",
      "Widgets",
      "Text",
      "Photos",
      "Shapes",
      "Layers",
    ];

    if (this.videoEnabled) {
      names.splice(4, 0, "Videos");
    }
    return names;
  }

  onMouseOverUserInfo(e: MouseEvent) {
    const el = e.target as HTMLElement;

    const bbox = el.getBoundingClientRect();
    const clone = el.cloneNode(true) as HTMLElement;
    clone.id = "user-info-clone";
    clone.classList.remove("w-20", "overflow-hidden");
    clone.style.position = "absolute";
    clone.style.top = bbox.top + "px";
    clone.style.left = bbox.left + "px";
    clone.style.zIndex = "1000";
    clone.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    document.body.appendChild(clone);

    const onLeave = () => {
      document.body.removeChild(clone);
      document.removeEventListener("mouseleave", onLeave);
    };
    clone.addEventListener("mouseleave", onLeave);
  }
}
</script>

<style lang="postcss" scoped>
.scrollbar {
  height: calc(100% - theme("spacing.12"));
}
.scrollbar::-webkit-scrollbar {
  @apply w-3;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 2rem rgba(0, 0, 0, 0.1),
    inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-gray-500 border border-gray-700;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.btn:hover {
  fill: green;
}

.icon-fill {
  fill: rgb(120, 120, 120);
}
.icon-fill:hover {
  fill: rgb(170, 170, 170);
}
</style>
