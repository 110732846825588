<template>
  <div>
    <div class="text-xl mb-6">
      <!-- Translation needed translate -->
      <div>Select replacements from the new data collection.</div>
    </div>

    <table class="table" v-if="nonArtificialRows.length > 0">
      <thead>
        <tr>
          <th>Existing Column</th>
          <th></th>
          <th>Replace With</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="index" v-for="(row, index) in nonArtificialRows">
          <td class="p-2 w-96">
            <div
              class="flex w-full items-center rounded-sm space-x-4 px-3 py-1 text-white bg-gray-600 whitespace-nowrap"
            >
              <div v-text="row.columnName"></div>
              <div
                class="text-xs text-gray-200 font-mono bg-gray-700 rounded-sm px-2"
                v-text="row.dataTypeDescription"
              ></div>
            </div>
          </td>
          <td class="p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </td>
          <td class="p-2 w-96">
            <SelectMenu
              class="dark-form-focus text-black"
              :class="{ 'bg-gray-500': row.selectedColumnId === '' }"
              :options="row.options"
              v-model="row.selectedColumnId"
              v-slot:default="props"
            >
              <div class="flex items-center space-x-4">
                <span>{{ props.item.label }}</span>
                <span
                  v-if="props.item.type"
                  class="text-xs font-mono bg-gray-300 rounded-sm px-2"
                  :class="{
                    'text-gray-900':
                      props.item.highlighted && !props.item.disabled,
                  }"
                  >{{ props.item.type }}</span
                >
              </div>
            </SelectMenu>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="!hideButton" class="p-2">
      <FormButton @click="saveBindings" v-t="'continue'"></FormButton>
    </div>

    <UiBlocker :visible="blockUi">
      <span v-text="blockUiMessage"></span>
    </UiBlocker>
  </div>
</template>

<script lang="ts">
import {
  DataBinding,
  DataConnection,
  DataTypes,
  Node,
  UserDefinedDataTypes,
} from "@/types/data";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { InputOption } from "@/types/inputs";

import UiBlocker from "@/components/UiBlocker.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import FormButton from "@/components/FormButton.vue";
import { isNullOrUndefined, removeReactivity } from "@/utils";
import { Widget } from "@/components/widgets/Widget";
import { APP_EDITOR_ROUTE_PATH } from "@/constants";
import { EventBus } from "@/eventbus";
import { useAppEditorStore, BindingReplacement } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { useFilterEditorStore } from "@/stores/filterEditor";

interface RemapRow {
  isArtificial: boolean;
  columnName: string;
  existingBindings: DataBinding[];
  dataTypeDescription: string;
  options: InputOption[];
  selectedColumnId: string | null;
  originalNodeUuid?: string;
}

@Component({
  components: { UiBlocker, SelectMenu, FormButton },
})
export default class RemapColumns extends Vue {
  @Prop(String) connectionUuid: string;
  @Prop(String) newConnectionUuid: string;
  @Prop(Array) newNodes: Node[];
  @Prop(Boolean) hideButton: boolean;

  loadingConnections = false;
  savingBindings = false;

  existingConnection: DataConnection | null = null;
  newConnection: DataConnection | null = null;

  replacementNodes: Node[] = [];
  rows: RemapRow[] = [];

  typeTranslations: Record<string, string> = {};
  updatingRefs = false;

  get appData() {
    return useAppDataStore();
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get nodesRequiringRemap() {
    return this.connectionEditor.nodesRequiringRemap;
  }

  get logicRefsRequiringRemap() {
    return this.connectionEditor.logicRefsRequiringRemap;
  }

  created() {
    DataTypes.forEach((prop) => {
      this.typeTranslations[prop] =
        this.$t(`dataType.${prop.toLowerCase()}`).toString() ?? prop;
    });
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get isLoaded() {
    return this.appEditor.isLoaded;
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get blockUi() {
    return typeof this.blockUiMessage !== "undefined";
  }

  get blockUiMessage() {
    if (!this.isLoaded) {
      return `${this.$t("loadingApp").toString()}...`;
    }
    if (this.loadingConnections) {
      return `${this.$t("loading").toString()}...`;
    }
    if (this.updatingRefs) {
      return "Updating connection references...";
    }
    return undefined;
  }

  get columnBindings() {
    return this.dataBindings.filter(
      (db) =>
        db.dataConnectionUuid === this.connectionUuid &&
        db.bindingType === "DataSetNode" &&
        db.parentWidgetId === this.selectedWidget?.wid
    );
  }

  get connectionIds() {
    return {
      isLoaded: this.isLoaded,
      connectionUuid: this.connectionUuid,
      newConnectionUuid: this.newConnectionUuid,
    };
  }

  get nonArtificialRows() {
    return this.rows.filter((r) => !r.isArtificial);
  }

  @Watch("connectionIds", { immediate: true })
  onConnectionIdsChanged(ids: any) {
    if (
      ids.isLoaded &&
      !isNullOrUndefined(ids.connectionUuid) &&
      !isNullOrUndefined(ids.newConnectionUuid)
    ) {
      try {
        this.loadConnections();
      } catch (e) {
        console.log("Error loading connection", e);
      }
    }
  }

  /**
   * Helper function for constructing a list of selectable options
   * for replacing an existing databound node
   */
  generateReplacementOptions(existingNode: Node) {
    /**
     * For artificial nodes (Display Index, Data Index) we don't need
     * to have the user make any selections for replacement, we can
     * automatically find the corresponding option from the new dataset.
     */
    if (existingNode.isArtificial === true) {
      const matchingNode = this.replacementNodes.find(
        (nn) =>
          nn.isArtificial === true &&
          nn.query === existingNode.query &&
          nn.name === existingNode.name
      );

      if (typeof matchingNode === "undefined") {
        /**
         * If for some reason there isn't a corresponding artificial node
         * in the new dataset, we'll effectively choose "Nothing" for this.
         */
        return [
          {
            label: "Nothing",
            type: "",
            value: "",
            disabled: false,
          },
        ];
      } else {
        return [
          {
            label: matchingNode.name,
            type: matchingNode.name,
            value: matchingNode.uuid as string,
            disabled: false,
          },
        ];
      }
    }

    const options = this.replacementNodes
      .filter(
        (nn) =>
          !nn.isArtificial &&
          nn.isSelected &&
          UserDefinedDataTypes.includes(nn.dataType)
      )
      .map((nn) => {
        return {
          label: nn.name,
          type: this.typeTranslations[nn.dataType],
          value: nn.uuid as string,
          disabled:
            nn.dataType !== existingNode.dataType &&
            !(
              existingNode.dataType === "ImageUpload" &&
              nn.dataType === "ImageUrl"
            ),
          //Change above line to allow ImageUpload > ImageUrl
        };
      });

    options.unshift({
      label: this.$t("RemapColumns.nothing").toString(),
      type: "",
      value: "",
      disabled: false,
    });
    return options;
  }

  async loadConnections() {
    this.loadingConnections = true;

    const loadExisting = this.connectionEditor
      .getConnection({
        dataConnectionUuid: this.connectionUuid,
        appUuid: this.$route.params.id,
      })
      .then((connection) => {
        this.existingConnection = connection;
      });

    const loadNew = this.connectionEditor
      .getConnection({
        dataConnectionUuid: this.newConnectionUuid,
        appUuid: this.$route.params.id,
      })
      .then((connection) => {
        this.newConnection = connection;
      });

    await Promise.all([loadExisting, loadNew]);

    this.loadingConnections = false;

    if (this.existingConnection !== null && this.newConnection !== null) {
      if (Array.isArray(this.newNodes) && this.newNodes.length > 0) {
        this.replacementNodes = this.newNodes?.slice();
      } else {
        this.replacementNodes = this.newConnection.nodeSets[0].nodes ?? [];
      }

      const existingNodes = this.existingConnection.nodeSets[0].nodes ?? [];

      existingNodes.forEach((n) => {
        // Collect all bindings for the current widget that use this node.

        const nodeBindings = this.columnBindings.filter(
          (b) => b.dataUuid === n.uuid
        );

        const mustReplaceNode =
          this.nodesRequiringRemap.map((n) => n.uuid).includes(n.uuid || "") ||
          nodeBindings.length > 0;

        if (mustReplaceNode) {
          const options = this.generateReplacementOptions(n);

          const row: RemapRow = {
            isArtificial: !!n.isArtificial, // artificial rows will be hidden from display
            columnName: n.name,
            existingBindings: nodeBindings,
            dataTypeDescription: this.typeTranslations[n.dataType],
            selectedColumnId: options[0].value,
            options: options,
            originalNodeUuid: n.uuid,
          };

          this.rows.push(row);
        }
      });
    }
  }

  get newParentDataset() {
    return this.newConnection?.nodeSets[0] ?? null;
  }

  replaceChildBindings(): BindingReplacement[] {
    const dataUuid = this.newParentDataset?.uuid;
    const dataNodes = this.replacementNodes;

    // Loop through all the user's replacement bindings
    return this.rows.flatMap((r) => {
      return r.existingBindings.map((db) => {
        // Make a copy of the existing binding.
        const oldBinding = removeReactivity<DataBinding>(db);
        let newBinding: DataBinding | undefined = undefined;

        const newNode = dataNodes.find((dn) => dn.uuid === r.selectedColumnId);
        if (typeof newNode !== "undefined") {
          // Copy the existing binding and set new values
          newBinding = removeReactivity<DataBinding>(db);
          newBinding.dataConnectionUuid = this.newConnectionUuid;
          newBinding.dataUuid = newNode.uuid as string;
          newBinding.dataName = newNode.name;
          newBinding.dataParentUuid = dataUuid;
        }

        return {
          oldBinding: oldBinding,
          newBinding: newBinding,
        };
      });
    });
  }

  replaceParentBinding(): BindingReplacement {
    const dataName = this.newParentDataset?.name;
    const dataUuid = this.newParentDataset?.uuid || "";

    const parentBinding = this.dataBindings.find(
      (db) =>
        db.dataConnectionUuid === this.connectionUuid &&
        db.bindingType === "DataSetParent" &&
        db.widgetId === this.selectedWidget?.wid
    );

    if (typeof parentBinding !== "undefined") {
      const newBinding = removeReactivity<DataBinding>(parentBinding);
      newBinding.dataConnectionUuid = this.newConnectionUuid;
      newBinding.dataName = dataName;
      newBinding.dataUuid = dataUuid;

      return {
        oldBinding: removeReactivity<DataBinding>(parentBinding),
        newBinding: newBinding,
      };
    } else {
      throw new Error("Unable to find existing parent binding to update.");
    }
  }

  gatherReplacements(): BindingReplacement[] {
    const parentBinding = this.replaceParentBinding();
    const childBindings = this.replaceChildBindings();
    return [parentBinding].concat(childBindings);
  }

  get selectedWidgetBinding() {
    return this.dataBindings.find(
      (db) => db.property === "data" && db.widgetId === this.selectedWidget?.wid
    );
  }

  get filterNodesToReplace() {
    return (this.existingConnection?.logicNodeReferences || []).filter(
      (ref) => ref.logicUuid === this.selectedWidgetBinding?.filterUuid
    );
  }

  async saveBindings() {
    // console.log("save bindings...", this.filterNodesToReplace);

    const nodeUuidsRequiredForFilter = this.filterNodesToReplace.map(
      (ref) => ref.nodeUuids[0]
    );

    if (this.nodesRequiringRemap.length > 0) {
      // Ping the backend to alert them of changes to filter/conditions referencing the replaced connection

      this.updatingRefs = true;

      try {
        if (this.selectedWidget?.type !== "Repeater") {
          // Replace data binding
          await this.appEditor.replaceDataBinding({
            widget: this.selectedWidget as Widget,
            connection: this.newConnection as DataConnection,
          });
          await this.appEditor.updateApp();
        }

        const sourceNodeToTargetNode: Record<string, string> = {};
        this.rows.forEach((row) => {
          sourceNodeToTargetNode[row.originalNodeUuid as string] =
            row.selectedColumnId as string;
        });

        if (
          nodeUuidsRequiredForFilter.some(
            (uuid) => sourceNodeToTargetNode[uuid] === ""
          )
        ) {
          // Must delete filter from binding -- user did not select all replacement nodes needed to preserve filter.
          const binding = { ...this.selectedWidgetBinding };
          delete binding.filterUuid;
          this.appEditor.updateDataBinding(binding as DataBinding);
          await this.appEditor.updateApp();

          // Remove any filter associated with this data binding
          const filterStore = useFilterEditorStore();
          filterStore.$reset();
        }

        // Update backend to know about logic refs
        await this.connectionEditor.updateRemapLogicRefs({
          sourceConnectionUuid: this.existingConnection?.uuid || "",
          targetConnectionUuid: this.newConnection?.uuid || "",
          logicUuids: this.logicRefsRequiringRemap,
          appUuid: this.$route.params.id,
          sourceNodeToTargetNode,
        });

        // Close modal, if not a Repeater
        // Only need to gather replacements and etc for Repeaters
        if (this.selectedWidget?.type !== "Repeater") {
          this.$router.push(
            `/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}`
          );
          EventBus.emit("AWAITING_SERVER", false);
          return;
        }
      } catch (e) {
        // TODO display error
        console.log("Error...", e);
      } finally {
        this.updatingRefs = false;
      }
    }

    if (this.selectedWidget?.type === "Repeater") {
      this.$emit("complete", {
        replacements: this.gatherReplacements(),
        newConnection: this.newConnection,
      });
    }
  }
}
</script>
