<template>
  <portal to="tokenDropdown">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <!--
    Custom select controls like this require a considerable amount of JS to implement from scratch. We're planning
    to build some low-level libraries to make this easier with popular frameworks like React, Vue, and even Alpine.js
    in the near future, but in the mean time we recommend these reference guides when building your implementation:

    https://www.w3.org/TR/wai-aria-practices/#Listbox
    https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
  -->
    <div
      ref="menu"
      class="absolute top-0 right-0 z-10 cursor-pointer"
      @click.stop
      @mousedown.prevent
      :style="styles"
    >
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="bg-white relative w-full border border-gray-300 rounded-sm shadow-sm pl-2 pr-9 py-1 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-app-teal focus:border-app-teal"
        @click="toggle"
      >
        <span class="block truncate" v-t="'textDataTokenMenu.addData'"></span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <!-- Heroicon name: selector -->
          <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>

      <!--
      Select popover, show/hide based on select state.

      Entering: ""
        From: ""
        To: ""
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50"
        v-if="expanded"
        ref="expanded"
        :style="expandedStyle"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll"
        >
          <li
            :key="$index"
            v-for="(item, $index) in items"
            role="option"
            :class="{
              'text-white bg-app-teal': item.highlighted,
              'text-gray-900': !item.highlighted,
            }"
            class="cursor-default select-none py-2 pl-3 pr-9"
            @click="select(item.value)"
            @mouseenter="highlight($event, $index)"
            @mouseleave="unhighlight"
            @mousedown.prevent
          >
            <!-- <Tooltip :text="item.label" :hideArrow="true"> -->
            <span class="block truncate">
              {{ item.label }}
            </span>

            <div
              v-if="item.highlighted"
              class="absolute top-0 right-0 text-white text-xs"
              :style="{
                top: tooltipTop + 'px',
              }"
              style="transform: translate(100%, 0)"
            >
              <div class="absolute tip-arrow-r border-gray-700"></div>
              <div
                class="absolute bg-gray-700 p-1 tip-offset-r whitespace-nowrap"
              >
                {{ item.label }}
              </div>
            </div>
            <!-- </Tooltip> -->
          </li>
        </ul>
      </div>
    </div>
  </portal>
</template>

<script lang="ts">
import { Point } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

import Tooltip from "@/components/Tooltip.vue";

@Component({
  components: {
    Tooltip,
  },
})
export default class TextDataTokenMenu extends Vue {
  @Prop(String) placeholder: string;
  @Prop(Array) options: any[];
  @Prop(Object) position: Point;

  highlightedIndex = -1;
  expanded = false;
  menuTop = 0;
  expandedHeight = 0;

  tooltipY = 0;

  get items() {
    return this.options.map((item: any, index: number) =>
      Object.assign(item, {
        highlighted: this.highlightedIndex === index,
      })
    );
  }

  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  get tooltipTop() {
    return this.tooltipY - this.position.y;
  }

  highlight(ev: any, value: number) {
    const bbox = ev.target.getBoundingClientRect();
    // console.log(bbox.y, this.position.y);
    this.tooltipY = bbox.y;
    this.highlightedIndex = value;
  }

  unhighlight() {
    this.highlightedIndex = -1;
  }

  close() {
    this.expanded = false;
    document.removeEventListener("click", this.close);
    document.removeEventListener("keydown", this.onKeyDown);
  }

  open() {
    this.expanded = true;
    document.addEventListener("click", this.close);
    document.addEventListener("keydown", this.onKeyDown);
    this.$nextTick(() => {
      this.menuTop = (
        this.$refs.menu as HTMLElement
      )?.getBoundingClientRect().top;

      this.expandedHeight = (
        this.$refs.expanded as HTMLElement
      )?.getBoundingClientRect().height;
    });
  }

  toggle() {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  }

  // - [ ] NOTE: This may not be ideal...if there are many options, we probably want to still open down
  // Probably just add a max

  get expandedStyle() {
    const INPUT_HEIGHT = 40; // May want to compute this
    const MINIMUM_VERTICAL_SPACE = this.expandedHeight + INPUT_HEIGHT;
    // console.log("min", MINIMUM_VERTICAL_SPACE);

    // const MAX_SPACE = 250;
    // const MIN_HEIGHT = Math.min(MAX_SPACE, MINIMUM_VERTICAL_SPACE);

    const windowHeight = window.innerHeight;
    let style: any = {
      // height: "calc(100% - 30px)"
    };

    if (windowHeight - this.menuTop < MINIMUM_VERTICAL_SPACE) {
      // return {
      //   bottom: `${INPUT_HEIGHT}px`
      // };
      style = { ...style, bottom: `${INPUT_HEIGHT}px` };
    }

    return style;
  }

  select(value: string) {
    this.$emit("selected", value);
    this.close();
  }

  onKeyDown(e: KeyboardEvent) {
    e.stopPropagation();
    e.preventDefault();
    if (!this.expanded) {
      return;
    }

    if (e.key === "Escape") {
      this.expanded = false;
    }

    if (
      e.key === "Enter" &&
      this.highlightedIndex > -1 &&
      this.highlightedIndex < this.options.length
    ) {
      const value = this.options[this.highlightedIndex].value;
      this.select(value);
    }

    if (e.key === "ArrowDown") {
      this.highlightedIndex++;
      if (this.highlightedIndex >= this.options.length) {
        this.highlightedIndex = this.options.length - 1;
      }
    }

    if (e.key === "ArrowUp") {
      this.highlightedIndex--;
      if (this.highlightedIndex < 0) {
        this.highlightedIndex = 0;
      }
    }
  }

  get styles(): { [key: string]: any } {
    return {
      position: "absolute",
      left: this.position.x + "px",
      top: this.position.y + "px",
      width: "150px",
      transform: "translate(0, -100%)",
      // overflow: "scroll"
    };
  }
}
</script>

<style scoped>
.tip-arrow-r {
  right: calc(100% - theme("spacing.2"));
  top: calc(theme("spacing.2") / 2);
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.tip-offset-r {
  left: calc(100% + theme("spacing.2"));
}
</style>
