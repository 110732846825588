<template>
  <router-link
    class="xl:w-1/3 md:w-1/2 p-2 pr-3 pb-3"
    :to="{
      path: '/templates/' + item.uuid + '/detail',
    }"
  >
    <div
      class="bg-gray-900 border-gray-900 hover:bg-app-dark1 border-2 p-3 pb-3 rounded-lg cursor-pointer flex flex-col space-y-2"
    >
      <img
        class="object-cover preview-shadow rounded self-center"
        :style="{ 'aspect-ratio': aspectRatio, width: imageWidth }"
        v-if="item.previewUrl"
        :src="item.previewUrl"
        :alt="item.width + 'x' + item.height"
      />
      <div
        v-if="!item.previewUrl"
        class="justify-center flex bg-gray-500 preview-shadow rounded self-center"
        :style="{ 'aspect-ratio': aspectRatio, width: imageWidth }"
      >
        <p class="my-auto text-sm">Generating Preview</p>
      </div>
      <div class="flex w-full space-between items-center">
        <div class="flex flex-grow items-center">
          <div
            v-if="isTemplateAdmin && item.isPublic"
            class="flex justify-center flex-col mr-1"
          >
            <ToolTip :text="$t('templates.templatePublic')" :hideArrow="true">
              <Icon name="UserGroup" class="w-6 h-6 text-app-teal"></Icon>
            </ToolTip>
          </div>
          <h2
            class="w-full text-[16px] font-semibold truncate text-white title-font"
          >
            {{ item.name }}
          </h2>
        </div>
        <div
          @click.stop.prevent="handleArrowClick"
          class="opacity-70 hover:opacity-100 text-white w-[14px] h-[14px]"
        >
          <Icon
            name="ChevronRight"
            style="transform: rotate(90deg) scaleY(1.8) scaleX(1.8)"
          />
        </div>
      </div>

      <!-- Dropdown menu: -->
      <portal to="dashboard-2">
        <div
          v-if="isActive"
          :style="dropdownPosition"
          class="absolute rounded-[8px] text-[12px] z-50 flex flex-col bg-gray-900 w-[200px] overflow-hidden"
        >
          <div
            class="flex items-center space-x-[-1px] opacity-75 hover:opacity-100 px-3 py-2 hover:bg-gray-800 w-full flex-grow cursor-pointer"
            @click="viewTemplateDetails"
          >
            <IconButton icon="Eye" class="icon" />
            <div>{{ $t("templates.viewDetails") }}</div>
          </div>

          <div
            v-if="canEdit"
            class="flex items-center space-x-[-1px] opacity-75 hover:opacity-100 px-3 py-2 hover:bg-gray-800 w-full flex-grow cursor-pointer"
            @click="editTemplateDetails"
          >
            <IconButton icon="Pencil" class="icon" />
            <div>{{ $t("templates.editDetails") }}</div>
          </div>

          <div
            class="flex items-center space-x-[-1px] opacity-75 hover:opacity-100 px-3 py-2 hover:bg-gray-800 w-full flex-grow cursor-pointer"
            @click="onCloneTemplateClick"
          >
            <IconButton icon="PlusCircle" class="icon" />
            <div>{{ $t("templates.createAppShort") }}</div>
          </div>
        </div>
      </portal>
    </div>
  </router-link>
</template>

<script lang="ts">
import { AppInfo } from "@/types/data";
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import IconButton from "@/views/Dashboard/IconButton.vue";
import ToolTip from "@/components/Tooltip.vue";
import { useTemplatesStore } from "@/stores/templates";
import { logger } from "@core/logger";
import { EventBus } from "@/eventbus";

@Component({
  components: {
    Icon,
    ToolTip,
    IconButton,
  },
})
export default class TemplateListItem extends Vue {
  @Prop() item: AppInfo;
  @Prop({ default: false }) isTemplateAdmin: boolean;
  @Prop({ default: null }) accountId: number | null;

  @Prop({ default: false }) isPortrait: boolean;

  @Prop(Number) forceUpdateDropdown: number;

  isActive = false;

  viewTemplateDetails() {
    this.$router.push({ path: "/templates/" + this.item.uuid + "/detail" });
  }

  editTemplateDetails() {
    this.$router.push({ path: "/templates/" + this.item.uuid + "/edit" });
  }

  get dropdownPosition() {
    const padding = 3;
    const bbox = this.$el.getBoundingClientRect();
    // If the bottom of the dropdown is too close to the bottom of the window, move it up
    const offset =
      Math.abs(bbox.bottom - window.innerHeight) < 100 ? -150 : -10;
    return {
      top: `${
        bbox.top + bbox.height + padding + this.forceUpdateDropdown + offset
      }px`,
      left: `${bbox.right - 210}px`,
      filter: `drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25)`,
    };
  }

  handleArrowClick() {
    if (!this.isActive) {
      EventBus.emit("TURN_OFF_DROPDOWN");
    }
    this.isActive = !this.isActive;
  }

  turnOffDropdown() {
    this.isActive = false;
  }

  mounted() {
    EventBus.on("TURN_OFF_DROPDOWN", this.turnOffDropdown);
    document.body.addEventListener("click", this.turnOffDropdown);
  }

  get store() {
    return useTemplatesStore();
  }

  get canEdit(): boolean {
    return (
      this.isTemplateAdmin || this.item.createdByAccountId === this.accountId
    );
  }

  async onCloneTemplateClick() {
    try {
      const app = await this.store.clone(this.item.uuid);
      this.$router.push({ path: `/app/edit/${app.uuid}` });
    } catch (err) {
      logger.track(err);
      window.alert(this.$t("unknownServerError"));
    }
  }

  get aspectRatio() {
    if (this.isPortrait) {
      return "9 / 16";
    }
    return "16 / 9";
  }

  get imageWidth() {
    if (this.isPortrait) {
      return "60%";
    }
    return "100%";
  }
}
</script>
<style lang="postcss" scoped>
.preview-shadow {
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3))
    drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.3));
}

.icon {
  @apply w-[14px] h-[14px] mr-2;
}
</style>
