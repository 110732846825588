<template>
  <svg class="w-full h-full" :viewBox="viewBox">
    <PhotoDropContainer
      v-bind="$props"
      backgroundPropertyName="backgroundImageUrl"
    >
      <rect
        x="0"
        y="0"
        width="50%"
        height="100%"
        :rx="radius"
        :ry="radius"
        :fill="rectFill"
      />
    </PhotoDropContainer>
    <PhotoDropContainer
      v-bind="$props"
      backgroundPropertyName="backgroundImageUrl2"
    >
      <rect
        x="50%"
        y="0"
        width="50%"
        height="100%"
        :rx="radius"
        :ry="radius"
        :fill="rectFill2"
      />
    </PhotoDropContainer>
  </svg>
</template>

<script lang="ts">
// import { DraggingPhotoData } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import PhotoDropContainer from "@/components/widgets/PhotoDropContainer.vue";

@Component({
  components: {
    PhotoDropContainer,
  },
})
export default class MultiframeComponent extends Vue {
  @Prop(String) readonly backgroundColor: string;
  @Prop(String) readonly backgroundImageUrl: string;
  @Prop(String) readonly backgroundImageUrl2: string;
  @Prop(String) readonly backgroundSize: string;
  @Prop(Number) readonly borderRadius: number;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  @Prop(String) readonly wid: string;
  @Prop(Number) readonly perspectiveAngle: number;
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;

  get viewBox() {
    return `0 0 ${this.w * this.scaleX} ${this.h * this.scaleY}`;
  }

  get rectFill() {
    if (this.backgroundImageUrl) {
      return this.$helpers.svgUrl(`#${this.wid}backgroundImageUrl`);
    }
    return this.backgroundColor;
  }

  get rectFill2() {
    if (this.backgroundImageUrl2) {
      return this.$helpers.svgUrl(`#${this.wid}backgroundImageUrl2`);
    }
    return this.backgroundColor;
  }

  get radius() {
    if (this.borderRadius > 0) {
      return `${this.borderRadius}`;
    }
    return null;
  }
}
</script>
