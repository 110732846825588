import { FontVariant } from "@/types";

const normalizeWeight = (value: string | number) => {
  if (typeof value === "number") {
    if (value > 900) {
      return 900;
    }
    if (value < 100) {
      return 100;
    }
    return value;
  }
  switch (value) {
    case "regular":
      return 400;
    case "bold":
      return 700;
  }
  return 400;
};

const normalizeStyle = (value: string) => {
  if (value === "italic" || value === "oblique") {
    return "italic";
  }
  return "normal";
};

export const normalizeFontVariant = (
  style: string,
  weight: string | number
) => {
  return {
    weight: normalizeWeight(weight),
    style: normalizeStyle(style),
  } as FontVariant;
};
