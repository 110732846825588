import { TextOptions } from "@/components/widgets/Text/TextOptions";
import { DataBinding, NodeData } from "@/types/data";
import { TextContent, DATA_TOKEN_TYPE } from "@/text";
import { removeReactivity } from "@/utils";

const traverse = (
  node: TextContent,
  data: Record<string, any>,
  func: Function
) => {
  for (let i in node) {
    func.call(null, node[i], data);
    if (node[i] !== null && typeof node[i] == "object") {
      traverse(node[i], data, func);
    }
  }
};

export const bindDynamicTextContent = (
  textWidget: TextOptions,
  dataBindings: DataBinding[],
  record: NodeData[]
): TextContent => {
  if (!textWidget) {
    return { type: "doc", content: [] };
  }

  const process = (node: TextContent, data: Record<string, any>) => {
    if (node && node.type === DATA_TOKEN_TYPE && node.attrs) {
      node.attrs["text"] = data[node.attrs.uuid];
    }
  };

  /**
   * Create a simple key/value Record with token binding data
   */
  const data: Record<string, any> = dataBindings.reduce(
    (result: any, b: DataBinding) => {
      if (b.widgetId === textWidget.wid && b.property.startsWith("content")) {
        const dataNode = record?.find((n) => n.uuid === b.dataUuid);
        result[b.dataUuid] = dataNode?.formattedValue.toString();
      }
      return result;
    },
    {}
  );

  // Make a copy of the content
  const content = removeReactivity<TextContent>(textWidget.content);

  // If we have token data, scan through the content tree
  // and replace datatoken nodes with value;
  if (Object.keys(data).length > 0) {
    traverse(content, data, process);
  }

  // console.log("Bound content", content);

  return content;
};

interface DataTokenValue {
  uuid: string;
  value: any;
}

export const populateDataToken = (
  content: TextContent,
  dataBinding: DataBinding,
  record: NodeData[] | NodeData
): TextContent => {
  // If binding is scalar, we just pass in the node directly, rather than an array of nodes
  const dataNode = Array.isArray(record)
    ? record.find((n) => n.uuid === dataBinding.dataUuid)
    : record;

  const dataValue = dataNode?.formattedValue?.toString();

  const data: DataTokenValue = { uuid: dataBinding.dataUuid, value: dataValue };

  // Make a copy of the content
  const contentCopy = removeReactivity<TextContent>(content);

  const process = (node: TextContent, data: DataTokenValue) => {
    if (
      node &&
      node.type === DATA_TOKEN_TYPE &&
      node.attrs &&
      node.attrs.uuid === data.uuid
    ) {
      node.attrs["text"] = data.value;
    }
  };

  // If we have token data, scan through the content tree
  // and replace datatoken nodes with value;
  if (dataNode) {
    traverse(contentCopy, data, process);
  }

  return contentCopy;
};

/**
 * This replaces all occurences of a dataUuid with a new dataUuid.
 * It is called from the remapCollection action in Vuex.
 * @param content Content of a Text widget
 * @param oldUuid Old dataUuid of a binding (to be replaced)
 * @param newUuid dataUuid of the new, replacement binding
 * @returns TextContent
 */
export const updateTokenBindings = (
  document: TextContent,
  oldUuid: string,
  newUuid: string | undefined
): TextContent => {
  document.content?.forEach((paragraph) => {
    paragraph.content = paragraph.content?.reduce((result, text) => {
      let shouldInclude = true;
      if (text.type === "datatoken" && text.attrs?.uuid === oldUuid) {
        if (typeof newUuid !== "undefined") {
          text.attrs.uuid = newUuid;
        } else {
          shouldInclude = false;
        }
      }
      if (shouldInclude) {
        result.push(text);
      }
      return result;
    }, [] as TextContent[]);
  });
  return document;
};

/**
 * Determines whether TextContent has any content or any data bindings.
 * If neither are present, it is considered "empty" and returns true.
 */
export const isTextContentEmpty = (content: TextContent): boolean => {
  let isEmpty = true;

  content.content?.forEach((paragraph) => {
    paragraph.content?.forEach((text) => {
      if (text.type === "datatoken" && typeof text.attrs?.uuid !== undefined) {
        isEmpty = false;
      } else if (
        text.type === "text" &&
        typeof text.text === "string" &&
        text.text.trim().length > 0
      ) {
        isEmpty = false;
      }
    });
  });

  return isEmpty;
};
