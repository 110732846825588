import { UserManager, WebStorageStateStore, User } from "oidc-client-ts";
import axios from "axios";
import { appSettings } from "../appSettings";

const IMP_USER = "IMP_user";
const IMP_SLUG = "IMP_slug";
const DEL_CA = "DELEGATE_childAcct";

class AuthService {
  private userManager: UserManager;
  private storage: Storage;

  private http = axios.create({
    baseURL: appSettings.backEnd.baseUrlAndPath,
  });

  constructor() {
    const origin = window.location.origin;
    const settings = {
      client_id: "app-builder.client",
      response_type: "code",
      scope: "openid profile app-builder.backend.api",
      authority: appSettings.openId.baseUrl,
      redirect_uri: `${origin}/auth/callback.html`,
      silent_redirect_uri: `${origin}/auth/silent-renew.html`,
      post_logout_redirect_uri: origin,
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: true,
      monitorSession: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    this.userManager = new UserManager(settings);
    this.userManager.clearStaleState();

    this.userManager.events.addSilentRenewError(() => {
      const host = window.location.host;
      if (!host.includes("localhost")) this.login(window.location.href);
    });

    this.userManager.events.addUserSignedOut(() => {
      sessionStorage.removeItem(DEL_CA);
    });

    this.storage = window.localStorage;

    window.addEventListener("storage", async (e) =>
      this.checkDelegateSession(e.key)
    );
  }

  // Used to redirect a open tab that is still pointing to a different child account
  // delegate session. This will redirect to the home page and reload the current child account
  // session.
  private async checkDelegateSession(key: string | null) {
    if (!key || !key.includes("oidc.user")) return;

    const sessionDelegateAcct = sessionStorage.getItem(DEL_CA);
    if (sessionDelegateAcct) {
      const childAcct = await this.getDelgateForAccountId();
      if (childAcct) {
        if (Number(sessionDelegateAcct) !== childAcct) {
          sessionStorage.setItem(DEL_CA, childAcct.toString());
          document.location.href = "/";
        }
      }
    }
  }

  public async delegate() {
    // force a re-fetch/re-signin of the user in order to get any updated claims
    // required for switching between child accounts when delegating
    await this.userManager.removeUser();
  }

  public impersonate(slug: string | (string | null)[]): void {
    if (typeof slug !== "string") return;

    this.storage.removeItem(IMP_USER);
    this.storage.setItem(IMP_SLUG, slug);
  }

  public getUser(): Promise<User | null> {
    const storedUser = this.storage.getItem(IMP_USER);
    if (storedUser) {
      return Promise.resolve(JSON.parse(storedUser));
    }

    return this.userManager.getUser();
  }

  public login(returnUrl: string): Promise<void> {
    return this.userManager.signinRedirect({ state: returnUrl || "/" });
  }

  public logout(): Promise<void> {
    this.storage.removeItem(IMP_SLUG);
    this.storage.removeItem(IMP_USER);
    return this.userManager.signoutRedirect();
  }

  public isImpersonating(): boolean {
    const storedUser = this.storage.getItem(IMP_USER);
    return storedUser ? true : false;
  }

  public async getAccessToken(): Promise<string | null> {
    const storedSlug = this.storage.getItem(IMP_SLUG);
    if (storedSlug) return `imp:${storedSlug}`;

    const user = await this.userManager.getUser();
    return user == null || user.expired || !user.access_token
      ? null
      : user.access_token;
  }

  public async getOrRenewAccessToken(): Promise<string | null> {
    const storedSlug = this.storage.getItem(IMP_SLUG);
    if (storedSlug) return `imp:${storedSlug}`;

    let user = await this.userManager.getUser();
    if (user == null || user.expired || !user.access_token)
      user = await this.userManager.signinSilent();

    return user == null || user.expired || !user.access_token
      ? null
      : user.access_token;
  }

  public async isLoggedIn(): Promise<boolean> {
    let user = null;

    const storedUser = this.storage.getItem(IMP_USER);
    if (storedUser) {
      user = JSON.parse(storedUser);
      return true;
    }

    const storedSlug = this.storage.getItem(IMP_SLUG);
    if (storedSlug) {
      try {
        const resp = await this.http.get("users/impersonated", {
          headers: { Authorization: `Bearer imp:${storedSlug}` },
        });
        const d = resp.data.data;
        user = new User({
          id_token: storedSlug,
          session_state: "",
          access_token: storedSlug,
          refresh_token: storedSlug,
          token_type: "",
          scope: "openid profile app-builder.backend.api",
          expires_at: 0,
          profile: d.profile,
          userState: null,
        });
        this.storage.setItem(IMP_USER, JSON.stringify(user));
        return true;
      } catch (error) {
        this.storage.removeItem(IMP_SLUG);
        return false;
      }
    }

    user = await this.userManager.getUser();

    const delegateForAcctId = await this.getDelgateForAccountId();
    if (delegateForAcctId)
      sessionStorage.setItem(DEL_CA, delegateForAcctId.toString());

    return user != null && !user.expired;
  }

  public async getAccountId(): Promise<number | null> {
    return (
      Number(await this.getCustomClaims<number | null>("accountId")) || null
    );
  }

  private async getDelgateForAccountId(): Promise<number | null> {
    return (
      Number(await this.getCustomClaims<number | null>("childAccountId")) ||
      null
    );
  }

  public async getActiveAccountId(): Promise<number | null> {
    const [childAcctId, acctId] = await Promise.all([
      this.getDelgateForAccountId(),
      this.getAccountId(),
    ]);
    return childAcctId ?? acctId;
  }

  private superAdminId = 1;
  private templateAdminId = 10305;
  public async isAdmin(): Promise<boolean> {
    const accountId = await this.getAccountId();
    return accountId === this.superAdminId;
  }

  public async isTemplateAdmin(): Promise<boolean> {
    const accountId = await this.getAccountId();
    return (
      accountId === this.superAdminId || accountId === this.templateAdminId
    );
  }

  private async getCustomClaims<T>(claimName: string): Promise<T | null> {
    const user = await this.getUser();
    return (
      (user?.profile[
        `https://schema.screenfeed.com/claims/${claimName}`
      ] as T) || null
    );
  }

  public handleCallback() {
    this.userManager
      .signinRedirectCallback()
      .then((user) => {
        location.href = (user.state as string) || "/";
      })
      .catch(() => {
        location.href = "/";
      });
  }

  public handleSilentRenew() {
    this.userManager.signinSilentCallback();
  }
}

export const auth = new AuthService();
