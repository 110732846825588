import { BarGraphPlaceholderData } from "@/components/widgets/BarGraph/BarGraphPlaceholderData";
import { ColumnGraphPlaceholderData } from "@/components/widgets/ColumnGraph/ColumnGraphPlaceholderData";
import { LineGraphPlaceholderData } from "@/components/widgets/LineGraph/LineGraphPlaceholderData";
import { PieGraphPlaceholderData } from "@/components/widgets/PieGraph/PieGraphPlaceholderData";
import { StackedGraphPlaceholderData } from "@/components/widgets/StackedGraph/StackedGraphPlaceholderData";
import {
  CalendarPlaceholderData,
  CalendarEventPlaceholderData,
  CalendarAgendaPlaceholderData,
} from "@/components/widgets/CalendarPlaceholderData";

/**
 * Returns placeholder data for supplied widget type (if available).
 * @param widgetType Type of widget
 * @returns Placeholder data for supplied type, or undefined if there is no fake data for type.
 */
export const getPlaceholderData = (widgetType: string): any => {
  switch (widgetType) {
    case "BarGraph":
      return BarGraphPlaceholderData;
    case "ColumnGraph":
      return ColumnGraphPlaceholderData;
    case "LineGraph":
      return LineGraphPlaceholderData;
    case "PieGraph":
      return PieGraphPlaceholderData;
    case "StackedGraph":
      return StackedGraphPlaceholderData;
    case "CalendarEvent":
      return CalendarEventPlaceholderData();
    case "CalendarAgenda":
    case "CalendarRoomSchedule":
    case "CalendarWeek":
      return CalendarAgendaPlaceholderData();
    case "CalendarDay":
      return CalendarPlaceholderData();
    default:
      return undefined;
  }
};
