<template>
  <!-- Add focus:outline-none? -->
  <button
    :type="type"
    class="relative flex items-center justify-center"
    :class="classes"
    :disabled="disabled"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mousedown="onMouseDown"
    @click="onClick"
  >
    <Icon class="block" :class="iconClasses" :name="icon" />
    <div ref="arrow" class="absolute" :class="arrowClasses"></div>
    <div
      ref="text"
      class="absolute rounded-sm text-xs font-bold text-white px-2 leading-6 whitespace-nowrap"
      style="z-index: 1000"
      :class="contentClasses"
      v-t="tooltip"
    ></div>
  </button>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import Icon from "@/components/icons/Icon.vue";
import TooltipMixin from "@/components/TooltipMixin";

@Component({
  components: { Icon },
})
export default class ButtonIcon extends mixins(TooltipMixin) {
  @Prop(String) icon: string;
  @Prop(Boolean) hover: boolean;
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) active: boolean;
  @Prop(Boolean) borders: boolean;
  @Prop(String) caption: string;
  @Prop(Number) width: number;
  @Prop({ type: String, default: "button" }) type: string;
  @Prop({ type: String, default: "6" }) size: string;
  // Allows us to prevent Undo/redo buttons from gaining focus and making "Backspace key to delete widget" action impossible
  @Prop({ default: false }) preventFocusOnClick: boolean;

  get classes() {
    const result: string[] = [];

    if (this.disabled) {
      result.push("opacity-50");
    }
    if (this.active) {
      result.push("bg-gray-500");
      result.push("bg-opacity-20");
    } else if (this.hover) {
      result.push("hover:bg-gray-500");
      result.push("hover:bg-opacity-20");
    }
    return result;
  }

  get iconClasses() {
    const result = this.iconSizeClasses;
    if (this.hover) {
      result.push("opacity-80");
      result.push("hover:opacity-100");
    }
    return result;
  }

  get iconSizeClasses() {
    switch (this.size) {
      case "3":
        return ["h-3", "w-3"];
      case "4":
        return ["h-4", "w-4"];
      case "5":
        return ["h-5", "w-5"];
      case "6":
        return ["h-6", "w-6"];
      case "7":
        return ["h-7", "w-7"];
      case "8":
        return ["h-8", "w-8"];
      default:
        return ["h-6", "w-6"];
    }
  }

  onClick() {
    this.$emit("click");
  }

  onMouseDown(e: MouseEvent) {
    if (this.preventFocusOnClick) {
      e.preventDefault();
    }
  }
}
</script>

<style scoped>
.tip-arrow-t {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-bottom-width: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-r {
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top: 8px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 8px solid transparent;
}
.tip-arrow-b {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-l {
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-right: 0px solid transparent;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.tip-offset-t {
  bottom: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-r {
  left: calc(100% + theme("spacing.2"));
}
.tip-offset-b {
  top: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-l {
  right: calc(100% + theme("spacing.2"));
}
</style>
