<template>
  <div class="p-3">
    <div class="flex justify-between">
      <div class="flex flex-col items-center">
        <FormLabel v-t="'borderColor'" class="mb-3" />
        <ColorInput
          class="cursor-pointer"
          :value="borderColor"
          @change="apply({ [colorProperty]: $event })"
          @input="preview({ [colorProperty]: $event })"
        />
      </div>

      <div class="w-full ml-4">
        <!-- unsure about center vs start here: -->
        <div class="w-full flex items-center flex-col">
          <FormLabel v-t="'borderWidth'" />
          <NumberSliderInput
            :min="0"
            :max="maxBorderWidth"
            :step="1"
            :value="borderWidth"
            @change="apply({ [widthProperty]: $event })"
            @input="preview({ [widthProperty]: $event })"
            class="w-full"
          />
        </div>
      </div>
    </div>

    <ToggleInput
      :value="borderIsDashed"
      @input="apply({ borderIsDashed: $event })"
      class="py-3"
      ><span class="font-semibold text-sm" v-t="'borderIsDashed'"></span>
    </ToggleInput>

    <div v-if="borderIsDashed">
      <div class="w-full">
        <FormLabel v-t="'borderDashSize'" />
        <NumberSliderInput
          :min="1"
          :max="100"
          :step="1"
          :value="borderDashSize"
          @change="apply({ borderDashSize: $event })"
          @input="preview({ borderDashSize: $event })"
          class="w-full"
        />
      </div>
      <div class="w-full">
        <FormLabel v-t="'borderGapSize'" />
        <NumberSliderInput
          :min="1"
          :max="100"
          :step="1"
          :value="borderGapSize"
          @change="apply({ borderGapSize: $event })"
          @input="preview({ borderGapSize: $event })"
          class="w-full"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import ColorInput from "@/components/inputs/ColorInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    NumberSliderInput,
    ColorInput,
    FormLabel,
    SelectMenu,
    ToggleInput,
  },
})
export default class BorderEditor extends Vue {
  @Prop({ type: String, default: "borderColor" }) colorProperty: string;
  @Prop({ type: String, default: "borderWidth" }) widthProperty: string;
  @Prop({ default: 100 }) maxBorderWidth: number;

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get selections() {
    return this.appEditor.selections;
  }

  // TODO figure out how to share this across components...
  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];
      if (values.length === 1) {
        result = values[0];
      }
    }
    return result as T;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps(this.selections, props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps(this.selections, props, "NO_UNDO");
  }

  get borderColor() {
    return this.getValue(this.colorProperty);
  }

  get borderWidth() {
    return this.getValue(this.widthProperty);
  }

  get borderIsDashed() {
    return this.getValue("borderIsDashed");
  }

  get borderDashSize() {
    return this.getValue("borderDashSize");
  }

  get borderGapSize() {
    return this.getValue("borderGapSize");
  }
}
</script>
