import { logger } from "@core/logger";

/**
 * The limited set of
 */
export type UserPreference =
  | "Conditions.SkipHelpBubbles"
  | "Conditions.SkipIntroHelp";

function get<T = string>(key: UserPreference) {
  const value = window.localStorage.getItem(key);
  if (value !== null) {
    try {
      return JSON.parse(value) as T;
    } catch (e) {
      logger.error(`Error parsing user preference ${key}: ${e}`);
    }
  }
  return null;
}

function set(key: UserPreference, value: unknown) {
  if (typeof value !== "undefined" && value !== null) {
    const valueString = JSON.stringify(value);
    window.localStorage.setItem(key, valueString);
  }
}
export const userPreferences = { get, set };
