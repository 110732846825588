<template>
  <div class="-mt-1 space-y-1">
    <InputLabel v-text="label"></InputLabel>
    <StaticInput
      :inputType="inputType"
      :value="model.value"
      :min="min"
      :max="max"
      :isInvalid="isInvalid"
      @input="handleInput"
    />

    <div
      v-if="isInvalid"
      class="mt-2 inline-block border border-red-800 px-2 rounded bg-red-700/10 text-center text-xs text-red-500"
      v-t="'ConditionsEditor.required'"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { useConditionEditorStore } from "@/stores/conditionEditor";
import { LogicOperand } from "@/types/logic";
import { OperandChangedEvent } from "./OperandChangedEvent";
import { OperandInputValueType } from "./OperandSelectType";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";

import StaticInput from "./StaticInput.vue";
import InputLabel from "./InputLabel.vue";

@Component({ components: { StaticInput, InputLabel } })
export default class SelectStaticValue extends Vue {
  @Prop(String) inputType: OperandInputValueType;
  @Prop(Object) model: LogicOperand;
  @Prop(Number) min: number | undefined;
  @Prop(Number) max: number | undefined;

  isDirty = false;

  handleInput(value: string) {
    const payload: OperandChangedEvent = {
      operand: { value },
    };
    this.isDirty = true;
    this.$emit("input", payload);
  }

  @Watch("inputType")
  onInputTypeChanged(
    newType: OperandInputValueType,
    oldType: OperandInputValueType
  ) {
    /**
     * List types (day of week, or month) will auto-select a value.
     * If the inputType changes, we want to 'reset' the value to blank,
     * but we don't set isDirty here because the user didn't do it themselves.
     */
    const listTypes: OperandInputValueType[] = ["DayOfWeek", "Month"];
    if (listTypes.includes(oldType) && !listTypes.includes(newType)) {
      this.isDirty = false;
      this.$emit("input", "");
    }
  }

  get store() {
    return useConditionEditorStore();
  }

  get isInvalid() {
    return (
      (this.isDirty || this.store.showErrors) &&
      !isNonEmptyString(this.model.value)
    );
  }

  get isListInput() {
    return this.inputType === "DayOfWeek" || this.inputType === "Month";
  }

  get label() {
    return this.isListInput ? "Select a value" : "Enter a value";
  }
}
</script>
