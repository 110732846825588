<template>
  <ScrollView @scroll="handleScroll" class="bg-app-dark1b">
    <template slot="header">
      <div class="flex h-full justify-between w-full items-center">
        <SearchBar
          :placeholder="'Search templates'"
          @onSearchInput="onSearchInput"
        />

        <div class="flex items-center space-x-3 scale-[1.5]">
          <div
            class="border border-gray-100 rounded-xs border-[1.5px] cursor-pointer"
            :class="isPortrait ? 'opacity-60' : ''"
            style="width: 16px; aspect-ratio: 16/9"
            @click="onFormatToggle(false)"
          ></div>
          <div
            class="border border-gray-100 rounded-xs border-[1.5px] cursor-pointer"
            :class="!isPortrait ? 'opacity-60' : ''"
            style="width: 9px; aspect-ratio: 9/16"
            @click="onFormatToggle(true)"
          ></div>

          <div></div>
        </div>
      </div>
    </template>
    <template slot="body">
      <div class="container ml-0 mr-0 pt-8">
        <router-view></router-view>
      </div>
      <div class="container ml-0 mr-0 mx-auto max-w-7x1" v-if="templates">
        <div class="flex flex-wrap">
          <TemplateListItem
            :key="a.uuid"
            v-for="a in templates"
            :item="a"
            :isTemplateAdmin="isTemplateAdmin"
            :accountId="accountId"
            :isPortrait="isPortrait"
            :forceUpdateDropdown="forceUpdateDropdown"
          />
        </div>
      </div>
      <UiBlocker :visible="loadingTemplates"> Loading Templates </UiBlocker>
    </template>
  </ScrollView>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import TemplateListItem from "./ListItem.vue";
import ScrollView from "@/views/Dashboard/ScrollView.vue";
import Icon from "@/components/icons/Icon.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import UiBlocker from "@/components/UiBlocker.vue";

import debounce from "lodash.debounce";
import { auth } from "@/api/authService";
import { useTemplatesStore } from "@/stores/templates";
import { EventBus } from "@/eventbus";
import SearchBar from "@/views/Dashboard/SearchBar.vue";

@Component({
  components: {
    TemplateListItem,
    ScrollView,
    Icon,
    ToggleInput,
    UiBlocker,
    SearchBar,
  },
})
export default class TemplateList extends Vue {
  query = "";
  isPortrait = false;
  isTemplateAdmin = false;
  loadingTemplates = false;
  accountId: number | null = null;

  forceUpdateDropdown = 0;

  get store() {
    return useTemplatesStore();
  }

  get templates() {
    return this.store.templates;
  }

  get category() {
    return this.store.category;
  }

  handleScroll() {
    EventBus.emit("TURN_OFF_DROPDOWN");
    this.forceUpdateDropdown = this.forceUpdateDropdown === 0.1 ? 0 : 0.1;
  }

  async created() {
    this.isTemplateAdmin = await auth.isTemplateAdmin();
    this.accountId = await auth.getActiveAccountId();
    await this.getSearch(this.templates.length === 0);
  }

  //#region Search

  async getSearch(showLoader = false) {
    this.loadingTemplates = showLoader;
    return this.store
      .search(this.category, this.isPortrait, this.query)
      .finally(() => {
        this.loadingTemplates = false;
      });
  }

  async onSearchInputInner(ev: Event) {
    this.query = (ev.target as HTMLInputElement)?.value;
    await this.getSearch(true);
  }

  onSearchInput = debounce(this.onSearchInputInner, 500);

  async onFormatToggle(value: boolean | null) {
    if (value !== null && this.isPortrait === value) return;
    this.isPortrait = value === null ? !this.isPortrait : value;
    await this.getSearch();
  }

  //#endregion

  @Watch("category")
  async onCategoryChanged() {
    await this.getSearch(true);
  }
}
</script>
