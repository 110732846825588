<template>
  <div
    @click="connectionClickHandler()"
    class="flex items-center justify-between cursor-pointer leading-tight rounded-lg hover:bg-gray-700 bg-app-dark3 p-2 w-full transition-all"
  >
    <div class="flex space-x-2 items-center" style="width: 70%">
      <img class="w-6 h-6 flex-none" :src="model.iconUrl" />

      <div class="name">
        {{ model.name }}
      </div>
    </div>

    <div class="text-gray-100">
      <Icon class="w-4 h-4" name="ChevronRight" />
    </div>
  </div>
</template>

<script lang="ts">
import { DataConnection } from "@/types/data";
import { extractErrorMessage } from "@/utils";
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import { useConnectionDataStore } from "@/stores/connectionData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    Icon,
  },
})
export default class ConnectionsList extends Vue {
  @Prop() model: DataConnection;

  get connectionDataStore() {
    return useConnectionDataStore();
  }

  get connectionEditorStore() {
    return useConnectionEditorStore();
  }

  connectionClickHandler() {
    const connection = this.model;
    this.connectionDataStore.errorLoadingData = "";
    this.connectionEditorStore.connection = connection;
    // console.log("click handle", connection);
    this.connectionDataStore.connectionData = null;
    this.connectionDataStore.recordIndex = 0;

    this.connectionDataStore
      .fetchConnectionData({ connectionId: connection.uuid })
      .catch((err) => {
        console.log("Error fetching data", err);
        this.connectionDataStore.errorLoadingData = extractErrorMessage(err);
      });
  }
}
</script>

<style scoped>
.name {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
</style>
