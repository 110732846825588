<template>
  <div class="h-full w-full">
    <div class="absolute inset-0 px-6 py-4 overflow-y-auto">
      <div>
        <div class="text-xl mb-6">
          <!-- Translation Needed Translate -->
          Select the node that contains your collection
        </div>

        <div v-if="showQuerySelection" class="flex space-x-6">
          <TabInput
            :value="selectedQuery"
            :options="[{ label: selectedQueryLabel, value: selectedQuery }]"
            @click.native="onChangeSelection"
          />
          <OutlineButton type="button" @click="onChangeSelection"
            >Change</OutlineButton
          >
        </div>

        <div class="space-y-6" v-show="showSelectionTree">
          <!-- This needs to be store.schema, not store.treeSchema, right? -->
          <TreeNode
            v-show="showSelectionTree"
            :depth="0"
            :node="store.schema"
            :shouldExpand="true"
            :showValue="true"
            :selectedQuery="selectedQuery"
            selectionType="Collection"
            @select="onNodeSelected"
          />

          <portal to="setupWizardNext">
            <FormButton @click="onFinishedSelecting"
              >Done Selecting Node</FormButton
            >
          </portal>
        </div>
      </div>

      <div
        class="border-t border-gray-800 mt-8 mb-2"
        v-if="showColumnDefinition"
      ></div>
      <div v-if="showColumnDefinition">
        <div>Select which values you'd like to use.</div>
        <DefineColumns
          :showQuery="true"
          :parentUuid="selectedUuid"
          :nodes="nodes"
          @complete="onCompleteClick"
          schemaType="Tree"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import TabInput from "@/components/inputs/TabInput.vue";
import TreeNode from "@/components/data/connections/setup/schema/TreeNode.vue";
import DefineColumns from "@/components/data/connections/setup/schema/DefineColumns.vue";
import FormButton from "@/components/FormButton.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import SetupStepConfirm from "@/components/data/connections/setup/SetupStepConfirm.vue";

import pick from "lodash.pick";
import { useConnectionSetupStore } from "@/stores/connectionSetup";
import { DataValue, SchemaNode, TreeSchemaNode } from "@/types/data";
import { ConnectionSourceId } from "@/types/ConnectionSources";

@Component({
  components: {
    TreeNode,
    TabInput,
    FormButton,
    OutlineButton,
    DefineColumns,
    SetupStepConfirm,
  },
})
export default class DefineTreeSchema extends Vue {
  @Prop(String) sourceId: ConnectionSourceId;
  @Prop(String) button: string;

  selectedUuid: string | null = null;
  selectedQuery: string | null = null;
  tree: TreeSchemaNode | undefined | null = null;
  nodes: SchemaNode[] = [];
  dataSetNode: SchemaNode | null = null;

  isNodeSelected = false;

  created() {
    this.tryAutoSelect();
  }

  /**
   * Scans the schema to see how many possible collection nodes there are.
   * If there is only 1, auto select it.
   */
  tryAutoSelect() {
    // TODO: Implement
  }

  get store() {
    return useConnectionSetupStore();
  }

  /**
   * Invoked when DefineColumns is complete
   */
  onCompleteClick(nodes: SchemaNode[]) {
    const cleanedNodes = [this.dataSetNode, ...nodes].map((n) =>
      pick(
        n,
        "dataType",
        "isSelected",
        "kind",
        "name",
        "parentUuid",
        "query",
        "referenceName",
        "uuid"
      )
    );
    this.$emit("complete", cleanedNodes);
  }

  isSelfOrFirstChildAnArray(node: TreeSchemaNode) {
    return (
      node.dataType === "ObjectArray" ||
      (node.children?.length === 1 &&
        node.children[0].dataType === "ObjectArray")
    );
  }

  get showColumnDefinition() {
    return this.isNodeSelected && this.nodes?.length > 0;
  }

  get showQuerySelection() {
    return this.isNodeSelected;
  }

  get showSelectionTree() {
    return !this.isNodeSelected;
  }

  onFinishedSelecting() {
    // console.log("finished");
    if (this.selectedUuid && this.selectedQuery) {
      this.isNodeSelected = true;
    }
  }

  onChangeSelection() {
    this.isNodeSelected = false;
  }

  onNodeSelected(node: TreeSchemaNode) {
    this.selectedQuery = node.query;
    this.selectedUuid = node.uuid;
    this.dataSetNode = node;
    // console.log("select node.....", node);

    if (["ObjectArray", "PrimativeArray"].includes(node.dataType)) {
      this.nodes = this.gatherColumnList(node);
    }
  }

  gatherColumnList(node: TreeSchemaNode) {
    /**
     * This fixes issue #1863, where a user selects a collection from a tree data source,
     * but the first child in the collection is *not* a reliable indicator of the interface of all following children.
     * Example data source: https://scrfddat.blob.core.windows.net/devtest/ObjectCollection.json
     * In that data source, the first two children have 2 properties, but the third child has 3 properties.
     * When generating the schema view for the chosen collection, we want to show all 3 properties.
     * So we have to find the child with the most properties, and use that as our schema-indicator.
     */
    const childWithMostProperties = node.children.slice(0).sort((a, b) => {
      if (!b.children || !a.children) return 0;
      return b.children[0].children?.length - a.children[0].children?.length;
    })[0];
    // console.log("most", childWithMostProperties, node.children);
    const list = this.gatherChildNodes(childWithMostProperties, node.name);
    const rows = this.collectData(node);

    // console.log("list", list, "rows", rows);
    rows.forEach((row) => {
      list.forEach((c, colIndex) => {
        c.sampleValues.push(row.children[colIndex]);
      });
    });

    // Ignore nested collections, and use query if name is blank
    return list
      .filter((n) => !n.dataType.includes("Array"))
      .map((n) => {
        // const queryParts = n.query.split(".");

        let newName = n.query.split(".")[n.query.split(".").length - 1];
        if (n.query.includes("[")) {
          newName = n.query.slice(
            n.query.lastIndexOf(".") + 1,
            n.query.lastIndexOf("[")
          );
        }
        // console.log("query parts....", queryParts);
        return { ...n, name: n.name ?? newName };
      });
  }

  collectData(node: TreeSchemaNode) {
    return node.children.map((c) => {
      return {
        ...c,
        children: this.gatherChildNodes(c).map((n) => n.value),
      };
    });
  }

  gatherChildNodes(node: TreeSchemaNode, name = "") {
    const res: TreeSchemaNode[] = [];
    node.children?.forEach((c) => {
      // Lets us build up nested names like "Current.HiTemp"
      const separator = name?.length > 0 ? "." : "";
      const displayQuery = `${name}${separator}${c.name}`;
      const isSelected = true;
      const sampleValues: DataValue[] = [];

      if (this.isSelfOrFirstChildAnArray(c)) {
        return;
      }

      if (c.dataType === "Object") {
        res.push(...this.gatherChildNodes(c, displayQuery));
        return;
      }
      res.push({ ...c, displayQuery, isSelected, sampleValues });
    });
    return res;
  }

  get selectedQueryLabel() {
    if (typeof this.selectedQuery === "string") {
      if (this.selectedQuery === "$") {
        return this.$t("TreeNode.documentRoot").toString();
      }
    }

    return this.selectedQuery;
  }

  // gatherProps(node, name = '') {
  //   let res = [];
  //   node.children.forEach((c) => {
  //     // Lets us build up nested names like "Current.HiTemp"
  //     const displayName = `${name}${name ? '.' : ''}${c.name}`;
  //     if (this.isArray(c)) return;
  //     if (c.dataType === 'Object') {
  //       // recurse
  //       res.push(...this.gatherProps(c, displayName));
  //       return;
  //     }
  //     res.push({ ...c, displayName });
  //   });
  //   return res;
  // },
}
</script>
