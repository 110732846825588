<template>
  <div></div>
</template>

<script lang="ts">
import { EMPTY_ROW_UUID } from "@/stores/editableData";
import { NodeData } from "@/types/data";
import { Component, Prop, Vue } from "vue-property-decorator";
import { makeCellDomId } from "../helpers";
import { useEditableDataStore } from "@/stores/editableData";

@Component({
  components: {},
})
export default class InputBase extends Vue {
  @Prop(String) rowUuid: string;
  @Prop() node: NodeData;

  get editableDataStore() {
    return useEditableDataStore();
  }

  get inputType() {
    return this.node?.dataType;
  }

  makeKeyId(rowUuid: string, columnUuid: string) {
    return makeCellDomId({ rowUuid, columnUuid });
  }

  get fromEmptyRow() {
    return this.rowUuid === EMPTY_ROW_UUID;
  }

  get value() {
    if (this.fromEmptyRow) {
      return this.editableDataStore.emptyRowValue;
    } else {
      return this.node.value; // TODO: Do we need formatted value??
    }
  }

  handleInput(ev: any) {
    const val = ev.target.value;
    // console.log("Handle input val...", val, this.fromEmptyRow, this.rowUuid);
    if (this.fromEmptyRow) {
      this.editableDataStore.emptyRowValue = val;
    } else {
      this.editableDataStore.setNodeValue({
        rowUuid: this.rowUuid,
        columnUuid: this.node.uuid || "",
        value: val,
      });
    }
  }
}
</script>
