<template>
  <div class="p-3 flex items-center flex-col space-y-2">
    <div
      v-if="calendarName"
      class="flex w-56 justify-start items-start flex-col px-3 pb-2"
    >
      <div class="flex items-center -ml-2 justify-between">
        <LightningBoltIcon :hover="false" :on="true" class="w-7 h-7" />
        <span
          v-t="'LightningBolt.connectedTo'"
          class="uppercase text-xs text-gray-500"
        ></span>
      </div>
      <div class="text-md">{{ calendarName }}</div>

      <div
        v-if="exposeIndex"
        class="w-full flex items-center justify-between px-4 mt-3"
      >
        <FormLabel
          v-t="'calendarEvent.dataIndex'"
          class="whitespace-nowrap mr-2"
          style="margin-bottom: 0"
        />

        <div class="flex items-center">
          <div
            @click="viewPrevious"
            class="w-4 h-4 bg-gray-200 rounded p-1 cursor-pointer"
          >
            <Icon name="ChevronLeft" />
          </div>
          <div class="mx-2">
            {{ dataIndex }} / {{ dataSet ? dataSet.children.length : 0 }}
          </div>
          <div
            @click="viewNext"
            class="w-4 h-4 bg-gray-200 rounded p-1 cursor-pointer"
          >
            <Icon name="ChevronRight" />
          </div>
        </div>
      </div>
    </div>

    <!-- Add or replace data prompt button: -->
    <ButtonGradient
      @click="openDataWizard"
      padding="px-4 w-56 h-10"
      class="text-white"
      customGradient="linear-gradient(-3deg, rgb(255, 93, 58) , rgb(214, 31, 150) 60%,  rgb(155, 42, 220))"
    >
      <div class="flex items-center w-full relative justify-between">
        <div v-t="addOrReplaceDataText" class="whitespace-nowrap text-sm"></div>
        <LightningBoltIconWhite class="w-6 h-6" />
      </div>
    </ButtonGradient>

    <div class="flex flex-col space-y-3 items-start py-3">
      <!-- Manual data editing: -->
      <ButtonGradient
        @click="clickManage"
        class="text-black"
        padding="px-4 py-3 w-56 h-10 border-1 border-app-gray hover:ring-1 hover:ring-app-teal hover:border-none"
        customGradient="transparent"
      >
        <div class="flex items-center w-full relative">
          <div v-t="'LightningBolt.editConnection'"></div>
          <Icon
            name="Table"
            class="absolute right-0 w-6 h-6"
            style="color: #999"
          />
        </div>
      </ButtonGradient>

      <portal to="filterModal">
        <FilterManager v-if="isFilterManagerVisible" />
      </portal>

      <ButtonGradient
        @click="openFilterManager"
        v-if="calendarName"
        padding="px-4 py-3 w-56 h-10 border-1 border-app-gray hover:ring-1 hover:ring-app-teal hover:border-none"
        customGradient="transparent"
        class="text-black"
      >
        <div class="flex items-center w-full relative">
          <div v-t="'filter'"></div>
          <div
            class="ml-3 rounded bg-gray-300 h-5 w-5 text-xs flex justify-center items-center"
            v-if="filterEditorStore.totalSavedRules > 0"
          >
            {{ filterEditorStore.totalSavedRules }}
          </div>
          <Icon name="Filter" class="absolute right-0 w-6 h-6" />
        </div>
      </ButtonGradient>
      <ButtonGradient
        v-if="connection"
        @click="removeConnection"
        padding="px-4 py-3 w-56 h-10 border-1 border-app-gray hover:ring-1 hover:ring-red-500 hover:border-none"
        class="text-black"
        customGradient="transparent"
      >
        <div class="flex items-center w-full relative">
          <div v-t="'LightningBolt.unbind'"></div>
          <UnbindIcon
            class="cursor-pointer h-8 w-8 absolute -right-1"
            :disabled="removingConnection"
          />
        </div>
      </ButtonGradient>
    </div>
  </div>
</template>

<script lang="ts">
import { NodeSetData } from "@/types/data";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import FormLabel from "@/components/FormLabel.vue";
import Icon from "@/components/icons/Icon.vue";
import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import LightningBoltIconWhite from "@/components/icons/LightningBoltIconWhite.vue";
import FilterManager from "@/components/data/connections/filters/FilterManager.vue";

import { ComponentOptions } from "./ComponentOptions";
import ButtonGradient from "@/components/ButtonGradient.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  PLACEHOLDER_CONNECTION_UUID,
} from "@/constants";
import UnbindIcon from "@/components/icons/UnbindIcon.vue";
import { useFilterEditorStore } from "@/stores/filterEditor";
import { useAppEditorStore } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionsStore } from "@/stores/connections";

// Hmm...should index cycler only appear when previewLive?
// If we show it, have to change number of indices that they cycle through

interface DatasetComponent extends ComponentOptions {
  dataIndex: number;
}
/**
 * Because connecting to a google calendar will potentially cause the user
 * to leave the app to go through the Google OAuth process, we will store
 * the seleted widget id in localStorage so we can restore the state of the
 * app when they return from the OAuth flow and can then automatically create
 * the data binding for them when they add a connection.
 */
// const authWidgetLocalStorageKey = "cab.googleCalendar.widgetId";

@Component({
  components: {
    FormLabel,
    Icon,
    ButtonGradient,
    ToggleInput,
    LightningBoltIcon,
    LightningBoltIconWhite,
    UnbindIcon,
    FilterManager,
  },
})
export default class CalendarDataChooser extends Vue {
  @Prop({ default: false }) exposeIndex: boolean;

  removingConnection = false;

  @Watch("selectedWidget", { deep: true })
  selectedWidgetChanged() {
    this.loadFilter();
  }

  created() {
    this.loadFilter();
  }

  loadFilter() {
    if (!this.dataBinding) return;
    this.filterEditorStore.loadFilter(
      this.dataBinding.dataConnectionUuid as string,
      this.dataBinding.filterUuid
    );
  }

  // // @State dataRenderMode: DataRenderMode;

  get isFilterManagerVisible() {
    return this.filterEditorStore.showModal;
  }

  get filterEditorStore() {
    return useFilterEditorStore();
  }

  get appData() {
    return useAppDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget as unknown as DatasetComponent;
  }

  removeConnection() {
    this.appEditor.removeDataBinding(this.dataBinding);
  }

  get dataBinding() {
    // - [x?] TODO: Allow for iCal here....
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.selectedWidget?.wid,
      bindingType: "DataSet",
    });
    return bindings[0];
  }

  get dataSet() {
    if (this.dataBinding && this.selectedWidget !== undefined) {
      const dataUuid = this.dataBinding.dataUuid;
      const dataSet = this.appData.data[this.selectedWidget.wid]?.[dataUuid];
      if (dataSet !== undefined) {
        return dataSet as NodeSetData;
      }
    }
    return {
      children: [],
    };
  }

  get connection() {
    if (this.dataBinding) {
      return useConnectionsStore().connections.find(
        (c) => c.uuid === (this.dataBinding.dataConnectionUuid as string)
      );
    }
    return undefined;
  }

  get calendarName() {
    return this.connection?.name;
  }

  get connectionEditorBasePath() {
    return `${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`;
  }

  get connectionEditorFullPath() {
    return `${this.connectionEditorBasePath}/${
      this.connection?.uuid || PLACEHOLDER_CONNECTION_UUID
    }`;
  }

  get addOrReplaceDataText() {
    return this.dataBinding
      ? this.$t("connectionSourceChooser.replaceDataSource")
      : this.$t("connectionSourceChooser.connectToDataSource");
  }

  openFilterManager() {
    this.filterEditorStore.openEditor(
      this.dataBinding.dataConnectionUuid as string,
      this.dataBinding.filterUuid
    );
  }

  openDataWizard() {
    const path = this.dataBinding
      ? `${this.connectionEditorFullPath}/replace`
      : `${this.connectionEditorBasePath}/new`;

    this.$router.push({
      path,
      query: {
        type: "Calendar",
        property: "data",
        widgetId: this.selectedWidget?.wid,
      },
    });
  }

  clickManage() {
    this.$router.push({
      path: `${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}/${
        this.connection?.uuid || PLACEHOLDER_CONNECTION_UUID
      }`,
      query: {
        type: "Calendar", // NOTE: Must be collection?? Or we need to handle....
        property: "data",
        widgetId: this.selectedWidget?.wid,
      },
    });
  }

  // =================================================
  // Handle dataIndex cycling
  // =================================================

  get dataIndex() {
    if (this.selectedWidget == undefined) {
      return 0;
    }

    const dataIndex = this.selectedWidget.dataIndex;

    // [ ] TODO: simplify
    if (
      dataIndex !== undefined &&
      dataIndex === this.dataSet.children.length - 1
    ) {
      return this.dataSet.children.length;
    }
    return dataIndex
      ? (dataIndex + 1 + this.dataSet.children.length) %
          this.dataSet.children.length
      : 1;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.selectedWidget?.wid], props);
  }

  viewPrevious() {
    const dataIndex = this.selectedWidget.dataIndex;
    if (typeof dataIndex !== "number") return;
    this.apply({
      dataIndex:
        (dataIndex + this.dataSet.children.length - 1) %
        this.dataSet.children.length,
    });
  }

  viewNext() {
    const dataIndex = this.selectedWidget.dataIndex;
    if (typeof dataIndex !== "number") return;
    this.apply({
      dataIndex:
        (dataIndex + this.dataSet.children.length + 1) %
        this.dataSet.children.length,
    });
  }
}
</script>

<style scoped></style>
