import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import {
  CyclingOptions,
  DefaultCyclingOptions,
} from "@/components/widgets/CyclingOptions";
import { Rectangle } from "@/types/shapes";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export type RepeaterFlowValue =
  | "row-rtl"
  | "row-ltr"
  | "column-rtl"
  | "column-ltr"
  | "row"
  | "column";

export type RepeaterSortDirectionValue = "asc" | "desc";

export const DATA_ROW_INDEX = "DATA_ROW_INDEX";

export const createRepeaterDimensions = (
  artboard: Rectangle
): Partial<RepeaterOptions> => {
  const artboardSizeConstraint = 3 * Math.min(artboard.w, artboard.h);
  const widgetMargin = Math.min(artboard.w * 0.025, artboardSizeConstraint);

  const repeaterOptions: Partial<RepeaterOptions> = {
    x: widgetMargin,
    y: widgetMargin,
    w: artboard.w - widgetMargin * 2,
    h: artboard.h - widgetMargin * 2,
  };

  return repeaterOptions;
};

export interface RepeaterChildData {
  [key: string]: {
    [key: string]: any;
  };
}

export interface RepeaterOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions,
    CyclingOptions {
  type: "Repeater";

  /**
   * The number of rows in the repeat grid
   */
  rows: number;

  /**
   * The space between rows in pixels.
   */
  rowGap: number;

  /**
   * The number of columns in the repeat grid
   */
  columns: number;

  /**
   * The space between columns in pixels.
   */
  columnGap: number;

  /**
   * Determines the direction of the flow of data.
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-flow
   */
  flow: RepeaterFlowValue;

  /**
   * If true, any cells that don't have data available will not be rendered.
   */
  hideEmptyCells: boolean;

  /**
   * The cell background color
   */
  cellBackgroundColor: string;

  /**
   * Whether to cycle content
   */
  cycleContent: boolean;

  /**
   * Whether to show the sorting options
   */
  sortData: boolean;
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...DefaultCyclingOptions,
  flow: "row",
  rows: 2,
  rowGap: 20,
  columnGap: 20,
  columns: 3,
  hideEmptyCells: false,
  backgroundColor: "rgba(0,0,0,0)",
  cellBackgroundColor: "rgba(0,0,0,0)",
  borderWidth: 1,
  sortData: false,
  cycleContent: true,
};

const CreateRepeater = (options: Partial<RepeaterOptions>): RepeaterOptions => {
  return Object.assign(
    {},
    defaultOptions,
    {
      wid: makeId(),
      type: "Repeater",
      name: DEFAULT_LAYER_NAMES.Repeater,
    },
    options
  );
};

export default CreateRepeater;
