<template>
  <component ref="provider" :is="componentName" @complete="onComplete" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConnectionSourceId } from "@/types/ConnectionSources";
import {
  SourceDataInfo,
  useConnectionSetupStore,
} from "@/stores/connectionSetup";
import ProvideFileUpload from "@/components/data/connections/setup/provide/FileUpload.vue";
import ProvideClipboard from "@/components/data/connections/setup/provide/Clipboard.vue";
import ProvideUrl from "@/components/data/connections/setup/provide/Url.vue";
import ProvideGoogleSheets from "@/components/data/connections/setup/provide/GoogleSheets.vue";
import ProvideGoogleCalendar from "@/components/data/connections/setup/provide/GoogleCalendar.vue";
import ProvideMicrosoftWorkbook from "@/components/data/connections/setup/provide/MicrosoftSheets.vue";
import ProvideMicrosoftCalendar from "@/components/data/connections/setup/provide/MicrosoftCalendar.vue";
import SetupNavigation from "@/components/data/connections/setup/SetupNavigation.vue";
import { useConnectionSourceStore } from "@/stores/connectionSource";

@Component({
  components: {
    SetupNavigation,
    ProvideFileUpload,
    ProvideClipboard,
    ProvideUrl,
    ProvideGoogleSheets,
    ProvideGoogleCalendar,
    ProvideMicrosoftWorkbook,
    ProvideMicrosoftCalendar,
  },
})
export default class SetupProvide extends Vue {
  @Prop(String) sourceId: ConnectionSourceId;

  store = useConnectionSetupStore();
  sourceStore = useConnectionSourceStore();

  get source() {
    return this.sourceStore.getSourceByName(this.sourceId);
  }

  get componentName() {
    return `Provide${this.source?.name}`;
  }

  created() {
    this.store.step = "Provide";
  }

  onComplete(schemaInfo: SourceDataInfo) {
    // Store schema
    this.store.provideData(schemaInfo).then((routeParams) => {
      const step = routeParams.step.toLowerCase();
      const uuid = routeParams.connectionUuid;

      this.$router.push({
        path: step,
        query: { ...this.$route.query, c: uuid },
      });
    });
  }
}
</script>
