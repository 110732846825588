import Vue from "vue";
import { defineStore } from "pinia";

import {
  Condition,
  ConditionGroup,
  ConditionGroupWidgetRef,
  Logic,
} from "@/types/logic";
import { DEFAULT_CONDITION_ID } from "@/constants";
import { api } from "@/api/backend";
import { useAppEditorStore } from "./appEditor";

/**
 * We will update the Widget interface to embed all condition-scope-able properties under a conditionVersions dictionary.
 *
 * "addWidget" will be updated, so that all new widgets are created in this new shape.
 * And all methods for reading/updating widget properties will be updated to use this new shape.
 *
 * To ensure backwards compatibility, we should loop through all widgets when an app loads, and put them into this new shape, if they are not already in it.
 */

export interface ConditionGroupsState {
  /**
   * Stores all condition groups that are attached to any widget within the app.
   */
  conditionGroups: ConditionGroup[];

  /**
   * Stores record of which Condition is actively being edited, for each widget (keyed by widgetId).
   */
  activeWidgetConditionsMap: Record<string, string>;

  /**
   * Stores most recently "copied" condition group uuid, for pasting
   */
  copiedConditionGroupUuid: string | null;

  /**
   * A flag that indicates whether the frontend is awaiting the backend to refresh widget data under a condition.
   */
  refreshingConditionUuid: string | null;
}

export interface CopiedConditionGroupPayload {
  conditionGroup: ConditionGroup;
  // copyFromToConditionUuids: Record<string, string>;
}

export const useConditionGroupsStore = defineStore("conditionGroups", {
  state: (): ConditionGroupsState => {
    return {
      conditionGroups: [],
      activeWidgetConditionsMap: {},
      copiedConditionGroupUuid: null,
      refreshingConditionUuid: null,
    };
  },

  getters: {
    getActiveConditionId: (state) => (wid: string) =>
      state.activeWidgetConditionsMap[wid] ?? DEFAULT_CONDITION_ID,
  },

  actions: {
    getConditionGroups(appUuid: string) {
      return api.get(`apps/${appUuid}/conditiongroups`).then((data) => {
        this.conditionGroups = data as ConditionGroup[];
      });
    },

    getConditionGroup(payload: {
      appUuid: string;
      conditionGroupUuid: string;
    }) {
      const { appUuid, conditionGroupUuid } = payload;
      return api.get(`apps/${appUuid}/conditiongroups/${conditionGroupUuid}`);
    },

    removeConditionGroup(payload: {
      conditionGroupUuid: string;
      widgetId: string;
      appUuid: string;
    }) {
      const appEditor = useAppEditorStore();
      const { appUuid, widgetId, conditionGroupUuid } = payload;
      return api
        .delete(
          `apps/${appUuid}/conditiongroups/${conditionGroupUuid}?widgetId=${widgetId}`
        )
        .then(() => {
          const conditionUuids = this.conditionGroups
            .find((cg) => cg.uuid === conditionGroupUuid)
            ?.conditions.map((c) => c.uuid);

          // Remove conditional data bindings associated with the widget from which the condition group was removed
          if (conditionUuids !== undefined && conditionUuids.length > 0) {
            appEditor.removeDataBindingsForConditions({
              widgetId,
              conditionUuids,
            });
          }

          // Remove conditions from Widget Conditions array
          if (
            typeof conditionUuids !== "undefined" &&
            conditionUuids?.length > 0
          ) {
            appEditor.removeWidgetConditions({
              widgetIds: [widgetId],
              conditionUuids,
            });
          }
        })
        .then(() => {
          // Ensure "publish" button updates to show "republish"
          appEditor.updateApp();
        });
    },

    async setActiveCondition(payload: {
      conditionGroupUuid: string;
      conditionUuid: string;
    }): Promise<any> {
      const { conditionGroupUuid, conditionUuid } = payload;

      const appEditor = useAppEditorStore();

      /**
       * Update all widgets that share a reference to the selected widget's conditionGroup
       */
      const conditionGroup = this.conditionGroups.find(
        (group) => group.uuid === conditionGroupUuid
      );

      const widgetRefs = conditionGroup?.widgets || [];

      widgetRefs.forEach((widget) => {
        Vue.set(this.activeWidgetConditionsMap, widget.widgetId, conditionUuid);
      });

      /**
       * Reset undo/redo history when user enters new conditional editing context.
       * Must call "clearStack" from the Header, to refer to same stack.
       * Do this regardless of whether there was an error refreshing widget data.
       */
      appEditor.resetUndoRedoState();
    },

    copyConditionGroup(payload: {
      appUuid: string;
      conditionGroupUuid?: string;
      widgetId: string;
      parentWidgetId?: string;
    }): Promise<CopiedConditionGroupPayload> {
      const { appUuid, widgetId, parentWidgetId, conditionGroupUuid } = payload;
      const cgUuid = conditionGroupUuid ?? this.copiedConditionGroupUuid;
      return api.post(`apps/${appUuid}/conditiongroups/${cgUuid}/copy`, {
        widgetId,
        parentWidgetId,
      });
    },

    // Note, there is also updateConditionGroup, for ordering

    getCondition(payload: { appUuid: string; conditionUuid: string }) {
      const { appUuid, conditionUuid } = payload;
      return api.get(`apps/${appUuid}/conditions/${conditionUuid}`);
    },

    createCondition(payload: {
      appUuid: string;
      condition: Logic;
      widgetId: string;
      parentWidgetId?: string;
    }): Promise<Condition> {
      const { appUuid, condition, widgetId, parentWidgetId } = payload;
      const data: any = { condition };
      const widgetRef: ConditionGroupWidgetRef = {
        widgetId,
      };
      if (parentWidgetId) {
        widgetRef.parentRepeaterWidgetId = parentWidgetId;
      }
      data.widgets = [widgetRef];
      console.log("Posting data", data);
      return api.post(`apps/${appUuid}/conditions`, data);
    },

    /**
     * Removes a condition from a condition group.
     * This affects all widgets that reference the condition group.
     * If the condition being deleted is the only condition in the group,
     * the group is deleted as well.
     * @param conditionUuid
     * @param conditionGroupUuid
     * @returns
     */
    async deleteCondition(conditionUuid: string, conditionGroupUuid: string) {
      const appEditor = useAppEditorStore();
      const appUuid = appEditor.uuid;

      const group = this.conditionGroups.find(
        (cg) => cg.uuid === conditionGroupUuid
      );

      if (typeof group === "undefined") {
        return Promise.reject(
          `Condition group ${conditionGroupUuid} not found`
        );
      }

      const condition = group.conditions.find((c) => c.uuid === conditionUuid);
      if (typeof condition === "undefined") {
        return Promise.reject(`Condition ${conditionUuid} not found`);
      }

      // Delete condition on the backend
      await api.delete(`apps/${appUuid}/conditions/${conditionUuid}`);

      // Get all widgetIds associated with this condition
      const widgetIds = this.conditionGroups.reduce((acc, cg) => {
        cg.conditions.forEach((c) => {
          if (c.uuid === conditionUuid) {
            acc.push(...cg.widgets.map((w) => w.widgetId));
          }
        });
        return acc;
      }, [] as string[]);

      // Remove condition from Widget Conditions array
      appEditor.removeWidgetConditions({
        widgetIds: widgetIds,
        conditionUuids: [conditionUuid],
      });

      // Remove data bindings associated with this conditionUuid, across all widgets attached to the condition group
      appEditor.removeDataBindingsForConditions({
        conditionUuids: [conditionUuid],
      });

      // If the condition being deleted is active for any widget,
      // reset that widget's active conditon to default.
      for (const key in this.activeWidgetConditionsMap) {
        if (this.activeWidgetConditionsMap[key] === conditionUuid) {
          console.info("Resetting active condition for widget to default", key);
          this.activeWidgetConditionsMap[key] = DEFAULT_CONDITION_ID;
        }
      }

      // Remove the condition from the condition group
      const index = group.conditions.findIndex((c) => c.uuid === conditionUuid);
      if (index > -1) {
        group.conditions.splice(index, 1);
      }

      // Check if the condition we just deleted was the LAST condition in the group.
      // If so, delete the group.
      if (group.conditions.length === 0) {
        // Delete condition group from local state
        this.conditionGroups = this.conditionGroups.filter(
          (cg) => cg.uuid !== conditionGroupUuid
        );
      }
    },

    updateSortOrder(
      appUuid: string,
      conditionGroupUuid: string,
      orderedIds: string[]
    ) {
      const group = this.conditionGroups.find(
        (cg) => cg.uuid === conditionGroupUuid
      );
      if (typeof group === "undefined") {
        return Promise.resolve();
      }

      const conditionOrder: Record<string, number> = {};
      orderedIds.forEach((uuid, index) => {
        const c = group.conditions.find((c) => c.uuid === uuid);
        if (c) {
          c.order = index;
          conditionOrder[uuid] = index;
        }
      });
      const endpoint = `apps/${appUuid}/conditiongroups/${conditionGroupUuid}`;
      return api.put(endpoint, { conditionOrder }).then(() => {
        const appEditor = useAppEditorStore();
        // Ensure "publish" button updates to show "republish"
        appEditor.updateApp();
      });
    },
  },
});
