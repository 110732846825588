<template>
  <ScrollView
    :zeroPadding="true"
    :isScrolled="scrollY > 0"
    class="bg-app-dark1b pb-12"
  >
    <template slot="header">
      <div class="flex h-full space-x-4 items-center">
        <SearchBar
          :placeholder="$t('connectionList.searchConnections').toString()"
          @onSearchInput="onSearchInput"
          @onSearchKeydown="onSearchKeydown"
          @onSearchEnter="onSearchEnter"
          @blur="selectedIndex = -1"
        />

        <!-- Sorting options: -->
        <div class="h-full flex items-center w-48">
          <SelectMenu
            :label="sortLabel"
            :textColor="'gray-500'"
            :hoverColor="'gray-200'"
            :checkColor="'gray-500'"
            class="text-black w-full h-8 text-sm"
            v-model="sortBy"
            :options="sortOptions"
            :rounded="true"
            :fullWidth="true"
            :dark="true"
          />
        </div>
      </div>
    </template>

    <template slot="body">
      <RecycleScroller
        ref="scroller"
        v-if="filteredConnections.length > 0"
        :items="rows"
        :item-size="rowHeight"
        key-field="id"
        v-slot="{ item: row, index }"
        @visible="onScrollerVisible"
        class="h-full flex-grow"
        listClass="!overflow-visible"
        itemClass="mt-8"
        id="connection-scroller"
        @resize="handleResize"
      >
        <div class="flex justify-start space-x-4 px-4 pl-8">
          <div
            v-for="(dc, idx) in row.arr"
            :key="idx"
            :style="{ width: 'calc(94% /' + itemsPerRow + ')' }"
            class="h-28 group p-4 rounded-[8px] bg-transparent hover:bg-gray-700 border border-gray-400 flex justify-between cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-700 focus:ring-gray-500"
          >
            <router-link
              v-if="dc.isCreateConnectionButton"
              class="flex items-center space-x-2 w-full justify-center text-lg"
              :to="{ path: '/connections/new' }"
            >
              <Icon class="w-7 h-7" name="PlusCircle" :hover="false" />
              <div>{{ $t("dataImporting.newConnection") }}</div>
            </router-link>
            <router-link
              v-else
              :to="editLink(dc.uuid)"
              :class="{
                'dark-form-focus-ring': selectedIndex === index,
              }"
              class="w-full"
              @mouseenter="hoveredId = dc.uuid"
              @mouseleave="hoveredId = null"
            >
              <div class="flex w-full flex-col space-y-3">
                <div class="flex w-full space-x-2">
                  <div class="shrink-0">
                    <img class="w-14 h-14 flex-none" :src="dc.iconUrl" />
                  </div>

                  <div
                    class="flex flex-col items-start justify-start flex-grow space-y-1"
                  >
                    <div
                      class="text-[0.9rem] leading-tight font-bold truncate-inner"
                      :title="dc.name"
                    >
                      {{ dc.name }}
                    </div>

                    <div class="flex space-x-1 items-center">
                      <a
                        v-if="dc.isFaulted"
                        :href="getReauthUrl(dc)"
                        class="underline text-white hover:text-white visited:text-purple-600"
                      >
                        <IconButton
                          icon="Exclamation"
                          class="icon text-yellow-400 h-5 w-5 mx-1"
                          tooltip="connectionList.disconnectedTooltip"
                          tooltipPosition="t"
                          @click="onFaultedButtonClick(dc)"
                        />
                      </a>
                      <div class="text-xxs opacity-60">
                        Last modified on {{ modifiedAt(dc) }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex justify-between text-xs w-full">
                  <!-- Dupe and delete: -->
                  <div class="flex items-center space-x-3">
                    <div
                      class="flex space-x-1 opacity-70 hover:opacity-100 hover:underline"
                      @click.prevent="onModerate(dc.uuid)"
                      v-if="dc.moderationMode !== null"
                    >
                      <Icon name="ShieldCheck" class="h-4 w-4" />

                      <div>Moderate</div>
                    </div>

                    <div
                      class="flex space-x-1 opacity-70 hover:opacity-100 hover:underline"
                      @click.prevent="openCloneModal(dc)"
                    >
                      <Icon name="Duplicate" class="h-4 w-4" />
                      <div>Duplicate</div>
                    </div>

                    <div
                      class="flex space-x-1 opacity-70 hover:opacity-100 hover:underline"
                      @click.prevent="openDeleteModal(dc)"
                    >
                      <IconSolid name="Trash" class="h-4 w-4" />
                      <div>Delete</div>
                    </div>
                  </div>

                  <!-- Bolt: -->
                  <div
                    class="opacity-70 hover:opacity-100"
                    @click.prevent="openAppConnModal(dc)"
                  >
                    <LightningBoltIcon
                      v-if="connectionHasApps(dc)"
                      class="w-4 h-4 scale-[1.5]"
                      :on="true"
                      :hover="false"
                    />
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </RecycleScroller>

      <!-- Clone connection modal: -->
      <ActionModal
        v-if="isCloneModalOpen"
        @on-accept="onCloneConnection"
        @on-reject="isCloneModalOpen = false"
      >
        <div class="flex flex-col space-y-2">
          <div>Please enter a name for your connection:</div>
          <input
            v-model="newConnectionName"
            class="w-full rounded px-2 dark-form-focus text-[14px] text-black py-2"
          />
        </div>
      </ActionModal>

      <!-- Delete connection modal: -->
      <ActionModal
        v-if="isDeleteModalOpen"
        size="1/2"
        :isAcceptBtnDisabled="!canDeleteConnection"
        @on-accept="onDeleteConnection"
        @on-reject="isDeleteModalOpen = false"
      >
        <ConnectionDelete
          @click.prevent.self=""
          :canDeleteConnection.sync="canDeleteConnection"
          :dcUuid="editingConnection?.uuid"
        >
        </ConnectionDelete>
      </ActionModal>

      <!-- Apps in connection modal: -->
      <AppsInConnection
        v-if="isAppConnModalOpen"
        @close="isAppConnModalOpen = false"
        :appsInConnection="editingConnection?.appConnections"
      />

      <!-- Search empty: -->
      <div
        class="inline-block mt-12 p-6 text-center rounded shadow-xl bg-app-dark5 mx-auto"
        v-if="hasNoSearchResults"
      >
        <div class="text-xl" v-t="'connectionList.noSearchResults'"></div>
      </div>
      <div v-if="showEmptyState" class="h-full flex flex-col px-8">
        <router-link
          style="height: 60px"
          class="flex items-center justify-start text-[13px] space-x-2 p-2 cursor-pointer hover:bg-app-overlays-25 pl-[1.8rem] opacity-90"
          :to="{ path: '/connections/new' }"
        >
          <Icon class="w-[20px] h-[20px]" name="PlusCircle" :hover="false" />
          <div>{{ $t("dataImporting.newConnection") }}</div>
        </router-link>

        <div
          class="w-128 h-full mx-auto tracking-wider flex flex-col items-center justify-center empty"
        >
          <div
            class="flex text-center w-max welcome"
            v-t="'appList.emptyListPt1'"
          ></div>
          <div
            class="flex text-center w-max welcome"
            v-t="'appList.emptyListConnections'"
          ></div>
          <img class="empty-screen" src="@/assets/empty_screen_logo.svg" />
        </div>
      </div>
      <UiBlocker :visible="loadingConnections"> Loading Connections </UiBlocker>
    </template>
  </ScrollView>
</template>

<script lang="ts">
import { DataConnection } from "@/types/data";
import { Component, Vue, Watch } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import IconButton from "@/views/Dashboard/IconButton.vue";
import ScrollView from "@/views/Dashboard/ScrollView.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import { APP_EDITOR_CONNECTION_ROUTE_PATH } from "@/constants";
import ActionModal from "@/components/ActionModal.vue";
import Modal from "@/components/Modal.vue";
import ConnectionDelete from "@/views/Connection/ConnectionDelete.vue";
import { RecycleScroller } from "vue-virtual-scroller";
import ToolTip from "@/components/Tooltip.vue";
import debounce from "lodash.debounce";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { InputOption } from "@/types/inputs";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { useConnectionsStore } from "@/stores/connections";
import { makeId } from "@/utils";
import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import SearchBar from "@/views/Dashboard/SearchBar.vue";
import AppsInConnection from "@/views/Connection/components/AppsInConnection.vue";

/**
 * TODO: New connection button
 * Maaaybe split out into multiple components
 *
 * NOTE: rows should always have items centered, even when only 2 per row
 *
 */

@Component({
  components: {
    RecycleScroller,
    ButtonGradient,
    Icon,
    IconSolid,
    IconButton,
    SelectMenu,
    Modal,
    ScrollView,
    UiBlocker,
    ActionModal,
    ConnectionDelete,
    ToolTip,
    LightningBoltIcon,
    SearchBar,
    AppsInConnection,
  },
})
export default class ConnectionsList extends Vue {
  canDeleteConnection = false;

  loaded = false;

  isCloneModalOpen = false;
  isDeleteModalOpen = false;
  isAppConnModalOpen = false;
  isFilterModalOpen = false;

  hoveredId: string | null = null;

  loadingConnections = false;
  scrollY = 0;
  readonly rowHeight = 140;
  itemWidth = 320;
  itemsPerRow = 3;

  editingConnection: DataConnection | null = null;

  search = "";
  selectedIndex = -1;

  newConnectionName = "";

  sortBy = "lastModified";

  handleResize() {
    const padding = 50;
    this.itemsPerRow = Math.floor(
      (this.$el.clientWidth - padding) / this.itemWidth
    );
  }

  chunk(arr: any[], size: number) {
    return arr
      .reduce((acc, e, i) => {
        if (i % size === 0) {
          acc.push(arr.slice(i, i + size));
        }
        return acc;
      }, [])
      .map((arr: any[]) => ({ id: makeId(5), arr }));
  }

  mounted() {
    this.handleResize();
  }

  get rows() {
    const createConnectionButton: any = {
      isCreateConnectionButton: true,
    };
    return this.chunk(
      [createConnectionButton].concat(this.filteredConnections),
      this.itemsPerRow
    );
  }

  get connectionStore() {
    return useConnectionEditorStore();
  }

  get connectionsStore() {
    return useConnectionsStore();
  }

  get connections() {
    return this.connectionsStore.connections;
  }

  get sortOptions(): InputOption[] {
    return [
      {
        label: this.$t("connectionList.sortByLastModified").toString(),
        value: "lastModified",
      },
      {
        label: this.$t("connectionList.sortByNameAsc").toString(),
        value: "nameAsc",
      },
      {
        label: this.$t("connectionList.sortByNameDesc").toString(),
        value: "nameDesc",
      },
    ];
  }

  @Watch("sortBy")
  onSortByChanged() {
    this.selectedIndex = -1;
  }

  @Watch("search")
  onSearchChanged() {
    this.selectedIndex = -1;
  }

  onSearchInputInner(e: Event) {
    this.search = (e.target as HTMLInputElement).value;
  }

  onSearchInput = debounce(this.onSearchInputInner, 250);

  onSearchKeydown(e: KeyboardEvent) {
    if (e.key === "ArrowDown") {
      const len = this.filteredConnections.length;
      if (this.selectedIndex + 1 < len) {
        this.selectedIndex++;
        this.scrollTo(this.selectedIndex, "down");
      }
    }
    if (e.key === "ArrowUp") {
      this.selectedIndex--;
      if (this.selectedIndex === -2) {
        this.selectedIndex = -1;
      }
      if (this.selectedIndex >= 0) {
        this.scrollTo(this.selectedIndex, "up");
      }
    }
  }

  scrollTo(index: number, direction: "up" | "down") {
    this.$nextTick(() => {
      const scroller = this.$refs.scroller as any;

      let idx = index;
      if (idx > 3) {
        idx -= 3;
        scroller.scrollToPosition(idx * this.rowHeight);
      }

      if (direction === "up" && index < 4) {
        scroller.scrollToPosition(0);
      }
    });
  }

  onSearchEnter() {
    if (this.selectedIndex > -1) {
      const c = this.filteredConnections[this.selectedIndex];
      if (typeof c !== "undefined") {
        const to = this.editLink(c.uuid);
        this.$router.push(to);
      }
    }
  }

  get hasNoSearchResults() {
    return (
      isNonEmptyString(this.search) &&
      this.loaded &&
      this.filteredConnections.length === 0
    );
  }

  openDeleteModal(conn: DataConnection) {
    this.editingConnection = conn;
    this.isDeleteModalOpen = true;
  }

  openAppConnModal(conn: DataConnection) {
    this.editingConnection = conn;
    this.isAppConnModalOpen = true;
  }

  openCloneModal(conn: DataConnection) {
    this.editingConnection = conn;
    this.newConnectionName = conn.name + " (Duplicate)";
    this.isCloneModalOpen = true;
  }

  modifiedAt(connection: DataConnection) {
    return new Date(connection.modifiedAt).toLocaleString("en-US", {
      dateStyle: "short",
    });
  }

  get newConnectionLink() {
    return { path: `/${APP_EDITOR_CONNECTION_ROUTE_PATH}/new` };
  }

  editLink(connectionUuid: string) {
    return { path: `/${APP_EDITOR_CONNECTION_ROUTE_PATH}/${connectionUuid}` };
  }

  get sortLabel() {
    // Do not label as "Sort by Sort A-Z"
    if (this.sortBy.includes("name")) return "";
    return this.$t("sortBy");
  }

  async created() {
    this.loadingConnections = this.filteredConnections.length === 0;
    this.connectionsStore.getConnections({}).finally(() => {
      this.loaded = true;
      this.loadingConnections = false;
    });
  }

  get filteredConnections() {
    return this.sortedConnections.filter((c) => {
      const q = this.search.toLowerCase().trim();
      if (isNonEmptyString(q) && !c.name.toLowerCase().includes(q)) {
        return false;
      }
      return true;
    });
  }

  get sortedConnections() {
    const connections = [...this.connections];
    if (this.sortBy === "lastModified") {
      connections.sort((a, b) => {
        const d1 = Date.parse(a.modifiedAt);
        const d2 = Date.parse(b.modifiedAt);
        return d2 - d1;
      });
    } else if (this.sortBy === "nameAsc") {
      connections.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if (this.sortBy === "nameDesc") {
      connections.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    }
    return connections;
  }

  get showEmptyState() {
    return this.loaded && this.connections.length === 0;
  }

  beforeDestroy() {
    const scroller = document.querySelector(
      ".vue-recycle-scroller"
    ) as HTMLDivElement;

    if (scroller !== null) {
      scroller.removeEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll(e: Event) {
    const scroller = e.target as HTMLDivElement;
    this.scrollY = scroller.scrollTop;
  }

  onScrollerVisible() {
    const scroller = document.querySelector(
      ".vue-recycle-scroller"
    ) as HTMLDivElement;

    if (scroller !== null) {
      scroller.addEventListener("scroll", this.handleScroll);
    }
  }

  fetchConnections() {
    this.loadingConnections = true;
    this.connectionsStore.getConnections({}).finally(() => {
      this.loadingConnections = false;
    });
  }

  onModerate(uuid: string) {
    this.$router.push({ path: `/connections/${uuid}/settings/moderation` });
  }

  async onCloneConnection() {
    try {
      await this.connectionStore.cloneConnection({
        dcUuid: this.editingConnection?.uuid || "",
        name: this.editingConnection?.name + " (Duplicate)",
      });

      await this.fetchConnections();
      this.isCloneModalOpen = false;
    } catch (e: any) {
      // TODO: Handle this error.
    }
  }

  connectionHasApps(connection: DataConnection) {
    return connection.appConnections?.length > 0;
  }

  async onDeleteConnection() {
    if (!this.editingConnection) return;
    try {
      await this.connectionStore.deleteConnection(this.editingConnection);
    } catch {
      // keep modal open to display error message
      return;
    }

    this.isDeleteModalOpen = false;

    this.connectionStore.removeDeletedConnection(this.editingConnection);
  }

  getReauthUrl(connection: DataConnection) {
    return `/connections/${connection.uuid}/reauthorize`;
  }

  onFaultedButtonClick(connection: DataConnection) {
    this.$router.push(this.getReauthUrl(connection));
  }
}
</script>

<style lang="postcss" scoped>
.welcome {
  font-size: 1.5vw;
}

.empty {
  margin-top: -4.5rem;
}

.empty-screen {
  margin-top: 3vw;
}

.recycle-item {
  height: 60%;
}

.truncate-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
</style>
