<template>
  <div class="p-2 divide-y">
    <div>
      <ButtonGradient
        @click="destroyGroup"
        v-if="!ungroupDisabled"
        class="text-white m-4 py-2"
      >
        <span v-t="'groupEditor.ungroup'"></span
      ></ButtonGradient>
    </div>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'animation.animation'"></div>
      </template>
      <AnimationEditor />
    </CollapsePanel>

    <BaseWidgetEditor />

    <ToggleInput class="py-3 px-2" v-model="verticalDynamism"
      ><span class="text-sm" v-t="'verticalDynamism'"></span
    ></ToggleInput>

    <div class="w-full px-5" v-if="verticalDynamism">
      <div class="text-sm text-gray-500" v-t="'verticalMargin'"></div>
      <NumberSliderInput
        :min="0"
        :max="200"
        :step="1"
        :value="verticalMargin"
        @input="apply('verticalMargin', $event, 'NO_UNDO')"
        @change="apply('verticalMargin', $event)"
        class="w-full"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import AnimationEditor from "@/components/editors/AnimationEditor.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { NO_UNDO } from "@/types";

@Component({
  components: {
    ToggleInput,
    NumberSliderInput,
    FormLabel,
    ButtonGradient,
    BaseWidgetEditor,
    AnimationEditor,
    CollapsePanel,
  },
})
export default class GroupEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];
      if (values.length === 1) {
        result = values[0];
      }
    }
    return result as T;
  }

  apply(prop: string, value: any, NO_UNDO?: NO_UNDO) {
    const props = {
      [prop]: value,
    };
    this.appEditor.setWidgetProps(this.selections, props, NO_UNDO);
  }

  get verticalMargin(): number {
    return this.getValue("verticalMargin") || 0;
  }

  get verticalDynamism(): boolean {
    return this.getValue("verticalDynamism");
  }

  set verticalDynamism(val: boolean) {
    const payload = {
      wid: this.selections[0],
    };
    if (val) {
      this.appEditor.verticalDynamismOn(payload);
    } else {
      this.appEditor.verticalDynamismOff(payload);
    }
  }

  get ungroupDisabled() {
    return this.selections.length > 1;
  }

  destroyGroup() {
    this.appEditor.destroyGroup(this.selections[0]);
  }
}
</script>
