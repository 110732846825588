<template>
  <div class="">
    <div class="mb-8" v-if="sheetOptions.length > 1">
      <!-- Translation Needed Translate -->
      <div class="mb-2 text-lg">Which sheet do you want to use?</div>
      <TabInput
        :value="rangeId"
        @input="onSheetSelected"
        :options="sheetOptions"
      />
    </div>

    <div v-if="showColumnEditor">
      <div class="flex items-end justify-between">
        <div>
          <div class="mb-2 text-lg">
            Does the first row contain column headers?
          </div>

          <TabInput v-model="hasHeaderRow" :options="headerRowOptions" />
        </div>
        <div
          class="mb-2 text-center"
          v-if="recordCount !== null"
          :class="{
            'w-64': store.schemaToBeReplaced.length === 0,
            'w-48': store.schemaToBeReplaced.length > 0,
          }"
        >
          <div
            class="uppercase tracking-wider text-gray-400 text-xs"
            v-t="'DefineSpreadsheetSchema.totalRecords'"
          ></div>
          <div class="text-xl">
            {{ totalRecords }}
          </div>
        </div>
      </div>

      <DefineColumns
        :replaceSchema="store.schemaToBeReplaced"
        :nodes="columnNodes"
        :totalCount="recordCount"
        :startIndex="startIndex"
        :parentUuid="parentNode?.uuid"
        :buttonText="'Continue'"
        :hasHeaderRow="hasHeaderRow"
        @complete="onCompleteClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConnectionSourceId } from "@/types/ConnectionSources";
import { NodeKind, SchemaNode, TableSchema } from "@/types/data";
import TabInput from "@/components/inputs/TabInput.vue";
import DefineColumns from "@/components/data/connections/setup/schema/DefineColumns.vue";
import { useConnectionSetupStore } from "@/stores/connectionSetup";

export interface ParsedSheet {
  parentNode: SchemaNode | null;
  columnNodes: SchemaNode[];
}

// This isn't actually needed elsewhere
const parseSheet = (sheet: TableSchema): ParsedSheet => {
  const parentNode =
    sheet.schema.find((n) => n.kind === NodeKind.nodeSet) ?? null;
  return {
    parentNode,
    columnNodes:
      sheet.schema.filter(
        (n) => n.uuid !== parentNode?.uuid && !n.isArtificial
      ) ?? [],
  };
};

@Component({
  components: { TabInput, DefineColumns },
})
export default class DefineSpreadsheetSchema extends Vue {
  @Prop(String) sourceId: ConnectionSourceId;
  @Prop(String) button: string;

  recordCount: number | null = null;
  parentNode: SchemaNode | null = null;
  columnNodes: SchemaNode[] = [];

  created() {
    if (
      typeof this.rangeId === "string" &&
      this.sheets.some((s) => s.rangeId === this.rangeId)
    ) {
      this.onSheetSelected(this.rangeId);
    } else if (this.sheets.length === 1) {
      this.onSheetSelected(this.sheets[0].rangeId);
    }
  }

  onSheetSelected(rangeId: string) {
    const sheet = this.sheets.find((s) => s.rangeId === rangeId);
    if (sheet) {
      // this.parentNode =
      //   sheet.schema.find((n) => n.kind === NodeKind.nodeSet) ?? null;
      // this.columnNodes =
      //   sheet.schema.filter((n) => n.uuid !== this.parentNode?.uuid) ?? [];

      const { parentNode, columnNodes } = parseSheet(sheet);
      this.parentNode = parentNode;
      this.columnNodes = columnNodes;
      this.recordCount = sheet.recordCount;

      if (this.rangeId !== rangeId) {
        this.$emit("sheetSelected", rangeId);
      }
    }
  }

  get totalRecords() {
    const count = this.recordCount ?? 0;
    return Math.max(0, this.hasHeaderRow ? count - 1 : count);
  }

  get store() {
    return useConnectionSetupStore();
  }

  get sheets() {
    return this.store.sheets;
  }

  get rangeId() {
    return this.store.queryContext;
  }

  get showColumnEditor() {
    return this.parentNode && this.columnNodes.length > 0;
  }

  get startIndex() {
    return this.parentNode?.startIndex ?? 0;
  }

  get hasHeaderRow() {
    return this.parentNode?.startIndex === 1;
  }

  set hasHeaderRow(value: boolean) {
    if (this.parentNode) {
      this.parentNode.startIndex = value ? 1 : 0;
    }
  }

  get headerRowOptions() {
    return [
      { label: this.$t("yes"), value: true },
      { label: this.$t("no"), value: false },
    ];
  }

  get sheetOptions() {
    return this.sheets.map((g) => {
      return {
        label: g.title,
        value: g.rangeId,
      };
    });
  }

  onCompleteClick(columns: SchemaNode[]) {
    const nodeList = [this.parentNode].concat(columns);
    console.log("DefineSpreadsheetSchema.onCompleteClick");
    this.$emit("complete", nodeList);
  }
}
</script>
