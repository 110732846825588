import tailwindColors from "tailwindcss/colors";

const ignored = [
  `lightBlue`,
  `warmGray`,
  `trueGray`,
  `coolGray`,
  `blueGray`,
  `rose`,
];

const getColors = () => {
  const colors = tailwindColors as any;
  const values = Object.keys(colors)
    .filter((groupKey, index) => {
      return (
        !ignored.includes(groupKey) &&
        index % 2 === 0 &&
        typeof colors[groupKey] !== "string"
      );
    })
    .map((groupKey) => {
      return Object.keys(colors[groupKey])
        .filter((key) => parseInt(key) % 100 === 0)
        .map((key) => colors[groupKey][key]);
    });

  return values.flatMap((v) => v);
};

export const colors: string[] = getColors();
