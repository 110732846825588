<template>
  <div class="divide-y divide-app-dark3 space-y-4">
    <table ref="table" class="column-table -mx-2">
      <thead>
        <tr v-if="showPreview">
          <th :colspan="previewColspan"></th>
          <th>
            <div class="relative w-full h-6">
              <div
                class="absolute top-0 left-0 right-0 uppercase tracking-wider text-gray-400 text-xs font-normal text-center transform translate-y-2"
                v-t="'schemaEditor.previewData'"
              ></div>
            </div>
          </th>
        </tr>
        <tr>
          <th class="px-2 w-14" v-if="showUseColumn">
            <div class="relative w-full h-6">
              <div
                class="absolute top-0 left-1/2 text-center transform -translate-x-1/2"
                v-t="'schemaEditor.include'"
              ></div>
            </div>
          </th>
          <th class="text-left px-2" v-t="'schemaEditor.columnName'"></th>
          <th class="w-48 px-2" v-t="'schemaEditor.columnType'"></th>

          <th
            v-if="showRequiredColumn"
            class="px-2 w-32"
            v-t="'schemaEditor.allowEmptyValues'"
          ></th>

          <th
            v-if="showPreview"
            class="p-0 h-full whitespace-no-wrap"
            style="width: 264px"
          >
            <div class="relative flex justify-center h-full">
              <button
                type="button"
                @click="previous"
                tabindex="-1"
                class="bg-gray-600 bg-opacity-50 rounded text-gray-200 hover:text-white block h-full px-2 pr-3"
              >
                ◀︎
              </button>
              <div class="w-32 text-sm">
                {{ previewDataLength > 0 ? previewIndex + 1 : 0 }} of
                {{ previewDataLength }}
              </div>
              <button
                type="button"
                @click="next"
                tabindex="-1"
                class="bg-gray-600 bg-opacity-50 rounded text-gray-200 hover:text-white block h-full pl-3 pr-2"
              >
                ▶
              </button>
            </div>
          </th>
          <th class="w-16 pl-2" v-if="showDelete"></th>
        </tr>
      </thead>
      <tbody>
        <!-- Existing schema columns: -->
        <tr
          v-for="(column, i) in columns"
          :key="'column' + i"
          class=""
          :class="{
            'hover:bg-gray-700': true, //column.isSelected
          }"
        >
          <td :class="cellClasses" v-if="showUseColumn">
            <label class="w-full block text-center cursor-pointer">
              <input
                type="checkbox"
                v-model="column.isSelected"
                class="form-checkbox text-app-green bg-gray-600 rounded cursor-pointer dark-form-focus"
                :class="{
                  'focus:ring-app-green': column.isSelected,
                  'focus:ring-gray-500': !column.isSelected,
                }"
              />
            </label>
          </td>
          <td :class="cellClasses">
            <div class="relative">
              <input
                data-name-input
                class="px-2 py-1 w-full border rounded-sm text-gray-900 dark-form-focus"
                type="text"
                v-model="column.name"
                :disabled="!column.isSelected"
                @keyup.enter="onAddClick"
                :class="{
                  'opacity-20 bg-white bg-opacity-100': !column.isSelected,
                }"
              />
              <div
                class="absolute top-full font-mono pl-1 text-gray-300 text-xs"
                v-if="showQuery"
                :class="{ 'opacity-20': !column.isSelected }"
              >
                <div class="pt-px">
                  {{ column.displayQuery }}
                </div>
              </div>
            </div>
          </td>
          <td :class="cellClasses">
            <SelectMenu
              :options="getTypeOptions(column.altTypes || [])"
              v-model="column.dataType"
              :disabled="!column.isSelected"
              :dark="true"
              :class="{ 'opacity-20': !column.isSelected }"
            />
          </td>
          <td v-if="showRequiredColumn" :class="cellClasses">
            <SelectMenu
              :options="booleanOptions"
              :value="!column.isRequired"
              @input="setIsRequired(!$event, column)"
              :disabled="!column.isSelected"
              :dark="true"
              :class="{ 'opacity-20': !column.isSelected }"
            />
          </td>
          <td
            v-if="showPreview"
            class="h-full px-2 text-center"
            style="width: 260px; max-width: 260px"
            :class="cellClasses"
          >
            <div
              style="max-width: 260px"
              class="py-1 rounded"
              :class="{
                'bg-gray-100 bg-opacity-20 shadow': column.isSelected,
                'bg-gray-200 bg-opacity-20 opacity-20': !column.isSelected,
              }"
            >
              <component
                v-if="column.dataType !== 'ObjectArray'"
                class="truncate px-2"
                :class="{
                  'h-24':
                    column.dataType === 'ImageUrl' ||
                    column.dataType === 'ImageUpload',
                }"
                :is="getColumnPreviewComponent(column.dataType || 'String')"
                :dataType="column.dataType"
                :value="getColumnPreviewData(column.sampleValues || [])"
              />
            </div>
          </td>
          <td class="px-2" v-if="showDelete">
            <OutlineButton
              @click="deleteColumn(i)"
              v-t="'schemaEditor.delete'"
            ></OutlineButton>
          </td>
        </tr>
        <tr v-if="canAddColumns">
          <td v-if="showUseColumn"></td>
          <td class="p-2">
            <OutlineButton
              class="text-sm"
              @click="onAddClick"
              v-t="'DefineColumns.addColumn'"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <portal to="setupWizardNext">
      <FormButton
        v-t="completeButtonText"
        @click="onCompleteClick"
      ></FormButton>
    </portal>
  </div>
</template>

<script lang="ts">
import { makeId, removeReactivity } from "@/utils";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import { InputOption } from "@/types/inputs";
import {
  DataType,
  DataTypes,
  DataValue,
  SchemaNode,
  SchemaType,
  UserDefinedDataTypes,
} from "@/types/data";

import RadioInput from "@/components/inputs/RadioInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import FormButton from "@/components/FormButton.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import SelectMenu from "@/components/SelectMenu.vue";

import DataNodeDateTime from "@/components/data/DataNodeDateTime.vue";
import DataNodeValue from "@/components/data/DataNodeValue.vue";
import DataNodeColor from "@/components/data/DataNodeColor.vue";
import DataNodeEmpty from "@/components/data/DataNodeEmpty.vue";
import DataNodeImageUrl from "@/components/data/DataNodeImageUrl.vue";
import DataNodePrimativeArray from "@/components/data/DataNodePrimativeArray.vue";

import { useConnectionSetupStore } from "@/stores/connectionSetup";
import { getComponentName } from "@/components/data/dataNodeComponents";
import { ColumnInput } from "@/components/data/connections/manualSource/settings/views/SchemaColumns.vue";

/**
 * Have a look at connection creation, it should be quite similar
 *
 *
 * TODO: firstRowHeader toggle
 * TODO: Add preview column
 * TODO: Check to delete, look at node bindings.. similar to RepeaterEditor and SlideEditor
 *
 * TODO: Actually update the schema... via updateDataconnection... verify it shows up in grid..
 */

// TODO: v-model of boolean should be reversed
// TODO: SchemaNodes should be storing data type via class DataType

export type ColumnEditMode = "Define" | "Design";

@Component({
  components: {
    FormButton,
    OutlineButton,
    RadioInput,
    CheckboxInput,
    ToggleInput,
    SelectMenu,
    DataNodeDateTime,
    DataNodeValue,
    DataNodeColor,
    DataNodeEmpty,
    DataNodeImageUrl,
    DataNodePrimativeArray,
  },
})
export default class DefineColumns extends Vue {
  @Prop(Array) readonly nodes: SchemaNode[];
  @Prop({ type: Number, default: 0 }) readonly startIndex: number;
  @Prop(Boolean) showQuery: boolean;
  @Prop(String) parentUuid: string;
  @Prop(String) buttonText: string;
  @Prop({ type: Number, required: false }) totalCount?: number;
  @Prop({ type: String, default: "Define" }) mode: ColumnEditMode;
  @Prop({ type: String, default: "Tabular" }) schemaType: SchemaType;
  @Prop(Boolean) hasHeaderRow: boolean;

  previewIndex = 0;
  columns: Partial<SchemaNode>[] = [];
  typeTranslations: Record<string, string> = {};

  setIsRequired(val: boolean, column: Partial<ColumnInput>) {
    column.isRequired = val;
  }

  created() {
    this.columns = this.nodes.slice();
    DataTypes.forEach((prop) => {
      this.typeTranslations[prop] =
        this.$t(`dataType.${prop.toLowerCase()}`).toString() ?? prop;
    });

    if (this.mode === "Design" && this.columns.length === 0) {
      this.onAddClick();
    }

    // console.log("create data design", this.nodes);
  }

  @Watch("parentUuid")
  onParentUuidChanged() {
    this.columns = this.nodes.slice();
  }

  get cellClasses() {
    const classes = ["p-2"];
    if (this.showQuery) {
      classes.push("pb-6");
    }
    return classes;
  }

  get showPreview() {
    return this.previewDataLength > 0;
  }

  get showUseColumn() {
    return this.columns.length > 1 && this.mode !== "Design";
  }

  get showRequiredColumn() {
    return this.mode === "Design";
  }

  get showInclude() {
    return true;
  }

  get showDelete() {
    return this.mode === "Design";
  }

  get canAddColumns() {
    return this.mode === "Design";
  }

  get previewColspan() {
    let count = 4;
    if (!this.showRequiredColumn) {
      count--;
    }
    if (!this.showUseColumn) {
      count--;
    }
    return count;
  }

  get previewLabel() {
    if (this.previewDataLength < (this.totalCount ?? 0)) {
      return this.$t("schemaEditor.previewData", {
        count: this.previewDataLength,
      }).toString();
    }
    return "";
  }

  get previewDataLength() {
    const col = this.columns?.find((c) => c.sampleValues?.length ?? 0 > 0);
    let count = Math.min(11, col?.sampleValues?.length ?? 0);

    /**
     * The following conditional seemed to give wrong result when pulling a collection from a tree,
     * (see issue #1863), so I added the second condition `&& this.schemaType !== 'Tree'`
     */

    // Always show consistent number of preview records
    if (this.startIndex === 0 && this.schemaType !== "Tree") {
      count--;
    }

    if (!this.hasHeaderRow) {
      count++;
    }

    return Math.max(0, count - this.startIndex);
  }

  get completeButtonText() {
    return this.buttonText ?? this.$t("continue");
  }

  onCompleteClick() {
    // console.log("DefineColumns.onCompleteClick");
    this.$emit("complete", removeReactivity(this.columns));
  }

  getNewColumnName() {
    const prefix = "New Column ";
    const nums = this.columns
      .map((c) => parseInt(c.name?.substr(prefix.length) as string))
      .filter((n) => !isNaN(n));

    const max = Math.max.apply(null, nums);
    const count = max === -Infinity ? 1 : max + 1;
    return `${prefix}${count}`;
  }

  addNewColumn() {
    const name = this.getNewColumnName();
    const newColumn: Partial<SchemaNode> = {
      uuid: undefined,
      parentUuid: this.parentUuid,
      //kind: NodeKind.node,
      query: makeId(),
      sampleValues: [],
      isSelected: true,
      name: name,
      dataType: "String",
      isRequired: false,
      altTypes: UserDefinedDataTypes,
    };
    // console.log("add new col", newColumn);
    this.columns.push(newColumn);
  }

  onAddClick() {
    if (!this.canAddColumns) return;
    this.addNewColumn();
    this.$nextTick(() => {
      const t = this.$refs.table as HTMLTableElement;
      const inputs = t.querySelectorAll("[data-name-input]");
      if (inputs.length > 0) {
        const input = inputs[inputs.length - 1];
        if (input) {
          (input as HTMLInputElement).focus();
          (input as HTMLInputElement).select();
        }
      }
    });
  }

  deleteColumn(index: number) {
    this.columns.splice(index, 1);
  }

  getTypeOptions(types: DataType[]): InputOption[] {
    // console.log("get type options", types);
    return (types || []).map((t) => {
      return { value: t, label: this.typeTranslations[t] ?? "Unknown" };
    });
  }

  getColumnPreviewComponent = (type: DataType) => {
    // switch (type) {
    //   case "Time":
    //   case "Date":
    //   case "DateTime":
    //     return `DataNodeDateTime`;
    //   case "String":
    //   case "Bool":
    //   case "Number":
    //     return `DataNodeValue`;
    //   case "Color":
    //     return `DataNodeColor`;
    //   case "ObjectArray":
    //     return `DataNodeEmpty`;
    //   default:
    //     return `DataNode${type}`;
    // }
    return getComponentName(type);
  };

  previous() {
    this.previewIndex--;
    if (this.previewIndex < 0) {
      this.previewIndex = this.previewDataLength - 1;
    }
  }

  next() {
    this.previewIndex++;
    if (this.previewIndex >= this.previewDataLength) {
      this.previewIndex = 0;
    }
  }

  getColumnPreviewData(values: DataValue[]) {
    // console.log("val...", values[this.previewIndex + this.startIndex]);
    return values[this.previewIndex + this.startIndex];
  }

  get booleanOptions(): InputOption[] {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }

  get store() {
    return useConnectionSetupStore();
  }
}
</script>

<style scoped lang="postcss">
.column-table {
  width: calc(100% + theme("spacing.4"));
}
</style>
