<template>
  <div class="bg-gray-200">
    <AnimationPreviewButton />
    <div class="space-y-4 px-2 py-4">
      <div
        v-for="(tween, index) in tweens"
        :key="index"
        :class="{ '-mt-4': tween.attribute === 'opacity' }"
      >
        <NumberInputBar
          v-if="tween.attribute != 'opacity'"
          :min="valueMin(animation.presetId)"
          :max="valueMax(tween.attribute, animation.presetId)"
          :value="tween.value"
          :width="24"
          :units="getUnits(tween.attribute)"
          :align="'right'"
          class="bg-gray-200"
          @change="handleTweenPropChange('value', $event, index)"
          ><div v-t="getPropertyName(tween.attribute)"></div
        ></NumberInputBar>
      </div>
      <NumberInputBar
        :min="0"
        :max="Infinity"
        :value="animation.duration"
        :width="22"
        :step="0.1"
        :units="$t('animation.seconds')"
        :align="'right'"
        @change="handleTweenPropChange('duration', $event)"
        ><div v-t="'animation.duration'"></div
      ></NumberInputBar>
      <NumberInputBar
        :value="animation.delay"
        :width="22"
        :step="0.1"
        :units="$t('animation.seconds')"
        :align="'right'"
        @change="handleTweenPropChange('delay', $event)"
        ><div v-t="'animation.delay'"></div
      ></NumberInputBar>
      <div class="ml-3">
        <!-- <div class="text-sm text-gray-500" v-t="'animation.ease'"></div> -->
        <EditorSelectMenu
          class="w-auto text-sm"
          :value="animation.ease"
          :options="eases"
          :menuWidth="'auto'"
          :menuAlign="'right'"
          :easeSelector="true"
          :fullWidth="false"
          @input="handleTweenPropChange('ease', $event)"
          ><template v-slot:label><span v-t="'animation.ease'"></span></template
        ></EditorSelectMenu>
      </div>
      <!-- These options only exist on transitions -->
      <div v-if="animationKey === 'transition'" class="space-y-4">
        <ToggleInput
          class="pl-1 -my-2"
          :value="animation.outro"
          :bgTransparent="true"
          @input="handleTweenPropChange('outro', $event)"
          ><span class="text-sm" v-t="'animation.outro'"></span
        ></ToggleInput>
        <ToggleInput
          v-if="animation.canReverse && animation.outro"
          class="pl-1 -my-2"
          :value="animation.reverse"
          :bgTransparent="true"
          @input="handleTweenPropChange('reverse', $event)"
          ><span class="text-sm" v-t="'animation.reverse'"></span
        ></ToggleInput>
        <NumberInputBar
          v-if="animation.outro && requireOutro"
          :min="0"
          :max="Infinity"
          :value="animation.end"
          :width="22"
          :step="0.1"
          :units="$t('animation.seconds')"
          :align="'right'"
          @change="handleTweenPropChange('end', $event)"
          ><div v-t="'animation.end'"></div
        ></NumberInputBar>
        <div
          v-if="animation.outro && !requireOutro"
          class="text-sm text-gray-500 pl-3"
        >
          <span v-t="'animation.end'"></span>
          <span v-t="'animation.gridCycle'"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Eases } from "../widgets/Animation";
import { AnimationPreset, Animations, AnimationTween } from "@/types/animation";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import ToggleInput from "../inputs/ToggleInput.vue";
import EditorSelectMenu from "../EditorSelectMenu.vue";
import AnimationPreviewButton from "@/components/AnimationPreviewButton.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { getActiveWidget } from "@/util/conditionsUtils";
import { RepeaterOptions } from "../widgets/Repeater/RepeaterOptions";
import { GlobalAppState } from "@/GlobalAppState";

@Component({
  components: {
    NumberInputBar,
    ToggleInput,
    EditorSelectMenu,
    AnimationPreviewButton,
  },
})
export default class AnimationPropertyEditor extends Vue {
  @Inject() context: GlobalAppState;
  @Prop(String) animationKey: string;
  @Prop(Object) animations: Animations;

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }
  get widgets() {
    return this.appEditor.widgets;
  }

  get requireOutro() {
    if (this.selectedWidget === undefined) {
      return false;
    }

    const parentWidget = this.appEditor.widgets[this.selectedWidget.parentId];
    const parent = getActiveWidget(parentWidget, this.context.conditions);
    if (parent?.type === "Repeater") {
      const repeater = parent as unknown as RepeaterOptions;
      return repeater.cycleContent === true;
    }

    return false;
  }

  get animation() {
    return this.animations[
      this.animationKey as keyof Animations
    ] as AnimationPreset;
  }

  get tweens() {
    return this.animation.tweens;
  }

  get eases() {
    return Eases;
  }

  getUnits(attribute: string) {
    let unit = "";
    switch (attribute) {
      case "x":
      case "y":
        unit = "px";
        break;
      case "scale":
      case "opacity":
        unit = "%";
        break;
    }
    return unit;
  }

  getPropertyName(attribute: string) {
    let name = "";

    switch (attribute) {
      case "x":
      case "y":
        name = "animation.tweenDescriptions.slideFrom";
        break;
      case "scale":
        name = "animation.tweenDescriptions.scaleFrom";
        break;
      case "opacity":
        name = "animation.tweenDescriptions.fadeFrom";
        break;
    }

    return name;
  }

  valueMin(presetId: string) {
    if (presetId === "slideRight" || presetId === "slideDown") {
      return -Infinity;
    } else return 0;
  }

  valueMax(attribute: string, presetId: string) {
    if (presetId === "slideRight" || presetId === "slideDown") {
      return 0;
    } else {
      switch (attribute) {
        case "opacity":
          return 100;
        case "rotation":
          return 360;
        default:
          return Infinity;
      }
    }
  }

  handleTweenPropChange(
    propName: string,
    value: number | string | boolean,
    index?: number
  ) {
    const newPreset = JSON.parse(JSON.stringify(this.animation));
    let newAnimations = JSON.parse(JSON.stringify(this.animations));

    if (index != undefined) {
      (newPreset.tweens[index][propName as keyof AnimationTween] as any) =
        value;
    } else {
      (newPreset[propName as keyof AnimationPreset] as any) = value;
    }

    newAnimations[this.animationKey as keyof Animations] = newPreset;

    this.$emit("animationPropChanged", newAnimations);
  }
}
</script>

<style scoped lang="postcss"></style>
