<template>
  <draggable
    v-model="widgets"
    filter=".is-editing"
    class="widget-list"
    :preventOnFilter="false"
    :ghost-class="'ghost'"
    :dragClass="'dragged'"
    @start="isDragging = true"
    @end="isDragging = false"
  >
    <div
      v-for="widget in widgets"
      :key="widget.wid"
      class="w-full flex flex-col widget-item my-1"
      :data-id="widget.wid"
    >
      <div
        class="pl-1 bg-app-dark-2 rounded wrapper w-full h-full"
        :class="{
          'bg-gray-700 ring-0 ring-gray-300': isSelected(widget.wid),
          'ring-1 ring-gray-500': showHovered(widget.wid),
        }"
      >
        <div class="flex items-center">
          <Icon
            v-if="isChild(widget.parentId)"
            name="ChildIndicator"
            class="h-6 w-6"
            stroke-width="0.5"
            stroke-linecap="butt"
            stroke-linejoin="miter"
          ></Icon>
          <div
            class="w-full flex rounded justify-between pr-2 py-1 items-center"
            @mouseenter="hoverStart(widget)"
            @mouseleave="hoverEnd()"
            @mousedown.exact="selectWidget(widget)"
            @mousedown.shift="toggleSelection(widget)"
          >
            <input
              class="w-2/3 focus:outline-none focus:bg-gray-500 bg-transparent rounded pl-1 pointer-events-none caret-app-teal"
              :class="{
                'pointer-events-auto ring-0 bg-gray-700': isSelected(
                  widget.wid
                ),
                'is-editing': isEditing,
              }"
              type="text"
              spellcheck="false"
              :id="`input-${widget.wid}`"
              :name="`input-${widget.wid}`"
              :value="displayValue(widget)"
              :disabled="!isSelected(widget.wid)"
              maxlength="24"
              @change="updateName(widget.wid, $event)"
              @keydown="onKeyDown($event)"
              ondblclick="this.select();"
              draggable="false"
              ondragstart="return false;"
              ondrop="event.dataTransfer.dropEffect='none';event.stopPropagation(); event.preventDefault();"
              @focus="isEditing = true"
              @blur="isEditing = false"
            />
            <div v-if="showLockIcon(widget)" @click="toggleLock(widget)">
              <Icon
                class="h-4 w-4 lock"
                :name="widget.locked ? 'LockClosed' : 'LockOpen'"
              ></Icon>
            </div>
          </div>
        </div>
        <template v-if="hasChildren(widget.wid)"
          ><DraggableLayers
            :contextWid="widget.wid"
            class="px-2 py-1"
            :class="{ 'pl-4': isGrandchild(widget.wid) }"
          ></DraggableLayers>
        </template>
      </div>
    </div>
  </draggable>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { EditingContext, useAppEditorStore } from "@/stores/appEditor";
import { Widget } from "../widgets/Widget";
import Icon from "@/components/icons/Icon.vue";
import { RepeaterOptions } from "../widgets/Repeater/RepeaterOptions";
import orderBy from "lodash.orderby";
import draggable from "vuedraggable";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { getActiveWidget } from "@/util/conditionsUtils";
import { BASE_PARENT_ID, DEFAULT_LAYER_NAMES } from "@/constants";

@Component({
  name: "DraggableLayers",
  components: {
    Icon,
    draggable,
  },
})
export default class DraggableLayers extends Vue {
  @Prop({ type: String, default: "base" }) readonly contextWid: string;

  isEditing = false;
  isDragging = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  showHovered(wid: string) {
    return wid === this.hoveredWidget && !this.isDragging;
  }

  get widgets(): Widget[] {
    const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
    let widgetsArray = Object.values(this.appEditor.widgets)
      .filter((wg) => wg.parentId === this.contextWid)
      .map((wg) => getActiveWidget(wg, conditions))
      .map((wg) => Object.assign({}, wg, this.appEditor.widgetData[wg.wid][0]));

    return orderBy(widgetsArray, "z", "desc");
  }

  set widgets(widgets: Widget[]) {
    let ids: string[] = [];
    widgets.forEach((widget) => {
      ids.push(widget.wid);
    });
    this.appEditor.setWidgetStackOrder(ids.reverse());
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget?.wid || null;
  }

  get hoveredWidget() {
    return this.appEditor.hoveredId || null;
  }

  get selectedWidgetParents() {
    return this.appEditor.selections.map(
      (wid) => this.appEditor.widgetById(wid)?.parentId
    );
  }

  hasChildren(wid: string) {
    return this.appEditor.getChildren(wid).length > 0;
  }

  isChild(parentId: string) {
    return parentId != BASE_PARENT_ID;
  }

  isGrandchild(parentId: string) {
    let parent = this.appEditor.widgetById(parentId);

    if (parent) {
      return this.isChild(parent.parentId);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter" || event.key === "Escape") {
      (event.target as HTMLInputElement).blur();
    }
  }

  isSelected(wid: string) {
    return this.appEditor.selections.includes(wid);
  }

  isHovered(wid: string) {
    return this.hoveredWidget === wid;
  }

  showLockIcon(widget: Widget) {
    return (
      this.isSelected(widget.wid) || this.isHovered(widget.wid) || widget.locked
    );
  }

  displayValue(widget: Widget) {
    return widget.name || DEFAULT_LAYER_NAMES[widget.type as string];
  }

  selectWidget(widget: Widget) {
    let parent = this.appEditor.widgetById(widget.parentId);
    if (parent?.type === "Repeater") {
      let context = {
        parentId: widget.parentId,
        widgetX: 0,
        widgetY: 0,
        offsetX: (parent as RepeaterOptions).columnGap,
        offsetY: (parent as RepeaterOptions).rowGap,
        repeaterIndex: 0,
        width: 0,
        height: 0,
      };
      this.appEditor.setEditingContext(context as EditingContext);
    } else {
      this.appEditor.resetEditingContext();
    }
    this.appEditor.replaceSelections([widget.wid]);
  }

  toggleSelection(widget: Widget) {
    if (this.appEditor.selections.includes(widget.wid)) {
      this.appEditor.removeSelection(widget.wid);
    } else {
      if (this.selectedWidgetParents.every((wid) => wid === widget.parentId)) {
        this.appEditor.addSelection(widget.wid);
      }
    }
  }

  updateName(wid: string, event: Event) {
    this.appEditor.setWidgetProps([wid], {
      name: (event.target as HTMLInputElement).value,
    });
  }

  hoverStart(wg: Widget) {
    this.appEditor.setHoveredId(wg.wid);
  }

  hoverEnd() {
    this.appEditor.setHoveredId("");
  }

  toggleLock(widget: Widget) {
    this.appEditor.setWidgetProps([widget.wid], {
      locked: !widget.locked,
    });
  }
}
</script>

<style scoped>
.ghost {
  box-shadow: none;
  opacity: 0.4;
}

.dragged {
  border: 0;
  border-radius: 0.25rem;
  box-shadow: none;
}

.dragged .wrapper {
  box-shadow: none;
  border-radius: 0.25rem;
}

.dragged .lock {
  display: none;
  visibility: hidden;
}
</style>
