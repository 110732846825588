<template>
  <div
    class="flex flex-col items-start justify-center leading-tight rounded-md bg-app-dark3 p-2 space-y-1"
    style="width: 98%"
  >
    <div class="flex p-1 space-x-2 items-center">
      <img class="w-10 h-10 flex-none" :src="store.connection?.iconUrl" />
      <div>
        {{ store.connection?.name }}
      </div>
    </div>

    <div class="flex justify-start w-full">
      <div class="header-btn" @click="routeToDataSettings()">
        <Icon class="w-3 h-3" name="Cog" />
        <div v-t="'dataPanel.settings'"></div>
      </div>
      <div class="header-btn" @click="routeToDataManager()">
        <Icon class="w-3 h-3" name="CircleOpen" />
        <div v-t="store.isEditable ? 'dataPanel.edit' : 'dataPanel.view'"></div>
      </div>
      <div
        v-if="store.canModerate"
        class="header-btn"
        @click="routeToModeration()"
      >
        <Icon
          class="w-3 h-3"
          name="CircleCheckmark"
          :class="{ 'text-app-teal': isModerated }"
        />
        <div v-t="'dataPanel.moderate'"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { APP_EDITOR_CONNECTION_ROUTE_PATH } from "@/constants";
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    Icon,
    IconSolid,
  },
})
export default class ConnectionHeader extends Vue {
  get store() {
    return useConnectionEditorStore();
  }

  clearSelectedConnection() {
    this.$emit("clearSelectedConnection");
  }

  get isModerated() {
    return this.store.connection?.moderationMode !== null;
  }

  get dataManagerRoute() {
    return `${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}/${
      this.store.connection?.uuid || ""
    }`;
  }

  // NOTE: Do we need "type: 'Collection'"? I wouldn't think so..
  // I doubt we need property either...
  routeToDataManager() {
    this.$router.push({
      path: this.dataManagerRoute,
      query: {
        type: "Collection",
        property: "data",
      },
    });
  }

  async routeToDataSettings() {
    this.$router.push({
      path: `${this.dataManagerRoute}/settings`,
      query: {
        type: "Collection",
        property: "data",
      },
    });
  }

  routeToModeration() {
    this.$router.push({
      path: `${this.dataManagerRoute}/settings/moderation`,
      query: {
        type: "Collection",
        property: "data",
      },
    });
  }
}
</script>

<style scoped lang="postcss">
.header-btn {
  @apply text-xs cursor-pointer flex space-x-[3px] text-gray-300 hover:text-gray-200 justify-center items-center p-[3px] mx-[3px] rounded-xl hover:bg-gray-600 hover:shadow-md;
}
</style>
