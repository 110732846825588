<template>
  <div class="w-57 flex items-center space-x-2">
    <SliderInput
      class="flex"
      :min="min"
      :max="max"
      :step="step"
      :value="value"
      @change="updateFinal"
      @input="update"
      :disabled="disabled"
      :dataBound="dataBound"
    />
    <EditorNumberInput
      class="w-12 ml-2 h-6"
      v-bind="numberInputAttrs"
      :rounded="rounded"
      @change="updateInput"
      :disabled="disabled"
      :dataBound="dataBound"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import FormLabel from "@/components/FormLabel.vue";
import SliderInput from "@/components/inputs/SliderInput.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";

// Unsure whether we want controls on NumInput..

@Component({
  components: { FormLabel, SliderInput, EditorNumberInput },
})
export default class NumberSliderInput extends Vue {
  @Prop(Boolean) controls: boolean;
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) dataBound: boolean;
  @Prop({ type: Number, default: Infinity }) max: number;
  @Prop({ type: Number, default: 0 }) min: number;
  @Prop(String) placeholder: string;
  @Prop({ default: true }) rounded: boolean;
  @Prop({ type: Number, default: 1 }) step: number;
  @Prop({ type: Number, default: NaN }) value: number;
  @Prop({ default: false }) cycle360: boolean;
  @Prop(Number) py: number;
  @Prop(Number) px: number;

  get numberInputAttrs() {
    return {
      controls: this.controls,
      min: this.min,
      max: this.max,
      value: this.value,
      step: this.step,
      cycle360: this.cycle360,
      placeholder: this.placeholder,
      disabled: this.disabled,
      dataBound: this.dataBound,
      px: this.px,
      py: this.py,
    };
  }

  get isDisabled() {
    return this.disabled || this.dataBound;
  }

  update(value: number) {
    this.$emit("input", value);
  }

  updateFinal(value: number) {
    this.$emit("change", value);
  }

  updateInput(val: any) {
    this.$emit("change", val);
  }
}
</script>
