import { defineStore } from "pinia";

import { DataConnection, SchemaType } from "@/types/data";
import { api } from "@/api/backend";
import uniqWith from "lodash.uniqwith";
import { useAppEditorStore } from "@/stores/appEditor";
import Vue from "vue";

const PAGE_SIZE = 20;

export interface ConnectionsState {
  connections: DataConnection[];
  isFetchingPage: boolean;
  numberSuccessfulPageFetchs: number;
}

export const useConnectionsStore = defineStore("connections", {
  state: (): ConnectionsState => {
    return {
      connections: [],
      isFetchingPage: false,
      numberSuccessfulPageFetchs: 0,
    };
  },
  getters: {
    appConnections(): DataConnection[] {
      const appEditor = useAppEditorStore();
      const dataBindings = appEditor.dataBindings;
      const dataBoundConnectionUuids = new Set(
        dataBindings.map((db) => db.dataConnectionUuid)
      );
      return this.connections.filter((c) =>
        dataBoundConnectionUuids.has(c.uuid)
      );
    },

    nonAppConnections(): DataConnection[] {
      const appEditor = useAppEditorStore();
      const dataBindings = appEditor.dataBindings;
      const dataBoundConnectionUuids = new Set(
        dataBindings.map((db) => db.dataConnectionUuid)
      );
      return this.connections.filter(
        (c) => !dataBoundConnectionUuids.has(c.uuid)
      );
    },
  },

  actions: {
    fetchPagedConnections() {
      if (this.isFetchingPage) return;
      this.isFetchingPage = true;
      const pageNumber = this.numberSuccessfulPageFetchs + 1;

      return api
        .get<DataConnection[]>(
          `dataconnection?expandNodes=true&pageSize=${PAGE_SIZE}&page=${pageNumber}&sortBy=name`
        )
        .then(async (connections) => {
          this.addConnections(connections);
          this.numberSuccessfulPageFetchs++;
        })
        .finally(() => {
          /**
           * Ensure a bit of time for connections to render, stretching out scroll space,
           * so that we don't accidentally trigger new fetch immediately
           */
          setTimeout(() => {
            this.isFetchingPage = false;
          }, 100);
        });
    },

    async getConnections(payload: {
      appUuid?: string;
      onlyAppConnections?: boolean;
      onlyCollections?: boolean;
      onlyNonCollections?: boolean;
      includeSchemaTypes?: SchemaType[];
      excludeSchemaTypes?: SchemaType[];
    }) {
      const queryParams = new URLSearchParams();
      if (payload?.appUuid) queryParams.append("appUuid", payload.appUuid);

      if (payload?.onlyAppConnections)
        queryParams.append(
          "onlyAppConnections",
          payload.onlyAppConnections === true ? "true" : "false"
        );

      if (payload?.onlyCollections)
        queryParams.append(
          "onlyCollections",
          payload.onlyCollections === true ? "true" : "false"
        );

      if (payload?.onlyNonCollections)
        queryParams.append(
          "onlyNonCollections",
          payload.onlyNonCollections === true ? "true" : "false"
        );

      if (
        payload?.includeSchemaTypes &&
        payload.includeSchemaTypes.length !== 0
      )
        queryParams.append(
          "includeSchemaTypes",
          payload.includeSchemaTypes.join(",")
        );

      if (
        payload?.excludeSchemaTypes &&
        payload.excludeSchemaTypes.length !== 0
      )
        queryParams.append(
          "excludeSchemaTypes",
          payload.excludeSchemaTypes.join(",")
        );

      return api
        .get<DataConnection[]>(`dataconnection?${queryParams.toString()}`)
        .then((res) => {
          this.connections = res;
          return res;
        });
    },

    async initializeConnections(appUuid: string) {
      return api
        .get<DataConnection[]>(
          `dataconnection?onlyAppConnections=true&appUuid=${appUuid}&expandNodes=true`
        )
        .then(async (connections) => {
          this.addConnections(connections);
        });
    },

    addConnections(connections: DataConnection[]) {
      this.connections = uniqWith(
        this.connections.concat(connections),
        (a, b) => a.uuid === b.uuid
      );
    },

    addConnection(connection: DataConnection) {
      const connIndex = this.connections.findIndex(
        (c) => c.uuid === connection.uuid
      );
      connIndex !== -1
        ? Vue.set(this.connections, connIndex, connection)
        : this.connections.push(connection);
    },

    removeConnection(connectionUuid: string) {
      const connIndex = this.connections.findIndex(
        (c) => c.uuid === connectionUuid
      );
      if (connIndex !== -1) {
        Vue.delete(this.connections, connIndex);
      }
    },
  },
});
