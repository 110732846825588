<template>
  <a class="link" :href="href" :target="target"><slot /></a>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class Link extends Vue {
  @Prop(String) href: string;
  @Prop({ type: String, default: "_self" }) target: string;
}
</script>

<style scoped lang="postcss">
.link {
  @apply relative cursor-pointer text-app-teal;
}
.link::after {
  content: "";
  @apply cursor-pointer  absolute transform -translate-y-px bottom-0 left-0 right-0 h-px bg-gray-500;
}
.link:hover::after {
  @apply bg-gray-400;
}
</style>
