<template>
  <svg
    v-if="data && data.length > 0"
    class="chart"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <clipPath :id="`column-clip-${wid}`">
        <rect x1="0" y1="0" :width="graphWidth" :height="graphHeight" />
      </clipPath>
    </defs>
    <g :transform="`translate(${leftMargin}, ${topMargin})`">
      <rect
        class="bg"
        v-if="showBg"
        :width="graphWidth"
        :height="graphHeight"
        x="0"
        y="0"
        :fill="bgColor"
      />
      <g class="grid" :transform="`translate(0, 0)`">
        <g
          class="tick"
          text-anchor="end"
          v-for="(tick, index) in ticks"
          :key="index"
          :transform="`translate(0, ${y(tick) + 0.5})`"
        >
          <line
            class="gridLine"
            v-if="showGrid"
            :stroke="lineColor"
            opacity="0.2"
            :x2="graphWidth"
          ></line>
        </g>
      </g>
      <g class="columns" :clip-path="$helpers.svgUrl(`#column-clip-${wid}`)">
        <rect
          v-for="(column, index) in columns"
          :fill="column.color"
          :key="index"
          :height="column.height"
          :width="column.width"
          :x="column.x"
          :y="column.y"
        ></rect>
        <g v-if="showValues">
          <text
            class="values"
            v-for="(column, index) in columns"
            :key="index"
            :x="column.x ? column.x + column.width / 2 : column.width / 2"
            :y="column.y - 10"
            :style="getStyles('label')"
            :font-size="label_fontSize"
            text-anchor="middle"
            dominant-baseline="middle"
          >
            {{ column.value }}
          </text>
        </g>
      </g>
      <g class="x-axis" :transform="`translate(0, ${graphHeight})`">
        <line
          class="domain"
          :stroke="lineColor"
          :stroke-width="lineThickness"
          x1="0"
          :x2="graphWidth"
          y1="0"
        ></line>
        <g
          class="tick"
          text-anchor="middle"
          v-for="(column, index) in columns"
          :key="index"
          :transform="
            column.x
              ? `translate(${column.x + column.width / 2}, 0)`
              : `translate(0, 0)`
          "
        >
          <line
            :stroke="lineColor"
            :stroke-width="lineThickness"
            :y1="0 - lineThickness / 2"
            :y2="3 + lineThickness"
          ></line>
          <text
            v-if="!xLabelTilt"
            :style="getStyles('label')"
            :font-size="label_fontSize"
            :y="9 + lineThickness"
            dy="0.9em"
          >
            {{ column.xLabel }}
          </text>
          <text
            v-if="xLabelTilt"
            :style="getStyles('label')"
            :font-size="label_fontSize"
            :y="9 + lineThickness"
            dy="0.71em"
            text-anchor="end"
            transform="rotate(-40) translate(-10, -2)"
          >
            {{ column.xLabel }}
          </text>
        </g>
      </g>
      <g class="y-axis" :transform="`translate(0, 0)`">
        <line
          class="domain"
          :stroke="lineColor"
          :stroke-width="lineThickness"
          x1="0"
          y1="0"
          :y2="graphHeight"
        ></line>
        <g
          class="tick"
          text-anchor="end"
          v-for="(tick, index) in ticks"
          :key="index"
          :transform="`translate(0, ${y(tick) + 0.5})`"
        >
          <line
            :stroke="lineColor"
            :stroke-width="lineThickness"
            :x1="0 + lineThickness / 2"
            :x2="-3 - lineThickness"
          ></line>

          <text
            :x="-9 - lineThickness"
            dy="0.32em"
            :style="getStyles('label')"
            :font-size="label_fontSize"
          >
            {{ tick }}
          </text>
        </g>
      </g>
    </g>
    <text
      class="x-axis title"
      :transform="xTitleTransform"
      text-anchor="middle"
      :style="getStyles('title')"
      :font-size="title_fontSize"
    >
      {{ labelTitle }}
    </text>
    <text
      class="y-axis title"
      :transform="yTitleTransform"
      text-anchor="middle"
      :style="getStyles('title')"
      :font-size="title_fontSize"
    >
      {{ valueTitle }}
    </text>
  </svg>
</template>

<script lang="ts">
import { NodeData } from "@/types/data";
import * as d3 from "d3";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ColumnGraphOptions } from "./ColumnGraphOptions";

@Component({})
export default class ColumnGraphComponent extends Vue {
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly barPadding: number;

  @Prop(Number) readonly minValue: number;
  @Prop(Number) readonly maxValue: number;
  @Prop(Number) readonly minManual: number;
  @Prop(Number) readonly maxManual: number;
  @Prop(String) readonly xTitle: string;
  @Prop(String) readonly yTitle: string;
  @Prop(String) readonly xTitleManual: string;
  @Prop(String) readonly yTitleManual: string;

  @Prop(String) readonly title_fontFamily: string;
  @Prop(Number) readonly title_fontSize: number;
  @Prop(Number) readonly title_fontWeight: number;
  @Prop(String) readonly title_fontStyle: string;
  @Prop(String) readonly title_textColor: string;
  @Prop(String) readonly title_textTransform: string;

  @Prop(String) readonly label_fontFamily: string;
  @Prop(Number) readonly label_fontSize: number;
  @Prop(Number) readonly label_fontWeight: number;
  @Prop(String) readonly label_fontStyle: string;
  @Prop(String) readonly label_textColor: string;
  @Prop(String) readonly label_textTransform: string;

  @Prop(String) readonly lineColor: string;
  @Prop(Number) readonly lineThickness: number;
  @Prop(Number) readonly labelTicks: number;
  @Prop(Number) readonly leftTitlePadding: number;
  @Prop(Number) readonly bottomTitlePadding: number;
  @Prop(String) readonly bgColor: string;
  @Prop(String) readonly barColor: string;
  @Prop(Array) readonly customBarColors: string[];
  @Prop(Boolean) readonly useCustomBarColors: boolean;
  @Prop(Boolean) readonly xLabelTilt: boolean;
  @Prop(Boolean) readonly showBg: boolean;
  @Prop(Boolean) readonly showGrid: boolean;
  @Prop(Boolean) readonly showValues: boolean;
  @Prop(String) readonly xAxisColumnId: string;
  @Prop(String) readonly yAxisColumnId: string;
  @Prop(String) readonly wid: string;
  @Prop({ type: Array, default: () => [] }) readonly data: NodeData[][];

  get width() {
    return this.w * this.scaleX;
  }

  get height() {
    return this.h * this.scaleY;
  }

  get graphWidth() {
    return this.width - this.leftMargin - this.lineThickness;
  }

  get graphHeight() {
    return (
      this.height -
      70 -
      this.lineThickness -
      this.label_fontSize -
      this.bottomTitlePadding -
      this.title_fontSize -
      this.tiltBuffer -
      this.topMargin
    );
  }

  get graphXTitle() {
    return this.xTitleManual === null ? this.xTitle : this.xTitleManual;
  }
  get graphYTitle() {
    return this.yTitleManual === null ? this.yTitle : this.yTitleManual;
  }

  get leftMargin() {
    return (
      this.valueWidth +
      this.leftTitlePadding +
      90 +
      this.title_fontSize / 2 +
      this.lineThickness
    );
  }

  get topMargin() {
    return this.showValues ? this.label_fontSize + 20 : this.label_fontSize;
  }

  get labelTitle() {
    if (this.data && this.data.length > 0) {
      if (this.xTitle != "") {
        return this.graphXTitle;
      }
      return this.data[0].find((c) => c.uuid === this.xAxisColumnId)
        ?.displayName;
    }
    return "";
  }

  get valueTitle() {
    if (this.data && this.data.length > 0) {
      if (this.yTitle != "") {
        return this.graphYTitle;
      }
      return this.data[0].find((c) => c.uuid === this.yAxisColumnId)
        ?.displayName;
    }
    return "";
  }

  get labels() {
    if (this.data.length > 0) {
      return this.data.map((row) => {
        return row.find((c) => c.uuid === this.xAxisColumnId)
          ?.formattedValue as string;
      });
    }
    return [] as string[];
  }

  get values() {
    if (this.data && this.data.length > 0) {
      let values: number[] = [];

      this.data.map((row) => {
        values.push(
          parseInt(
            row.find((c) => c.uuid === this.yAxisColumnId)?.value as string
          )
        );
      });

      return values;
    }
    return [];
  }

  get yMax() {
    let optionValue = this.maxManual === null ? this.maxValue : this.maxManual;
    return isNaN(optionValue)
      ? Math.ceil(Math.max(...this.values))
      : optionValue;
  }

  get yMin() {
    let optionValue = this.minManual === null ? this.minValue : this.minManual;
    return isNaN(optionValue)
      ? Math.floor(Math.min(...this.values))
      : optionValue;
  }

  get x() {
    return d3
      .scaleBand()
      .range([0, this.graphWidth])
      .padding(1 - this.barPadding)
      .domain(this.labels);
  }

  get y() {
    return d3
      .scaleLinear()
      .range([this.graphHeight, 0])
      .domain([this.yMin, this.yMax]);
  }

  get tiltBuffer() {
    if (this.xLabelTilt) {
      return Math.tan(40 * (Math.PI / 180)) * this.labelWidth;
    } else {
      return 0;
    }
  }

  get colorArray() {
    return this.customBarColors;
  }

  get ticks() {
    return this.y.ticks(this.labelTicks);
  }

  get columns() {
    let columns = this.data.map((r, index) => {
      let value = parseInt(
        r.find((c) => c.uuid === this.yAxisColumnId)?.value as string
      );
      let label =
        r.find((c) => c.uuid === this.xAxisColumnId)?.formattedValue ?? "";
      let color = this.useCustomBarColors
        ? this.colorArray[index]
        : this.barColor;

      return {
        xLabel: label,
        value: value,
        x: this.x(label),
        y: value > 0 ? this.y(value) : this.y(0),
        width: this.x.bandwidth(),
        height: this.y(this.yMin) - this.y(value),
        color: color,
      };
    });
    return columns;
  }

  get xTitleTransform() {
    return `translate(${
      this.graphWidth / 2 + this.leftMargin + this.lineThickness / 2
    }, ${
      this.graphHeight +
      40 +
      this.bottomTitlePadding +
      this.lineThickness +
      this.label_fontSize +
      this.title_fontSize +
      this.tiltBuffer +
      this.topMargin
    } )`;
  }

  get yTitleTransform() {
    return `translate(${
      this.leftMargin - this.valueWidth - this.leftTitlePadding - 40
    }, ${this.graphHeight / 2 + this.topMargin} ), rotate(-90)`;
  }

  get labelTracker() {
    let object = this.getStyles("label");
    let labels = [];
    for (const property in object) {
      labels.push(`${property}, ${object[property]}`);
    }
    return `${labels}|${this.yAxisColumnId}`;
  }

  get valueTracker() {
    let object = this.getStyles("value");
    let values = [];
    for (const property in object) {
      values.push(`${property}, ${object[property]}`);
    }
    return `${values}|${this.xAxisColumnId}`;
  }

  get longestLabel() {
    let longest = this.labels.reduce(function (a: string, b: string) {
      return a.length > b.length ? a : b;
    });
    return longest;
  }

  get longestValue() {
    return Math.max(...this.values);
  }

  get labelWidth() {
    let labelLength = this.longestLabel.length;
    let fontSize = this.label_fontSize;
    return labelLength * (fontSize / 2);
  }

  get valueWidth() {
    let valueLength = this.longestValue.toString().length;
    let fontSize = this.label_fontSize;

    return valueLength * fontSize * 1.5;
  }

  getStyles(prefix: string) {
    const vm = this as any;
    const result: Partial<ColumnGraphOptions> = {};
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.fill = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }
    return result;
  }
}
</script>

<style></style>
