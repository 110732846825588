<template>
  <div class="divide-y">
    <ColorPanelPalette
      class="p-3"
      :values="basicPalette"
      @select="selectSwatch"
    />

    <ColorPanelPalette
      class="p-3"
      :values="defaultPalette"
      @select="selectSwatch"
    />

    <div class="p-3">
      <ColorPicker
        :value="value"
        :gradientEnabled="gradientEnabled"
        @input="onColorPickerInput"
        @change="selectValue"
        ref="picker"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { colors } from "./colors";
import ColorPicker from "./inputs/ColorPicker.vue";
import ColorPanelPalette from "@/components/ColorPanelPalette.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import tinycolor from "tinycolor2";

@Component({
  components: { ColorPicker, ColorPanelPalette, CollapsePanel },
})
export default class ColorPanel extends Vue {
  @Prop(String) value: string;
  @Prop(Boolean) gradientEnabled: boolean;
  showDefaultPalette = false;

  selectValue(color: string) {
    this.$emit("change", color);
  }

  selectSwatch(color: string) {
    const isGradient = this.value.includes("gradient");
    const { updateGradientColor } = this.$refs.picker as any;

    if (!isGradient) {
      // this.$emit("change", color);
      this.$emit("selectSwatch", color);
    } else {
      // Update gradient
      const tc = tinycolor(color);
      updateGradientColor(tc, true);
    }
  }

  onColorPickerInput(color: string) {
    this.$emit("input", color);
  }

  get basicPalette() {
    return [
      "hsla(0, 0%, 0%, 0)",
      "hsla(360, 100%, 100%, 1)",
      "hsla(0, 0%, 0%, 1)",
    ];
  }

  get defaultPalette() {
    return colors;
  }
}
</script>
