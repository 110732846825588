<template>
  <div class="relative">
    <SelectAuthorization
      dataProviderId="GoogleCalendar"
      authProvider="Google"
      :showReauthorize="showReauthorize"
      :selectedAuthId="auth?.providerAuthId"
      @select="onAccountSelect"
      @reauthorized="onReauthorize"
    />

    <portal to="setupWizardUiBlocker">
      <UiBlocker v-if="loadingMessage">
        <div class="mt-4 text-xl text-white" v-text="loadingMessage"></div>
      </UiBlocker>
    </portal>

    <portal to="setupWizardNext">
      <FormButton
        class="dark-form-focus"
        :disabled="!showCalendars"
        @click="onCalendarSelected"
        v-t="'continue'"
      />
    </portal>

    <div v-if="fetchingCalendars">
      <div class="py-6 text-xl" v-t="'GoogleCalendar.fetchingCalendars'"></div>
    </div>
    <div class="py-6 space-y-6" v-if="showCalendars">
      <div v-t="'GoogleCalendar.selectCalendar'" class="text-xl"></div>
      <TabInput v-model="selectedCalendarId" :options="calendarOptions" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api/backend";
import { SourceDataSchema, ProviderAuthorization } from "@/types/data";
import { GoogleCalendar, GoogleCalendarList } from "@/types/google";

import SelectAuthorization from "@/components/data/connections/setup/provide/SelectAuthorization.vue";
import FatalServerError from "@/components/FatalServerError.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import TabInput from "@/components/inputs/TabInput.vue";
import Icon from "@/components/icons/Icon.vue";
import { SourceDataInfo } from "@/stores/connectionSetup";

@Component({
  components: {
    FatalServerError,
    SelectAuthorization,
    UiBlocker,
    FormButton,
    SelectMenu,
    TabInput,
    Icon,
  },
})
export default class ProvideGoogleCalendar extends Vue {
  showReauthorize = false;

  fetchingAuths = false;
  auths: ProviderAuthorization[] | null = null;
  auth: ProviderAuthorization | null = null;

  hasCalendars = false;
  fetchingCalendars = false;
  fetchCalendarError: string | null = null;
  calendars: GoogleCalendar[] | null = null;
  selectedCalendarId: string | null = null;

  fetchingEvents = false;

  get calendarOptions() {
    if (this.showCalendars)
      return this.calendars?.map((c) => {
        return {
          label: c.summary === this.auth?.username ? "Default" : c.summary,
          value: c.id,
        };
      });
    return undefined;
  }

  get showCalendars() {
    return (
      this.fetchingCalendars === false &&
      this.calendars !== null &&
      this.calendars.length > 0
    );
  }

  get selectedCalendarName() {
    return this.calendars?.find((c) => c.id === this.selectedCalendarId)
      ?.summary;
  }

  get loadingMessage() {
    if (this.fetchingCalendars) {
      return this.$t("GoogleCalendar.loadingCalendars").toString();
    }

    if (this.fetchingEvents) {
      return this.$t("GoogleCalendar.loadingEvents").toString();
    }
    return undefined;
  }

  onReauthorize() {
    this.showReauthorize = false;
    this.fetchCalendars();
  }

  onAccountSelect(auth: ProviderAuthorization | null) {
    this.auth = auth;
  }

  @Watch("auth.providerAuthId")
  onAuthChanged(providerAuthId?: number) {
    this.showReauthorize = false;
    this.calendars = null;
    if (typeof providerAuthId === "number") {
      this.fetchCalendars();
    }
  }

  fetchCalendars() {
    if (this.auth === null) {
      return;
    }

    const resultCount = 50;
    const authId = this.auth.providerAuthId;
    const url = `google/calendars?providerAuthId=${authId}&resultsPerPage=${resultCount}&minAccessRole=reader`;

    this.calendars = null;
    this.showReauthorize = false;
    this.fetchingCalendars = true;
    return api
      .get<GoogleCalendarList>(url)
      .then((response) => {
        this.calendars = response.calendars;
      })
      .catch((errors) => {
        const code = errors?.[0]?.code;

        switch (code) {
          case "authFailure":
            this.showReauthorize = true;
            break;
          default:
            this.fetchCalendarError = Array.isArray(errors)
              ? `Errors: ${errors.map((e: any) => e.message).join(". ")}`
              : errors.toString();
            break;
        }
      })
      .finally(() => {
        this.fetchingCalendars = false;
      });
  }

  onCalendarSelected() {
    if (this.selectedCalendarId !== null) {
      const calendar = this.calendars?.find(
        (c) => c.id === this.selectedCalendarId
      );
      if (calendar) {
        const payload = {
          calendarId: calendar.id,
          providerAuthId: this.auth?.providerAuthId,
        };

        this.fetchingEvents = true;
        return api
          .post<SourceDataSchema>(`google/calendarevents`, payload)
          .then((response) => {
            this.$emit("complete", {
              name: this.selectedCalendarName,
              type: "Calendar",
              schema: response,
              providerAuthId: payload.providerAuthId,
            } as SourceDataInfo);
          })
          .finally(() => {
            this.fetchingEvents = false;
          });
      }
    }
  }
}
</script>
