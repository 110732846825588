<template>
  <ShapeBase
    v-bind="$props"
    :borderGradientParts="borderGradientParts"
    :gradientParts="gradientParts"
    dataType="Line"
  >
    <line
      :x1="borderWidth / 2"
      :y1="h / 2"
      :x2="w * scaleX - borderWidth / 2"
      :y2="h / 2"
      :stroke="border"
      :stroke-linecap="linecap"
      :stroke-width="borderWidth"
      :stroke-dasharray="dashArray"
      fill="none"
    />
  </ShapeBase>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { makeId, parseGradientCss } from "@/utils";
import ShapeBase from "@/components/ShapeBase.vue";
import AnimatableWidgetWidget from "@/components/widgets/AnimatableWidget.vue";

@Component({
  inheritAttrs: false,
  components: {
    ShapeBase,
  },
})
export default class LineComponent extends AnimatableWidgetWidget {
  @Prop(Number) readonly borderRadius: number;
  @Prop(String) readonly backgroundColor: string;
  @Prop(String) readonly backgroundImageUrl: string;
  @Prop(String) readonly backgroundSize: string;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  @Prop(Number) readonly perspectiveAngle: number;

  // TODO: Wrap up in an object? Same for bgImage
  @Prop(Number) readonly shadowX: number;
  @Prop(Number) readonly shadowY: number;
  @Prop(Number) readonly shadowBlur: number;
  @Prop(String) readonly shadowColor: string;
  @Prop(Boolean) readonly shadowDisplay: boolean;

  @Prop(Number) readonly borderWidth: number;
  @Prop(String) readonly borderColor: string;
  @Prop(Number) readonly borderDashSize: number;
  @Prop(Number) readonly borderGapSize: number;
  @Prop(String) readonly linecap: string;

  // @Prop() tempBackgroundInfo: any;
  @Prop({ default: makeId }) componentId: string;

  get borderDashed() {
    return this.borderDashSize > 0;
  }

  get dashArray() {
    if (this.borderDashed) {
      return `${this.borderDashSize} ${this.borderGapSize}`;
    } else {
      return "";
    }
  }

  get border() {
    if (this.borderGradientParts.type === "Solid") {
      return this.borderColor;
    }
    return this.$helpers.svgUrl(
      `#${this.borderGradientParts.type}-grad-border-${this.wid}`
    );
  }

  get gradientParts() {
    const parts = parseGradientCss(this.backgroundColor);
    return parts;
  }

  get borderGradientParts() {
    return parseGradientCss(this.borderColor);
  }
}
</script>
