import { removeReactivity } from "@/utils";

export const DATA_TOKEN_TYPE = "datatoken";

/**
 * This is a copy of the definition in @tiptap/core
 * It is copied here to avoid importing @tiptap into
 * the renderer bundle
 */
export type TextContent = {
  type: string;
  attrs?: Record<string, any>;
  content?: TextContent[];
  marks?: {
    type: string;
    attrs?: Record<string, any>;
    [key: string]: any;
  }[];
  text?: string;
  [key: string]: any;
};

const generateHtml = (doc: TextContent) => {
  if (doc.content) {
    return doc.content
      .flatMap((p) => {
        const para =
          p.content &&
          p.content
            .map((n) => {
              return n.type === "datatoken" ? n.attrs?.text : n.text;
            })
            .join("");
        return `<p>${para ?? "&nbsp;"}</p>`;
      })
      .join("");
  }
  return "";
};

export const contentToHtml = (content: TextContent) => {
  if (typeof content === "undefined") {
    return "";
  }

  if (typeof content === "string") {
    return content;
  }

  const plainContent = removeReactivity<TextContent>(content);

  const html = generateHtml(plainContent);

  // Add empty space inside empty p tags to ensure the browser renders them with height:
  const res = html
    .replace(/<p><\/p>/g, "<p>&nbsp;</p>")
    .replace(/<p> <\/p>/g, "<p>&nbsp;</p>")
    .replace(/<p><span(.*)><\/span><\/p>/g, "<p><span$1>&nbsp;</span></p>");
  return res;
};

export const makeTextContent = (text: string) => {
  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: text,
          },
        ],
      },
    ],
  };
};

export const makeDynamicTextContent = (
  dataUuid: string,
  content: string | number
) => {
  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [
          {
            type: "datatoken",
            attrs: {
              uuid: dataUuid,
              // Ensure that when user drags in an empty data token, the widget doesn't get 0 height:
              text: content === "" ? "&nbsp;" : content,
            },
          },
          {
            type: "text",
            text: " ",
          },
        ],
      },
    ],
  };
};
