// Modules
import Vue from "vue";
import { AppState, makeInitialAppState } from "@/stores/AppState";
import { ConditionsData, RendererData } from "@/types/rendererData";
import { DataBinding, NodeData, NodeSetData } from "@/types/data";
import { Size, ResolvedWidgetData } from "@/types";
import { getScaleFactor } from "@/utils";
import { Widget } from "@/components/widgets/Widget";
import { AppRendererOptions } from "./rendererOptions";

export interface RendererState extends AppState {
  renderId: string | null;

  renderScale: number;
  renderWidth: number;
  renderHeight: number;

  /**
   * Whether the app has a valid license associated with it.
   */
  isLicensed: boolean;

  /**
   * Whether the app is published.
   */
  isPublished: boolean;

  /**
   * Whether the app is a bundle
   */
  isBundled: boolean;

  /**
   * Whether the app is being snapshotted.
   */
  isSnapshot: boolean;

  bundledAssetUrls?: Record<string, string>;
  conditions?: ConditionsData;

  dataBindings: DataBinding[];
  widgetData: Record<string, Record<string, NodeSetData | NodeData>>;
  dataUrl: string;

  showIntroImage: boolean;
  showFallbackImage: boolean;
}

export const clearState = (state: RendererState) => {
  state.renderId = null;
  state.isLicensed = false;
  state.isPublished = false;
  state.isBundled = false;
  state.isSnapshot = false;

  state.dataBindings = [];
  state.widgetData = {};
  state.dataUrl = "";
  state.conditions = undefined;

  state.showIntroImage = false;
  state.showFallbackImage = false;
};

export const makeInitialState = () => {
  const state = makeInitialAppState() as RendererState;
  clearState(state);
  return Vue.observable(state);
};

const state = makeInitialState();

export { state as renderState };

export const adjustWidgetOffset = (
  widget: Widget,
  scaleFactor: number,
  appSize: Size,
  renderSize: Size
) => {
  const renderAspect = renderSize.w / renderSize.h;
  const appAspect = appSize.w / appSize.h;
  const xOffset =
    renderAspect > appAspect ? (renderSize.w - appSize.w * scaleFactor) / 2 : 0;
  const yOffset =
    renderAspect > appAspect ? 0 : (renderSize.h - appSize.h * scaleFactor) / 2;

  return Object.assign(widget, {
    x: widget.x + xOffset,
    y: widget.y + yOffset,
  });
};

/**
 * When an app is bundled, the bundle will include all the images
 * for the app in the bundle. The bundler will also include an additional
 * property into the app data model called `bundled`.
 * {
 *   bundled: {
 *    urls: {
 *      // The 'key' is the original asset url
 *      "http://kitchen-qa.screenfeed.com/app/asset/*******.jpg":
 *        // The value is the file path to the bundled url
 *        "bundled_files/*******.jpg"
 *     }
 *   }
 * }
 * This provides an easy-to-use lookup table we can use at runtime
 * to replace remote urls with local, bundled urls.
 *
 * This function simply scans through the `WidgetData` structure
 * and attempts to replace any remote url with a local bundled url
 * if it exists in the lookup.
 */
export const replaceBundledUrls = (
  widgetData: ResolvedWidgetData,
  urls: Record<string, string> | undefined
) => {
  if (typeof urls !== "undefined" && Object.keys(urls).length >= 0) {
    for (const widgetId in widgetData) {
      widgetData[widgetId].forEach((record) => {
        for (const prop in record) {
          const currentUrl = record[prop];
          if (prop === "url" && urls[currentUrl]) {
            record[prop] = urls[currentUrl];
          }
        }
      });
    }
  }
  return widgetData;
};

export const replaceState = (payload: {
  rendererData: RendererData;
  renderId: string;
  rendererOptions: AppRendererOptions;
}) => {
  const { rendererData, renderId, rendererOptions } = payload;

  if (!rendererData) return;

  state.renderId = renderId;
  state.assets = rendererData.assets;
  state.height = rendererData.height;
  state.width = rendererData.width;
  state.name = rendererData.name;
  state.uuid = rendererData.uuid;
  state.widgets = rendererData.model.widgets;
  state.custom = rendererData.model.custom;
  state.parents = rendererData.model.parents;
  state.appVersionUuid = rendererData.currentVersion;
  state.ianaTimeZone = rendererData.ianaTimeZone;

  state.fallbackImageTimeoutSec = rendererData.fallbackImageTimeoutSec;
  state.introImageTimeoutSec = rendererData.introImageTimeoutSec;

  state.isPublished = rendererData.isPublished;
  state.isLicensed = rendererData.isLicensed;
  state.dataBindings = rendererData.dataBindings;
  state.widgetData = rendererData.widgetData;
  state.isBundled = typeof rendererData.bundled === "object";
  state.bundledAssetUrls = rendererData.bundled?.urls;
  state.conditions = rendererData.conditions;

  state.isSnapshot = rendererOptions.snapshot;
};

export const updateRendererConditionsData = (payload: RendererData) => {
  const { conditions, dataBindings, widgetData, assets } = payload;
  Vue.set(state, "conditions", conditions);
  Vue.set(state, "dataBindings", dataBindings);
  Vue.set(state, "widgetData", widgetData);
  Vue.set(state, "assets", assets);
};

export const updateRendererData = (payload: RendererData) => {
  const { widgetData } = payload;

  for (const widgetId in widgetData) {
    Vue.set(state.widgetData, widgetId, widgetData[widgetId]);
  }
};

export const updateRenderSize = (payload: Size) => {
  state.renderHeight = payload.h;
  state.renderWidth = payload.w;

  const appSize = { w: state.width, h: state.height };
  const renderSize = payload;
  state.renderScale = getScaleFactor(renderSize, appSize);
};

export const updateLicenseStatus = (payload: { isLicensed: boolean }) => {
  state.isLicensed = payload.isLicensed;
};
