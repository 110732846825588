<template>
  <div class="relative" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <slot />
    <div ref="arrow" class="absolute" :class="arrowClasses"></div>
    <div
      ref="text"
      class="absolute rounded-sm text-xs font-bold text-white px-2 leading-6 w-max z-50 break-all"
      style="max-width: 250px"
      :class="contentClasses"
      v-text="getText()"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ToolTip extends Vue {
  @Prop(String) position: "t" | "r" | "b" | "l";
  @Prop(Boolean) disabled: boolean;
  @Prop() text: string;
  @Prop({ type: Boolean, default: false }) hideArrow: boolean;

  visible = false;
  private timer: any = null;

  getText() {
    return typeof this.text === "string" ? this.text : "";
  }

  destroyed() {
    clearTimeout(this.timer);
  }

  onMouseEnter() {
    this.timer = setTimeout(this.showTip, 150);
  }

  onMouseLeave() {
    this.hideTip();
    clearTimeout(this.timer);
  }

  showTip() {
    if (this.disabled) {
      this.visible = false;
      return;
    }
    this.visible = true;
  }

  hideTip() {
    this.visible = false;
  }

  @Watch("disabled")
  onDisabledChanged(isDisabled: boolean) {
    if (isDisabled) {
      this.visible = false;
      clearTimeout(this.timer);
    }
  }

  get bgColor() {
    return "bg-gray-700";
  }

  get borderColor() {
    return "border-gray-700";
  }

  get pos() {
    switch (this.position) {
      case "t":
      case "r":
      case "b":
      case "l":
        return this.position;
      default:
        return "b";
    }
  }

  get contentClasses() {
    if (!this.visible) {
      return ["invisible"];
    }
    switch (this.pos) {
      case "t":
        return [this.bgColor, "tip-offset-t"];
      case "r":
        return [this.bgColor, "tip-offset-r"];
      case "l":
        return [this.bgColor, "tip-offset-l"];
      default:
        return [this.bgColor, "tip-offset-b"];
    }
  }

  get arrowClasses() {
    if (this.hideArrow || !this.visible || !this.text) {
      return ["invisible"];
    }
    switch (this.pos) {
      case "t":
        return [this.borderColor, "tip-arrow-t"];
      case "r":
        return [this.borderColor, "tip-arrow-r"];
      case "l":
        return [this.borderColor, "tip-arrow-l"];
      default:
        return [this.borderColor, "tip-arrow-b"];
    }
  }
}
</script>

<style scoped>
.tip-arrow-t {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-bottom-width: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-r {
  left: 100%;
}
.tip-arrow-b {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-l {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.tip-offset-t {
  bottom: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-r {
  left: calc(100% + theme("spacing.2"));
}
.tip-offset-b {
  top: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-l {
  top: calc(100% + theme("spacing.2"));
  right: 50%;
  transform: translateX(10px);
}
</style>
