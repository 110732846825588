<template>
  <div class="text-settings px-4 pb-3 space-y-3">
    <div class="flex space-x-4">
      <FontFamilyInput
        class="w-38"
        :value="fontFamily"
        @input="updateFontFamily"
      />
      <FontSizeInput :value="fontSize" @input="update('fontSize', $event)" />
    </div>

    <div class="flex my-3">
      <FontVariantInput
        :family="fontFamily"
        :value="fontVariant"
        @input="onVariantSelected"
        class="w-40"
      />

      <ScalarBinder
        :propName="element + '_textColor'"
        propType="Color"
        v-slot:default="slotProps"
      >
        <ColorInput
          class="ml-6"
          iconSize="5"
          :value="slotProps.value"
          @input="previewTextColor($event)"
          @change="updateTextColor($event)"
          :dataBound="slotProps.isBound"
        />
      </ScalarBinder>
    </div>
    <div
      class="flex w-full pl-3"
      v-if="!(disableLineHeight && disableLetterSpacing)"
    >
      <div
        v-if="!disableLineHeight"
        class="line-height flex items-center mr-12 space-x-1"
      >
        <Icon name="LineHeight" class="w-6 h-6 mr-px" />
        <EditorNumberInput
          class="w-14"
          :controls="true"
          :value="lineHeight"
          :step="0.1"
          @change="update('lineHeight', $event)"
        />
      </div>
      <div
        v-if="!disableLetterSpacing"
        class="letter-spacing flex items-center space-x-1"
      >
        <Icon name="LetterSpacing" class="w-6 h-6 mr-px" />
        <EditorNumberInput
          class="w-14"
          :controls="true"
          :value="letterSpacing"
          :step="0.5"
          @change="update('letterSpacing', $event)"
        />
      </div>
    </div>

    <div class="flex pl-2 space-x-10">
      <div v-if="!disableAlignment">
        <EditorRadioInput
          type="icon"
          class="w-24"
          :value="textAlign"
          :options="hAlignOptions"
          @input="update('textAlign', $event)"
        />
      </div>
      <div v-if="!disableTextTransform">
        <TextTransformInput
          class="w-24"
          :value="textTransform"
          @input="updateTextTransform"
        />
      </div>

      <!-- <RadioInput
          type="icon"
          class="mt-2"
          :value="alignVertical"
          :options="vAlignOptions"
          @input="apply({ alignVertical: $event })"
        /> -->
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import FormLabel from "@/components/FormLabel.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import FontSizeInput from "@/components/inputs/FontSizeInput.vue";
import ColorInput from "@/components/inputs/ColorInput.vue";
import Icon from "@/components/icons/Icon.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import EditorRadioInput from "@/components/inputs/EditorRadioInput.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";
import FontVariantInput from "@/components/inputs/FontVariantInput.vue";
import TextTransformInput from "@/components/inputs/TextTransformInput.vue";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { FontVariant } from "fontfaceobserver";
import { useAppEditorStore } from "@/stores/appEditor";

// TODO: Clean up alignment buttons....use RadioInput

// Just pulling in applyFontFamily mutation here, rather than pasting handler onto every instance of this component

@Component({
  components: {
    FormLabel,
    FontFamilyInput,
    FontSizeInput,
    ColorInput,
    Icon,
    EditorRadioInput,
    SelectMenu,
    EditorSelectMenu,
    EditorNumberInput,
    ButtonIcon,
    ScalarBinder,
    FontVariantInput,
    TextTransformInput,
  },
})
export default class GroupTextFormatEditor extends Vue {
  @Prop(String) label: string;
  @Prop(String) element: string;
  @Prop(Object) model: any;
  @Prop({ default: false }) disableAlignment: boolean;
  @Prop({ default: false }) disableLineHeight: boolean;
  @Prop({ default: false }) disableLetterSpacing: boolean;
  @Prop({ default: false }) disableTextTransform: boolean;

  get appEditor() {
    return useAppEditorStore();
  }

  get textDecorationOptions() {
    return [
      { label: this.$t("textDecorationOptions.none"), value: "none" },
      {
        label: this.$t("textDecorationOptions.strikethrough"),
        value: "line-through",
      },
      { label: this.$t("textDecorationOptions.underline"), value: "underline" },
    ];
  }

  get hAlignOptions() {
    return [
      { label: "TextAlignLeft", value: "left" },
      { label: "TextAlignCenter", value: "center" },
      { label: "TextAlignRight", value: "right" },
    ];
  }

  get fontVariant() {
    return { style: this.fontStyle, weight: this.fontWeight };
  }

  onVariantSelected(variant: FontVariant) {
    this.$emit("update", {
      [`${this.element}_fontWeight`]: variant.weight,
      [`${this.element}_fontStyle`]: variant.style,
    });
  }

  get textColor() {
    return this.model[`${this.element}_textColor`];
  }

  get fontFamily() {
    return this.model[`${this.element}_fontFamily`];
  }

  get fontSize() {
    return this.model[`${this.element}_fontSize`];
  }

  get textTransform() {
    return this.model[`${this.element}_textTransform`];
  }

  get textDecoration() {
    return this.model[`${this.element}_textDecoration`];
  }

  get textAlign() {
    return this.model[`${this.element}_textAlign`];
  }

  get fontWeight() {
    return this.model[`${this.element}_fontWeight`];
  }

  get fontStyle() {
    return this.model[`${this.element}_fontStyle`];
  }

  get lineHeight() {
    return this.model[`${this.element}_lineHeight`];
  }

  get letterSpacing() {
    return this.model[`${this.element}_letterSpacing`];
  }

  get fontVariation() {
    let weight = "normal";
    switch (this.fontWeight) {
      case 200:
        weight = "light";
        break;
      case 700:
        weight = "bold";
        break;
    }
    return `${weight}-${this.fontStyle}`;
  }

  updateTextTransform(value: string) {
    const current = this.model[`${this.element}_textTransform`];
    const newValue = current === value ? "none" : value;
    this.appEditor.setWidgetProps([this.model.wid], {
      [`${this.element}_textTransform`]: newValue,
    });
  }

  updateTextAlign(value: string) {
    this.appEditor.setWidgetProps([this.model.wid], {
      [`${this.element}_textAlign`]: value,
    });
  }

  update(property: string, value: any) {
    this.$emit("update", {
      [`${this.element}_${property}`]: value,
    });
  }

  updateTextColor(value: string) {
    this.appEditor.applyTextColor(
      this.model.wid,
      this.model.syncTextColors,
      this.element,
      value
    );
  }

  previewTextColor(value: string) {
    this.appEditor.applyTextColor(
      this.model.wid,
      this.model.syncTextColors,
      this.element,
      value,
      "NO_UNDO"
    );
  }

  updateFontFamily(value: string) {
    // this.$emit("updateFontFamily", { element: this.element, value });

    // Can avoid sending list of textElements...if we just search ALL keys for "fontFamily"....should work

    this.appEditor.applyFontFamily({
      model: this.model, // only need wid and syncFonts...Oh..and also list of all textElements.....
      element: this.element,
      value,
    });
  }
}
</script>

<style lang="postcss" scoped>
.font-style-icon {
  @apply w-7 h-7 cursor-pointer hover:bg-app-teal;
  padding: 0.2rem;
}

.textStyle {
  max-width: 14rem;
}
.textStyle:hover {
  background: #ddd;
}
.text-styles {
  top: 25px;
  left: 0px;
  z-index: 10000;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
