<template>
  <div class="w-full">
    <RemapColumns
      v-if="isReady"
      ref="remapColumns"
      :connectionUuid="setupStore.connectionUuidToBeReplaced"
      :newConnectionUuid="setupStore.connection?.uuid"
      :newNodes="setupStore.configuredNodes"
      :hideButton="true"
      @complete="onRemapComplete"
    />

    <portal to="setupWizardNext">
      <FormButton v-t="'continue'" @click="onCompleteClick"></FormButton>
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";
import FormButton from "@/components/FormButton.vue";
import RemapColumns from "@/components/data/connections/manage/RemapColumns.vue";
import { isNullOrUndefined } from "@/utils";
import { RemapCollectionPayload, useAppEditorStore } from "@/stores/appEditor";
import { SetupStep } from "@/types/ConnectionSources";
import { EventBus } from "@/eventbus";

const props = defineProps<{
  setupStep: SetupStep | undefined;
}>();

const emits = defineEmits<{
  (e: "complete", value: SetupStep): void;
}>();

const appEditor = useAppEditorStore();
const setupStore = useConnectionSetupStoreV2();
const remapColumns = ref(null);

const isReady = computed(() => {
  return (
    !isNullOrUndefined(setupStore.connectionUuidToBeReplaced) &&
    !isNullOrUndefined(setupStore.connection?.uuid)
  );
});

async function onCompleteClick() {
  /**
   * This is strange, but we're triggering the saveBindings()
   * method on the remapColumns component which then emits
   * the "complete" event which we've bound to `onRemapComplete()`.
   *
   * The reason is... we want to use the `Continue` button from _this_
   * component to trigger that action because it lives in a portal.
   */
  await (remapColumns.value! as RemapColumns).saveBindings();
  emits("complete", props.setupStep!);
}

async function onRemapComplete(payload: RemapCollectionPayload) {
  try {
    EventBus.emit("AWAITING_SERVER", true);
    await appEditor.remapCollection(payload);
  } finally {
    EventBus.emit("AWAITING_SERVER", false);
  }
}
</script>
