<template>
  <div class="flex flex-col px-4">
    <slot />

    <div class="flex flex-wrap">
      <div
        v-for="comp in allComponents"
        :key="comp"
        class="icon-container flex items-center flex-col w-14 text-center text-xs mr-4 mt-1 cursor-pointer hover:text-app-pink"
        @mouseenter="mouseenterIcon"
        @mouseleave="clearIconTransform"
        @mousedown="mousedownIcon"
        @mouseup="clearIconTransform"
        @click="addComponent(comp)"
      >
        <ComponentIcon :name="comp" style="transform: scale(1)" />
        <div style="line-height: 1.2">{{ comp }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import RectangleOptions from "@/components/widgets/Rectangle/RectangleOptions";
import EllipseOptions from "@/components/widgets/Ellipse/EllipseOptions";
import SvgOptions from "@/components/widgets/Svg/SvgOptions";
import TriangleOptions from "@/components/widgets/Triangle/TriangleOptions";
import LineOptions from "@/components/widgets/Line/LineOptions";

import { Widget } from "../widgets/Widget";
import { RepeaterOptions } from "../widgets/Repeater/RepeaterOptions";
import ComponentIcon from "@/components/icons/componentIcons/ComponentIcon.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({ components: { ComponentIcon } })
export default class ShapesMenu extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get allComponents() {
    return ["Rectangle", "Ellipse", "Polygon", "Triangle", "Line"];
  }

  // These 3 copied from WidgetsMenu
  mouseenterIcon(ev: MouseEvent) {
    const { currentTarget } = ev;
    (currentTarget as HTMLElement).style.transform = "translate(-1px,-1px)";
  }

  mousedownIcon(ev: MouseEvent) {
    const { currentTarget } = ev;
    (currentTarget as HTMLElement).style.transform = "translate(1px,1px)";
  }

  clearIconTransform(ev: MouseEvent) {
    const { currentTarget } = ev;
    (currentTarget as HTMLElement).style.transform = "translate(0,0)";
  }

  // You know what....We may just want to recompute path on resize?
  // Since we'll have to recompute based on wg props (inner radius, star, radius)
  // Eh...probably not
  // But should the wg be responsible for computing its path....rather than take that as a prop....Yes

  /**
   * TODO: Must extend this to any widget that can be added from left-hand panel to a repeater/slide
   */
  get size(): { w: number; h: number } {
    let size = { w: this.artboard.w, h: this.artboard.h };
    if (this.isBaseEditingContext) {
      return size;
    }

    const wg = this.appEditor.widgetById(this.editingContext.parentId);
    if (!wg) {
      return size;
    }

    switch (wg.type) {
      case "Repeater": {
        const rw = wg as unknown as RepeaterOptions;
        return {
          w: (rw.w - (rw.columns - 1) * rw.columnGap) / rw.columns,
          h: (rw.h - (rw.rows - 1) * rw.rowGap) / rw.rows,
        };
      }

      default:
        return size;
    }
  }

  addToCanvas(widget: Widget) {
    this.appEditor.addWidgetAction({ widget });
    this.appEditor.replaceSelections([widget.wid]);
    this.$emit("closeMenu");
  }

  addComponent(shape: string) {
    (this as any)[`add${shape}`]();
  }

  addPolygon() {
    const { w, h } = this.size;
    const sideLength = Math.min(w, h) / 2;

    const widget = SvgOptions({
      canScaleX: true,
      canScaleY: true,
      x: (w - sideLength) / 2,
      y: (h - sideLength) / 2,
      w: sideLength,
      h: sideLength,
      innerRadius: 0.2,
      starEnabled: true,
      numSides: 5,
      lockAspect: true,
    });

    this.addToCanvas(widget);
  }

  addEllipse() {
    const { w, h } = this.size;
    const sideLength = Math.min(w, h) / 2;

    const widget = EllipseOptions({
      canScaleX: true,
      canScaleY: true,
      x: (w - sideLength) / 2,
      y: (h - sideLength) / 2,
      w: sideLength,
      h: sideLength,
    });

    this.addToCanvas(widget);
  }

  addRectangle() {
    const { w, h } = this.size;
    const sideLength = Math.min(w, h) / 2;

    const widget = RectangleOptions({
      canScaleX: true,
      canScaleY: true,
      x: (w - sideLength) / 2,
      y: (h - sideLength) / 2,
      w: sideLength,
      h: sideLength,
    });

    this.addToCanvas(widget);
  }

  addTriangle() {
    const { w, h } = this.size;
    const sideLength = Math.min(w, h) / 2;

    const widget = TriangleOptions({
      canScaleX: true,
      canScaleY: true,
      x: (w - sideLength) / 2,
      y: (h - sideLength) / 2,
      w: sideLength,
      h: sideLength,
    });

    this.addToCanvas(widget);
  }

  addLine() {
    const { w, h } = this.size;

    const widget = LineOptions({
      canScaleX: true,
      canScaleY: false,
      x: w / 8,
      y: h / 2 - 35,
      w: w - (2 * w) / 8,
      h: 70,
      borderWidth: 70,
      borderColor: "#000000",
    });

    this.addToCanvas(widget);
  }
}
</script>

<style scoped>
.icon-container {
  transition: transform 0.1s;
}
</style>
