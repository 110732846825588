<template>
  <svg
    class="w-5 h-5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g v-if="type === 'Column'">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.56641 4.65724C5.9435 4.55472 6.34029 4.5 6.74986 4.5H17.2499C17.6594 4.5 18.0562 4.55472 18.4333 4.65724C17.9406 3.67454 16.924 3 15.7499 3H8.24986C7.0757 3 6.0591 3.67454 5.56641 4.65724ZM2.25 12C2.25 10.3431 3.59315 9 5.25 9H18.75C20.4069 9 21.75 10.3431 21.75 12V18C21.75 19.6569 20.4069 21 18.75 21H5.25C3.59315 21 2.25 19.6569 2.25 18V12ZM4.06641 7.65724C4.4435 7.55472 4.84029 7.5 5.24986 7.5H18.7499C19.1594 7.5 19.5562 7.55472 19.9333 7.65724C19.4406 6.67454 18.424 6 17.2499 6H6.74986C5.5757 6 4.5591 6.67454 4.06641 7.65724ZM13.239 11.0197C13.2768 10.8811 13.2152 10.7345 13.0897 10.6646C12.9642 10.5946 12.8071 10.6192 12.709 10.7243L8.33405 15.4118C8.24914 15.5027 8.2263 15.6354 8.27589 15.7495C8.32549 15.8637 8.43806 15.9375 8.5625 15.9375H11.5909L10.761 18.9803C10.7232 19.1189 10.7848 19.2655 10.9103 19.3354C11.0358 19.4054 11.1929 19.3808 11.291 19.2757L15.666 14.5882C15.7509 14.4973 15.7737 14.3646 15.7241 14.2505C15.6745 14.1363 15.5619 14.0625 15.4375 14.0625H12.4091L13.239 11.0197Z"
        fill="currentColor"
      />
    </g>

    <g v-if="type === 'Scalar'">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6152 1.59493C14.9165 1.76287 15.0643 2.1146 14.9736 2.44734L12.982 9.75H20.25C20.5487 9.75 20.8189 9.92719 20.9379 10.2011C21.0569 10.475 21.0021 10.7934 20.7983 11.0117L10.2983 22.2617C10.063 22.5139 9.68604 22.573 9.38481 22.4051C9.08357 22.2371 8.9357 21.8854 9.02644 21.5527L11.0181 14.25H3.75002C3.45137 14.25 3.18118 14.0728 3.06216 13.7989C2.94313 13.525 2.99795 13.2066 3.20173 12.9883L13.7017 1.73826C13.937 1.48613 14.314 1.42699 14.6152 1.59493Z"
        fill="currentColor"
      />
    </g>

    <g v-if="type === 'DateTime'">
      <path
        d="M12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5C11.5858 13.5 11.25 13.1642 11.25 12.75C11.25 12.3358 11.5858 12 12 12C12.4142 12 12.75 12.3358 12.75 12.75Z"
        fill="currentColor"
      />
      <path
        d="M7.5 15.75C7.91421 15.75 8.25 15.4142 8.25 15C8.25 14.5858 7.91421 14.25 7.5 14.25C7.08579 14.25 6.75 14.5858 6.75 15C6.75 15.4142 7.08579 15.75 7.5 15.75Z"
        fill="currentColor"
      />
      <path
        d="M8.25 17.25C8.25 17.6642 7.91421 18 7.5 18C7.08579 18 6.75 17.6642 6.75 17.25C6.75 16.8358 7.08579 16.5 7.5 16.5C7.91421 16.5 8.25 16.8358 8.25 17.25Z"
        fill="currentColor"
      />
      <path
        d="M9.75 15.75C10.1642 15.75 10.5 15.4142 10.5 15C10.5 14.5858 10.1642 14.25 9.75 14.25C9.33579 14.25 9 14.5858 9 15C9 15.4142 9.33579 15.75 9.75 15.75Z"
        fill="currentColor"
      />
      <path
        d="M10.5 17.25C10.5 17.6642 10.1642 18 9.75 18C9.33579 18 9 17.6642 9 17.25C9 16.8358 9.33579 16.5 9.75 16.5C10.1642 16.5 10.5 16.8358 10.5 17.25Z"
        fill="currentColor"
      />
      <path
        d="M12 15.75C12.4142 15.75 12.75 15.4142 12.75 15C12.75 14.5858 12.4142 14.25 12 14.25C11.5858 14.25 11.25 14.5858 11.25 15C11.25 15.4142 11.5858 15.75 12 15.75Z"
        fill="currentColor"
      />
      <path
        d="M12.75 17.25C12.75 17.6642 12.4142 18 12 18C11.5858 18 11.25 17.6642 11.25 17.25C11.25 16.8358 11.5858 16.5 12 16.5C12.4142 16.5 12.75 16.8358 12.75 17.25Z"
        fill="currentColor"
      />
      <path
        d="M14.25 15.75C14.6642 15.75 15 15.4142 15 15C15 14.5858 14.6642 14.25 14.25 14.25C13.8358 14.25 13.5 14.5858 13.5 15C13.5 15.4142 13.8358 15.75 14.25 15.75Z"
        fill="currentColor"
      />
      <path
        d="M15 17.25C15 17.6642 14.6642 18 14.25 18C13.8358 18 13.5 17.6642 13.5 17.25C13.5 16.8358 13.8358 16.5 14.25 16.5C14.6642 16.5 15 16.8358 15 17.25Z"
        fill="currentColor"
      />
      <path
        d="M16.5 15.75C16.9142 15.75 17.25 15.4142 17.25 15C17.25 14.5858 16.9142 14.25 16.5 14.25C16.0858 14.25 15.75 14.5858 15.75 15C15.75 15.4142 16.0858 15.75 16.5 15.75Z"
        fill="currentColor"
      />
      <path
        d="M15 12.75C15 13.1642 14.6642 13.5 14.25 13.5C13.8358 13.5 13.5 13.1642 13.5 12.75C13.5 12.3358 13.8358 12 14.25 12C14.6642 12 15 12.3358 15 12.75Z"
        fill="currentColor"
      />
      <path
        d="M16.5 13.5C16.9142 13.5 17.25 13.1642 17.25 12.75C17.25 12.3358 16.9142 12 16.5 12C16.0858 12 15.75 12.3358 15.75 12.75C15.75 13.1642 16.0858 13.5 16.5 13.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75 2.25C7.16421 2.25 7.5 2.58579 7.5 3V4.5H16.5V3C16.5 2.58579 16.8358 2.25 17.25 2.25C17.6642 2.25 18 2.58579 18 3V4.5H18.75C20.4069 4.5 21.75 5.84315 21.75 7.5V18.75C21.75 20.4069 20.4069 21.75 18.75 21.75H5.25C3.59315 21.75 2.25 20.4069 2.25 18.75V7.5C2.25 5.84315 3.59315 4.5 5.25 4.5H6V3C6 2.58579 6.33579 2.25 6.75 2.25ZM20.25 11.25C20.25 10.4216 19.5784 9.75 18.75 9.75H5.25C4.42157 9.75 3.75 10.4216 3.75 11.25V18.75C3.75 19.5784 4.42157 20.25 5.25 20.25H18.75C19.5784 20.25 20.25 19.5784 20.25 18.75V11.25Z"
        fill="currentColor"
      />
    </g>

    <g v-if="type === 'QueryString'">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2535 2.29167C19.6447 2.15552 20.0722 2.36227 20.2083 2.75347C21.2033 5.61221 21.75 8.73356 21.75 12C21.75 15.2664 21.2033 18.3878 20.2083 21.2465C20.0722 21.6377 19.6447 21.8445 19.2535 21.7083C18.8623 21.5722 18.6555 21.1447 18.7917 20.7535C19.7313 18.0536 20.25 15.099 20.25 12C20.25 8.90103 19.7313 5.94637 18.7917 3.24653C18.6555 2.85533 18.8623 2.42783 19.2535 2.29167ZM5.02596 2.30479C5.40991 2.46021 5.59517 2.89746 5.43975 3.28141C4.3503 5.97282 3.75 8.91538 3.75 12C3.75 15.0846 4.3503 18.0272 5.43975 20.7186C5.59517 21.1025 5.40991 21.5398 5.02596 21.6952C4.64201 21.8506 4.20477 21.6654 4.04935 21.2814C2.88874 18.4142 2.25 15.2808 2.25 12C2.25 8.71922 2.88874 5.58577 4.04935 2.71859C4.20477 2.33464 4.64201 2.14938 5.02596 2.30479ZM9.30143 7.35695C10.1246 6.85038 11.205 7.24315 11.5106 8.16009L12.2266 10.308L13.601 8.2464C14.2504 7.27228 15.5007 6.89746 16.5789 7.35362L16.7922 7.44389C17.1737 7.60528 17.3521 8.04537 17.1907 8.42684C17.0293 8.80832 16.5892 8.98673 16.2078 8.82534L15.9944 8.73507C15.5797 8.55963 15.0989 8.70379 14.8491 9.07845L12.8275 12.1108L13.9124 15.3656L15.3569 14.4766C15.7097 14.2596 16.1717 14.3695 16.3887 14.7223C16.6058 15.0751 16.4958 15.537 16.1431 15.7541L14.6986 16.6431C13.8754 17.1496 12.795 16.7568 12.4894 15.8399L11.7734 13.692L10.399 15.7536C9.74958 16.7277 8.49934 17.1025 7.42112 16.6464L7.20777 16.5561C6.82629 16.3947 6.64788 15.9546 6.80927 15.5732C6.97067 15.1917 7.41075 15.0133 7.79223 15.1747L8.00558 15.2649C8.42028 15.4404 8.90114 15.2962 9.15091 14.9215L11.1725 11.8892L10.0876 8.63443L8.64307 9.52336C8.2903 9.74045 7.82834 9.63045 7.61126 9.27769C7.39417 8.92492 7.50416 8.46296 7.85693 8.24587L9.30143 7.35695Z"
        fill="currentColor"
      />
    </g>

    <g v-if="type === 'Static'">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 6C2.25 4.34315 3.59315 3 5.25 3H18.75C20.4069 3 21.75 4.34315 21.75 6V18C21.75 19.6569 20.4069 21 18.75 21H5.25C3.59315 21 2.25 19.6569 2.25 18V6ZM6.21967 6.96967C6.51256 6.67678 6.98744 6.67678 7.28033 6.96967L9.53033 9.21967C9.82322 9.51256 9.82322 9.98744 9.53033 10.2803L7.28033 12.5303C6.98744 12.8232 6.51256 12.8232 6.21967 12.5303C5.92678 12.2374 5.92678 11.7626 6.21967 11.4697L7.93934 9.75L6.21967 8.03033C5.92678 7.73744 5.92678 7.26256 6.21967 6.96967ZM10.5 11.25C10.0858 11.25 9.75 11.5858 9.75 12C9.75 12.4142 10.0858 12.75 10.5 12.75H13.5C13.9142 12.75 14.25 12.4142 14.25 12C14.25 11.5858 13.9142 11.25 13.5 11.25H10.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OperandTypeIcon extends Vue {
  @Prop(String) type: string;
}
</script>
