<template>
  <input
    ref="input"
    :class="[
      'bg-transparent',
      'rounded',
      'outline-none',
      'ring-0',
      'ring-gray-900',
      'focus:text-gray-900',
      'focus:bg-white',
      'focus:ring-1',
      'hover:ring-1',
      'px-1',
    ]"
    :size="size"
    type="text"
    @focus="onFocus"
    @change="onChange($event)"
    @input="onInput($event)"
    @keydown.enter="enterPress"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

/*
The idea is to make the size of the input respond to value.
But, we want it to respond as user is typing, without actually emitting to update value.
Hence the tmp variable.

Issues have arisen when user goes back to main page from app, then returns to edit either the same or a different app.

Ahhhh and the width seems to increase doubly for every space entered in the name....hmm.....
Seems to result from fact that different characters ("d" and " ") have different widths....
*/

@Component
export default class InlineInput extends Vue {
  @Prop(String) readonly value: string;
  tmp = "";
  // created() {
  //   this.tmp = this.value;
  //   // console.log("create inline", this.value);
  //   this.$nextTick(() => {
  //     this.setValue(this.value);
  //   });
  // }

  // There is still issue that when developing and make a change, the input disappears....

  @Watch("isLoaded")
  onLoaded(newValue: boolean) {
    // console.log("loaded!", newValue, this.value.length);

    if (newValue) {
      this.tmp = this.value;
      this.setValue(this.value);
    }
  }

  setValue(val: string) {
    if (!this.$refs.input) return;
    (this.$refs.input as HTMLInputElement).value = val;
  }

  @Watch("value")
  onValueChanged(newValue: string) {
    this.setValue(newValue);
  }

  onChange(e: Event) {
    this.$emit("change", (e.target as HTMLInputElement).value);
  }

  onInput(e: Event) {
    this.tmp = (e.target as HTMLInputElement).value;
  }

  onFocus() {
    setTimeout(() => {
      (this.$refs.input as HTMLInputElement).select(); // Selects text
    }, 25);
  }

  enterPress() {
    (this.$refs.input as HTMLInputElement).blur();
  }

  get size() {
    return this.tmp.length;
  }
}
</script>

<style scoped></style>
