<template>
  <div class="flex flex-col space-y-6">
    <!-- shouldModerate toggle -->
    <div>
      <h3>Do you need to moderate this data?</h3>

      <ToggleInputLabelled
        :value="shouldModerate"
        @input="onToggleModerate"
        :dark="true"
      />
    </div>

    <!-- Moderation options (custom radio button): -->
    <div v-if="shouldModerate">
      <h3>How would you like to moderate?</h3>

      <div class="flex space-x-6 mt-3">
        <div
          class="radio-btn"
          :class="[
            moderationMode === 'Approval'
              ? 'border-app-teal'
              : 'border-gray-300',
          ]"
          @click="moderationMode = 'Approval'"
        >
          <div class="flex justify-between mb-2">
            <h4>Require Approval</h4>
            <div
              class="w-4 h-4 rounded-full"
              style="padding: 1px"
              :class="[
                moderationMode === 'Approval' ? 'bg-app-teal' : 'bg-gray-300',
              ]"
            >
              <IconSolid
                name="Checkmark"
                v-if="moderationMode === 'Approval'"
              />
            </div>
          </div>
          <div class="leading-tight text-sm">
            All records are <span class="underline font-bold">excluded</span> by
            default and must be explicitly
            <span class="underline font-bold">approved</span>.
          </div>
        </div>

        <div
          class="radio-btn"
          :class="[
            moderationMode === 'Removal'
              ? 'border-app-teal'
              : 'border-gray-300',
          ]"
          @click="moderationMode = 'Removal'"
        >
          <div class="flex justify-between mb-2">
            <h4>Allow Removal</h4>
            <div
              class="w-4 h-4 rounded-full"
              style="padding: 1px"
              :class="[
                moderationMode === 'Removal' ? 'bg-app-teal' : 'bg-gray-300',
              ]"
            >
              <IconSolid name="Checkmark" v-if="moderationMode === 'Removal'" />
            </div>
          </div>
          <div class="leading-tight text-sm">
            All records are <span class="underline font-bold">included</span> by
            default and must be explicitly
            <span class="underline font-bold">removed</span>.
          </div>
        </div>
      </div>

      <!-- <RadioInput
        v-model="moderationMode"
        :options="moderationModeOptions"
        class="mt-2"
      /> -->
    </div>

    <!-- Select primary key (only required if connection is synced, i.e. not editable by the user): -->
    <div v-if="shouldModerate && !isEditable">
      <h3>Select a column that is a unique identifier for each record</h3>

      <div class="flex space-x-4 mt-2 items-center">
        <div>
          <SelectMenu
            class="text-black w-44 ignore-list"
            :options="schemaColumnOptions"
            placeholder="Choose a column"
            v-model="selectedSchemaColumnUuid"
          />
        </div>
        <div
          class="mt-2 text-sm underline cursor-pointer text-gray-400 font-bold self-start"
          @click="showHelp = !showHelp"
        >
          What does this mean?
        </div>
      </div>

      <!-- Help box: -->
      <div v-if="showHelp" class="box w-1/2 mt-4 p-4">
        <div class="flex justify-between mb-2 font-bold text-lg">
          <div>Why is a unique identifier needed?</div>
          <div
            class="w-5 h-5 text-gray-400 opacity-80 cursor-pointer"
            @click="showHelp = false"
          >
            <Icon name="Close" width="4" />
          </div>
        </div>

        <div class="pr-20">
          <p class="mb-4 leading-tight text-sm">
            To use moderation, your data set must have a column containing a
            unique identifier for each record that will never change.
          </p>

          <p class="leading-tight text-sm">
            We need this to correctly keep track of which records you've
            previously approved (because it's possible the other data in that
            record may have changed since the last sycnhronization).
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col space-y-2">
      <div v-if="errorMessage" class="text-red-500">
        {{ $t("Error.generic") }}
      </div>

      <div>
        <ButtonGradient
          :disabled="selectedSchemaColumnUuid === ''"
          @click="saveChanges"
          class="py-1 text-lg"
        >
          <span v-t="'saveChanges'"></span>
        </ButtonGradient>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ModerationMode } from "@/types/data";
import { Component, Vue, Watch } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import ToggleInputLabelled from "@/components/inputs/ToggleInputLabelled.vue";
import RadioInput from "@/components/inputs/RadioInput.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import { parseModeratedRows } from "@/util/dataUtils";
import { useEditableDataStore } from "@/stores/editableData";
import { useConnectionDataStore } from "@/stores/connectionData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { useAppDataStore } from "@/stores/appData";

// TODO: Warn user if changing moderation mode and already has moderation data -- will get blown away

// TODO: Issues with calendars randomly losing data?? Going to "undefined"
// I wonder if this is because we failed to set primary key??
// TODO: primary key handling

// TODO: Should probably move the "setEditMode" out to Settings itself? or whole modal launch?

// TODO: Translation of underlined text

@Component({
  components: {
    ButtonGradient,
    ToggleInputLabelled,
    RadioInput,
    SelectMenu,
    Icon,
    IconSolid,
  },
})
export default class Moderation extends Vue {
  shouldModerate = false;
  moderationMode: ModerationMode = "Approval";
  showHelp = false;
  selectedSchemaColumnUuid = "";

  get connectionDataStore() {
    return useConnectionDataStore();
  }

  get moderationData() {
    return this.connectionDataStore.moderationDataRows;
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get schema() {
    return this.connection?.nodeSets[0]?.nodes ?? [];
  }

  get errorMessage() {
    return this.connectionEditor.errorMessage;
  }

  get isEditable() {
    return this.connection?.canEditData ?? false;
  }

  setup() {
    if (
      this.connection?.moderationMode !== undefined &&
      this.connection?.moderationMode !== null
    ) {
      this.shouldModerate = true;
      this.moderationMode = this.connection.moderationMode;
    }

    this.setSelectedSchemaColumnUuid();
  }

  /**
   * Use Watcher because it is possible for user to open directly to this view from left-hand data panel,
   * which causes Manager's getConnection to finish loading after this is created.
   *
   * This also fixes bug, where selectedSchemaColumnUuid is computed before new data comes is loaded
   * (as when create new conn and come here immediately via link)
   */
  @Watch("connection.uuid")
  connectionChanged(uuid: string) {
    this.$nextTick(this.setup);
  }

  async created() {
    this.setup();
  }

  get schemaWithoutArtificial() {
    return (this.schema || []).filter((x) => !x.isArtificial);
  }

  setSelectedSchemaColumnUuid() {
    const schema = this.schemaWithoutArtificial;

    const primaryKeyUuid = schema.find((n) => n.isPrimaryKey)?.uuid ?? "";

    this.selectedSchemaColumnUuid = !primaryKeyUuid
      ? (schema[0]?.uuid as string)
      : primaryKeyUuid;
  }

  get schemaColumnOptions() {
    return this.schemaWithoutArtificial.map((node) => {
      return {
        label: node.name,
        value: node.uuid,
      };
    });
  }

  onToggleModerate(value: boolean | null) {
    if (value !== null && this.shouldModerate === value) return;
    this.shouldModerate = value === null ? !this.shouldModerate : value;
  }

  /**
   * We must update the data grid (i.e. set this.rows) here...
   * If they turn OFF moderation, we can maybe just look at widgetData?
   * If they turn ON, we need to set up moderation rows
   */

  async saveChanges() {
    const moderationMode = this.shouldModerate ? this.moderationMode : null;

    this.connectionEditor.setModerationMode(moderationMode);

    const nodes =
      moderationMode === null
        ? this.schema.map((n) => ({ ...n, isPrimaryKey: false }))
        : this.schema.map((n) => {
            return n.uuid === this.selectedSchemaColumnUuid
              ? { ...n, isPrimaryKey: true }
              : { ...n, isPrimaryKey: false };
          });

    await this.connectionEditor.updateDataConnection({
      appUuid: this.$route.params.id,
      nodes,
    });

    // If they turn ON mod mode, or change already-on mode, we want to refresh moderationData (and internal copy of it) here
    if (this.shouldModerate) {
      await this.connectionDataStore.getModerationData({
        dcUuid: this.connection?.uuid as string,
      });

      const moderationData = Array.isArray(this.moderationData)
        ? this.moderationData
        : [];
      this.editableDataStore.moderationFilter = "All";

      /**
       * Don't set rows if dataset is empty
       */
      if (moderationData.length > 0) {
        const rows = parseModeratedRows(moderationData);
        this.editableDataStore.rows = rows;
      }
    }
    // Refresh widget data to get original (un-moderated) data back
    useAppDataStore().invalidateCacheForConnection(this.connection?.uuid || "");
  }
}
</script>

<style lang="postcss" scoped>
h4 {
  @apply font-bold;
}

.radio-btn {
  @apply rounded-lg border p-4 w-1/4 cursor-pointer;
}
</style>
