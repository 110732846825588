import { DataBinding, NodeData, NodeSetData } from "@/types/data";
import { BASE_PARENT_ID } from "@/constants";
import { getActiveConditionId, getActiveWidget } from "@/util/conditionsUtils";
import { getPlaceholderData } from "@/placeholderData";
import { TextContent } from "@/text";
import { populateDataToken } from "@/text/binding";
import { TextOptions } from "@/components/widgets/Text/TextOptions";
import { RepeaterOptions } from "@/components/widgets/Repeater/RepeaterOptions";
import { WidgetDataCache } from "./appData";
import { cleanColorValue, removeReactivity } from "@/utils";
import { ConditionsData } from "@/types/rendererData";
import { AppMode, ResolvedWidgetData, SavedAsset } from "@/types";
import { WidgetWithConditions } from "@/components/widgets/Widget";

export const widgetData = (
  appMode: AppMode,
  widgets: Record<string, WidgetWithConditions>,
  assets: SavedAsset[],
  dataBindings: DataBinding[],
  data: WidgetDataCache,
  conditions: ConditionsData | Record<string, string> | undefined
): ResolvedWidgetData => {
  /**
   * The `result` variable will contain dynamic properties for widgets.
   *
   * It's essentially a lookup table. They key is the widgetId, the value
   * is an array of objects which will be merged into the widget data before
   * it's rendered.
   *
   * We use an array for the value so that the same widget can have different
   * values in a repeater. Each element in the array corresponds to a 'row' in
   * the repeater's dataset.
   *
   * Widgets not in a repeater will only have a single object in the array.
   *
   * ```
   * {
   *   // Below is a widget in a repeater with dynamic backgroundColor
   *
   *   'abc123': [
   *     { backgroundColor: 'red' },
   *     { backgroundColor: 'green' },
   *     { backgroundColor: 'blue' }
   *   ],
   *
   *   // Below is a widget in a repeater with dynamic bg img and border colors
   *
   *   'def456': [
   *     { backgroundImage: 'https://...', borderColor: "hsl(0,0,0)" },
   *     { backgroundImage: 'https://...', borderColor: "hsl(255,0,80)" },
   *     { backgroundImage: 'https://...', borderColor: "hsl(0,0,0)" },
   *   ]
   *
   *   // Below is a widget NOT in a repeater with dynamic background.
   *   // Notice it only has a single value in the array.
   *
   *   'ghi789': [
   *     { backgroundColor: 'red' }
   *   ]
   * }
   * ```
   */
  const result: ResolvedWidgetData = {};
  for (const widgetId in widgets) {
    result[widgetId] = [];
  }

  /**
   * We'll need to loop through databindings multiple times
   * because, when we apply scalar bindings and asset bindings
   * we'll need to know ahead of time how "big" each repeater
   * is, so we can apply the correct data to each widget.
   */
  const repeaterDataSets: Map<string, NodeData[][]> = new Map();

  /**
   * Now we are going to loop through all the databindings and populate the
   * result object with the data for each widget.
   */
  (dataBindings || []).forEach((db) => {
    /**
     * -------------------------------------------------------------
     * STEP 1: Skip if binding points to a widget that doesn't exist.
     * -------------------------------------------------------------
     * It's possible we have data bindings that refer to widgets that
     * no longer exist in state. We already know that at this point
     * `result` has a key for every widget in state.
     */
    if (!result[db.widgetId]) {
      // console.log(
      //   `${db.bindingType} binding references non-existent widget ${db.widgetId}`,
      //   JSON.stringify(db, null, 2)
      // );
      return;
    }

    // // * Skip data (or asset) binding if not associated with active condition for widget.
    // // * Only Asset, Scalar, DataSetNode bindings are given conditionIds.
    // // * Other bindings (DataSet, DataSetParent), at the moment, should NOT be scoped to a condition.
    // if (db.conditionUuid) {
    //   const conditionUuid =
    //     state.appMode === "render"
    //       ? getRenderedConditionId(db.widgetId, conditions)
    //       : useConditionGroupsStore().getActiveConditionId(db.widgetId);
    //   if (db.conditionUuid !== conditionUuid) {
    //     return;
    //   }
    // }

    /**
     * -------------------------------------------------------------
     * STEP 2: Gather dynamic props for all repeater children
     * -------------------------------------------------------------
     */
    if (db.bindingType === "DataSetNode") {
      // If DataState is undefined, skip this binding.
      if (!data) {
        return;
      }

      const key = db.parentWidgetId || "";
      const prop = "data";
      if (!data[key] || !data[key][prop]) {
        return;
      }
      const nodeSet = data[key][prop] as NodeSetData;
      const rows = (nodeSet.children ?? []) as NodeData[][];
      const propsArray: Record<string, unknown>[] = result[db.widgetId];

      rows.forEach((row, index) => {
        let value: any;
        const groupUuid = row[0].groupUuid;

        const childWidget = getActiveWidget(
          widgets[db.widgetId],
          conditions,
          groupUuid
        );

        // Ignore conditional DataSetNode bindings that are not active
        const isBindingActive =
          db.conditionUuid ===
          getActiveConditionId(db.widgetId, conditions, groupUuid);
        if (!isBindingActive && db.conditionUuid) return;

        const isTextContentBinding =
          childWidget.type === "Text" && db.property === "content";

        if (isTextContentBinding) {
          let content = (childWidget as TextOptions).content;

          // Check if we've already stored replaced content into this
          // local widget data, if so we want to use that instaed.
          if (propsArray[index] && propsArray[index]["content"]) {
            content = propsArray[index]["content"] as TextContent;
          }

          value = populateDataToken(content, db, row);
        } else {
          value = row.find((c) => c.uuid === db.dataUuid)?.value;
        }

        // We're going to store _groupUuid so that we can use it later
        // When applying conditional Scalar and Asset bindings.

        const handleNormally = () => {
          if (propsArray.length > 0 && index < propsArray.length) {
            propsArray[index][db.property] = value;
            propsArray[index]["_groupUuid"] = groupUuid;
          } else {
            propsArray.push({
              [db.property]: value,
              _groupUuid: groupUuid,
            });
          }
        };

        const handleSpecially = () => {
          // What if we get index 2 first?
          // Then we need to fill up 0 and 1 with nothing, and insert at index 2.
          if (index < propsArray.length) {
            propsArray[index][db.property] = value;
            propsArray[index]["_groupUuid"] = groupUuid;
          } else {
            for (let i = 0; i < index - propsArray.length; i++) {
              propsArray.push({});
            }
            propsArray.push({
              [db.property]: value,
              _groupUuid: groupUuid,
            });
          }
        };

        if (appMode === "render") {
          if (isTextContentBinding) {
            handleNormally();
          } else {
            handleSpecially();
          }
        } else {
          handleNormally();
        }
      });
    }

    /**
     * -------------------------------------------------------------
     * STEP 3: Bind datasets to Repeaters, Calendars, Graphs, etc
     * -------------------------------------------------------------
     */
    if (data && ["DataSetParent", "DataSet"].includes(db.bindingType)) {
      const key = db.widgetId;
      const prop = db.property || "data";

      // console.log("datasetparent...", data);

      if (!data[key] || !data[key][prop]) {
        // console.log(
        //   `${db.bindingType} binding references non-existent dataset`,
        //   JSON.stringify(db, null, 2)
        // );
        return;
      }

      const d = data[key][prop] as NodeSetData;
      const dataSet = d.children ?? [];

      // Store the data set so we can use it for Scalar and Asset bindings later.
      repeaterDataSets.set(db.widgetId, dataSet as NodeData[][]);

      if (result[db.widgetId].length === 0) {
        // console.log(
        //   "Repeater didn't have any items in dynamicProps array, adding it"
        // );
        result[db.widgetId].push({
          [db.property]: dataSet,
          importedRecordCount: d.importedRecordCount,
        });
      } else {
        // console.log("Repeater had items in dynamicProps array, merging it");
        // Because Calendars, Graphs, and Repeaters are never repeated
        // the dynamicProps array will only ever have a single object
        // So let's merge the `data` property into that object.
        result[db.widgetId][0] = Object.assign({}, result[db.widgetId][0], {
          [db.property]: dataSet,
          importedRecordCount: d.importedRecordCount,
        });
      }
    }
  });

  /**
   * -------------------------------------------------------------
   * STEP 4: Bind Scalar and Asset bindings
   * -------------------------------------------------------------
   * Now that we already know the sizes of every databound repeater
   * in the app, we can loop through the databindings again and
   * populate Asset and Scalar bindings.
   */
  (dataBindings || []).forEach((db) => {
    const widget = widgets[db.widgetId];

    if (!widget) {
      // console.log(
      //   `${db.bindingType} binding references non-existent widget ${db.widgetId}`,
      //   JSON.stringify(db, null, 2)
      // );
      return;
    }

    const dynamicProps = result[db.widgetId];

    /**
     * We need to determine how many times the widget associated with
     * this databinding is going to be repeated, so we can push
     * ensure Scalar and Asset bindings are placed into the
     * dynamicProps array the correct number of times.
     *
     * If the widget is inside a data bound repeater, it will be repeated
     * based on the number of rows in the repeaters dataset.
     *
     * If it is inside a repeater that is NOT data bound, it will be
     * repeated based on the size (rows * columns).
     *
     * If it is not inside a repeater at all, it will not be repeated,
     * just rendered once.
     */

    let repeatCount = 1; // Default to 1, meaning no repeater.
    let repeaterData: NodeData[][] | undefined = undefined;

    if (widget.parentId !== BASE_PARENT_ID) {
      // We should check parent and grandparent because
      // the target widget could be a child of a group
      // inside a repeater.
      let ancestor = widgets[widget.parentId];
      if (ancestor && ancestor.parentId !== BASE_PARENT_ID) {
        ancestor = widgets[ancestor.parentId];
      }

      // Ensure we have the active condition for the ancestor.
      // because number of rows and columns could be different
      // under each condition.
      const ancestorWidget = getActiveWidget(ancestor, conditions);

      if (ancestorWidget.type === "Repeater") {
        const repeater = ancestorWidget as unknown as RepeaterOptions;
        repeaterData = repeaterDataSets.get(repeater.wid);
        repeatCount = repeaterData?.length ?? repeater.rows * repeater.columns;
      }
    }

    if (db.bindingType === "Scalar") {
      for (let i = 0; i < repeatCount; i++) {
        // Represents the dynamic props for this widget at this repeater index.
        const propsObject: Record<string, unknown> = dynamicProps[i] ?? {};

        let isBindingActive = true;

        /**
         * A binding is only active if the conditionUuid matches the
         * active condition for the widget.
         */
        if (db.conditionUuid) {
          const groupUuid = repeaterData?.[i]?.[0]?.groupUuid;
          isBindingActive =
            db.conditionUuid ===
            getActiveConditionId(db.widgetId, conditions, groupUuid);
        }

        if (isBindingActive) {
          let value = null;
          // Use property names "content_{id}" for these properties
          if (widget.type === "Text" && db.property.startsWith("content")) {
            const content = (getActiveWidget(widget, conditions) as TextOptions)
              .content;
            const contentData = data?.[db.widgetId]?.[db.property] as NodeData;
            value = populateDataToken(content, db, contentData);
          } else {
            value = (data?.[db.widgetId]?.[db.property] as NodeData)?.value;
          }
          // Label the populated text content as "content" so text component can pick it up:
          const property = db.property.startsWith("content")
            ? "content"
            : db.property;
          propsObject[property] = value;
        }

        if (i < dynamicProps.length - 1) {
          dynamicProps[i] = propsObject;
        } else {
          dynamicProps.push(propsObject);
        }
      }
    }

    if (db.bindingType === "Asset") {
      for (let i = 0; i < repeatCount; i++) {
        // Represents the dynamic props for this widget at this repeater index.
        const propsObject: Record<string, unknown> = dynamicProps[i] ?? {};

        let isBindingActive = true;

        /**
         * A binding is only active if the conditionUuid matches the
         * active condition for the widget.
         */
        if (db.conditionUuid) {
          const groupUuid = repeaterData?.[i]?.[0]?.groupUuid;
          isBindingActive =
            db.conditionUuid ===
            getActiveConditionId(db.widgetId, conditions, groupUuid);
        }

        if (isBindingActive) {
          const value = assets.find((a) => a.uuid === db.dataUuid)?.url;
          propsObject[db.property] = value;
        }

        if (i < dynamicProps.length - 1) {
          dynamicProps[i] = propsObject;
        } else {
          dynamicProps.push(propsObject);
        }
      }
    }
  });

  /**
   * -------------------------------------------------------------
   * STEP 5: Bind hover background images and placeholder data
   * -------------------------------------------------------------
   * Now that all data-bound properties for widgets have been
   * populated, we can loop through the `results` object and
   *  - Populate any hover background images
   *  - Populate placeholder data for Graphs and Calendars
   *  - Clean up any color values
   */
  for (const widgetId in result) {
    const widget = widgets[widgetId];
    const dynamicProps = result[widgetId];

    if (!widget.type) {
      delete widgets[widgetId];
      continue;
    }

    if (widget.type?.includes("Graph") || widget.type?.includes("Calendar")) {
      const placeholderData = removeReactivity(getPlaceholderData(widget.type));
      if (
        dynamicProps.length > 0 &&
        typeof dynamicProps[0]["data"] === "undefined"
      ) {
        (dynamicProps[0] as any)["data"] = placeholderData;
        (dynamicProps[0] as any)["_placeholderData"] = true;
      } else if (dynamicProps.length === 0) {
        dynamicProps.push({
          data: placeholderData,
          _placeholderData: true,
        });
      }
    }

    dynamicProps.forEach((dp) => {
      Object.keys(dp).forEach((key) => {
        if (key.toLowerCase().includes("color")) {
          dp[key] = cleanColorValue(dp[key]).value;
        }
      });
    });
  }

  return result;
};
