<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-if="steps.length > 0">
    <nav aria-label="Progress">
      <ol
        role="list"
        :class="{ 'rounded-tr-lg rounded-tl-lg': shouldRoundCorners }"
        class="cursor-default select-none border bg-gray-800 border-gray-900 divide-y divide-app-dark2 md:flex md:divide-y-0"
      >
        <li
          v-for="(step, stepIdx) in steps"
          :key="step.name"
          class="relative md:flex-1 md:flex"
        >
          <router-link
            v-if="step.isComplete"
            :to="step.to"
            class="group cursor-pointer flex items-center w-full"
            :class="{ 'pointer-events-none': isWizardComplete }"
          >
            <span class="px-6 py-4 flex items-center font-bold">
              <span
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-app-teal rounded-full group-hover:bg-app-teal group-hover:bg-opacity-80"
              >
                <IconBigCheckmark class="text-app-teal" aria-hidden="true" />
              </span>
              <span class="ml-4 text-gray-500">{{ step.name }}</span>
            </span>
          </router-link>

          <div
            v-else-if="step.isCurrent"
            class="px-6 py-4 flex items-center font-bold"
            aria-current="step"
          >
            <span
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-app-teal rounded-full"
            >
              <span class="text-sm">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-white">{{ step.name }}</span>
          </div>

          <div v-else class="group flex items-center">
            <span class="px-6 py-4 flex items-center font-bold">
              <span
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-500 rounded-full"
              >
                <span class="text-sm text-gray-500">{{ step.id }}</span>
              </span>
              <span class="ml-4 text-gray-500">{{ step.name }}</span>
            </span>
          </div>

          <template v-if="stepIdx !== steps.length - 1">
            <!-- Arrow separator for lg screens and up -->
            <div
              class="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                class="h-full w-full text-black"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";
import { useRoute } from "vue-router/composables";
import { useI18n } from "vue-i18n-composable";
import IconBigCheckmark from "@/components/icons/IconBigCheckmark.vue";
import { APP_EDITOR_ROUTE_PATH } from "@/constants";
import { computed } from "vue";

const route = useRoute();
const { t } = useI18n();
const setupStore = useConnectionSetupStoreV2();

const shouldRoundCorners = computed(() => {
  // Use rounded corners if this is a dashboard, setup route
  return !route.path.startsWith(`/${APP_EDITOR_ROUTE_PATH}`);
});

const isWizardComplete = computed(() => {
  return setupStore?.steps?.find((s) => s.id === "Complete"); // && s.isCurrent);
});

function getStepName(stepName: string, key: string): string {
  switch (stepName) {
    case "selection":
    case "replace":
    case "complete":
      return t(key).toString();
    case "configure":
      return t("Setup.defineConfigure").toString();
    default:
      return key;
  }
}

const steps = computed(() => {
  if (!setupStore || !setupStore.steps) return [];

  return (
    setupStore.steps
      .filter((s) => s.shouldShowInNavigation)
      .map((s, index) => {
        const isCurrentStep = s.id === setupStore.currentStep?.id;
        const currentStepIndex = setupStore.steps!.findIndex(
          (step) => step.id === setupStore.currentStep?.id
        );
        const thisStepIndex = setupStore.steps!.findIndex(
          (step) => step.id === s.id
        );
        const isComplete = thisStepIndex < currentStepIndex;
        return {
          id: (index + 1).toString(),
          name: getStepName(s.id, s.displayNameI18nKey),
          to: { path: s.id, query: route.query },
          isCurrent: isCurrentStep,
          isComplete: isComplete,
          isDisabled: !isCurrentStep && !isComplete,
        };
      }) ?? []
  );
});
</script>
