<template>
  <div class="divide-y divide-app-dark3">
    <div class="text-xl">Preview the first {{ previewCount }} events</div>
    <div class="p-4 text-sm" :key="index" v-for="(e, index) in eventData">
      <div class="font-bold">{{ e.summary }}</div>

      <div v-if="e.isAllDay">ALL DAY</div>

      <div v-if="!e.durationExceedsOneDay">
        <TimeDisplay :value="e.start" />
        <TimeRangeDisplay
          class="ml-2 inline-block"
          :start="e.start"
          :end="e.end"
          :options="{
            hour: 'numeric',
            minute: 'numeric',
            hour12: !use24Hour,
          }"
        />
      </div>

      <div class="text-gray-200" v-if="e.description">
        {{ e.description }}
      </div>
      <div class="text-gray-200" v-if="e.location">
        {{ e.location }}
      </div>
    </div>

    <portal to="setupWizardNext">
      <FormButton v-t="'continue'" @click="onCompleteClick"></FormButton>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ConnectionSourceId } from "@/types/ConnectionSources";
import { NodeData, NodeKind, SchemaNode } from "@/types/data";
import TabInput from "@/components/inputs/TabInput.vue";
import TimeRangeDisplay from "@/components/widgets/TimeRangeDisplay.vue";
import TimeDisplay from "@/components/widgets/TimeDisplay.vue";
import FormButton from "@/components/FormButton.vue";
import { CalendarEvent } from "@/types/calendar";
import { range } from "@/utils";

@Component({
  components: { TabInput, TimeDisplay, TimeRangeDisplay, FormButton },
})
export default class DefineCalendarSchema extends Vue {
  @Prop(String) sourceId: ConnectionSourceId;
  @Prop(String) button: string;
  @Prop(Array) events: SchemaNode[];

  get parentNode() {
    return this.events.find((n) => n.kind === NodeKind.nodeSet);
  }

  get columnNodes() {
    return this.events.filter((n) => n.uuid !== this.parentNode?.uuid) ?? [];
  }

  // created() {
  //   console.log("create calendar schema setup", this.events, this.columnNodes);
  // }

  /**
   * Determines if current browser is set to 24 hour time.
   * https://newbedev.com/javascript-detect-if-user-s-locale-are-set-to-use-12-hour-or-24-hour-time-format
   */
  get use24Hour() {
    return (
      new Intl.DateTimeFormat(undefined, {
        hour: "numeric",
      })
        ?.formatToParts(new Date(2020, 0, 1, 13))
        ?.find((part) => part.type === "hour")?.value.length === 2
    );
  }

  get previewCount() {
    const count = (this.columnNodes?.[0]?.sampleValues ?? []).length;
    return Math.min(5, count);
  }

  /**
   * It would be better if we could just query the backend for this data
   * in a list, rather than constructing it from the sampleValues, but oh well.
   */
  get eventData() {
    return range(0, this.previewCount).map((index) => {
      return new CalendarEvent(
        this.columnNodes.map((n) => {
          return {
            query: n.query,
            value: n.sampleValues?.[index]?.value ?? null,
          } as NodeData;
        })
      );
    });
  }

  onCompleteClick(nodes: SchemaNode[]) {
    this.$emit("complete", this.events);
  }
}
</script>
