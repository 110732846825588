/**
 * It's possible this should be named "collectionDataStore" instead.
 * It deals peripherally with non-editable collection data, via the moderation filter.
 * And the fact that it powers EditDataTable (which is also mis-named, and used for non-editable collections.)
 */

import Vue from "vue";
import { defineStore } from "pinia";
import {
  DataBinding,
  DataConnection,
  DataType,
  ManualDataRow,
  NodeData,
  NodeKind,
  SchemaNode,
} from "@/types/data";
import { isCurrency } from "@/utils";
import { Point } from "@/types";
import { uuidv4 } from "@core/utils/uuid";
import { EventBus } from "@/eventbus";
import { api } from "@/api/backend";
import { useConnectionDataStore } from "./connectionData";
import { Widget } from "@/components/widgets/Widget";
import { useAppDataStore } from "./appData";
import { useAppEditorStore } from "./appEditor";
import { useConnectionEditorStore } from "./connectionEditor";
/**
 *
 * @param value Any value you want to check (except object, array)
 */
export const isEmpty = (value: any): boolean => {
  if (typeof value === "undefined") {
    return true;
  }

  if (value === null) {
    return true;
  }

  if (
    typeof value === "string" &&
    (value.length === 0 || (value.length > 0 && value.trim() === ""))
  ) {
    return true;
  }

  return false;
};

export interface DataTypeInterface {
  name: DataType;
  validate: (val: any) => boolean;
  errorMessage: string;
  getComponent: (val: any, showArea?: boolean) => string;
}

// TODO: Date and Time types... different inputs?
// Is that working now?

export const NodeDataTypes: DataTypeInterface[] = [
  {
    name: "String",
    validate: (val: any) => {
      return true; // Accept anything
    },
    errorMessage: "Text is required.",
    getComponent: (val: string, showArea = false) => {
      if (val?.length > 50 && showArea) {
        return "TextAreaInput";
      }
      return "TextInput";
    },
  },
  {
    name: "Number",
    /**
     * Not sure it makes sense to check type here
     * because we use a number input, and then have to parseInt the value
     * Just to check that it is indeed a number here
     * So just return true
     * (Assumes that all browsers disallow invalid input for number inputs)
     */
    validate: (val: any) => {
      // return typeof val === "number";
      return true;
    },
    errorMessage: "A number is required.",
    getComponent: (val: number) => {
      return isCurrency(val?.toString()) ? "CurrencyInput" : "NumberInput";
    },
  },
  {
    name: "DateTime",
    validate: (val: any) => {
      return true;
    },
    errorMessage: "A datetime is required.",

    getComponent: () => {
      return "DatetimeInput";
    },
  },
  {
    name: "Bool",
    validate: (val: any) => {
      return true;
    },
    errorMessage: "A true/false value is required.",
    getComponent: () => {
      return "BooleanInput";
    },
  },
  {
    name: "ImageUrl",
    validate: (val: any) => {
      return true;
    },
    errorMessage: "An image URL is required.",
    getComponent: () => {
      return "TextInput";
    },
  },
  {
    name: "ImageUpload",
    validate: (val: any) => {
      return true;
    },
    errorMessage: "An valid image file is required.",
    getComponent: () => {
      return "ImageUploadInput";
    },
  },
  {
    name: "Color",
    validate: (val: any) => {
      return val?.match(/^#[0-9A-F]{6,8}$/i);
    },
    errorMessage: "A color is required.",
    getComponent: () => {
      return "ColorInput";
    },
  },
];

export const validateCell = (schemaNode: SchemaNode, value: any) => {
  const { dataType, isRequired } = schemaNode;

  if (isRequired && isEmpty(value)) {
    return false;
  }

  // NOTE: Using includes rather than === to handle Date/Time
  return NodeDataTypes.find((dt) =>
    dt.name.includes(dataType as string)
  )?.validate(value);
};

export const encodeLookupKey = (cell: EditableCellInfo) => {
  const { rowUuid, columnUuid } = cell;
  return `${rowUuid},${columnUuid}`;
};

export const decodeLookupKey = (key: string) => {
  const [rowUuid, columnUuid] = key.split(",");
  return { rowUuid, columnUuid };
};

export const EMPTY_ROW_UUID = "empty";

export interface EditableCellInfo {
  rowUuid: string;
  columnUuid: string;
}

interface SchemaPostResult extends DataConnection {
  appDataBindings: DataBinding[] | null;
}

export type ModerationFilter = "All" | "Selected" | "Deselected";

// export enum ModerationFilter {
//   All = 1,
//   Selected = 2,
//   Deselected = 3,
// }

const createRow = (
  schema: SchemaNode[],
  insertValue?: Partial<NodeData>
): ManualDataRow => {
  return {
    rowUuid: uuidv4(), // Generate a valid uuid
    // isNew: true, // Use this for painting cells green. But also can be used when posting data to backend
    columns: schema.map((node) => {
      const { uuid, name } = node;
      const value = insertValue?.uuid === uuid ? insertValue?.value : "";
      return {
        ...node,
        displayName: name,
        value: value as any,
        formattedValue: value as string,
        uuid: node.uuid || null,
      };
    }),
  };
};

interface EditableDataState {
  editingRowUuid: string;
  editingCell: EditableCellInfo;
  modificationsLookup: Record<string, boolean>;
  deletedRowsLookup: Record<string, boolean>;
  addedRowsLookup: Record<string, boolean>;
  validationErrorsLookup: Record<string, boolean>;
  rows: ManualDataRow[];
  rowsInitialState: ManualDataRow[];
  searchText: string;
  applyModificationsFilter: boolean;
  applyValidationErrorsFilter: boolean;
  emptyRowValue: any;

  errorMessage: string;
  showFixValidationErrorsMessage: boolean;
  dropdownPosition: Point;
  showConfirmCloseModal: boolean;
  showMoveRowModal: boolean;
  moveRowUuid: string;
  showModal: boolean;
  dropdownOpenRowUuid: string;

  dataManagerTabIndex: number;
  settingsTabIndex: number;
  showDisconnectSuccessMessage: boolean;
  moderationFilter: ModerationFilter;
}

const makeInitialState = () => {
  return {
    showModal: false,
    dropdownOpenRowUuid: "",
    editingRowUuid: "",
    editingCell: {
      rowUuid: "",
      columnUuid: "",
    },
    modificationsLookup: {},
    deletedRowsLookup: {},
    addedRowsLookup: {},
    validationErrorsLookup: {},
    rows: [],
    searchText: "",
    rowsInitialState: [],
    applyModificationsFilter: false,
    applyValidationErrorsFilter: false,
    dropdownPosition: { x: 0, y: 0 },
    showFixValidationErrorsMessage: false,
    emptyRowValue: null,
    errorMessage: "",
    showConfirmCloseModal: false,
    showMoveRowModal: false,
    moveRowUuid: "",
    dataManagerTabIndex: 0,
    settingsTabIndex: 0,
    showDisconnectSuccessMessage: false,
    moderationFilter: "All" as ModerationFilter,
  };
};

export const useEditableDataStore = defineStore("editableData", {
  state: (): EditableDataState => {
    return makeInitialState();
  },

  getters: {
    /**
     *
     * Scope text searches to current moderation filter/bucket
     * If errors filter, show all errors
     *
     */
    filteredRows(): ManualDataRow[] {
      if (this.applyValidationErrorsFilter) {
        return this.validationErrorRows;
      }

      let rows = this.rows;

      if (this.moderationFilter === "Selected") {
        rows = this.moderationSelectedRows;
      }
      if (this.moderationFilter === "Deselected") {
        rows = this.moderationDeselectedRows;
      }

      if (this.searchText === "") {
        return rows || [];
      }

      // Apply search text filter:
      return rows.slice(0).filter((row) => {
        return row.columns.some((node) =>
          node.value
            ?.toString()
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        );
      });
    },

    editingRow(): ManualDataRow | null {
      if (!this.editingRowUuid) return null;
      return this.rows.find(
        (row) => row.rowUuid === this.editingRowUuid
      ) as ManualDataRow;
    },

    rowIndex(): number {
      return this.filteredRows.findIndex(
        (r: ManualDataRow) => r.rowUuid === this.editingRowUuid
      );
    },

    /**
     * Remove deleted rows from addedRows, modifiedRows, for upsertRequest
     */
    addedRows(): ManualDataRow[] {
      const deletedRowUuids: string[] = Object.keys(this.deletedRowsLookup);
      const addedRowUuids: string[] = Object.keys(this.addedRowsLookup);

      return this.rows.filter(
        (r) =>
          addedRowUuids.includes(r.rowUuid) &&
          !deletedRowUuids.includes(r.rowUuid)
      );
    },

    modifiedRows(): ManualDataRow[] {
      const deletedRowUuids: string[] = Object.keys(this.deletedRowsLookup);
      const modifiedCellKeys: string[] = Object.keys(this.modificationsLookup);
      const modifiedRowUuids = Array.from(
        new Set(modifiedCellKeys.map((key) => decodeLookupKey(key).rowUuid))
      );

      return this.rows.filter(
        (r) =>
          modifiedRowUuids.includes(r.rowUuid) &&
          !deletedRowUuids.includes(r.rowUuid)
      );
    },

    deletedRows(): ManualDataRow[] {
      const deletedRowUuids: string[] = Object.keys(this.deletedRowsLookup);
      return this.rows.filter((r) => deletedRowUuids.includes(r.rowUuid));
    },

    validationErrorRows(): ManualDataRow[] {
      const errorCellKeys: string[] = Object.keys(this.validationErrorsLookup);
      const deletedRowUuids: string[] = Object.keys(this.deletedRowsLookup);

      const errorRowUuids = Array.from(
        new Set(errorCellKeys.map((key) => decodeLookupKey(key).rowUuid))
      );

      return (this.rows || []).filter(
        (r) =>
          !deletedRowUuids.includes(r.rowUuid) &&
          errorRowUuids.includes(r.rowUuid)
      );
    },

    // Do not include deleted rows here, because count is filter button. So is weird to exclude from count but show in filter.
    validationErrorsCount(): number {
      const errorCellKeys: string[] = Object.keys(this.validationErrorsLookup);
      const deletedRowUuids: string[] = Object.keys(this.deletedRowsLookup);
      return errorCellKeys.filter((key) => {
        const { rowUuid } = decodeLookupKey(key);
        return !deletedRowUuids.includes(rowUuid);
      }).length;
    },

    hasUnsavedChanges(): boolean {
      return (
        Object.keys(this.deletedRowsLookup).length > 0 ||
        Object.keys(this.addedRowsLookup).length > 0 ||
        Object.keys(this.modificationsLookup).length > 0
      );
    },

    moderationSelectedRows(): ManualDataRow[] {
      return this.rows.filter(
        (r) => this.rows.find((x) => x.rowUuid === r.rowUuid)?.isSelected
      );
    },

    moderationDeselectedRows(): ManualDataRow[] {
      return this.rows.filter(
        (r) => !this.rows.find((x) => x.rowUuid === r.rowUuid)?.isSelected
      );
    },
  },

  actions: {
    clearFilters() {
      this.searchText = "";
      this.applyModificationsFilter = false;
    },

    setRowsInitialState(rows: ManualDataRow[]) {
      if (!rows) return;
      // Not sure about this..
      if (rows.length === 0) {
        this.rowsInitialState = [];
      }
      this.rowsInitialState = JSON.parse(JSON.stringify(rows));
      this.rows = rows;
    },

    clearState() {
      Object.assign(this, makeInitialState());
    },

    clearLookups() {
      Vue.set(this, "modificationsLookup", {});
      Vue.set(this, "validationErrorsLookup", {});
      Vue.set(this, "addedRowsLookup", {});
      Vue.set(this, "deletedRowsLookup", {});
    },

    setModerationDataRowSelected(payload: { refUuid: string; value: boolean }) {
      const { refUuid, value } = payload;
      const row = this.rows.find((n) => n.rowUuid === refUuid) as ManualDataRow;
      Vue.set(row, "isSelected", value);
      // We want to set this node as modified, so that hasUnsavedChanges becomes true
      const key = encodeLookupKey({ rowUuid: refUuid, columnUuid: "none" });
      Vue.set(this.modificationsLookup, key, true);
    },

    addRowAtIndex(payload: { row: ManualDataRow; index: number }) {
      const { row, index } = payload;
      this.rows.splice(index, 0, row);
    },

    addToAddedRows(uuid: string) {
      Vue.set(this.addedRowsLookup, uuid, true);
    },

    removeRow(uuid: string) {
      Vue.set(this.deletedRowsLookup, uuid, true);
    },

    undoRemoveRow(uuid: string) {
      Vue.delete(this.deletedRowsLookup, uuid);
    },

    clearModifications() {
      Vue.set(this, "modificationsLookup", {});
    },

    addValidationError(key: string) {
      Vue.set(this.validationErrorsLookup, key, true);
    },

    clearValidationErrors() {
      Vue.set(this, "validationErrorsLookup", {});
    },

    setNodeValue(payload: {
      rowUuid: string;
      columnUuid: string;
      value: any;
      formattedValue?: any;
      assetUuid?: any;
    }) {
      const { rowUuid, columnUuid, value } = payload;
      const row = this.rows.find((r) => r.rowUuid === rowUuid);
      const node = row?.columns.find((c) => c.uuid === columnUuid) as NodeData;

      Vue.set(node, "value", value); // Data grid inputs rely on this
      Vue.set(node, "formattedValue", payload.formattedValue ?? value); // Used for currency
      if (payload.assetUuid) {
        Vue.set(node, "assetUuid", payload.assetUuid); //Used for ImageUpload
      }
    },

    clearEditingCell(payload: {
      rowUuid: string;
      columnUuid: string;
      value?: any;
      schema: SchemaNode[];
    }) {
      const { rowUuid, columnUuid, schema } = payload;

      // Check needed because this is called on every click...Yikes should fix that
      if (!columnUuid) {
        return;
      }

      if ((rowUuid === EMPTY_ROW_UUID || !rowUuid) && !payload.value) {
        this.editingCell.columnUuid = "";
        this.editingCell.rowUuid = "";
        return;
      }

      // TODO: should be in fn
      const value =
        payload.value && rowUuid == EMPTY_ROW_UUID
          ? payload.value
          : this.rows
              .find((r) => r.rowUuid === rowUuid)
              ?.columns.find((node) => node.uuid === columnUuid)?.value;
      const initialValue = this.rowsInitialState
        .find((r) => r.rowUuid === rowUuid)
        ?.columns.find((node) => node.uuid === columnUuid)?.value;

      const cellKey = encodeLookupKey({ rowUuid, columnUuid });

      // VALIDATE DATA
      const schemaNode = schema.find(
        (n) => n.uuid === columnUuid
      ) as SchemaNode;
      const isValid = validateCell(schemaNode, value);

      if (!isValid) {
        Vue.set(this.validationErrorsLookup, cellKey, true);
      } else {
        Vue.delete(this.validationErrorsLookup, cellKey);
      }

      // We check against snapshot of initial state to calculate number of modifications
      if (value === initialValue) {
        // Must remove from modifications if there
        if (cellKey in this.modificationsLookup) {
          Vue.delete(this.modificationsLookup, cellKey);
        }
      } else {
        // Undefined check has result that new additions (anything not present in original dataset) are ignored
        if (initialValue !== undefined) {
          Vue.set(this.modificationsLookup, cellKey, true);
        }
      }

      this.editingCell.columnUuid = "";
      this.editingCell.rowUuid = "";
    },

    moveRowToPosition(payload: { rowUuid: string; position: number }) {
      const { rowUuid, position } = payload;
      const startIdx = this.rows.findIndex((r) => r.rowUuid === rowUuid);
      const row = this.rows.splice(startIdx, 1)[0];
      this.rows.splice(position, 0, row);
      const key = encodeLookupKey({ rowUuid, columnUuid: "none" });
      Vue.set(this.modificationsLookup, key, true);
    },

    insertRow(payload: { position: "above" | "below"; rowUuid: string }) {
      const { position, rowUuid } = payload;
      const rowIdx = this.filteredRows.findIndex(
        (r: ManualDataRow) => r.rowUuid === rowUuid
      );
      const idx = position === "above" ? rowIdx : rowIdx + 1;
      const connectionEditor = useConnectionEditorStore();
      if (connectionEditor.schema !== null) {
        const schema = connectionEditor.schema;
        const row = createRow(schema);
        this.addToAddedRows(row.rowUuid);
        this.addRowAtIndex({ row, index: idx });
        return row.rowUuid;
      }
    },

    createRowWithNode(node: Partial<NodeData>) {
      const connectionEditor = useConnectionEditorStore();
      if (connectionEditor.schema !== null) {
        const schema = connectionEditor.schema;
        const row = createRow(schema, node);
        const newRows = [...(this.rows || []), row];
        this.rows = newRows;
        this.addToAddedRows(row.rowUuid);
        return row.rowUuid;
      }
    },

    adjustEditingRowIndex(direction: "inc" | "dec") {
      const adjustValue = direction === "inc" ? 1 : -1;
      let currIdx = this.rowIndex;
      let newRowUuid = "";

      /**
       * Skip deleted rows
       */
      while (true) {
        const nextIdx =
          (currIdx + this.filteredRows.length + adjustValue) %
          this.filteredRows.length;

        const { rowUuid } = this.filteredRows.find(
          (r: ManualDataRow, idx: number) => idx === nextIdx
        ) as ManualDataRow;
        currIdx = nextIdx;
        if (!Object.keys(this.deletedRowsLookup).includes(rowUuid)) {
          newRowUuid = rowUuid;
          break;
        }
      }

      this.editingRowUuid = newRowUuid;
    },

    incrementEditingRowIndex() {
      this.adjustEditingRowIndex("inc");
    },

    decrementEditingRowIndex() {
      this.adjustEditingRowIndex("dec");
    },

    // ================================================================================

    async upsertManualTableData(args: { dcUuid: string; widgetIds: string[] }) {
      const connectionEditor = useConnectionEditorStore();
      connectionEditor.clearErrors();
      EventBus.emit("AWAITING_SERVER", true);
      connectionEditor.isLoading = true;

      const { dcUuid, widgetIds } = args;

      const addedRows = [...this.addedRows].map((row: ManualDataRow) => {
        return { ...row, action: "insert" };
      });
      const modifiedRows = [...this.modifiedRows].map((row: ManualDataRow) => {
        return { ...row, action: "update" };
      });
      const deletedRows = [...this.deletedRows].map((row: ManualDataRow) => {
        return { ...row, action: "delete" };
      });

      const alteredRows = [...addedRows, ...modifiedRows, ...deletedRows];

      const rows = alteredRows.map((row: any) => {
        const index = this.rows.findIndex((r) => r.rowUuid === row.rowUuid);
        return { ...row, index };
      });

      const payload = {
        rows,
      };

      // console.log(
      //   "======= UPSERTING MANUAL ROWS =======",
      //   payload,
      //   JSON.stringify(payload),
      //   alteredRows.length,
      //   widgetIds,
      //   appUuid
      // );

      /**
       * This endpoint throws an error if you call it without any rows in the payload.
       * This case can occur when this method is called from the SaveButton,
       * and the user has only made changes to moderation selections for rows.
       */
      if (alteredRows.length > 0) {
        await api.post(`dataconnection/${dcUuid}/manualtabledata`, payload);

        // Let connectionDataStore know about changes so RecordView (in left hand DataMenu) can reflect changes
        const connectionDataStore = useConnectionDataStore();
        connectionDataStore.fetchConnectionData({ connectionId: dcUuid });
      }

      // WidgetIds might not be available if edited from dashboard.
      if (widgetIds && widgetIds.length > 0) {
        // Ensures data fetches for the widget
        useAppDataStore().invalidateCacheForConnection(dcUuid);
      }
    },

    // This would get called from both of below, which would get exposed to outer world
    // and may in turn call a series of internal functions, such as convertToManualTableData
    async createEditableConnection(payload: {
      dataRows: any[];
      columnSchema: any[];
      type: "disconnect" | "create";
      appUuid: string;
      connectionName?: string;
      widget?: Widget | undefined;
      convertFromConnectionUuid?: string;
    }) {
      const {
        dataRows,
        columnSchema,
        type,
        appUuid,
        connectionName,
        widget,
        convertFromConnectionUuid,
      } = payload;
      let name = `${connectionName} - Manual`;
      if (type === "create" && widget) {
        name = `${widget.type} Data`;
      }
      const postPayload: any = {
        source: "ManualSource",
        name: name,
        nodes: columnSchema,
        rows: dataRows,
      };
      // If disconnecting from a widget, pass that info along
      if (widget && type === "disconnect") {
        postPayload.convertForWidgetId = widget.wid;
        postPayload.appUuid = appUuid;
      }
      if (type === "disconnect") {
        postPayload.convertFromConnectionUuid = convertFromConnectionUuid;
      }

      // post to endpoint
      const newConnection: SchemaPostResult = await api.post(
        "dataconnection/manualtabledata/schema",
        postPayload
      );
      // init connection
      const store = useConnectionDataStore();
      return store.initializeConnection(newConnection).then(async () => {
        // either replace or create data binding

        if (type === "disconnect") {
          this.replaceDataBinding(newConnection);
        } else if (type === "create") {
          this.createDataBinding(newConnection, widget);
        }

        await store.refreshConnectionData({
          connectionId: newConnection.uuid,
          isModerated: newConnection.moderationMode !== null,
        });
        return newConnection;
      });
    },

    replaceDataBinding(connection: SchemaPostResult) {
      const { nodeSets, appDataBindings } = connection;
      // NOTE: Must strip off the sf_index property here!
      const schema = (nodeSets[0].nodes || []).filter(
        (n: any) => !n.isArtificial
      );
      const appEditor = useAppEditorStore();
      appEditor.replaceDataBindings({ bindings: appDataBindings });

      const connectionEditor = useConnectionEditorStore();
      connectionEditor.schema = schema;
    },

    createDataBinding(
      connection: SchemaPostResult,
      widget: Widget | undefined
    ) {
      const { uuid, nodeSets } = connection;
      // NOTE: Must strip off the sf_index property here!
      const schema = nodeSets[0].nodes?.filter((n) => !n.isArtificial);
      // console.log("schema", schema);
      if (widget) {
        const bindingType =
          widget.type === "Repeater" ? "DataSetParent" : "DataSet";
        const binding: DataBinding = {
          widgetId: widget.wid,
          property: "data",
          dataUuid: nodeSets[0].uuid as string,
          bindingType,
          dataConnectionUuid: uuid,
        };

        const appEditor = useAppEditorStore();
        appEditor.addDataBinding(binding);

        const connectionEditor = useConnectionEditorStore();
        connectionEditor.setConnection(connection);
        connectionEditor.schema = schema;
      }
    },

    async disconnectAndCreateEditableConnection(args: {
      appUuid: string;
      convertFromConnectionUuid: string;
      name: string;
      widget: Widget | undefined;
    }) {
      const connectionEditor = useConnectionEditorStore();
      connectionEditor.clearErrors();
      connectionEditor.isLoading = true;

      const { appUuid, convertFromConnectionUuid, name, widget } = args;

      // Convert the data to "manaul table data" form:
      const data: { rows: ManualDataRow[]; schema: SchemaNode[] } =
        await api.get(
          `dataconnection/${convertFromConnectionUuid}/convertto/manualtabledata`
        );

      const { rows, schema } = data;

      const dataRows = rows.map((r, i) => {
        return {
          index: i,
          isSelected: !!r.isSelected,
          columns: r.columns,
        };
      });

      // Need to NOT take the first one, which is NodeSet -- and also want to avoid any PrimitiveArrays or ObjectArrays
      // And we also want to strip off "Name", "Email" and "ResponseStatus" nodes from disconnected Calendar schemas
      // We use slice(1) because the FIRST item in the schema is the entire nodeset, and all first-level nodes have that as parentUuid
      const columnSchema = schema
        .map((n) => {
          return { ...n, columnId: n.uuid }; // Avoids the "invalid columnId error"
        })
        .filter(
          (n) =>
            !schema
              .slice(1)
              .map((x) => x.uuid)
              .includes(n.parentUuid)
        )
        .filter((n) => n.kind === NodeKind.node);

      return this.createEditableConnection({
        dataRows,
        columnSchema,
        type: "disconnect",
        appUuid,
        widget,
        connectionName: name,
        convertFromConnectionUuid,
      });
    },

    createEditableConnectionFromPlaceholderData(args: {
      appUuid: string;
      widget?: Widget | undefined;
    }) {
      const connectionEditor = useConnectionEditorStore();
      connectionEditor.clearErrors();
      connectionEditor.isLoading = true;
      EventBus.emit("AWAITING_SERVER", true);

      const { appUuid, widget } = args;
      const { rows, deletedRowsLookup } = this;
      const schema: SchemaNode[] = connectionEditor.schema;

      const dataRows = rows
        ?.filter((r) => !Object.keys(deletedRowsLookup).includes(r.rowUuid))
        .map((r, i) => {
          // NOTE: strip off rowUuid here
          return {
            index: i,
            isSelected: !!r.isSelected, // Pass moderation selection info
            columns: r.columns.map((n) => {
              const { value, uuid } = n;
              // console.log("create new node", uuid, n);
              return {
                formattedValue: value, // TODO: IS this wrong?
                query: uuid, // NOTE: This must be query, not columnId
              };
            }),
          };
        });

      const columnSchema = schema.map((n) => {
        const { name, dataType, uuid, isRequired } = n;
        return {
          name,
          dataType,
          query: uuid,
          isSelected: true, // This is needed
          isRequired: isRequired || true,
        };
      });

      return this.createEditableConnection({
        dataRows,
        columnSchema,
        type: "create",
        appUuid,
        widget,
      });
    },
  },
});
