import {
  Widget,
  WidgetProperties,
  WidgetWithConditions,
} from "@/components/widgets/Widget";
import { DEFAULT_CONDITION_ID } from "@/constants";
import { DataBindingType } from "@/types/data";
import { ConditionsData } from "@/types/rendererData";
import omit from "lodash.omit";

export const isConditionalBindingType = (type: DataBindingType): boolean => {
  return ["Asset", "DataSetNode", "Scalar"].includes(type);
};

/**
 * Takes a `WidgetWithCondtions` and returns a `Widget` which
 * will have the props from the active condition.
 *
 * The props returned here do NOT have their dynamic values resolved.
 *
 * You'll need to merge this with `widgetData` in a separate step.
 *
 * @param widget The stored `WidgetWithConditions`
 * @param conditions This can be either the `ConditionsData` object (from RenderData) or the `Record<string, string>` object from (conditionGroupStore)
 * @param groupUuid The groupUuid of the current row of the repeater data set (if it has one)
 */
export const getActiveWidget = (
  widget: WidgetWithConditions,
  conditions: ConditionsData | Record<string, string> | undefined,
  groupUuid: string | undefined = undefined
): Widget => {
  if (!widget) return {} as Widget;

  if (widget.conditionalVersions === undefined) {
    return widget as unknown as Widget;
  }

  if (conditions === undefined) {
    console.error("getActiveWidget called with undefined conditions");
    return widget as unknown as Widget;
  }

  const activeConditionId = getActiveConditionId(
    widget.wid,
    conditions,
    groupUuid
  );

  const source = omit(widget, "conditionalVersions");
  const props =
    widget.conditionalVersions[activeConditionId] ??
    widget.conditionalVersions[DEFAULT_CONDITION_ID];

  return {
    ...source,
    ...props,
  };
};

export const getActiveConditionId = (
  widgetId: string,
  conditions: ConditionsData | Record<string, string> | undefined,
  groupUuid?: string
): string => {
  if (conditions === undefined) {
    console.error("getActiveWidget called with undefined conditions");
    return DEFAULT_CONDITION_ID;
  }

  // If conditions object is provided, it means we are in the renderer
  if (conditions?.widgets !== undefined) {
    const widgets = (conditions as ConditionsData).widgets;
    const conditionInfo = widgets[widgetId];

    if (typeof conditionInfo?.conditionUuid === "string") {
      return conditionInfo.conditionUuid;
    }

    if (typeof conditionInfo?.recordCondRef === "string") {
      const recordCondRef = (conditions as ConditionsData)
        .recordCondRefToRecordCondition[conditionInfo.recordCondRef];

      return recordCondRef[groupUuid as string] ?? DEFAULT_CONDITION_ID;
    }
  } else if (widgetId in conditions) {
    return (conditions as Record<string, string>)[widgetId];
  }

  return DEFAULT_CONDITION_ID;
};

/**
 * Converts a `Widget` into a `WidgetWithCondtions`.
 *
 * This modifies the widget by reference. It does NOT return a new object.
 */
export const addConditionalVersions = (wg: Widget) => {
  if ("conditionalVersions" in wg) {
    return;
  }

  const wc = wg as unknown as WidgetWithConditions;
  const props: WidgetProperties = {};

  for (const prop in wg) {
    if (
      prop === "wid" ||
      prop === "parentId" ||
      prop === "locked" ||
      prop === "type"
    ) {
      continue;
    }
    const val = wg[prop as keyof Widget];
    if (typeof val === "object") {
      props[prop] = JSON.parse(JSON.stringify(val));
    } else {
      props[prop] = val;
    }
    delete (wg as any)[prop];
  }
  wc.conditionalVersions = {
    [DEFAULT_CONDITION_ID]: props,
  };
};

/**
 * Serves similar purpose to getActiveConditionId, from the pinia store.
 * However, this is used in renderer, not in the app editor.
 * Uses info from backend about which condition is active at a given time, for each widget.
 */
export const getRenderedConditionId = (
  widgetId: string,
  conditions: ConditionsData | undefined,
  groupUuid?: string
) => {
  let activeConditionId = DEFAULT_CONDITION_ID;

  if (
    !conditions ||
    typeof conditions !== "object" ||
    typeof conditions.widgets === "undefined"
  ) {
    return activeConditionId;
  }

  const conditionInfo = conditions.widgets[widgetId];

  if (typeof conditionInfo?.conditionUuid === "string") {
    activeConditionId = conditionInfo.conditionUuid;
  }

  if (typeof conditionInfo?.recordCondRef === "string") {
    const recordCondRef =
      conditions.recordCondRefToRecordCondition[conditionInfo.recordCondRef];

    activeConditionId =
      recordCondRef[groupUuid as string] ?? DEFAULT_CONDITION_ID;
  }

  return activeConditionId;
};
