<template>
  <div class="shadow-xl p-4 flex flex-col items-center text-xl" :style="styles">
    <div
      class="control-box rounded relative flex flex-wrap bg-gray-200 p-4 pr-20 leading-normal"
    >
      <div
        class="bottom-0 right-0 mr-2 mb-2 rounded bg-white w-16 text-center text-white bg-blue-500 absolute px-1"
      >
        <button class="apply-btn" @click="applyChange">Apply</button>
      </div>

      <div v-if="!useRelativeTime" class="w-full flex flex-wrap">
        <div v-for="(option, i) in customDtf" :key="i">
          <component
            :is="getComponentType(option)"
            :type="option.type"
            :value="option.value"
            :hour12="option.hour12"
            :index="i"
            @inputChange="handleInput($event)"
          />
        </div>

        <div class="top-0 right-0 mr-2 mt-2 rounded absolute">
          <div
            class="add-container w-6 h-6 bg-gray-400"
            @click="showFields = !showFields"
          >
            <svg
              v-if="!showFields"
              height="100%"
              width="100%"
              viewBox="0 0 16 16"
            >
              <line x1="3" y1="8" x2="13" y2="8" stroke="black" />
              <line y1="3" x1="8" y2="13" x2="8" stroke="black" />
            </svg>
            <svg
              v-if="showFields"
              height="100%"
              width="100%"
              viewBox="0 0 16 16"
            >
              <line x1="3" y1="3" x2="13" y2="13" stroke="black" />
              <line y1="13" x1="3" y2="3" x2="13" stroke="black" />
            </svg>
          </div>
          <div
            class="add-options absolute bg-white shadow-xl"
            v-if="showFields"
          >
            <div
              class="add-field hover:bg-gray-200 px-2 py-1 cursor-pointer"
              v-for="(field, i) in possibleFields"
              :key="i"
              @click="addField(field)"
            >
              {{ capitalize(field) }}
            </div>
          </div>
        </div>
        <!-- end add field modal -->
      </div>

      <div v-if="useRelativeTime">
        Relative format:
        <div>
          <select @input="changeRelativeParams">
            <option
              v-for="(option, i) in relativeOptions"
              :key="i"
              :value="option"
              :selected="option === relativeParam"
              >{{ capitalize(option) }}</option
            >
          </select>
        </div>
      </div>
    </div>

    <div class="my-8 p-3 rounded bg-gray-200">{{ preview }}</div>

    <div class="flex items-center">
      <input class="mr-1" type="checkbox" v-model="useCurrentTime" />
      Use current time
    </div>

    <div v-if="!useCurrentTime" class="bg-gray-200 rounded p-4 my-1 relative">
      Enter a date-time:
      <input
        type="text"
        class="ml-1"
        @change="newCustomTime($event)"
        value="7/20/2020 6:00 PM"
      />

      <div class="flex items-center">
        <input class="mr-1" type="checkbox" v-model="useRelativeTime" />
        Relative time
      </div>

      <div
        class="bottom-0 right-0 mr-2 mb-2 rounded w-16 text-center text-white bg-blue-500 absolute px-1"
      >
        <button class="apply-btn" @click="applyChange">Apply</button>
      </div>
    </div>

    <div class="preset-container overflow-scroll">
      <div
        v-for="(options, i) in datetimePresetOptions"
        class="preset p-1 hover:bg-gray-100 hover:opacity-50 cursor-pointer"
        :key="i"
        :class="getClass(options)"
        @click="changePreset(options)"
      >
        {{ getDisplay(options) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  datetimePresets,
  sampleDate,
  dateBasePreset,
  timeBasePreset
} from "@/datetimeFormats";
import {
  dtPresetToOptions,
  dtOptionsToString,
  relativeTimeFormat
} from "@/datetimeUtils";
import DatetimeFormatTextInput from "@/components/inputs/DatetimeFormatTextInput.vue";
import DatetimeFormatSelect from "@/components/inputs/DatetimeFormatSelect.vue";
import { capitalize } from "@/utils";
import { DateTime } from "luxon";
import { DatetimeOption } from "@/types/DateTimeOption";

// - [ ] TODO: want to (un)check currentTime checkbox on mount

// TODO: Probably want to dim rest of screen behind modal
// TODO: Also add "click outside to close" to add fields "modal"
// TODO: we should highlight the preset immediately, if they reopen modal after changing dtf

// - [ ] TODO: "Enter datetime" text input needs to pull value from existing userCustomTime, if exists

// Possibly a "use decimal" option ("round")...possibly want to "commatize" big numbers
// TODO: The clock icon could depend on real time!?

@Component({
  components: {
    DatetimeFormatSelect,
    DatetimeFormatTextInput
  }
})
export default class DatetimeFormatInput extends Vue {
  @Prop() readonly datetimeFormat: DatetimeOption[];
  @Prop() readonly customFormatOptions: DatetimeOption[][];

  @Watch("useCurrentTime")
  toggleCurrent(useCurrent: boolean) {
    if (useCurrent) {
      this.useRelativeTime = false;
    }
  }

  relativeOptions = [
    "default",
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds"
  ];

  changeRelativeParams(ev: any) {
    // console.log(ev.target.value);
    this.relativeParam = ev.target.value;
  }

  relativeParam = "default";
  sampleCustom = "7/20/2020 6:00 PM";
  useCurrentTime = true;
  useRelativeTime = false;
  userCustomTime: Date = new Date(Date.parse(this.sampleCustom));

  capitalize = capitalize;

  customDtf: DatetimeOption[] = [];
  possibleFields = [
    "second",
    "minute",
    "hour",
    "day",
    "month",
    "year",
    "dayPeriod"
  ];
  activePreset: any = null;
  showFields = false;
  hasChanged = false;

  // TODO: Probably have to make it obvious with a button, rather than relying on the user pressing enter
  newCustomTime(ev: any) {
    // console.log("new time:", ev.target.value);
    this.userCustomTime = new Date(Date.parse(ev.target.value));
  }

  mounted() {
    const dtf = this.datetimeFormat[0];

    if (dtf.type === "timeStyle" || dtf.type === "dateStyle") {
      if (dtf.type === "timeStyle") {
        this.customDtf = dtPresetToOptions(timeBasePreset);
      } else if (dtf.type === "dateStyle") {
        this.customDtf = dtPresetToOptions(dateBasePreset);
      }
    } else {
      this.customDtf = this.datetimeFormat;
    }

    // TODO: add this logic to Modal mixin
    setTimeout(() => {
      document.addEventListener("click", () => {
        this.$emit("closed");
      });
      this.$el.addEventListener("click", ev => {
        ev.stopPropagation();
      });
    }, 0);
  }

  get datetimePresetOptions(): any[] {
    const cust = this.customFormatOptions.slice(0).reverse();
    return cust.concat(datetimePresets.map((x: any) => dtPresetToOptions(x)));
  }

  addField(field: string) {
    const oldDtf = JSON.parse(JSON.stringify(this.customDtf));
    this.showFields = false;
    this.hasChanged = true;

    oldDtf.push({
      type: "literal",
      value: " "
    });
    oldDtf.push({
      type: field,
      value: "2-digit"
    });

    this.customDtf = oldDtf;
  }

  getClass(preset: DatetimeOption[]) {
    if (preset === this.activePreset) {
      return "bg-gray-200";
    }
    return "";
  }

  handleInput(ev: any) {
    const oldDtf = JSON.parse(JSON.stringify(this.customDtf));
    const dtComponent = oldDtf[ev.index];
    this.hasChanged = true;

    if (ev.value === "remove") {
      oldDtf.splice(ev.index, 1);
      this.customDtf = oldDtf;
      return;
    }

    // Handle day vs weekday here:
    if (dtComponent.type === "day" || dtComponent.type === "weekday") {
      const dayType =
        ev.value === "2-digit" || ev.value === "numeric" ? "day" : "weekday";
      dtComponent.type = dayType;
    }
    if (dtComponent.type === "hour") {
      dtComponent.hour12 = !ev.value.includes("24");
    }
    dtComponent.value = ev.value;

    // Just altering the values of a single component in the datetime caused weird bugs..:
    // Ah because of reference vs value! Need to reassign the array.
    this.customDtf = oldDtf;
  }

  get preview() {
    const date = this.useCurrentTime ? new Date() : this.userCustomTime;
    if (this.useRelativeTime) {
      return relativeTimeFormat(date, this.relativeParam);
    }
    return dtOptionsToString(this.customDtf, date);
  }

  getComponentType(option: any) {
    return option.type === "literal"
      ? "DatetimeFormatTextInput"
      : "DatetimeFormatSelect";
  }

  getDisplay(presetOptions: DatetimeOption[]) {
    return dtOptionsToString(presetOptions, sampleDate);
  }

  changePreset(presetOptions: DatetimeOption[]) {
    this.activePreset = presetOptions;
    this.customDtf = presetOptions;
  }

  applyChange() {
    // console.log("apply dtf change");
    this.$emit("changeDt", {
      dtf: this.customDtf,
      hasChanged: this.hasChanged,
      cdt: this.userCustomTime,
      useNow: this.useCurrentTime,
      relativeParam: this.useRelativeTime ? this.relativeParam : ""
    });
    this.hasChanged = false;
  }

  get styles() {
    return {
      width: "40vw",
      height: "80vh",
      position: "absolute",
      top: "10vh",
      left: "30vw",
      background: "white"
    };
  }
}
</script>

<style>
.preset-container {
  height: 50vh;
  width: 85%;
}
.control-box {
  width: 85%;
  min-height: 10vh;
}

.apply-btn:focus {
  border: none;
  outline: none;
}
.apply-btn:hover {
  opacity: 0.5;
}

.add-container:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>
