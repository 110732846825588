<template>
  <div
    class="relative flex items-center space-x-2 cursor-pointer h-5 ml-3"
    :class="{ 'opacity-50 cursor-not-allowed': disabled }"
    @click.stop="handleClick"
  >
    <div class="text-sm text-gray-500 select-none w-3/4">
      <slot />
    </div>

    <ScalarBinder
      v-if="bindable"
      :propName="propName"
      propType="Color"
      v-slot:default="slotProps"
      tooltipPosition="l"
    >
      <ColorInput
        class="w-10 flex justify-center"
        :value="slotProps.value"
        :gradientEnabled="gradientEnabled"
        :iconSize="iconSize"
        @input="onInput"
        @change="onChange"
        ref="pickerButton"
        :dataBound="slotProps.isBound"
      />
    </ScalarBinder>

    <ColorInput
      v-else
      class="w-10 flex justify-center pr-1"
      :value="value"
      :gradientEnabled="gradientEnabled"
      :iconSize="iconSize"
      @input="onInput"
      @change="onChange"
      ref="pickerButton"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ColorInput from "@/components/inputs/ColorInput.vue";
import LightningBolt from "@/components/data/LightningBolt.vue";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    ColorInput,
    LightningBolt,
    ScalarBinder,
  },
})
export default class ColorInputBar extends Vue {
  @Prop(String) value: string;
  @Prop({ type: String, default: "5" }) iconSize: string;
  @Prop(String) tooltip: string;
  @Prop(Boolean) disabled: boolean;
  @Prop() gradientEnabled: boolean;
  @Prop(String) propName: string;

  @Prop({ default: true }) bindable: boolean;

  get selectedProps() {
    const appEditor = useAppEditorStore();
    return appEditor.selectedProps;
  }

  handleClick() {
    (this.$refs.pickerButton as ColorInput).toggle();
  }

  onInput(value: string) {
    this.$emit("input", value);
  }

  onChange(value: string) {
    this.$emit("change", value);
  }

  get renderValue() {
    return this.getValue<number>("angle");
  }

  // TODO figure out how to share this across components...(mixin? but hmm can only extend one..)
  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];

      result = values[0];
    }
    return result as T;
  }
}
</script>
