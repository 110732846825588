/**
 * This is a helper function for our QA environment.
 *
 * It will check if the current branch is deployed and if so, it will return the preview URL with the branch name.
 * @param previewUrl
 * @returns
 */
export async function getPreviewUrlWithBranch(previewUrl?: string) {
  if (previewUrl === undefined) {
    return;
  }

  const branch = getPreviewBranchName();

  if (branch) {
    const newUrl = appendBranchToFeedUrl(previewUrl, branch);
    try {
      const isDeployed = await isPreviewBranchDeployed(previewUrl, branch);
      if (isDeployed) {
        return newUrl.toString();
      } else {
        console.error(`App Renderer branch: ${branch} not deployed.`);
      }
    } catch (error) {
      console.error(
        `Error while checking if App Renderer branch ${branch} is deployed.`,
        error
      );
    }
  }

  return previewUrl;
}

/**
 * Returns the current Pull Request branch name from the url if it exists.
 *
 * This is useful when we are doing QA work on Pull Request Preview Urls.
 *
 * If current url is:
 * `https://connect-git-branch-name-screenfeed.vercel.app`
 *
 * It would return "branch-name"
 */
export function getPreviewBranchName() {
  const url = new URL(window.location.href);
  if (url.hostname.endsWith("vercel.app")) {
    const parts = url.hostname.split("-");
    return parts.slice(2, parts.length - 1).join("-");
  }
}

function appendBranchToFeedUrl(feedUrl: string, branch: string) {
  const newUrl = new URL(feedUrl);
  // Ensure https
  if (newUrl.protocol === "http:") {
    newUrl.protocol = "https:";
  }
  newUrl.searchParams.delete("_htmlBranch");
  newUrl.searchParams.append("_htmlBranch", branch);
  return newUrl.toString();
}

/**
 * Performs a HEAD request to determine whether the given branch has been deployed via Team City
 */
export async function isPreviewBranchDeployed(feedUrl: string, branch: string) {
  if (branch) {
    const newUrl = appendBranchToFeedUrl(feedUrl, branch);
    const response = await fetch(newUrl.toString(), { method: "HEAD" });
    return response.ok;
  }

  return false;
}
