import { Position, Point, Size, Rectangle } from "@/types";
import { BaseTextStyles, ShadowProps } from "@/types/properties";
import { logger } from "@core/logger";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";

import { DateTime } from "luxon";
import { BackendError } from "./api/backend";
import {
  DefaultTransformOptions,
  TransformOptions,
} from "./components/widgets/TransformOptions";
import { Widget } from "./components/widgets/Widget";
import { svgUrlFixRequired } from "./util/browser";
import { RepeaterOptions } from "@/components/widgets/Repeater/RepeaterOptions";

export const cleanColorValue = (color: string) => {
  let value = color;
  let formattedValue = color;
  const hexRegex = /^#?([a-f0-9]{6,8}|[a-f0-9]{3,4})$/i;
  const isHex = value.match(hexRegex);
  // Ensure a "#" is prepended to hex-formatted values
  if (isHex && !color.startsWith("#")) {
    value = `#${color}`;
    formattedValue = value;
  }
  // Don't display the final two characters of an 8-digit hex value
  if (color.length === 9 && color.startsWith("#") && color.endsWith("FF")) {
    formattedValue = color.substring(0, 7);
  }
  return { value, formattedValue };
};

export const loadImages = (urls: string[]) => {
  return urls.map(
    (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
          img.onload = null; // for garbage collection
          resolve("ok");
        };

        img.onerror = function () {
          img.onerror = null; // for garbage collection
          reject();
        };

        img.src = url;
      })
  );
};

/**
 * These 3 methods are used to determine what query params to append to on-the-fly image requests made by Connect widgets.
 * The two main use cases are: Image widget, and Shape widget with a background image.
 */

interface ImageQueryParams {
  w: number;
  h: number;
  scaleX: number;
  scaleY: number;
  type: string;
  backgroundImageW?: number;
  backgroundImageH?: number;
  backgroundRepeat?: string;
  backgroundRepeatSize?: number;
  backgroundSize?: string;
}

const getImageWidgetQueryParams = (payload: ImageQueryParams) => {
  const { w, h, scaleX, scaleY, backgroundSize } = payload;
  // console.log("get image widget query params", window.devicePixelRatio);
  const width = Math.floor(w * scaleX * window.devicePixelRatio);
  const height = Math.floor(h * scaleY * window.devicePixelRatio);
  const fitMode = backgroundSize === "contain" ? "max" : "crop";
  return `width=${width}&height=${height}&mode=${fitMode}`;
};

const getShapeWidgetImageQueryParams = (payload: ImageQueryParams) => {
  const {
    w,
    h,
    scaleX,
    scaleY,
    backgroundImageW,
    backgroundImageH,
    backgroundRepeat,
    backgroundRepeatSize,
    type,
  } = payload;

  const width = (type === "Svg" ? w : w * scaleX) * window.devicePixelRatio;
  const height = (type === "Svg" ? h : h * scaleY) * window.devicePixelRatio;
  const bgRatio = (backgroundImageW || 1) / (backgroundImageH || 1);

  const imageW =
    backgroundRepeat === "no-repeat" ? width : backgroundRepeatSize;

  let scl = 1;
  if (!isNaN(backgroundImageW) && !isNaN(backgroundImageH)) {
    scl = (backgroundImageH || 1) / (backgroundImageW || 1);
  }

  let imageH = height;
  if (backgroundRepeat !== "no-repeat" && backgroundRepeatSize) {
    imageH = backgroundRepeatSize * scl;
  }

  const imageRatio = (imageW || 1) / imageH;

  return bgRatio > imageRatio
    ? `height=${Math.floor(height)}`
    : `width=${Math.floor(width)}`;
};

export const getImageQueryParams = (payload: ImageQueryParams) => {
  const { type } = payload;
  if (type === "Image") {
    return getImageWidgetQueryParams(payload);
  }
  return getShapeWidgetImageQueryParams(payload);
};

export const computeRepeaterEditingContext = (
  widget: Widget,
  e: MouseEvent,
  scale: number
) => {
  const { rows, columns, rowGap, columnGap, borderWidth, flow } =
    widget as unknown as RepeaterOptions;

  const { w, h, x: widgetX, y: widgetY } = getApparentDims(widget);

  const x = e.offsetX / scale;
  const y = e.offsetY / scale;

  const cellWidth = (w - columnGap * (columns + 1)) / columns;
  const cellHeight = (h - rowGap * (rows + 1)) / rows;

  let row = -1;
  let column = -1;
  let offsetX = 0;
  let offsetY = 0;
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < columns; c++) {
      const minX = (c + 1) * columnGap + c * cellWidth;
      const maxX = minX + cellWidth;
      const minY = (r + 1) * rowGap + r * cellHeight;
      const maxY = minY + cellHeight;
      if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
        offsetX = minX;
        offsetY = minY;
        row = r;
        column = c;
      }
    }
  }

  offsetX += borderWidth;
  offsetY += borderWidth;

  if (row === -1 || column === -1) {
    return undefined;
  }

  if (rows === 1 && columns === 1) {
    offsetX = 0;
    offsetY = 0;
  }

  const repeaterIndex = flow.includes("row")
    ? row * columns + column
    : column * rows + row;

  return {
    parentId: widget.wid,
    widgetX,
    widgetY,
    offsetX,
    offsetY,
    repeaterIndex,
    width: cellWidth,
    height: cellHeight,
  };
};

/**
 * This is sort of like `Promise.allSettled()` except it just swallows all errors.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
 *
 * See Stack Overflow for this technique
 * https://stackoverflow.com/a/36115549/5651
 */
export const allSettled = (promises: Promise<unknown>[]) => {
  return Promise.all(promises.map((p) => p.catch((e) => logger.track(e))));
};

export const capitalize = (str: string) => {
  return `${str[0].toUpperCase() + str.slice(1)}`;
};

/**
 * Takes a css color string and returns true if the alpha channel is equal to zero.
 * @param value a CSS Color string (#RGB, #RGBA, #RRGGBB, #RRGGBBAA, rgba(0,0,0,0), hsla(0,0%,0%,0))
 * @returns boolean - true if color has zero alpha value
 */
export const isTransparent = (value: string): boolean => {
  if (!isNonEmptyString(value)) {
    return false;
  }

  // #RGBA
  if (value.length === 5) {
    return value.substring(4) === "0";
  }

  // #RRGGBBAA
  if (value.length === 9) {
    return value.substring(7) === "00";
  }

  // rgba(0,0,0,0)
  if (value.startsWith("rgba")) {
    const parts = value.split(",");
    return parts[3].trim() === "0)";
  }

  if (value.startsWith("hsla")) {
    const parts = value.split(",");
    return parseInt(parts[2].trim()) === 0;
  }

  // TODO: handle hsla

  return false;
};

const parseHexString = (value: string) => {
  const result = [];
  while (value.length >= 2) {
    result.push(parseInt(value.substring(0, 2), 16));
    value = value.substring(2, value.length);
  }

  return result;
};

const parseRgbString = (value: string) => {
  return value
    .substring(value.indexOf("(") + 1, value.length - 1)
    .split(",")
    .map((x) => parseInt(x)); // not sure why this has different results than just .map(parseInt)..
};

const parseHslString = (value: string) => {
  return value
    .substring(value.indexOf("(") + 1, value.length - 1)
    .split(",")
    .map((x) => parseInt(x));
};

/**
 * Takes a css color string and returns true if the color is "near white".
 * @param value a CSS Color string (#RGB, #RGBA, #RRGGBB, #RRGGBBAA, rgba(0,0,0,0))
 * @returns boolean - true if color is "near white"
 */
export const isNearWhite = (value: string): boolean => {
  let parts: number[] = [];
  if (value.startsWith("#")) {
    parts = parseHexString(value.slice(1));
  } else if (value.startsWith("rgb")) {
    parts = parseRgbString(value);
  } else if (value.startsWith("hsl")) {
    parts = parseHslString(value);
    return parts[2] > 90;
  }

  // For hex and rgb
  if (parts.length > 0) {
    return parts.slice(0, 3).every((p) => p > 230);
  }
  return false;
};

export const clampSize = (size: Size, max: number) => {
  let { w, h } = size;
  if (w === h) return { w: max, h: max };
  if (w > h) {
    h /= w / max;
    w = max;
  } else {
    w /= h / max;
    h = max;
  }
  return { w, h };
};

export const EMPTY_STATE = "emptyState";

export const purgeNullValues = (obj: any) => {
  if (!obj) return obj;
  const res: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null) res[key] = obj[key];
  });
  return res;
};

export const clamp = (val: number, r1: number, r2: number) => {
  if (val < r1) return r1;
  if (val > r2) return r2;
  return val;
};

export const getRange = (start: number, end: number) => {
  const res = [];
  for (let i = start; i <= end; i++) {
    res.push(i);
  }
  return res;
};

export const getTextCssFromProps = (style: BaseTextStyles) => {
  const s: Partial<CSSStyleDeclaration> = {};
  if (!style) return s;

  Object.keys(style).forEach((key: keyof BaseTextStyles) => {
    if (style[key] === "inherit") return;
    (s as any)[key] = style[key];
    // Map to correct CSS property name
    if (key === "textColor") {
      s.color = style[key];
    }
    if (key === "fontSize") {
      s.fontSize = `${style[key]}px`;
    }
    if (key === "alignHorizontal") {
      switch (style[key]) {
        case "left":
          s.justifyContent = "flex-start";
          break;
        case "center":
          s.justifyContent = "center";
          break;
        case "right":
          s.justifyContent = "flex-end";
          break;
      }
      s.textAlign = style[key];
    }
    if (key === "letterSpacing") {
      s.letterSpacing = `${style[key]}pt`;
    }
  });
  return s;
};

// export const dummyCalendarData = [
//   {
//     Summary: "All Day Event Title",
//     Start: makeHourTodayDate(0).toISO(),
//     End: makeHourTodayDate(0, 1).toISO(),
//     Location: "The Moon"
//   },
//   {
//     Summary: "Another Event Title",
//     Start: makeHourTodayDate(9).toISO(),
//     End: makeHourTodayDate(11, 0, 45).toISO()
//     // Location: "The Dark Side of the Moon"
//   },
//   {
//     Summary: "This is a Short Event!",
//     Start: makeHourTodayDate(15, 0, 30).toISO(),
//     End: makeHourTodayDate(17).toISO(),
//     Location: "Room 237"
//   },
//   {
//     Summary: "Multi Day Event Title",
//     Start: makeHourTodayDate(0, -1).toISO(),
//     End: makeHourTodayDate(0, 2).toISO(),
//     Location: "The Moon"
//   },
//   {
//     Summary: "Earth rotates",
//     Start: makeHourTodayDate(15, 2).toISO(),
//     End: makeHourTodayDate(17, 2).toISO()
//     // Location: "The Dark Side of the Moon"
//   },
//   {
//     Summary: "Time passes",
//     Start: makeHourTodayDate(18, 1).toISO(),
//     End: makeHourTodayDate(20, 1).toISO(),
//     Location: "Room 237"
//   },
//   {
//     Summary: "Multi Day Event Title 2",
//     Start: makeHourTodayDate(0, 1).toISO(),
//     End: makeHourTodayDate(0, 3).toISO(),
//     Location: "The Moon"
//   },
//   {
//     Summary: "Something happens",
//     Start: makeHourTodayDate(11, -3).toISO(),
//     End: makeHourTodayDate(17, -3, 15).toISO()
//     // Location: "The Dark Side of the Moon"
//   },
//   {
//     Summary: "This is an event!",
//     Start: makeHourTodayDate(10, -2).toISO(),
//     End: makeHourTodayDate(12, -2, 30).toISO(),
//     Location: "Room 237"
//   }
// ];

export const getEventXProportions = (
  event: { start: DateTime; end: DateTime },
  interval: { start: DateTime; end: DateTime }
) => {
  const res = [0, 1];
  const intervalDuration = interval.end.diff(interval.start).milliseconds; // positive if first is *after* second

  // If event starts after interval start, compute its left position:
  const startDiff = event.start.diff(interval.start).milliseconds;
  if (startDiff > 0) {
    res[0] = startDiff / intervalDuration;
  }
  // If event ends before interval end, compute its right position:
  const endDiff = interval.end.diff(event.end).milliseconds;
  if (endDiff) {
    res[1] = 1 - endDiff / intervalDuration;
  }
  return res;
};

export const isCurrency = (val: string): boolean => {
  const regex = /\$/g;
  return regex.test(val);
};

/**
 * For now, assumes currency is U.S.$, but should internationalize in the future
 */
export const formatCurrencyString = (val: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(val);
};

/*
This should return x,y,w,h of bg image, given its scale, 
original dims, and the dims of the component.
This will allow us to determine limits on repositioning the bg image,
and also to position the image within its svg.
*/
export const placeCenteredBackgroundImage = (
  wg: any,
  imgSize: Size,
  imgScl: number
): Position => {
  const { w, h } = getApparentDims(wg);
  // console.log("place...", wg.w, wg.scaleX, w)
  let x = 0,
    y = 0,
    width = 0,
    height = 0;
  const widgetAR = w / h;
  const imgAR = imgSize.w / imgSize.h;
  if (imgAR > widgetAR) {
    height = h;
    width = height * imgAR;
    x = (w - width) / 2;
  } else {
    width = w;
    height = width / imgAR;
    y = (h - height) / 2;
  }
  const wgCenter = { x: w / 2, y: h / 2 };
  const imgRelWgCenter = subtractVectors({ x, y }, wgCenter);
  const scaledVec = scaleVector(imgRelWgCenter, imgScl);
  const finalPt = addVectors(wgCenter, scaledVec);
  const final = {
    x: finalPt.x,
    y: finalPt.y,
    w: width * imgScl,
    h: height * imgScl,
    z: 1,
  };

  const clean = {
    x: isNaN(final.x) ? 0 : final.x,
    y: isNaN(final.y) ? 0 : final.y,
    w: isNaN(final.w) ? 0 : final.w,
    h: isNaN(final.h) ? 0 : final.h,
    z: 1,
  };

  return clean;
};

export const getPolygonPath = (payload: {
  ctr: Point;
  numSides: number;
  radius: number;
  innerRadius: number;
  starEnabled: boolean;
  borderWidth: number;
}) => {
  const { ctr, numSides, radius, innerRadius, starEnabled } = payload;

  // TODO: How to deal with this?
  // This moves the star down a bit (away from perfect center but makes more visual sense):
  // ctr.y *= 1.1;
  // radius -= borderWidth;

  // const percent = 100 - (radius - borderWidth) / borderWidth;
  // console.log("draw poly", radius, innerRadius, borderWidth, percent);
  // radius *= (percent / 100);
  // innerRadius *= (percent / 100);

  //Commented out the parts that change the shape based on borderWidth
  //We can mask the shape with a copy of itself with no stroke, double the border width,
  //and it will act the same
  //const innerAngle = ((Math.PI / 2) * (numSides - 2)) / numSides;
  // const radiusRemove = borderWidth / Math.sin(innerAngle / 2);

  // Close enough:
  // radius -= radiusRemove;
  // innerRadius -= radiusRemove / 2;

  const pts = [];
  const angle = (2 * Math.PI) / numSides;
  for (let i = 0; i < numSides; i++) {
    // outer points
    pts.push({
      x: ctr.x + radius * Math.cos(-Math.PI / 2 + angle * i),
      y: ctr.y + radius * Math.sin(-Math.PI / 2 + angle * i),
    });
    if (starEnabled) {
      // inner points (for star)
      pts.push({
        x: ctr.x + innerRadius * Math.cos(-Math.PI / 2 + angle * (i + 0.5)),
        y: ctr.y + innerRadius * Math.sin(-Math.PI / 2 + angle * (i + 0.5)),
      });
    }
  }
  let res = "M ";
  pts.forEach((pt, i) => {
    if (i == 0) {
      res += `${pt.x} ${pt.y} `;
    } else {
      res += `L ${pt.x} ${pt.y} `;
    }
  });
  return res + "Z";
};

// const fitInto = (display: Size, boundary: Size): Size => {
//   const wr = display.w / boundary.w;
//   const hr = display.h / boundary.h;
//   const proportion = 1 / Math.max(wr, hr);
//   //   if (wr >1 || hr > 1) {
//   //       proportion = 1 / Math.max(wr, hr);
//   //   } else {
//   //       proportion = 1 / Math.max(wr, hr);
//   //   }
//   return { w: display.w * proportion, h: display.h * proportion };
// };

// drop-shadow or box-shadow?
// hmm...drop is less supported, and it does NOT support spread...also no inset shadows...
// but dropshadow would work on svg....

export const capitalizeAll = (str: string) => {
  return str
    .split(" ")
    .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`)
    .join(" ");
};
// Ah shoot, I forgot about border-radius...
// Oh funny, drop-shadow also adds shadow to the bounding box and handles...huh...

// example input:
// "10px 10px 2px hsla(210, 50%, 50%, 1)",
// which will get piped in to "filter: drop-shadow(${stuff})"
// or be added directly as "text-shadow"
export const parseShadowCss = (str: string): ShadowProps => {
  // console.log("shadow css:", str);
  if (!str) return { x: 0, y: 0, blur: 0, color: "" };

  let color;
  // NOTE: we can also have hex strings...we could just remove all those?
  // we can also have just "orange" etc....Yikes
  // const regex = /(hsla[^)]*\))/g;

  // TODO:
  // This should capture hex as well:
  // Ok still missing some.....Not sure if this is the issue or not....keep looking
  const regex = /(hsla[^)]*\))|(#[A-Fa-f0-9]+)/g;
  const cleanStr = str.replace(regex, (match: string) => {
    color = match;
    return "";
  });
  const parts = cleanStr
    .split(" ")
    .filter((x) => x)
    .map((x) => parseInt(x));
  const x = parts[0];
  const y = parts[1];
  const blur = parts[2];

  return { x, y, color: color || "", blur };
};

// console.log("TEST:", parseShadowCss("10px 10px 2px hsla(210, 50%, 50%, 1)"));

// example inputs:
// "hsla(210, 85%, 65%, 1)"
// "linear-gradient(40deg, hsla(210, 85%, 65%, 1) 10%, hsla(310, 85%, 65%, 1) 90%)"
// "radial-gradient(hsla(210, 85%, 65%, 1) 10%, hsla(310, 85%, 65%, 1) 90%)"
export const parseGradientCss = (str: string) => {
  let type = "Solid";

  if (!str) return { type: "", color_1: "" };

  if (str.includes("linear")) type = "Linear";
  if (str.includes("radial")) type = "Radial";

  const colors: string[] = [];

  // -[ ] TODO: we can also have hex strings...we could just remove all those?
  const regex = /(hsla[^)]*\))/g;
  const regex2 = /(hsl[^)]*\))/g;

  const cleanStr = str
    .replace(regex, (match: string) => {
      colors.push(match);
      return "";
    })
    .replace(regex2, (match: string) => {
      colors.push(match);
      return "";
    });

  // console.log('x:', cleanStr);

  if (type === "Solid") {
    return {
      type,
      color: str,
    };
  }

  const start = cleanStr.indexOf("(") + 1;
  const end = cleanStr.lastIndexOf(")");
  const parts = cleanStr
    .slice(start, end)
    .split(",")
    .map((x) => parseInt(x));

  const angle = type === "Linear" ? parts[0] : 0;
  const offset_1 = type === "Linear" ? parts[1] : parts[0];
  const offset_2 = type === "Linear" ? parts[2] : parts[1];
  const color_1 = colors[0];
  const color_2 = colors[1];

  return {
    type,
    color_1,
    color_2,
    offset_1,
    offset_2,
    angle,
  };
};

export interface SnapAxis {
  name: string;
  value: number;
  dist?: number;
  wid?: string;
  id?: string; // adding for custom snap axes, which we want to keep track of by id, but without association to a widget
}

// This assumes for now that zooming transform origin is always 50 50
// which is maybe fine..perhaps we can just accompany scale with a translation

// Translates from "canvas space" to "screen space":
export const scaledCoords = (
  point: Point,
  canvas: Size | Rectangle,
  scale: number
): Point => {
  const canvasCenter = { x: canvas.w / 2, y: canvas.h / 2 };
  const coords = subtractVectors(point, canvasCenter);
  //const coords=  {
  //   x: point.x - canvas.w / 2,
  //   y: point.y - canvas.h / 2
  // };
  return {
    x: coords.x * scale + canvas.w / 2,
    y: coords.y * scale + canvas.h / 2,
  };
};

// Translates from "screen space" to "canvas space":
// (I think the same could be accomplished by passing in 1/scale to scaledCoords)
export const scaledCoordsInvert = (
  point: Point,
  canvas: Size | Rectangle,
  scale: number
): Point => {
  return {
    x: (point.x - canvas.w / 2) / scale + canvas.w / 2,
    y: (point.y - canvas.h / 2) / scale + canvas.h / 2,
  };
};

export const isSameAxis = (first: SnapAxis, second: SnapAxis): boolean => {
  const isX = first.name.includes("x");
  const tgtX = second.name.includes("x");
  const isY = first.name.includes("y");
  const tgtY = second.name.includes("y");
  return (isX && tgtX) || (isY && tgtY);
};

export const getAxes = (
  left: number,
  top: number,
  width: number,
  height: number
) => {
  return {
    x: left,
    cx: left + width / 2,
    rx: left + width,
    y: top,
    cy: top + height / 2,
    by: top + height,
  };
};

export const getAxesAbs = (
  left: number,
  top: number,
  right: number,
  bottom: number
) => {
  const width = right - left;
  const height = bottom - top;
  return {
    x: left,
    cx: left + width / 2,
    rx: left + width,
    y: top,
    cy: top + height / 2,
    by: top + height,
  };
};

export const isWithin = (point: Point, rect: Rectangle) => {
  if (!point || !rect) return false;
  return (
    point.x >= rect.x &&
    point.x <= rect.x + rect.w &&
    point.y >= rect.y &&
    point.y <= rect.y + rect.h
  );
};

// TODO: Abstract shared from these 2
// Difference is that this will allow box to sit around a single element,
// as should happen for nested group single children

export const addVectors = (v1: Point, v2: Point) => {
  return { x: v1.x + v2.x, y: v1.y + v2.y };
};

export const subtractVectors = (v1: Point, v2: Point) => {
  return { x: v1.x - v2.x, y: v1.y - v2.y };
};

// Takes a mouse click position and maps it into canvas coordinates, accounting for scale:
export const screenCoordsToCanvas = (
  pt: Point,
  canvasBox: Rectangle,
  scale: number
) => {
  const ptRelCanvas = subtractVectors(pt, canvasBox);
  const canvasCtr = {
    x: canvasBox.w / 2,
    y: canvasBox.h / 2,
  };
  const ptRelCtr = subtractVectors(ptRelCanvas, canvasCtr);
  const scaledPtRelCtr = {
    x: ptRelCtr.x / scale,
    y: ptRelCtr.y / scale,
  };
  const scaledPt = addVectors(scaledPtRelCtr, canvasCtr);
  return scaledPt;
};

// TAKES IN ANGLE IN RADIANS:
export const rotateVector = (v1: Point, a: number) => {
  return {
    x: v1.x * Math.cos(a) - v1.y * Math.sin(a),
    y: v1.x * Math.sin(a) + v1.y * Math.cos(a),
  };
};

export const scaleVector = (v1: Point, k: number) => {
  return { x: v1.x * k, y: v1.y * k };
};
/*
As I tug RIGHT, x and scaleX update; same for DOWN and y
As i tug LEFT, only scaleX changes...

I think the key is going to be that  w, h never  change,  after wg is created.  They  are static.

So  if x  is 500, w is  200, scaleX  is  2....
We compute where right side is, wwhich is x+w = 700. And then go back 2 * w, to 300.

I think that's it..

Apparent w and h are always going to be scaleX*w, or scaleY*h
And apparent x and y are calculated as above

This function helps us deal with the weird way that free-transform keeps track of position:
*/
export const getApparentDims = (wg: TransformOptions): TransformOptions => {
  if (!wg) {
    return Object.assign({}, DefaultTransformOptions, { w: 0, h: 0 });
  }
  const { x, y, w, h, scaleX, scaleY } = wg;
  return {
    ...wg,
    //Rounding to one decimal place
    x: Math.round((x + w - scaleX * w) * 10) / 10,
    y: Math.round((y + h - scaleY * h) * 10) / 10,
    w: Math.round(w * scaleX * 10) / 10,
    h: Math.round(h * scaleY * 10) / 10,
    scaleX: 1,
    scaleY: 1,
  };
};

// so...say real x is 100, but apparentX is 200. User enters 201 as new  x....so they want apparentX so be 201...
// so what must x,y be, given w,h,and scales, to produce certain apparentX and y?
// Actually, what must x, y, w and h be, given apparent x, y,  w, h, and scaleX and scaleY
// Might be better to alter scaleY than h...Same issue with text component resizing
export const getApparentDimsInvert = (data: any): Rectangle => {
  // Ensures that when real w and h are scaled by scaleX and scaleY, they yield the desired apparent w and h:
  const w = data.w / data.scaleX;
  const h = data.h / data.scaleY;
  const x = data.x - (1 - data.scaleX) * w;
  const y = data.y - (1 - data.scaleY) * h;
  return { x, y, w, h };
};

// If scaleY is not 1, then y must change as well as h to produce a change in apparentH that leaves apparentY fixed
export const dimsToUpdateApparentHeight = (
  t: TransformOptions,
  newHeight: number
) => {
  const h = newHeight / t.scaleY;
  // What must y be changed to, given our new h, if apparentY is to be preserved?
  const y = t.y + (t.h - h) * (1 - t.scaleY);

  return { h, y };
};

export const dimsToUpdateApparentWidth = (
  t: TransformOptions,
  newWidth: number
) => {
  const w = newWidth / t.scaleX;
  const x = t.x + (t.w - w) * (1 - t.scaleX);
  return { w, x };
};

/**
 * Computes the area of a triangle defined by 3 vertices.
 * Thanks https://stackoverflow.com/questions/17136084/checking-if-a-point-is-inside-a-rotated-rectangle
 */
const computeArea = (triangle: Point[]): number => {
  const [p1, p2, p3] = triangle;
  const area =
    Math.abs(
      p2.x * p1.y -
        p1.x * p2.y +
        (p3.x * p2.y - p2.x * p3.y) +
        (p1.x * p3.y - p3.x * p1.y)
    ) / 2;
  return area;
};

/**
 * Computes distance between two vertices.
 */
const computeDistance = (p1: Point, p2: Point): number => {
  const xDiff = p1.x - p2.x;
  const yDiff = p1.y - p2.y;
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

/**
 * Computes whether a point is within a rotated rectangular region defined by 4 vertices.
 * Used for checking whether a click lies within a rotated widget (used for group move, and deselect).
 * Thanks https://stackoverflow.com/questions/17136084/checking-if-a-point-is-inside-a-rotated-rectangle
 *
 * Rectangles should be provided in this order (clockwise from upper left):
 * P1----P2
 * |     |
 * P4----P3
 */
export const isWithinRotatedRectangle = (
  pt: Point,
  rectangle: Point[]
): boolean => {
  const [p1, p2, p3, p4] = rectangle;
  const width = computeDistance(p1, p2);
  const height = computeDistance(p1, p4);
  const triangleAreas = [
    computeArea([p1, pt, p4]),
    computeArea([p3, pt, p4]),
    computeArea([p3, pt, p2]),
    computeArea([p1, pt, p2]),
  ];
  const triangleAreasSum = triangleAreas.reduce((sum, area) => sum + area, 0);
  const rectangleArea = width * height;

  if (rectangleArea < 0.01) return false;

  /**
   * The point is within the region iff sum of triangle areas is less than or equal to area of rectangle.
   * In some cases, rectangle area ends up SLIGHTLY smaller (0.0000001) due to rounding issues.
   * So add just a touch to it, to yield right answer in that case.
   */

  return triangleAreasSum <= rectangleArea + 0.01;
};

/**
 * Thanks to https://stackoverflow.com/questions/10962379/how-to-check-intersection-between-2-rotated-rectangles
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices. Uses the Separating Axis Theorem
 */
export const doPolygonsIntersect = (a: Point[], b: Point[]): boolean => {
  var polygons = [a, b];
  var minA, maxA, projected, i, i1, j, minB, maxB;

  for (i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates
    // the two shapes
    var polygon = polygons[i];
    for (i1 = 0; i1 < polygon.length; i1++) {
      // grab 2 vertices to create an edge
      var i2 = (i1 + 1) % polygon.length;
      var p1 = polygon[i1];
      var p2 = polygon[i2];

      // find the line perpendicular to this edge
      var normal = { x: p2.y - p1.y, y: p1.x - p2.x };

      // for each vertex in the first shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minA = Infinity;
      maxA = -Infinity;

      for (j = 0; j < a.length; j++) {
        projected = normal.x * a[j].x + normal.y * a[j].y;
        if (projected < minA) {
          minA = projected;
        }
        if (projected > maxA) {
          maxA = projected;
        }
      }

      // for each vertex in the second shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minB = Infinity;
      maxB = -Infinity;

      for (j = 0; j < b.length; j++) {
        projected = normal.x * b[j].x + normal.y * b[j].y;
        if (projected < minB) {
          minB = projected;
        }
        if (projected > maxB) {
          maxB = projected;
        }
      }

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (maxA < minB || maxB < minA) {
        // console.log("polygons don't intersect!");
        return false;
      }
    }
  }
  return true;
};

/**
 * Gets the positions of the corners of a possibly rotated box.
 *
 * Returns points in this order:
 * P1----P2
 * |     |
 * P4----P3
 */
export const getVertices = (box: TransformOptions): Point[] => {
  const { x, y, w, h, angle } = box;

  const ctr = { x: x + w / 2, y: y + h / 2 };
  const ptRelCtr = subtractVectors({ x, y }, ctr);
  const radians = (Math.PI * angle) / 180;
  const rotatedVec = rotateVector(ptRelCtr, radians);
  const rotatedW = rotateVector({ x: w, y: 0 }, radians);
  const rotatedH = rotateVector({ x: 0, y: h }, radians);
  const pt1 = addVectors(ctr, rotatedVec);
  const pt2 = addVectors(pt1, rotatedW);
  const pt3 = addVectors(pt2, rotatedH);
  const pt4 = addVectors(pt1, rotatedH);
  return [pt1, pt2, pt3, pt4];
};

/*
Gets the smallest bounding rectangle that fits around another rectangle, accounting for possible rotation:
*/
export const getAngledBoundingBox = (values: TransformOptions): Rectangle => {
  const { x, y, w, h, angle } = values;
  if (angle === 0) {
    return { x, y, w, h };
  }

  const [pt1, pt2, pt3, pt4] = getVertices(values);

  const minX = Math.min(pt1.x, pt2.x, pt3.x, pt4.x);
  const minY = Math.min(pt1.y, pt2.y, pt3.y, pt4.y);
  const maxX = Math.max(pt1.x, pt2.x, pt3.x, pt4.x);
  const maxY = Math.max(pt1.y, pt2.y, pt3.y, pt4.y);
  return { x: minX, y: minY, w: maxX - minX, h: maxY - minY };
};

export const getBoundingBox = (values: TransformOptions[]): Rectangle => {
  let x = Infinity;
  let y = Infinity;
  let w = 0;
  let h = 0;

  values.forEach((value: TransformOptions) => {
    const box = getAngledBoundingBox(getApparentDims(value));

    x = Math.min(box.x, x);
    y = Math.min(box.y, y);
    w = Math.max(box.x + box.w, w);
    h = Math.max(box.y + box.h, h);
  });

  x = x === Infinity ? 0 : x;
  y = y === Infinity ? 0 : y;

  return { x, y, w: w - x, h: h - y };
};

// NOTE: If we have to just keep recomputing this stuff...
// maybe best to keep using absolute position...
// But then have to manually update all ys after every child change...a pain...
export const getBoundingBoxWithVerticalDynamism = (
  values: TransformOptions[],
  verticalMargin: number
): Rectangle => {
  let x = Infinity;
  let y = Infinity;
  let w = 0;
  // let h = 0;

  let totalHeight = 0;

  values.forEach((value: TransformOptions) => {
    const box = getAngledBoundingBox(getApparentDims(value));

    totalHeight += box.h;

    x = Math.min(box.x, x);
    y = Math.min(box.y, y);
    w = Math.max(box.x + box.w, w);
    // h = Math.max(box.y + box.h, h);
  });

  totalHeight += (values.length - 1) * verticalMargin;

  x = x === Infinity ? 0 : x;
  y = y === Infinity ? 0 : y;

  return { x, y, w: w - x, h: totalHeight };
};

// Only use letters that aren't easily confused with numbers
const characters = "abcdefghjkmnpqrstuvwxyz0123456789";
const charactersLength = characters.length;
const lettersLength = characters.indexOf("0");
export const makeId = (length = 8): string => {
  let result = characters.charAt(Math.floor(Math.random() * lettersLength));
  for (let i = 0; i < length - 1; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isNaN = Number.isNaN || (<any>window).isNaN;
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/;
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/;
export const normalizeDecimalNumber = (value: any, times = 100000000000) =>
  REGEXP_DECIMALS.test(value) ? Math.round(value * times) / times : value;

export const isNumber = (value: string) => REGEXP_NUMBER.test(value);

export const quantizeNumber = (
  value: number,
  quantum: number,
  cover = true
) => {
  if (!quantum) {
    return 0;
  }
  const remainder = value % quantum;
  const sign = value >= 0 ? 1 : -1;
  const mod = cover && remainder ? quantum : 0;
  return value - remainder + sign * mod;
};

export const getImageDimensions = (url: string): Promise<Size> => {
  const img = new Image();
  img.style.opacity = "0.01";
  document.body.appendChild(img);
  img.src = url;
  return new Promise((resolve, reject) => {
    img.onload = function () {
      img.onload = null;
      setTimeout(() => {
        const size = {
          w: img.naturalWidth,
          h: img.naturalHeight,
        };

        document.body.removeChild(img);
        resolve(size);
      }, 20);
    };
    img.onerror = function (e) {
      document.body.removeChild(img);
      img.onerror = null;
      reject(e);
    };
  });
};

export const removeReactivity = <T>(value: unknown): T => {
  if (typeof value === "undefined") {
    return value as unknown as T;
  }
  return JSON.parse(JSON.stringify(value)) as T;
};

export const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

export const removeDuplicateStrings = (values: string[]) => {
  let i: number;
  const len = values.length;
  const out: string[] = [];
  const obj: any = {};

  for (i = 0; i < len; i++) {
    obj[values[i]] = 0;
  }
  for (const key in obj) {
    out.push(key);
  }
  return out;
};

/**
 * Grabs a value from a vue-router query object.
 * @returns The value
 */
export const getQueryValue = <T>(value: string | (string | null)[]): T => {
  if (Array.isArray(value) && value.length > 0) {
    return value[0] as unknown as T;
  }
  return value as unknown as T;
};

export const imageResize = (
  src: string,
  width?: number,
  height?: number,
  mode: "max" | "crop" = "max"
) => {
  if (typeof width === "undefined" && typeof height === "undefined") {
    return src;
  }

  if (!isNonEmptyString(src)) {
    return undefined;
  }

  try {
    const url = new URL(src);
    url.searchParams.set("width", Math.round(width as number).toString());
    url.searchParams.set("height", Math.round(height as number).toString());
    url.searchParams.set("mode", mode);
    return url.toString();
  } catch (err) {
    logger.track(err);
    return undefined;
  }
};

export const isNullOrUndefined = (value: any) => {
  return typeof value === "undefined" || value === null;
};

export const throttle = (callback: (context: any, args: any) => void) => {
  let lastArgs: any;
  let timer: number | null = null;

  const later = (context: any) => () => {
    timer = null;
    callback.apply(context, lastArgs);
  };

  const throttled = function (...args: any[]) {
    lastArgs = args;
    if (timer === null) {
      // @ts-ignore
      timer = window.requestAnimationFrame(later(this));
    }
  };

  throttled.cancel = () => {
    cancelAnimationFrame(timer as number);
    timer = null;
  };

  return throttled;
};

/**
 * Returns a scale value that when applied to the source will make the source fit within the destination
 * @param destination The target size
 * @param source The starting size
 * @returns
 */
export const getScaleFactor = (destination: Size, source: Size): number => {
  const widthRatio = source.w / destination.w;
  const heightRatio = source.h / destination.h;
  const proportion = 1 / Math.max(widthRatio, heightRatio);
  return proportion;
};

/**
 * Modifies scalable widget properties according to the ratio betwen app size and render size.
 * @param widget The widget to modify
 * @param scaleFactor The amount to scale the widget properties
 * @returns Modified widget
 */
export const scaleWidget = (widget: Widget, scaleFactor: number) => {
  const PROPS_TO_SCALE = [
    "w",
    "h",
    "x",
    "y",
    "columnGap",
    "rowGap",
    "shadowX",
    "shadowY",
    "shadowBlur",
    "borderWidth",
  ];

  const PROPS_SUFFIXES_TO_SCALE = ["fontSize", "letterSpacing"];

  const result = { ...widget };
  PROPS_TO_SCALE.forEach((prop) => {
    (result as any)[prop] *= scaleFactor;
    // Must yield exact same value as we predict in getImageQueryParams
    (result as any)[prop] = Math.floor((result as any)[prop]);
  });

  Object.keys(widget).forEach((key) => {
    if (PROPS_SUFFIXES_TO_SCALE.some((p) => key.includes(p))) {
      (result as any)[key] *= scaleFactor;
      // Must yield exact same value as we predict in getImageQueryParams
      (result as any)[key] = Math.floor((result as any)[key]);
    }
  });

  return result;
};

export const extractErrorMessage = (
  err: BackendError[] | { message: string } | string,
  defaultMessage = ""
) => {
  let msg = "";
  if (Array.isArray(err)) {
    msg = err[0].message;
  } else if (typeof err === "object" && "message" in err) {
    msg = err.message;
  } else if (isNonEmptyString(err)) {
    msg = err;
  }
  if (!isNonEmptyString(msg)) {
    return defaultMessage;
  }
  return msg;
};

/**
 * Returns the value of a Vue Router query param as a string or undefined.
 * Query params with multiple values will be returned as a comma-delimited string.
 * @param value Parameter key
 */
export const getRouteQueryValue = (value: string | (string | null)[]) => {
  if (Array.isArray(value)) {
    return value.filter((v) => v !== null).join(",");
  }
  if (isNonEmptyString(value)) {
    return value as string;
  }
  return undefined;
};

/**
 * Takes a fragment ID and returns a an CSS `url()` value with the absolute URL prepended
 * if the browser requires it.
 * @param fragmentId Fragment ID of the SVG element (e.g. #abc234-bg-mask)
 * @example
 * // returns "url(https://example.com/#abc234-bg-mask)"
 */
export const svgUrl = (fragmentId: string) => {
  const urlAndPath = window.location.href.split("#");
  const id = `#${fragmentId.replace("#", "")}`;
  const url = svgUrlFixRequired ? urlAndPath + id : id;
  return `url(${url})`;
};
