<template>
  <ConditionsModal v-if="isLoaded">
    <!-- Header -->
    <div
      class="relative flex items-center justify-between px-8 py-5 border-b border-gray-600"
    >
      <label
        class="text-2xl text-center"
        for="condition-name"
        v-text="headerText"
      ></label>
      <div
        class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex pl-36"
      >
        <input
          class="w-80 mx-5 px-3 py-1 rounded text-lg text-black dark-form-focus"
          :class="{ 'outline outline-red-500 bg-red-50': nameError }"
          id="condition-name"
          :placeholder="$t('ConditionsEditor.nameCondition').toString()"
          :value="conditionEditor.name"
          :disabled="isSaving"
          @input="onNameChanged($event)"
          @change="onNameChanged($event)"
        />
        <PrimaryButton
          class="w-36"
          @click="onSubmit"
          :disabled="isSaveDisabled"
        >
          <span v-t="'ConditionsEditor.saveChanges'"></span>
        </PrimaryButton>
      </div>

      <OutlineButton type="button" @click="onCancel"
        ><span v-t="'cancel'"></span
      ></OutlineButton>
    </div>

    <!-- Description -->
    <div
      class="text-center text-3xl italic text-gray-500 py-8 px-10 border-b border-gray-600"
      v-if="conditionEditor.description.length > 0 && !showIntro"
    >
      <span
        v-for="(t, index) in conditionEditor.description"
        :key="index"
        v-text="t.value"
        :class="{
          'text-app-gold': t.type === 'value',
          capitalize: index === 0,
        }"
      >
      </span>
    </div>

    <!-- Modal Scroll Content -->
    <div ref="scroller" class="flex-grow h-full overflow-y-scroll">
      <!-- Introduction -->

      <ConditionsIntro v-if="showIntro" @continue="onIntroContinue" />
      <!-- Rules Editor -->
      <div v-if="!showIntro" class="space-y-16 mb-12 flex-grow">
        <div
          class="relative"
          :key="rg.uuid"
          v-for="(rg, index) in conditionEditor.groups"
        >
          <!-- OR Link -->
          <div
            class="py-4 bg-app-dark3 border-y border-gray-600 flex justify-center items-center"
            v-if="index > 0"
          >
            <div
              class="rounded bg-app-purple px-4 py-1 text-lg font-semibold leading-none text-white shadow-lg"
              v-t="'Condition.or'"
            ></div>
          </div>

          <RuleGroupEditor
            ref="ruleGroupEditor"
            :showAdd="showAddButton(index)"
            :conditionUuid="conditionEditor.conditionUuid"
            :model="rg"
          />
        </div>
      </div>
    </div>

    <UiBlocker :visible="isSaving">
      <div v-t="'ConditionsEditor.saving'"></div>
    </UiBlocker>
  </ConditionsModal>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { useConditionEditorStore } from "@/stores/conditionEditor";
import RuleGroupEditor from "./RuleGroupEditor.vue";
import UiBlocker from "../UiBlocker.vue";
import ConditionsModal from "./ConditionsModal.vue";
import {
  ConditionRuleToken,
  makeConditionRuleTextToken,
} from "@/components/logic/describeRule";
import { BASE_PARENT_ID } from "@/constants";
import PrimaryButton from "../PrimaryButton.vue";
import OutlineButton from "../OutlineButton.vue";
import ConditionsIntro from "./ConditionsIntro.vue";
import { Dictionary } from "vue-router/types/router";
import { userPreferences } from "@/userPreferences";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    RuleGroupEditor,
    ConditionsModal,
    PrimaryButton,
    OutlineButton,
    UiBlocker,
    ConditionsIntro,
  },
})
export default class ConditionEditor extends Vue {
  isSaving = false;
  isInitialized = false;
  showIntro = false;

  get conditionEditor() {
    return useConditionEditorStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get isLoaded() {
    return this.appEditor.isLoaded;
  }
  get widgets() {
    return this.appEditor.widgets;
  }

  onIntroContinue() {
    this.showIntro = false;
  }

  @Watch("conditionEditor.updateCounter")
  onUpdateCounterChanged() {
    const ruleGroupEditors = (this.$refs.ruleGroupEditor ||
      []) as RuleGroupEditor[];

    let tokens: ConditionRuleToken[] = [];
    ruleGroupEditors.forEach((r, index) => {
      if (index > 0) {
        tokens = tokens.concat(makeConditionRuleTextToken(" OR "));
      }
      tokens = tokens.concat(r.describe());
    });
    this.conditionEditor.description = tokens;
  }

  showAddButton(index: number) {
    return (
      this.conditionEditor.groups.length > 1 &&
      index !== this.conditionEditor.groups.length - 1
    );
  }

  confirmLeave() {
    return window.confirm(
      this.$t("ConditionsEditor.discardUnsavedChanges").toString()
    );
  }

  beforeDestroy() {
    // Prevent users from navigating away if there are unsaved changes.
    window.removeEventListener("beforeunload", this.confirmRefresh, {
      capture: true,
    });
  }

  @Watch("store.hasUnsavedChanges", { immediate: true })
  onHasUnsavedChanges(hasUnsavedChanges: boolean) {
    if (hasUnsavedChanges) {
      window.addEventListener("beforeunload", this.confirmRefresh, {
        capture: true,
      });
    } else {
      window.removeEventListener("beforeunload", this.confirmRefresh, {
        capture: true,
      });
    }
  }

  confirmRefresh(event: BeforeUnloadEvent) {
    event.preventDefault();
    return (event.returnValue = this.$t(
      "ConditionsEditor.discardUnsavedChanges"
    ).toString());
  }

  beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (this.conditionEditor.hasUnsavedChanges && !this.confirmLeave()) {
      return;
    }

    next();
  }

  sendToConfirmation(newConditionUuid: string | undefined) {
    const query: Dictionary<string> = {
      widgetId: this.conditionEditor.selectedWidgetId,
      conditionGroupUuid: this.conditionEditor.conditionGroupUuid as string,
    };

    if (newConditionUuid) {
      query["newConditionUuid"] = newConditionUuid;
    }

    return this.$router.push({
      name: "condition-confirm",
      params: {
        id: this.$route.params.id,
      },
      query,
    });
  }

  @Watch("isLoaded", { immediate: true })
  onAppLoaded(isLoaded: boolean) {
    if (this.isInitialized) {
      return;
    }

    if (isLoaded) {
      const store = useConditionEditorStore();

      const appUuid = this.$route.params.id;
      const selectedWidgetId = this.$route.query.widgetId as string;
      const conditionUuid = this.$route.params.conditionUuid;
      const conditionGroupUuid = this.$route.query.conditionGroupUuid as string;

      const repeaterParentWidgetId =
        this.getRepeaterParentWidgetId(selectedWidgetId);

      store
        .initialize(
          appUuid,
          selectedWidgetId,
          conditionUuid,
          conditionGroupUuid,
          repeaterParentWidgetId
        )
        .then(() => {
          this.isInitialized = true;

          this.showIntro =
            userPreferences.get<boolean>("Conditions.SkipIntroHelp") !== true &&
            conditionUuid === "new";

          if (conditionUuid === "new") {
            store.description = makeConditionRuleTextToken(
              this.$t("ConditionsEditor.initialDescription").toString()
            );
          } else {
            this.onUpdateCounterChanged();
          }
        });
    }
  }

  onSubmit() {
    this.isSaving = true;

    this.conditionEditor
      .save()
      .then(({ action, condition }) => {
        console.log("Save complete", { action, uuid: condition.uuid });
        this.sendToConfirmation(
          action === "created" ? condition.uuid : undefined
        );
      })
      .catch((err) => {
        if (err === "invalid") {
          // Can safely ignore. Maybe this isn't the best way to validate?
          return;
        }
        throw err;
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  onCancel() {
    this.$router.push({
      name: "edit",
      params: {
        id: this.conditionEditor.appUuid,
      },
    });
  }

  getRepeaterParentWidgetId(widgetId: string) {
    const widget = this.widgets[widgetId];
    if (widget && widget.parentId !== BASE_PARENT_ID) {
      const parent = this.widgets[widget.parentId];
      return parent.type === "Repeater" ? parent.wid : undefined;
    }
  }

  get headerText() {
    if (this.conditionEditor.isCreating) {
      return this.$t("ConditionsEditor.createACondition").toString();
    }
    return this.$t("ConditionsEditor.updateCondition").toString();
  }

  get nameError(): string | undefined {
    if (this.conditionEditor.showErrors && !this.conditionEditor.nameIsValid) {
      return this.$t("ConditionsEditor.nameRequired").toString();
    }
    return undefined;
  }

  get invalidRulesError(): string | undefined {
    if (
      this.conditionEditor.showErrors &&
      this.conditionEditor.invalidRules.length > 0
    ) {
      return this.$t("ConditionsEditor.invalidRulesError").toString();
    }
    return undefined;
  }

  get errors() {
    const result: string[] = [];
    if (!this.conditionEditor.showErrors) {
      return result;
    }
    if (this.nameError) {
      result.push(this.nameError);
    }
    if (this.invalidRulesError) {
      result.push(this.invalidRulesError);
    }
    return result;
  }

  get isValid() {
    return (
      this.conditionEditor.invalidRules.length === 0 &&
      this.conditionEditor.nameIsValid
    );
  }

  get isSaveDisabled() {
    return this.isSaving || !this.conditionEditor.hasUnsavedChanges;
  }

  onNameChanged(event: Event) {
    const name = (event.target as HTMLInputElement).value.trim();
    this.conditionEditor.changeConditionName(name);
  }

  @Watch("store.totalRules")
  onTotalRulesChanged(newCount: number, oldCount: number) {
    if (newCount > oldCount) {
      this.$nextTick(() => {
        const scroller = this.$refs.scroller as HTMLDivElement;
        scroller.scrollTop = scroller.scrollHeight;
      });
    }
  }
}
</script>
