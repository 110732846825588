<template>
  <Modal @close="close" size="1/2">
    <div
      class="rounded-lg border border-gray-500 text-gray-200 pb-4"
      style="background: #1e1e1e"
    >
      <div
        class="px-6 py-4 flex justify-between items-center border-b border-gray-500"
      >
        <h3 class="text-xl" v-t="'dataPanel.usagePromptTitle'"></h3>

        <OutlineButton
          @click="close"
          class="opacity-75 hover:opacity-100 transition-all"
        >
          {{ $t("dataPanel.cancel") }}
        </OutlineButton>
      </div>

      <div class="p-6 flex flex-col items-center space-y-6">
        <div
          class="text-md flex justify-center"
          v-t="'dataPanel.usagePrompt'"
        ></div>

        <div style="width: 65%" class="flex flex-col space-y-4">
          <button
            class="p-4 rounded-lg w-full shadow-md border border-gray-300"
            :class="{
              'opacity-75 hover:opacity-100 transition-all cursor-pointer':
                !isRepeaterOptionsSlotOpen,
            }"
            v-for="(button, index) in buttons"
            :key="index"
            @click="button.onClick"
          >
            <div class="flex justify-between items-center">
              <div class="flex space-x-4">
                <Icon :name="button.icon" class="h-12 w-12" stroke-width="1" />

                <div class="flex items-start flex-col text-left pr-2">
                  <div class="font-semibold text-md">{{ button.label }}</div>
                  <div class="text-xs leading-tight">
                    {{ button.description }}
                  </div>
                </div>
              </div>

              <div @click="chevronClick">
                <Icon
                  name="ChevronRight"
                  class="w-6 h-6 cursor-pointer"
                  :class="{ 'rotate-90': isRepeaterOptionsSlotOpen }"
                />
              </div>
            </div>

            <!-- Repeater options controls: -->
            <div
              v-if="isRepeaterOptionsSlotOpen"
              class="py-4 px-16 flex flex-col space-y-6"
            >
              <!-- Rows/columns inputs: -->
              <div class="flex space-x-3">
                <div class="flex flex-col space-y-1">
                  <div class="text-xs" v-t="'dataPanel.rows'"></div>
                  <input
                    type="number"
                    min="1"
                    v-model="rows"
                    class="text-gray-700 p-1 py-[3px] rounded w-12"
                  />
                </div>

                <div class="flex flex-col space-y-1">
                  <div class="text-xs" v-t="'dataPanel.columns'"></div>
                  <input
                    type="number"
                    min="1"
                    v-model="columns"
                    class="text-gray-700 p-1 py-[3px] rounded w-12"
                  />
                </div>
              </div>

              <!-- Cycling options radio input: -->
              <div class="flex flex-col space-y-2">
                <div
                  class="flex space-x-2 cursor-pointer"
                  @click="shouldCycle = true"
                >
                  <input type="radio" v-model="shouldCycle" :value="true" />
                  <div>{{ $t("dataPanel.cycleTrueLabel", { size }) }}</div>
                </div>

                <div
                  class="flex space-x-2 cursor-pointer"
                  @click="shouldCycle = false"
                >
                  <input type="radio" v-model="shouldCycle" :value="false" />
                  <div>
                    {{ $t("dataPanel.cycleFalseLabel", { size }) }}
                  </div>
                </div>
              </div>

              <div class="text-xs">
                *{{ $t("dataPanel.assureCanBeChanged") }}
              </div>

              <div class="flex space-x-6">
                <OutlineButton
                  :stopPropagation="true"
                  @click="isRepeaterOptionsSlotOpen = false"
                  >{{ $t("dataPanel.back") }}</OutlineButton
                >

                <button
                  class="text-white bg-gradient-to-b from-green-500 to-green-600 rounded p-2 px-4"
                  @click="confirmCreateRepeater"
                  v-t="'dataPanel.continue'"
                ></button>
              </div>
            </div>
          </button>
        </div>

        <LearnMore>{{ $t("dataPanel.usagePromptLearnMore") }}</LearnMore>
      </div>
    </div>

    <UiBlocker :visible="isLoading">{{
      $t("dataPanel.creatingBinding")
    }}</UiBlocker>

    <div v-if="showError" class="text-red-500 text-center m-2">
      {{ $t("errorCreatingWidget") }}
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import UiBlocker from "@/components/UiBlocker.vue";

import { APP_EDITOR_ROUTE_PATH } from "@/constants";
import LearnMore from "@/components/menus/data/LearnMore.vue";

import { useDragDropStore } from "@/stores/dragDrop";
import OutlineButton from "@/components/OutlineButton.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { Widget, WidgetWithConditions } from "@/components/widgets/Widget";
import { makeId } from "@/utils";
import { getActiveWidget } from "@/util/conditionsUtils";
import { useConditionGroupsStore } from "@/stores/conditionGroups";

@Component({
  components: {
    Modal,
    Icon,
    IconSolid,
    UiBlocker,
    LearnMore,
    OutlineButton,
    ButtonGradient,
  },
})
export default class UsagePrompt extends Vue {
  isLoading = false;
  isRepeaterOptionsSlotOpen = false;
  showError = false;

  rows = 2;
  columns = 3;
  shouldCycle = true;

  // Make clicks "pass through" the chevron icon to the button, unless the repeater options are open
  chevronClick(ev: any) {
    if (this.isRepeaterOptionsSlotOpen) {
      ev.stopPropagation();
      this.isRepeaterOptionsSlotOpen = false;
    }
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get size() {
    return this.rows * this.columns;
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  close(keepDraggingInfo = false) {
    this.dragDropStore.usagePromptIsOpen = false;
    this.dragDropStore.isHandlingDrop = false;
    if (!keepDraggingInfo) this.dragDropStore.draggingInfo = null;
  }

  get buttons(): {
    label: string;
    description: string;
    icon: string;
    onClick: () => void;
  }[] {
    const useAloneButton = {
      label: this.$t("dataPanel.actions.scalar.label") as string,
      description: this.$t("dataPanel.actions.scalar.description") as string,
      icon: "UseOnOwn",
      onClick: () => {
        this.createScalarDataWidget();
      },
    };

    const repeaterButton = {
      label: this.$t("dataPanel.actions.repeater.label") as string,
      description: this.$t("dataPanel.actions.repeater.description") as string,
      icon: "GridThick",
      onClick: () => {
        if (this.isRepeaterOptionsSlotOpen) return;
        this.isRepeaterOptionsSlotOpen = true;
      },
    };

    if (this.isRepeaterOptionsSlotOpen) {
      return [repeaterButton];
    }

    const btns = [
      repeaterButton,
      {
        label: this.$t("dataPanel.actions.slide.label") as string,
        description: this.$t("dataPanel.actions.slide.description") as string,
        icon: "Slider",
        onClick: () => {
          this.createRepeaterWidget({ isSlide: true });
        },
      },
    ];

    if (!this.dragDropStore.preventScalarUsage) btns.push(useAloneButton);

    if (!this.dragDropStore.isReplacingData) return btns;

    // Choosing "Use on its own" (useAloneButton) from a foreign dataset will add a repeated scalar binding.
    const replaceDropOptions = [
      {
        label: this.$t("dataPanel.actions.replace.label") as string,
        description: this.$t("dataPanel.actions.replace.description") as string,
        icon: "Switch",
        onClick: () => {
          this.initiateReplaceData();
        },
      },
    ];
    // NOTE: When "Use on its own" is not available.. might make more sense just to immediately launch replace flow.
    if (!this.dragDropStore.preventScalarUsage)
      replaceDropOptions.push(useAloneButton);

    return replaceDropOptions;
  }

  // When a user clicks "use in a repeating grid" or "use in a slide"
  async createRepeaterWidget(payload?: { isSlide: boolean }) {
    this.showError = false;
    this.isLoading = true;
    const isSlide = payload?.isSlide ?? false;

    const createPayload: any = {
      isSlide,
    };
    if (!isSlide) {
      createPayload.rows = Number(this.rows);
      createPayload.columns = Number(this.columns);
      createPayload.shouldCycle = this.shouldCycle;
    }
    createPayload.repeaterWidgetId = makeId();

    try {
      const hoveredPhotoWid = this.dragDropStore
        .hoveredPhotoDropWidgetId as string;
      const hoveredPhotoConditionId =
        useConditionGroupsStore().getActiveConditionId(hoveredPhotoWid);

      const repeaterWidget = (await this.appEditor.createDynamicWidget(
        {
          isNewRepeater: true,
          hoveredPhotoDropWidgetId: hoveredPhotoWid,
          hoveredPhotoDropWidgetConditionId: hoveredPhotoConditionId,
          draggingInfo: this.dragDropStore.draggingInfo,
          isScalar: false,
          widgetId: makeId(),
        },
        createPayload,
        ""
      )) as Widget;

      this.appEditor.replaceSelections([repeaterWidget.wid]);

      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      const repeater = getActiveWidget(
        repeaterWidget as unknown as WidgetWithConditions,
        conditions
      );

      let offsetX = repeater?.columnGap || 0;
      let offsetY = repeater?.rowGap || 0;

      if (repeater?.rows === 1 && repeater.columns === 1) {
        offsetX = 0;
        offsetY = 0;
      }

      // Enter editing mode for repeater:
      this.appEditor.setEditingContext({
        parentId: repeaterWidget?.wid,
        repeaterIndex: 0,
        widgetX: repeater?.x,
        widgetY: repeater?.y,
        offsetX,
        offsetY,
      });

      await this.appEditor.updateApp();
      this.dragDropStore.hoverTarget = null;
      this.dragDropStore.draggingInfo = null;

      this.close();
    } catch (e) {
      this.showError = true;
    } finally {
      this.isLoading = false;
    }
  }

  // When a user clicks "use on its own"
  async createScalarDataWidget() {
    this.isLoading = true;

    const hoveredPhotoWid = this.dragDropStore
      .hoveredPhotoDropWidgetId as string;
    const hoveredPhotoConditionId =
      useConditionGroupsStore().getActiveConditionId(hoveredPhotoWid);

    try {
      await this.appEditor.createDynamicWidget(
        {
          isScalar: true,
          draggingInfo: this.dragDropStore.draggingInfo,
          hoveredPhotoDropWidgetId: hoveredPhotoWid,
          hoveredPhotoDropWidgetConditionId: hoveredPhotoConditionId,
          isNewRepeater: false,
          widgetId: makeId(),
        },
        null,
        ""
      );
      await this.appEditor.updateApp();
      this.dragDropStore.hoverTarget = null;
      this.dragDropStore.draggingInfo = null;
      this.close();
    } catch (err) {
      this.showError = true;
      console.log("err", err);
    } finally {
      this.isLoading = false;
    }
  }

  get hoveredRepeaterWid() {
    return this.editingContext?.parentId;
  }

  // When a user clicks "replace the dataset bound to this repeater"
  initiateReplaceData() {
    const hoveredRepeaterBinding = this.dataBindings.find(
      (db) =>
        db.bindingType === "DataSetParent" &&
        db.widgetId === this.hoveredRepeaterWid
    );

    const basePath = `/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/connections/${hoveredRepeaterBinding?.dataConnectionUuid}`;
    const subpath = `replace/${this.connectionEditor.connection?.uuid}/preview`;

    this.$router
      .push({
        path: `${basePath}/${subpath}`,
        query: {
          type: "Collection",
          property: "data",
          widgetId: this.hoveredRepeaterWid,
        },
      })
      .then(() => {
        this.close(true);
      });
  }

  mounted() {
    // Ensure repeater stays selected through the replacement process
    if (this.dragDropStore.isReplacingData && this.hoveredRepeaterWid) {
      this.appEditor.replaceSelections([this.hoveredRepeaterWid]);
    }
  }

  confirmCreateRepeater() {
    if (this.rows < 0 || this.columns < 0) return;
    this.createRepeaterWidget();
  }
}
</script>

<style scoped lang="postcss">
input:invalid {
  outline: 2px solid red;
}

input[type="radio"] {
  accent-color: #fff;
}
</style>
