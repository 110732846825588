<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { measureText } from "@/textfit";
import { dimsToUpdateApparentHeight } from "@/utils";
import { TransformOptions } from "../TransformOptions";
import { useDragDropStore } from "@/stores/dragDrop";
import { BASE_PARENT_ID } from "@/constants";
import { useAppEditorStore } from "@/stores/appEditor";
import DatetimeComponent from "./DatetimeComponent.vue";
import { EventBus } from "@/eventbus";
import { contentToHtml } from "@/text";

/**
 * Copied from TextWrapper.vue
 */

@Component({
  components: {},
})
export default class DatetimeWrapper extends DatetimeComponent {
  baseScaleX = 1;
  baseFontSize = 96;

  baseY = 0;
  baseH = 0;

  ignoreListeners = false;

  // We don't want to include scalar-bound text widgets here, because we DO want to updateHeight for them on mount.
  get isDataBoundChildOfRepeater() {
    return this.appEditor.dataBindings.some(
      (db) => db.widgetId === this.wid && db.property === "content"
    );
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  mounted() {
    this.baseY = this.y;
    this.baseH = this.h;
    this.baseFontSize = this.fontSize || 96;
    this.baseScaleX = this.scaleX || 1;

    const el = this.$el as HTMLDivElement;
    el.addEventListener("mouseenter", this.mouseenter);
    el.addEventListener("mouseleave", this.mouseleave);
    EventBus.on("UPDATE_FONT_SIZE", this.updateFontSize);
    EventBus.on("IGNORE_TEXT_WIDGET_LISTENERS", this.setIgnoreListeners);
    EventBus.on("DATA_TOKEN_ADDED", this.updateHeightIfNeeded);

    if (!this.isDataBoundChildOfRepeater) {
      this.$nextTick(this.updateHeight);
    }
  }

  updateHeightIfNeeded() {
    // We only need to update heights for child widgets with dynamic content:
    const isDataBound = this.appEditor.dataBindings.some(
      (db) => db.widgetId === this.wid && db.property === "content"
    );
    if (!isDataBound) return;

    // We need to determine which index/record this text wrapper is representing -- that is, which cell it shows up in, within the repeater.
    // We only want to update the height of text widget(s) living within that cell (based on their dynamic content).
    if (this.cellIndex === this.editingContext.repeaterIndex) {
      this.$nextTick(this.updateHeight);
    }
  }

  setIgnoreListeners(val: boolean) {
    this.ignoreListeners = val;
  }

  created() {
    // Ensure that whenever a user clicks into a repeater cell to edit it (or creates a repeater),
    // we set the text widget's height to match the dynamic content that inhabits THAT CELL's text widget.
    this.updateHeightIfNeeded();

    this.$watch(
      () => this.editingContext,
      () => {
        this.updateHeightIfNeeded();
      }
    );
  }

  beforeDestroy() {
    const el = this.$el as HTMLDivElement;
    el.removeEventListener("mouseenter", this.mouseenter);
    el.removeEventListener("mouseleave", this.mouseleave);
    EventBus.off("UPDATE_FONT_SIZE", this.updateFontSize);
    EventBus.off("IGNORE_TEXT_WIDGET_LISTENERS", this.setIgnoreListeners);
    EventBus.off("DATA_TOKEN_ADDED", this.updateHeightIfNeeded);
  }

  updateFontSize() {
    this.$nextTick(() => {
      this.baseFontSize = this.fontSize || 96;
      this.baseScaleX = this.scaleX || 1;
    });
  }

  /**
   * This is a special method that is called by WidgetResizer
   * when the widget is resized by the user.
   * @returns Promise of resized props to apply to the widget
   */
  handleResize() {
    // This is useful for when we figure out track  true/false
    return Promise.resolve({ h: this.h, y: this.y, fontSize: this.fontSize });
  }

  get watchedTextMeasureObject() {
    const watchedObject: Record<string, any> = {
      textTransform: this.textTransform,
      fontFamily: this.fontFamily,
      fontWeight: this.fontWeight,
      fontStyle: this.fontStyle,
      fontSize: this.fontSize,
      letterSpacing: this.letterSpacing,
      lineHeight: this.lineHeight,
    };

    // Only difference from TextWrapper
    watchedObject.datetimeFormat = this.datetimeFormat;
    watchedObject.datetimeValue = this.datetimeValue;

    return watchedObject;
  }

  @Watch("watchedTextMeasureObject", { deep: true })
  onWatchedPropsChanged() {
    if (!this.appEditor.isDraggingWidget && !this.ignoreListeners) {
      this.updateHeight();
    }
  }

  updateHeight() {
    const props = this.measureTextDimensions();
    this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
  }

  /**
   * Returns the height and y position of the text, given the current text options.
   */
  measureTextDimensions(): { h: number; y: number } {
    const size = measureText(this as any, this.html);
    const { h, y } = dimsToUpdateApparentHeight(
      {
        ...this.$props,
        ...{ h: this.baseH, y: this.baseY },
      } as TransformOptions,
      size.h
    );

    return { h, y };
  }

  get html() {
    return contentToHtml(this.content as any);
  }

  @Watch("y")
  yChanged() {
    this.baseY = this.y;
  }

  @Watch("h")
  hChanged() {
    this.baseH = this.h;
  }

  @Watch("scaleX")
  onWidthChanged() {
    if (this.ignoreListeners) return;
    this.$nextTick(() => {
      // Horizontal resize
      if (this.appEditor.dragResizeDimension === "x") {
        // Store these when middle handle is dragged to keep a baseline for font scaling:
        this.baseScaleX = this.scaleX;
        this.baseFontSize = this.fontSize || 96;
        const props = this.measureTextDimensions();
        this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
      } else {
        // Corner handle resize
        this.baseY = this.y;
        this.baseH = this.h;

        // Ok, this seems to solve middle-then-corner bug:
        let fontSize = (this.baseFontSize * this.scaleX) / this.baseScaleX;
        fontSize = Math.round(fontSize);

        const props = { fontSize };
        this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
      }
    });
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get dragDropStore() {
    return this.appEditor.appMode !== "render" ? useDragDropStore() : null;
  }

  waitingToEnterTimeout: number;

  mouseenter() {
    if (this.ignoreMouseEnter) return;
    this.waitingToEnterTimeout = window.setTimeout(this.enterHoverState, 100);
  }

  enterHoverState() {
    if (!this.dragDropStore) return;
    this.dragDropStore.hoverTarget = {
      widgetId: this.wid,
      widgetType: "Text",
    };
    this.appEditor.enterTextEditMode(this.wid);
    this.appEditor.replaceSelections([this.wid]);
  }

  get ignoreMouseEnter() {
    if (!this.dragDropStore?.isDraggingItem) return true;
    if (this.dragDropStore?.isDraggingImage) return true;
    // Ignore mouseenter events on Datetime components (which utilize the Text component)
    if (this.type !== "Text") return true;

    /**
     * If user is dragging a node that satisfies the following condition, prevent user from dropping it in
     * (because it creates a difficult-to-otherwise-fix bug):
     * From same column as some node/token we are already bound to, in this text widget.
     */

    const allMyBoundDataUuids = this.appEditor.dataBindings
      .filter((db) => db.widgetId === this.wid && db.bindingType === "Scalar")
      .map((db) => db.dataUuid);

    const nodeIsFromDisallowedColumn = allMyBoundDataUuids.includes(
      this.dragDropStore.draggingInfo?.dataUuid as string
    );

    if (nodeIsFromDisallowedColumn) return true;

    /**
     * If user is dragging a data index node, moderated-out node, or calendar node (i.e. preventScalarUsage is true)...
     * We must ignore mouse enter, UNLESS the text component has a parent (repeater) that is either:
     * - not bounded to a dataset, or
     * - bound to the same dataset the dragged node is from.
     */
    if (this.dragDropStore?.preventScalarUsage) {
      if (this.parentId === BASE_PARENT_ID) return true;
      const hoveredRepeaterBinding = this.appEditor.dataBindings.find(
        (db) =>
          db.bindingType === "DataSetParent" && db.widgetId === this.parentId
      );
      if (!hoveredRepeaterBinding) return false;
      const isFromForeignDataset =
        hoveredRepeaterBinding.dataConnectionUuid !==
        this.dragDropStore?.draggingInfo?.connectionUuid;
      return isFromForeignDataset;
    }
    return false;
  }

  mouseleave() {
    window.clearTimeout(this.waitingToEnterTimeout);
  }
}
</script>
<style scoped>
.text-widget {
  white-space: pre-wrap;
}
</style>
