<template>
  <svg dataType="ProgressBar" :width="width" :height="height">
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        :id="svgId('fgFill-Linear-grad')"
        :gradientTransform="fgFillGradientTransform"
      >
        <stop :stop-color="fgFillColor_1" :offset="fgFillOffset_1 + '%'" />
        <stop :stop-color="fgFillColor_2" :offset="fgFillOffset_2 + '%'" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        :id="svgId('stroke-Linear-grad')"
        :gradientTransform="strokeGradientTransform"
      >
        <stop :stop-color="strokeColor_1" :offset="strokeOffset_1 + '%'" />
        <stop :stop-color="strokeColor_2" :offset="strokeOffset_2 + '%'" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        :id="svgId('bgFill-Linear-grad')"
        :gradientTransform="bgFillGradientTransform"
      >
        <stop :stop-color="bgFillColor_1" :offset="bgFillOffset_1 + '%'" />
        <stop :stop-color="bgFillColor_2" :offset="bgFillOffset_2 + '%'" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        :id="svgId('dividerFill-Linear-grad')"
        :gradientTransform="dividerFillGradientTransform"
      >
        <stop
          :stop-color="dividerFillColor_1"
          :offset="dividerFillOffset_1 + '%'"
        />
        <stop
          :stop-color="dividerFillColor_2"
          :offset="dividerFillOffset_2 + '%'"
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        :id="svgId('dividerStroke-Linear-grad')"
        :gradientTransform="dividerStrokeGradientTransform"
      >
        <stop
          :stop-color="dividerStrokeColor_1"
          :offset="dividerStrokeOffset_1 + '%'"
        />
        <stop
          :stop-color="dividerStrokeColor_2"
          :offset="dividerStrokeOffset_2 + '%'"
        />
      </linearGradient>
      <radialGradient :id="svgId('fgFill-Radial-grad')">
        <stop :stop-color="fgFillColor_1" :offset="fgFillOffset_1 + '%'" />
        <stop :stop-color="fgFillColor_2" :offset="fgFillOffset_2 + '%'" />
      </radialGradient>
      <radialGradient :id="svgId('stroke-Radial-grad')">
        <stop :stop-color="strokeColor_1" :offset="strokeOffset_1 + '%'" />
        <stop :stop-color="strokeColor_2" :offset="strokeOffset_2 + '%'" />
      </radialGradient>
      <radialGradient :id="svgId('bgFill-Radial-grad')">
        <stop :stop-color="bgFillColor_1" :offset="bgFillOffset_1 + '%'" />
        <stop :stop-color="bgFillColor_2" :offset="bgFillOffset_2 + '%'" />
      </radialGradient>
      <radialGradient :id="svgId('dividerStroke-Radial-grad')">
        <stop
          :stop-color="dividerFillColor_1"
          :offset="dividerFillOffset_1 + '%'"
        />
        <stop
          :stop-color="dividerFillColor_2"
          :offset="dividerFillOffset_2 + '%'"
        />
      </radialGradient>
      <radialGradient :id="svgId('dividerFill-Radial-grad')">
        <stop
          :stop-color="dividerFillColor_1"
          :offset="dividerFillOffset_1 + '%'"
        />
        <stop
          :stop-color="dividerFillColor_2"
          :offset="dividerFillOffset_2 + '%'"
        />
      </radialGradient>

      <mask :id="svgId('mask')" maskUnits="userSpaceOnUse">
        <rect
          class="foreground"
          fill="white"
          :width="width"
          :height="barHeight"
          x="0"
          :y="graphHeight - barHeight"
        />
      </mask>
    </defs>
    <g :transform="barTransform">
      <rect
        class="background"
        :fill="barStyle.background.fill"
        :stroke="barStyle.background.stroke"
        :stroke-width="strokeWidth"
        :width="bgWidth"
        :height="bgHeight"
        :rx="borderRadius"
        :x="(graphWidth - bgWidth) / 2"
        :y="strokeWidth / 2"
      />
      <rect
        class="foreground"
        :mask="svgUrl('#mask')"
        :fill="barStyle.foreground.fill"
        :stroke="barStyle.foreground.stroke"
        :stroke-width="strokeWidth"
        :width="fgWidth"
        :height="fgHeight"
        :x="strokeWidth / 2"
        :y="strokeWidth / 2"
        :rx="borderRadius"
      />
      <rect
        class="divider"
        v-if="showDivider"
        :fill="barStyle.divider.fill"
        :width="dividerBarWidth"
        :height="barStyle.divider.height"
        :stroke="barStyle.divider.stroke"
        :stroke-width="dividerStrokeWidth"
        :x="(graphWidth - dividerBarWidth) / 2"
        :y="dividerPosition"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { parseGradientCss } from "@/utils";
import * as d3 from "d3";

@Component({})
export default class ProgressBarComponent extends Vue {
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly minValue: number;
  @Prop(Number) readonly maxValue: number;
  @Prop(Number) readonly currentValue: number;
  @Prop(String) readonly bgFill: string;
  @Prop(Number) readonly fgPadding: number;
  @Prop(String) readonly fgFill: string;
  @Prop(String) readonly stroke: string;
  @Prop(Number) readonly strokeWidth: number;
  @Prop(Boolean) readonly showDivider: boolean;
  @Prop(String) readonly dividerFill: string;
  @Prop(String) readonly dividerStroke: string;
  @Prop(Number) readonly dividerStrokeWidth: number;
  @Prop(Number) readonly borderRadius: number;
  @Prop(Number) readonly dividerHeight: number;
  @Prop(Number) readonly dividerWidth: number;
  @Prop(String) readonly wid: string;
  @Prop(Number) readonly cellIndex?: number;

  svgUrl(fragmentId: string): string {
    const id = this.svgId(fragmentId.replace("#", ""));
    return this.$helpers.svgUrl(`#${id}`);
  }

  svgId(id: string): string {
    if (this.cellIndex !== undefined) {
      return `${this.wid}-${id}-${this.cellIndex}}`;
    }
    return `${this.wid}-${id}`;
  }

  get width() {
    return this.w * this.scaleX;
  }

  get height() {
    return this.h * this.scaleY;
  }

  get graphMargin() {
    let x = this.width * 1.2;
    let y = this.height * 1.1;
    return `${y}px, ${x}px`;
  }

  get graphWidth() {
    return this.width * 0.7;
  }

  get graphHeight() {
    return this.height * 0.8;
  }

  get dividerTotalHeight() {
    return this.barStyle.divider.height + this.dividerStrokeWidth;
  }

  //This ensures dividerWidth doesn't go to 0 when strokeWidth is equal or greater to it.
  get dividerBarWidth() {
    return Math.max(1, this.barStyle.divider.width - this.dividerStrokeWidth);
  }

  get bgWidth() {
    return Math.max(1, this.graphWidth - this.strokeWidth);
  }

  get bgHeight() {
    return this.graphHeight - this.strokeWidth;
  }

  get fgWidth() {
    return this.graphWidth - this.strokeWidth;
  }

  get fgHeight() {
    return this.graphHeight - this.strokeWidth;
  }

  get y() {
    return d3
      .scaleLinear()
      .range([this.graphHeight, 0])
      .domain([this.minValue, this.maxValue]);
  }

  get y2() {
    return d3
      .scaleLinear()
      .range([this.graphHeight - this.strokeWidth / 2, this.strokeWidth / 2])
      .domain([this.minValue, this.maxValue]);
  }

  get barHeight() {
    return this.graphHeight - this.y(this.currentValue);
  }

  get dividerPosition() {
    return this.y(this.currentValue) - this.barStyle.divider.height / 2;
  }

  get cornerRadius() {
    return this.borderRadius / 10;
  }

  get barStyle() {
    let background = {
      fill:
        this.bgFillGradientParts.type === "Solid"
          ? this.bgFill
          : this.bgFillGradientFill,
      stroke:
        this.strokeGradientParts.type === "Solid"
          ? this.stroke
          : this.strokeGradientFill,
    };
    let foreground = {
      fill:
        this.fgFillGradientParts.type === "Solid"
          ? this.fgFill
          : this.fgFillGradientFill,
      padding: this.width * (this.fgPadding / 100),
      stroke:
        this.strokeGradientParts.type === "Solid"
          ? this.stroke
          : this.strokeGradientFill,
    };
    let divider = {
      fill:
        this.dividerFillGradientParts.type === "Solid"
          ? this.dividerFill
          : this.dividerFillGradientFill,
      height: this.dividerHeight * (this.height / 100),
      width: this.graphWidth * (this.dividerWidth / 100),
      stroke:
        this.dividerStrokeGradientParts.type === "Solid"
          ? this.dividerStroke
          : this.dividerStrokeGradientFill,
      strokeOffset:
        (this.width * this.dividerWidth) / 100 -
        (this.width * this.dividerStrokeWidth) / 100,
    };

    return { background, foreground, divider };
  }

  get barTransform() {
    return (
      "translate(" +
      (this.width - this.graphWidth) / 2 +
      "," +
      (this.height - this.graphHeight) / 2 +
      ")"
    );
  }
  //This section needs to be reduced.
  //Foreground Fill Gradient
  get fgFillGradientFill() {
    return this.svgUrl(`#fgFill-${this.fgFillGradientParts.type}-grad`);
  }
  get fgFillGradientTransform() {
    const angle = this.fgFillGradient_angle;

    return `rotate(${angle + 90}, ${this.width / 2}, ${this.height / 2})`;
  }
  get fgFillGradientParts() {
    const parts = parseGradientCss(this.fgFill);
    return parts;
  }
  get fgFillColor_1() {
    return this.fgFillGradientParts.color_1;
  }
  get fgFillColor_2() {
    return this.fgFillGradientParts.color_2;
  }
  get fgFillOffset_1() {
    return this.fgFillGradientParts.offset_1 || 0;
  }
  get fgFillOffset_2() {
    return this.fgFillGradientParts.offset_2 || 100;
  }
  get fgFillGradient_angle() {
    return this.fgFillGradientParts.angle || 0;
  }

  //Stroke Gradient
  get strokeGradientFill() {
    return this.svgUrl(`#stroke-${this.strokeGradientParts.type}-grad`);
  }
  get strokeGradientTransform() {
    const angle = this.strokeGradient_angle;

    return `rotate(${angle + 90}, ${this.width / 2}, ${this.height / 2})`;
  }
  get strokeGradientParts() {
    const parts = parseGradientCss(this.stroke);
    return parts;
  }
  get strokeColor_1() {
    return this.strokeGradientParts.color_1;
  }
  get strokeColor_2() {
    return this.strokeGradientParts.color_2;
  }
  get strokeOffset_1() {
    return this.strokeGradientParts.offset_1 || 0;
  }
  get strokeOffset_2() {
    return this.strokeGradientParts.offset_2 || 100;
  }
  get strokeGradient_angle() {
    return this.strokeGradientParts.angle || 0;
  }

  //Background Fill Gradient
  get bgFillGradientFill() {
    return this.svgUrl(`#bgFill-${this.bgFillGradientParts.type}-grad`);
  }
  get bgFillGradientTransform() {
    const angle = this.bgFillGradient_angle;

    return `rotate(${angle + 90}, ${this.width / 2}, ${this.height / 2})`;
  }
  get bgFillGradientParts() {
    const parts = parseGradientCss(this.bgFill);
    return parts;
  }
  get bgFillColor_1() {
    return this.bgFillGradientParts.color_1;
  }
  get bgFillColor_2() {
    return this.bgFillGradientParts.color_2;
  }
  get bgFillOffset_1() {
    return this.bgFillGradientParts.offset_1 || 0;
  }
  get bgFillOffset_2() {
    return this.bgFillGradientParts.offset_2 || 100;
  }
  get bgFillGradient_angle() {
    return this.bgFillGradientParts.angle || 0;
  }

  //Divider Fill Gradient
  get dividerFillGradientFill() {
    return this.svgUrl(
      `#dividerFill-${this.dividerFillGradientParts.type}-grad`
    );
  }
  get dividerFillGradientTransform() {
    const angle = this.dividerFillGradient_angle;

    return `rotate(${angle + 90}, ${this.width / 2}, ${this.height / 2})`;
  }
  get dividerFillGradientParts() {
    const parts = parseGradientCss(this.dividerFill);
    return parts;
  }
  get dividerFillColor_1() {
    return this.dividerFillGradientParts.color_1;
  }
  get dividerFillColor_2() {
    return this.dividerFillGradientParts.color_2;
  }
  get dividerFillOffset_1() {
    return this.dividerFillGradientParts.offset_1 || 0;
  }
  get dividerFillOffset_2() {
    return this.dividerFillGradientParts.offset_2 || 100;
  }
  get dividerFillGradient_angle() {
    return this.dividerFillGradientParts.angle || 0;
  }

  //Divider Stroke Gradient
  get dividerStrokeGradientFill() {
    return this.svgUrl(
      `#dividerStroke-${this.dividerStrokeGradientParts.type}-grad`
    );
  }
  get dividerStrokeGradientTransform() {
    const angle = this.dividerStrokeGradient_angle;

    return `rotate(${angle + 90}, ${this.width / 2}, ${this.height / 2})`;
  }
  get dividerStrokeGradientParts() {
    const parts = parseGradientCss(this.dividerStroke);
    return parts;
  }
  get dividerStrokeColor_1() {
    return this.dividerStrokeGradientParts.color_1;
  }
  get dividerStrokeColor_2() {
    return this.dividerStrokeGradientParts.color_2;
  }
  get dividerStrokeOffset_1() {
    return this.dividerStrokeGradientParts.offset_1 || 0;
  }
  get dividerStrokeOffset_2() {
    return this.dividerStrokeGradientParts.offset_2 || 100;
  }
  get dividerStrokeGradient_angle() {
    return this.dividerStrokeGradientParts.angle || 0;
  }
}
</script>

<style scoped></style>
