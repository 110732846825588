<template>
  <div>
    <section data-testid="authenticator">
      <component
        :is="getAuthenticator(props.setupStep?.authenticator?.type)"
        :authenticator="props.setupStep?.authenticator"
        @is-satisifed="(isSatisfied) => (isAuthSatisifed = isSatisfied)"
      />
    </section>

    <section data-testid="inputs">
      <div v-for="input in validationInputs" :key="input.callbackModelKey">
        <component :is="getInputComponent(input.type)" :input="input" />
      </div>
    </section>

    <div data-testid="error" class="mt-2 text-red-500" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <portal to="setupWizardNext">
      <FormButton
        :disabled="!canContinue"
        class="dark-form-focus"
        v-t="'continue'"
        @click="onComplete"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";
import TextInput from "./provide/components/inputs/TextInput.vue";
import FormButton from "@/components/FormButton.vue";
import {
  AuthenticatorType,
  InputType,
  Input,
  ProvideStep,
  ValidationInput,
} from "@/types/ConnectionSources";

const props = defineProps<{
  setupStep: ProvideStep | undefined;
}>();

const emits = defineEmits<{
  (e: "complete", value: ProvideStep): void;
}>();

const isAuthSatisifed = ref(false);
const errorMessage = ref<string | undefined>();
const validationInputs = ref<ValidationInput[] | undefined>([]);
const setupStore = useConnectionSetupStoreV2();

onMounted(() => {
  validationInputs.value = buildValidationInputs(props.setupStep?.inputs);
});

const canContinue = computed(() => {
  return !setupStore.hasUnhandledServerError && isModelValid.value;
});

const isModelValid = computed(() => {
  return validationInputs.value?.every((input) => input.isValid) ?? false;
});

function buildValidationInputs(
  inputs: Input[] | null | undefined
): ValidationInput[] | undefined {
  return inputs?.map((input) => {
    return {
      ...input,
      value: undefined,
      isValid: false,
    };
  });
}

function buildCallbackModel(inputs: ValidationInput[] | null | undefined) {
  const callbackModel: Record<string, any> = {};
  inputs?.forEach((input) => {
    callbackModel[input.callbackModelKey] = input.value;
  });
  return callbackModel;
}

function onComplete() {
  errorMessage.value = undefined;
  if (props.setupStep?.endpoint) {
    const callbackModel = buildCallbackModel(validationInputs.value);
    const payload = {
      model: callbackModel,
    };

    setupStore
      .loadSchema(props.setupStep?.endpoint, payload)
      .then(() => {
        emits("complete", props.setupStep!);
      })
      .catch((error) => {
        errorMessage.value = error.message;
      });
  }
}

function getInputComponent(type: InputType | undefined) {
  switch (type) {
    case "Text":
      return TextInput;
    default:
      return undefined;
  }
}

function getAuthenticator(type: AuthenticatorType | undefined) {
  switch (type) {
    default:
      return undefined;
  }
}
</script>
