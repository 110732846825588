import { defineStore } from "pinia";

export interface MainAppState {
  recentlyUsedFonts: string[];
}

/**
 * The purpose of this store is to keep track of info that should be available when a user swaps between apps, and no matter what page they first load.
 */

export const useMainAppStore = defineStore("mainApp", {
  state: (): MainAppState => {
    return {
      recentlyUsedFonts: [],
    };
  },

  actions: {
    addFont(font: string) {
      if (this.recentlyUsedFonts.includes(font)) {
        return;
      }

      if (this.recentlyUsedFonts.length === 3) {
        this.recentlyUsedFonts.shift();
      }
      this.recentlyUsedFonts.push(font);
    },
  },
});
