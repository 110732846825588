<template>
  <div class="divide-y">
    <div class="text-settings px-4">
      <div class="my-3 flex space-x-4">
        <FontFamilyInput
          class="w-38"
          :value="fontFamily"
          @input="apply({ fontFamily: $event })"
        />
        <FontSizeInput :value="fontSize" @input="updateFontSize" />
      </div>

      <div class="flex my-3">
        <FontVariantInput
          :family="fontFamily"
          :value="fontVariant"
          @input="onVariantSelected"
          class="w-40"
        />

        <ScalarBinder
          propName="textColor"
          propType="Color"
          v-slot:default="slotProps"
        >
          <ColorInput
            class="ml-6"
            iconSize="5"
            :value="slotProps.value"
            :dataBound="slotProps.isBound"
            @input="preview({ textColor: $event })"
            @change="apply({ textColor: $event })"
          />
        </ScalarBinder>
      </div>
      <div class="flex w-full px-3">
        <div class="line-height flex items-center mr-12 space-x-1">
          <Icons name="LineHeight" class="w-6 h-6 mr-px" />
          <EditorNumberInput
            class="w-14"
            :step="0.1"
            :controls="true"
            :value="lineHeight"
            @change="onLineHeightChanged"
          />
        </div>
        <div class="letter-spacing flex items-center space-x-1">
          <Icons name="LetterSpacing" class="w-6 h-6 mr-px" />
          <EditorNumberInput
            class="w-14"
            :controls="true"
            :value="letterSpacing"
            @change="updateLetterSpacing"
          />
        </div>
      </div>

      <div class="py-3 px-1 flex space-x-10">
        <EditorRadioInput
          type="icon"
          class="mt-2 w-24"
          :value="alignHorizontal"
          :options="hAlignOptions"
          @input="apply({ alignHorizontal: $event })"
        />

        <TextTransformInput
          class="mt-2 w-24"
          :value="textTransform"
          @input="updateTextTransform($event)"
        />
      </div>
    </div>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'shadow'"></div>
      </template>
      <div class="divide-y">
        <ShadowEditor />
      </div>
    </CollapsePanel>
    <CollapsePanel v-if="showAnimationPanel">
      <template slot="title">
        <div v-t="'animation.animation'"></div>
      </template>
      <AnimationEditor />
    </CollapsePanel>
    <div>
      <BaseWidgetEditor
        :selectionLocked="getValue('locked')"
        :showLockAspectToggle="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { FontVariant } from "@/types";

// Components
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import EditorModuleSection from "@/components/editors/EditorModuleSection.vue";
import ColorInput from "@/components/inputs/ColorInput.vue";
import EditorRadioInput from "@/components/inputs/EditorRadioInput.vue";
import Icon from "@/components/Icon.vue";
import Icons from "@/components/icons/Icon.vue";
import ButtonIcon from "@/components/ButtonIcon.vue";
import Modal from "@/components/Modal.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import FontSizeInput from "@/components/inputs/FontSizeInput.vue";
import ComponentEditorButton from "@/components/ComponentEditorButton.vue";
import ComponentEditorPanel from "@/components/ComponentEditorPanel.vue";
import NumberSliderInput from "@/components/inputs/NumberSliderInput.vue";
import TextTransformInput from "@/components/inputs/TextTransformInput.vue";

import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import FontVariantInput from "@/components/inputs/FontVariantInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ShadowEditor from "@/components/editors/ShadowEditor.vue";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { EventBus } from "@/eventbus";
import AnimationEditor from "@/components/editors/AnimationEditor.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { animationAllowed } from "@/components/widgets/animationAllowed";
@Component({
  components: {
    AnimationEditor,
    ColorInput,
    EditorNumberInput,
    EditorModuleSection,
    EditorRadioInput,
    ButtonIcon,
    Icon,
    Icons,
    Modal,
    FontFamilyInput,
    FontSizeInput,
    ComponentEditorPanel,
    ComponentEditorButton,
    NumberSliderInput,
    FormLabel,
    EditorSelectMenu,
    BaseWidgetEditor,
    CollapsePanel,
    ShadowEditor,
    ScalarBinder,
    FontVariantInput,
    TextTransformInput,
  },
})
export default class TextEditor extends Vue {
  // NOTE: Does this component need to load fonts? I don't think so...

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get showAnimationPanel() {
    return animationAllowed(this.selectedWidget);
  }

  // TODO figure out how to share this across components...
  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];
      result = values[0];
    }
    return result as T;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps(this.appEditor.selections, props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps(this.appEditor.selections, props, "NO_UNDO");
  }

  get dataParentUuid() {
    if (this.selectedWidget) {
      const bindings = this.appEditor.bindingsForComponent({
        widgetId: this.selectedWidget.parentId,
        // bindingType: "DataSet"
      });
      if (bindings.length > 0) {
        return bindings[0].dataUuid;
      }
    }
    return undefined;
  }

  get showDataPanel() {
    return typeof this.dataParentUuid === "string";
  }

  get alignHorizontal() {
    return this.getValue<string>("alignHorizontal");
  }

  get vAlignOptions() {
    return ["top", "middle", "bottom"].map((text) => ({
      label: `text-${text}`,
      value: text,
    }));
  }

  get hAlignOptions() {
    return ["Left", "Center", "Right"].map((text) => ({
      label: `TextAlign${text}`,
      value: text,
    }));
  }

  get fontFamily() {
    return this.getValue("fontFamily");
  }

  get textColor() {
    return this.getValue<string>("textColor");
  }

  // Font variation ------------------------------------------- //

  get fontWeight() {
    return this.getValue("fontWeight");
  }

  get fontStyle() {
    return this.getValue("fontStyle");
  }

  get fontVariant() {
    return { style: this.fontStyle, weight: this.fontWeight };
  }

  onVariantSelected(variant: FontVariant) {
    this.apply({ fontWeight: variant.weight, fontStyle: variant.style });
  }

  // Font Size ------------------------------------------- //

  get fontSize() {
    return this.getValue<number>("fontSize");
  }

  updateFontSize(val: number) {
    this.apply({ fontSize: val });
    EventBus.emit("UPDATE_FONT_SIZE", val);
  }

  // Letter Spacing ------------------------------------------- //

  get letterSpacing() {
    return this.getValue("letterSpacing");
  }

  updateLetterSpacing(value: number) {
    this.apply({ letterSpacing: value });
  }

  // Line Height ----------------------------------------------- //

  get lineHeight() {
    return this.getValue("lineHeight");
  }

  onLineHeightChanged(value: number) {
    this.apply({ lineHeight: value });
  }

  // Text Formatting ------------------------------------------- //

  get textTransform() {
    return this.getValue("textTransform");
  }

  updateTextTransform(value: string) {
    if (value === this.textTransform) {
      this.apply({ textTransform: "none" });
    } else {
      this.apply({ textTransform: value });
    }
  }
}
</script>

<style lang="postcss" scoped>
.textStyle {
  max-width: 14rem;
}
.textStyle:hover {
  background: #ddd;
}
.text-styles {
  top: 25px;
  left: 0px;
  z-index: 10000;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
