<template>
  <div class="flex relative z-10 space-x-1">
    <button
      type="button"
      :key="item.value"
      :disabled="disabled"
      v-for="item in items"
      class="rounded-sm hover:bg-gray-200 flex-1"
      :class="[item.selected ? 'btn-radio-active z-20 ' + activeColor : '']"
      @click="update(item.value)"
    >
      <Icon
        v-if="type === 'icon'"
        :name="item.label"
        fill="currentColor"
        class="w-full h-full p-1"
      />
      <span
        v-else
        class="block p-1 select-none"
        :style="textLabelStyle"
        v-t="item.label"
      ></span>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import { InputOption } from "@/types/inputs";

@Component({
  components: { Icon },
})
export default class EditorRadioInput extends Vue {
  @Prop() value: string | number | boolean;
  @Prop({ type: String, default: "text" }) type: string;
  @Prop({ default: null }) customFill: string;
  @Prop(Array) options: InputOption[];
  @Prop(Boolean) disabled: boolean;

  get items() {
    return this.options.map((item) => {
      return Object.assign({}, item, { selected: this.value === item.value });
    });
  }

  get activeColor() {
    return this.customFill ?? "bg-gray-400 border-gray-400";
  }

  get textLabelStyle() {
    const result: any = {};
    (result.fontFamily = "Inter"), "sans-serif";
    return result;
  }

  update(value: string | number | boolean) {
    this.$emit("input", value);
  }
}
</script>
