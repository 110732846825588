<template>
  <div class="bbox" v-if="visible">
    <div
      class="bbox-edge"
      :style="line"
      :key="$index"
      v-for="(line, $index) in lines"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Rectangle } from "@/types";
import { getBoundingBox } from "../utils";
import { useAppEditorStore } from "@/stores/appEditor";
import { TransformOptions } from "./widgets/TransformOptions";

const rectCss = (rect: Rectangle) => {
  return {
    top: `${rect.y}px`,
    left: `${rect.x}px`,
    width: `${rect.w}px`,
    height: `${rect.h}px`,
  };
};

@Component
export default class BoundingBox extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get visible() {
    return (
      this.selections.length > 1 && this.box && this.box.w > 0 && this.box.h > 0
    );
  }

  get lines() {
    let b = this.box;
    return (
      b &&
      [
        { y: b.y, x: b.x, w: b.w, h: 1 }, // Top edge
        { y: b.y, x: b.x + b.w - 1, w: 1, h: b.h }, // Right edge
        { y: b.y + b.h - 1, x: b.x, w: b.w, h: 1 }, // Bottom edge
        { y: b.y, x: b.x, w: 1, h: b.h }, // Left edge
      ].map(rectCss)
    );
  }

  get selectedWidgets() {
    return this.selections
      .map((wid) => this.appEditor.widgetById(wid))
      .filter((w) => w !== undefined) as TransformOptions[];
  }

  get box() {
    const bbox = getBoundingBox(this.selectedWidgets);
    return this.appEditor.scaleForEditLayer(bbox);
  }
}
</script>

<style scoped>
.bbox {
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
}
.bbox-edge {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
}
</style>
