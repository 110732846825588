import { EventBus } from "@/eventbus";
import { GOOGLE_FONTS_NO_ITALICS } from "@/fonts/google-fonts-no-italics";
import { FontAssetInfo } from "@/types/bundleTypes";
import { logger } from "@core/logger";
import FontFaceObserver from "fontfaceobserver";
import isEqual from "lodash.isequal";
import uniqWith from "lodash.uniqwith";

//////////////////////////////////////////////////////////////////////////////////////////
//  INTERNAL METHODS
//////////////////////////////////////////////////////////////////////////////////////////

const addLinkElement = (fonts: FontAssetInfo[], id: string) => {
  if (Array.isArray(fonts) && fonts.length > 0) {
    const familyNames = Array.from(new Set(fonts.map((f) => f.family)));

    // See https://developers.google.com/fonts/docs/css2#forming_api_urls
    const params = familyNames.map((f) => {
      const italicWeights: number[] = [];
      const normalWeights: number[] = [];

      fonts.forEach((font) => {
        if (font.family === f) {
          if (font.style === "italic") {
            italicWeights.push(font.weight);
          } else {
            normalWeights.push(font.weight);
          }
        }
      });

      italicWeights.sort((a, b) => a - b);
      normalWeights.sort((a, b) => a - b);

      const axis_tuples = normalWeights
        .map((w) => `0,${w}`)
        .concat(italicWeights.map((w) => `1,${w}`))
        .join(";");
      return `family=${f}:ital,wght@${axis_tuples}`;
    });

    const href = `https://fonts.googleapis.com/css2?${params.join("&")}`;

    const selectorId = `fonts-${id}`;
    let link = document.querySelector(`#${selectorId}`);

    if (link === null) {
      link = document.createElement("link") as HTMLLinkElement;
      link.setAttribute("id", selectorId);
      link.setAttribute("rel", "stylesheet");
      link.addEventListener("error", (e) => {
        link?.parentElement?.removeChild(link);
        logger.track(
          `Font stylesheet failed to load ${selectorId}. Url ${href.toString()}`
        );
      });
      document.head.appendChild(link);
    }

    link.setAttribute("href", href);

    // Wait for a moment to allow the DOM to update
    return new Promise((resolve) => setTimeout(resolve, 25));
  }

  return Promise.resolve();
};

const handleItalics = (font: FontAssetInfo) => {
  /**
   * We need to account for the fact that we are allowing users to
   * choose Italic variants for fonts that do not support Italics.
   *
   * This is bad because if we attempt to load the italic version
   * of a font from Google, and it does not exist, then FontFaceObserver
   * will wait the full `timeout` duration to conclude the font
   * doesn't exist.
   *
   * Snapshots and Player API "readyToPlay" notifications will be
   * needlessly delayed.
   *
   * So, for any 'Italic' font that we know doesn't exist, we'll
   * force it to test for the presence of the "normal" style.
   */

  const copy = { ...font };
  if (
    copy.style === "italic" &&
    GOOGLE_FONTS_NO_ITALICS.includes(copy.family)
  ) {
    // Force observer to check for "normal" style instead
    copy.style = "normal";
  }

  return copy;
};

const loadFontInteral = (font: FontAssetInfo, timeout = 2000) => {
  const loader = new FontFaceObserver(font.family, {
    weight: font.weight,
    style: font.style,
  })
    .load(null, timeout)
    .then(() => {
      return Promise.resolve(true);
    })
    .catch((error) => {
      logger.track(`Failed to load font ${JSON.stringify(font)}`, error);
      return Promise.resolve(false);
    });

  return loader;
};

//////////////////////////////////////////////////////////////////////////////////////////
//  EXPORTED METHODS
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Used for loading a group of fonts
 *
 * Returns a promise that resolves when all the requested fonts have either
 *  - loaded in the DOM and made ready for use
 *  - failed to load
 */
export const loadFonts = async (
  fonts: FontAssetInfo[],
  id: string,
  timeout = 2000
) => {
  const copy = fonts.map((f) => handleItalics(f));

  // Because we've forced 'italic' to be 'normal' in
  // some cases, it could have generated duplicates.
  const uniqueAssets = uniqWith(copy, isEqual);

  await addLinkElement(uniqueAssets, id);

  const loaders = uniqueAssets.map((f) => loadFontInteral(f, timeout));
  return Promise.all(loaders).then(() => {
    EventBus.emit("FONTS_LOADED", id);
  });
};
