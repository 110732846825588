<template>
  <div class="relative h-full">
    <slot />

    <!-- If no connection is selected, list all connections: -->
    <div class="space-y-4 items-start flex flex-col h-full">
      <button
        class="mx-4 flex space-x-[6px] text-gray-100 text-xs justify-center items-center p-[14px] rounded-lg border-gray-100 border opacity-75 hover:opacity-100 hover:shadow-md hover:bg-app-dark4 transition-all w-[85%]"
        @click="addDataConnectionClickHandler"
      >
        <Icon class="w-[14px] h-[14px]" name="CirclePlus" />
        <div v-t="'dataPanel.createConnection'"></div>
      </button>

      <div
        v-if="
          connectionsStore.appConnections.length > maxAllowedConnectionCount
        "
        class="p-2 m-4 mr-6 rounded border border-app-yellow text-app-yellow text-xs flex space-x-2 items-center"
      >
        <div>
          <IconSolid
            name="Exclamation"
            class="h-6 w-6"
            fill="none"
            stroke="#ed9515"
          />
        </div>
        <div>
          {{
            $t("dataPanel.exceededMaxConnections", {
              size: maxAllowedConnectionCount,
            })
          }}
        </div>
      </div>

      <ConnectionsList />

      <ConnectionsEmpty
        v-if="
          connectionsStore.connections?.length === 0 &&
          !connectionDataStore.isLoadingData
        "
      />
    </div>

    <!-- There is a selected connection -->
    <Transition @enter="enterRecordView" @leave="leaveRecordView">
      <div
        v-if="connectionEditor.connection?.uuid"
        class="absolute -top-14 bg-app-dark2 w-full flex flex-col space-y-2"
        style="height: calc(100% + 16px)"
        ref="recordView"
      >
        <div class="flex justify-between items-center px-4 mt-4">
          <div
            class="text-gray-100 font-semibold"
            v-t="'dataPanel.title'"
          ></div>
          <CloseButton @close="handleBackClick" />
        </div>

        <RecordView />
      </div>
    </Transition>

    <portal to="mainEditor">
      <UsagePrompt v-if="dragDropStore.usagePromptIsOpen" ref="usagePrompt" />
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";

import { APP_EDITOR_CONNECTION_ROUTE_PATH } from "@/constants";

import ConnectionsList from "@/components/menus/data/ConnectionsList.vue";
import ConnectionsEmpty from "@/components/menus/data/ConnectionsEmpty.vue";
import UsagePrompt from "@/components/menus/data/UsagePrompt.vue";
import RecordView from "@/components/menus/data/RecordView.vue";
import gsap from "gsap";
import CloseButton from "@/components/menus/CloseButton.vue";
import { useDragDropStore } from "@/stores/dragDrop";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { useConnectionsStore } from "@/stores/connections";
import { useConnectionDataStore } from "@/stores/connectionData";

@Component({
  components: {
    Icon,
    IconSolid,
    RecordView,
    ConnectionsList,
    ConnectionsEmpty,
    UsagePrompt,
    CloseButton,
  },
})
export default class DataMenu extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get maxAllowedConnectionCount() {
    return this.appEditor.maxAllowedConnectionCount;
  }

  get connectionDataStore() {
    return useConnectionDataStore();
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get connectionsStore() {
    return useConnectionsStore();
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  /**
   * This ensures that the state of the left hand panel Data menu will be preserved
   * even after user opens data manager from right hand panel,
   * even for a different data connection.
   * (But if user is editing the same connection that is open, we shouldn't use cached info.)
   * (Also need to not use cached info if user is replacing dataset.)
   */

  @Watch("$route")
  routeChanged() {
    const isOpeningDataManager = this.$route.fullPath.includes("connections");
    const { previouslySelectedConnection } = this.connectionEditor;
    if (isOpeningDataManager) {
      this.connectionEditor.previouslySelectedConnection =
        this.connectionEditor.connection;
    } else {
      if (
        previouslySelectedConnection?.uuid &&
        previouslySelectedConnection?.uuid !==
          this.connectionEditor.connection?.uuid
      ) {
        this.connectionEditor.connection = previouslySelectedConnection;
        this.connectionEditor.previouslySelectedConnection = null;
      }
    }
  }

  created() {
    if (this.connectionsStore.connections.length === 0) {
      this.connectionsStore.fetchPagedConnections();
    }
  }

  enterRecordView() {
    const recordView = this.$refs.recordView as gsap.TweenTarget;
    gsap.from(recordView, { x: "100%" });
    gsap.from(recordView, { opacity: 0, ease: "power1.in", duration: 0.1 });
  }

  leaveRecordView() {
    const recordView = this.$refs.recordView as gsap.TweenTarget;
    gsap.to(recordView, { x: "100%" });
    gsap.to(recordView, { opacity: 0, ease: "power1.out", duration: 0.1 });
  }

  handleBackClick() {
    this.connectionEditor.connection = null;
  }

  addDataConnectionClickHandler() {
    this.$router.push({
      path: `${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}/new`,
      query: {
        type: "Collection",
        property: "data",
        hideExistingConnections: "true",
      },
    });
  }
}
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(27, 27, 27, 0.7);
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

.icon-fill {
  fill: rgb(120, 120, 120);
}
.icon-fill:hover {
  fill: rgb(170, 170, 170);
}
</style>
