<template>
  <component
    :is="`IconEase${iconName}`"
    fill="none"
    viewBox="0 0 12 12"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    :stroke-width="width"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconEaseNone from "./IconEaseNone.vue";
import IconEaseAccelerate from "./IconEaseAccelerate.vue";
import IconEaseDecelerate from "./IconEaseDecelerate.vue";
import IconEaseNatural from "./IconEaseNatural.vue";
import IconEaseQuickAccelerate from "./IconEaseQuickAccelerate.vue";
import IconEaseQuickDecelerate from "./IconEaseQuickDecelerate.vue";
import IconEaseQuick from "./IconEaseQuick.vue";
import IconEaseSlowAccelerate from "./IconEaseSlowAccelerate.vue";
import IconEaseSlowDecelerate from "./IconEaseSlowDecelerate.vue";
import IconEaseSlow from "./IconEaseSlow.vue";

@Component({
  components: {
    IconEaseNone,
    IconEaseAccelerate,
    IconEaseDecelerate,
    IconEaseNatural,
    IconEaseQuickAccelerate,
    IconEaseQuickDecelerate,
    IconEaseQuick,
    IconEaseSlowAccelerate,
    IconEaseSlowDecelerate,
    IconEaseSlow,
  },
})
export default class EaseIcon extends Vue {
  @Prop(String) name: string;
  @Prop({ default: 1 }) width: number;

  get iconName() {
    return this.name;
  }
}
</script>

<style scoped lang="postcss"></style>
