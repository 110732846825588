<template>
  <div class="flex flex-col space-y-4 pt-4 text-center">
    <div class="text-red-500">{{ dataEmptyMessage }}</div>

    <div>{{ actionMessage }}</div>
  </div>
</template>

<script lang="ts">
import { useConnectionDataStore } from "@/stores/connectionData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DataEmpty extends Vue {
  get store() {
    return useConnectionDataStore();
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get dataEmptyMessage() {
    if (this.store.connectionIsTree)
      return this.$t("dataPanel.dataEmptyTreeMessage");
    return this.$t("dataPanel.dataEmptyMessage");
  }

  get actionMessage() {
    if (this.connectionEditor.isEditable)
      return this.$t("dataPanel.dataEmptyEditableAction");
    return this.$t("dataPanel.dataEmptyAction");
  }
}
</script>
