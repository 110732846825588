import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface CalendarDayOptions
  extends ComponentOptions,
    TransformOptions,
    BackgroundOptions {
  data?: any[];
  previewLiveData: boolean;

  dayOffset: number;
  dayFormat: string;
  startHour: number;
  endHour: number;
  use24Hour: boolean;
  useDefaultStartTime: boolean;
  showEventLocation: boolean;
  showEventTime: boolean;
  syncFonts: boolean;
  syncTextColors: boolean;

  // Hour Lines
  showVerticalLines: boolean;
  verticalLinesColor: string;

  // Now Line
  nowLineColor: string;
  showNowLine: boolean;

  // Background
  showBackground: boolean;
  calendarBackgroundColor: string;
  eventBackgroundColor: string;

  // Text Styles ---------------------------------------

  day_fontFamily: string | null;
  day_textColor: string | null;
  day_fontSize: number;
  day_fontWeight: number;
  day_letterSpacing: number;
  day_lineHeight: number;
  day_textAlign: string;
  day_fontStyle: string;
  day_textTransform: string;
  day_textDecoration: string;

  // title_fontFamily: string | null;
  // title_textColor: string;
  // title_fontSize: number;
  // title_fontWeight: number;
  // title_letterSpacing: number;
  // title_lineHeight: number;
  // title_textAlign: string;
  // title_fontStyle: string;
  // title_textTransform: string;
  // title_textDecoration: string;

  hours_fontFamily: string | null;
  hours_textColor: string | null;
  hours_fontSize: number;
  hours_fontWeight: number;
  hours_letterSpacing: number;
  hours_lineHeight: number;
  hours_textAlign: string;
  hours_fontStyle: string;
  hours_textTransform: string;
  hours_textDecoration: string;

  eventTitle_fontFamily: string | null;
  eventTitle_textColor: string | null;
  eventTitle_fontSize: number;
  eventTitle_fontWeight: number;
  eventTitle_letterSpacing: number;
  eventTitle_lineHeight: number;
  eventTitle_textAlign: string;
  eventTitle_fontStyle: string;
  eventTitle_textTransform: string;
  eventTitle_textDecoration: string;

  eventLocation_fontFamily: string | null;
  eventLocation_textColor: string | null;
  eventLocation_fontSize: number;
  eventLocation_fontWeight: number;
  eventLocation_letterSpacing: number;
  eventLocation_lineHeight: number;
  eventLocation_textAlign: string;
  eventLocation_fontStyle: string;
  eventLocation_textTransform: string;
  eventLocation_textDecoration: string;

  eventTime_fontFamily: string | null;
  eventTime_textColor: string | null;
  eventTime_fontSize: number;
  eventTime_fontWeight: number;
  eventTime_letterSpacing: number;
  eventTime_lineHeight: number;
  eventTime_textAlign: string;
  eventTime_fontStyle: string;
  eventTime_textTransform: string;
  eventTime_textDecoration: string;
}

export const defaultTextOptions: any = {
  day_fontSize: 72,
  day_fontWeight: 300,
  day_textColor: "rgba(22,162,73,100)",
  day_textTransform: "uppercase",
  day_textAlign: "center",
  hours_fontSize: 28,
  hours_fontWeight: 700,
  hours_textColor: "rgba(52, 66, 86, 100)",
  hours_textTransform: "none",
  eventTitle_fontSize: 32,
  eventTitle_fontWeight: 700,
  eventTitle_textColor: "rgba(255,255,255,100)",
  eventTitle_textTransform: "capitalize",
  eventLocation_fontSize: 36,
  eventLocation_fontWeight: 300,
  eventLocation_textColor: "rgba(255,255,255,100)",
  eventLocation_textTransform: "none",
  eventTime_fontSize: 26,
  eventTime_fontWeight: 300,
  eventTime_textColor: "rgba(255,255,255,100)",
  eventTime_textTransform: "none",
};

["day", "hours", "eventTitle", "eventLocation", "eventTime"].forEach((prop) => {
  defaultTextOptions[`${prop}_fontFamily`] = "Montserrat";
  defaultTextOptions[`${prop}_textDecoration`] = "none";
  defaultTextOptions[`${prop}_fontStyle`] = "normal";
  defaultTextOptions[`${prop}_lineHeight`] = 1.2;
  defaultTextOptions[`${prop}_letterSpacing`] = 0;
});

["hours", "eventTitle", "eventLocation", "eventTime"].forEach((prop) => {
  defaultTextOptions[`${prop}_textAlign`] = "left";
});

const defaultOptions = {
  ...defaultTextOptions,
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBackgroundOptions,
  dayOffset: 0,
  dayFormat: "today",
  startHour: 8,
  endHour: 17,
  previewLiveData: false,
  // title: "Calendar Title",
  // showTitle: true,
  showEventLocation: true,
  showEventTime: true,
  use24Hour: false,
  useDefaultStartTime: true,
  showVerticalLines: true,
  showNowLine: true,
  showBackground: true,
  verticalLinesColor: "rgba(203,213,225,100)",
  nowLineColor: "rgba(33,196,93,100)",
  eventBackgroundColor: "rgba(52,66,86,100)",
  calendarBackgroundColor: "rgba(241,245,249,100)",
  syncFonts: false,
  syncTextColors: false,
};

const CreateCalendarDay = (options: Partial<CalendarDayOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "CalendarDay",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.CalendarDay,
  });
};

export default CreateCalendarDay;
