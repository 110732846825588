<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import CalendarAgendaComponent from "@/components/widgets/CalendarAgenda/CalendarAgendaComponent.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { EventBus } from "@/eventbus";

@Component
export default class CalendarAgendaWrapper extends CalendarAgendaComponent {
  get appEditor() {
    return useAppEditorStore();
  }

  get scale() {
    return this.appEditor.scale;
  }

  get watcherObject() {
    // Font properties that affect layout
    const propSuffixes = [
      "fontFamily",
      "fontWeight",
      "fontSize",
      "letterSpacing",
      "textTransform",
      "fontStyle",
      "lineHeight",
    ];

    const textProps = Object.keys(this.$props).reduce((acc, prop) => {
      if (propSuffixes.some((suffix) => prop.endsWith(suffix))) {
        return Object.assign(acc, { [prop]: (this as any)[prop] });
      }
      return acc;
    }, {});

    return Object.assign({}, textProps, {
      scaleX: this.scaleX,
      data: this.data.length, // Fast, but possibly inaccurate
      dayOffset: this.dayOffset,
      dayFormat: this.dayFormat,
    });
  }

  @Watch("watcherObject", { deep: true })
  watcherChanged() {
    this.$nextTick(this.updateHeight);
  }

  updateHeight() {
    const bbox = (this.$refs.content as HTMLElement)?.getBoundingClientRect();
    const h = bbox.height || 0;
    const props = { h: h / this.scale };
    this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
  }

  mounted() {
    this.$nextTick(this.updateHeight);
  }

  created() {
    EventBus.on("FONTS_LOADED", this.handleFontsLoaded);
  }

  beforeDestroy() {
    EventBus.off("FONTS_LOADED", this.handleFontsLoaded);
  }

  handleFontsLoaded(id: string) {
    if (id === "in-use") {
      this.$nextTick(this.updateHeight);
    }
  }
}
</script>
