import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface StackedGraphOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  h: number;
  w: number;
  topMargin: number;
  rightMargin: number;
  bottomMargin: number;
  leftMargin: number;
  minValue: number;
  maxValue: number;
  barPadding: number;

  value_fontFamily: string;
  value_fontSize: number;
  value_fontWeight: number;
  value_fontStyle: string;
  value_textColor: string;
  legend_fontFamily: string;
  legend_fontSize: number;
  legend_fontWeight: number;
  legend_fontStyle: string;
  legend_textColor: string;
  legend_textTransform: string;
  showLegend: boolean;
  legendMargin: number;
  legendPosition: string;
  colors: string[];
  showValues: boolean;
  valuesAsPercent: boolean;
  xAxisColumnId: string;
  yAxisColumnId: string;
}

export const defaultStackedGraphOptions = {
  value_fontFamily: "Montserrat",
  value_fontSize: 24,
  value_fontWeight: 600,
  value_fontStyle: "normal",
  value_textColor: "#000000",
  legend_fontFamily: "Montserrat",
  legend_fontSize: 24,
  legend_fontWeight: 300,
  legend_fontStyle: "normal",
  legend_textColor: "#000000",
  legend_textTransform: "capitalize",
  showValues: false,
  showLegend: true,
  legendMargin: 30,
  legendPosition: "Right",
  valuesAsPercent: false,
  /**
   * Stores the UUID of the data column representing the x axis
   */
  xAxisColumnId: "a",
  /**
   * Stores the UUID of the data column representing the y axis
   */
  yAxisColumnId: "b",
  colors: [
    "#0D3B5A",
    "#185783",
    "#2984C5",
    "#3ABFF8",
    "#BAE5FD",
    "#E1F3FE",
    "#BAE5FD",
    "#3ABFF8",
    "#2984C5",
    "#185783",
  ],
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultStackedGraphOptions,
};

const CreateStackedGraph = (options: Partial<StackedGraphOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "StackedGraph",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.StackedGraph,
  });
};

export default CreateStackedGraph;
