<template>
  <div class="divide-y">
    <div class="space-y-3 py-8 px-4">
      <ColorInputBar
        :value="borderColor"
        :iconSize="'5'"
        @input="preview({ borderColor: $event })"
        @change="apply({ borderColor: $event })"
        :gradientEnabled="true"
        propName="borderColor"
      >
        <span v-t="'color'"></span>
      </ColorInputBar>

      <NumberInputBar :value="borderWidth" @change="modifyWidth($event)">
        <span v-t="'thickness'"></span>
      </NumberInputBar>

      <NumberInputBar
        :value="borderDashSize"
        @change="apply({ borderDashSize: $event })"
      >
        <span v-t="'dash'"></span>
      </NumberInputBar>

      <NumberInputBar
        :value="borderGapSize"
        @change="apply({ borderGapSize: $event })"
      >
        <span v-t="'gap'"></span>
      </NumberInputBar>
      <div class="text-gray-500 ml-3 text-sm" v-t="'linecap'"></div>
      <EditorSelectMenu
        class="pl-1"
        :value="linecap"
        :options="linecapOptions"
        @input="apply({ linecap: $event })"
      >
      </EditorSelectMenu>
    </div>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'shadow'"></div>
      </template>
      <div class="divide-y">
        <ShadowEditor />
      </div>
    </CollapsePanel>
    <CollapsePanel v-if="showAnimationPanel">
      <template slot="title">
        <div v-t="'animation.animation'"></div>
      </template>
      <AnimationEditor />
    </CollapsePanel>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import BorderEditor from "@/components/editors/BorderEditor.vue";
import ShadowEditor from "@/components/editors/ShadowEditor.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import Icon from "@/components/icons/Icon.vue";
import AnimationEditor from "@/components/editors/AnimationEditor.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { animationAllowed } from "@/components/widgets/animationAllowed";

@Component({
  components: {
    ColorInputBar,
    NumberInputBar,
    CollapsePanel,
    EditorSelectMenu,
    BorderEditor,
    ShadowEditor,
    ButtonGradient,
    Icon,
    AnimationEditor,
  },
})
export default class LineEditor extends Vue {
  // TODO: Must update to account for dynamic bg images in repeater

  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }
  get selectedProps() {
    return this.appEditor.selectedProps;
  }
  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }
  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get showAnimationPanel() {
    return animationAllowed(this.selectedWidget);
  }

  get borderColor() {
    return this.selectedProps.borderColor[0];
  }

  get borderWidth() {
    return this.selectedProps.borderWidth[0];
  }

  get borderDashSize() {
    return this.selectedProps.borderDashSize[0];
  }

  get borderGapSize() {
    return this.selectedProps.borderGapSize[0];
  }

  get borderRadius() {
    return this.selectedProps.borderWidth[0];
  }

  get linecap() {
    return this.selectedProps.linecap[0];
  }

  get linecapOptions() {
    return [
      { label: "Square", value: "square" },
      { label: "Round", value: "round" },
    ];
  }

  modifyWidth(event: any) {
    this.apply({ borderWidth: event, h: event });
  }

  apply(props: any) {
    this.appEditor.setWidgetProps(this.selections, props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps(this.selections, props, "NO_UNDO");
  }
}
</script>
