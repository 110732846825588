<template>
  <div class="h-full">
    <div class="flex space-x-2 items-center relative h-full">
      <!-- Swatch -->
      <div
        class="w-6 h-6 rounded swatch-outline flex-shrink-0"
        :style="{ backgroundColor: color }"
      ></div>

      <span>#</span>
      <input
        :id="makeKeyId(rowUuid, node.uuid || '')"
        type="text"
        :value="hex"
        @change="setColor($event?.target?.value)"
        @click.stop
        @blur="$emit('blur', $event)"
      />
    </div>
    <div class="absolute mt-2">
      <ColorPicker
        :value="color"
        @input="setColor"
        @change="setColor"
        class="flex-grow text-black w-80 p-1"
        :stopPropagation="true"
        :isFromDataManager="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import InputBase from "@/components/data/connections/manualSource/inputs/InputBase.vue";
import ColorPicker from "@/components/inputs/ColorPicker.vue";
import tinycolor from "tinycolor2";

@Component({
  components: {
    ColorPicker,
  },
})
export default class ColorInput extends InputBase {
  get color() {
    return tinycolor(this.value).toHex8String();
  }

  get hex() {
    if (!this.value) return "";
    return tinycolor(this.value).toHex8();
  }

  setColor(val: any) {
    this.handleInput({ target: { value: tinycolor(val).toHex8String() } });
  }
}
</script>
