<template>
  <div class="flex items-center">
    <slot :value="renderValue" :isBound="isBound" />

    <LightningBolt
      v-if="!isChild"
      :bound="isBound"
      @bind="onBindClick"
      @unbind="onUnbindClick"
      :tooltipPosition="tooltipPosition"
    />

    <ChildBindingDropdown v-else :propType="propType" :propName="propName" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LightningBolt from "@/components/data/LightningBolt.vue";
import { APP_EDITOR_CONNECTION_ROUTE_PATH } from "@/constants";
import { BASE_PARENT_ID } from "@/constants";
import ChildBindingDropdown from "@/components/editors/ChildBindingDropdown.vue";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { useAppEditorStore } from "@/stores/appEditor";

/**
 *
 * This component is in charge of determining whether selectedWidget has a parent,
 * and if it does, show that dropdown when zap opens, instead of opening the manager. (i.e. show ChildBindingDropdown)
 *
 * NOTE: bindingsForComponent will NEED to use bindingType... so put that back for repeaters
 *
 */

@Component({
  components: {
    LightningBolt,
    ChildBindingDropdown,
  },
})
export default class ScalarBinder extends Vue {
  @Prop(String) propName: string;
  @Prop(String) propType: string;
  @Prop(String) tooltipPosition: string;

  get appEditor() {
    return useAppEditorStore();
  }

  get dataBindings() {
    return this.appEditor.dataBindings;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get isChild() {
    return (
      this.selectedWidget?.parentId &&
      this.selectedWidget.parentId !== BASE_PARENT_ID
    );
  }

  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];
      // NOTE: Again, this is crude -- just taking the first widget's val as the group's val
      // if (values.length === 1) {
      result = values[0];
      // }
    }
    return result as T;
  }

  /**
   * This is exposed and bound to parent via slot-scope
   */
  get renderValue() {
    if (this.isBound && this.selectedWidget !== undefined) {
      if (this.isChild) {
        return this.widgetData[this.selectedWidget.wid][
          this.editingContext.repeaterIndex || 0
        ]?.[this.propName];
      }

      const boundItem = this.widgetData[this.selectedWidget.wid].find((x) =>
        Object.keys(x).some((key) => key === this.propName)
      );

      return boundItem
        ? boundItem[this.propName]
        : this.getValue(this.propName);
    }
    return this.getValue(this.propName);
  }

  get conditionGroupsStore() {
    return useConditionGroupsStore();
  }

  get activeConditionId() {
    if (this.selectedWidget === undefined) {
      return undefined;
    }
    return this.conditionGroupsStore.getActiveConditionId(
      this.selectedWidget?.wid
    );
  }

  // NOTE: Should use bindingsForComponent, maybe
  get dataBinding() {
    return this.dataBindings.find(
      (db) =>
        db.property === this.propName &&
        db.widgetId === this.selectedWidget?.wid &&
        db.conditionUuid === this.activeConditionId
    );
  }

  get isBound() {
    return this.dataBinding ? true : false;
  }

  onBindClick() {
    const basePath = `${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`;
    if (this.isBound) {
      // Push to data manager view
      this.$router.push({
        path: `${basePath}/${this.dataBinding?.dataConnectionUuid}?widgetId=${this.selectedWidget?.wid}&type=${this.propType}&property=${this.propName}&bindingType=Scalar`,
      });
    } else {
      this.$router.push({
        path: `${basePath}/new?widgetId=${this.selectedWidget?.wid}&type=${this.propType}&property=${this.propName}&bindingType=Scalar`,
      });
    }
  }

  onUnbindClick() {
    if (this.selectedWidget === undefined) {
      return;
    }
    this.appEditor.unbindProperty({
      widgetId: this.selectedWidget.wid,
      conditionUuid: this.activeConditionId || "",
      propertyName: this.propName,
      value: this.renderValue,
    });
  }
}
</script>
