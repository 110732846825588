<template>
  <button class="text-sm flex space-x-2 pl-3" @click="toggle">
    <Icon :name="eyeIcon" class="w-5 h-5" />
    <div class="text-gray-500" v-t="isVisible"></div>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";

@Component({
  components: { Icon },
})
export default class VisibilityInput extends Vue {
  @Prop(Boolean) value: boolean;

  get eyeIcon() {
    if (this.value) {
      return "Eye";
    } else {
      return "EyeOff";
    }
  }

  get isVisible() {
    if (this.value) {
      return "visible";
    } else {
      return "hidden";
    }
  }

  toggle() {
    this.$emit("input", !this.value);
    this.$emit("change", !this.value);
  }
}
</script>
