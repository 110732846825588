<template>
  <button
    type="button"
    @click="onClick"
    class="outline-0 focus:ring-[3px] shadow-md border-1 rounded-sm mb-1 mt-1 px-2"
    :class="{
      border: theme === 'light',
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    }"
    :style="borderStyles"
    :disabled="disabled"
    :title="text"
  >
    <div class="flex items-center space-x-2">
      <div class="flex items-center justify-center w-8 h-8">
        <img class="w-5 h-5" :src="iconUrl" />
      </div>
      <div
        class="p-2 font-medium"
        :class="{ 'text-gray-500': disabled }"
        v-text="text"
      ></div>
    </div>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AuthSignInButton extends Vue {
  @Prop(Boolean) disabled: boolean;
  @Prop(String) theme: "dark" | "light";
  @Prop(String) text: string;
  @Prop(String) iconUrl: string;
  isActive = false;
  isFocused = false;

  get borderStyles() {
    const color = this.theme === "dark" ? "#bfb6b6" : "#bfb6b6";
    return { "border-color": color };
  }

  get bgFill() {
    if (this.disabled) return "#EBEBEB";

    if (this.isActive) return "#eee";

    return "#fff";
  }

  get textStyles() {
    return { "fill-opacity": this.disabled ? 0.4 : 0.54 };
  }

  onClick() {
    if (this.disabled === false) this.$emit("click");
  }

  mounted() {
    this.$el.addEventListener("mousedown", this.onMouseDown);
  }

  beforeDestroy() {
    this.$el.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("mouseup", this.onMouseUp);
  }
  onMouseDown() {
    this.isActive = true;
    document.addEventListener("mouseup", this.onMouseUp);
  }

  onMouseUp() {
    this.isActive = false;
    document.removeEventListener("mouseup", this.onMouseUp);
  }
}
</script>
