<template>
  <div class="countdown flex items-center justify-center w-full h-full">
    <div
      class="flex items-center justify-center"
      :key="index"
      v-for="(item, index) in renderedUnits"
    >
      <div
        class="flex items-start justify-center relative"
        v-if="display[item]"
        :style="{
          marginLeft: `${marginX / 2}rem`,
          marginRight: `${marginX / 2}rem`,
        }"
      >
        <div class="data flex flex-col items-center justify-center">
          <div
            class="value flex items-center justify-center text-center"
            v-text="padNumber(item, values[item])"
            :style="[
              {
                width: textWidths[item],
                textIndent: `${time_letterSpacing}px`,
              },
              getStyles('time'),
            ]"
          ></div>
          <div
            class="label flex items-center justify-center"
            :style="[
              { transform: `translate(0, ${marginY / 2}rem)` },
              getStyles('label'),
            ]"
            v-text="item"
          ></div>
        </div>
        <div
          class="colon absolute"
          id="colon"
          :style="[
            {
              opacity: colonOpacity,
              left: '100%',
              transform: `translate( ${colonX} , ${colonY})`,
            },
            getStyles('time'),
          ]"
          v-if="timeUnitsArray[timeUnitsArray.length - 1] != item"
        >
          :
        </div>
      </div>
    </div>
    <div id="test" :style="getStyles('time')">0</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateTime, Duration, DurationUnits } from "luxon";
import { EventBus } from "@/eventbus";

@Component({})
export default class CountdownComponent extends Vue {
  @Prop(String) readonly datetimeValue: string;
  @Prop(String) readonly pausedDateTime: string;
  @Prop(Number) readonly width: number;
  @Prop(Number) readonly height: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Boolean) readonly pastAllowed: boolean;
  @Prop(Boolean) readonly paused: boolean;
  @Prop(Number) readonly time_fontSize: number;
  @Prop(String) readonly time_textColor: string;
  @Prop(String) readonly time_fontFamily: string;
  @Prop(Number) readonly time_fontWeight: number;
  @Prop(String) readonly time_textTransform: string;
  @Prop(String) readonly time_fontStyle: string;
  @Prop(Number) readonly time_letterSpacing: number;
  @Prop(Number) readonly label_fontSize: number;
  @Prop(String) readonly label_textColor: string;
  @Prop(String) readonly label_fontFamily: string;
  @Prop(Number) readonly label_fontWeight: number;
  @Prop(String) readonly label_textTransform: string;
  @Prop(String) readonly label_fontStyle: string;
  @Prop(Number) readonly label_letterSpacing: number;
  @Prop(Number) readonly marginX: number;
  @Prop(Number) readonly marginY: number;
  @Prop(Boolean) readonly showDivider: boolean;
  @Prop(Boolean) readonly showYears: boolean;
  @Prop(Boolean) readonly showMonths: boolean;
  @Prop(Boolean) readonly showWeeks: boolean;
  @Prop(Boolean) readonly showDays: boolean;
  @Prop(Boolean) readonly showHours: boolean;
  @Prop(Boolean) readonly showMinutes: boolean;
  @Prop(Boolean) readonly showSeconds: boolean;

  private timer: any;
  values: { [k: string]: number } = {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  remaining: Duration = this.chosenTime.diffNow("seconds");

  textWidths: { [unit: string]: string } = {};

  colonX = "5.25rem";
  colonY = "-0.5rem";

  get chosenTime() {
    return DateTime.fromISO(this.datetimeValue);
  }

  get colonOpacity() {
    if (this.showDivider) {
      return 1;
    } else {
      return 0;
    }
  }

  created() {
    this.startTimer();
    console.log(this.pausedDateTime);
  }

  mounted() {
    this.countdown();
    EventBus.on("FONTS_LOADED", this.measureText);
  }

  beforeDestroy() {
    clearInterval(this.timer);
    EventBus.off("FONTS_LOADED", this.measureText);
  }

  startTimer() {
    this.timer = setInterval(this.countdown, 1000);
  }

  padNumber(item: string, value: number) {
    if (item === "seconds" || item === "minutes") {
      return (value < 10 ? "0" : "") + value;
    }
    return value;
  }

  timeUnits = [
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
  ];

  get showArray() {
    return [
      this.showYears,
      this.showMonths,
      this.showWeeks,
      this.showDays,
      this.showHours,
      this.showMinutes,
      this.showSeconds,
    ];
  }

  // This passes the units that are toggled on into the countdown function to tell luxon how to calculate values.
  get timeUnitsArray() {
    let array = [];
    for (let i = 0; i < this.timeUnits.length; i++) {
      if (this.showArray[i] === true) {
        array.push(this.timeUnits[i]);
      }
    }
    return array as DurationUnits;
  }

  countdown() {
    var timeUnits = this.timeUnitsArray;
    this.remaining = this.paused
      ? this.chosenTime.diff(DateTime.fromISO(this.pausedDateTime), "seconds")
      : this.chosenTime.diffNow("seconds");
    var diff = this.paused
      ? this.chosenTime.diff(DateTime.fromISO(this.pausedDateTime), timeUnits)
      : this.chosenTime.diffNow(timeUnits);

    if (!this.pastAllowed && this.remaining["seconds"] <= 0) {
      this.values.years = 0;
      this.values.months = 0;
      this.values.weeks = 0;
      this.values.days = 0;
      this.values.hours = 0;
      this.values.minutes = 0;
      this.values.seconds = 0;
      this.measureText();
    } else {
      this.values.years = Math.floor(Math.abs(diff.years));
      this.values.months = Math.floor(Math.abs(diff.months));
      this.values.weeks = Math.floor(Math.abs(diff.weeks));
      this.values.days = Math.floor(Math.abs(diff.days));
      this.values.hours = Math.floor(Math.abs(diff.hours));
      this.values.minutes = Math.floor(Math.abs(diff.minutes));
      this.values.seconds = Math.floor(Math.abs(diff.seconds));
      this.measureText();
    }

    return;
  }

  // This determines which time units are ultimately displayed in the app. It checks to see if they are toggled on,
  // and if there is at least one of those time units left in the countdown.
  get display() {
    var obj: { [k: string]: boolean } = {};
    this.timeUnits.forEach((k, i) => {
      obj[k] = this.showArray[i] ? true : false;
    });
    return obj;
  }

  get renderedUnits() {
    let array: string[] = [];
    for (const item in this.display) {
      if (this.display[item] === true) {
        array.push(item);
      }
    }
    return array;
  }

  measureText() {
    let textWidth: number = document.getElementById("test")?.clientWidth ?? 10;
    let textHeight: number =
      document.getElementById("test")?.clientHeight ?? 10;
    let colonWidth: number =
      document.getElementById("colon")?.clientWidth ?? 10;
    let array: { [k: string]: string } = {};

    this.renderedUnits.forEach((k) => {
      let stringLength: number;
      if (
        (k === "seconds" && this.values.seconds < 10) ||
        (k === "minutes" && this.values.minutes < 10)
      ) {
        stringLength = 2;
      } else {
        stringLength = (this as any).values[k].toString().length;
      }
      array[k] = `${
        textWidth * 1.1 * stringLength +
        stringLength -
        1 * this.time_letterSpacing
      }px`;
    });
    this.textWidths = array;
    //returning the number as rem, with base font size 16
    this.colonX = `${this.marginX / 2 - colonWidth / 16 / 2}rem`;
    this.colonY = `${(textHeight / 16) * -1}px`;
  }

  getStyles(prefix: string) {
    const vm = this as any;
    const result: any = {};
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontSize`]) {
      result.fontSize = `${vm[`${prefix}_fontSize`]}px`;
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.color = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }
    if (vm[`${prefix}_letterSpacing`]) {
      result.letterSpacing = `${vm[`${prefix}_letterSpacing`]}px`;
    }
    return result;
  }
}
</script>

<style scoped>
.visible {
  opacity: 1;
}

#test {
  visibility: hidden;
  position: absolute;
}
</style>
