<template>
  <FilterModal>
    <form
      @submit.prevent="submit"
      class="h-full flex-grow flex flex-col text-white"
      id="filter-form"
    >
      <!-- Header: -->
      <div
        class="flex justify-between items-center border-b border-app-dark1 px-6 pt-5 pb-4 shrink-0 border-b border-gray-600"
      >
        <div class="flex space-x-8 items-center">
          <div class="text-xl" v-t="'filterManager.filterData'"></div>
          <div class="text-sm underline text-gray-400 cursor-pointer mt-1">
            <a
              href="https://www.screenfeed.com/support-articles/filters-in-connect"
              rel="noopener noreferrer"
              target="_blank"
              class="link"
              v-t="'filterManager.learnMore'"
            ></a>
          </div>
        </div>

        <div class="flex space-x-5">
          <ButtonGradient
            type="submit"
            class="px-2 py-1"
            :disabled="!filterStore.hasUnsavedChanges"
          >
            <span class="whitespace-nowrap">{{ $t("saveChanges") }}</span>
          </ButtonGradient>

          <OutlineButton @click="cancelClickHandler" v-t="'close'">
          </OutlineButton>

          <OutlineButton
            @click="onDeleteFilterClick"
            v-if="hasActiveFilters"
            class="flex items-center"
          >
            <IconSolid name="Trash" class="w-4 h-4 mr-1" />
            <span
              class="whitespace-nowrap"
              v-t="'filterManager.removeFilter'"
            ></span>
          </OutlineButton>
        </div>
      </div>

      <!-- Errors: -->
      <div
        v-if="filterStore.showErrors && filterStore.invalidRules.length > 0"
        class="text-red-500"
      >
        Please fix the errors highlighted below.
      </div>

      <!-- Form body: -->
      <div class="flex-grow h-full overflow-y-auto">
        <div
          class="relative"
          :key="group.uuid"
          v-for="(group, index) in filterStore.groups"
        >
          <!-- OR Link -->
          <div
            class="py-4 bg-app-dark3 border-y border-gray-600 flex justify-center items-center"
            v-if="index > 0"
          >
            <div
              class="rounded bg-app-purple px-4 py-1 text-lg font-semibold leading-none text-white shadow-lg"
              v-t="'Condition.or'"
            ></div>
          </div>

          <RuleGroupEditor
            :model="group"
            :showAdd="true"
            :groupIndex="index"
            :lastGroupIndex="filterStore.groups.length - 1"
          />
        </div>
      </div>
    </form>
  </FilterModal>
</template>

<script lang="ts">
import ButtonGradient from "@/components/ButtonGradient.vue";
import { Component, Vue } from "vue-property-decorator";
import { DataBinding } from "@/types/data";

import { EventBus } from "@/eventbus";
import { useFilterEditorStore } from "@/stores/filterEditor";
import { useAppEditorStore } from "@/stores/appEditor";
import { useAppDataStore } from "@/stores/appData";
import RuleGroupEditor from "./RuleGroupEditor.vue";
import FilterModal from "./FilterModal.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import { logger } from "@core/logger";

@Component({
  components: {
    ButtonGradient,
    RuleGroupEditor,
    FilterModal,
    IconSolid,
    OutlineButton,
  },
})
export default class FilterManager extends Vue {
  get appData() {
    return useAppDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidgetBinding() {
    return this.appEditor.selectedWidgetBinding;
  }

  get filterStore() {
    return useFilterEditorStore();
  }

  async submit() {
    try {
      EventBus.emit("AWAITING_SERVER", true);
      await this.filterStore.save();

      if (!this.hasActiveFilters) {
        // Update data binding
        const uuid = this.filterStore.uuid;
        const db = { ...this.selectedWidgetBinding } as DataBinding;
        db.filterUuid = uuid;
        this.appEditor.updateDataBinding(db);
      }

      // Let backend know about updated data binding so that fetching fresh widget data gets the filtered data:
      try {
        await this.appEditor.updateApp();
      } catch (e) {
        window.alert("Unable to save app");
      }

      this.appData.invalidateCacheForConnection(
        this.selectedWidgetBinding?.dataConnectionUuid as string
      );
      this.filterStore.showModal = false;
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
    }
  }

  async onDeleteFilterClick() {
    const confirm = window.confirm(
      this.$t("filterManager.confirmRemove") as string
    );

    if (confirm === false) {
      return;
    }

    EventBus.emit("AWAITING_SERVER", true);

    try {
      await this.filterStore.deleteFilter();
    } catch (e) {
      logger.track(e);
      window.alert(this.$t("filterManager.deleteError") as string);
      EventBus.emit("AWAITING_SERVER", false);
      return;
    }

    // Also take it off of the data binding (because controls hasActiveFilter)
    const db = { ...this.selectedWidgetBinding } as DataBinding;
    delete db.filterUuid;
    this.appEditor.updateDataBinding(db);

    try {
      await this.appEditor.updateApp();
    } catch (e) {
      logger.track(e);
      window.alert(this.$t("unknownServerError") as string);
      EventBus.emit("AWAITING_SERVER", false);
      return;
    }

    this.filterStore.showModal = false;
    EventBus.emit("AWAITING_SERVER", false);
  }

  get hasActiveFilters(): boolean {
    return this.selectedWidgetBinding?.filterUuid ? true : false;
  }

  cancelClickHandler() {
    let shouldKeepOpen = false;

    if (this.filterStore.hasUnsavedChanges) {
      shouldKeepOpen =
        window.confirm(this.$t("filterManager.confirmClose") as string) ===
        false;
    }

    this.filterStore.showModal = shouldKeepOpen;
  }
}
</script>
