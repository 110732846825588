<template>
  <div class="divide-y">
    <CollapsePanel>
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <div class="pb-8 px-4 pr-6 space-y-3">
        <ScalarBinder
          propName="minValue"
          propType="Number"
          v-slot:default="slotProps"
          tooltipPosition="l"
        >
          <NumberInputBar
            class="w-full"
            :value="slotProps.value"
            :width="22"
            @change="apply({ minValue: $event })"
            :dataBound="slotProps.isBound"
          >
            <span v-t="'minValue'"></span>
          </NumberInputBar>
        </ScalarBinder>

        <ScalarBinder
          propName="maxValue"
          propType="Number"
          v-slot:default="slotProps"
          tooltipPosition="l"
        >
          <NumberInputBar
            class="w-full"
            :value="slotProps.value"
            :dataBound="slotProps.isBound"
            :width="22"
            @change="apply({ maxValue: $event })"
          >
            <span v-t="'maxValue'"></span>
          </NumberInputBar>
        </ScalarBinder>

        <ScalarBinder
          propName="currentValue"
          propType="Number"
          v-slot:default="slotProps"
          tooltipPosition="l"
        >
          <NumberInputBar
            class="w-full"
            :value="slotProps.value"
            :width="22"
            @change="apply({ currentValue: $event })"
            :dataBound="slotProps.isBound"
          >
            <span v-t="'currentValue'"></span>
          </NumberInputBar>
        </ScalarBinder>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'progress.progressBar'"></div>
      </template>
      <div class="px-4 space-y-3 pb-8">
        <ColorInputBar
          :value="model.fgFill"
          :iconSize="'5'"
          :gradientEnabled="true"
          @input="preview({ fgFill: $event })"
          @change="apply({ fgFill: $event })"
          propName="fgFill"
        >
          <span v-t="'fill'"></span
        ></ColorInputBar>

        <ColorInputBar
          :value="model.bgFill"
          :iconSize="'5'"
          :gradientEnabled="true"
          @input="preview({ bgFill: $event })"
          @change="apply({ bgFill: $event })"
          propName="bgFill"
        >
          <span v-t="'progress.progressBarBackground'"></span
        ></ColorInputBar>

        <ColorInputBar
          :value="model.stroke"
          :iconSize="'5'"
          :gradientEnabled="true"
          @input="preview({ stroke: $event })"
          @change="apply({ stroke: $event })"
          propName="stroke"
        >
          <span v-t="'border'"></span
        ></ColorInputBar>

        <NumberInputBar
          :value="model.strokeWidth"
          @change="apply({ strokeWidth: $event })"
        >
          <span v-t="'borderWidth'"></span>
        </NumberInputBar>

        <NumberInputBar
          :value="model.borderRadius"
          @change="apply({ borderRadius: $event })"
        >
          <span v-t="'cornerRadius'"></span>
        </NumberInputBar>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'progress.divider'"></div>
      </template>
      <div class="pb-8 px-4 space-y-3">
        <ColorInputBar
          :value="model.dividerFill"
          :iconSize="'5'"
          :gradientEnabled="true"
          @input="preview({ dividerFill: $event })"
          @change="apply({ dividerFill: $event })"
          propName="dividerFill"
        >
          <div v-t="'fill'"></div
        ></ColorInputBar>

        <ColorInputBar
          :value="model.dividerStroke"
          :iconSize="'5'"
          :gradientEnabled="true"
          @input="preview({ dividerStroke: $event })"
          @change="apply({ dividerStroke: $event })"
          propName="dividerStroke"
        >
          <div v-t="'border'"></div
        ></ColorInputBar>

        <NumberInputBar
          :value="model.dividerStrokeWidth"
          @change="apply({ dividerStrokeWidth: $event })"
        >
          <div v-t="'borderWidth'"></div>
        </NumberInputBar>

        <NumberInputBar
          :value="model.dividerHeight"
          @change="apply({ dividerHeight: $event })"
        >
          <div v-t="'width'"></div>
        </NumberInputBar>
        <NumberInputBar
          :value="model.dividerWidth"
          @change="apply({ dividerWidth: $event })"
        >
          <div v-t="'height'"></div>
        </NumberInputBar>
        <VisibilityInput
          :value="model.showDivider"
          @input="apply({ showDivider: $event })"
        />
      </div>
    </CollapsePanel>
    <div>
      <BaseWidgetEditor :selectionLocked="model.locked" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import ColorPicker from "@/components/inputs/ColorPicker.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import Icon from "@/components/icons/Icon.vue";
import VisibilityInput from "@/components/inputs/VisibilityInput.vue";
import {
  ProgressBarOptions,
  defaultProgressBarOptions,
} from "./ProgressBarOptions";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CollapsePanel,
    ColorPicker,
    ColorInputBar,
    NumberInputBar,
    ToggleInput,
    ButtonGradient,
    SelectMenu,
    BaseWidgetEditor,
    Icon,
    VisibilityInput,
    ScalarBinder,
  },
})
export default class ProgressBarEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as ProgressBarOptions;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  // ==================================

  // selectValue(color: string) {
  //   this.$emit("change", color);
  // }
  // onColorPickerInput(color: string) {
  //   this.$emit("input", color);
  // }

  // get presets() {
  //   return progressBarPresets;
  // }

  get eyeIcon() {
    if (this.showDivider) {
      return "Eye";
    } else {
      return "EyeOff";
    }
  }

  get dividerVisibility() {
    if (this.showDivider) {
      return "visible";
    } else {
      return "hidden";
    }
  }

  get defaults() {
    return defaultProgressBarOptions;
  }

  // get cornerOptions() {
  //   return [
  //     {
  //       label: this.$t("progress.square"),
  //       value: 0,
  //     },
  //     {
  //       label: this.$t("progress.semiRound"),
  //       value: 0.2,
  //     },
  //     {
  //       label: this.$t("progress.round"),
  //       value: 0.5,
  //     },
  //   ];
  // }

  get showDivider() {
    return this.model.showDivider;
  }

  set showDivider(value: any) {
    this.apply({ showDivider: value });
  }
}
</script>
