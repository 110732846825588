<template>
  <div class="w-full h-full">
    <div class="w-full" ref="content">
      <div :style="getStyles('date')" class="px-4">
        {{ dateTitle }}
      </div>
      <div :style="{ backgroundColor: eventBackgroundColor }">
        <!-- <CalendarEmptyMessage v-if="!hasDataConnection" /> -->

        <!-- All day events: -->
        <div
          v-for="(ev, i) in allDayEvents"
          :key="`long-ev-${i}`"
          class="w-full my-2 px-8 py-6 text-3xl"
          :style="{
            ...getStyles('allDayEvent'),
            background: allDayEventBackgroundColor,
          }"
        >
          <div v-if="multidayEventsExist" class="font-bold mb-4">
            {{ formatAllDayTime(ev) }}
          </div>
          <div>{{ ev.summary }}</div>
        </div>

        <!-- Short events: -->
        <div
          v-for="(ev, i) in shortEvents"
          :key="`short-ev-${i}`"
          class="mt-6 mb-6 px-4"
          :style="getOpacityStyle(ev)"
        >
          <div class="flex items-center">
            <div
              class="w-5 h-5 border-3 mr-3 rounded"
              :style="getBoxFill(ev)"
            ></div>

            <TimeRangeDisplay
              class="flex flex-wrap"
              :style="getStyles('eventTime')"
              :start="ev.start"
              :end="ev.end"
              :options="{
                hour: 'numeric',
                minute: 'numeric',
                hour12: !use24Hour,
              }"
            />
          </div>

          <div class="ml-8">
            <div :style="getStyles('eventTitle')" class="mb-1">
              {{ ev.summary }}
            </div>
            <div :style="getStyles('eventLocation')">{{ ev.location }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import TimeRangeDisplay from "@/components/widgets/TimeRangeDisplay.vue";
import { eventIsOver, eventIsUpcoming } from "@/types/calendar";
import { DateTime, Interval } from "luxon";
import { CalendarEvent } from "@/types/calendar";
import { NodeData, NodeSetData } from "@/types/data";
import CalendarEmptyMessage from "@/components/widgets/CalendarEmptyMessage.vue";
import CalendarBase from "@/components/widgets/CalendarBase.vue";
import { StyleValue } from "vue/types/jsx";

@Component({
  components: {
    TimeRangeDisplay,
    CalendarEmptyMessage,
  },
})
export default class CalendarAgendaComponent extends CalendarBase {
  @Prop(Array) data: any[];
  @Prop(Boolean) previewLiveData: boolean;

  @Prop() wid: string;
  // @Prop(Boolean) useDefaultStartTime: boolean;
  @Prop(Number) startHour: number;
  @Prop(Number) endHour: number;
  @Prop(String) eventBackgroundColor: string;
  @Prop(String) allDayEventBackgroundColor: string;
  // @Prop(String) allDayEventTextColor: string;
  // @Prop(String) eventBulletColor: string;

  @Prop(Number) dayOffset: number;
  @Prop(String) dayFormat: string;
  @Prop({ default: false }) use24Hour: boolean;

  @Prop(Number) scaleX: number;

  // ---------------------------------------------------------------------
  @Prop(String) readonly date_fontFamily: string | null;
  @Prop(Number) readonly date_fontWeight: number;
  @Prop(String) readonly date_textColor: string | null;
  @Prop(Number) readonly date_fontSize: number;
  @Prop(Number) readonly date_letterSpacing: number;
  @Prop(String) readonly date_textTransform: string | null;
  @Prop(String) readonly date_textDecoration: string | null;
  @Prop(String) readonly date_fontStyle: string | null;
  @Prop(Number) readonly date_lineHeight: number;
  @Prop(String) readonly date_textAlign: string | null;

  @Prop(String) readonly allDayEvent_fontFamily: string | null;
  @Prop(Number) readonly allDayEvent_fontWeight: number;
  @Prop(String) readonly allDayEvent_textColor: string | null;
  @Prop(Number) readonly allDayEvent_fontSize: number;
  @Prop(Number) readonly allDayEvent_letterSpacing: number;
  @Prop(String) readonly allDayEvent_textTransform: string | null;
  @Prop(String) readonly allDayEvent_textDecoration: string | null;
  @Prop(String) readonly allDayEvent_fontStyle: string | null;
  @Prop(Number) readonly allDayEvent_lineHeight: number;
  @Prop(String) readonly allDayEvent_textAlign: string | null;

  @Prop(String) readonly eventTime_fontFamily: string | null;
  @Prop(Number) readonly eventTime_fontWeight: number;
  @Prop(String) readonly eventTime_textColor: string | null;
  @Prop(Number) readonly eventTime_fontSize: number;
  @Prop(Number) readonly eventTime_letterSpacing: number;
  @Prop(String) readonly eventTime_textTransform: string | null;
  @Prop(String) readonly eventTime_textDecoration: string | null;
  @Prop(String) readonly eventTime_fontStyle: string | null;
  @Prop(Number) readonly eventTime_lineHeight: number;
  @Prop(String) readonly eventTime_textAlign: string | null;

  @Prop(String) readonly eventTitle_fontFamily: string | null;
  @Prop(Number) readonly eventTitle_fontWeight: number;
  @Prop(String) readonly eventTitle_textColor: string | null;
  @Prop(Number) readonly eventTitle_fontSize: number;
  @Prop(Number) readonly eventTitle_letterSpacing: number;
  @Prop(String) readonly eventTitle_textTransform: string | null;
  @Prop(String) readonly eventTitle_textDecoration: string | null;
  @Prop(String) readonly eventTitle_fontStyle: string | null;
  @Prop(Number) readonly eventTitle_lineHeight: number;
  @Prop(String) readonly eventTitle_textAlign: string | null;

  @Prop(String) readonly eventLocation_fontFamily: string | null;
  @Prop(Number) readonly eventLocation_fontWeight: number;
  @Prop(String) readonly eventLocation_textColor: string | null;
  @Prop(Number) readonly eventLocation_fontSize: number;
  @Prop(Number) readonly eventLocation_letterSpacing: number;
  @Prop(String) readonly eventLocation_textTransform: string | null;
  @Prop(String) readonly eventLocation_textDecoration: string | null;
  @Prop(String) readonly eventLocation_fontStyle: string | null;
  @Prop(Number) readonly eventLocation_lineHeight: number;
  @Prop(String) readonly eventLocation_textAlign: string | null;

  /*
DESIGN NOTES:
- All/multi day events display "March 3" or "March 1 - March 4"
- Except in the case that we have no multiday events; then cut the time display
- Date title like "March 3"
- Events titles like "10:00AM - 11:00AM"
*/

  // [x] use txt color for event bullet color as in Week
  // [x] put all day event into one panel, both colors -- oh, or was idea to expose all text format options for alldaytext?
  // hmm.. should be like DayLabels for week (just text/bg color)
  // [x] use timeRange

  // Yeah it seems that text for all day event titles should be editable...(times show as well for multiday...)

  // [ ] eventTime does not respect alignment
  // [ ] Question: should dateFormat affect this alldaytime? I'd guess so...
  // [ ] not exactly clear how hour range should work..if start at 11am, should we catch 9-11:45 event?

  // NOTE: do we want to allow manipulation of start hour? Seems odd given showing of past events...
  // NOTE: ugliness of dot/event title in one div

  // get hasDataConnection() {
  //   return this.data && this.data.length > 0;
  // }

  get calendarDate() {
    const d = DateTime.now()
      .plus({ days: this.dayOffset })
      .set({ hour: this.startHour })
      .set({ minute: 0 });
    return Interval.fromDateTimes(
      d,
      d.plus({ hours: this.endHour - this.startHour })
    );
  }

  getOpacityStyle(ev: CalendarEvent) {
    return {
      opacity: ev && eventIsOver(ev) ? 0.5 : 1,
    };
  }

  get eventBulletColor() {
    return this.eventTime_textColor;
  }

  getBoxFill(ev: CalendarEvent): StyleValue {
    const backgroundColor = eventIsUpcoming(ev)
      ? "white"
      : this.eventBulletColor;

    const result: StyleValue = {};
    if (this.eventBulletColor !== null) {
      result.borderColor = this.eventBulletColor;
    }
    if (backgroundColor !== null) {
      result.backgroundColor = backgroundColor;
    }
    return result;
  }

  get eventsForCalendar() {
    // return this.d.filter(e => this.calendarDate.overlaps(e.interval));

    // const useLiveData =
    //   this.data && this.data.length > 0 && this.previewLiveData;
    // const data = useLiveData ? this.data : dummyCalendarData;

    const data = this.data;

    // if (this.data && this.data.length > 0) {
    // Ensure that if event starts at 5:50pm and cal interval ends at 6pm, we don't try to show it
    const start = this.calendarDate.start;
    const end = this.calendarDate.end.minus({ minutes: 15 });
    const interval = Interval.fromDateTimes(start, end);

    return data
      .map((row: (NodeData | NodeSetData)[]) => new CalendarEvent(row))
      .filter((e: CalendarEvent) => interval.overlaps(e.interval));
    // }
    // return [];
  }

  get dateTitle() {
    if (this.dayFormat === "today") {
      switch (this.dayOffset) {
        case 0:
          return "Today";
        case 1:
          return "Tomorrow";
        case 2:
          return "In 2 Days";
        case 3:
          return "In 3 Days";
      }
      return "Today";
    }
    return this.formatDate(DateTime.now().plus({ days: this.dayOffset }));
    // .toUpperCase(); // This is why not obeying text transform
  }

  get allDayEvents() {
    const longEvents = this.eventsForCalendar.filter(
      (e) => e.durationIsOrExceedsOneDay
    );
    return longEvents.sort((a, b) => {
      return a.start.diff(b.start).milliseconds;
    });
  }

  get multidayEventsExist() {
    return this.allDayEvents.filter((e) => e.durationExceedsOneDay).length > 0;
  }

  formatAllDayTime(ev: CalendarEvent) {
    return ev.durationExceedsOneDay
      ? `${this.formatDate(ev.start)} - ${this.formatDate(ev.end)}`
      : this.formatDate(ev.start);
  }

  get dayFormatTokens(): any {
    switch (this.dayFormat) {
      // Changing this from calendarDay in order to accomodate design, "April 2"
      case "date-short":
        return { month: "long", day: "numeric" };
      case "date-med":
        return { weekday: "short", month: "short", day: "numeric" };
      case "date-long":
        return { weekday: "long", month: "long", day: "numeric" };
      default:
        return {};
    }
  }

  formatDate(dt: DateTime) {
    // return dt.toFormat("LLLL d");
    return dt.toLocaleString(this.dayFormatTokens);
  }

  get shortEvents() {
    const shortEvents = this.eventsForCalendar.filter(
      (e) => !e.durationIsOrExceedsOneDay
    );

    return shortEvents.sort((a, b) => {
      return a.start.diff(b.start).milliseconds;
    });
  }
}
</script>

<style></style>
