import { AppPayload } from "./types/data";
import { RepeaterOptions } from "./components/widgets/Repeater/RepeaterOptions";
import { RendererData } from "./types/rendererData";
import { AppMode } from "@/types";
import {
  getDefaultAnimationOptions,
  AnimatableWidgetTypes,
} from "./components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { cleanAppModel } from "./cleanModel";
import { Widget } from "./components/widgets/Widget";
import {
  addConditionalVersions,
  isConditionalBindingType,
} from "./util/conditionsUtils";
import { DEFAULT_CONDITION_ID } from "./constants";
import { logger } from "@core/logger";
import { PieGraphOptions } from "./components/widgets/PieGraph/PieGraphOptions";
import { StackedGraphOptions } from "./components/widgets/StackedGraph/StackedGraphOptions";

type AppMigration = {
  version: number;
  migrate: (app: AppPayload | RendererData, mode: AppMode) => void;
};

export const migrations: AppMigration[] = [
  {
    version: 7,
    migrate: function (app: AppPayload | RendererData) {
      for (const widgetId in app.model.widgets) {
        const widget = app.model.widgets[widgetId];

        for (const cid in widget.conditionalVersions) {
          const props = widget.conditionalVersions[cid] as
            | StackedGraphOptions
            | PieGraphOptions;
          if (
            (widget.type === "PieGraph" || widget.type === "StackedGraph") &&
            typeof props.colors === "string"
          ) {
            props.colors = (props.colors as string).split("|");
          }
        }
      }
    },
  },
  {
    version: 6,
    migrate: function (app: AppPayload | RendererData) {
      for (const wid in app.model.widgets) {
        /**
         * Convert each `Widget` into a `WidgetWithCondtions`
         */
        addConditionalVersions(app.model.widgets[wid] as unknown as Widget);

        /**
         * Set each `Asset` and `Scalar` and `DataNodeSet` binding to use the default condition id.
         */
        app.dataBindings.forEach((db) => {
          db.conditionUuid =
            db.widgetId === wid && isConditionalBindingType(db.bindingType)
              ? DEFAULT_CONDITION_ID
              : undefined;
        });
      }
    },
  },
  {
    version: 5,
    migrate: function (app: AppPayload | RendererData) {
      const widgets = Object.values(app.model.widgets).filter((widget) =>
        AnimatableWidgetTypes.includes(widget.type)
      );
      Object.values(widgets).forEach((widget) => {
        if (!("animations" in widget)) {
          (widget as unknown as AnimationOptions).animations =
            getDefaultAnimationOptions().animations;
          return;
        }
      });
    },
  },
  {
    version: 4,
    migrate: function () {
      /**
       * This used to be a migration to cleanup font problems
       * and is now covered by cleanAppModel.cleanFonts()
       *
       * Migrations should only exist to apply schema changes
       * to the app model. They should not be used to fix data.
       */
      return;
    },
  },

  {
    version: 3,
    migrate: function (app: AppPayload | RendererData) {
      const widgets = app.model.widgets;
      Object.entries(widgets).forEach((widget) => {
        if (widget[1].type === "Slide") {
          widget[1].type = "Repeater";
          (widget[1] as unknown as RepeaterOptions).columns = 1;
          (widget[1] as unknown as RepeaterOptions).rows = 1;
          (widget[1] as unknown as RepeaterOptions).rowGap = 20;
          (widget[1] as unknown as RepeaterOptions).columnGap = 20;
          (widget[1] as unknown as RepeaterOptions).flow = "row";
        }
        if (
          widget[1].type === "Repeater" &&
          (widget[1] as unknown as RepeaterOptions).flow === undefined
        ) {
          (widget[1] as unknown as RepeaterOptions).flow = "row";
        }
      });
    },
  },
  {
    version: 2,
    migrate: function (app: AppPayload | RendererData) {
      if (app.dataBindings) {
        app.dataBindings.forEach((e) => {
          if (e.bindingType === "DataSetParent") {
            if (!e.parentWidgetId) {
              e.parentWidgetId = app.model.widgets[e.widgetId].parentId;
            }
          }
          if (
            e.bindingType === "DataSet" &&
            e.property === "data" &&
            app.model.widgets[e.widgetId]?.type === "Repeater"
          ) {
            e.bindingType = "DataSetParent";
          }
        });

        /**
         * This kind of data binding gets created accidentally somehow, and should be removed.
         * They give rise to "Scalar bindings must have a query defined" internal server error.
         */
        app.dataBindings = app.dataBindings.filter(
          (e) =>
            !(
              e.bindingType === "Scalar" &&
              e.query === null &&
              e.dataName === "NA"
            )
        );
      }
    },
  },
  {
    version: 1,
    migrate: function () {
      //Base version
    },
  },
];

export const appSchemaVersion = Math.max(...migrations.map((m) => m.version));

export function applyModelMigrations(
  app: AppPayload | RendererData,
  mode: AppMode
) {
  const currentVersion = parseInt(
    app.model.appSchemaVersion?.toString() ?? "1",
    10
  );

  if (currentVersion > appSchemaVersion) {
    logger.track(
      `App schema version ${currentVersion} is greater than the maximum supported version ${appSchemaVersion}.`
    );
  }

  if (currentVersion < appSchemaVersion) {
    console.info(
      `Schema version is ${currentVersion}. But it should be ${appSchemaVersion}`
    );
  }

  if (currentVersion < appSchemaVersion) {
    for (let i = currentVersion + 1; i <= appSchemaVersion; i++) {
      migrations.find(({ version }) => version === i)?.migrate(app, mode);
    }
    app.model.appSchemaVersion = appSchemaVersion;
  }

  cleanAppModel(app.model);
}
