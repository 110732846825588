import { LogicOperation, LogicRule } from "@/types/logic";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";

export function ruleRequiresRightOperand(rule: LogicRule) {
  return !(
    ["Null", "NotNull", "BoolIsTrue", "BoolIsFalse"] as LogicOperation[]
  ).includes(rule.operation);
}

export function validateRule(rule: LogicRule) {
  if (!isNonEmptyString(rule.operandLeft.type)) {
    return false;
  }

  if (!isNonEmptyString(rule.operation)) {
    return false;
  }

  if (ruleRequiresRightOperand(rule)) {
    if (!isNonEmptyString(rule.operandRight.type)) {
      return false;
    }

    const rightHasValue = isNonEmptyString(rule.operandRight.value);

    // Check types whose value's are not auto-selected
    switch (rule.operandRight.type) {
      case "ConnectionScalar":
      case "QueryString":
      case "Static": {
        return rightHasValue;
      }
    }
  }
  return true;
}
