<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="transform origin-center"
    :class="{
      'cursor-pointer bolt-shadow scale-110': isHovered && hover,
    }"
    @click="$emit('click')"
  >
    <defs>
      <linearGradient
        x1="50%"
        y1="1.18553322%"
        x2="50%"
        y2="100%"
        :id="id('yellow-grad')"
      >
        <stop stop-color="#FFFBDA" offset="0%"></stop>
        <stop stop-color="#FFE071" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g>
      <polygon
        stroke-linejoin="round"
        :stroke="stroke"
        stroke-width="1.5"
        :fill="fill"
        fill-rule="evenodd"
        points="9 17 17 6 17 13 23 13 15 24 15 17"
      ></polygon>
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { makeId } from "@/utils";

@Component
export default class LightningBoltIcon extends Vue {
  @Prop(Boolean) on: boolean;
  @Prop({ default: true }) hover: boolean;

  _id = makeId();
  isHovered = false;

  get stroke() {
    return this.isHovered || this.on ? "#FFD129" : "#D1D1D1";
  }

  get fill() {
    return this.isHovered || this.on ? this.url("yellow-grad") : "#F5F5F5";
  }

  mounted() {
    (this.$el as SVGElement).addEventListener("mouseover", this.onMouseOver);
    (this.$el as SVGElement).addEventListener("mouseout", this.onMouseOut);
  }

  beforeDestroy() {
    (this.$el as SVGElement).removeEventListener("mouseover", this.onMouseOver);
    (this.$el as SVGElement).removeEventListener("mouseout", this.onMouseOut);
  }

  onMouseOver() {
    this.isHovered = true;
  }

  onMouseOut() {
    this.isHovered = false;
  }

  id(value: string) {
    return `${this._id}-${value}`;
  }

  href(value: string) {
    return `#${this._id}-${value}`;
  }

  url(value: string) {
    return `url(#${this._id}-${value})`;
  }
}
</script>

<style scoped lang="postcss">
.bolt-shadow {
  filter: drop-shadow(0 1px 3px rgba(194, 117, 0, 0.2));
}
</style>
