<template>
  <div class="divide-y">
    <CollapsePanel>
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <div class="pb-8 px-4 space-y-3">
        <ScalarBinder
          propName="minValue"
          propType="Number"
          v-slot:default="slotProps"
          tooltipPosition="l"
        >
          <NumberInputBar
            class="w-full"
            :value="slotProps.value"
            :width="22"
            @change="apply({ minValue: $event })"
            :dataBound="slotProps.isBound"
          >
            <span v-t="'minValue'"></span>
          </NumberInputBar>
        </ScalarBinder>

        <ScalarBinder
          propName="maxValue"
          propType="Number"
          v-slot:default="slotProps"
          tooltipPosition="l"
        >
          <NumberInputBar
            class="w-full"
            :value="slotProps.value"
            :dataBound="slotProps.isBound"
            :width="22"
            @change="apply({ maxValue: $event })"
          >
            <span v-t="'maxValue'"></span>
          </NumberInputBar>
        </ScalarBinder>

        <ScalarBinder
          propName="currentValue"
          propType="Number"
          v-slot:default="slotProps"
          tooltipPosition="l"
        >
          <NumberInputBar
            class="w-full"
            :value="slotProps.value"
            :width="22"
            @change="apply({ currentValue: $event })"
            :dataBound="slotProps.isBound"
          >
            <span v-t="'currentValue'"></span>
          </NumberInputBar>
        </ScalarBinder>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'progress.progressCircle'"></div>
      </template>
      <div class="pb-8 px-4 space-y-3">
        <ColorInputBar
          :value="model.fgFill"
          :iconSize="'5'"
          :gradientEnabled="false"
          @input="preview({ fgFill: $event })"
          @change="apply({ fgFill: $event })"
          propName="fgFill"
        >
          <span v-t="'fill'"></span
        ></ColorInputBar>
        <ColorInputBar
          :value="model.bgFill"
          :iconSize="'5'"
          :gradientEnabled="false"
          @input="preview({ bgFill: $event })"
          @change="apply({ bgFill: $event })"
          propName="bgFill"
        >
          <span v-t="'background'"></span
        ></ColorInputBar>
        <DoubleSliderInput
          class="mb-4"
          :min="0"
          :max="100"
          :minRange="1"
          :minValue="model.fgInnerRadius"
          :maxValue="model.fgOuterRadius"
          @updateMin="updateSliderValue($event, 'fgInnerRadius')"
          @updateMax="updateSliderValue($event, 'fgOuterRadius')"
          ><div v-t="'progress.progressRadius'"></div
        ></DoubleSliderInput>
        <DoubleSliderInput
          class="mb-4"
          :min="0"
          :max="100"
          :minRange="1"
          :minValue="model.bgInnerRadius"
          :maxValue="model.bgOuterRadius"
          @updateMin="updateSliderValue($event, 'bgInnerRadius')"
          @updateMax="updateSliderValue($event, 'bgOuterRadius')"
          ><div v-t="'progress.backgroundRadius'"></div
        ></DoubleSliderInput>
        <NumberInputBar
          :value="model.cornerRadius"
          @change="apply({ cornerRadius: $event })"
        >
          <span v-t="'progress.cornerRadius'"></span>
        </NumberInputBar>
      </div>
    </CollapsePanel>
    <div>
      <BaseWidgetEditor :selectionLocked="model.locked" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import ColorPicker from "@/components/inputs/ColorPicker.vue";
import NumberInputBar from "@/components/inputs/NumberInputBar.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import DoubleSliderInput from "@/components/inputs/DoubleSliderInput.vue";
import {
  ProgressDonutOptions,
  defaultProgressDonutOptions,
} from "./ProgressDonutOptions";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CollapsePanel,
    ColorPicker,
    ColorInputBar,
    NumberInputBar,
    ToggleInput,
    ButtonGradient,
    SelectMenu,
    BaseWidgetEditor,
    DoubleSliderInput,
    ScalarBinder,
  },
})
export default class ProgressDonutEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as ProgressDonutOptions;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  get selectionLocked() {
    return true;
  }

  get defaults() {
    return defaultProgressDonutOptions;
  }

  get linecapOptions() {
    return [
      {
        label: "Butt",
        value: "butt",
      },
      {
        label: "Round",
        value: "round",
      },
    ];
  }

  updateSliderValue(ev: any, prop: string) {
    const { value, track } = ev;
    this.apply({ [prop]: value, track });
  }
}
</script>
