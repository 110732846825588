<template>
  <component :is="renderComponent" v-bind="$props" />
</template>

<script lang="ts">
import { DataType, DataValue } from "@/types/data";
import { Component, Prop, Vue } from "vue-property-decorator";

import DataNodeDateTime from "@/components/data/DataNodeDateTime.vue";
import DataNodeValue from "@/components/data/DataNodeValue.vue";
import DataNodeImage from "@/components/data/DataNodeImage.vue";
import DataNodeColor from "@/components/data/DataNodeColor.vue";
import DataNodeEmpty from "@/components/data/DataNodeEmpty.vue";

@Component({
  components: {
    DataNodeDateTime,
    DataNodeValue,
    DataNodeImage,
    DataNodeColor,
    DataNodeEmpty,
  },
})
export default class DataNodeDisplay extends Vue {
  @Prop({ type: String, required: false }) readonly uuid: string;
  @Prop(String) readonly dataType: DataType;
  @Prop(Object) readonly value: DataValue;

  get renderComponent() {
    switch (this.dataType) {
      case "Time":
      case "Date":
      case "DateTime":
        return `DataNodeDateTime`;
      case "String":
      case "Bool":
      case "Number":
        return `DataNodeValue`;
      case "Color":
        return `DataNodeColor`;
      case "ImageUrl":
      case "ImageUpload":
        return `DataNodeImage`;
      default:
        return `DataNodeEmpty`;
    }
  }
}
</script>
