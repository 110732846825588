<template>
  <div class="divide-y">
    <CollapsePanel>
      <template slot="title">
        <div v-t="'animation.animation'"></div>
      </template>
      <AnimationEditor />
    </CollapsePanel>

    <div>
      <div class="px-4 py-3">
        <ToggleInput
          class=""
          :value="loop"
          :bgTransparent="true"
          @input="apply({ loop: $event })"
          ><span class="text-sm" v-t="'VideoEditor.loop'"></span
        ></ToggleInput>
      </div>

      <BaseWidgetEditor :selectionLocked="selectedWidget?.locked ?? false" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CollapsePanel from "@/components/CollapsePanel.vue";

import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import AnimationEditor from "@/components/editors/AnimationEditor.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";

import { useAppEditorStore } from "@/stores/appEditor";
import { VideoOptions } from "./VideoOptions";

@Component({
  components: {
    CollapsePanel,
    BaseWidgetEditor,
    AnimationEditor,
    ToggleInput,
  },
})
export default class VideoEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget as VideoOptions;
  }

  apply(props: Partial<VideoOptions>) {
    if (this.selectedWidget !== undefined) {
      this.appEditor.setWidgetProps([this.selectedWidget?.wid], props);
    }
  }

  onUrlChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    this.apply({ url: target.value });
  }

  onMimeTypeChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    this.apply({ mimeType: target.value });
  }

  get dataParentUuid() {
    if (this.selectedWidget) {
      const bindings = this.appEditor.bindingsForComponent({
        widgetId: this.selectedWidget.parentId,
      });
      if (bindings.length > 0) {
        return bindings[0].dataUuid;
      }
    }
    return undefined;
  }

  get showDataPanel() {
    return typeof this.dataParentUuid === "string";
  }

  get loop() {
    return this.selectedWidget.loop;
  }

  get url() {
    return this.selectedWidget.url;
  }

  get mimeType() {
    return this.selectedWidget.mimeType;
  }
}
</script>
