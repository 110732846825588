<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import CalendarEventComponent from "@/components/widgets/CalendarEvent/CalendarEventComponent.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { EventBus } from "@/eventbus";

@Component
export default class CalendarEventWrapper extends CalendarEventComponent {
  get appEditor() {
    return useAppEditorStore();
  }

  get scale() {
    return this.appEditor.scale;
  }

  get watcherObject() {
    const result: any = {
      scaleX: this.scaleX,
      showEventLocation: this.showEventLocation,
      showEventDescription: this.showEventDescription,
      showEventCreator: this.showEventCreator,
      showDivider: this.showDivider,
      data: this.d,
    };

    return {
      ...this.textLayoutProps,
      ...result,
    };
  }

  @Watch("watcherObject", { deep: true })
  watcherChanged() {
    this.$nextTick(this.updateHeight);
  }

  /**
   * This only needs to be called from within the app editor.
   * It essentially serves to update the height of the selection box.
   */
  updateHeight() {
    const bbox = (this.$refs.content as HTMLElement)?.getBoundingClientRect();
    if (!bbox) return;
    const props = { h: bbox.height / this.scale };
    this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
  }

  created() {
    EventBus.on("FONTS_LOADED", this.handleFontsLoaded);
  }
  mounted() {
    this.updateHeight();
  }

  beforeDestroy() {
    EventBus.off("FONTS_LOADED", this.handleFontsLoaded);
  }

  handleFontsLoaded(id: string) {
    if (id === "in-use") {
      this.$nextTick(this.updateHeight);
    }
  }
}
</script>
