import { NodeKind } from "@/types/data";
import { makeHourTodayDate, weekday } from "@/types/calendar";
import { DateTime } from "luxon";

// NOTE: Should this actually populate the full week in the same way every time?
// TODO: No...that's an issue..can't always just go 3 days into future, for week, e.g. if today is Saturday

const multiOffset = () => (weekday() < 5 ? 2 : -3);

interface EventPlaceholderNode {
  query: string;
  value: string | Date;
  dataType?: string;
}

export const CalendarEventPlaceholderData = () => [
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(15, 0, 30).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(17, 0, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Event Title Here",
      formattedValue: "Event Title Here",
    },
    {
      query: "Location",
      value: "Conference Room",
      formattedValue: "Conference Room",
    },
    {
      query: "CreatorEmail",
      value: "user@email.com",
      formattedValue: "user@email.com",
    },
  ],
].map(prepareCalendarNode);

export const CalendarAgendaPlaceholderData = () => [
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(15, 0, 30).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(17, 0, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Weekly Leadership Meeting",
      formattedValue: "Weekly Leadership Meeting",
    },
    {
      query: "Location",
      value: "Conference Room",
      formattedValue: "Conference Room",
    },
    {
      query: "CreatorEmail",
      value: "user@email.com",
      formattedValue: "user@email.com",
    },
  ],
  // Event 2
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(10, 0, 15).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(12, 0, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Product Roadmap Discussion",
      formattedValue: "Product Roadmap Discussion",
    },
    {
      query: "Location",
      value: "Conference Room",
      formattedValue: "Conference Room",
    },
    {
      query: "CreatorEmail",
      value: "user@email.com",
      formattedValue: "user@email.com",
    },
  ],
  // Event 3 (all day)
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(0, 0, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(0, 1, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Jeremy Click's Birthday (All day)",
      formattedValue: "Jeremy Click's Birthday (All day)",
    },
    {
      query: "Location",
      value: "Conference Room",
      formattedValue: "Conference Room",
    },
    {
      query: "CreatorEmail",
      value: "user@email.com",
      formattedValue: "user@email.com",
    },
  ],
  // Event 4 (multi day)
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(0, multiOffset(), 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(0, multiOffset() + 2, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Conference (Multi day)",
      formattedValue: "Conference (Multi day)",
    },
    {
      query: "Location",
      value: "Conference Room",
      formattedValue: "Conference Room",
    },
    {
      query: "CreatorEmail",
      value: "user@email.com",
      formattedValue: "user@email.com",
    },
  ],
]
  // Add dummy data 3 weeks into future (needed for CalendarWeek):
  .flatMap((event: EventPlaceholderNode[]) => {
    const futureEvents = [event];
    for (let i = 1; i <= 3; i++) {
      const newEvent = event.map((node) => {
        if (["Start", "End"].includes(node.query)) {
          return {
            ...node,
            value: DateTime.fromJSDate(node.value as Date)
              .plus({ days: 6 * i }) // Using 6 instead of 7 offsets all events by one day, each week into future, showing off the widget better
              .toJSDate(),
          };
        } else {
          return node;
        }
      });
      futureEvents.push(newEvent);
    }

    return futureEvents;
  })
  .map(prepareCalendarNode);

export const CalendarPlaceholderData = () => [
  // Event 1
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(15, 0, 30).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(17, 0, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Homecoming Planning Meeting",
      formattedValue: "Homecoming Planning Meeting",
    },
    {
      query: "Location",
      value: "Room 123",
      formattedValue: "Room 123",
    },
    {
      query: "CreatorEmail",
      value: "user@email.com",
      formattedValue: "user@email.com",
    },
  ],
  // Event 2
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(10, 0, 15).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(12, 0, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Guidance Counselor Huddle",
      formattedValue: "Guidance Counselor Huddle",
    },
    {
      query: "Location",
      value: "Room 123",
      formattedValue: "Room 123",
    },
  ],
  // Event 3 (all day)
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(0, 0, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(0, 1, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "New Student Orientation (All day)",
      formattedValue: "New Student Orientation (All day)",
    },
    {
      query: "Location",
      value: "Auditorium",
      formattedValue: "Auditorium",
    },
  ],
  // Event 4 (multi day)
  [
    {
      query: "Description",
      value: "This is an event description.",
      formattedValue: "This is an event description.",
    },
    {
      query: "Start",
      value: makeHourTodayDate(0, multiOffset(), 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "End",
      value: makeHourTodayDate(0, multiOffset() + 2, 0).toJSDate(),
      dataType: "DateTime",
    },
    {
      query: "Summary",
      value: "Test Event 4 (Multi day)",
      formattedValue: "Test Event 4 (Multi day)",
    },
    {
      query: "Location",
      value: "Room 100",
      formattedValue: "Room 100",
    },
  ],
].map(prepareCalendarNode);

function prepareCalendarNode(row: any[]) {
  return row.map((node) => {
    return {
      ...node,
      uuid: node.query,
      displayName: node.query,
      kind: NodeKind.node,
      dataType: node.dataType || "String",
    };
  });
}
