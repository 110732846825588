import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DateTime } from "luxon";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface CountdownOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  datetimeValue: string;
  width: number;
  height: number;
  pastAllowed: boolean;
  countsUp: boolean;
  paused: boolean;
  pausedDateTime: string;
  time_textColor: string;
  time_fontFamily: string;
  time_fontSize: number;
  time_fontWeight: number;
  time_textTransform: string;
  time_fontStyle: string;
  time_letterSpacing: number;
  label_textColor: string;
  label_fontFamily: string;
  label_fontSize: number;
  label_fontWeight: number;
  label_textTransform: string;
  label_fontStyle: string;
  label_letterSpacing: number;
  syncFonts: boolean;
  syncTextColors: boolean;
  marginX: number;
  marginY: number;
  timeUnits: string;
  showDivider: boolean;
  showYears: boolean;
  showMonths: boolean;
  showWeeks: boolean;
  showDays: boolean;
  showHours: boolean;
  showMinutes: boolean;
  showSeconds: boolean;
}

const date = new Date();
const year = date.getFullYear();
const month = date.getMonth();
const day = date.getDate();
const future = new Date(year, month + 1, day + 12).toISOString().split("T")[0];
const dateTime = DateTime.now().toISO();

export const defaultCountdownOptions = {
  datetimeValue: future,
  countsUp: false,
  pastAllowed: false,
  paused: false,
  pausedDateTime: dateTime,
  time_textColor: "#1d283a",
  time_fontFamily: "Montserrat",
  time_fontSize: 108,
  time_fontWeight: 700,
  time_textTransform: "capitalize",
  time_fontStyle: "normal",
  time_letterSpacing: 0,
  label_textColor: "#16a249",
  label_fontFamily: "Montserrat",
  label_fontSize: 24,
  label_fontWeight: 400,
  label_textTransform: "uppercase",
  label_fontStyle: "normal",
  label_letterSpacing: 0,
  syncFonts: true,
  syncTextColors: false,
  marginX: 5,
  marginY: 0,
  timeUnits: "days,hours,minutes,seconds",
  showDivider: true,
  showYears: false,
  showMonths: false,
  showWeeks: false,
  showDays: true,
  showHours: true,
  showMinutes: true,
  showSeconds: true,
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultCountdownOptions,
  showDivider: true,
};

const CreateCountdown = (options: Partial<CountdownOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "Countdown",
    wid: makeId(6),
    name: DEFAULT_LAYER_NAMES.Countdown,
  });
};

export default CreateCountdown;
