<template>
  <div class="relative">
    <form @submit.prevent="onContinue">
      <div class="text-xl mb-2" v-t="'SetupName.enterConnectionName'"></div>
      <input
        ref="nameInput"
        type="text"
        class="px-2 py-1 w-128 flex-grow rounded-sm border text-gray-900 dark-form-focus"
        v-model.trim="name"
      />
    </form>

    <portal to="setupWizardNext">
      <FormButton
        @click="onContinue"
        :disabled="!name"
        v-t="'continue'"
      ></FormButton>
    </portal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";
import FormButton from "@/components/FormButton.vue";
import { SetupStep } from "@/types/ConnectionSources";

const props = defineProps<{
  setupStep: SetupStep | undefined;
}>();

const emits = defineEmits<{
  (e: "complete", value: SetupStep): void;
}>();

const name = ref("");
const nameInput = ref<HTMLInputElement>();

const setupStore = useConnectionSetupStoreV2();

onMounted(() => {
  name.value = suggestedConnectionName();
  nameInput.value?.focus();
});

function suggestedConnectionName() {
  return "";
}

function onContinue() {
  setupStore.name = name.value;
  emits("complete", props.setupStep!);
}
</script>
