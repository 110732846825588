<template>
  <component
    :is="`IconSolid${name}`"
    fill="currentColor"
    viewBox="0 0 20 20"
    stroke="none"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconSolidArchive from "./IconSolidArchive.vue";
import IconSolidBadgeCheck from "./IconSolidBadgeCheck.vue";
import IconSolidChip from "./IconSolidChip.vue";
import IconSolidClipboardList from "./IconSolidClipboardList.vue";
import IconSolidColorSwatch from "./IconSolidColorSwatch.vue";
import IconSolidDuplicate from "./IconSolidDuplicate.vue";
import IconSolidEye from "./IconSolidEye.vue";
import IconSolidFilter from "./IconSolidFilter.vue";
import IconSolidHashtag from "./IconSolidHashtag.vue";
import IconSolidLink from "./IconSolidLink.vue";
import IconSolidTemplate from "./IconSolidTemplate.vue";
import IconSolidViewGrid from "./IconSolidViewGrid.vue";
import IconSolidViewList from "./IconSolidViewList.vue";
import IconSolidLogout from "./IconSolidLogout.vue";
import IconSolidChevronRight from "./IconSolidChevronRight.vue";
import IconSolidData from "./IconSolidData.vue";
import IconSolidTrash from "./IconSolidTrash.vue";
import IconSolidX from "./IconSolidX.vue";
import IconSolidViewExpanded from "./IconSolidViewExpanded.vue";
import IconSolidExclamation from "./IconSolidExclamation.vue";
import IconSolidXCircle from "./IconSolidXCircle.vue";
import IconSolidSelectCarets from "./IconSolidSelectCarets.vue";
import IconSolidCheckmark from "./IconSolidCheckmark.vue";
import IconSolidCog from "./IconSolidCog.vue";
import IconSolidSort from "./IconSolidSort.vue";
import IconSolidPencil from "./IconSolidPencil.vue";
import IconSolidInsertAbove from "./IconSolidInsertAbove.vue";
import IconSolidInsertBelow from "./IconSolidInsertBelow.vue";
import IconSolidMoveToFront from "./IconSolidMoveToFront.vue";
import IconSolidMoveToBack from "./IconSolidMoveToBack.vue";
import IconSolidUndo from "./IconSolidUndo.vue";
import IconSolidReposition from "./IconSolidReposition.vue";
import IconSolidRemove from "./IconSolidRemove.vue";
import IconSolidRefresh from "./IconSolidRefresh.vue";
import IconSolidCheckmarkFilled from "./IconSolidCheckmarkFilled.vue";
import IconSolidHand from "./IconSolidHand.vue";
import IconSolidPlusCircle from "./IconSolidPlusCircle.vue";

@Component({
  components: {
    IconSolidArchive,
    IconSolidBadgeCheck,
    IconSolidChip,
    IconSolidClipboardList,
    IconSolidColorSwatch,
    IconSolidDuplicate,
    IconSolidEye,
    IconSolidFilter,
    IconSolidHashtag,
    IconSolidLink,
    IconSolidTemplate,
    IconSolidViewGrid,
    IconSolidViewList,
    IconSolidLogout,
    IconSolidChevronRight,
    IconSolidData,
    IconSolidTrash,
    IconSolidX,
    IconSolidViewExpanded,
    IconSolidExclamation,
    IconSolidXCircle,
    IconSolidSelectCarets,
    IconSolidCheckmark,
    IconSolidCog,
    IconSolidSort,
    IconSolidPencil,
    IconSolidInsertAbove,
    IconSolidInsertBelow,
    IconSolidMoveToFront,
    IconSolidMoveToBack,
    IconSolidUndo,
    IconSolidReposition,
    IconSolidRefresh,
    IconSolidRemove,
    IconSolidCheckmarkFilled,
    IconSolidHand,
    IconSolidPlusCircle,
  },
})
export default class IconSolid extends Vue {
  @Prop(String) name: string;
}
</script>

<style scoped></style>
