<template>
  <Modal
    @close="closeAppSettingsModal"
    size="2/5"
    customBg="bg-app-dark3"
    class="text-white relative"
  >
    <div class="flex flex-col h-3/5">
      <div class="flex flex-row items-center h-18 px-6 py-4">
        <div class="flex flex-1">
          <div v-t="'appSettings'" class="font-bold w-full"></div>
        </div>
        <div class="flex flex-row flex-1 justify-end">
          <ButtonGradient
            padding="px-4 py-1 mr-6"
            class="whitespace-nowrap"
            @click="saveAppSettings()"
            >{{ $t("templates.save") }}</ButtonGradient
          >
          <ButtonGradient
            @click="closeAppSettingsModal"
            class="flex justify-end border border-gray-500 text-white"
            customGradient="linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,0))"
            padding="px-4 py-1"
          >
            <span v-t="'cancel'"></span>
          </ButtonGradient>
        </div>
      </div>
      <div class="w-full bg-gray-900" style="height: 1px"></div>
      <div
        class="p-6 pt-3 flex flex-col w-full space-y-3"
        style="max-height: 75vh; overflow-y: scroll"
      >
        <div class="flex flex-col">
          <label class="pb-1" v-t="'name'"></label>
          <input
            type="text"
            class="rounded px-2 py-1 text-black font-semibold"
            :class="{ warning: validName === false }"
            v-model="newAppName"
            @input="handleNameChange"
          />
          <div class="py-2 text-sm" v-if="validName === false">
            <span
              class="bg-red-600 bg-opacity-60 bg px-2 py-1 rounded-sm border-1 border-red-500"
              v-t="'warnings.nameRequired'"
            ></span>
          </div>
        </div>
        <div class="flex flex-col w-full pb-3">
          <label class="pb-1 mt-2" v-t="'timezone.timezone'"></label>
          <TimeZoneSelect :value="timeZone" @selectTimeZone="selectTimeZone" />
        </div>
        <div class="border-t border-b border-gray-900 py-3 flex flex-col py-2">
          <label class="pb-1" v-t="'dimensions'"></label>
          <div class="flex flex-row">
            <div class="w-16 flex flex-col items-center">
              <input
                type="number"
                class="w-full text-center rounded px-1 py-1 text-black font-semibold"
                :class="{ warning: !validWidth }"
                v-model.number="newWidth"
                @input="handleSizeInput"
              />
              <label class="text-xs uppercase font-light mt-1" v-t="'width'" />
            </div>
            <div class="w-10 text-center font-bold pt-1">×</div>
            <div class="w-16 flex flex-col items-center">
              <input
                type="number"
                class="w-full text-center rounded px-1 py-1 text-black font-semibold"
                :class="{ warning: !validHeight }"
                v-model.number="newHeight"
                @input="handleSizeInput"
              />
              <label class="text-xs uppercase font-light mt-1" v-t="'height'" />
            </div>
          </div>
          <div class="py-2 text-sm" v-if="!validDimensions">
            <span
              class="bg-red-600 bg-opacity-60 bg px-2 py-1 rounded-sm border-1 border-red-500"
              v-t="'warnings.invalidDimensions'"
            ></span>
          </div>
        </div>
        <ExpandTransition>
          <div v-if="sizeWillChange">
            <div class="py-2 space-y-2 flex-col justify-start">
              <div>Automatically scale objects?</div>

              <div class="text-sm">
                <div
                  class="flex items-start space-x-2 p-2 rounded border"
                  :class="{
                    'border-gray-500': scaleContent,
                    'border-transparent': !scaleContent,
                  }"
                >
                  <input
                    class="transform translate-y-px w-4 h-4 shrink-0"
                    type="radio"
                    name="resize"
                    id="yesResize"
                    v-model="scaleContent"
                    :value="true"
                  />
                  <label for="yesResize"
                    ><span class="font-bold" v-t="'appSettingsMenu.yes'"></span
                    ><span v-t="'appSettingsMenu.yesExplain'"></span
                  ></label>
                </div>
                <div
                  class="flex items-start space-x-2 p-2 rounded border"
                  :class="{
                    'border-gray-500': !scaleContent,
                    'border-transparent': scaleContent,
                  }"
                >
                  <input
                    class="transform translate-y-px w-4 h-4 shrink-0"
                    type="radio"
                    name="resize"
                    id="noResize"
                    v-model="scaleContent"
                    :value="false"
                  />
                  <label for="noResize"
                    ><span class="font-bold" v-t="'appSettingsMenu.no'"></span
                    ><span v-t="'appSettingsMenu.noExplain'"></span
                  ></label>
                </div>
              </div>
            </div>
            <ExpandTransition>
              <div v-if="!scaleContent" class="mt-2 space-y-1">
                <div>{{ $t("appSettingsMenu.anchor") }}</div>

                <div class="flex items-center space-x-4 pl-2">
                  <table class="inline">
                    <tr :key="rIndex" v-for="(row, rIndex) in resizeAnchors">
                      <td :key="cIndex" v-for="(a, cIndex) in row">
                        <button
                          type="button"
                          @click="anchorPoint = a"
                          class="cursor-pointer block border border-gray-500 rounded-md font-bold text-white w-5 h-5"
                          :class="{ anchor: anchorPoint === a }"
                          padding="px-4 py-1"
                        ></button>
                      </td>
                    </tr>
                  </table>
                  <div class="text-sm pb-2">
                    {{ $t("appSettingsMenu.anchorInfo") }}
                  </div>
                </div>
              </div>
            </ExpandTransition>
          </div>
        </ExpandTransition>

        <!-- Preloader image input: -->
        <div class="flex flex-col space-y-3 pb-4 border-b border-gray-900">
          <label class="-mb-1" v-t="'preloaderImageTitle'"></label>
          <div
            class="text-sm font-light italic text-xs"
            v-t="'AppSettings.introImageDescription'"
          ></div>

          <div class="flex space-x-4">
            <div class="flex flex-col items-center">
              <ImageUpload
                class="border border-gray-900 text-xs"
                :class="{
                  'bg-gray-600 hover:bg-gray-500': !introImagePreviewUrl,
                  'bg-black hover:bg-gray-800': introImagePreviewUrl,
                }"
                :width="thumbnailWidth"
                :height="thumbnailHeight"
                :assetFunctionType="'IntroImage'"
                :imageUrl="introImagePreviewUrl"
                @complete="onIntroImageUploadComplete"
              />

              <button
                class="text-xs flex items-center space-x-1 mt-1 text-gray-400 hover:text-gray-100"
                v-if="introImagePreviewUrl"
                @click="deleteImageByType('IntroImage')"
              >
                <Icon name="Trash" class="w-4 h-4" />
                <span v-t="'remove'"></span>
              </button>
            </div>

            <div class="flex flex-col space-y-1">
              <label
                class="text-sm"
                v-t="'AppSettings.minimumTimeIntro'"
              ></label>
              <div class="w-24 flex space-x-1 items-center">
                <input
                  type="number"
                  class="w-full text-center rounded px-1 py-1 text-black font-semibold"
                  v-model.number="minimumSecondsToDisplayPreloader"
                  min="0"
                />

                <label class="text-xs" v-t="'AppSettings.seconds'"></label>
              </div>
            </div>
          </div>
        </div>

        <!-- Fallback image input: -->
        <div class="flex flex-col space-y-3 pb-4 border-b border-gray-900">
          <label class="-mb-1" v-t="'fallbackImageTitle'"></label>
          <div
            class="text-sm font-light italic text-xs"
            v-t="'AppSettings.fallbackImageDescription'"
          ></div>

          <div class="flex space-x-4">
            <div class="flex flex-col items-center">
              <ImageUpload
                class="border border-gray-900 text-xs"
                :class="{
                  'bg-gray-600 hover:bg-gray-500': !fallbackImagePreviewUrl,
                  'bg-black hover:bg-gray-800': fallbackImagePreviewUrl,
                }"
                :width="thumbnailWidth"
                :height="thumbnailHeight"
                :assetFunctionType="'FallbackImage'"
                :imageUrl="fallbackImagePreviewUrl"
                @complete="onFallbackImageUploadComplete"
              />

              <button
                class="text-xs flex items-center space-x-1 mt-1 text-gray-400 hover:text-gray-100"
                v-if="fallbackImagePreviewUrl"
                @click="deleteImageByType('FallbackImage')"
              >
                <Icon name="Trash" class="w-4 h-4" />
                <span>Remove</span>
              </button>
            </div>

            <div class="flex flex-col space-y-1">
              <label
                class="text-sm"
                v-t="'AppSettings.maxTimeStaleData'"
              ></label>
              <div class="w-24 flex space-x-1 items-center">
                <input
                  type="number"
                  class="w-full text-center rounded px-1 py-1 text-black font-semibold"
                  v-model.number="maximumMinutesAllowedForStaleData"
                  min="0"
                />

                <label class="text-xs" v-t="'AppSettings.minutes'"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UiBlocker :visible="saving" v-t="'AppSettings.saving'"></UiBlocker>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ExpandTransition from "@/components/transitions/ExpandTransition.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import Modal from "@/components/Modal.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import Icon from "@/components/icons/IconSolid.vue";
import TimeZoneSelect from "@/components/TimeZoneSelect.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import OutlineButton from "@/components/OutlineButton.vue";

import { useAppEditorStore } from "@/stores/appEditor";
import debounce from "lodash.debounce";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { removeReactivity } from "@/utils";
import { WidgetWithConditions } from "./widgets/Widget";
import {
  BASE_PARENT_ID,
  DEFAULT_FALLBACK_IMAGE_TIMEOUT_SEC,
  DEFAULT_INTRO_IMAGE_TIMEOUT_SEC,
} from "@/constants";
import { SavedAsset, Size, AssetFunctionType } from "@/types";
import { api } from "@/api/backend";
import { logger } from "@core/logger";

const getScaleFactor = (oldSize: Size, newSize: Size): number => {
  const wr = oldSize.w / newSize.w;
  const hr = oldSize.h / newSize.h;
  const proportion = 1 / Math.max(wr, hr);
  return proportion;
};

const scaleAndResizeWidgets = (
  widgets: Record<string, WidgetWithConditions>,
  oldSize: Size,
  newSize: Size,
  anchorPoint: string,
  scale: boolean
) => {
  const newAR = newSize.w / newSize.h;
  const oldAR = oldSize.w / oldSize.h;
  const scaleFactor = scale ? getScaleFactor(oldSize, newSize) : 1;

  var xAnchor;
  var yAnchor;

  switch (anchorPoint) {
    case "topLeft":
      xAnchor = 0;
      yAnchor = 0;
      break;
    case "topCenter":
      xAnchor = (newSize.w - oldSize.w * scaleFactor) / 2;
      yAnchor = 0;
      break;
    case "topRight":
      xAnchor = newSize.w - oldSize.w;
      yAnchor = 0;
      break;
    case "midLeft":
      xAnchor = 0;
      yAnchor = (newSize.h - oldSize.h * scaleFactor) / 2;
      break;
    case "center":
      xAnchor = (newSize.w - oldSize.w * scaleFactor) / 2;
      yAnchor = (newSize.h - oldSize.h * scaleFactor) / 2;
      break;
    case "midRight":
      xAnchor = newSize.w - oldSize.w;
      yAnchor = (newSize.h - oldSize.h * scaleFactor) / 2;
      break;
    case "botLeft":
      xAnchor = 0;
      yAnchor = newSize.h - oldSize.h;
      break;
    case "botCenter":
      xAnchor = (newSize.w - oldSize.w * scaleFactor) / 2;
      yAnchor = newSize.h - oldSize.h;
      break;
    case "botRight":
      xAnchor = newSize.w - oldSize.w;
      yAnchor = newSize.h - oldSize.h;
  }

  const xOffset = scale ? (newAR > oldAR ? xAnchor : 0) : xAnchor;
  const yOffset = scale ? (newAR > oldAR ? 0 : yAnchor) : yAnchor;

  const PROPS_TO_SCALE = [
    "w",
    "h",
    "x",
    "y",
    "shadowX",
    "shadowY",
    "shadowBlur",
    "borderWidth",
  ];
  // Adding "lineHeight" had bad results
  const PROPS_SUFFIXES_TO_SCALE = ["fontSize", "letterSpacing"];
  const result: Record<string, WidgetWithConditions> = {};

  for (let widgetId in widgets) {
    const copy = removeReactivity<WidgetWithConditions>(widgets[widgetId]);
    for (let versionId in copy.conditionalVersions) {
      if (copy.parentId === BASE_PARENT_ID) {
        const props = copy.conditionalVersions[versionId];
        for (let key in props) {
          if (
            PROPS_TO_SCALE.includes(key) ||
            PROPS_SUFFIXES_TO_SCALE.some((p) => key.includes(p))
          ) {
            props[key] *= scaleFactor;
          }
        }

        props.x += xOffset || 0;
        props.y += yOffset || 0;
      }
    }
    result[widgetId] = copy;
  }

  return result;
};

@Component({
  components: {
    UiBlocker,
    Icon,
    ExpandTransition,
    Modal,
    TimeZoneSelect,
    ButtonGradient,
    OutlineButton,
    ImageUpload,
  },
})
export default class AppSettings extends Vue {
  validName = true;
  validWidth = true;
  validHeight = true;
  saving = false;

  newAppName = "";
  timeZone = "";

  newWidth = 1000;
  newHeight = 1000;

  scaleContent = true;
  anchorPoint = "center";
  sizeWillChange = false;

  minimumSecondsToDisplayPreloader = DEFAULT_INTRO_IMAGE_TIMEOUT_SEC;
  maximumMinutesAllowedForStaleData = Math.floor(
    DEFAULT_FALLBACK_IMAGE_TIMEOUT_SEC / 60
  );

  created() {
    this.newAppName = this.name;
    this.newWidth = this.width;
    this.newHeight = this.height;
    this.timeZone =
      this.ianaTimeZone && this.ianaTimeZone != ""
        ? this.ianaTimeZone.toLowerCase()
        : Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();

    if (typeof this.appEditor.introImageTimeoutSec === "number") {
      this.minimumSecondsToDisplayPreloader = this.appEditor
        .introImageTimeoutSec as number;
    }
    // Convert seconds (how the number is stored) to minutes (how it is manipulated in UI):
    if (typeof this.appEditor.fallbackImageTimeoutSec === "number") {
      this.maximumMinutesAllowedForStaleData = Math.floor(
        this.appEditor.fallbackImageTimeoutSec / 60
      );
    }
  }

  fitRectangleWithinBounds(
    size: { width: number; height: number },
    bounds: { width: number; height: number }
  ) {
    const aspectRatio = size.width / size.height;
    const boundsAspectRatio = bounds.width / bounds.height;
    if (aspectRatio > boundsAspectRatio) {
      // width is the limiting factor
      return {
        width: bounds.width,
        height: bounds.width / aspectRatio,
      };
    } else {
      // height is the limiting factor
      return {
        width: bounds.height * aspectRatio,
        height: bounds.height,
      };
    }
  }

  get thumbnailWidth() {
    return this.fitRectangleWithinBounds(
      { width: this.appEditor.width, height: this.appEditor.height },
      { width: 120, height: 90 }
    ).width;
  }

  get thumbnailHeight() {
    return this.fitRectangleWithinBounds(
      { width: this.appEditor.width, height: this.appEditor.height },
      { width: 120, height: 90 }
    ).height;
  }

  get introImagePreviewUrl() {
    return this.appEditor.assets.find((a) => a.functionType === "IntroImage")
      ?.url;
  }

  get fallbackImagePreviewUrl() {
    return this.appEditor.assets.find((a) => a.functionType === "FallbackImage")
      ?.url;
  }

  async deleteImageByType(imageType: AssetFunctionType) {
    const assetIdx = this.appEditor.assets.findIndex(
      (a) => a.functionType === imageType
    );
    if (assetIdx > -1) {
      const asset = this.appEditor.assets[assetIdx];
      this.deleteAsset(asset.uuid);
    }
  }

  async deleteAsset(assetId: string) {
    try {
      await api.delete(
        `assets/${assetId}?appVersionUuid=${this.appEditor.appVersionUuid}`
      );
    } catch (err) {
      logger.track("Unable to delete asset from app settings page", err);
    }
  }

  onIntroImageUploadComplete(image: SavedAsset) {
    this.onUploadComplete(image, "IntroImage");
  }

  onFallbackImageUploadComplete(image: SavedAsset) {
    this.onUploadComplete(image, "FallbackImage");
  }

  async onUploadComplete(image: SavedAsset, imageType: AssetFunctionType) {
    // If user is replacing existing image, we want to remove it first
    const asset = this.appEditor.assets.find(
      (a) => a.functionType === imageType
    );

    // Doesn't have functionType at this point, so add it
    image.functionType = imageType;
    this.appEditor.assets.push(image);

    if (asset) {
      const assetIdx = this.appEditor.assets.findIndex(
        (a) => a.uuid === asset.uuid
      );
      this.appEditor.assets.splice(assetIdx, 1);
      await this.deleteAsset(asset.uuid);
    }
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get name() {
    return this.appEditor.name;
  }

  get width() {
    return this.appEditor.width;
  }

  get height() {
    return this.appEditor.height;
  }

  get rulerSize() {
    return this.appEditor.rulerSize;
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get ianaTimeZone() {
    return this.appEditor.ianaTimeZone;
  }

  // APP DIMENSIONS ---------------------------------------------------
  handleSizeInput = debounce(this.handleSizeInputInner, 400);

  handleSizeInputInner() {
    this.validWidth = this.validateDimension(this.newWidth);
    this.validHeight = this.validateDimension(this.newHeight);

    this.sizeWillChange =
      this.validDimensions &&
      (this.newWidth !== this.width || this.newHeight !== this.height);
  }

  validateDimension(value: number) {
    return !isNaN(value) && value > 0;
  }

  get validDimensions() {
    return this.validWidth && this.validHeight;
  }

  get resizeAnchors() {
    return [
      ["topLeft", "topCenter", "topRight"],
      ["midLeft", "center", "midRight"],
      ["botLeft", "botCenter", "botRight"],
    ];
  }

  setAnchor(anchor: string) {
    this.anchorPoint = anchor;
  }

  selectTimeZone(timeZone: any) {
    this.timeZone = timeZone;
  }

  handleNameChange = debounce(this.handleNameChangeInner, 400);

  handleNameChangeInner() {
    this.validName = isNonEmptyString(this.newAppName);
  }

  get isFormValid() {
    return this.validDimensions && this.validName;
  }

  async saveAppSettings() {
    if (this.isFormValid === false) {
      return;
    }
    this.saving = true;

    let oldSize: Size = { w: this.width, h: this.height };
    let newSize: Size = { w: this.newWidth, h: this.newHeight };
    const sizeHasChanged = newSize.h !== oldSize.h || newSize.w !== oldSize.w;

    if (sizeHasChanged) {
      this.appEditor.setAppDimensions({
        width: this.newWidth,
        height: this.newHeight,
      });

      this.appEditor.setWidgets(
        scaleAndResizeWidgets(
          this.appEditor.widgets,
          oldSize,
          newSize,
          this.anchorPoint,
          this.scaleContent
        )
      );
      this.centerArtboard();
    }
    this.appEditor.setAppName(this.newAppName);
    this.appEditor.setTimeZone(this.timeZone);

    // Convert minutes (how number is manipulated in UI) to seconds (how it is stored on app model):
    this.appEditor.fallbackImageTimeoutSec =
      this.maximumMinutesAllowedForStaleData * 60;
    // console.log(
    //   "saving app settings, fallbackimagetimeout",
    //   this.appEditor.fallbackImageTimeoutSec,
    //   this.introImagePreviewUrl
    // );
    this.appEditor.introImageTimeoutSec = this.minimumSecondsToDisplayPreloader;

    await this.appEditor.updateApp();
    this.saving = false;
    this.closeAppSettingsModal();
  }

  closeAppSettingsModal() {
    this.$emit("close");
  }

  centerArtboard() {
    const ruler = this.rulerSize;
    const artboard = this.artboard;
    const canvas = (
      document.querySelector("#canvas") as HTMLElement
    ).getBoundingClientRect();
    let x = ruler + (canvas.width - artboard.w) / 2;
    let y = ruler + (canvas.height - artboard.h) / 2;
    this.appEditor.positionArtboard({ x, y });
  }
}
</script>
