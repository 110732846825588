<template>
  <div class="flex">
    <EditorNumberInput
      class="w-22"
      icon="Rotation"
      :controls="true"
      :align="'left'"
      :min="0"
      :max="360"
      :value="value"
      @change="onChange"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FormLabel from "@/components/FormLabel.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import { useAppEditorStore } from "@/stores/appEditor";
// import ScalarBinder from "@/components/editors/ScalarBinder.vue";

// TODO: Dimensions x and y will not go negative on Safari

@Component({
  components: {
    FormLabel,
    EditorNumberInput,
  },
})
export default class RotationEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get showEditor() {
    return "angle" in this.selectedProps;
  }

  get value() {
    return this.getValue<number>("angle");
  }

  onChange(value: number) {
    this.appEditor.setWidgetProps(this.selections, {
      angle: value,
    });
  }

  // TODO figure out how to share this across components...(mixin? but hmm can only extend one..)
  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];

      result = values[0];
    }
    return result as T;
  }
}
</script>
