<script lang="ts">
import { Point, SavedAsset } from "@/types";
import { StockAsset } from "@/types/photos";
import { clampSize, makeId } from "@/utils";
import { Component, Vue } from "vue-property-decorator";
import { CANVAS_ID } from "@/constants";
import { useDragDropStore, DataWidgetInfo } from "@/stores/dragDrop";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { useAssetStore } from "@/stores/assets";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { useAppEditorStore } from "@/stores/appEditor";

// This component is extended by DataNodeImageUrl as well as PhotosMenu
type DataNodeImage = {
  uuid: string;
  url: string;
  width?: number;
  height?: number;
};
type DraggedMedia = SavedAsset | StockAsset | DataNodeImage;

@Component
export default class PhotoDragging extends Vue {
  dragPosition: Point = { x: 0, y: 0 };
  dragClickOffset: Point = { x: 0, y: 0 };

  draggedMedia: DraggedMedia | null = null;
  assetPromise: Promise<SavedAsset>;
  isFromDataSetExplorer = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }
  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }
  get canvasBox() {
    return this.appEditor.canvasBox;
  }
  get scale() {
    return this.appEditor.scale;
  }
  get artboard() {
    return this.appEditor.artboard;
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  get assetStore() {
    return useAssetStore();
  }

  get draggingPhotoSrc() {
    return this.dragDropStore.draggingInfo?.url;
  }

  get conditionsStore() {
    return useConditionGroupsStore();
  }

  onStartDrag(
    ev: any,
    asset: DraggedMedia,
    isFromDataSetExplorer = false,
    nodeDataInfo?: DataWidgetInfo
  ) {
    const id = "id" in asset ? asset.id : asset.uuid;
    // Store this info so we can access in endDrag, to pass to addBgImage and addImage actions:
    this.isFromDataSetExplorer = isFromDataSetExplorer;

    // Clear out editing context so that can drag node to any cell (i.e., don't preselect the first cell)
    this.appEditor.resetEditingContext();

    if (nodeDataInfo && "nodeUuid" in nodeDataInfo) {
      const { nodeSetUuid, nodeUuid } = nodeDataInfo;

      this.dragDropStore.draggingInfo = {
        type: "Node",
        ...nodeDataInfo,
        dataParentUuid: nodeSetUuid,
        dataUuid: nodeUuid as string,
        dataType: "ImageUrl",
      };
    } else {
      this.dragDropStore.draggingInfo = { type: "Asset" };
    }

    if (!isFromDataSetExplorer) {
      if (this.assetStore.loadingSearchResults || !isNonEmptyString(id))
        return false;
    }

    if ("id" in asset) {
      this.assetPromise = this.assetStore.getStockAsset(asset.id);
    }

    this.draggedMedia = asset;

    ev.preventDefault();

    if (!this.dragDropStore.draggingInfo) {
      this.dragDropStore.draggingInfo = {
        type: "Asset",
      };
    }

    this.dragDropStore.draggingInfo.width = asset.width || 250;
    this.dragDropStore.draggingInfo.height = asset.height || 250;
    this.dragDropStore.draggingInfo.url = asset.url;

    const ghostSize = clampSize(
      {
        w: this.dragDropStore.draggingInfo?.width,
        h: this.dragDropStore.draggingInfo?.height,
      },
      400
    );
    this.dragDropStore.updateGhostStyle({
      width: ghostSize.w,
      height: ghostSize.h,
    });

    this.dragClickOffset.x = ev.offsetX;
    this.dragClickOffset.y = ev.offsetY;
    document.addEventListener("mouseup", this.dragStopListener);
    document.addEventListener("mousemove", this.dragListener);
  }

  // This never fired because we stopped propagation so mouseup from sidebar didn't reach here
  dragStopListener(e: any) {
    this.endDrag(e);
    document.removeEventListener("mouseup", this.dragStopListener);
    document.removeEventListener("mousemove", this.dragListener);
  }

  dragListener(ev: any) {
    ev.preventDefault();
    const canvas = document.getElementById(CANVAS_ID) as HTMLElement;

    // Visually indicate whether photo drop will cancel it
    const opacity = canvas.contains(ev.target) ? 0.88 : 0.5;

    this.dragPosition.x = ev.clientX;
    this.dragPosition.y = ev.clientY;

    this.dragDropStore.updateGhostStyle({
      opacity: opacity,
      top: this.dragPosition.y - this.dragClickOffset.y,
      left: this.dragPosition.x - this.dragClickOffset.x,
    });
  }

  async getDraggedMediaData(): Promise<DraggedMedia> {
    if (this.draggedMedia === null) {
      return Promise.reject();
    }

    if (this.draggedMedia !== null && "id" in this.draggedMedia) {
      return this.assetPromise;
    }
    return Promise.resolve(this.draggedMedia);
  }

  async endDrag(e: any) {
    // Ignore this event if photo was dropped outside the "#canvas" element:
    const CANVAS = document.getElementById(CANVAS_ID) as HTMLElement;
    if (!CANVAS.contains(e.target)) {
      this.dragDropStore.draggingInfo = null;
      return;
    }

    const dropPoint = this.appEditor.getArtboardCoordinates(
      { x: e.clientX, y: e.clientY },
      this.dragClickOffset
    );
    if (this.dragDropStore.draggingInfo) {
      this.dragDropStore.draggingInfo.dropPoint = dropPoint;
    }

    // NOTE: This is causing some slowdown in the UX...might want to rework:
    // Assets from the SK (and bg patterns) do not have an id property:
    const asset = await this.getDraggedMediaData();

    if (this.isFromDataSetExplorer) {
      // Ensure ghost does not show above usage prompt:
      this.dragDropStore.updateGhostStyle({
        opacity: 0,
      });
      await this.dragDropStore.handleNodeDrop();

      return;
    }

    const photoDropWidgetId = this.dragDropStore.hoveredPhotoDropWidgetId;

    if (photoDropWidgetId) {
      const targetWidget = this.appEditor.widgetById(photoDropWidgetId);
      const payload: any = {
        asset,
        wid: photoDropWidgetId,
        conditionUuid:
          this.conditionsStore.getActiveConditionId(photoDropWidgetId),
        parentWidgetId: this.editingContext?.parentId,
      };

      console.log("dropping image", payload.conditionUuid);

      if (targetWidget?.type === "Image") {
        // Replace image's url
        this.appEditor.updateImageSource(payload);
      } else {
        // Replace shape's backgroundImageUrl
        this.appEditor.updateBackgroundImage(payload);
      }
    } else {
      const maxSize = 400;
      const { w, h } = clampSize(
        {
          w: (asset.width as number) || 250,
          h: (asset.height as number) || 250,
        },
        maxSize
      );

      const payload: any = {
        asset,
        boundingBox: {
          x: dropPoint.x,
          y: dropPoint.y,
          w: w / this.scale,
          h: h / this.scale,
        },
        newWidgetId: makeId(),
        parentWidgetId: this.editingContext?.parentId,
      };

      await this.appEditor.addImageComponent(payload);
    }

    await this.appEditor.updateApp();

    this.draggedMedia = null;
    this.dragDropStore.draggingInfo = null;
  }
}
</script>

<style scoped></style>
