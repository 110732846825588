import { DateTime, Interval } from "luxon";
import { NodeData, NodeSetData } from "./data";
//
export class CalendarEvent {
  start: DateTime;
  end: DateTime;
  summary: string;
  location?: string;
  creatorEmail?: string;
  description?: string;

  get interval() {
    return Interval.fromDateTimes(this.start, this.end);
  }

  get isAllDay() {
    return this.start.hour === 0 && this.end.hour === 0;
  }

  get isSingleDay() {
    return this.isAllDay && this.end.day - this.start.day === 1;
  }

  get durationExceedsOneDay() {
    // return this.end  - this.start
    return this.end.diff(this.start).milliseconds > SECONDS_IN_DAY;
  }

  get durationIsOrExceedsOneDay() {
    // return this.end  - this.start
    return this.end.diff(this.start).milliseconds >= SECONDS_IN_DAY;
  }

  constructor(data: (NodeData | NodeSetData)[]) {
    data.forEach((d) => {
      // Strip the index number from the query if present,
      // converting a calendar connection to an editable table
      // connection, BE adds an index to the query for scalar bindings
      const query = d.query.replace(/[0-9]/g, "");
      const prop = query.toLocaleLowerCase();
      const value = (d as NodeData).value;
      switch (query) {
        case "Start":
        case "End":
          (this as any)[prop] = DateTime.fromJSDate(new Date(value as number));
          break;
        case "CreatorEmail":
          this.creatorEmail = value as string;
        case "Attendees":
          // ignore
          break;

        // This next check shouldn't be necessary, but currently there
        // are multiple elements in the dataset with query="Summary"
        case "Summary":
          // console.log(
          //   "summary...",
          //   value,
          //   this.summary,
          //   d.kind === NodeKind.node,
          //   d.kind
          // );
          // this.summary = d.kind === NodeKind.node ? value : this.summary;
          this.summary = value as string;
          break;
        default:
          (this as any)[prop] = value;
      }
    });
  }
}

export const eventIsOver = (ev: CalendarEvent) => {
  return DateTime.now() > ev.end;
};

export const eventIsUpcoming = (ev: CalendarEvent) => {
  return DateTime.now() < ev.start;
};

export const SECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export const eventLiesWithinRange = (
  ev: CalendarEvent,
  interval: DateTime[]
) => {
  return intervalsOverlap([ev.start, ev.end], interval);
};

const intervalsOverlap = (i1: DateTime[], i2: DateTime[]) => {
  return i1[0] < i2[1] && i1[1] > i2[0];
};

export const createTracks = (events: CalendarEvent[]): CalendarEvent[][] => {
  const tracks: CalendarEvent[][] = [];

  for (let i = 0; i < events.length; i++) {
    const ev = events[i];

    // Loop through tracks, see if interval can be put onto any of them
    let eventAddedToTrack = false;
    for (let j = 0; j < tracks.length; j++) {
      // Does the current event overlap any other events in this track?
      if (!tracks[j].some((te) => te.interval.overlaps(ev.interval))) {
        eventAddedToTrack = true;
        tracks[j].push(ev);
        break;
      }
    }

    // create new track with this event on it
    if (!eventAddedToTrack) tracks.push([ev]);
  }

  return tracks;
};

export const weekday = () => DateTime.now().weekday;

export const makeHourTodayDate = (hour: number, daysOffset = 0, minute = 0) => {
  const { month, day, year } = DateTime.now();

  const dt = DateTime.fromObject({
    month,
    day,
    year,
    hour: hour,
    minute,
  }).plus({ days: daysOffset });
  // console.log("make", dt);
  return dt;
};
