<template>
  <div class="bg-yellow-50 border-l-4 border-yellow-400 p-3" id="toast">
    <div class="flex">
      <div class="flex-shrink-0">
        <IconSolid
          :name="'Exclamation'"
          class="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <p class="text-sm text-yellow-700" v-t="message"></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import IconSolid from "@/components/icons/IconSolid.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import gsap from "gsap";

const toastId = "#toast";

@Component({
  components: {
    IconSolid,
  },
})
export default class ToastMessage extends Vue {
  @Prop(String) readonly message: string;
  @Prop(Number) readonly duration: number;

  mounted() {
    const toast = document.querySelector(toastId);
    gsap.to(toast, {
      opacity: 0,
      duration: 0.5,
      delay: this.duration - 0.5,
      ease: "power1.out",
    });
  }
}
</script>
