import { logger } from "@core/logger";
import { getGoogleFont } from "./searchFonts";
import { Font } from "@/types";

const DEFAULT_FONTS = [
  "Abril Fatface",
  "Archivo Narrow",
  "B612",
  "Bitter",
  "Cabin",
  "Cairo",
  "Courier Prime",
  "Crimson Text",
  "EB Garamond",
  "Inter",
  "Karla",
  "Lato",
  "Lobster",
  "Lora",
  "Montserrat",
  "Open Sans",
  "Playfair Display",
  "PT Sans",
  "Spectral",
  "Raleway",
  "Roboto",
  "Roboto Mono",
  "Ubuntu",
];

/**
 * We use this to display a dropdown list of font options in the text editor.
 * @param userFonts Additional font family names (outside the standard list)
 * @returns A combined and alphabetically sorted list of fonts.
 */
export const getFontOptions = (userFonts: string[] = []) => {
  const families = Array.from(new Set(DEFAULT_FONTS.concat(userFonts)));

  return getFonts(families).then((fonts) => {
    // Sort by family name
    fonts.sort((a, b) => a.family.localeCompare(b.family));
    return fonts;
  });
};

/**
 * Takes the list of families and returns Font object with variants
 */
const getFonts = (families: string[]) => {
  return Promise.all(
    families.map((item) =>
      getGoogleFont(item).catch(() => {
        logger.track(`Unable to load variants for unknown font "${item}"`);
        return Promise.resolve(null);
      })
    )
  ).then((results) => {
    // Filter out values that are null, they failed to load.
    // After filtering, return a flattened list.
    return results.filter((r) => r !== null).flatMap((r) => r) as Font[];
  });
};
