<template>
  <div class="text-gray-600">
    <div
      class="rounded-md w-26 h-26 flex justify-center items-center relative text-white"
      @click="select"
      :class="buttonClasses"
      @mouseenter="toggleHover"
      @mouseleave="toggleHover"
    >
      <AnimationIcon class="w-full h-full" :name="presetName" />
      <div
        v-if="displayXIcon"
        class="flex items-center justify-center h-full w-full absolute"
      >
        <div class="bg-app-pink w-3 h-3 rounded absolute"></div>
        <IconSolid
          class="h-6 w-6 absolute"
          name="XCircle"
          :class="{ rotate: !isSelected }"
        />
      </div>
    </div>

    <div class="text-center text-sm mt-1 relative w-full">
      {{ preset.label }}
      <div
        :class="{
          arrow: isSelected && preset.presetId != 'none',
        }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { AnimationPreset } from "@/types/animation";
import IconSolid from "@/components/icons/IconSolid.vue";
import AnimationIcon from "@/components/icons/animationIcons/AnimationIcon.vue";

@Component({
  components: {
    IconSolid,
    AnimationIcon,
  },
})
export default class AnimationPresetTile extends Vue {
  @Prop(Object) preset: AnimationPreset;
  @Prop(String) selectedPresetId: string;

  hover = false;

  toggleHover() {
    this.hover = !this.hover;
  }

  get isSelected() {
    return this.preset.presetId === this.selectedPresetId;
  }

  get presetName() {
    return (
      this.preset.presetId.charAt(0).toUpperCase() +
      this.preset.presetId.slice(1)
    );
  }

  get displayXIcon() {
    return this.isSelected && this.selectedPresetId === "none" ? false : true;
  }

  get iconAngle() {
    return this.isSelected ? "0" : "45";
  }

  get buttonClasses() {
    return this.isSelected
      ? ["outline", "outline-app-pink", "bg-gray-100"]
      : [
          "border-2",
          "hover:border-0",
          "hover:outline",
          "hover:outline-gray-600",
        ];
  }

  select() {
    this.$emit("select", this.preset);
  }
}
</script>

<style scoped lang="postcss">
.arrow {
  position: absolute;
  top: 140%;
  left: 42%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.rotate {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
</style>
