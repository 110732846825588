<template>
  <div
    class="flex justify-center items-center italic text-red-500 h-full text-3xl"
  >
    Please add a calendar data connection to supply this component with data.
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class CalendarEmptyMessage extends Vue {}
</script>
