<template>
  <div class="pb-10">
    <button
      :disabled="isBundling"
      class="flex justify-between text-app-violet items-center bg-app-violet bg-opacity-20 ring-2 ring-app-violet hover:bg-opacity-40 rounded-md pl-4 pr-2 w-full h-8 mb-1"
      @click="buildBundle()"
    >
      <span v-t="'GetApp.download'"></span>
      <Icon class="h-6 w-6 text-app-violet" name="Download" />
    </button>
    <span
      class="text-xs pl-4 mt-1 w-full whitespace-normal inline-block"
      v-t="'GetApp.downloadExplain'"
    ></span>
    <!-- Body: -->
    <div class="w-full">
      <div class="pl-4 py-2 space-y-1">
        <div>
          <input
            class="mr-2"
            type="radio"
            name="bundleType"
            v-model="bundleType"
            value="GenericZip"
            id="GenericZip"
          />
          <label
            for="GenericZip"
            v-t="'DownloadBundle.BundleTypes.GenericZip'"
          ></label>
        </div>
        <div>
          <input
            class="mr-2"
            type="radio"
            name="bundleType"
            v-model="bundleType"
            value="Brightsign"
            id="Brightsign"
          />
          <label
            for="Brightsign"
            v-t="'DownloadBundle.BundleTypes.Brightsign'"
          ></label>
        </div>
        <div>
          <input
            class="mr-2"
            type="radio"
            name="bundleType"
            v-model="bundleType"
            value="Broadsign"
            id="Broadsign"
          />
          <label
            for="Broadsign"
            v-t="'DownloadBundle.BundleTypes.Broadsign'"
          ></label>
        </div>
        <div>
          <input
            class="mr-2"
            type="radio"
            name="bundleType"
            v-model="bundleType"
            value="Signagelive"
            id="Signagelive"
          />
          <label
            for="Signagelive"
            v-t="'DownloadBundle.BundleTypes.Signagelive'"
          ></label>
        </div>
      </div>
    </div>

    <div class="w-full px-6 flex items-center" v-if="isBundling">
      <LoadingAnimation />
      <span class="ml-2 text-sm" v-t="'DownloadBundle.buildingBundle'"></span>
    </div>

    <div class="w-46 px-4" v-if="bundleResult">
      <a
        class="text-app-violet inline-block w-full whitespace-normal"
        :download="bundleResult.filename"
        :href="bundleResult.url"
        ><span class="w-16" v-t="'DownloadBundle.download'"></span>:
        {{ bundleResult.filename }}</a
      >
    </div>

    <div class="w-full text-sm w-60 px-4" v-if="bundleErrors.length">
      <div
        class="text-app-red font-bold whitespace-normal"
        v-t="'DownloadBundle.bundlingError'"
      ></div>
      <div class="text-sm" :key="msg" v-for="msg in bundleErrors">
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api/backend";
import ButtonGradient from "@/components/ButtonGradient.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import Icon from "@/components/icons/Icon.vue";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import {
  getPreviewBranchName,
  isPreviewBranchDeployed,
} from "@/util/htmlBranch";

interface BundleResult {
  filename: string;
  url: string;
}

@Component({
  components: {
    ButtonGradient,
    OutlineButton,
    Icon,
    LoadingAnimation,
  },
})
export default class DownloadBundle extends Vue {
  bundleType = "GenericZip";
  isBundling = false;
  bundleErrors: string[] = [];
  bundleResult: BundleResult | null = null;

  async buildBundle() {
    if (this.isBundling) {
      return;
    }

    this.bundleResult = null;
    this.bundleErrors = [];
    this.isBundling = true;

    const appEditor = useAppEditorStore();
    const appUuid = appEditor.uuid;
    try {
      const branchParam = await this.getBranchParam(appEditor.feed.previewUrl);
      this.bundleResult = await api.get<BundleResult>(
        `apps/${appUuid}/bundle?bundleType=${this.bundleType}${branchParam}`
      );
    } catch (e) {
      if (typeof e === "string") {
        this.bundleErrors = [e];
      } else if (Array.isArray(e) && e.length > 0) {
        this.bundleErrors = e
          .filter((err) => err.message)
          .map((err) => err.message);
      }

      if (this.bundleErrors.length === 0) {
        this.bundleErrors = ["An unknown error occurred."];
      }
    } finally {
      this.isBundling = false;
    }
  }

  async getBranchParam(feedUrl: string) {
    const branch = getPreviewBranchName();
    console.log("getBranchParam", branch);
    if (branch) {
      try {
        const isDeployed = await isPreviewBranchDeployed(feedUrl, branch);
        return isDeployed
          ? `&htmlBranch=${window.encodeURIComponent(branch)}`
          : "";
      } catch (e) {
        console.error(
          `Error while checking if app renderer branch "${branch}" is deployed.`,
          e
        );
      }
    }

    return "";
  }
}
</script>
