<template>
  <div class="w-full h-full -ml-2">
    <div
      class="flex flex-row justify-between"
      v-if="node.assetUuid && node.formattedValue"
    >
      <OutlineButton class="dark-form-focus" type="button" @click="handleUpload"
        ><span v-t="'manualInputs.replace'"></span
      ></OutlineButton>
      <OutlineButton class="dark-form-focus" type="button" @click="onRemove"
        ><span v-t="'manualInputs.remove'"></span
      ></OutlineButton>
    </div>
    <div v-else>
      <OutlineButton class="dark-form-focus" type="button" @click="handleUpload"
        ><span v-t="'manualInputs.upload'"></span
      ></OutlineButton>
    </div>

    <img
      class="mt-4 mx-auto w-full max-h-60 object-scale-down"
      v-if="node.formattedValue"
      :src="node.formattedValue"
    />
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import InputBase from "@/components/data/connections/manualSource/inputs/InputBase.vue";
import DataNodeImage from "@/components/data/DataNodeImage.vue";
import { uploadImage } from "@/uploadAssets";
import OutlineButton from "@/components/OutlineButton.vue";
import { useEditableDataStore } from "@/stores/editableData";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    DataNodeImage,
    OutlineButton,
  },
})
export default class ImageUploadInput extends InputBase {
  get editableDataStore() {
    return useEditableDataStore();
  }

  onRemove() {
    this.editableDataStore.setNodeValue({
      rowUuid: this.rowUuid,
      columnUuid: this.node.uuid || "",
      formattedValue: this.editableDataStore.emptyRowValue,
      assetUuid: this.editableDataStore.emptyRowValue,
      value: null,
    });
    this.editableDataStore.clearEditingCell({
      rowUuid: this.rowUuid,
      columnUuid: this.node.uuid || "",
      value: this.node.formattedValue,
      schema: useConnectionEditorStore().schema,
    });
  }

  handleUpload() {
    uploadImage().then((image) => {
      if (image) {
        this.editableDataStore.setNodeValue({
          rowUuid: this.rowUuid,
          columnUuid: this.node.uuid || "",
          formattedValue: image.url,
          assetUuid: image.uuid,
          value: image.url,
        });
        this.editableDataStore.clearEditingCell({
          rowUuid: this.rowUuid,
          columnUuid: this.node.uuid || "",
          value: this.node.formattedValue,
          schema: useConnectionEditorStore().schema,
        });
      }
    });
  }
}
</script>
