<template>
  <div>
    <SelectMenu
      :value="timeZone"
      :options="timeZones"
      :searchable="true"
      @input="selectTimeZone"
    />
    <div v-if="isLoading && !error" v-t="'timezone.loading'"></div>
    <div v-if="error" class="text-red-500" v-t="'timezone.error'"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import SelectMenu from "@/components/SelectMenu.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import Icon from "@/components/icons/Icon.vue";
import { api } from "@/api/backend";
import { InputOption } from "@/types/inputs";

@Component({ components: { IconSolid, Icon, SelectMenu } })
export default class TimeZoneSelect extends Vue {
  @Prop(String) value: string | null;
  error = false;
  timeZone = "";
  timeZones: any[] = [];
  isLoading = false;

  selectTimeZone(timeZone: string) {
    this.timeZone = timeZone;
    this.$emit("selectTimeZone", timeZone);
  }

  created() {
    this.isLoading = true;
    this.getTimeZones()
      .then((options) => {
        this.isLoading = false;
        this.timeZones = options;
        this.timeZone =
          this.value ??
          Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();
      })
      .catch(() => {
        this.error = true;
      });
  }

  getTimeZones() {
    return api.get<any>(`utility/timezones`).then((res) => {
      let timeZonesObject: { key: string; value: string }[] =
        res.timeZoneDisplayNameToIanaTimeZone;
      const options: InputOption[] = [];
      timeZonesObject.forEach((timeZone) => {
        options.push({
          label: timeZone.value,
          value: timeZone.key,
        });
      });
      return options;
    });
  }
}
</script>

<style scoped></style>
