<template>
  <svg class="w-full h-full" :viewBox="viewBox">
    <g :transform="roundTransform">
      <g class="meter">
        <path
          class="background"
          :d="this.roundStyle.background()"
          :fill="bgFill"
        />
        <path
          class="foreground"
          :d="this.roundStyle.foreground()"
          :fill="fgFill"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import * as d3 from "d3";

@Component({})
export default class ProgressDonutComponent extends Vue {
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;

  @Prop(String) readonly shape: string;
  @Prop(Number) readonly roundPreset: string;
  @Prop(Number) readonly minValue: number;
  @Prop(Number) readonly maxValue: number;
  @Prop(Number) readonly currentValue: number;
  @Prop(String) readonly bgFill: string;
  @Prop(String) readonly fgFill: string;
  @Prop(Number) readonly bgInnerRadius: number;
  @Prop(Number) readonly bgOuterRadius: number;
  @Prop(Number) readonly fgInnerRadius: number;
  @Prop(Number) readonly fgOuterRadius: number;
  @Prop(Number) readonly cornerRadius: number;
  @Prop(Boolean) readonly lockAspect: boolean;
  @Prop(String) readonly wid: string;

  get viewBox() {
    return `0 0 ${this.w * this.scaleX} ${this.h * this.scaleY}`;
  }

  get roundTransform() {
    return "translate(" + this.radius + "," + this.radius + ")";
  }

  twoPi = 2 * Math.PI;

  get diameter() {
    return Math.min(this.w * this.scaleX, this.h * this.scaleY);
  }

  get radius() {
    return this.diameter / 2;
  }

  get progress() {
    return (this.currentValue / this.maxValue) * this.twoPi;
  }

  get roundStyle() {
    let background = d3
      .arc()
      .startAngle(0)
      .innerRadius((this.radius * this.bgInnerRadius) / 100)
      .outerRadius((this.radius * this.bgOuterRadius) / 100)
      .endAngle(this.twoPi);

    let foreground = d3
      .arc()
      .startAngle(0)
      .innerRadius((this.radius * this.fgInnerRadius) / 100)
      .outerRadius((this.radius * this.fgOuterRadius) / 100)
      .endAngle(this.progress)
      .cornerRadius(this.cornerRadius);

    return { background, foreground };
  }
}
</script>

<style scoped></style>
