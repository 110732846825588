<template>
  <div class="flex items-center mr-2">
    <a
      :href="url"
      target="_blank"
      class="cursor-pointer flex flex-row items-center bg-white pl-2 pr-3 py-1 space-x-1 rounded-md border-1 hover:text-app-purple hover:border-gray-300"
    >
      <Icon class="h-5 w-5" name="Eye" />
      <span v-t="'preview'"></span>
    </a>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from "vue";
import Icon from "@/components/icons/Icon.vue";
import { getPreviewUrlWithBranch } from "@/util/htmlBranch";

const props = defineProps({
  url: {
    type: String,
  },
});

const url = ref<string | undefined>(props.url);

watch(() => props.url, updateUrl, { immediate: true });

async function updateUrl(previewUrl?: string) {
  url.value = await getPreviewUrlWithBranch(previewUrl);
}
</script>
