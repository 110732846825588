<template>
  <div class="w-full">
    <DefineTreeSchema
      v-if="store.schemaType === 'Tree'"
      @querySelected="onQueryContextSelected"
      @complete="onCompleteClick"
    />

    <DefineSpreadsheetSchema
      v-if="store.schemaType === 'Tabular' && !store.canEditData"
      @sheetSelected="onQueryContextSelected"
      @complete="onCompleteClick"
    />

    <DefineCalendarSchema
      v-if="store.schemaType === 'Calendar' && store.schema.length > 0"
      :events="store.schema"
      :nodes="store.configuredNodes"
      @complete="onCompleteClick"
    />

    <DefineManualSchema
      v-if="store.schemaType === 'Tabular' && store.canEditData"
      :nodes="store.configuredNodes"
      @complete="onCompleteClick"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { useConnectionSetupStore } from "@/stores/connectionSetup";

import UiBlocker from "@/components/UiBlocker.vue";
import DefineTreeSchema from "@/components/data/connections/setup/schema/DefineTreeSchema.vue";
import DefineSpreadsheetSchema from "@/components/data/connections/setup/schema/DefineSpreadsheetSchema.vue";
import DefineCalendarSchema from "@/components/data/connections/setup/schema/DefineCalendarSchema.vue";
import DefineManualSchema from "@/components/data/connections/setup/schema/DefineManualSchema.vue";
import { SchemaNode } from "@/types/data";

@Component({
  components: {
    DefineTreeSchema,
    DefineSpreadsheetSchema,
    DefineCalendarSchema,
    DefineManualSchema,
    UiBlocker,
  },
})
export default class SetupSchema extends Vue {
  get store() {
    return useConnectionSetupStore();
  }

  created() {
    this.store.step = "Schema";
    // console.log("schema", this.store.schema);
  }

  onQueryContextSelected(queryContext: string) {
    this.store.setQueryContext(queryContext);
  }

  onCompleteClick(nodes: SchemaNode[]) {
    this.store
      .defineSchema(nodes)
      .then((routeParams) => {
        this.$router.push({
          path: routeParams.step.toLowerCase(),
          query: { ...this.$route.query, c: routeParams.connectionUuid },
        });
      })
      .catch((reason) => {
        if (reason === "schemaMismatch") {
          // DO SOMETHING
        }
      });
  }
}
</script>
