<template>
  <div class="px-8 py-6">
    Collect view
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import IconSolid from "@/components/icons/IconSolid.vue";

@Component({
  components: {
    IconSolid
  }
})
export default class DashboardCollect extends Vue {}
</script>
