<template>
  <component
    :is="tag"
    :type="type"
    @click="click"
    class="rounded text-white border select-none dark-form-focus border-gray-500 hover:border-gray-400"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class OutlineButton extends Vue {
  @Prop(Boolean) disabled: boolean;
  @Prop({ type: String, default: "button" }) type: "submit" | "button" | "link";
  @Prop(String) size: "xs" | "sm" | "base" | "lg" | "xl" | "2xl";
  @Prop({ default: false }) stopPropagation: boolean;

  get tag() {
    if (this.type === "link") {
      return "a";
    }
    return "button";
  }

  get classes() {
    let classes = this.sizeClasses;
    if (this.disabled) {
      classes += " cursor-not-allowed opacity-50";
    }
    return classes;
  }

  get sizeClasses() {
    switch (this.size) {
      case "xs":
        return "text-xs px-2 py-1 rounded-xs";
      case "sm":
        return "text-sm px-3 py-1 rounded-sm";
      case "lg":
        return "text-lg px-5 py-3 rounded-lg";
      case "xl":
        return "text-xl px-6 py-4 rounded-xl";
      case "2xl":
        return "text-2xl px-7 py-5 rounded-xl";
      default:
        return "text-md px-4 py-2 rounded";
    }
  }

  click(ev: Event) {
    if (this.stopPropagation) {
      ev.stopPropagation();
    }
    if (this.disabled) {
      return;
    }
    this.$emit("click");
  }
}
</script>
