<template>
  <div class="relative flex-grow px-8 py-6">
    <RemapColumns v-bind="$props" @complete="onCompleteClick" />
    <UiBlocker :visible="savingBindings">
      <span v-t="'saving'"></span>
    </UiBlocker>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import RemapColumns from "@/components/data/connections/manage/RemapColumns.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import { useDragDropStore } from "@/stores/dragDrop";
import { RemapCollectionPayload, useAppEditorStore } from "@/stores/appEditor";
import { makeId } from "@/utils";

@Component({
  components: { RemapColumns, UiBlocker },
})
export default class Remap extends Vue {
  @Prop(String) connectionUuid: string;
  @Prop(String) newConnectionUuid: string;

  savingBindings = false;

  redirectToApp() {
    this.$router.push({ name: "edit", params: { id: this.$route.params.id } });
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  onCompleteClick(payload: RemapCollectionPayload) {
    this.savingBindings = true;

    this.appEditor
      .remapCollection(payload)
      .then(async () => {
        /**
         * Create a new child widget representing the node the user just dropped to initiate the replace flow.
         * (not needed if user is replacing an image source property, which will be handled in remap step --
         * and that gets skipped with the `skipWidgetCreation` idea in createChildDataWidget.)
         * (Stretch goal: in theory, we could even pre-populate the remap choice when updating image)
         */

        if (this.dragDropStore.draggingInfo) {
          await this.appEditor.createDynamicWidget(
            {
              isNewRepeater: false,
              isScalar: false,
              draggingInfo: this.dragDropStore.draggingInfo,
              hoveredPhotoDropWidgetId: this.dragDropStore
                .hoveredPhotoDropWidgetId as string,
              widgetId: makeId(),
            },
            null,
            null
          );
          await this.appEditor.updateApp();
          this.dragDropStore.draggingInfo = null;
          this.dragDropStore.hoverTarget = null;
        }

        this.redirectToApp();
      })
      .finally(() => {
        this.savingBindings = false;
      });
  }
}
</script>
