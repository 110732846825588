<template>
  <div class="py-6 flex flex-col space-y-12">
    <div class="px-10 flex flex-col text-center space-y-6">
      <div class="font-bold" v-t="'dataPanel.connectionsEmptyMessage'"></div>

      <div class="text-gray-400" v-t="'dataPanel.connectionsEmptyAction'"></div>
    </div>
    <div class="flex justify-center">
      <LearnMore>{{ $t("dataPanel.connectionsEmptyLearnMore") }}</LearnMore>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import LearnMore from "@/components/menus/data/LearnMore.vue";

@Component({
  components: {
    LearnMore,
  },
})
export default class ConnectionsEmpty extends Vue {
  @Prop(Boolean) selected: boolean;
}
</script>
