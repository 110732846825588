<template>
  <div
    class="w-20 bg-gray-800 h-full border-b border-gray-800 relative flex-shrink-0"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <router-link class="block w-full h-full flex justify-center" to="/"
      ><img
        class="block w-8 h-full"
        src="@/assets/logo.svg"
        style="filter: drop-shadow(0 1px 3px #0009)"
    /></router-link>
    <div ref="arrow" class="absolute" :class="arrowClasses"></div>
    <div
      ref="text"
      class="absolute rounded-sm text-xs font-bold text-white px-2 leading-6 whitespace-nowrap"
      :class="contentClasses"
      v-t="tooltip"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TooltipMixin from "@/components/TooltipMixin";

@Component({})
export default class LogoButton extends mixins(TooltipMixin) {
  @Prop({ type: String, default: "" }) tooltip: string;
  @Prop(String) tooltipPosition: string;
  @Prop(Boolean) tooltipDisabled: boolean;
}
</script>

<style scoped>
.tip-arrow-t {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-bottom-width: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-r {
  left: 100%;
}
.tip-arrow-b {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-l {
  right: 100%;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f00;
}

.tip-offset-t {
  bottom: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-r {
  left: calc(100% + theme("spacing.2"));
}
.tip-offset-b {
  top: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-l {
  right: calc(100% + theme("spacing.2"));
}
</style>
