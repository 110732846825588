<template>
  <div>
    <span
      class="button-root relative z-10 h-8 inline-flex shadow-sm rounded-sm text-white"
      @click.stop
    >
      <button
        type="button"
        :disabled="isSaving"
        :class="[
          'relative inline-flex items-center px-4 w-full justify-center font-semibold w-28',
          isPublished ? 'rounded-l-md' : 'rounded-md',
          isSaving ? 'opacity-80 cursor-not-allowed' : '',
          hasUnpublishedChanges ? 'bg-app-yellow' : 'bg-app-emerald',
        ]"
        @click="publishClick"
        v-t="publishStatus"
      ></button>
      <span class="relative block" v-if="isPublished">
        <button
          id="option-menu"
          type="button"
          :class="[
            'relative h-full inline-flex items-center px-1',
            'rounded-r-md border-l border-white',
            hasUnpublishedChanges ? 'bg-app-yellow' : 'bg-app-emerald',
          ]"
          @click="toggle"
        >
          <span class="sr-only">Open options</span>
          <!-- Heroicon name: chevron-down -->
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div
          class="origin-top-right absolute right-0 mt-2 -mr-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          v-show="expanded"
        >
          <div
            class="py-1 block text-sm text-gray-700 hover:text-gray-900"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="option-menu"
          >
            <button class="option-btn" role="menuitem" @click="unpublishClick">
              <Icon class="h-5 w-5" name="Ban" />
              <span class="ml-2" v-t="'unpublish'"></span>
            </button>
          </div>
        </div>
      </span>
    </span>

    <portal to="modal">
      <ActionModal
        v-if="showPublishModal"
        :acceptBtnText="$t('publish')"
        @on-accept="publishApp"
        @on-reject="showPublishModal = false"
      >
        <div v-text="unpublishConfirmationText"></div>
      </ActionModal>
      <ActionModal
        v-if="showUnpublishModal"
        :acceptBtnText="$t('unpublish')"
        @on-accept="unpublishApp"
        @on-reject="showUnpublishModal = false"
      >
        <div v-t="'warnings.unpublishAppWarning'"></div>
      </ActionModal>

      <Modal
        v-if="showSubscribeToPublishModal"
        size="1/2"
        @close="showSubscribeToPublishModal = false"
      >
        <SubscribeToPublish
          :metadata="canPublishMetadata"
          @close="showSubscribeToPublishModal = false"
        />
      </Modal>

      <Modal
        v-if="showMaxAppConnectionError"
        size="1/2"
        @close="showMaxAppConnectionError = false"
      >
        <div
          v-if="maxAllowedConnectionCount"
          class="p-2 m-4 mr-6 rounded border border-app-yellow text-app-yellow text-lg"
        >
          <div class="flex space-x-2 items-start">
            <div class="p-1">
              <IconSolid
                name="Exclamation"
                class="h-6 w-6"
                fill="none"
                stroke="#ed9515"
              />
            </div>
            <div class="whitespace-pre-line space-y-4">
              <div>
                {{
                  $t("PublishButton.exceededMaxConnections", {
                    size: maxAllowedConnectionCount,
                  })
                }}
              </div>
              <OutlineButton
                size="sm"
                @click="showMaxAppConnectionError = false"
                v-t="'close'"
              ></OutlineButton>
            </div>
          </div>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/eventbus";
import { api } from "@/api/backend";
import { CanPublishMetadata } from "@/types/data";
import { DateTime } from "luxon";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import Modal from "@/components/Modal.vue";
import ActionModal from "@/components/ActionModal.vue";
import OutlineButton from "./OutlineButton.vue";
import SubscribeToPublish from "@/components/SubscribeToPublish.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    Icon,
    IconSolid,
    Modal,
    ActionModal,
    OutlineButton,
    SubscribeToPublish,
  },
})
export default class PublishButton extends Vue {
  isSaving = false;
  expanded = false;

  canPublishMetadata: CanPublishMetadata | null = null;
  showPublishModal = false;
  showUnpublishModal = false;
  showSubscribeToPublishModal = false;
  showMaxAppConnectionError = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get publishMeta() {
    return this.appEditor.publishMeta;
  }

  get modifiedAt() {
    return this.appEditor.modifiedAt;
  }

  get hasUnsavedChanges() {
    return this.appEditor.hasUnsavedChanges;
  }

  get isTemplate() {
    return this.appEditor.isTemplate;
  }

  get appUuid() {
    return this.appEditor.uuid;
  }

  get maxAllowedConnectionCount() {
    return this.appEditor.maxAllowedConnectionCount;
  }

  lux = (dateString: string) => {
    return DateTime.fromJSDate(new Date(dateString));
  };

  get isPublished() {
    return this.publishMeta?.isPublished;
  }

  get hasUnpublishedChanges() {
    if (
      this.publishMeta?.isPublished &&
      this.publishMeta?.publishedOn !== null &&
      this.modifiedAt !== null
    ) {
      return this.lux(this.modifiedAt) > this.lux(this.publishMeta.publishedOn);
    } else {
      return true;
    }
  }

  get publishStatus() {
    if (this.publishMeta?.isPublished) {
      return this.hasUnpublishedChanges || this.hasUnsavedChanges
        ? "republish"
        : "published";
    } else {
      return "publish";
    }
  }

  close() {
    this.expanded = false;
    document.removeEventListener("click", this.close);
  }

  open() {
    this.expanded = true;
    document.addEventListener("click", this.close);
  }

  toggle() {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  }

  mounted() {
    EventBus.on("SAVING_APP", (val) => {
      this.isSaving = val;
    });
  }

  async publishApp() {
    this.showPublishModal = false;
    this.showMaxAppConnectionError = false;
    const appId = this.$route.params.id;

    EventBus.emit("AWAITING_SERVER", true);
    EventBus.emit("APP_SAVE_ERROR", false);

    try {
      await this.appEditor.updateApp();
      await api.put(`apps/${appId}/publish`);
      // Load onto a lean amount of info about the app to avoid overwriting conditional dynamic widget data
      await this.appEditor.loadPublishMeta(appId);
    } catch (err) {
      if (Array.isArray(err) && err.length > 0) {
        const error = err[0];
        if (
          "code" in error &&
          error.code === "MaxAppConnectionAllowedExceeded"
        ) {
          this.showMaxAppConnectionError = true;
        }
      } else {
        EventBus.emit("APP_SAVE_ERROR", true);
      }
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
    }
  }

  async unpublishApp() {
    this.showUnpublishModal = false;
    const appId = this.$route.params.id;
    EventBus.emit("AWAITING_SERVER", true);
    await this.appEditor.updateApp();
    await api.put(`apps/${appId}/unpublish`);
    await this.appEditor.loadApp(appId);
    EventBus.emit("AWAITING_SERVER", false);
  }

  get unpublishConfirmationText() {
    return !this.isTemplate
      ? this.$t("warnings.publishAppWarning").toString()
      : this.$t("warnings.publishTemplateWarning").toString();
  }

  async publishClick() {
    try {
      this.canPublishMetadata = await api.get<CanPublishMetadata>(
        `apps/${this.appUuid}/canpublish`
      );
    } catch (e) {
      console.log(e);
      EventBus.emit("APP_SAVE_ERROR", true);
      return;
    }

    if (!this.canPublishMetadata.canPublish) {
      this.showSubscribeToPublishModal = true;
    } else if (this.isPublished) {
      // If published, confirm whether they want to update all screens
      this.showPublishModal = true;
    } else {
      // Otherwise just publish
      EventBus.emit("AWAITING_SERVER", true);
      EventBus.emit("APP_SAVE_ERROR", false);

      try {
        await this.appEditor.updateApp();
        await api.put(`apps/${this.appUuid}/publish`);
        await this.appEditor.loadApp(this.appUuid);
        EventBus.emit("APP_CHANGES_SAVED");
      } catch (err) {
        if (Array.isArray(err) && err.length > 0) {
          const error = err[0];
          if (
            "code" in error &&
            error.code === "MaxAppConnectionAllowedExceeded"
          ) {
            this.showMaxAppConnectionError = true;
          }
        } else {
          EventBus.emit("APP_SAVE_ERROR", true);
        }
      } finally {
        EventBus.emit("AWAITING_SERVER", false);
      }
    }
  }

  unpublishClick() {
    this.close();
    this.showUnpublishModal = true;
  }

  closeMaxAppConnectionError() {
    this.showMaxAppConnectionError = false;
  }
}
</script>

<style lang="postcss" scoped>
.button-root {
  /* #f0f0f0 in decimal RGB */
  --color: 6, 78, 59;
}

.button-shade {
  /* border-top-color: rgba(255, 255, 255, 0.5);
  border-right-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-left-color: rgba(255, 255, 255, 0.5); */
  box-shadow: inset 1px 1px 1px #fff3,
    inset -1px -1px 1px rgba(var(--color), 0.3), 0 1px 1px #0002,
    0 1px 5px #0002;
}

.option-btn {
  @apply flex w-full px-4 py-2 text-left whitespace-nowrap hover:bg-gray-100;
}
</style>
