<template>
  <ScrollView
    :zeroPadding="true"
    :isScrolled="scrollY > 0"
    class="bg-app-dark1b"
  >
    <template slot="header">
      <AppsHeader />
    </template>
    <template slot="body">
      <router-view></router-view>

      <div
        class="w-128 h-full mx-auto tracking-wider flex flex-col items-center justify-center empty"
        v-if="loaded && apps.length === 0"
      >
        <div
          class="flex text-center w-max welcome"
          v-t="'appList.emptyListPt1'"
        ></div>
        <div
          class="flex text-center w-max welcome"
          v-t="'appList.emptyListApps'"
        ></div>
        <img class="empty-screen" src="@/assets/empty_screen_logo.svg" />
      </div>
      <ConfirmCopy ref="confirmCopy" />

      <UiBlocker :visible="loadingApps"
        ><div v-t="'appList.loadingApps'"></div>
      </UiBlocker>
    </template>
  </ScrollView>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import { EventBus } from "@/eventbus";
import { AppInfo } from "@/types/data";

import AppsHeader from "@/views/Dashboard/AppListViews/AppsHeader.vue";
import ScrollView from "@/views/Dashboard/ScrollView.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import ConfirmCopy from "./AppListViews/ConfirmCopy.vue";
import { useAppsStore } from "@/stores/apps";
import { logger } from "@core/logger";
import { AppListAction } from "@/types";

@Component({
  components: {
    ScrollView,
    UiBlocker,
    AppsHeader,
    ConfirmCopy,
  },
})
export default class Apps extends Vue {
  loaded = false;
  loadingApps = false;
  scrollY = 0;

  showUrlCopied = false;

  get store() {
    return useAppsStore();
  }

  get apps() {
    return this.store.apps;
  }

  created() {
    EventBus.on("APPS_LIST_VIEW_VISIBLE", this.handleListViewVisible);
    EventBus.on("APPS_LIST_ACTION", this.handleAppAction);
  }

  beforeDestroy() {
    EventBus.off("APPS_LIST_VIEW_VISIBLE", this.handleListViewVisible);
    EventBus.off("APPS_LIST_ACTION", this.handleAppAction);

    const scroller = document.querySelector(
      ".vue-recycle-scroller"
    ) as HTMLDivElement;

    if (scroller !== null) {
      scroller.removeEventListener("scroll", this.handleScroll);
    }
  }

  mounted() {
    this.loadingApps = this.apps.length === 0;
    this.store
      .loadApps()
      .then(() => {
        this.loaded = true;
      })
      .finally(() => {
        this.loadingApps = false;
      });
  }

  handleScroll(e: Event) {
    const scroller = e.target as HTMLDivElement;
    this.scrollY = scroller.scrollTop;
  }

  handleListViewVisible() {
    const scroller = document.querySelector(
      ".vue-recycle-scroller"
    ) as HTMLDivElement;

    if (scroller !== null) {
      scroller.addEventListener("scroll", this.handleScroll);
    }
  }

  handleAppAction(event: { action: AppListAction; uuid: string }) {
    const app = this.apps.find((a) => a.uuid === event.uuid);
    if (app === undefined) {
      return;
    }
    switch (event.action) {
      // case "delete":
      //   this.handleDelete(app);
      // break;
      case "launch":
        this.handleLaunch(app);
        break;
      // case "duplicate":
      //   this.handleDuplicate(app);
      // break;
      case "copyUrl":
        this.handleCopyUrl(app);
        break;
      case "preview":
        this.handlePreview(app);
        break;
    }
  }

  handleDuplicate(app: AppInfo) {
    const nameSuggestion = `${app.name} (copy)`;
    const newName = window.prompt(
      this.$t("appList.cloneApp") as string,
      nameSuggestion
    );
    if (newName !== null) {
      EventBus.emit("AWAITING_SERVER", true);
      this.store
        .cloneApp(app.uuid, newName)
        .then((app) => {
          this.$router.push(`/app/edit/${app.uuid}`);
        })
        .catch((err) => {
          logger.track(err);
          window.alert(this.$t("unknownServerError") as string);
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    }
  }

  handleLaunch(app: AppInfo) {
    if (app.publishMeta.isPublished) {
      window.open(app.feed.publishedUrl, "_blank");
    } else {
      window.alert("This app is not published yet.");
    }
  }

  handlePreview(app: AppInfo) {
    window.open(app.feed.previewUrl, "_blank");
  }

  handleDelete(app: AppInfo) {
    if (window.confirm(this.$t("appList.confirmAppDelete") as string)) {
      EventBus.emit("AWAITING_SERVER", true);
      this.store
        .archiveApp(app.uuid)
        .then(() => {
          return this.store.loadApps();
        })
        .catch((err) => {
          logger.track(err);
          window.alert(this.$t("unknownServerError") as string);
        })
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    }
  }

  handleCopyUrl(app: AppInfo) {
    const url = app.feed.publishedUrl;
    navigator.clipboard.writeText(url).then(
      () => {
        (this.$refs.confirmCopy as ConfirmCopy).confirm();
      },
      () => {
        window.alert("Your browser doesn't allow click-to-copy");
      }
    );
  }
}
</script>

<style scoped lang="postcss">
.icon {
  margin-left: 0.2rem;
}

.welcome {
  font-size: 1.5vw;
}

.empty-screen {
  margin-top: 3vw;
}

.view-control svg {
  transition: fill 0.1s;
}
</style>
