<template>
  <div class="w-full flex flex-grow h-full">
    <SelectTreeValue
      v-if="setupStore.schemaWrapper?.schemaType === 'Tree'"
      :rootNode="setupStore.schemaWrapper?.schema"
      :selectedQuery="selectedNode?.query"
      :selectionType="setupStore.propertyType"
      @select="onSelectClick"
    />

    <!-- <SelectSpreadsheetValue
      v-if="store.schemaType === 'Tabular'"
      :sheets="store.sheets"
      :rangeId="store.queryContext"
      :connectionUuid="store.connectionUuid"
      :propertyType="store.propertyType"
      @sheetSelected="onQueryContextSelected"
      @select="onSelectClick"
    /> -->

    <portal to="setupWizardSelect">
      <ScalarNodeDisplay
        v-if="nodeDisplayProps.value"
        :nodeDisplayProps="nodeDisplayProps"
      />
      <div v-else v-t="'ConnectionSetup.scalarSelect.selectValue'"></div>
    </portal>

    <portal to="setupWizardNext">
      <FormButton
        :disabled="!nodeDisplayProps.value"
        v-t="'ConnectionSetup.scalarSelect.selectBtn'"
        @click="onCompleteClick"
      ></FormButton>
    </portal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, defineEmits } from "vue";
import { SetupStep } from "@/types/ConnectionSources";
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";
import SelectTreeValue from "@/components/data/connections/setup/selection/SelectTreeValue.vue";
//import SelectSpreadsheetValue from "@/components/data/connections/setup/selection/SelectSpreadsheetValue.vue";
import FormButton from "@/components/FormButton.vue";
import ScalarNodeDisplay from "@/components/data/connections/manage/data/views/ScalarNodeDisplay.vue";
import { NodeData } from "@/types/data";
import { ScalarNode } from "@/stores/connectionSetup";

const props = defineProps<{
  setupStep: SetupStep | undefined;
}>();

const emits = defineEmits<{
  (e: "complete", value: SetupStep): void;
}>();

const setupStore = useConnectionSetupStoreV2();
const selectedNode = ref<Partial<NodeData> | null>(null);

// Note: renderValue is coming from SelectSpreadsheetValue
const nodeDisplayProps = computed(() => {
  return {
    uuid: selectedNode.value?.uuid,
    dataType: selectedNode.value?.dataType,
    value: (selectedNode.value as any)?.renderValue ?? selectedNode.value,
  };
});

function onSelectClick(node: ScalarNode) {
  selectedNode.value = node;
}

function onCompleteClick() {
  // Trigger the binding/connection creation, close modal.
  setupStore.selectedNode = selectedNode.value as ScalarNode;
  emits("complete", props.setupStep!);
}
</script>
