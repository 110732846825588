import { Vue, Component } from "vue-property-decorator";
import { removeReactivity } from "@/utils";
import { useAppEditorStore } from "./stores/appEditor";
import { NO_UNDO } from "./types";
import { EventBus } from "./eventbus";

/**
 * Main strategy found here: https://github.com/anthonygore/vuex-undo-redo/blob/master/src/plugin.js
 *
 * Any action that must be tracked for undo/redo should be added to the following list.
 *
 * If we need to avoid tracking a particular action whose name does appear on the list,
 * (either because it is invoked by another undo-able action,
 * or because it is controlling a continuous input such as from a slider)
 * then we pass "NO_UNDO" as an argument to the action.
 * (see the "shouldTrack" method below.)
 */

type ActionPayload = {
  name: keyof ReturnType<typeof useAppEditorStore>;
  args: any[];
};

const trackedActions = new Set([
  "addWidget",
  "removeWidgets",
  "setWidgetProps",
  "moveGroup",
  "groupWidgets",
  "destroyGroup",
  "nudge",
  "distribute",
  "align",
  "toggleSyncTextColors",
  "applyTextColor",
  "toggleSyncFonts",
  "applyFontFamily",
  "verticalDynamismOn",
  "verticalDynamismOff",
  "pasteWidgets",
  "toggleLockAspect",
  "setWidgetStackOrder",
  "updateTextContent",

  "setAppName",
  "setAppDimensions",
  "setTimeZone",

  "addImageComponent",
  "updateBackgroundImage",
  "updateImageSource",

  "addDataBinding",
  "updateDataBinding",
  "removeDataBinding",
  "removeRepeaterBinding",

  "createDynamicWidget",
  "createDataToken",

  "undoableRemapAction",
]);

@Component({})
export default class UndoRedo extends Vue {
  done: ActionPayload[] = [];
  undone: ActionPayload[] = [];
  newMutation = true;

  get appEditor() {
    return useAppEditorStore();
  }

  shouldTrack(action: ActionPayload) {
    const { name, args } = action;

    // If this action has any arguments matching `NO_UNDO` then we don't track it.
    if (args.some((arg) => (arg as NO_UNDO) === "NO_UNDO")) {
      return false;
    }
    return trackedActions.has(name);
  }

  created() {
    this.appEditor.$onAction(({ name, args }) => {
      if (!this.shouldTrack({ name, args })) return;

      this.done.push(removeReactivity({ name, args }));

      // console.log("track for undo", name, args);
      if (this.newMutation) {
        this.undone = [];
      }
    });
  }

  undo() {
    // This might feel jarring, but it fixes issues when a user executes "undo" while editing a repeater.
    this.appEditor.resetEditingContext();

    const lastAction = this.done.pop() as ActionPayload;
    this.undone.push(lastAction);

    this.newMutation = false;
    this.appEditor.emptyState();

    // console.log("last action", lastAction);

    (removeReactivity(this.done) as ActionPayload[]).forEach(
      ({ name, args }) => {
        this.appEditor[name](...args);
        EventBus.emit("UNDO_FIRED");
        // console.log("Firing for undo", name);
        this.done.pop();
      }
    );

    if (lastAction.name === "undoableRemapAction") {
      EventBus.emit("FIRE_SAVE");
    }

    this.newMutation = true;
  }

  redo() {
    this.newMutation = false;
    const { name, args } = removeReactivity(this.undone.pop()) as ActionPayload;
    this.appEditor[name](...args);
    EventBus.emit("UNDO_FIRED");
    this.newMutation = true;
  }

  clearStack() {
    this.done = [];
    this.undone = [];
  }

  get canUndo() {
    return this.done.length > 0;
  }

  get canRedo() {
    return this.undone.length > 0;
  }
}
