type LimitedConsole = Pick<
  Console,
  "error" | "info" | "log" | "debug" | "trace" | "warn"
> & {
  track(...data: any[]): void;
};

const createLogger = (): LimitedConsole => {
  return {
    error: (args) => window.console.error(args),
    info: (args) => window.console.info(args),
    log: (args) => window.console.log(args),
    debug: (args) => window.console.debug(args),
    trace: (args) => window.console.trace(args),
    warn: (args) => window.console.warn(args),
    track: (...data: any[]) => {
      const TrackJS = (window as any).TrackJS;
      if (TrackJS && typeof TrackJS.track === "function") {
        TrackJS.track(data);
      } else {
        window.console.error(data);
      }
    },
  };
};

export const logger = createLogger();
