<template>
  <div class="flex">
    <Tooltip :text="boltTooltipText" :position="tooltipPosition">
      <LightningBoltIcon
        class="inline-block"
        :on="bound"
        @click.native.stop="onBind"
      />
    </Tooltip>
    <Tooltip
      v-if="bound"
      :text="$t('LightningBolt.unbind')"
      :position="tooltipPosition"
    >
      <UnbindIcon
        class="inline-block -mx-2 cursor-pointer"
        @click.native.stop="onUnbind"
      />
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import UnbindIcon from "@/components/icons/UnbindIcon.vue";
import Tooltip from "@/components/Tooltip.vue";

@Component({
  components: { LightningBoltIcon, UnbindIcon, Tooltip },
})
export default class LightningBolt extends Vue {
  @Prop(Boolean) bound: boolean;
  @Prop(String) tooltipPosition: string;

  get boltTooltipText() {
    return this.bound
      ? this.$t("LightningBolt.editConnection").toString()
      : this.$t("LightningBolt.connect").toString();
  }
  onBind() {
    this.$emit("bind");
    this.$emit("click");
  }
  onUnbind() {
    // console.log("unbind inner....");
    this.$emit("unbind");
  }
}
</script>
