<template>
  <div class="divide-y">
    <CollapsePanel :shouldListenForOpenCommand="true">
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <div>
        <CalendarDataChooser :widgetId="model.wid" :exposeIndex="true" />
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarEvent.eventTitle'"></div>
      </template>
      <div>
        <GroupTextFormatEditor
          :model="model"
          element="eventTitle"
          @update="apply($event)"
        />
        <div class="px-4">
          <ToggleInput :value="model.syncFonts" @input="syncFontsInput"
            ><span class="text-sm" v-t="'syncFonts'"></span
          ></ToggleInput>

          <ToggleInput
            class="pt-2 pb-5"
            :value="model.syncTextColors"
            @input="syncTextColorsInput"
            ><span class="text-sm" v-t="'syncTextColors'"></span
          ></ToggleInput>
        </div>
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarEvent.eventTime'"></div>
      </template>
      <div>
        <GroupTextFormatEditor
          :model="model"
          element="eventTime"
          @update="apply($event)"
        />
        <ToggleInput
          class="pt-3 pb-5 px-4"
          :value="model.use24Hour"
          @input="apply({ use24Hour: $event })"
          ><span class="text-sm" v-t="'calendarDay.use24HourClock'"></span
        ></ToggleInput>
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarEvent.eventLocation'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="eventLocation"
          @update="apply($event)"
        />
        <div class="px-4">
          <VisibilityInput
            :value="model.showEventLocation"
            @input="apply({ showEventLocation: $event })"
          />
        </div>
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarEvent.dividerLine'"></div>
      </template>
      <div class="px-4 space-y-3 mb-5">
        <VisibilityInput
          :value="model.showDivider"
          @input="apply({ showDivider: $event })"
        />

        <ColorInputBar
          :value="model.dividerColor"
          @input="preview({ dividerColor: $event })"
          @change="apply({ dividerColor: $event })"
          propName="dividerColor"
        >
          <span class="text-sm" v-t="'calendarEvent.color'"></span>
        </ColorInputBar>
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarEvent.eventCreator'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="eventCreator"
          @update="apply($event)"
        />
        <div class="px-4">
          <VisibilityInput
            :value="model.showEventCreator"
            @input="apply({ showEventCreator: $event })"
          />
        </div>
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarEvent.eventDescription'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="eventDescription"
          @update="apply($event)"
        />
        <div class="px-4">
          <VisibilityInput
            :value="model.showEventDescription"
            @input="apply({ showEventDescription: $event })"
          />
        </div>
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CalendarDataChooser from "@/components/widgets/CalendarDataChooser.vue";
import { CalendarEventOptions } from "./CalendarEventOptions";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import FormLabel from "@/components/FormLabel.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import Icon from "@/components/icons/Icon.vue";
import VisibilityInput from "@/components/inputs/VisibilityInput.vue";
import { useAppEditorStore } from "@/stores/appEditor";

// TODO: Need way to control the index, at least for previewing
// Do we want to keep dummy data?

@Component({
  components: {
    CollapsePanel,
    ToggleInput,
    ColorInputBar,
    FormLabel,
    FontFamilyInput,
    GroupTextFormatEditor,
    BaseWidgetEditor,
    CalendarDataChooser,
    Icon,
    VisibilityInput,
  },
})
export default class CalendarEventEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as CalendarEventOptions;
  }

  // Would be nice to not repeat this from component
  // Could maybe filter keys for includes "fontFamily"....as in mutation...
  get textElements() {
    return [
      "eventTitle",
      "eventLocation",
      "eventTime",
      "eventDescription",
      "eventCreator",
    ];
  }
  get showEventLocation() {
    return this.model.showEventLocation;
  }

  set showEventLocation(value: any) {
    this.apply({ showEventLocation: value });
  }

  get showEventCreator() {
    return this.model.showEventCreator;
  }

  set showEventCreator(value: any) {
    this.apply({ showEventCreator: value });
  }

  get showEventDescription() {
    return this.model.showEventDescription;
  }

  set showEventDescription(value: any) {
    this.apply({ showEventDescription: value });
  }

  eye(prop: any) {
    if (prop) {
      return "Eye";
    } else {
      return "EyeOff";
    }
  }

  visibility(prop: any) {
    if (prop) {
      return "visible";
    } else {
      return "hidden";
    }
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  syncFontsInput(val: boolean) {
    this.appEditor.toggleSyncFonts({
      wid: this.model.wid,
      syncFonts: val,
      textElements: this.textElements,
    });
  }

  syncTextColorsInput(val: boolean) {
    this.appEditor.toggleSyncTextColors({
      wid: this.model.wid,
      syncTextColors: val,
      textElements: this.textElements,
    });
  }
}
</script>
