<template>
  <div
    class="mt-4 py-2 px-1 border-gray-400 border-t border-t-solid text-gray-600 text-xs tracking-wide"
    v-text="label"
  ></div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EditorModule extends Vue {
  @Prop({ type: String, default: "Unnamed Section" }) label: string;
}
</script>
