<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <!--
  Custom select controls like this require a considerable amount of JS to implement from scratch. We're planning
  to build some low-level libraries to make this easier with popular frameworks like React, Vue, and even Alpine.js
  in the near future, but in the mean time we recommend these reference guides when building your implementation:

  https://www.w3.org/TR/wai-aria-practices/#Listbox
  https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
-->
  <div class="flex flex-row items-center justify-between w-full relative">
    <div
      v-if="hasSlot"
      class="text-sm text-gray-500 cursor-pointer"
      @click="toggle"
    >
      <slot name="label"></slot>
    </div>
    <div
      ref="menu"
      :class="{ 'w-full': fullWidth, 'pointer-events-none': disabled }"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="truncate hover:bg-white select-none focus:bg-white text-gray-900 relative w-full hover:ring-1 focus:ring-1 focus:ring-app-teal focus:outline-none rounded-sm pl-2 pr-9 py-1 text-left"
        @click="toggle"
        :disabled="disabled"
        :class="{ 'hover:ring-gray-300': !expanded }"
        :style="selectionDisplayStyle"
      >
        <div v-if="!easeSelector" class="flex">
          <span v-if="label">{{ label }}</span>
          <span> {{ selectionText }}</span>
        </div>

        <div v-if="easeSelector" class="flex">
          <span class="h-5 w-5 flex items-center justify-center mr-1">
            <EaseIcon class="h-4 w-4" :name="selectedItem?.label" />
          </span>
          <span> {{ selectionText }}</span>
        </div>

        <!-- Heroicon name: selector -->
        <Icon
          v-if="hover || expanded"
          :name="icon"
          class="h-6 w-6 absolute top-1 right-0 pr-2 pointer-events-none text-gray-400"
        />
      </button>

      <div
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50"
        :class="{
          'w-auto': menuWidth === 'auto',
          'right-0': menuAlign === 'right',
        }"
        v-show="expanded && !disabled"
        ref="expanded"
        :style="expandedStyle"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
        >
          <li
            :key="$index"
            v-for="(item, $index) in items"
            role="option"
            :class="[
              item.disabled
                ? 'bg-gray-500 cursor-not-allowed opacity-50'
                : item.highlighted
                ? 'text-white bg-app-teal'
                : 'text-gray-900',
              {
                'pr-9': !easeSelector,
                'pr-4': easeSelector,
              },
            ]"
            class="cursor-default select-none flex flex-row relative py-2 pl-3"
            @click="select(item)"
            @mouseenter="highlight($index)"
            @mouseleave="unhighlight"
          >
            <div v-if="easeSelector" class="flex flex-row">
              <span
                class="flex items-center w-5 h-5"
                :class="{
                  'text-white': item.highlighted,
                  'text-app-teal': !item.highlighted,
                }"
              >
                <!-- Heroicon name: check -->
                <IconSolid
                  v-if="item.value === value"
                  name="Checkmark"
                  class="h-5 w-5"
                />
              </span>
              <span class="h-5 w-5 flex items-center justify-center mr-1">
                <EaseIcon class="h-4 w-4" :name="item.label" />
              </span>
            </div>

            <span
              class="block truncate"
              :class="{
                'font-semibold': item.selected,
                'font-normal': !item.selected,
              }"
              :style="item.style"
              >{{ item.label }}
            </span>
            <span
              v-if="item.value === value && !easeSelector"
              class="text-app-teal absolute inset-y-0 right-0 flex items-center pr-2"
              :class="{
                'text-white': item.highlighted,
                'text-app-teal': !item.highlighted,
              }"
            >
              <!-- Heroicon name: check -->
              <IconSolid name="Checkmark" class="h-5 w-5" />
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import isEqual from "lodash.isequal";
import { InputOption } from "@/types/inputs";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import EaseIcon from "./icons/animationIcons/EaseIcon.vue";

@Component({ components: { IconSolid, Icon, EaseIcon } })
export default class EditorSelectMenu extends Vue {
  @Prop(String) placeholder: string;
  @Prop() value: string | boolean | number | object;
  @Prop(Array) options: InputOption[];
  @Prop({ default: "" }) label: string;
  @Prop({ default: false }) rounded: boolean;
  @Prop({ default: true }) fullWidth: boolean;
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) dark: boolean;
  @Prop(String) menuWidth: string;
  @Prop({ type: String, default: "right" }) menuAlign: string;
  @Prop({ type: Boolean, default: false }) easeSelector: boolean;

  highlightedIndex = -1;
  expanded = false;
  menuTop = 0;
  expandedHeight = 0;
  hover = false;

  get hasSlot() {
    return !!this.$slots.label;
  }

  get items() {
    return this.options.map((item: any, index: number) =>
      Object.assign(item, {
        highlighted: this.highlightedIndex === index,
        selected: item.value === this.value,
      })
    );
  }

  get icon() {
    if (this.expanded) {
      return "ChevronUp";
    } else {
      return "ChevronDown";
    }
  }

  created() {
    if (this.value) {
      this.highlightedIndex = this.options.findIndex(
        (o) => o.value === this.value
      );
    }
  }

  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  highlight(value: number) {
    this.highlightedIndex = value;
  }

  unhighlight() {
    this.highlightedIndex = -1;
  }

  close(ev: any = null) {
    // Aha! clicks on this component are @click.stop...why did we do that??
    // OOOh so that click on this very thing won't close it, sure
    // But then we have result that clicking on another select menu will not close this one. Problem.
    // Solve by checking whether click was inside this component here, before closing
    if (ev === null || !this.$el.contains(ev.target)) {
      this.expanded = false;
      document.removeEventListener("click", this.close);
      document.removeEventListener("keydown", this.onKeyDown);
    }
  }

  open() {
    this.expanded = true;
    document.addEventListener("click", this.close);
    document.addEventListener("keydown", this.onKeyDown);
    this.$nextTick(() => {
      this.expandedHeight = (
        this.$refs.expanded as HTMLElement
      ).getBoundingClientRect().height;
    });
  }

  toggle() {
    if (this.disabled) return;
    if (this.expanded) {
      this.close();
    } else {
      // Huh...it doesn't automatically update this, ever. So must do it manually.
      this.menuTop = (
        this.$refs.menu as HTMLElement
      ).getBoundingClientRect().top;
      this.open();
    }
  }

  // - [ ] NOTE: This may not be ideal...if there are many options, we probably want to still open down
  // Probably just add a max

  get expandedStyle() {
    const INPUT_HEIGHT = 40; // May want to compute this
    const MINIMUM_VERTICAL_SPACE = this.expandedHeight + INPUT_HEIGHT;
    // console.log("min", MINIMUM_VERTICAL_SPACE);

    // const MAX_SPACE = 250;
    // const MIN_HEIGHT = Math.min(MAX_SPACE, MINIMUM_VERTICAL_SPACE);

    const windowHeight = window.innerHeight;
    if (windowHeight - this.menuTop < MINIMUM_VERTICAL_SPACE) {
      return {
        bottom: `${INPUT_HEIGHT}px`,
      };
    }

    return {};
  }

  select(item: InputOption) {
    if (item.disabled) {
      return;
    }

    this.$emit("input", item.value);
    this.close();
  }

  onKeyDown(e: KeyboardEvent) {
    e.stopPropagation();
    e.preventDefault();
    if (!this.expanded) {
      return;
    }

    if (e.key === "Escape") {
      this.expanded = false;
    }

    if (
      e.key === "Enter" &&
      this.highlightedIndex > -1 &&
      this.highlightedIndex < this.options.length
    ) {
      this.select(this.options[this.highlightedIndex]);
    }

    if (e.key === "ArrowDown") {
      this.highlightedIndex++;
      if (this.highlightedIndex >= this.options.length) {
        this.highlightedIndex = this.options.length - 1;
      }
    }

    if (e.key === "ArrowUp") {
      this.highlightedIndex--;
      if (this.highlightedIndex < 0) {
        this.highlightedIndex = 0;
      }
    }
  }

  get selectedItem() {
    return this.options.find((o) => isEqual(o.value, this.value));
  }

  get selectionDisplayStyle() {
    return this.selectedItem?.style || {};
  }

  get selectionText() {
    return this.selectedItem?.label || this.placeholder;
  }
}
</script>

<style scoped></style>
