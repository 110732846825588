<template>
  <div class="w-full">
    <RemapColumns
      v-if="isReady"
      ref="remapColumns"
      :connectionUuid="store.connectionUuidToBeReplaced"
      :newConnectionUuid="store.connectionUuid"
      :newNodes="store.configuredNodes"
      :hideButton="true"
      @complete="onRemapComplete"
    />

    <UiBlocker :visible="savingBindings">
      <span v-t="'saving'"></span>
    </UiBlocker>

    <portal to="setupWizardNext">
      <FormButton v-t="'continue'" @click="onCompleteClick"></FormButton>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConnectionSourceId } from "@/types/ConnectionSources";
import { useConnectionSetupStore } from "@/stores/connectionSetup";

import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import RemapColumns from "@/components/data/connections/manage/RemapColumns.vue";
import { isNullOrUndefined } from "@/utils";
import { RemapCollectionPayload } from "@/stores/appEditor";

@Component({
  components: {
    RemapColumns,
    UiBlocker,
    FormButton,
  },
})
export default class SetupReplace extends Vue {
  @Prop(String) sourceId: ConnectionSourceId;

  savingBindings = false;

  get store() {
    return useConnectionSetupStore();
  }

  created() {
    this.store.step = "Replace";
    console.log("SetupReplace.vue -- created");
  }

  get isReady() {
    return (
      !isNullOrUndefined(this.store.connectionUuidToBeReplaced) &&
      !isNullOrUndefined(this.store.connectionUuid)
    );
  }

  onCompleteClick() {
    /**
     * This is strange, but we're triggering the saveBindings()
     * method on the remapColumns component which then emits
     * the "complete" event which we've bound to `onRemapComplete()`.
     *
     * The reason is... we want to use the `Continue` button from _this_
     * component to trigger that action because it lives in a portal.
     */
    (this.$refs.remapColumns as RemapColumns).saveBindings();
  }

  onRemapComplete(payload: RemapCollectionPayload) {
    this.savingBindings = true;
    this.store
      .defineReplacements(payload)
      .then((routeParams) => {
        this.$router.push({
          path: routeParams.step.toLowerCase(),
          query: { ...this.$route.query },
        });
      })
      .finally(() => {
        this.savingBindings = false;
      });
  }
}
</script>
