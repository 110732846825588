import { Point, TempBackgroundInfo, WidgetType } from "@/types";
import { DataBinding, DataType, NodeData } from "@/types/data";
import { defineStore } from "pinia";
import { EventBus } from "@/eventbus";
import { makeId } from "@/utils";
import { BASE_PARENT_ID } from "@/constants";
import { useConditionGroupsStore } from "./conditionGroups";
import { useAppEditorStore } from "./appEditor";
import { useConnectionEditorStore } from "./connectionEditor";
import { useConnectionDataStore } from "./connectionData";

export interface HoverTarget {
  widgetId: string;
  widgetType: WidgetType;
  // Allow setting context to determine this, rather than computing from list of components here
  isPhotoDropContainer?: boolean;
}

export interface DataWidgetInfo extends NodeData {
  connectionUuid: string;
  nodeSetUuid?: string;
  children?: NodeData[];
}

export interface DraggingInfo {
  type: "Asset" | "Node";
  parentWidgetId?: string;
  dropPoint?: Point;

  // For "Nodes"
  dataUuid?: string;
  connectionUuid?: string;
  dataParentUuid?: string;
  query?: string;
  dataType?: DataType;
  formattedValue?: string;
  children?: NodeData[];
  isArtificial?: boolean;

  // For "Assets"
  url?: string;
  width?: number;
  height?: number;
  mimeType?: string;
}

interface DragGhostStyle {
  opacity: number;
  width: number;
  height: number;
  top: number;
  left: number;
}

export interface DragDropState {
  hoverTarget: HoverTarget | null;
  draggingInfo: DraggingInfo | null;
  usagePromptIsOpen: boolean;
  isReplacingData: boolean; // Can probably be computed...
  isHandlingDrop: boolean;
  ghostStyle: DragGhostStyle;
}

export const useDragDropStore = defineStore("dragDrop", {
  state: (): DragDropState => {
    return {
      hoverTarget: null,
      draggingInfo: null,
      usagePromptIsOpen: false,
      isReplacingData: false,
      isHandlingDrop: false,
      ghostStyle: {
        opacity: 0,
        width: 400,
        height: 400,
        top: 0,
        left: 0,
      },
    };
  },
  getters: {
    isDraggingItem(): boolean {
      return this.draggingInfo !== null;
    },

    isDraggingNode(): boolean {
      return this.draggingInfo?.type === "Node";
    },

    isDraggingImage(): boolean {
      return (
        this.draggingInfo?.type === "Asset" ||
        ["ImageUrl", "ImageUpload"].includes(this.draggingInfo?.dataType || "")
      );
    },

    hoveredTextWidgetId(): string | null {
      if (this.hoverTarget === null) return null;
      if (this.hoverTarget.widgetType !== "Text") return null;
      return this.hoverTarget.widgetId;
    },

    hoveredPhotoDropWidgetId(): string | null {
      if (!this.hoverTarget?.isPhotoDropContainer) return null;
      return this.hoverTarget.widgetId;
    },

    draggedPhotoNodeUuid(): string | null {
      if (!this.isDraggingImage || !this.isDraggingNode) return null;
      return this.draggingInfo?.dataUuid || null;
    },

    tempBackgroundInfo(): TempBackgroundInfo | null {
      if (!this.isDraggingImage) return null;
      if (!this.hoverTarget?.isPhotoDropContainer) return null;

      const appEditor = useAppEditorStore();
      return {
        url: this.draggingInfo?.url,
        wid: this.hoverTarget?.widgetId || "",
        w: this.draggingInfo?.width,
        h: this.draggingInfo?.height,
        isDynamic:
          !!this.draggedPhotoNodeUuid && !appEditor.isBaseEditingContext,
      };
    },

    /**
     * There are 3 cases under which we need to hide the "use on its own" option:
     * - The node is from a calendar dataset
     * - The node represents a "data index"
     * - The node is from a row that has been moderated out of the dataset
     */

    preventScalarUsage(): boolean {
      const connectionDataStore = useConnectionDataStore();
      const connectionEditorStore = useConnectionEditorStore();

      const isFromCalendar =
        connectionEditorStore.connection?.schemaType === "Calendar";
      if (isFromCalendar) return true;

      const isArtificialNode = this.draggingInfo?.isArtificial === true;
      if (isArtificialNode) return true;

      if (connectionEditorStore.moderationMode === null) return false;

      const isFromModeratedOutRow =
        connectionEditorStore.moderationMode === "Approval"
          ? !connectionDataStore.moderationRow?.isSelected
          : connectionDataStore.moderationRow?.isSelected;

      return isFromModeratedOutRow;
    },
  },

  actions: {
    async handleNodeDrop() {
      const appEditor = useAppEditorStore();
      const editingContext = appEditor.editingContext;
      const dataBindings = appEditor.dataBindings;

      this.isHandlingDrop = true;

      const hoveredRepeaterWid =
        editingContext.parentId === BASE_PARENT_ID
          ? null
          : editingContext.parentId;
      const hoveredRepeaterBinding = dataBindings.find(
        (db: DataBinding) =>
          db.bindingType === "DataSetParent" &&
          db.widgetId === hoveredRepeaterWid
      );

      this.isReplacingData = false;

      /**
       * Show usagePrompt if:
       * (1) node is dropped onto canvas from a collection, OR
       * (2) node is dropped into repeater from a foreign collection.
       */

      const connectionDataStore = useConnectionDataStore();

      let shouldOpenUsagePrompt = !connectionDataStore.connectionIsTree;
      if (hoveredRepeaterWid && !connectionDataStore.connectionIsTree) {
        shouldOpenUsagePrompt =
          typeof hoveredRepeaterBinding?.dataConnectionUuid !== "undefined" &&
          hoveredRepeaterBinding?.dataConnectionUuid !==
            this.draggingInfo?.connectionUuid;
        if (shouldOpenUsagePrompt) {
          this.isReplacingData = true;
        }
      }
      if (this.hoveredTextWidgetId) {
        shouldOpenUsagePrompt = false;
      }

      if (shouldOpenUsagePrompt) {
        this.usagePromptIsOpen = true;
        return;
      }

      this.createDynamicWidgetAction(
        hoveredRepeaterWid,
        hoveredRepeaterBinding
      );
    },

    async createDynamicWidgetAction(
      hoveredRepeaterWid: string | null,
      hoveredRepeaterBinding: DataBinding | undefined
    ) {
      EventBus.emit("AWAITING_SERVER", true);

      const creatingToken = !!this.hoveredTextWidgetId;

      const appEditor = useAppEditorStore();
      const conditionsStore = useConditionGroupsStore();

      if (creatingToken) {
        const hoveredTextWidgetConditionId =
          conditionsStore.getActiveConditionId(this.hoveredTextWidgetId || "");
        EventBus.emit("DATA_TOKEN_DROPPED", {
          widgetId: this.hoveredTextWidgetId as string,
          conditionId: hoveredTextWidgetConditionId,
          draggingInfo: this.draggingInfo as DraggingInfo,
          parentWidgetId: hoveredRepeaterWid || BASE_PARENT_ID,
        });
        return;
      }

      try {
        const hasDataSetBinding = !!hoveredRepeaterBinding;
        // Binding should be scalar if node is from a foreign dataset;
        // that is, if the repeater already has a dataset bound, and the node is from a different one.
        let isScalar =
          hasDataSetBinding &&
          hoveredRepeaterBinding?.dataConnectionUuid !==
            this.draggingInfo?.connectionUuid;

        if (!creatingToken) {
          isScalar = useConnectionDataStore().connectionIsTree;
        }

        const hoveredPhotoDropWidgetConditionId =
          conditionsStore.getActiveConditionId(
            this.hoveredPhotoDropWidgetId || ""
          );

        await appEditor.createDynamicWidget(
          {
            isNewRepeater: false,
            isScalar: !hoveredRepeaterWid ? true : isScalar,
            hoveredPhotoDropWidgetId: this.hoveredPhotoDropWidgetId as string,
            hoveredPhotoDropWidgetConditionId:
              hoveredPhotoDropWidgetConditionId,
            draggingInfo: this.draggingInfo,
            widgetId: makeId(),
            parentWidgetId: hoveredRepeaterWid,
          },
          null,
          hoveredRepeaterBinding ? null : hoveredRepeaterWid
        );

        // Trigger data refresh
        await appEditor.updateApp();
        this.draggingInfo = null;
        this.hoverTarget = null;
      } finally {
        EventBus.emit("AWAITING_SERVER", false);
        this.isHandlingDrop = false;
      }
    },

    updateGhostStyle(style: Partial<DragGhostStyle>) {
      this.ghostStyle = {
        ...this.ghostStyle,
        ...style,
      };
    },
  },
});
