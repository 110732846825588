<template>
  <div
    class="txfrm pointer-events-none"
    :class="{ 'txfrm--active': showBorder }"
    :style="zStyle"
    @mouseleave="mouseleave"
  >
    <div
      v-if="showBorder"
      class="resizer-controls pointer-events-none"
      :style="controls"
    >
      <WidgetUIControls
        v-bind="$props"
        :style="{ transform: 'rotate(' + -angle + 'deg)' }"
      />
      <div
        v-if="showRotationHandle"
        class="resizer-rotator pointer-events-auto"
        :style="transformHandleStyle(true)"
        @mousedown="handleRotation"
      ></div>
      <div
        class="resizer-corner top-0 left-0 pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="canDragCorners"
        @mousedown="handleScale('tl', $event)"
      ></div>
      <div
        class="resizer-x top-1/2 left-0 pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="showXResizeHandles"
        @mousedown="handleScale('ml', $event)"
      ></div>
      <div
        class="resizer-corner top-0 left-full pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="canDragCorners"
        @mousedown="handleScale('tr', $event)"
      ></div>
      <div
        class="resizer-y top-0 left-1/2 pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="showYResizeHandles"
        @mousedown="handleScale('tm', $event)"
      ></div>
      <div
        class="resizer-corner top-full left-0 pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="canDragCorners"
        @mousedown="handleScale('bl', $event)"
      ></div>
      <div
        class="resizer-y top-full left-1/2 pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="showYResizeHandles"
        @mousedown="handleScale('bm', $event)"
      ></div>
      <div
        class="resizer-corner top-full left-full pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="canDragCorners"
        @mousedown="handleScale('br', $event)"
      ></div>
      <div
        class="resizer-x top-1/2 left-full pointer-events-auto"
        :style="transformHandleStyle()"
        v-if="showXResizeHandles"
        @mousedown="handleScale('mr', $event)"
      ></div>
    </div>

    <div
      class="resizer-border pointer-events-none"
      :style="borderStyle"
      :class="{
        'resizer-border-selected': showBorder,
        'resizer-border-selected-hovered': showBorder && hovered,
        'resizer-border-hover': hovered,
      }"
    ></div>

    <div :style="element" class="pointer-events-none"></div>
  </div>
</template>

<script lang="ts">
import { styler, scale, translate, rotate } from "@/lib/free-transform";
import { throttle } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import { EventBus } from "@/eventbus";
import { KeyCodes } from "@/keycodes";
import { StyleValue } from "vue/types/jsx";
import WidgetUIControls from "@/components/WidgetUIControls.vue";
import { useAppEditorStore } from "@/stores/appEditor";
import { findWidget } from "@/findWidget";
import { SnapPair } from "@/types/snapping";
import { ResizeDimension } from "@/types";

// Aha! For groups, we can just put txfrm__content ABOVE the controls....because controls needn't be visible! Perfect
// This should allow for selection of children

/*
- [ ] TODO: Add snapping back in -- maybe try for abstraction now?
- [ ] TODO: Use outline instead of transparent border

- [ ] Make sure you only show photohover border if Rect, Ellipse or SVG
*/

type MouseEventHandler = (e: MouseEvent) => void;

type ScaleType = "tl" | "tm" | "tr" | "mr" | "br" | "bm" | "bl" | "ml";

const getResizeDimension = (scaleType: ScaleType): ResizeDimension => {
  if (["tl", "tr", "br", "bl"].includes(scaleType)) {
    return "x&y";
  }
  if (scaleType.includes("l") || scaleType.includes("r")) {
    return "x";
  }
  return "y";
};

@Component({
  inheritAttrs: false,
  components: {
    WidgetUIControls,
  },
})
export default class Resizer extends Vue {
  @Prop({ type: Number }) w: number;
  @Prop({ type: Number }) h: number;
  @Prop({ type: Number }) x: number;
  @Prop({ type: Number }) y: number;
  @Prop({ type: Number }) z: number;
  @Prop({ type: String }) wid: string;
  @Prop({ type: Number, default: 1 }) scaleX: number;
  @Prop({ type: Number, default: 1 }) scaleY: number;
  @Prop({ type: Number, default: 0 }) angle: number;
  @Prop() perspective: number;
  @Prop({ type: String }) type: string;
  @Prop({ type: Boolean }) locked: boolean;
  @Prop({ type: Boolean }) lockAspect: boolean;
  @Prop() children: any[];
  @Prop({ type: Number, default: 0.001 }) scaleLimit: number; // Lower this to allow more squishing
  @Prop({ type: Number, default: 1 }) aspectRatio: number;
  @Prop({ type: Boolean, default: true }) scaleFromCenter: boolean;
  @Prop(Boolean) canScaleX: boolean;
  @Prop(Boolean) canScaleY: boolean;

  @Prop({ type: Boolean }) spacePressed: boolean;

  mousedownPosition = {
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    snapX: 0,
    snapY: 0,
  };

  get appEditor() {
    return useAppEditorStore();
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get hoveredId() {
    return this.appEditor.hoveredId;
  }

  get scale() {
    return this.appEditor.scale;
  }

  get widgets() {
    return this.appEditor.renderableWidgets;
  }

  get artboard() {
    return this.appEditor.artboard;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get canvasBox() {
    return this.appEditor.canvasBox;
  }

  get animationPlaying() {
    return this.appEditor.animationPlaying;
  }

  // hasSelectedTextComponent = false;

  get canDragCorners() {
    // Show corner handles for text component (including Datetime) even though canScaleY is false:
    if (this.isTextlikeWidget) return true;
    return this.canScaleX && this.canScaleY;
  }

  get showXResizeHandles() {
    /**
     * If the object height is less than a certain amount
     * the resize handles on the sides overlap and it looks bad.
     */
    const min = 64;
    return (
      (this.canScaleX && Math.ceil(this.h * this.scaleY) >= min) ||
      this.selectedWidget?.type === "Line"
    );
  }

  get showYResizeHandles() {
    /**
     * If the object width is less than a certain amount the
     * resize handles on the top and bottom overlap and it looks bad.
     */
    //Exception neccesary for line shape component
    const min = 64;
    return this.canScaleY && Math.ceil(this.w * this.scaleX) >= min;
  }

  get zStyle(): StyleValue {
    return {
      zIndex: this.selected && !this.locked ? 2000 : this.z,
    };
  }

  transformHandleStyle(isRotator = false): StyleValue {
    let showHandle = this.selected && this.selections.length === 1;
    if (!isRotator && this.type === "Group") showHandle = false;
    if (this.locked) showHandle = false;
    return {
      display: showHandle ? "block" : "none",
      pointerEvents: "auto",
    };
  }

  get canSelect() {
    return !this.spacePressed && this.editingContext.parentId !== this.wid;
  }

  get selected() {
    return this.selections.includes(this.wid);
  }

  get showBorder() {
    if (this.animationPlaying) {
      return false;
    }
    return (
      this.selected ||
      (this.children || []).some((wg) => this.selections.includes(wg.wid))
    );
  }

  get showRotationHandle() {
    return this.type != "Repeater";
  }

  get hovered() {
    return this.wid === this.hoveredId;
  }

  get borderStyle(): { [key: string]: any } {
    return { ...this.element };
  }

  // Accounts for distinction between render layer (this.x, etc) and manipulation layer (realPosition):
  get realPosition() {
    const rect = { x: this.x, y: this.y, w: this.w, h: this.h };
    const result = this.appEditor.scaleForEditLayer(rect);

    // Account for special case of children of Repeater that lives within a Group:
    const widget = this.appEditor.widgetById(this.wid);
    const parent = this.appEditor.widgetById(widget?.parentId as string);

    if (parent?.type === "Repeater") {
      const grandparent = this.appEditor.widgetById(parent?.parentId as string);
      if (grandparent?.type === "Group") {
        result.x += grandparent.x * this.scale;
        result.y += grandparent.y * this.scale;
      }
    }

    return result;
  }

  get element(): { [key: string]: any } {
    const { x, y, w, h } = this.realPosition;

    const { element } = styler({
      x: x,
      y: y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      width: w,
      height: h,
      angle: this.angle,
      disableScale: true, // always disable scale
    });

    // Allows us to detect clicks on children (really, distinction between layers not necessary for this):
    const zStyle = this.type === "Group" ? { zIndex: 1000 } : {};

    return {
      ...element,
      ...zStyle,
      // ...pointerEvents,
      width: element.width ? `${element.width}px` : null,
      height: element.height ? `${element.height}px` : null,
    };
  }

  get controls(): { [key: string]: any } {
    const { x, y, w, h } = this.realPosition;

    const { controls } = styler({
      x,
      y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      width: w,
      height: h,
      angle: this.angle,
      disableScale: true,
    });

    return {
      ...controls,
      width: `${controls.width}px`,
      height: `${controls.height}px`,
    };
  }

  get isTextlikeWidget() {
    return this.type === "Text" || this.type === "Datetime";
  }

  handleScale(scaleType: ScaleType, event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.mousedownPosition.scaleX = this.scaleX;
    this.mousedownPosition.scaleY = this.scaleY;

    const resizeDimension = getResizeDimension(scaleType);
    const isCornerHandle = resizeDimension === "x&y";
    this.appEditor.dragResizeDimension = resizeDimension;

    const onResize = scale(
      scaleType,
      {
        startX: event.pageX,
        startY: event.pageY,
        x: this.x,
        y: this.y,
        canvasScale: this.scale,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        width: this.w,
        height: this.h,
        widgetType: this.type,
        angle: this.angle,
        scaleLimit: this.scaleLimit,
        scaleFromCenter: this.scaleFromCenter && event.altKey,
        enableScaleFromCenter: this.scaleFromCenter,
        aspectRatio: this.aspectRatio && event.shiftKey && this.canDragCorners,
        enableAspectRatio: this.aspectRatio && this.canDragCorners,
        lockAspect:
          this.lockAspect || (this.isTextlikeWidget && isCornerHandle), // Act as if lockedAspect is true in this case
      },
      (payload: any) => {
        const changed = !(
          payload.x === this.x &&
          payload.y === this.y &&
          payload.scaleX === this.mousedownPosition.scaleX &&
          payload.scaleY === this.mousedownPosition.scaleY
        );
        if (changed) {
          const props = {
            x: payload.x,
            y: payload.y,
            scaleX: payload.scaleX,
            scaleY: payload.scaleY,
          };

          // This works for our bug...(dragging middle handles -- horizontal resize -- of text comp that has been corner-resized)
          // But creates bug where after you  translate it, aand thenn drag,  it breaks
          // Maybe  fixed with y/h watchers to update base

          // Must "freeze" y dimension to avoid jumpiness bug when resizing text comp horizontally
          const lockY = this.isTextlikeWidget && !isCornerHandle;
          if (lockY) {
            props.y = this.y;
          }

          this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
        }
      }
    );

    const onResizeComplete = async () => {
      if (
        !(
          this.x === this.mousedownPosition.x &&
          this.y === this.mousedownPosition.y &&
          this.scaleX === this.mousedownPosition.scaleX &&
          this.scaleY === this.mousedownPosition.scaleY
        )
      ) {
        /**
         * This event ensures that we also trigger the corresponding fontSize update.
         */
        EventBus.emit("COMPLETE_CORNER_RESIZE", this.wid);

        let payload: any = {
          x: this.x,
          y: this.y,
          scaleX: this.scaleX,
          scaleY: this.scaleY,
        };

        const widget = findWidget(this.$root, this.wid);
        if (
          widget &&
          "handleResize" in widget &&
          typeof widget.handleResize === "function"
        ) {
          const resizedProps = await widget.handleResize();
          payload = {
            ...payload,
            ...resizedProps,
          };
        }
        this.appEditor.setWidgetProps([this.wid], payload);
      }
    };

    this.handleDrag(onResize, onResizeComplete);
  }

  handleTranslation(e: MouseEvent) {
    this.mousedownPosition.x = this.x;
    this.mousedownPosition.y = this.y;

    // console.log("translate from widgetresizer");

    e.stopPropagation();
    if (this.locked) {
      return;
    }

    const onMove = translate(
      {
        x: this.x,
        y: this.y,
        w: this.w,
        h: this.h,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        startX: e.pageX,
        startY: e.pageY,
        canvasScale: this.scale,
        widgets: this.widgets,
        wid: this.wid,
        artboard: this.artboard,
        isBaseEditingContext: this.isBaseEditingContext,
        editingContext: this.editingContext,
      },
      (payload: { x: number; y: number; snapPairs: SnapPair[] }) => {
        const changed = !(
          payload.x === this.mousedownPosition.x &&
          payload.y === this.mousedownPosition.y
        );

        EventBus.emit("SNAP_PAIRS", payload.snapPairs);

        if (changed) {
          const props = {
            x: payload.x,
            y: payload.y,
            w: this.w,
            h: this.h,
          };
          this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
        }
      }
    );

    const onMoveComplete = () => {
      if (
        !(
          this.x === this.mousedownPosition.x &&
          this.y === this.mousedownPosition.y
        )
      ) {
        const props = {
          x: this.x,
          y: this.y,
          w: this.w,
          h: this.h,
        };
        this.appEditor.setWidgetProps([this.wid], props);
      }
    };

    this.handleDrag(onMove, onMoveComplete);
  }

  handleRotation(e: MouseEvent) {
    e.stopPropagation();
    this.mousedownPosition.angle = this.angle;

    const onRotate = rotate(
      {
        startX: e.pageX,
        startY: e.pageY,
        x: this.x,
        y: this.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        canvasScale: this.scale,
        canvasX: this.canvasBox.x,
        canvasY: this.canvasBox.y,
        canvasW: this.canvasBox.w,
        canvasH: this.canvasBox.h,
        artboardX: this.artboard.x,
        artboardY: this.artboard.y,
        width: this.w,
        height: this.h,
        angle: this.angle,
      },
      (event: { angle: number }) => {
        if (this.angle !== event.angle) {
          let a = event.angle;
          if (a < 0) a = (a + 360 * 10) % 360;
          if (a >= 360) a = a % 360;
          this.appEditor.setWidgetProps(
            [this.wid],
            {
              angle: a,
            },
            "NO_UNDO"
          );
        }
      }
    );

    const onRotateComplete = () => {
      if (this.angle !== this.mousedownPosition.angle) {
        this.appEditor.setWidgetProps([this.wid], {
          angle: this.angle,
        });
      }
    };

    this.handleDrag(onRotate, onRotateComplete);
  }

  /**
   * This helper function creates temporary mousemove and mouseup handlers.
   *
   * It wraps repeated logic for rotate, resize and move handlers.
   *
   * @param onMouseMove Logic to execute on each mousemove event while dragging
   * @param onMouseUp Logic to execute on mouseup after dragging
   */
  handleDrag(onMouseMove: MouseEventHandler, onMouseUp: MouseEventHandler) {
    this.appEditor.isDraggingWidget = true;
    const throttledHandler = throttle(onMouseMove);

    const up = async (e: MouseEvent) => {
      this.appEditor.isDraggingWidget = false;

      onMouseUp(e);

      document.removeEventListener("mousemove", throttledHandler);
      document.removeEventListener("mouseup", up);

      EventBus.emit("SNAP_PAIRS", []);
    };

    document.addEventListener("mousemove", throttledHandler);
    document.addEventListener("mouseup", up);
  }

  mouseenter() {
    this.appEditor.setHoveredId(this.wid);
  }

  mouseleave() {
    this.appEditor.setHoveredId("");
  }

  click() {
    if (this.locked) {
      this.appEditor.replaceSelections([this.wid]);
    }

    // So this doesn't work... because mousedown fires first and selects it
    // Prob will work if put in mousedown
    // But then we have issue that it initiates a drag...Fix with return.
    // Oooh..but we WANT it to be able to drag in this situation.
    // So really want to wait for mouseup for enter editing...

    // This mostly works....but we should not do this if we were just dragging
    // if (
    //   this.selected &&
    //   this.type === "Text" &&
    //   !this.hasSelectedTextComponent
    // ) {
    //   this.enterTextEditMode(this.wid);
    // }

    // this.hasSelectedTextComponent = false;
  }

  // Need to NOT do this at the end of a groupDrag (handled with stopPropagation when end dragbox drawing, and when end groupdrag)
  mouseup(event: any) {
    // console.log("mouseup");
    const multiselected =
      this.selections.length > 1 && !KeyCodes.isMultiselectKey(event);
    if (multiselected) {
      // console.log("click locked", this.wid);
      this.appEditor.replaceSelections([this.wid]);
    }
  }

  mousedown(event: any) {
    if (event.button !== 0) {
      return;
    }
    if (this.locked) {
      return;
    }

    let shouldClearSelections = false;

    // We need to propagate this event in order to alert CanvasEditor that it can handle panning and multiselect dragging
    // (so do not stopPropagation)

    // Handle case where widget other than multiselected widgets is mousedowned (do not group drag!):
    if (
      this.selections.length > 1 &&
      !KeyCodes.isMultiselectKey(event) &&
      !this.selected
    ) {
      this.appEditor.replaceSelections([this.wid]);
    }
    // Let multidrag handle this:
    if (this.selections.length > 1 && !KeyCodes.isMultiselectKey(event)) return;

    if (this.selected && KeyCodes.isMultiselectKey(event)) {
      this.appEditor.removeSelection(this.wid);
      return;
    }

    if (!this.canSelect) {
      return;
    }

    if (!KeyCodes.isMultiselectKey(event)) {
      if (this.selections.length === 1) {
        if (this.selections[0] !== this.wid) {
          shouldClearSelections = true;
        } else {
          // TODO: Is this even needed?
          const wg = this.appEditor.widgetById(this.selections[0]);
          if (wg && wg.locked) {
            shouldClearSelections = true;
          }
        }
      }
    }

    if (shouldClearSelections) {
      this.appEditor.replaceSelections([]);
    }

    this.appEditor.addSelection(this.wid);
    this.handleTranslation(event);
  }
}
</script>

<style lang="postcss">
.txfrm {
  position: relative;
}

.txfrm--active {
  position: absolute;
  z-index: 5;
}

.resizer-controls {
  z-index: 2;
}

.txfrm--active .vdr-border {
  @apply border-gray-500;
}

.resizer-rotator,
.resizer-corner,
.resizer-x,
.resizer-y {
  @apply absolute 
    transform -translate-x-1/2 -translate-y-1/2
    bg-white
    border
    border-gray-500
    cursor-pointer
    hover:border-gray-700;
  border-width: 1px;
}

.resizer-rotator {
  @apply w-6 h-6 left-1/2 -top-24;
  border-width: 0;
  border-radius: 100%;
  background-image: url("../assets/rotate-cursor.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.resizer-corner {
  @apply w-2 h-2;
}

.resizer-x {
  @apply w-2 h-8;
  width: 6px;
}
.resizer-y {
  @apply w-8 h-2;
  height: 6px;
}

.resizer-border {
  outline-style: dashed;
  outline-width: 1px;
  outline-color: theme("colors.gray.500");
  opacity: 0;
}
.resizer-border-selected {
  opacity: 0.5;
  outline-style: solid;
}
.resizer-border-hover {
  opacity: 0.5;
}
</style>
