<template>
  <div class="text-gray-500 pb-6" id="container">
    <div class="border-y p-6">
      <div v-if="conditionsErrorMessage" class="text-red-500 mb-2">
        {{ conditionsErrorMessage }}
      </div>
      <button
        type="button"
        class="outline-button"
        @click="handleAddConditionClick"
      >
        <Icon class="w-5 h-5" name="PlusCircle" />
        <div class="text-sm" v-t="'Conditions.addCondition'"></div>
      </button>
    </div>

    <!-- Conditions buttons: -->
    <Draggable
      direction="vertical"
      v-if="conditionGroup"
      class="p-6 flex flex-col space-y-6"
      v-model="conditions"
      group="conditions"
      handle="[drag-handle]"
      @start="isDragging = true"
      @end="isDragging = false"
    >
      <ConditionButton
        v-for="(condition, index) in conditions"
        :key="condition.uuid"
        :name="condition.name"
        :index="index"
        :uuid="condition.uuid"
        :selected="condition.uuid === selectedConditionId"
        :dragging="isDragging"
        :draggable="condition.draggable"
        @select="selectCondition"
        @remove="removeCondition"
        @edit="editCondition"
      />
    </Draggable>

    <div class="p-6 pt-0" v-if="conditionGroup">
      <ConditionButton
        name="Default"
        :uuid="defaultConditionId"
        :selected="defaultConditionId === selectedConditionId"
        :draggable="false"
        @select="selectCondition"
      />
    </div>

    <!-- Footer butttons: -->
    <div
      v-if="conditionGroup || showPasteConditionsButton"
      class="border-y p-6 space-y-4"
    >
      <Tooltip :text="copyTooltip">
        <button
          id="condition-copy-button"
          v-if="conditionGroup"
          type="button"
          class="w-full text-sm flex items-center justify-center border border-gray-500 space-x-2 py-1 rounded-md cursor-pointer hover:bg-gray-500/10"
          @click="handleCopyConditionClick"
          @mouseleave="copyTooltip = ''"
        >
          <svg
            class="w-4 h-4"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 3C0.5 2.20435 0.81607 1.44129 1.37868 0.87868C1.94129 0.31607 2.70435 0 3.5 0H18.5C19.2956 0 20.0587 0.31607 20.6213 0.87868C21.1839 1.44129 21.5 2.20435 21.5 3V9H27.5C28.2956 9 29.0587 9.31607 29.6213 9.87868C30.1839 10.4413 30.5 11.2044 30.5 12V27C30.5 27.7956 30.1839 28.5587 29.6213 29.1213C29.0587 29.6839 28.2956 30 27.5 30H12.5C11.7044 30 10.9413 29.6839 10.3787 29.1213C9.81607 28.5587 9.5 27.7956 9.5 27V21H3.5C2.70435 21 1.94129 20.6839 1.37868 20.1213C0.81607 19.5587 0.5 18.7956 0.5 18V3ZM12.5 21V27H27.5V12H21.5V18C21.5 18.7956 21.1839 19.5587 20.6213 20.1213C20.0587 20.6839 19.2956 21 18.5 21H12.5ZM18.5 18V3H3.5V18H18.5Z"
              fill="#737373"
            />
          </svg>

          <div>{{ $t("Conditions.copy") }}</div>
        </button>
      </Tooltip>

      <Tooltip :disabled="canPasteConditions" :text="pasteButtonTooltip">
        <button
          type="button"
          v-if="showPasteConditionsButton"
          :disabled="!canPasteConditions"
          class="w-full text-sm flex items-center justify-center border border-gray-500 space-x-2 py-1 rounded-md"
          :class="{
            'opacity-50 cursor-not-allowed': !canPasteConditions,
            'cursor-pointer hover:bg-gray-500/10': canPasteConditions,
          }"
          @click="handlePasteConditionClick"
        >
          <Icon class="w-5 h-5" name="Duplicate" />
          <div v-t="'Conditions.paste'"></div>
        </button>
      </Tooltip>
    </div>

    <ConditionsHelp v-if="!conditionGroup" class="p-6" />
    <ConditionsEditorHelpBubbles />
  </div>
</template>

<script lang="ts">
import { DEFAULT_CONDITION_ID } from "@/constants";
import { ConditionGroup } from "@/types/logic";
import { Component, Vue } from "vue-property-decorator";
import orderBy from "lodash.orderby";
import Draggable from "vuedraggable";

import HelpBubble from "@/components/HelpBubble.vue";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { EventBus } from "@/eventbus";

import Icon from "@/components/icons/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import ActionModal from "@/components/ActionModal.vue";
import ConditionsHelp from "@/components/editors/ConditionsHelp.vue";
import ConditionsEditorHelpBubbles from "@/components/editors/ConditionsEditorHelpBubbles.vue";
import ConditionButton from "@/components/editors/ConditionButton.vue";
import { extractErrorMessage } from "@/utils";
import { AppTimeline } from "../widgets/Animation";
import { useAppEditorStore } from "@/stores/appEditor";
import { logger } from "@core/logger";

type ConditionListItem = {
  name: string;
  uuid: string;
  order: number;
  draggable: boolean;
};
@Component({
  components: {
    Draggable,
    Icon,
    Tooltip,
    IconSolid,
    ActionModal,
    ConditionsHelp,
    ConditionsEditorHelpBubbles,
    ConditionButton,
    HelpBubble,
  },
})
export default class ConditionsEditor extends Vue {
  isDragging = false;
  hideIntro = false;
  copyTooltip = "";
  // showConfirmRemoveModal = false;
  conditionsErrorMessage = "";
  defaultConditionId = DEFAULT_CONDITION_ID;

  get appEditor() {
    return useAppEditorStore();
  }

  get widgets() {
    return this.appEditor.widgets;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get store() {
    return useConditionGroupsStore();
  }

  get conditionGroupUuid() {
    return this.conditionGroup?.uuid || "";
  }

  get conditionGroup(): ConditionGroup | null {
    return (
      this.store.conditionGroups.find((c) =>
        c.widgets.some((w) => w.widgetId === this.selectedWidget?.wid)
      ) ?? null
    );
  }

  get selectedConditionId() {
    if (this.selectedWidget === undefined) {
      return undefined;
    }
    return this.store.getActiveConditionId(this.selectedWidget?.wid);
  }

  get showPasteConditionsButton() {
    return (
      this.store.copiedConditionGroupUuid !== null &&
      this.store.copiedConditionGroupUuid !== this.conditionGroupUuid
    );
  }

  get canPasteConditions() {
    return this.conditionGroup === null;
  }

  get pasteButtonTooltip() {
    if (!this.canPasteConditions) {
      return this.$t("Conditions.pasteTooltipDisabled").toString();
    }
    return "";
  }

  get defaultCondition() {
    return {
      name: this.$t("Conditions.defaultName").toString(),
      uuid: DEFAULT_CONDITION_ID,
      draggable: false,
    };
  }

  get conditions(): ConditionListItem[] {
    const conditions = this.conditionGroup?.conditions ?? [];
    const items = conditions.map((c) => {
      return {
        name:
          this.store.refreshingConditionUuid === c.uuid
            ? this.$t("ConditionsEditor.loading").toString()
            : c.name,
        uuid: c.uuid,
        order: c.order,
        draggable: conditions.length > 1,
      } as ConditionListItem;
    });

    return orderBy(items ?? [], "order");
  }

  set conditions(conditions: ConditionListItem[]) {
    this.updateSortOrder(conditions);
  }

  // NOTE: It may be better not to do this, but instead just update local state manually on action success
  async refresh() {
    return this.store.getConditionGroups(this.$route.params.id);
  }

  updateWidgetConditionsMap(conditionId: string) {
    if (this.selectedWidget === undefined) {
      return;
    }
    Vue.set(
      this.store.activeWidgetConditionsMap,
      this.selectedWidget?.wid,
      conditionId
    );
  }

  initWidgetConditionalVersions() {
    // Make a copy of the default state for each condition in the condition group
    (this.conditionGroup?.conditions || []).forEach((condition) => {
      if (this.selectedWidget !== undefined) {
        const widgetVersionPayload = {
          widgetId: this.selectedWidget.wid,
          conditionUuid: condition.uuid as string,
        };

        this.appEditor.addWidgetCondition(widgetVersionPayload);

        // Also clone any data bindings associated with the widget under the default condition, for each new condition
        this.appEditor.cloneDataBindingsForNewCondition(widgetVersionPayload);
      }
    });
  }

  async selectCondition(conditionUuid: string) {
    // Turn off preview animation if it is playing a loop
    AppTimeline.kill();
    try {
      await this.store.setActiveCondition({
        conditionUuid,
        conditionGroupUuid: this.conditionGroup?.uuid as string,
      });
    } catch (err) {
      // console.log("Error refreshing data for condition", err);
      this.conditionsErrorMessage = extractErrorMessage(
        err as any,
        this.$t("errorRefreshing").toString()
      );
    }
  }

  removeCondition(conditionUuid: string) {
    const count = this.conditionGroup?.widgets.length ?? 0;

    const msg =
      count > 1
        ? this.$t("Conditions.removeConfirmMultiple", {
            count,
          }).toString()
        : this.$t("Conditions.removeConfirm").toString();

    if (window.confirm(msg)) {
      EventBus.emit("AWAITING_SERVER", true);
      this.store
        .deleteCondition(conditionUuid, this.conditionGroup?.uuid as string)
        .finally(() => {
          EventBus.emit("AWAITING_SERVER", false);
        });
    }
  }

  editCondition(conditionUuid: string) {
    this.$router.push({
      name: "condition-edit",
      params: {
        id: this.$route.params.id,
        conditionUuid: conditionUuid,
      },
      query: {
        widgetId: this.selectedWidget?.wid,
        conditionGroupUuid: this.conditionGroupUuid,
      },
    });
  }

  handleCopyConditionClick() {
    this.store.copiedConditionGroupUuid = this.conditionGroupUuid;
    this.copyTooltip = this.$t("Conditions.copySuccess").toString();
  }

  async handlePasteConditionClick() {
    /**
     * Check if the parentId for all widgets associated with the
     * copied condition group share the same parentId as the
     * destination widget.
     */
    const hasSameParent = this.store.conditionGroups
      .find((cg) => cg.uuid === this.store.copiedConditionGroupUuid)
      ?.widgets.map((w) => this.widgets[w.widgetId]?.parentId)
      .every((parentId) => {
        if (typeof parentId === "undefined") {
          // It's possible we have some orphaned widgetReferences in the
          // condition group, so if parentId is undefined, it's ok
          return true;
        }
        return parentId === this.selectedWidget?.parentId;
      });

    if (!hasSameParent) {
      // TODO: Use a better toast message instead.
      alert(
        "Can't paste conditions from widgets with different editing contexts"
      );
      return;
    }

    EventBus.emit("AWAITING_SERVER", true);
    this.conditionsErrorMessage = "";

    try {
      if (this.selectedWidget === undefined) {
        const message =
          "ConditionsEditor.handlePasteConditionClick: No selected widget";
        logger.track(message);
        throw new Error(message);
      }

      await this.store.copyConditionGroup({
        appUuid: this.$route.params.id,
        widgetId: this.selectedWidget?.wid,
      });

      await this.refresh();

      this.initWidgetConditionalVersions();
    } catch (err) {
      // console.log("Error pasting condition", err);
      this.conditionsErrorMessage = extractErrorMessage(
        err as string,
        this.$t("Conditions.errorPasting").toString()
      );
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
    }
  }

  handleAddConditionClick() {
    if (this.selectedWidget === undefined) {
      const message =
        "ConditionsEditor.handleAddConditionClick: No selected widget";
      logger.track(message);
      throw new Error(message);
    }

    const queryParams: Record<string, string> = {
      widgetId: this.selectedWidget?.wid,
    };

    if (this.conditionGroupUuid) {
      queryParams.conditionGroupUuid = this.conditionGroupUuid;
    }

    // If no group exists, create a new group and add a condition to it.
    this.$router.push({
      name: "condition-edit",
      params: {
        id: this.$route.params.id,
        conditionUuid: "new",
      },
      query: queryParams,
    });
  }

  updateSortOrder(items: ConditionListItem[]) {
    this.store.updateSortOrder(
      this.$route.params.id,
      this.conditionGroupUuid,
      items.map((c) => c.uuid)
    );
  }
}
</script>

<style lang="postcss" scoped>
.outline-button,
.add-condition-btn {
  @apply w-full flex items-center justify-center border border-gray-500 space-x-2 py-3 rounded-md cursor-pointer hover:bg-gray-500/10;
}

.footer-btn {
  @apply text-xs py-2 px-3 rounded cursor-pointer hover:bg-gray-100 hover:shadow flex justify-center items-center space-x-1;
}
</style>
