export interface ShadowOptions {
  shadowX: number;
  shadowY: number;
  shadowBlur: number;
  shadowColor: string;
  shadowDisplay: boolean;
}

export const DefaultShadowOptions = {
  shadowX: 0,
  shadowY: 0,
  shadowBlur: 0,
  shadowColor: "black",
  shadowDisplay: false,
};

export const getShadowCss = (options: ShadowOptions) => {
  if (!options.shadowDisplay) return {};
  return {
    filter: `drop-shadow(${options.shadowX}px ${options.shadowY}px ${options.shadowBlur}px ${options.shadowColor})`,
  };
};
