<template>
  <div class="p-4">
    <button
      @click="handleClick"
      class="button flex flex-col relative w-full py-2 px-3 h-10 rounded cursor-pointer bg-gray-100 shadow flex justify-center items-center"
    >
      <div class="flex items-center justify-center">
        <Icon class="h-5 w-5" :name="previewIconName" />
        <div class="whitespace-nowrap ml-1">{{ previewButtonText }}</div>
      </div>
      <div
        id="animation-progress-bar"
        v-show="animationPlaying"
        class="w-full h-1.5 bg-gray-500 absolute bottom-0 left-0 overflow-hidden"
      ></div>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: { Icon },
})
export default class AnimationPreviewButton extends Vue {
  @Prop(Number) readonly duration: number;

  get appEditor() {
    return useAppEditorStore();
  }

  get animationPlaying() {
    return this.appEditor.animationPlaying;
  }

  get previewButtonText() {
    return this.animationPlaying
      ? this.$t("animation.stopPreview")
      : this.$t("animation.preview");
  }

  get previewIconName() {
    return this.animationPlaying ? "Cancel" : "Play";
  }

  handleClick() {
    if (this.animationPlaying) {
      this.appEditor.animationPlaying = false;
    } else {
      this.appEditor.animationPlaying = true;
    }
  }
}
</script>

<style lang="postcss" scoped>
.button {
  @apply py-2 px-3 h-10 rounded cursor-pointer bg-gray-100 shadow flex justify-center items-center;
}
</style>
