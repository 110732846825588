import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BlurOptions,
  DefaultBlurOptions,
} from "@/components/widgets/BlurOptions";
import {
  ShadowOptions,
  DefaultShadowOptions,
} from "@/components/widgets/ShadowOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { DEFAULT_LAYER_NAMES } from "@/constants";
export interface EllipseOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    ShadowOptions,
    BlurOptions,
    AnimationOptions,
    BackgroundOptions {}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultShadowOptions,
  ...DefaultBlurOptions,
  ...DefaultBackgroundOptions,
  ...getDefaultAnimationOptions(),
};

const CreateEllipse = (options: Partial<EllipseOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "Ellipse",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.Ellipse,
  });
};

export default CreateEllipse;
