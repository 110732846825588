<template>
  <ShapeBase
    v-bind="$props"
    :gradientParts="gradientParts"
    :borderGradientParts="borderGradientParts"
    dataType="Ellipse"
  >
    <defs>
      <mask :id="`${wid}-${componentId}-ellipse`">
        <ellipse cx="50%" cy="50%" rx="100%" ry="100%" fill="white" />
        <ellipse cx="50%" cy="50%" :rx="rx" :ry="ry" fill="black" />
      </mask>
    </defs>
    <ellipse
      cx="50%"
      cy="50%"
      :rx="rx"
      :ry="ry"
      fill="none"
      :stroke="border"
      :stroke-width="borderWidth"
      :stroke-dasharray="dashArray"
      :mask="$helpers.svgUrl(`#${wid}-${componentId}-ellipse`)"
    />
    <ellipse cx="50%" cy="50%" :rx="rx" :ry="ry" :fill="fill" />
  </ShapeBase>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { makeId, parseGradientCss } from "@/utils";
import ShapeBase from "@/components/ShapeBase.vue";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";

// NOTE: if expose Gaussian blur, need to lower the radius

@Component({
  components: {
    ShapeBase,
  },
})
export default class EllipseComponent extends AnimatableWidget {
  @Prop(String) readonly color: string;
  @Prop(String) readonly backgroundColor: string;
  @Prop(String) readonly backgroundImageUrl: string;
  @Prop(String) readonly backgroundSize: string;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  // TODO: Wrap up in an object? Same for bgImage
  @Prop(Number) readonly shadowX: number;
  @Prop(Number) readonly shadowY: number;
  @Prop(Number) readonly shadowBlur: number;
  @Prop(String) readonly shadowColor: string;
  @Prop(Boolean) readonly shadowDisplay: boolean;

  @Prop(Number) readonly blurValue: number;
  @Prop(Boolean) readonly blurDisplay: boolean;

  @Prop(Number) readonly borderWidth: number;
  @Prop(String) readonly borderColor: string;
  @Prop(Number) readonly borderDashSize: number;
  @Prop(Number) readonly borderGapSize: number;

  @Prop(String) readonly dropShadow: string;

  @Prop({ default: makeId }) componentId: string;

  get borderDashed() {
    return this.borderDashSize > 0;
  }

  get dashArray() {
    if (this.borderDashed) {
      return `${this.borderDashSize} ${this.borderGapSize}`;
    } else {
      return "";
    }
  }

  get gradientParts() {
    return parseGradientCss(this.backgroundColor);
  }

  get borderGradientParts() {
    return parseGradientCss(this.borderColor);
  }

  get fill() {
    if (this.backgroundImageUrl) {
      return this.$helpers.svgUrl(`#${this.componentId}backgroundImageUrl`);
    }
    if (this.gradientParts.type === "Solid") {
      return this.backgroundColor;
    }
    return this.$helpers.svgUrl(`#${this.gradientParts.type}-grad-${this.wid}`);
  }

  get border() {
    if (this.borderGradientParts.type === "Solid") {
      return this.borderColor;
    }

    return this.$helpers.svgUrl(
      `#${this.borderGradientParts.type}-grad-border-${this.wid}`
    );
  }

  // Cut off bug.....huh.....
  get rx() {
    const w = this.w * this.scaleX;
    const borderPercent = (100 * (w - this.borderWidth / 2)) / w;
    const borderRemove = 100 - borderPercent;
    let r = 50 - borderRemove;
    if (this.blurDisplay) r /= 2;
    return `${r}%`;
  }

  get ry() {
    const h = this.h * this.scaleY;
    const borderPercent = (100 * (h - this.borderWidth / 2)) / h;
    const borderRemove = 100 - borderPercent;
    let r = 50 - borderRemove;
    if (this.blurDisplay) r /= 2;

    // const r = 50 - (100 * this.borderWidth) / 2 / this.h;
    return `${r}%`;
  }
}
</script>
