<template>
  <Modal :size="size" @close="onReject">
    <div class="p-6 pb-0 text-lg" v-if="title">
      {{ title }}
    </div>
    <div class="p-6">
      <slot />
    </div>
    <div class="p-6 pt-0 flex justify-start space-x-6">
      <ButtonGradient
        class="dark-form-focus"
        :disabled="isAcceptBtnDisabled"
        @click="onAccept()"
        ref="primaryButton"
      >
        <span v-t="acceptBtnText"></span>
      </ButtonGradient>

      <OutlineButton @click="onReject()">
        <span v-t="rejectBtnText"></span>
      </OutlineButton>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import OutlineButton from "@/components/OutlineButton.vue";

@Component({
  components: {
    Modal,
    ButtonGradient,
    OutlineButton,
  },
})
export default class ActionModal extends Vue {
  @Prop({ type: String, default: "" }) title: string;
  @Prop({ type: String, default: "continue" }) acceptBtnText: string;
  @Prop({ type: String, default: "cancel" }) rejectBtnText: string;
  @Prop({ type: Boolean, default: false }) isAcceptBtnDisabled: boolean;
  @Prop({ type: String, default: "" }) focusSelector: string;
  @Prop({ type: String, default: "1/3" }) size: string;

  @Emit()
  onAccept() {}

  @Emit()
  onReject() {}

  mounted() {
    this.$nextTick(() => {
      if (this.focusSelector) {
        const el = this.$el.querySelector(this.focusSelector) as HTMLElement;
        el.focus();
      } else {
        (this.$refs.primaryButton as any)?.focusInput(); // handled by ButtonGradient
      }
    });
  }
}
</script>
