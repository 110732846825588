<template>
  <div class="relative" :class="width">
    <div @click="onClick">
      <slot name="selector" />
    </div>
    <div ref="arrow" class="absolute" :class="arrowClasses"></div>
    <div
      ref="text"
      class="absolute rounded text-xs font-bold text-white p-3 leading-6 z-50 break-all"
      :class="contentClasses"
    >
      <p class="w-full justify-center flex py-3 text-lg">{{ text }}</p>
      <div class="flex justify-between w-full whitespace-nowrap">
        <ButtonGradient
          @click="onConfirmInner()"
          class="text-sm opacity-80"
          :customGradient="confirmGradient"
          padding="pt-2 pb-3 px-4"
          hover="opacity-100"
        >
          <span>{{ confirm }}</span>
        </ButtonGradient>
        <ButtonGradient
          @click="onRejectInner()"
          class="text-sm opacity-80 border border-gray-500 text-white ml-2"
          customGradient="linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,0))"
          padding="pt-2 pb-3 px-4"
          hover="opacity-100"
        >
          <span>Cancel</span>
        </ButtonGradient>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";

@Component({
  components: {
    ButtonGradient,
  },
})
export default class PopoverConfirm extends Vue {
  @Prop(String) position: string;
  @Prop({ default: "Are you sure?" }) text: string;
  @Prop({ default: "Yes" }) confirm: string;
  @Prop() confirmGradient: string;
  @Prop({ default: "w-48" }) width: string;
  @Prop({ default: false }) shiftArrow: boolean;

  @Emit()
  onConfirm() {}

  @Emit()
  onReject() {}

  visible = false;

  onClick() {
    this.visible = !this.visible;
  }

  onConfirmInner() {
    this.visible = false;
    this.$emit("onConfirm");
  }

  onRejectInner() {
    this.visible = false;
    this.$emit("onReject");
  }

  get bgColor() {
    return "bg-gray-600";
  }

  get borderColor() {
    return "border-gray-600";
  }

  get pos() {
    switch (this.position) {
      case "t":
      case "r":
      case "b":
      case "l":
        return this.position;
      default:
        return "b";
    }
  }

  get contentClasses() {
    if (!this.visible) {
      return ["invisible"];
    }
    const baseClasses = [this.bgColor, this.width];
    switch (this.pos) {
      case "t":
        return [...baseClasses, "tip-offset-t"];
      case "r":
        return [...baseClasses, "tip-offset-r"];
      case "l":
        return [...baseClasses, "tip-offset-l"];
      default:
        return [...baseClasses, "tip-offset-b"];
    }
  }

  get arrowClasses() {
    if (false || !this.visible) {
      return ["invisible"];
    }
    switch (this.pos) {
      case "t":
        return [this.borderColor, "tip-arrow-t"];
      case "r":
        return [this.borderColor, "tip-arrow-r"];
      case "l":
        return [this.borderColor, "tip-arrow-l"];
      default:
        return [
          this.borderColor,
          "tip-arrow-b",
          this.shiftArrow ? "shift-arrow" : "",
        ];
    }
  }
}
</script>

<style scoped>
.tip-arrow-t {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-bottom-width: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tip-arrow-r {
  left: 100%;
}
.tip-arrow-b {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.2");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.shift-arrow {
  left: 25% !important;
}
.tip-arrow-l {
  right: 100%;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
}

.tip-offset-t {
  bottom: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-r {
  left: calc(100% + theme("spacing.2"));
}
.tip-offset-b {
  top: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.tip-offset-l {
  right: calc(100% + theme("spacing.2"));
}
</style>
