import { BASE_PARENT_ID } from "@/constants";
import { Widget } from "./Widget";
import { useAppEditorStore } from "@/stores/appEditor";
import { AnimatableWidgetTypes } from "./Animation";

export function animationAllowed(widget: Widget | undefined) {
  if (widget === undefined) return;
  const parentType =
    widget.parentId !== BASE_PARENT_ID
      ? useAppEditorStore().widgetById(widget.parentId)?.type
      : null;

  if (AnimatableWidgetTypes.includes(widget.type) && parentType != "Group") {
    return true;
  } else {
    return false;
  }
}
