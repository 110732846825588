<template>
  <button
    @click="click"
    class="hover rounded-md font-bold text-white w-5 h-5"
  ></button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class AnchorButton extends Vue {
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) working: boolean;

  click() {
    if (this.working || this.disabled) {
      return;
    }
    this.$emit("click");
  }
}
</script>
