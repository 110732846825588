<template>
  <Modal
    size="1/2"
    v-if="editableDataStore.showMoveRowModal"
    @close="editableDataStore.showMoveRowModal = false"
  >
    <div class="p-6 px-12 flex items-center justify-between space-x-4">
      <div class="text-lg" v-t="'manualInputs.repositionRowPrompt'"></div>

      <input
        ref="positionInput"
        type="number"
        class="text-white w-48 text-xl text-center bg-transparent border-b border-white"
        :max="editableDataStore.rows.length"
        :min="1"
        :value="newPosition"
        @change="updateNewPosition"
        @keydown.enter="enterKeyHandler"
      />

      <div class="flex space-x-4">
        <ButtonGradient class="px-2 py-1" @click="saveChanges">
          <div v-t="'save'"></div>
        </ButtonGradient>
        <OutlineButton @click="editableDataStore.showMoveRowModal = false">
          <div v-t="'cancel'"></div>
        </OutlineButton>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import { useEditableDataStore } from "@/stores/editableData";

@Component({
  components: {
    Modal,
    ButtonGradient,
    EditorNumberInput,
    OutlineButton,
  },
})
export default class RepositionRowModal extends Vue {
  get editableDataStore() {
    return useEditableDataStore();
  }

  newPosition = 1;

  @Watch("editableDataStore.showMoveRowModal")
  moveRowModalChanged() {
    this.newPosition = 1;
  }

  enterKeyHandler() {
    this.newPosition = parseInt(
      (this.$refs.positionInput as HTMLInputElement).value
    );
    this.saveChanges();
  }

  updateNewPosition(ev: Event) {
    this.newPosition = (ev.target as any).value;
  }

  saveChanges() {
    this.editableDataStore.moveRowToPosition({
      rowUuid: this.editableDataStore.moveRowUuid,
      position: this.newPosition - 1,
    });
    this.editableDataStore.showMoveRowModal = false;
  }
}
</script>
