<template>
  <button
    :class="buttonClasses"
    :style="buttonStyle"
    @click="onClick"
    v-text="buttonText"
  ></button>
</template>

<script lang="ts">
import { AssetFunctionType } from "@/types";
import { UploadAssetOptions, uploadImage } from "@/uploadAssets";
import { Component, Prop, Vue } from "vue-property-decorator";

import { useAppEditorStore } from "@/stores/appEditor";

@Component
export default class ImageUpload extends Vue {
  @Prop(Number) readonly width: number;
  @Prop(Number) readonly height: number;
  @Prop(Number) readonly text: number;
  @Prop(String) readonly imageUrl?: string;
  @Prop(String) readonly assetFunctionType?: AssetFunctionType;

  // isUploading = false;

  get buttonClasses() {
    const result = [
      "relative block rounded flex items-center justify-center",
      "cursor-pointer",
      "bg-center bg-no-repeat bg-contain",
    ];
    return result.join(" ");
  }

  get buttonStyle() {
    return {
      width: this.width + "px",
      height: this.height + "px",
      backgroundImage: `url(${this.imageUrl})`,
    };
  }

  get buttonText() {
    if (this.imageUrl !== undefined) {
      return "";
    }
    return this.$t("ImageUpload.uploadImage");
  }

  get uploadOptions(): UploadAssetOptions {
    if (this.assetFunctionType !== undefined) {
      return {
        meta: {
          appUuid: useAppEditorStore().uuid,
          assetFunctionType: this.assetFunctionType,
        },
      };
    }
    return {};
  }

  onClick() {
    uploadImage(this.uploadOptions)
      .then((result) => {
        if (result) {
          this.$emit("complete", result);
        }
      })
      .catch(() => {
        const message = this.$t("ImageUpload.uploadError");
        window.alert(message);
      });
  }
}
</script>

<style scoped></style>
