<template>
  <button
    style="text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2)"
    :style="btnStyle"
    :disabled="disabled"
    :class="classes"
    @click="$emit('click')"
    @hover="$emit('hover')"
    ref="button"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ButtonGradient extends Vue {
  @Prop({ default: "px-4" }) padding: string;
  @Prop(String) customGradient: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: true }) bold: boolean;
  @Prop() hover: string;
  @Prop({ type: String, default: "md" }) size: "xs" | "sm" | "md" | "lg";

  // TODO: We need to NOT hardcode the background like this, in order for hover to work
  get btnStyle() {
    if (!this.customGradient) return {};
    return {
      background: this.customGradient,
    };
  }

  get classes() {
    let classes = [
      "relative inline-flex items-center justify-start",
      "bg-gradient-to-b from-app-pink to-app-purple",
      "rounded",
      this.sizeClasses,
      this.padding,
    ];
    if (this.disabled) {
      classes.push("cursor-not-allowed opacity-50");
    }
    if (isNonEmptyString(this.hover)) {
      classes.push(this.hover);
    }

    return classes;
  }

  get sizeClasses() {
    switch (this.size) {
      case "xs":
        return "px-2 py-px text-xs";
      case "sm":
        return "px-3 py-1 text-sm";
      case "lg":
        return "px-6 py-3 text-lg";
      default:
        return "px-4 py-1";
    }
  }

  focusInput() {
    (this.$refs.button as HTMLElement).focus();
  }
}
</script>
