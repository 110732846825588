<template>
  <div :class="{ dropShadow: renderBackground }">
    <div
      id="artboard"
      class="absolute top-0 left-0"
      :class="{ 'bg-white': renderBackground }"
      :style="styles"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { useAppEditorStore } from "@/stores/appEditor";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Artboard extends Vue {
  @Prop({ type: Boolean, default: true }) renderBackground: boolean;

  // TODO: Figure out how to select the artboard.. double click?
  // TODO: Artboard should be resizable (and possibly creatable by dragging)

  get artboard() {
    const appEditor = useAppEditorStore();
    return appEditor.artboard;
  }

  get styles() {
    return {
      width: `${this.artboard.w}px`,
      height: `${this.artboard.h}px`,
      transform: `translate(${this.artboard.x}px, ${this.artboard.y}px)`,
      zIndex: -2,
    };
  }
}
</script>

<style scoped>
.dropShadow {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
}

#artboard {
  clip-path: inset(0 -1px -1px 0);
}
</style>
