<template>
  <div>
    <div class="-mt-1 space-y-1">
      <InputLabel v-t="'SelectDateTimeValue.selectValue'"></InputLabel>
      <SelectMenu
        ref="menu"
        :dark="true"
        class="w-full dark-form-focus"
        :value="model.type"
        @input="handleInput($event)"
        :options="options"
      />
    </div>

    <div class="mt-2 space-y-1">
      <InputLabel v-t="'SelectDateTimeValue.valuePreview'"></InputLabel>
      <ValuePreview>{{ valuePreview }}</ValuePreview>
      <InputHelpText v-t="'SelectDateTimeValue.valueHelp'"> </InputHelpText>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";

import { InputOption } from "@/types/inputs";
import { LogicOperand, LogicOperandType } from "@/types/logic";

import { OperandChangedEvent } from "./OperandChangedEvent";

import SelectMenu from "@/components/SelectMenu.vue";
import InputHelpText from "./InputHelpText.vue";
import InputLabel from "./InputLabel.vue";
import ValuePreview from "./ValuePreview.vue";

@Component({
  components: { SelectMenu, InputHelpText, InputLabel, ValuePreview },
})
export default class SelectDateTimeValue extends Vue {
  @Prop(Object) model: LogicOperand;

  timer = 0;
  now = DateTime.now();

  created() {
    this.timer = window.setInterval(() => {
      this.now = DateTime.now();
    }, 500);
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.menu as SelectMenu)?.focus();
    });
  }

  beforeDestroy() {
    window.clearInterval(this.timer);
  }

  get options() {
    return [
      { label: this.$t("DateTimeLogic.CurrentTime"), value: "CurrentTime" },
      { label: this.$t("DateTimeLogic.CurrentDate"), value: "CurrentDate" },
      {
        label: this.$t("DateTimeLogic.CurrentDateTime"),
        value: "CurrentDateTime",
      },
      {
        label: this.$t("DateTimeLogic.CurrentDayOfWeek"),
        value: "CurrentDayOfWeek",
      },
      {
        label: this.$t("DateTimeLogic.CurrentDayOfMonth"),
        value: "CurrentDayOfMonth",
      },
      {
        label: this.$t("DateTimeLogic.CurrentDayOfYear"),
        value: "CurrentDayOfYear",
      },
      { label: this.$t("DateTimeLogic.CurrentMonth"), value: "CurrentMonth" },
      { label: this.$t("DateTimeLogic.CurrentYear"), value: "CurrentYear" },
    ] as InputOption[];
  }

  get valuePreview() {
    switch (this.model.type) {
      case "CurrentDate":
        return this.now.toLocaleString();
      case "CurrentTime":
        return this.now.toLocaleString(DateTime.TIME_SIMPLE);
      case "CurrentDateTime":
        return this.now.toLocaleString(DateTime.DATETIME_SHORT);
      case "CurrentDayOfWeek":
        return this.now.weekdayLong;
      case "CurrentDayOfMonth":
        return this.now.day;
      case "CurrentDayOfYear":
        return this.now.ordinal;
      case "CurrentMonth":
        return this.now.monthLong;
      case "CurrentYear":
        return this.now.year;
    }
    return "";
  }

  handleInput(type: LogicOperandType) {
    const payload: OperandChangedEvent = { operand: { type: type } };
    this.$emit("input", payload);
  }
}
</script>
