/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineStore } from "pinia";

import { AppState, makeInitialAppState } from "./AppState";
import {
  Point,
  Rectangle,
  ResizeDimension,
  SavedAsset,
  Size,
  ResolvedWidgetData,
  NO_UNDO,
  ContentMenuName,
} from "@/types";
import {
  Widget,
  WidgetProperties,
  WidgetWithConditions,
} from "@/components/widgets/Widget";
import {
  AppInfo,
  AppPayload,
  DataBinding,
  DataBindingProperty,
  DataBindingType,
  DataConnection,
  Feed,
  FeedDeliveryMethod,
  PublishMetadata,
  SavedAppInfo,
  SchemaType,
} from "@/types/data";
import { useConditionGroupsStore } from "./conditionGroups";
import {
  addConditionalVersions,
  getActiveConditionId,
  getActiveWidget,
  isConditionalBindingType,
} from "@/util/conditionsUtils";
import { BASE_PARENT_ID, DEFAULT_CONDITION_ID } from "@/constants";
import {
  addVectors,
  dimsToUpdateApparentHeight,
  getAngledBoundingBox,
  getApparentDims,
  getApparentDimsInvert,
  getBoundingBox,
  makeId,
  purgeNullValues,
  removeDuplicateStrings,
  removeReactivity,
  rotateVector,
  scaleVector,
  scaledCoordsInvert,
  subtractVectors,
} from "@/utils";
import {
  CreateGroup,
  GroupOptions,
} from "@/components/widgets/Group/GroupOptions";
import { appSchemaVersion, applyModelMigrations } from "@/migrations";
import { api } from "@/api/backend";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { EventBus } from "@/eventbus";
import CreateRepeater, {
  createRepeaterDimensions,
  RepeaterOptions,
} from "@/components/widgets/Repeater/RepeaterOptions";
import Vue from "vue";
import { TransformOptions } from "@/components/widgets/TransformOptions";
import { makeDynamicTextContent, TextContent } from "@/text";
import CreateText, { TextOptions } from "@/components/widgets/Text/TextOptions";
import { measureText } from "@/textfit";
import { isTextContentEmpty, updateTokenBindings } from "@/text/binding";
import { getWidgetPropertyDefault } from "@/components/widgets/getWidgetPropertyDefault";
import CreateImage from "@/components/widgets/Image/ImageOptions";
import uniqWith from "lodash.uniqwith";
import { widgetData } from "./widgetData";

import { gatherAssets } from "./gatherAssets";
import { FontAssetInfo, ImageAssetInfo } from "@/types/bundleTypes";
import { useAppDataStore } from "./appData";
import { useConnectionsStore } from "./connections";
import { useConnectionDataStore } from "./connectionData";
import { applyDynamicImages } from "./dynamicImages";
import CreateDatetime from "@/components/widgets/Datetime/DatetimeOptions";
import { DraggingInfo } from "./dragDrop";
import CreateVideo from "@/components/widgets/Video/VideoOptions";
import { useMainAppStore } from "./mainApp";

/**
 * Thanks to https://medium.com/@karenmarkosyan/how-to-manage-promises-into-dynamic-queue-with-vanilla-javascript-9d0d1f8d4df5
 *
 * This allows to us to manage calls to updateApp, which can come from 3 sources:
 * - user clicks "Save button"
 * - auto-save fires
 * - some "app-triggered" save event fires, such as pasteWidgetsClick, or remapCollection
 *
 * We want to ensure that only one call to updateApp is made at a time. So if a call is in progress, we should not initiate another.
 * We should wait until that first call is processed before firing the next one, to avoid triggering 409 errors on the back-end.
 * That is what this queue accomplishes! :)
 */
class Queue {
  static queue: any[] = [];
  static workingOnPromise = false;

  static enqueue(promise: () => Promise<any>) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        promise,
        resolve,
        reject,
      });
      this.dequeue();
    });
  }

  static dequeue() {
    if (this.workingOnPromise) {
      return false;
    }
    const item = this.queue.shift();
    if (!item) {
      return false;
    }
    try {
      this.workingOnPromise = true;
      item
        .promise()
        .then((value: any) => {
          this.workingOnPromise = false;
          item.resolve(value);
          setTimeout(() => this.dequeue(), 0);
        })
        .catch((err: any) => {
          this.workingOnPromise = false;
          item.reject(err);
          setTimeout(() => this.dequeue(), 0);
        });
    } catch (err) {
      this.workingOnPromise = false;
      item.reject(err);
      setTimeout(() => this.dequeue(), 0);
    }
    return true;
  }
}

// const updatePromiseQueue = new Queue();

/**
 * Remove any data bindings that are no longer associated with a widget.
 */
const pruneUnusedDataBindings = (app: AppPayload) => {
  const bindings = app.dataBindings;
  const widgets = app.model.widgets;

  const filteredBindings = bindings.filter((b) => {
    const hasParentId =
      b.parentWidgetId && b.parentWidgetId !== BASE_PARENT_ID
        ? b.parentWidgetId in widgets
        : true;
    return hasParentId && b.widgetId in widgets;
  });

  app.dataBindings = filteredBindings;
};

const rulerSize = 20;
export const initialArtboardPosition: Point = {
  x: rulerSize * 2,
  y: rulerSize * 2,
};

const getMaxZ = (widgets: Widget[]) => {
  const values = widgets.map((wg) => wg.z).concat([0]);
  return Math.max(...values);
};

const getMaxZByParentId = (state: EditorState, parentId?: string) => {
  parentId = parentId || state.editingContext.parentId;

  const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
  const values = Object.values(state.widgets)
    .map((wg) => getActiveWidget(wg, conditions))
    .filter((wg) => wg.parentId === parentId)
    .map((wg) => wg.z)
    .concat([0]);

  return Math.max(...values);
};

/**
 * Returns a mutable reference to a widget's 'active' props without
 * requiring you to specify the activeConditionId.
 *
 * DOES NOT RETURN top level props `wid`, `parentId`, `type` or `locked`.
 */
const getMutableWidgetProps = (
  widget: WidgetWithConditions
): WidgetProperties => {
  const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
  const cid = getActiveConditionId(widget?.wid, conditions);

  // Return a reference to this object, so it can be mutated
  return widget?.conditionalVersions?.[cid] as WidgetProperties;
};

// Used to position children on the canvas after ungrouped:
const getChildAbsolutePosition = (group: Widget, child: Widget): Point => {
  const { x, y, w, h, angle } = group as Widget;

  if (angle === 0) {
    // If not rotated, we simply add the parent x,y to the child x,y
    return { x: child.x + group.x, y: child.y + group.y };
  } else {
    // must account for group's rotation:
    const groupCenter = { x: x + w / 2, y: y + h / 2 };
    const groupPosRelCenter = { x: -w / 2, y: -h / 2 };
    const radians = (angle * Math.PI) / 180;
    const rotatedGroupPosRelCtr = rotateVector(groupPosRelCenter, radians);
    const rotatedGroupPosAbsolute = addVectors(
      groupCenter,
      rotatedGroupPosRelCtr
    );
    const wVec = rotateVector({ x: w, y: 0 }, radians);
    const hVec = rotateVector({ x: 0, y: h }, radians);

    let wgAbsolutePosition = addVectors(
      rotatedGroupPosAbsolute,
      scaleVector(wVec, child.x / w)
    );
    wgAbsolutePosition = addVectors(
      wgAbsolutePosition,
      scaleVector(hVec, child.y / h)
    );

    // now must find wVec and hVec for widget (child) itself, to  find wg center.
    const wgWVec = rotateVector({ x: child.w, y: 0 }, radians);
    const wgHVec = rotateVector({ x: 0, y: child.h }, radians);

    let wgCtr = addVectors(wgAbsolutePosition, scaleVector(wgWVec, 0.5));
    wgCtr = addVectors(wgCtr, scaleVector(wgHVec, 0.5));

    const rotatedWgPosRelCtr = addVectors(
      scaleVector(wgWVec, -0.5),
      scaleVector(wgHVec, -0.5)
    );
    const ogWgPosRelCtr = rotateVector(rotatedWgPosRelCtr, -radians);
    const ogWgPosAbsolute = addVectors(wgCtr, ogWgPosRelCtr);
    return ogWgPosAbsolute;
  }
};

export const recalculateGroupPosition = (
  group: TransformOptions,
  offset: Point
): Point => {
  const { x, y, w, h, angle } = group;
  const radians = (Math.PI * angle) / 180;
  const ogCtr = { x: x + w / 2, y: y + h / 2 };
  const newGrpPreRotate = addVectors(group, offset);
  const newGrpRelOgCtr = subtractVectors(newGrpPreRotate, ogCtr);
  const newGrpRotatedRelCtr = rotateVector(newGrpRelOgCtr, radians);
  const newGrpRotated = addVectors(ogCtr, newGrpRotatedRelCtr);

  const wVec = rotateVector({ x: w - offset.x, y: 0 }, radians);
  const hVec = rotateVector({ x: 0, y: h - offset.y }, radians);

  let newGrpCtr = addVectors(newGrpRotated, scaleVector(wVec, 0.5));
  newGrpCtr = addVectors(newGrpCtr, scaleVector(hVec, 0.5));
  const newGrpRotatedPosRelCtr = addVectors(
    scaleVector(wVec, -0.5),
    scaleVector(hVec, -0.5)
  );
  const newGrpPosRelCtr = rotateVector(newGrpRotatedPosRelCtr, -radians);
  const newGrpPos = addVectors(newGrpCtr, newGrpPosRelCtr);

  return newGrpPos;
};

// TODO: Does this need to be a standalone method? Can it be an action?
const addWidget = (
  state: EditorState,
  widget: Widget | WidgetWithConditions
) => {
  addConditionalVersions(widget as Widget);

  Vue.set(state.widgets, widget.wid, widget);

  state.hasUnsavedChanges = true;

  // Add parent reference
  if (widget.parentId !== BASE_PARENT_ID) {
    Vue.set(
      state.parents,
      widget.parentId,
      (state.parents[widget.parentId] || []).concat([widget.wid])
    );
  }
};

export type CreateDataTokenOptions = {
  widgetId: string;
  conditionId: string;
  connectionId: string;
  parentWidgetId: string;
  isScalar: boolean;
  content: TextContent;
  dataUuid: string;

  query?: any;
  textTokenUuid?: string;

  shouldCreateRepeaterBinding?: boolean;
  dataParentUuid?: string;
};

export declare type SelectedProps = {
  [property: string]: string[] | number[] | boolean[];
};

export interface HoveredTextInfo {
  wid: string;
}

export interface BindingReplacement {
  oldBinding: DataBinding;

  /**
   * If the new binding is undefined, the old binding will be removed rather than replaced.
   */
  newBinding: DataBinding | undefined;
}

export interface RemapCollectionPayload {
  replacements: BindingReplacement[];
  newConnection: DataConnection;
}

export interface BindingsSearchOptions {
  widgetId?: string;
  bindingType?: DataBindingType;
  schemaType?: SchemaType;
  dataConnectionUuid?: string;
  dataParentUuid?: string;
  property?: string;
  conditionUuid?: string;
}

export interface EditingContext {
  parentId: string;
  widgetX: number;
  widgetY: number;
  offsetX: number;
  offsetY: number;
  width?: number;
  height?: number;
  repeaterIndex?: number;
}

const defaultEditingContext = (): EditingContext => {
  return {
    parentId: BASE_PARENT_ID,
    widgetX: 0,
    widgetY: 0,
    offsetX: 0,
    offsetY: 0,
  };
};

export interface EditorState extends AppState {
  selections: string[];
  hoveredId: string;
  rulerSize: number;
  savedAppInfo: SavedAppInfo;
  artboardPosition: Point;
  scale: number;
  scaleStep: number;
  clipboard: WidgetWithConditions[];
  canvasBox: Rectangle;
  editingContext: EditingContext;
  textEditingWidget: string | null;
  isLoaded: boolean;
  hasUnsavedChanges: boolean;
  modifiedAt: string | null;
  awaitingServer: boolean;

  // TODO: Move to wherever connections live
  cachedConnections: DataConnection[];

  activeContentMenu: ContentMenuName | null;
  animationPlaying: boolean;
  clickFromEditorPanel: boolean;
  isDraggingWidget: boolean;

  /**
   * If a widget is being resized using resize handles, this will
   * store the dimension (x, y or x & y) of the resize action.
   *
   * If a widget is not being resized, this will be null.
   */
  dragResizeDimension: ResizeDimension | null;

  cyclingIndexes: Record<string, number>;
}

export const makeInitialEditorState = (): EditorState => {
  const appState = makeInitialAppState();
  appState.appMode = "edit";
  return {
    ...appState,
    appSchemaVersion: 1,
    savedAppInfo: {} as SavedAppInfo,
    selections: [],
    hoveredId: "",
    clipboard: [],
    rulerSize: rulerSize,
    artboardPosition: { ...initialArtboardPosition },
    scale: 1,
    scaleStep: 0.1,
    canvasBox: { x: 0, y: 0, w: 0, h: 0 },
    editingContext: defaultEditingContext(),
    textEditingWidget: null,
    isLoaded: false,
    hasUnsavedChanges: false,
    modifiedAt: null,
    awaitingServer: false,
    cachedConnections: [],

    dataBindings: [],

    activeContentMenu: null,
    animationPlaying: false,
    clickFromEditorPanel: false,
    isDraggingWidget: false,
    dragResizeDimension: null,

    cyclingIndexes: {},
  };
};

export const useAppEditorStore = defineStore("appEditor", {
  state: (): EditorState => ({
    ...makeInitialEditorState(),
  }),

  getters: {
    widgetData(): ResolvedWidgetData {
      const appData = useAppDataStore();
      /**
       * Because renderer and editor have different ways
       * for determining the active condition for a widget,
       * we'll inject a function so the `widgetData` function
       * doesn't need to do so many conditionals based on appMode.
       */
      const result = widgetData(
        this.appMode,
        this.widgets,
        this.assets,
        this.dataBindings,
        appData.data,
        useConditionGroupsStore().activeWidgetConditionsMap
      );

      /**
       * This will scan through the widgetData and insert any
       * temporary, dynamic "hover" images that are
       * being dragged into the app.
       */
      return applyDynamicImages(
        this.widgets,
        this.dataBindings,
        appData.data,
        result
      );
    },

    renderableWidgets(): Widget[] {
      /**
       * Must spread out *all* dynamic options in getters.wigetData[wg.wid],
       * to account for case where a widget has multiple properties powered dynamically.
       */
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      return (
        Object.values(this.widgets)
          .filter((wg) => wg.parentId === BASE_PARENT_ID)
          .map((wg) => getActiveWidget(wg, conditions))
          // .map((wg) => Object.assign({}, wg, getters.widgetData[wg.wid][0]));
          .map((wg) => {
            return {
              ...wg,
              ...this.widgetData[wg.wid].reduce((acc: object, val: object) => {
                return { ...acc, ...val };
              }, {}),
            };
          })
      );
    },
    editableWidgets(): Widget[] {
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      return Object.values(this.widgets)
        .filter((wg) => wg.parentId === this.editingContext.parentId)
        .map((wg) => getActiveWidget(wg, conditions))
        .map((wg) => Object.assign({}, wg, this.widgetData[wg.wid][0]));
    },

    artboard(): Rectangle {
      return {
        w: this.width,
        h: this.height,
        ...this.artboardPosition,
      };
    },

    customFonts(): string[] {
      return this.custom.fonts || [];
    },

    selectionCount(): number {
      return this.selections.length;
    },
    selectedProps(): SelectedProps {
      if (this.selections.length === 0) {
        return {};
      }

      return this.selections.reduce((result: any, wid: string) => {
        const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
        const widget = getActiveWidget(this.widgets[wid], conditions);

        if (!widget) {
          return result;
        }

        for (const prop in widget) {
          const propValue = widget[prop as keyof Widget];
          if (typeof propValue !== "undefined") {
            if (prop in result) {
              const valuesArray = result[prop] as any[];
              if (!valuesArray.includes(propValue)) {
                valuesArray.push(propValue);
              }
            } else {
              result[prop] = [propValue];
            }
          }
        }

        return result;
      }, {});
    },
    selectedTypes(): string[] {
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      return this.selections.reduce((result: any, wid: string) => {
        const widget = getActiveWidget(this.widgets[wid], conditions);
        if (widget && !result.includes(widget.type)) {
          result.push(widget.type);
        }
        return result;
      }, []);
    },
    selectedWidget(): Widget | undefined {
      if (this.selections.length === 1) {
        const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
        return getActiveWidget(this.widgets[this.selections[0]], conditions);
      }
    },

    selectedWidgetBinding(): DataBinding | undefined {
      return this.dataBindings.find(
        (b: DataBinding) => b.widgetId === this.selectedWidget?.wid
      );
    },

    /**
     * Returns the Point (x,y) of the active editing context relative to canvas origin
     */
    origin(): Point {
      let offsetX = this.editingContext.offsetX;
      let offsetY = this.editingContext.offsetY;
      if (!this.isBaseEditingContext) {
        const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
        const wg = getActiveWidget(
          this.widgets[this.editingContext.parentId],
          conditions
        );
        if (wg) {
          const { x, y } = getApparentDims(wg);
          offsetX += x;
          offsetY += y;
        }
      }
      return {
        x: this.artboardPosition.x + offsetX,
        y: this.artboardPosition.y + offsetY,
      };
    },
    editContextOrigin(): Point {
      if (!this.isBaseEditingContext) {
        const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
        const wg = getActiveWidget(
          this.widgets[this.editingContext.parentId],
          conditions
        );
        if (wg) {
          const { x, y } = getApparentDims(wg);
          const offsetX = this.editingContext.offsetX;
          const offsetY = this.editingContext.offsetY;
          return {
            x: this.artboardPosition.x + x + offsetX,
            y: this.artboardPosition.y + y + offsetY,
          };
        }
      }
      return this.artboardPosition;
    },
    isBaseEditingContext(): boolean {
      return this.editingContext.parentId === BASE_PARENT_ID;
    },
    assetsInUse(): {
      fonts: FontAssetInfo[];
      images: ImageAssetInfo[];
    } {
      const conditionGroupsStore = useConditionGroupsStore();
      const conditions = conditionGroupsStore.activeWidgetConditionsMap;
      return gatherAssets(
        this,
        this.renderableWidgets,
        this.widgetData,
        conditions
      );
    },

    // DATA BINDING GETTERS ----------------------------------

    uniquConnectionFromBindingsCount(): number {
      return this.dataBindings
        .map((b) => b.dataConnectionUuid)
        .filter((value, index, self) => self.indexOf(value) === index).length;
    },
  },

  actions: {
    // Adding a simple action for this, since we may want to make it undo-able
    setCyclingIndex(payload: { widgetId: string; value: number }): void {
      const { widgetId, value } = payload;
      Vue.set(this.cyclingIndexes, widgetId, value);
    },
    /**
     * Translates a rectangle from the scaled "render" context to the
     * unscaled "editor" context.
     *
     * It takes artboard position and canvas scale into account.
     *
     * This is useful for drawing boxes in the "editor" layer which will
     * correspond to objects in the "render layer" (which is scaled via CSS).
     *
     * This does not account for chilren of a rotated repeater, so we disallow rotated repeaters
     *
     */
    scaleForEditLayer(box: Rectangle): Rectangle {
      const { canvasBox, scale } = this;
      const canvasCenter = {
        x: canvasBox.x + canvasBox.w / 2,
        y: canvasBox.y + canvasBox.h / 2,
      };

      const editContext = this.editContextOrigin;
      const editContextRelCtr = subtractVectors(editContext, canvasCenter);
      const scaledVec = scaleVector(editContextRelCtr, scale);
      const editContextPos = addVectors(canvasCenter, scaledVec);

      // what?! why scale - 1??
      const boxX = box.x * scale + editContextPos.x + (scale - 1) * canvasBox.x;
      const boxY = box.y * scale + editContextPos.y + (scale - 1) * canvasBox.y;

      return {
        x: boxX,
        y: boxY,
        w: box.w * scale,
        h: box.h * scale,
      };
    },

    getChildren(parentWidgetId: string) {
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      return (this.parents[parentWidgetId] ?? []).map((childWidgetId) => {
        return Object.assign(
          {},
          getActiveWidget(this.widgets[childWidgetId], conditions),
          this.widgetData[childWidgetId]?.[0] ?? {}
        );
      });
    },

    widgetById(wid: string): Widget | undefined {
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      return getActiveWidget(this.widgets[wid], conditions);
    },

    getArtboardCoordinates(client: Point, offset: Point): Point {
      const offsetX = this.editingContext?.offsetX ?? 0;
      const offsetY = this.editingContext?.offsetY ?? 0;
      const widgetX = this.editingContext?.widgetX ?? 0;
      const widgetY = this.editingContext?.widgetY ?? 0;

      const p2 = subtractVectors(client, this.canvasBox);
      const coords = scaledCoordsInvert(p2, this.canvasBox, this.scale);
      const coordsRelArtboard = subtractVectors(coords, this.artboard);
      const x = coordsRelArtboard.x - offset.x / this.scale - offsetX - widgetX;
      const y = coordsRelArtboard.y - offset.y / this.scale - offsetY - widgetY;
      return { x, y };
    },

    // Calculate the y value of each child in a vertically dynamic group (as it will be rendered):
    verticallyDynamicChild(payload: { parentWid: string; childWid: string }) {
      const { parentWid, childWid } = payload;
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      const { verticalMargin, verticalDynamism, orderedChildIds } =
        getActiveWidget(this.widgets[parentWid], conditions) as GroupOptions;
      if (!verticalDynamism) return undefined;
      let y = 0;
      const children = this.parents[parentWid].map((wid) => this.widgets[wid]);
      const result: Widget[] = [];
      children
        .slice(0)
        .sort((a, b) => {
          if (!orderedChildIds) return 1;
          return (
            orderedChildIds.indexOf(a.wid) - orderedChildIds.indexOf(b.wid)
          );
        })
        .map((wg) => getActiveWidget(wg, conditions))
        .forEach((child) => {
          result.push({ ...child, y });
          y += child.h * child.scaleY;
          y += verticalMargin;
        });
      return result.find((wg) => wg.wid === childWid);
    },

    // TODO: Revisit these "save" and "preserve" lists...
    // This is used to clear the state (but preserve certain info, and reset the widgets to saved state) when undo is called:
    emptyState() {
      const clearThroughUndo = ["widgets", "parents", "name"];

      // https://github.com/vuejs/vuex/issues/1118
      const s = makeInitialEditorState();

      // const conditionsStore = useConditionGroupsStore();

      clearThroughUndo.forEach((key) => ((this as any)[key] = (s as any)[key]));
      this.dataBindings = [];

      // seems to fix weird bug:
      if (this.savedAppInfo === undefined) {
        this.savedAppInfo = {} as SavedAppInfo;
      }
      // Initialize the state correctly, given the saved state of the current app:
      // console.log("empty state", state.savedAppInfo);
      this.widgets = removeReactivity(this.savedAppInfo.widgets);
      this.parents = removeReactivity(this.savedAppInfo.parents);
      this.name = removeReactivity(this.savedAppInfo.name);
      this.dataBindings = removeReactivity(this.savedAppInfo.dataBindings);

      // (this as any).data.data = removeReactivity(this.savedAppInfo.data);

      // console.log("emptied state...", this.widgets, this.savedAppInfo);
    },

    resetEditorState() {
      // TODO: determine whether Pinia's built-in $reset method is the
      // same as using makeInitialEditorState();
      this.$reset();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // state = makeInitialEditorState();
    },

    initStateWith(payload: AppPayload) {
      this.hasUnsavedChanges = false;
      Object.keys(payload).forEach((key) => {
        if (key === "dataBindings") {
          // DataBindings are handled in loadApp()
        } else if (key === "model") {
          this.widgets = payload.model.widgets as Record<
            string,
            WidgetWithConditions
          >;
          this.parents = payload.model.parents;
          this.custom = payload.model.custom;
          this.appSchemaVersion = payload.model.appSchemaVersion;
          this.selections = [];
        } else {
          (this as any)[key] = (payload as any)[key];
        }
      });
    },

    setChecksum(payload: { checksum: number; modifiedAt: string }) {
      this.checksum = payload.checksum;
      this.modifiedAt = payload.modifiedAt;
    },

    setHasUnsavedChanges(hasChanges: boolean) {
      this.hasUnsavedChanges = hasChanges;
    },

    // This is used to totally clear state in app builder's beforeRouteEnter method:
    clearWidgets() {
      this.widgets = {};
    },

    // ARTBOARD STUFF -----------------------------------------------

    positionArtboard(point: Point) {
      this.artboardPosition.x = point.x;
      this.artboardPosition.y = point.y;
    },

    resetArtboard() {
      this.artboardPosition = { ...initialArtboardPosition };
      this.scale = 1;
    },

    // SELECTIONS STUFF -------------------------------------------

    addSelection(wid: string) {
      if (!this.selections.includes(wid)) {
        this.selections.push(wid);
      }
    },

    removeSelection(wid: string) {
      // console.log("remove sels State", wid);

      const index = this.selections.indexOf(wid);
      if (index > -1) {
        this.selections.splice(index, 1);
      }
    },

    replaceSelections(wids: string[]) {
      // console.log("replace sels State", wids);
      this.selections = wids;
    },

    setHoveredId(wid: string) {
      this.hoveredId = wid;
    },

    copyWidgets() {
      if (this.selections.length > 0) {
        this.clipboard = this.selections
          .map((wid) => {
            return { ...this.widgets[wid] };
          })
          .filter((wg) => !wg.locked);
      }
    },

    setAppName(name: string) {
      this.hasUnsavedChanges = true;
      // console.log("set appp name", name);
      this.name = name;
    },

    setAppDimensions(payload: { width: number; height: number }) {
      this.hasUnsavedChanges = true;
      this.width = payload.width;
      this.height = payload.height;
    },

    setWidgets(widgets: Record<string, WidgetWithConditions>) {
      this.widgets = widgets;
    },

    setTimeZone(timeZone: string) {
      this.hasUnsavedChanges = true;
      this.ianaTimeZone = timeZone;
    },

    resetUndoRedoState(appInfo?: SavedAppInfo) {
      EventBus.emit("CLEAR_UNDO_STACK");

      if (typeof appInfo === "undefined") {
        /* Reconstruct appInfo based on current app state */
        appInfo = {
          name: this.name,
          appSchemaVersion: this.appSchemaVersion,
          parents: this.parents,
          widgets: this.widgets,
          data: (this as any).data,
          dataBindings: this.dataBindings,
          custom: {
            fonts: [],
            userUploadedAssets: [],
          },
        };
      }

      // console.log("reset undo redo...setting savedappinfo", appInfo);

      this.savedAppInfo = removeReactivity(appInfo);
    },

    setCanvasBox(box: Rectangle) {
      // console.log("set canvasbox", box);
      this.canvasBox.x = box.x;
      this.canvasBox.y = box.y;
      this.canvasBox.w = box.w;
      this.canvasBox.h = box.h;
    },

    // WIDGET STUFF -----------------------------------------------

    // NOTE: Think we must pass in group id to this, so exists in payload and can be undone/redone

    // I think we should just create fresh children...using scaleX,scaleY,w,h,x,y to find apparentDims, and give those to child as its real dims
    // I.e. whenever a widget is grouped, or ungrouped, it will start "fresh" with a new w and h (assuming it has been scaled).
    // Aha, and if we do this when we create a group, don't have to worry about ungrouping -- bc no way to resize children. Nice.
    groupWidgets(payload: {
      childWidgetIds: string[];
      groupWidgetId: string;
      groupAsRepeater?: boolean;
      parentId: string;
    }) {
      const { childWidgetIds, groupWidgetId, groupAsRepeater, parentId } =
        payload;

      EventBus.emit("IGNORE_TEXT_WIDGET_LISTENERS", true);

      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      const widgets = removeReactivity<WidgetWithConditions[]>(
        childWidgetIds.map((id) => this.widgets[id])
      ).map((wg) => getActiveWidget(wg, conditions));

      const z = getMaxZ(widgets);
      const groupBox = getBoundingBox(widgets);
      let group: GroupOptions | RepeaterOptions = Object.assign(
        {},
        CreateGroup({
          ...groupBox,
          z: z,
          parentId,
        }),
        { wid: groupWidgetId }
      );

      /*
       * Size and position the Repeater so that the selected widgets fill one cell, and they do not appear to move when grouped
       */

      const rowGap = 0;
      const columnGap = 0;

      if (groupAsRepeater) {
        // NOTE: would be nice to compute how many cells can fit based on size/pos of selection, and artboard size
        const rows = 1;
        const columns = 1;
        // rowGap = 20;
        // columnGap = 20;
        const repeaterWidth = columns * groupBox.w + (columns + 1) * columnGap;
        const repeaterHeight = rows * groupBox.h + (rows + 1) * rowGap;
        const repeaterBox = {
          x: groupBox.x - columnGap,
          y: groupBox.y - rowGap,
          w: repeaterWidth,
          h: repeaterHeight,
        };
        group = Object.assign(
          {},
          CreateRepeater({
            ...repeaterBox,
            z: z,
            parentId,
            rowGap,
            columnGap,
            rows,
            columns,
          }),
          { wid: groupWidgetId }
        );
      }

      const children = this.parents[parentId];
      if (Array.isArray(children)) {
        // Remove the new group child ids from the current editingContext child list.
        Vue.set(
          this.parents,
          parentId,
          children.filter((wid) => !childWidgetIds.includes(wid))
        );
        // Add new group to current editingContext child list.
        if (!children.includes(group.wid)) {
          this.parents[parentId].push(group.wid);
        }
      }

      // Update position & parent info for group children.
      childWidgetIds.forEach((wid) => {
        const { conditionalVersions } = this.widgets[wid];

        for (const cid in conditionalVersions) {
          const wg = this.widgets[wid].conditionalVersions[cid];
          const rect = getApparentDims(wg);
          wg.x = rect.x - group.x - rowGap;
          wg.y = rect.y - group.y - rowGap;
          wg.w = rect.w;
          wg.h = rect.h;
          wg.scaleX = 1;
          wg.scaleY = 1;
          this.widgets[wid].parentId = group.wid;
        }

        // wg.parentId = group.wid;
      });

      addConditionalVersions(group as Widget);

      // Add new group to widgets list.
      Vue.set(this.widgets, group.wid, group);

      // Add child widgets to new group child list
      Vue.set(this.parents, group.wid, childWidgetIds);

      // Select new group widget
      this.selections = [group.wid];

      // On the next tick, turn this off, so that we can ignore height-shifting watcher in TextWrapper
      setTimeout(() => {
        EventBus.emit("IGNORE_TEXT_WIDGET_LISTENERS", false);
      }, 0);
    },

    addToNewRepeater(
      payload: { wid: string; parentWidgetId: string },
      NO_UNDO?: NO_UNDO
    ) {
      const { wid, parentWidgetId } = payload;
      const widget = this.widgets[wid];
      const mutableProps = getMutableWidgetProps(widget);
      Vue.set(widget, "parentId", parentWidgetId);
      Vue.set(mutableProps, "x", 20);
      Vue.set(mutableProps, "y", 20);

      Vue.set(this.parents, parentWidgetId, [wid]);
    },

    destroyGroup(wid: string) {
      this.verticalDynamismOff({ wid: wid }, "NO_UNDO");
      this.ungroupWidgets(wid);
    },

    ungroupWidgets(wid: string) {
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      const groupWidget = getActiveWidget(this.widgets[wid], conditions);
      const childIds = this.parents[wid];

      // console.log("ungroup", childIds.slice(0));

      if (Array.isArray(childIds)) {
        // Update position for children
        childIds.forEach((wid) => {
          for (const cid in this.widgets[wid].conditionalVersions) {
            const child = this.widgets[wid].conditionalVersions[cid];
            const newPosition = getChildAbsolutePosition(
              groupWidget,
              child as Widget
            );
            child.x = newPosition.x;
            child.y = newPosition.y;
            child.angle += groupWidget.angle;
          }
        });

        // Figure out how to adjust the z-index of all child widgets
        // so they fit in the same visual order after ungrouping.

        // Ahhh, cannot use state.widgets' keys here, because they include all children
        const groupSiblingIds =
          groupWidget.parentId === BASE_PARENT_ID
            ? Object.values(this.widgets)
                .filter((wg) => wg.parentId === BASE_PARENT_ID)
                .map((wg) => wg.wid)
            : this.parents[groupWidget.parentId];

        const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
        const sortByZ = (wids: string[]) => {
          const copy = wids.slice(0);
          copy.sort(
            (a, b) =>
              getActiveWidget(this.widgets[a], conditions).z -
              getActiveWidget(this.widgets[b], conditions).z
          );
          return copy;
        };

        const sortedWidgetIds = sortByZ(groupSiblingIds);
        const sortedChildIds = sortByZ(childIds);
        const indexOfGroupWidget = sortedWidgetIds.indexOf(wid);
        sortedWidgetIds.splice(indexOfGroupWidget, 1, ...sortedChildIds);

        // Update new z indexes for all widgets
        // TODO: Do for each version
        removeDuplicateStrings(sortedWidgetIds).forEach(
          (wid: string, i: number) => {
            const wg = getMutableWidgetProps(this.widgets[wid]);
            this.widgets[wid].parentId = groupWidget.parentId;
            wg.z = i + 1;

            // Add child to new parent array
            const children = this.parents[groupWidget.parentId];
            if (Array.isArray(children) && !children.includes(wid)) {
              children.push(wid);
            }
          }
        );

        // Remove old group widget from it's parent's child list
        const children = this.parents[groupWidget.parentId];
        if (Array.isArray(children)) {
          const groupWidgetChildIndex = children.indexOf(groupWidget.wid);
          if (groupWidgetChildIndex > -1) {
            children.splice(groupWidgetChildIndex, 1);
          }
        }

        // Remove Group Widget
        Vue.delete(this.widgets, groupWidget.wid);
        Vue.delete(this.parents, groupWidget.wid);
        this.selections = childIds;
      }
    },

    nudge(payload: {
      direction: "x" | "y";
      distance: number;
      selections: string[];
    }) {
      const { direction, distance, selections } = payload;
      selections.forEach((wid: string) => {
        const props = getMutableWidgetProps(this.widgets[wid]);
        if (props) {
          props[direction] += distance;
        }
      });
    },

    distribute(payload: { direction: "vert" | "hor"; selections: string[] }) {
      const { direction, selections } = payload;
      const props: TransformOptions[] = selections
        .map((wid: string) => this.widgets[wid])
        .map(getMutableWidgetProps);

      if (props.length <= 2) {
        return;
      }
      const axis = direction === "hor" ? "x" : "y";
      const span = direction === "hor" ? "w" : "h";
      const sorted = props.sort((a, b) => a[axis] - b[axis]);
      const [first, ...rest] = sorted;
      const last = rest.splice(rest.length - 1, 1)[0];
      const firstEnd = first[axis] + first[span];
      const lastStart = last[axis];
      let spaceToDistribute = lastStart - firstEnd;
      const restSpans = rest.reduce((acc: number, val) => acc + val[span], 0);
      spaceToDistribute -= restSpans;
      const numSpaces = sorted.length - 1;
      const eachSpanLength = spaceToDistribute / numSpaces;
      let current = firstEnd;
      rest.forEach((wg) => {
        wg[axis] = Math.round(current + eachSpanLength);
        current += wg[span] + eachSpanLength;
      });
    },

    toggleSyncTextColors(payload: {
      wid: string;
      syncTextColors: boolean;
      textElements: string[];
    }) {
      const { wid, syncTextColors, textElements } = payload;
      // NOTE: won't work for children
      const props = getMutableWidgetProps(this.widgets[wid]);
      Vue.set(props, "syncTextColors", syncTextColors);

      if (syncTextColors) {
        // Always assume "master" textcolor is first in textElements array
        const masterTextColorProp = `${textElements[0]}_textColor`;
        const masterColor = props[masterTextColorProp];
        // console.log("turnon stc", masterColor);
        textElements.forEach((el) => {
          Vue.set(props, `${el}_textColor`, masterColor);
        });
      }
    },

    applyTextColor(
      widgetId: string,
      syncTextColors: boolean,
      element: string,
      colorValue: string,
      NO_UNDO?: NO_UNDO
    ) {
      const props = getMutableWidgetProps(this.widgets[widgetId]);

      if (syncTextColors) {
        const keysToSync = Object.keys(props).filter((key) =>
          key.includes("_textColor")
        );
        keysToSync.forEach((key) => {
          Vue.set(props, key, colorValue);
        });
      } else {
        Vue.set(props, `${element}_textColor`, colorValue);
      }
    },

    toggleSyncFonts(payload: {
      wid: string;
      syncFonts: boolean;
      textElements: string[];
    }) {
      const { wid, syncFonts, textElements } = payload;

      // NOTE: won't work for children
      const props = getMutableWidgetProps(this.widgets[wid]);
      Vue.set(props, "syncFonts", syncFonts);

      // Activate sync fonts (do it here to work with undo/redo)
      if (syncFonts) {
        // Always assume "master" font is first in textElements array
        const masterFontProp = `${textElements[0]}_fontFamily`;
        const masterFont = props[masterFontProp];

        textElements.forEach((el: string) => {
          Vue.set(props, `${el}_fontFamily`, masterFont);
        });
      }
    },

    applyFontFamily(payload: {
      model: { wid: string; syncFonts: boolean };
      element: string;
      value: unknown;
    }) {
      const { model, element, value } = payload;
      const props = getMutableWidgetProps(this.widgets[model.wid]);

      if (model.syncFonts) {
        const keysToSync = Object.keys(props).filter((key) =>
          key.includes("_fontFamily")
        );
        keysToSync.forEach((key) => {
          Vue.set(props, key, value);
        });
      } else {
        Vue.set(props, `${element}_fontFamily`, value);
      }
    },

    verticalDynamismOn(payload: { wid: string }) {
      const { wid } = payload;
      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      // NOTE: won't work for children
      const props = getMutableWidgetProps(this.widgets[wid]);
      const children = this.parents[wid].map((id) =>
        getActiveWidget(this.widgets[id], conditions)
      );

      // Sort children based on current y-values:
      const orderedChildIds = children
        .slice(0)
        .sort((a, b) => {
          return a.y - b.y;
        })
        .map((wg) => wg.wid);

      Vue.set(props, "orderedChildIds", orderedChildIds);

      // Compute new height of group:
      const newH =
        children.slice(0).reduce((total: number, wg: Widget) => {
          return total + wg.h * wg.scaleY;
        }, 0) +
        props.verticalMargin * (children.length - 1);

      Vue.set(props, "h", newH);
      Vue.set(props, "verticalDynamism", true);
    },

    verticalDynamismOff(payload: { wid: string }, NO_UNDO?: NO_UNDO) {
      const { wid } = payload;
      const groupProps = getMutableWidgetProps(
        this.widgets[wid]
      ) as GroupOptions;

      if (!groupProps) return;

      // Give each child a new y-value to reflect its current (dynamic) position:
      let y = 0;
      (groupProps.orderedChildIds || []).forEach((wid: string) => {
        const childProps = getMutableWidgetProps(this.widgets[wid]);
        Vue.set(childProps, "y", y);

        y += childProps.h * childProps.scaleY;
        y += groupProps.verticalMargin;
      });

      Vue.set(groupProps, "verticalDynamism", false);
    },

    align(payload: { direction: string; selections: string[] }) {
      const { direction, selections } = payload;

      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;
      const dimensions = selections.map((wid: string) =>
        getApparentDims(getActiveWidget(this.widgets[wid], conditions))
      );

      const box = getBoundingBox(dimensions);

      selections.forEach((wid: string) => {
        const wg = getMutableWidgetProps(this.widgets[wid]) as TransformOptions;

        const apparentWg = getApparentDims(wg);
        // const mergedWg = { ...apparentWg };

        // Account for possible widget rotation
        const mergedWg = getAngledBoundingBox({
          ...apparentWg,
        }) as TransformOptions;

        const originalMergedY = mergedWg.y;
        const originalMergedX = mergedWg.x;

        switch (direction) {
          case "left":
            // wg.x = box.x;
            mergedWg.x = box.x;
            break;
          case "right":
            mergedWg.x = box.x + box.w - mergedWg.w;
            break;
          case "ctr-col":
            mergedWg.x = box.x + box.w / 2 - mergedWg.w / 2;
            break;
          case "top":
            mergedWg.y = box.y;
            break;
          case "bottom":
            mergedWg.y = box.y + box.h - mergedWg.h;
            break;
          case "ctr-row":
            mergedWg.y = box.y + box.h / 2 - mergedWg.h / 2;
            break;
        }

        // Problem is....if real y is 0, and h is  100, and scaleY is 2, apparent Y is going to be -100.
        // Huh...don't see why this was needed here
        // OOOOH because this uses a DIFFERENT getapparentdims! Which rturns scalex ad scaley of 1
        // translate.js has its own...
        mergedWg.scaleX = wg.scaleX;
        mergedWg.scaleY = wg.scaleY;

        /**
         * If the widget is not rotated (has angle 0), we can simply compute the real coordinates that the widget must have in order to *appear* at its new dimensions/position.
         *
         * If the widget is rotated, we first compute the angled bounding box (the smallest non-rotated rectangle that encloses the rotated widget's bounding box).
         * Then, we compute the difference that this must be moved (along x or y axis) in order to appear at its target position.
         * Then we add this difference to the widget's current x or y position to get its target apparent position, and as before, compute the real coordinates based on that.
         */

        if (wg.angle == 0) {
          const dims = getApparentDimsInvert(mergedWg);

          wg.x = dims.x;
          wg.y = dims.y;
          wg.w = dims.w;
          wg.h = dims.h;
        } else {
          // Handle rotated widgets

          const dims = getApparentDimsInvert({
            ...wg,
            x: wg.x + mergedWg.x - originalMergedX,
            y: wg.y + mergedWg.y - originalMergedY,
            w: wg.w,
            h: wg.h,
            scaleX: 1,
            scaleY: 1,
          });
          wg.x = dims.x;
          wg.y = dims.y;
          wg.w = dims.w;
          wg.h = dims.h;
        }
      });
    },

    addWidget(widget: Widget, NO_UNDO?: NO_UNDO) {
      const z = getMaxZByParentId(this);
      widget.z = z + 1;
      addWidget(this, widget);
    },

    duplicateWidget(widget: any, isFromBaseLevel: boolean) {
      const dbs = widget.bindings;
      if (dbs.length > 0) {
        dbs.forEach((binding: DataBinding) => {
          const newBinding = Object.assign({}, binding, {
            widgetId: widget.wid,
            parentWidgetId: widget.parentId,
          });
          this.dataBindings.push(newBinding);
        });
      }

      const z = getMaxZByParentId(this);

      if (isFromBaseLevel) {
        const PASTE_OFFSET = { x: 20, y: 20 };

        for (const cid in widget.conditionalVersions) {
          const props = widget.conditionalVersions[cid];
          props.x += PASTE_OFFSET.x;
          props.y += PASTE_OFFSET.y;
          props.z = z + 1;
        }
      }

      addWidget(this, {
        ...widget,
        z: z + 1,
      });
      // }
    },

    /**
     * Must behave as follows:
     * - For all "base level" widgets (widgets from the level that we copied from),
     *    - add data bindings that were passed on payload -- needed there, because they refer to original widget id
     *    - and shift by paste_offset
     *    - and go through all their children and Duplicate them
     *       - Copy data bindings
     *       - Copy widget
     */

    pasteWidgets(payload: {
      widgets: (WidgetWithConditions & { bindings: DataBinding[] })[];
    }) {
      const { widgets } = payload;

      widgets.forEach((wg, index) => {
        this.duplicateWidget(wg, wg.parentId === widgets[0].parentId);
      });
    },

    moveGroup(
      payload: {
        wids: string[];
        delta: Point;
        groupStartingPositions: Record<string, Point>;
      },
      NO_UNDO?: NO_UNDO
    ) {
      const widgets: WidgetWithConditions[] = payload.wids.map(
        (wid: string) => this.widgets[wid]
      );
      const { delta, groupStartingPositions } = payload;

      widgets.forEach((wg) => {
        const startingPos = groupStartingPositions[wg.wid];
        const props = getMutableWidgetProps(wg);
        props.x = startingPos.x + delta.x;
        props.y = startingPos.y + delta.y;
      });
    },

    removeDataBindingsForWidgets(payload: {
      widgetsInfo: { wid: string; conditionId: string }[];
    }) {
      const { widgetsInfo } = payload;
      this.hasUnsavedChanges = true;

      if (widgetsInfo && widgetsInfo.length > 0) {
        widgetsInfo.forEach(({ wid, conditionId }) => {
          const widget = this.widgets[wid];

          // Must clear out extra bindings here, because we are not yet deleting them when user updates bg image with new photo

          const bindings = this.dataBindings as DataBinding[];
          const newBindings = bindings.filter((db) => {
            return !(db.widgetId === wid && db.conditionUuid === conditionId);
          });
          Vue.set(this, "dataBindings", newBindings);
          Vue.set(widget, "tempBackgroundInfo", { url: "" }); // TODO: can we do this elsewhere?
        });
      }
    },

    removeWidgets(payload: { wids: string[] }, NO_UNDO?: NO_UNDO) {
      const widgetIds = payload.wids;

      EventBus.emit("IGNORE_TEXT_WIDGET_LISTENERS", true);

      this.hasUnsavedChanges = true;

      if (widgetIds && widgetIds.length > 0) {
        widgetIds.forEach((wid) => {
          const widget = this.widgets[wid];
          if (!widget) return;
          const parentId = widget.parentId;
          const bindings = this.dataBindings as DataBinding[];

          // Remove parents reference and all children:
          if (["Repeater", "Group"].includes(widget.type)) {
            // Delete children from state.widgets
            (this.parents[wid] || []).forEach((id) => {
              Vue.delete(this.widgets, id);

              // Also delete bindings for child:
              const newBindings = this.dataBindings.filter(
                (b: DataBinding) => b.widgetId !== id
              );
              Vue.set(this, "dataBindings", newBindings);
            });
            Vue.delete(this.parents, wid);
          }

          // Delete widget
          Vue.delete(this.widgets, wid);

          // Delete databindings associated with widget
          const newBindings = this.dataBindings.filter(
            (db: DataBinding) => db.widgetId !== wid
          );
          Vue.set(this, "dataBindings", newBindings);

          const children = this.parents[parentId];

          if (Array.isArray(children) && children.length > 0) {
            // Remove widget from parent array
            const childIndex = children.indexOf(wid);
            if (childIndex > -1) {
              children.splice(childIndex, 1);
            }
          }

          // If the widget has a parent, we need to do more work
          if (parentId !== BASE_PARENT_ID) {
            const parentType = this.widgets[parentId].type;
            const parentProps = getMutableWidgetProps(this.widgets[parentId]);
            if (!parentProps) return;

            if (parentType === "Group") {
              // OOOH. Only do this if was a group!
              // If the widget we deleted above was an "only child", delete it's parent too. So cruel...
              if (children.length === 0) {
                Vue.delete(this.widgets, parentId);
                Vue.delete(this.parents, parentId);

                const idx = bindings.findIndex(
                  (db) => db.widgetId === parentId
                );
                if (idx > -1) {
                  bindings.splice(idx, 1);
                }
              } else {
                // Resposition group widget and children
                const childWidgets = children
                  .map((wid) => this.widgets[wid])
                  .map((wg) => getMutableWidgetProps(wg));
                const childrenBounds = getBoundingBox(childWidgets);
                const newPosition = recalculateGroupPosition(
                  parentProps,
                  childrenBounds
                );

                parentProps.x = newPosition.x;
                parentProps.y = newPosition.y;
                parentProps.w = childrenBounds.w;
                parentProps.h = childrenBounds.h;

                childWidgets.forEach((wg) => {
                  wg.x -= childrenBounds.x;
                  wg.y -= childrenBounds.y;
                });
              }
            }
          }
        });
      }

      setTimeout(() => {
        EventBus.emit("IGNORE_TEXT_WIDGET_LISTENERS", false);
      }, 0);
    },

    toggleLockAspect(payload: { lockAspect: boolean; wid: string }) {
      const { lockAspect, wid } = payload;
      const props = getMutableWidgetProps(this.widgets[wid]);

      if (typeof props === "undefined") {
        return;
      }

      props.lockAspect = lockAspect;
    },

    setWidgetStackOrder(widgetIds: string[]) {
      (widgetIds || []).forEach((wid: string, i: number) => {
        const props = getMutableWidgetProps(this.widgets[wid]);
        if (props) {
          props.z = i + 1;
        }
      });
    },

    addCustomFont(font: string) {
      if (!this.custom.fonts) {
        Vue.set(this.custom, "fonts", []);
      }
      this.custom.fonts.push(font);
    },

    setAwaitingServer(val: boolean) {
      this.awaitingServer = val;
    },

    setPublishMeta(val: PublishMetadata) {
      this.publishMeta = val;
    },

    setAppVersionUuid(val: string) {
      this.appVersionUuid = val;
    },

    // WIDGET SETTINGS STUFF -----------------------------------------------

    setWidgetProps(
      widgetIds: string[],
      props: Partial<WidgetProperties>,
      NO_UNDO?: NO_UNDO
    ) {
      if (NO_UNDO === undefined) {
        this.hasUnsavedChanges = true;
      }

      const mainAppStore = useMainAppStore();

      widgetIds.forEach((widgetId: string) => {
        if (widgetId !== undefined) {
          const widgetProps = getMutableWidgetProps(this.widgets[widgetId]);
          if (!widgetProps) return;
          for (const prop in props) {
            Vue.set(widgetProps, prop, props[prop]);
            if (prop.includes("fontFamily")) {
              mainAppStore.addFont(props[prop]);
            }
          }
        }
      });
    },

    updateTextContent(
      payload: {
        wid: string;
        content: TextContent;
      },
      NO_UNDO?: NO_UNDO
    ) {
      const { content, wid } = payload;
      if (!wid) return;
      const props = getMutableWidgetProps(this.widgets[wid]) as TextOptions;

      if (props) {
        props.content = content;
        const size = measureText(props);

        // wg.h = size.h; // OLD

        const { h, y } = dimsToUpdateApparentHeight(props, size.h);

        // const newH = size.h / wg.scaleY;
        // const newY = wg.y + (wg.h - newH) * (1 - wg.scaleY);

        props.h = h;
        props.y = y;
      }
    },

    /**
     * Replaces all datatoken bindings in text widgets
     */
    updateTextBindings(payload: { replacements: BindingReplacement[] }) {
      payload.replacements.forEach((replacement) => {
        const widgetId = replacement.oldBinding.widgetId;
        const props = getMutableWidgetProps(
          this.widgets[widgetId]
        ) as TextOptions;

        // Need to access `type` here because it doesn't
        // exist on the `props` object.
        const type = this.widgets[widgetId].type;

        if (typeof props !== "undefined" && type === "Text") {
          props.content = updateTokenBindings(
            props.content,
            replacement.oldBinding.dataUuid,
            replacement.newBinding?.dataUuid
          );
        }
      });
    },

    resetWidgetProperties(payload: {
      properties: { property: string; widgetId: string }[];
    }) {
      payload.properties.forEach((item) => {
        const widget = this.widgets[item.widgetId] as any;
        widget[item.property] = getWidgetPropertyDefault(
          widget.type,
          item.property
        );
      });
    },

    // SCALE STUFF -----------------------------------------------

    setScale(value: number) {
      if (Math.abs(1 - value) < 0.05) {
        value = Math.round(value);
      }
      this.scale = value;
    },

    leaveTextEditMode() {
      // Tricky..because we do NOT want to deselect if they trigger Blur by clicking a handle...
      // Also, want to be able to select another wg on same click
      // console.log("leave textedit");
      this.selections = [];
      this.textEditingWidget = null;
    },

    enterTextEditMode(wid: string) {
      this.textEditingWidget = wid;
    },

    setEditingContext(context: EditingContext) {
      this.editingContext = context;
      this.selections = [];
    },

    resetEditingContext() {
      this.editingContext = defaultEditingContext();
      this.selections = [];
    },

    /**
     * We call this when an asset is added to the canvas
     * NOTE: When app data is loaded from the server, the
     * assets array from the server data overwrites the
     * local assets array. This is ok because they should
     * be completely synchronized.
     */
    addAsset(asset: SavedAsset) {
      const index = this.assets.findIndex((a) => a.uuid === asset.uuid);
      if (index > -1) {
        this.assets.splice(index, 1);
      }
      this.assets.push(asset);
    },

    // setShouldRefreshConnections(state, val: boolean) {
    //   state.shouldRefreshConnections = val;
    // },

    setCachedConnections(conns: DataConnection[]) {
      this.cachedConnections = conns;
    },

    addWidgetCondition(payload: { conditionUuid: string; widgetId: string }) {
      const { conditionUuid, widgetId } = payload;
      const widget = this.widgets[widgetId];
      Vue.set(widget.conditionalVersions, conditionUuid, {
        ...widget.conditionalVersions[DEFAULT_CONDITION_ID],
      });
    },

    /**
     * Removes provided conditions from provided widgets
     */
    removeWidgetConditions(args: {
      widgetIds: string[];
      conditionUuids: string[];
    }) {
      args.widgetIds.forEach((widgetId) => {
        const widget = this.widgets[widgetId];
        if (typeof widget === "undefined") return;
        args.conditionUuids.forEach((id) => {
          Vue.delete(widget.conditionalVersions, id);
        });
      });
    },

    // ---------------
    // ACTIONS
    // ---------------

    // TODO: This doesn't get/set state.
    createApp(payload: {
      size: Size;
      name: string;
      ianaTimeZone: string;
    }): Promise<string | void> {
      const { size, name, ianaTimeZone } = payload;

      const data = {
        name: name || "DefaultAppName",
        width: size.w,
        height: size.h,
        model: {
          appSchemaVersion: appSchemaVersion,
          widgets: {},
          custom: {},
        },
        feedDeliveryMethod: FeedDeliveryMethod.Html,
        ianaTimeZone: ianaTimeZone,
      };
      return api.post<AppPayload>("apps", data).then((response) => {
        return response.uuid;
      });
    },

    // TODO: This doesn't get/set state.
    async createFeed(payload: {
      appUuid: string;
      name: string;
    }): Promise<Feed> {
      return api.post<Feed>(`apps/${payload.appUuid}/feed`, {
        name: payload.name,
        deliveryMethod: FeedDeliveryMethod.Html,
      });
    },

    async updateAppName(payload: { id: string; name: string }) {
      return api.get<AppPayload>(`apps/${payload.id}`).then((app) => {
        app.name = payload.name;
        return api.put<AppPayload>(`apps/${payload.id}`, app).then((res) => {
          if (res) {
            this.name = res.name;
            this.checksum = res.checksum;
            this.modifiedAt = res.modifiedAt;
          }
        });
      });
    },

    // TODO: This references a 'data' module that doesn't exist in Pinia code yet.
    async updateApp() {
      if (!this.uuid) {
        return Promise.resolve();
      }

      // Use queue to ensure only one call to updateApp is made at a time.
      return Queue.enqueue(() => {
        // console.log("Enqueueing a promise...");

        /**
         * Remove any duplicates from dataBindings array,
         * just in case any issue has occurred that caused one to be incorrectly added.
         * We want to remove any dupes even if query or dataUuid is different, as long as property, widgetId and conditionUuid match.
         * This avoids triggering a backend issue on the save action.
         */
        const dataBindings: DataBinding[] = uniqWith(
          removeReactivity(this.dataBindings),
          (db, otherDb) => {
            const sameWidgetId = db.widgetId === otherDb.widgetId;
            const sameConditionuuid =
              db.conditionUuid === otherDb.conditionUuid;
            // We want to allow for multiple bindings to same widget under same conditionUuid of the 'content' property
            const sameProperty =
              db.property !== "content" && db.property === otherDb.property;

            return sameWidgetId && sameConditionuuid && sameProperty;
          }
        );

        const payload: Partial<AppPayload> = {
          appVersionUuid: this.appVersionUuid,
          width: this.width,
          height: this.height,
          dataBindings,
          model: {
            appSchemaVersion: this.appSchemaVersion,
            widgets: removeReactivity(this.widgets),
            parents: removeReactivity(this.parents),
            custom: removeReactivity(this.custom),
          },
          name: this.name,
          ianaTimeZone: this.ianaTimeZone,
          checksum: this.checksum,
          modifiedAt: this.modifiedAt,
        };

        if (typeof this.fallbackImageTimeoutSec === "number") {
          payload.fallbackImageTimeoutSec = this.fallbackImageTimeoutSec;
        }

        if (typeof this.introImageTimeoutSec === "number") {
          payload.introImageTimeoutSec = this.introImageTimeoutSec;
        }

        return api.put<AppPayload>(`apps/${this.uuid}`, payload);
      })
        .then((response: any) => {
          if (response) {
            // Do this to handle case of pasting a repeater with a filter;
            // We need to use dataBinding from the server, that is updated with new filterUuid.

            this.setDataBindings(response.dataBindings);

            this.setChecksum({
              checksum: response.checksum as number,
              modifiedAt: response.modifiedAt as string,
            });
          }
        })
        .finally(() => {
          // console.log("Finally resolved.");
          this.setHasUnsavedChanges(false);
        });
    },

    async loadPublishMeta(appId: string) {
      return api.get<AppInfo>(`apps/${appId}`).then(async (app) => {
        this.publishMeta = app.publishMeta;
        this.appVersionUuid = app.appVersionUuid;
        // console.log("publishing app...", app);
        if (app.dataBindings) {
          this.dataBindings = app.dataBindings;
        }
      });
    },

    async loadApp(appId: string) {
      return api.get<AppPayload>(`apps/${appId}`).then(async (app) => {
        if (!app.model) {
          app.model = {
            appSchemaVersion: 1,
            widgets: {},
            parents: {},
            custom: { fonts: [], userUploadedAssets: [] },
          };
        }

        applyModelMigrations(app, "edit");

        pruneUnusedDataBindings(app);

        // This is formerly "initStateWith"
        this.hasUnsavedChanges = false;
        Object.keys(app).forEach((key) => {
          if (key === "dataBindings") {
            this.setDataBindings(app.dataBindings);
          } else if (key === "model") {
            this.widgets = app.model.widgets as Record<
              string,
              WidgetWithConditions
            >;
            this.parents = app.model.parents;
            this.custom = app.model.custom;
            this.appSchemaVersion = app.model.appSchemaVersion;
            this.selections = [];
          } else {
            (this as any)[key] = (app as any)[key];
          }
        });

        /**
         * Store initial widget data on savedAppInfo so that we can use it when user presses undo, clearing out state
         */
        const appInfo: SavedAppInfo = {
          appSchemaVersion: app.model.appSchemaVersion,
          name: app.name,
          widgets: purgeNullValues(app.model.widgets),
          parents: app.model.parents || {},
          dataBindings: app.dataBindings,
          data: {}, // rootState.data.data,  TODO: UNCOMMENT
          custom: app.model.custom,
        };

        this.resetUndoRedoState(appInfo);

        // Load initial widget data
        await useAppDataStore().initializeWidgetData(app.uuid);

        // Reset the state of the connection editor since it is scoped to the currently edited app
        useConnectionEditorStore().$reset();

        // No need to await the following request. We can do it in the background.
        useConnectionsStore().initializeConnections(app.uuid);
        return app;
      });
    },

    removeWidgetsAction(payload: { widgetIds: string[] }) {
      const { widgetIds } = payload;
      const conditionsStore = useConditionGroupsStore();

      // Collect any children wids that will also be deleted, for the condition group deletion step
      const allWidsToRemove = [
        ...widgetIds,
        ...widgetIds
          .filter((wid) => wid in this.parents)
          .flatMap((wid) => this.parents[wid]),
      ];
      this.removeWidgets({ wids: widgetIds });

      // Remove condition groups, reset undo/redo
      const widgetsWithConditions = allWidsToRemove
        .map((wid) => {
          const cg = conditionsStore.conditionGroups.find((cg) =>
            cg.widgets.map((w) => w.widgetId).includes(wid)
          );
          if (!cg) return undefined;
          return { widgetId: wid, conditionGroupUuid: cg.uuid };
        })
        .filter((cg) => cg !== undefined);

      if (widgetsWithConditions.length === 0) return;

      Promise.all(
        widgetsWithConditions.map((data) =>
          conditionsStore.removeConditionGroup({
            appUuid: this.uuid,
            conditionGroupUuid: data?.conditionGroupUuid || "",
            widgetId: data?.widgetId || "",
          })
        )
      ).finally(() => {
        // Clear undo/redo state, because user will not be able to undo through a condition group deletion
        // this.storeInitialAppState();
        this.resetUndoRedoState();
      });
    },

    removeRepeaterBinding(
      payload: {
        widgetIds: string[];
        dataBinding: DataBinding;
      },
      NO_UNDO?: NO_UNDO
    ) {
      const { widgetIds, dataBinding } = payload;

      this.removeWidgets(
        {
          wids: widgetIds,
        },
        NO_UNDO
      );

      this.removeDataBinding(dataBinding, "NO_UNDO");
    },

    addWidgetAction(payload: {
      widget: Widget;
      preserveParentId?: boolean;
      track?: boolean;
    }) {
      const { widget, preserveParentId, track } = payload;
      // const z = getMaxZByParentId(state);
      // widget.z = z + 1;

      // Add parentId here so that it passes in as payload to addWidget mutation
      // But if payload already has it, avoid this
      if (!preserveParentId) {
        widget.parentId = this.editingContext.parentId || BASE_PARENT_ID;
      }

      this.addWidget(widget, track === false ? "NO_UNDO" : undefined);
    },

    updateBackgroundImage(payload: {
      asset: SavedAsset;
      wid: string;
      conditionUuid: string;
      parentWidgetId?: string;
    }) {
      const { asset, wid, conditionUuid, parentWidgetId } = payload;

      // Two issues when adding from Datasetexplorer: no uuid, and addAsset fails

      const binding: DataBinding = {
        dataUuid: asset.uuid, // asset's uuid
        bindingType: "Asset",
        widgetId: wid,
        property: "backgroundImageUrl",
        conditionUuid,
      };

      binding.parentWidgetId = parentWidgetId
        ? parentWidgetId
        : this.editingContext.parentId;

      // If there is already an asset binding associated with this Conditional Widget Version, must delete it
      const existingBinding = {
        widgetId: wid,
        property: "backgroundImageUrl",
        conditionUuid,
      };

      this.removeDataBinding(existingBinding, "NO_UNDO");

      const props = {
        backgroundImageW: asset.width,
        backgroundImageH: asset.height,
      };

      this.setWidgetProps([wid], props, "NO_UNDO");
      this.addAsset(asset); // Maybe just ignore this in undo/redo for now?

      this.addDataBinding(binding, "NO_UNDO");
    },

    updateImageSource(payload: {
      asset: SavedAsset;
      wid: string;
      conditionUuid: string;
      parentWidgetId?: string;
    }) {
      const { asset, wid, conditionUuid, parentWidgetId } = payload;

      const binding: DataBinding = {
        dataUuid: asset.uuid,
        bindingType: "Asset",
        widgetId: wid,
        property: "url",
        conditionUuid,
      };

      binding.parentWidgetId = parentWidgetId
        ? parentWidgetId
        : this.editingContext.parentId;
      // console.log("update image source", conditionUuid);

      // If there is already an asset binding associated with this Conditional Widget Version, must delete it
      const existingBinding = { widgetId: wid, property: "url", conditionUuid };

      this.removeDataBinding(existingBinding, "NO_UNDO");

      this.addAsset(asset);

      this.addDataBinding(binding, "NO_UNDO");
    },

    async addImageComponent(payload: {
      asset: SavedAsset;
      boundingBox: Rectangle;
      parentWidgetId?: string;
      newWidgetId: string;
    }): Promise<void> {
      const { asset, boundingBox, parentWidgetId, newWidgetId } = payload;

      const widget = CreateImage({
        name: asset.name,
        x: boundingBox.x,
        y: boundingBox.y,
        w: boundingBox.w,
        h: boundingBox.h,
        lockAspect: true,
        canScaleX: true,
        canScaleY: true,
        wid: newWidgetId,
      });

      const binding: DataBinding = {
        dataUuid: asset.uuid, // asset's uuid
        bindingType: "Asset",
        widgetId: widget.wid,
        property: "url",
        conditionUuid: DEFAULT_CONDITION_ID,
      };

      binding.parentWidgetId = parentWidgetId
        ? parentWidgetId
        : this.editingContext?.parentId;
      // NOTE: Perhaps we must update app with the bindings?

      const addWidgetPayload: any = {
        widget,
        track: false,
      };

      // If we are dropping node into a repeater...
      if (parentWidgetId) {
        addWidgetPayload.preserveParentId = true;
        addWidgetPayload.widget.parentId = parentWidgetId;
      }

      await this.addWidgetAction(addWidgetPayload);

      this.addAsset(asset);

      this.addDataBinding(binding, "NO_UNDO");
      this.replaceSelections([widget.wid]);
    },

    async addVideoComponent(payload: {
      asset: SavedAsset;
      boundingBox: Rectangle;
      parentWidgetId?: string;
      newWidgetId: string;
    }): Promise<void> {
      const { asset, boundingBox, parentWidgetId, newWidgetId } = payload;

      const widget = CreateVideo({
        name: asset.name,
        x: boundingBox.x,
        y: boundingBox.y,
        w: boundingBox.w,
        h: boundingBox.h,
        lockAspect: true,
        canScaleX: true,
        canScaleY: true,
        wid: newWidgetId,
        mimeType: asset.type,
      });

      const binding: DataBinding = {
        dataUuid: asset.uuid, // asset's uuid
        bindingType: "Asset",
        widgetId: widget.wid,
        property: "url",
        conditionUuid: DEFAULT_CONDITION_ID,
      };

      binding.parentWidgetId = parentWidgetId
        ? parentWidgetId
        : this.editingContext?.parentId;
      // NOTE: Perhaps we must update app with the bindings?

      const addWidgetPayload: any = {
        widget,
        track: false,
      };

      // If we are dropping node into a repeater...
      if (parentWidgetId) {
        addWidgetPayload.preserveParentId = true;
        addWidgetPayload.widget.parentId = parentWidgetId;
      }

      await this.addWidgetAction(addWidgetPayload);

      this.addAsset(asset);

      this.addDataBinding(binding, "NO_UNDO");
      this.replaceSelections([widget.wid]);
    },

    // ----------------------------------------------------
    // DATA BINDINGS STUFF
    // ----------------------------------------------------

    setDataBindings(dataBindings: DataBinding[] = []) {
      Vue.set(this, "dataBindings", dataBindings);
    },

    addDataBinding(binding: DataBinding, NO_UNDO?: NO_UNDO) {
      if (!("conditionUuid" in binding)) {
        binding.conditionUuid = DEFAULT_CONDITION_ID;
      }
      this.dataBindings.push(binding);
      this.hasUnsavedChanges = true;
    },

    // When user disconnects a data source, backend will send back list of new bindings that point at new manual data source
    // These must replace their existing counterparts in the state.dataBindings array
    replaceDataBindings(args: { bindings: DataBinding[] | null }) {
      const { bindings } = args;
      if (bindings) {
        bindings.forEach((binding) => {
          const idx = this.dataBindings.findIndex(
            (b) =>
              b.bindingType === binding.bindingType &&
              b.widgetId === binding.widgetId &&
              b.parentWidgetId === binding.parentWidgetId &&
              b.dataName === binding.dataName
          );
          this.dataBindings.splice(idx, 1, binding);
        });
      }
    },

    updateDataBinding(binding: DataBinding) {
      const indexes = this.dataBindings.map((db, index) => {
        const matchesConditionUuid = binding.conditionUuid
          ? db.conditionUuid === binding.conditionUuid
          : true;
        if (
          db.widgetId === binding.widgetId &&
          db.property === binding.property &&
          matchesConditionUuid
        ) {
          return index;
        }
      });
      this.hasUnsavedChanges = true;
      indexes.forEach((index) => {
        if (typeof index === "number") {
          this.dataBindings.splice(index, 1);
        }
      });
      this.dataBindings.push(binding);
    },

    async undoableRemapAction(payload: RemapCollectionPayload) {
      const { replacements } = payload;

      // Remap the collection bindings
      replacements.forEach((replacement) => {
        const oldBinding = replacement.oldBinding;
        const newBinding = replacement.newBinding;

        // Because bindings don't have a uuid (primary key) we search with multiple properties
        const existingBindingIndex = this.dataBindings.findIndex(
          (db) =>
            db.bindingType === oldBinding.bindingType &&
            db.dataUuid === oldBinding.dataUuid &&
            db.dataConnectionUuid === oldBinding.dataConnectionUuid &&
            db.property === oldBinding.property &&
            db.widgetId === oldBinding.widgetId
        );

        if (existingBindingIndex === -1) {
          console.log(JSON.stringify(oldBinding, null, 2));
          throw new Error("unable to find existing binding to replace");
        }

        if (typeof newBinding === "undefined") {
          /**
           * If the new binding is 'undefined' that means the user did
           * not select a replacement column from their new dataset and
           * the existing binding should be deleted.
           */
          this.dataBindings.splice(existingBindingIndex, 1);
        } else {
          // Copy all properties from the new binding onto the existing one.
          const binding = this.dataBindings[existingBindingIndex];
          Object.keys(newBinding).forEach((key) => {
            (binding as any)[key] = (newBinding as any)[key];
          });
        }
      });

      // Update Text bindings
      this.updateTextBindings({ replacements });

      // Gather all databound widgets where the user did not provide a replacement
      const deletedBindings = payload.replacements.filter(
        (r) => typeof r.newBinding === "undefined"
      );

      //////////////////////////////////////////////////////////////////////////////////////////
      //  DELETE EMPTY TEXT & IMAGE WIDGETS
      //////////////////////////////////////////////////////////////////////////////////////////

      const conditions = useConditionGroupsStore().activeWidgetConditionsMap;

      // Find any text widgets which are now "empty" and remove them.
      const emptyTextWidgetIds = deletedBindings
        .map((r) => this.widgets[r.oldBinding.widgetId])
        .map((w) => getActiveWidget(w, conditions))
        .filter(
          (w) =>
            w.type === "Text" && isTextContentEmpty((w as TextOptions).content)
        )
        .map((w) => w.wid);

      const emptyImageWidgets = deletedBindings
        .map((r) => this.widgets[r.oldBinding.widgetId])
        .map((w) => getActiveWidget(w, conditions))
        .filter((w) => {
          // Return the image widgets which now have zero dataBindings.
          return (
            w.type === "Image" &&
            this.dataBindings.filter((db) => db.widgetId === w.wid).length === 0
          );
        })
        .map((w) => w.wid);

      const widgetsToDelete: string[] =
        emptyTextWidgetIds.concat(emptyImageWidgets);

      if (widgetsToDelete.length > 0) {
        this.removeWidgets({ wids: widgetsToDelete }, "NO_UNDO");
      }

      //////////////////////////////////////////////////////////////////////////////////////////
      //  RESET DEFAULT PROPERTIES
      //////////////////////////////////////////////////////////////////////////////////////////

      // Loop through each one and set the default binding value for that widget property
      const widgetProps = deletedBindings
        // Filter out any widgets that were _just_ deleted
        .filter((r) => !widgetsToDelete.includes(r.oldBinding.widgetId))
        // Generate a mapping of widget, type, property
        .map((r) => {
          const w = this.widgets[r.oldBinding.widgetId];
          return {
            property: r.oldBinding.property,
            widgetId: w.wid,
            type: w.type,
          };
        })
        // Remove any text content bindings (since they were already dealt with)
        .filter((x) => !(x.type === "Text" && x.property === "content"));

      if (widgetProps.length > 0) {
        this.resetWidgetProperties({ properties: widgetProps });
      }
    },

    /**
     * Called when a user replaces a connection that is bound to a Repeater or Slide.
     * This updates
     *   - the `data` binding for the repeater/slide
     *   - any bindings for child widgets bound to data in the replaced connection
     *     including any text token bindings
     * @returns
     */
    async remapCollection(payload: RemapCollectionPayload) {
      const { replacements, newConnection } = payload;

      this.undoableRemapAction(payload);

      // Make sure we invalidate cache for both connections, to ensure widget fetches fresh data after remap:
      useAppDataStore().invalidateCacheForConnection(newConnection?.uuid);
      useAppDataStore().invalidateCacheForConnection(
        replacements[0]?.oldBinding?.dataConnectionUuid || ""
      );

      // Ensure info about new connection is reflected in left-hand panel, if it is open to "data" submenu:
      const connectionEditor = useConnectionEditorStore();
      connectionEditor.setConnection(newConnection);
      connectionEditor.previouslySelectedConnection = newConnection;
      useConnectionDataStore().fetchConnectionData({
        connectionId: newConnection?.uuid,
      });

      return this.updateApp();
    },

    removeDataBinding(
      payload: {
        widgetId?: string;
        property: string;
        conditionUuid?: string;
        dataUuid?: string;
      },
      NO_UNDO?: NO_UNDO
    ) {
      // const { widgetId, property, conditionId } = payload;

      const index = this.dataBindings.findIndex((db) => {
        let result = true;
        Object.keys(payload).forEach((key) => {
          if (key in db && (db as any)[key] !== (payload as any)[key]) {
            result = false;
          }
        });
        return result;
      });
      if (index > -1) {
        this.hasUnsavedChanges = true;
        this.dataBindings.splice(index, 1);
      }
    },

    cloneDataBindingsForNewCondition(args: {
      widgetId: string;
      conditionUuid: string;
    }) {
      const copies: DataBinding[] = [];

      this.dataBindings.forEach((db) => {
        /**
         * This method may have been called immediately after
         * adding the first condition for a widget. If this case
         * existing databindings will not have a conditionUuid.
         *
         * So we should first loop through and ensure that all
         * data bindings that should have a condition id, have one.
         */
        if (
          db.widgetId === args.widgetId &&
          isConditionalBindingType(db.bindingType)
        ) {
          // Set the default condition id for the binding
          if (typeof db.conditionUuid === "undefined") {
            db.conditionUuid = DEFAULT_CONDITION_ID;
          }

          // Make a copy
          copies.push(
            Object.assign({}, db, {
              conditionUuid: args.conditionUuid,
            })
          );
        }
      });

      copies.forEach((db) => {
        this.dataBindings.push(db);
      });
    },

    removeDataBindingsForConditions(args: {
      conditionUuids: string[];
      widgetId?: string;
    }) {
      if (
        typeof args.conditionUuids === "undefined" ||
        args.conditionUuids.length === 0
      ) {
        return;
      }

      // Replace entire array of dataBindings with a filtered list
      // https://v2.vuejs.org/v2/guide/list.html#Replacing-an-Array
      this.dataBindings = this.dataBindings.filter((db) => {
        if (typeof db.conditionUuid === "undefined") {
          return true;
        }
        let shouldRemoveBinding = args.conditionUuids.includes(
          db.conditionUuid
        );

        // If a widgetId is passed in, only filter out the binding if it is attached to that widget
        if (args.widgetId) {
          shouldRemoveBinding =
            shouldRemoveBinding && args.widgetId === db.widgetId;
        }
        return !shouldRemoveBinding;
      });
    },

    removeDataTokenBinding(payload: {
      widgetId: string;
      dataUuid: string;
      conditionUuid?: string;
    }) {
      const { widgetId, dataUuid, conditionUuid } = payload;
      const index = this.dataBindings.findIndex((db) => {
        let isTarget = db.widgetId === widgetId && db.dataUuid === dataUuid;
        if (conditionUuid) {
          isTarget = isTarget && db.conditionUuid === conditionUuid;
        }
        return isTarget;
      });

      if (index > -1) {
        this.dataBindings.splice(index, 1);
      }
    },

    bindingsForComponent(searchOptions: BindingsSearchOptions) {
      if (searchOptions.widgetId === undefined) {
        return [];
      }
      const bindings = this.dataBindings.filter((db) => {
        return (
          db.widgetId === searchOptions.widgetId &&
          (typeof searchOptions.dataConnectionUuid !== "undefined"
            ? db.dataConnectionUuid === searchOptions.dataConnectionUuid
            : true) &&
          (typeof searchOptions.dataParentUuid !== "undefined"
            ? db.dataParentUuid === searchOptions.dataParentUuid
            : true) &&
          (typeof searchOptions.bindingType !== "undefined"
            ? db.bindingType === searchOptions.bindingType
            : true) &&
          (typeof searchOptions.property !== "undefined"
            ? db.property === searchOptions.property
            : true) &&
          (typeof searchOptions.conditionUuid !== "undefined"
            ? db.conditionUuid === searchOptions.conditionUuid
            : true)
        );
      });

      // TODO: determine where we want connections to live
      const connectionSources = (
        useConnectionsStore().connections as DataConnection[]
      ).reduce(
        (obj: any, c: DataConnection) =>
          Object.assign(obj, { [c.uuid]: c.schemaType }),
        {}
      );

      return bindings.filter((b) => {
        return typeof searchOptions.schemaType !== "undefined" &&
          typeof b.dataConnectionUuid === "string"
          ? connectionSources[b.dataConnectionUuid] === searchOptions.schemaType
          : true;
      });
    },

    // These two are from connection.ts

    replaceDataBinding(args: { connection: DataConnection; widget: Widget }) {
      const appEditor = useAppEditorStore();
      const dataBindings = appEditor.dataBindings;
      const { connection, widget } = args;
      const binding = dataBindings.find(
        (db) => db.property === "data" && db.widgetId === widget.wid
      );

      if (binding === undefined) {
        return;
      }

      const newBinding: DataBinding = {
        ...binding,
        dataConnectionUuid: connection.uuid,
        dataUuid: connection.nodeSets[0].uuid as string,
      };
      appEditor.updateDataBinding(newBinding);

      return useConnectionDataStore().initializeConnection(
        connection as DataConnection
      );
    },

    // Used in Preview and SetupComplete (and now also in ScalarSelect)
    createDataBinding(args: {
      connection: DataConnection;
      widget: Widget;
      property: DataBindingProperty;
      isScalar?: boolean; // Technically redundant; presence of query indicates same thing
      query?: string;
      dataUuid?: string;
      conditionUuid?: string;
    }) {
      // Starting to feel like createScalarBinding could be own thing...
      const {
        connection,
        widget,
        property,
        isScalar,
        query,
        dataUuid,

        conditionUuid,
      } = args;

      const bindingType = isScalar
        ? "Scalar"
        : widget.type === "Repeater"
        ? "DataSetParent"
        : "DataSet";

      const uuid = isScalar
        ? (dataUuid as string)
        : (connection.nodeSets[0].uuid as string);

      // TODO: pass in real name somehow...
      const dataName = isScalar ? query : connection.nodeSets[0].name;

      const binding: DataBinding = {
        widgetId: widget.wid,
        property,
        dataUuid: uuid,
        dataName,
        dataConnectionUuid: connection.uuid,
        bindingType,
        parentWidgetId: BASE_PARENT_ID,
      };
      if (isScalar) {
        binding.query = query;
        binding.conditionUuid = conditionUuid;
      }

      // console.log(JSON.stringify(binding));

      const appEditor = useAppEditorStore();
      appEditor.addDataBinding(binding);

      // Calling initializeConnection with scalar bindings breaks things.. So we use refreshWidgetData instead
      if (isScalar) return;

      // This is what must be called to get datasetchooser and datasetexplorer to recognize data
      // (pushes it on to this.connections, AND loads data)
      const store = useConnectionDataStore();
      store.initializeConnection(connection as DataConnection);
    },

    createDataToken(options: CreateDataTokenOptions) {
      const {
        widgetId,
        conditionId,
        connectionId,
        parentWidgetId,
        textTokenUuid,
        isScalar,
        content,
        query,
        dataParentUuid,
        dataUuid,
        shouldCreateRepeaterBinding,
      } = options;

      const binding: DataBinding = {
        widgetId: widgetId,
        property: isScalar
          ? (`content_${textTokenUuid}` as DataBindingProperty)
          : "content",
        parentWidgetId: parentWidgetId ? parentWidgetId : BASE_PARENT_ID,
        dataUuid: dataUuid,
        query: query,
        bindingType: isScalar ? "Scalar" : "DataSetNode",
        dataConnectionUuid: connectionId,
        conditionUuid: conditionId,
      };
      if (dataParentUuid && !isScalar) {
        binding.dataParentUuid = dataParentUuid;
        delete binding.query;
      }

      if (shouldCreateRepeaterBinding) {
        const repeaterBinding: DataBinding = {
          dataConnectionUuid: connectionId,
          dataUuid: dataParentUuid as string,
          bindingType: "DataSetParent",
          widgetId: parentWidgetId,
          parentWidgetId: BASE_PARENT_ID,
          property: "data",
        };

        this.addDataBinding(repeaterBinding, "NO_UNDO");
      }

      this.addDataBinding(binding as DataBinding, "NO_UNDO");
      this.setWidgetProps([widgetId], { content }, "NO_UNDO");
    },

    /**
     * Point of entry for all undo-able "dragDrop"-initiated actions.
     * (Create widget from usage prompt, or from bare drop event.)
     * Handles creating a repeater if needed, to house the new widget, and a repeater binding if none exists.
     * All of these actions must be "chained together" here for undo/redo to work properly.
     *
     * The binding should be scalar when:
     * - user drops node from Tree connection into Repeater,
     * - user drops node from Tree connection onto canvas,
     * - user drops node from Collection onto canvas and chooses "use on its own"
     *
     * And it should be be non-scalar (node binding) when user drops node:
     * - into a non-data-bound Repeater,
     * - or from the same collection that is already bound to the Repeater.
     * - or from a foreign collection.
     */
    createDynamicWidget(
      args: {
        isNewRepeater: boolean;
        hoveredPhotoDropWidgetId: string;
        hoveredPhotoDropWidgetConditionId?: string;
        isScalar: boolean;
        draggingInfo: DraggingInfo | null;
        widgetId: string;
        parentWidgetId?: string | null;
      },
      newRepeaterInfo: {
        rows: number;
        columns: number;
        isSlide: boolean;
        shouldCycle: boolean;
        repeaterWidgetId: string;
      } | null,
      repeaterBindingWidgetId: string | null
    ) {
      const {
        hoveredPhotoDropWidgetId,
        hoveredPhotoDropWidgetConditionId,
        isScalar,
        isNewRepeater,
        draggingInfo,
        widgetId,
        parentWidgetId,
      } = args;

      const connectionEditor = useConnectionEditorStore();

      // console.log("create dynamic widget", draggingInfo);

      let repeaterWidget: Widget | null = null;

      if (!!newRepeaterInfo) {
        const { isSlide, shouldCycle, rows, columns, repeaterWidgetId } =
          newRepeaterInfo;

        const options: Partial<RepeaterOptions> = createRepeaterDimensions(
          this.artboard
        );
        if (isSlide) {
          options.columns = 1;
          options.rows = 1;
        }
        if (rows && columns) {
          options.rows = rows;
          options.columns = columns;
        }
        if (shouldCycle === false) {
          options.cycleContent = false;
        }
        options.wid = repeaterWidgetId;
        repeaterWidget = CreateRepeater(options);

        this.addWidgetAction({
          widget: repeaterWidget as unknown as Widget,
          track: false,
        });
      }

      // ======

      /**
       * This is a bandaid fix for a bug where selectedConnection.nodeSets is cleared out to null when user opens Data manager view.
       * We can fallback to relying on connectionData.uuid based on the connection that is live in the left hand panel.
       */
      const dataUuid =
        draggingInfo?.dataParentUuid ||
        (draggingInfo as any)?.nodeSetUuid ||
        useConnectionEditorStore().connection?.nodeSets?.[0]?.uuid ||
        useConnectionDataStore().connectionData?.uuid;

      if (repeaterBindingWidgetId || repeaterWidget) {
        const repeaterBinding: DataBinding = {
          dataConnectionUuid: draggingInfo?.connectionUuid,
          dataUuid: dataUuid as string,
          bindingType: "DataSetParent",
          widgetId: repeaterBindingWidgetId
            ? repeaterBindingWidgetId
            : repeaterWidget?.wid ?? "",
          parentWidgetId: BASE_PARENT_ID,
          property: "data",
        };

        this.addDataBinding(repeaterBinding, "NO_UNDO");
      }

      // ======

      if (!draggingInfo) return;

      const { x, y } = draggingInfo?.dropPoint || { x: 0, y: 0 };

      const parentWid =
        repeaterWidget?.wid || parentWidgetId || this.editingContext?.parentId;
      const artboard = this.artboard;

      let widget: any = {
        wid: widgetId,
        x: x,
        y: y,
      };

      if (isNewRepeater) {
        // NOTE: Maybe center in repeater? Not sure
        widget.x = 20;
        widget.y = 20;
      }

      const binding: Partial<DataBinding> = {
        dataConnectionUuid:
          draggingInfo?.connectionUuid ?? connectionEditor.connection?.uuid,
        dataUuid: draggingInfo?.dataUuid || "",
        bindingType: isScalar ? "Scalar" : "DataSetNode",
        widgetId: widget.wid || "",
        parentWidgetId: parentWid,
        conditionUuid: DEFAULT_CONDITION_ID,
      };

      if (isScalar) {
        binding.query = draggingInfo?.query;
      } else {
        binding.dataParentUuid =
          draggingInfo?.dataParentUuid ||
          connectionEditor.connection?.nodeSets?.[0]?.uuid;
      }

      if (typeof binding.parentWidgetId === "undefined") {
        delete binding.parentWidgetId;
      }

      const parentRepeater = this.widgetById(parentWid);

      let cellWidth = artboard.w * 0.3;

      // widgetById returns {} if none is found, so use presence of "wid" to test existence
      if (parentRepeater !== undefined && "wid" in parentRepeater) {
        const { w, scaleX, columns } =
          parentRepeater as unknown as RepeaterOptions;
        cellWidth = (w * scaleX) / columns;
      }

      let skipWidgetCreation = false;

      if (hoveredPhotoDropWidgetId) {
        const targetWidget = this.widgetById(hoveredPhotoDropWidgetId);
        const isBackgroundImage = targetWidget?.type !== "Image";

        binding.property = isBackgroundImage ? "backgroundImageUrl" : "url";
        binding.widgetId = hoveredPhotoDropWidgetId;
        binding.conditionUuid = hoveredPhotoDropWidgetConditionId;

        skipWidgetCreation = true;
      }

      // Handle data type of Object... sometimes actually a Datetime.. like Weather data tree
      let dataType = draggingInfo?.dataType;

      if (dataType === "Object" && draggingInfo?.children?.[0]) {
        dataType = draggingInfo?.children[0].dataType;
      }

      if (!skipWidgetCreation) {
        switch (dataType) {
          case "Bool":
          case "String":
          case "Number":
          case "Color":
          case "Url":
          case "Object":
            binding.property = isScalar
              ? (`content_${makeId()}` as DataBindingProperty)
              : "content";
            widget = CreateText(widget);
            break;
          case "ImageUrl":
          case "ImageUpload":
            widget = CreateImage(widget);
            binding.property = "url";
            break;

          case "Date":
            binding.property = "datetimeValue";
            widget.isDate = true;
            widget = CreateDatetime(widget);
            break;
          case "Time":
            binding.property = "datetimeValue";
            widget.isTime = true;
            widget.datetimeFormat = JSON.stringify({
              hour: "numeric",
              minute: "numeric",
            });
            widget = CreateDatetime(widget);
            break;
          case "DateTime":
            binding.property = "datetimeValue";
            widget = CreateDatetime(widget);
            break;
        }

        if (
          !["ImageUrl", "ImageUpload", "Date", "Time", "DateTime"].includes(
            dataType || ""
          )
        ) {
          widget.content = makeDynamicTextContent(
            binding.dataUuid || "",
            draggingInfo?.formattedValue || ""
          );

          // Stretch: Perhaps double width until it contains largest word?
          widget.canScaleY = false;
          widget.w = 3840;
          widget.fontSize = Math.floor(cellWidth / 10);
          // eslint-disable-next-line no-case-declarations
          const size = measureText(widget, undefined, true);
          widget.h = size.h;
          widget.w = cellWidth * 0.5;
        }
      }

      const addWidgetPayload: any = {
        widget,
      };

      // If we are dropping node into a repeater...
      if (parentWid) {
        addWidgetPayload.preserveParentId = true;
        addWidgetPayload.widget.parentId = parentWid;
      }

      let realWidgetId = widget.wid;

      if (!skipWidgetCreation) {
        this.addWidgetAction({ ...addWidgetPayload, track: false });
      } else {
        realWidgetId = hoveredPhotoDropWidgetId;

        /**
         * Handle case of user choosing "New Repeater" --
         * Requires us to *transport* the existing target widget into the new repeater.
         */
        if (isNewRepeater) {
          this.addToNewRepeater(
            {
              wid: hoveredPhotoDropWidgetId as string,
              parentWidgetId: parentWid as string,
            },
            "NO_UNDO"
          );
        }

        // Remove data binding if it exists
        this.removeDataBinding(
          {
            widgetId: hoveredPhotoDropWidgetId as string,
            property: binding.property as DataBindingProperty,
            conditionUuid: hoveredPhotoDropWidgetConditionId,
          },
          "NO_UNDO"
        );

        if (binding.property === "backgroundImageUrl") {
          const props = {
            backgroundImageW: draggingInfo?.width,
            backgroundImageH: draggingInfo?.height,
          };
          this.setWidgetProps(
            [hoveredPhotoDropWidgetId as string],
            props,
            "NO_UNDO"
          );
        }
      }

      this.addDataBinding(binding as DataBinding, "NO_UNDO");
      this.replaceSelections([realWidgetId]);

      return repeaterWidget;
    },

    unbindProperty(args: {
      widgetId: string;
      conditionUuid: string;
      propertyName: string;
      value: any;
    }) {
      const { widgetId, conditionUuid, propertyName, value } = args;
      this.setWidgetProps(
        [widgetId],
        {
          [propertyName]: value, // Use freshly-unbound value for static value
        },
        "NO_UNDO"
      );
      this.removeDataBinding(
        {
          widgetId: widgetId,
          property: propertyName,
          conditionUuid: conditionUuid,
        },
        "NO_UNDO"
      );
    },
  },
});
