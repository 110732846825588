<template>
  <div class="relative flex">
    <LightningBoltIcon
      v-if="showBolt"
      :on="!!dataBinding || showDropdown"
      @click.native.stop="showDropdown = !showDropdown"
    />

    <div
      v-if="showDropdown"
      class="absolute right-0 bg-white shadow-lg rounded w-40"
      style="top: 30px; z-index: 2000"
    >
      <div
        v-for="col in connectionColumns"
        :key="col.value"
        class="w-full flex items-center justify-between px-3 py-2 hover:bg-app-teal hover:text-white"
        @click.stop="selectColumn(col)"
      >
        <div>{{ col.label }}</div>

        <IconSolid
          v-if="isSelected(col.value)"
          name="Checkmark"
          class="h-4 w-4"
        />
      </div>
    </div>

    <Tooltip v-if="dataBinding" :text="$t('LightningBolt.unbind')" position="l">
      <UnbindIcon
        class="inline-block -mx-2 cursor-pointer"
        @click.native.stop="onUnbind"
      />
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import { DataBinding, SchemaNode, DataBindingProperty } from "@/types/data";
import { removeReactivity } from "@/utils";
import IconSolid from "@/components/icons/IconSolid.vue";
import UnbindIcon from "@/components/icons/UnbindIcon.vue";
import Tooltip from "@/components/Tooltip.vue";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { useAppEditorStore } from "@/stores/appEditor";
import { logger } from "@core/logger";
import { useConnectionsStore } from "@/stores/connections";

/**
 * NOTE: Copying almost all logic from DataColumnChooser
 *
 **/

@Component({
  components: {
    LightningBoltIcon,
    IconSolid,
    UnbindIcon,
    Tooltip,
  },
})
export default class ChildBindingDropdown extends Vue {
  @Prop(String) propType: string;
  @Prop(String) propName: string;

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  showDropdown = false;

  closeDropdown() {
    this.showDropdown = false;
  }

  mounted() {
    document.addEventListener("click", this.closeDropdown);
  }

  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  }

  get parentBinding() {
    if (this.selectedWidget === undefined) {
      return undefined;
    }
    return this.appEditor.bindingsForComponent({
      widgetId: this.selectedWidget.parentId,
      bindingType: "DataSetParent",
    })[0];
  }

  get conditionGroupsStore() {
    return useConditionGroupsStore();
  }

  get activeConditionId() {
    if (this.selectedWidget === undefined) {
      return undefined;
    }
    return this.conditionGroupsStore.getActiveConditionId(
      this.selectedWidget?.wid
    );
  }

  get dataBinding() {
    if (this.selectedWidget === undefined) {
      return undefined;
    }
    return this.appEditor.bindingsForComponent({
      widgetId: this.selectedWidget.wid,
      bindingType: "DataSetNode",
      property: this.propName,
      conditionUuid: this.activeConditionId,
    })[0];
  }

  get connection() {
    const store = useConnectionsStore();
    return store.connections.find(
      (c) => c.uuid === this.parentBinding?.dataConnectionUuid
    );
  }

  dataTypeFilter(node: SchemaNode): boolean {
    switch (this.propType) {
      case "Image":
        return node.dataType === "ImageUrl" || node.dataType === "ImageUpload";
      case "Color":
        return node.dataType === "Color";
      case "Number":
        return node.dataType === "Number";
      case "DateTime":
        return !!node.dataType?.match(/Date|Time/);
      case "Text":
        return true;
      default:
        return false;
    }
  }

  isSelected(uuid: string | undefined) {
    return this.dataBinding?.dataUuid === uuid;
  }

  selectColumn(col: { label: string; value: string | undefined }) {
    if (this.selectedWidget === undefined) {
      logger.track("ChildBindingDropdown: selectedWidget is undefined");
      return;
    }

    if (this.parentBinding === undefined) {
      logger.track("ChildBindingDropdown: parentBinding is undefined");
      return;
    }
    // uuid of schema node
    const uuid = col.value || "";

    if (this.dataBinding) {
      const binding = removeReactivity<DataBinding>(this.dataBinding);
      binding.dataUuid = uuid;
      this.appEditor.updateDataBinding(binding);
    } else {
      const newBinding: DataBinding = {
        widgetId: this.selectedWidget.wid,
        property: this.propName as DataBindingProperty,
        dataUuid: uuid,
        bindingType: "DataSetNode",
        dataConnectionUuid: this.connection?.uuid,
        dataParentUuid: this.parentBinding.dataUuid,
        parentWidgetId: this.selectedWidget.parentId,
        conditionUuid: this.activeConditionId,
      };
      // console.log("new binding", newBinding);

      this.appEditor.addDataBinding(newBinding);

      // No need to refresh widget data, because the parent already has the dataset
    }

    this.closeDropdown();
  }

  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];
      // NOTE: Again, this is crude -- just taking the first widget's val as the group's val
      // if (values.length === 1) {
      result = values[0];
      // }
    }
    return result as T;
  }

  onUnbind() {
    if (this.selectedWidget === undefined) {
      logger.track(
        "ChildBindingDropdown.onUnbind: selectedWidget is undefined"
      );
      return;
    }
    if (this.activeConditionId === undefined) {
      logger.track(
        "ChildBindingDropdown.onUnbind: activeConditionId is undefined"
      );
      return;
    }
    this.appEditor.unbindProperty({
      widgetId: this.selectedWidget.wid,
      conditionUuid: this.activeConditionId,
      propertyName: this.propName,
      value: this.getValue(this.propName),
    });
  }

  get showBolt() {
    return this.connectionColumns.length > 0;
  }

  get connectionColumns() {
    return (
      this.connection?.nodeSets?.[0]?.nodes
        ?.filter((n) => this.dataTypeFilter(n as SchemaNode))
        .map((n) => {
          return { label: n.name, value: n.uuid };
        }) || []
    );
  }
}
</script>
