import { defineStore } from "pinia";
import { api } from "@/api/backend";
import { SavedAsset, StyleKit } from "@/types";
import { SearchRequest, SearchResponse, StockAsset } from "@/types/photos";
import { logger } from "@core/logger";

// Eventually we should rename SavedAsset to Asset
type Asset = SavedAsset;

// 49 = Pixabay
const SearchEndpoint = "assets/stock/49/search";
const AssetEndpoint = (assetId: string) => `assets/stock/49/${assetId}`;

export interface AssetsState {
  lastRequest: SearchRequest | null;
  loadingStockAsset: boolean;
  loadingSearchResults: boolean;
  searching: boolean;
  stockAssets: StockAsset[];
  searchPage: number;
  searchResultCount: number | null;

  userAssets: Asset[];
  styleKitUuid: string | null;
}

const searchRequestsAreEqual = (
  a: SearchRequest | null,
  b: SearchRequest | null
) => {
  if (a === null || b === null) {
    return false;
  }

  return (
    a.query === b.query &&
    a.page === b.page &&
    a.limit === b.limit &&
    a.orientation === b.orientation &&
    a.color === b.color
  );
};

export const useAssetStore = defineStore("assets", {
  state: (): AssetsState => {
    return {
      lastRequest: null,
      loadingStockAsset: false,
      loadingSearchResults: false,
      searching: false,
      searchPage: 1,
      searchResultCount: null,
      stockAssets: [],
      userAssets: [],
      styleKitUuid: null,
    };
  },
  getters: {
    userVideos(): Asset[] {
      return this.userAssets.filter((a) => a.type?.startsWith("video/"));
    },
    userImages(): Asset[] {
      return this.userAssets.filter((a) => a.type?.startsWith("image/"));
    },
  },

  actions: {
    clearSearch() {
      this.searching = false;
      this.searchPage = 1;
      this.searchResultCount = null;
      this.stockAssets = [];
    },
    searchStockAssets(request: SearchRequest) {
      if (!searchRequestsAreEqual(this.lastRequest, request)) {
        this.clearSearch();
      }

      this.searching = true;
      this.loadingSearchResults = true;
      request.limit = 50;
      request.page = this.searchPage;

      this.lastRequest = { ...request };
      return api
        .post<SearchResponse>(SearchEndpoint, request)
        .then((response) => {
          this.stockAssets = this.stockAssets.concat(response.items);
          this.searchPage = response.page;
          this.searchResultCount = response.count;
        })
        .finally(() => {
          this.loadingSearchResults = false;
        });
    },

    /**
     * Fetch the next "page" of search results
     */
    pageAssets(request: SearchRequest) {
      if (
        this.searchResultCount === null ||
        this.stockAssets.length >= this.searchResultCount
      ) {
        return;
      }
      this.searchPage += 1;
      this.searchStockAssets(request);
    },

    getStockAsset(id: string) {
      this.loadingStockAsset = true;
      return api
        .get<SavedAsset>(AssetEndpoint(id))
        .then((r: SavedAsset) => {
          return r;
        })
        .finally(() => {
          this.loadingStockAsset = false;
        });
    },

    async saveUserAsset(asset: Asset) {
      await this.ensureStyleKitExists();
      return api
        .post("stylekit/" + this.styleKitUuid + "/asset/" + asset.uuid)
        .then(() => {
          this.userAssets.push(asset);
        });
    },

    removeUserAsset(assetId: string) {
      if (this.styleKitUuid === null) {
        logger.warn(
          "Can't remove an asset when a stylekit doesn't exist for this account"
        );
        return;
      }

      return api
        .delete("stylekit/" + this.styleKitUuid + "/asset/" + assetId)
        .then(() => {
          const index = this.userAssets.findIndex((a) => a.uuid === assetId);
          this.userAssets.splice(index, 1);
        });
    },

    async loadUserAssets() {
      await this.ensureStyleKitExists();
      return this.userAssets;
    },

    /**
     * Don't call this method from a component
     * It is only meant to be used from other methods
     * in the savedAssets pinia store
     */
    async ensureStyleKitExists() {
      // If uuid exists. we have already loaded stylekit.
      if (this.styleKitUuid !== null) {
        return;
      }

      const kits = await api.get<StyleKit[]>("stylekit");

      if (Array.isArray(kits) && kits.length > 0) {
        this.styleKitUuid = kits[0].uuid;
        this.userAssets = kits[0].images;
        return;
      }

      const emptyKit = {
        images: [],
        textStyles: [],
        customFonts: [],
        palettes: [],
      };

      return api.post<StyleKit>("stylekit", emptyKit).then((kit) => {
        this.styleKitUuid = kit.uuid;
        this.userAssets = kit.images;
      });
    },
  },
});
