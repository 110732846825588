<template>
  <div class="space-y-2 -mt-2">
    <div class="space-y-1">
      <InputLabel v-t="'SelectQueryStringValue.parameterName'"></InputLabel>
      <input
        class="w-full px-2 py-1 rounded-sm font-mono text-black"
        :placeholder="$t('SelectQuerystringValue.parameterName').toString()"
        type="text"
        :value="model.value"
        @input="handleValueInput"
        :class="{
          'outline outline-red-500 bg-red-50 dark-form-focus-error': isInvalid,
          'dark-form-focus': !isInvalid,
        }"
      />
      <div
        v-if="isInvalid"
        class="mt-2 inline-block border border-red-800 px-2 rounded bg-red-700/10 text-center text-xs text-red-500"
        v-t="'ConditionsEditor.required'"
      ></div>
    </div>

    <div class="space-y-1">
      <InputLabel class="space-x-2">
        <span v-t="'SelectQueryStringValue.defaultValue'"></span>
        <span
          class="text-gray-400"
          v-t="'SelectQueryStringValue.optional'"
        ></span
      ></InputLabel>
      <StaticInput
        class="w-full"
        :inputType="inputType"
        :value="model.default"
        :min="min"
        :max="max"
        @input="handleDefaultInput"
      />

      <InputHelpText v-t="'SelectQueryStringValue.defaultHelp'">
      </InputHelpText>
    </div>
  </div>
</template>

<script lang="ts">
import { LogicOperand } from "@/types/logic";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { OperandChangedEvent } from "./OperandChangedEvent";
import { useConditionEditorStore } from "@/stores/conditionEditor";
import StaticInput from "./StaticInput.vue";
import InputHelpText from "./InputHelpText.vue";
import InputLabel from "./InputLabel.vue";
import { OperandInputValueType } from "./OperandSelectType";

@Component({ components: { StaticInput, InputHelpText, InputLabel } })
export default class SelectQueryStringValue extends Vue {
  @Prop(String) inputType: OperandInputValueType;
  @Prop(Object) model: LogicOperand;
  @Prop(Number) min: number | undefined;
  @Prop(Number) max: number | undefined;

  isDirty = false;

  mounted() {
    const el = this.$el.querySelector("input");
    if (typeof el !== "undefined") {
      el?.focus();
    }
  }

  get store() {
    return useConditionEditorStore();
  }

  get isInvalid() {
    return (
      (this.isDirty || this.store.showErrors) &&
      !isNonEmptyString(this.model.value)
    );
  }

  handleValueInput(e: Event) {
    const payload: OperandChangedEvent = {
      operand: {
        value: (e.target as HTMLInputElement).value,
        default: this.model.default,
      },
    };
    this.isDirty = true;
    this.$emit("input", payload);
  }

  handleDefaultInput(defaultValue: string) {
    const payload: OperandChangedEvent = {
      operand: {
        value: this.model.value,
        default: defaultValue,
      },
    };
    this.$emit("input", payload);
  }

  @Watch("inputType")
  onInputTypeChanged(
    newType: OperandInputValueType,
    oldType: OperandInputValueType
  ) {
    /**
     * List types (day of week, or month) will auto-select a value.
     * If the inputType changes, we want to 'reset' the value to blank,
     * but we don't set isDirty here because the user didn't do it themselves.
     */
    const listTypes: OperandInputValueType[] = ["DayOfWeek", "Month"];
    if (listTypes.includes(oldType) && !listTypes.includes(newType)) {
      this.handleDefaultInput("");
    }
  }
}
</script>
