<script lang="ts">
import { Component } from "vue-property-decorator";
import RepeaterComponent from "@/components/widgets/Repeater/RepeaterComponent.vue";
import { useDragDropStore } from "@/stores/dragDrop";
import { getApparentDims } from "@/utils";
import { RepeaterOptions } from "./RepeaterOptions";
import { useAppEditorStore } from "@/stores/appEditor";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { useConnectionsStore } from "@/stores/connections";

const computeRepeaterCellOffset = (
  widget: RepeaterOptions,
  cellIdx: number
) => {
  const { rows, columns, rowGap, columnGap, borderWidth, flow } =
    widget as RepeaterOptions;

  const { w, h } = getApparentDims(widget);
  const cellWidth = (w - columnGap * (columns + 1)) / columns;
  const cellHeight = (h - rowGap * (rows + 1)) / rows;

  let r = Math.floor(cellIdx / columns);
  let c = cellIdx % columns;

  if (flow.includes("col")) {
    c = Math.floor(cellIdx / rows);
    r = cellIdx % rows;
  }

  let offsetX = (c + 1) * columnGap + c * cellWidth;
  let offsetY = (r + 1) * rowGap + r * cellHeight;
  offsetX += borderWidth;
  offsetY += borderWidth;
  if (rows === 1 && columns === 1) {
    offsetX = 0;
    offsetY = 0;
  }
  return {
    offsetX,
    offsetY,
    cellWidth,
    cellHeight,
  };
};

@Component
export default class RepeaterWrapper extends RepeaterComponent {
  get dragDropStore() {
    return useDragDropStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get cyclingIndex() {
    return this.appEditor.cyclingIndexes[this.wid];
  }

  created() {
    // cyclingIndex ------------------
    this.$watch(
      () => this.cyclingIndex,
      (value: number | undefined) => {
        if (value !== undefined) {
          this.pageIndex = value;
        }
      }
    );

    // isRepeaterBeingEdited -----------------
    this.$watch(
      () => this.editingContext && this.editingContext.parentId === this.wid,
      (value: boolean) => {
        this.isRepeaterBeingEdited = value;
      }
    );

    // isBaseEditingContext -----------------
    this.$watch(
      () => this.appEditor.isBaseEditingContext,
      (value: boolean) => {
        this.isBaseEditingContext = value;
      }
    );

    // isDraggingItem -----------------
    this.$watch(
      () => this.dragDropStore.isDraggingItem,
      (value: boolean) => {
        this.isDraggingItem = value;
      }
    );

    // isDraggingImage -----------------
    this.$watch(
      () => this.dragDropStore.isDraggingImage,
      (value: boolean) => {
        this.isDraggingImage = value;
      }
    );

    // cellEditIndex -----------------
    this.$watch(
      () => this.editingContext.repeaterIndex,
      (value: number | undefined) => {
        this.cellEditIndex = value ?? null;
      }
    );

    // isHovered -----------------
    this.$watch(
      () => this.appEditor.hoveredId,
      (value: string | undefined) => {
        // console.log("hoveredId changed", value);
        this.isHovered = isNonEmptyString(value) && value === this.wid;
      }
    );

    this.$watch(
      () => this.boundConnection?.moderationMode,
      () => {
        this.setIsModerated();
      }
    );

    this.setIsModerated();
  }

  get connectionsStore() {
    return useConnectionsStore();
  }

  get boundConnection() {
    return this.connectionsStore.connections.find(
      (c) => c.uuid === this.dataBinding?.dataConnectionUuid
    );
  }

  setIsModerated() {
    this.isModerated = this.boundConnection?.moderationMode !== null;
  }

  mounted() {
    const container = this.$refs.container as HTMLDivElement;
    container.addEventListener("mouseleave", this.mouseleaveParent);

    const cells = this.$refs.repeaterCell as HTMLDivElement[];
    cells.forEach((cell) => {
      cell.addEventListener("mouseenter", this.mouseenterCell);
    });
  }

  beforeDestroy() {
    const container = this.$refs.container as HTMLDivElement;
    container.removeEventListener("mouseleave", this.mouseleaveParent);

    const cells = this.$refs.repeaterCell as HTMLDivElement[];
    cells.forEach((cell) => {
      cell.removeEventListener("mouseenter", this.mouseenterCell);
    });
  }

  mouseenterCell(e: MouseEvent) {
    if (!this.dragDropStore?.isDraggingItem || this.locked) return;

    const el = e.target as HTMLDivElement;
    const idx = parseInt(el?.dataset?.idx ?? "-1");
    /**
     * Set cell index so that cell can update styling.
     *
     * Compute and store the offset here so that CanvasTextEditor can access it if needed,
     * when dragging a data token over a text widget child of this repeater.
     */
    const { offsetX, offsetY, cellWidth, cellHeight } =
      computeRepeaterCellOffset(
        { ...this.$props, w: this.width, h: this.height } as RepeaterOptions,
        idx
      );

    this.appEditor.setEditingContext({
      repeaterIndex: idx,
      offsetX: offsetX,
      offsetY: offsetY,
      height: cellHeight,
      width: cellWidth,
      widgetX: getApparentDims(this as any)?.x,
      widgetY: getApparentDims(this as any)?.y,
      parentId: this.wid,
    });
  }

  mouseleaveParent() {
    if (!this.dragDropStore?.isDraggingItem || this.locked) return;

    /**
     * If this mouseleave is triggered by the mouse entering a child text widget,
     * (or by the mouse entering a group widget which itself contains a child text widget),
     * then don't clear hovered repeater info.
     *
     * Same goes when mouseleave occurs due to node drop events.
     */

    const ignoreClearEvent = this.childrenWids.some((wid) => {
      const grandchildren = this.appEditor.parents[wid] || [];
      return (
        wid === this.dragDropStore?.hoveredTextWidgetId ||
        grandchildren.some(
          (gcWid) => gcWid === this.dragDropStore?.hoveredTextWidgetId
        )
      );
    });

    if (ignoreClearEvent) {
      return;
    }
    if (this.dragDropStore?.isHandlingDrop) return;

    this.appEditor.resetEditingContext();
  }
}
</script>
