<template>
  <div class="flex flex-col h-full p-6">
    <component :is="component" :dataBinding="dataBinding" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ScalarSelect from "@/components/data/connections/manage/data/views/ScalarSelect.vue";
import ReadOnlyData from "@/components/data/connections/manage/data/views/ReadOnlyData.vue";
import EditDataTable from "@/components/data/connections/manualSource/EditDataTable.vue";
import { DataBinding } from "@/types/data";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    ScalarSelect,
    EditDataTable,
    ReadOnlyData,
  },
})
export default class Data extends Vue {
  @Prop(String) readonly connectionUuid: string;

  get connection() {
    const store = useConnectionEditorStore();
    return store.connection;
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get component() {
    if (this.dataBinding?.bindingType === "Scalar") {
      return "ScalarSelect";
    }
    if (this.connection?.isCollection) {
      return "EditDataTable";
    }
    if (
      this.selectedWidget?.type.includes("Calendar") ||
      this.selectedWidget?.type.includes("Graph")
    ) {
      return "EditDataTable";
    }
    return "ReadOnlyData";
  }

  mounted() {
    console.log("mount Data.vue", this.component);
  }

  get conditionGroupsStore() {
    return useConditionGroupsStore();
  }

  get activeConditionId() {
    return this.conditionGroupsStore.getActiveConditionId(
      this.selectedWidget?.wid as string
    );
  }

  get dataBinding(): DataBinding | null {
    let bindingType =
      this.selectedWidget?.type === "Repeater" ? "DataSetParent" : "DataSet";

    if (this.$route.query.type) {
      bindingType = "Scalar";
    }

    const boundProp = this.$route.query.property;

    return (
      this.appEditor.dataBindings.find((db) => {
        const conditionMatches = db.conditionUuid
          ? db.conditionUuid === this.activeConditionId
          : true;

        return (
          db.widgetId === this.selectedWidget?.wid &&
          db.bindingType === bindingType &&
          (boundProp ? db.property === boundProp : true) &&
          conditionMatches
        );
      }) || null
    );
  }
}
</script>
