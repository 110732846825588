<template>
  <div>
    <!-- Backdrop -->
    <div
      class="fixed inset-0 flex justify-center items-center bg-gray-400 backdrop-blur-sm bg-opacity-80 z-50"
    ></div>
    <!-- Body Contianer -->
    <div class="fixed inset-8 flex items-center justify-center z-50">
      <!-- Modal Body -->
      <div
        style="min-width: 900px"
        class="bg-app-dark1 text-white rounded-lg h-full flex flex-col w-full shadow-xl"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FilterModal extends Vue {}
</script>
