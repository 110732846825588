<template>
  <div class="flex flex-col flex-grow">
    <!-- Header: -->
    <div class="flex justify-between">
      <div class="flex space-x-4 w-full">
        <input
          id="search-input"
          class="w-2/5 h-8 rounded-lg border border-black"
          type="text"
          placeholder="Find records"
          :value="editableDataStore.searchText"
          @input="searchInputHandler"
          @click="searchClickHandler"
        />

        <!-- Moderation filter: -->
        <div
          class="flex border border-black rounded-sm"
          v-if="moderationMode !== null"
        >
          <div
            class="py-1 px-4 text-sm cursor-pointer flex items-center"
            @click="editableDataStore.moderationFilter = 'Selected'"
            :class="[
              editableDataStore.moderationFilter === 'Selected'
                ? 'bg-app-dark3'
                : 'text-gray-400 bg-app-dark2',
            ]"
          >
            {{ moderationSelectedText }}
          </div>
          <div
            class="border-l border-r border-black py-1 px-4 text-sm cursor-pointer flex items-center"
            @click="editableDataStore.moderationFilter = 'Deselected'"
            :class="[
              editableDataStore.moderationFilter === 'Deselected'
                ? 'bg-app-dark3'
                : 'text-gray-400 bg-app-dark2',
            ]"
          >
            {{ moderationDeselectedText }}
          </div>
          <div
            class="py-1 px-4 text-sm cursor-pointer flex items-center"
            @click="editableDataStore.moderationFilter = 'All'"
            :class="[
              editableDataStore.moderationFilter === 'All'
                ? 'bg-app-dark3'
                : 'text-gray-400 bg-app-dark2',
            ]"
          >
            All
          </div>
        </div>

        <!-- Disconnect success message: -->
        <div
          v-if="editableDataStore.showDisconnectSuccessMessage"
          class="flex space-x-3 rounded-lg bg-app-green items-center px-3 text-sm"
        >
          <div>Your data has been disconnected</div>
          <div @click="editableDataStore.showDisconnectSuccessMessage = false">
            <Icon name="Close" class="w-4 h-4 cursor-pointer" width="4" />
          </div>
        </div>

        <!-- Validation error count/filter: -->
        <div
          v-if="editableDataStore.validationErrorsCount > 0"
          class="flex pl-3 pr-2 py-1 bg-errors-faded border border-errors font-bold text-white cursor-pointer rounded items-center"
          @click="validationErrorsFilterClickHandler"
        >
          <div>
            {{ numberValidationErrorsMessage }}
          </div>
          <div class="ml-2">
            <span v-if="editableDataStore.applyValidationErrorsFilter">
              <Icon class="w-4 h-4" name="Close" stroke-width="4" />
            </span>
            <span v-else>
              <Icon
                class="w-4 h-4"
                name="SizeTriangle"
                fill="white"
                style="transform: rotate(180deg) scale(1.25, 0.75)"
              />
            </span>
          </div>
        </div>

        <!-- Validation error fix message: -->
        <div
          v-if="
            editableDataStore.showFixValidationErrorsMessage &&
            editableDataStore.validationErrorsCount > 0
          "
          class="text-red-500 text-lg italic select-text"
        >
          Please resolve the validation errors with your data before saving.
        </div>

        <div
          v-if="editableDataStore.errorMessage"
          class="text-red-500 text-lg italic select-text"
        >
          {{ editableDataStore.errorMessage }}
        </div>
      </div>

      <div class="flex space-x-4 ml-2">
        <SaveButton v-if="showSaveButton" />

        <div v-if="connection">
          <FileDownloader> </FileDownloader>
        </div>
      </div>
    </div>

    <!-- Table/grid: -->
    <div class="flex mt-3 relative flex-grow" v-if="!awaitingServer">
      <div class="relative flex-grow bg-app-dark1">
        <div class="absolute inset-0 overflow-auto data-grid-container">
          <table class="data-grid text-sm">
            <thead>
              <!-- Data header: -->
              <tr>
                <th><div style="width: 28px"></div></th>
                <th
                  v-for="(key, i) in columns"
                  :key="i"
                  style="min-width: 10rem"
                >
                  {{ key }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="editableDataStore.filteredRows.length > 0">
                <tr
                  v-for="(row, i) in editableDataStore.filteredRows"
                  :key="i"
                  :class="{
                    'selected-row':
                      row.rowUuid === editableDataStore.editingRowUuid,
                  }"
                >
                  <th
                    :class="getHeaderClasses({ uuid: row.rowUuid, index: i })"
                    class="text-gray-200 cursor-pointer group"
                    @mouseenter="hoveredIdx = i"
                    @mouseleave="hoveredIdx = -1"
                    @click.stop="cellHeaderClickHandler($event, row.rowUuid)"
                  >
                    <span class="relative">
                      {{ i + 1 }}
                      <!-- "Expand" icon: -->
                      <span
                        class="absolute right-0 top-0 z-10 opacity-0 group-hover:opacity-100"
                        style="transform: translate(16px, 3px) rotate(180deg)"
                      >
                        <Icon
                          name="SizeTriangle"
                          class="w-3 h-3"
                          fill="rgba(200,200,200,0.5)"
                          stroke="none"
                        />
                      </span>
                    </span>
                  </th>
                  <!-- Data cell: -->
                  <!-- Here we ignore any nodes that correspond to deleted schema nodes -->
                  <td
                    v-for="(node, j) in getDataCells(row)"
                    :key="j"
                    :class="[
                      getCellClasses({
                        rowUuid: row.rowUuid,
                      }),
                    ]"
                    @click.stop="cellClickHandler(row.rowUuid, node)"
                    @mousedown="
                      mousedownHandler({
                        rowUuid: row.rowUuid,
                        columnUuid: node.uuid || '',
                      })
                    "
                    @dblclick="
                      doubleClickCellHandler(
                        $event,
                        row.rowUuid,
                        node.uuid || '',
                        node.dataType
                      )
                    "
                    @keypress.enter="enterKeyHandler"
                    @mouseover="onCellMouseOver($event, node.dataType)"
                    @mouseout="onCellMouseOut($event, node.dataType)"
                  >
                    <component
                      v-if="showCellInput(row.rowUuid, node.uuid || '')"
                      :is="getInputComponent(node)"
                      :node="node"
                      :rowUuid="row.rowUuid"
                      :columnUuid="node.uuid"
                    />

                    <div
                      v-else
                      class="pointer-events-none flex space-x-2"
                      :class="[
                        {},
                        getCellBorderClass({
                          rowUuid: row.rowUuid,
                          columnUuid: node.uuid || '',
                        }),
                      ]"
                    >
                      <div v-if="isColorNode(node)">
                        <!-- Swatch -->
                        <div
                          class="w-6 h-6 rounded swatch-outline"
                          :style="{ backgroundColor: getColor(node) }"
                        ></div>
                      </div>
                      <DataNodeImage
                        class="block object-contain pointer-events-none h-6 w-full"
                        v-if="
                          node.dataType === 'ImageUrl' && node.formattedValue
                        "
                        :uuid="node.uuid"
                        :dataType="node.dataType"
                        :value="{
                          value: node.value,
                          formattedValue: node.formattedValue,
                        }"
                      />
                      <div
                        v-else-if="node.dataType === 'ImageUpload'"
                        class="flex space-x-2 w-full"
                      >
                        <DataNodeImage
                          class="block object-contain pointer-events-none h-6 w-full"
                          v-if="node.assetUuid && node.formattedValue"
                          :uuid="node.assetUuid"
                          :dataType="node.dataType"
                          :value="{
                            value: node.value,
                            formattedValue: node.formattedValue,
                          }"
                        />
                        <div
                          v-else
                          class="flex items-center justify-center text-center pointer-events-none h-6 w-full"
                        >
                          <button
                            class="rounded text-sm text-white border bg-gray-900 select-none dark-form-focus border-gray-400 p-2 border-2 px-4 py-1"
                            type="button"
                            v-t="'manualInputs.upload'"
                          ></button>
                        </div>
                      </div>
                      <div v-else class="truncate-container">
                        <div class="truncate-content">
                          {{ renderValue(node) }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <!-- Empty row: -->
              <tr ref="emptyRow" v-if="isEditable">
                <th
                  class="hover:bg-gray-500 cursor-pointer text-gray-200 text-2xl"
                  style="font-weight: 700; padding: 0.2rem 0"
                  @click.stop="plusClickHandler"
                >
                  +
                </th>
                <td
                  v-for="node in schema"
                  :key="node.uuid || ''"
                  @click.stop="cellClickHandler(emptyRowUuid, node)"
                  @mousedown="
                    mousedownHandler({
                      rowUuid: emptyRowUuid,
                      columnUuid: node.uuid || '',
                    })
                  "
                  @dblclick="
                    doubleClickCellHandler(
                      $event,
                      emptyRowUuid,
                      node.uuid || ''
                    )
                  "
                  @keypress.enter="enterKeyHandler"
                  class="border border-black"
                >
                  <component
                    v-if="showCellInput(emptyRowUuid, node.uuid || '')"
                    :is="
                      getInputComponent({
                        uuid: node.uuid,
                        value: emptyRowValue,
                        formattedValue: emptyRowValue,
                      })
                    "
                    :node="node"
                    :rowUuid="emptyRowUuid"
                  >
                  </component>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="z-10 absolute top-0 left-0 right-0 border-b border-black">
          <!-- Table top border. Hides some of the under-scroll content -->
        </div>
        <div
          class="z-10 absolute top-9 transform translate-y-px left-0 right-4 border-b border-gray-900"
        >
          <!-- Border under table header row. The real border disappears when scrolled. -->
        </div>
        <div class="z-10 absolute top-0 left-0 bottom-0 border-r border-black">
          <!-- Table left border. Hides some of the under-scroll content -->
        </div>
        <div
          class="z-10 absolute w-4 h-4 bottom-0 right-0 border-r border-b border-black bg-app-dark1"
        >
          <!-- This is a small square on the bottom right corner. Otherwise a white box shows -->
        </div>
      </div>

      <EditDataRowForm
        class="w-96"
        v-if="editableDataStore.editingRowUuid"
        :isEditable="isEditable"
      />

      <DataRowDropdown :isEditable="isEditable" />
      <RepositionRowModal />
    </div>

    <div
      v-if="
        editableDataStore.filteredRows.length === 0 &&
        editableDataStore.searchText !== ''
      "
      class="absolute top-0 left-0 w-full flex justify-center items-center text-lg text-white pointer-events-none"
    >
      <div class="flex justify-center items-center">
        There are no records that match your search.
      </div>
    </div>

    <!-- <portal-target id="gridColorPicker" name="gridColorPicker"> </portal-target> -->
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

import DataRowDropdown from "@/components/data/connections/manualSource/DataRowDropdown.vue";
import EditDataRowForm from "@/components/data/connections/manualSource/EditDataRowForm.vue";

import TextInput from "@/components/data/connections/manualSource/inputs/TextInput.vue";
import NumberInput from "@/components/data/connections/manualSource/inputs/NumberInput.vue";
import TextAreaInput from "@/components/data/connections/manualSource/inputs/TextAreaInput.vue";
import DatetimeInput from "@/components/data/connections/manualSource/inputs/DatetimeInput.vue";
import ColorInput from "@/components/data/connections/manualSource/inputs/ColorInput.vue";
import CurrencyInput from "@/components/data/connections/manualSource/inputs/CurrencyInput.vue";
import ImageUploadInput from "@/components/data/connections/manualSource/inputs/ImageUploadInput.vue";
import BooleanInput from "@/components/data/connections/manualSource/inputs/BooleanInput.vue";

import FileDownloader from "@/components/data/FileDownloader.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import SaveButton from "@/components/data/connections/manage/SaveButton.vue";
import RepositionRowModal from "@/components/data/connections/manualSource/RepositionRowModal.vue";

import {
  encodeLookupKey,
  NodeDataTypes,
  DataTypeInterface,
  EMPTY_ROW_UUID,
} from "@/stores/editableData";
import { formatCurrencyString, isCurrency } from "@/utils";
import debounce from "lodash.debounce";
import { DataType, ManualDataRow, NodeData, SchemaNode } from "@/types/data";
import Icon from "@/components/icons/Icon.vue";
import { DateTime } from "luxon";
import { appSettings } from "@/appSettings";
import tinycolor from "tinycolor2";
import DataNodeImage from "@/components/data/DataNodeImage.vue";
import { makeCellDomId } from "./helpers";
import { uploadImage } from "@/uploadAssets";
import { useEditableDataStore } from "@/stores/editableData";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

/**
 *
 *
 * [ ] Must validate new cell from empty row. Not happening rn. e.g. number when type is really string
 * [ ] NOTE: Noticing something odd with bar graph (or in general??) -- rivers with same name do not both show up in graph. only last one does.
 * [ ] Colors/styles cleanup -- put them in tailwind config, app-bg-red, app-bg-orange---  and "darkest-i"
 * Also, Jesse's direction on styles for error filter button
 * [ ] Still want to show header row if there are no search results
 * oh and not just the header row! the HEADER!
 * [ ] clear search button
 * [ ] border-separate....not always working
 * [ ] truncate...with separate...hmmm
 * [ ] should we use blur here, as in form?
 */

@Component({
  components: {
    DataRowDropdown,
    EditDataRowForm,
    Icon,
    TextInput,
    TextAreaInput,
    NumberInput,
    DatetimeInput,
    ColorInput,
    CurrencyInput,
    ImageUploadInput,
    FileDownloader,
    ButtonGradient,
    SaveButton,
    DataNodeImage,
    RepositionRowModal,
    BooleanInput,
  },
})
export default class EditDataTable extends Vue {
  hoveredIdx = -1;

  emptyRowUuid = EMPTY_ROW_UUID;

  clickedImageNode: Partial<NodeData> | null = null;

  get moderationMode() {
    const store = useConnectionEditorStore();
    return store.moderationMode;
  }

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get rawSchema() {
    return this.connectionEditor.schema as SchemaNode[];
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  get emptyRowValue() {
    return this.editableDataStore.emptyRowValue;
  }
  set emptyRowValue(value: any) {
    this.editableDataStore.emptyRowValue = value;
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get awaitingServer() {
    return this.appEditor.awaitingServer;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  get schema() {
    return this.rawSchema || [];
  }
  /**
   * NOTE: This component will rely on data/connections/manage/Manager.vue to fetch data,
   * and on actions from disconnect, sync, or schema columns to update the schema/rows on the state as needed.
   * That way, this component never has to fetch any data.
   */

  get isModerated() {
    return this.moderationMode !== null;
  }

  get moderationSelectedText() {
    return this.moderationMode === "Approval"
      ? this.$t("dataModeration.moderationModeApproved")
      : this.$t("dataModeration.moderationModeRemoved");
  }

  get moderationDeselectedText() {
    return this.moderationMode === "Approval"
      ? this.$t("dataModeration.moderationModeUnapproved")
      : this.$t("dataModeration.moderationModeIncluded");
  }

  /**
   * Handles the fact that after adding or removing a schema node, in Edit schema view, a user may immediately return to this grid view.
   * We need to remove any cells that correspond to deleted schema nodes,
   * And to add cells for any newly created schema nodes.
   */
  getDataCells(row: ManualDataRow) {
    const cells: Partial<NodeData>[] = row.columns.filter((c) =>
      this.schema.find((n) => n.uuid === c.uuid)
    );

    // console.log("data cells...", cells, row.columns, this.schema);
    this.schema.forEach((node) => {
      if (!cells.find((c) => c.uuid === node.uuid)) {
        cells.push({
          uuid: node.uuid as string,
          value: null,
          formattedValue: "",
        });
      }
    });
    return cells;
  }

  async mounted() {
    // Ahh...I think what was confusing me... As soon as we tab out of first empty row cell, and it has value,we should create
    window.addEventListener("keydown", this.tabKeyListener);
    window.addEventListener("click", this.elementClickHandler);
  }

  get isEditable() {
    if (!this.connection) return true;
    return this.connection.canEditData;
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.tabKeyListener);
    window.removeEventListener("click", this.elementClickHandler);
  }

  get showSaveButton() {
    return this.isEditable || this.isModerated;
  }

  get exportEndpoint() {
    const beUrl = appSettings.backEnd.baseUrlAndPath;
    return `${beUrl}/dataconnection/${this.connection?.uuid}/export?exportFormat=csv`;
  }

  // Pressing enter key submits value, leaves edit mode
  enterKeyHandler() {
    /**
     * It pains me that this works..
     * Ridiculous hack to deal with fact that ColorInput is not setting value quickly enough... nextTick didn't work
     */
    setTimeout(() => {
      const val = this.emptyRowValue;
      this.createRowFromEmptyIfShould();

      // Think the reason we're passing in empty row value...is in order to validate it
      this.editableDataStore.clearEditingCell({
        ...this.editableDataStore.editingCell,
        value: val,
        schema: this.schema,
      });
    }, 50);
  }

  async advanceCursorToNextPosition(ev: Event) {
    ev.preventDefault();
    const { columnUuid } = this.editableDataStore.editingCell;
    const position = this.schema.findIndex((n) => n.uuid === columnUuid);
    const nextPosition = position === this.schema.length - 1 ? 0 : position + 1;

    const nextColumnUuid = this.schema[nextPosition].uuid || "";
    let rowUuid = this.editableDataStore.editingCell.rowUuid;

    let nextColumnDataType = this.getColumnDataType(nextColumnUuid);

    // Must capture reference to parent of next input, before focusing that input, in order to measure its width
    const currentTd = document.querySelector(
      `#${this.makeKeyId(rowUuid, columnUuid)}`
    )?.parentElement;
    let nextTd = currentTd?.nextSibling as HTMLElement;
    if (nextPosition === 0)
      nextTd = currentTd?.parentElement?.firstChild as HTMLElement;

    // If user tabbed out of first new value in empty row, must create row, get its uuid, and then set focus to the new row
    if (rowUuid === EMPTY_ROW_UUID && this.emptyRowValue) {
      rowUuid = (await this.createRowFromEmptyIfShould()) as string;
      this.emptyRowValue = null;
    }
    this.editableDataStore.clearEditingCell({
      ...this.editableDataStore.editingCell,
      schema: this.schema,
    });

    if (nextColumnDataType != "ImageUpload") {
      this.editableDataStore.editingCell.rowUuid = rowUuid;
      this.editableDataStore.editingCell.columnUuid = nextColumnUuid;
    } else {
      this.editableDataStore.editingRowUuid = rowUuid;
    }

    this.clampInputWidthAndFocus(nextTd as HTMLElement);
  }

  tabKeyListener(ev: KeyboardEvent) {
    /**
     * Using setTimeout of 50ms ensures that we only try to create row from empty AFTER the emptyRowValue has been set.
     * Even in the case of Color input, which for some reason takes longer to set that value.
     * However, in case of non-Color inputs, timeout causes tab to focus browser's url input.
     */

    if (
      ev.code === "Tab" &&
      this.editableDataStore.editingCell.rowUuid !== ""
    ) {
      const { columnUuid } = this.editableDataStore.editingCell;
      if (this.getColumnDataType(columnUuid) === "Color") {
        setTimeout(() => {
          this.advanceCursorToNextPosition(ev);
        }, 50);
      } else {
        this.advanceCursorToNextPosition(ev);
      }
    }
  }

  // Intention is to handle case where user has errors filter applied, but removes the last error in their rows of data
  @Watch("editableDataStore.validationErrorsCount")
  errorsCountChanged(val: number) {
    if (val === 0) {
      this.editableDataStore.applyModificationsFilter = false;
    }
  }

  // Populates header of table
  get columns() {
    return this.schema.map((node) => node?.name);
  }

  get numberValidationErrorsMessage() {
    const num = this.editableDataStore.validationErrorsCount;
    const suffix =
      Object.keys(this.editableDataStore.validationErrorsLookup).length === 1
        ? ""
        : "s";
    return `${num} problem${suffix}`;
  }

  makeKeyId(rowUuid: string, columnUuid: string) {
    return makeCellDomId({ rowUuid, columnUuid });
  }

  /**
   * Render DateTimes in a more readable way
   *
   * - [ ] TODO: issue where leaving cell after no change triggers a modification
   * Maybe we can check if changes have been made in datetime input before setting val
   * (Note: only happens for placeholder data)
   *
   * TODO: The inputs get way too squished with multiple columns and the form open
   *
   * TODO: Handle colors here also (Want to return a default format.. and also show swatch, I think)
   *
   * TODO: And..currencies.. Do we just put in "right" format? with commas and 2 float point for US$, e.g.?
   * Like, what if "$1000" is the formattedValue?
   * To be consistent with color and datetimes.... we should pick the format for them.
   * But we have to try to infer the currency, which would let us pick proper format.
   *
   * then issue of handling input...should be numeric....so symbol is split off...but then how to edit symbol?
   * Ah and we also have to detect whether to show number input...or currency input....
   *
   * And then the issue of when rendering it in a widget...how do we expose those formatting options..
   */
  renderValue(node: Partial<NodeData>) {
    const { uuid, formattedValue } = node;

    const schemaNode = this.schema.find((n) => n.uuid === uuid);
    const dataType = schemaNode?.dataType;

    if (["Date", "Time", "DateTime"].includes(dataType as string)) {
      if (!node.value) return "";
      const dt =
        DateTime.fromJSDate(node.value as Date).invalidReason === null
          ? DateTime.fromJSDate(node.value as Date)
          : DateTime.fromJSDate(new Date(node.value as Date));

      let format = DateTime.DATETIME_MED;
      if (dataType === "Date") format = DateTime.DATE_MED;
      if (dataType === "Time") format = DateTime.TIME_SIMPLE;

      return dt.toLocaleString(format);
    }

    if (dataType === "Color") {
      if (!node.value) return "";
      const val = tinycolor(node.value as string);
      return val.toHex8String();
    }

    if (dataType === "Number" && isCurrency(formattedValue as string)) {
      return formatCurrencyString(node.value as number);
    }

    if (dataType === "Bool") {
      return this.renderBoolean(node);
    }

    // Should be using formattedValue, rather than raw value:
    return formattedValue;
  }

  renderBoolean(node: Partial<NodeData>) {
    const schemaNode = this.schema.find((n) => n.uuid === node.uuid);
    if (node.value !== true && node.value !== false && schemaNode?.isRequired) {
      return "";
    }
    let label = "notSpecified";
    if (node.value === true) label = "true";
    if (node.value === false) label = "false";
    return this.$t(`booleanValues.${label}`);
  }

  isColorNode(node: Partial<NodeData>) {
    const schemaNode = this.schema.find((n) => n.uuid === node.uuid);
    return schemaNode?.dataType === "Color";
  }

  getColor(node: Partial<NodeData>) {
    return tinycolor(node.value as string).toHex8String();
  }

  getInputComponent(node: Partial<NodeData>) {
    const { uuid, value, formattedValue } = node;
    const schemaNode = this.schema.find((n) => n.uuid === uuid);

    // Kind of wonky to use includes rather than === here, but it allows both Date and Time to map to DateTime
    const dataType = NodeDataTypes.find((d) =>
      d.name.includes(schemaNode?.dataType as string)
    ) as DataTypeInterface;

    // Allows us to use Currency input if needed:
    if (dataType?.name === "Number")
      return dataType.getComponent(formattedValue);
    return dataType?.getComponent(value);
  }

  getColumnDataType(uuid: string) {
    return this.schema.find((n) => n.uuid === uuid)?.dataType;
  }

  // ================================================================================================
  // STYLERS
  // ================================================================================================

  getHeaderClasses(payload: { uuid: string; index: number }) {
    const { uuid, index } = payload;
    const classes = "border border-black";
    if (
      uuid === this.editableDataStore.editingRowUuid ||
      uuid === this.editableDataStore.dropdownOpenRowUuid
    )
      return `${classes} bg-gray-400`;
    if (index === this.hoveredIdx) return `${classes} bg-gray-500`;
    const row = this.editableDataStore.rows[index];
    const isDeleted = Object.keys(
      this.editableDataStore.deletedRowsLookup
    ).includes(row.rowUuid);
    const opacityClass = isDeleted ? "opacity-50 line-through" : "";

    return `${classes} ${opacityClass} bg-app-dark1`;
  }

  getCellClasses(payload: { rowUuid: string }) {
    const { rowUuid } = payload;
    const isDeleted = Object.keys(
      this.editableDataStore.deletedRowsLookup
    ).includes(rowUuid);
    return isDeleted ? "opacity-50 line-through" : "";
  }

  getCellBorderClass(payload: { rowUuid: string; columnUuid: string }) {
    const { rowUuid, columnUuid } = payload;

    let borderColorClass = "";

    const key = encodeLookupKey({ rowUuid, columnUuid });

    if (Object.keys(this.editableDataStore.modificationsLookup).includes(key)) {
      borderColorClass = "data-cell-modified";
    }

    const rowIsNew = Object.keys(
      this.editableDataStore.addedRowsLookup
    ).includes(rowUuid);
    if (rowIsNew) {
      borderColorClass = "data-cell-new";
    }

    const cellHasError = Object.keys(
      this.editableDataStore.validationErrorsLookup
    ).includes(key);
    if (cellHasError) {
      borderColorClass = "data-cell-invalid";
    }

    return borderColorClass;
  }

  showCellInput(rowUuid: string, columnUuid: string) {
    const res =
      this.editableDataStore.editingCell?.rowUuid === rowUuid &&
      this.editableDataStore.editingCell?.columnUuid === columnUuid;

    return res;
  }

  // ================================================================================================
  // END STYLERS
  // ================================================================================================

  // ================================================================================================
  // CLICK/INPUT HANDLERS
  // ================================================================================================

  // We use click.stop to stopPropagation for click events that should NOT have this behavior
  elementClickHandler() {
    // Hide the dropdown
    this.editableDataStore.dropdownOpenRowUuid = "";

    this.createRowFromEmptyIfShould();
    // Exit cell editing mode
    this.editableDataStore.clearEditingCell({
      ...this.editableDataStore.editingCell,
      value: this.emptyRowValue,
      schema: this.schema,
    });
  }

  searchClickHandler() {
    this.editableDataStore.editingRowUuid = "";
    this.editableDataStore.applyValidationErrorsFilter = false;
  }

  validationErrorsFilterClickHandler() {
    const applyFilter = this.editableDataStore.applyValidationErrorsFilter; // store it because we clear out in clearfilters
    this.editableDataStore.clearFilters();
    this.editableDataStore.applyValidationErrorsFilter = !applyFilter;
    this.editableDataStore.moderationFilter = "All";
    this.editableDataStore.editingRowUuid = ""; // Close editing form
  }

  cellHeaderClickHandler(event: any, uuid: string) {
    const thElement = event.target.closest("th");
    const bbox = thElement.getBoundingClientRect();

    /**
     * The dropdown needs to live outside the table container, which has overflow-hidden,
     * in order to not get cut off at the bottom edge
     *
     * This sets the dropdown position based on table header position and width, accounting for table container,
     * in order to find where to place dropdown relative to table container
     */
    const table = this.$el.querySelector(".data-grid-container");
    const tableBbox = table?.getBoundingClientRect() as any;

    const dropdownPos = {
      x: bbox.x + bbox.width - tableBbox.x,
      y: bbox.y - tableBbox.y,
    };
    this.editableDataStore.dropdownPosition.x = dropdownPos.x;
    this.editableDataStore.dropdownPosition.y = dropdownPos.y;

    if (this.editableDataStore.dropdownOpenRowUuid === uuid) {
      this.editableDataStore.dropdownOpenRowUuid = "";
    } else {
      this.editableDataStore.dropdownOpenRowUuid = uuid;
    }
    this.editableDataStore.clearEditingCell({
      ...this.editableDataStore.editingCell,
      schema: this.schema,
    });
  }

  // Focus first cell in empty row
  plusClickHandler(ev: any) {
    this.editableDataStore.searchText = "";
    this.editableDataStore.editingCell.rowUuid = EMPTY_ROW_UUID;
    this.editableDataStore.editingCell.columnUuid = this.schema[0].uuid || "";

    this.$nextTick(() => {
      ev.target.parentElement.querySelector("input").focus();
    });
  }

  /**
   * Ensure any previously open cell is "closed" and its value updated, when any cell is clicked.
   */
  mousedownHandler(cell: { rowUuid: string; columnUuid: string }) {
    const { rowUuid, columnUuid } = cell;
    if (
      this.editableDataStore.editingCell.rowUuid === rowUuid &&
      this.editableDataStore.editingCell.columnUuid === columnUuid
    ) {
      return;
    }

    if (
      rowUuid === EMPTY_ROW_UUID &&
      columnUuid !== this.editableDataStore.editingCell.columnUuid
    ) {
      this.enterKeyHandler();
      return;
    }

    this.editableDataStore.clearEditingCell({
      ...this.editableDataStore.editingCell,
      schema: this.schema,
    });
  }

  cellClickHandler(rowUuid: string, node: Partial<NodeData>) {
    // Close the dropdown menu, if open:
    this.editableDataStore.dropdownOpenRowUuid = "";

    this.imageUploadClickHandler(rowUuid, node);
  }

  async imageUploadClickHandler(rowUuid: string, node: Partial<NodeData>) {
    if (node.dataType === "ImageUpload") {
      if (rowUuid === this.emptyRowUuid) {
        const newRowUuid = this.editableDataStore.createRowWithNode({
          uuid: node.uuid as string,
          value: this.emptyRowValue,
        });
        this.editableDataStore.editingRowUuid = newRowUuid as string;
      } else {
        this.editableDataStore.editingRowUuid = rowUuid;
        // If showing "upload" button, open the modal
        if (!node.formattedValue) {
          uploadImage().then((image) => {
            if (image) {
              this.editableDataStore.setNodeValue({
                rowUuid: this.editableDataStore.editingRowUuid,
                columnUuid: node.uuid ?? "",
                formattedValue: image.url,
                assetUuid: image.uuid,
                value: image.url,
              });
              this.editableDataStore.clearEditingCell({
                rowUuid: this.editableDataStore.editingRowUuid,
                columnUuid: node.uuid ?? "",
                value: node.formattedValue,
                schema: this.schema,
              });
            }
          });
        }
      }
    }
  }

  doubleClickCellHandler(
    ev: any,
    rowUuid: string,
    columnUuid: string,
    dataType?: string
  ) {
    // Disallow cell editing for non-manual connections:
    if (!this.isEditable) {
      // console.log("Non manual, hoss");
      return;
    }
    // Ignore dblclicks on cell that is being edited
    if (
      rowUuid === this.editableDataStore.editingCell?.rowUuid &&
      columnUuid === this.editableDataStore.editingCell?.columnUuid
    )
      return;

    // Ignore if deleted
    if (
      Object.keys(this.editableDataStore.deletedRowsLookup).includes(rowUuid)
    ) {
      return;
    }
    // Don't set editing cell if Image Upload. That cell opens data row form instead.
    let columnDataType = this.getColumnDataType(columnUuid);

    if (dataType != "ImageUpload" && columnDataType != "ImageUpload") {
      this.editableDataStore.editingCell.rowUuid = rowUuid;
      this.editableDataStore.editingCell.columnUuid = columnUuid;
    }

    this.clampInputWidthAndFocus(ev.target);
  }

  clampInputWidthAndFocus(parentElement: HTMLElement) {
    // Ensure input does not expand beyond initial width of column
    const cellWidth = parentElement.getBoundingClientRect().width;

    this.$nextTick(() => {
      const input = parentElement.querySelector("input");
      input?.focus();
      if (input) {
        // Ugly hack -- relies on fact that only Color inputs have any markup BEFORE the input itself
        // TODO: test currency input..
        const offset = input.previousSibling !== null ? 70 : 3;
        input.style.width = `${cellWidth - offset}px`;
      }
    });
  }

  // ================================================================================================
  // END CLICK HANDLERS
  // ================================================================================================

  createRowFromEmptyIfShould(): void | string {
    const val = this.emptyRowValue;
    this.emptyRowValue = null;

    // If they have just entered first value in the Empty row, create a row from that data
    if (val) {
      const newRowUuid = this.editableDataStore.createRowWithNode({
        uuid: this.editableDataStore.editingCell.columnUuid,
        value: val,
      });

      return newRowUuid;
    }
  }

  // Have to do like this to avoid "this" issues I think:
  searchInputUpdate(val: string) {
    this.editableDataStore.searchText = val;
  }

  searchInputHandler = debounce((ev: Event) => {
    const val = (ev.target as any).value;
    this.searchInputUpdate(val);
  }, 400);

  onCellMouseOver(event: MouseEvent, dataType: DataType | undefined) {
    if (dataType === "ImageUrl" || dataType === "ImageUpload") {
      const cell = event.target as HTMLTableCellElement;
      const img = cell.querySelector("img");
      if (img !== null) {
        const imageHoverDiv = document.createElement("div");
        imageHoverDiv.style.position = "absolute";
        imageHoverDiv.style.pointerEvents = "none";
        imageHoverDiv.classList.add("img-hover-div");

        const clone = img.cloneNode() as HTMLImageElement;
        clone.setAttribute("class", "");
        clone.classList.add("img-clone");

        imageHoverDiv.appendChild(clone);
        if (dataType === "ImageUpload") {
          const imageHoverText = document.createElement("div");
          imageHoverText.innerHTML = this.$t(
            "manualInputs.imageHoverText"
          ).toString();
          imageHoverText.classList.add(
            "bg-gray-600",
            "-ml-1",
            "mt-2",
            "px-2",
            "py-px",
            "rounded-sm"
          );
          imageHoverDiv.appendChild(imageHoverText);
        }
        cell.appendChild(imageHoverDiv);
      }
    }
  }

  onCellMouseOut(event: MouseEvent, dataType: DataType | undefined) {
    if (dataType === "ImageUrl" || dataType === "ImageUpload") {
      const cell = event.target as HTMLTableCellElement;
      const img = cell.querySelector(".img-hover-div");
      if (img !== null) {
        cell.removeChild(img);
      }
    }
  }
}
</script>

<style scoped lang="postcss">
#search-input {
  @apply text-white p-2;

  /* Should match color of thead */
  background: rgb(30, 30, 30);
}

#search-input:focus {
  @apply bg-white text-black;
}

input {
  min-height: 20px;
}

.data-grid .selected-row td,
.data-grid .selected-row th {
  @apply bg-gray-600 text-white;
}

.data-grid thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.data-grid tbody tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.data-grid .data-cell-invalid {
  @apply h-full border border-red-500  border-opacity-80 bg-red-500 bg-opacity-10;
}
.data-grid .data-cell-new {
  @apply h-full border border-app-green  border-opacity-60 bg-app-green bg-opacity-10;
}
.data-grid .data-cell-modified {
  @apply h-full border border-app-orange border-opacity-80;
}

.data-grid :deep(input) {
  @apply h-full w-full outline outline-app-teal border-none bg-transparent px-2;
}

/* .truncate-container {
  display: table;
  table-layout: auto;
  min-height: 20px;
} */

.truncate-content {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

.img-clone {
  @apply bg-gray-500 shadow-lg ring-2 ring-offset-2 ring-gray-600 h-48;
}
</style>
