<template>
  <div @click="$emit('close')">
    <Icon
      name="CircleClose"
      class="w-6 h-6 cursor-pointer icon-fill z-10"
      stroke="rgb(30,30,30)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import Icon from "@/components/icons/Icon.vue";
@Component({
  components: {
    Icon,
  },
})
export default class CloseButton extends Vue {}
</script>

<style lang="postcss" scoped>
.icon-fill {
  fill: rgb(120, 120, 120);
}
.icon-fill:hover {
  fill: rgb(170, 170, 170);
}
</style>
