<template>
  <div
    ref="menu"
    class="relative bg-white rounded-[4px]"
    :class="containerClass"
  >
    <button
      ref="button"
      type="button"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      :class="{
        rounded: rounded,
        'h-full': fullWidth,
        'dark-form-focus': dark,
        'light-form-focus': !dark,
        'opacity-50': disabled,
      }"
      class="truncate bg-inherit text-inherit relative w-full rounded-[4px] shadow-sm pl-2 pr-9 py-1 text-left"
      @click="toggle"
      :disabled="disabled"
    >
      <span v-if="label">{{ label }}</span>
      <span class="h-full" :style="selectedItem?.style">
        {{ selectionText }}</span
      >
      <span
        class="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none"
      >
        <IconSolid name="SelectCarets" class="h-5 w-5 text-gray-400" />
      </span>
    </button>

    <div
      class="absolute mt-1 w-full rounded-[4px] bg-white shadow-lg z-50"
      v-show="expanded && !disabled"
      ref="expanded"
      :style="expandedStyle"
    >
      <div class="m-2 sticky top-2" v-if="searchable">
        <TextInput class="p-2" v-model="filter" />
        <Icon class="absolute w-5 h-5 right-2 top-2" :name="'Search'" />
      </div>
      <ul
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        class="max-h-72 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none divide-y"
      >
        <li
          :key="$index"
          v-for="(item, $index) in items"
          role="option"
          class="cursor-default select-none relative py-2 pl-3 pr-9 text-xxs"
          :class="getItemClass(item, $index)"
          @click="select(item)"
          @mouseenter="highlight($index)"
          @mouseleave="unhighlight"
        >
          <slot :item="item">
            <span
              class="block truncate font-normal"
              :style="item.style"
              v-text="item.label"
            >
            </span>
          </slot>

          <span
            v-if="item.value === value"
            class="text-app-teal absolute inset-y-0 right-0 flex items-center pr-2"
            :class="getCheckColorClass($index)"
          >
            <IconSolid name="Checkmark" class="h-[18px] w-[18px]" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import isEqual from "lodash.isequal";
import { InputOption } from "@/types/inputs";
import TextInput from "@/components/inputs/TextInput.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import Icon from "@/components/icons/Icon.vue";

@Component({ components: { IconSolid, Icon, TextInput } })
export default class SelectMenu extends Vue {
  @Prop(String) placeholder: string;
  @Prop() value: string | boolean | number | object;
  @Prop(Array) options: InputOption[];
  @Prop({ default: "" }) label: string;
  @Prop({ default: false }) rounded: boolean;
  @Prop({ default: false }) fullWidth: boolean;
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) dark: boolean;
  @Prop({ type: Boolean, default: false }) searchable: boolean;
  @Prop(String) textColor: string;
  @Prop(String) checkColor: string;
  @Prop(String) hoverColor: string;

  highlightedIndex = -1;
  expanded = false;
  menuTop = 0;
  expandedHeight = 0;
  filter = "";

  get filteredItems() {
    return this.filter.length > 0
      ? this.options.filter((o: InputOption) =>
          o.label.toLowerCase().includes(this.filter.toLowerCase())
        )
      : this.options;
  }

  get items() {
    return this.filteredItems.map((item) =>
      Object.assign(item, {
        selected: item.value === this.value,
      })
    );
  }

  getItemClass(item: any, index: number) {
    const classes = [];
    if (item.disabled) {
      classes.push("text-gray-400 bg-gray-100 italic cursor-not-allowed");
    } else {
      if (this.highlightedIndex === index) {
        if (!this.hoverColor) classes.push("text-white");
        classes.push(this.hoverColorClass);
      } else {
        classes.push(this.textColorClass);
      }
    }

    return classes.join(" ");
  }

  get containerClass() {
    const classes = [this.textColorClass];
    if (this.fullWidth) {
      classes.push("w-full");
    }
    if (this.disabled) {
      classes.push("pointer-events-none");
    }

    return classes.join(" ");
  }

  get textColorClass() {
    return this.textColor ? `text-${this.textColor}` : "text-gray-900";
  }

  get hoverColorClass() {
    return this.hoverColor ? `bg-${this.hoverColor}` : "bg-app-teal";
  }

  getCheckColorClass(index: number) {
    if (this.highlightedIndex === index) {
      return this.checkColor ? `text-${this.checkColor}` : "text-white";
    } else {
      return this.checkColor ? `text-${this.checkColor}` : "text-app-teal";
    }
  }

  created() {
    if (this.value) {
      this.highlightedIndex = this.options.findIndex(
        (o) => o.value === this.value
      );
    }
  }

  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  highlight(value: number) {
    this.highlightedIndex = value;
  }

  unhighlight() {
    this.highlightedIndex = -1;
  }

  close(ev: any = null) {
    // Aha! clicks on this component are @click.stop...why did we do that??
    // OOOh so that click on this very thing won't close it, sure
    // But then we have result that clicking on another select menu will not close this one. Problem.
    // Solve by checking whether click was inside this component here, before closing
    if (ev === null || !this.$el.contains(ev.target)) {
      this.expanded = false;
      document.removeEventListener("click", this.close);
      document.removeEventListener("keydown", this.onKeyDown);
    }
  }

  open() {
    this.expanded = true;
    document.addEventListener("click", this.close);
    document.addEventListener("keydown", this.onKeyDown);
    this.$nextTick(() => {
      this.expandedHeight = (
        this.$refs.expanded as HTMLElement
      ).getBoundingClientRect().height;
    });
  }

  toggle() {
    if (this.disabled) return;
    if (this.expanded) {
      this.close();
    } else {
      // Huh...it doesn't automatically update this, ever. So must do it manually.
      this.menuTop = (
        this.$refs.menu as HTMLElement
      ).getBoundingClientRect().top;
      this.open();
    }
  }

  // - [ ] NOTE: This may not be ideal...if there are many options, we probably want to still open down
  // Probably just add a max

  get expandedStyle() {
    const INPUT_HEIGHT = 40; // May want to compute this
    const MINIMUM_VERTICAL_SPACE = this.expandedHeight + INPUT_HEIGHT;

    // const MAX_SPACE = 250;
    // const MIN_HEIGHT = Math.min(MAX_SPACE, MINIMUM_VERTICAL_SPACE);

    const windowHeight = window.innerHeight;
    if (windowHeight - this.menuTop < MINIMUM_VERTICAL_SPACE) {
      return {
        bottom: `${INPUT_HEIGHT}px`,
      };
    }

    return {};
  }

  select(item: InputOption) {
    if (item.disabled) {
      return;
    }

    this.$emit("input", item.value);
    this.close();
  }

  focus() {
    (this.$refs.button as HTMLButtonElement)?.focus();
  }

  onKeyDown(e: KeyboardEvent) {
    e.stopPropagation();
    let actionkeys = ["Escape", "Enter", "ArrowDown", "ArrowUp"];
    if (actionkeys.includes(e.key)) {
      e.preventDefault();
    }

    if (!this.expanded) {
      return;
    }

    if (e.key === "Escape") {
      this.expanded = false;
    }

    if (
      e.key === "Enter" &&
      this.highlightedIndex > -1 &&
      this.highlightedIndex < this.options.length
    ) {
      const value = this.options[this.highlightedIndex].value;
      this.select({ value, label: "" });
    }

    if (e.key === "ArrowDown") {
      this.highlightedIndex++;
      if (this.highlightedIndex >= this.options.length) {
        this.highlightedIndex = this.options.length - 1;
      }
    }

    if (e.key === "ArrowUp") {
      this.highlightedIndex--;
      if (this.highlightedIndex < 0) {
        this.highlightedIndex = 0;
      }
    }
  }

  get selectedItem() {
    return this.options.find((o) => isEqual(o.value, this.value));
  }

  get selectionText() {
    return this.selectedItem?.label || this.placeholder;
  }
}
</script>

<style scoped></style>
