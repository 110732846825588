export const isFunction = (func: any) => {
  return (
    typeof func === "function" ||
    Object.prototype.toString.call(func) === "[object Function]"
  );
};

export const matchesSelectorToParentElements = (
  el: any,
  selector: string,
  baseNode: any
) => {
  let node = el;

  const matchesSelectorFunc =
    [
      "matches",
      "webkitMatchesSelector",
      "mozMatchesSelector",
      "msMatchesSelector",
      "oMatchesSelector"
    ].find(func => isFunction(node[func])) || "";

  if (!isFunction(node[matchesSelectorFunc])) return false;

  do {
    if (node[matchesSelectorFunc](selector)) return true;
    if (node === baseNode) return false;
    node = node.parentNode;
  } while (node);

  return false;
};

export const addEvent = (el: any, event: string, handler: any) => {
  if (!el) {
    return;
  }
  if (el.attachEvent) {
    el.attachEvent("on" + event, handler);
  } else if (el.addEventListener) {
    el.addEventListener(event, handler, true);
  } else {
    el["on" + event] = handler;
  }
};

export const removeEvent = (el: any, event: string, handler: any) => {
  if (!el) {
    return;
  }
  if (el.detachEvent) {
    el.detachEvent("on" + event, handler);
  } else if (el.removeEventListener) {
    el.removeEventListener(event, handler, true);
  } else {
    el["on" + event] = null;
  }
};

export const MOUSE_EVENTS = {
  start: "mousedown",
  move: "mousemove",
  stop: "mouseup"
};

export const TOUCH_EVENTS = {
  start: "touchstart",
  move: "touchmove",
  stop: "touchend"
};

export const userSelectNone = {
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  MsUserSelect: "none"
};

export const userSelectAuto = {
  userSelect: "auto",
  MozUserSelect: "auto",
  WebkitUserSelect: "auto",
  MsUserSelect: "auto"
};
