import XHRUpload from "@uppy/xhr-upload/lib/index";
import cuid from "cuid";
import ProgressTimeout from "@uppy/utils/lib/ProgressTimeout";
import EventTracker from "@uppy/utils/lib/EventTracker";
import NetworkError from "@uppy/utils/lib/NetworkError";
import isNetworkError from "@uppy/utils/lib/isNetworkError";

function buildResponseError(xhr, error) {
  // No error message
  if (!error) error = new Error("Upload error");
  // Got an error message string
  if (typeof error === "string") error = new Error(error);
  // Got something else
  if (!(error instanceof Error)) {
    error = Object.assign(new Error("Upload error"), { data: error });
  }

  if (isNetworkError(xhr)) {
    error = new NetworkError(error, xhr);
    return error;
  }

  error.request = xhr;
  return error;
}

class CustomXHRUpload extends XHRUpload {
  startProgressSim(timer, progressFunc) {
    const interval_mSec = 1000;
    const avgDwnldSpeed = (6 * 1000000) /* 6Mb */ / 8; /* 8 bits/byte */
    const intervalBytes = avgDwnldSpeed;
    timer = setInterval(() => progressFunc(intervalBytes), interval_mSec);
  }

  uploadRemote(file, current, total) {
    const opts = this.getOptions(file);
    return new Promise((resolve, reject) => {
      this.uppy.emit("upload-started", file);

      const fields = {};
      const metaFields = Array.isArray(opts.metaFields)
        ? opts.metaFields
        : // Send along all fields by default.
          Object.keys(file.meta);

      metaFields.forEach((name) => {
        fields[name] = file.meta[name];
      });

      const xhr = new XMLHttpRequest();
      this.uploaderEvents[file.id] = new EventTracker(this.uppy);

      var progressSimTimer = null;

      const timer = new ProgressTimeout(opts.timeout, () => {
        xhr.abort();
        clearInterval(progressSimTimer);
        queuedRequest.done();
        const error = new Error(
          this.i18n("timedOut", { seconds: Math.ceil(opts.timeout / 1000) })
        );
        this.uppy.emit("upload-error", file, error);
        reject(error);
      });

      const id = cuid();

      xhr.addEventListener("loadstart", (ev) => {
        this.uppy.log(`[XHRUpload] ${id} started`);
        file.progress.uploadStarted = Date.now();

        const interval_mSec = 1000;
        // Magic number avg download speed in US is 12Mb/s, splitting it in half to cover download and then upload into our system
        const avgDwnldBytesPerSec =
          (6 * 1000000) /* 6Mb */ / 8; /* 8 bits/byte */
        progressSimTimer = setInterval(() => {
          let progBytes =
            file.progress.bytesUploaded + avgDwnldBytesPerSec < file.size
              ? (file.progress.bytesUploaded += avgDwnldBytesPerSec)
              : file.progress.bytesUploaded; // hold below 100% if simulation finishes first

          this.uppy.emit("upload-progress", file, {
            uploader: this,
            bytesUploaded: progBytes,
            bytesTotal: file.size,
          });
        }, interval_mSec);
      });

      xhr.addEventListener("progress", (ev) => {
        timer.progress();
      });

      xhr.addEventListener("load", (ev) => {
        this.uppy.log(`[XHRUpload] ${id} finished`);
        clearInterval(progressSimTimer);
        timer.done();
        queuedRequest.done();
        if (this.uploaderEvents[file.id]) {
          this.uploaderEvents[file.id].remove();
          this.uploaderEvents[file.id] = null;
        }

        if (opts.validateStatus(ev.target.status, xhr.responseText, xhr)) {
          const body = opts.getResponseData(xhr.responseText, xhr);
          const uploadURL = body[opts.responseUrlFieldName];

          const uploadResp = {
            status: ev.target.status,
            body,
            uploadURL,
          };

          this.uppy.emit("upload-success", file, uploadResp);

          return resolve(file);
        } else {
          clearInterval(progressSimTimer);
          const body = opts.getResponseData(xhr.responseText, xhr);
          const error = buildResponseError(
            xhr,
            opts.getResponseError(xhr.responseText, xhr)
          );

          const response = {
            status: ev.target.status,
            body,
          };

          this.uppy.emit("upload-error", file, error, response);
          return reject(error);
        }
      });

      xhr.addEventListener("error", (ev) => {
        this.uppy.log(`[XHRUpload] ${id} errored`);
        timer.done();
        queuedRequest.done();
        if (this.uploaderEvents[file.id]) {
          this.uploaderEvents[file.id].remove();
          this.uploaderEvents[file.id] = null;
        }

        const error = buildResponseError(
          xhr,
          opts.getResponseError(xhr.responseText, xhr)
        );
        this.uppy.emit("upload-error", file, error);
        return reject(error);
      });

      xhr.open(opts.method.toUpperCase(), file.remote.url, true);
      xhr.withCredentials = opts.withCredentials;
      if (opts.responseType !== "") {
        xhr.responseType = opts.responseType;
      }

      const queuedRequest = this.requests.run(() => {
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("authorization", opts.headers["authorization"]);

        xhr.send(
          JSON.stringify({
            ...file.remote.body,
            endpoint: opts.endpoint,
            size: file.data.size,
            fieldname: opts.fieldName,
            metadata: fields,
            httpMethod: opts.method,
            useFormData: opts.formData,
            headers: opts.headers,
          })
        );
        return () => {
          timer.done();
          xhr.abort();
        };
      });

      this.onFileRemove(file.id, () => {
        queuedRequest.abort();
        reject(new Error("File removed"));
      });

      this.onCancelAll(file.id, () => {
        queuedRequest.abort();
        reject(new Error("Upload cancelled"));
      });
    });
  }
}

export default CustomXHRUpload;
