<template>
  <div class="stacked-graph relative" v-if="data && data.length > 0">
    <div
      v-if="showLegend"
      class="legend flex"
      ref="legend"
      :style="legendStyles"
    >
      <div
        class="legend-item flex items-center m-2"
        :key="labelIndex"
        v-for="(item, labelIndex) in stacks"
      >
        <svg
          class="box flex-shrink-0"
          viewbox="0 0 100 100"
          :width="legend_fontSize * scaleX"
          :height="legend_fontSize * scaleX"
        >
          <rect x="0" y="0" :width="100" :height="100" :fill="item.color" />
        </svg>
        <div class="ml-2">{{ item.label }}</div>
      </div>
    </div>
    <svg
      class="chart"
      :width="width"
      :height="height"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g class="columns">
        <rect
          v-for="(stack, index) in stacks"
          :fill="stack.color"
          :key="index"
          :height="stack.height"
          :width="graphWidth"
          :x="stack.x"
          :y="stack.y"
        ></rect>
        <g v-if="showValues">
          <text
            class="values"
            v-for="(stack, index) in stacks"
            :key="index"
            :x="stack.x + stack.width / 2"
            :y="stack.y + stack.height / 2"
            :style="getStyles('value')"
            :font-size="value_fontSize"
            text-anchor="middle"
            dominant-baseline="middle"
          >
            {{ valuesAsPercent ? `${stack.percent}%` : stack.value }}
          </text>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { NodeData } from "@/types/data";
import * as d3 from "d3";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CSSProperties } from "vue/types/jsx";

@Component({})
export default class StackedGraphComponent extends Vue {
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;

  @Prop(String) readonly value_fontFamily: string;
  @Prop(Number) readonly value_fontSize: number;
  @Prop(Number) readonly value_fontWeight: number;
  @Prop(String) readonly value_fontStyle: string;
  @Prop(String) readonly value_textColor: string;
  @Prop(String) readonly legend_fontFamily: string;
  @Prop(Number) readonly legend_fontSize: number;
  @Prop(Number) readonly legend_fontWeight: number;
  @Prop(String) readonly legend_fontStyle: string;
  @Prop(String) readonly legend_textColor: string;
  @Prop(String) readonly legend_textTransform: string;

  @Prop(String) readonly lineColor: string;
  @Prop(Number) readonly lineThickness: string;
  @Prop(Boolean) readonly showLegend: boolean;
  @Prop(Number) readonly legendMargin: number;
  @Prop(String) readonly legendPosition: string;
  @Prop(Array) readonly colors: string[];
  @Prop(Boolean) readonly showValues: boolean;
  @Prop(Boolean) readonly valuesAsPercent: boolean;
  @Prop(String) readonly xAxisColumnId: string;
  @Prop(String) readonly yAxisColumnId: string;
  @Prop(String) readonly wid: string;
  @Prop({ type: Array, default: () => [] }) readonly data: NodeData[][];

  get width() {
    return this.w * this.scaleX;
  }

  get height() {
    return this.h * this.scaleY;
  }

  get graphWidth() {
    return this.width;
  }

  get graphHeight() {
    return this.height;
  }

  get rangeX() {
    return this.graphWidth;
  }

  get rangeY() {
    return this.graphHeight;
  }

  get labels() {
    if (this.data.length > 0) {
      return this.data.map((row) => {
        return row.find((c) => c.uuid === this.xAxisColumnId)
          ?.formattedValue as string;
      });
    }
    return [] as string[];
  }

  get values() {
    if (this.data && this.data.length > 0) {
      let values: any = [];

      this.data.map((row) => {
        values.push(
          parseInt(
            row.find((c) => c.uuid === this.yAxisColumnId)?.value as string
          )
        );
      });

      return values;
    }
    return [];
  }

  get total() {
    return this.values.reduce((a: number, b: number) => a + b, 0);
  }

  get stackData() {
    let value = 0;
    let data = [];
    for (var i = 0; i < this.values.length; i++) {
      var datum = {
        label: this.labels[i],
        value: this.values[i],
        startValue: value / this.total,
        endValue: (value += this.values[i]) / this.total,
      };

      data.push(datum);
    }
    return data;
  }

  get y() {
    return d3.scaleLinear().domain([0, 1]).range([0, this.graphHeight]);
  }

  get stacks() {
    let stacks = this.stackData.map((r, index) => {
      let value = r.value;
      let label = r.label;
      let width = this.graphWidth;
      let height = this.y(r.endValue) - this.y(r.startValue);
      let x = 0;
      let y = this.y(r.startValue);
      let color = this.colors[index];
      let percent = ((r.value / this.total) * 100).toFixed(0);

      return {
        value: value,
        label: label,
        width: width,
        height: height,
        x: x,
        y: y,
        color: color,
        percent: percent,
      };
    });
    return stacks;
  }

  getStyles(prefix: string) {
    const vm = this as any;
    const result: any = {};
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontSize`]) {
      result.fontSize = vm[`${prefix}_fontSize`] * this.scaleX;
    }
    if (vm[`${prefix}_fontWeight`] && prefix) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.fill = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }

    return result;
  }

  get legendStyles() {
    const unconditionalProperties: CSSProperties = {
      position: "absolute",
      display: "flex",
      flexWrap: "wrap",
      width: "auto",
      fontFamily: this.getStyles("legend").fontFamily,
      fontSize: this.getStyles("legend").fontSize + "px",
      fontWeight: this.getStyles("legend").fontWeight,
      color: this.getStyles("legend").fill,
      fontStyle: this.getStyles("legend").fontStyle,
      textTransform: this.getStyles("legend").textTransform,
    };

    let result: CSSProperties;

    switch (this.legendPosition) {
      case "Top":
        {
          let conditionalProperties = {
            flexDirection: "row" as const,
            bottom: `${this.graphHeight + this.legendMargin}px`,
            left: "0%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      case "Bottom":
        {
          let conditionalProperties = {
            flexDirection: "row" as const,
            top: `${this.graphHeight + this.legendMargin}px`,
            left: "0%",
            height: `${this.graphHeight / 5}px`,
            alignItems: "flex-start",
            justifyContent: "flex-start",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      case "Left":
        {
          let conditionalProperties = {
            flexDirection: "column" as const,
            top: "0%",
            right: `${this.graphWidth + this.legendMargin}px`,
            height: `${this.graphHeight}px`,
            alignItems: "flex-start",
            justifyContent: "flex-start",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      case "Right":
        {
          let conditionalProperties = {
            flexDirection: "column" as const,
            top: "0%",
            left: `${this.graphWidth * 1.05 + this.legendMargin}px`,
            height: `${this.graphHeight}px`,
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
      default:
        {
          let conditionalProperties = {
            flexDirection: "row" as const,
            top: "0%",
            left: "0%",
            height: `${this.graphHeight / 5}px`,
            alignItems: "flex-start",
            justifyContent: "flex-start",
          };
          result = { ...unconditionalProperties, ...conditionalProperties };
        }
        break;
    }

    return result;
  }
}
</script>

<style></style>
