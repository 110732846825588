<template>
  <div class="w-full">
    <!-- <DefineSpreadsheetSchema
      v-if="schemaType === 'Tabular' && !store.canEditData"
      @sheetSelected="onQueryContextSelected"
      @complete="onCompleteClick"
    />

    <DefineCalendarSchema
      v-if="schemaType === 'Calendar' && store.schema.length > 0"
      :events="store.schema"
      :nodes="store.configuredNodes"
      @complete="onCompleteClick"
    />

    <DefineManualSchema
      v-if="schemaType === 'Tabular' && store.canEditData"
      :nodes="store.configuredNodes"
      @complete="onCompleteClick"
    /> -->
    <component
      :is="schemaComponents[schemaComponent]"
      :schema="schema"
      @complete="onComplete"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";

import DefineTreeSchemaV2 from "@/components/data/connections/setup/schema/DefineTreeSchemaV2.vue";
// import DefineSpreadsheetSchemaV2 from "@/components/data/connections/setup/schema/DefineSpreadsheetSchema.vue";
// import DefineCalendarSchemaV2 from "@/components/data/connections/setup/schema/DefineCalendarSchema.vue";
// import DefineManualSchemaV2 from "@/components/data/connections/setup/schema/DefineManualSchema.vue";
import { SchemaNode } from "@/types/data";
import { SetupStep } from "@/stores/connectionSetup";

const schemaComponents = {
  DefineTreeSchemaV2,
  // DefineSpreadsheetSchemaV2,
  // DefineCalendarSchemaV2,
  // DefineManualSchemaV2,
};

const props = defineProps<{
  setupStep: SetupStep | undefined;
}>();

const emits = defineEmits<{
  (e: "complete", value: SetupStep): void;
}>();

const setupStore = useConnectionSetupStoreV2();

const schemaComponent = computed(
  () => setupStore.schemaWrapper?.clientComponentName
);

const schema = computed(() => setupStore.schemaWrapper?.schema);

function onComplete(nodes: SchemaNode[]) {
  setupStore.configuredNodes = nodes;
  emits("complete", props.setupStep!);
}
</script>
