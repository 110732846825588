<template>
  <Transition
    enter-class="opacity-0"
    enter-active-class="transition-opacity"
    leave-active-class="transition-opacity"
    enter-to-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="showControlButtons"
      class="absolute -bottom-8 right-0 bg-white border border-black pointer-events-auto flex shadow-md rounded-md"
      @mousedown.stop
    >
      <Tooltip
        v-for="(btn, i) in controlButtons"
        :key="i"
        :text="btn.tooltipText"
        :class="{ 'border-r border-black': i < controlButtons.length - 1 }"
      >
        <div
          class="hover:bg-gray-200 active:bg-gray-300 cursor-pointer p-1"
          @click="btn.action"
          :class="{
            'rounded-l-md': i === 0,
            'rounded-r-md': i === controlButtons.length - 1,
          }"
        >
          <component
            :is="btn.icon + 'Icon'"
            stroke-width="1.5"
            class="h-4 w-4"
          />
        </div>
      </Tooltip>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import PencilIcon from "@/components/ui/icons/PencilIcon.vue";
import TrashIcon from "@/components/ui/icons/TrashIcon.vue";
import DuplicateIcon from "@/components/ui/icons/DuplicateIcon.vue";
import Tooltip from "@/components/Tooltip.vue";
import { EventBus } from "@/eventbus";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    PencilIcon,
    TrashIcon,
    DuplicateIcon,
    Tooltip,
  },
})
export default class WidgetUIControls extends Vue {
  @Prop({ type: String }) wid: string;
  @Prop({ type: String }) type: string;
  @Prop({ type: Number }) x: number;
  @Prop({ type: Number }) y: number;
  @Prop({ type: Number }) columnGap: number;
  @Prop({ type: Number }) rowGap: number;

  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get showControlButtons() {
    if (this.appEditor.isDraggingWidget) return false;
    return this.selections.includes(this.wid) && this.selections.length === 1;
  }

  get controlButtons() {
    const duplicateButton = {
      tooltipText: this.$t("widgetUi.duplicate"),
      icon: "Duplicate",
      action: this.handleDuplicateClick,
    };
    const deleteButton = {
      tooltipText: this.$t("widgetUi.delete"),
      icon: "Trash",
      action: this.handleDeleteClick,
    };

    const buttons = [duplicateButton, deleteButton];

    if (this.type === "Text") {
      buttons.unshift({
        tooltipText: this.$t("widgetUi.edit"),
        icon: "Pencil",
        action: this.handleEditTextClick,
      });
    }
    if (this.type === "Repeater") {
      buttons.unshift({
        tooltipText: this.$t("widgetUi.edit"),
        icon: "Pencil",
        action: this.handleEditRepeaterClick,
      });
    }

    return buttons;
  }

  handleDuplicateClick() {
    this.appEditor.copyWidgets();
    // Fire all logic that needs to happen when "paste" action is executed:
    EventBus.emit("DUPLICATE_WIDGET_ACTION");
  }

  handleDeleteClick() {
    this.appEditor.removeWidgetsAction({ widgetIds: this.selections });
  }

  handleEditTextClick() {
    this.appEditor.enterTextEditMode(this.wid);
  }

  handleEditRepeaterClick() {
    this.appEditor.setEditingContext({
      parentId: this.wid,
      repeaterIndex: 0,
      widgetX: this.x,
      widgetY: this.y,
      offsetX: this.columnGap,
      offsetY: this.rowGap,
    });
  }
}
</script>
