import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  ShadowOptions,
  DefaultShadowOptions,
} from "@/components/widgets/ShadowOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "../BackgroundOptions";
import { getDefaultAnimationOptions } from "@/components/widgets/Animation";
import { AnimationOptions } from "@/types/animation";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface LineOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    ShadowOptions,
    AnimationOptions,
    BackgroundOptions {
  linecap: string;
}

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultShadowOptions,
  ...DefaultBackgroundOptions,
  ...getDefaultAnimationOptions(),
  linecap: "square",
};

const CreateLine = (options: Partial<LineOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "Line",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.Line,
  });
};

export default CreateLine;
