<template>
  <div class="flex flex-col h-full">
    <div class="relative h-24 z-10 bg-gray-900 px-8">
      <slot name="header" />
      <div class="header-shadow" v-show="scrollY > 0 || isScrolled"></div>
    </div>

    <div
      class="relative scroll-body flex flex-col"
      ref="scroller"
      @scroll="$emit('scroll')"
      :class="[zeroPadding ? 'px-0' : 'px-8']"
    >
      <slot name="body" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ScrollView extends Vue {
  @Prop({ default: false }) zeroPadding: boolean;
  @Prop({ type: Boolean, required: false }) isScrolled?: boolean;
  scrollY = 0;

  mounted() {
    if (typeof this.isScrolled !== "boolean") {
      (this.$refs.scroller as HTMLElement).addEventListener(
        "scroll",
        this.handleScroll
      );
    }
  }

  beforeDestroy() {
    (this.$refs.scroller as HTMLElement).removeEventListener(
      "scroll",
      this.handleScroll
    );
  }

  handleScroll() {
    if (typeof this.isScrolled !== "boolean") {
      this.scrollY = (this.$refs.scroller as HTMLDivElement).scrollTop;
    }
  }
}
</script>

<style scoped lang="postcss">
.header-shadow {
  @apply absolute w-full left-0 right-0 h-2 top-full;
  @apply border-t border-gray-900;
  @apply pointer-events-none;

  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}

.scroll-body {
  flex: 1; /* takes the remaining height of the "container" div */
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
