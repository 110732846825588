<template>
  <div class="grid gap-y-2 gap-x-8 grid-cols-2 grid-rows-2 ml-10 pb-4 w-48">
    <div class="flex items-center">
      <EditorNumberInput
        class="w-20"
        label="X"
        :controls="true"
        :align="'left'"
        :min="-100"
        :max="100"
        :value="shadowX"
        @change="updateProp('shadowX', $event)"
      />
    </div>

    <div class="flex items-center ml-1">
      <EditorNumberInput
        class="w-20"
        icon="Blur"
        :controls="true"
        :min="0"
        :max="100"
        :step="1"
        :value="shadowBlur"
        @change="updateProp('shadowBlur', $event)"
      />
    </div>

    <div class="flex items-center">
      <EditorNumberInput
        class="w-20"
        label="Y"
        :controls="true"
        :align="'left'"
        :min="-100"
        :max="100"
        :value="shadowY"
        @change="updateProp('shadowY', $event)"
      />
    </div>

    <ScalarBinder
      propName="shadowColor"
      propType="Color"
      v-slot:default="slotProps"
    >
      <ColorInput
        class="flex items-center pl-2"
        iconSize="5"
        :value="slotProps.value"
        @change="updateProp('shadowColor', $event)"
        :dataBound="slotProps.isBound"
      />
    </ScalarBinder>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import EditorNumberInput from "@/components/inputs/EditorNumberInput.vue";
import ColorInput from "@/components/inputs/ColorInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import { DefaultShadowOptions } from "@/components/widgets/ShadowOptions";
import tinycolor from "tinycolor2";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { useAppEditorStore } from "@/stores/appEditor";

// TODO: ColorInputBar is not doing track=false

@Component({
  components: {
    EditorNumberInput,
    ColorInput,
    FormLabel,
    SelectMenu,
    ToggleInput,
    ScalarBinder,
  },
})
export default class ShadowEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  // TODO figure out how to share this across components...
  getValue<T>(key: string): T {
    let result: any;
    if (key in this.selectedProps) {
      let values = this.selectedProps[key] as any[];
      if (values.length === 1) {
        result = values[0];
      }
    }
    return result as T;
  }

  get shadowDisplay() {
    if (this.shadowColor === undefined) {
      return false;
    }

    let alpha = tinycolor(this.shadowColor as string).getAlpha();
    return (this.shadowX === 0 &&
      this.shadowY === 0 &&
      this.shadowBlur === 0) ||
      alpha === 0
      ? false
      : true;
  }

  updateProp(name: string, value: any) {
    this.appEditor.setWidgetProps(this.selections, {
      [name]: value,
      shadowDisplay: this.shadowDisplay,
    });
  }

  get shadowBlurStyle() {
    let result: any = {};
    result.filter = `drop-shadow(0px 0px 4px #000000)`;

    return result;
  }

  get shadowX() {
    return this.getValue("shadowX") || DefaultShadowOptions.shadowX;
  }

  get shadowY() {
    return this.getValue("shadowY") || DefaultShadowOptions.shadowY;
  }

  get shadowBlur() {
    return this.getValue("shadowBlur") || DefaultShadowOptions.shadowBlur;
  }

  get shadowColor() {
    return this.getValue("shadowColor") || DefaultShadowOptions.shadowColor;
  }
}
</script>
