<template>
  <div
    class="divide-y relative pb-12 select-none"
    :style="editorStyles"
    @mousedown="handleMousedown"
    @mouseup="handleMouseup"
  >
    <div
      class="w-full flex flex-row space-x-5 pt-3 px-6 pb-3 h-24"
      v-if="showLockEditor || selectionLocked || showAlignmentEditor"
    >
      <LockEditor class="pt-2" v-if="showLockEditor || selectionLocked" />
      <AlignmentEditor v-if="showAlignmentEditor && !showGroupButton" />
    </div>

    <CollapsePanel ref="conditionsPanel" v-if="showConditionsEditor">
      <template slot="title">
        <div v-t="'Conditions.title'"></div>
      </template>
      <ConditionsEditor />
    </CollapsePanel>

    <div
      v-if="!selectionLocked && (showGroupButton || showGroupRepeaterButton)"
      class="flex space-x-4 p-3 text-xs justify-center"
    >
      <ButtonGradient
        @click="createGroup"
        v-if="showGroupButton"
        class="text-white w-1/3 py-1 leading-tight"
      >
        <span v-t="'groupEditor.group'"></span
      ></ButtonGradient>
      <Tooltip
        :disabled="!makeRepeaterDisabled"
        :text="$t('repeaterEditor.disabledWidget')"
        :position="'b'"
        class="w-1/3"
      >
        <ButtonGradient
          @click="createGroup({ groupAsRepeater: true })"
          v-if="showGroupRepeaterButton"
          :disabled="makeRepeaterDisabled"
          class="text-white py-1 leading-tight"
        >
          <span v-t="'groupEditor.createRepeater'"></span
        ></ButtonGradient>
      </Tooltip>
    </div>
    <div
      v-if="showAlignmentEditor && showGroupButton"
      class="w-full flex flex-row items-center space-x-5 pt-3 px-6 pb-3 h-20"
    >
      <AlignmentEditor />
    </div>

    <component
      class="flex flex-col"
      :model="selectedWidget"
      :is="activeComponentEditor"
      v-if="showComponentEditor"
    ></component>

    <div v-if="isSimpleComponent && selections.length === 1">
      <BaseWidgetEditor :selectionLocked="selectionLocked" />
    </div>

    <div
      class="absolute inset-0 bg-gray-500 bg-opacity-90 p-3"
      v-show="portalHasContent"
    >
      <portal-target @change="onPortalChange" name="EditorPanel" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

import AlignmentEditor from "@/components/editors/AlignmentEditor.vue";
import DimensionsEditor from "@/components/editors/DimensionsEditor.vue";
import GroupEditor from "@/components/widgets/Group/GroupEditor.vue";
import LockEditor from "@/components/editors/LockEditor.vue";
import RotationEditor from "@/components/editors/RotationEditor.vue";
import OpacityEditor from "@/components/editors/OpacityEditor.vue";
import MultiComponentEditor from "@/components/editors/MultiComponentEditor.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import BorderEditor from "@/components/editors/BorderEditor.vue";
import { makeId } from "@/utils";
import Tooltip from "@/components/Tooltip.vue";
import ConditionsEditor from "@/components/editors/ConditionsEditor.vue";

import SvgEditor from "@/components/widgets/Svg/SvgEditor.vue";
import EllipseEditor from "@/components/widgets/Ellipse/EllipseEditor.vue";
import RectangleEditor from "@/components/widgets/Rectangle/RectangleEditor.vue";
import TextEditor from "@/components/widgets/Text/TextEditor.vue";
import CalendarEventEditor from "@/components/widgets/CalendarEvent/CalendarEventEditor.vue";
import CalendarDayEditor from "@/components/widgets/CalendarDay/CalendarDayEditor.vue";
import CalendarWeekEditor from "@/components/widgets/CalendarWeek/CalendarWeekEditor.vue";
import CalendarAgendaEditor from "@/components/widgets/CalendarAgenda/CalendarAgendaEditor.vue";
import CalendarRoomScheduleEditor from "@/components/widgets/CalendarRoomSchedule/CalendarRoomScheduleEditor.vue";
import CountdownEditor from "@/components/widgets/Countdown/CountdownEditor.vue";
import ProgressBarEditor from "@/components/widgets/ProgressBar/ProgressBarEditor.vue";
import ProgressDonutEditor from "@/components/widgets/ProgressDonut/ProgressDonutEditor.vue";
import ColumnGraphEditor from "@/components/widgets/ColumnGraph/ColumnGraphEditor.vue";
import BarGraphEditor from "@/components/widgets/BarGraph/BarGraphEditor.vue";
import LineGraphEditor from "@/components/widgets/LineGraph/LineGraphEditor.vue";
import PieGraphEditor from "@/components/widgets/PieGraph/PieGraphEditor.vue";
import StackedGraphEditor from "@/components/widgets/StackedGraph/StackedGraphEditor.vue";
import RepeaterEditor from "@/components/widgets/Repeater/RepeaterEditor.vue";
import ImageEditor from "@/components/widgets/Image/ImageEditor.vue";
import DatetimeEditor from "@/components/widgets/Datetime/DatetimeEditor.vue";
import AnalogClockEditor from "@/components/widgets/AnalogClock/AnalogClockEditor.vue";
import TriangleEditor from "@/components/widgets/Triangle/TriangleEditor.vue";
import LineEditor from "@/components/widgets/Line/LineEditor.vue";
import VideoEditor from "@/components/widgets/Video/VideoEditor.vue";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";
import { useAppEditorStore } from "@/stores/appEditor";
import { Widget } from "./widgets/Widget";

const singleSelectionComponents = [
  "CalendarEvent",
  "CalendarDay",
  "CalendarWeek",
  "CalendarAgenda",
  "CalendarRoomSchedule",
  "Countdown",
  "ProgressBar",
  "ProgressDonut",
  "Svg",
  "Repeater",
];

// These components should render BaseWidgetEditor (dims, rotation and opacity)
// Otherwise, component wants to expose other options inside of "Widget Settings" and has to handle that itself
const simpleComponents = [
  "Rectangle",
  "Ellipse",
  "Svg",
  "Datetime",
  "Triangle",
  "Line",
];

//

const editorName = (type: string) =>
  isNonEmptyString(type) ? `${type}Editor` : null;

@Component({
  components: {
    EllipseEditor,
    RectangleEditor,
    SvgEditor,
    TextEditor,
    CalendarEventEditor,
    CalendarDayEditor,
    CalendarWeekEditor,
    CalendarAgendaEditor,
    CalendarRoomScheduleEditor,
    CountdownEditor,
    ProgressBarEditor,
    ProgressDonutEditor,
    ColumnGraphEditor,
    BarGraphEditor,
    LineGraphEditor,
    PieGraphEditor,
    StackedGraphEditor,
    RepeaterEditor,
    ImageEditor,
    VideoEditor,
    DatetimeEditor,
    AnalogClockEditor,
    TriangleEditor,
    LineEditor,
    AlignmentEditor,
    DimensionsEditor,
    OpacityEditor,
    GroupEditor,
    RotationEditor,
    LockEditor,
    MultiComponentEditor,
    CollapsePanel,
    BaseWidgetEditor,
    BorderEditor,
    ButtonGradient,
    Tooltip,
    ConditionsEditor,
  },
})
export default class ComponentEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get selectedProps() {
    return this.appEditor.selectedProps;
  }

  get selectedTypes() {
    return this.appEditor.selectedTypes;
  }

  get selections() {
    return this.appEditor.selections;
  }

  get editableWidgets() {
    return this.appEditor.editableWidgets;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget as Widget;
  }

  get widgetById() {
    return this.appEditor.widgetById;
  }

  get textEditingWidget() {
    return this.appEditor.textEditingWidget;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  @Watch("selectedWidget.wid")
  onSelectedWidgetChange() {
    (this.$refs.conditionsPanel as CollapsePanel)?.collapse();
  }

  portalHasContent = false;

  get isSimpleComponent() {
    return simpleComponents.includes(this.selectedTypes[0]);
  }

  get showComponentEditor() {
    return (
      isNonEmptyString(this.activeComponentEditor) &&
      !this.selectionLocked &&
      this.selections.length === 1
    );
  }

  // If user is editing a repeater, do not show lock editor (rather, only show dataset explorer):
  get showLockEditor() {
    const isRepeater = ["Repeater"].some((str) =>
      (this.selectedTypes[0] || "").includes(str)
    );

    return this.isBaseEditingContext || !isRepeater;
  }

  createGroup(payload: { groupAsRepeater: boolean }) {
    const wid = makeId();
    const groupAsRepeater = payload?.groupAsRepeater || false;

    this.appEditor.groupWidgets({
      childWidgetIds: this.selections,
      groupWidgetId: wid,
      groupAsRepeater,
      parentId: this.editingContext.parentId,
    });
  }

  onPortalChange(hasContent: boolean) {
    this.portalHasContent = hasContent;
  }

  get editorStyles() {
    let result: any = {};
    result.fontFamily = "Inter, Sans-Serif";
    return result;
  }

  get selectionLocked() {
    return "locked" in this.selectedProps && this.selectedProps.locked[0];
  }

  get showGroupButton() {
    if (this.selections.length < 2) return false;
    if (this.selections.some((wid) => this.widgetById(wid)?.type === "Group"))
      return false;

    // Disallow groups within Repeaters for now
    // if (!this.isBaseEditingContext) return false;

    return true;
  }

  get showConditionsEditor() {
    if (this.selections.length > 1) return false;
    if (this.selectionLocked) return false;
    return true;
  }

  get showGroupRepeaterButton() {
    if (this.selections.length < 2) return false;
    if (
      this.selections.some(
        (wid) => this.widgetById(wid)?.type === "Repeater" || ""
      )
    )
      return false;
    if (!this.isBaseEditingContext) return false;
    return true;
  }

  get makeRepeaterDisabled() {
    let result = false;

    this.selectedTypes.forEach((type) => {
      if (
        [
          "ColumnGraph",
          "BarGraph",
          "LineGraph",
          "PieGraph",
          "StackedGraph",
          "CalendarAgenda",
          "CalendarDay",
          "CalendarEvent",
          "CalendarRoomSchedule",
          "CalendarWeek",
        ].includes(type)
      ) {
        result = true;
      }
    });
    return result;
  }

  // If user is editing a repeater, do not show alignment editor (rather, only show dataset explorer):
  get showAlignmentEditor() {
    const isRepeater = ["Repeater"].some((str) =>
      (this.selectedTypes[0] || "").includes(str)
    );

    if (isRepeater && !this.isBaseEditingContext) return false;
    // console.log("editing...", isRepeater, this.isBaseEditingContext);

    return (
      !this.selectionLocked && this.editableWidgets.length > 1
      //  &&this.isBaseEditingContext
    );
  }

  // TODO: Get rid of multicomp editor, or move grouping stuff in there
  get activeComponentEditor() {
    let result: string | null = null;
    if (
      this.selectedWidget &&
      singleSelectionComponents.includes(this.selectedWidget.type)
    ) {
      result = editorName(this.selectedWidget.type);
    } else if (this.selectedTypes.length === 1) {
      result = editorName(this.selectedTypes[0]);
    }

    return result ?? "";
  }

  handleMousedown(e: any) {
    if (this.textEditingWidget) {
      this.appEditor.clickFromEditorPanel = true;
    }
  }
  handleMouseup() {
    this.appEditor.clickFromEditorPanel = false;
  }
}
</script>
