import CreateEllipse from "./Ellipse/EllipseOptions";
import CreateImage from "./Image/ImageOptions";
import CreateRectangle from "./Rectangle/RectangleDefinition";
import CreateText from "./Text/TextOptions";
import CreateMultiframe from "./Multiframe/MultiframeOptions";
import CreateTriangle from "./Triangle/TriangleOptions";
import CreateLine from "./Line/LineOptions";
import CreateAnalogClock from "./AnalogClock/AnalogClockOptions";
import CreatePieGraph from "./PieGraph/PieGraphOptions";
import CreateLineGraph from "./LineGraph/LineGraphOptions";
import CreateBarGraph from "./BarGraph/BarGraphOptions";
import CreateColumnGraph from "./ColumnGraph/ColumnGraphOptions";
import CreateProgressDonut from "./ProgressDonut/ProgressDonutOptions";
import CreateProgressBar from "./ProgressBar/ProgressBarOptions";
import CreateCalendarRoomSchedule from "./CalendarRoomSchedule/CalendarRoomScheduleOptions";
import CreateCalendarAgenda from "./CalendarAgenda/CalendarAgendaOptions";
import CreateCountdown from "./Countdown/CountdownOptions";
import CreateRepeater from "./Repeater/RepeaterOptions";
import CreateCalendarWeek from "./CalendarWeek/CalendarWeekOptions";
import CreateCalendarDay from "./CalendarDay/CalendarDayOptions";
import CreateCalendarEvent from "./CalendarEvent/CalendarEventOptions";
import CreateDatetime from "./Datetime/DatetimeOptions";
import CreateSvg from "./Svg/SvgOptions";
import CreateStackedGraph from "./StackedGraph/StackedGraphOptions";
import { WidgetType } from "@/types";

export const getWidgetPropertyDefault = (
  widgetType: WidgetType,
  property: string
) => {
  let defaults: any = null;
  switch (widgetType) {
    case "Rectangle":
      defaults = CreateRectangle({});
      break;
    case "Text":
      defaults = CreateText({});
      break;
    case "Ellipse":
      defaults = CreateEllipse({});
      break;
    case "Image":
      defaults = CreateImage({});
      break;
    case "Group":
      // Not needed.
      break;
    case "Multiframe":
      defaults = CreateMultiframe({});
      break;
    case "Svg":
      defaults = CreateSvg({});
      break;
    case "Datetime":
      defaults = CreateDatetime({});
      break;
    case "CalendarEvent":
      defaults = CreateCalendarEvent({});
      break;
    case "CalendarDay":
      defaults = CreateCalendarDay({});
      break;
    case "CalendarWeek":
      defaults = CreateCalendarWeek({});
      break;
    case "Repeater":
      defaults = CreateRepeater({});
      break;
    case "Countdown":
      defaults = CreateCountdown({});
      break;
    case "CalendarAgenda":
      defaults = CreateCalendarAgenda({});
      break;
    case "CalendarRoomSchedule":
      defaults = CreateCalendarRoomSchedule({});
      break;
    case "ProgressBar":
      defaults = CreateProgressBar({});
      break;
    case "ProgressDonut":
      defaults = CreateProgressDonut({});
      break;
    case "ColumnGraph":
      defaults = CreateColumnGraph({});
      break;
    case "BarGraph":
      defaults = CreateBarGraph({});
      break;
    case "LineGraph":
      defaults = CreateLineGraph({});
      break;
    case "PieGraph":
      defaults = CreatePieGraph({});
      break;
    case "StackedGraph":
      defaults = CreateStackedGraph({});
      break;
    case "AnalogClock":
      defaults = CreateAnalogClock({});
      break;
    case "Triangle":
      defaults = CreateTriangle({});
      break;
    case "Line":
      defaults = CreateLine({});
      break;
  }

  if (property in defaults) {
    return defaults[property];
  }
};
