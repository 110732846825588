<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { getBoundingBox, getBoundingBoxWithVerticalDynamism } from "@/utils";
import { getActiveWidget } from "@/util/conditionsUtils";
import { Widget, WidgetWithConditions } from "@/components/widgets/Widget";
import GroupComponent from "@/components/widgets/Group/GroupComponent.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  inheritAttrs: false,
})
export default class GroupWrapper extends GroupComponent {
  get appEditor() {
    return useAppEditorStore();
  }

  get childrenWatchObject() {
    const childWids = this.context.parents[this.wid];
    return (childWids || []).map((wid: string) => this.context.widgets[wid]);
  }

  @Watch("childrenWatchObject", { deep: true })
  childrenChanged(widgets: WidgetWithConditions[]) {
    this.$nextTick(() => {
      if (!widgets) return;
      const widgetVersions = widgets.map((wg) =>
        getActiveWidget(wg, this.context.conditions)
      );
      // We need to find bbox differently if vertical dynamism is active
      const bbox = this.verticalDynamism
        ? getBoundingBoxWithVerticalDynamism(
            widgetVersions,
            this.verticalMargin
          )
        : getBoundingBox(widgetVersions);

      const props = { w: bbox.w, h: bbox.h };
      this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
    });
  }

  @Watch("verticalMargin")
  marginChanged() {
    // UPDATE GROUP HEIGHT based on (APPARENT) h of all wgs, plus margins:
    this.$nextTick(() => {
      const newH =
        this.children.slice(0).reduce((total: number, wg: Widget) => {
          return total + wg.h * wg.scaleY;
        }, 0) +
        this.verticalMargin * (this.children.length - 1);

      const props = { h: newH };
      this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
    });
  }
}
</script>
