<template>
  <div class="divide-y">
    <CollapsePanel :shouldListenForOpenCommand="true">
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <div>
        <CalendarDataChooser :widgetId="model.wid" />
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarDay.dayTitle'"></div>
      </template>

      <div class="py-3 px-4">
        <div
          class="text-sm text-gray-500 ml-2"
          v-t="'calendarDay.dateFormat'"
        ></div>
        <EditorSelectMenu
          :value="model.dayFormat"
          :options="dayFormatOptions"
          @input="apply({ dayFormat: $event })"
        />
      </div>
      <div class="pb-3 px-4">
        <div
          class="text-sm text-gray-500 ml-2"
          v-t="'calendarDay.dateOffset'"
        ></div>
        <EditorSelectMenu
          :value="model.dayOffset"
          :options="offsetOptions"
          @input="apply({ dayOffset: $event })"
        />
        <div
          class="text-sm text-gray-600 mt-2 px-2 py-1 leading-tight border rounded-sm shadow-sm"
          v-t="'calendarDay.offsetExplain'"
        ></div>
      </div>
      <div class="pb-5 px-7">
        <div class="text-sm text-gray-500" v-t="'calendarDay.hourRange'"></div>
        <DoubleSliderInput
          :min="0"
          :max="24"
          :minRange="3"
          :minValue="model.startHour"
          :maxValue="model.endHour"
          @updateMin="updateStartHour"
          @updateMax="updateEndHour"
        />

        <div class="flex justify-center">
          <div
            class="inline-block text-center text-xs px-3 py-1 bg-gray-100 border rounded-lg"
          >
            {{ formattedHourRange }}
          </div>
        </div>
      </div>

      <GroupTextFormatEditor
        :model="model"
        element="date"
        @update="apply($event)"
        :disableLineHeight="true"
      />
      <ToggleInput class="px-4" :value="model.syncFonts" @input="syncFontsInput"
        ><span class="text-sm" v-t="'syncFonts'"></span
      ></ToggleInput>

      <ToggleInput
        class="pt-2 pb-5 px-4"
        :value="model.syncTextColors"
        @input="syncTextColorsInput"
        ><span class="text-sm" v-t="'syncTextColors'"></span
      ></ToggleInput>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarAgenda.allDayEvent'"></div>
      </template>
      <div>
        <!-- <ColorInputBar
          class="py-3 px-4"
          :value="model.allDayEventTextColor"
          @input="preview({ allDayEventTextColor: $event })"
          @change="apply({ allDayEventTextColor: $event })"
        >
          <div
            class="font-semibold text-sm"
            v-t="'calendarAgenda.allDayEventTextColor'"
          ></div>
        </ColorInputBar> -->

        <GroupTextFormatEditor
          :model="model"
          element="allDayEvent"
          @update="apply($event)"
        />
        <ColorInputBar
          class="pt-3 pb-8 px-4"
          :value="model.allDayEventBackgroundColor"
          @input="preview({ allDayEventBackgroundColor: $event })"
          @change="apply({ allDayEventBackgroundColor: $event })"
          :gradientEnabled="true"
          propName="allDayEventBackgroundColor"
        >
          <span
            class="text-sm"
            v-t="'calendarAgenda.allDayEventBackgroundColor'"
          ></span>
        </ColorInputBar>
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarDay.eventTime'"></div>
      </template>
      <GroupTextFormatEditor
        :model="model"
        element="eventTime"
        @update="apply($event)"
        :disableAlignment="true"
      />
      <ToggleInput
        class="pb-5 px-4"
        :value="use24Hour"
        @input="apply({ use24Hour: $event })"
        ><span class="text-sm" v-t="'calendarDay.use24HourClock'"></span
      ></ToggleInput>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarDay.eventTitle'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="eventTitle"
          @update="apply($event)"
        />
      </div>
    </CollapsePanel>

    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarDay.eventLocation'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="eventLocation"
          @update="apply($event)"
        />
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarDay.eventBackground'"></div>
      </template>
      <div class="pb-5 pt-3 pl-6">
        <ScalarBinder
          propName="eventBackgroundColor"
          propType="Color"
          v-slot:default="slotProps"
          tooltipPosition="r"
        >
          <ColorInput
            :value="slotProps.value"
            :dataBound="slotProps.isBound"
            @input="preview({ eventBackgroundColor: $event })"
            @change="apply({ eventBackgroundColor: $event })"
        /></ScalarBinder>
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";

import { CalendarAgendaOptions } from "./CalendarAgendaOptions";
import CalendarDataChooser from "@/components/widgets/CalendarDataChooser.vue";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInput from "@/components/inputs/ColorInput.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import FormLabel from "@/components/FormLabel.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import DoubleSliderInput from "@/components/inputs/DoubleSliderInput.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import ScalarBinder from "@/components/editors/ScalarBinder.vue";
import { useAppEditorStore } from "@/stores/appEditor";

// Question: line height?? Seems useful to control vertical margins

@Component({
  components: {
    CollapsePanel,
    TextInput,
    ToggleInput,
    EditorSelectMenu,
    FormLabel,
    FontFamilyInput,
    ColorInput,
    ColorInputBar,
    CalendarDataChooser,
    GroupTextFormatEditor,
    DoubleSliderInput,
    BaseWidgetEditor,
    ScalarBinder,
  },
})
export default class CalendarAgendaEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as CalendarAgendaOptions;
  }

  get textElements() {
    return ["date", "eventTitle", "eventTime", "eventLocation"];
  }

  updateStartHour(ev: any) {
    const { value, track } = ev;
    this.apply({ startHour: value, track });
  }

  updateEndHour(ev: any) {
    const { value, track } = ev;
    this.apply({ endHour: value, track });
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  syncFontsInput(val: boolean) {
    this.appEditor.toggleSyncFonts({
      wid: this.model.wid,
      syncFonts: val,
      textElements: this.textElements,
    });
  }

  syncTextColorsInput(val: boolean) {
    this.appEditor.toggleSyncTextColors({
      wid: this.model.wid,
      syncTextColors: val,
      textElements: this.textElements,
    });
  }

  get dayFormatOptions() {
    const d = new Date();
    return [
      {
        label: "Today",
        value: "today",
      },
      {
        label: d.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        }),
        value: "date-short",
      },
      {
        label: d.toLocaleDateString("en-US", {
          month: "short",
          weekday: "short",
          day: "numeric",
        }),
        value: "date-med",
      },
      {
        label: d.toLocaleDateString("en-US", {
          month: "long",
          weekday: "long",
          day: "numeric",
        }),
        value: "date-long",
      },
    ];
  }

  get offsetOptions() {
    return [
      { label: this.$t("calendarOffsetLabel.yesterday"), value: -1 },
      { label: this.$t("calendarOffsetLabel.currentDay"), value: 0 },
      { label: this.$t("calendarOffsetLabel.oneDayAhead"), value: 1 },
      { label: this.$t("calendarOffsetLabel.twoDaysAhead"), value: 2 },
      { label: this.$t("calendarOffsetLabel.threeDaysAhead"), value: 3 },
    ];
  }

  get use24Hour() {
    return this.model.use24Hour;
  }

  // --------------------------------------------------------
  // Start / End Hours
  // --------------------------------------------------------

  get formattedHourRange() {
    return this.$t("calendarDay.hourRangeValues", {
      start: this.formatHour(this.model.startHour),
      end: this.formatHour(this.model.endHour),
    });
  }

  formatHour(hour: number) {
    return DateTime.fromObject({ hour: hour, minute: 0 }).toLocaleString({
      hour: "numeric",
      hour12: !this.use24Hour,
    });
  }
}
</script>
