<template>
  <div :id="uuid" v-html="displayValue"></div>
</template>

<script lang="ts">
import { DataType, DataValue } from "@/types/data";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DataNodeValue extends Vue {
  @Prop({ type: String, required: false }) readonly uuid: string;
  @Prop(String) readonly dataType: DataType;
  @Prop() readonly value: DataValue | undefined;

  /**
   * Use "&nbsp" in combination with changing `v-text` to `v-html` to render an empty cell by default, when value is null or undefined.
   */
  get displayValue() {
    return this.value?.formattedValue || this.value?.value || "&nbsp;";
  }
}
</script>

<style scoped></style>
