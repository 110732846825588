<template>
  <div id="reauthorize" class="flex items-center justify-center p-6 h-full">
    <div
      class="w-128 flex items-center justify-center p-6 rounded-md bg-gray-700/80 custom-shadow-box min-h-[240px]"
    >
      <div v-if="showSync" class="relative">
        <div
          v-if="isSynchronizing"
          class="flex flex-col items-center justify-center space-y-6"
        >
          <LoadingAnimation />
          <div v-t="'Reauthorize.synchronizingData'"></div>
        </div>
        <div
          v-if="showSuccessMessage"
          class="flex flex-col items-center justify-center space-y-4"
        >
          <div class="bg-app-green h-8 w-8 rounded-full">
            <IconSolid name="Checkmark" />
          </div>
          <div v-t="'Reauthorize.connectionRestored'"></div>
        </div>
      </div>

      <ThirdPartyOAuth
        class=""
        v-if="showThirdPartyAuth"
        :dataProviderId="dataProviderId"
        @success="onAuthSuccess"
        @error="onAuthError"
      >
        <div class="mb-4 text-red-500" v-if="showWrongAccountError === false">
          {{ thirdPartySyncError }}
        </div>
        <div
          class="mb-4 space-y-2 text-red-500"
          v-if="showWrongAccountError === true"
        >
          <div v-t="'Reauthorize.scopeError1'"></div>
          <div
            class="text-sm text-gray-400"
            v-text="$t('Reauthorize.scopeError2', { authProvider })"
          ></div>
          <div
            class="text-sm text-gray-400"
            v-t="'Reauthorize.scopeError3'"
          ></div>
        </div>
      </ThirdPartyOAuth>

      <div v-if="showNonThirdPartyAuthError" class="space-y-4">
        <div class="text-2xl" v-t="'Reauthorize.unableToConnect'"></div>
        <div v-t="'Reauthorize.anErrorOccurred'"></div>
        <div v-if="isErrorUnknown">
          <div v-t="'Reauthorize.possibleCauses'"></div>
          <ul class="ml-8">
            <li class="list-disc" v-t="'Reauthorize.noLongerExists'"></li>
            <li class="list-disc" v-t="'Reauthorize.notAccessible'"></li>
            <li class="list-disc" v-t="'Reauthorize.serverError'"></li>
          </ul>
        </div>
        <div v-else>{{ connectionErrorMessage }}</div>
        <div v-t="'Reauthorize.whatThisMeans'"></div>
        <div v-t="'Reauthorize.reachOut'"></div>
        <OutlineButton
          @click="$router.push(fixConnectionPath)"
          v-t="'Reauthorize.tryToSync'"
        ></OutlineButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import { getConnectionErrorMessage } from "@/components/data/connections/connectionErrorMessage";
import ThirdPartyOAuth from "@/components/data/connections/setup/ThirdPartyOAuth.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    ThirdPartyOAuth,
    UiBlocker,
    IconSolid,
    LoadingAnimation,
    OutlineButton,
  },
})
export default class Reauthorize extends Vue {
  showSync = false;
  isSynchronizing = false;
  showSuccessMessage = false;
  showWrongAccountError = false;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get errorCode() {
    return this.connectionEditor.errorCode;
  }

  get errorMessage() {
    return this.connectionEditor.errorMessage;
  }

  get showThirdPartyAuth() {
    return (
      this.showSync === false &&
      this.connection?.authProvider !== "None" &&
      this.errorCode === "authFailure"
    );
  }

  get showNonThirdPartyAuthError() {
    return this.showSync === false && this.showThirdPartyAuth === false;
  }

  get connectionErrorMessage() {
    return getConnectionErrorMessage(this.errorCode, this.errorMessage);
  }

  get isErrorUnknown() {
    return this.errorCode === "unknown";
  }

  get fixConnectionPath() {
    return this.$router.currentRoute.fullPath.replace(
      "reauthorize",
      "settings/sync"
    );
  }

  get authProvider() {
    return this.connection?.authProviderDisplay ?? "";
  }

  get dataProviderId() {
    return this.connection?.dataProvider ?? undefined;
  }

  get thirdPartySyncError() {
    let appName = this.connection?.dataProviderDisplay ?? "data source";
    return this.$t("Reauthorize.thirdPartyAuthError", { appName });
  }

  onAuthSuccess() {
    this.showWrongAccountError = false;
    this.isSynchronizing = true;
    this.showSync = true;
    this.connectionEditor
      .synchronizeData()
      .then(() => {
        this.showSuccessMessage = true;
      })
      .catch((reason) => {
        // If they get a sync error directly after
        // successfully authorizing, it means they
        // probably authorized a different account.
        this.showWrongAccountError = true;
        this.showSync = false;
        throw reason;
      })
      .finally(() => {
        this.isSynchronizing = false;
      });
  }

  onAuthError() {
    this.showWrongAccountError = false;
  }
}
</script>

<style scoped lang="postcss">
.custom-shadow {
  box-shadow: 0 10px 30px -10px #0004, inset 0 1px 0 #ffffff19;
}
</style>
