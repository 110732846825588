export const appSettings = {
  backEnd: {
    baseUrlAndPath: `${import.meta.env.VITE_APP_BACKEND_BASE_URL}/${
      import.meta.env.VITE_APP_BACKEND_BASE_PATH
    }`,
  },
  openId: {
    baseUrl: import.meta.env.VITE_APP_OPENID_BASE_URL,
  },
  i18n: {
    locale: import.meta.env.VITE_APP_I18N_LOCALE || "en",
    fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en",
  },
  environment: {
    isProduction: import.meta.env.PROD,
  },
};

export default appSettings;
