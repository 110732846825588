import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface LineGraphOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  h: number;
  w: number;
  minValue: number;
  maxValue: number;
  xTitle: string;
  yTitle: string;

  title_fontFamily: string;
  title_fontSize: number;
  title_fontWeight: number;
  title_textColor: string;
  title_textTransform: string;
  label_fontFamily: string;
  label_fontSize: number;
  label_fontWeight: number;
  label_textColor: string;
  label_textTransform: string;

  labelTicks: number;
  leftTitlePadding: number;
  bottomTitlePadding: number;
  bgColor: string;
  lineColor: string;
  lineThickness: number;
  plotlineColor: string;
  plotlineThickness: number;
  xLabelTilt: boolean;
  showBg: boolean;
  showGrid: boolean;
  showValues: boolean;
  xAxisColumnId: string;
  yAxisColumnId: string;
  colorColumn: string;
  xTitleManual: string | null;
  yTitleManual: string | null;
  minManual: number | null;
  maxManual: number | null;
}

export const defaultLineGraphOptions = {
  h: 400,
  w: 500,
  minValue: 336,
  maxValue: 875,
  xTitle: "Name",
  yTitle: "Height (m)",
  barPadding: 0.4,
  title_fontFamily: "Montserrat",
  title_fontSize: 36,
  title_fontWeight: 300,
  title_fontStyle: "normal",
  title_textColor: "#000000",
  title_textTransform: "none",
  label_fontFamily: "Montserrat",
  label_fontSize: 20,
  label_fontWeight: 700,
  label_fontStyle: "normal",
  label_textColor: "#000000",
  label_textTransform: "none",
  labelTicks: 6,
  leftTitlePadding: 0,
  bottomTitlePadding: 0,
  bgColor: "#f2f2f2",
  lineColor: "#000000",
  lineThickness: 1,
  plotlineColor: "#e21d48",
  plotlineThickness: 7,
  xLabelTilt: false,
  showBg: false,
  showGrid: false,
  showValues: false,
  xAxisColumnId: "a",
  yAxisColumnId: "b",
  xTitleManual: null,
  yTitleManual: null,
  minManual: null,
  maxManual: null,
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultLineGraphOptions,
};

const CreateLineGraph = (options: Partial<LineGraphOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "LineGraph",
    wid: makeId(),
    name: DEFAULT_LAYER_NAMES.LineGraph,
  });
};

export default CreateLineGraph;
