<template>
  <div>
    <div class="text-xl mb-2" v-if="title" v-text="title"></div>
    <div class="border bg-gray-800 border-gray-900 text-sm text-indigo-400">
      <pre
        class="bg-gray-700 bg-opacity-50 border-b border-gray-900 px-4 py-3"
        v-text="verbAndUri"
      ></pre>
      <div class="px-4 py-3">
        <div class="text-xs text-gray-500">Request Body</div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ApiExample extends Vue {
  @Prop(String) title: string;
  @Prop(String) verb: string;
  @Prop(String) uri: string;

  get verbAndUri() {
    return `${this.verb.toUpperCase()} ${this.uri}`;
  }
}
</script>

<style lang="postcss"></style>
