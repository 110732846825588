import { defineStore } from "pinia";
import { api } from "@/api/backend";
import {
  ConnectionSource,
  ConnectionSourceId,
  SetupStepId,
} from "@/types/ConnectionSources";
import {
  BindingType,
  ConnectionRefreshRate,
  DataBinding,
  DataConnection,
  Node,
  SchemaNode,
  SchemaType,
  SourceDataSchema,
  TableSchema,
  TabularDataSchema,
  DataBindingProperty,
  ConnectionOption,
} from "@/types/data";
import { PLACEHOLDER_CONNECTION_UUID } from "@/constants";
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { BASE_PARENT_ID } from "@/constants";
import { useConnectionDataStore } from "@/stores/connectionData";
import { removeReactivity } from "@/utils";
import { useConnectionSourceStore } from "./connectionSource";
import { RemapCollectionPayload, useAppEditorStore } from "./appEditor";
import { useConnectionsStore } from "./connections";
import { useConnectionEditorStore } from "./connectionEditor";

export interface UrlConnectionState {
  url: string;
  params: ConnectionOption[];
  headers: ConnectionOption[];
  method: string;
  body: string | undefined;
}

export interface ConnectionSetupState {
  /**
   * User defined name for the connection.
   */
  name: string | null;

  /**
   * The unique id for the connection.
   * For manual data sources, it is populated on the "complete" step.
   * For other sources, it is populated during the "provide" step.
   */
  connectionUuid: string | null;

  /**
   * Url Connection state for the connection.
   */
  urlConnection: UrlConnectionState | null;

  /**
   * Indicates if the connection data is editable
   */
  canEditData: boolean;

  /**
   * Authorization ID for the 3rd party data source for the connection
   */
  providerAuthId: string | null;

  /**
   * The connection source (chosen from the connection menu)
   */
  sourceId: ConnectionSourceId | string | undefined;

  /**
   * This is used to pre-populate the connection name input box.
   * We store the uploaded filename or google sheet name if available.
   */
  sourceName: string | null;

  /**
   * Whether the connection is a collection.
   * If false, it's a "Scalar" connection.
   */
  isCollection: boolean;

  /**
   * The user's selection for how often they want their url data source synchronized
   */
  refreshRate: ConnectionRefreshRate | null;

  /**
   * Represents the "sheet id" or "tree node id" or "calendar id" the user selected when defining their schema
   */
  queryContext: string | null;

  /**
   * The Tree or Calendar schema generated from the backend for the provided source data.
   */
  schema: SchemaNode[];

  /**
   * The Table schema(s) schema generated from the backend for the provided source data.
   */
  sheets: TableSchema[];

  /**
   * The type of schema used for this connection
   */
  schemaType: SchemaType | null;

  /**
   * This stores the users column configurations for collections
   */
  configuredNodes: SchemaNode[];

  /**
   * The current setup step
   */
  step: SetupStepId;

  /**
   * The selected node when creating a scalar connection
   */
  selectedNode: ScalarNode | null;

  /**
   * The app uuid.
   * Only applicable for ⚡️ binding
   */
  appUuid: string | null;

  /**
   * The id for the widget the user is attempting to bind to.
   * Only applicable for ⚡️ binding
   */
  widgetId: string | null;

  /**
   * The widget property the user is attempting to bind.
   * Only applicable for ⚡️ binding
   */
  propertyName: string | null;

  /**
   * The widget property the user is attempting to bind.
   * Only applicable for ⚡️ binding
   */
  propertyType: string | null;

  /**
   * Whether the user is replacing an existing connection with a new one.
   */
  isReplacing: boolean;

  /**
   * Whether the user has reached the complete step of connection setup.
   */
  isCompleted: boolean;

  /**
   * The uuid of the connection that will be replaced
   */
  connectionUuidToBeReplaced: string | null;

  /**
   * If the server throws an error that cannot
   * be handled here, show a message.
   */
  showFatalError: boolean;
}

export interface ScalarNode {
  uuid: string;
  query: string;
}

export interface SourceDataInfo {
  name: string | null;
  schema: SourceDataSchema;
  type: SchemaType;
  providerAuthId?: string;
}

export interface ConnectionSetupRouteParams {
  sourceId: ConnectionSourceId;
  connectionUuid: string | undefined;
  step: SetupStepId;
}

export interface SetupStep {
  id: SetupStepId;
  isCurrent: boolean;
  isComplete: boolean;
}

export const useConnectionSetupStore = defineStore("connectionSetup", {
  state: (): ConnectionSetupState => {
    return {
      appUuid: null,
      widgetId: null,
      sourceId: undefined,
      providerAuthId: null,
      connectionUuid: null,
      urlConnection: null,
      canEditData: false,
      isCollection: true,
      configuredNodes: [],
      queryContext: null,
      schema: [],
      sheets: [],
      schemaType: null,
      refreshRate: null,
      name: null,
      step: "Provide",
      sourceName: null,
      selectedNode: null,
      propertyName: null,
      propertyType: null,
      isReplacing: false,
      isCompleted: false,
      connectionUuidToBeReplaced: null,
      showFatalError: false,
    };
  },
  getters: {
    sheetCount(): number {
      return this.sheets === null ? 0 : this.sheets.length;
    },

    // Always assume the user wants to create a collection connection,
    // UNLESS they are pulling from a Url (i.e. json/xml) source AND seeking to bind to a non-"data" property.
    shouldCreateTreeConnection(): boolean {
      return (
        this.sourceId === "Url" &&
        typeof this.propertyName !== "undefined" &&
        this.propertyName !== "data"
      );
    },

    selectedSheetName() {
      if (this.schemaType === "Tabular" && this.queryContext) {
        const rangeId = this.queryContext as string;
        const sheets = this.sheets as TableSchema[];
        return sheets.find((s) => s.rangeId === rangeId)?.title;
      }
    },

    source(): ConnectionSource | undefined {
      const sourceStore = useConnectionSourceStore();
      return sourceStore.getSourceByName(this.sourceId);
    },

    steps(): SetupStep[] {
      if (this.source === undefined) return [];

      const appEditor = useAppEditorStore();
      const type: BindingType = this.isCollection ? "Collection" : "Scalar";
      const sourceSteps = this.source.setupSteps.slice();
      const widget = appEditor.widgets[this.widgetId as string];

      // HAAAAACK!!!!
      // Ensure that scalar binding creation process is snappy for the user -- do not ask for name/sharing info.
      if (type === "Scalar") {
        sourceSteps[1] = "Selection";
      } else if (
        typeof this.propertyName !== "undefined" &&
        this.propertyName !== "data"
      ) {
        // Another hack.
        // The user may be creating a collection connection, but wanting to create a scalar binding.
        // We detect their intention to create a scalar binding via this.propertyName -- if it's not "data", it must be scalar.
        // (But this fails when user is creating connection from dashboard -- then there isn't any property name)
        sourceSteps.splice(2, 0, "Selection");
      }

      /**
       * We only want to add the remap step if widget is repeater,
       * or a graph, if bound to data that is referenced by a filter or condition.
       * (Would be nice to share logic with RemapFilterConditionColumnsMixin...)
       */
      let mustRemapNodes = widget?.type === "Repeater";

      const connectionStore = useConnectionEditorStore();
      if (
        connectionStore.nodesRequiringRemap?.length > 0 &&
        !widget?.type.includes("Calendar")
      ) {
        mustRemapNodes = true;
      }
      // console.log("widget requires remap", widgetRequiresRemap);

      // Add replace step if replacing
      if (
        this.isReplacing &&
        mustRemapNodes &&
        sourceSteps.indexOf("Replace") === -1
      ) {
        sourceSteps.splice(sourceSteps.length - 1, 0, "Replace");
      }

      const currentIndex = sourceSteps.findIndex((ss) => ss === this.step);
      return sourceSteps.map((s, index) => {
        return {
          id: s,
          isCurrent: s === this.step,
          isComplete: index < currentIndex,
        };
      });
    },

    previousStep(): SetupStep | undefined {
      const currentIndex = this.steps.findIndex((s) => s.isCurrent);
      // console.log({ currentIndex, steps: this.steps });
      if (currentIndex > 0) {
        return this.steps[currentIndex - 1];
      }
    },

    schemaToBeReplaced(): Node[] {
      if (
        this.isReplacing &&
        this.widgetId &&
        this.connectionUuidToBeReplaced
      ) {
        const appEditor = useAppEditorStore();

        const nodeSet = useConnectionsStore().connections.find(
          (c) => c.uuid === this.connectionUuidToBeReplaced
        )?.nodeSets?.[0];

        const nodes = (nodeSet?.nodes ?? []).filter((n) => !n.isArtificial);

        const bindings = appEditor.dataBindings.filter(
          (db) =>
            db.parentWidgetId === this.widgetId &&
            db.dataConnectionUuid === this.connectionUuidToBeReplaced &&
            db.bindingType === "DataSetNode"
        );

        const schemaNodes =
          bindings
            .map((db) => nodes.find((n) => n.uuid === db.dataUuid))
            .filter((db) => typeof db !== "undefined") ?? [];

        return schemaNodes as Node[];
      }
      return [];
    },

    connectionName(): string {
      if (
        this.name === null ||
        typeof this.name === "undefined" ||
        (typeof this.name === "string" && this.name.trim().length === 0)
      ) {
        return "Default";
      }
      return this.name;
    },
  },

  actions: {
    loadSchema(connectionId: string) {
      return api.get<SourceDataSchema>(`dataconnection/${connectionId}/schema`);
    },

    /**
     * Stores the provided schema
     * @param schema Either TabularDataSchema or TreeDataSchema
     * @returns Route data for the next step
     */
    provideData(data: SourceDataInfo) {
      this.schemaType = data.type;
      this.sourceName = data.name;
      this.connectionUuid = data.schema.connectionUuid;
      this.providerAuthId = data.providerAuthId ?? null;

      if (data.type === "Tabular") {
        this.sheets = (data.schema as TabularDataSchema)?.sheets;
      } else {
        this.schema = data.schema.schema;
      }
      return this.completeStep("Provide");
    },

    /**
     * Stores the sheet id or tree node id the user selected when defining their schema
     * @param queryContext
     */
    setQueryContext(queryContext: string) {
      this.queryContext = queryContext;
    },

    /**
     * Stores the list of configured nodes for the collection
     * @param nodes
     * @returns Route data for the next step
     */
    defineSchema(nodes: SchemaNode[]) {
      this.configuredNodes = nodes;
      return this.completeStep("Schema");
    },

    defineSelectedNode(node: ScalarNode) {
      this.selectedNode = { uuid: node.uuid, query: node.query };
      return this.completeStep("Selection");
    },

    /**
     * Stores the refreshRate for synchronized connections
     * @param refreshRate
     * @returns
     */
    defineSync(refreshRate: ConnectionRefreshRate) {
      this.refreshRate = refreshRate;
      return this.completeStep("Sync");
    },

    defineName(name: string) {
      this.name = name;
      return this.completeStep("Name");
    },

    defineReplacements(payload: RemapCollectionPayload) {
      const appEditor = useAppEditorStore();
      return appEditor.remapCollection(payload).then(() => {
        return this.completeStep("Replace");
      });
    },

    saveConnection(): Promise<DataConnection> {
      if (this.source === undefined) throw new Error("Source is undefined");

      const promise =
        this.source.provisionMethod === "None"
          ? this.saveManualData()
          : this.saveUrlOrUpload();

      return promise
        .then((result) => {
          this.connectionUuid = result.uuid;
          this.canEditData = result.canEditData;

          if (this.widgetId) {
            const appEditor = useAppEditorStore();
            if (
              appEditor.selectedWidget !== undefined &&
              this.connectionUuid != PLACEHOLDER_CONNECTION_UUID &&
              (appEditor.selectedWidget.type.includes("Graph") ||
                appEditor.selectedWidget.type.includes("Calendar")) &&
              this.isReplacing
            ) {
              this.replaceDataBinding(result);
            } else {
              return this.createBinding(result);
            }
          }

          /**
           * I am not 100% sure this is the correct place since similar
           * logic is used elswhere PreviewData and ScalarSelect.
           *
           * But it cleaned up the Setup.vue file quite a bit and
           * illustrates that we can communicate with vuex from Pinia
           * (until we have the chance to completely replace vuex with Pinia).
           */

          return result;
        })
        .catch((errors) => {
          this.showFatalError = true;
          throw errors;
        });
    },

    async createBinding(connection: DataConnection) {
      // It is possible that this.isCollection is true, but the property name is NOT "data"
      // (when a user is creating a collection connection and a scalar binding at the same time).
      // In these cases we need to create a scalar binding.
      if (this.isCollection && this.propertyName === "data") {
        return this.createCollectionBinding(connection);
      } else {
        return this.createScalarBinding(connection);
      }
    },

    async replaceDataBinding(connection: DataConnection) {
      const appEditor = useAppEditorStore();

      if (appEditor.selectedWidget === undefined) {
        throw new Error("Selected widget is undefined");
      }

      await appEditor.replaceDataBinding({
        connection: connection,
        widget: appEditor.selectedWidget,
      });

      // Needed in case data is filtered
      await appEditor.updateApp();

      return connection;
    },

    async createScalarBinding(connection: DataConnection) {
      const conditionGroupsStore = useConditionGroupsStore();
      const activeConditionId = conditionGroupsStore.getActiveConditionId(
        this.widgetId as string
      );
      const binding: DataBinding = {
        widgetId: this.widgetId as string,
        property: this.propertyName as DataBindingProperty,
        dataUuid: this.selectedNode?.uuid as string,
        dataName: this.selectedNode?.query as string,
        query: this.selectedNode?.query as string,
        dataConnectionUuid: connection.uuid,
        bindingType: "Scalar",
        parentWidgetId: BASE_PARENT_ID,
        conditionUuid: activeConditionId,
      };

      const appEditor = useAppEditorStore();

      // console.log("Creating scalar binding.", binding, this.selectedNode);

      appEditor.addDataBinding(binding);

      await appEditor.updateApp();

      return connection;
    },

    async createCollectionBinding(connection: DataConnection) {
      const appEditor = useAppEditorStore();

      const widget = appEditor.widgets[this.widgetId as string];
      const parentNode = connection.nodeSets[0];

      const bindingType =
        widget?.type === "Repeater" ? "DataSetParent" : "DataSet";

      const binding: DataBinding = {
        widgetId: this.widgetId as string,
        property: this.propertyName as DataBindingProperty,
        bindingType: bindingType,
        dataUuid: parentNode.uuid as string,
        dataName: parentNode.name,
        dataConnectionUuid: connection.uuid,
      };

      appEditor.addDataBinding(binding);

      await useConnectionDataStore().initializeConnection(connection);

      return connection;
    },

    saveUrlOrUpload(): Promise<DataConnection> {
      const source = this.source;
      if (source === undefined)
        throw new Error("Source is undefined in saveUrlOrUpload");

      let nodes = [];
      if (this.isCollection) {
        nodes = this.configuredNodes;
      } else {
        if (this.schemaType === "Tabular") {
          const sheet = this.sheets.find(
            (s) => s.rangeId === this.queryContext
          );
          if (typeof sheet === "undefined") {
            throw new Error("Can't find sheet based on stored queryContext");
          }
          nodes = sheet.schema;
        } else {
          nodes = Array.isArray(this.schema) ? this.schema : [this.schema];
        }
      }

      const payload: any = {
        name: this.connectionName,
        providerAuthId: this.providerAuthId,
        nodes,
        isCollection: this.isCollection,
        isUpload: source.provisionMethod === "Upload",
      };

      if (this.appUuid !== null) {
        payload.appUuid = this.appUuid;
      }

      if (source.provisionMethod === "Url") {
        payload.refreshRateSec = this.refreshRate;
      }

      return api.put<DataConnection>(
        `dataconnection/${this.connectionUuid}`,
        payload
      );
    },

    saveManualData(): Promise<DataConnection> {
      const nodes = this.configuredNodes.map((n) => {
        return removeReactivity<SchemaNode[]>(n);
      });

      nodes.forEach((c: any) => {
        delete c.sampleValues;
        delete c.altTypes;
        c.shouldDelete = false;
      });

      const payload: any = {
        name: this.connectionName,
        nodes: nodes,
        refreshRate: "Never",
        isCollection: true,
      };

      if (this.appUuid !== null) {
        payload.appUuid = this.appUuid;
      }

      return api.post<DataConnection>(
        "dataconnection/manualtabledata/schema",
        payload
      );
    },

    /**
     * Returns a new url for the next step in the setup process.
     * @param step The step that has just been completed
     */
    completeStep(step: SetupStepId): Promise<ConnectionSetupRouteParams> {
      const sourceSteps = this.steps;
      const index = sourceSteps.findIndex((s) => s.id === step);

      if (index === -1 || index + 1 > sourceSteps.length - 1) {
        return Promise.reject("Can't find next step");
      }

      const nextStep = sourceSteps[index + 1].id;

      const params: ConnectionSetupRouteParams = {
        sourceId: this.sourceId as ConnectionSourceId,
        connectionUuid: this.connectionUuid as string,
        step: nextStep,
      };

      const connectionDataStore = useConnectionDataStore();

      if (nextStep === "Complete") {
        return this.saveConnection().then((result) => {
          this.step = nextStep;
          this.isCompleted = true;

          // Open up this new connection in the left hand panel
          connectionDataStore.openNewConnection(result);

          // Manual data sources don't get their connection UUID until now, so set it.
          if (result.uuid) {
            params.connectionUuid = result.uuid;
          }
          return params;
        });
      }

      this.step = nextStep;
      return Promise.resolve(params);
    },
  },
});
