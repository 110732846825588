<template>
  <button
    type="button"
    @click="($event) => click($event)"
    class="button-gloss shadow px-4 py-1 rounded font-bold text-white bg-app-green select-none"
    :disabled="disabled || working"
    :class="{
      'cursor-not-allowed opacity-50': disabled,
      'cursor-wait opacity-50': working,
      'dark-form-focus': dark,
    }"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class FormButton extends Vue {
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) working: boolean;
  @Prop(Boolean) dark: boolean;

  click(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.working || this.disabled) {
      return;
    }
    this.$emit("click");
  }
}
</script>
