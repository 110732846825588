<template>
  <div
    @click.stop
    v-if="textEditingWidget"
    id="canvas-text-editor"
    :style="{ transform: `scale(${scale})` }"
    class="absolute top-0 left-0 w-full h-full"
  >
    <Artboard :renderBackground="false">
      <div
        :style="transformStyles"
        ref="editor"
        @mousemove="mousemove"
        @mouseleave="mouseleave"
        @mouseup="mouseup"
      >
        <TextDataTokenMenu
          v-if="tokenOptions.length > 0"
          :options="tokenOptions"
          @selected="onTokenSelected"
          :position="dataTokenMenuPosition"
        />

        <editor-content :style="textStyles" :editor="editor" />
      </div>
    </Artboard>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from "vue-property-decorator";
import { styler } from "@/lib/free-transform";

import { Editor, EditorContent, JSONContent } from "@tiptap/vue-2";

import { getTextStyleCss } from "@/textfit";
import Artboard from "@/components/Artboard.vue";
import TextDataTokenMenu from "@/components/TextDataTokenMenu.vue";

import { BASE_PARENT_ID } from "@/constants";
import { DataBinding, NodeData, NodeSetData } from "@/types/data";
import { TextOptions } from "@/components/widgets/Text/TextOptions";
import { NO_UNDO, Point } from "@/types";
import { getApparentDims, makeId, scaledCoords } from "@/utils";
import { contentSchema } from "@/text/schema";
import { DATA_TOKEN_TYPE, TextContent } from "@/text";
import { bindDynamicTextContent } from "@/text/binding";
import { KeyCodes } from "@/keycodes";
import { RepeaterOptions } from "@/components/widgets/Repeater/RepeaterOptions";
import { EventBus } from "@/eventbus";
import { DraggingInfo, useDragDropStore } from "@/stores/dragDrop";

/**
 * There are 3 ways to add a data token to a text widget.
 * Two of them occur when the text is being edited, and so are handled here in this component:
 *
 * 1. Drag a data node from left-hand panel into a text widget: create a scalar binding.
 *
 * 2. Use the "add data" dropdown to bind a data token in a child text widget to a column from the parent repeater.
 *
 * 3. Drag a data node from the left-hand panel to create a new text widget.
 * This is handled in connectionDataStore.createDataWidget
 */
import { useConditionGroupsStore } from "@/stores/conditionGroups";
import { useAppEditorStore } from "@/stores/appEditor";
import { logger } from "@core/logger";
import { GroupOptions } from "./widgets/Group/GroupOptions";
import { useAppDataStore } from "@/stores/appData";
import { useConnectionsStore } from "@/stores/connections";
import isEqual from "lodash.isequal";

@Component({
  components: {
    Artboard,
    EditorContent,
    TextDataTokenMenu,
  },
})
export default class CanvasTextEditor extends Vue {
  editor!: Editor;

  mouseCoords: { pos: number } | null = null;
  cursorElement: HTMLElement | null = null;
  mouseReleaseCoords: { pos: number } | null = null;

  // Track the startingContent so we can _only_ track changes to the widget when content actually changes.
  // Reset it after adding a data token so that if user only does that, we don't track it twice. But if they make more changes, we track them too.
  startingContent: any = null;

  get appData() {
    return useAppDataStore();
  }

  get appEditor() {
    return useAppEditorStore();
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get canvasBox() {
    return this.appEditor.canvasBox;
  }

  get scale() {
    return this.appEditor.scale;
  }

  get artboardPosition() {
    return this.appEditor.artboardPosition;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  get widgets() {
    return this.appEditor.widgets;
  }

  get textEditingWidget() {
    if (this.appEditor.textEditingWidget === null) {
      logger.track("CanvasTextEditor. textEditingWidget is null");
      return "";
    }
    return this.appEditor.textEditingWidget;
  }

  addingToken = false;

  created() {
    this.editor = new Editor({
      content: "",
      extensions: contentSchema,
      editorProps: {
        handleDOMEvents: {
          keydown: (view: any, ev: any) => {
            if (ev.code === "Backspace") {
              this.handleBackspace(view.state);
            }
            if (KeyCodes.isUndo(ev)) {
              // Avoid tiptap's standard "undo" behavior:
              return false;
            }
            return true;
          },
        },
      },

      onBlur: ({ event }) => {
        // console.log("blur is called");

        const undoArg = this.addingToken ? "NO_UNDO" : undefined;

        // No idea why need this to access state here
        this.applyText(undoArg);
        const wid = this.appEditor.textEditingWidget || "";
        this.appEditor.leaveTextEditMode();

        if (this.appEditor.clickFromEditorPanel) {
          // Undo the fact that leaveTextEditMode has deselected the widget:
          this.appEditor.replaceSelections([wid]);
        }
        // [ ] For now  handdling in mutation, but doesn't solve "click other wg" problem
        // Issue when click right panel -- should not deselect then.
      },

      onUpdate: () => {
        this.applyText("NO_UNDO");
      },
    });

    EventBus.on("DATA_TOKEN_DROPPED", this.addDataToken as any);
  }

  beforeDestroy() {
    this.editor.destroy();
    EventBus.off("DATA_TOKEN_DROPPED", this.addDataToken as any);
  }

  // This reacts to an event that is fired from DraggableNode.vue, when a data node is dropped into the text widget.
  async addDataToken(payload: {
    widgetId: string;
    parentWidgetId: string;
    conditionId: string;
    draggingInfo: DraggingInfo;
  }) {
    const { widgetId, conditionId, draggingInfo, parentWidgetId } = payload;
    const { dataUuid, query, connectionUuid } = draggingInfo;

    try {
      const content = this.editor.schema.nodes[DATA_TOKEN_TYPE].create({
        uuid: `${dataUuid}`,
      });

      const jsonContent = JSON.parse(JSON.stringify(content));
      this.editor
        .chain()
        .focus()
        .setTextSelection({
          from: this.mouseReleaseCoords?.pos || 0,
          to: this.mouseReleaseCoords?.pos || 0,
        })
        .insertContent(jsonContent)
        .insertContent(JSON.parse(JSON.stringify(this.editor.schema.text(" "))))
        .run();

      /**
       * Must create a non-scalar (DataSetNode) binding if dropped into a repeater,
       * from the same dataset that is bound to the repeater, or from any dataset, if none is bound.
       */
      const targetRepeaterConnectionUuid = this.appEditor.bindingsForComponent({
        widgetId: parentWidgetId,
        bindingType: "DataSetParent",
      })?.[0]?.dataConnectionUuid;
      const isDroppedInRepeater =
        parentWidgetId && parentWidgetId !== BASE_PARENT_ID;
      const isDroppedFromSameDataset =
        targetRepeaterConnectionUuid === connectionUuid;
      let isScalar = true;
      if (
        isDroppedInRepeater &&
        (isDroppedFromSameDataset ||
          targetRepeaterConnectionUuid === "undefined")
      ) {
        isScalar = false;
      }

      this.appEditor.createDataToken({
        widgetId,
        conditionId,
        isScalar,
        shouldCreateRepeaterBinding: !!(
          isDroppedInRepeater && targetRepeaterConnectionUuid === "undefined"
        ),
        connectionId: connectionUuid as string,
        parentWidgetId,
        query,
        dataUuid: dataUuid as string,
        textTokenUuid: makeId(),
        content: this.editor.getJSON() as TextContent,
      });

      // Must run AFTER binding is created
      if (isScalar) {
        // This will trigger an extraneous call to syncDataForBindings via Editor.vue, but oh well.
        await this.appEditor.updateApp();
        // Wait for widget data to refresh before attempting to getBindingData and insert content
        await this.appData.refreshWidgetData(this.widget.wid);
      }

      this.setEditorContent();
      this.startingContent = this.editor.getJSON();
    } finally {
      EventBus.emit("AWAITING_SERVER", false);
      EventBus.emit("DATA_TOKEN_ADDED");
      this.dragDropStore.isHandlingDrop = false;
      this.dragDropStore.draggingInfo = null;
    }
  }

  get proseMirrorElement() {
    return this.$el.querySelector(".ProseMirror");
  }

  draggingMouseCoordinates: Point | null = null;

  droppedMouseCoordinates: Point = { x: 0, y: 0 };

  @Watch("draggingMouseCoordinates", { deep: true })
  mouseCoordinatesChanged() {
    if (this.draggingMouseCoordinates) {
      // Update cursor position
      this.updateCursorPosition();
    } else {
      // Clear out cursor position, and store coordinates for token-creation action in addDataToken
      this.onTokenDropped();
    }
  }

  updateCursorPosition() {
    const { view } = this.editor;

    this.droppedMouseCoordinates = {
      ...this.draggingMouseCoordinates,
    } as Point;

    const coordinates = view.posAtCoords({
      left: this.draggingMouseCoordinates?.x || 0,
      top: this.draggingMouseCoordinates?.y || 0,
    });

    if (coordinates) {
      (this.proseMirrorElement as HTMLElement).style.caretColor = "transparent";

      // Show a cursor where the user is dragging to signal where their node will get dropped:
      this.mouseCoords = { ...coordinates };
      this.updateCursorStyle();
    }
  }

  onTokenDropped() {
    const { view } = this.editor;

    const coordinates = view.posAtCoords({
      left: this.droppedMouseCoordinates.x,
      top: this.droppedMouseCoordinates.y,
    });

    this.mouseReleaseCoords = coordinates;
    (this.proseMirrorElement as HTMLElement).style.caretColor = "#000";

    if (this.cursorElement) this.cursorElement.style.display = "none";
  }

  // Copied from dropCursor at https://github.com/ProseMirror/prosemirror-dropcursor
  updateCursorStyle() {
    const { view } = this.editor;
    const WIDTH = 2;

    if (isNaN(this.mouseCoords?.pos || 0)) return;

    const pos = this.mouseCoords?.pos;
    // console.log("Pos", pos);

    let $pos = view.state.doc.resolve(pos || 0);

    let isBlock = !$pos.parent.inlineContent,
      rect;

    if (isBlock) {
      let before = $pos.nodeBefore,
        after = $pos.nodeAfter;
      if (before || after) {
        let node = view.nodeDOM(pos! - (before ? (before as any).nodeSize : 0));
        if (node) {
          let nodeRect = (node as HTMLElement).getBoundingClientRect();
          let top = before ? nodeRect.bottom : nodeRect.top;
          if (before && after)
            top =
              (top +
                (view.nodeDOM(pos!) as HTMLElement).getBoundingClientRect()
                  .top) /
              2;
          rect = {
            left: nodeRect.left,
            right: nodeRect.right,
            top: top - WIDTH / 2,
            bottom: top + WIDTH / 2,
          };
        }
      }
    }

    if (!rect) {
      let coords = this.editor.view.coordsAtPos(pos!);
      rect = {
        left: coords.left - WIDTH / 2,
        right: coords.left + WIDTH / 2,
        top: coords.top,
        bottom: coords.bottom,
      };
    }

    let parent = view.dom.offsetParent!;
    if (!this.cursorElement) {
      this.cursorElement = parent.appendChild(document.createElement("div"));

      this.cursorElement.style.cssText =
        "position: absolute; z-index: 50; pointer-events: none;";
      this.cursorElement.style.backgroundColor = "#444";
    }

    let parentLeft, parentTop;

    if (
      !parent ||
      (parent == document.body && getComputedStyle(parent).position == "static")
    ) {
      parentLeft = -pageXOffset;
      parentTop = -pageYOffset;
    } else {
      let rect = parent.getBoundingClientRect();
      parentLeft = rect.left - parent.scrollLeft;
      parentTop = rect.top - parent.scrollTop;
    }

    // Multiple by 1/scale to ensure this works at any canvas scale
    this.cursorElement.style.display = "block";
    this.cursorElement.style.left =
      (1 / this.scale) * (rect.left - parentLeft) + "px";
    this.cursorElement.style.top =
      (1 / this.scale) * (rect.top - parentTop) + "px";
    this.cursorElement.style.width =
      (1 / this.scale) * (rect.right - rect.left) + "px";
    this.cursorElement.style.height =
      (1 / this.scale) * (rect.bottom - rect.top) + "px";
  }

  get widget() {
    // We know this is a Text widget because
    // we check when setting textEditingWidget
    return this.appEditor.widgetById(this.textEditingWidget) as TextOptions;
  }

  handleBackspace(state: any) {
    const {
      selection: { $from, from, to },
      doc,
    } = state; // get current selection

    const selectedNode = state.selection.node;

    // Handle case where user deletes a selection of text that includes data tokens:
    let tokensToDelete: any[] = [];
    doc.nodesBetween(from, to, (node: any) => {
      if (node.type.name === DATA_TOKEN_TYPE) {
        tokensToDelete.push(node);
      }
    });

    // Check node before cursor
    const nodeBeforeIsToken = $from.nodeBefore?.type.name === DATA_TOKEN_TYPE;

    if (nodeBeforeIsToken) {
      tokensToDelete = [$from.nodeBefore];
    }

    // Check node selection
    const selectedNodeIsToken = selectedNode?.type.name === DATA_TOKEN_TYPE;

    if (selectedNodeIsToken) {
      tokensToDelete = [selectedNode];
    }

    this.handleTokenDeletion(tokensToDelete);
  }

  /**
   * When a token is deleted, we want to delete it's associated data bindings,
   * but only if the same token doesn't also exist somewhere else
   */
  handleTokenDeletion(deletedTokens: any[]) {
    // Wait a tick so that it actually deletes the stuff
    // console.log("delete tokens...", deletedTokens);
    this.$nextTick(() => {
      deletedTokens.forEach((node: any) => {
        this.appEditor.removeDataTokenBinding({
          widgetId: this.textEditingWidget,
          dataUuid: node.attrs.uuid,
          conditionUuid: useConditionGroupsStore().getActiveConditionId(
            this.textEditingWidget
          ),
        });
      });
    });
  }

  /**
   * Transforms, sanitizes and stores editor content into the widget data
   */
  applyText(NO_UNDO?: NO_UNDO) {
    let content = this.editor.getJSON();
    if (content.content[0].content) {
      content.content[0].content.forEach((e: any) => {
        if (e.type === "text") {
          e.text = e.text.replace(
            /&nbsp;|<\/span>&nbsp;|\u00a0|\u202f|\u0020/g,
            " "
          );
        } else if (e.type === "datatoken") {
          e.attrs.text = e.attrs.text.replace(
            /&nbsp;|<\/span>&nbsp;|\u00a0|\u202f|\u0020/g,
            " "
          );
        }
      });
    }

    // On the blur event, we don't need to updateTextContent, if it hasn't changed since user started editing.
    if (NO_UNDO === undefined && isEqual(content, this.startingContent)) {
      return;
    }

    this.appEditor.updateTextContent(
      {
        wid: this.textEditingWidget,
        content: content as TextContent,
      },
      NO_UNDO
    );
    const editorContent = bindDynamicTextContent(
      this.widget as TextOptions,
      this.appEditor.dataBindings,
      this.dataNodes
    );

    const { from, to } = this.editor.state.selection;
    this.editor.commands.setContent(editorContent);
    this.editor.commands.setTextSelection({ from, to });
  }

  get textStyles() {
    let dynamicProps = this.widgetData[this.textEditingWidget];
    const options = JSON.parse(JSON.stringify(this.widget));
    let styles = getTextStyleCss(options);

    styles.width = this.transformStyles.width;
    styles.height = this.transformStyles.height;
    if (dynamicProps.length > 0) {
      dynamicProps.forEach((p) => {
        let key = Object.keys(p)[0];
        let value = Object.values(p)[0];
        if (key === "textColor") {
          styles.color = value;
        }
        if (key === "opacity") {
          styles.opacity = value;
        }
      });
    }
    return styles;
  }

  get tokenOptions() {
    // NOTE: We will need to handle non-repeater bindings when XML/JSON is implemented.
    const connection = useConnectionsStore().connections.find(
      (c) => c.uuid === this.repeaterBinding?.dataConnectionUuid
    );

    return (connection?.nodeSets?.[0]?.nodes || []).map((n, i) => {
      return { label: n.name, value: n.uuid };
    });
  }

  /**
   * Get databinding for parent (or grandparent) repeater (if it exists)
   */
  get repeaterBinding() {
    if (!this.widget) return null;

    // This computed property used to reference the getParentRepeaterBinding() getter.
    // But it was the only usage, so moving it to this computed instead.
    const parent = this.widgets[this.widgets[this.widget.wid]?.parentId];

    if (parent && parent.type === "Repeater") {
      return this.appEditor.dataBindings.find(
        (db: DataBinding) =>
          db.widgetId === parent.wid &&
          (db.bindingType === "DataSetParent" || db.bindingType === "DataSet")
      );
    } else if (parent && parent.type === "Group") {
      return this.appEditor.dataBindings.find(
        (db: DataBinding) =>
          db.widgetId === parent.parentId &&
          (db.bindingType === "DataSetParent" || db.bindingType === "DataSet")
      );
    }
    return undefined;
  }

  get dataNodes() {
    let nodes: NodeData[] = [];

    // Gather nodes that have scalar text bindings:
    const dataSet = this.appData.data[this.widget?.wid];

    const contentData = Object.keys(dataSet || {}).reduce((acc, key) => {
      if (key.startsWith("content")) {
        return { ...acc, [key]: dataSet[key] };
      } else {
        return acc;
      }
    }, {});

    if (dataSet && Object.keys(dataSet).some((k) => k.startsWith("content"))) {
      nodes = Object.values(contentData);
    }

    // Get proper row to support DataSetNode bindings:
    if (this.repeaterBinding && "wid" in this.widget) {
      // We can be certain the parent is a Repeater or Group if `repeaterBinding` is not null
      const parent = this.appEditor.widgetById(this.widget.parentId) as
        | RepeaterOptions
        | GroupOptions;
      const key =
        parent.type === "Group" ? parent.parentId : this.widget.parentId;
      const dataSet = this.appData.data[key];

      // "Data" is the name of the property that Repeater datasets are bound to
      let rows = (dataSet?.data as NodeSetData)?.children as NodeData[][];

      const parentRepeater = this.appEditor.widgetById(key) as RepeaterOptions;

      // Account for paging and sorting of repeater widget:
      const { rows: repeaterRows, columns } =
        parentRepeater as unknown as RepeaterOptions;

      // Handle paging
      const cycleIndex = this.appEditor.cyclingIndexes[key] || 0;

      const pageSize = columns * repeaterRows;
      const dataIndex =
        (this.editingContext.repeaterIndex || 0) + cycleIndex * pageSize;

      if (rows && rows.length > 0 && typeof dataIndex === "number") {
        nodes = [...nodes, ...rows[dataIndex]];
      }
    }

    return nodes;
  }

  getBindingData(contentBindings: DataBinding[]) {
    // console.log("get binding data", this.dataNodes);
    if (contentBindings.length > 0 && this.dataNodes.length > 0) {
      return contentBindings.reduce(
        (result: { [key: string]: any }, b: DataBinding) => {
          const node = this.dataNodes.find(
            (n: NodeData) => n.uuid === b.dataUuid
          );
          result[b.dataUuid] = node?.formattedValue;
          return result;
        },
        {}
      );
    }

    // NOTE: Need to handle bindings for non-repeaters here...
  }

  get conditionGroupsStore() {
    return useConditionGroupsStore();
  }

  get activeConditionId() {
    return this.conditionGroupsStore.getActiveConditionId(
      this.textEditingWidget
    );
  }

  /**
   * When a user adds a token (to a child of a repeater) from the token menu, we need to update the editor content.
   */
  onTokenSelected(dataUuid: string) {
    let binding = this.appEditor.dataBindings.find(
      (db) => db.widgetId === this.widget.wid && db.dataUuid === dataUuid
    );

    const content = this.editor.schema.nodes[DATA_TOKEN_TYPE].create({
      uuid: `${dataUuid}`,
    });

    const jsonContent = JSON.parse(JSON.stringify(content)) as JSONContent;
    this.editor
      .chain()
      .focus()
      .insertContent(jsonContent)
      .insertContent(JSON.parse(JSON.stringify(this.editor.schema.text(" "))))
      .run();

    if (!binding && this.repeaterBinding) {
      const { parentId } = this.widget;

      /**
       * Account for case that text widget occurs within a Group which itself occurs within a Repeater.
       * In this case, parentWidgetId must be the Repeater's id, rather than the Group's,
       * in order to properly pull data into the text data token binding.
       */
      const parent = this.appEditor.widgetById(parentId) as
        | RepeaterOptions
        | GroupOptions;
      const grandparent = this.appEditor.widgetById(parent?.parentId);

      const parentIsEmbeddedGroup = ["Repeater"].includes(
        grandparent?.type || ""
      );

      const parentWidgetId = parentIsEmbeddedGroup
        ? parent?.parentId
        : parentId;

      this.appEditor.createDataToken({
        widgetId: this.widget.wid,
        conditionId: this.activeConditionId,
        isScalar: false,
        connectionId: this.repeaterBinding.dataConnectionUuid as string,
        dataParentUuid: this.repeaterBinding.dataUuid,
        parentWidgetId,
        dataUuid: dataUuid as string,
        content: this.editor.getJSON() as TextContent,
      });
    } else {
      this.appEditor.updateTextContent({
        wid: this.widget.wid,
        content: this.editor.getJSON() as TextContent,
      });
    }

    this.startingContent = this.editor.getJSON();
  }

  mounted() {
    this.$nextTick(() => {
      this.setEditorContent();
      this.positionDataTokenMenu();
    });

    this.startingContent = this.appEditor.widgetById(
      this.textEditingWidget
    )?.content;
  }

  setEditorContent() {
    const content = bindDynamicTextContent(
      this.widget as TextOptions,
      this.appEditor.dataBindings,
      this.dataNodes
    );

    this.editor.commands.focus();
    this.editor.commands.setContent(content);
  }

  get transformStyles(): { [key: string]: string | number } {
    let x = this.widget.x;
    let y = this.widget.y;

    // Account for possibility that widget has a parent
    const parentId = this.widget.parentId;

    if (parentId !== BASE_PARENT_ID) {
      const parent = this.appEditor.widgetById(parentId) as any;

      // Calculate actual y value here (for vertically dynamic groups), as rendered, and set y to that:
      const child = this.appEditor.verticallyDynamicChild({
        parentWid: parentId,
        childWid: this.widget.wid,
      });
      if (child) {
        y = child.y;
      }

      // Account for cell location in repeaters (includes all gap calculations):
      x += this.offsetX;
      y += this.offsetY;

      const parentDims = getApparentDims(parent);

      x += parentDims.x;
      y += parentDims.y;

      /**
       * Account for case that text widget occurs within a Group which itself occurs within a Repeater.
       * Since widgetById returns {} if no matching widget is found, check for existence of "wid" property.
       */

      const grandparent = this.appEditor.widgetById(parent?.parentId);
      if (grandparent !== undefined && "wid" in grandparent) {
        const grandparentDims = getApparentDims(grandparent);
        x += grandparentDims.x;
        y += grandparentDims.y;
      }
    }

    const { element } = styler({
      x,
      y,
      scaleX: this.widget.scaleX,
      scaleY: this.widget.scaleY,
      width: this.widget.w || 0,
      height: this.widget.h || 0,
      angle: this.widget.angle,
      disableScale: true,
    });

    return {
      ...element,
      width: `${element.width}px`,
      height: `${element.height}px`,
      zIndex: this.widget.z,
    };
  }

  // ------------------------------------------------------------
  // Data Token Menu Position Logic
  // ------------------------------------------------------------
  dataTokenMenuPosition: Point = { x: 0, y: 0 };

  // TODO: Do not hard code...must correspond to actual width of element
  dataTokenMenuWidth = 150;
  dataTokenMenuScale = 0.8;

  get offsetX() {
    return this.editingContext.offsetX || 0;
  }

  get offsetY() {
    return this.editingContext.offsetY || 0;
  }

  positionDataTokenMenu() {
    const artboard = scaledCoords(
      this.artboardPosition,
      this.canvasBox,
      this.scale
    );

    const dims = getApparentDims(this.widget);

    let parentX = 0;
    let parentY = 0;
    if (this.widget.parentId) {
      const parent = this.appEditor.widgetById(this.widget.parentId) as any;
      const parentDims = getApparentDims(parent);
      parentX = parentDims.x;
      parentY = parentDims.y;
    }

    const point = {
      x: dims.x + parentX + dims.w + this.offsetX,
      y: dims.y + parentY + this.offsetY,
    };

    const offset = {
      x: this.canvasBox.x + artboard.x + this.scale * point.x,
      y: this.canvasBox.y + artboard.y + this.scale * point.y,
    };

    // Ensure data token dropdown does not sit atop the righthand editor panel:
    const rightHandPanelWidth = (
      document.querySelector(".RightEditorPanel") as HTMLElement
    ).getBoundingClientRect().width;
    const maxXPosition = window.innerWidth - rightHandPanelWidth;

    const menuWidth = this.dataTokenMenuWidth * this.dataTokenMenuScale;

    let xPosition = offset.x - menuWidth;

    if (xPosition + menuWidth > maxXPosition)
      xPosition -= xPosition + menuWidth - maxXPosition;

    xPosition -= 30; // Just remove a bit extra

    this.dataTokenMenuPosition.x = xPosition; // huh...why not work....Oh of course the scale haha
    this.dataTokenMenuPosition.y = offset.y;
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  mousemove(event: MouseEvent) {
    // console.log("mousemove", event);

    if (!this.dragDropStore.isDraggingNode) return;

    if (!this.draggingMouseCoordinates) {
      this.draggingMouseCoordinates = { x: 0, y: 0 };
    }
    this.draggingMouseCoordinates.x = event.clientX;
    this.draggingMouseCoordinates.y = event.clientY;
  }

  mouseleave() {
    if (!this.dragDropStore.isDraggingNode) return;
    if (this.dragDropStore.isHandlingDrop) return;

    this.dragDropStore.hoverTarget = null;
    this.appEditor.leaveTextEditMode();
    this.appEditor.replaceSelections([]);
  }

  mouseup() {
    if (!this.dragDropStore.isDraggingNode) return;

    this.draggingMouseCoordinates = null;
  }
}
</script>

<style lang="postcss">
.area {
  overflow-wrap: normal;
  word-wrap: normal;
  overflow: visible;
  resize: none;
}

.ProseMirror {
  cursor: text;
  /* background: green; */
  caret-color: #000;
  /* This seems OK...maybe best solution */
  word-wrap: normal !important;
  /* Same idea, ensure consistency bw tiptap and text component rendering: */
  white-space: pre-wrap !important;
}

/* Yes, do this, but add outline to component itself: */
.ProseMirror:focus {
  outline: none;
}

.ProseMirror .node-in-selection {
  /* background: #99def7; */

  border: 2px solid #99def7;
  border-radius: 5%;
}

.ProseMirror-selectednode {
  /* background: rgba(255, 215, 0, 0.8); */

  background: rgba(60, 130, 246, 0.3);
  /* outline: 2px solid rgb(59, 130, 246); */
}

/* Enables us to visually select multiple tokens: */

.ProseMirror span {
  user-select: auto !important;
}
.ProseMirror span:empty:before {
  content: "\00a0 ";
}

.ProseMirror strong {
  font-weight: bold !important;
}
</style>
