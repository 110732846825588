<template>
  <div class="flex flex-grow h-full p-6 space-x-12" id="settings">
    <!-- Sidebar: -->
    <div class="flex flex-col space-y-2 w-36">
      <router-link
        v-for="tab in tabsRender"
        :key="tab.path"
        class="cursor-pointer hover:text-app-teal hover:bg-gray-800"
        :to="{ path: tab.path, query: $route.query }"
      >
        <div
          class="py-2 px-6 rounded border"
          :class="[
            isTabSelected(tab.path)
              ? 'text-app-teal border-gray-300'
              : 'border-gray-300/0',
          ]"
        >
          {{ tab.label }}
        </div>
      </router-link>
    </div>

    <!-- Main: -->
    <div class="relative flex-grow mt-1" id="settings-view">
      <div class="absolute inset-0 overflow-auto">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { Component, Vue } from "vue-property-decorator";

/**
 * Always show Name; Sync and Disconnect only if non-Editable; Moderation and Schema come from DataSourceType.
 *
 * Only used when managing an existing connection.
 * Can be accessed either from Dashboard, OR from app editor.
 * In app editor, can be either Document or Collection connection.
 *
 * Why is Gooogle Sheet schema editable? Only Calendar is not...
 */

@Component({
  components: {},
})
export default class Settings extends Vue {
  get connection() {
    const store = useConnectionEditorStore();
    return store.connection;
  }
  get showSharing() {
    return true;
  }

  get showSync() {
    if (!this.isCollection) return true;
    return this.canSync;
  }

  get showSchema() {
    if (!this.isCollection) return false;
    return this.canEditSchema;
  }

  get showModeration() {
    if (!this.isCollection) return false;
    return this.canModerate;
  }

  get showDisconnect() {
    if (!this.isCollection) return false;
    return !this.connection?.canEditData;
  }

  get showConfigure() {
    return this.canSync && (this.connection?.canConfigureRequest ?? false);
  }

  get tabs() {
    return [
      {
        label: this.$t("Settings.name").toString(),
        path: "name",
        show: this.showSharing,
      },
      {
        label: this.$t("Settings.sync").toString(),
        path: "sync",
        show: this.showSync,
      },
      {
        label: this.$t("Settings.schema").toString(),
        path: "schema",
        show: this.showSchema,
      },
      {
        label: this.$t("Settings.moderation").toString(),
        path: "moderation",
        show: this.showModeration,
      },
      {
        label: this.$t("Settings.disconnect").toString(),
        path: "disconnect",
        show: this.showDisconnect,
      },
      {
        label: this.$t("Settings.configure").toString(),
        path: "Configure",
        show: this.showConfigure,
      },
    ];
  }

  get tabsRender() {
    return this.tabs.filter((t) => t.show);
  }

  /**
   * This caused backwards-compatibility issues
   * If connection was created before we added this flag, this would break.
   * So we return true if the connection has non-null moderationMode
   */
  get isCollection() {
    if (this.connection?.moderationMode !== null) return true;
    return this.connection?.isCollection;
  }

  get canModerate() {
    return this.connection?.canModerate ?? false;
  }

  get canEditSchema() {
    return this.connection?.canEditSchema ?? false;
  }

  get canSync() {
    return this.connection?.isSyncable ?? false;
  }

  isTabSelected(path: string) {
    return this.$route.path.includes(`/${path}`);
  }
}
</script>

<style lang="postcss" scoped>
#settings-view::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  background: rgb(30, 30, 30);
}

#settings-view::-webkit-scrollbar-thumb {
  background: rgba(117, 117, 117, 0.7);
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

:deep(.box) {
  @apply bg-app-dark5 border border-gray-500 rounded-lg self-start;
}

:deep(h3) {
  @apply font-bold text-lg;
}

:deep(ul) {
  list-style: inside;
  @apply mt-2;
}

:deep(ul li) {
  @apply mt-1;
}

/* "Undo" the above changes for SelectMenus: */
:deep(.ignore-list ul) {
  list-style: none;
  @apply m-0;
}

:deep(.ignore-list ul li) {
  @apply m-0;
}
</style>
