<template>
  <div id="main" class="relative w-full flex h-full">
    <div ref="canvas" :id="canvasId" class="relative bg-gray-200 flex-grow">
      <CanvasRender class="z-0" />
      <CanvasTextEditor v-if="isTextEditorActive" class="z-10" />
      <CanvasEditor class="z-20" :style="pointerStyle" />
      <CanvasRulers class="z-30" />
      <div
        v-if="!isBaseEditingContext && !dragDropStore.isDraggingItem"
        class="absolute bottom-6 z-40 text-center center-canvas-area"
      >
        <button
          type="button"
          class="bg-app-purple rounded text-white font-semibold px-3 py-2"
          @click="resetEditingContext"
        >
          {{ $t("canvas.exitContextButton") }}
        </button>
        <div
          class="absolute left-0 right-0 top-full text-center text-xs text-gray-500"
          v-t="'canvas.exitContextHotKey'"
        ></div>
      </div>
    </div>

    <div class="RightEditorPanel" :class="panelClasses">
      <ComponentEditor />
    </div>

    <portal-target
      id="colorPicker"
      name="colorPicker"
      class="RightEditorPanel"
      :class="panelClasses"
      @change="onColorPickerChange"
      slim
    >
    </portal-target>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CanvasRender from "./CanvasRender.vue";
import CanvasEditor from "./CanvasEditor.vue";
import CanvasTextEditor from "./CanvasTextEditor.vue";
import CanvasRulers from "./CanvasRulers.vue";
import ComponentEditor from "@/components/ComponentEditor.vue";
import { EventBus } from "@/eventbus";
import { CANVAS_ID } from "@/constants";
import { useDragDropStore } from "@/stores/dragDrop";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CanvasEditor,
    CanvasTextEditor,
    CanvasRender,
    CanvasRulers,
    ComponentEditor,
  },
})
export default class Canvas extends Vue {
  canvasId = CANVAS_ID;

  get appEditor() {
    return useAppEditorStore();
  }

  get selections() {
    return this.appEditor.selections;
  }

  get editingContext() {
    return this.appEditor.editingContext;
  }

  get isBaseEditingContext() {
    return this.appEditor.isBaseEditingContext;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get textEditingWidget() {
    return this.appEditor.textEditingWidget;
  }

  get hasSelection() {
    return this.selections.length > 0;
  }

  get dragDropStore() {
    return useDragDropStore();
  }

  get pointerStyle() {
    const pointerStyle =
      this.dragDropStore.isDraggingItem || this.textEditingWidget
        ? { pointerEvents: "none" }
        : {};
    return pointerStyle;
  }

  get draggingPhotoSrc() {
    return this.dragDropStore.draggingInfo?.url;
  }

  get showComponentEditor() {
    if (this.selectedWidget?.type === "Repeater" && !this.isBaseEditingContext)
      return false;
    return this.hasSelection;
  }

  get panelClasses() {
    const classes = [
      "absolute",
      "top-0",
      "right-0",
      "bottom-0",
      "z-50",
      "border-l",
      "bg-app-panel",
      "border-gray-300",
      "overflow-y-auto",
      "overflow-x-hidden",
      "flex",
      "flex-col",
      "justify-start",
    ];

    if (this.showComponentEditor) {
      classes.push("w-72");
      classes.push("shadow-lg");
    } else {
      classes.push("w-0");
    }
    return classes;
  }

  get isTextEditorActive() {
    return !this.draggingPhotoSrc && !!this.textEditingWidget;
  }

  created() {
    EventBus.on("CANVAS_BOX_UPDATE", () => {
      this.$nextTick(this.updateCanvasBox);
    });
  }

  updateCanvasBox() {
    const el = this.$refs.canvas as HTMLElement;
    if (el) {
      const bbox = el.getBoundingClientRect();
      const canvasBox = {
        x: bbox.left,
        y: bbox.top,
        w: bbox.width,
        h: bbox.height,
      };

      this.appEditor.setCanvasBox(canvasBox);
    }
  }

  onColorPickerChange(hasContent: boolean) {
    if (hasContent) {
      this.$nextTick(() => {
        EventBus.emit("COLOR_PICKER_MOUNTED");
      });
    }
  }

  resetEditingContext() {
    this.appEditor.resetEditingContext();
  }
}
</script>

<style lang="postcss">
.center-canvas-area {
  /* prettier-ignore */
  left: calc(50% - theme("spacing.72"));
  /* prettier-ignore */
  transform: translateX(calc(-50% + theme("spacing.72")));
}
</style>
