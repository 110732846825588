import { DateTime } from "luxon";
import { DatetimeOption } from "@/types/DateTimeOption";

// thanks SO
const getOrdinalNum = (n: number): string => {
  return (
    n +
    (n > 0
      ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : "")
  );
};

const getMeridian = (date: Date, type?: string): string => {
  const dtf = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    hour12: true,
  });
  let res: any = dtf.format(date);
  const meridian = res.split(" ")[1];
  switch (type) {
    case "pm":
      return meridian.toLowerCase();
    case "P":
      return meridian[0];
    case "p":
      return meridian[0].toLowerCase();
    default:
      return meridian;
  }
};

export const relativeTimeFormat = (date: Date, units: any) => {
  const rel = DateTime.fromJSDate(date);
  if (units !== "default") return rel.toRelative({ unit: units });
  return rel.toRelative();
};

export const dtOptionsToString = (
  options: DatetimeOption[],
  date: Date
): string => {
  return options.reduce((acc: any, option: any) => {
    if (option.type === "literal") return acc + option.value;

    // I wonder why they don't give dayPeriod a value..
    if (option.type === "dayPeriod") {
      return acc + getMeridian(date, option.value);
    }
    // Handle ordinal type:
    if (option.type === "weekday" && option.value === "ordinal") {
      const dtf = new Intl.DateTimeFormat("en-US", { day: "numeric" });
      let res: any = dtf.format(date);
      return acc + getOrdinalNum(parseInt(res));
    }

    // Handle 24-hour option:
    if (option.value === "numeric-24") {
      option.value = "numeric";
      option.hour12 = false;
    }
    if (option.value === "2-digit-24") {
      option.value = "2-digit";
      option.hour12 = false;
    }
    const dtf = new Intl.DateTimeFormat("en-US", {
      [option.type]: option.value,
      hour12: option.hour12,
    });
    let res: any = dtf.format(date);
    // Need to do this because {hour: "numeric"} returns "2 PM" instead of "2":
    if (option.type === "hour") res = parseInt(res).toString();
    // Need this because passing {[x]: "2-digit"} on its own does not actually add the leading 0:
    if (option.value === "2-digit" && res.length === 1) res = "0" + res;
    return acc + res;
  }, "");
};

export function dtPresetToOptions(preset: any): DatetimeOption[] {
  const fmt = new Intl.DateTimeFormat("en-US", preset);
  const parts = fmt.formatToParts();

  return parts.map((part) => {
    const type = part.type;
    if (type === "literal") return part;

    let value = preset[type];

    // Need to flag this manually because formatToParts does not preserve this info:
    let hour12 = true;
    if (type === "hour" && preset.hasOwnProperty("hour12") && !preset.hour12) {
      hour12 = false;
    }

    return {
      type,
      value,
      hour12,
    };
  });
}

// console.log("result:", dtOptionsToString(dtPresetToOptions(samplePreset)));

// const dtf = new Intl.DateTimeFormat("en-US", { timeStyle: "medium" });
// const now = new Date();
// console.log(dtf.format(now), dtf.formatToParts(now));

// dtf.formatToParts(now):
// [ { type: 'month', value: '05' },
//   { type: 'literal', value: '/' },
//   { type: 'day', value: '07' } ]
