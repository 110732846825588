<template>
  <div class="px-3 py-2">
    <FormLabel v-t="label" />

    <SelectMenu
      v-model="dataUuid"
      :options="connectionColumns"
      class="flex-grow"
    />
  </div>
</template>

<script lang="ts">
import { DataBinding, DataBindingProperty } from "@/types/data";
import { Node } from "@/types/data";
import { Component, Prop, Vue } from "vue-property-decorator";
import SelectMenu from "@/components/SelectMenu.vue";
import FormLabel from "@/components/FormLabel.vue";

import { Widget } from "@/components/widgets/Widget";
import { removeReactivity } from "@/utils";
import { useAppEditorStore } from "@/stores/appEditor";
import { logger } from "@core/logger";
import { useConnectionsStore } from "@/stores/connections";

@Component({
  components: { SelectMenu, FormLabel },
})
export default class DataColumnChooser extends Vue {
  @Prop(String) label: string;
  @Prop(String) dataProperty: string;
  @Prop(String) dataParentUuid: string;

  get appEditor() {
    return useAppEditorStore();
  }

  get selectedWidget() {
    if (this.appEditor.selectedWidget === undefined) {
      logger.track("DataColumnChooser: selectedWidget is undefined");
    }
    return this.appEditor.selectedWidget as Widget;
  }

  get dataUuid() {
    return this.dataBinding?.dataUuid ?? "";
  }

  set dataUuid(uuid: string) {
    if (uuid === "") {
      this.appEditor.removeDataBinding({
        widgetId: this.selectedWidget.wid,
        property: this.dataProperty,
      });
      return;
    }

    if (this.dataBinding) {
      const binding = removeReactivity<DataBinding>(this.dataBinding);
      binding.dataUuid = uuid;
      this.appEditor.updateDataBinding(binding);
    } else {
      const newBinding: DataBinding = {
        widgetId: this.selectedWidget.wid,
        property: this.dataProperty as DataBindingProperty,
        dataUuid: uuid,
        bindingType: "DataSetNode",
        dataConnectionUuid: this.connection?.uuid,
        dataParentUuid: this.dataParentUuid,
        parentWidgetId: this.selectedWidget.parentId,
      };
      this.appEditor.addDataBinding(newBinding);
    }
  }

  get dataBinding() {
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.selectedWidget.wid,
      bindingType: "DataSetNode",
      dataParentUuid: this.dataParentUuid,
    });
    return bindings.length > 0 ? bindings[0] : undefined;
  }

  // assumes connection must be on parent
  get connection() {
    const parentBinding = this.appEditor.bindingsForComponent({
      widgetId: this.selectedWidget.parentId,
      // bindingType: "DataSet"
    });
    if (parentBinding.length === 0) {
      return undefined;
    }
    return useConnectionsStore().connections.find(
      (c) => c.uuid === parentBinding[0]?.dataConnectionUuid
    );
  }

  dataTypeFilter(node: Node) {
    switch (this.selectedWidget.type) {
      case "Image":
        return node.dataType === "ImageUrl" || node.dataType === "ImageUpload";
      case "Text":
        return true;
      default:
        return false;
    }
  }

  get connectionColumns() {
    let result: any[] = [{ label: "None", value: "" }];
    let columns = this.connection?.nodeSets[0]?.nodes
      ?.filter(this.dataTypeFilter)
      .map((n, i) => {
        return { label: n.name, value: n.uuid };
      });
    if (Array.isArray(columns) && columns.length > 0) {
      return result.concat(columns);
    }
    return result;
  }
}
</script>

<style scoped></style>
