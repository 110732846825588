<template>
  <div class="flex flex-col h-full">
    <div
      v-if="devBannerText"
      v-text="devBannerText"
      class="fixed text-white font-bold text-lg justify-center flex items-center z-40"
      :class="bannerClasses"
      style="pointer-events: none; text-shadow: 0 2px 0 black"
    ></div>
    <router-view></router-view>

    <div
      v-if="awaitingServer"
      class="inset-0 flex justify-center items-center fixed bg-gray-700 bg-opacity-70"
      style="z-index: 30000"
    >
      <LoadingAnimation />
    </div>
    <portal-target
      id="help-portal"
      class="fixed z-50"
      name="help"
    ></portal-target>
    <portal-target
      id="dashboard-portal"
      class="fixed z-50"
      name="dashboard"
    ></portal-target>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "./eventbus";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import { useAppEditorStore } from "./stores/appEditor";

@Component({
  components: { LoadingAnimation },
})
export default class App extends Vue {
  // showLoadingAnimation = false;
  devBannerText = "";

  get appEditor() {
    return useAppEditorStore();
  }

  get awaitingServer() {
    return this.appEditor.awaitingServer;
  }

  created() {
    if (window.location.host === "connect-qa.screenfeed.com") {
      this.devBannerText = "QA";
    }
    if (window.location.host.includes("localhost")) {
      this.devBannerText = "LOCAL";
    }
  }

  handleAwaitingServer(value: boolean | undefined) {
    this.appEditor.setAwaitingServer(!!value);
  }

  mounted() {
    EventBus.on("AWAITING_SERVER", this.handleAwaitingServer);
  }

  beforeDestroy() {
    EventBus.off("AWAITING_SERVER", this.handleAwaitingServer);
  }

  get bannerClasses() {
    const isEdit = this.$route.path.startsWith("/app/");
    return isEdit ? ["h-16", "w-20"] : ["top-16", "w-64"];
  }
}
</script>

<style lang="postcss">
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
}
</style>
