import { makeId } from "@/utils";

import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import {
  TransformOptions,
  DefaultTransformOptions,
} from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface CalendarAgendaOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  data?: any[];
  previewLiveData: boolean;

  dayOffset: number;
  dayFormat: string;
  startHour: number;
  endHour: number;
  use24Hour: boolean;
  useDefaultStartTime: boolean;
  syncFonts: boolean;
  syncTextColors: boolean;
  eventBackgroundColor: string;
  allDayEventBackgroundColor: string;
  // allDayEventTextColor: string;

  allDayEvent_fontFamily: string | null;
  allDayEvent_textColor: string | null;
  allDayEvent_fontSize: number;
  allDayEvent_fontWeight: number;
  allDayEvent_letterSpacing: number;
  allDayEvent_lineHeight: number;
  allDayEvent_textAlign: string;
  allDayEvent_fontStyle: string;
  allDayEvent_textTransform: string;
  allDayEvent_textDecoration: string;

  date_fontFamily: string | null;
  date_textColor: string | null;
  date_fontSize: number;
  date_fontWeight: number;
  date_letterSpacing: number;
  date_lineHeight: number;
  date_textAlign: string;
  date_fontStyle: string;
  date_textTransform: string;
  date_textDecoration: string;

  eventTitle_fontFamily: string | null;
  eventTitle_textColor: string | null;
  eventTitle_fontSize: number;
  eventTitle_fontWeight: number;
  eventTitle_letterSpacing: number;
  eventTitle_lineHeight: number;
  eventTitle_textAlign: string;
  eventTitle_fontStyle: string;
  eventTitle_textTransform: string;
  eventTitle_textDecoration: string;

  eventTime_fontFamily: string | null;
  eventTime_textColor: string | null;
  eventTime_fontSize: number;
  eventTime_fontWeight: number;
  eventTime_letterSpacing: number;
  eventTime_lineHeight: number;
  eventTime_textAlign: string;
  eventTime_fontStyle: string;
  eventTime_textTransform: string;
  eventTime_textDecoration: string;

  eventLocation_fontFamily: string | null;
  eventLocation_textColor: string | null;
  eventLocation_fontSize: number;
  eventLocation_fontWeight: number;
  eventLocation_letterSpacing: number;
  eventLocation_lineHeight: number;
  eventLocation_textAlign: string;
  eventLocation_fontStyle: string;
  eventLocation_textTransform: string;
  eventLocation_textDecoration: string;
}

const mainColor = "rgba(52,66,86,100)";
const accentColor = "rgba(244,62,92,100)";

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultTransformOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  borderRadius: 0,
  dayOffset: 0,
  dayFormat: "today",
  startHour: 7,
  endHour: 18,
  previewLiveData: false,
  // title: "Calendar Title",
  // showTitle: true,
  use24Hour: false,
  useDefaultStartTime: true,
  eventBackgroundColor: "rgba(0,0,0,0)",
  eventBulletColor: mainColor,
  allDayEventBackgroundColor: mainColor,
  // allDayEventTextColor: "white",
  syncFonts: false,
  syncTextColors: false,
};

// Ahhh, tricky -- how to allow manipulation of multiday....
// fake it for now with text-4xl?

export const defaultTextOptions: any = {
  date_fontSize: 96,
  date_fontWeight: 300,
  date_textColor: accentColor,
  date_lineHeight: 1.2,
  date_textTransform: "capitalize",
  eventTitle_fontSize: 60,
  eventTitle_fontWeight: 400,
  eventTitle_textColor: mainColor,
  eventTitle_lineHeight: 1.2,
  eventTitle_textTransform: "none",
  eventLocation_fontSize: 36,
  eventLocation_fontWeight: 400,
  eventLocation_textColor: accentColor,
  eventLocation_lineHeight: 1.2,
  eventLocation_textTransform: "none",
  eventTime_fontSize: 36,
  eventTime_fontWeight: 700,
  eventTime_textColor: mainColor,
  eventTime_lineHeight: 2.0,
  eventTime_textTransform: "none",
  allDayEvent_fontSize: 36,
  allDayEvent_fontWeight: 400,
  allDayEvent_textColor: "rgba(255,255,255,100)",
  allDayEvent_lineHeight: 1.2,
  allDayEvent_textTransform: "none",
};

["date", "eventTitle", "eventLocation", "eventTime", "allDayEvent"].forEach(
  (prop) => {
    defaultTextOptions[`${prop}_fontFamily`] = "Montserrat";
    defaultTextOptions[`${prop}_textDecoration`] = "none";
    defaultTextOptions[`${prop}_fontStyle`] = "normal";
    defaultTextOptions[`${prop}_letterSpacing`] = 0;
    defaultTextOptions[`${prop}_textAlign`] = "left";
  }
);

defaultTextOptions.allDayEvent_textColor = "white";

const CreateCalendarAgenda = (options: Partial<CalendarAgendaOptions>) => {
  return Object.assign({}, defaultOptions, defaultTextOptions, options, {
    wid: makeId(),
    type: "CalendarAgenda",
    name: DEFAULT_LAYER_NAMES.CalendarAgenda,
  });
};

export default CreateCalendarAgenda;
