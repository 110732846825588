import i18n from "@/i18n";
import { gsap } from "gsap";
import {
  AnimationOptions,
  AnimationPresetList,
  AnimationTween,
  Animations,
} from "@/types/animation";
import { getApparentDims } from "@/utils";
import { TransformOptions } from "./TransformOptions";
import { styler } from "@/lib/free-transform/lib";
import { renderState } from "@/rendererState";

export const Eases = [
  { label: "None", value: "none" },
  {
    label: `${i18n.tc("animation.easeNames.slow")}`,
    value: "power1.inOut",
  },
  {
    label: `${i18n.tc("animation.easeNames.slowAccelerate")}`,
    value: "power1.in",
  },
  {
    label: `${i18n.tc("animation.easeNames.slowDecelerate")}`,
    value: "power1.out",
  },
  {
    label: `${i18n.tc("animation.easeNames.natural")}`,
    value: "power3.inOut",
  },
  {
    label: `${i18n.tc("animation.easeNames.accelerate")}`,
    value: "power3.in",
  },
  {
    label: `${i18n.tc("animation.easeNames.decelerate")}`,
    value: "power3.out",
  },
  {
    label: `${i18n.tc("animation.easeNames.quick")}`,
    value: "expo.inOut",
  },
  {
    label: `${i18n.tc("animation.easeNames.quickAccelerate")}`,
    value: "expo.in",
  },
  {
    label: `${i18n.tc("animation.easeNames.quickDecelerate")}`,
    value: "expo.out",
  },
];

export const AnimatableWidgetTypes = [
  "Image",
  "Text",
  "Group",
  "Rectangle",
  "Ellipse",
  "Svg",
  "Triangle",
  "Line",
];

export const AppTimeline = gsap.timeline();

export function getAnimationId(widgetId: string, cellIndex?: number) {
  const suffix = typeof cellIndex === "undefined" ? "" : `-${cellIndex}`;
  return `data-anim-${widgetId}${suffix}`;
}

export function resetAnimation(timeline: gsap.core.Timeline) {
  timeline.revert();
  timeline.clear();
  timeline.pause();
}

function convertTweenValue(
  x: number,
  y: number,
  attribute: string,
  value: number
) {
  if (attribute === "opacity" || attribute === "scale") {
    return value / 100;
  } else if (attribute === "y") {
    return y + value;
  } else if (attribute === "x") {
    return x + value;
  } else {
    return value;
  }
}

function pauseTimeline(timeline: gsap.core.Timeline) {
  console.log("pausing timeline");
  timeline.pause();
}

function outroEnd(
  end: number | undefined,
  duration: number,
  cycleDuration: number | undefined
) {
  if (cycleDuration != null) {
    return cycleDuration - duration;
  } else {
    return end ? end - duration : duration;
  }
}

export type AnimationInstructions = {
  selector: string;
  animations: Animations;
  cycleDuration: number | undefined;
  x: number;
  y: number;
  opacity: number;
  transform: string;
};

function gatherAnimationInstructions(vnode: Vue) {
  const result: AnimationInstructions[] = [];

  function traverse(vnode: Vue) {
    if (vnode.$props && vnode.$props["animations"]) {
      const animations = vnode.$props["animations"] as Animations;
      const hasAnimations =
        animations.loop.presetId !== "none" ||
        animations.transition.presetId !== "none";

      if (hasAnimations) {
        const apparentDims = getApparentDims(vnode.$props as TransformOptions);
        const { element } = styler({
          x: vnode.$props.x,
          y: vnode.$props.y,
          scaleX: vnode.$props.scaleX,
          scaleY: vnode.$props.scaleY,
          width: vnode.$props.w || 0,
          height: vnode.$props.h || 0,
          angle: vnode.$props.angle,
          disableScale: true,
        });

        result.push({
          selector: getAnimationId(
            vnode.$props["wid"],
            vnode.$props["cellIndex"]
          ),
          animations: vnode.$props["animations"],
          cycleDuration: vnode.$props["cycleDuration"],
          x: apparentDims.x,
          y: apparentDims.y,
          opacity: vnode.$props["opacity"] / 100,
          transform: element.transform,
        });
      }
    }
    const children = Array.from(vnode.$children);
    for (const child of children) {
      traverse(child);
    }
  }

  traverse(vnode);
  return result;
}

export function animate(root: Vue) {
  // Do not animate during screenshot service's process:
  if (renderState.isSnapshot === true) return;
  gsap.config({
    nullTargetWarn: false,
  });
  const instructions = gatherAnimationInstructions(root);
  instructions.forEach((i) => {
    const widgetTimeline = gsap.timeline();
    const loopTimeline = gsap.timeline();
    const inOutTimeline = gsap.timeline();
    const element = document.getElementById(i.selector);
    if (element == null) {
      console.warn(`Could not find element with selector ${element}`);
      return;
    }
    const animations = i.animations;
    const cycleDuration = i.cycleDuration;

    gsap.set(element, {
      x: i.x,
      y: i.y,
      opacity: i.opacity,
      transform: i.transform,
    });

    for (const key in animations) {
      const animation = animations[key as keyof typeof animations];
      if (animation.tweens && animation.tweens.length > 0) {
        animation.tweens?.forEach((tween: AnimationTween) => {
          if (element && animation.presetId != "none") {
            element.style.willChange = "transform, scale, opacity";
            if (key === "transition") {
              inOutTimeline.from(
                element,
                {
                  [`${tween.attribute}`]: convertTweenValue(
                    i.x,
                    i.y,
                    tween.attribute,
                    tween.value
                  ),
                  force3D: false,
                  duration: animation.duration,
                  ease: animation.ease,
                },
                animation.delay
              );
              if (animation.outro) {
                inOutTimeline.to(
                  element,
                  {
                    [`${tween.attribute}`]: convertTweenValue(
                      i.x,
                      i.y,
                      tween.attribute,
                      animation.reverse ? -tween.value : tween.value
                    ),
                    duration: animation.duration,
                    ease: animation.ease,
                    force3D: false,
                    onStart: pauseTimeline,
                    onStartParams: [loopTimeline],
                  },
                  outroEnd(animation.end, animation.duration, cycleDuration)
                );
              }
            } else if (key === "loop") {
              loopTimeline.to(
                element,
                {
                  [`${tween.attribute}`]: convertTweenValue(
                    i.x,
                    i.y,
                    tween.attribute,
                    tween.value
                  ),
                  duration: animation.duration,
                  ease: animation.ease,
                  force3D: false,
                  repeat: tween.repeat,
                  repeatDelay: tween.repeatDelay,
                  yoyo: true,
                },
                animation.delay +
                  animations.transition.delay +
                  animations.transition.duration
              );
            }
          }
        });
      }
    }
    widgetTimeline.add(inOutTimeline, 0);
    widgetTimeline.add(loopTimeline, 0);
    AppTimeline.add(widgetTimeline, 0);
  });

  AppTimeline.eventCallback("onComplete", () => {
    instructions.forEach((i) => {
      const element = document.getElementById(i.selector) as HTMLElement;
      if (!element) return;
      element.style.willChange = "auto";
    });
  });
  AppTimeline.play(0);
}

export function getDefaultAnimationOptions(): AnimationOptions {
  return {
    animations: {
      transition: {
        presetId: "none",
        label: i18n.tc("animation.presetNames.none"),
        duration: 0,
        delay: 0,
        ease: "none",
        tweens: [
          {
            attribute: "opacity",
            value: 100,
          },
        ],
      },
      loop: {
        presetId: "none",
        label: i18n.tc("animation.presetNames.none"),
        duration: 0,
        delay: 0,
        ease: "none",
        tweens: [
          {
            attribute: "opacity",
            value: 100,
          },
        ],
      },
    },
  };
}

export const AnimationPresets: AnimationPresetList = {
  transition: [
    {
      presetId: "none",
      label: i18n.tc("animation.presetNames.none"),
      duration: 0,
      delay: 0,
      ease: "none",
      tweens: [
        {
          attribute: "opacity",
          value: 100,
        },
      ],
    },
    {
      presetId: "fade",
      label: i18n.tc("animation.presetNames.fade"),
      duration: 2.0,
      delay: 0,
      ease: "power3.out",
      outro: false,
      end: 10,
      tweens: [
        {
          attribute: "opacity",
          value: 0,
        },
      ],
    },
    {
      presetId: "grow",
      label: i18n.tc("animation.presetNames.grow"),
      duration: 2.0,
      delay: 0,
      ease: "power3.out",
      outro: false,
      end: 10,
      tweens: [
        {
          attribute: "scale",
          value: 0,
        },
        {
          attribute: "opacity",
          value: 0,
        },
      ],
    },
    {
      presetId: "shrink",
      label: i18n.tc("animation.presetNames.shrink"),
      duration: 2.0,
      delay: 0,
      ease: "power3.out",
      outro: false,
      end: 10,
      tweens: [
        {
          attribute: "scale",
          value: 200,
        },
        {
          attribute: "opacity",
          value: 0,
        },
      ],
    },
    {
      presetId: "slideUp",
      label: i18n.tc("animation.presetNames.slideUp"),
      duration: 2.0,
      delay: 0,
      outro: false,
      end: 10,
      canReverse: true,
      reverse: false,
      ease: "power3.out",
      tweens: [
        {
          attribute: "y",
          value: 500,
        },
        {
          attribute: "opacity",
          value: 0,
        },
      ],
    },
    {
      presetId: "slideDown",
      label: i18n.tc("animation.presetNames.slideDown"),
      duration: 2.0,
      delay: 0,
      ease: "power3.out",
      outro: false,
      end: 10,
      canReverse: true,
      reverse: false,
      tweens: [
        {
          attribute: "y",
          value: -500,
        },
        {
          attribute: "opacity",
          value: 0,
        },
      ],
    },
    {
      presetId: "slideLeft",
      label: i18n.tc("animation.presetNames.slideLeft"),
      duration: 2.0,
      delay: 0,
      ease: "power3.out",
      outro: false,
      end: 10,
      canReverse: true,
      reverse: false,
      tweens: [
        {
          attribute: "x",
          value: 500,
        },
        {
          attribute: "opacity",
          value: 0,
        },
      ],
    },
    {
      presetId: "slideRight",
      label: i18n.tc("animation.presetNames.slideRight"),
      duration: 2.0,
      delay: 0,
      ease: "power3.out",
      outro: false,
      end: 10,
      canReverse: true,
      reverse: false,
      tweens: [
        {
          attribute: "x",
          value: -500,
        },
        {
          attribute: "opacity",
          value: 0,
        },
      ],
    },
  ],
  loop: [
    {
      presetId: "none",
      label: i18n.tc("animation.presetNames.none"),
      duration: 0,
      delay: 0,
      ease: "none",
      tweens: [
        {
          attribute: "opacity",
          value: 100,
        },
      ],
    },
    {
      presetId: "blink",
      label: i18n.tc("animation.presetNames.blink"),
      duration: 1.0,
      delay: 0,
      ease: "power3.inOut",
      tweens: [
        {
          attribute: "opacity",
          repeat: -1,
          repeatDelay: 1,
          stagger: 0,
          value: 0,
          yoyo: true,
        },
      ],
    },
    {
      presetId: "pulse",
      label: i18n.tc("animation.presetNames.pulse"),
      duration: 2.0,
      delay: 0,
      ease: "expo.inOut",
      tweens: [
        {
          attribute: "scale",
          repeat: -1,
          repeatDelay: 0,
          stagger: 0,
          value: 120,
          yoyo: true,
        },
      ],
    },
  ],
};
