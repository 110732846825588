<template>
  <div
    class="absolute top-0 bg-app-dark2 text-white rounded-md p-6 shadow-xl"
    :class="bubbleClasses"
  >
    <slot />

    <button
      v-if="closable"
      type="button"
      class="block absolute top-1 w-8 h-8 transform -translate-x-1/2 -translate-y-1/2 rounded-full z-10 text-gray-500 hover:text-gray-400"
      :class="{ 'left-1': position === 'l', 'right-1': position !== 'l' }"
      @click="$emit('close')"
    >
      <svg
        class="w-full h-full"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <rect
            x="4"
            y="1"
            width="36"
            height="36"
            rx="18"
            fill="currentColor"
            shape-rendering="crispEdges"
          />
          <path
            d="M28 13L16 25M28 25L16 13"
            stroke="#171717"
            stroke-width="3"
            stroke-linecap="round"
          />
        </g>
      </svg>
    </button>

    <div
      ref="arrow"
      class="absolute border-app-dark2"
      :class="arrowClasses"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HelpBubble extends Vue {
  @Prop(String) position!: "t" | "r" | "b" | "l";
  @Prop({ type: Boolean, required: false }) closable: boolean;

  get arrowClasses() {
    return `popover-arrow-${this.position}`;
  }

  get bubbleClasses() {
    return `popover-offset-${this.position}`;
  }
}
</script>

<style lang="postcss" scoped>
.popover-arrow-t {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.3");
  border-bottom-width: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.popover-arrow-r {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.3");
  border-left-width: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.popover-arrow-b {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.3");
  border-top-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.popover-arrow-l {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: theme("spacing.3");
  border-right-width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.popover-offset-t {
  bottom: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.popover-offset-r {
  left: calc(100% + theme("spacing.2"));
  @apply -translate-y-1/2;
}
.popover-offset-b {
  top: calc(100% + theme("spacing.2"));
  left: 50%;
  transform: translateX(-50%);
}
.popover-offset-l {
  right: calc(100% + theme("spacing.5"));
  @apply -translate-y-1/2;
}
</style>
