<template>
  <div v-if="input">
    <label :id="input.callbackModelKey">{{ input.displayName }}</label>
    <input
      ref="inputRef"
      v-model.trim="input.value"
      :aria-labelledby="input.callbackModelKey"
      :placeholder="placeholder"
      type="text"
      class="block w-full mt-2 px-2 py-1 flex-grow rounded-sm border text-gray-900 dark-form-focus"
      :required="input.isRequired"
    />
    <div v-if="!isValid" class="text-red-500 text-sm mt-1">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { ValidationInput } from "@/types/ConnectionSources";
import { useTextValidation } from "./validation/useTextValidation";

const props = defineProps<{
  input: ValidationInput;
}>();

const inputRef = ref<HTMLInputElement | null>(null);
const placeholder = ref("");

const { isValid, errorMessage } = useTextValidation(inputRef, {
  isRequired: props.input.isRequired,
});

onMounted(() => {
  placeholder.value = props.input.properties?.placeholder ?? "";
});

watch(
  () => isValid.value,
  (isValid) => {
    props.input.isValid = isValid;
  }
);
</script>
