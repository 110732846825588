<template>
  <div class="w-full h-full">
    <VideoPlaceholder
      class="bg-gray-300 text-lg w-full h-full"
      :url="url"
      :width="w"
      :height="h"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { makeId } from "@/utils";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";
import VideoPlaceholder from "@/components/VideoPlaceholder.vue";

@Component({
  components: { VideoPlaceholder },
})
export default class VideoWrapper extends AnimatableWidget {
  @Prop(String) readonly url: string;

  /**
   * It is important that each instance of this component has its own unique id,
   * for the case of children of repeaters (instances of which share their wid across cells).
   */
  @Prop({ default: makeId }) componentId: string;
}
</script>
