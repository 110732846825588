<template>
  <div class="space-y-2">
    <div class="flex space-x-3" v-if="stackUpEnabled || stackDownEnabled">
      <ButtonIcon
        icon="MoveToFront"
        tooltip="moveToFront"
        @click="stackToTop"
        :disabled="!stackUpEnabled"
      />
      <ButtonIcon
        icon="MoveForward"
        tooltip="moveForward"
        @click="stackUp"
        :disabled="!stackUpEnabled"
      />
      <ButtonIcon
        icon="MoveBackward"
        tooltip="moveBackward"
        @click="stackDown"
        :disabled="!stackDownEnabled"
      />
      <ButtonIcon
        icon="MoveToBack"
        tooltip="moveToBack"
        @click="stackToBottom"
        :disabled="!stackDownEnabled"
      />
    </div>
    <div class="flex space-x-3" v-if="!distributeDisabled">
      <ButtonIcon
        icon="DistributeHorizontally"
        tooltip="distributeHorizontally"
        :disabled="distributeDisabled"
        tooltipPosition="r"
        @click="update('hor')"
      />
      <ButtonIcon
        icon="DistributeVertically"
        tooltip="distributeVertically"
        :disabled="distributeDisabled"
        tooltipPosition="r"
        @click="update('vert')"
      />
    </div>
    <div class="flex flex-nowrap w-full space-x-8">
      <div class="flex space-x-3" v-if="!alignDisabled">
        <ButtonIcon
          icon="AlignTop"
          tooltip="align.top"
          :disabled="alignDisabled"
          @click="update('top')"
        />
        <ButtonIcon
          icon="AlignMiddle"
          tooltip="align.middle"
          :disabled="alignDisabled"
          @click="update('ctr-row')"
        />
        <ButtonIcon
          icon="AlignBottom"
          tooltip="align.bottom"
          :disabled="alignDisabled"
          @click="update('bottom')"
        />
      </div>
      <div class="flex space-x-3" v-if="!alignDisabled">
        <ButtonIcon
          icon="AlignLeft"
          tooltip="align.left"
          :disabled="alignDisabled"
          @click="update('left')"
        />
        <ButtonIcon
          icon="AlignCenter"
          tooltip="align.center"
          :disabled="alignDisabled"
          @click="update('ctr-col')"
        />
        <ButtonIcon
          icon="AlignRight"
          tooltip="align.right"
          :disabled="alignDisabled"
          @click="update('right')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ButtonIcon from "@/components/ButtonIcon.vue";
import { BASE_PARENT_ID } from "@/constants";
import { useAppEditorStore } from "@/stores/appEditor";

// TODO: alignment editor should show up for Groups

@Component({
  components: { ButtonIcon },
})
export default class AlignmentEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get editableWidgets() {
    return this.appEditor.editableWidgets;
  }

  get selections() {
    return this.appEditor.selections;
  }

  update(direction: string) {
    if (direction === "vert" || direction === "hor") {
      this.appEditor.distribute({ direction, selections: this.selections });
    } else {
      this.appEditor.align({ direction, selections: this.selections });
    }
  }

  get distributeDisabled() {
    return this.selections.length < 3;
  }

  get alignDisabled() {
    return this.selections.length < 2;
  }

  get moveDisabled() {
    return this.selections.length < 1;
  }

  get showEditor() {
    return this.selections.length >= 1;
  }

  get zList() {
    let wgs = this.editableWidgets;
    const values = wgs.map((wg) => wg.z);
    values.sort((a, b) => a - b);
    return values;
  }

  get maxZ() {
    return this.zList[this.zList.length - 1];
  }

  get minZ() {
    return this.zList[0];
  }

  get stackUpEnabled() {
    if (this.selections.length === 1) {
      const wg = this.appEditor.widgetById(this.selections[0]);
      return wg && wg.z < this.maxZ;
    }
    return false;
  }

  get stackDownEnabled() {
    if (this.selections.length === 1) {
      const wg = this.appEditor.widgetById(this.selections[0]);
      return wg && wg.z > this.minZ;
    }
    return false;
  }

  alterStack(widgetId: string, dir = "up") {
    const widget = this.appEditor.widgetById(widgetId);
    if (!widget) {
      return;
    }

    const widgets =
      widget.parentId === BASE_PARENT_ID
        ? this.editableWidgets
        : this.appEditor.getChildren(widget.parentId);
    const widgetIds = widgets
      .slice(0)
      .sort((a, b) => a.z - b.z)
      .map((wg) => wg.wid);

    const idx = widgetIds.indexOf(widgetId);

    // Swap widgets
    if (["up", "down"].includes(dir)) {
      const nextIdx = dir === "up" ? idx + 1 : idx - 1;
      const nextWid = widgetIds[nextIdx];
      widgetIds[idx] = nextWid;
      widgetIds[nextIdx] = widgetId;
    } else {
      // Dir is "top" or "bottom"
      widgetIds.splice(idx, 1);

      if (dir === "top") {
        widgetIds.push(widgetId);
      } else {
        widgetIds.unshift(widgetId);
      }
    }

    this.appEditor.setWidgetStackOrder(widgetIds);
  }

  // NOTE: Don't think we need check for selections length
  stackUp() {
    if (this.selections.length === 1) {
      this.alterStack(this.selections[0], "up");
    }
  }

  stackToTop() {
    this.alterStack(this.selections[0], "top");
  }

  stackDown() {
    if (this.selections.length === 1) {
      this.alterStack(this.selections[0], "down");
    }
  }

  stackToBottom() {
    this.alterStack(this.selections[0], "bottom");
  }
}
</script>
