"use strict";

const SNAPPING_RANGE = 20;

// - [ ] NOTE: Do we just need to do "touches" stuff in here to fix Stylus bugs?
// Or are they Windows related somehow
// Handle snapping in here?
// With the "real" dims idea, to capture where it is even if appears "glued" in place?
// and the we use THOSE dims to look for new snap pairs, rather than actual x,y

// - [x] don't forget to test with diff canvas scales.

const isSameAxis = (first, second) => {
  const isX = first.name.includes("x");
  const tgtX = second.name.includes("x");
  const isY = first.name.includes("y");
  const tgtY = second.name.includes("y");
  return (isX && tgtX) || (isY && tgtY);
};

// Oh jeez, we're going to have to map to getApparentDims before passing to this, eh?
const getAxes = (dims) => {
  const { x, y, w, h } = dims;
  return {
    x: x,
    cx: x + w / 2,
    rx: x + w,
    y: y,
    cy: y + h / 2,
    by: y + h,
  };
};

// NOTE that "artboard"  can now also refer to repeater editing cell
// Filter wgs by wid before calling this:
const getSnapTargets = (widgets, artboard) => {
  const axes = widgets
    .map((wg) => {
      const apparent = getApparentDims(wg);
      const axes = getAxes(apparent);

      return Object.keys(axes).map((key) => {
        return {
          name: key,
          value: axes[key],
          // wid: wg.wid
        };
      });
    })
    .flat();

  const artboardAxes = [
    { name: "x", value: 0 },
    { name: "cx", value: artboard.w / 2 },
    { name: "rx", value: artboard.w },
    { name: "y", value: 0 },
    { name: "cy", value: artboard.h / 2 },
    { name: "by", value: artboard.h },
  ].map((axis) => {
    return { ...axis, type: "artboard" };
  });

  return [...axes, ...artboardAxes];
};

// Note: Keep track of whether snapped to artboard or other wg (hint lines could change)
const getSnappedAxes = (dims, snapTargets) => {
  // Get element's own axes:
  const myAxes = getAxes(dims);

  // const RANGE = 8; // unsure whether we should divide by this.scale

  // Keep track of minimum-distance x- and y-axis snapping pairs:
  let srcX = {
    name: "cx",
    value: -1,
    dist: Infinity,
  };
  let srcY = {
    name: "cy",
    value: -1,
    dist: Infinity,
  };
  let tgtX = {
    name: "",
    value: -1,
  };
  let tgtY = {
    name: "",
    value: -1,
  };

  // Find nearest targets:
  snapTargets.forEach((possibleTgt) => {
    Object.keys(myAxes).forEach((key) => {
      const axisVal = myAxes[key];
      const myAxis = {
        name: key,
        value: axisVal,
      };
      const dist = Math.abs(possibleTgt.value - axisVal);

      if (
        dist < srcX.dist &&
        isSameAxis(myAxis, possibleTgt) &&
        isSameAxis(myAxis, srcX)
      ) {
        srcX.name = key;
        srcX.value = axisVal;
        srcX.dist = dist;
        tgtX = possibleTgt;
      }
      if (
        dist < srcY.dist &&
        isSameAxis(myAxis, possibleTgt) &&
        isSameAxis(myAxis, srcY)
      ) {
        srcY.name = key;
        srcY.value = axisVal;
        srcY.dist = dist;
        tgtY = possibleTgt;
      }
    });
  });

  const allTgts = [
    { src: srcX, tgt: tgtX },
    { src: srcY, tgt: tgtY },
  ].filter((pair) => {
    return pair.src.dist < SNAPPING_RANGE;
  });

  return allTgts;
};

const getApparentDims = (wg) => {
  if (!wg) return { x: 0, y: 0, w: 0, h: 0 };
  const right = wg.x + wg.w;
  const x = right - wg.scaleX * wg.w;
  const btm = wg.y + wg.h;
  const y = btm - wg.scaleY * wg.h;
  const w = wg.w * wg.scaleX;
  const h = wg.h * wg.scaleY;
  return { ...wg, x, y, w, h };
};

const getApparentDimsInvert = (data) => {
  const w = data.w / data.scaleX;
  const h = data.h / data.scaleY;
  const x = data.x - (1 - data.scaleX) * w;
  const y = data.y - (1 - data.scaleY) * h;
  return { x, y, w, h };
};

const applySnapping = (snapPairs, dims) => {
  const { x, y, w, h } = dims;

  var newX = x;
  var newY = y;

  snapPairs.forEach((pair) => {
    const snapAxis = pair.src;
    const tgt = pair.tgt;
    let xOff = 0;
    let yOff = 0;

    // snap or "glue" the element into place:
    if (tgt.name.includes("x")) {
      newX = tgt.value;

      if (snapAxis.name === "cx") {
        xOff = w / 2;
      }
      if (snapAxis.name === "rx") {
        xOff = w;
      }
      newX -= xOff;
    } else {
      newY = tgt.value;

      if (snapAxis.name === "cy") {
        yOff = h / 2;
      }
      if (snapAxis.name === "by") {
        yOff = h;
      }
      newY -= yOff;
    }
  });

  return { x: newX, y: newY };
};

export default function (_ref, onUpdate) {
  var x = _ref.x,
    y = _ref.y,
    startX = _ref.startX,
    startY = _ref.startY,
    scl = _ref.canvasScale,
    w = _ref.w,
    h = _ref.h,
    angle = _ref.angle,
    scaleX = _ref.scaleX,
    scaleY = _ref.scaleY,
    widgets = _ref.widgets,
    wid = _ref.wid,
    artboard = _ref.artboard,
    isBaseEditingContext = _ref.isBaseEditingContext,
    editingContext = _ref.editingContext;

  // return function (dragEvent) {
  //   x += (dragEvent.pageX - startX) / scl;
  //   y += (dragEvent.pageY - startY) / scl;
  //   onUpdate({ x: x, y: y });
  //   startX = dragEvent.pageX;
  //   startY = dragEvent.pageY;
  // };

  // Do not overwrite initial startX,startY,x,y values
  // but instead compute based on difference from those initial values every time
  return function (dragEvent) {
    // Testing....
    const pageX = dragEvent.touches
      ? dragEvent.touches[0].pageX
      : dragEvent.pageX;
    const pageY = dragEvent.touches
      ? dragEvent.touches[0].pageY
      : dragEvent.pageY;

    let newx = x + (pageX - startX) / scl;
    let newy = y + (pageY - startY) / scl;

    const apparent = getApparentDims({
      x: newx,
      y: newy,
      w,
      h,
      scaleX,
      scaleY,
    });

    let possibleSnapPairs = [];

    let widgetSnapTargets = widgets.filter((wg) => wg.wid !== wid);
    let secondarySnapTarget = artboard;

    /*
     * If a repeater is being edited, update secondarySnapTarget to cell's dimensions
     */

    let ignoreSnapping = angle !== 0;

    if (!ignoreSnapping && !isBaseEditingContext) {
      const { offsetX, offsetY, parentId, height, width } = editingContext;
      const parent = widgets.find((w) => w.wid === parentId);
      const { x, y } = getApparentDims(parent);
      const cellDims = {
        x: x + offsetX,
        y: y + offsetY,
        w: width,
        h: height,
      };
      secondarySnapTarget = cellDims;

      // Ignore snapping in a rotated Repeater, or a Repeater within a Group (whose parent cannot be found here):
      if (parent?.angle !== 0 || !parent) {
        ignoreSnapping = true;
      }

      // Not working... But maybe it's too busy anyway. Just snap to cell borders.
      // widgetSnapTargets = widgetSnapTargets.filter(
      //   wg => wg.parentId === parentId
      // );
      widgetSnapTargets = [];
    }

    if (!ignoreSnapping) {
      const snapTargets = getSnapTargets(
        widgetSnapTargets,
        secondarySnapTarget
      );
      possibleSnapPairs = getSnappedAxes(apparent, snapTargets);
    }

    // -[ ] DO NOT try to snap if rotated

    // Have to do the apparent => merge => invert rigamarole:
    if (possibleSnapPairs.length > 0) {
      const newDims = applySnapping(possibleSnapPairs, apparent);
      // console.log("snappairs", possibleSnapPairs);
      const merged = { ...apparent, ...newDims };
      const dims = getApparentDimsInvert(merged);
      newx = dims.x;
      newy = dims.y;
    }

    // THIS IS IT!
    // if (Math.abs(newx - 0) < 100) {
    //   newx = 0;
    // }
    // And this works!
    // if (Math.abs(apparent.x - 0) < 100) {
    //   const merged = { ...apparent, ...{ x: 0 } };
    //   const dims = getApparentDimsInvert(merged);
    //   newx = dims.x;
    // }

    onUpdate({ x: newx, y: newy, snapPairs: possibleSnapPairs });
  };
}
