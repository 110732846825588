<template>
  <div class="w-full relative" :style="{ height: totalHeight + 'px' }">
    <!-- Date label and vertical lines layer: -->
    <div class="absolute top-0 left-0 w-full h-full">
      <div :id="'header' + wid" class="w-full flex">
        <div
          class="flex justify-center items-center font-bold p-5"
          :style="getDayParentStyle(dayLabel)"
          v-for="dayLabel in dayLabels"
          :key="dayLabel.label"
        >
          <div
            class="flex justify-center whitespace-nowrap text-center w-full"
            :class="`days-${wid}`"
            :style="getDayLabelStyle(dayLabel)"
          >
            {{ dayLabel.label }}
          </div>
        </div>
      </div>
      <div class="flex h-full">
        <div
          v-for="(h, i) in dayRange"
          :key="h"
          class="flex-grow flex"
          :style="dayWidthStyle"
        >
          <div v-if="i > 0" :style="verticalLineStyle"></div>
        </div>
      </div>
    </div>

    <!-- Calendar data layer: -->
    <div class="absolute top-0 left-0 w-full h-full">
      <!-- Need some way of giving this lower top to account for date labels...what if we put invisible div with same data? -->
      <div class="w-full flex invisible">
        <div
          :style="{ height: headerHeight + 'px' }"
          v-for="h in dayRange"
          :key="h"
        >
          Mon. 25
        </div>
      </div>
      <!-- Tracks container (py-4 is odd; goes with scale by 1.1 or 1.2): -->
      <div class="w-full" ref="tracks">
        <div
          v-for="(track, i) in tracks"
          :key="i"
          class="w-full relative py-4"
          :id="wid + 'track' + i"
          :style="trackHeights[i]"
        >
          <div v-for="(ev, i) in track" :key="i" :style="getEventStyle(ev)">
            <div :style="getEventStartFinStyle(ev)">
              <svg
                class="h-full w-full"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <!-- <circle :fill="event_backgroundColor" r="50" cx="50" cy="50" /> -->
                <path :fill="event_backgroundColor" d="M51,0 L51,100 L0,50 Z" />
              </svg>
            </div>
            <div
              class="flex h-full w-full"
              :class="`multidayEventTitle-${wid}`"
              :style="getStyles('multidayEventTitle')"
            >
              {{ ev.summary }}
            </div>
            <div :style="getEventEndFinStyle(ev)">
              <svg
                class="h-full w-full"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <!-- <circle fill="blue" r="50" cx="50" cy="50" /> -->
                <path
                  :fill="event_backgroundColor"
                  d="M49,0 L100,50 L49,100 Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- (Again, we have problem that h-full is too much) -->
      <!-- Short event lists container: -->
      <div class="w-full pt-6" ref="main">
        <div class="flex">
          <div
            v-for="h in dayRange"
            :key="h"
            class="flex-grow pl-4"
            :style="dayWidthStyle"
          >
            <!-- List short events: -->
            <div
              v-for="(ev, i) in eventsByDay[h]"
              :key="i"
              :style="getOpacityStyle(ev, dummyTime)"
              class="flex-col pb-5"
            >
              <div class="flex items-center w-full h-full">
                <div
                  class="flex flex-none items-center justify-center shrink-0"
                  :style="{ minWidth: `${2 * scaleX}em` }"
                >
                  <div
                    class="flex shrink-0"
                    :style="{
                      backgroundColor: eventTime_textColor,
                      width: `${1 * scaleX}em`,
                      height: `${1 * scaleX}em`,
                    }"
                  ></div>
                </div>
                <div
                  :style="getStyles('eventTime')"
                  class="flex flex-1 font-bold w-5/6 whitespace-nowrap"
                  :class="`eventTime-${wid}`"
                >
                  {{ formatTime(ev.start) }}
                </div>
              </div>
              <div
                :style="[getStyles('eventTitle')]"
                class="leading-tight pl-1/8 pr-2"
                :class="`eventTitle-${wid}`"
              >
                {{ ev.summary }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop } from "vue-property-decorator";
import { DateTime } from "luxon";
import { clamp, getEventXProportions, getRange } from "@/utils";

import {
  createTracks,
  eventLiesWithinRange,
  eventIsOver,
  SECONDS_IN_DAY,
} from "@/types/calendar";

import { CalendarEvent } from "@/types/calendar";
import { NodeData, NodeSetData } from "@/types/data";
import CalendarEmptyMessage from "@/components/widgets/CalendarEmptyMessage.vue";
import CalendarBase from "@/components/widgets/CalendarBase.vue";
import { EventBus } from "@/eventbus";
import { GlobalAppState } from "@/GlobalAppState";

/*
DESIGN NOTES:
- Fade out events once complete
- Remove events (past, then furthest ahead) (?)
- All day (one day) events appear in list view, rather than tracks, with display "All Day"
- Short event times like "9am" (TODO: currently is "9:00am")
- Date titles like "Tue. 24"
- Long event times have no display; just use horizontal space
*/

// [ ] get rid of this.d, formatTime, and startsbeforeweek

// and in Week, multiday bg should be folded into multiday
// today label could be folded into daylabels

// [ ] bug with "test5" (time label not showing up)
// [x] TODO: Watchers
// [ ] TODO: Use dayFormat (?)
// [x] TODO: the update() issue with trackheights
// [ ] TODO: Handle case of list events that overflow container (maybe just let flow off for now?)

@Component({
  components: {
    CalendarEmptyMessage,
  },
})
export default class CalendarWeekComponent extends CalendarBase {
  @Inject() context: GlobalAppState;

  @Prop(Array) data: any[];
  @Prop(Boolean) previewLiveData: boolean;

  @Prop({ default: false }) use24Hour: boolean;
  @Prop() wid: string;
  @Prop() scaleX: number;
  @Prop(Number) weekOffset: number;

  // @Prop(String) eventBulletColor: string;
  @Prop(String) verticalLines_color: string;
  @Prop(String) event_backgroundColor: string;
  @Prop(String) today_backgroundColor: string;
  @Prop(String) today_textColor: string;

  // ---------------------------------------------------------------------
  @Prop(String) readonly eventTitle_fontFamily: string | null;
  @Prop(Number) readonly eventTitle_fontWeight: number;
  @Prop(String) readonly eventTitle_textColor: string | null;
  @Prop(Number) readonly eventTitle_fontSize: number;
  @Prop(Number) readonly eventTitle_letterSpacing: number;
  @Prop(String) readonly eventTitle_textTransform: string | null;
  @Prop(String) readonly eventTitle_textDecoration: string | null;
  @Prop(String) readonly eventTitle_fontStyle: string | null;
  @Prop(Number) readonly eventTitle_lineHeight: number;
  @Prop(String) readonly eventTitle_textAlign: string | null;

  @Prop(String) readonly eventTime_fontFamily: string | null;
  @Prop(Number) readonly eventTime_fontWeight: number;
  @Prop(String) readonly eventTime_textColor: string | null;
  @Prop(Number) readonly eventTime_fontSize: number;
  @Prop(Number) readonly eventTime_letterSpacing: number;
  @Prop(String) readonly eventTime_textTransform: string | null;
  @Prop(String) readonly eventTime_textDecoration: string | null;
  @Prop(String) readonly eventTime_fontStyle: string | null;
  @Prop(Number) readonly eventTime_lineHeight: number;
  @Prop(String) readonly eventTime_textAlign: string | null;

  @Prop(String) readonly days_fontFamily: string | null;
  @Prop(Number) readonly days_fontWeight: number;
  @Prop(String) readonly days_textColor: string | null;
  @Prop(Number) readonly days_fontSize: number;
  @Prop(Number) readonly days_letterSpacing: number;
  @Prop(String) readonly days_textTransform: string | null;
  @Prop(String) readonly days_textDecoration: string | null;
  @Prop(String) readonly days_fontStyle: string | null;
  @Prop(Number) readonly days_lineHeight: number;
  @Prop(String) readonly days_textAlign: string | null;

  @Prop(String) readonly multidayEventTitle_fontFamily: string | null;
  @Prop(Number) readonly multidayEventTitle_fontWeight: number;
  @Prop(String) readonly multidayEventTitle_textColor: string | null;
  @Prop(Number) readonly multidayEventTitle_fontSize: number;
  @Prop(Number) readonly multidayEventTitle_letterSpacing: number;
  @Prop(String) readonly multidayEventTitle_textTransform: string | null;
  @Prop(String) readonly multidayEventTitle_textDecoration: string | null;
  @Prop(String) readonly multidayEventTitle_fontStyle: string | null;
  @Prop(Number) readonly multidayEventTitle_lineHeight: number;
  @Prop(String) readonly multidayEventTitle_textAlign: string | null;

  headerHeight = 0;

  dummyTime = 0;
  trackHeights: any[] = [];
  eventPaddingPercent = 0.5;
  dayRange = getRange(0, 6); // rename? confused with weekBounds?
  updateFrequencySeconds = 1;

  days_init: number;
  eventTitle_init: number;
  eventTime_init: number;
  multidayEventTitle_init: number;

  get totalHeight() {
    const scl = this.context.renderScale || 1;
    return (
      this.headerHeight +
      (this.$refs.main as HTMLElement)?.getBoundingClientRect().height / scl +
      this.trackHeights.reduce((acc, val) => parseInt(val.height) + acc, 0)
    );
  }

  mounted() {
    this.updateLayout();

    setInterval(() => {
      this.dummyTime++;
    }, this.updateFrequencySeconds * 1000);

    // In case fonts load after `mounted` fires, we'll update again
    EventBus.on("FONTS_LOADED", this.updateLayout);
  }

  beforeDestroy() {
    EventBus.off("FONTS_LOADED", this.updateLayout);
  }

  updateLayout() {
    this.$nextTick(() => {
      this.updateHeaderHeight();
      this.updateTrackHeights();
    });
  }

  get d(): CalendarEvent[] {
    // TODO: filter here

    // const useLiveData =
    //   this.data && this.data.length > 0 && this.previewLiveData;
    // const data = useLiveData ? this.data : dummyCalendarData;

    const data = this.data;

    // if (this.data && this.data.length > 0) {
    return data.map(
      (row: (NodeData | NodeSetData)[]) => new CalendarEvent(row)
    );
    // }
    // return [];
  }

  getOpacityStyle(ev: CalendarEvent) {
    return {
      opacity: ev && eventIsOver(ev) ? 0.5 : 1,
    };
  }

  getEventFinStyle() {
    return {
      height: "100%",
      width: "5vh",
      top: 0,
      position: "absolute",
    };
  }

  getEventPadding() {
    return {
      paddingLeft: `${1.5 * this.scaleX}`,
      paddingRight: `${1 * this.scaleX}`,
    };
  }

  endsAfterCalendarWeek(ev: CalendarEvent) {
    const endHour = this.weekBounds[1];
    return ev.end > endHour;
  }

  startsBeforeCalendarWeek(ev: CalendarEvent) {
    const startHour = this.weekBounds[0];
    return ev.start < startHour;
  }

  getEventStartFinStyle(ev: CalendarEvent) {
    if (!this.startsBeforeCalendarWeek(ev)) {
      return { display: "none" };
    }
    return {
      ...this.getEventFinStyle(),
      left: "-2.5vh",
    };
  }

  getEventEndFinStyle(ev: CalendarEvent) {
    if (!this.endsAfterCalendarWeek(ev)) {
      return { display: "none" };
    }
    return {
      ...this.getEventFinStyle(),
      right: "-2.5vh",
    };
  }

  updateHeaderHeight() {
    const headerHeight = document.querySelector(
      `#header${this.wid}`
    )?.clientHeight;
    // console.log("HH", headerHeight);
    this.headerHeight = headerHeight || 0;
  }

  get verticalLineStyle() {
    return {
      height: `calc(100% - ${this.headerHeight}px)`,
      width: "1px",
      background: this.verticalLines_color,
    };
  }

  get dayWidthStyle() {
    return {
      width: `${100 / 7}%`,
    };
  }

  getDayParentStyle(dayLabel: any) {
    const obj = dayLabel.isToday
      ? { background: this.today_backgroundColor }
      : {};
    return {
      ...this.dayWidthStyle,
      ...obj,
    };
  }

  getDayLabelStyle(dayLabel: any) {
    const obj = dayLabel.isToday ? { color: this.today_textColor } : {};
    // Object.assign mutates this.dayWidthStyle...
    return {
      ...this.getStyles("days", false),
      ...obj,
    };
  }

  get dayLabels() {
    // use week bounds to return list of e.g. "Mon. 24"
    let day = this.weekBounds[0];
    const days = [day];
    getRange(1, 6).forEach((i) => {
      days.push(day.plus({ days: i }));
    });
    return days.map((d) => {
      return {
        label: d.toFormat("ccc. d"),
        isToday: d.day === DateTime.now().day,
      };
    });
  }

  updateTrackHeights() {
    this.trackHeights = this.tracks.map((track, i) => {
      const id = `${this.wid}track${i}`;
      const el = document.getElementById(id) as HTMLDivElement;
      if (!el) return {};
      const childrenHeights = Array.from(el.children).map((c) => {
        return c.clientHeight;
      });
      const max = Math.max(...childrenHeights);
      return {
        height: `${max * 1.1}px`, // add a bit of padding with scale by 1.2
      };
    });
  }

  getEventStyle(ev: CalendarEvent) {
    const xs = getEventXProportions(ev, {
      start: this.weekBounds[0],
      end: this.weekBounds[1],
    }).map((n) => clamp(n, 0, 1));

    return {
      position: "absolute",
      left: `${100 * xs[0] + this.eventPaddingPercent}%`,
      right: `${100 * (1 - xs[1]) + this.eventPaddingPercent}%`,
      background: this.event_backgroundColor,
      padding: `${1 * this.scaleX}rem`,
    };
  }

  get tracks(): CalendarEvent[][] {
    const longEvents = this.eventsForCalendar.filter(
      (e) => e.end.diff(e.start).milliseconds > SECONDS_IN_DAY
    );
    return createTracks(longEvents);
  }

  get eventsByDay(): CalendarEvent[][] {
    // return shortEvents grouped into day categories and sorted by time
    const shortEvents = this.eventsForCalendar.filter(
      (e) => e.end.diff(e.start).milliseconds <= SECONDS_IN_DAY
    );

    return getRange(0, 6).map((i) => {
      return shortEvents
        .filter((e) => e.start.weekday % 7 === i)
        .sort((a, b) => {
          return a.start.diff(b.start).milliseconds;
        });
    });
  }

  formatTime(dt: DateTime) {
    // console.log("format", dt);
    if (dt.hour === 0 && dt.minute === 0) {
      return "All Day";
    }
    let options: any = {
      hour: "numeric",
      minute: "numeric",
      hour12: !this.use24Hour,
    };

    if (dt.minute === 0 && !this.use24Hour) {
      options = {
        hour: "numeric",
      };
    }
    // const meridiem = dt.hour > 11 ? "p" : "a"
    // return `${dt.toFormat("h:mm")} ${meridiem}m`;
    return `${dt.toLocaleString(options)}`.replace(/\sAM|\sPM/gi, (match) =>
      match.toLowerCase().trim()
    );
  }

  get weekBounds() {
    // return dates of sunday/saturday given the current day of the week and date
    let { weekday } = DateTime.now();
    if (weekday === 7) weekday = 0; // Sunday
    const startDay = DateTime.now()
      .set({ hour: 0, minute: 0 })
      .minus({ days: weekday })
      .plus({ days: 7 * this.weekOffset });
    const endDay = DateTime.now()
      .set({ hour: 0, minute: 0 })
      .plus({ days: 7 - weekday })
      .plus({ days: 7 * this.weekOffset });

    // console.log("bounds", startDay, endDay);
    return [startDay, endDay];
  }

  //  TODO:  Move this to this.d,  and rename to just events
  get eventsForCalendar() {
    // return events that fit within weekBounds
    const x = this.d.filter((ev) =>
      eventLiesWithinRange(ev, [this.weekBounds[0], this.weekBounds[1]])
    );
    // console.log("evs", x, this.d);
    return x;
  }
}
</script>

<style>
.eventTitle {
  display: inline-block;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.2em;
}

.multidayEventTitle {
  display: inline-block;
  overflow: hidden;
  max-height: 2.4em;
  line-height: 1.2em;
}
</style>
