<template>
  <a
    class="flex items-center space-x-1 text-sm text-gray-100 cursor-pointer hover:underline opacity-80 hover:opacity-100"
    target="_blank"
    href="https://www.screenfeed.com/support-articles/managing-your-data-connections"
  >
    <Icon name="Play" class="w-7 h-7" stroke-width="1" />
    <div>
      <slot></slot>
    </div>
  </a>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";

@Component({
  components: {
    Icon,
  },
})
export default class LearnMore extends Vue {}
</script>
