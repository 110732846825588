<template>
  <div class="w-full">
    <div>
      <div v-if="auths.length > 0" class="text-xl">
        Selected {{ authProvider }} Account
      </div>
      <div
        v-if="auths.length > 0"
        class="mt-1 relative text-black bg-white rounded w-96"
      >
        <button
          v-if="hasSelection"
          class="absolute right-4 top-5 transform -translate-px rounded border border-gray-300 px-2 py-px text-sm text-gray-500 hover:text-gray-900 hover:border-gray-400"
          @click="clearSelection"
          v-t="'SelectAuthorization.switch'"
        ></button>
        <div class="divide-y">
          <button
            v-for="(auth, index) in filteredAuths"
            :key="auth.providerAuthId"
            type="button"
            @click="select(auth)"
            class="flex items-center w-full h-16 space-x-3 px-4 py-3"
            :class="{
              'cursor-default': hasSelection,
              'hover:bg-app-teal cursor-pointer': !hasSelection,
              'rounded-tl rounded-tr': index === 0,
            }"
          >
            <div
              class="flex-shrink-0 inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-600"
            >
              <svg
                class="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <div class="text-left break-all leading-none">
              <div v-if="auth.name" class="font-bold">{{ auth.name }}</div>
              <div class="text-sm font-medium">{{ auth.username }}</div>
            </div>
          </button>
          <div v-if="hasSelection === false" class="px-4 py-3">
            <ThirdPartyOAuth
              class=""
              :dataProviderId="dataProviderId"
              @success="onAuthSuccess"
            >
              <template v-slot:title>
                <div>Add another account</div>
              </template>
            </ThirdPartyOAuth>
          </div>
        </div>
      </div>

      <div v-if="showInitialAuth">
        <ThirdPartyOAuth
          class="rounded-md bg-gray-600/80 custom-shadow-box p-16"
          :dataProviderId="dataProviderId"
          theme="dark"
          @success="onAuthSuccess"
        >
        </ThirdPartyOAuth>
      </div>
    </div>
    <ThirdPartyOAuth
      v-if="showReauthorize"
      class="bg-gray-600 custom-shadow-box p-8 self-center rounded-lg mt-8"
      :dataProviderId="dataProviderId"
      theme="dark"
      @success="onReauthorize"
    >
      <div
        class="text-red-500 mb-6"
        v-t="'SelectAuthorization.needPermission'"
      ></div>
    </ThirdPartyOAuth>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api/backend";
import { AuthProvider, ProviderAuthorization } from "@/types/data";
import ThirdPartyOAuth from "@/components/data/connections/setup/ThirdPartyOAuth.vue";
import Icon from "@/components/icons/Icon.vue";

@Component({
  components: {
    ThirdPartyOAuth,
    Icon,
  },
})
export default class SelectAuthorization extends Vue {
  @Prop(String) authProvider: AuthProvider;
  @Prop(String) dataProviderId: string;
  @Prop(Number) selectedAuthId: number | undefined;
  @Prop(Boolean) showReauthorize: boolean;

  open = false;
  isAutoSelected = false;
  fetchingAuths = false;
  auths: ProviderAuthorization[] = [];

  get hasSelection() {
    return typeof this.selectedAuthId !== "undefined";
  }

  get lastSelectionKey() {
    return `SelectAuthorization_${this.authProvider}_${this.dataProviderId}`;
  }

  get filteredAuths() {
    return this.auths.filter((a) => {
      if (this.hasSelection) {
        return a.providerAuthId === this.selectedAuthId;
      } else {
        return true;
      }
    });
  }

  get showInitialAuth() {
    return this.fetchingAuths === false && this.auths.length === 0;
  }

  created() {
    this.fetchExistingAuths().then(() => {
      // If there's only 1 account, select it by default;
      if (this.auths.length === 1) {
        this.select(this.auths[0]);
      } else if (this.auths.length > 1) {
        /**
         * If there are more than one user accounts and
         * no account is already selected. Try to pull the
         * last selection from local storage and use that.
         */
        if (!this.hasSelection) {
          const id = this.getStoredSelection();
          if (typeof id === "number") {
            const auth = this.auths.find((a) => a.providerAuthId === id);
            if (auth) {
              this.select(auth);
            }
          }
        }
      }
    });
  }

  getStoredSelection() {
    const value = localStorage.getItem(this.lastSelectionKey);
    console.log(`getting ${this.lastSelectionKey}: ${value}`);
    if (value !== null) {
      return parseInt(value, 10);
    }
    return undefined;
  }

  @Watch("selectedAuthId")
  onSelectionChanged(value: number | undefined) {
    if (typeof value === "number") {
      console.log(`storing ${this.lastSelectionKey}: ${value}`);
      localStorage.setItem(this.lastSelectionKey, value.toString());
    } else {
      console.log(`clearing ${this.lastSelectionKey}`);
      localStorage.removeItem(this.lastSelectionKey);
    }
  }

  fetchExistingAuths() {
    this.fetchingAuths = true;
    return api
      .get<ProviderAuthorization[]>(
        `connection-oauth2/users?dataProviderId=${this.dataProviderId}`
      )
      .then((response) => {
        this.auths = response;
      })
      .finally(() => {
        this.fetchingAuths = false;
      });
  }

  onAuthSuccess() {
    // Get list of existing providerAuthIds before fetching new ones
    const authIds = this.auths?.map((a) => a.providerAuthId);
    this.fetchExistingAuths().then(() => {
      if (this.auths.length === 1) {
        this.select(this.auths[0]);
      } else if (this.auths.length > 1 && authIds?.length > 0) {
        let newAuths = this.auths.filter(
          (a) => !authIds.includes(a.providerAuthId)
        );
        // If there is only 1 new authorization, auto-select it.
        if (newAuths.length === 1) {
          this.select(newAuths[0]);
        }
      }
    });
  }

  select(auth: ProviderAuthorization) {
    this.$emit("select", { ...auth });
  }

  clearSelection() {
    this.$emit("select");
  }

  onReauthorize() {
    this.$emit("reauthorized");
  }
}
</script>
