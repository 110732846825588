<template>
  <div v-on="listeners" :class="borderClasses" class="wrapper flex relative">
    <input
      class="w-full appearance-none px-1 focus:outline-none focus:shadow-outline"
      :class="inputClasses"
      ref="input"
      v-bind="attrs"
      type="number"
      :name="name"
      :value="currentValue"
      :min="min"
      :max="max"
      :step="step"
      :readonly="dataBound || readonly || !inputtable"
      :disabled="isDisabled"
      :placeholder="placeholder"
      autocomplete="off"
      @change="change"
      @keypress.enter="enter"
      @paste="paste"
      @dblclick="onDoubleClick"
    />
    <div
      class="select-none flex items-center justify-center absolute top-0 right-0 h-full w-5 text-xs text-gray-500 z-1"
      v-if="label"
    >
      <div v-text="label"></div>
    </div>
    <button
      class="spinner spinner-up z-2 text-gray-500"
      v-if="controls"
      type="button"
      tabindex="-1"
      :disabled="disabled || readonly || !increasable"
      @click="increase"
    >
      <Icon name="arrow-up" style="width: 8px; height: 8px" />
    </button>
    <button
      class="spinner spinner-down z-2 text-gray-500"
      v-if="controls"
      type="button"
      tabindex="-1"
      :disabled="disabled || readonly || !decreasable"
      @click="decrease"
    >
      <Icon name="arrow-down" style="width: 8px; height: 8px" />
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { normalizeDecimalNumber, isNumber, isNaN } from "@/utils";
import { KeyCodes } from "@/keycodes";
import Icon from "@/components/Icon.vue";

@Component({
  components: { Icon },
})
export default class NumberInput extends Vue {
  @Prop({ type: Object, default: undefined }) attrs: any;
  @Prop(Boolean) center: boolean;
  @Prop(Boolean) controls: boolean;
  @Prop(Boolean) disabled: boolean;
  @Prop({ type: Boolean, default: true }) inputtable: boolean;
  @Prop(Boolean) inline: boolean;
  @Prop({ type: Number, default: Infinity }) max: number;
  @Prop({ type: Number, default: 0 }) min: number;
  @Prop(String) name: string;
  @Prop(String) label: string;
  @Prop(String) placeholder: string;
  @Prop(Boolean) readonly: boolean;
  @Prop(Boolean) rounded: boolean;
  @Prop({ type: Number, default: 1 }) step: number;
  @Prop({ type: Number, default: NaN }) value: number;
  @Prop({ type: String, default: "sm" }) textSize: string;
  @Prop(Boolean) cycle360: boolean;
  @Prop(Boolean) isTransparent: boolean;
  @Prop(Boolean) dataBound: boolean;

  externalChange = false;
  currentValue = NaN;
  shiftKey = false;
  tabKey = false;

  hasEntered = false;

  created() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
  }
  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.code === KeyCodes.SHIFT) {
      this.shiftKey = true;
    }
    if (e.code === KeyCodes.TAB) {
      this.tabKey = true;
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.code === KeyCodes.SHIFT) {
      this.shiftKey = false;
    }
    if (e.code === KeyCodes.TAB) {
      this.tabKey = false;
    }
  }

  onDoubleClick() {
    let input = this.$refs.input as HTMLInputElement;
    input.select();
  }

  get borderClasses() {
    return {
      disabled: this.disabled,
      border: !this.isTransparent,
      "border-b": this.isTransparent,
      "border-app-gold": this.dataBound,
    };
  }

  get inputClasses() {
    const classes: string[] = [];

    if (this.center) {
      classes.push("text-center");
    }

    if (this.textSize === "sm") {
      classes.push("text-sm");
    }

    if (this.textSize === "lg") {
      classes.push("text-lg");
    }

    if (this.isTransparent) {
      classes.push("bg-transparent");
    }

    if (this.dataBound) {
      classes.push("bg-yellow-50");
      classes.push("text-yellow-500");
      classes.push("select-none");
    }

    return classes;
  }

  get isDisabled() {
    return (
      this.dataBound ||
      this.disabled ||
      (!this.decreasable && !this.increasable)
    );
  }

  /**
   * Indicate if the value is increasable.
   * @returns {boolean} Return `true` if it is decreasable, else `false`.
   */
  get increasable() {
    const num = this.currentValue;

    return isNaN(num) || num < this.max;
  }

  /**
   * Indicate if the value is decreasable.
   * @returns {boolean} Return `true` if it is decreasable, else `false`.
   */
  get decreasable() {
    const num = this.currentValue;

    return isNaN(num) || num > this.min;
  }

  /**
   * Filter listeners
   * @returns {Object} Return filtered listeners.
   */
  get listeners() {
    const listeners = { ...this.$listeners };

    delete listeners.change;

    return listeners;
  }

  @Watch("value", { immediate: true })
  onValueChanged(newValue: any, oldValue: any) {
    if (
      // Avoid triggering change event when created
      !(isNaN(newValue) && typeof oldValue === "undefined") &&
      // Avoid infinite loop
      newValue !== this.currentValue
    ) {
      this.externalChange = true;
      // const val = this.cycle360 ? (newValue + 360) % 360 : newValue;
      // console.log("val  changed", newValue);
      this.setValue(newValue);
    }
  }

  enter(event: any) {
    this.hasEntered = true;
    let val = Math.min(this.max, Math.max(this.min, event.target.value));
    // console.log("enter!", val);
    (this.$refs.input as HTMLInputElement).blur();
    this.setValue(val);
    this.hasEntered = false;
  }

  change(event: any) {
    // Must avoid emitting twice in the case that user presses Enter to submit value:
    if (this.hasEntered) return;
    let val = Math.min(this.max, Math.max(this.min, event.target.value));
    this.setValue(val);
  }

  // change(event: any) {
  //   let val = Math.min(this.max, Math.max(this.min, event.target.value));
  //   console.log("change!", val);

  //   // NOPE -- this breaks the increase/decrease arrow key listeners
  //   // We want to ONLY do this if change is triggered by click outside

  //   // Force to override the number in the input box (#13).
  //   // (this.$refs.input as HTMLInputElement).value = this.value.toString();
  // }

  // input(event: any) {
  //   console.log("input input", event.target.value);
  // }

  /**
   * Paste event handler.
   * @param {Event} event - Event object.
   */
  paste(event: any) {
    // @ts-ignore
    const clipboardData = event.clipboardData || window.clipboardData;

    if (clipboardData && !isNumber(clipboardData.getData("text"))) {
      event.preventDefault();
    }
  }

  /**
   * Decrease the value.
   */
  decrease() {
    if (this.decreasable) {
      let { currentValue } = this;

      if (isNaN(currentValue)) {
        currentValue = 0;
      }

      let val = Math.min(
        this.max,
        Math.max(this.min, normalizeDecimalNumber(currentValue - this.step))
      );

      this.setValue(val);
    }
  }

  /**
   * Increase the value.
   */
  increase() {
    if (this.increasable) {
      let { currentValue } = this;

      if (isNaN(currentValue)) {
        currentValue = 0;
      }

      this.setValue(
        Math.min(
          this.max,
          Math.max(this.min, normalizeDecimalNumber(currentValue + this.step))
        )
      );
    }
  }

  /**
   * Set new value and dispatch change event.
   * @param {number} value - The new value to set.
   */
  setValue(value: any) {
    const oldValue = this.currentValue;
    let newValue = this.rounded ? Math.round(value) : value;

    if (this.shiftKey && !this.externalChange && !this.tabKey) {
      let delta = this.step * 10 - this.step;
      newValue += oldValue < newValue ? delta : -delta;
    }

    if (this.min <= this.max) {
      newValue = Math.min(this.max, Math.max(this.min, newValue));
    }
    // console.log("set val", value);

    if (this.cycle360) {
      newValue = (newValue + 360) % 360;
    }

    this.currentValue = newValue;

    if (newValue === oldValue) {
      // Force to override the number in the input box (#13).
      (this.$refs.input as HTMLInputElement).value = newValue;
    }

    if (!this.externalChange) {
      // console.log("emit num", newValue, oldValue);
      this.$emit("change", newValue, oldValue);
    }
    this.externalChange = false;
  }
}
</script>

<style lang="postcss" scoped>
.wrapper button {
  visibility: hidden;
}
.wrapper button:disabled {
  display: none;
}
.wrapper button:focus {
  outline: none;
}
.wrapper:hover button {
  visibility: visible;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */

  /* background: rgb(242, 242, 242); */
}

input:focus {
  background: white;
}

.spinner {
  @apply flex items-center bg-white justify-center absolute w-4 border-l;
  border-right: solid 1px transparent;
}
.spinner:active {
  @apply bg-gray-300;
}
.spinner-up {
  @apply top-0 right-0 border-b;
  bottom: 50%;
}
.spinner-down {
  @apply bottom-0 right-0;
  top: 50%;
}
</style>
