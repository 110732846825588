<template>
  <div class="relative w-full">
    <input
      class="slider"
      type="range"
      :min="min"
      :max="max"
      step="1"
      :class="{ active: activeGradientNum === 1 }"
      :value="sliderMin"
      @input="$emit('inputMin', $event)"
      @change="$emit('changeMin', $event)"
      @mousedown="$emit('clickMin')"
    />
    <input
      class="slider"
      type="range"
      :min="min"
      :max="max"
      step="1"
      :class="{ active: activeGradientNum === 2 }"
      :value="sliderMax"
      @input="$emit('inputMax', $event)"
      @change="$emit('changeMax', $event)"
      @mousedown="$emit('clickMax')"
    />

    <div
      class="absolute top-0 h-2 w-full rounded-full border-0"
      :style="linearBg"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

// TODO: this must distingush between input and change

// NOTE: Copying a lot from DoubleSliderInput

// [x] TODO: Bg fill gradient must come from colors
// NOTE: Should possible do offset as well

// [x] TODO: give active thumb border of app-teal ("#21c5f0") instead of white

// [x] TODO: are clicks not working? want those to set activeGradientNum

@Component({})
export default class GradientSliderInput extends Vue {
  @Prop(Number) minValue: number;
  @Prop(Number) maxValue: number;
  @Prop(Number) min: number;
  @Prop(Number) max: number;
  @Prop(String) color1: string;
  @Prop(String) color2: string;
  @Prop(Number) activeGradientNum: number;

  mounted() {
    // console.log(
    //   "agn",
    //   this.activeGradientNum,
    //   this.activeGradientNum === 1,
    //   this.activeGradientNum === 2
    // );
  }

  get sliderMin() {
    return this.minValue;
  }

  //   set sliderMin(v: number) {
  //     this.$emit("updateMin", v);
  //   }

  get sliderMax() {
    return this.maxValue;
  }

  //   set sliderMax(v: number) {
  //     this.$emit("updateMax", v);
  //   }

  get linearBg() {
    return {
      background: `linear-gradient(to right, ${this.color1}, ${this.color2})`
    };
  }
}
</script>

<style scoped lang="postcss">
.slider {
  -webkit-appearance: none;
  @apply w-full h-full outline-none rounded-lg;
  @apply appearance-none absolute left-0 bottom-0 w-full;
}

.slider:hover {
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
  @apply cursor-pointer h-1 w-full;
}

.slider::-webkit-slider-thumb {
  box-shadow: 0 0 2px 1px #777;
  @apply appearance-none relative cursor-pointer bg-transparent w-3 h-3 z-10 rounded-full border-3 border-solid border-white top-0;
}

.active::-webkit-slider-thumb {
  /* @apply border-app-teal; */
  /* border-color: border-app-teal; */
  box-shadow: 0 0 2px 1px #21c5f0;
}

.slider::-webkit-slider-runnable-track,
.slider:focus::-webkit-slider-runnable-track,
.slider:focus::-ms-fill-lower,
.slider:focus::-ms-fill-upper {
  @apply appearance-none;
}
</style>
