<template>
  <div :id="uuid" class="text-gray-600 font-italic">Empty</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataType, DataValue } from "@/types/data";

@Component
export default class DataNodeEmpty extends Vue {
  @Prop({ type: String, required: false }) readonly uuid: string;
  @Prop(String) readonly dataType: DataType;
  @Prop() readonly value: DataValue;
}
</script>

<style scoped></style>
