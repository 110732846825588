<template>
  <div>
    <div class="flex items-end justify-between my-3">
      <p class="text-lg" v-t="'ProvideClipboard.copyAndPaste'"></p>

      <Link
        class="mx-1 cursor-help"
        href="https://screenfeed.com/support-articles/managing-your-data-connections"
        target="_blank"
        v-t="'needHelp'"
      />
    </div>
    <div class="relative">
      <textarea
        ref="content"
        rows="10"
        autofocus
        v-model="content"
        class="appearance-none p-3 py-2 block w-full mb-4 rounded text-sm text-black font-mono dark-form-focus"
      ></textarea>
    </div>

    <div
      v-if="inputError"
      v-text="inputError"
      class="inline-block rounded shadow px-3 py-px error-message"
    ></div>

    <portal to="setupWizardNext">
      <FormButton @click="onUploadData">Upload Data</FormButton>
    </portal>

    <portal to="setupWizardUiBlocker">
      <UiBlocker :visible="uploading">
        <div class="mt-4 text-xl text-white">Uploading Data</div>
      </UiBlocker>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import UiBlocker from "@/components/UiBlocker.vue";
import Link from "@/components/Link.vue";
import FormButton from "@/components/FormButton.vue";
import { SourceDataSchema } from "@/types/data";
import { api } from "@/api/backend";
import { SourceDataInfo } from "@/stores/connectionSetup";

@Component({
  components: { UiBlocker, FormButton, Link },
})
export default class Clipboard extends Vue {
  inputError: string | null = null;
  uploading = false;
  content = "";

  getFile() {
    const mimeType = "text/csv";
    const fileName = "ClipboardData.csv";
    return new File([this.content.trim()], fileName, { type: mimeType });
  }

  saveToLocalStorage() {
    localStorage.setItem("clipboardContent", this.content);
  }

  loadFromLocalStorage() {
    this.content = localStorage.getItem("clipboardContent") ?? "";
  }

  onUploadData() {
    if (this.content.trim() === "") {
      this.inputError = this.$t("ProvideClipboard.inputRequired").toString();
      return;
    } else {
      this.inputError = null;
    }

    const file = this.getFile();
    const data = new FormData();
    data.append("file", file);
    data.append("type", file.type);
    data.append("name", file.name);

    this.uploading = true;

    // TODO: Error handling
    api
      .post<SourceDataSchema>("dataconnection/upload", data)
      .then((response) => {
        this.$emit("complete", {
          name: null,
          type: "Tabular",
          schema: response,
        } as SourceDataInfo);
      })
      .finally(() => {
        this.uploading = false;
      });
  }
}
</script>

<style scoped lang="postcss">
.error-message {
  background: rgba(246, 38, 38, 0.35);
  border: 1px solid rgba(246, 38, 38, 0.75);
}
</style>
