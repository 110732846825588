<template>
  <ShapeBase
    class="rectangle"
    v-bind="$props"
    :gradientParts="gradientParts"
    :borderGradientParts="borderGradientParts"
    dataType="Rectangle"
  >
    <defs>
      <mask :id="`${wid}-${componentId}-rectangleStroke`">
        <rect
          x="0"
          y="0"
          :width="w * scaleX"
          :height="h * scaleY"
          fill="white"
        />
        <rect
          vector-effect="non-scaling-stroke"
          :x="borderWidth / 2"
          :y="borderWidth / 2"
          :width="w * scaleX - borderWidth"
          :height="h * scaleY - borderWidth"
          :rx="radius"
          :ry="radius"
          fill="black"
        />
      </mask>
    </defs>

    <rect
      :x="borderWidth / 2"
      :y="borderWidth / 2"
      :width="w * scaleX - borderWidth"
      :height="h * scaleY - borderWidth"
      :rx="radius"
      :ry="radius"
      fill="none"
      :stroke-dasharray="dashArray"
      :stroke-width="borderWidth"
      :stroke="border"
      :mask="$helpers.svgUrl(`#${wid}-${componentId}-rectangleStroke`)"
      vector-effect="non-scaling-stroke"
    />
    <rect
      :x="borderWidth / 2"
      :y="borderWidth / 2"
      :width="w * scaleX - borderWidth"
      :height="h * scaleY - borderWidth"
      :rx="radius"
      :ry="radius"
      :fill="rectFill"
    />
  </ShapeBase>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { makeId, parseGradientCss } from "@/utils";
import ShapeBase from "@/components/ShapeBase.vue";
import AnimatableWidget from "@/components/widgets/AnimatableWidget.vue";

@Component({
  inheritAttrs: false,
  components: {
    ShapeBase,
  },
})
export default class RectangleComponent extends AnimatableWidget {
  @Prop(String) readonly backgroundColor: string;
  @Prop(String) readonly backgroundImageUrl: string;
  @Prop(String) readonly backgroundSize: string;
  @Prop(Number) readonly borderRadius: number;
  @Prop(Number) readonly backgroundImageW: number;
  @Prop(Number) readonly backgroundImageH: number;
  @Prop(String) readonly backgroundRepeat: string;
  @Prop(Number) readonly backgroundRepeatSize: number;

  @Prop(Number) readonly perspectiveAngle: number;

  // TODO: Wrap up in an object? Same for bgImage
  @Prop(Number) readonly shadowX: number;
  @Prop(Number) readonly shadowY: number;
  @Prop(Number) readonly shadowBlur: number;
  @Prop(String) readonly shadowColor: string;
  @Prop(Boolean) readonly shadowDisplay: boolean;

  @Prop(Number) readonly borderWidth: number;
  @Prop(String) readonly borderColor: string;
  @Prop(Number) readonly borderDashSize: number;
  @Prop(Number) readonly borderGapSize: number;

  // @Prop() tempBackgroundInfo: any;
  @Prop({ default: makeId }) componentId: string;

  get borderDashed() {
    return this.borderDashSize > 0;
  }

  get dashArray() {
    if (this.borderDashed) {
      return `${this.borderDashSize} ${this.borderGapSize}`;
    } else {
      return "";
    }
  }

  // NOTE: May end up needing viewBox to get gradients working

  get rectFill() {
    if (this.backgroundImageUrl) {
      return this.$helpers.svgUrl(`#${this.componentId}backgroundImageUrl`);
    }
    if (this.gradientParts.type === "Solid") {
      return this.backgroundColor;
    }
    return this.$helpers.svgUrl(`#${this.gradientParts.type}-grad-${this.wid}`);
  }

  get border() {
    if (this.borderGradientParts.type === "Solid") {
      return this.borderColor;
    }
    return this.$helpers.svgUrl(
      `#${this.borderGradientParts.type}-grad-border-${this.wid}`
    );
  }

  // Only thing is, if it's a square, then 50 is the max
  get radius() {
    if (this.borderRadius > 0) {
      const min = Math.min(this.w * this.scaleX, this.h * this.scaleY);

      return `${(this.borderRadius / 100) * min}`;
    }
    return null;
  }

  get gradientParts() {
    return parseGradientCss(this.backgroundColor);
  }

  get borderGradientParts() {
    return parseGradientCss(this.borderColor);
  }
}
</script>
