<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <span
    class="button-root relative z-10 h-8 w-28 inline-flex shadow-sm rounded-sm text-white"
    @click.stop
  >
    <button
      type="button"
      :class="[
        'relative inline-flex items-center px-4 w-full justify-center rounded-md font-semibold',
        isPublished ? 'bg-app-violet' : 'bg-gray-400',
      ]"
      @click="toggle"
      v-t="'getApp'"
    ></button>
    <div
      class="origin-top-right bg-gray-100 text-gray-500 absolute right-0 top-10 -mr-1 rounded-md bg-white ring-1 p-8 ring-gray-300 w-72 flex flex-col justify-center"
      v-show="expanded"
    >
      <div>
        <button
          class="flex justify-between text-app-violet items-center bg-app-violet bg-opacity-20 ring-2 ring-app-violet hover:bg-opacity-40 rounded-md pl-4 pr-2 w-full h-8 mb-1"
          @click="copyUrl"
        >
          <span
            v-t="showCopiedMessage ? 'GetApp.copied' : 'GetApp.copy'"
          ></span>
          <Icon class="h-6 w-6" name="Duplicate" />
        </button>
        <div class="block">
          <span class="text-xs pl-4" v-t="'GetApp.copyExplain'"></span>
        </div>
      </div>
      <div class="mt-6 w-full">
        <a
          :href="feed.publishedUrl"
          target="_blank"
          class="flex justify-between text-app-violet items-center bg-app-violet bg-opacity-20 ring-2 ring-app-violet hover:bg-opacity-40 rounded-md pl-4 pr-2 w-full h-8 mb-1"
          type="submit"
        >
          <span v-t="'GetApp.open'"></span>
          <Icon class="h-6 w-6 text-app-violet" name="OpenLink" />
        </a>

        <span class="text-xs pl-4" v-t="'GetApp.openExplain'"></span>
      </div>
      <div class="mt-6">
        <DownloadBundle />
      </div>
      <!-- Add this back when the article it should link to exists -->
      <!-- <a href="">
        <div class="flex flex-row w-full items-center">
          <Icon class="h-8 w-8 text-app-violet mr-2" name="Play" />
          <span
            class="text-app-violet text-sm w-50 whitespace-normal"
            v-t="'GetApp.learn'"
          ></span>
        </div>
      </a> -->
    </div>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/icons/Icon.vue";
import DownloadBundle from "./DownloadBundle.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: { Icon, DownloadBundle },
})
export default class GetAppButton extends Vue {
  expanded = false;
  @Prop(Boolean) disabled: boolean;

  showCopiedMessage = false;

  get appEditor() {
    return useAppEditorStore();
  }

  get publishMeta() {
    return this.appEditor.publishMeta;
  }

  get feed() {
    return this.appEditor.feed;
  }

  get isPublished() {
    return this.publishMeta?.isPublished;
  }

  copyUrl() {
    this.showCopiedMessage = true;
    navigator.clipboard.writeText(this.feed.publishedUrl);
    setTimeout(() => {
      this.showCopiedMessage = false;
    }, 3000);
  }

  close() {
    this.expanded = false;
    document.removeEventListener("click", this.close);
  }

  open() {
    this.expanded = true;
    document.addEventListener("click", this.close);
  }

  toggle() {
    if (this.disabled) {
      return;
    }
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  }
}
</script>

<style lang="postcss" scoped>
.button-root {
  /* #f0f0f0 in decimal RGB */
  --color: 6, 78, 59;
}

.button-shade {
  /* border-top-color: rgba(255, 255, 255, 0.5);
  border-right-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-left-color: rgba(255, 255, 255, 0.5); */
  box-shadow: inset 1px 1px 1px #fff3,
    inset -1px -1px 1px rgba(var(--color), 0.3), 0 1px 1px #0002,
    0 1px 5px #0002;
}

.option-btn {
  @apply flex w-full px-4 py-2 text-left whitespace-nowrap hover:bg-gray-100;
}
</style>
