export type TextTransformValue =
  | "none"
  | "capitalize"
  | "uppercase"
  | "lowercase";

export type HorizontalAlignValue = "left" | "center" | "right";
export type VerticalAlignValue = "top" | "middle" | "bottom";

export interface TextStyleOptions {
  alignHorizontal?: HorizontalAlignValue;
  alignVertical?: VerticalAlignValue;
  fontFamily?: string;
  fontWeight?: number;
  fontStyle?: string;
  fontSize?: number;
  textColor?: string;
  textTransform?: TextTransformValue;
  textStrike?: boolean;
  textUnderline?: boolean;
  letterSpacing?: number;
  lineHeight?: number;
  paragraphSpacing?: number;
}

export const DefaultTextStyleOptions = {
  alignHorizontal: "Left",
  alignVertical: "top",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 96,
  textColor: "#000",
  textTransform: "none",
  textStrike: false,
  textUnderline: false,
  letterSpacing: 0,
  lineHeight: 1.2,
  paragraphSpacing: 1,
};
