import { isEmpty } from "@core/utils/isEmpty";
import { BASE_PARENT_ID } from "./constants";
import { AppModel } from "./types/data";
// import { TextOptions } from "./components/widgets/Text/TextOptions";
// import { makeTextContent } from "./text";

const cleanParents = (app: AppModel) => {
  /**
   * A bug in our code used to allow duplicate widget ids to
   * be added to the state.parents Record.
   *
   * Also, it seems widgets that have been deleted still remain
   * in the state.parents Record as well.
   *
   * This bug has been fixed, but some apps were persisted with
   * this corrupt data and so now we always need to clean it.
   *
   * We don't want to do it in a migration, because migrations
   * are about changing the app model.
   */
  if (typeof app.parents !== "object") {
    app.parents = {};
  }

  for (const parentId in app.parents) {
    const childIds = app.parents[parentId] ?? [];
    app.parents[parentId] = [...new Set(childIds)].filter(
      (wid) => typeof app.widgets[wid] !== "undefined"
    );
  }
};

const cleanWidgets = (app: AppModel) => {
  for (const wid in app.widgets) {
    const widget = app.widgets[wid];

    // If a widget doesn't have a parentId, set it to default
    if (isEmpty(widget.parentId)) {
      widget.parentId = BASE_PARENT_ID;
    }

    // There was a bug with graphs, where every time the widget was selected,
    // the customBarColors property had its contents multiplied, resulting in
    // an enormous array and a performance hit. The bug has been fixed, but
    // this fix is needed to address any existing graphs that are affected
    if (widget.type === "BarGraph" || widget.type === "ColumnGraph") {
      Object.values(widget.conditionalVersions).forEach((widgetVersion) => {
        //Arbitrary value of 50 chosen. It is higher than the number of items anyone
        //would ever display in a graph, and low enough to not impact performance.
        //Arbitrary value used so this file does not need to be rewritten to use AppPayload.
        //The value doesn't really matter, it will be reset by the widget on load, this is to
        //ensure that it does load and does not time out.
        if (widgetVersion.customBarColors.length > 50) {
          widgetVersion.customBarColors = widgetVersion.customBarColors.slice(
            0,
            50
          );
        }
      });
    }

    if (widget.type === "PieGraph" || widget.type === "StackedGraph") {
      Object.values(widget.conditionalVersions).forEach((widgetVersion) => {
        if (widgetVersion.colors.length > 50) {
          widgetVersion.colors = widgetVersion.colors.slice(0, 50);
        }
      });
    }

    if (widget.type === "CalendarRoomSchedule") {
      Object.values(widget.conditionalVersions).forEach((widgetVersion) => {
        if (
          !(
            "clockTime_fontSize" in widgetVersion &&
            "clockTime_fontFamily" in widgetVersion
          )
        ) {
          widgetVersion.clockTime_fontSize = 100;
          widgetVersion.clockTime_fontWeight = 400;
          widgetVersion.clockTime_textColor = "#000000";
          widgetVersion.clockTime_fontFamily = "Montserrat";
          widgetVersion.clockTime_textTransform = "none";
          widgetVersion.clockTime_textDecoration = "none";
          widgetVersion.clockTime_fontStyle = "normal";
          widgetVersion.clockTime_lineHeight = 1.2;
          widgetVersion.clockTime_textAlign = "left";
        }
      });
    }

    // Don't think this is needed any longer
    // if (widget.type === "Text") {
    //   const textWidget = widget as TextOptions;
    //   if (typeof textWidget.content === "string") {
    //     // Old text content could contain HTML, let's gather textContent
    //     const oldHtml = `<div>${w.content}</div>`;
    //     const doc = new DOMParser().parseFromString(oldHtml, "text/html");
    //     const textContent = doc.body.textContent || "";
    //     textWidget.content = makeTextContent(textContent);
    //   }
    // }
  }
};

/**
 * Replaces known system fonts with similar Google Fonts.
 *
 * We used to allow System fonts, but no longer because...
 *  - Sometimes system fonts would render differently on different machines
 *  - Google Font loader would fail to load system fonts.
 */
const cleanFonts = (app: AppModel) => {
  const widgets = app.widgets;
  Object.entries(widgets).forEach(([, widget]) => {
    const keysWithInvalidBoldFont: string[] = [];
    Object.entries(widget).forEach(([key, value]) => {
      if (key.endsWith("fontFamily")) {
        switch ((value as string).toLowerCase()) {
          case "monospace":
            (widget as any)[key] = "Courier Prime";
            break;
          case "serif":
            (widget as any)[key] = "Crimson Text";
            break;
          case "sans serif":
          case "sans-serif":
            (widget as any)[key] = "Inter";
            break;
          case "abril fatface":
          case "lobster":
            keysWithInvalidBoldFont.push(key);
            break;
        }
      }
    });

    // We allowed users to choose non-existent font weights for
    // "Abril Fatface" and "Lobster" which could effect snapshot timing.
    // This will reset to weight 400.
    keysWithInvalidBoldFont.forEach((key) => {
      const fontWeightKey = key.replace("fontFamily", "fontWeight");
      const fontWeightValue = (widget as any)[fontWeightKey];
      if (!["regular" || "400" || 400].includes(fontWeightValue)) {
        (widget as any)[fontWeightKey] = 400;
      }
    });
  });
};

/**
 * Cleans the app model of any corrupted data.
 */
export const cleanAppModel = (app: AppModel) => {
  const methods = [cleanParents, cleanWidgets, cleanFonts];

  methods.forEach((method) => method(app));
};
