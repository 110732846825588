<template>
  <div
    class="flex items-center justify-center bg-no-repeat bg-center bg-contain"
    :style="styles"
  >
    <span
      class="text-center whitespace-pre-line"
      v-if="loading"
      v-text="loadingText"
    ></span>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, watch } from "vue";
import { useI18n } from "vue-i18n-composable";
import { CSSProperties } from "vue/types/jsx";

const props = defineProps({
  width: {
    type: Number,
    required: true,
  },
  height: {
    type: Number,
    required: true,
  },
  url: {
    type: String,
  },
});

const loading = ref(true);
const imageUrl = ref<string | null>(null);

const loadingText = computed(() => {
  const { t } = useI18n();
  return loading.value ? `${t("loading")}\n${t("thumbnail")}` : null;
});

const styles = computed(() => {
  const styles: CSSProperties = {
    aspectRatio: `${props.width} / ${props.height}`,
  };
  if (imageUrl.value) {
    styles.backgroundImage = `url(${imageUrl.value})`;
  }
  return styles;
});

function getThumbnail(url?: string) {
  if (url === undefined) {
    return;
  }
  const video = document.createElement("video");
  video.crossOrigin = "anonymous";
  video.preload = "auto";
  video.muted = true;
  video.src = url;

  video.oncanplay = function () {
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      imageUrl.value = canvas.toDataURL();
      loading.value = false;
    }
  };
}

watch(() => props.url, getThumbnail, { immediate: true });
</script>
