<template>
  <div class="w-full h-full text-white text-[18px]">
    <div
      :id="dashboardSidebarId"
      class="fixed flex flex-col justify-between h-full bg-gray-900 w-64 border-r border-gray-900 mt-[-1px]"
    >
      <div class="flex flex-col divide-y divide-gray-900">
        <router-link :to="{ name: 'home' }">
          <FullLogo class="h-24 w-full flex justify-center" />
        </router-link>

        <div
          v-for="m in menu"
          :key="m.path"
          :class="{ 'h-full': m.name === 'Templates' && m.isActive }"
        >
          <router-link
            :to="m.path"
            class="w-full flex items-center cursor-pointer py-[1.15rem] pl-12"
            :class="{
              'bg-app-dark1a opacity-100': m.isActive,
              'hover:bg-app-dark1a hover:opacity-100 opacity-80': !m.isActive,
            }"
          >
            <div class="w-7 h-7 mr-3"><IconSolid :name="m.icon" /></div>
            <div class="font-semibold">{{ m.name }}</div>
          </router-link>
          <div
            class="border-t border-gray-900 bg-gray-900 bg-opacity-80 py-2 h-full"
            v-if="m.isActive && m.name === 'Templates'"
          >
            <div
              class="p-2 pl-12 cursor-pointer"
              v-for="(c, index) in categories"
              :key="index"
            >
              <router-link
                class="flex items-center pl-1"
                :to="{
                  path: `templates?category=${c.id}`,
                }"
                @click.native="onCategoryClick(c.id)"
              >
                <div
                  class="h-[20px] w-[20px] border-2 rounded-md relative flex items-center justify-center hover:bg-gray-500 hover:border-gray-100"
                  :class="{
                    'border-gray-400': category != c.id,
                    'border-gray-100': category === c.id,
                  }"
                >
                  <div
                    class="h-[9px] w-[9px] bg-gray-100 rounded-sm"
                    :class="{ hidden: category != c.id }"
                  />
                </div>
                <label
                  class="pl-3 text-sm font-semibold cursor-pointer"
                  :class="category === c.id ? 'text-white' : 'text-gray-500'"
                  >{{ c.value }}</label
                >
              </router-link>
            </div>
            <div
              class="w-full my-3 flex justify-center items-center"
              v-if="isTemplateAdmin"
            >
              <router-link :to="{ name: 'templates-categories' }">
                <div
                  class="text-[16px] h-[40px] px-3 rounded h-8 flex items-center justify-center space-x-2 border border-gray-100 text-gray-100 opacity-80 hover:opacity-100 hover:bg-app-dark3"
                >
                  <IconSolid name="Cog" class="h-5 w-5" />
                  <span class="ml-2" v-t="'templates.manageCategories'"></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <router-link
          :to="{ name: 'signout' }"
          class="w-full flex items-center cursor-pointer py-[1.15rem] pl-12 hover:bg-app-dark1a hover:opacity-100 opacity-80"
        >
          <div class="w-7 h-7 mr-3"><IconSolid name="Logout" /></div>
          <div class="font-semibold" v-t="'signOut'"></div>
        </router-link>

        <a
          target="_blank"
          href="https://www.screenfeed.com/support-categories/using-connect"
          class="w-full flex items-center cursor-pointer py-[1.15rem] pl-12 hover:bg-app-dark1a hover:opacity-100 opacity-80"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-7 h-7 mr-3"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
          <div class="font-semibold" v-t="'help'"></div>
        </a>
      </div>

      <UserInfo class="ml-12 mb-2 text-xs" />
    </div>

    <div class="ml-64 h-full">
      <router-view></router-view>
    </div>

    <portal-target name="dashboard-2"></portal-target>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import IconSolid from "@/components/icons/IconSolid.vue";
import Icon from "@/components/icons/Icon.vue";
import Apps from "@/views/Dashboard/Apps.vue";
import Collect from "@/views/Dashboard/Collect.vue";
import Control from "@/views/Dashboard/Control.vue";
import FullLogo from "@/views/Dashboard/FullLogo.vue";
import Modal from "@/components/Modal.vue";
// @ts-ignore
import UserInfo from "@/components/UserInfo.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import { auth } from "@/api/authService";
import { DASHBOARD_SIDEBAR_ID } from "@/constants";
import { useTemplatesStore } from "@/stores/templates";
import { useUserStore } from "@/stores/user";

const menu = [
  {
    component: "Apps",
    name: "My Apps",
    icon: "Chip",
    path: "/apps",
  },
  {
    component: "Connections",
    name: "Connections",
    icon: "Data",
    path: "/connections",
  },
  {
    component: "Templates",
    name: "Templates",
    icon: "Template",
    path: "/templates",
  },
];

@Component({
  components: {
    IconSolid,
    Icon,
    Apps,
    Collect,
    Control,
    FullLogo,
    Modal,
    ButtonGradient,
    UserInfo,
  },
})
export default class Dashboard extends Vue {
  isTemplateAdmin = false;

  dashboardSidebarId = DASHBOARD_SIDEBAR_ID;

  get templatesStore() {
    return useTemplatesStore();
  }

  get category() {
    return this.templatesStore.category;
  }

  get categories() {
    return this.templatesStore.categories;
  }

  async created() {
    this.isTemplateAdmin = await auth.isTemplateAdmin();
    this.templatesStore.getOptions();

    if (this.category === null) {
      const cat = Number(this.$route.query.category) || 0;
      if (cat != null && cat != 0) {
        this.templatesStore.setCategory(cat);
      } else {
        this.templatesStore.setCategory(null);
      }
    }
  }

  get userStore() {
    return useUserStore();
  }

  get menu() {
    return menu.map((m) =>
      Object.assign(m, { isActive: this.$route.path.startsWith(m.path) })
    );
  }

  get activeRoute() {
    return this.menu.find((m) => m.isActive);
  }

  onCategoryClick(categoryId: number | null) {
    if (this.category === categoryId) {
      this.templatesStore.setCategory(null);
      this.$router.push({ name: "templates-list" });
    } else {
      this.templatesStore.setCategory(categoryId);
    }
  }
}
</script>

<style lang="postcss">
.fill-area {
  @apply absolute rounded w-full h-full top-0 left-0;
}
</style>
