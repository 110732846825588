<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { useAppEditorStore } from "@/stores/appEditor";
import BarGraphComponent from "@/components/widgets/BarGraph/BarGraphComponent.vue";
import { BarGraphOptions } from "./BarGraphOptions";
import { NodeData } from "@/types/data";
import { EventBus } from "@/eventbus";

// NOTE: seems like all graphs should be able to use exact same wrapper
// -- though x and y column options are a sticking point

@Component
export default class BarGraphWrapper extends BarGraphComponent {
  get appEditor() {
    return useAppEditorStore();
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
  }

  setDataDependentProps() {
    this.setAxisColumns();
    this.$nextTick(() => {
      this.setInitial();
    });
  }

  created() {
    EventBus.on("UNDO_FIRED", this.setDataDependentProps);
  }

  beforeDestroy() {
    EventBus.off("UNDO_FIRED", this.setDataDependentProps);
  }

  @Watch("dataWatchProp", { immediate: true })
  onDataBindingChanged() {
    this.setDataDependentProps();
  }

  get initialXTitle() {
    if (this.xColumnOptions.length > 0) {
      return this.dataSet[0]?.find(
        (c) => c.uuid === this.xColumnOptions[0].value
      )?.displayName;
    } else {
      return "";
    }
  }

  get initialYTitle() {
    if (this.yColumnOptions.length > 0) {
      return this.dataSet[0]?.find(
        (c) => c.uuid === this.yColumnOptions[0].value
      )?.displayName;
    } else {
      return "";
    }
  }

  setInitial() {
    const payload: Partial<BarGraphOptions> = {
      xTitle: this.initialXTitle,
      yTitle: this.initialYTitle,
    };
    if (!isNaN(this.NewMaxValue)) {
      payload.maxValue = this.NewMaxValue;
    }
    if (!isNaN(this.NewMinValue)) {
      payload.minValue = this.NewMinValue;
    }
    if (this.initialColorArray.length > 0) {
      payload.customBarColors = this.initialColorArray;
    }
    this.apply(payload);
  }

  get initialColorArray() {
    let newArray: any = [];
    let colorArrayCopy = this.colorArray.slice();
    let timesToCopy = this.dataSet.length / this.colorArray.length;
    if (isNaN(timesToCopy) || timesToCopy === Infinity) {
      return colorArrayCopy;
    }
    for (let i = 0; i < timesToCopy; i++) {
      newArray = newArray.concat(colorArrayCopy);
    }
    return newArray;
  }

  get dataWatchProp() {
    const id = this.dataBinding?.dataConnectionUuid;
    const ph = this.widgetData[this.wid]?.[0]?._placeholderData?.toString();
    return `c${id}-${ph}`;
  }

  get dataBinding() {
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.wid,
      bindingType: "DataSet",
    });
    return bindings.length > 0 ? bindings[0] : undefined;
  }

  get dataSet(): NodeData[][] {
    const dataSet =
      (this.widgetData[this.wid]?.[0]?.data as NodeData[][]) ?? [];
    return dataSet;
  }

  get dataColumns(): NodeData[] {
    return this.dataSet.length > 0 ? this.dataSet[0] : [];
  }

  get values() {
    let values: number[] = [];
    this.dataSet.map((row) => {
      values.push(
        parseInt(
          row?.find((c) => c.uuid === this.xAxisColumnId)?.value as string
        )
      );
    });
    return values;
  }

  get NewMinValue() {
    let smallestValue = Math.floor(
      Math.min(...this.values.filter((v: any) => !isNaN(v)))
    );
    return smallestValue < 0 ? smallestValue : 0;
  }

  get NewMaxValue() {
    return Math.ceil(Math.max(...this.values.filter((v: any) => !isNaN(v))));
  }

  get xColumnOptions() {
    return this.dataColumns
      .filter((c) => c.dataType === "Number" && c.query != "__sf_index__")
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get yColumnOptions() {
    return this.dataColumns
      .filter(
        (c) =>
          c.dataType === "String" ||
          c.dataType === "Date" ||
          c.dataType === "Time" ||
          c.dataType === "DateTime"
      )
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  setAxisColumns() {
    const xAxisColumnIdOptions = this.xColumnOptions.map((o) => o.value);
    const yAxisColumnIdOptions = this.yColumnOptions.map((o) => o.value);
    if (
      xAxisColumnIdOptions &&
      xAxisColumnIdOptions.length > 0 &&
      !xAxisColumnIdOptions.includes(this.xAxisColumnId)
    ) {
      let xAxisColumnId = xAxisColumnIdOptions[0];
      this.apply({ xAxisColumnId });
    }
    if (
      yAxisColumnIdOptions &&
      yAxisColumnIdOptions.length > 0 &&
      !yAxisColumnIdOptions.includes(this.yAxisColumnId)
    ) {
      let yAxisColumnId = yAxisColumnIdOptions[0];
      this.apply({ yAxisColumnId });
    }
  }
}
</script>
