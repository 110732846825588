<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { useAppEditorStore } from "@/stores/appEditor";
import PieGraphComponent from "@/components/widgets/PieGraph/PieGraphComponent.vue";
import { NodeData } from "@/types/data";
import { EventBus } from "@/eventbus";
import { PieGraphOptions } from "./PieGraphOptions";

// NOTE: This and StackedGraphWrapper should both incorporate fix from https://github.com/ScreenfeedDigitalSignage/connect/pull/220/files

@Component
export default class PieGraphWrapper extends PieGraphComponent {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.widgetById(this.wid) as PieGraphOptions;
  }

  get widgetData() {
    return this.appEditor.widgetData;
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.wid], props, "NO_UNDO");
  }

  setDataDependentProps() {
    this.setAxisColumns();
    this.setInitial();
  }

  created() {
    EventBus.on("UNDO_FIRED", this.setDataDependentProps);
  }

  beforeDestroy() {
    EventBus.off("UNDO_FIRED", this.setDataDependentProps);
  }

  @Watch("dataWatchProp", { immediate: true })
  onDataBindingChanged() {
    this.setDataDependentProps();
  }

  get initialColorArray() {
    let newArray: any = [];
    let colorArrayCopy = this.model.colors.slice();
    let timesToCopy = this.dataSet.length / this.model.colors.length;
    if (isNaN(timesToCopy) || timesToCopy === Infinity) {
      return colorArrayCopy;
    }
    for (let i = 0; i < timesToCopy; i++) {
      newArray = newArray.concat(colorArrayCopy);
    }
    return newArray;
  }

  get dataWatchProp() {
    const id = this.dataBinding?.dataConnectionUuid;
    const ph = this.widgetData[this.wid]?.[0]?._placeholderData?.toString();
    return `c${id}-${ph}`;
  }

  get dataBinding() {
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.wid,
      bindingType: "DataSet",
    });
    return bindings.length > 0 ? bindings[0] : undefined;
  }

  get dataSet(): NodeData[][] {
    const dataSet =
      (this.widgetData[this.wid]?.[0]?.data as NodeData[][]) ?? [];
    return dataSet;
  }

  get dataColumns(): NodeData[] {
    return this.dataSet.length > 0 ? this.dataSet[0] : [];
  }

  get yColumnOptions() {
    return this.dataColumns
      .filter(
        (c) =>
          c.dataType === "String" ||
          c.dataType === "Date" ||
          c.dataType === "Time" ||
          c.dataType === "DateTime"
      )
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  get xColumnOptions() {
    return this.dataColumns
      .filter((c) => c.dataType === "Number" && c.query != "__sf_index__")
      .map((c) => {
        return {
          label: c.displayName,
          value: c.uuid,
        };
      });
  }

  setInitial() {
    const payload: Partial<PieGraphOptions> = {};
    if (this.initialColorArray.length > 0) {
      payload.colors = this.initialColorArray;
    }
    this.apply(payload);
  }

  setAxisColumns() {
    const xAxisColumnIdOptions = this.xColumnOptions.map((o) => o.value);
    const yAxisColumnIdOptions = this.yColumnOptions.map((o) => o.value);
    if (
      xAxisColumnIdOptions &&
      xAxisColumnIdOptions.length > 0 &&
      !xAxisColumnIdOptions.includes(this.xAxisColumnId)
    ) {
      let xAxisColumnId = xAxisColumnIdOptions[0];
      this.apply({ xAxisColumnId });
    }
    if (
      yAxisColumnIdOptions &&
      yAxisColumnIdOptions.length > 0 &&
      !yAxisColumnIdOptions.includes(this.yAxisColumnId)
    ) {
      let yAxisColumnId = yAxisColumnIdOptions[0];
      this.apply({ yAxisColumnId });
    }
  }
}
</script>
