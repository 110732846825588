<template>
  <div :id="uuid" v-text="formatted"></div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataType, DataValue } from "@/types/data";

@Component
export default class DataNodeDateTime extends Vue {
  @Prop({ type: String, required: false }) readonly uuid: string;
  @Prop(String) readonly dataType: DataType;
  @Prop() readonly value: DataValue;

  get formatted() {
    let d = new Date(Date.parse(this.value.value as string));

    if (this.dataType === "Time") {
      return d.toLocaleTimeString();
    }

    if (this.dataType === "Date") {
      return d.toLocaleDateString();
    }

    return d.toLocaleString();
  }
}
</script>

<style scoped></style>
