<template>
  <div
    id="canvas-scaler"
    :style="{ transform: `scale(${scale})` }"
    class="absolute top-0 left-0 w-full h-full select-none"
  >
    <Artboard>
      <div
        :id="getSelector(wg.props.wid)"
        v-for="wg in widgets"
        :key="wg.props.wid"
        :style="wg.style"
        class="relative"
      >
        <div
          class="absolute left-0 rounded border border-app-teal p-1"
          :class="wg.props.type === 'Group' ? '-top-16' : '-top-8'"
          v-if="showWidgetIdDebugText"
        >
          Widget Id: {{ wg.props.wid }}, {{ wg.props.z }}
        </div>
        <component
          :renderWid="wg.props.wid"
          :is="$helpers.getWidgetComponent(wg.props.type)"
          v-bind="wg.props"
        />
      </div>
    </Artboard>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { styler } from "@/lib/free-transform";

import Artboard from "@/components/Artboard.vue";
import { getAnimationId } from "./widgets/Animation";
import { useAppEditorStore } from "@/stores/appEditor";
import { StyleValue } from "vue/types/jsx";

@Component({
  components: {
    Artboard,
  },
})
export default class CanvasRender extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get renderableWidgets() {
    return this.appEditor.renderableWidgets;
  }

  get scale() {
    return this.appEditor.scale;
  }

  getSelector(wid: string) {
    return getAnimationId(wid);
  }

  get showWidgetIdDebugText() {
    return this.$route?.query.debug === "true";
  }

  get widgets() {
    return this.renderableWidgets.map((wg) => {
      const { element } = styler({
        x: wg.x,
        y: wg.y,
        scaleX: wg.scaleX,
        scaleY: wg.scaleY,
        width: wg.w || 0,
        height: wg.h || 0,
        angle: wg.angle,
        disableScale: true,
      });

      const style: StyleValue = {
        transform: element.transform,
        position: "absolute",
        opacity: `${wg.opacity / 100}`,
        width: `${element.width}px`,
        height: `${element.height}px`,
        zIndex: wg.z,
      };

      const props = {
        ...wg,
        width: element.width, // aha! must also be passed in AppRenderer
        height: element.height,
      };

      return { style, props };
    });
  }
}
</script>
