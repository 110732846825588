<template>
  <div
    id="data-manager"
    @keydown.esc="cancelClickHandler"
    style="z-index: 20000"
    class="fixed inset-0 select-none h-full w-full flex flex-col flex-grow bg-app-dark3 text-white"
  >
    <!-- Header/tabs: -->
    <div
      class="px-6 flex-grow-0 w-full bg-app-dark0 flex justify-between items-center"
    >
      <div class="flex pt-4 items-center">
        <router-link
          v-for="tab in tabs"
          :key="tab.path"
          :to="{ path: tab.path, query: $route.query }"
          class="py-3 px-6 cursor-pointer rounded-t-md whitespace-nowrap"
          :class="[
            isTabSelected(tab.path)
              ? 'bg-app-dark3'
              : 'text-gray-300 hover:bg-gray-800 hover:text-gray-200',
          ]"
          >{{ tab.label }}
        </router-link>

        <router-link
          v-if="showFixProblem"
          :to="{ path: fixConnectionPath, query: $route.query }"
          class="flex items-center whitespace-nowrap"
          :class="[
            isTabSelected(fixConnectionPath)
              ? 'py-3 px-6 cursor-pointer rounded-t-md bg-app-dark3'
              : 'text-yellow-50 text-sm border border-yellow-500 bg-yellow-500/10 hover:bg-yellow-500/20 rounded ml-5 pl-1 pr-3 py-1',
          ]"
        >
          <IconSolid class="text-yellow-400 h-5 w-5 mr-1" name="Exclamation" />
          <span v-t="'Manager.fixConnectionProblem'"></span>
        </router-link>
      </div>
      <div class="flex items-center truncate">
        <div class="text mx-6 truncate max-width-[65%]" v-if="connection">
          {{ connection?.name ?? "Untitled Connection" }}
        </div>

        <div class="flex space-x-4">
          <OutlineButton
            class="p-2 border-2 border-gray-600"
            customGradient="transparent"
            @click="cancelClickHandler"
            v-t="'close'"
          >
          </OutlineButton>
        </div>
      </div>
    </div>

    <div class="flex-grow flex flex-col min-h-0 relative">
      <router-view></router-view>

      <UiBlocker :visible="isLoading">
        <div v-t="'Manager.loadingConnection'" />
      </UiBlocker>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import Modal from "@/components/Modal.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  APP_EDITOR_ROUTE_PATH,
  PLACEHOLDER_CONNECTION_UUID,
} from "@/constants";

import { NodeData, SchemaNode, DataBindingType } from "@/types/data";
import { parseDataSchema, parseDataRows } from "@/util/dataUtils";
import { Route } from "vue-router";
import { useDragDropStore } from "@/stores/dragDrop";
import { useEditableDataStore } from "@/stores/editableData";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionDataStore } from "@/stores/connectionData";
import {
  ConnectionReqInfo,
  useConnectionEditorStore,
} from "@/stores/connectionEditor";
import { useConnectionsStore } from "@/stores/connections";

const isEditorRoute = (route: Route) => {
  return route.path.includes(APP_EDITOR_ROUTE_PATH);
};

const isConnectionsRoute = (route: Route) => {
  return route.path.includes(APP_EDITOR_CONNECTION_ROUTE_PATH);
};

@Component({
  components: { Modal, OutlineButton, UiBlocker, IconSolid },
})
export default class Manager extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get connectionsStore() {
    return useConnectionsStore();
  }

  get connectionDataStore() {
    return useConnectionDataStore();
  }

  get editableDataStore() {
    return useEditableDataStore();
  }

  beforeRouteEnter(to: Route, from: Route, next: () => void) {
    /**
     * Clear out previously loaded state before entering the route
     * This avoids weird visual effects where it temporarily displays
     * the data that was loaded before.
     */
    // TODO: will this work with pinia? -- No, it will not.
    // this.connectionEditor.$reset();
    useEditableDataStore().clearState();
    next();
  }

  get tabs() {
    const result = [
      {
        label: this.dataTabLabel,
        path: `${this.basePath}/data`,
      },
    ];

    // Only show "Replace" if non-scalar binding (i.e. collection binding)
    // AND the user already has a data connection.
    // (If they just have placeholder data, we expose the "Replace" option in the right-hand editor itself.)
    // -----------------------------------------
    if (
      isEditorRoute(this.$route) &&
      typeof this.$route.query.widgetId !== "undefined" &&
      this.dataBinding?.bindingType !== "Scalar" &&
      this.$route.params.connectionUuid !== PLACEHOLDER_CONNECTION_UUID
    ) {
      result.push({
        label: "Replace Data",
        path: `${this.basePath}/replace`,
      });
    }
    // -----------------------------------------

    if (
      this.dataConnectionUuid &&
      this.dataConnectionUuid !== PLACEHOLDER_CONNECTION_UUID
    ) {
      result.push({
        label: "Settings",
        path: `${this.basePath}/settings`,
      });
    }

    return result;
  }

  get fixConnectionPath() {
    return `${this.basePath}/reauthorize`;
  }

  get showFixProblem() {
    return this.connection?.isFaulted === true;
  }

  get basePath() {
    // TODO: Figure out a better way to generate these urls. Maybe named routes?

    if (isEditorRoute(this.$route)) {
      return `/${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}/${this.$route.params.connectionUuid}`;
    }

    if (isConnectionsRoute(this.$route)) {
      return `/${APP_EDITOR_CONNECTION_ROUTE_PATH}/${this.$route.params.connectionUuid}`;
    }

    return "";
  }

  get dataTabLabel() {
    if (this.dataConnectionUuid === PLACEHOLDER_CONNECTION_UUID)
      return "Data Grid";
    return this.dataBinding?.bindingType === "Scalar" ? "Data" : "Data Grid";
  }

  isTabSelected(path: string) {
    return this.$route.path.startsWith(path);
  }

  cancelClickHandler() {
    // In case user is bailing out of "replace data" flow early, must clear out draggingNode:
    useDragDropStore().draggingInfo = null;

    if (isEditorRoute(this.$route)) {
      this.$router.push({
        name: "edit",
        params: { id: this.$route.params.id },
      });
      return;
    }

    if (isConnectionsRoute(this.$route)) {
      this.$router.push({ name: "connections-list" });
    }
  }

  // =======================================================
  // DATA LOADING
  // =======================================================

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get moderationData() {
    return this.connectionDataStore.moderationDataRows;
  }
  get moderationMode() {
    return this.connectionEditor.moderationMode;
  }

  get connection() {
    return this.connectionEditor.connection;
  }
  get schema() {
    return this.connectionEditor.schema;
  }
  get isLoading() {
    return this.connectionEditor.isLoading;
  }

  get selectedWidget() {
    return this.appEditor.selectedWidget;
  }

  get dataBinding() {
    if (this.selectedWidget === undefined) {
      return undefined;
    }

    // console.log("Manager get binding", this.$route.query.type);

    let bindingType: DataBindingType =
      this.selectedWidget?.type === "Repeater" ? "DataSetParent" : "DataSet";

    // NOTE: Unsure whether second condition is needed... seems like we just don't have query type in this case... so not needed...
    if (
      this.$route.query.type &&
      !["Calendar", "Collection"].includes(this.$route.query.type as string)
    ) {
      bindingType = "Scalar";
    }
    // console.log("binding type....", bindingType, this.$route.query.type);
    const bindings = this.appEditor.bindingsForComponent({
      widgetId: this.selectedWidget?.wid || "",
      bindingType,
      property: this.$route.query.property as string,
    });

    return bindings.length > 0 ? bindings[0] : undefined;
  }

  // Placeholder data
  get dataSet(): NodeData[][] {
    if (this.selectedWidget) {
      // Dataset/collection properties are named "data"
      return (
        (this.appEditor.widgetData[this.selectedWidget?.wid]?.[0]
          ?.data as NodeData[][]) ?? []
      );
    }
    return [];
  }

  get dataConnectionUuid() {
    return this.$route.params.connectionUuid;
  }

  get dataConnection() {
    const conn = this.connectionsStore.connections?.find(
      (c) => c.uuid === this.dataConnectionUuid
    );
    return conn;
  }

  get hasDataConnection() {
    return (
      typeof this.dataConnectionUuid !== "undefined" &&
      this.dataConnectionUuid !== PLACEHOLDER_CONNECTION_UUID
    );
  }

  /**
   * Must get moderation data whenever connection is moderated, be it editable or synced
   */
  get mustFetchModerationData() {
    return this.moderationMode !== null && this.hasDataConnection;
  }

  async loadDataConnection() {
    // This ensures that any calls to updateDataConnection from the Settings view do NOT go through POST route
    this.connectionEditor.isEditMode = true;

    const { rows } = await this.connectionDataStore.refreshConnectionData({
      connectionId: this.dataConnectionUuid,
      isModerated: this.mustFetchModerationData,
    });

    /**
     * Rather than parsing schema from dataset, use the connection's schema -- for one thing, this lets us get accurate isRequired info for each node.
     *
     * However, if the insertion order was buggy on the backend, this schema (from nodeSets[0].nodes) may be in wrong order.
     * Therefore we must order it according to the order that the columns appear in the data.
     *
     * And, filter out any columns that are not actually present in the dataset (fixes bug with calendar data).
     *
     * (But only if there *are* rows in the dataset. For empty datasets, we still need to set schema here.)
     */

    const firstRecordSchemaUuids = rows?.[0]?.columns.map((c) => c.uuid) || [];

    const schema = (this.connection?.nodeSets[0]?.nodes as unknown[]).filter(
      (node: any) => {
        const isWithinDataSet =
          firstRecordSchemaUuids.length > 0
            ? firstRecordSchemaUuids.includes(node.uuid)
            : true;

        return !node.isArtificial && node.isSelected && isWithinDataSet;
      }
    ) as SchemaNode[];

    if (rows && rows.length > 0) {
      schema.sort((a, b) => {
        const aIdx = rows[0].columns.findIndex((x) => x.uuid === a.uuid);
        const bIdx = rows[0].columns.findIndex((x) => x.uuid === b.uuid);
        return aIdx - bIdx;
      });
    }

    this.connectionEditor.schema = schema;
  }

  loadPlaceholderData() {
    // console.log("Load ph data", this.dataSet);
    const schema = parseDataSchema(this.dataSet);
    const rows = parseDataRows(this.dataSet);

    this.editableDataStore.setRowsInitialState(rows);

    this.connectionEditor.schema = schema;

    this.connectionDataStore.setReadOnlyData({
      schemaType: "Tabular",
      data: this.dataSet,
    });
  }

  /**
   * Either fetch data from existing manual connection, or load up widget's placeholder data
   */
  async created() {
    // console.log("create manager", this.$route.params);
    if (this.$route.params.newConnectionUuid) {
      // User opened up to replace immediately -- don't need to load here, but instead will load in PreviewData
      return;
    }

    this.connectionEditor.isLoading = true;

    if (this.appEditor.hasUnsavedChanges) {
      await this.appEditor.updateApp();
      this.appEditor.setHasUnsavedChanges(false);
    }

    const { connectionUuid } = this.$route.params;

    if (connectionUuid !== PLACEHOLDER_CONNECTION_UUID) {
      // This sets the connection to be edited in the background

      const payload: ConnectionReqInfo = {
        dataConnectionUuid: connectionUuid,
      };
      const appUuid = this.$route.params.id;
      if (appUuid) {
        payload.appUuid = appUuid;
      }
      // console.log("getting connection...", payload);

      const connection = await this.connectionEditor.getConnection(payload);

      console.log("Got conn", connection);

      /**
       * Don't attempt to load Tree-shaped data here.
       */
      if (
        connectionUuid &&
        !connection?.isCollection &&
        connection?.schemaType !== "Calendar" &&
        !connection?.moderationMode
      ) {
        return this.connectionDataStore
          .loadReadOnlyData({ uuid: connectionUuid })
          .finally(() => {
            this.connectionEditor.isLoading = false;
          });
      }
    }

    // Remove any moderationMode info from state.connection
    if (connectionUuid === PLACEHOLDER_CONNECTION_UUID) {
      this.connectionEditor.connection = null;
    }

    try {
      if (this.hasDataConnection) {
        await this.loadDataConnection();
      } else {
        this.loadPlaceholderData();
      }
    } catch (e) {
      console.log("Mount error", e);
      this.editableDataStore.errorMessage = `Error fetching: ${e as string}`;
    } finally {
      this.$nextTick(() => {
        this.connectionEditor.isLoading = false;
      });
    }
  }
}
</script>
