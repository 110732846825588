<template>
  <div class="w-full space-y-8">
    <SelectAuthorization
      authProvider="Microsoft"
      dataProviderId="MicrosoftWorkbook"
      :showReauthorize="showReauthorize"
      :selectedAuthId="auth?.providerAuthId"
      @select="onAccountSelect"
    />

    <div v-if="auth != null">
      <form ref="form" class="space-y-6" @submit.prevent="onSearch">
        <div>
          <div
            class="block text-xl mb-1"
            v-t="'MicrosoftSheets.enterName'"
          ></div>
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="search"
              v-model="name"
              class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-900 focus:border-gray-500"
              placeholder="file_name.xlsx"
              required
            />
            <button
              type="submit"
              class="text-white absolute right-2.5 bottom-2.5 bg-app-teal focus:ring-4 focus:outline-none focus:ring-blue-500 font-medium rounded-lg text-sm px-4 py-2"
            >
              Search
            </button>
          </div>

          <div
            class="inline-block px-3 py-px rounded border border-red-500 mt-1 text-sm bg-red-500 bg-opacity-50"
            v-if="inputError"
            v-text="inputError"
          ></div>
        </div>
      </form>

      <div v-if="driveItems && !spreadsheet">
        <div
          class="overflow-x-auto relative shadow-md sm:rounded-lg mt-10 bg-gray-600"
        >
          <table class="w-full text-sm text-left text-white bg-gray-600">
            <thead class="text-xs text-white uppercase bg-gray-800">
              <tr>
                <th scope="col" class="py-3 px-6">File name</th>
                <th scope="col" class="py-3 px-6">Created</th>
                <th scope="col" class="py-3 px-6">Modified</th>
                <th scope="col" class="py-3 px-6">Author</th>
                <th scope="col" class="py-3 px-6">
                  <span class="sr-only">Use</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                @click="onDriveItemSelected(di)"
                class="cursor-pointer bg-gray-600 hover:bg-gray-400 text-white"
                v-for="di in driveItems"
                :key="di.id"
              >
                <th scope="row" class="py-4 px-6 font-medium whitespace-nowrap">
                  {{ di.name }}
                </th>
                <td class="py-4 px-6">
                  {{ di.created }}
                </td>
                <td class="py-4 px-6">
                  {{ di.lastModified }}
                </td>
                <td class="py-4 px-6">
                  {{ di.createdBy }}
                </td>
                <td class="py-4 px-6 text-right">
                  <a
                    href="#"
                    class="text-white inline-flex items-center px-2.5 rounded-md text-sm cursor-pointer bg-app-teal"
                    >Select</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <portal to="setupWizardUiBlocker">
        <UiBlocker :visible="fetching">
          <div
            class="mt-4 text-xl text-white"
            v-t="'MicrosoftSheets.loadingSpreadsheet'"
          ></div>
        </UiBlocker>
      </portal>
    </div>

    <div class="relative" v-if="spreadsheet">
      <div
        class="border border-app-dark2 bg-black bg-opacity-20 rounded p-6 space-y-6"
      >
        <div class="flex items-center space-x-4">
          <img
            :src="store.source?.iconUrl"
            :alt="store.source?.displayName + ' icon'"
            class="h-12"
          />
          <div>
            <div class="text-lg">{{ spreadsheet.spreadsheetName }}</div>
            <div class="font-mono text-xs text-gray-500">
              {{ selectedDriveItem?.id }}
            </div>
          </div>
        </div>

        <div class="text-xl" v-t="'MicrosoftSheets.selectSheet'"></div>

        <TabInput v-model="selectedRangeId" :options="sheetOptions" />

        <div
          v-if="showEmptyError"
          class="text-red-500 italic"
          v-t="'MicrosoftSheets.emptySheetError'"
        ></div>

        <portal to="setupWizardNext">
          <FormButton
            :disabled="selectedRangeId === null"
            type="button"
            @click="onSheetSelected"
            v-t="'continue'"
          ></FormButton>
        </portal>
      </div>

      <portal to="setupWizardUiBlocker">
        <UiBlocker :visible="importing">
          <div
            class="mt-4 text-xl text-white"
            v-t="loadingSheetDataMessage"
          ></div>
        </UiBlocker>
      </portal>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import { api } from "@/api/backend";
import { ProviderAuthorization, TabularDataSchema } from "@/types/data";

import SelectAuthorization from "@/components/data/connections/setup/provide/SelectAuthorization.vue";
import FatalServerError from "@/components/FatalServerError.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import TabInput from "@/components/inputs/TabInput.vue";
import Icon from "@/components/icons/Icon.vue";
import {
  SourceDataInfo,
  useConnectionSetupStore,
} from "@/stores/connectionSetup";

import {
  SearchDriveResponse,
  MicrosoftSheetsResponse,
  DriveItem,
} from "@/types/microsoft";

@Component({
  components: {
    FatalServerError,
    UiBlocker,
    FormButton,
    SelectMenu,
    TabInput,
    Icon,
    SelectAuthorization,
  },
})
export default class ProvideMicrosoftWorkbook extends Vue {
  inputError: string | null = null;
  authorizing = false;
  showReauthorize = false;
  showEmptyError = false;
  fetchingAuths = false;
  auth: ProviderAuthorization | null = null;
  auths: ProviderAuthorization[] | null = null;
  selectedAuth: ProviderAuthorization | null = null;
  selectedDriveItem: DriveItem | null = null;
  driveItems: DriveItem[] | null = null;
  name = "";
  workbookId = "";
  fetching = false;
  importing = false;
  spreadsheet: MicrosoftSheetsResponse | null = null;
  selectedRangeId: string | null = null;

  onReauthorize() {
    this.showReauthorize = false;
  }

  onAccountSelect(auth: ProviderAuthorization) {
    this.auth = auth;
  }

  onDriveItemSelected(item: DriveItem) {
    this.selectedDriveItem = item;
    this.onImport();
  }

  onSearch() {
    this.inputError = "";
    this.selectedDriveItem = null;
    this.spreadsheet = null;
    this.selectedRangeId = null;

    if (this.name === "") return;

    this.fetching = true;

    api
      .post<SearchDriveResponse>(`microsoft/drive`, {
        query: this.name,
        providerAuthId: this.auth?.providerAuthId,
      })
      .then((response) => {
        this.driveItems = response.items.length > 0 ? response.items : null;
        if (this.driveItems === null || this.driveItems.length === 0)
          this.inputError =
            "No results, please confirm the file name and type of 'xlsx'";
      })
      .catch((errors) => {
        const code = errors?.[0]?.code;

        switch (code) {
          case "authFailure":
            this.showReauthorize = true;
            break;
          default:
            this.inputError = Array.isArray(errors)
              ? `Errors: ${errors.map((e: any) => e.message).join(". ")}`
              : errors.toString();
            break;
        }

        return Promise.reject(errors);
      })
      .finally(() => {
        this.fetching = false;
      });
  }

  onSheetSelected() {
    if (this.selectedRangeId === null) {
      return;
    }

    const rangeOpts = {
      range: this.selectedRangeId,
      take: 25,
      sheetId: this.spreadsheet?.sheets.find(
        (s) => s.rangeId == this.selectedRangeId
      )?.id,
    };

    const payload = {
      providerAuthId: this.spreadsheet?.providerAuthId,
      spreadsheetId: this.selectedDriveItem?.id,
      driveId: this.selectedDriveItem?.driveId,
      rangeOptions: rangeOpts,
      isCollection: this.store.isCollection,
    };

    this.importing = true;
    this.showEmptyError = false;

    return api
      .post<TabularDataSchema>(
        `microsoft/${this.selectedDriveItem?.id}/data`,
        payload
      )
      .then((response) => {
        this.$emit("complete", {
          name: this.spreadsheet?.spreadsheetName,
          type: "Tabular",
          schema: response,
          providerAuthId: payload.providerAuthId,
        } as SourceDataInfo);
      })
      .catch((errors) => {
        // console.log("error importing sheet", err);
        const code = errors?.[0]?.code;
        if (code === "noData") {
          this.showEmptyError = true;
        }
      })
      .finally(() => {
        this.importing = false;
      });
  }

  onImport() {
    this.inputError = "";
    this.fetching = true;

    api
      .post<MicrosoftSheetsResponse>(
        `microsoft/${this.selectedDriveItem?.id}/sheets`,
        {
          providerAuthId: this.auth?.providerAuthId,
          spreadsheetName: this.selectedDriveItem?.name,
          driveId: this.selectedDriveItem?.driveId,
        }
      )
      .then((response) => {
        this.spreadsheet = response;
        this.selectedRangeId = this.spreadsheet?.sheets?.[0].rangeId ?? null;
      })
      .catch((errors) => {
        const code = errors?.[0]?.code;

        switch (code) {
          case "authFailure":
            this.showReauthorize = true;
            break;
          default:
            this.inputError = Array.isArray(errors)
              ? `Errors: ${errors.map((e: any) => e.message).join(". ")}`
              : errors.toString();
            break;
        }

        return Promise.reject(errors);
      })
      .finally(() => {
        this.fetching = false;
      });
  }

  get sheetOptions() {
    return (
      this.spreadsheet?.sheets.map((s) => {
        return {
          label: s.title,
          value: s.rangeId,
        };
      }) ?? []
    );
  }

  get loadingSheetDataMessage() {
    const sheet =
      this.spreadsheet?.sheets.find((s) => s.rangeId === this.selectedRangeId)
        ?.title ?? this.$t("MicrosoftSheets.selectedSheet").toString();
    return this.$t("MicrosoftSheets.loadingSheetData", { sheet });
  }

  get store() {
    return useConnectionSetupStore();
  }
}
</script>
