<template>
  <div class="h-full">
    <div
      class="p-4 pr-2 flex flex-col items-start space-y-4"
      style="height: calc(100% - 3rem)"
    >
      <ConnectionHeader @clearSelectedConnection="clearSelectedConnection" />

      <div class="flex justify-between w-full pr-3">
        <!-- Selected row Index control -->
        <RecordIndexControl v-if="!store.connectionIsTree && !store.isEmpty" />

        <!-- Control to toggle moderation status of each record: -->
        <ModerationToggle />
      </div>

      <!-- Connection's data: -->
      <RecordData v-if="!store.errorLoadingData" id="recordData" />

      <!-- Error message: -->
      <div
        v-else
        class="flex flex-col space-y-2 text-red-500 leading-tight text-sm"
        style="min-height: 75vh"
      >
        <div v-if="store.isModerationKeyError">
          Please select a column with unique values. Click the "Moderate" button
          to access this setting.
        </div>
        <div v-else v-t="'dataPanel.errorMessage'"></div>
      </div>
    </div>

    <UiBlocker :visible="store.isLoadingData" :isTransparent="true"
      >{{ $t("dataPanel.loading") }}...</UiBlocker
    >
  </div>
</template>

<script lang="ts">
import { useConnectionDataStore } from "@/stores/connectionData";
import { Component, Vue } from "vue-property-decorator";
import UiBlocker from "@/components/UiBlocker.vue";
import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import ConnectionHeader from "@/components/menus/data/ConnectionHeader.vue";
import ModerationToggle from "@/components/menus/data/ModerationToggle.vue";
import RecordIndexControl from "@/components/menus/data/RecordIndexControl.vue";
import RecordData from "@/components/menus/data/RecordData.vue";

@Component({
  components: {
    UiBlocker,
    Icon,
    IconSolid,
    ConnectionHeader,
    RecordIndexControl,
    RecordData,
    ModerationToggle,
  },
})
export default class RecordView extends Vue {
  get store() {
    return useConnectionDataStore();
  }

  clearSelectedConnection() {
    this.$emit("backClick");
  }
}
</script>

<style scoped lang="postcss">
::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(27, 27, 27, 0.7);
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}
</style>
