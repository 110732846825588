<template>
  <div
    class="flex items-center justify-around w-full outline-app-teal outline"
    style="height: 100%"
  >
    <div class="absolute w-32">
      <SelectMenu
        :placeholder="$t('selectionPrompt')"
        class="w-full"
        :value="value"
        :options="options"
        @input="handleInput({ target: { value: $event } })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import InputBase from "@/components/data/connections/manualSource/inputs/InputBase.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import { TranslateResult } from "vue-i18n";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { SchemaNode } from "@/types/data";

interface BooleanOption {
  value: boolean | null;
  label: TranslateResult;
}

@Component({
  components: {
    SelectMenu,
  },
})
export default class BooleanInput extends InputBase {
  get connectionStore() {
    return useConnectionEditorStore();
  }

  get allowEmptyValues() {
    const schemaNode = this.connectionStore.schema.find(
      (node: SchemaNode) => node.uuid === this.node.uuid
    );
    return !schemaNode?.isRequired;
  }

  mounted() {
    const isBooleanValue =
      this.node.value === true || this.node.value === false;
    if (!isBooleanValue && this.allowEmptyValues) {
      this.handleInput({ target: { value: null } });
    }
  }

  get options() {
    const options: BooleanOption[] = [
      { value: true, label: this.$t("booleanValues.true") },
      { value: false, label: this.$t("booleanValues.false") },
    ];
    if (this.allowEmptyValues) {
      options.unshift({
        value: null,
        label: this.$t("booleanValues.notSpecified"),
      });
    }
    return options;
  }
}
</script>
