<template>
  <div class="flex flex-col space-y-6">
    <div class="w-50 flex flex-col">
      <label v-t="'ProvideUrl.requestMethod'"></label>
      <SelectMenu
        class="w-full mt-1"
        :options="getMethods"
        :value="method?.value"
        @input="onSelectedMethodChanged"
      />
    </div>
    <div class="flex flex-col mt-3" v-if="method?.value === 'POST'">
      <label class="mb-1" v-t="'ProvideUrl.body'"></label>
      <textarea
        v-model="body.value"
        rows="5"
        cols="5"
        class="rounded-sm text-gray-900 p-3"
        placeholder="{ Code here... }"
        style="width: 420px"
      ></textarea>
    </div>
    <div class="flex flex-col mt-3">
      <label class="mb-1" v-t="'ProvideUrl.params'"></label>
      <div class="flex flex-row">
        <input
          ref="paramKey"
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
          placeholder="Key"
          v-model="param.key"
        />
        <input
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 w-50"
          placeholder="Value"
          v-model="param.value"
        />
        <button
          type="button"
          class="text-white inline-flex items-center px-5 py-2 ml-5 rounded-md text-sm cursor-pointer border bg-app-teal border-gray-500 hover:border-gray-900"
          @click="onParamAdd"
        >
          Add
        </button>
      </div>
      <div class="flex flex-row mt-3" v-for="(p, i) in params" :key="p.key">
        <input
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
          v-model="p.key"
          placeholder="Key"
        />
        <input
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 w-50"
          v-model="p.value"
          placeholder="Value"
        />
        <IconButton
          icon="Trash"
          class="icon w-7 h-7 cursor-pointer my-auto ml-5"
          @click="onParamDelete(i)"
        />
      </div>
    </div>
    <div class="flex flex-col mt-3">
      <label class="mb-1" v-t="'ProvideUrl.headers'"></label>
      <div class="flex flex-row">
        <input
          ref="headerKey"
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
          placeholder="Key"
          v-model="header.key"
        />
        <input
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 w-50"
          placeholder="Value"
          v-model="header.value"
        />
        <button
          type="button"
          class="text-white inline-flex items-center px-5 py-2 ml-5 rounded-md text-sm cursor-pointer border bg-app-teal border-gray-500 hover:border-gray-900"
          @click="onHeaderAdd"
        >
          Add
        </button>
      </div>
      <div class="flex flex-row mt-3" v-for="(h, i) in headers" :key="h.key">
        <input
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 mr-5 w-50"
          placeholder="Key"
          v-model="h.key"
        />
        <input
          type="text"
          class="rounded-sm border text-gray-900 px-2 py-1 w-50"
          placeholder="Value"
          v-model="h.value"
        />
        <IconButton
          icon="Trash"
          class="icon w-7 h-7 cursor-pointer my-auto ml-5"
          @click="onHeaderDelete(i)"
        />
      </div>
    </div>
    <div>
      <ButtonGradient @click="saveChanges" class="py-1 text-lg">
        <span v-t="'saveChanges'"></span>
      </ButtonGradient>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import ToggleInputLabelled from "@/components/inputs/ToggleInputLabelled.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import IconButton from "@/views/Dashboard/IconButton.vue";
import {
  ConnectionOption,
  DataConnection,
  ConnectionOptionType,
} from "@/types/data";
import LightningBoltIcon from "@/components/icons/LightningBoltIcon.vue";
import { useConnectionsStore } from "@/stores/connections";
import { useConnectionEditorStore } from "@/stores/connectionEditor";

@Component({
  components: {
    ButtonGradient,
    ToggleInputLabelled,
    SelectMenu,
    IconButton,
    LightningBoltIcon,
  },
})
export default class Configure extends Vue {
  newOptions: ConnectionOption[] = [];

  method: ConnectionOption = new ConnectionOption();
  headers: ConnectionOption[] = [];
  params: ConnectionOption[] = [];
  body: ConnectionOption = new ConnectionOption();

  param: ConnectionOption = this.newParam();
  header: ConnectionOption = this.newHeader();

  paramKey = "";
  paramValue = "";

  headerKey = "";
  headerValue = "";

  showValidationError = false;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get schema() {
    return this.connectionEditor.schema;
  }

  get connectionUse() {
    const store = useConnectionsStore();
    return store.connections.find(
      (c) => c.uuid === (this.connection?.uuid as string)
    ) as DataConnection;
  }

  mounted() {
    this.map();
  }

  map() {
    if (this.connection != null) {
      this.newOptions = this.connection?.options.map((o) => {
        return { ...o };
      });
      this.method =
        this.newOptions?.find(
          (o) => o.type === ConnectionOptionType[ConnectionOptionType.Method]
        ) || new ConnectionOption();
      this.body =
        this.newOptions?.find(
          (o) => o.type === ConnectionOptionType[ConnectionOptionType.Body]
        ) || new ConnectionOption();
      this.params =
        this.newOptions?.filter(
          (o) => o.type === ConnectionOptionType[ConnectionOptionType.Parameter]
        ) || [];
      this.headers =
        this.newOptions?.filter(
          (o) => o.type === ConnectionOptionType[ConnectionOptionType.Header]
        ) || [];
    }
  }

  get showError() {
    return this.showValidationError;
  }

  get getMethods() {
    return [
      { label: "GET", value: "GET" },
      { label: "POST", value: "POST" },
    ];
  }

  newParam() {
    return {
      key: "",
      value: "",
      type: ConnectionOptionType[ConnectionOptionType.Parameter],
      id: null,
    };
  }

  newHeader() {
    return {
      key: "",
      value: "",
      type: ConnectionOptionType[ConnectionOptionType.Header],
      id: null,
    };
  }

  onSelectedMethodChanged(value: any) {
    this.method.value = value;
    this.method.key = ConnectionOptionType[ConnectionOptionType.Method];
    this.method.type = ConnectionOptionType[ConnectionOptionType.Method];
  }

  onParamAdd() {
    if (
      this.params.findIndex((p) => p.key === this.param.key) != -1 ||
      (this.param.key === "" && this.param.value === "")
    )
      return;

    this.params.push(this.param);
    this.param = this.newParam();
    (this.$refs.paramKey as HTMLInputElement).focus();
  }

  onParamDelete(index: number) {
    this.params.splice(index, 1);
  }

  onHeaderAdd() {
    if (
      this.headers.findIndex((p) => p.key === this.header.key) != -1 ||
      (this.header.key === "" && this.header.value === "")
    )
      return;

    this.headers.push(this.header);
    this.header = this.newHeader();
    (this.$refs.headerKey as HTMLInputElement).focus();
  }

  onHeaderDelete(index: number) {
    this.headers.splice(index, 1);
  }

  async saveChanges() {
    if (this.param.key !== "" || this.param.value !== "") this.onParamAdd();

    if (this.header.key !== "" || this.header.value !== "") this.onHeaderAdd();

    this.newOptions = [this.method].concat(this.params).concat(this.headers);
    if (this.body?.value != null) {
      this.body.key = ConnectionOptionType[ConnectionOptionType.Body];
      this.body.type = ConnectionOptionType[ConnectionOptionType.Body];
      this.newOptions.push(this.body);
    }

    if (this.connection !== undefined && this.connection !== null) {
      const newConnection = {
        ...this.connection,
        options: this.newOptions,
      };

      this.connectionEditor.setConnection(newConnection);
      await this.connectionEditor.updateDataConnection(newConnection);

      // If we just set the connection and updated it,
      // why do we need to fetch it again?
      // await this.connectionStore.getConnection({
      //   dataConnectionUuid: this.connection.uuid,
      //   appUuid: this.$route.params.id,
      // });

      this.map();
    }
  }
}
</script>

<style scoped lang="postcss">
.validation-error {
  opacity: 0.75;
  background: rgba(246, 38, 38, 0.5);
  border: 1px solid #f62626;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
</style>
