<template>
  <div class="text-center">
    <div class="text-2xl" v-t="'SetupComplete.confirmCreated'"></div>

    <div v-if="isEditable">
      <router-link class="link" :to="editLink">Edit your data</router-link>
    </div>

    <div class="flex flex-col" v-if="isModeratable">
      <div>Need to moderate your data?</div>
      <router-link class="link" :to="moderationLink"
        >Set up moderation</router-link
      >

      <a
        href="https://screenfeed.com/support-articles/managing-your-data-connections"
        target="_blank"
        class="link"
        >Learn about moderation</a
      >
    </div>

    <div v-if="!inAppEditor" class="flex flex-col space-y-3 mt-4 items-center">
      <OutlineButton @click="clickReturn"
        >Return to connections list</OutlineButton
      >
      <OutlineButton @click="clickGoToConnection"
        >Go to your connection</OutlineButton
      >
    </div>
  </div>
</template>

<script lang="ts">
import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  APP_EDITOR_ROUTE_PATH,
} from "@/constants";
import { useConnectionSetupStore } from "@/stores/connectionSetup";
import { Component, Vue } from "vue-property-decorator";
import OutlineButton from "@/components/OutlineButton.vue";

@Component({ components: { OutlineButton } })
export default class CompleteDefault extends Vue {
  get store() {
    return useConnectionSetupStore();
  }

  created() {
    if (this.store.schemaType === "Tree") {
      /**
       * NOTE: For App editor.. it may be better to redirect back to app, but this is fine for now.
       */
      this.$router.push(this.editLink);
    }
  }

  get isEditable() {
    return this.store.canEditData;
  }

  get isModeratable() {
    return this.store.source?.canModerate ?? false;
  }

  get inAppEditor() {
    return this.$route.path.includes(APP_EDITOR_ROUTE_PATH);
  }

  get basePath() {
    return this.inAppEditor
      ? `${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`
      : `${APP_EDITOR_CONNECTION_ROUTE_PATH}`;
  }

  get editLink() {
    return {
      path: `/${this.basePath}/${this.store.connectionUuid}/data`,
    };
  }

  get moderationLink() {
    return `/${this.basePath}/${this.store.connectionUuid}/settings/moderation`;
  }

  clickReturn() {
    this.$router.push("/connections");
  }

  clickGoToConnection() {
    this.$router.push(`/${this.basePath}/${this.store.connectionUuid}`);
  }
}
</script>

<style scoped lang="postcss">
.link {
  @apply text-app-teal;
  text-decoration: underline;
}
</style>
