<template>
  <RecycleScroller
    ref="scroller"
    class="h-full flex-grow"
    :items="apps"
    :item-size="60"
    key-field="uuid"
    itemClass="px-8"
    v-slot="{ item: app, index }"
    @visible="onVisible"
  >
    <router-link
      :to="{ name: 'create-app' }"
      v-if="app.isNewAppButton"
      style="height: 60px"
      class="flex items-center justify-start h-full text-[13px] space-x-2 p-2 cursor-pointer hover:bg-app-overlays-25 pl-[1.8rem] opacity-90"
    >
      <Icon class="w-[20px] h-[20px]" name="PlusCircle" />
      <div>New App</div>
    </router-link>
    <div v-else>
      <div class="bg-gray-500 w-full h-[1px]"></div>
      <ListItem
        :isSelected="index === selectedIndex"
        :name="app.name"
        :uuid="app.uuid"
        :image="app.previewUrl"
        :width="app.width"
        :height="app.height"
        :isPublished="app.publishMeta.isPublished"
      />
    </div>
  </RecycleScroller>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { RecycleScroller } from "vue-virtual-scroller";

import { EventBus } from "@/eventbus";
import Icon from "@/components/icons/Icon.vue";
import ListItem from "./ListItem.vue";
import { useAppsStore } from "@/stores/apps";

@Component({
  components: {
    RecycleScroller,
    ListItem,
    Icon,
  },
})
export default class AppsListView extends Vue {
  get store() {
    return useAppsStore();
  }

  get apps() {
    const newAppButtonInfo = {
      uuid: "dummyNewApp",
      publishMeta: {},
      isNewAppButton: true,
    };

    return [newAppButtonInfo, ...this.store.matchingApps];
  }

  get selectedIndex() {
    return this.store.selectedIndex;
  }

  readonly itemSize = 60;

  onVisible() {
    EventBus.emit("APPS_LIST_VIEW_VISIBLE", "");
  }

  @Watch("selectedIndex")
  scrollTo(newIndex: number, oldIndex: number | undefined) {
    const old = typeof oldIndex === "undefined" ? -1 : oldIndex;
    const direction = newIndex > old ? "down" : "up";

    this.$nextTick(() => {
      const scroller = this.$refs.scroller as any;

      let idx = newIndex;
      if (idx > 3) {
        idx -= 3;
        scroller.scrollToPosition(idx * this.itemSize);
      }

      if (direction === "up" && newIndex < 4) {
        scroller.scrollToPosition(0);
      }
    });
  }
}
</script>

<style lang="postcss" scoped>
.vue-recycle-scroller__item-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
