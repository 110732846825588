import { RequestClient } from "@uppy/companion-client";

export default class CustomRequestClient extends RequestClient {
  constructor(uppy, opts) {
    super(uppy, opts);

    // Need to override the allowedHeaders to include the 'authorization' header
    super.allowedHeaders = [
      "accept",
      "content-type",
      "authorization",
      "uppy-auth-token",
    ];
  }
}
