<template>
  <div class="relative flex-grow text-white">
    <div class="absolute inset-0 p-8 overflow-auto data-grid-container">
      <div v-if="connectionsExist && !hideExistingConnections" class="mb-6">
        <div class="text-2xl mb-4">Choose an existing Connection</div>

        <div class="relative grid grid-cols-3 gap-4" style="min-height: 156px">
          <router-link
            :to="c.to"
            v-for="c in connectionsRender"
            :key="c.uuid"
            class="source-link"
          >
            <img class="w-10 h-10 flex-none" :src="c.iconUrl" />

            <div class="flex-grow">
              <div class="font-bold">{{ c.name }}</div>
              <div class="text-sm text-gray-400">
                {{ renderConnectionInfo(c) }}
              </div>
            </div>
          </router-link>

          <div
            v-if="showMoreConnectionsButton"
            class="source-link justify-center font-bold cursor-pointer"
            @click="toggleShowMoreConnections"
          >
            <div v-if="!showAllConnections">See All</div>
            <div v-else>See Less</div>
          </div>

          <UiBlocker class="rounded-lg" :visible="loadingConnections">
            Loading Connections
          </UiBlocker>
        </div>
      </div>

      <FatalServerError
        v-if="showServerError"
        v-t="'errors.fetchConnectionsError'"
        class="mb-6"
      >
      </FatalServerError>

      <div v-if="!hideNewConnectionOptions">
        <div class="text-2xl mb-4 mt-4">Create a Connection</div>
        <SourceChooser
          :type="type"
          :isReplacing="isReplacing"
          :isSeekingCalendar="seekingCalendar"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import SourceChooser from "@/components/data/connections/setup/SourceChooser.vue";
import { SchemaType, DataConnection } from "@/types/data";
import UiBlocker from "@/components/UiBlocker.vue";
import { APP_EDITOR_CONNECTION_ROUTE_PATH } from "@/constants";
import { DateTime } from "luxon";
import { getQueryValue } from "@/utils";
import FatalServerError from "@/components/FatalServerError.vue";
import { logger } from "@core/logger";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionsStore } from "@/stores/connections";

/*
 * When user selects existing connection..
 *  - If they are selecting a Collection, then show Preview view.
 *  - If they are selecting a non-Collection, then show Select Node view.
 *
 * We cache the connections here so we don't have to keep fetching when user goes back from preview.
 */

// IDEA: Add cloneConnection as option for existing connections
// TODO: Show warning if using more than 5 connections in app.

@Component({
  components: {
    Modal,
    SourceChooser,
    ButtonGradient,
    UiBlocker,
    FatalServerError,
  },
})
export default class ChooseConnection extends Vue {
  @Prop({ type: Boolean, default: false }) isReplacing: boolean;

  get appEditor() {
    return useAppEditorStore();
  }

  get cachedConnections() {
    return this.appEditor.cachedConnections;
  }

  connections: DataConnection[] = [];

  loadingConnections = false;
  showAllConnections = false;

  connectionsExist = true;
  showServerError = false;

  get type() {
    return getQueryValue(this.$route.query.type);
  }

  mounted() {
    console.log("Mount ChooseConnection");
  }

  get hideExistingConnections() {
    return getQueryValue(this.$route.query.hideExistingConnections);
  }

  // Do not allow user to create a new connection when they are creating a scalar data binding:
  // (This is done in order to maintain consistency and avoid having to deal with allowing the user to enter new data for an editable connection.)
  get hideNewConnectionOptions() {
    return getQueryValue(this.$route.query.bindingType) === "Scalar";
  }

  get seekingCollection() {
    return this.type === "Collection" || this.isReplacing; // is this OR needed?
  }

  get seekingCalendar() {
    return this.type === "Calendar";
  }

  renderDate(isoString: string) {
    return DateTime.fromISO(isoString).toLocaleString();
  }

  renderConnectionInfo(c: DataConnection) {
    if (c.modifiedAt) {
      return `Last modified on ${this.renderDate(c.modifiedAt)}`;
    }
    return `Created on ${this.renderDate(c.createdOn)}`;
  }

  get shouldRefreshConnections() {
    if (this.cachedConnections.length === 0) return true;

    if (this.$route.query.shouldRefresh === "false") {
      return false;
    }
    return true;
  }

  toggleShowMoreConnections() {
    this.showAllConnections = !this.showAllConnections;
  }

  async created() {
    this.loadConnections();
  }

  async loadConnections() {
    /**
     * Build list of available connections to use.
     * Concatenates all shared connections attached to account, and any connections used in this app.
     */
    this.loadingConnections = true;
    this.showServerError = false;

    if (this.shouldRefreshConnections) {
      let payload: {
        appUuid?: string;
        onlyCollections?: boolean;
        includeSchemaTypes?: SchemaType[];
        excludeSchemaTypes?: SchemaType[];
      } = {
        appUuid: this.$route.params.id,
      };
      if (this.seekingCalendar) payload.includeSchemaTypes = ["Calendar"];
      else if (this.seekingCollection) payload.onlyCollections = true;
      else payload.excludeSchemaTypes = ["Calendar"]; // Hide calendar connections when creating scalar binding

      try {
        const store = useConnectionsStore();
        this.connections = await store.getConnections(payload);
      } catch (err: any) {
        this.showServerError = true;
        logger.track(err);
      } finally {
        this.appEditor.setCachedConnections([...this.connections]);
      }
    } else {
      this.connections = this.cachedConnections;
    }

    if (this.connections.length === 0) {
      this.connectionsExist = false;
    }

    this.loadingConnections = false;
  }

  getPath(c: DataConnection) {
    const basePath = `/app/edit/${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`;

    if (this.isReplacing) {
      return `${basePath}/${this.$route.params.connectionUuid}/replace/${c.uuid}/preview`;
    }

    return this.seekingCollection || this.seekingCalendar
      ? `${basePath}/new/${c.uuid}/preview`
      : `${basePath}/new/${c.uuid}/select`;
  }

  get showMoreConnectionsButton() {
    return this.connections.length > 6;
  }

  get connectionsRender() {
    let connections = this.connections;

    if (this.isReplacing) {
      // Filter out the connection that is being replaced...
      const id = this.$route.params.connectionUuid;
      connections = connections.filter((c) => c.uuid !== id);
    }

    if (this.showMoreConnectionsButton && !this.showAllConnections) {
      connections = connections.slice(0, 5);
    }
    return connections.map((c) => {
      return {
        ...c,
        to: { path: this.getPath(c), query: { ...this.$route.query } },
      };
    });
  }
}
</script>

<style scoped lang="postcss">
.source-link {
  @apply flex items-center space-x-5 px-5 py-3 rounded-md;
  @apply bg-gradient-to-b from-gray-800 to-gray-800 border border-gray-900;
  @apply hover:border-black hover:shadow-lg hover:from-app-dark2 hover:to-gray-800;
}
</style>
