<template>
  <EditorRadioInput
    :value="value"
    :options="options"
    @input="$emit('input', $event)"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import EditorRadioInput from "@/components/inputs/EditorRadioInput.vue";

@Component({
  components: {
    EditorRadioInput,
  },
})
export default class TextTransformInput extends Vue {
  @Prop(String) value: string;

  get options() {
    return [
      {
        label: "TextTransformOptions.uppercase",
        value: "uppercase",
      },
      {
        label: "TextTransformOptions.capitalize",
        value: "capitalize",
      },
      {
        label: "TextTransformOptions.lowercase",
        value: "lowercase",
      },
    ];
  }
}
</script>
