<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseWidget extends Vue {
  @Prop(String) readonly wid: string;
  @Prop(String) readonly parentId: string;
  @Prop(String) readonly type: string;
  @Prop(Boolean) readonly locked: boolean;
}
</script>
