<template>
  <div class="relative">
    <SelectAuthorization
      authProvider="Microsoft"
      dataProviderId="MicrosoftCalendar"
      :showReauthorize="showReauthorize"
      :selectedAuthId="auth?.providerAuthId"
      @select="onAccountSelect"
    />

    <portal to="setupWizardUiBlocker">
      <UiBlocker v-if="loadingMessage">
        <div class="mt-4 text-xl text-white" v-text="loadingMessage"></div>
      </UiBlocker>
    </portal>

    <portal to="setupWizardNext">
      <FormButton
        class="dark-form-focus"
        :disabled="!showCalendars"
        @click="onCalendarSelected"
        v-t="'continue'"
      />
    </portal>

    <div class="py-6 space-y-6" v-if="showCalendars">
      <div v-t="'MicrosoftCalendar.selectCalendar'" class="text-xl"></div>
      <TabInput v-model="selectedCalendarId" :options="calendarOptions" />
    </div>

    <div class="py-6 space-y-6" v-if="!hasCalendars">
      <div v-t="'MicrosoftCalendar.noCalendars'" class="text-xl"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api/backend";
import { ProviderAuthorization, SourceDataSchema } from "@/types/data";
import { MicrosoftCalendar, MicrosoftCalendarList } from "@/types/microsoft";

import SelectAuthorization from "@/components/data/connections/setup/provide/SelectAuthorization.vue";
import FatalServerError from "@/components/FatalServerError.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import FormButton from "@/components/FormButton.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import TabInput from "@/components/inputs/TabInput.vue";
import Icon from "@/components/icons/Icon.vue";
import { SourceDataInfo } from "@/stores/connectionSetup";

@Component({
  components: {
    FatalServerError,
    SelectAuthorization,
    UiBlocker,
    FormButton,
    SelectMenu,
    TabInput,
    Icon,
  },
})
export default class ProvideMicrosoftCalendar extends Vue {
  showReauthorize = false;
  auth: ProviderAuthorization | null = null;

  hasCalendars = true;
  fetchingCalendars = false;
  fetchCalendarError: string | null = null;
  calendars: MicrosoftCalendar[] | null = null;
  selectedCalendarId: string | null = null;

  fetchingEvents = false;

  onReauthorize() {
    this.showReauthorize = false;
    this.fetchCalendars();
  }

  onAccountSelect(auth: ProviderAuthorization | null) {
    this.auth = auth;
  }

  @Watch("auth.providerAuthId")
  onAuthChanged(providerAuthId?: number) {
    if (typeof providerAuthId === "number") {
      this.fetchCalendars();
    }
  }

  fetchCalendars() {
    if (this.auth === null) {
      return;
    }
    const resultCount = 50;
    const authId = this.auth.providerAuthId;
    const url = `microsoft/calendars?providerAuthId=${authId}&resultsPerPage=${resultCount}`;
    this.hasCalendars = true;
    this.fetchingCalendars = true;
    return api
      .get<MicrosoftCalendarList>(url)
      .then((response) => {
        this.calendars = response.calendars;
        if (this.calendars == null || this.calendars.length == 0)
          this.hasCalendars = false;
      })
      .catch((errors) => {
        const code = errors?.[0]?.code;

        switch (code) {
          case "authFailure":
            this.showReauthorize = true;
            break;
          default:
            this.fetchCalendarError = Array.isArray(errors)
              ? `Errors: ${errors.map((e: any) => e.message).join(". ")}`
              : errors.toString();
            break;
        }

        return Promise.reject(errors);
      })
      .finally(() => {
        this.fetchingCalendars = false;
      });
  }

  onCalendarSelected() {
    if (this.selectedCalendarId !== null) {
      const calendar = this.calendars?.find(
        (c) => c.id === this.selectedCalendarId
      );
      if (calendar) {
        const payload = {
          calendarId: calendar.id,
          providerAuthId: this.auth?.providerAuthId,
        };

        this.fetchingEvents = true;
        return api
          .post<SourceDataSchema>(`microsoft/calendarevents`, payload)
          .then((response) => {
            this.$emit("complete", {
              name: this.selectedCalendarName,
              type: "Calendar",
              schema: response,
              providerAuthId: payload.providerAuthId,
            } as SourceDataInfo);
          })
          .finally(() => {
            this.fetchingEvents = false;
          });
      }
    }
  }

  get calendarOptions() {
    if (this.showCalendars)
      return this.calendars?.map((c) => {
        return {
          label: c.summary === this.auth?.username ? "Default" : c.summary,
          value: c.id,
        };
      });
    return undefined;
  }

  get showCalendars() {
    return this.calendars !== null && this.calendars.length > 0;
  }

  get selectedCalendarName() {
    return this.calendars?.find((c) => c.id === this.selectedCalendarId)
      ?.summary;
  }

  get loadingMessage() {
    if (this.fetchingCalendars) {
      return this.$t("MicrosoftCalendar.loadingCalendars").toString();
    }

    if (this.fetchingEvents) {
      return this.$t("MicrosoftCalendar.loadingEvents").toString();
    }

    return undefined;
  }
}
</script>
