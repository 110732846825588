<template>
  <div
    ref="copied"
    class="opacity-0 pointer-events-none fixed top-0 left-1/2 px-4 py-2 flex items-center justify-center space-x-2 rounded-br-md rounded-bl-md border border-app-green bg-app-green shadow-xl text-xl z-40"
  >
    <IconSolid name="Checkmark" class="h-6 w-6" />
    <span v-t="'appList.urlCopied'"></span>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import gsap from "gsap";
import IconSolid from "@/components/icons/IconSolid.vue";

@Component({
  components: {
    IconSolid,
  },
})
export default class ConfirmCopy extends Vue {
  confirm() {
    const div = this.$refs.copied as HTMLDivElement;

    const tl = gsap.timeline();
    tl.fromTo(
      div,
      {
        transformOrigin: "top center",
        opacity: 0,
        scale: 0.8,
        y: "-100%",
        duration: 0.2,
        ease: "power2.out",
      },
      {
        transformOrigin: "top center",
        opacity: 1,
        scale: 1,
        y: 0,
        duration: 0.2,
        ease: "power2.out",
      }
    );
    tl.to(div, {
      delay: 0.9,
      opacity: 1,
      scale: 0.8,
      y: "-100%",
      duration: 0.2,
      ease: "power2.out",
    });
  }
}
</script>
