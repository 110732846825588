import {
  ComponentOptions,
  DefaultComponentOptions,
} from "@/components/widgets/ComponentOptions";
import { TransformOptions } from "@/components/widgets/TransformOptions";
import {
  BorderOptions,
  DefaultBorderOptions,
} from "@/components/widgets/BorderOptions";
import {
  BackgroundOptions,
  DefaultBackgroundOptions,
} from "@/components/widgets/BackgroundOptions";
import { makeId } from "@/utils";
import { DEFAULT_LAYER_NAMES } from "@/constants";

export interface ProgressBarOptions
  extends ComponentOptions,
    TransformOptions,
    BorderOptions,
    BackgroundOptions {
  w: number;
  h: number;
  bgFill: string;
  fgFill: string;
  stroke: string;
  strokeWidth: number;
  fgPadding: number;
  borderRadius: number;
  showGradient: boolean;
  barGradientStart: string;
  barGradientEnd: string;
  showDivider: boolean;
  dividerFill: string;
  dividerStroke: string;
  dividerStrokeWidth: number;
  dividerHeight: number;
  dividerWidth: number;
  bgInnerRadius: number;
  bgOuterRadius: number;
  fgInnerRadius: number;
  fgOuterRadius: number;

  minValue: number;
  maxValue: number;
  currentValue: number;
}

export const defaultProgressBarOptions = {
  w: 100,
  h: 400,
  minValue: 0,
  maxValue: 100,
  currentValue: 65,
  bgFill: "rgba(187, 247, 208, 100)",
  fgFill: "rgba(33, 196, 93, 100)",
  stroke: "rgba(0, 0, 0, 100)",
  strokeWidth: 0,
  fgPadding: 0,
  borderRadius: 0,
  showGradient: false,
  barGradientStart: "rgba(0, 0, 0, 0)",
  barGradientEnd: "rgba(17, 48, 84, 100)",
  showDivider: true,
  dividerFill: "rgba(255, 255, 255, 100)",
  dividerStroke: "rgba(0, 0, 0, 100)",
  dividerStrokeWidth: 0,
  dividerHeight: 1,
  dividerWidth: 100,
};

const defaultOptions = {
  ...DefaultComponentOptions,
  ...DefaultBorderOptions,
  ...DefaultBackgroundOptions,
  ...defaultProgressBarOptions,
  w: 540,
  h: 360,
  x: 0,
  y: 0,
  z: 0,
  angle: 90,
  scaleX: 1,
  scaleY: 1,
  canScaleY: true,
  canScaleX: true,
  lockAspect: false,
};

const CreateProgressBar = (options: Partial<ProgressBarOptions>) => {
  return Object.assign({}, defaultOptions, options, {
    type: "ProgressBar",
    wid: makeId(6),
    name: DEFAULT_LAYER_NAMES.ProgressBar,
  });
};

export default CreateProgressBar;
