<template>
  <div @mouseenter="hover = true" @mouseleave="hover = false">
    <Modal size="any" v-if="showAddFont" v-on:close="showAddFont = false">
      <div class="flex flex-col p-6 space-y-2">
        <div class="text-xl flex space-x-1">
          <div v-t="'textEditor.enterFontNameOne'"></div>
          <a
            class="text-blue-500 underline"
            href="https://fonts.google.com/"
            target="_blank"
          >
            {{ $t("textEditor.enterFontNameTwo") }}</a
          >
        </div>
        <form @submit.prevent="searchFont" class="w-full flex space-x-3">
          <input
            v-model.trim="newFont"
            type="text"
            ref="newFont"
            placeholder="e.g. Merriweather"
            class="w-72 focus:outline-none px-1 rounded border-gray-200 border text-black"
            @input="errorLoadingFont = ''"
            @change="errorLoadingFont = ''"
          />

          <ButtonGradient type="submit">
            <span v-t="'textEditor.search'"></span>
          </ButtonGradient>

          <OutlineButton @click="closeAddFont"
            ><div v-t="'cancel'"></div
          ></OutlineButton>
        </form>
        <div
          v-if="addingFont"
          class="text-xs text-app-green"
          v-t="'textEditor.addingFont'"
        ></div>
        <div
          v-if="!addingFont && errorLoadingFont"
          class="text-xs text-red-500"
        >
          {{ errorLoadingFont }} &nbsp;
        </div>
      </div>
    </Modal>

    <div class="relative w-full select-none" ref="fontFamilyPicker">
      <div
        v-if="showFontPicker"
        class="absolute bg-white z-50 rounded-md py-1 ring-1 ring-black ring-opacity-5 hover:shadow-lg w-48 top-full mt-1"
      >
        <div class="flex flex-col">
          <div class="flex-grow h-72 overflow-y-auto">
            <div
              v-for="(f, idx) in fontsDisplay"
              :key="f.family + idx"
              class="w-full px-3 py-1 hover:bg-app-teal hover:text-white relative checkmark-parent"
              @mousedown="updateFontInternal(f.family)"
            >
              <span :style="{ fontFamily: f.family }">{{ f.family }}</span>

              <span
                v-if="f.family === value"
                class="absolute inset-y-0 right-0 flex items-center pr-2 checkmark"
              >
                <IconSolid name="Checkmark" class="h-5 w-5" />
              </span>
              <div
                class="absolute bottom-0 w-44 h-[1px] bg-gray-900"
                v-if="f.lineBeneath"
              ></div>
            </div>
          </div>
          <div
            class="border-t px-3 py-1 hover:bg-gray-100 cursor-pointer"
            @click="openAddFont"
            v-t="'addFont'"
          ></div>
        </div>
      </div>
      <div
        class="focus:bg-white hover:bg-white relative w-full hover:ring-1 rounded-sm pl-2 pr-9 py-1 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-app-teal"
        :style="{ fontFamily: fontFamilyValue }"
        :class="{
          'cursor-not-allowed opacity-50': disabled,
          'border-app-teal ring-app-teal ring-1 bg-white': showFontPicker,
          'hover:ring-gray-300': !showFontPicker,
        }"
        @click="toggleFontPicker"
        ref="fontFamilyDisplay"
      >
        <span class="block truncate">{{ fontFamilyDisplay }}</span>
        <Icon
          v-if="hover || showFontPicker"
          class="w-6 h-6 absolute top-1 right-0 pr-2 pointer-events-none text-gray-400"
          :name="icon"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { loadFonts } from "@/fonts/loadFonts";
import { KeyCodes } from "@/keycodes";

import Icon from "@/components/icons/Icon.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import Modal from "@/components/Modal.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import { FontAssetInfo } from "@/types/bundleTypes";
import { getGoogleFont } from "@/fonts/searchFonts";
import { getFontOptions } from "@/fonts/fontOptions";
import { Font, FontVariant } from "@/types";
import { useAppEditorStore } from "@/stores/appEditor";
import { useMainAppStore } from "@/stores/mainApp";

// TODO: Would be  nice to scroll to selected font on open

// Change arrows
// and see fontsizeinput

// TODO: Need to make height less if won't fit on screen
// You know actually...as long as it's only like 30vh, this is almost never going to happen...still should do
// TODO: scroll to proper position when open
// https://stackoverflow.com/questions/635706/how-to-scroll-to-an-element-inside-a-div#:~:text=You%20need%20to%20get%20the,')%3B%20var%20topPos%20%3D%20myElement.

interface FontDisplay extends Font {
  lineBeneath?: boolean;
}
@Component({
  components: {
    Modal,
    Icon,
    IconSolid,
    ButtonGradient,
    OutlineButton,
  },
})
export default class FontFamilyInput extends Vue {
  @Prop(String) value: string;
  @Prop(Boolean) disabled: boolean;

  get appEditor() {
    return useAppEditorStore();
  }

  get customFonts() {
    return this.appEditor.customFonts;
  }

  showFontPicker = false;
  showAddFont = false;
  newFont = "";
  errorLoadingFont = "";
  hover = false;
  addingFont = false;
  fontsReady = false;
  fontOptions: FontDisplay[] = [];

  created() {
    this.loadFonts();
  }

  get mainAppStore() {
    return useMainAppStore();
  }

  get fontsDisplay(): FontDisplay[] {
    return [
      ...(this.mainAppStore.recentlyUsedFonts ?? [])
        .map((f: string, idx: number) => ({
          family: f,
          variants: [{ style: "normal", weight: 400 }] as FontVariant[],
          lineBeneath: idx === 0,
        }))
        .reverse(),
      ...this.fontOptions,
    ];
  }

  loadFonts() {
    getFontOptions(this.customFonts).then((fonts) => {
      this.fontOptions = fonts;
      this.fontsReady = true;
    });
  }

  @Watch("customFonts")
  onCustomFontsChanged() {
    this.loadFonts();
  }

  get fontFamilyValue() {
    return this.value;
  }

  get fontFamilyDisplay() {
    return this.value || "";
  }

  get icon() {
    if (this.showFontPicker) {
      return "ChevronUp";
    } else {
      return "ChevronDown";
    }
  }

  closeMenu(ev: MouseEvent) {
    if (!this.$el.contains(ev.target as Node)) {
      this.showFontPicker = false;
    }
  }

  closeAddFont() {
    this.showAddFont = false;
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.code === KeyCodes.ESCAPE) {
      console.log("escape");
      this.closeAddFont();
    }
  }

  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("click", this.closeMenu);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("click", this.closeMenu);
  }

  async searchFont() {
    if (this.addingFont) {
      return;
    }

    this.errorLoadingFont = "";

    if (
      this.fontOptions.find(
        (font) => font.family.toLowerCase() === this.newFont.toLowerCase()
      )
    ) {
      this.errorLoadingFont = this.$t(
        "textEditor.fontAlreadyAvailable"
      ).toString();
    } else {
      getGoogleFont(this.newFont)
        .then((font) => {
          return font.variants.map((v) => {
            return {
              source: "Google",
              family: font.family,
              weight: v.weight,
              style: v.style,
            };
          }) as FontAssetInfo[];
        })
        .then((assets) => {
          this.addingFont = true;
          return loadFonts(assets, `custom-${this.newFont}`)
            .then(() => {
              this.appEditor.addCustomFont(assets[0].family);
              this.updateFontInternal(assets[0].family);
              this.showAddFont = false;
            })
            .catch(() => {
              this.addingFont = false;
              this.errorLoadingFont = this.$t("textEditor.fontFailedToLoad", {
                family: assets[0].family,
              }).toString();
            });
        })
        .catch(() => {
          this.addingFont = false;
          this.errorLoadingFont = this.$t("textEditor.noMatchError").toString();
        });
    }
  }

  openAddFont() {
    this.showFontPicker = false;
    this.errorLoadingFont = "";
    this.showAddFont = true;
    this.newFont = "";
    this.$nextTick(() => {
      (this.$refs.newFont as HTMLElement).focus();
    });
  }

  toggleFontPicker() {
    if (!this.disabled) {
      this.showFontPicker = !this.showFontPicker;
    }
  }

  // Or.. just accept propertyName as optional prop...such as "locationTextStyles"
  updateFontInternal(value: string) {
    this.$emit("input", value);
    this.showFontPicker = false;
    this.hover = false;
  }
}
</script>

<style lang="postcss" scoped>
.highZ {
  z-index: 1000000;
  position: relative;
}

.checkmark {
  @apply text-app-teal;
}
.checkmark-parent:hover .checkmark {
  @apply text-white;
}
</style>
