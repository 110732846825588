<template>
  <div
    class="relative flex-grow flex min-h-0 flex-col h-full"
    :class="{ 'p-8': isReplacing }"
  >
    <SetupNavigation />

    <div
      class="h-full overflow-y-auto dark-scrollbar flex-grow flex flex-col border-b border-l border-r border-gray-900 shadow-xl rounded-br-lg rounded-bl-lg bg-gray-700"
    >
      <div
        v-if="showAlreadyCompleteMessage"
        class="mt-4 mx-24 px-4 py-2 rounded bg-app-green/10 border border-app-green"
      >
        <div class="font-bold text-lg" v-t="'SetupComplete.backWarning1'"></div>
        <div class="space-x-1">
          <span v-t="'SetupComplete.backWarning2'"></span>
          <router-link
            class="text-app-teal underline"
            :to="connectionSettingsRoute"
            v-t="'SetupComplete.backWarning3'"
          >
          </router-link>
          <span>.</span>
        </div>
      </div>

      <div class="relative w-full flex-grow px-6 py-4 justify-center">
        <router-view />
        <FatalServerError
          v-if="showFatalError"
          class="absolute z-10 p-16 inset-0 flex bg-gray-700 flex-col items-center"
        />
      </div>

      <div
        v-if="showFooterNav"
        class="flex flex-grow-0 border-t border-app-dark3 px-6 py-4"
        :class="{
          'justify-between': showCancelButton,
          'justify-end': !showCancelButton,
        }"
      >
        <OutlineButton @click="clickCancel" v-if="showCancelButton"
          >Cancel</OutlineButton
        >

        <div class="flex space-x-3">
          <router-link
            class="rounded flex items-center text-white border select-none dark-form-focus border-gray-500 hover:border-gray-400"
            :to="backLink"
            v-if="showBackButton"
            ><span class="px-3" v-t="'back'"></span
          ></router-link>

          <portal-target name="setupWizardSelect" slim></portal-target>

          <portal-target name="setupWizardNext" slim></portal-target>
        </div>
      </div>
      <portal-target name="setupWizardUiBlocker" slim></portal-target>
    </div>

    <UiBlocker :visible="loadingState">
      <div v-t="'ConnectionSetup.loadingState'"></div>
    </UiBlocker>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import { useConnectionSetupStore } from "@/stores/connectionSetup";

import OutlineButton from "@/components/OutlineButton.vue";
import UiBlocker from "@/components/UiBlocker.vue";
import SetupNavigation from "@/components/data/connections/setup/SetupNavigation.vue";
import FatalServerError from "@/components/FatalServerError.vue";
import { ConnectionSourceId } from "@/types/ConnectionSources";
import { useConnectionSourceStore } from "@/stores/connectionSource";
import { getQueryValue } from "@/utils";
import { Location } from "vue-router";

import { mixins } from "vue-class-component";
import RemapFilterConditionColumnsMixin from "@/components/RemapFilterConditionColumnsMixin";

import {
  APP_EDITOR_CONNECTION_ROUTE_PATH,
  APP_EDITOR_ROUTE_PATH,
} from "@/constants";

@Component({
  components: {
    UiBlocker,
    SetupNavigation,
    OutlineButton,
    FatalServerError,
  },
})
export default class ConnectionSetup extends mixins(
  RemapFilterConditionColumnsMixin
) {
  @Prop(String) sourceId: ConnectionSourceId;
  @Prop(String) appId?: string;
  @Prop({ type: Boolean, default: false }) isReplacing: boolean;

  sourceStore = useConnectionSourceStore();
  loadingState = false;
  showAlreadyCompleteMessage = false;

  get useFullHeight() {
    return this.store.step === "Selection";
  }

  get minHeightStyles() {
    let value = "240px";
    if (this.store.step === "Schema" && this.store.schemaType === "Tree") {
      value = "600px";
    }
    return { minHeight: value };
  }

  get store() {
    return useConnectionSetupStore();
  }

  get source() {
    return this.sourceStore.getSourceByName(this.sourceId);
  }

  get showBackButton() {
    return typeof this.backLink !== "undefined";
  }

  get inAppEditor() {
    return this.$route.path.includes(APP_EDITOR_ROUTE_PATH);
  }

  get showCancelButton() {
    return !this.inAppEditor;
  }

  clickCancel() {
    this.$router.push("/connections");
  }

  get showFatalError() {
    return this.store.showFatalError;
  }

  get showFooterNav() {
    // IF the wizard is complete, hide the footer
    if (this.store.step === "Complete") {
      return false;
    }

    // Special case. No need for a "next" button on Fileupload
    if (this.store.sourceId === "FileUpload" && this.store.step === "Provide") {
      return false;
    }
    return true;
  }

  get connectionSettingsRoute() {
    const id = this.$route.query.c;
    if (id) {
      const basePath = this.$route.path.includes(APP_EDITOR_ROUTE_PATH)
        ? `${APP_EDITOR_ROUTE_PATH}/${this.$route.params.id}/${APP_EDITOR_CONNECTION_ROUTE_PATH}`
        : `${APP_EDITOR_CONNECTION_ROUTE_PATH}`;

      return { path: `/${basePath}/${id}/settings` };
    }
    return {};
  }

  get backLink(): Location | undefined {
    const step = this.store.previousStep;
    if (typeof step !== "undefined") {
      const query = this.$route.query;

      if (step.id === "Provide") {
        // If redirecting to Provide step, remove connectionUuid
        delete query.c;
      }

      return { path: step.id.toLowerCase(), query };
    }
    return undefined;
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    if (this.store.isCompleted && !to.fullPath.includes("complete")) {
      this.showAlreadyCompleteMessage = true;
      return next(false);
    }

    next();
  }

  created() {
    this.initState();

    // Allows store to determine whether remap is requirerd (based on filters/conditions)
    this.findAndSetNodesRequiringRemap();
  }

  @Watch("$route.fullPath")
  bindQueryValues() {
    this.store.sourceId = this.sourceId;
    this.store.appUuid = this.$route.params.id;
    this.store.connectionUuid = getQueryValue(this.$route.query?.c);
    this.store.widgetId = getQueryValue(this.$route.query?.widgetId);
    this.store.propertyName = getQueryValue(this.$route.query?.property);

    const type: string | null = getQueryValue(this.$route.query?.type);

    if (type === "Collection" || type === "Calendar") {
      this.store.propertyType = type;
      this.store.isCollection = true;
    } else if (this.store.shouldCreateTreeConnection) {
      this.store.propertyType = type;
      this.store.isCollection = false;
    } else {
      this.store.isCollection = true;
    }
  }

  initState() {
    const source = this.sourceStore.getSourceByName(this.sourceId);
    if (source === undefined)
      throw new Error(
        `This is not a valid connection source. ${this.sourceId}`
      );

    this.store.$reset();
    this.store.step = source.setupSteps[0];

    /**
     * Check url to see whether someone refreshed their browser during setup.
     * If so, they'll need to start over. Our method for storing setup state
     * in localStorage is too buggy for now.
     */
    const pathParts = this.$route.path.toLowerCase().split("/");
    if (!pathParts.includes(this.store.step.toLowerCase())) {
      /**
       * The user must have refreshed the page on a subsequent step during setup,
       * or arrived here via bookmark. We need to redirect them to the first step.
       */

      this.$router.push({
        path: this.store.step.toLowerCase(),
        query: { ...this.$route.query },
      });
      return;
    }

    if (this.isReplacing) {
      this.store.isReplacing = this.isReplacing;
      this.store.connectionUuidToBeReplaced = this.$route.params.connectionUuid;
      if (typeof this.store.connectionUuidToBeReplaced === "undefined")
        throw new Error(
          "Connection uuid to be replaced is not present in the route params"
        );
    }

    if (source.provisionMethod === "None") {
      this.store.schemaType = "Tabular";
      this.store.canEditData = true;
    }

    // Rebind query values (in case it the watcher fired first)
    this.bindQueryValues();
  }
}
</script>
