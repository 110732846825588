<template>
  <div class="flex flex-col space-y-6">
    <div class="flex items-center space-x-4">
      <img class="w-12 h-12" :src="connection?.iconUrl" />
      <div class="text-2xl" :title="connection?.name">
        {{ connection?.name }}
      </div>
    </div>

    <div class="flex flex-col" v-if="showFirstRowHeaderToggle">
      <div class="text-lg font-bold" v-t="'schemaEditor.headerRow'"></div>
      <ToggleInputLabelled v-model="isFirstRowHeader" :dark="true" />
    </div>
    <table ref="table">
      <thead>
        <th v-t="'schemaEditor.columnName'"></th>
        <th v-t="'schemaEditor.columnType'"></th>
        <th v-t="'schemaEditor.allowEmptyValues'"></th>
        <th></th>
      </thead>

      <tbody>
        <!-- Existing schema columns: -->
        <tr v-for="(column, i) in schemaRender" :key="'column' + i">
          <template v-if="!column.isErrorRow">
            <td>
              <input class="input w-full" type="text" v-model="column.name" />
            </td>
            <td>
              <Tooltip
                :disabled="column.canEdit"
                :text="getTypeTooltipText(column)"
              >
                <SelectMenu
                  class="ignore-list text-black"
                  :options="dataTypeOptions"
                  v-model="column.dataType"
                  :disabled="!column.canEdit"
                />
              </Tooltip>
            </td>
            <td class="w-52 whitespace-nowrap">
              <Tooltip
                :disabled="column.canEdit"
                :text="getDefaultTooltipText(column)"
              >
                <SelectMenu
                  class="ignore-list text-black"
                  :options="booleanOptions"
                  :value="!column.isRequired"
                  @input="setIsRequired(!$event, column)"
                  :disabled="!column.canEdit"
              /></Tooltip>
            </td>
            <td>
              <Tooltip
                v-if="canEditSchema"
                :disabled="column.canEdit"
                :text="getDeleteTooltipText(column)"
              >
                <ButtonGradient
                  class="py-1 border-gray-500 border w-20"
                  customGradient="transparent"
                  hover="bg-blue-300"
                  :disabled="!column.canEdit"
                  @click="toggleShouldDelete(column)"
                >
                  <span v-if="!column.shouldDelete">Delete</span>
                  <span v-else
                    ><IconSolid name="Undo" class="w-5"
                  /></span> </ButtonGradient
              ></Tooltip>
            </td>
          </template>
          <td v-else-if="!column.name">
            <div
              class="border border-errors rounded text-xs bg-errors-faded px-2 py-1 -mt-2"
              style="width: fit-content"
              v-t="'schemaEditor.nameRequired'"
            ></div>
          </td>
        </tr>

        <!-- New schema column: -->
        <tr v-if="canAddToSchema">
          <td class="text-xl font-bold" v-t="'schemaEditor.addAColumn'"></td>
        </tr>
        <tr id="add-row" v-if="canAddToSchema">
          <td>
            <div>
              <input
                :class="[showNewRowError ? 'border border-errors' : '']"
                class="input w-full"
                type="text"
                v-model="newColumn.name"
                placeholder="Column Name"
                @keyup.enter="addColumnClick"
              />
            </div>
          </td>
          <td>
            <SelectMenu
              class="ignore-list text-black"
              :options="dataTypeOptions"
              :value="newColumn.dataType"
              @input="setDataType($event, newColumn)"
            />
          </td>
          <td>
            <SelectMenu
              class="ignore-list text-black"
              :options="booleanOptions"
              :value="!newColumn.isRequired"
              @input="setIsRequired(!$event, newColumn)"
            />
          </td>
          <td>
            <ButtonGradient
              class="py-1"
              customGradient="linear-gradient(to bottom, rgba(150, 150, 150, 1), rgba(150, 150, 150, 0.5))"
              @click="addColumnClick"
              v-t="'schemaEditor.add'"
            >
            </ButtonGradient>
          </td>
        </tr>
        <tr v-if="showNewRowError">
          <td>
            <div
              class="border border-errors rounded text-xs bg-errors-faded px-2 py-1 -mt-2"
              style="width: fit-content"
              v-t="'schemaEditor.nameRequired'"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="mt-6">
      <div
        class="mb-6 text-red-500 italic"
        v-if="errorMessage"
        v-t="'schemaEditor.updateError'"
      >
        {{ errorMessage }}
      </div>
      <ButtonGradient class="py-1 text-lg ml-2" @click="submit">
        <span v-t="'saveChanges'"></span>
      </ButtonGradient>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonGradient from "@/components/ButtonGradient.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import { DataConnection, DataType, NodeData, SchemaNode } from "@/types/data";
import ToggleInputLabelled from "@/components/inputs/ToggleInputLabelled.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import Tooltip from "@/components/Tooltip.vue";
import { makeId } from "@/utils";
import { useEditableDataStore } from "@/stores/editableData";
import { logger } from "@core/logger";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import { useConnectionDataStore } from "@/stores/connectionData";

/**
 *
 * TODO: Prevent/disable manipulation of bound columns
 *
 * [ ] TODO: Dropdown at bottom of modal gets cut off .... same issue as with Disconnect popup
 */

/**
 * NOTE: Account for "used in filter" case?
 * NOTE: Will be able to detect whether used as node or dataset..
 */

const DATA_TYPES = [
  {
    label: "Text",
    value: "String",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "Date",
    value: "Date",
  },
  {
    label: "Time",
    value: "Time",
  },
  {
    label: "DateTime",
    value: "DateTime",
  },
  {
    label: "Image Url",
    value: "ImageUrl",
  },
  {
    label: "Image Upload",
    value: "ImageUpload",
  },
  {
    label: "Color",
    value: "Color",
  },
  {
    label: "Boolean",
    value: "Bool",
  },
];

const preventDeleteReasons = {
  UsedInThisApp: "UsedInThisApp",
  UsedInOtherApps: "UsedInOtherApps",
};

export interface ColumnInput extends SchemaNode {
  preventDeleteReason?: string;
  usedInApps?: any[];
  canEdit?: boolean;
}

@Component({
  components: {
    ButtonGradient,
    SelectMenu,
    ToggleInputLabelled,
    IconSolid,
    Tooltip,
  },
})
export default class SchemaColumns extends Vue {
  @Prop(Boolean) isEditable: boolean;

  get connectionEditor() {
    return useConnectionEditorStore();
  }

  get schema() {
    return this.connectionEditor.schema as SchemaNode[];
  }

  get connection() {
    return this.connectionEditor.connection;
  }

  get dataBindingUsageInfo() {
    return this.connectionEditor.dataBindingUsageInfo;
  }

  errorMessage = "";

  dataTypeOptions: any[] = DATA_TYPES;
  defaultDataType = "String";
  schemaInner: ColumnInput[] = [];
  showNewRowError = false;
  newColumn: Partial<ColumnInput> = this.createEmptyColumn();
  isFirstRowHeader = true;
  booleanOptions: any[] = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  get editableDataStore() {
    return useEditableDataStore();
  }

  get showFirstRowHeaderToggle() {
    if (!this.connection) return false;

    return (
      !this.connection.canEditData && this.connection.schemaType === "Tabular"
    );
  }

  createEmptyColumn(): Partial<ColumnInput> {
    const id = makeId();

    return {
      name: "",
      dataType: "String",
      isRequired: true,
      canEdit: true,
      uuid: null as any, // NOTE: must be null when we pass to server
      query: id,
    };
  }

  get startIndex() {
    return this.isFirstRowHeader ? 1 : 0;
  }

  get schemaRender() {
    let res: any = [];
    this.schemaInner.forEach((node) => {
      res.push(node);
      res.push({ ...node, isErrorRow: true });
    });
    return res;
  }

  get canEditSchema() {
    return this.connection?.canEditSchema ?? false;
  }

  get canAddToSchema() {
    return (
      (this.connection?.canEditData && this.connection?.canEditSchema) ?? false
    );
  }

  async mounted() {
    if (this.connection === null) {
      logger.track("SchemaColumns: connection is null");
      return;
    }
    await this.connectionEditor.getConnectionBindings({
      dcUuid: this.connection.uuid,
    });

    this.schemaInner = [...this.schema].map((n) => {
      let res: ColumnInput = {
        ...n,
        canEdit: true,
        preventDeleteReason: "",
        usedInApps: [],
      };

      const usageInfo = this.dataBindingUsageInfo ?? [];

      const bindingsUsingNode = [
        ...usageInfo.filter((db) => db.dataUuid === n.uuid),
      ];
      const appId = this.$route.params.id;

      if (!bindingsUsingNode) return res;

      if (bindingsUsingNode.some((b) => b.appInfo[0].appUuid === appId)) {
        res.preventDeleteReason = preventDeleteReasons.UsedInThisApp;
        res.canEdit = false;
        // console.log("used in this app!");
        return res;
      }

      if (bindingsUsingNode.length > 0) {
        res.preventDeleteReason = preventDeleteReasons.UsedInOtherApps;
        res.canEdit = false;
        res.usedInApps = bindingsUsingNode
          .filter((b) => b.appInfo[0].appUuid !== appId)
          .map((b) => b.appInfo[0]);
        // console.log("used in apps...", res);
        return res;
      }

      return res;
    });

    /**
     * NOTE: Why doesn't every connection have nodeSets...?
     */
    if (this.connection.nodeSets)
      this.isFirstRowHeader =
        !!this.connection.nodeSets[0]?.startIndex &&
        this.connection.nodeSets[0].startIndex > 0;
  }

  setDataType(val: DataType, column: Partial<ColumnInput>) {
    column.dataType = val;
  }

  setIsRequired(val: boolean, column: Partial<ColumnInput>) {
    column.isRequired = val;
  }

  toggleShouldDelete(n: SchemaNode) {
    const res = [...this.schemaInner];
    const node = res.find((x) => x.uuid === n.uuid);
    if (node) {
      node.shouldDelete = !node.shouldDelete;
    }
    Vue.set(this, "schemaInner", res);
  }

  getPreventReasonText(n: ColumnInput) {
    switch (n.preventDeleteReason) {
      case preventDeleteReasons.UsedInThisApp:
        return "it is currently being used";
      case preventDeleteReasons.UsedInOtherApps:
        return `it is being used by the following apps: ${n.usedInApps
          ?.map((x) => x.appName)
          .join(", ")}`;
    }
  }

  getDeleteTooltipText(n: ColumnInput) {
    return `This column cannot be deleted because ${this.getPreventReasonText(
      n
    )}.`;
  }

  getTypeTooltipText(n: ColumnInput) {
    return `This column's type cannot be altered because ${this.getPreventReasonText(
      n
    )}.`;
  }

  getDefaultTooltipText(n: ColumnInput) {
    return `This column cannot be edited because ${this.getPreventReasonText(
      n
    )}.`;
  }

  addColumnClick() {
    this.addColumn();
    this.$nextTick(() => {
      const t = this.$refs.table as HTMLTableElement;
      const inputs = t.querySelectorAll("[data-name-input]");
      if (inputs.length > 0) {
        const input = inputs[inputs.length - 1];
        if (input) {
          (input as HTMLInputElement).focus();
          (input as HTMLInputElement).select();
        }
      }
    });
  }

  addColumn() {
    if (this.newColumn.name) {
      this.showNewRowError = false;
      // console.log("add column...", this.newColumn);
      this.schemaInner.push(this.newColumn as ColumnInput);
      this.newColumn = this.createEmptyColumn();
    } else {
      this.showNewRowError = true;
    }
  }

  async submit() {
    if (this.schemaInner.some((n) => !n.name)) return;

    if (this.connection === null) {
      return;
    }

    if (this.newColumn.name) {
      this.addColumn();
    }

    const schemaToPost = this.schemaInner.map((n) => {
      const res = { ...n };
      delete res.preventDeleteReason;
      delete res.usedInApps;
      delete res.canEdit;
      res.isSelected = !res.shouldDelete;
      return res;
    });

    const nodeSet = {
      uuid: this.connection.nodeSets[0]?.uuid,
      name: this.connection.nodeSets[0]?.name,
      startIndex: this.startIndex,
      isSelected: true,
    };
    schemaToPost.push(nodeSet as any);

    try {
      const connection: DataConnection =
        await this.connectionEditor.updateDataConnection({
          nodes: schemaToPost,
        });

      // This removes any deleted columns from the current UI
      this.schemaInner = this.schemaInner.filter((x) => !x.shouldDelete);

      /**
       * We set the schema here so that if user tabs back to data grid view, they see their changes reflected.
       * Also must update the `columns` property on each row.
       */

      // If index was -1, set to Infinity (this means it was a new column, whose uuid didn't exist in schemaInner)
      const schema = connection.nodeSets[0].nodes
        ?.filter((n) => !n.isArtificial)
        .sort((a, b) => {
          const aIndex = this.schemaInner.findIndex((x) => x.uuid === a.uuid);
          const bIndex = this.schemaInner.findIndex((x) => x.uuid === b.uuid);
          return (
            (aIndex < 0 ? Infinity : aIndex) - (bIndex < 0 ? Infinity : bIndex)
          );
        }) as SchemaNode[];

      const mapSchemaNodeToData = (n: SchemaNode): NodeData => {
        return {
          ...n,
          value: null,
          formattedValue: "",
          displayName: n.name,
          uuid: n.uuid || null,
        };
      };

      const newNodes = schema.filter(
        (x) => this.schemaInner.findIndex((y) => y.uuid === x.uuid) < 0
      );

      const schemaUuids = schema.map((n) => n.uuid);

      const rows = [...this.editableDataStore.rows].map((row) => {
        const newColumns = [
          ...row.columns.filter((col) =>
            schemaUuids.includes(col.uuid as string)
          ), // Remove deleted columns
          ...newNodes.map(mapSchemaNodeToData), // Add new columns
        ];
        return {
          ...row,
          columns: newColumns,
        };
      });

      this.editableDataStore.setRowsInitialState(rows);
      this.connectionEditor.schema = schema;
      // Do this so that if user has updated "isRequired" on any columns, we reflect those changes in the data editor view
      this.editableDataStore.clearValidationErrors();

      useConnectionDataStore().fetchConnectionData({
        connectionId: this.connection?.uuid,
      });
    } catch (e) {
      console.log("error with edit schema update", e);
      // TODO: check if this has message property or what
      this.errorMessage = e as string;
    }
  }
}
</script>

<style lang="postcss" scoped>
td,
th {
  @apply p-2;
}

table {
  max-width: 80%;
}

th {
  text-align: start;
  @apply text-xl;
}

#add-row td {
  @apply pt-0;
}

.input {
  @apply text-black rounded-sm px-2 py-1;
}
</style>
