<template>
  <svg
    v-if="data && data.length > 0"
    class="chart"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <mask :id="`line-graph-mask-${wid}`">
        <rect
          :x="0"
          :y="0"
          fill="white"
          :width="graphWidth"
          :height="graphHeight"
        />
      </mask>
    </defs>
    <g :transform="`translate(${leftMargin}, ${topMargin})`">
      <rect
        class="bg"
        v-if="showBg"
        :width="graphWidth"
        :height="graphHeight"
        x="0"
        y="0"
        :fill="bgColor"
      />

      <g class="x-axis" :transform="`translate(0, ${graphHeight})`">
        <line
          class="domain"
          :stroke="lineColor"
          :stroke-width="lineThickness"
          x1="0"
          :x2="graphWidth"
          y1="0"
        ></line>
        <g class="x-axis ticks wrapper" v-if="labels.length > 0">
          <g
            class="tick"
            text-anchor="middle"
            v-for="(item, index) in labels"
            :key="index"
            :transform="`translate(${
              (graphWidth / (labels.length - 1)) * index
            }, 0)`"
          >
            <line
              :stroke="lineColor"
              :stroke-width="lineThickness"
              :y1="0 - lineThickness / 2"
              :y2="3 + lineThickness"
            ></line>
            <text
              v-if="!xLabelTilt"
              :style="getStyles('label')"
              :y="9 + lineThickness"
              :dy="label_fontSize"
              :font-size="label_fontSize"
            >
              {{ item }}
            </text>
            <text
              v-if="xLabelTilt"
              :style="getStyles('label')"
              :y="9 + lineThickness"
              :dy="label_fontSize"
              :font-size="label_fontSize"
              text-anchor="end"
              transform="rotate(-40) translate(-10, -2)"
            >
              {{ item }}
            </text>
          </g>
        </g>
      </g>
      <g class="y-axis" :transform="`translate(0, 0)`">
        <line
          class="domain"
          :stroke="lineColor"
          :stroke-width="lineThickness"
          x1="0"
          y1="0"
          :y2="graphHeight"
        ></line>
        <g
          class="tick"
          text-anchor="end"
          v-for="(tick, index) in ticks"
          :key="index"
          :transform="`translate(0, ${
            (graphHeight / (ticks.length - 1)) * index
          })`"
        >
          <line
            :stroke="lineColor"
            :stroke-width="lineThickness"
            :x1="0 + lineThickness / 2"
            :x2="-3 - lineThickness"
          ></line>
          <line
            class="gridLine"
            v-if="showGrid"
            :stroke="label_textColor"
            opacity="0.2"
            :x2="graphWidth"
          ></line>
          <text
            :x="-9 - lineThickness"
            dy="0.32em"
            :style="getStyles('label')"
            :font-size="label_fontSize"
          >
            {{ tick }}
          </text>
        </g>
      </g>
      <polyline
        ref="plotline"
        class="plotline"
        :mask="$helpers.svgUrl(`#line-graph-mask-${wid}`)"
        :points="plotLine"
        :stroke="plotlineColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        :stroke-width="plotlineThickness"
      />
    </g>
    <text
      class="x-axis title"
      :transform="xTitleTransform"
      text-anchor="middle"
      :style="getStyles('title')"
      :font-size="title_fontSize"
    >
      {{ labelTitle }}
    </text>
    <text
      class="y-axis title"
      :transform="yTitleTransform"
      text-anchor="middle"
      :style="getStyles('title')"
      :font-size="title_fontSize"
    >
      {{ valueTitle }}
    </text>
  </svg>
</template>

<script lang="ts">
import { NodeData } from "@/types/data";
import * as d3 from "d3";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class LineGraphComponent extends Vue {
  @Prop(Number) readonly h: number;
  @Prop(Number) readonly w: number;
  @Prop(Number) readonly scaleX: number;
  @Prop(Number) readonly scaleY: number;
  @Prop(Number) readonly barPadding: number;

  @Prop(Number) readonly minValue: number;
  @Prop(Number) readonly maxValue: number;
  @Prop(Number) readonly minManual: number;
  @Prop(Number) readonly maxManual: number;
  @Prop(String) readonly xTitle: string;
  @Prop(String) readonly yTitle: string;
  @Prop(String) readonly xTitleManual: string;
  @Prop(String) readonly yTitleManual: string;

  @Prop(String) readonly title_fontFamily: string;
  @Prop(Number) readonly title_fontSize: number;
  @Prop(Number) readonly title_fontWeight: number;
  @Prop(String) readonly title_fontStyle: string;
  @Prop(String) readonly title_textColor: string;
  @Prop(String) readonly title_textTransform: string;

  @Prop(String) readonly label_fontFamily: string;
  @Prop(Number) readonly label_fontSize: number;
  @Prop(Number) readonly label_fontWeight: number;
  @Prop(String) readonly label_fontStyle: string;
  @Prop(String) readonly label_textColor: string;
  @Prop(String) readonly label_textTransform: string;

  @Prop(String) readonly lineColor: string;
  @Prop(Number) readonly lineThickness: number;
  @Prop(Number) readonly labelTicks: number;
  @Prop(Number) readonly leftTitlePadding: number;
  @Prop(Number) readonly bottomTitlePadding: number;
  @Prop(String) readonly bgColor: string;
  @Prop(String) readonly plotlineColor: string;
  @Prop(Number) readonly plotlineThickness: number;
  @Prop(Boolean) readonly xLabelTilt: boolean;
  @Prop(Boolean) readonly showBg: boolean;
  @Prop(Boolean) readonly showGrid: boolean;
  @Prop(Boolean) readonly showValues: boolean;
  @Prop(String) readonly xAxisColumnId: string;
  @Prop(String) readonly yAxisColumnId: string;
  @Prop(String) readonly colorColumn: string;
  @Prop(String) readonly wid: string;
  @Prop({ type: Array, default: () => [] }) readonly data: NodeData[][];

  get width() {
    return this.w * this.scaleX;
  }

  get height() {
    return this.h * this.scaleY;
  }

  get graphWidth() {
    return Math.max(
      this.width - this.leftMargin - this.lineThickness - this.labelWidth,
      0
    );
  }

  get graphHeight() {
    return Math.max(
      this.height -
        this.topMargin -
        70 -
        this.lineThickness -
        this.label_fontSize -
        this.bottomTitlePadding -
        this.title_fontSize -
        this.tiltBuffer,
      0
    );
  }

  get graphXTitle() {
    return this.xTitleManual === null ? this.xTitle : this.xTitleManual;
  }
  get graphYTitle() {
    return this.yTitleManual === null ? this.yTitle : this.yTitleManual;
  }

  get leftMargin() {
    return (
      this.valueWidth +
      this.leftTitlePadding +
      90 +
      this.title_fontSize / 2 +
      this.lineThickness
    );
  }

  get topMargin() {
    return this.label_fontSize;
  }

  get labelTitle() {
    if (this.data && this.data.length > 0) {
      if (this.xTitle != "") {
        return this.graphXTitle;
      }
      return this.data[0].find((c) => c.uuid === this.xAxisColumnId)
        ?.displayName;
    }
    return "";
  }

  get valueTitle() {
    if (this.data && this.data.length > 0) {
      if (this.yTitle != "") {
        return this.graphYTitle;
      }
      return this.data[0].find((c) => c.uuid === this.yAxisColumnId)
        ?.displayName;
    }
    return "";
  }

  get labels() {
    if (this.data.length > 0) {
      return this.data.map((row) => {
        return row.find((c) => c.uuid === this.xAxisColumnId)
          ?.formattedValue as string;
      });
    }
    return [] as string[];
  }

  get values() {
    if (this.data && this.data.length > 0) {
      let values: any = [];

      this.data.map((row) => {
        values.push(
          parseInt(
            (row.find((c) => c.uuid === this.yAxisColumnId)?.value as string) ??
              0
          )
        );
      });

      return values;
    }
    return [];
  }

  get dataMaxValue() {
    return Math.max(...this.values);
  }

  get dataMinValue() {
    return Math.min(...this.values);
  }

  get graphMin() {
    return this.minManual === null ? this.minValue : this.minManual;
  }

  get graphMax() {
    return this.maxManual === null ? this.maxValue : this.maxManual;
  }

  get buffer() {
    let max = Math.ceil(this.dataMaxValue);
    let min = Math.floor(this.dataMinValue);
    let dif = max - min;
    return dif * 0.1;
  }

  get domain() {
    return this.dataMaxValue - this.dataMinValue;
  }

  get yMax() {
    let optionValue = this.graphMax;
    return isNaN(optionValue)
      ? Math.ceil(Math.max(...this.values) + this.buffer)
      : optionValue;
  }

  get yMin() {
    let optionValue = this.graphMin;
    return isNaN(optionValue)
      ? Math.floor(Math.min(...this.values) - this.buffer)
      : optionValue;
  }

  get xPad() {
    return this.graphWidth * 0.05;
  }

  get yPad() {
    return this.graphHeight / 200;
  }

  get plotLine() {
    let points = this.values
      .map((value: number, index: number) => {
        let x = this.x(index);
        let y = this.y(value);
        return `${x} ${y}`;
      })
      .join(", ");

    return points;
  }

  get tiltBuffer() {
    if (this.xLabelTilt) {
      return Math.tan(40 * (Math.PI / 180)) * this.labelWidth;
    } else {
      return 0;
    }
  }

  get x() {
    return d3
      .scaleLinear()
      .domain([0, this.values.length - 1])
      .range([this.xPad, this.graphWidth - this.xPad]);
  }

  get y() {
    return d3
      .scaleLinear()
      .domain([this.yMax, this.yMin])
      .range([0, this.graphHeight])
      .nice();
  }

  get ticks() {
    return this.y.ticks(this.labelTicks);
  }

  get xTitleTransform() {
    return `translate(${
      this.graphWidth / 2 + this.leftMargin + this.lineThickness / 2
    }, ${
      this.graphHeight +
      40 +
      this.bottomTitlePadding +
      this.lineThickness +
      this.label_fontSize +
      this.title_fontSize +
      this.tiltBuffer +
      this.topMargin
    } )`;
  }

  get yTitleTransform() {
    return `translate(${
      this.leftMargin - this.valueWidth - this.leftTitlePadding - 40
    }, ${this.graphHeight / 2 + this.topMargin} ), rotate(-90)`;
  }

  get labelTracker() {
    let object = this.getStyles("label");
    let labels = [];
    for (const property in object) {
      labels.push(`${property}, ${object[property]}`);
    }
    return `${labels}|${this.yAxisColumnId}`;
  }

  get valueTracker() {
    let object = this.getStyles("value");
    let values = [];
    for (const property in object) {
      values.push(`${property}, ${object[property]}`);
    }
    return `${values}|${this.xAxisColumnId}`;
  }

  get longestLabel() {
    let longest = this.labels.reduce(function (a: string, b: string) {
      return a.length > b.length ? a : b;
    });
    return longest;
  }

  get longestValue() {
    return Math.max(this.values);
  }

  get labelWidth() {
    let labelLength = this.longestLabel.length;
    let fontSize = this.label_fontSize;
    return labelLength * (fontSize / 2);
  }

  get valueWidth() {
    let valueLength = this.longestValue.toString().length;
    let fontSize = this.label_fontSize;

    return valueLength * fontSize * 1.5;
  }

  getStyles(prefix: string) {
    const vm = this as any;
    const result: any = {};
    if (vm[`${prefix}_fontFamily`]) {
      result.fontFamily = vm[`${prefix}_fontFamily`];
    }
    if (vm[`${prefix}_fontWeight`]) {
      result.fontWeight = vm[`${prefix}_fontWeight`];
    }
    if (vm[`${prefix}_textColor`]) {
      result.fill = vm[`${prefix}_textColor`];
    }
    if (vm[`${prefix}_fontStyle`]) {
      result.fontStyle = vm[`${prefix}_fontStyle`];
    }
    if (vm[`${prefix}_textTransform`]) {
      result.textTransform = vm[`${prefix}_textTransform`];
    }
    return result;
  }
}
</script>

<style scoped></style>
