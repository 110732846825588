<template>
  <div class="text-center py-12 space-y-4">
    <div class="text-2xl">Your Connection has been created</div>
    <div>
      Now create a Zap that can automatically add data to this collection.
    </div>

    <a
      class="inline-flex items-center px-6 py-3 border border-app-magenta text-lg font-bold rounded-md shadow-sm text-white bg-gradient-to-br from-app-purple to-app-magenta hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      href="https://screenfeed.com/support-articles/creating-a-zapier-connection"
      target="_blank"
      v-t="'CompleteZapier.readInstructions'"
    ></a>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class CompleteZapier extends Vue {}
</script>

<style lang="postcss"></style>
