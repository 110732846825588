<template>
  <div :id="uuid" class="text-gray-600 font-italic">Primative Array</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataType, DataValue } from "@/types/data";

@Component
export default class DataNodePrimativeArray extends Vue {
  @Prop({ type: String, required: false }) readonly uuid: string;
  @Prop(String) readonly dataType: DataType;
  @Prop() readonly value: DataValue;

  mounted() {
    console.log("prim array", this.value, this.dataType);
  }
}
</script>

<style scoped></style>
