import { NodeKind } from "@/types/data";

export const BarGraphPlaceholderData = [
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Nile",
      formattedValue: "Nile",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Length (mi.)",
      uuid: "b",
      value: 4130,
      formattedValue: "4130",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 0,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Amazon",
      formattedValue: "Amazon",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Length (mi.)",
      uuid: "b",
      value: 3976,
      formattedValue: "3976",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 1,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Yangtze",
      formattedValue: "Yangtze",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Length (mi.)",
      uuid: "b",
      value: 3917,
      formattedValue: "3917",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 2,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Mississippi",
      formattedValue: "Mississippi",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Length (mi.)",
      uuid: "b",
      value: 3902,
      formattedValue: "3902",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 3,
      query: "_sf_index__",
    },
  ],
  [
    {
      dataType: "String",
      displayName: "Name",
      uuid: "a",
      value: "Yenisei",
      formattedValue: "Yenisei",
      query: "A",
    },
    {
      dataType: "Number",
      displayName: "Length (mi.)",
      uuid: "b",
      value: 3445,
      formattedValue: "3445",
      query: "B",
    },
    {
      dataType: "Index",
      displayName: "Index",
      uuid: "i",
      value: 4,
      query: "__sf_index__",
    },
  ],
].map((row) => {
  return row.map((node) => {
    return {
      ...node,
      kind: NodeKind.node,
      isArtificial: node.displayName === "Index",
    };
  });
});
