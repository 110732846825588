<template>
  <svg
    width="47px"
    height="47px"
    viewBox="0 0 47 47"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="-6.39648129%"
        y1="96.8022108%"
        x2="95.6628991%"
        y2="31.6620608%"
        :id="svgId('linearGradient-1')"
      >
        <stop stop-color="#4996E6" offset="0%"></stop>
        <stop stop-color="#40A0E8" offset="20%"></stop>
        <stop stop-color="#29BBEE" offset="53%"></stop>
        <stop stop-color="#04E7F7" offset="96%"></stop>
        <stop stop-color="#00ECF8" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="59.9687749%"
        y1="18.75%"
        x2="9.17472644%"
        y2="112.694099%"
        :id="svgId('linearGradient-2')"
      >
        <stop stop-color="#9C2ADC" offset="12%"></stop>
        <stop stop-color="#B425BF" offset="25%"></stop>
        <stop stop-color="#F01A74" offset="51%"></stop>
        <stop stop-color="#FF1761" offset="58%"></stop>
        <stop stop-color="#FF8B21" offset="88%"></stop>
      </linearGradient>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(0.000000, -39.000000)">
        <g transform="translate(-145.000000, 0.000000)">
          <g transform="translate(-39,39)">
            <polygon
              :id="svgId('path-blue')"
              :fill="svgUrl('linearGradient-1')"
              :mask="svgUrl('mask-blue')"
              style="mix-blend-mode: multiply"
              points="221 35 189 30.6060008 189 7.54441374 221 0"
            ></polygon>

            <polygon
              :id="svgId('path-pink')"
              :fill="svgUrl('linearGradient-2')"
              :mask="svgUrl('mask-pink')"
              opacity="0.8"
              points="195 47 225 38.0461957 225 16.5380435 195 12"
            ></polygon>

            <mask :id="svgId('mask-blue')">
              <polygon
                :id="svgId('mask-path-blue')"
                ref="blue"
                fill="white"
                points="221 35 189 30.6060008 189 7.54441374 221 0"
              ></polygon>
            </mask>

            <mask :id="svgId('mask-pink')">
              <polygon
                ref="pink"
                :id="svgId('mask-path-pink')"
                fill="white"
                points="195 47 225 38.0461957 225 16.5380435 195 12"
              ></polygon>
            </mask>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import gsap, { Power3 } from "gsap";
import { makeId } from "@/utils";

@Component({})
export default class LoadingAnimation extends Vue {
  tl!: gsap.core.Timeline;

  randomId = makeId();

  svgId(id: string) {
    return `${id}-${this.randomId}`;
  }
  svgUrl(id: string) {
    return `url(#${id}-${this.randomId})`;
  }

  mounted() {
    const pink = this.$refs.pink as gsap.TweenTarget;
    const blue = this.$refs.blue as gsap.TweenTarget;
    this.tl = gsap
      .timeline({
        repeat: -1,
        ease: Power3.easeIn,
      })
      .from(blue, { y: "+=50" }, 0)
      .from(pink, { y: "+=50" }, 0.2)
      .to(blue, { y: "-=50" }, 1.1)
      .to(pink, { y: "-=50" }, 1.3);
  }

  beforeDestroy() {
    this.tl.kill();
  }
}
</script>
