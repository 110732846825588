<template>
  <div class="flex-grow">
    <component
      v-if="!redirectToEditor"
      :is="completeComponents[documentationComponent]"
      :connection="connection"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useConnectionSetupStoreV2 } from "@/stores/connectionSetupV2";
import { useAppEditorStore } from "@/stores/appEditor";
import { useConnectionEditorStore } from "@/stores/connectionEditor";
import CompleteDefaultV2 from "@/components/data/connections/setup/complete/CompleteDefaultV2.vue";
import CompleteZapier from "@/components/data/connections/setup/complete/CompleteZapier.vue";
import CompleteRestApi from "@/components/data/connections/setup/complete/CompleteRestApi.vue";
import { CompleteStep } from "@/types/ConnectionSources";

const completeComponents = {
  CompleteDefaultV2,
  CompleteZapier,
  CompleteRestApi,
};

const props = defineProps<{
  setupStep: CompleteStep | undefined;
}>();

const route = useRoute();
const router = useRouter();
const setupStore = useConnectionSetupStoreV2();
const appEditorStore = useAppEditorStore();
const connectionEditorStore = useConnectionEditorStore();

const documentationComponent = computed(() => {
  return props.setupStep?.documentationComponentName;
});

const selectedWidget = computed(() => {
  return appEditorStore.selectedWidget;
});

const appUuid = computed(() => {
  return appEditorStore.uuid;
});
/**
 * (1) If user is creating a connection from App editor view,
 * create the binding and then close the modal. (Node selection will happen as an earlier setup step.)
 *
 * (1.5) If connection source is zapier/api, show instructions
 *
 * (2) If user is REPLACING a Collection connection from App editor view,
 * update the binding and then if widget is repeater, show "remap columns" view.
 *
 * (3) If user is creating a Collection connection from the Dashboard/Connections,
 * take them to the Dashboard/Connections/{connectionUuid} view (which is Data Manager).
 */

const redirectToEditor = computed(() => {
  return false; // !setupStore.isCollection && appUuid.value;
});

const existingConnectionUuid = computed(() => {
  return route.params?.connectionUuid;
});

const newConnectionUuid = computed(() => {
  return route.query?.c as string;
});

const isReplacing = computed(() => {
  return route.path.includes("replace");
});

const connection = computed(() => {
  return setupStore.connection;
});

onMounted(async () => {
  // Redirect Scalar Bindings back to app editor
  // NOTE: We handle this for tree connections in CompleteDefault
  // If calendar, and we are replacing, must call remap refs endpoint
  if (selectedWidget.value?.type.includes("Calendar") && isReplacing) {
    await connectionEditorStore.updateRemapLogicRefs({
      sourceConnectionUuid: existingConnectionUuid.value,
      targetConnectionUuid: newConnectionUuid.value || "",
      logicUuids: connectionEditorStore.logicRefsRequiringRemap,
      appUuid: route.params.id,
    });
  }
  if (redirectToEditor.value) {
    router.push({
      name: "edit",
      params: { id: appUuid.value as string },
    });
  }
});
</script>
