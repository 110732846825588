<template>
  <component
    :is="`Icon${iconName}`"
    fill="none"
    viewBox="0 0 50 50"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    :stroke-width="width"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconCalendarAgenda from "./IconCalendarAgenda.vue";
import IconCalendarWeek from "./IconCalendarWeek.vue";
import IconCalendarDay from "./IconCalendarDay.vue";
import IconCalendarEvent from "./IconCalendarEvent.vue";
import IconCalendarRoomSchedule from "./IconCalendarRoomSchedule.vue";
import IconProgressBar from "./IconProgressBar.vue";
import IconProgressEllipse from "./IconProgressEllipse.vue";
import IconCounter from "./IconCounter.vue";
import IconRepeater from "./IconRepeater.vue";
import IconRectangle from "./IconRectangle.vue";
import IconEllipse from "./IconEllipse.vue";
import IconPolygon from "./IconPolygon.vue";
import IconBarGraph from "./IconBarGraph.vue";
import IconColumnGraph from "./IconColumnGraph.vue";
import IconLineGraph from "./IconLineGraph.vue";
import IconPieGraph from "./IconPieGraph.vue";
import IconStackedGraph from "./IconStackedGraph.vue";
import IconAnalogClock from "./IconClock.vue";
import IconTriangle from "./IconTriangle.vue";
import IconDatetime from "./IconDateTime.vue";
import IconLine from "./IconLine.vue";

@Component({
  components: {
    IconRepeater,
    IconCalendarAgenda,
    IconCalendarWeek,
    IconCalendarDay,
    IconCalendarEvent,
    IconCalendarRoomSchedule,
    IconProgressBar,
    IconProgressEllipse,
    IconCounter,
    IconRectangle,
    IconEllipse,
    IconPolygon,
    IconBarGraph,
    IconColumnGraph,
    IconLineGraph,
    IconPieGraph,
    IconStackedGraph,
    IconAnalogClock,
    IconTriangle,
    IconDatetime,
    IconLine,
  },
})
export default class ComponentIcon extends Vue {
  @Prop(String) name: string;
  @Prop({ default: 2 }) width: number;

  get iconName() {
    return this.name;
  }
}
</script>

<style>
/* A bit concerning  to remove scoped here... */
.white-stroke {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.white-fill {
  fill: currentColor;
}

.gray-fill {
  /* fill: #adadad; */
  fill: currentColor;
  opacity: 0.67;
  stroke: none;
}

.light-gray-fill {
  fill: currentColor;
  opacity: 0.4;
  stroke: none;
}

.gray-stroke {
  /* stroke: #adadad; */
  stroke: currentColor;
  opacity: 0.67;
}

/* For handling Shapes menu icons: */
.shape {
  stroke: currentColor;
  fill: currentColor;
  fill-opacity: 0.67;
}

.gray-slice {
  fill: currentColor;
  stroke: #404040;
  fill-opacity: 0.67;
}

.light-gray-slice {
  fill: currentColor;
  stroke: #404040;
  fill-opacity: 0.84;
}

.white-slice {
  fill: currentColor;
  stroke: #404040;
}
</style>
