<template>
  <router-view v-if="isMounted"></router-view>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useConnectionSourceStore } from "@/stores/connectionSource";

const isMounted = ref(false);

onMounted(async () => {
  await useConnectionSourceStore().fetchConnectionSources();
  isMounted.value = true;
});
</script>
