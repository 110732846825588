<template>
  <div class="divide-y">
    <CollapsePanel :shouldListenForOpenCommand="true">
      <template slot="title">
        <div v-t="'data'"></div>
      </template>
      <div>
        <CalendarDataChooser :widgetId="model.wid" />
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarWeek.dayLabels'"></div>
      </template>
      <div class="py-3 px-4">
        <div
          class="text-gray-500 text-sm ml-2"
          v-t="'calendarWeek.weekOffset'"
        ></div>
        <EditorSelectMenu
          :value="model.weekOffset"
          :options="offsetOptions"
          @input="apply({ weekOffset: $event })"
        />
        <div
          class="text-sm text-gray-600 mt-1 px-2 py-1 leading-tight border rounded-sm shadow-sm"
          v-t="'calendarWeek.offsetExplain'"
        ></div>
      </div>
      <GroupTextFormatEditor
        :model="model"
        element="days"
        @update="apply($event)"
        :disableLineHeight="true"
      />
      <div class="px-4 space-y-3 pt-2 pb-5">
        <ColorInputBar
          :value="model.today_backgroundColor"
          @input="preview({ today_backgroundColor: $event })"
          @change="apply({ today_backgroundColor: $event })"
          :gradientEnabled="true"
          propName="today_backgroundColor"
        >
          <span v-t="'calendarWeek.todayBackgroundColor'"></span>
        </ColorInputBar>

        <ColorInputBar
          :value="model.today_textColor"
          @input="preview({ today_textColor: $event })"
          @change="apply({ today_textColor: $event })"
          propName="today_textColor"
        >
          <span v-t="'calendarWeek.todayTextColor'"></span>
        </ColorInputBar>

        <ColorInputBar
          :value="model.verticalLines_color"
          @input="preview({ verticalLines_color: $event })"
          @change="apply({ verticalLines_color: $event })"
          propName="verticalLines_color"
        >
          <span v-t="'calendarWeek.dayLines'"></span>
        </ColorInputBar>
      </div>
      <div class="px-4 -mt-2">
        <ToggleInput :value="model.syncFonts" @input="syncFontsInput"
          ><span v-t="'syncFonts'"></span
        ></ToggleInput>

        <ToggleInput
          class="pt-2 pb-5"
          :value="model.syncTextColors"
          @input="syncTextColorsInput"
          ><span v-t="'syncTextColors'"></span
        ></ToggleInput>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarWeek.multidayEvents'"></div>
      </template>
      <div>
        <GroupTextFormatEditor
          :model="model"
          element="multidayEventTitle"
          @update="apply($event)"
        />
        <ColorInputBar
          class="pt-3 pb-8 px-4"
          :value="model.event_backgroundColor"
          @input="preview({ event_backgroundColor: $event })"
          @change="apply({ event_backgroundColor: $event })"
          :gradientEnabled="true"
          propName="event_backgroundColor"
        >
          <span class="text-sm" v-t="'calendarWeek.backgroundColor'"></span>
        </ColorInputBar>
      </div>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarWeek.eventTime'"></div>
      </template>
      <GroupTextFormatEditor
        :model="model"
        element="eventTime"
        @update="apply($event)"
        :disableAlignment="true"
      />
      <ToggleInput
        class="pb-5 px-4"
        :value="model.use24Hour"
        @input="apply({ use24Hour: $event })"
        ><span v-t="'calendarWeek.use24HourClock'"></span
      ></ToggleInput>
    </CollapsePanel>
    <CollapsePanel>
      <template slot="title">
        <div v-t="'calendarWeek.eventTitle'"></div>
      </template>
      <div class="pb-3">
        <GroupTextFormatEditor
          :model="model"
          element="eventTitle"
          @update="apply($event)"
          :disableAlignment="true"
        />
      </div>
    </CollapsePanel>
    <BaseWidgetEditor :selectionLocked="model.locked" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { CalendarWeekOptions } from "./CalendarWeekOptions";
import CollapsePanel from "@/components/CollapsePanel.vue";
import ColorInputBar from "@/components/inputs/ColorInputBar.vue";
import FormLabel from "@/components/FormLabel.vue";
import GroupTextFormatEditor from "@/components/editors/GroupTextFormatEditor.vue";
import ToggleInput from "@/components/inputs/ToggleInput.vue";
import FontFamilyInput from "@/components/inputs/FontFamilyInput.vue";
import EditorSelectMenu from "@/components/EditorSelectMenu.vue";
import BaseWidgetEditor from "@/components/BaseWidgetEditor.vue";
import CalendarDataChooser from "@/components/widgets/CalendarDataChooser.vue";
import { useAppEditorStore } from "@/stores/appEditor";

@Component({
  components: {
    CollapsePanel,
    ToggleInput,
    ColorInputBar,
    FormLabel,
    FontFamilyInput,
    GroupTextFormatEditor,
    EditorSelectMenu,
    BaseWidgetEditor,
    CalendarDataChooser,
  },
})
export default class CalendarWeekEditor extends Vue {
  get appEditor() {
    return useAppEditorStore();
  }

  get model() {
    return this.appEditor.selectedWidget as unknown as CalendarWeekOptions;
  }

  get textElements() {
    return ["days", "eventTitle", "eventTime", "multidayEventTitle"];
  }

  apply(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props);
  }

  preview(props: any) {
    this.appEditor.setWidgetProps([this.model.wid], props, "NO_UNDO");
  }

  syncFontsInput(val: boolean) {
    this.appEditor.toggleSyncFonts({
      wid: this.model.wid,
      syncFonts: val,
      textElements: this.textElements,
    });
  }

  syncTextColorsInput(val: boolean) {
    this.appEditor.toggleSyncTextColors({
      wid: this.model.wid,
      syncTextColors: val,
      textElements: this.textElements,
    });
  }

  get offsetOptions() {
    return [
      { label: "Current Week", value: 0 },
      { label: "One Week Ahead", value: 1 },
      { label: "Two Weeks Ahead", value: 2 },
      { label: "Three Weeks Ahead", value: 3 },
    ];
  }
}
</script>
