import { Vue, Component, Watch, Prop } from "vue-property-decorator";

@Component({})
export default class TooltipMixin extends Vue {
    @Prop({ type: String, default: "" }) tooltip: string;
    @Prop(String) tooltipPosition: string;
    @Prop(Boolean) tooltipDisabled: boolean;

    tooltipVisible = false;
    private tooltipTimer: any = null;

    // TODO: mouse events do not fire on disabled elements, so if you redo into disabled state, tooltip stays open
    // We could just turn off tooltipVis when disabled...
    // I guess that's consistent with what we have now
    // Possibly want to come back and allow tooltip hover on disabled buttons

    onMouseEnter() {
        // console.log("me", this.tooltip, this.tooltipDisabled, this.tooltipVisible)
        if (this.tooltip.length > 0) {
            this.tooltipTimer = setTimeout(this.showTip, 150);
        }
    }

    onMouseLeave() {
        // console.log("ml");
        this.hideTip();
        clearTimeout(this.tooltipTimer);
    }

    showTip() {
        // console.log("show tip");
        if (this.tooltipDisabled) {
            this.tooltipVisible = false;
            return;
        }
        // console.log("show tip2");

        this.tooltipVisible = true;
    }

    hideTip() {
        this.tooltipVisible = false;
    }

    @Watch("tooltipDisabled")
    onTooltipDisabledChanged(isDisabled: boolean) {
        if (isDisabled) {
            this.tooltipVisible = false;
            clearTimeout(this.tooltipTimer);
        }
    }

    @Watch("disabled")
    onDisabledChanged(isDisabled: boolean) {
        if (isDisabled) {
            this.tooltipVisible = false;
            clearTimeout(this.tooltipTimer);
        }
    }

    destroyed() {
        clearTimeout(this.tooltipTimer);
    }


    get bgColor() {
        return "bg-gray-700";
    }

    get borderColor() {
        return "border-gray-700";
    }

    get pos() {
        switch (this.tooltipPosition) {
            case "t":
            case "r":
            case "l":
                return this.tooltipPosition;
            default:
                return "b";
        }
    }

    get contentClasses() {
        if (!this.tooltipVisible) {
            return ["invisible"];
        }
        switch (this.pos) {
            case "t":
                return [this.bgColor, "tip-offset-t"];
            case "r":
                return [this.bgColor, "tip-offset-r"];
            case "l":
                return [this.bgColor, "tip-offset-l"];
            default:
                return [this.bgColor, "tip-offset-b"];
        }
    }

    get arrowClasses() {
        if (!this.tooltipVisible) {
            return ["invisible"];
        }
        switch (this.pos) {
            case "t":
                return [this.borderColor, "tip-arrow-t"];
            case "r":
                return [this.borderColor, "tip-arrow-r"];
            case "l":
                return [this.borderColor, "tip-arrow-l"];
            default:
                return [this.borderColor, "tip-arrow-b"];
        }
    }
}


