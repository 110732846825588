<template>
  <div class="relative">
    <div
      class="flex items-center justify-center h-32"
      v-if="loadingConnections"
    >
      <span v-t="'SelectScalarValue.loadingConnections'"></span>
    </div>
    <div class="space-y-3" v-if="connectionsLoaded">
      <div class="-mt-1 space-y-1">
        <InputLabel v-t="'SelectScalarValue.selectConnection'"></InputLabel>
        <SelectMenu
          ref="menu"
          :dark="true"
          class="w-full dark-form-focus"
          :value="model.connectionUuid"
          @input="onConnectionSelected"
          :options="connectionOptions"
          :searchable="connectionOptions.length > 15"
        />
      </div>

      <div class="relative space-y-1" v-if="model.connectionUuid?.length > 0">
        <InputLabel
          v-if="hasSelection"
          v-t="'SelectScalarValue.selectedValuePath'"
        ></InputLabel>

        <div class="relative">
          <ValuePreview>
            <div class="overflow-hidden mr-8">
              <div
                @mouseenter="($event) => previewMouseEnter($event)"
                @mouseleave="previewMouseLeave"
                v-if="hasSelection"
                class="truncate min-w-0"
                :class="{ 'opacity-0': showFullPreview }"
                v-text="model.value"
              ></div>
              <div
                v-else
                class="min-w-0 text-white font-sans truncate"
                v-t="`ScalarValueSelect.selectValue`"
              ></div>
            </div>
          </ValuePreview>
          <div
            class="absolute top-0 right-8 bottom-0 border-l border-gray-600"
          ></div>
          <button
            class="absolute top-1/2 right-0 transform -translate-y-1/2"
            type="button"
            @click="showModal"
          >
            <LightningBoltIcon :on="hasSelection" />
          </button>
        </div>
        <div
          v-show="hasSelection && showFullPreview"
          class="pointer-events-none absolute text-sm left-2 pr-1 bottom-1 outline outline-gray-800 bg-gray-800 text-app-gold font-mono"
          v-text="model.value"
        ></div>

        <InputHelpText v-if="!hasSelection" class="relative pt-3 text-right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 absolute right-2 -top-1 text-app-gold"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
            />
          </svg>
          <span v-t="'SelectScalarValue.willOpenPicker'"></span>
        </InputHelpText>
      </div>

      <div class="space-y-1" v-if="hasSelection">
        <InputLabel v-t="'SelectScalarValue.selectedValuePreview'"></InputLabel>

        <ValuePreview class="px-2 py-1 overflow-x-auto whitespace-nowrap">
          <div
            :class="{
              'text-app-gold':
                loadingValuePreview === false && valuePreview !== null,
              'text-gray-500':
                loadingValuePreview === true || valuePreview === null,
            }"
            class="transform translate-y-px"
          >
            {{ valuePreviewText }}
          </div>
        </ValuePreview>
        <InputHelpText v-t="'SelectScalarValue.valuePreviewHelp'">
        </InputHelpText>
      </div>

      <!-- 
      If a user creates a complex condition with many Scalar values
      v-show could become a performance issue because of how many modals
      with data grids would be in the DOM.  If so, we can switch to v-if
    -->
      <Modal size="fill" v-show="modalIsVisible" @close="onCloseModal">
        <div class="flex flex-grow p-4">
          <SelectScalarNode
            @load="onSourceLoaded"
            @change="onNodeSelected"
            @cancel="onCloseModal"
            :connectionUuid="model.connectionUuid"
            :nodeUuid="model.nodeUuid"
            :nodeQuery="model.value"
            :targetType="inputType"
            @back="onCloseModal"
          />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { useConditionEditorStore } from "@/stores/conditionEditor";
import { NodeData, SchemaNode } from "@/types/data";
import { LogicOperand } from "@/types/logic";

import { InputOption } from "@/types/inputs";
import { OperandChangedEvent } from "./OperandChangedEvent";
import { OperandInputValueType } from "./OperandSelectType";

import Modal from "@/components/Modal.vue";
import SelectMenu from "@/components/SelectMenu.vue";
import OutlineButton from "@/components/OutlineButton.vue";
import SelectScalarNode from "./SelectScalarNode.vue";
import InputLabel from "./InputLabel.vue";
import InputHelpText from "./InputHelpText.vue";
import ValuePreview from "./ValuePreview.vue";
import ToolTip from "../Tooltip.vue";
import LightningBoltIcon from "../icons/LightningBoltIcon.vue";
import { isNonEmptyString } from "@core/utils/isNonEmptyString";

@Component({
  components: {
    SelectMenu,
    OutlineButton,
    Modal,
    SelectScalarNode,
    ToolTip,
    LightningBoltIcon,
    InputLabel,
    InputHelpText,
    ValuePreview,
  },
})
export default class SelectScalarValue extends Vue {
  @Prop(Object) model: LogicOperand;
  @Prop(Object) node: SchemaNode;
  @Prop(String) inputType: OperandInputValueType;

  loadingValuePreview = true;
  loadingConnections = false;
  modalIsVisible = false;
  showFullPreview = false;
  valuePreview: string | null = null;

  get connectionOptions(): InputOption[] {
    if (this.store.connections === null) {
      return [];
    }

    // Do not expose option to select scalar value from calendar, because that operation is not supported on the backend.
    return this.store.connections
      .filter((c) => c.schemaType !== "Calendar")
      .map((c) => ({
        label: c.name,
        value: c.uuid,
      }));
  }

  get hasSelection() {
    return isNonEmptyString(this.model.nodeUuid);
  }

  get connectionsLoaded() {
    return this.loadingConnections === false && this.store.connections !== null;
  }

  get valuePreviewText() {
    if (this.loadingValuePreview) {
      return this.$t("SelectScalarValue.loading").toString();
    }

    if (this.valuePreview === null) {
      return this.$t("SelectScalarValue.empty").toString();
    }

    return this.valuePreview;
  }

  created() {
    this.loadingConnections = true;
    this.store.loadConnections().finally(() => {
      this.loadingConnections = false;
      if (
        !isNonEmptyString(this.model.connectionUuid) &&
        this.connectionOptions.length > 0
      ) {
        this.onConnectionSelected(this.connectionOptions[0].value as string);
      }
    });
  }

  onConnectionSelected(uuid: string) {
    if (this.model.connectionUuid !== uuid) {
      const operand: OperandChangedEvent = {
        operand: {
          connectionUuid: uuid,
        },
        node: undefined,
      };
      this.$emit("input", operand);
    }
  }

  onNodeSelected(node: NodeData) {
    const operand: OperandChangedEvent = {
      operand: {
        connectionUuid: this.model.connectionUuid,
        nodeUuid: node.uuid as string,
        value: node.query,
        valueType: node.dataType,
      },
      node: {
        dataType: node.dataType,
        query: node.query,
        uuid: node.uuid as string,
      },
    };
    this.loadingValuePreview = false;

    // Sometimes Scalar Date values have empty "formattedValue"
    // so we'll fallback to `value` if formattedValue is empty.
    let preview = node.formattedValue;
    if (!isNonEmptyString(preview)) {
      preview = (node.value ?? "").toString();
    }
    this.valuePreview = preview;
    this.modalIsVisible = false;
    this.$emit("input", operand);
  }

  onSourceLoaded(selection: NodeData) {
    this.loadingValuePreview = false;
    const value = selection?.formattedValue;
    if (isNonEmptyString(value)) {
      this.valuePreview = value;
    }
  }

  showModal() {
    this.modalIsVisible = true;
  }

  onCloseModal() {
    this.modalIsVisible = false;
  }

  previewMouseEnter(e: MouseEvent) {
    const el = e.target as HTMLDivElement;
    this.showFullPreview = el.offsetWidth < el.scrollWidth;
  }
  previewMouseLeave() {
    this.showFullPreview = false;
  }

  get store() {
    return useConditionEditorStore();
  }
}
</script>
