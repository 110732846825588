<template>
  <form class="p-10" @submit.prevent="submitForm">
    <div class="pt-1 text-2xl" v-t="'apps.chooseDimensions'"></div>
    <div class="text mt-2 mb-8">
      <span v-t="'apps.promptTemplates'"></span>

      <router-link
        to="/templates"
        v-t="'apps.templates'"
        class="ml-1 text-app-magenta border-app-magenta border-b"
      />
    </div>

    <div class="flex items-center">
      <div
        v-for="(size, i) in defaultAppSizes"
        :key="i"
        :class="[
          activeSizeIndex === i ? 'bg-app-magenta' : 'opacity-80',
          size.h > size.w ? 'flex-col' : '',
        ]"
        class="hover:bg-app-magenta cursor-pointer flex-shrink-0 bg-gray-700 border border-gray-200 mr-8 flex justify-center items-center text-sm p-8 text-center"
        :style="getAppSizeStyle(size)"
        style="line-height: 1"
        @click="selectSize(i)"
      >
        <div>{{ size.w }}</div>
        <div>x</div>
        <div>{{ size.h }}</div>
        <!-- {{ size.w }}x{{ size.h }} -->
      </div>
    </div>

    <div class="flex items-center text-md mt-10 space-x-8">
      <div>
        <div class="mr-3" v-t="'width'"></div>
        <input
          type="number"
          :value="appWidth"
          @input="inputAppWidth"
          class="w-32 rounded px-2 py-1 text-black"
        />
      </div>
      <div>
        <div class="mr-3" v-t="'height'"></div>
        <input
          type="number"
          :value="appHeight"
          @input="inputAppHeight"
          class="w-32 rounded px-2 py-1 text-black"
        />
      </div>
    </div>
    <div
      :class="{ invisible: !showErrorMsg }"
      class="text-red-500 mt-2 ml-8"
      v-t="'apps.dimensionsError'"
    ></div>

    <label class="pb-1 mt-2" v-t="'timezone.timezone'"></label>
    <TimeZoneSelect style="max-width: 640px" @selectTimeZone="selectTimeZone" />
    <div class="mb-10 mt-10" style="max-width: 640px">
      <div class="text-2xl mb-2" v-t="'apps.promptName'"></div>
      <input
        type="text"
        v-model="appName"
        class="w-full rounded px-2 py-1 text-black"
      />
    </div>

    <ButtonGradient
      type="submit"
      :disabled="createButtonDisabled"
      class="px-5 py-2 mr-6"
      ><span v-t="'apps.createShort'"></span
    ></ButtonGradient>

    <button
      type="button"
      class="px-5 py-2 bg-gray-700 rounded-md hover:bg-gray-600"
      @click="cancelClick"
      v-t="'cancel'"
    ></button>
  </form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Size } from "@/types";
import NumberInput from "@/components/inputs/NumberInput.vue";
import IconSolid from "@/components/icons/IconSolid.vue";
import Icon from "@/components/icons/Icon.vue";
import ButtonGradient from "@/components/ButtonGradient.vue";
import TimeZoneSelect from "@/components/TimeZoneSelect.vue";
import { EventBus } from "@/eventbus";
import SelectMenu from "@/components/SelectMenu.vue";
import { useAppEditorStore } from "@/stores/appEditor";

// [ ] fix btn sizes, line height, char spacing issues

// [x-ish] need validation
// Hmm......don't want to compute showError...bc then shows at start.
// But after they get the message....how to make it go away?
// Maybe....you don't??
// [x-ish] issue of getting to templates view from here.....maybe put that stuff on store after all, like with applistview?

// [ ] probably want some kind of "creating app..." modal

// Must set activeIdx to -1, if change w or h...
// But if change w or h to a defaultsize, set activeIdx to that idx...
// and then finally, when activeIdx changes (from click), need to set w and h...

@Component({
  components: {
    NumberInput,
    IconSolid,
    Icon,
    ButtonGradient,
    SelectMenu,
    TimeZoneSelect,
  },
})
export default class CreateApp extends Vue {
  appWidth: number | null = null;
  appHeight: number | null = null;
  appName = "";
  timeZone = "";

  showErrorMsg = false;

  defaultAppSizes: Size[] = [
    { w: 1920, h: 1080 },
    { w: 3840, h: 2160 },
    { w: 1080, h: 1920 },
    { w: 2160, h: 3840 },
  ];

  // Nothing selected, by default
  activeSizeIndex = -1;

  @Watch("appWidth")
  widthChanged(val: number) {
    const w = val;
    const h = this.appHeight;
    const idx = this.defaultAppSizes.findIndex((s) => s.w === w && s.h === h);
    this.activeSizeIndex = idx;
  }

  @Watch("appHeight")
  heightChanged(val: number) {
    const h = val;
    const w = this.appWidth;
    const idx = this.defaultAppSizes.findIndex((s) => s.w === w && s.h === h);
    this.activeSizeIndex = idx;
  }

  mounted() {
    this.timeZone = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.toLowerCase();
  }

  selectTimeZone(timeZone: string) {
    this.timeZone = timeZone;
  }

  get createButtonDisabled() {
    if (
      this.appHeight === null ||
      this.appWidth === null ||
      this.appName === ""
    )
      return true;
    return false;
  }

  cancelClick() {
    this.$router.push({ path: "/" });
  }

  async submitForm() {
    // Do validation here
    if (
      this.appWidth === null ||
      isNaN(this.appWidth) ||
      this.appWidth < 10 ||
      this.appHeight === null ||
      isNaN(this.appHeight) ||
      this.appHeight < 10
    ) {
      this.showErrorMsg = true;
      return;
    }

    // NOTE: no need to disable btn here, since loader covers it
    EventBus.emit("AWAITING_SERVER", true);

    // [ ] TODO: ERROR HANDLING
    const appEditor = useAppEditorStore();
    const w = this.appWidth;
    const h = this.appHeight;
    const newId = await appEditor.createApp({
      name: this.appName,
      size: { w, h },
      ianaTimeZone: this.timeZone,
    });

    EventBus.emit("AWAITING_SERVER", false);
    this.$router.push(`/app/edit/${newId}`);
  }

  selectSize(idx: number) {
    this.appWidth = this.defaultAppSizes[idx].w;
    this.appHeight = this.defaultAppSizes[idx].h;

    this.activeSizeIndex = idx;
  }

  inputAppWidth(ev: any) {
    const val = parseInt(ev.target.value);
    this.appWidth = val;
  }

  inputAppHeight(ev: any) {
    const val = parseInt(ev.target.value);
    this.appHeight = val;
  }

  getAppSizeStyle(size: Size) {
    const max = Math.max(size.w, size.h);
    const target = 150;
    return {
      width: `${(target * size.w) / max}px`,
      height: `${(target * size.h) / max}px`,
    };
  }
}
</script>

<style lang="postcss" scoped>
/* .cancel-btn {
  background: linear-gradient(to bottom, rgb(125, 125, 125), rgb(70, 70, 70));
} */

.text-3xl {
  font-size: 1.8rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
