<template>
  <input
    type="text"
    ref="input"
    :value="value"
    class="shadow-sm px-2 py-1 focus:ring-app-teal focus:ring-app-teal block w-full border border-gray-300 rounded-md"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @dblclick="onDoubleClick"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TextInput extends Vue {
  @Prop() value: any;

  onDoubleClick() {
    let input = this.$refs.input as HTMLInputElement;
    input.select();
  }
}
</script>
